import { createContext, useContext } from 'react';
import { Handle, On } from '@modules/emitter';

export const TermsAndConditionsModalContext = createContext<
  | {
      opened: boolean;
      on: On;
      emit: Handle;
      oneYearTermsOpened: boolean;
    }
  | undefined
>(undefined);

export const useTermsAndConditionsModalContext = () => {
  const context = useContext(TermsAndConditionsModalContext);
  if (context === undefined) {
    throw new Error(
      'useTermsAndConditionsModalContext must be used within a TermsAndConditionsModalProvider',
    );
  }
  return context;
};
