import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '@graphql/fragments/common/customerFragment';

export const SOCIAL_LOGIN = gql`
  mutation socialLogin($connectionToken: String!, $rememberMe: Boolean) {
    socialLogin(connectionToken: $connectionToken, rememberMe: $rememberMe) {
      accessToken
      refreshToken
      presumedToken
      customer {
        ...customerFragment
      }
      id
      authEvent {
        loginId
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;
