import { FilterQuery } from '@commons/filters';
import { Order } from '@commons/order';
import { FILTER_IDS } from '@commons/reorder';
import { routing } from '@constants/routing';

export const useReorderLinkWithFilter = (order: Order) => {
  if (!order.reorder) return null;

  const filterQuery: FilterQuery = {
    facetId: FILTER_IDS.ORDERS_BY_DATE,
    facetValueIds: [order.orderId],
  };

  const query = new URLSearchParams({
    filters: JSON.stringify([filterQuery]),
  });

  return `${routing.reorderItems}?${query}`;
};
