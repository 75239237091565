import { useApolloClient } from '@apollo/client';
import { Dispatch } from 'react';
import { StatusLevelType } from '@api';
import { AuthUser, USER_AUTH_STATUS } from '@modules/auth/types';
import { getUserFromMinimumAccountPreferences } from '@modules/auth/utils/getUserFromMinimumAccountPreferences';
import { getUserFromAccountPreferences } from '@modules/auth/utils/getUserFromAccountPreferences';
import {
  queryAccountPreferences,
  queryMinimumAccountPreferences,
} from '@modules/auth/hooks/queryAccountPreferences';
import { AUTH_USER_ACTIONS_TYPES, AuthUserActions } from '../context/AuthUserReducer';

interface InitialAuthInfoParams {
  dispatch?: Dispatch<AuthUserActions>;
}

export const useSetInitialAuthInfo = ({ dispatch }: InitialAuthInfoParams) => {
  const client = useApolloClient();

  const updateUserInfo = (incomingUserInfo: AuthUser) => {
    dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_USER_INFO, payload: incomingUserInfo });
  };

  const initAuthInfo = async () => {
    const { data: minimumAccountPreferencesData } = await queryMinimumAccountPreferences(client);
    const { accountPreferences: minimumAccountPreferences } = minimumAccountPreferencesData;

    if (minimumAccountPreferences === null) {
      dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS, payload: StatusLevelType.GUEST });
    } else if (minimumAccountPreferences.statusLevel === StatusLevelType.RECOGNIZED) {
      dispatch?.({
        type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS,
        payload: StatusLevelType.RECOGNIZED,
      });
      updateUserInfo(getUserFromMinimumAccountPreferences(minimumAccountPreferencesData));
    } else {
      const { data: accountPreferencesData } = await queryAccountPreferences(client);
      dispatch?.({
        type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS,
        payload: StatusLevelType.SIGNED_IN,
      });
      updateUserInfo(getUserFromAccountPreferences(accountPreferencesData));

      dispatch?.({
        type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
        payload: USER_AUTH_STATUS.AUTH_COMPLETE,
      });
    }
  };

  return {
    initAuthInfo,
    updateUserInfo,
  };
};
