import { Cache, useApolloClient } from '@apollo/client';
import { Modifier, Modifiers } from '@apollo/client/cache';
import { useCallback } from 'react';

export const useCacheFieldsInvalidate = () => {
  const client = useApolloClient();

  const cleanFunction: Modifiers | Modifier<unknown> = (_, { DELETE }) => {
    return DELETE;
  };

  const clearCacheFields = useCallback(
    (cacheFields: string[], options?: Partial<Cache.ModifyOptions>) => {
      const preparedFields = cacheFields.reduce(
        (obj, field) => ({ ...obj, [field]: cleanFunction }),
        {},
      );

      client.cache.modify({
        ...options,
        fields: {
          ...preparedFields,
        },
      });
    },
    [client.cache],
  );

  return {
    clearCacheFields,
  };
};
