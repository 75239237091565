import { gql } from '@apollo/client';

export const MINIMAL_STANDING_ORDERS_INFO = gql`
  query GetMinimalStandingOrdersInfo {
    standingOrders {
      id
      error {
        code
        message
        detail
      }
    }
  }
`;
