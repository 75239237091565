import { gql } from '@apollo/client';
import { BREADCRUMB_FRAGMENT } from '@graphql/fragments/common/breadcrumbFragment';
import { PRODUCT_BASIC_FRAGMENT } from './productBasicFragment';

export const PRODUCT_CART_PAGE_FRAGMENT = gql`
  fragment productCartPageFragment on ProductExtendedData {
    productBasicData {
      ...productBasicFragment
    }
    breadcrumbs {
      ...breadcrumbFragment
    }
  }
  ${PRODUCT_BASIC_FRAGMENT}
  ${BREADCRUMB_FRAGMENT}
`;
