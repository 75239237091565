import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getUrlAnchor } from '@utils/getUrlAnchor';

export const useScrollToAnchor = (loading: boolean) => {
  const router = useRouter();

  useEffect(() => {
    const urlHashLink = getUrlAnchor(router.asPath);

    if (!loading && urlHashLink) {
      const elementToScroll = document.getElementById(urlHashLink);
      if (elementToScroll) {
        window.scrollTo(0, elementToScroll.offsetTop);
      }
    }
  }, [loading, router.asPath]);
};
