import { ApolloCache, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { AccountPreferences } from '@api';
import { ACCOUNT_PREFERENCES, MINIMUM_ACCOUNT_PREFERENCES } from '@graphql/account';

export const updateAccountPreferecesQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  data: Partial<AccountPreferences>,
) => {
  client.writeQuery({
    query: ACCOUNT_PREFERENCES,
    data: {
      accountPreferences: {
        ...data,
      },
    },
  });
};

export const updateMinimumAccountPreferecesQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  data: Partial<AccountPreferences>,
) => {
  client.writeQuery({
    query: MINIMUM_ACCOUNT_PREFERENCES,
    data: {
      accountPreferences: {
        ...data,
      },
    },
  });
};

export const updateAccountPreferencesCache = (
  cache: ApolloCache<unknown>,
  updatedPreferences: Partial<AccountPreferences>,
) => {
  cache.modify({
    fields: {
      accountPreferences(preferences) {
        return {
          ...preferences,
          ...updatedPreferences,
        };
      },
    },
  });
};
