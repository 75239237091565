import { OrderModificationCheckoutResult as OrderModificationCheckoutResultApi } from '@api';
import { CheckoutResult } from '@commons/checkout';
import { accountAdapter } from '@adapters/accountAdapter';
import { formatTimePeriodString } from '@utils/formatTimePeriodString';

const { getDeliveryAddress, getPaymentFullInfo } = accountAdapter();

export const getCheckOutResult = (
  data?: DeepPartial<OrderModificationCheckoutResultApi>,
): CheckoutResult => ({
  deliveryAddress: getDeliveryAddress(data?.deliveryAddress),
  payments: {
    coveredByGiftCard: !!data?.payments?.coveredByGiftCard,
    payments: data?.payments?.payments?.map(getPaymentFullInfo) ?? [],
  },
  timeslot: {
    timePeriod: formatTimePeriodString(data?.timeslot?.timePeriod ?? ''),
    startDate: data?.timeslot?.startDate ?? '',
    endDate: data?.timeslot?.endDate ?? '',
  },
  cart: {
    deliveryPassCartOnly: !!data?.cart?.deliveryPassCartOnly,
  },
  isExpressOrder: data?.isExpressOrder ?? false,
});
