import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { CustomAmountControls } from '../CustomAmountControls/CustomAmountControls';
import styles from './EditView.module.scss';

interface EditViewProps {
  minCustomAmount?: number;
  onSave: () => void;
}

export const EditView = ({ minCustomAmount = 20, onSave }: EditViewProps) => {
  const { t } = useLocalization('giftCards');
  return (
    <div className={styles.container}>
      <Typography variant="h3" component="h3" className={styles.title}>
        {t('giftCardModal.customAmountTitle')}
      </Typography>
      <Typography variant="subtitle1" component="p" className={styles.subtitle}>
        {t('giftCardModal.minimalAmount', { value: minCustomAmount })}
      </Typography>
      <CustomAmountControls onSave={onSave} />
    </div>
  );
};
