import { ChangeEvent, WheelEvent, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Divider, Typography } from '@mui/material';
import * as yup from 'yup';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLocalization } from '@hooks/useLocalization';
import { Button, DropDown, FormikInput } from '@components/UI';
import { EDIT_CARD_TYPE, PAYMENT_TYPE } from '@commons/payment';
import {
  amex as AmexIcon,
  discover as DiscoverIcon,
  mastercard as MastercardIcon,
  visa as VisaIcon,
} from '@assets/icons/payments';
import { getExpirationMonthOptions } from '@utils/getExpirationMonthOptions';
import { getExpirationYearsOptions } from '@utils/getExpirationYearsOptions';
import { Grid } from '@components/UI/Grid/Grid';
import { usePaymentMethod } from '@hooks/usePaymentMethods/usePaymentMethod';
import { useEditPayment } from '@hooks/payments/useEditPayment';
import { ALERT_TYPES } from '@commons/account';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { PhoneField } from '@components/FormikFields';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';
import { useCountries } from '@hooks/common/useCountries';
import { getCountryOptions } from '@utils/getCountryOptions';
import { BACKGROUND_MODE } from '@commons/modals';
import { handlePreventNotNumbers } from '@utils/validation';
import { RecaptchaDisclaimer } from '@components/RecaptchaDisclaimer/RecaptchaDisclaimer';
import { getRoutingBasedUITheme } from '@helpers/common.helpers';
import styles from './EditCardModal.module.scss';

interface EditCardModalProps {
  opened: boolean;
  closeModal: () => void;
  id: string;
  backgroundMode?: BACKGROUND_MODE;
}

type SetFieldValue = (field: string, value?: string) => void;

const DEFAULT_COUNTRY_NAME = 'US';
const MAX_CVC_LENGTH = 4;
const MAX_ZIP_CODE_LENGTH = 5;
const MIN_ZIP_CODE_NUMBER = 0;
const MAX_ZIP_CODE_NUMBER = 99999;
const MAX_CARDHOLDER_LENGTH = 80;
const MAX_BILLING_ADDRESS_LENGTH = 55;
const MAX_APT_LENGTH = 20;
const REG_EXP_CARDHOLDER = /^[A-Za-z\s]+$/g;
const REG_EXP_BILLING_ADDRESS = /^[A-Za-z\d\s\\/]*$/g;
const REG_EXP_NUMBERS = /^\d+$/g;
const PHONE_MASK = '(___) ___-____';

export const EditCardModal = ({ opened, closeModal, id, backgroundMode }: EditCardModalProps) => {
  const { t } = useLocalization('account');
  const uiTheme = getRoutingBasedUITheme();
  const standingOrderMode = useStandingOrderMode();
  const { data: editCardInfo } = usePaymentMethod(id);
  const [errorMessage, setErrorMessage] = useState('');
  const [editPayment] = useEditPayment();
  const { dispatchAlert } = useAccountAlertContext();
  const { data: countries } = useCountries();
  const countryOptions = useMemo(() => {
    return getCountryOptions(countries);
  }, [countries]);
  const expirationYearsOptions = useMemo(() => {
    return getExpirationYearsOptions();
  }, []);
  const expirationMonthOptions = useMemo(() => {
    return getExpirationMonthOptions();
  }, []);

  const requiredError = t('payments.editModal.errors.required');
  const billingError = t('payments.editModal.errors.billingAddress');

  const schema = yup.object().shape({
    [EDIT_CARD_TYPE.CARDHOLDER]: yup
      .string()
      .matches(REG_EXP_CARDHOLDER, t('payments.editModal.errors.cardholder'))
      .required(requiredError),
    [EDIT_CARD_TYPE.CVC]: yup
      .string()
      .min(3, t('payments.editModal.errors.cvcRequired'))
      .matches(REG_EXP_NUMBERS, t('payments.editModal.errors.cvcDigits'))
      .required(requiredError),
    [EDIT_CARD_TYPE.BILLING_ADDRESS]: yup
      .string()
      .matches(REG_EXP_BILLING_ADDRESS, billingError)
      .required(requiredError),
    [EDIT_CARD_TYPE.CITY]: yup
      .string()
      .matches(REG_EXP_BILLING_ADDRESS, billingError)
      .required(requiredError),
    [EDIT_CARD_TYPE.APARTMENT]: yup.string().matches(REG_EXP_BILLING_ADDRESS, billingError),
  });

  const renderPaymentIcon = (): JSX.Element | null => {
    switch (editCardInfo.cardType) {
      case PAYMENT_TYPE.VISA:
        return <VisaIcon className={styles.payment_icon} />;
      case PAYMENT_TYPE.MASTERCARD:
        return <MastercardIcon className={styles.payment_icon} />;
      case PAYMENT_TYPE.DISCOVER:
        return <DiscoverIcon className={styles.payment_icon} />;
      case PAYMENT_TYPE.AMEX:
        return <AmexIcon className={styles.payment_icon} />;
      default:
        return null;
    }
  };

  const onChangeExpirationMonth = (setFieldValue: SetFieldValue, newValue: string) => {
    setFieldValue(EDIT_CARD_TYPE.EXPIRATION_MONTH, newValue);
  };

  const onChangeExpirationYear = (setFieldValue: SetFieldValue, newValue: string) => {
    setFieldValue(EDIT_CARD_TYPE.EXPIRATION_YEAR, newValue);
  };

  const onChangeCountry = (setFieldValue: SetFieldValue, newValue: string) => {
    setFieldValue(EDIT_CARD_TYPE.COUNTRY, newValue);
  };

  const validateZipcode = (value: string) => {
    const INVALID_ZIPCODE_VALUE = '00000';
    if (!value) return requiredError;
    if (value === INVALID_ZIPCODE_VALUE) return t('payments.editModal.errors.invalidZipCode');
  };

  const validatePhone = (value: string) => {
    if (!value) {
      return requiredError;
    }
    if (value === PHONE_MASK) {
      return t('payments.editModal.errors.phone');
    }
    if (value.includes('_')) {
      return t('payments.editModal.errors.phone');
    }
  };

  const onChangeZipcode = (e: ChangeEvent<HTMLInputElement>, setField: SetFieldValue) => {
    setField(EDIT_CARD_TYPE.ZIP_CODE, e.target.value.slice(0, MAX_ZIP_CODE_LENGTH));
  };

  const onChangeCvc = (e: ChangeEvent<HTMLInputElement>, setField: SetFieldValue) => {
    setField(EDIT_CARD_TYPE.CVC, e.target.value.slice(0, MAX_CVC_LENGTH));
  };

  const handleSubmit = async (values: { [key in EDIT_CARD_TYPE]: string }) => {
    const input = {
      id,
      cardMonth: values.expiration_month,
      cvc: values.cvc,
      cardHolderName: values.cardholder,
      cardYear: values.expiration_year,
      cardBrand: editCardInfo.cardType,
      phone: values.phone,
      address: {
        address1: values.billing_address,
        city: values.city,
        country: values.country,
        zipCode: values.zip_code.toString(),
        apartment: values.apartment,
        state: editCardInfo.billingAddress?.state,
        serviceType: editCardInfo.billingAddress?.serviceType || SERVICE_TYPE.HOME,
      },
    };

    editPayment({
      variables: {
        input,
        standingOrderMode,
      },
      onCompleted: (res) => {
        if (!!res?.editPayment?.validationErrors?.length) {
          return setErrorMessage(res.editPayment.validationErrors[0].error);
        }
        dispatchAlert(
          ALERT_TYPES.SUCCESS,
          t('payments.successMessages.editCard', {
            cardType: editCardInfo.cardType,
            cardNumber: editCardInfo.cardNumber,
          }),
        );
        closeModal();
      },
    });
  };

  return (
    <ModalWindow
      title={t('payments.editPaymentModalTitle')}
      open={opened}
      className={styles.edit_modal}
      onClose={closeModal}
      backgroundMode={backgroundMode}
      errorMessage={errorMessage}
      isScrollable
    >
      <Formik
        className={styles.form}
        initialValues={{
          [EDIT_CARD_TYPE.CARDHOLDER]: editCardInfo.nameOnCard,
          [EDIT_CARD_TYPE.CARD_NUMBER]: `•••• •••• •••• ${editCardInfo.cardNumber}`,
          [EDIT_CARD_TYPE.EXPIRATION_MONTH]: editCardInfo.expirationMonth,
          [EDIT_CARD_TYPE.EXPIRATION_YEAR]: editCardInfo.expirationYear,
          [EDIT_CARD_TYPE.CVC]: '',
          [EDIT_CARD_TYPE.BILLING_ADDRESS]: editCardInfo.billingAddress?.address1 || '',
          [EDIT_CARD_TYPE.APARTMENT]: editCardInfo.billingAddress?.apartment || '',
          [EDIT_CARD_TYPE.COUNTRY]: editCardInfo.billingAddress?.country || DEFAULT_COUNTRY_NAME,
          [EDIT_CARD_TYPE.PHONE]: editCardInfo.phoneNumber,
          [EDIT_CARD_TYPE.CITY]: editCardInfo.billingAddress?.city || '',
          [EDIT_CARD_TYPE.ZIP_CODE]: editCardInfo.billingAddress?.zipCode || '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnChange={false}
      >
        {({ errors, validateField, setFieldValue, values }) => (
          <Form className={styles.form}>
            <Grid container rowSpacing={4} columnSpacing={2} columns={{ xs: 4, sm: 6 }}>
              <Grid item xs={4} sm={6}>
                <Field
                  name={EDIT_CARD_TYPE.CARDHOLDER}
                  control="input"
                  type="text"
                  label={t('payments.editModal.cardholder')}
                  component={FormikInput}
                  maxLength={MAX_CARDHOLDER_LENGTH}
                  error={!!errors[EDIT_CARD_TYPE.CARDHOLDER]}
                  helperText={errors[EDIT_CARD_TYPE.CARDHOLDER]}
                  onBlur={() => validateField(EDIT_CARD_TYPE.CARDHOLDER)}
                  id={EDIT_CARD_TYPE.CARDHOLDER}
                />
              </Grid>
              <Grid item xs={4} sm={6}>
                <Field
                  name={EDIT_CARD_TYPE.CARD_NUMBER}
                  control="input"
                  type="text"
                  label={t('payments.editModal.cardNumber')}
                  component={FormikInput}
                  icon={renderPaymentIcon()}
                  readOnly
                  id={EDIT_CARD_TYPE.CARD_NUMBER}
                />
              </Grid>
              <Grid item xs={4} sm={6}>
                <Grid container columns={3} columnSpacing={2}>
                  <Grid item xs={1}>
                    <DropDown
                      className={styles.with_margin_right}
                      title={t('payments.editModal.expirationMonth')}
                      value={values.expiration_month}
                      onChange={(newValue) => onChangeExpirationMonth(setFieldValue, newValue)}
                      options={expirationMonthOptions}
                      id="edit-payment-card-month"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <DropDown
                      className={styles.with_margin_right}
                      title={t('payments.editModal.expirationYear')}
                      value={values.expiration_year}
                      onChange={(newValue) => onChangeExpirationYear(setFieldValue, newValue)}
                      options={expirationYearsOptions}
                      id="edit-payment-card-year"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Field
                      name={EDIT_CARD_TYPE.CVC}
                      control="input"
                      type="text"
                      maxLength={MAX_CVC_LENGTH}
                      label={t('payments.editModal.cvc')}
                      component={FormikInput}
                      error={!!errors[EDIT_CARD_TYPE.CVC]}
                      helperText={errors[EDIT_CARD_TYPE.CVC]}
                      onBlur={() => validateField(EDIT_CARD_TYPE.CVC)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeCvc(e, setFieldValue)}
                      onKeyPress={handlePreventNotNumbers}
                      onWheel={(e: WheelEvent) => (e.target as HTMLInputElement).blur()}
                      id={EDIT_CARD_TYPE.CVC}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={6}>
                <Divider className={styles.divider} />
              </Grid>
              <Grid item xs={4} sm={6}>
                <Typography variant="h5" className={styles.title}>
                  {t('payments.editModal.billingAddress')}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={6}>
                <Field
                  name={EDIT_CARD_TYPE.BILLING_ADDRESS}
                  control="input"
                  type="text"
                  maxLength={MAX_BILLING_ADDRESS_LENGTH}
                  label={t('payments.editModal.billingAddress')}
                  component={FormikInput}
                  error={!!errors[EDIT_CARD_TYPE.BILLING_ADDRESS]}
                  helperText={errors[EDIT_CARD_TYPE.BILLING_ADDRESS]}
                  onBlur={() => validateField(EDIT_CARD_TYPE.BILLING_ADDRESS)}
                  id={EDIT_CARD_TYPE.BILLING_ADDRESS}
                />
              </Grid>
              <Grid item xs={2} sm={3}>
                <Field
                  className={styles.with_margin_right}
                  name={EDIT_CARD_TYPE.APARTMENT}
                  control="input"
                  type="text"
                  maxLength={MAX_APT_LENGTH}
                  label={t('payments.editModal.apartment')}
                  component={FormikInput}
                  error={!!errors[EDIT_CARD_TYPE.APARTMENT]}
                  helperText={errors[EDIT_CARD_TYPE.APARTMENT]}
                  onBlur={() => validateField(EDIT_CARD_TYPE.APARTMENT)}
                  id={EDIT_CARD_TYPE.APARTMENT}
                />
              </Grid>
              <Grid item xs={2} sm={3}>
                <DropDown
                  title={t('payments.editModal.country')}
                  value={values.country}
                  onChange={(newValue) => onChangeCountry(setFieldValue, newValue)}
                  options={countryOptions}
                  id="edit-payment-card-country"
                />
              </Grid>
              <Grid item xs={2} sm={3}>
                <Field
                  className={styles.with_margin_right}
                  name={EDIT_CARD_TYPE.CITY}
                  control="input"
                  type="text"
                  maxLength={MAX_BILLING_ADDRESS_LENGTH}
                  label={t('payments.editModal.city')}
                  component={FormikInput}
                  error={!!errors[EDIT_CARD_TYPE.CITY]}
                  helperText={errors[EDIT_CARD_TYPE.CITY]}
                  onBlur={() => validateField(EDIT_CARD_TYPE.CITY)}
                  id={EDIT_CARD_TYPE.CITY}
                />
              </Grid>
              <Grid item xs={2} sm={3}>
                <Field
                  name={EDIT_CARD_TYPE.ZIP_CODE}
                  control="input"
                  type="text"
                  min={MIN_ZIP_CODE_NUMBER}
                  max={MAX_ZIP_CODE_NUMBER}
                  label={t('payments.editModal.zipCode')}
                  component={FormikInput}
                  validate={validateZipcode}
                  error={!!errors[EDIT_CARD_TYPE.ZIP_CODE]}
                  helperText={errors[EDIT_CARD_TYPE.ZIP_CODE]}
                  onBlur={() => validateField(EDIT_CARD_TYPE.ZIP_CODE)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeZipcode(e, setFieldValue)}
                  onKeyPress={handlePreventNotNumbers}
                  onWheel={(e: WheelEvent) => (e.target as HTMLInputElement).blur()}
                  id={EDIT_CARD_TYPE.ZIP_CODE}
                />
              </Grid>
              <Grid item xs={4} sm={6}>
                <Field
                  name={EDIT_CARD_TYPE.PHONE}
                  label={t(`payments.editModal.phone`)}
                  component={PhoneField}
                  value={values.phone}
                  validate={validatePhone}
                  error={!!errors[EDIT_CARD_TYPE.PHONE]}
                  helperText={errors[EDIT_CARD_TYPE.PHONE]}
                  onBlur={() => validateField(EDIT_CARD_TYPE.PHONE)}
                  id={EDIT_CARD_TYPE.PHONE}
                />
              </Grid>
              <Grid item xs={4} sm={6}>
                <Button
                  className={styles.button}
                  mode={uiTheme}
                  type="submit"
                  size="large"
                  fullWidth
                >
                  {t('payments.editModal.saveCreditCardButton')}
                </Button>
                <RecaptchaDisclaimer />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ModalWindow>
  );
};
