import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const CANCEL_MODIFY = gql`
  mutation cancelOrderModify {
    cancelOrderModify {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
