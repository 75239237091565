export enum FACET_TYPES {
  FILTER = 'FILTER',
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  // custom type, instead using null value
  NULL = 'NULL',
}

// todo: register all selection types (e.g. we could get 'MULTIPLE' type from the server)
export enum FACET_SELECTION_TYPE {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
  // custom type, instead using null value
  NULL = 'NULL',
}

export interface FacetValue {
  id: string;
  name: string;
  count: number | null;
  active: boolean;
  selected: boolean;
  synthetic?: boolean;
}

export interface Facet {
  id: string;
  name: string;
  count?: number | null;
  detailName?: string;
  type: FACET_TYPES;
  selectionType: FACET_SELECTION_TYPE;
  value: FacetValue[];
  disabled?: boolean;
  synthetic?: boolean;
  primary?: boolean;
}

export interface SortByFilter {
  id: string;
  name: string;
  selected: boolean;
}

export interface FilterQuery {
  facetId: string;
  facetValueIds: string[];
}
