import Trans from 'next-translate/Trans';
import { useLocalization } from '@hooks/index';
import styles from './AddressNotFound.module.scss';

interface AddressNotFoundProps {
  onClick: () => void;
}

export const AddressNotFound = ({ onClick }: AddressNotFoundProps) => {
  const { t } = useLocalization();

  return (
    <div className={styles.wrapper}>
      {t('delivery.addressNotFound')}{' '}
      <Trans
        i18nKey="common:delivery.addManuallyNote"
        components={{
          button: <button onClick={onClick} className={styles.button} />,
        }}
      />
    </div>
  );
};
