import { gql } from '@apollo/client';
import { DELIVERY_ADDRESS_FRAGMENT } from '@graphql/fragments/account/deliveryAddress';
import { ORDER_MODIFICATIONS_FRAGMENT } from '@graphql/fragments/cart/common/orderModificationsFragment';

export const CHECKOUT = gql`
  query Checkout {
    checkout {
      cart {
        deliveryPassCartOnly
        orderModifications {
          ...orderModificationsFragment
        }
      }
      deliveryAddress {
        ...deliveryAddressFragment
      }
      timeslot {
        id
        endDate
        soldOut
        startDate
        timePeriod
        unavailable
        selected
      }
      payments {
        coveredByGiftCard
        payments {
          id
          selected
          title
          accountNumber
          type
          expiration
          accountNumber
          bankAccountType
          bankName
          paymentTypeDescription
          eWalletID
          vendorEWalletID
          mpLogoURL
          ppLogoURL
          email
          isDefault
          isDebit
          isExpired
        }
      }
    }
  }
  ${DELIVERY_ADDRESS_FRAGMENT}
  ${ORDER_MODIFICATIONS_FRAGMENT}
`;
