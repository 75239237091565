import { RefObject, useEffect, useRef } from 'react';
import { isShowComponent, sendBeacon, manageView } from '@hooks/criteo/useCriteoBeacon.utils';
import { CriteoFormatBeacon } from '@modules/criteo';

interface UseCriteoBeacon {
  onViewBeacon?: string;
  onClickBeacon?: string;
  onLoadBeacon?: string;
  componentRef?: RefObject<HTMLDivElement | HTMLButtonElement>;
  isProductSelected?: boolean;
  criteoFormatBeaconView?: CriteoFormatBeacon;
}

export const useCriteoBeacon = ({
  onClickBeacon,
  onLoadBeacon,
  onViewBeacon,
  componentRef,
  isProductSelected,
  criteoFormatBeaconView,
}: UseCriteoBeacon) => {
  const viewed = useRef<boolean>(false);
  const loaded = useRef<boolean>(false);
  const isShow = isShowComponent(isProductSelected);

  const handleClick = () => {
    if (onClickBeacon) {
      sendBeacon(onClickBeacon);
    }
  };

  useEffect(() => {
    if (onLoadBeacon && !loaded.current) {
      sendBeacon(onLoadBeacon);
      loaded.current = true;
    }

    const observer = componentRef
      ? manageView({
          onViewBeacon,
          viewed,
          isShow,
          componentRef,
          criteoFormatBeaconView,
        })
      : undefined;
    return () => {
      observer?.disconnect();
    };
  }, [onLoadBeacon, onViewBeacon, componentRef, isShow, criteoFormatBeaconView]);

  return { handleClick };
};
