import { AccountPreferencesQuery } from '@api';
import { AuthUser } from '@modules/auth/types';

export const getUserFromAccountPreferences = (data: AccountPreferencesQuery): AuthUser => ({
  firstName: data?.accountPreferences?.firstName,
  lastName: data?.accountPreferences?.lastName,
  email: data?.accountPreferences?.emailAddress,
  memberSinceYear: data?.accountPreferences?.memberSinceYear?.toString(),
  phoneNumber: data?.accountPreferences?.phoneNumber,
  statusLevel: data?.accountPreferences?.statusLevel,
  isSocialLoginOnly: data?.accountPreferences?.isSocialLoginOnly,
  notifications: data?.accountPreferences?.notifications,
  isMasqueradeMode: data?.accountPreferences?.isMasqueradeMode,
  isEligibleForPreReservation: data?.accountPreferences?.isEligibleForPreReservation,
  workPhoneNumber: data?.accountPreferences?.workPhoneNumber,
  workPhoneExtension: data?.accountPreferences?.workPhoneExtension,
});
