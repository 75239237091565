import { DeliveryAddress } from '@commons/deliveryAddresses';
import { Payment } from '@commons/payment';
import { CartLineInfo } from '@commons/cart';
import { ProductBasicPrice } from '@commons/product';
import { Price } from '@commons/price';
import { Carousel } from '@commons/carousel';

export enum ORDER_STATUS {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  MODIFIED = 'MODIFIED',
  CONTACT_CUSTOMER_SERVICE = 'CONTACT_CUSTOMER_SERVICE',
  DELIVERED = 'DELIVERED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_TRANSIT = 'IN_TRANSIT',
  MODIFYING = 'MODIFYING',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  RETURNED = 'RETURNED',
}

export enum ORDER_TYPE {
  REGULAR = 'REGULAR',
  GIFT_CARD = 'GIFT_CARD',
  DELIVERY_PASS = 'DELIVERY_PASS',
  DONATION = 'DONATION',
}

export enum TIMESLOT {
  CHECK_AVAILABLE_TIMESLOTS = 'CHECK_AVAILABLE_TIMESLOTS',
  CHECKOUT_TIMESLOTS = 'CHECKOUT_TIMESLOTS',
}

export interface OrderState {
  isCancelable: boolean;
  isDelivered: boolean;
  isModifiable: boolean;
  isPending: boolean;
  isTrackable: boolean;
  isCreditEligible: boolean;
}

export interface TotalSection {
  deliveryFee: Price;
  tip: Price;
  totalTax: Price;
}

export interface Order {
  isActive: boolean;
  orderId: string;
  orderState: OrderState;
  orderStatus?: ORDER_STATUS;
  isExpressOrder: boolean;
  orderTotal: string;
  discounts: { value: { value: number } }[];
  reorder: boolean;
  requestedDate: string;
  deliveryEnd: string;
  deliveryStart: string;
  orderType?: ORDER_TYPE;
  totalSection: TotalSection;
  deliveryCutOffTime: string;
  deliveredAtDateTime: string;
  deliveryCutOffDateTime: string;
  modifiedOrderLinesCount: number;
  billingRef: string;
  soTemplateName: string;
}

export interface ConfOptionInput {
  characteristicName: string;
  characteristicValue: string;
}

export interface AddToCartInput {
  productId: string;
  configuration?: ConfOptionInput[];
  salesUnit: string;
  eventSource: string;
  quantity: number;
  categoryId: string;
  skuCode: string;
  itemListName?: string;
  itemPosition: number;
}

export interface ConfirmationOrder {
  cartLines: CartLineInfo[];
  delivery: DeliveryAddress;
  firstOrder: boolean;
  info: Order;
  payment: Payment;
  canModify: boolean;
  containsWineSection: boolean;
  containsDlvPassOnly: boolean;
  estimatedTotal: ProductBasicPrice;
  promoCodes: string[];
  modified: boolean;
  modifyOrderCount: number;
  orderSuccessEventId: string;
  recommendationCarousel: Carousel;
}

export interface LastDeliveredOrderInfo {
  orderId: string;
}

export interface LastDeliveredOrder {
  lastDeliveredOrders: LastDeliveredOrderInfo[];
}

export interface OrderDetails {
  cartLines: CartLineInfo[];
  delivery: DeliveryAddress;
  info: Order;
  payment: Payment;
  canModify: boolean;
  containsWineSection: boolean;
  estimatedTotal: ProductBasicPrice;
  totalWithoutTax: ProductBasicPrice;
}
