import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const SET_PRODUCT_QUANTITY_IN_LINE_ITEM = gql`
  mutation SetProductQuantityInLineItem(
    $lineItemId: ID!
    $quantity: Float!
    $inModifyMode: Boolean
  ) {
    changeCartLineQuantity(
      cartLineId: $lineItemId
      newQuantity: $quantity
      inModifyMode: $inModifyMode
    ) {
      ...cartFragment
      changeQuantityEventId
    }
  }
  ${CART_FRAGMENT}
`;
