import styles from './ControlsContent.module.scss';

interface ControlsContainerProps {
  deleteButton: React.ReactNode;
  isEditButtonVisible: boolean;
  editButton: React.ReactNode;
  unavailable: boolean;
  isCounterVisible: boolean;
  counter: React.ReactNode;
  isModifiedAmountVisible: boolean;
  modifiedAmount: React.ReactNode;
  isReplaceableBtnVisible: boolean;
  replaceBtn: React.ReactNode;
}

export const ControlsContainer = ({
  unavailable,
  isModifiedAmountVisible,
  modifiedAmount,
  isCounterVisible,
  counter,
  isEditButtonVisible,
  editButton,
  deleteButton,
  isReplaceableBtnVisible,
  replaceBtn,
}: ControlsContainerProps) => {
  return (
    <div className={styles.controllers}>
      {isEditButtonVisible && editButton}
      {unavailable ? (
        <>
          {isReplaceableBtnVisible && replaceBtn}
          {deleteButton}
        </>
      ) : (
        <>
          {deleteButton}
          {isModifiedAmountVisible && modifiedAmount}
          {isCounterVisible && counter}
        </>
      )}
    </div>
  );
};
