import React from 'react';
import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI/Button/Button';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './AutoRenewalDisableConfirmationModal.module.scss';

interface AutoRenewalDisableConfirmationModalProps {
  opened: boolean;
  onClose: () => void;
  expireDate: string;
  onDisable: () => void;
}

export const AutoRenewalDisableConfirmationModal = ({
  opened,
  onClose,
  expireDate,
  onDisable,
}: AutoRenewalDisableConfirmationModalProps) => {
  const { t } = useLocalization('account');

  const handleConfirm = () => {
    onDisable();
    onClose();
  };

  return (
    <ModalWindow
      open={opened}
      onClose={onClose}
      className={styles.wrapper}
      labelId={MODAL_TITLE_ID.AUTO_RENEWAL_DISABLE}
    >
      <>
        <Typography
          className={styles.heading}
          variant="h4"
          component="h2"
          id={MODAL_TITLE_ID.AUTO_RENEWAL_DISABLE}
        >
          {t('deliveryPass.disableAutoRenewal')}
        </Typography>
        <Typography className={styles.text} variant="body">
          {t('deliveryPass.disableWarning', { expireDate })}
        </Typography>
        <Button onClick={handleConfirm} size="large" isFullWidth>
          {t('deliveryPass.buttons.disable')}
        </Button>
        <Button
          className={styles.button}
          onClick={onClose}
          variant="outlined"
          size="large"
          isFullWidth
        >
          {t('deliveryPass.buttons.cancel')}
        </Button>
      </>
    </ModalWindow>
  );
};
