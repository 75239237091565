module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  logBuild: false,
  loadLocaleFrom: (lang, ns) => import(`./src/locales/${lang}/${ns}.json`).then((m) => m.default),
  pages: {
    '*': [
      'common',
      'productTile',
      'auth',
      'modals',
      'header',
      'components',
      'product',
      'account',
      'cart',
      'pagination',
      'legal',
      'errors',
      'footer',
      'checkout',
      'modify-order',
      'giftCards',
      'standingOrder',
      'search',
      'referAFriend',
    ],
    'rgx:^/order-modify': ['modify-order'],
    'rgx:^/help': ['help'],
    'rgx:^/expressco/checkout': ['checkout'],
    'rgx:^/superdepartment': ['superdepartment'],
    'rgx:^/quickshop': ['reorder'],
    'rgx:^/expressco/success': ['orderDetails', 'checkout'],
    'rgx:^/deals_sale': ['dealsAndSale'],
    'rgx:^/account/order_details': ['orderDetails', 'checkout'],
    'rgx:^/gift_card': ['giftCards'],
    'rgx:^/login/retrieve_password': ['retrieve_password'],
  },
};
