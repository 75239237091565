import { ValidateDeliveryAddressQuery } from '@api';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';

export const ATP_ERROR = `Sorry, we're unable to recognize this address. Please make sure the <b>building number</b> is correct.`;

export const ATP_FORMAT_ERROR = 'Use letters and numbers only';

export const ATP_ABSENCE_WARNING =
  "We're sorry, an Apt. number is required for this address. Please add an Apt. number or update the address.";

export const FLOOR_ROOM_ABSENCE_WARNING =
  "We're sorry, a Floor/Room number is required for this address. Please add a Floor/Room number or update the address.";

export const COMMERCIAL_ADDRESS_ERROR =
  'Sorry, according to our data this is a commercial address, eligible for our corporate delivery service, FreshDirect At The Office.';

export const RESIDENTIAL_ADDRESS_ERROR =
  'Sorry, according to our data this is a residential address, eligible for our Home Delivery service. Please remove any entered company name.';

export const UNABLE_TO_PROCESS_ERROR = `We''re sorry, but we''re unable to process this address. For assistance, please call our Customer Service Team at 1-866-283-7374`;

export const ENTER_ONE_OF_THE_FOLLOWING_ADDRESSES_ERROR = `Sorry, we're unable to recognize this address. Did you mean to enter one of the following instead?`;

export const DONT_RECOGNIZED_ERROR =
  "Sorry, we're unable to recognize this address - please make sure it's entered correctly";

export const APT_SUIT_REQUIRED = `An Apt/Suite # is required for this address.`;

export const DONT_RECOGNIZED_ADDRESS_ERROR = `Sorry, we're unable to recognize this <b>address</b>. Please make sure it's entered correctly.`;

export const UNABLE_TO_RECOGNIZE = `Sorry, we're unable to recognize this Apt/Suite #. Please make sure it's entered correctly.`;

export const ADDRESS_MUST_BE_IN_DELIVERY_ZONE_ERROR = `We're sorry, but you must have an address in one of our <a href="javascript:fd.components.zipCheckPopup.openZipCheckPopup()">delivery zones</a>. Please update your address or enter a new address.`;

export const OUT_OF_DELIVERY_ZONE_ERROR = `We're sorry; FreshDirect does not deliver to this address.  To see where we deliver, <a href="javascript:fd.components.zipCheckPopup.openZipCheckPopup()">click here</a>.`;

export const FRESH_DIRECT_DOESNT_DELIVER_ERROR = `We're sorry; FreshDirect does not deliver to this address. To see where we deliver, <a href="javascript:fd.components.zipCheckPopup.openZipCheckPopup()">click here</a>.`;

export const ADDRESS_IS_NOT_SET_UP_ERROR = `We''re sorry! You cannot continue at this time because your address isn''t set up in our system. We''re working to enter it as quickly as possible. Please call us toll free at 1-866-283-7374 and we''ll inform you when the problem is corrected.<br><br>Meanwhile, feel free to continue browsing our site. We''ll store everything in your cart until you''re able to check out.`;

export const VALIDATION_ERRORS: { [key: string]: string } = {
  [ATP_ERROR]: 'notRecognizedAtpUnit',
  [UNABLE_TO_RECOGNIZE]: 'notRecognizedAtpUnit',
  [APT_SUIT_REQUIRED]: 'requiredApt',
  [ATP_FORMAT_ERROR]: 'validateName',
  [ATP_ABSENCE_WARNING]: 'required',
  [DONT_RECOGNIZED_ERROR]: 'notRecognized',
  [OUT_OF_DELIVERY_ZONE_ERROR]: 'outOfDeliveryArea',
  [ADDRESS_MUST_BE_IN_DELIVERY_ZONE_ERROR]: 'outOfDeliveryArea',
};

export const SUBMIT_ERRORS: { [key: string]: string } = {
  [COMMERCIAL_ADDRESS_ERROR]: 'commercialAddress',
  [RESIDENTIAL_ADDRESS_ERROR]: 'residentialAddress',
};

export const ERRORS: { [key: string]: string } = { ...VALIDATION_ERRORS, ...SUBMIT_ERRORS };

export const formatError = (
  validateDeliveryAddress: ValidateDeliveryAddressQuery['validateDeliveryAddress'],
) => {
  const actionResult = validateDeliveryAddress?.actionResult?.errors;
  const validationErrors = validateDeliveryAddress?.validationErrors;
  const actionError = actionResult?.length ? actionResult[0]?.description : null;
  const validationError = validationErrors?.length ? validationErrors[0]?.name : null;
  const isErrorAbsentAtpUnit =
    actionError === ATP_ABSENCE_WARNING || validationError === ATP_ABSENCE_WARNING;
  const isErrorAtpUnit =
    actionError === ATP_ERROR ||
    validationError === ATP_ERROR ||
    actionError === ATP_FORMAT_ERROR ||
    isErrorAbsentAtpUnit;

  return { isErrorAbsentAtpUnit, isErrorAtpUnit, actionError, validationError };
};

export const getServiceTypeMessageKey = (
  availableServiceTypes: SERVICE_TYPE[] | null,
  addressServiceType: SERVICE_TYPE,
  cosFeatureEnabled = false,
) => {
  const isHome = availableServiceTypes?.some((serviceType) => serviceType === SERVICE_TYPE.HOME);
  const isCorporate = availableServiceTypes?.some(
    (serviceType) => serviceType === SERVICE_TYPE.CORPORATE,
  );
  let message = null;

  switch (addressServiceType) {
    case SERVICE_TYPE.HOME: {
      if (isCorporate) {
        message = 'corporateAddress';
      }
      break;
    }
    case SERVICE_TYPE.CORPORATE: {
      if (isHome) {
        message = 'residentialAddress';
      }
      break;
    }
  }

  if (isHome && isCorporate) {
    // TUE - 15318 would be reverted after B2B rollout
    //message = 'dualAddress';
    message = cosFeatureEnabled ? 'dualAddress' : '';
  }

  return message;
};
