import {
  useState,
  useEffect,
  MouseEvent,
  KeyboardEventHandler,
  useRef,
  SyntheticEvent,
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import cx from 'classnames';
import { MenuProps } from '@mui/material';
import { arrowDown as ArrowDown } from '@assets/icons/system';
import { KEYBOARD_CODE } from '@constants/keyboard';
import { useLocalization } from '@hooks/useLocalization';
import styles from './DropDown.module.scss';

export type DropDownSize = 'mini' | 'medium' | 'standard' | 'large';

export interface OptionType {
  label: string;
  value: string;
  variationItemProductId?: string;
  isNew?: boolean;
}

export interface ExtendedOptionType extends OptionType {
  [key: string]: unknown;
}

export interface DropDownProps {
  title?: string | null;
  value: string;
  children?: never;
  onChange?: (newValue: string) => void;
  disabled?: boolean;
  size?: DropDownSize;
  error?: string;
  options: OptionType[] | ExtendedOptionType[];
  className?: string;
  placeholder?: string | null;
  selectClassName?: string;
  customOption?: (props: { option: OptionType; onOptionClick: () => void }) => JSX.Element;
  onClick?: (e: MouseEvent) => void;
  open?: boolean;
  menuStyleOverride?: { [key: string]: string };
  menuProps?: Partial<MenuProps>;
  menuClassName?: string;
  id?: string;
}

export const DropDown = ({
  value,
  size = 'standard',
  onChange,
  title,
  disabled,
  options,
  error,
  placeholder,
  className,
  selectClassName,
  customOption: CustomOption,
  open,
  onClick,
  menuStyleOverride,
  menuProps,
  menuClassName,
  id,
  ...rest
}: DropDownProps) => {
  const { t } = useLocalization('product');
  const [isKeyboardUser, setIsKeyboardUser] = useState(false);
  const additionalInfoRef = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(!!open);
  const classesSelect = cx(styles.dropdown, { [styles.error]: error }, selectClassName);
  const classesFormControl = cx(
    styles.form_control,
    {
      [styles.error]: error,
      [styles.disabled]: disabled,
      [styles.selected]: value.length === 0,
      [styles.withPlaceholder]: !!placeholder,
    },
    className,
  );

  const classesMenu = cx([styles.menu, styles[size]], menuClassName);
  const renderValue = () =>
    value !== '' ? (
      options.find((option) => option.value === value)?.label
    ) : (
      <span>{placeholder}</span>
    );

  useEffect(() => {
    const handler = () => {
      setIsOpen(false);
      setIsKeyboardUser(false);
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  useEffect(() => {
    if (isKeyboardUser && additionalInfoRef.current) {
      setTimeout(() => {
        (additionalInfoRef.current?.parentElement as HTMLElement).focus();
      }, 100);
    }
  }, [isKeyboardUser, additionalInfoRef.current]);

  const handleCustomOptionKeyDownEvent: KeyboardEventHandler = (e) => {
    const { currentTarget, key } = e;
    const optionInfoIcon = currentTarget?.querySelector(`.${styles.item_with_pic} button`);
    const targetIndex = options.map((item) => item.value).indexOf(currentTarget.id);
    const focusedItem = document.activeElement;

    if (!isKeyboardUser) {
      setIsKeyboardUser(true);
    } else {
      if (key === KEYBOARD_CODE.ARROW_UP && targetIndex === 0) {
        setTimeout(() => {
          (document.getElementById(options[0].value) as HTMLElement).focus();
        }, 0);
      }

      if (key === KEYBOARD_CODE.TAB) {
        optionInfoIcon === focusedItem
          ? (currentTarget as HTMLElement).focus()
          : (optionInfoIcon as HTMLElement).focus();
      }

      if (
        (key === KEYBOARD_CODE.ENTER || key === KEYBOARD_CODE.SPACE) &&
        currentTarget.id &&
        focusedItem !== optionInfoIcon
      ) {
        onChange?.(currentTarget.id);
        setIsOpen(false);
        setIsKeyboardUser(false);
      }
    }
  };

  const handleCustomOptionClick = () => {
    setIsOpen(false);
    setIsKeyboardUser(false);
  };

  const handleSelectClose = (event: SyntheticEvent) => {
    const isTabPressed = event && 'key' in event && event.key === KEYBOARD_CODE.TAB;
    if (isTabPressed) {
      return;
    }
    setIsOpen(false);
    setIsKeyboardUser(false);
  };

  const handleDropdownOpen = () => {
    setIsOpen(true);

    setTimeout(() => {
      const firstOption = document.getElementById(options[0].value) as HTMLElement;
      if (!!firstOption) {
        firstOption.focus();
      }
    }, 0);
  };

  //TODO: add optional children props; delete pickupDropdown and replace with this one
  return (
    <FormControl {...rest} className={classesFormControl} fullWidth>
      <div className={cx(styles.dropdown_wrapper, styles[size])}>
        {title && (
          <InputLabel id={`select-label-${id}`} className={styles.dropdown_label}>
            {title}
          </InputLabel>
        )}
        <Select
          className={classesSelect}
          labelId={`select-label-${id}`}
          id={`select-${id}`}
          value={value}
          disabled={disabled}
          label={title}
          MenuProps={{
            ...menuProps,
            className: classesMenu,
            disableScrollLock: true,
            sx: menuStyleOverride,
          }}
          open={isOpen}
          onOpen={handleDropdownOpen}
          onClose={handleSelectClose}
          IconComponent={ArrowDown}
          displayEmpty
          renderValue={renderValue}
          onChange={(event) => {
            onChange?.(event.target.value);
          }}
          inputProps={{
            'aria-describedby': `select-error-${id}`,
          }}
          onClick={onClick}
          SelectDisplayProps={{ role: 'listbox' }}
        >
          {options.map((option, index) => {
            const shouldDisplayAdditionalInfo = index === 0 && isKeyboardUser;

            return CustomOption
              ? [
                  shouldDisplayAdditionalInfo ? (
                    <MenuItem disableRipple tabIndex={-1} className={styles.item_with_pic}>
                      <span className={styles.addtional_info} ref={additionalInfoRef}>
                        {t('bundleOptions.addtionalInfo')}
                      </span>
                    </MenuItem>
                  ) : null,
                  <MenuItem
                    disableRipple
                    key={option.value}
                    value={option.value}
                    className={styles.item_with_pic}
                    onKeyDown={handleCustomOptionKeyDownEvent}
                    id={option.value}
                    aria-labelledby={option.label}
                  >
                    <CustomOption option={option} onOptionClick={handleCustomOptionClick} />
                  </MenuItem>,
                ]
              : [
                  <MenuItem
                    disableRipple
                    key={option.value}
                    value={option.value}
                    className={styles.dropdown_item}
                  >
                    {option.label}
                  </MenuItem>,
                ];
          })}
        </Select>
      </div>

      <FormHelperText
        className={styles.error_text}
        id={`select-error-${id}`}
        error
        aria-live="polite"
      >
        <Typography variant="smallBody"> {error}</Typography>
      </FormHelperText>
    </FormControl>
  );
};
