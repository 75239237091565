import cx from 'classnames';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ACCOUNT_ALERT_TYPES } from '@context/AccountAlertContext/AccountAlertContext';
import { Alert } from '@components/UI';
import { useDirectionScroll } from '@hooks/index';
import { useAlert } from '@hooks/useAlert';
import styles from './AccountAlert.module.scss';

interface AccountAlertProps {
  children?: never;
  className?: string;
}

export const AccountAlert = ({ className }: AccountAlertProps) => {
  const { context } = useAccountAlertContext();
  const isScrollingToBottom = useDirectionScroll();
  const isValidContextData = context.state.type && context.state.message;
  const DELAY_TIME = 4000;

  useAlert({
    delay: DELAY_TIME,
    shouldHide: !!isValidContextData,
    hideCallback: () => {
      context.dispatch({ type: ACCOUNT_ALERT_TYPES.CLEAR_ALERT, payload: {} });
    },
  });

  return isValidContextData ? (
    <div className={cx(styles.wrapper, { [styles.wrapper_scrolled]: isScrollingToBottom })}>
      <Alert className={cx(styles.alert, className)} type={context.state.type}>
        {context.state.message && (
          <div dangerouslySetInnerHTML={{ __html: context.state.message }} />
        )}
      </Alert>
    </div>
  ) : null;
};
