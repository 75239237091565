import { useMutation } from '@apollo/client';
import { DELETE_PAYMENT_METHOD } from '@graphql/account/mutations/deletePaymentMethod';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';

export const useDeletePaymentMethod = () => {
  return useMutation(DELETE_PAYMENT_METHOD, {
    refetchQueries: [PAYMENT_METHODS],
    update(
      cache,
      {
        data: {
          deletePaymentMethod: { data: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
};
