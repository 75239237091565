import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLocalization } from '@hooks/useLocalization';
import { AddBankAccountTabContent } from '@components/PaymentsAddTabs/components/AddBankAccountTabContent/AddBankAccountTabContent';
import { PaypalTabContent } from '@components/PaymentsAddTabs/components/PaypalTabContent/PaypalTabContent';
import { VenmoTabContent } from '@components/PaymentsAddTabs/components/VenmoTabContent/VenmoTabContent';
import { EbtTabContent } from '@components/PaymentsAddTabs/components/EbtTabContent/EbtTabContent';
import { AddCardTabContent } from '@components/PaymentsAddTabs/components/AddCardTabContent/AddCardTabContent';
import { PaymentsType, PAYMENT_TABS } from '@commons/payment';
import { ALERT_TYPES } from '@commons/account';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { DropDown } from '@components/UI';
import { BACKGROUND_MODE } from '@commons/modals';
import styles from './AddPaymentsDesktopModal.module.scss';

interface AddPaymentsDesktopModalProps {
  isOpen: boolean;
  closeModal: () => void;
  type: PAYMENT_TABS;
  setType: Dispatch<SetStateAction<PAYMENT_TABS>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
}

interface Payment {
  label: string;
  value: string;
}

const contentMap = {
  [PAYMENT_TABS.CREDIT_CARD]: AddCardTabContent,
  [PAYMENT_TABS.BANK_ACCOUNT]: AddBankAccountTabContent,
  [PAYMENT_TABS.PAYPAL]: PaypalTabContent,
  [PAYMENT_TABS.VENMO]: VenmoTabContent,
  [PAYMENT_TABS.EBT]: EbtTabContent,
};

export const AddPaymentsDesktopModal = ({
  isOpen,
  closeModal,
  type,
  setType,
  setErrorMessage,
}: AddPaymentsDesktopModalProps) => {
  const { dispatchAlert } = useAccountAlertContext();
  const { t } = useLocalization('account');
  const payments: Payment[] = useMemo(
    () => [
      {
        label: t('payments.modalTabs.creditDebitTab'),
        value: t('payments.modalTabs.creditDebitTab'),
      },
      {
        label: t('payments.modalTabs.bankAccountTab'),
        value: t('payments.modalTabs.bankAccountTab'),
      },
      {
        label: t('payments.modalTabs.paypalTab'),
        value: t('payments.modalTabs.paypalTab'),
      },
      {
        label: t('payments.modalTabs.venmo'),
        value: t('payments.modalTabs.venmo'),
      },
      {
        label: t('payments.modalTabs.ebt'),
        value: t('payments.modalTabs.ebt'),
      },
    ],
    [t],
  );
  const initialPayment = payments[type];
  const [currPayment, setCurrPayment] = useState(initialPayment);

  useEffect(() => {
    setCurrPayment(payments[type]);
  }, [type, payments]);

  const handleSuccess = (paymentType: PaymentsType, message = '') => {
    dispatchAlert(ALERT_TYPES.SUCCESS, message);
    closeModal();
  };

  const handleChange = (newValue: string) => {
    const newCurrPayment = payments.find((payment) => payment.value === newValue) as Payment;
    const newType = payments.indexOf(newCurrPayment);
    setCurrPayment(newCurrPayment);
    setType(newType);
  };

  const Content = contentMap[type];
  return (
    <ModalWindow
      className={styles.modal}
      title={t('payments.addPaymentModalTitle')}
      open={isOpen}
      onClose={closeModal}
      backgroundMode={BACKGROUND_MODE.TRANSPARENT}
    >
      <>
        <DropDown
          value={currPayment.value}
          onChange={handleChange}
          options={payments}
          className={styles.dropdown}
          id="add-payment-desktop"
        />
        <Content onError={setErrorMessage} onSuccess={handleSuccess} />
      </>
    </ModalWindow>
  );
};
