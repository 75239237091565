import { useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Input, InputProps } from '../Input/Input';

type PhoneInputProps = InputProps & {
  mask?: string;
  defaultValue?: string;
  value?: string;
};

export const PhoneInput = ({
  mask = '(###) ###-####',
  defaultValue = '',
  ...rest
}: PhoneInputProps) => {
  const numberFormatRef = useRef(null);
  return (
    <NumberFormat
      {...rest}
      format={mask}
      mask="_"
      customInput={Input}
      defaultValue={defaultValue}
      ref={numberFormatRef}
      type="tel"
    />
  );
};
