import { useMemo } from 'react';
import { AdServer } from '@api';
import { useAdServerContext } from '@context/AdServerContext/AdServerContext';

interface UseAdServerProps {
  // must be immutable
  customAdQueryParams?: AdServer['adQueryParams'];
}

export const useAdServer = ({ customAdQueryParams }: UseAdServerProps = {}):
  | AdServer
  | undefined => {
  const { adServer: adServerData, adServerLoading } = useAdServerContext();

  return useMemo(
    () =>
      adServerData && !adServerLoading
        ? {
            ...adServerData,
            adQueryParams: [...(adServerData.adQueryParams ?? []), ...(customAdQueryParams ?? [])],
          }
        : undefined,
    [adServerData, customAdQueryParams, adServerLoading],
  );
};
