import { emit } from '@modules/emitter';
import { MODAL_OPEN, modalAction, MODALS_LIST } from '@modules/modals/types/core';

export const fireOpenModal = <TData>(message: MODALS_LIST, payload?: TData) =>
  emit(
    modalAction({
      data: {
        modalType: message,
        payload,
      },
      message: MODAL_OPEN,
    }),
  );
