import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { GET_USER_DELIVERY_ADDRESSES_PROTECTED } from '@graphql/account/queries/userDeliveryAddresses';

export const useLazyUserDeliveryAddresses = (options: LazyQueryHookOptions = {}) => {
  const queryData = useLazyQuery(GET_USER_DELIVERY_ADDRESSES_PROTECTED, {
    ...options,
    ssr: false,
  });

  return queryData;
};
