import { gql } from '@apollo/client';
import { PAYMENT_FRAGMENT } from '@graphql/fragments/account/payment';

export const PAYMENT_METHODS = gql`
  query PaymentMethods($standingOrderMode: StandingOrderMode) {
    paymentMethods(standingOrderMode: $standingOrderMode) {
      payments {
        ...paymentFragment
      }
      default
      selected
    }
  }
  ${PAYMENT_FRAGMENT}
`;
