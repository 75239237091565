import { useReactiveVar } from '@apollo/client';
import { cartDisabledVar } from '@graphql/variables/cartDisabledVar';

export const useDisablePage = () => {
  const disabled = useReactiveVar(cartDisabledVar);

  return {
    disabled,
    enable: () => cartDisabledVar(false),
    disable: () => cartDisabledVar(true),
  };
};
