import { gql } from '@apollo/client';
import { CREDIT_ORDER_FRAGMENT } from '@graphql/fragments/account/creditOrder';

export const CREDITS_ORDER_HISTORY = gql`
  query CreditOrderHistory {
    creditsOrderHistory {
      orders {
        ...creditOrderFragment
      }
      hasEligibleOrders
    }
  }
  ${CREDIT_ORDER_FRAGMENT}
`;
