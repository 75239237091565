import Trans from 'next-translate/Trans';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import styles from '../../DeliveryAlert.module.scss';

interface GeolocationNotInDeliverableZoneProps {
  zipCode: string;
  onClick?: () => void;
}

export const GeolocationNotInDeliverableZone = ({
  zipCode,
  onClick,
}: GeolocationNotInDeliverableZoneProps) => {
  const { t } = useLocalization('common');

  return (
    <div className={styles.alert_text_group}>
      <Trans
        i18nKey="common:alerts.outOfDeliveryZone"
        components={{
          bold: <b />,
        }}
        values={{ zipCode: zipCode }}
      />
      {}
      <Button variant="underline" className={styles.link} onClick={onClick}>
        {t('alerts.changeAddressText')}
      </Button>
    </div>
  );
};
