import { gql } from '@apollo/client';

export const GET_PENDING_ORDERS = gql`
  query GetPendingOrders {
    pendingOrdersInfo {
      pendingOrders {
        requestedDate
        orderId
        orderTotal
        orderStatus
        orderItemsCounter
        orderState {
          isTrackable
          isModifiable
          isCancelable
          isPending
          isDelivered
        }
        reorder
        deliveryStart
        deliveryEnd
        isActive
      }
      showPendingOrders
    }
  }
`;

export const GET_PENDING_ORDERS_CACHE_NAME = 'pendingOrdersInfo';
