import { gql } from '@apollo/client';

export const DELIVERY_CONTACT_FRAGMENT = gql`
  fragment deliveryContactFragment on DeliveryContactInfo {
    firstName
    lastName
    companyName
    phoneNumber {
      phone
      extension
      type
    }
    instructions
  }
`;
