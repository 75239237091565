import {
  GaPageView,
  IGaPageView,
  PGaPageView,
} from '@modules/ga/events/custom/custom-page-view/ga-page-view';

/* eslint-disable @typescript-eslint/naming-convention*/
export interface PGaPageViewTimeSlots extends PGaPageView {
  available_timeslot_value?: string | null;
  unavailable_timeslot_present?: string | null;
}

export interface IGaPageViewTimeSlots extends IGaPageView {
  available_timeslot_value: string;
  unavailable_timeslot_present: string;
}

export function GaPageViewTimeSlots(p: PGaPageViewTimeSlots): IGaPageViewTimeSlots {
  const pageLoad = GaPageView(p);
  const available_timeslot_value = p.available_timeslot_value ?? '';
  const unavailable_timeslot_present = p.unavailable_timeslot_present ?? '';

  return {
    ...pageLoad,
    available_timeslot_value,
    unavailable_timeslot_present,
  };
}
