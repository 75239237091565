export const getSectionTitle = (
  hasCouponError: boolean,
  unavailable: boolean,
  outOfStock: boolean,
  isUnavailableOnPage: boolean,
  defaultTitle: string,
): string => {
  if (hasCouponError) return '';
  if (unavailable) return 'unavailableText';
  if (outOfStock) return 'outOfStockText';
  if (isUnavailableOnPage) return 'availableText';

  return defaultTitle || '';
};
