import cx from 'classnames';
import { useRouter } from 'next/router';
import { MainLayoutProps } from '@layouts/types';
import { routing } from '@constants/routing';
import { useAPC } from '@hooks/promo/useAPC';
import { ExpressNotificationAlert } from '@components/ExpressNotificationAlert/ExpressNotificationAlert';
import { MainLayoutProvider } from '@context/MainLayoutContext';
import { GlobalAlertContainer } from '@components/GlobalAlertContainer/GlobalAlertContainer';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { CartForCheckoutContextProvider } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import styles from './MainLayout.module.scss';

export const MainLayout = ({
  children,
  isStickyHeader,
  wrapperClass,
  isContainer = true,
  hideFooter,
}: MainLayoutProps) => {
  const classes = cx(styles.wrapper, wrapperClass, {
    [styles.container]: isContainer,
  });
  const { isKnownUser: isLoggedIn } = useAuthContext();

  const router = useRouter();
  const showFooter = !hideFooter && router.pathname !== routing.serverError;
  const showHeaderAndAlerts = router.pathname !== routing.serverError;

  useAPC();

  return (
    <CartForCheckoutContextProvider>
      <MainLayoutProvider>
        {showHeaderAndAlerts && (
          <>
            {isLoggedIn && <GlobalAlertContainer showAlerts />}
            <Header isStickyHeader={isStickyHeader} />
          </>
        )}
        <div className={classes} id="main-layout" tabIndex={-1} role="main">
          {children}
        </div>
        <ExpressNotificationAlert />
        {showFooter && <Footer />}
      </MainLayoutProvider>
    </CartForCheckoutContextProvider>
  );
};
