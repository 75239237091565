import { gql } from '@apollo/client';

export const UNLINK_SOCIAL_ACCOUNT = gql`
  mutation UnlinkSocialAccount($email: String!, $socialNetworkProvider: String!) {
    unlinkSocialAccount(email: $email, socialNetworkProvider: $socialNetworkProvider) {
      firstName
      lastName
      phoneNumber
      emailAddress
      linkedSocialProviders
      notifications {
        offers
        orderNotices
        sendNewsLetter
      }
    }
  }
`;
