import { emit } from '@modules/emitter';
import { MODAL_CLOSE, modalAction, MODALS_LIST } from '@modules/modals/types/core';

export const fireCloseModal = (message: MODALS_LIST) =>
  emit(
    modalAction({
      data: {
        modalType: message,
      },
      message: MODAL_CLOSE,
    }),
  );
