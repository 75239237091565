import React, { FC } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import {
  appStore as AppStoreIcon,
  googlePlay as GooglePlayIcon,
  alexa as AlexaIcon,
} from '@assets/icons/stores';
import {
  facebook as FaceBookIcon,
  instagram as InstagramIcon,
  twitter as TwitterIcon,
  youtube as YoutubeIcon,
} from '@assets/icons/socials/simple';
import { InnerItemType } from '@layouts/Footer/Footer';
import { useLocalization } from '@hooks/useLocalization';
import styles from '../../../../Footer.module.scss';

interface SocialIconProps {
  isMobileAppLink: boolean;
  innerItem: InnerItemType;
  titleId?: string;
  innerItemId?: string;
}

enum STORE {
  APPLE = 'apps.apple.com',
  GOOGLE = 'play.google.com',
  ALEXA = 'partner.blu.ai/quick-links?skillid=amzn1.ask.skill',
}

const appIcons: { [key: string]: FC<{ className?: string }> } = {
  [STORE.APPLE]: AppStoreIcon,
  [STORE.GOOGLE]: GooglePlayIcon,
  [STORE.ALEXA]: () => <Image height={57} width={120} src={AlexaIcon.src} alt="Alexa" />,
};

const socialIcons: { [key: string]: FC<{ className?: string }> } = {
  Facebook: FaceBookIcon,
  Instagram: InstagramIcon,
  Twitter: TwitterIcon,
  YouTube: YoutubeIcon,
};

export const ItemIcon = ({ innerItem, isMobileAppLink, titleId, innerItemId }: SocialIconProps) => {
  const { name, link, icon } = innerItem;
  const { t } = useLocalization();
  let SocialIcon: FC<{ className?: string }> | null = null;

  if (isMobileAppLink) {
    const iconKey = Object.keys(appIcons).filter((key) => link.includes(key))[0];
    SocialIcon = appIcons[iconKey];
  } else if (!!icon) {
    SocialIcon = socialIcons[name];
  }

  let socialIconName;
  if (name) {
    socialIconName = name;
  } else if (link?.includes(STORE.APPLE)) {
    socialIconName = t('common:footerMobileApps.apple');
  } else if (link?.includes(STORE.GOOGLE)) {
    socialIconName = t('common:footerMobileApps.google');
  }

  if (!SocialIcon) return null;

  if (isMobileAppLink) {
    return (
      <a
        href={link ?? '#'}
        target={'_blank'}
        className={cx(styles.subtitle, styles.apk_link)}
        rel="noreferrer"
        aria-label={socialIconName}
        aria-labelledby={`${titleId} ${innerItemId}`}
        id={innerItemId}
      >
        <SocialIcon
          className={cx(styles.social_icon, {
            [styles.store_icon]: isMobileAppLink,
          })}
        />
      </a>
    );
  }
  return (
    <a href={link ?? '#'} aria-label={socialIconName} tabIndex={-1}>
      <SocialIcon
        className={cx(styles.social_icon, {
          [styles.store_icon]: isMobileAppLink,
        })}
      />
    </a>
  );
};
