import { Skeleton } from '@mui/material';
import React from 'react';
import { AdBlock } from '@components/AdBlock/AdBlock';
import { AD_BLOCK_DIMENSIONS } from '@commons/dfp';
import { PreviewBlockSkeleton } from '@features/ProductPage/components/PreviewBlock/PreviewBlockSkeleton';
import { ProductPageImageBlockSkeleton } from '@features/ProductPage/components/ProductPageImageBlock/ProductPageImageBlockSkeleton';
import { AdditionalInfoSkeleton } from '@features/ProductPage/components/AdditionalInfo/AdditionalInfoSkeleton';
import styles from '../../ProductPage.module.scss';

export const ProductPageMainComponentSkeleton = () => {
  return (
    <div>
      <AdBlock
        adBlockId="DFPSystemMessage"
        adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
      />
      <div className={styles.layout}>
        <div className={styles.layout_topbar}>
          <Skeleton data-testid="landing header skeleton" variant="text" height={60} width="50%" />
        </div>

        <div className={styles.layout_summary}>
          <PreviewBlockSkeleton />
        </div>

        <div className={styles.layout_image}>
          <ProductPageImageBlockSkeleton />
        </div>

        <div className={styles.layout_actions}>
          <Skeleton className={styles.skeleton_text} variant="rectangular" />
          <Skeleton className={styles.skeleton_button} variant="rounded" />
        </div>

        <div className={styles.layout_info}>
          <AdditionalInfoSkeleton />
        </div>
      </div>
    </div>
  );
};
