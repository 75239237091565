import { useEffect, useRef } from 'react';
import { useReferAFriendInvites } from '@hooks/referAFriend/useReferAFriendInvites';
import { useReferAFriend } from '@hooks/referAFriend/useReferAFriend';
import { useReferralPromotionData } from '@hooks/referAFriend/useReferralPromotionData';
import { CircularLoader } from '@components/CircularLoader/CircularLoader';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { ReferAFriendHeader } from '@components/ReferAFriendHeader/ReferAFriendHeader';
import { ReferAFriendFooter } from '@components/ReferAFriendFooter/ReferAFriendFooter';
import { getDate } from '@utils/getDate';
import styles from './AccountPageReferAFriend.module.scss';

type Invite = {
  email: string;
  date: string;
  status: string;
  credit: number | null;
};

export const AccountPageReferAFriend = () => {
  const initialLoading = useRef(true);
  const {
    data: referAFriendInvites,
    loading: loadingReferAFreind,
    refetch: fetchReferAFreindInvites,
  } = useReferAFriendInvites();
  const { data: rafPromoData, refetch: fetchReferralPromotionData } = useReferralPromotionData();
  const { data: referAFriend, refetch: fetchReferAFriend } = useReferAFriend();

  useEffect(() => {
    fetchReferAFreindInvites();
    fetchReferAFriend();
    fetchReferralPromotionData();
  }, [fetchReferAFreindInvites, fetchReferAFriend, fetchReferralPromotionData]);

  useEffect(() => {
    if (referAFriendInvites) {
      initialLoading.current = false;
    }
  }, [referAFriendInvites]);

  if (loadingReferAFreind && initialLoading.current) {
    return <AccountPageLoader />;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <ReferAFriendHeader
            referAFriendData={referAFriend}
            rafPromoData={rafPromoData?.referralPromotionData}
            inviteSuccessCallback={() => fetchReferAFreindInvites()}
          />
          {loadingReferAFreind && (
            <div className={styles.loader_wrapper}>
              <CircularLoader />
            </div>
          )}
          {referAFriendInvites?.referAFriendInvites?.allInvites?.length > 0 && (
            <div className={styles.invites}>
              <ul>
                {referAFriendInvites.referAFriendInvites.allInvites?.map(
                  (invite: Invite, index: number) => (
                    <li key={'invite_' + index} className={styles.invite}>
                      <p className={styles.email}>
                        {invite.email} {invite.credit && '$' + invite.credit}
                      </p>
                      <p className={styles.date}>{getDate(invite.date, 'dddd, MMM D')}</p>
                      <p>{invite.status}</p>
                    </li>
                  ),
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className={styles.footer_wrapper}>
        <ReferAFriendFooter rafPromoData={rafPromoData?.referralPromotionData} />
      </div>
    </>
  );
};
