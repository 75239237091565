import { IconButton as MuiIconButton, IconButtonProps as MUIIconButtonProps } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import cx from 'classnames';
import styles from './IconButton.module.scss';

export interface IconButtonProps extends MUIIconButtonProps {
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'outlined' | 'text';
  ariaExpanded?: boolean;
  ariaLabel?: string;
}

export const IconButton = forwardRef(
  (
    {
      size = 'small',
      variant = 'default',
      ariaExpanded,
      ariaLabel,
      className,
      children,
      ...restProps
    }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement | null>,
  ) => {
    const classes = cx([styles.iconButton, styles[size], styles[variant], className]);
    const additionalProps = {
      ...(ariaExpanded !== undefined && { 'aria-expanded': ariaExpanded }),
      ...(ariaLabel && { 'aria-label': ariaLabel }),
    };

    return (
      <MuiIconButton
        ref={ref}
        {...restProps}
        {...additionalProps}
        disableRipple
        className={classes}
      >
        {children}
      </MuiIconButton>
    );
  },
);

IconButton.displayName = 'IconButton';
