import { CartLine } from '@commons/cart';
import { getFormattedProductPrice } from '@modules/ga/utils/getFormattedProductPrice';
import { Price } from '@commons/price';
import { getDiscount } from '@modules/ga/eventBodyGetters/utils/getDiscount';

export const getProductsWithProperQuantity = (cartLines: CartLine[]) => {
  return cartLines.map((cartLineItem) => {
    return {
      ...cartLineItem.product,
      itemPosition: cartLineItem.itemPosition,
      quantity: {
        ...cartLineItem.product.quantity,
        quantity: cartLineItem.quantitySelector.quantity,
      },
      itemListName: cartLineItem.itemListName,
      discountAmount: getDiscount(cartLineItem),
      price: <Price>getFormattedProductPrice({
        price: cartLineItem.product.price,
        quantity: cartLineItem.quantitySelector.quantity,
        displayPrice: cartLineItem.displayPrice.value,
      }),
    };
  });
};
