import { Typography } from '@mui/material';
import { Link } from '@components/UI';
import { phone as PhoneIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { useCustomerServicePhoneNumber } from '@hooks/common/useCustomerServicePhoneNumber';
import styles from '../ContactUsActions.module.scss';

export const Phones = (): JSX.Element => {
  const { t } = useLocalization('common');
  const { getCustomerServicePhoneNumber } = useCustomerServicePhoneNumber();
  const customerServicePhoneNumber = getCustomerServicePhoneNumber(
    t('contactUs.phoneBlock.firstLinkText'),
  );

  return (
    <div className={styles.button_group_content}>
      <PhoneIcon className={styles.icon} />
      <Typography className={styles.content_heading}>
        {t('contactUs.phoneBlock.linkTitle')}
        <span>
          <Link href={`tel:${customerServicePhoneNumber}`} classNameLinkText={styles.link_text}>
            <span className={styles.link}>{customerServicePhoneNumber}</span>
          </Link>
          <span className={styles.first_link_help_text}>
            {' '}
            {t('contactUs.phoneBlock.firstLinkHelpText')}
          </span>
          <br />
          <span className={styles.secondary_link}>
            <Link
              href={`tel:${t('contactUs.phoneBlock.secondLinkText')}`}
              classNameLinkText={styles.link_text}
            >
              <span className={styles.link}>{t('contactUs.phoneBlock.secondLinkText')}</span>
            </Link>
          </span>
          <span> {t('contactUs.phoneBlock.secondLinkHelpText')}</span>
        </span>
      </Typography>
    </div>
  );
};
