import { DocumentNode, NextLink, Operation } from '@apollo/client';
import { setCookie } from 'cookies-next';
import { ERROR_SPECIFICATIONS } from '@constants/errorCodes';
import { ExtendedNetworkError } from '@commons/graphql';
import { emit } from '@modules/emitter';
import { Fail } from '@modules/fail';
import { GRAPHQL_ERRORS } from '@commons/graphqlErrors';
import { COOKIES_LIST } from '@commons/cookies';
import { EXPRESS_FILTER_HEADER } from '@constants/requestHeaders';

export interface NetworkErrorResolverArgs {
  networkError: ExtendedNetworkError;
  operation: Operation;
  forward: NextLink;
}

export const handleInconsistentExpressState = (operation: Operation, forward: NextLink) => {
  const oldHeaders = operation.getContext().headers;
  const prevExpressState = oldHeaders[EXPRESS_FILTER_HEADER];
  setCookie(COOKIES_LIST.IS_EXPRESS, !prevExpressState);
  operation.setContext({
    headers: {
      ...oldHeaders,
      [EXPRESS_FILTER_HEADER]: !prevExpressState,
    },
  });
  return forward(operation);
};

export const resolveNetworkError = ({
  networkError,
  operation,
  forward,
}: NetworkErrorResolverArgs) => {
  const errorResult = networkError.result?.[0]?.errors;
  if (errorResult) {
    for (const err of errorResult) {
      const errorClassification = err?.extensions?.classification;

      switch (errorClassification) {
        case ERROR_SPECIFICATIONS.INCONSISTENT_EXPRESS_STATE:
          return handleInconsistentExpressState(operation, forward);
      }
    }
  }

  emit(
    Fail({
      message:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        networkError?.statusCode === 404
          ? GRAPHQL_ERRORS.SERVER_UNAVAILABLE
          : networkError?.message,
    }),
  );

  return forward(operation);
};

export const getGQLOperationNameFromDocumentNode = (operationQuery: DocumentNode) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return operationQuery.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value;
};
