import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export interface CategoryDataLoadOptions {
  breadcrumb: string;
}

export const categoryDataLoadHandler = ({ breadcrumb }: CategoryDataLoadOptions) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'category_data_load',
      ua_category: 'Category',
      ua_action: 'Breadcrumb',
      ua_label: breadcrumb,
      breadcrumb: breadcrumb,
    }),
  );
};
