import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getCheckoutEvent } from '@modules/ga/eventBodyGetters/getCheckoutEvent';
import { getProductMetrics } from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getProductsWithProperQuantity } from '@modules/ga/eventBodyGetters/utils/getProductsWithProperQuantity';
import { AddPaymentInfoOptions } from '@modules/ga/events/ecommerce/add-payment-info/addPaymentInfoCreator';

export interface AddPaymentInfoOptionsWithAnalytics extends AddPaymentInfoOptions {
  commonAnalyticsData: AnalyticsData;
}

export const addPaymentInfoHandler = ({
  cartLinesList,
  commonAnalyticsData,
}: AddPaymentInfoOptionsWithAnalytics) => {
  const products = getProductsWithProperQuantity(cartLinesList);
  const { userDetails } = commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getCheckoutEvent({
      products:
        products.map((product) =>
          getProductMetrics({
            ...product,
            position: product.itemPosition,
            listName: product.itemListName,
          }),
        ) ?? [],
      currencyCode: getCurrencyCodeFromSymbol(products?.[0]?.price?.currency),
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
      event: 'checkoutStep-3',
      eventName: 'add_payment_info',
      uaLabel: 'Step 3',
      step: 3,
      option: 'add_payment_info',
    }),
  );
};
