import { CREDIT_STATUSES } from '@commons/credits';
import { getMaskedCardNumber } from '@utils/getMaskedCardNumber';

export const getStatusStringTemplate = (status: string, creditNumber?: string) => {
  if (creditNumber && status === CREDIT_STATUSES.REFUND) {
    return `${status} to ${getMaskedCardNumber(creditNumber)}, `;
  }

  return status && `${status}, `;
};
