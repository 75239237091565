import { useLazyQuery } from '@apollo/client';
import { DELIVERY_PASS_INFO } from '@graphql/deliveryPass/queries/deliveryPassInfo';
import { getDeliveryPassInfo } from '@adapters/deliveryPassAdapter';

export const useLazyDeliveryPassInfo = () => {
  const [fetchDeliveryPassInfo, { data, ...queryResult }] = useLazyQuery(DELIVERY_PASS_INFO, {
    fetchPolicy: 'network-only',
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...queryResult,
    data: getDeliveryPassInfo(data?.deliveryPassInfo),
    fetchDeliveryPassInfo,
  };
};
