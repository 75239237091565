export enum TABS {
  DESCRIPTION = 'Description',
  NUTRITION = 'Nutrition',
  INGREDIENTS = 'Ingredients',
  INSTRUCTIONS = 'Instructions',
  EMPTY = '',
  ALCOHOL = 'Alcohol info',
}

export const TAB_HREFS = {
  [TABS.DESCRIPTION]: '#description',
  [TABS.NUTRITION]: '#nutrition',
  [TABS.INGREDIENTS]: '#ingredients',
  [TABS.INSTRUCTIONS]: '#instructions',
  [TABS.ALCOHOL]: '#alcohol',
};
