import { Divider } from '@mui/material';
import { getAddressText } from '@utils/getAddressText';
import { getWeekdayString } from '@utils/getWeekdayString';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { TimeslotData } from '@commons/timeslots';
import { useSelectedAddress } from '@hooks/deliveryAddress/useSelectedAddress';
import styles from './TimeSlotsSelected.module.scss';

interface TimeSlotsSelectedProps {
  selectedTimeslot: TimeslotData;
  onEditClick: () => void;
}

export const TimeSlotsSelected = ({ selectedTimeslot, onEditClick }: TimeSlotsSelectedProps) => {
  const { t } = useLocalization('checkout');
  const { data: selectedAddress } = useSelectedAddress();
  const selectedAddressText = getAddressText(selectedAddress.address);
  const weekday = getWeekdayString(selectedTimeslot.startDate);

  return (
    <div className={styles.container}>
      <div className={styles.selected_slot}>
        <div>
          <p>
            {weekday}, {selectedTimeslot.timePeriod}
          </p>
          <p>{selectedAddressText}</p>
        </div>
        <Button onClick={onEditClick} variant="underline" aria-label={t('editButtonLabel')}>
          {t('editButton')}
        </Button>
      </div>
      <Divider className={styles.divider} />
    </div>
  );
};
