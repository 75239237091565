import { useEffect, useState } from 'react';
import cx from 'classnames';
import { isDevMode } from '@helpers/dev.helpers';
import { useLocalStorage, STORAGE_KEYS } from '@utils/storageService';
import { close as CloseIcon } from '@assets/icons/system';
import { BuildInfo, Features, RunTimeConfig, Contexts, Devnotes } from './components';
import styles from './DevTools.module.scss';

// configuration
const CONFIG_OPEN = 'open';
const CONFIG_POSITION = 'position';
const POSITIONS = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

const CONTENT = [
  {
    title: 'Build info',
    component: BuildInfo,
  },
  {
    title: 'Devnotes',
    component: Devnotes,
  },
  {
    title: 'Features',
    component: Features,
  },
  {
    title: 'Runtime config',
    component: RunTimeConfig,
  },
  {
    title: 'Contexts',
    component: Contexts,
  },
];

export const DevTools = () => {
  const [enabled, setEnabled] = useState(false);
  const [config, setConfig] = useLocalStorage(STORAGE_KEYS.DEV_WIDGET, {
    [CONFIG_POSITION]: POSITIONS[0],
    [CONFIG_OPEN]: false,
  });
  const position = config[CONFIG_POSITION] || POSITIONS[0];

  const togglePopover = (force?: boolean) => {
    const state = document.getElementById('fddevtools')?.togglePopover(force);
    if (state !== undefined) {
      setConfig({ ...config, [CONFIG_OPEN]: state });
    }
  };

  const setPopupPosition = (pos: string) => {
    setConfig({ ...config, [CONFIG_POSITION]: pos });
  };

  useEffect(() => {
    isDevMode({ cb: () => setEnabled(true) });
  }, [setEnabled]);

  useEffect(() => {
    if (enabled) {
      if (config[CONFIG_OPEN]) {
        togglePopover(true);
      }
      document?.body.classList.add('developer');
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [enabled, config[CONFIG_OPEN]]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.altKey && event.key === 'd') {
        togglePopover();
      }
    };

    window.addEventListener('keydown', handleKeyDown, false);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  const hostname = window.location.hostname.replace('.freshdirect.com', '');

  return (
    <>
      {/* @ts-expect-error popover support is lacking */}
      <div id="fddevtools" popover="manual" className={cx(styles.popover, styles[position])}>
        <div className={styles.toolbar}>
          <h2>{hostname}</h2>
          <button onClick={() => togglePopover(false)}>
            <CloseIcon width={16} height={16} />
          </button>
        </div>
        <button
          className={cx(styles.posbutton, styles.posbutton_tl)}
          onClick={() => setPopupPosition('top-left')}
        >
          <span>TL</span>
        </button>
        <button
          className={cx(styles.posbutton, styles.posbutton_tr)}
          onClick={() => setPopupPosition('top-right')}
        >
          <span>TR</span>
        </button>
        <button
          className={cx(styles.posbutton, styles.posbutton_bl)}
          onClick={() => setPopupPosition('bottom-left')}
        >
          <span>BL</span>
        </button>
        <button
          className={cx(styles.posbutton, styles.posbutton_br)}
          onClick={() => setPopupPosition('bottom-right')}
        >
          <span>BR</span>
        </button>

        <div className={styles.content}>
          {CONTENT.map(({ title, component: Component }) => {
            return <Component key={title} />;
          })}
        </div>
      </div>
      <button className={styles.toggle} onClick={() => togglePopover()}>
        🛠
      </button>
    </>
  );
};
