import { useState } from 'react';
import { RadioButton } from '@components/UI';
import { getDate } from '@utils/getDate';
import { getDateInterval } from '@helpers/getDateInterval';
import styles from './OrderList.module.scss';
interface Order {
  orderId: string;
  requestedDate: string;
  deliveryStart: string;
  deliveryEnd: string;
}

interface OrderListProps {
  orders: Order[];
  onChange: (orderId: string) => void;
}

export const OrderList = ({ orders, onChange }: OrderListProps) => {
  const [selectedOrderId, setSelectedOrderId] = useState(orders[0]?.orderId ?? '');

  const handleOnChange = (orderId = '') => {
    setSelectedOrderId(orderId);
    onChange(orderId);
  };

  return (
    <>
      {orders.map((order: Order) => {
        const timeRange = getDateInterval(order.deliveryStart, order.deliveryEnd);
        return (
          <div key={order.orderId} className={styles.order_wrapper}>
            <RadioButton
              onChange={handleOnChange}
              id={order.orderId}
              value={order.orderId}
              isChecked={selectedOrderId === order.orderId}
            >
              <div className={styles.order_wrapper_info}>
                <span>{`${getDate(order.requestedDate, 'dddd, MMMM D')}, ${timeRange}`}</span>
                <span className={styles.order_wrapper_order_id}>#{order.orderId}</span>
              </div>
            </RadioButton>
          </div>
        );
      })}
    </>
  );
};
