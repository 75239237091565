import { gql } from '@apollo/client';
import { LIGHT_CART_MUTATION_FRAGMENT } from '@graphql/fragments/cart/common/lightCartMutationFragment';

export const DELETE_CART_LINE_LIGHT = gql`
  mutation DeleteCartLineLight($cartLineId: ID!) {
    deleteCartLineLight(cartLineId: $cartLineId) {
      ...lightCartMutationFragment
    }
  }
  ${LIGHT_CART_MUTATION_FRAGMENT}
`;
