import { CurrentDeliveryAddress } from '@commons/deliveryAddresses';
import { addressAdapter } from '@adapters/addressAdapter';
import { useUserDeliveryAddresses } from '@hooks/deliveryAddress/useUserDeliveryAddresses';
import { useGeolocation } from './useGeolocation';

export const useCurrentDeliveryAddress = (): CurrentDeliveryAddress => {
  const currentAddress = {} as CurrentDeliveryAddress;
  const { getAddress } = addressAdapter();
  const { data, loading: addressLoading } = useUserDeliveryAddresses();
  const { data: geolocationAddress, loading: geolocationAddressLoading } = useGeolocation();

  const selectedAddress = data?.userDeliveryAddresses?.selectedAddress;
  const temporaryAddress = data?.userDeliveryAddresses?.temporaryAddress;

  const shouldUseTemporaryAddress = !addressLoading && temporaryAddress?.selected;
  const shouldUseGeoAddress = !geolocationAddressLoading && geolocationAddress?.address?.address1;
  const shouldUseSelectedAddress = !addressLoading && selectedAddress?.selected;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCurrentAddress = (addressInfo: any, isGeoAddress?: boolean) => {
    const { expressEnabled, address, isTemporary } = addressInfo;
    currentAddress.expressEligibleAddress = !!expressEnabled;
    currentAddress.address = getAddress(address);
    currentAddress.isTemporary = isGeoAddress ?? isTemporary;
  };

  if (shouldUseSelectedAddress) {
    setCurrentAddress(selectedAddress);
  } else if (shouldUseTemporaryAddress) {
    setCurrentAddress(temporaryAddress);
  } else if (shouldUseGeoAddress) {
    setCurrentAddress(geolocationAddress, true);
  } else {
    currentAddress.expressEligibleAddress = !!data?.userDeliveryAddresses?.userExpressEnabled;
    currentAddress.address = getAddress();
  }

  currentAddress.addressLoading = addressLoading || geolocationAddressLoading;

  return {
    address: currentAddress.address,
    isTemporary: currentAddress.isTemporary,
    expressEligibleAddress: currentAddress.expressEligibleAddress,
    addressLoading: currentAddress.addressLoading,
    data,
  };
};
