import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Input } from '@components/UI';
import { AddressAutocomplete } from '@components/AddressAutocomplete/AddressAutocomplete';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { useLocalization } from '@hooks/useLocalization';
import { FormValues } from '@components/DeliveryOptionsContent/DeliveryOptionsContent';
import { ADDRESS_SERVICE_TYPE, AddressToSend, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { Grid } from '@components/UI/Grid/Grid';
import { accountAdapter } from '@adapters/accountAdapter';
import { usePrevious } from '@hooks/usePrevious';
import { SMALL_FIELD_LENGTH } from '@utils/validation';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import styles from './addressOptionInput.module.scss';
import { AddressServiceTypeNotification } from './addressServiceTypeNotification';

interface AddressOptionInputProps {
  isAptUnitAvailable: boolean;
  selectedAddress?: Suggestion | null;
  inputAddress: string;
  isAddressSelected: boolean;
  withSubmitButton?: boolean;
  blurOnSelect?: boolean;
  setInputAddress: (value: string) => void;
  setAPIError: (value: string) => void;
  onChange: (value: AddressToSend) => void;
  onChangeMode: () => void;
  onBlurred?: () => void;
  formik: {
    setFieldValue: (field: string, value: Suggestion | string | null) => void;
    values: FormValues;
    touched: FormikTouched<FormValues>;
    errors: FormikErrors<FormValues>;
    setFieldTouched: (field: string, isTouched?: boolean) => void;
    setFieldError: (field: string, message?: string) => void;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    validateField: (field: string) => void;
  };
  showServiceTypeInfo?: ADDRESS_SERVICE_TYPE;
  id?: string;
  serviceType?: SERVICE_TYPE;
  shouldValidateAddress?: boolean;
  setShouldValidateAddress?: (isValidated: boolean) => void;
  onInputChange?: () => void;
}

export const AddressOptionInput = ({
  isAptUnitAvailable,
  inputAddress,
  formik,
  isAddressSelected,
  blurOnSelect,
  withSubmitButton,
  setInputAddress,
  setAPIError,
  onChangeMode,
  onChange,
  onBlurred,
  id,
  showServiceTypeInfo,
  serviceType,
  shouldValidateAddress,
  setShouldValidateAddress,
  onInputChange,
}: AddressOptionInputProps) => {
  const { values, touched, errors, setFieldTouched, setFieldError, handleChange } = formik;
  const [selectedAddress, setSelectedAddress] = useState<AddressToSend>({
    address1: '',
    apartment: '',
    city: '',
    serviceType: SERVICE_TYPE.NULL,
    zipCode: '',
  });
  const { status } = useFormikContext();
  const { getFeature } = useHeaderContext();

  const { getAddressFromSuggestion } = accountAdapter();
  const { t } = useLocalization();

  const addressPrevTouched = usePrevious(touched.address);

  const aptUnitTextError = (touched.aptUnit && errors.aptUnit) || undefined;
  const addressTextError = (touched.address && errors.address) || null;
  const addressTextWarning = (touched.address && status?.warnings.address) || undefined;
  const shouldShowServiceNotification = !!getFeature?.(FEATURE.COS) && serviceType === undefined;

  useEffect(() => {
    if (addressPrevTouched && status?.warnings.address) {
      setFieldTouched('address');
    }
  }, [status?.warnings.address, addressPrevTouched, setFieldTouched]);

  useEffect(() => {
    if (shouldValidateAddress) {
      onBlurred?.();
      setShouldValidateAddress?.(false);
    }
  }, [shouldValidateAddress]);

  const hasAptError = isAptUnitAvailable && !!errors.aptUnit;

  const onChangeAptUnit = (e: ChangeEvent<HTMLInputElement>) => {
    if (errors.aptUnit) setFieldError('aptUnit');
    handleChange(e);
    onInputChange?.();
  };

  const handleInputChange = () => {
    onInputChange?.();
  };

  return (
    <div className={styles.container}>
      <AddressAutocomplete
        inputAddress={inputAddress}
        error={addressTextError ?? addressTextWarning}
        isAddressSelected={isAddressSelected}
        blurOnSelect={blurOnSelect}
        className={styles.enter_address_input}
        onInputAddress={setInputAddress}
        onSelect={(value: Suggestion | null) => {
          if (value) {
            setSelectedAddress(getAddressFromSuggestion(value));
            onChange(getAddressFromSuggestion(value));
          }
        }}
        onInputChange={handleInputChange}
        onFocusedOut={() => {
          setFieldTouched('address');
        }}
        onChangeMode={onChangeMode}
        onAPIError={setAPIError}
        id={id}
      />

      <Input
        className={styles.enter_address}
        label={t(
          serviceType === SERVICE_TYPE.CORPORATE ? 'delivery.floorRoom' : 'delivery.aptUnit',
        )}
        error={hasAptError}
        helperText={hasAptError ? aptUnitTextError : ''}
        onChange={onChangeAptUnit}
        onBlur={() => {
          setFieldTouched('aptUnit');
          if (!!selectedAddress.address1) {
            onChange?.(selectedAddress);
          }
        }}
        isActive={isAptUnitAvailable}
        value={values.aptUnit}
        name="aptUnit"
        id="aptUnit"
        maxLength={SMALL_FIELD_LENGTH}
        autoComplete="address-line2"
      />

      <Grid container flexDirection="column">
        {shouldShowServiceNotification && (
          <AddressServiceTypeNotification
            showServiceTypeInfo={showServiceTypeInfo}
            onInputChange={handleInputChange}
          />
        )}
        {withSubmitButton && (
          <Grid item>
            <Button type="submit" size="large" variant="outlined" isFullWidth>
              {t('delivery.apply')}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
