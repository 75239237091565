import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  AnonUserDeliveryAddressesQuery,
  AnonUserDeliveryAddressesQueryVariables,
  UserDeliveryAddressesProtectedQuery,
  UserDeliveryAddressesProtectedQueryVariables,
} from '@api';
import { GET_USER_DELIVERY_ADDRESSES_PROTECTED } from '@graphql/account/queries/userDeliveryAddresses';
import { GET_ANON_USER_DELIVERY_ADDRESSES } from '@graphql/account/queries/anonUserDeliveryAddresses';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';

export type UseUserDeliveryAddressOptions = QueryHookOptions<
  UserDeliveryAddressesProtectedQuery | AnonUserDeliveryAddressesQuery,
  UserDeliveryAddressesProtectedQueryVariables | AnonUserDeliveryAddressesQueryVariables
>;

interface UseUserDeliveryAddressParams {
  queryOptions?: UseUserDeliveryAddressOptions;
  forLoggedInUser?: boolean;
}

export const useUserDeliveryAddresses = (params?: UseUserDeliveryAddressParams) => {
  const standingOrderMode = useStandingOrderMode();
  return useQuery<
    UserDeliveryAddressesProtectedQuery | AnonUserDeliveryAddressesQuery,
    UserDeliveryAddressesProtectedQueryVariables | AnonUserDeliveryAddressesQueryVariables
  >(
    params?.forLoggedInUser
      ? GET_USER_DELIVERY_ADDRESSES_PROTECTED
      : GET_ANON_USER_DELIVERY_ADDRESSES,
    {
      ...params?.queryOptions,
      variables: {
        ...params?.queryOptions?.variables,
        standingOrderMode,
      },
      errorPolicy: 'all',
      ssr: false,
    },
  );
};
