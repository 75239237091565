import { createContext, useContext } from 'react';

interface MobilePromoContextInterface {
  opened: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const MobilePromoModalContext = createContext<MobilePromoContextInterface>({
  opened: false,
  onOpen: () => undefined,
  onClose: () => undefined,
});

export const useMobilePromoModalContext = () => useContext(MobilePromoModalContext);
