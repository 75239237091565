import Box from '@mui/material/Box';
import cx from 'classnames';
import { forwardRef, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { Button } from '@components/UI';
import { truncateTextWithDots } from '@utils/truncateTextWithDots';
import { getAddressStringFromGeolocation } from '@utils/getAddressStringFromGeolocation';
import { useGeolocation } from '@hooks/deliveryAddress/useGeolocation';
import { arrowDown as ArrowDown, arrowUp as ArrowUp } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { ButtonProps } from '@components/UI/Button/Button';
import { getAddressText } from '@utils/getAddressText';
import { useCurrentDeliveryAddress } from '@hooks/deliveryAddress/useCurrentDeliveryAddress';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { handleServiceTypeChange } from '@utils/handleServiceTypeChange';
import { useIsServiceTypeUrlParamsPresent } from '@hooks/useIsServiceTypeUrlParamsPresent';
import styles from './Address.module.scss';

export const ADDRESS_TEXT_MAX_LENGTH = 22;

interface AddressProps extends ButtonProps {
  isExpanded?: boolean;
  showIcon?: boolean;
}

const getDeliveryAddressText = (
  addressText: string,
  addressStringFromGeolocation: string | null,
  isScreenReaderOnly?: boolean,
) => {
  if (!addressText) {
    return addressStringFromGeolocation;
  }

  return isScreenReaderOnly
    ? addressText
    : truncateTextWithDots(addressText, ADDRESS_TEXT_MAX_LENGTH) || '';
};

export const AddressButton = forwardRef<HTMLDivElement, AddressProps>(
  ({ isExpanded, showIcon, className, ...rest }, ref) => {
    const { t } = useLocalization('common');
    const { address, addressLoading, isTemporary } = useCurrentDeliveryAddress();
    const { clearCacheFields } = useCacheFieldsInvalidate();
    const addressText = getAddressText(address);
    const {
      data: userGeolocationAddress,
      error: userGeolocationError,
      isDeliverableZone,
    } = useGeolocation();

    const { isServiceTypeParamsPresent } = useIsServiceTypeUrlParamsPresent();

    useEffect(() => {
      handleServiceTypeChange(address.serviceType, clearCacheFields, isServiceTypeParamsPresent);
    }, [address.serviceType, clearCacheFields, isServiceTypeParamsPresent]);

    const addressStringFromGeolocation =
      isDeliverableZone && !userGeolocationError
        ? getAddressStringFromGeolocation(userGeolocationAddress)
        : null;

    const deliveryAddressText = getDeliveryAddressText(addressText, addressStringFromGeolocation);

    const hasGeolocationError = !(
      !!userGeolocationError &&
      userGeolocationAddress.isDeliverable &&
      userGeolocationAddress.isSuccess
    );

    const renderArrow = () => {
      if (!addressText || !showIcon || isTemporary) return null;
      if (isExpanded) return <ArrowUp className={styles.arrow} width={6} height={6} />;
      return <ArrowDown className={styles.arrow} width={6} height={6} />;
    };

    const classes = cx(className, {
      [styles.success_delivery_link]: deliveryAddressText || !hasGeolocationError,
      [styles.link]: hasGeolocationError && !deliveryAddressText,
      [styles.is_in_hamburger]: !showIcon,
    });

    const getDeliveryAddressLabel = () => {
      if (deliveryAddressText) {
        const isScreenReaderOnly = true;

        return (
          <>
            <span className={styles.address_label} aria-hidden="true">
              {deliveryAddressText}
            </span>
            <span className={styles.address_label_screen_reader_only}>
              {getDeliveryAddressText(
                addressText,
                addressStringFromGeolocation,
                isScreenReaderOnly,
              )}
            </span>
          </>
        );
      }
      return t('alerts.enterNewAddress');
    };

    return (
      <Box display="flex" ref={ref} className={styles.container}>
        {addressLoading ? (
          <Box className={styles.loadingBlock}>
            <Skeleton />
          </Box>
        ) : (
          <Button {...rest} variant="underline" className={classes} data-testid="address_label">
            {getDeliveryAddressLabel()} {renderArrow()}
          </Button>
        )}
      </Box>
    );
  },
);

AddressButton.displayName = 'Address';
