import { useQuery } from '@apollo/client';
import { SettingsQuery, SettingsQueryVariables } from '@api';
import { GET_SETTINGS } from '@graphql/common/queries/settings';
import { getSettings } from '@adapters/settingsAdapter';

export const useSettings = (shouldSkip = false) => {
  const { data, ...rest } = useQuery<SettingsQuery, SettingsQueryVariables>(GET_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ssr: false,
    skip: shouldSkip,
  });

  return {
    data: getSettings(data),
    ...rest,
  };
};
