export interface PMasqueradeBarData {
  masqueradeModeActive?: boolean | null;
  isForceOrderAvailable?: boolean | null;
  agentId?: string | null;
  userId?: string | null;
  makeGoodOrderId?: string | null;
  eStoreId?: string | null;
  plantId?: string | null;
  parentOrderId?: string | null;
}

export interface IMasqueradeBarData {
  isForceOrderAvailable: boolean;
  masqueradeModeActive: boolean;
  agentId: string;
  userId: string;
  makeGoodOrderId: string;
  eStoreId: string;
  plantId: string;
  parentOrderId: string;
  isAddOnOrderMode: boolean;
  isMakeGoodMode: boolean;
}

export function MasqueradeBarData(p: PMasqueradeBarData): IMasqueradeBarData {
  const agentId = p.agentId ?? '';
  const userId = p.userId ?? '';
  const masqueradeModeActive = p.masqueradeModeActive ?? Boolean(agentId || userId);
  const isForceOrderAvailable = p.isForceOrderAvailable ?? false;
  const makeGoodOrderId = p.makeGoodOrderId ?? '';
  const eStoreId = p.eStoreId ?? '';
  const plantId = p.plantId ?? '';
  const parentOrderId = p.parentOrderId ?? '';
  const isAddOnOrderMode = !!parentOrderId;
  const isMakeGoodMode = !!makeGoodOrderId;

  return {
    agentId,
    userId,
    masqueradeModeActive,
    isForceOrderAvailable,
    makeGoodOrderId,
    eStoreId,
    plantId,
    parentOrderId,
    isAddOnOrderMode,
    isMakeGoodMode,
  };
}
