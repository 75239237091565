import router from 'next/router';
import { routing } from '@constants/routing';

export const homeRedirect = () => {
  if (router.pathname === routing.loginLegacy || router.pathname === routing.loginUXE) {
    router.push(routing.home);
  }
};

export const modifyOrderRedirect = () => {
  if (router.pathname.includes(routing.modifyOrder)) {
    return;
  }
};
