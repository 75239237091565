import { createContext, ReactNode, useContext, useMemo } from 'react';
import { safeName } from '@utils/getBreadcrumbsTitleText';

const defaultLocationName = 'unknown';

const LocationEventContext = createContext<{
  location: string;
}>({ location: defaultLocationName });

function ProductListLocationProvider({
  children,
  location,
}: {
  children: ReactNode;
  location?: string;
}) {
  const locationMemo = useMemo(
    () => (location ? safeName(location) : defaultLocationName),
    [location],
  );
  return (
    <LocationEventContext.Provider value={{ location: locationMemo }}>
      {children}
    </LocationEventContext.Provider>
  );
}

function useProductListLocation() {
  const context = useContext(LocationEventContext);

  return {
    getLocation: (product?: { variantId: string | null }) => {
      if (context.location === defaultLocationName && product?.variantId) return product.variantId;
      return context.location;
    },
  };
}

export { ProductListLocationProvider, useProductListLocation };
