import { gql } from '@apollo/client';
import { PRODUCT_BASIC_FRAGMENT } from '@graphql/fragments/product/productBasicFragment';

export const DELIVERY_PASS_PLANS = gql`
  query DeliveryPassPlans {
    deliveryPasses {
      freeTrialEligible
      freeTrialOptinSku
      displayOverlay
      deliveryPasses {
        isActive
        isPreviousPlan
        isBestValue
        descriptionMediaPath
        deliveryPassProduct {
          ...productBasicFragment
        }
      }
    }
  }
  ${PRODUCT_BASIC_FRAGMENT}
`;
