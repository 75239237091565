import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '@graphql/fragments/common/address';
import { DELIVERY_TIMESLOTS_DATA_FRAGMENT } from '@graphql/fragments/timeslots/deliveryTimeslotData';

export const RESERVED_TIMESLOT_FRAGMENT = gql`
  fragment reservationTimeslotFragment on ReservationTimeslotData {
    chefsTable
    eligiblePreReservation
    forceOrder
    note
    reservationInfo {
      title
      address {
        ...addressFragment
      }
      dateTime
      startDateDay
      startDateMonthDay
      expirationDateTime
      reservationType
      timeslotId
    }
    deliveryTimeslotData {
      ...deliveryTimeslotDataFragment
    }
  }
  ${ADDRESS_FRAGMENT}
  ${DELIVERY_TIMESLOTS_DATA_FRAGMENT}
`;
