import React, { useCallback, useEffect, useState } from 'react';
import { Cart, CartLine } from '@commons/cart';
import { useMasqueradeBarData } from '@hooks/masquerade/useMasqueradeBarData';
import { MakeGoodControls } from '@modules/masquerade/components/MakeGoodControls/MakeGoodControls';
import { UNAVAILABLE } from '@constants/cart';
import { onCheckoutClickAction } from '@modules/actions/checkout-click-action';

export const useMakeGoodControls = (cart: Cart) => {
  const { data: masqueradeBarData } = useMasqueradeBarData();
  const [checkoutClicked, setCheckoutClicked] = useState(false);

  const hasComplaintReasons = cart?.csrData?.complaintReasons.length;
  const makeGoodMode = masqueradeBarData.masqueradeModeActive && masqueradeBarData.makeGoodOrderId;
  const hasCartLinesWithoutReason = !!cart.cartSections
    .filter(({ name }) => name !== UNAVAILABLE)
    .map(({ cartLines }) => cartLines?.filter(({ complaintReason }) => !complaintReason))
    .flat().length;

  useEffect(() => {
    if (!makeGoodMode) {
      return;
    }
    return onCheckoutClickAction((data) => {
      if (makeGoodMode && hasCartLinesWithoutReason) {
        data.event.preventDefault();
        data.event.stopPropagation();
      }
      setCheckoutClicked(hasCartLinesWithoutReason);
    });
  }, [hasCartLinesWithoutReason, makeGoodMode]);

  useEffect(() => {
    setCheckoutClicked(false);
  }, [cart.cartSections]);

  const renderAdditionalControlsForLineItem = useCallback(
    (cartLine: CartLine, isUnavailable?: boolean) => {
      const cartLineIds = cart.cartSections
        .map(({ cartLines }) =>
          cartLines?.filter(({ freeItem }) => freeItem === cartLine.freeItem).map(({ id }) => id),
        )
        .flat();

      return (
        <MakeGoodControls
          isFree={cartLine.freeItem}
          complaintReason={cartLine.complaintReason}
          complaintReasons={cart?.csrData?.complaintReasons}
          cartLineId={cartLine.id}
          cartLineIds={cartLineIds}
          key={cartLine.id}
          isUnavailable={isUnavailable}
          checkoutClicked={checkoutClicked}
        />
      );
    },
    [cart.cartSections, cart?.csrData?.complaintReasons, checkoutClicked],
  );

  return {
    renderAdditionalControlsForLineItem:
      makeGoodMode && hasComplaintReasons ? renderAdditionalControlsForLineItem : null,
    makeGoodMode,
  };
};
