import { Grid } from '@mui/material';
import React from 'react';
import cx from 'classnames';
import { ProductPrice } from '@commons/product';
import { Subtotal as SubtotalType } from '@commons/cart';
import { Subtotal } from '../Subtotal/Subtotal';
import styles from './BagDetailsSideBagDrawer.module.scss';

interface BagDetailsSideBagDrawerProps {
  orderMinimumDetails: { remaining: string; min: string } | undefined;
  subtotal: SubtotalType;
  totalWithoutDiscount: ProductPrice;
}

export const BagDetailsSideBagDrawer = ({
  orderMinimumDetails,
  subtotal,
  totalWithoutDiscount,
}: BagDetailsSideBagDrawerProps) => {
  return (
    <Grid container className={cx(styles.container, styles.drawer_opened)}>
      <Grid
        item
        className={cx(styles.details_wrapper, styles.drawer_opened, {
          [styles.order_error]: orderMinimumDetails,
        })}
      >
        <Grid
          item
          className={cx(styles.details_wrapper_sub, styles.no_left_padding, styles.drawer_opened)}
        >
          <div className={styles.detail_items}>
            <Subtotal
              dataTestId="cart-subtotal"
              current={subtotal}
              previous={totalWithoutDiscount}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
