import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import styles from './AddNewPaymentDesktop.module.scss';

interface AddNewPaymentDesktopProps {
  onClick: () => void;
}

export const AddNewPaymentDesktop = ({ onClick }: AddNewPaymentDesktopProps) => {
  const { t } = useLocalization('account');

  return (
    <button onClick={onClick} className={styles.tile}>
      <Typography component="p" className={styles.text}>
        {t('payments.addNewPayment')}
      </Typography>
    </button>
  );
};
