import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { FC, useCallback } from 'react';
import { BaseLink } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useNavigationTracking } from '@hooks/header/useNavigationTracking';
import { arrowDown as ArrowDown } from '@assets/icons/system';
import { DepartmentUnion } from '@api';
import styles from '../../Hamburger.module.scss';
import { DepartmentListItem } from './DepartmentListItem';
import { NavIcon } from './NavIcon';
import { SubMenuModalData } from './SubMenuModalData';

interface SuperDepartmentListItemProps {
  id: string | null;
  name: string | null;
  subDepartments: Array<DepartmentUnion | null>;
  expanded: string | null;
  icon?: string | null;
  url: string;
  setExpanded(name: string | null): void;
  openSubMenu(data: SubMenuModalData): void;
  closeMenu: () => void;
}

export const SuperDepartmentListItem: FC<SuperDepartmentListItemProps> = ({
  name,
  icon,
  url,
  subDepartments,
  expanded,
  setExpanded,
  openSubMenu,
  closeMenu,
}) => {
  const { t } = useLocalization('header');
  const seeAllGtmProps = useNavigationTracking(`See all ${name}`);

  const handleAccordionChange = useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? name : null);
    },
    [setExpanded, name],
  );
  if (!name) return null;

  return (
    <>
      <NavIcon icon={icon} alt={`${name} icon`} />
      <Accordion
        disableGutters
        className={cx(styles.accordion, styles.without_margin)}
        expanded={expanded === name}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ArrowDown width={18} height={11} />}
          aria-controls={`${name}-content`}
          id={`${name}-header`}
          className={styles.accordion_summary}
        >
          <Typography noWrap className={styles.list_text}>
            {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={styles.sub_dep_list}>
            <BaseLink href={url}>
              <ListItem className={styles.sub_dep_item}>
                <ListItemButton
                  className={styles.list_button}
                  {...seeAllGtmProps}
                  onClick={closeMenu}
                >
                  <Typography noWrap className={styles.list_text}>
                    {t('seeAll', { headline: name })}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </BaseLink>
            {subDepartments.map(
              (subDepartment) =>
                subDepartment && (
                  <ListItem key={subDepartment.id} className={styles.sub_dep_item}>
                    <DepartmentListItem
                      superDepartmentName={name}
                      department={subDepartment}
                      openSubMenu={openSubMenu}
                    />
                  </ListItem>
                ),
            )}
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
