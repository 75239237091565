import router from 'next/router';
import { PAGE_TYPE, PAGE_TYPE_ROUTES } from '@constants/pageType';
import { routing } from '@constants/routing';

export type GetPageType = () => PAGE_TYPE;

export const getCurrentPageType: GetPageType = () => {
  const { pathname, query } = router;
  const HOME_PAGE_TYPE = 'HOMEPAGE';
  const id = (query?.id as string) || '';
  const finalizedPathname = pathname.replace('[id]', id);

  if (finalizedPathname === routing.home) {
    return HOME_PAGE_TYPE;
  }

  const matchedPageTypeRoutes: [PAGE_TYPE, string][] = [];

  Object.entries(PAGE_TYPE_ROUTES).forEach((pageTypeToRoutesMap) => {
    const [pageType, pageTypeRoutes] = pageTypeToRoutesMap;

    if (pageType !== HOME_PAGE_TYPE) {
      pageTypeRoutes.forEach((route) => {
        if (finalizedPathname.includes(route)) {
          matchedPageTypeRoutes.push([pageType as PAGE_TYPE, route]);
        }
      });
    }
  });

  matchedPageTypeRoutes.sort(([, a], [, b]) => b.length - a.length);
  const bestMatchingPageType = matchedPageTypeRoutes[0]?.[0];

  return bestMatchingPageType || 'NOT_RECOGNIZED';
};
