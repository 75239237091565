import { createContext, useContext } from 'react';
import { On, Handle } from '@modules/emitter';

export const OrderDeliveryModalContext = createContext<
  | {
      on: On;
      emit: Handle;
    }
  | undefined
>(undefined);

export const useOrderDeliveryModalContext = () => {
  const context = useContext(OrderDeliveryModalContext);
  if (context === undefined) {
    throw new Error(
      'useOrderDeliveryModalContext must be used within a OrderDeliveryModalProvider',
    );
  }
  return context;
};
