import { AccountPreferences } from '@commons/account';

export type GetLoginType = (p: AccountPreferences) => string;
export const getLoginType: GetLoginType = ({ socialProviders, email }) => {
  if (!email) {
    return '';
  }

  return socialProviders?.[0] ?? 'email';
};
