import { ApolloLink } from '@apollo/client';
import { logger } from '@logger';
import { isClient } from '@utils/isClient';

export const roundTripLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  const id = Date.now();
  operation.setContext({
    start: new Date(),
    headers: { ...operation.getContext().headers, id },
  });

  return forward(operation).map((result) => {
    // Called after server responds
    const time = Number(new Date()) - operation.getContext().start;
    let userId = '';
    try {
      userId = operation
        .getContext()
        .headers['Cookie'].split(';')
        .find((cookie: string) => cookie.includes('FDUser'))
        .split('=')[1]
        .trim();
    } catch (e) {}

    if (!isClient()) {
      logger.debug(
        {
          id,
          duration: `${time} ms`,
          source: 'server',
          userId,
          data: result.data,
        },
        `[GQL RESPONSE] Operation "${operation.getContext().headers.GqlOperation}"`,
      );
    }

    return result;
  });
});
