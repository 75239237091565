import { Action, IAction, PAction } from '@modules/action';

export interface PHide extends PAction {
  id: string;
}

export interface IHide extends IAction {
  id: string;
}

export const THide = 'Hide';

export function Hide(p: PHide): IHide {
  const type = p.type ?? THide;
  const action = Action({
    ...p,
    type,
  });
  const id = p.id;
  return {
    ...action,
    id,
  };
}
