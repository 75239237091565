type Off = () => void;

type OnEvent = (
  target: EventTarget,
  type: string,
  listener: EventListenerOrEventListenerObject | null,
) => Off;

export const onEvent: OnEvent = (target, type, listener) => {
  target.addEventListener(type, listener);
  return () => {
    target.removeEventListener(type, listener);
  };
};
