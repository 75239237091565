import { useRouter } from 'next/router';
import { isServiceTypeParamsValid } from '@utils/isServiceTypeParamsValid';

export const useIsServiceTypeUrlParamsPresent = () => {
  const router = useRouter();
  const serviceTypeParams =
    'string' === typeof router?.query?.serviceType ? router?.query?.serviceType : '';
  const isServiceTypeParamsPresent = isServiceTypeParamsValid(serviceTypeParams);

  return { isServiceTypeParamsPresent };
};
