import { ReactNode } from 'react';
import { PAYMENT_TABS } from '@commons/payment';
import styles from './PaymentTabPanel.module.scss';

interface PaymentTabPanelProps {
  children: ReactNode;
  tabValue: PAYMENT_TABS;
  activeTab: PAYMENT_TABS;
}

export const PaymentTabPanel = ({
  children,
  tabValue,
  activeTab,
  ...props
}: PaymentTabPanelProps) => {
  return (
    <div
      {...props}
      className={styles.wrapper}
      role="tabpanel"
      hidden={tabValue !== activeTab}
      id={`payment-tabpanel-${tabValue}`}
      aria-labelledby={`payment-tab-${tabValue}`}
    >
      {tabValue === activeTab && <>{children}</>}
    </div>
  );
};
