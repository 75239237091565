import { useQuery } from '@apollo/client';
import { PAYMENT_METHOD } from '@graphql/account/queries/paymentMethod';
import { getExpirationMonthOptions } from '@utils/getExpirationMonthOptions';
import { getExpirationYearsOptions } from '@utils/getExpirationYearsOptions';
import { Address } from '@api';

export const usePaymentMethod = (paymentId?: string) => {
  const { data, ...queryParams } = useQuery(PAYMENT_METHOD, {
    variables: { paymentId },
    fetchPolicy: 'no-cache',
  });
  const payment = data?.payment;

  const getBillingAddress = (address: Address) => ({
    address1: address?.address1 ?? '',
    apartment: address?.apartment ?? '',
    city: address?.city ?? '',
    country: address?.country ?? '',
    state: address?.state ?? '',
    zipCode: address?.zipCode ?? '',
    serviceType: address?.serviceType ?? null,
  });

  const cardNumber = payment?.accountNumber?.slice(4) || '';
  const cardType = payment?.type || '';
  const nameOnCard = payment?.nameOnCard || '';
  const billingAddress = getBillingAddress(payment?.billingAddress);
  const expirationMonth = payment?.expiration?.slice(0, 2) || getExpirationMonthOptions()[0].value;
  const expirationYear = payment?.expiration?.slice(3) || getExpirationYearsOptions()[0].value;
  const phoneNumber = payment?.bestNumber || '';
  const routeNumber = payment?.abaRouteNumber || '';
  const bankName = payment?.bankName || '';
  const bankAccountType = payment?.bankAccountType || '';

  return {
    ...queryParams,
    data: {
      cardNumber,
      cardType,
      nameOnCard,
      billingAddress,
      expirationMonth,
      expirationYear,
      phoneNumber,
      routeNumber,
      bankName,
      bankAccountType,
    },
  };
};
