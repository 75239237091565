import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { InputButton } from '@components/UI/Inputs/InputButton/InputButton';
import { useLocalization } from '@hooks/useLocalization';
import { usePromo } from '@hooks/promo/usePromo';
import { localStorageService, STORAGE_KEYS } from '@utils/storageService';
import styles from './PromoCodeField.module.scss';

interface PromoCodeFieldProps {
  className?: string;
}

export const PromoCodeField = ({ className }: PromoCodeFieldProps) => {
  const { t } = useLocalization();
  const { status, cartPromo, error, apply, remove, loading } = usePromo();
  const [inputValue, setInputValue] = useState(cartPromo || '');

  const promoInputRef = useRef<HTMLInputElement>(null);
  const isApplied = status === 'applied';
  const submitted = isApplied || status === 'condition';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (submitted) return;
    setInputValue(e.target.value);
  };

  const handleRemove = () => {
    remove(() => setInputValue(''));
    localStorageService?.remove(STORAGE_KEYS.APPLIED_APC_PROMO);
  };

  useEffect(() => {
    setInputValue(cartPromo || '');

    if (!!cartPromo && !isApplied) {
      apply(inputValue.trim());
    }
  }, [cartPromo]);

  const handleSubmit = () => {
    if (submitted) return;
    if (!inputValue.trim()) {
      promoInputRef?.current?.focus();
    }
    apply(inputValue.trim());
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    handleSubmit();
  };

  return (
    <InputButton
      loading={loading}
      value={inputValue}
      errorText={error}
      error={status === 'invalid'}
      buttonName={t('buttons.add')}
      buttonLabel={t('promo.addButtonLabel')}
      submittedButton={t('buttons.remove')}
      submittedButtonLabel={t('promo.removeButtonLabel')}
      submitted={submitted}
      label={t('promo.label')}
      className={cx(styles.wrapper, className)}
      onChange={handleChange}
      onClick={handleSubmit}
      onSubmitted={handleRemove}
      onKeyUp={handleKeyUp}
      name="promo"
      fullWidth
      id={t('promo.label').replaceAll(' ', '_')}
      helperTextId="promo-helper-text"
      inputFieldRef={promoInputRef}
    />
  );
};
