/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import cx from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { getCookie } from 'cookies-next';
import { routing } from '@constants/routing';
import { Link } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useModal } from '@hooks/common';
import { useDeliveryPass } from '@hooks/deliveryPass/useDeliveryPass';
import { ActivatedDeliveryPass } from '@components/Modals/ActivatedDeliveryPass/ActivatedDeliveryPass';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { activatedDeliveryPassModalShown } from '@graphql/variables/activatedDeliveryPassModal';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { useDeliveryPassInfo } from '@hooks/deliveryPass/useDeliveryPassInfo';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useServiceType } from '@hooks/useServiceType';
import { COOKIES_LIST } from '@commons/cookies';
import { useViewedFeatureImpressions } from '@hooks/deliveryPass/useViewedFeatureImpressions';
import { DeliveryPassModal } from '../DeliveryPassModal/DeliveryPassModal';
import styles from './Delivery.module.scss';

interface DeliveryProps {
  deliveryVisible: boolean;
}

export const Delivery = ({ deliveryVisible }: DeliveryProps) => {
  const { t } = useLocalization('cart');
  const { t: tCommon } = useLocalization();
  const { isCorporateServiceType } = useServiceType();
  const { isOpen, openModal, closeModal } = useModal();

  const { cartInfo } = useCartForCheckoutContext();
  const {
    data: { totalSection, deliveryPassCartOnly, cartLinesList, csrData },
    loading: cartLoading,
  } = cartInfo;

  const { value } = totalSection.deliveryFee;

  const {
    data: { deliveryPassApplied },
    loading: deliveryPassInfoLoading,
  } = useDeliveryPassInfo();

  const {
    freeTrialEligible,
    plans,
    loading: plansLoading,
    freeOneYearEligible: freeOneYearAvailable,
    twoMonthsTrialEligible: twoMonthsTrialAvailable,
    freeTrialOptinSku,
    displayFreeTrialOverlay,
  } = useDeliveryPass();

  const deliveryPassModalShown = useReactiveVar(activatedDeliveryPassModalShown);

  const hasPlanInCart = cartLinesList.some((cartLine) =>
    plans.some((plan) => plan.product.productId === cartLine.product.productId),
  );

  const isDeliveryForceWaived = csrData?.isDeliveryForceWaived ?? false;
  const freeWithDeliveryPass = deliveryPassApplied || hasPlanInCart;
  const freeWithDeliveryPassDisplayed =
    freeWithDeliveryPass && !isDeliveryForceWaived && !isCorporateServiceType;
  const deliveryPassPromotionDisplayed =
    freeTrialEligible && !isDeliveryForceWaived && !isCorporateServiceType;
  //Need to skip delivery pass modal when cypress tests run, because of a lot of trigger dependencies
  const isAutomationTestsRun = getCookie(COOKIES_LIST.AUTOMATION) === 'qa-cypress';
  const deliveryLabel = freeWithDeliveryPassDisplayed ? t('freeDeliveryWith') : t('delivery');
  const loading = deliveryPassInfoLoading || plansLoading || cartLoading;
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const { isAuthModalOpen } = useAuthModalContext();

  const autoPopulateFreeDeliveryPassModal =
    !isAutomationTestsRun &&
    freeTrialEligible &&
    displayFreeTrialOverlay &&
    isLoggedIn &&
    !isAuthModalOpen &&
    !hasPlanInCart &&
    !isDeliveryForceWaived &&
    !isCorporateServiceType &&
    !deliveryPassInfoLoading &&
    !plansLoading;

  const [viewedFeatureImpressions] = useViewedFeatureImpressions({});

  useEffect(() => {
    if (autoPopulateFreeDeliveryPassModal) {
      openModal();
      if (twoMonthsTrialAvailable) {
        viewedFeatureImpressions({
          variables: {
            feature: 'dpTrialOfferCheckout',
          },
        });
      }
      sendGtmEvent(
        GtmCustomEvent({
          event: 'deliverypass-autopopulate',
          event_name: 'deliverypass_free_delivery',
          ua_category: 'deliverypass',
          ua_action: 'free delivery',
          ua_label: 'free',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPopulateFreeDeliveryPassModal]);

  if (deliveryPassCartOnly || loading) return null;

  const handleCloseDeliveryPassModal = () => activatedDeliveryPassModalShown(false);

  const onOpenModal = () => {
    openModal();
    sendGtmEvent(
      GtmCustomEvent({
        event: 'deliverypass-click',
        event_name: 'deliverypass_free_delivery',
        ua_category: 'deliverypass',
        ua_action: 'free delivery',
        ua_label: 'free',
      }),
    );
  };

  const renderDeliveryValue = () => {
    if (freeWithDeliveryPassDisplayed) {
      return (
        <Link legacyBehavior classNameLinkText={styles.link} href={routing.deliveryPass}>
          {t('deliveryPass')}
        </Link>
      );
    }

    if (deliveryPassPromotionDisplayed) {
      return (
        <Typography component="span" variant="body" className={styles.delivery_value}>
          <Typography className={styles.formatted_price} fontWeight="bold">
            {value.formattedPrice}
          </Typography>
          <span className={styles.or}>{tCommon('or')}</span>
          <button onClick={onOpenModal} className={cx(styles.link, styles.free_pass_button)}>
            {tCommon('free')}
          </button>
        </Typography>
      );
    }

    if (isCorporateServiceType) {
      return (
        <Typography
          component="span"
          variant="body"
          className={styles.delivery_value}
          data-testid="delivery-price-without-DP"
        >
          <span>{value.formattedPrice}</span>
        </Typography>
      );
    }

    return (
      <Typography component="span" variant="body" className={styles.delivery_value}>
        <span>{value.formattedPrice}</span>
      </Typography>
    );
  };

  return (
    <>
      <div
        data-testid="cart-delivery"
        className={deliveryVisible ? styles.delivery : styles.hide_delivery}
      >
        <Typography component="span" variant="body" className={styles.delivery_label}>
          {deliveryLabel}{' '}
        </Typography>
        {renderDeliveryValue()}
      </div>
      {isOpen && (
        <DeliveryPassModal
          open={isOpen}
          onClose={closeModal}
          freeOneYearAvailable={freeOneYearAvailable}
          twoMonthsTrialAvailable={twoMonthsTrialAvailable}
          freeTrialOptinSku={freeTrialOptinSku}
        />
      )}
      {deliveryPassModalShown && (
        <ModalWindow
          open={deliveryPassModalShown}
          onClose={handleCloseDeliveryPassModal}
          className={styles.delivery_pass_modal}
          wrapperClassName={styles.delivery_pass_modal_content}
        >
          <ActivatedDeliveryPass onClose={handleCloseDeliveryPassModal} />
        </ModalWindow>
      )}
    </>
  );
};
