import { useMutation } from '@apollo/client';
import { GET_GIFT_CART } from '@graphql/cart/queries/getGiftCart';
import { EMPTY_GIFT_CARDS_CART } from '@graphql/giftCard/mutations/emptyGiftCardsCart';
import { EmptyGiftCardsCartMutation, EmptyGiftCardsCartMutationVariables } from '@api';

export const useEmptyGiftCards = () => {
  return useMutation<EmptyGiftCardsCartMutation, EmptyGiftCardsCartMutationVariables>(
    EMPTY_GIFT_CARDS_CART,
    {
      update(cache, { data }) {
        if (data) {
          cache.writeQuery({
            query: GET_GIFT_CART,
            data: {
              giftCardsCart: {
                __typename: 'GiftCardsCart',
                cardsCount: 0,
                cards: [],
              },
            },
          });
        }
      },
    },
  );
};
