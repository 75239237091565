import React from 'react';
import cx from 'classnames';
import { Nutrition as NutritionAPI } from '@commons/product';
import styles from '@features/ProductPage/components/Nutrition/Nutrition.module.scss';
import { useLocalization } from '@hooks/useLocalization';
import { MAIN_NUTRITIONS } from '@features/ProductPage/constants';

export const ExtraItemsSingle = (nutrition: NutritionAPI) => {
  const { t } = useLocalization('product');
  return (
    <>
      <table className={styles.table_extra_section}>
        <tbody>
          {nutrition?.extra?.map((nutritionItem) => {
            const shouldNotBeReseted = !Object.values(MAIN_NUTRITIONS).includes(nutritionItem.id);
            return (
              <tr
                key={nutritionItem.displayName}
                className={cx(styles.table_item, {
                  [styles.reseted_item]: shouldNotBeReseted,
                })}
              >
                <th>
                  <span>{nutritionItem.displayName} </span>
                  <span className={styles.extra_weight}>
                    {`${nutritionItem.value} ${nutritionItem.uom}`}
                  </span>
                </th>
                <td>{`${nutritionItem.dailyValue} ${nutritionItem.dailyUom}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.medium_border_top}>
        <span className={styles.single_daily_value_text}>{t('nutrition.dailyValueText')}</span>
      </div>
    </>
  );
};
