import { ApolloCache } from '@apollo/client';
import { DeliveryAddress } from '@api';

export const updateCheckoutAddressesCache = (
  cache: ApolloCache<unknown>,
  newAddress: DeliveryAddress,
) => {
  cache.modify({
    fields: {
      checkout(checkout) {
        return {
          ...checkout,
          deliveryAddress: { ...newAddress },
        };
      },
    },
  });
};
