import { FormikHelpers, FormikValues } from 'formik';
import { ChangeEvent, MutableRefObject } from 'react';
import {
  AddressInput,
  AddressToSend,
  ALT_DELIVERY_OPTION,
  PHONE_TYPE,
  SERVICE_TYPE,
} from '@commons/deliveryAddresses';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { CorporateFormKeys } from '@components/CorporateAddressForm/CorporateAddressForm.types';
import { ResidentialFormKeys } from '@components/ResidentialAddressForm/ResidentialAddressForm.types';
import {
  ATP_ABSENCE_WARNING,
  FLOOR_ROOM_ABSENCE_WARNING,
} from '@utils/mapAddressServerErrorsToTranslationKey';
import { FORM_FIELDS as ResidentialFormFields } from '@components/ResidentialAddressForm/ResidentialAddressForm.enums';
import { FORM_FIELDS as CorporateFormFields } from '@components/CorporateAddressForm/CorporateAddressForm.enums';

type GetAddressFromSuggestion = (suggestion: Suggestion) => AddressToSend;
type Warnings = Record<string, unknown>;

type GetAddressInput = {
  values: FormikValues;
  getAddressFromSuggestion: GetAddressFromSuggestion;
  deliveryAddressSuggestion: MutableRefObject<Suggestion | null>;
  neighborDeliveryAddressSuggestion?: MutableRefObject<Suggestion | null>;
  inputExtension?: string | null;
};

const extendResidentialAddress = (
  addressInput: AddressInput,
  values: FormikValues,
  getAddressFromSuggestion: GetAddressFromSuggestion,
  neighborDeliveryAddressSuggestion?: MutableRefObject<Suggestion | null>,
) => {
  const {
    deliveryOption,
    unattendedInstructions,
    neighborApt,
    neighborFirstName,
    neighborLastName,
    neighborPhone,
  } = values;

  if (deliveryOption === ALT_DELIVERY_OPTION.UNATTENDED) {
    addressInput.backupInfo = {
      setting: deliveryOption,
      contact: {
        instructions: unattendedInstructions,
      },
    };
  }

  if (deliveryOption === ALT_DELIVERY_OPTION.NEIGHBOR) {
    const neighborDeliveryAddress = getAddressFromSuggestion(
      neighborDeliveryAddressSuggestion?.current as Suggestion,
    );

    addressInput.backupInfo = {
      setting: deliveryOption,
      address: neighborApt
        ? { ...neighborDeliveryAddress, apartment: neighborApt }
        : neighborDeliveryAddress,
      contact: {
        firstName: neighborFirstName,
        lastName: neighborLastName,
        phoneNumber: {
          phone: neighborPhone,
          extension: '',
          type: PHONE_TYPE.MOBILE,
        },
        instructions: unattendedInstructions,
      },
    };
  }
  return addressInput;
};

const extendCorporateAddress = (addressInput: AddressInput, values: FormikValues) => {
  addressInput.address = {
    ...addressInput.address,
    companyName: values.companyName,
  };
  return addressInput;
};

export const getAddressInput = ({
  values,
  getAddressFromSuggestion,
  deliveryAddressSuggestion,
  neighborDeliveryAddressSuggestion,
  inputExtension = '',
}: GetAddressInput): AddressInput => {
  const { firstName, lastName, aptUnit, phone, email, instructions, deliveryOption } = values;
  const deliveryAddress = getAddressFromSuggestion(deliveryAddressSuggestion.current as Suggestion);

  const addressInput: AddressInput = {
    address: aptUnit ? { ...deliveryAddress, apartment: aptUnit } : deliveryAddress,
    contact: {
      firstName,
      lastName,
      email,
      phoneNumber: {
        phone,
        extension: getExtensionInString(inputExtension, values),
        type: PHONE_TYPE.MOBILE,
      },
      instructions,
    },
    backupInfo: {
      setting: deliveryOption,
    },
  };

  return deliveryAddress.serviceType === SERVICE_TYPE.HOME
    ? extendResidentialAddress(
        addressInput,
        values,
        getAddressFromSuggestion,
        neighborDeliveryAddressSuggestion,
      )
    : extendCorporateAddress(addressInput, values);
};

export const handleFormChange =
  (
    values: FormikValues,
    isErrorVisible: (name: string) => boolean,
    setFieldError: FormikHelpers<FormikValues>['setFieldError'],
  ) =>
  (event: ChangeEvent<HTMLFormElement>) => {
    const name = event.target?.name;
    const value = event.target?.value;
    if (
      value &&
      value !== values[name as ResidentialFormKeys | CorporateFormKeys] &&
      isErrorVisible(name)
    ) {
      setFieldError(name, '');
    }
  };

export const mapSaveAddressError = (error: string, serviceType: SERVICE_TYPE): string => {
  if (serviceType === SERVICE_TYPE.CORPORATE) {
    return (
      {
        [ATP_ABSENCE_WARNING]: FLOOR_ROOM_ABSENCE_WARNING,
      }[error] ?? error
    );
  }
  return error;
};

export const setAptUnitFieldError =
  (
    setFieldError: FormikHelpers<FormikValues>['setFieldError'],
    serviceType: SERVICE_TYPE,
    t: (str: string) => string,
    aptUnitFieldName: string,
  ) =>
  (message: string) => {
    const isResidentialError =
      serviceType === SERVICE_TYPE.CORPORATE && message !== t('delivery.error.residentialAddress');
    const isCorporateError =
      serviceType === SERVICE_TYPE.HOME && message !== t('delivery.error.corporateAddress');

    if (isResidentialError || isCorporateError) {
      setFieldError(aptUnitFieldName, message);
    }
  };

export const setAddressFieldWarnings =
  (
    getFormWarnings: (message: string) => Warnings,
    setFormWarnings: (warnings: Warnings) => void,
    setServiceTypeError: (error: boolean) => void,
    cosFeatureEnabled: boolean,
    isCreateStandingOrder: boolean = false,
  ) =>
  ({ address: messageKey }: { address: string }) => {
    const newFormWarnings = getFormWarnings(messageKey);
    setFormWarnings(newFormWarnings);

    if (cosFeatureEnabled && !isCreateStandingOrder) {
      setServiceTypeError(!!messageKey && messageKey !== 'dualAddress');
    }
  };

export const getShouldShowAtpUnit = (isAptUnitAvailable: boolean, values: FormikValues) =>
  isAptUnitAvailable || !!values.aptUnit;

export const getShouldShowNeighborAtpUnit = (
  isNeighborAptUnitAvailable: boolean,
  values: FormikValues,
) => isNeighborAptUnitAvailable || !!values.neighborApt;

export const getAddressInString = (inputAddress: string | null, values: FormikValues) =>
  inputAddress ?? (values.address || '');

export const getExtensionInString = (inputExtension: string | null, values: FormikValues) =>
  inputExtension ?? (values.extension || '');

export const getAptForForm = (apartment: string, aptUnit: string | null) =>
  apartment || aptUnit || null;

export const handleAddressFormValidation = (
  setFieldError: FormikHelpers<FormikValues>['setFieldError'],
  formFields: typeof ResidentialFormFields | typeof CorporateFormFields,
  validateField: FormikHelpers<FormikValues>['validateField'],
  isManualFormSubmitted: boolean,
) => {
  if (isManualFormSubmitted) {
    setFieldError(formFields.ADDRESS, '');
    setFieldError(formFields.APT_UNIT, '');
  } else {
    validateField(formFields.ADDRESS);
  }
};
