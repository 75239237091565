import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import styles from './LeftNavSkeleton.module.scss';

export const LeftNavSkeleton = () => {
  return (
    <Stack className={styles.left_nav}>
      <Skeleton variant="text" width={50} />
      <Skeleton variant="text" width={100} className={styles.list_item} />
      <Skeleton variant="text" width={100} className={styles.list_item} />
      <Skeleton variant="text" width={150} className={styles.list_item} />
      <Skeleton variant="text" width={100} className={styles.list_item} />
      <Skeleton variant="text" width={100} className={styles.list_item} />
      <Skeleton variant="text" width={200} className={styles.list_item} />
      <Skeleton variant="text" width={100} className={styles.list_item} />
      <Skeleton variant="text" width={100} className={styles.list_item} />
    </Stack>
  );
};
