import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '@graphql/fragments/common/address';

export const GET_USER_GEOLOCATION_ADDRESS = gql`
  query geolocation {
    geolocation {
      deliverable
      expressEnabled
      success
      address {
        ...addressFragment
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;
