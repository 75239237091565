import { FC, KeyboardEventHandler } from 'react';
import cx from 'classnames';
import { plus as PlusIcon, plusV2 as PlusIconV2 } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { AutosuggestProduct, ProductTile } from '@commons/product';
import { ReorderCounter } from '../../ReorderCounter/ReorderCounter';
import { IconButton } from '../../UI';
import styles from '../QuantityCounter.module.scss';

interface CollapsedCounterProps {
  productCount: number;
  isReorder?: boolean;
  onClick?(): void;
  onKeyDown?: KeyboardEventHandler;
  buttonArialLabel?: string;
  nonFocusable?: boolean;
  addBtnRef?: React.RefObject<HTMLButtonElement>;
  product?: ProductTile | AutosuggestProduct;
  isV2?: boolean;
}

export const CollapsedCounter: FC<CollapsedCounterProps> = ({
  productCount,
  onClick,
  onKeyDown,
  isReorder = false,
  buttonArialLabel,
  nonFocusable,
  addBtnRef,
  product,
  isV2 = false,
}) => {
  const { t } = useLocalization('components');
  const ariaText = buttonArialLabel ?? `${product?.brandName} ${product?.productName}`;

  if (isReorder) {
    return (
      <ReorderCounter
        productCount={productCount}
        onClick={onClick}
        buttonArialLabel={buttonArialLabel}
      />
    );
  }

  return (
    <span>
      <IconButton
        ref={addBtnRef}
        onClick={onClick}
        onKeyDown={onKeyDown}
        className={cx(styles.collapse_button, { [styles.v2]: isV2 })}
        aria-label={`${t('quantityCounter.ariaLabels.addToCart')} ${ariaText}`}
        variant="outlined"
        disabled={nonFocusable}
        data-testid={t('quantityCounter.ariaLabels.addToCart')}
      >
        {!isV2 && <PlusIcon className={styles.icon} role="img" />}
        {isV2 && <PlusIconV2 width={11} height={11} className={styles.icon} role="img" />}
      </IconButton>
    </span>
  );
};
