import { useMutation } from '@apollo/client';
import { EDIT_PAYMENT } from '@graphql/account/mutations/editPayment';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';

export const useEditPayment = () =>
  useMutation(EDIT_PAYMENT, {
    update(
      cache,
      {
        data: {
          editPayment: { data: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
