import { useQuery } from '@apollo/client';
import { GET_EXPRESSTOGGLEEVENTID } from '@graphql/common/queries/getExpressToggleEventId';

export const useExpressToggleEventId = () => {
  const { data, refetch } = useQuery(GET_EXPRESSTOGGLEEVENTID, { skip: true });

  return {
    data: data?.expressToggleEventId,
    refetch,
  };
};
