import { ApolloLink } from '@apollo/client';
import { routing } from '@constants/routing';
import { getCurrentPageUrl } from '@utils/getCurrentPageUrl';

export const expressCheckoutLink = new ApolloLink((operation, forward) => {
  const currentUrl = getCurrentPageUrl();
  const isOnCheckoutPages =
    (currentUrl === routing.cart ||
      currentUrl === routing.checkout ||
      currentUrl?.includes(routing.modifyOrder)) &&
    operation.operationName !== 'MinimumAnalyticsData' &&
    operation.operationName !== 'SeoData';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation.setContext((ctx: Record<string, any>) => {
    return {
      ...ctx,
      headers: {
        ...ctx.headers,
        ['X-Express-Checkout-Operation']: String(isOnCheckoutPages),
      },
    };
  });
  return forward(operation);
});
