import cx from 'classnames';
import { Typography } from '@mui/material';
import styles from './Title.module.scss';

interface TitleProps {
  showTitle: boolean;
  isUnavailableSection: boolean;
  title: string;
  departmentTitle?: boolean;
}

export const Title = ({ showTitle, isUnavailableSection, title, departmentTitle }: TitleProps) => {
  return (
    <Typography
      component="p"
      variant="smallBody"
      className={cx({
        [styles.title]: showTitle,
        [styles.unavailable_title]: isUnavailableSection,
        [styles.department_title]: departmentTitle,
      })}
    >
      {title}
    </Typography>
  );
};
