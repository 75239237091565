import { Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikInput } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { EMAIL_REGEX } from '@utils/validation';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { HelperText } from '../HelperText/HelperText';
import styles from './PasswordModal.module.scss';

interface PasswordModalProps {
  onSubmit: (form: { [key: string]: string }) => void;
  initialEmail: string | null;
}

enum FIELDS {
  EMAIL = 'email',
  PASSWORD = 'password',
}

const validationSchema = Yup.object().shape({
  [FIELDS.PASSWORD]: Yup.string()
    .min(6, 'preferences.linkedAccounts.passwordModal.describe')
    .required('preferences.linkedAccounts.passwordModal.describe'),
  [FIELDS.EMAIL]: Yup.string()
    .matches(EMAIL_REGEX, 'preferences.linkedAccounts.passwordModal.invalidEmail')
    .required('preferences.linkedAccounts.passwordModal.invalidEmail'),
});

export const PasswordModal = ({ onSubmit, initialEmail }: PasswordModalProps) => {
  const initialEmailValue = initialEmail || '';
  const { t } = useLocalization('account');

  const handleSubmit = (values: { [key in FIELDS]: string }) => {
    onSubmit(values);
  };
  return (
    <>
      <div className={styles.text_wrapper}>
        <Typography
          component="h2"
          variant="h5"
          className={styles.title}
          id={MODAL_TITLE_ID.PASSWORD}
        >
          {t('preferences.linkedAccounts.passwordModal.title')}
        </Typography>
      </div>
      <Formik
        initialValues={{
          [FIELDS.EMAIL]: initialEmailValue,
          [FIELDS.PASSWORD]: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ errors, values, touched, handleBlur }) => (
          <Form className={styles.form}>
            <Field
              name={FIELDS.EMAIL}
              control="input"
              className={styles.input}
              label="Email"
              component={FormikInput}
              error={touched[FIELDS.EMAIL] && !!errors[FIELDS.EMAIL]}
              helperText={t(errors[FIELDS.EMAIL] || '')}
              onBlur={handleBlur}
              id={FIELDS.EMAIL}
            ></Field>
            <Field
              name={FIELDS.PASSWORD}
              control="input"
              type="password"
              className={styles.input}
              label="Password"
              component={FormikInput}
              error={touched[FIELDS.PASSWORD] && !!errors[FIELDS.PASSWORD]}
              helperText={
                <HelperText
                  showCheckIcon={
                    !!touched[FIELDS.PASSWORD] &&
                    !!values[FIELDS.PASSWORD] &&
                    !errors[FIELDS.PASSWORD]
                  }
                />
              }
              onBlur={handleBlur}
              id={FIELDS.PASSWORD}
            ></Field>
            <Button
              data-testid="password-submit"
              type="submit"
              size="large"
              fullWidth
              className={styles.button}
            >
              {t('preferences.linkedAccounts.passwordModal.save')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
