import { FC, KeyboardEvent, useState } from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { Product, ProductPrice, ProductTile } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import styles from '@features/ProductPage/components/AddToCart/AddToCart.module.scss';
import { getCurrencySymbol } from '@utils/productHelper';
import { addZerosToPrice } from '@utils/getPriceFormat';
import { info as InfoIcon } from '@assets/icons/system';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { InfoContent } from '@components/ProductConfigurationModal/components/InfoContent/InfoContent';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { getPrice } from '@utils/getPrice';

export interface AddToCartControlsProps {
  className?: string;
  shouldShowTotalPrice: boolean;
  product: Product | ProductTile;
  totalPrice: number;
  estimatedWeight: string | null;
  shouldShowQuantity: boolean;
  quantity: number;
  currentPrice?: ProductPrice;
  prevPrice?: ProductPrice;
  discount?: number;
  isShowPercentage?: boolean;
}

export const AddToCartControls: FC<AddToCartControlsProps> = ({
  className,
  shouldShowTotalPrice,
  product,
  totalPrice,
  estimatedWeight,
  shouldShowQuantity,
  quantity,
  currentPrice,
  prevPrice,
  discount,
  isShowPercentage,
  children,
}) => {
  const { t } = useLocalization('product');
  const hasEstimatedWeight = !!estimatedWeight;
  const hasDisclaimer = !!product?.estimatedWeightDisclaimer;

  const onIconKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') handleInfoIconClick();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [htmlUrl, setHtmlUrl] = useState<string | null>(null);

  const handleInfoIconClick = () => {
    setHtmlUrl(product?.estimatedWeightDisclaimer || null);
    setModalTitle(t('configurationModal.moreInfo.weightTitle'));
    setIsModalOpen((prev) => !prev);
  };

  const renderModalWindow = () => {
    if (!isModalOpen || !htmlUrl) return null;
    return (
      <ModalWindow
        open={isModalOpen}
        onClose={handleInfoIconClick}
        className={styles.content}
        labelId={MODAL_TITLE_ID.INFO_CONTENT_MODAL}
      >
        <InfoContent
          url={htmlUrl}
          title={modalTitle}
          className={styles.window_disclaimer}
          titleId={MODAL_TITLE_ID.INFO_CONTENT_MODAL}
        />
      </ModalWindow>
    );
  };

  const isPrevPriceReadyToCalculate =
    !!prevPrice &&
    !!prevPrice.value &&
    currentPrice?.value &&
    prevPrice.value > 0 &&
    prevPrice.value > currentPrice?.value;
  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.price_wrapper} aria-live="polite" aria-atomic="true">
        {shouldShowTotalPrice && (
          <div className={styles.total_price_wrapper}>
            <div>
              <Typography
                component="b"
                variant="h4"
                className={styles.price}
                data-testid="add-to-cart-total-price"
              >
                {t('totalPrice', {
                  totalPrice:
                    getCurrencySymbol(product.price.currency) + addZerosToPrice(totalPrice, 2),
                })}
              </Typography>{' '}
              {isPrevPriceReadyToCalculate && (
                <span>
                  {prevPrice?.value && prevPrice.currency && (
                    <del
                      className={styles.product_page_price_previous}
                      aria-label={t('previewBlock.productPrice.previous')}
                    >
                      {hasEstimatedWeight
                        ? getPrice(
                            prevPrice?.value * parseFloat(estimatedWeight) || 0,
                            prevPrice?.currency,
                          )
                        : getPrice(prevPrice?.value || 0, prevPrice?.currency)}
                    </del>
                  )}
                  {isShowPercentage && currentPrice?.value && prevPrice?.value && (
                    <span
                      className={styles.product_page_price_percents}
                      aria-label={t('previewBlock.productPrice.percentage')}
                    >
                      {!!discount && t('previewBlock.productPrice.saveLabel', [discount])}
                    </span>
                  )}
                </span>
              )}
            </div>
            {quantity >= 1 && (
              <Typography variant="body" className={styles.info_count}>
                {quantity === 1
                  ? t('itemsInBag_one', { count: quantity })
                  : t('itemsInBag_other', { count: quantity })}
              </Typography>
            )}
          </div>
        )}
        <div>
          {hasEstimatedWeight && (
            <Typography component="span" variant="smallBody" className={styles.weight}>
              {t('configurationModal.weight', { estimatedWeight })}
              {hasDisclaimer && (
                <InfoIcon
                  className={styles.info_icon}
                  onClick={handleInfoIconClick}
                  onKeyDown={onIconKeyDown}
                  tabIndex={0}
                  role="link"
                  aria-label={t('configurationModal.weightLabel')}
                />
              )}
            </Typography>
          )}
        </div>
        {shouldShowQuantity && (
          <Typography variant="body" className={styles.info_count}>
            {quantity > 1
              ? t('itemsInBag_other', { count: quantity })
              : t('itemsInBag_one', { count: quantity })}
          </Typography>
        )}
      </div>
      {renderModalWindow()}
      {children}
    </div>
  );
};
