/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useRef } from 'react';
import { useInterval } from 'usehooks-ts';
import { ApolloError } from '@apollo/client';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import { useModifyOrder } from '@hooks/orderModification/useModifyOrder';
import { useModal } from '@hooks/common';
import { ModifyOrderErrorModal } from '@components/Modals/ModifyOrderErrorModal/ModifyOrderErrorModal';
import { ModifyOrderPollingModal } from '@components/Modals/ModifyOrderPollingModal/ModifyOrderPollingModal';
import { useOrderModalState } from '@graphql/variables/orderModalStateVar';
import { getModifyingOrderLink } from '@utils/getModifyingOrderLink';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { ORDER_MODAL_STATE } from '@constants/orderModalState';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { CallbackFunction, FailureFunction } from '@modules/auth/hooks/useProtectedCallback';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { AUTH_ERROR_MESSAGE } from '@constants/errorCodes';

interface PendingOrderCardProps {
  className: string;
  orderId: string;
  isFullWidth?: boolean;
  onClose?: () => void;
  variant?: 'contained' | 'outlined' | 'underline';
  size?: 'small' | 'large';
  isExpressOrder?: boolean;
  modifyPollingEnabled?: boolean;
}

export const ModifyOrderButton = ({
  className,
  orderId,
  isFullWidth = false,
  onClose = () => null,
  variant,
  size = 'small',
  isExpressOrder = false,
  modifyPollingEnabled = true,
}: PendingOrderCardProps) => {
  const { t } = useLocalization();
  const { t: text } = useLocalization('account');
  const router = useRouter();
  const errorModal = useModal();
  const modifyPollingModal = useModal();
  const [modifyOrder, { loading }] = useModifyOrder();
  const { setOrderModalState } = useOrderModalState();
  const { setProtectedCallback } = useAuthContext();
  const { clearCacheFields } = useCacheFieldsInvalidate();

  const POLLING_DELAY = 3000;
  const POLLING_LIMIT = 10;
  const pollingCount = useRef<number>(0);
  const isPollWaiting = useRef<boolean>(false);
  const pollingLimit = useRef<number>(POLLING_LIMIT);
  const pollingDelay = useRef<number>(POLLING_DELAY);
  const isPolling = useRef<boolean>(false);

  useInterval(
    () => {
      pollingCount.current = pollingCount.current + 1;
      if (pollingCount.current >= pollingLimit.current) {
        isPolling.current = false;
        pollingDelay.current = -1;
      }
      if (!isPollWaiting.current) {
        isPollWaiting.current = true;
        redirectToModifyOrderPage(orderId);
      }
    },
    isPolling.current && modifyPollingModal.isOpen ? pollingDelay.current : null,
  );

  const redirectToModifyOrderPagePolling = () => {
    modifyPollingModal.openModal();
    isPolling.current = true;
    pollingCount.current = 0;
    pollingDelay.current = POLLING_DELAY;
  };

  const handleErrors = (failure?: FailureFunction, error?: ApolloError) => {
    if (modifyPollingEnabled) {
      isPollWaiting.current = false;
    }
    if (
      !modifyPollingEnabled ||
      (modifyPollingEnabled && pollingCount.current >= pollingLimit.current) ||
      (modifyPollingEnabled && error?.message === AUTH_ERROR_MESSAGE)
    ) {
      modifyPollingModal?.closeModal();
      if (!(modifyPollingEnabled && error?.message === AUTH_ERROR_MESSAGE)) {
        errorModal.openModal();
      }
      if (failure && error) {
        failure(error);
      }
    } else if (modifyPollingEnabled && isPolling) {
      return;
    }
  };

  const redirectToModifyOrderPage = (id: string) => {
    const protectedModifyOrderCommand: CallbackFunction = (failure) => {
      return modifyOrder({
        variables: { orderId: id },
        onCompleted: (res) => {
          if (!res.modifyOrder.success) {
            return handleErrors();
          }
          clearCacheFields(['initModifyOrderCheckout', 'orderModifications']);
          setOrderModalState(ORDER_MODAL_STATE.MODIFYING);
          router.replace(getModifyingOrderLink(orderId));
          onClose();
        },
        onError: (error) => {
          return handleErrors(failure, error);
        },
      });
    };

    setProtectedCallback(protectedModifyOrderCommand);
    sendGtmEvent(
      GtmCustomEvent({
        event: 'modify-click',
        event_name: 'enter_modify_mode',
        ua_category: 'modify',
        ua_action: 'enter modify mode',
        ua_label: router.pathname,
      }),
    );
  };

  const handleClick = () => {
    return modifyPollingEnabled
      ? redirectToModifyOrderPagePolling()
      : redirectToModifyOrderPage(orderId);
  };

  return (
    <>
      <Button
        className={className}
        onClick={handleClick}
        variant={variant}
        size={size}
        loading={loading}
        isFullWidth={isFullWidth}
        data-testid="modify-order-button"
      >
        {t('buttons.modify')}
        <Typography component="span" variant="body" sx={visuallyHidden}>
          {text('orderHistory.order.orderNumber', { orderId: orderId })}
        </Typography>
      </Button>
      <ModifyOrderErrorModal
        onClose={errorModal.closeModal}
        opened={errorModal.isOpen}
        isExpressOrder={isExpressOrder}
      />
      {modifyPollingEnabled && (
        <ModifyOrderPollingModal
          onClose={modifyPollingModal.closeModal}
          opened={modifyPollingModal.isOpen}
        />
      )}
    </>
  );
};
