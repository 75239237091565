import cx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import styles from './Header.module.scss';
import { StaticContent } from './components/StaticContent/StaticContent';
import { StickyContent } from './components/StickyContent/StickyContent';
import { SkipLink } from './components/SkipLink/SkipLink';

interface StickyHeader {
  mobile: boolean;
  tablet: boolean;
  laptop: boolean;
}

type StickyHeaderType = boolean | StickyHeader;

interface HeaderProps {
  isStickyHeader?: StickyHeaderType;
}

export const Header = ({ isStickyHeader = true }: HeaderProps) => {
  const { screenSize } = useHeaderContext();
  const isMobile = !!screenSize?.isMobile;
  const isTablet = !!screenSize?.isTablet;
  const isMobileOrTablet = isMobile || isTablet;
  const isFromLargeTablet = !screenSize?.isSmallScreen;
  const [isSticky, setIsSticky] = useState(!!isStickyHeader);
  const { headerContentRef } = useHeaderContext();
  const headerRef = useRef<HTMLDivElement>(null);

  const handleSticky = useCallback(() => {
    let shouldBeSticky = !!isStickyHeader;
    if (typeof isStickyHeader === 'object') {
      shouldBeSticky =
        (isStickyHeader.laptop && isFromLargeTablet) ||
        (isStickyHeader.tablet && isTablet) ||
        (isStickyHeader.mobile && isMobileOrTablet);
    }

    setIsSticky(shouldBeSticky);
  }, [isMobileOrTablet, isTablet, isFromLargeTablet, isStickyHeader]);

  useEffect(handleSticky, [handleSticky]);

  const headerClasses = cx(styles.header, {
    [styles.is_static_header]: !isSticky,
    [styles.is_sticky]: isSticky,
  });

  return (
    <header ref={headerRef} className={headerClasses}>
      <div ref={headerContentRef} className={styles.content}>
        <SkipLink>
          <button type="button">Skip to content</button>
        </SkipLink>
        {isSticky ? <StickyContent /> : <StaticContent />}
      </div>
    </header>
  );
};
