import { LightweightCart } from '@api';
import { cartAdapter } from '@adapters/cartAdapter';
import { CHANNEL_LIST } from './channel';

const { getLightCart } = cartAdapter();
type FormattedTileList = [number, Element][];

export const getProductPositionFromLightCart = (
  lightCart: DeepPartial<LightweightCart> | undefined,
  id: string,
) => {
  const productFromLightCart = getLightCart(lightCart).cartLines.find(
    ({ productId }) => productId === id,
  );

  return productFromLightCart?.itemPosition ?? 0;
};

export const getFormattedTileList = (list: FormattedTileList): FormattedTileList =>
  list
    .filter(([_, value]) => {
      const { width, height } = value.getBoundingClientRect();
      return width && height;
    })
    .map(([_, value], index) => [index, value]);

export const getRWChannel = (product?: { variantId?: string }) => {
  const { variantId } = product ?? {};

  return variantId ? `rec_${variantId}` : CHANNEL_LIST.recBrSearch;
};
