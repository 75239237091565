import { WineData } from '@commons/product';

export type WineDataKeys = keyof WineData;

export const ALCOHOL_TITLE_TRANSLATIONS = {
  country: 'alcoholInfo.title.country',
  region: 'alcoholInfo.title.region',
  city: 'alcoholInfo.title.city',
  importer: 'alcoholInfo.title.importer',
  types: 'alcoholInfo.title.type',
  vintage: 'alcoholInfo.title.vintage',
  alcoholGrade: 'alcoholInfo.title.alcohol',
  grape: 'alcoholInfo.title.grape',
  classification: 'alcoholInfo.title.classification',
  varietals: 'alcoholInfo.title.varietals',
  agingNotes: 'alcoholInfo.title.agingNotes',
};

export const ALCOHOL_KEYS = Object.keys(ALCOHOL_TITLE_TRANSLATIONS) as WineDataKeys[];
