import { Maybe, VarItem } from '@api';
import { Variant } from '@commons/cart';
import { Product } from '@commons/product';

export const getSelectedProductsNames = (variants: Variant[] | undefined, product: Product) => {
  let selectedProductsNames = '';
  const variationGroups = product?.bundle?.variationGroups;
  const values: Maybe<VarItem>[] = [];

  if (variationGroups.length) {
    variationGroups.forEach((elem) => {
      if (elem?.variations) {
        elem?.variations[0]?.values?.forEach((value) => {
          values.push(value);
        });
      }
    });
  }

  if (values.length) {
    selectedProductsNames =
      variants
        ?.map((variant) => values.find((el) => el?.name === variant.characteristicValue)?.label)
        .filter((label) => !!label)
        .join(', ') || '';
  }

  return selectedProductsNames;
};
