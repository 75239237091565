import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMinimumAnalyticsData } from '@modules/ga/hooks/useMinimumAnalyticsData';

export const useRefetchEventId = () => {
  const router = useRouter();
  const { loading, refetch } = useMinimumAnalyticsData();

  useEffect(() => {
    const handleRouterChange = () => {
      refetch();
    };

    router.events.on('beforeHistoryChange', handleRouterChange);
    return () => {
      router.events.off('beforeHistoryChange', handleRouterChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading;
};
