import { Maybe, Price as PriceApi, PriceProperty } from '@api';
import { Price } from '@commons/price';
import { ProductBasicPrice } from '@commons/product';

interface PriceAdapter {
  getPrice: (price?: DeepPartial<Maybe<PriceApi>>) => Price;
  getBasicPrice: (price?: DeepPartial<Maybe<PriceApi>>) => ProductBasicPrice;
}

const getPrice = (price?: DeepPartial<Maybe<PriceApi>>): Price => ({
  value: price?.value ?? 0,
  currency: price?.currency ?? '',
  formattedPrice: price?.formattedPrice ?? '',
});

const getPriceProperties = (properties?: DeepPartial<PriceProperty[]>) => {
  return properties?.length ? (properties as PriceProperty[]) : [];
};

const getBasicPrice = (price?: DeepPartial<Maybe<PriceApi>>): ProductBasicPrice => ({
  value: price?.value ?? 0,
  currency: price?.currency ?? '',
  formattedPrice: price?.formattedPrice ?? '',
  properties: getPriceProperties(price?.properties),
});

export const priceAdapter = (): PriceAdapter => ({
  getPrice,
  getBasicPrice,
});
