import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Divider, Typography } from '@mui/material';
import { RequiredNote } from '@components/UI';
import { GiftCardTemplate } from '@adapters/giftCardAdatper';
import { useLocalization } from '@hooks/useLocalization';
import { Values } from '../../types';
import { GiftCardAmountTiles } from '../AmountTiles/AmountTiles';
import { Controls } from '../Controls/Controls';
import { GiftCardTemplates } from '../Templates/Templates';
import { BulkControls } from '../BulkGiftCardForm/BulkControls/BulkControls';
import styles from './PurchaseView.module.scss';

interface PurchaseViewProps {
  templates: GiftCardTemplate[];
  amountOptions: string[];
  loadingTemplates: boolean;
  loadingAmountOptions: boolean;
  addBulkGiftCard?: boolean;
  onClickCustomAmount: () => void;
  setGiftCardError?: (arg: object) => void;
  isSinglePurchaseView?: boolean;
}

export const PurchaseView = ({
  templates,
  amountOptions,
  loadingTemplates,
  loadingAmountOptions,
  onClickCustomAmount,
  addBulkGiftCard,
  setGiftCardError,
  isSinglePurchaseView,
}: PurchaseViewProps) => {
  const { t } = useLocalization('giftCards');
  const { errors } = useFormikContext<Values>();

  useEffect(() => {
    setGiftCardError?.(errors);
  }, [errors]);

  return (
    <div>
      <GiftCardTemplates templates={templates} loadingTemplates={loadingTemplates} />
      <Divider className={styles.divider} />
      {isSinglePurchaseView && <RequiredNote optionalNote />}
      <Typography
        variant="subtitle1"
        component="h4"
        className={styles.subtitle}
        id="amount-tile-radio-container"
      >
        {t('giftCardModal.giftCardAmountSubtitle')}
      </Typography>
      <GiftCardAmountTiles
        amountOptions={amountOptions}
        loadingAmountOptions={loadingAmountOptions}
        onClickCustomAmount={onClickCustomAmount}
      />
      <Divider className={styles.divider} />
      {addBulkGiftCard ? <BulkControls /> : <Controls />}
    </div>
  );
};
