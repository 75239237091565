import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { COUPON_MODAL_LINK } from '@constants/cart';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import { useLoadHtml } from '@hooks/useLoadHtml/useLoadHtml';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './CouponModal.module.scss';

interface CouponModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CouponModal = ({ isOpen, onClose }: CouponModalProps) => {
  const { t } = useLocalization('cart');
  const htmlText = useLoadHtml(COUPON_MODAL_LINK);

  if (!htmlText) return null;

  return (
    <ModalWindow
      open={isOpen}
      className={styles.wrapper}
      onClose={onClose}
      labelId={MODAL_TITLE_ID.COUPON_INFO_MODAL}
    >
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: htmlText,
          }}
        />
        <Button onClick={onClose} size="large" isFullWidth>
          {t('buttons.okay')}
        </Button>
      </>
    </ModalWindow>
  );
};
