/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { logger } from '@logger';

export const useNavigationTracking = (label?: string | null) => {
  const trackNavigation = useCallback(() => {
    if (!label) {
      logger.warn('Navigation label was not provided for tracking!');
      return;
    }

    sendGtmEvent(
      GtmCustomEvent({
        event: 'header-nav',
        event_name: 'header_nav_click',
        ua_category: 'header-nav',
        ua_action: 'click',
        ua_label: label,
      }),
    );
  }, [label]);

  const onClick = useCallback(() => {
    trackNavigation();
  }, [trackNavigation]);

  const onKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') trackNavigation();
    },
    [trackNavigation],
  );

  return {
    onClick,
    onKeyDown,
  };
};
