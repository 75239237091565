import { Typography } from '@mui/material';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { ReservationInfo } from '@commons/timeslots';
import { openReservationTimeSlotsModal } from '@modules/modals/events/reservationTimeSlotsModal';
import styles from './ReservedTimeSlot.module.scss';

interface ReservedTimeSlotProps {
  reservationInfo: ReservationInfo;
}

export const ReservedTimeSlot = ({ reservationInfo }: ReservedTimeSlotProps) => {
  const { t } = useLocalization('account');
  const { address, isWeeklyReservation, dateTime, startDateDay } = reservationInfo;
  const hasReservation = isWeeklyReservation && !!startDateDay;
  const dateInfo = `${startDateDay}, ${dateTime}`;
  const apartmentText = address.apartment ? `${address.apartment},` : '';
  const addressInfo = `${address.address1}, ${apartmentText} ${address.city} ${address.state} ${address.zipCode}`;

  const renderTimeSlotInfo = () => {
    return (
      <>
        <Typography component="p" variant="body">
          {dateInfo}
        </Typography>
        <Typography
          component="p"
          variant="body"
          className={styles.time_slot_info}
          aria-label={t('delivery.card.ariaLabels.address')}
        >
          {addressInfo}
        </Typography>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography component="p" variant="smallBody" className={styles.title}>
          {t('deliveryPass.reservedTimeSlot')}
        </Typography>
        {hasReservation ? (
          renderTimeSlotInfo()
        ) : (
          <Typography component="p" variant="body" className={styles.time_slot_info}>
            {t('deliveryPass.noReserveTimeSlotText')}
          </Typography>
        )}
      </div>
      {hasReservation ? (
        <Button variant="underline" onClick={() => openReservationTimeSlotsModal({ isEdit: true })}>
          {t('deliveryPass.buttons.edit')}
        </Button>
      ) : (
        <Button variant="underline" onClick={() => openReservationTimeSlotsModal()}>
          {t('deliveryPass.reserveTimeSlot')}
        </Button>
      )}
    </div>
  );
};
