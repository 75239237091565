import { Skeleton, Typography } from '@mui/material';
import cx from 'classnames';
import styles from './PreviewBlock.module.scss';

export const PreviewBlockSkeleton = () => (
  <section className={styles.preview_block}>
    <Typography variant="h4" component="h1" className={cx(styles.spacing, styles.product_name)}>
      <Skeleton />
    </Typography>
    <div className={styles.product_details}>
      <div className={styles.product_price_and_quantity}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>
      <Skeleton variant="text" />
    </div>
  </section>
);
