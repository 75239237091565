import { Address } from '@commons/deliveryAddresses';

export enum PAYMENT_TYPE {
  VISA = 'Visa',
  MASTERCARD = 'Mastercard',
  AMEX = 'Amex',
  DISCOVER = 'Discover',
  NULL = 'NULL',
  ECHECK = 'ECheck',
  EBT = 'EBT',
  GIFT_CARD = 'GiftCard',
}

export enum PAYMENT_TABS {
  CREDIT_CARD = 0,
  BANK_ACCOUNT = 1,
  PAYPAL = 2,
  VENMO = 3,
  EBT = 4,
}

export enum EWALLET_PAYMENT_TYPE {
  PAYPAL = 'PayPal',
  VENMO = 'Venmo',
  NULL = 'NULL',
}

export type PaymentsType = EWALLET_PAYMENT_TYPE | PAYMENT_TYPE;

export enum EDIT_CARD_TYPE {
  CARDHOLDER = 'cardholder',
  CARD_NUMBER = 'card_number',
  EXPIRATION_MONTH = 'expiration_month',
  EXPIRATION_YEAR = 'expiration_year',
  CVC = 'cvc',
  BILLING_ADDRESS = 'billing_address',
  APARTMENT = 'apartment',
  COUNTRY = 'country',
  CITY = 'city',
  ZIP_CODE = 'zip_code',
  PHONE = 'phone',
}

export enum EDIT_BANK_ACCOUNT_TYPE {
  BANK_ACCOUNT_TYPE = 'bank_account_type',
  NAME = 'name',
  BANK_NAME = 'bank_name',
  ACCOUNT_NUMBER = 'account_number',
  VERIFY_ACCOUNT_NUMBER = 'verify_account_number',
  ROUTING_NUMBER = 'routing_number',
  BILLING_ADDRESS = 'billing_address',
  APARTMENT = 'apartment',
  COUNTRY = 'country',
  CITY = 'city',
  ZIP_CODE = 'zip_code',
  PHONE = 'phone',
}

export enum BANK_ACCOUNT_DROPDOWN_TYPE {
  C = 'Checking',
  S = 'Savings',
  X = 'Commercial Checking',
}

export interface PaymentGeneralInfo {
  id: string;
  email: string | null;
  canDelete: boolean;
  isDefault: boolean;
  isExpired: boolean;
  selected: boolean;
  title: string | null;
  type: PaymentsType;
  billingAddress: Address;
  paymentTypeDescription: string | null;
  referencedOrderId: string | null;
}

export interface PaymentLogos {
  mpLogoURL: string | null;
  ppLogoURL: string | null;
}

export interface PaymentEWalletInfo {
  eWalletID: string | null;
  vendorEWalletID: string | null;
}

export interface PaymentBankInfo {
  abaRouteNumber: string | null;
  accountNumber: string | null;
  bankAccountType: string | null;
  bankName: string | null;
  bestNumber: string | null;
  expiration: string | null;
  isDebit: boolean;
  nameOnCard: string | null;
}

export interface Payment
  extends PaymentGeneralInfo,
    PaymentLogos,
    PaymentEWalletInfo,
    PaymentBankInfo {}

export interface FullPayments {
  default: string | null;
  selected: string | null;
  defaultPayment: Payment | null;
  selectedPayment: Payment | null;
  payments: Payment[];
  hasPayments: boolean;
  hasPaypalWallet: boolean;
  hasVenmoWallet: boolean;
}

export interface PaymentAvailabilities {
  showBankAccount: boolean;
  showEbt: boolean;
}
