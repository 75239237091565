import { gql, HttpLink } from '@apollo/client';
import { logger } from '@logger';
import { getGQLOperationNameFromDocumentNode } from '@graphql/links/utils';
import { isClient } from '@utils/isClient';

export const httpLink = () =>
  new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    credentials: 'include',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    fetch: customFetch,
  });

function customFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  let body;

  if (typeof init?.body !== 'string') {
    logger.error('body is not a string');
  } else {
    try {
      body = JSON.parse(init.body);
    } catch (e) {
      logger.error('impossible to parse body');
    }
  }

  const operationName = getGQLOperationNameFromDocumentNode(
    gql`
      ${body.query}
    `,
  );

  const headers = init?.headers
    ? {
        ...init.headers,
        gqloperation: operationName,
      }
    : {};

  if (!isClient()) {
    logger.debug(
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        id: (init?.headers as any)?.id,
        source: 'server',
        headers,
        body,
      },
      `[GQL REQUEST] Operation "${operationName}"`,
    );
  }

  return fetch(input, { ...init, headers });
}
