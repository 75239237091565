import { CartLine, Cart, CartLight } from '@commons/cart';

export const getCartLineByProductId = (cart: Cart, productId: string): CartLine | undefined => {
  return cart.cartLinesList.find(({ product }) => {
    return product.productId === productId;
  });
};

export const getCartLinesByProductId = (cart: Cart, productId: string): CartLine[] => {
  return cart.cartLinesList.filter(({ product }) => {
    return product.productId === productId;
  });
};

export const getCartLineByLineId = (cart: Cart, lineId: string): CartLine | undefined => {
  return cart.cartLinesList.find(({ id }) => id === lineId);
};

export const getLightCartLineById = (cart: CartLight, lineId: string) =>
  cart.cartLines.find((cartLine) => cartLine.id === lineId);

export const getLightCartLineByProductId = (cart: CartLight, productId: string) =>
  cart.cartLines.find((cartLine) => cartLine.productId === productId);

export const getLightCartLinesByProductId = (cart: CartLight, productId: string) =>
  cart.cartLines.filter((cartLine) => cartLine.productId === productId);
