import { makeVar, ReactiveVar } from '@apollo/client';

const showPendingOrdersModalVar: ReactiveVar<boolean> = makeVar<boolean>(false);

export const getShowPendingOrdersModalVar = () => showPendingOrdersModalVar;

export const setShowPendingOrdersModalVar = (show: boolean) => {
  showPendingOrdersModalVar(show);
};

export const shouldAddToCartVar: ReactiveVar<boolean> = makeVar<boolean>(false);

export const productToAddToCartVar = makeVar({});

const reorderErrMsgVar: ReactiveVar<[string]> = makeVar<[string]>(['']);

export const getReorderErrMsgVar = () => reorderErrMsgVar;

export const setReorderErrMsgVar = (errs: [string]) => {
  reorderErrMsgVar(errs);
};

const reorderIndexVar: ReactiveVar<number | undefined> = makeVar<number | undefined>(undefined);

export const getReorderIndexVar = () => reorderIndexVar;

export const setReorderIndexVar = (index?: number) => {
  reorderIndexVar(index);
};
