import { StoredCartLine } from '@utils/storageService';

export const getItemListName = (
  cartLines: StoredCartLine[] | null | undefined,
  cartLineId: string,
): string | undefined => cartLines?.find((item) => item.id === cartLineId)?.itemListName;

export const getItemPosition = (
  cartLines: StoredCartLine[] | null | undefined,
  cartLineId: string,
): number | undefined => cartLines?.find((item) => item.id === cartLineId)?.itemPosition;
