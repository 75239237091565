import { Typography } from '@mui/material';
import cx from 'classnames';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { PaymentsType } from '@commons/payment';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './PaymentDeleteModal.module.scss';

interface PaymentDeleteModalProps {
  onClose: () => void;
  onDelete: () => void;
  opened: boolean;
  isEWallet: boolean;
  type?: PaymentsType | string;
  cardNumber?: string | null;
  loading?: boolean;
}

export const PaymentDeleteModal = ({
  onClose,
  onDelete,
  opened = false,
  cardNumber,
  type,
  isEWallet,
  loading,
}: PaymentDeleteModalProps) => {
  const { t } = useLocalization();
  const deleteButtonText = isEWallet
    ? t('deleteModal.unlinkPayment', { type })
    : t('deleteModal.deletePayment', { cardType: type, cardNumber });

  return (
    <ModalWindow
      className={styles.modal}
      onClose={onClose}
      open={opened}
      labelId={MODAL_TITLE_ID.PAYMENT_DELETE}
    >
      <div className={styles.modal_wrapper}>
        <Typography
          variant="h3"
          component="h2"
          className={styles.heading}
          id={MODAL_TITLE_ID.PAYMENT_DELETE}
        >
          {t('deleteModal.areYouSure')}
        </Typography>
        <Typography variant="smallBody" className={styles.info_text}>
          {t('deleteModal.deleteInfo')}
        </Typography>
        <Button
          className={cx(styles.button, styles.button_close)}
          onClick={onClose}
          variant="contained"
          size="large"
          loading={loading}
          disabled={loading}
        >
          {t('deleteModal.keepPayment')}
        </Button>
        <Button
          className={styles.button}
          onClick={onDelete}
          variant="outlined"
          size="large"
          loading={loading}
          disabled={loading}
        >
          {deleteButtonText}
        </Button>
      </div>
    </ModalWindow>
  );
};
