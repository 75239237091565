import { useMutation, MutationHookOptions } from '@apollo/client';
import { DELIVERY_PASS_PLANS } from '@graphql/deliveryPass/queries/deliveryPass';
import { ACTIVATE_FREE_TRIAL_DELIVERY_PASS } from '@graphql/deliveryPass/mutations/activateFreeTrialDeliveryPass';
import { DELIVERY_PASS_INFO } from '@graphql/deliveryPass/queries/deliveryPassInfo';
import { GET_CART } from '@graphql/cart';
import { USER_DETAILS } from '@graphql/common/queries/userDetails';

export const useActivateFreeTrialDeliveryPassAtCheckout = (options: MutationHookOptions) => {
  return useMutation(ACTIVATE_FREE_TRIAL_DELIVERY_PASS, {
    ...options,
    refetchQueries: [DELIVERY_PASS_INFO, DELIVERY_PASS_PLANS, GET_CART, USER_DETAILS],
  });
};
