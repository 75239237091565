import { ApolloCache } from '@apollo/client';
import { AccountPreferences } from '@api';

export const updateAccountPreferencesNotificationsCache = (
  cache: ApolloCache<unknown>,
  updatedNotifications: Partial<AccountPreferences['notifications']>,
) => {
  cache.modify({
    fields: {
      accountPreferences(preferences) {
        return {
          ...preferences,
          notifications: {
            ...preferences.notifications,
            ...updatedNotifications,
          },
        };
      },
    },
  });
};
