import cx from 'classnames';
// Basically, swiper dev doesn't want to follow some best practices enabling eslint support.
// Refer to https://github.com/nolimits4web/swiper/issues/5300 for more info. For now, we shall just do:
import { useSwiper } from 'swiper/react';
import { arrowLeft as ArrowLeft, arrowRight as ArrowRight } from '@assets/icons/system';
import { IconButton } from '@components/UI';
import styles from './ProductCarouselNavigation.module.scss';

interface ProductCarouselNavigationProps {
  className: string;
  isEnd: boolean;
  isStart: boolean;
  name: string;
}

export const ProductCarouselNavigation = ({
  className,
  isEnd,
  isStart,
  name,
  ...restProps
}: ProductCarouselNavigationProps) => {
  const swiper = useSwiper();
  const formattedName = name ? name + ' ' : '';
  return (
    <div className={cx(styles.navigation, className)} {...restProps}>
      <IconButton
        aria-label={`display previous ${formattedName}slide`}
        onClick={() => swiper.slidePrev()}
        onMouseDown={(event) => event.preventDefault()}
        className={cx(styles.left_arrow, styles.nav_icon)}
        disabled={isStart}
      >
        <ArrowLeft width={12} height={24} className={styles.arrow_icon} />
      </IconButton>
      <IconButton
        onClick={() => swiper.slideNext()}
        onMouseDown={(event) => event.preventDefault()}
        className={cx(styles.right_arrow, styles.nav_icon)}
        aria-label={`display next ${formattedName}slide`}
        disabled={isEnd}
      >
        <ArrowRight width={12} height={24} className={styles.arrow_icon} />
      </IconButton>
    </div>
  );
};
