import { useEffect, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useSettings } from '@hooks/useSettings/useSettings';
import { useFeatureEligibilityQuery } from '@hooks/common/useFeatureEligibility';
import {
  disableFeature,
  enableFeature,
  isFeatureEnabled,
  resetAllFeatures,
  updateFromSettings,
  updateFeaturesFromEligibilities,
  DYNAMIC_FEATURES,
} from '@utils/dynamicFeatures';
import { DynamicFeatureContext } from './DynamicFeatureContext';

interface DynamicFeatureProviderProps {
  children: ReactNode;
}

export const DynamicFeatureProvider = ({ children }: DynamicFeatureProviderProps) => {
  const {
    query: { enable, disable, resetFeatures },
  } = useRouter();
  const { data: settings, loading: settingsLoading } = useSettings();
  const { data: eligibility, loading: eligibilityLoading } = useFeatureEligibilityQuery();
  const loading = settingsLoading || eligibilityLoading;

  useEffect(() => {
    if (settings && !settingsLoading) {
      updateFromSettings(settings);
    }
  }, [settings, settingsLoading]);

  useEffect(() => {
    if (eligibility && !eligibilityLoading) {
      updateFeaturesFromEligibilities(eligibility?.featureEligibilities ?? []);
    }
  }, [eligibility, eligibilityLoading]);

  useEffect(() => {
    if (enable) {
      (enable as string).split('|').forEach((feature) => {
        const [featureName, params] = feature.split(':');
        const options = params
          ? Object.fromEntries(params.split(',').map((param) => param.split('=')))
          : {};
        if (Object.values(DYNAMIC_FEATURES).includes(featureName as DYNAMIC_FEATURES)) {
          enableFeature(featureName as DYNAMIC_FEATURES, options);
        }
      });
    }
    if (disable) {
      (disable as string).split('|').forEach((feature) => {
        if (Object.values(DYNAMIC_FEATURES).includes(feature as DYNAMIC_FEATURES)) {
          disableFeature(feature as DYNAMIC_FEATURES);
        }
      });
    }
    if (resetFeatures) {
      resetAllFeatures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DynamicFeatureContext.Provider
      value={{
        isFeatureEnabled,
        enableFeature,
        disableFeature,
        resetAllFeatures,
        loading,
      }}
    >
      {children}
    </DynamicFeatureContext.Provider>
  );
};
