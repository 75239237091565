import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { cartAdapter } from '@adapters/cartAdapter';

const { getLightCart } = cartAdapter();

export const useCartProductCounterLight = (options?: QueryHookOptions) => {
  const { data, ...rest } = useQuery(GET_LIGHT_CART, {
    ssr: false,
    ...options,
  });

  return { data: getLightCart(data?.lightweightCart), ...rest };
};
