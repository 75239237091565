import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import cx from 'classnames';
import Image from 'next/image';
import { Popper, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { reorder as ReorderIcon } from '@assets/icons/informational/with-spacing';
import { arrowDown as ArrowDown, arrowUp as ArrowUp } from '@assets/icons/system';
import { routing } from '@constants/routing';
import { dealsAnonSubmenu, dealsSubmenu } from '@constants/dealsSubmenu';
import { useLocalization } from '@hooks/useLocalization';
import { useAuthContext, hasPastOrders } from '@modules/auth/context';
import { NavigationData } from '@hooks/header/useNavigationData';
import { NavigationMenuItem, DepartmentUnion, DropdownMenu } from '@api';
import { BaseLink } from '@components/UI';
import { DropdownMenuContent } from '../DropdownMenuContent/DropdownMenuContent';
import { isSuperDepartmentItem } from '../../../../isSuperDepartmentItem';
import { CategoriesSection } from '../CategoriesSection';
import { NavTabs } from '../NavTabs/NavTabs';
import { useGlobalNavKeyboardNavigation } from './useGlobalNavKeyboardNavigation';
import styles from './GlobalNav.module.scss';

interface NavigationProps {
  navigationData: NavigationData;
}

const DEBOUNCE_VALUE = 300;

const ICONS = {
  [routing.reorderItems]: <ReorderIcon />,
  [routing.shoppingLists]: <ReorderIcon />,
};

const REORDER_ID = 'quickshop';

const getIcon = (link?: string | null, icon?: string | null) => {
  return (
    ICONS[link || ''] || (
      <Image src={icon || ''} className={styles.nav_item_icon} alt="" width={42} height={42} />
    )
  );
};

const getMenuItem = (navItem: NavigationMenuItem, index: number, isActive?: boolean) => {
  const tabIndex = index === 0 ? 0 : -1;
  const link = navItem.link?.trim();
  return link ? (
    <BaseLink href={link}>
      <a
        className={styles.nav_item_link}
        role="menuitem"
        tabIndex={tabIndex}
        data-testid={navItem.id}
      >
        <Typography
          className={styles.nav_item_link_text}
          width="100%"
          textAlign="center"
          variant="body"
        >
          {navItem?.name}
        </Typography>
        {isActive && <span className="sr_only">Selected</span>}
      </a>
    </BaseLink>
  ) : (
    <Typography
      className={styles.nav_item_nolink_text}
      width="100%"
      textAlign="center"
      variant="body"
    >
      {navItem?.name}
    </Typography>
  );
};

const getDepartment = (
  departments: Record<string, DepartmentUnion | null | undefined>,
  id?: string | null,
) => (id && departments[id] ? departments[id] : undefined);

const getDropdownMenu = (
  additionalMenus: Array<DropdownMenu | null>,
  linkId: string | null | undefined,
  link: string | null | undefined,
  isKnownUser?: boolean,
) => {
  // TUE-12489: hardcode dropdown menu for deals, web only
  if (link === routing.dealsAndSale) {
    return isKnownUser ? dealsSubmenu : dealsAnonSubmenu;
  }

  const dropdownMenu = additionalMenus?.find((menu) => menu?.id === linkId);
  return dropdownMenu ?? null;
};

export const GlobalNav = ({
  navigationData: { primaryNavigation, departments, additionalMenus },
}: NavigationProps) => {
  const { t } = useLocalization('header');
  const { isKnownUser, userInfo } = useAuthContext();
  const userHasPastOrders = hasPastOrders(userInfo);
  const navListRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { asPath, push: goToPage } = useRouter();

  const isActive = useCallback(
    (navItem: NavigationMenuItem) => {
      // exact link match
      if (navItem.link?.trim() === asPath) {
        return true;
      }

      // check first part of the path
      const pathStart = asPath.split('/')[1];

      return pathStart === navItem.id;
    },
    [asPath],
  );

  const navItems = (primaryNavigation ?? []).filter(
    (item) => item?.link || item?.linkId,
  ) as NavigationMenuItem[];

  const reorderNavItem: NavigationMenuItem = {
    name: userHasPastOrders ? t('reorderList') : t('shoppingLists'),
    link: userHasPastOrders ? routing.reorderItems : routing.shoppingLists,
    id: REORDER_ID,
  };
  if (isKnownUser) {
    navItems.push(reorderNavItem);
  }

  const getNavClass = (navItem: NavigationMenuItem) => {
    return cx(styles.nav_item, {
      [styles.active]: isActive(navItem),
      [styles.reorder]: navItem.id === REORDER_ID,
    });
  };

  const [dDIndex, setDDIndex] = useState(0);
  const [department, setDepartment] = useState<DepartmentUnion | null>(null);
  const [dropDownMenu, setDropDownMenu] = useState<DropdownMenu | null>(null);
  const { isPopoverOpen, setIsPopoverOpen } = useGlobalNavKeyboardNavigation({
    navListRef,
    popoverRef,
  });

  const getCurrentNavItem = useCallback(() => {
    return navListRef.current?.querySelector(`[data-key="${dDIndex}"]`);
  }, [dDIndex]);

  const activateDDContent = useCallback(
    (index: number) => {
      setDDIndex(index);
      const navItem = navItems[index];
      const adepartment = getDepartment(departments, navItem?.id);
      const adropDownMenu = getDropdownMenu(
        additionalMenus,
        navItem?.linkId,
        navItem?.link?.trim(),
        isKnownUser,
      );

      if (adepartment) {
        setDepartment(adepartment);
        setDropDownMenu(null);
        setIsPopoverOpen(true);
      } else if (adropDownMenu) {
        setDepartment(null);
        setDropDownMenu(adropDownMenu);
        setIsPopoverOpen(true);
      } else {
        setIsPopoverOpen(false);
      }
    },
    [additionalMenus, departments, navItems, setIsPopoverOpen, isKnownUser],
  );

  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prev) => !prev);
  }, [setIsPopoverOpen]);

  const handleMenuButton = useCallback(
    (index: number) => {
      if (dDIndex === index) {
        togglePopover();
      } else {
        activateDDContent(index);
      }
    },
    [activateDDContent, dDIndex, togglePopover],
  );

  const handleMouseEnter = () => {
    debouncedHandleMouseLeave.cancel();
  };

  const handleMouseLeave = () => {
    setIsPopoverOpen(false);
  };

  const debouncedHandleMouseLeave = useMemo(
    () => debounce(handleMouseLeave, DEBOUNCE_VALUE),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [DEBOUNCE_VALUE],
  );

  useEffect(() => {
    return () => {
      debouncedHandleMouseLeave.cancel();
    };
  }, [debouncedHandleMouseLeave]);

  return (
    <nav className={styles.navigation} data-testid="header-navigation">
      <ul
        role="menubar"
        className={styles.navbar}
        ref={navListRef}
        data-testid="nav-panel"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={debouncedHandleMouseLeave}
      >
        {navItems.map((navItem, index) => {
          const link = navItem.link?.trim();
          return (
            <li
              key={index}
              data-key={index}
              data-testid="primary-nav-item"
              className={getNavClass(navItem)}
              role="none"
              onMouseEnter={() => activateDDContent(index)}
              onClick={() => link && goToPage(link)}
            >
              <div className={styles.nav_item_icon}>{getIcon(link, navItem.icon)}</div>
              <div className={styles.nav_item_controls}>
                {getMenuItem(navItem, index, isActive(navItem))}
                {(getDepartment(departments, navItem.id) ||
                  getDropdownMenu(additionalMenus, navItem.linkId, link, isKnownUser)) && (
                  <button
                    className={styles.nav_item_caret}
                    type="button"
                    role="menuitem"
                    aria-haspopup="true"
                    aria-label={t('openDropdownMenu', { name: navItem.name })}
                    aria-expanded={isPopoverOpen && dDIndex === index}
                    tabIndex={-1}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMenuButton(index);
                    }}
                  >
                    {isPopoverOpen && dDIndex === index ? <ArrowUp /> : <ArrowDown />}
                  </button>
                )}
              </div>
            </li>
          );
        })}
        {department && (
          <Popper
            ref={popoverRef}
            id={department.id}
            open={isPopoverOpen}
            anchorEl={navListRef.current}
            placement="bottom-start"
            className={styles.nav_dropdown}
            disablePortal
            data-testid="big-dropdown-menu"
          >
            {isSuperDepartmentItem(department) ? (
              <NavTabs superDepartment={department} />
            ) : (
              <CategoriesSection showHeadlines department={department} />
            )}
          </Popper>
        )}
        {dropDownMenu && (
          <Popper
            ref={popoverRef}
            id={dropDownMenu.id}
            open={isPopoverOpen}
            anchorEl={getCurrentNavItem()}
            placement="bottom"
            className={styles.nav_dropdown_menu}
            disablePortal
            data-testid="dropdown-menu"
          >
            <DropdownMenuContent menu={dropDownMenu} />
          </Popper>
        )}
      </ul>
    </nav>
  );
};
