import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { AddToShoppingListModalProps } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';

export const openAddToShoppingListModal = (props: AddToShoppingListModalProps) =>
  fireOpenModal(MODALS_LIST.ADD_TO_SHOPPING_LIST_MODAL, props);

export const closeAddToShoppingListModal = () =>
  fireCloseModal(MODALS_LIST.ADD_TO_SHOPPING_LIST_MODAL);
