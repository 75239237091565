import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const getCaptchaToken = (action?: string): Promise<string> => {
  const key = publicRuntimeConfig.recaptureKey;

  return new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const grecaptcha = (window as any).grecaptcha;
    if (!grecaptcha) {
      resolve('CAPTCHA_NOT_LOADED_TOKEN');
    }
    // create a timeout to prevent the user from waiting forever
    setTimeout(() => {
      resolve('CAPTCHA_TIMEOUT_TOKEN');
    }, 10000);
    grecaptcha.enterprise.ready(() => {
      grecaptcha.enterprise
        .execute(key, { action: action || 'submit' })
        .then((tokenCaptcha: string) => {
          resolve(tokenCaptcha);
        })
        .catch(() => {
          // TODO: after adding error handler, update that
          throw Error('[Captcha] Something went wrong with captcha');
        });
    });
  });
};
