import { ReactNode, createContext, useContext, useState } from 'react';
import { useStandingOrdersAvailability } from '@hooks/standingOrders/useStandingOrdersAvailability';

interface MainLayoutContextProps {
  isShowingHeaderAlert: boolean;
  isLoadingHeaderAlert: boolean;
  handleIsShowingHeaderAlert?: (isShowingAlert: boolean) => void;
  handleIsLoadingHeaderAlert?: (isLoadingAlert: boolean) => void;
  isStandingOrderAvailable?: boolean;
  hasValidStandingOrders?: boolean;
  isStandingOrderEligible?: boolean;
  isStandingOrderVisible?: boolean;
  isStandingOrderLoading?: boolean;
}

interface MainLayoutProviderProps {
  children: ReactNode;
}

export const MainLayoutChildrenContext = createContext<MainLayoutContextProps>({
  isShowingHeaderAlert: false,
  isLoadingHeaderAlert: false,
});

export const useMainLayoutContext = () => {
  const ctx = useContext(MainLayoutChildrenContext);

  if (ctx === undefined) {
    throw new Error('useMainLayoutContext must be used within a MainLayoutProvider');
  }

  return ctx;
};

export const MainLayoutProvider = ({ children }: MainLayoutProviderProps) => {
  const [isShowingHeaderAlert, setIsShowingHeaderAlert] = useState(false);
  const [isLoadingHeaderAlert, setIsLoadingHeaderAlert] = useState(false);
  const {
    isStandingOrderEligible,
    isStandingOrderVisible,
    isStandingOrderLoading,
    hasValidStandingOrders,
    isStandingOrderAvailable,
  } = useStandingOrdersAvailability();

  const handleIsShowingHeaderAlert = (isShowingAlert: boolean) => {
    setIsShowingHeaderAlert(isShowingAlert);
  };

  const handleIsLoadingHeaderAlert = (isLoadingAlert: boolean) => {
    setIsLoadingHeaderAlert(isLoadingAlert);
  };

  return (
    <MainLayoutChildrenContext.Provider
      value={{
        isShowingHeaderAlert,
        isLoadingHeaderAlert,
        handleIsShowingHeaderAlert,
        handleIsLoadingHeaderAlert,
        isStandingOrderAvailable,
        hasValidStandingOrders,
        isStandingOrderEligible,
        isStandingOrderVisible,
        isStandingOrderLoading,
      }}
    >
      {children}
    </MainLayoutChildrenContext.Provider>
  );
};
