import { useEffect, useState } from 'react';
import { ModalsFactory } from '@modules/modals/components/ModalsFactory';
import { on } from '@modules/emitter';
import { MODAL_EVENT, MODAL_OPEN, ModalEventAction } from '@modules/modals/types/core';
import { ModalProps } from '@modules/modals/types/modalProps';

export const ModalsManager = () => {
  const [modal, setModal] = useState<ModalProps>();

  useEffect(() => {
    const emitter = on((action) => {
      if (action.type === MODAL_EVENT) {
        const actionMessage = action.message as ModalEventAction;
        if (actionMessage === MODAL_OPEN) {
          setModal({
            type: action.data.modalType,
            data: action.data.payload,
          });
          return;
        }
        setModal(undefined);
      }
    });

    return emitter;
  });

  return modal ? <ModalsFactory data={modal} /> : null;
};
