import { gql } from '@apollo/client';

export const PRICE_FRAGMENT = gql`
  fragment priceFragment on Price {
    currency
    value
    formattedPrice
    properties {
      type
      value
    }
  }
`;
