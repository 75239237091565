import { isExistPageViewInDataLayer } from '@modules/ga/utils/isExistPageViewInDataLayer';

export interface PGtmEvent {}

export interface IGtmEvent {}

export function GtmEvent(p: PGtmEvent): IGtmEvent {
  return {};
}

const buffer: IGtmEvent[] = [];

export const clearDataLayerBuffer = () => (buffer.length = 0);

const shouldPushEventToBuffer = (event: string) =>
  !isExistPageViewInDataLayer() && event !== 'custom_pageview';

const pushBufferToDataLayer = () => {
  while (buffer.length) {
    window.dataLayer.push(buffer.shift());
  }
};

export const clearDataLayer = () => {
  window.dataLayer.push(function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.reset();
  });
  window.dataLayer.length = 0;

  clearDataLayerBuffer();
};

export function sendGtmEvent(event: IGtmEvent): void {
  window.dataLayer = window.dataLayer || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (shouldPushEventToBuffer((event as any)?.event)) {
    buffer.push(event);
    return;
  }

  window.dataLayer.push(event);
  pushBufferToDataLayer();
}
