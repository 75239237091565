import { ServerResponse } from 'http';
import { IMiddlewareFilter } from './middleware-filter';
import { MiddlewareFilterScope } from './middleware-filter-scope';

const regionIdSearchParamKey = 'region_id';

export const regionIdHeaderKey = 'X-REGION-ID';

export const getRegionId = (res?: ServerResponse) => {
  return res?.getHeader(regionIdHeaderKey) as string;
};

export const applyRegionId: IMiddlewareFilter = async (oldScope) => {
  const requestUrl = new URL(oldScope.requestUrl);
  const regionId = requestUrl.searchParams.get(regionIdSearchParamKey);
  const responseHeaders = new Headers(oldScope.responseHeaders);
  if (regionId) {
    responseHeaders.set(regionIdHeaderKey, regionId);
  }
  return MiddlewareFilterScope({ ...oldScope, requestUrl, responseHeaders });
};
