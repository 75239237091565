import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const GET_GIFT_CARDS = gql`
  query GiftCards($showAllReceived: Boolean = false, $showAllPurchased: Boolean = false) {
    giftCards(showAllReceived: $showAllReceived, showAllPurchased: $showAllPurchased) {
      purchasedCards {
        recipientEmail
        recipientName
        personalMessage
        certificateNumber
        formattedDate
        status
        saleId
        formattedAmount
        template {
          gcTemplateId
          gcTemplateLabel
          gcTemplateTitle
          gcTemplateImageLink
        }
      }
      receivedCards {
        cards {
          id
          senderName
          purchaseDate
          applied
          initialAmount {
            ...priceFragment
          }
          currentAmount {
            ...priceFragment
          }
          redeemable
          applied
        }
        totalCardsCount
        totalAmount {
          ...priceFragment
        }
      }
    }
  }
  ${PRICE_FRAGMENT}
`;
