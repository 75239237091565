import { OtpEnabledLoginType } from '@api';

interface OtpLoginTypes {
  isPasswordLogin: boolean;
  isEmailOtpLogin: boolean;
  isPhoneOtpLogin: boolean;
}

export const getOtpLoginTypes = (otpEnabledLoginType: OtpEnabledLoginType): OtpLoginTypes => {
  const emailOtpTypes = [
    OtpEnabledLoginType.MIXED_EMAIL,
    OtpEnabledLoginType.MIXED,
    OtpEnabledLoginType.OTP_EMAIL,
    OtpEnabledLoginType.OTP,
  ];

  const phoneOtpTypes = [
    OtpEnabledLoginType.MIXED_PHONE,
    OtpEnabledLoginType.MIXED,
    OtpEnabledLoginType.OTP_PHONE,
    OtpEnabledLoginType.OTP,
  ];

  return {
    isPasswordLogin: otpEnabledLoginType === OtpEnabledLoginType.NONE,
    isEmailOtpLogin: emailOtpTypes.includes(otpEnabledLoginType),
    isPhoneOtpLogin: phoneOtpTypes.includes(otpEnabledLoginType),
  };
};
