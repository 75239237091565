import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '../../fragments/account/actionResult';

export const UPDATE_CREDIT_REFUND_EVENT = gql`
  mutation UpdateCreditRefundEvent($input: StoreCreditRefundInput!) {
    updateCreditRefundEvent(input: $input) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
