import { Router } from 'next/router';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { setCookie } from 'cookies-next';
import { INIT_MASQUERADE_MODE } from '@graphql/masquerade/mutations/initMasqueradeMode';
import { getInitMasqueradeModeInput } from '@adapters/masqueradeAdapter';
import { MasqueradeQueryParams } from '@modules/masquerade-query-params';
import { initModifyOrder, shouldInitModifyOrder } from '@utils/initModifyOrder';
import {
  updateAccountPreferecesQuery,
  updateMinimumAccountPreferecesQuery,
} from '@hooks/account/updateAccountPreferencesCache';
import { updateMasqueradeBarDataQuery } from '@hooks/masquerade/updateMasqueradeBarDataCache';
import { COOKIES_LIST } from '@commons/cookies';

interface ModifyModeProps {
  client: ApolloClient<NormalizedCacheObject>;
  redirectUrl: string;
  isExpress: boolean;
}

const performPostInitActions = async ({ redirectUrl, isExpress, client }: ModifyModeProps) => {
  setCookie(COOKIES_LIST.IS_EXPRESS, isExpress);

  if (shouldInitModifyOrder(redirectUrl)) {
    await initModifyOrder(client, redirectUrl);
  }
};

interface InitMasqueradeModeProps {
  client: ApolloClient<NormalizedCacheObject>;
  router: Router;
  masqueradeModeData: MasqueradeQueryParams;
}

export const initMasqueradeMode = async ({
  client,
  router,
  masqueradeModeData,
}: InitMasqueradeModeProps) => {
  const result = await client.mutate({
    mutation: INIT_MASQUERADE_MODE,
    variables: { input: getInitMasqueradeModeInput(masqueradeModeData) },
    update(cache, { data: { initMasqueradeMode: masqueradeMode } }) {
      updateMinimumAccountPreferecesQuery(client, masqueradeMode.accountPreferences);
      updateAccountPreferecesQuery(client, masqueradeMode.accountPreferences);
      updateMasqueradeBarDataQuery(client, masqueradeMode.masqueradeBarData);
    },
  });
  const redirectUrl = result.data.initMasqueradeMode.redirectUrl ?? '';
  const isExpress = result.data.initMasqueradeMode.isExpressAddOnOrder ?? false;

  await performPostInitActions({ redirectUrl, client, isExpress });

  router.push(redirectUrl);
};
