import { LegalOverlay } from '@components/Modals/LegalOverlay/LegalOverlay';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { CART_MODAL_TYPES } from '@components/OrderFooter/types';
import styles from './GiftCardTermsModal.module.scss';

interface GiftCardTermsModalProps {
  opened: boolean;
  onClose: () => void;
}

export const GiftCardTermsModal = ({ opened, onClose }: GiftCardTermsModalProps) => {
  return (
    <ModalWindow open={opened} onClose={onClose} className={styles.modal_wrapper}>
      <LegalOverlay type={CART_MODAL_TYPES.GIFT_CARD_TERMS_AND_CONDITIONS} handleClose={onClose} />
    </ModalWindow>
  );
};
