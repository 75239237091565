import { CircularProgress, Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './ModifyOrderPollingModal.module.scss';

interface ModifyOrderPollingModalProps {
  onClose: () => void;
  opened: boolean;
}

export const ModifyOrderPollingModal = ({ onClose, opened }: ModifyOrderPollingModalProps) => {
  const { t } = useLocalization();

  return (
    <ModalWindow
      className={styles.modal}
      onClose={onClose}
      open={opened}
      labelId={MODAL_TITLE_ID.MODIFY_ORDER_POLLING}
    >
      <>
        <Typography
          variant="h3"
          component="h2"
          className={styles.title}
          id={MODAL_TITLE_ID.MODIFY_ORDER_POLLING}
        >
          {t('modifyingModal.pollingModal.title')}
        </Typography>
        <Typography variant="body" component="p" className={styles.spinner}>
          <CircularProgress size={64} className={styles.loading_icon} />
        </Typography>
        <Typography variant="body" component="p" className={styles.subtitle}>
          {t('modifyingModal.pollingModal.subtitle')}
        </Typography>
        <Button onClick={onClose} isFullWidth>
          {t('modifyingModal.pollingModal.cancel')}
        </Button>
      </>
    </ModalWindow>
  );
};
