import { useRouter } from 'next/router';
import { useUpdateEffect } from 'usehooks-ts';
import { useReactiveVar } from '@apollo/client';
import { ProductRangePrice } from '@commons/product';
import {
  isGroupScaleModalOpenVar,
  groupScaleModalIdVar,
} from '@graphql/variables/isGroupScaleModalOpenVar';
import { GroupScaleModal } from './components/GroupScaleModal/GroupScaleModal';
import { GroupScaleLabel } from './components/GroupScaleLabel/GroupScaleLabel';

interface GroupScaleProps {
  children?: never;
  className?: string;
  grpDescription?: string | null;
  grpPrice?: string | null;
  grpPrices?: ProductRangePrice[] | null;
  grpId: string | null;
  version: string | null;
  size?: 'medium' | 'large';
  id?: string;
  isV2?: boolean;
}

export const GroupScale = ({
  className,
  grpDescription,
  grpId,
  grpPrice,
  grpPrices,
  version,
  size,
  id,
  isV2 = false,
}: GroupScaleProps) => {
  const router = useRouter();
  const isOpen = useReactiveVar(isGroupScaleModalOpenVar);
  const currentGroupScaleId = useReactiveVar(groupScaleModalIdVar);
  const shouldModalOpen = !!id ? isOpen && currentGroupScaleId === id : isOpen;
  const isDataReady = !!grpDescription && !!grpId && !!grpPrice && !!version;
  const groupScaleMessage = 'Any ' + grpPrice;
  const scaleCount = grpPrices ? grpPrices[0]?.scaleLowerBound : null;

  useUpdateEffect(() => {
    isGroupScaleModalOpenVar(false);
    groupScaleModalIdVar('');
  }, [router.query.id]);

  if (!isDataReady) {
    return null;
  }

  const handleModalClose = () => {
    isGroupScaleModalOpenVar(false);
    groupScaleModalIdVar('');
  };

  const handleModalOpen = (e?: { currentTarget: { id: string | undefined } }) => {
    if (!!id && e?.currentTarget.id === id) {
      groupScaleModalIdVar(id);
    }

    isGroupScaleModalOpenVar(true);
  };

  return (
    <>
      <GroupScaleLabel
        message={groupScaleMessage}
        isActive={shouldModalOpen}
        onClick={handleModalOpen}
        size={size}
        className={className}
        id={id}
        isV2={isV2}
      />
      {shouldModalOpen && (
        <GroupScaleModal
          open={shouldModalOpen}
          onClose={handleModalClose}
          grpDescription={grpDescription}
          scaleCount={scaleCount}
          grpId={grpId}
          grpVersion={version}
        />
      )}
    </>
  );
};
