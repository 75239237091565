import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import styles from './CopyRight.module.scss';

interface CopyRightProps {
  fixed?: boolean;
}

export const CopyRight = ({ fixed }: CopyRightProps) => {
  const { t } = useLocalization('footer');
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Typography
      variant="smallBody"
      className={cx(styles.subtitle, styles.with_margin, {
        [styles.fixed_copyright]: fixed,
      })}
    >
      <span>{t('copyRight', { year })}</span>
      <span className={styles.left_margin}>{t('allRights')}</span>
    </Typography>
  );
};
