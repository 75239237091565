import cx from 'classnames';
import { Typography } from '@mui/material';
import { getCurrencySymbol } from '@utils/productHelper';
import { ProductPrice } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import styles from './TilePrice.module.scss';

interface TilePriceProps {
  children?: never;
  className?: string;
  formattedCurrentPrice: string;
  currentPrice: ProductPrice;
  scaleUnit: string | null;
  prevPrice: ProductPrice;
  minValueToOrder: number;
  isShowPercentage?: boolean;
  savingString?: string;
  discount?: number;
  freeItem?: boolean;
  isV2?: boolean;
}

export const TilePrice = ({
  className,
  formattedCurrentPrice,
  prevPrice,
  savingString,
  minValueToOrder = 1,
  discount,
  isShowPercentage = true,
  freeItem = false,
  isV2 = false,
}: TilePriceProps) => {
  const { t } = useLocalization('product');
  const savingStringComponent = discount ? 'div' : 'span';

  if (freeItem) {
    return (
      <div className={cx(styles.tile_price, className, { [styles.v2]: isV2 })}>
        <span
          className={styles.tile_price_free}
          data-testid="Tile price current"
          data-qa="tile_product_price"
        >
          {t('common:freeProduct')}
        </span>
      </div>
    );
  }

  return (
    <>
      <div
        className={cx(styles.tile_price, className, { [styles.v2]: isV2 })}
        data-testid="Tile price"
      >
        {formattedCurrentPrice && (
          <span
            className={styles.tile_price_current}
            data-testid="Tile price current"
            data-qa="tile_product_price"
          >
            <span className="sr_only">
              {t('common:currentPrice')} {!!discount && !!prevPrice.value && t('common:now')}
            </span>
            {formattedCurrentPrice + ' '}
            {isV2 && minValueToOrder > 1 && (
              <p
                className={styles.tile_price_current_min}
                data-testid="Tile minimal unit value"
                data-qa="tile_min_quantity"
              >
                {'(' + `${t('minOfV2')} ${minValueToOrder}` + ')'}
              </p>
            )}
          </span>
        )}
        {!!discount && (
          <>
            {!!prevPrice.value && (
              <del
                className={styles.tile_price_previous}
                data-testid="Tile previous price"
                data-qa="tile_product_was_price"
              >
                <span className="sr_only">{t('common:earlierPrice')}</span>
                {`${getCurrencySymbol(prevPrice.currency)}${prevPrice.value}`}
              </del>
            )}
            {isShowPercentage && !!discount && (
              <span
                className={styles.tile_price_percents}
                data-testid="Tile discount percentage"
                data-qa="tile_product_discount_percentage"
              >
                {`${t('save')} ${discount}%`}
              </span>
            )}
          </>
        )}
      </div>
      {savingString && (
        <Typography
          component={savingStringComponent}
          className={cx(styles.scale_price, { [styles.v2]: isV2 })}
        >
          <span className="sr_only">{t('common:buy')}</span>
          {savingString}
        </Typography>
      )}
      {!isV2 && minValueToOrder > 1 && (
        <p
          className={styles.tile_price_current_min}
          data-testid="Tile minimal unit value"
          data-qa="tile_min_quantity"
        >
          {`${t('minOf')} ${minValueToOrder}`}
        </p>
      )}
    </>
  );
};
