import { default as dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { Translate } from 'next-translate';
import { getDateInterval } from '@helpers/getDateInterval';
import { convertUTCDateSting } from '@utils/convertUTCDateSting';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const getDateIntervalWithDay = (
  from: string,
  to: string,
  t: Translate,
  seperator?: boolean,
) => {
  const utcDate = convertUTCDateSting(from);
  const dayOfWeek = dayjs.utc(utcDate).format('dddd');

  let formattedDate;
  if (dayjs.utc(utcDate).isToday()) {
    formattedDate = t('date.today');
  } else if (dayjs.utc(utcDate).isTomorrow()) {
    formattedDate = t('date.tomorrow');
  } else {
    formattedDate = dayOfWeek;
  }

  return `${formattedDate}${seperator ? ',' : ''} ${getDateInterval(from, to)}`;
};
