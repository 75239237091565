import { Typography } from '@mui/material';
import { Alert, ButtonOutlined } from '@components/UI';
import { Gift as GiftIcon } from '@assets/icons/informational';
import { ProductTile } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import { openSampleProductsModal } from '@modules/modals/events/sampleProductsModal';
import styles from './SampleProductAlert.module.scss';

interface SampleProductAlertProps {
  sampleProducts: ProductTile[];
}

export const SampleProductAlert = ({ sampleProducts }: SampleProductAlertProps) => {
  const { t } = useLocalization('cart');

  const handleOpenModal = () => {
    openSampleProductsModal({ sampleProducts });
  };

  return (
    <Alert isFullWidth className={styles.alert}>
      <div className={styles.wrapper}>
        <GiftIcon className={styles.icon} width={32} height={32} />
        <Typography className={styles.text} component="span">
          {t('sampleProduct.alertText')}
        </Typography>
      </div>
      <ButtonOutlined
        customClass={styles.button}
        onClick={handleOpenModal}
        size="small"
        data-testid="open-sample-products-modal-button"
      >
        {t('sampleProduct.getFreeItemsButton')}
      </ButtonOutlined>
    </Alert>
  );
};
