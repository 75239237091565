import isUndefined from 'lodash/isUndefined';
import { useUpdateEffect } from 'usehooks-ts';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';

export const useModifyPageExpressContext = (isExpressOrder: boolean | undefined) => {
  const { toggle, eligible, saveExpressFilterToStorage, enabled } = useExpressContext();
  const isModifyMode = !isUndefined(isExpressOrder);

  useUpdateEffect(() => {
    const shouldEnableExpressContext = isExpressOrder && eligible;
    const shouldDisableExpressContext = !isExpressOrder;

    saveExpressFilterToStorage(enabled);

    if (!isModifyMode) {
      return;
    } else if (shouldEnableExpressContext) {
      toggle(true);
    } else if (shouldDisableExpressContext) {
      toggle(false);
    }
  }, [eligible, isExpressOrder, isModifyMode]);
};
