import { AuthUser } from '@modules/auth/types';
import { FIELDS } from '../types';

export const getFormValues = (userInfo?: AuthUser) => {
  return {
    [FIELDS.PHONE]: userInfo?.phoneNumber || '',
    [FIELDS.SMS_DELIVERY_UPDATES]: !!userInfo?.notifications?.orderNotices,
    [FIELDS.SMS_OFFERS]: !!userInfo?.notifications?.offers,
    [FIELDS.WORK_PHONE_NUMBER]: userInfo?.workPhoneNumber || '',
    [FIELDS.WORK_PHONE_EXTENSION]: userInfo?.workPhoneExtension || '',
  };
};
