/* eslint-disable @typescript-eslint/naming-convention */
import { CartLineLightWithGA } from '@commons/cart';
import {
  getProductMetrics,
  ProductMetrics,
} from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { getFormattedProductPrice } from '@modules/ga/utils/getFormattedProductPrice';

export interface AdvancedCartProductMetrics
  extends Pick<
    ProductMetrics,
    'id' | 'item_id' | 'sku' | 'price' | 'coupon' | 'dimension6' | 'discount' | 'quantity'
  > {
  item_name: string;
  index: number;
  item_brand: string | null;
  item_category: string;
  item_category2: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
}

export const getAdvancedCartProductMetrics = (
  cartLine: CartLineLightWithGA,
): AdvancedCartProductMetrics => {
  const baseData = getProductMetrics({
    ...cartLine,
    price: getFormattedProductPrice({
      price: cartLine.price,
      quantity: cartLine.quantitySelector.quantity,
      displayPrice: cartLine.displayPrice.value,
    }),
  });
  return {
    item_id: baseData.item_id,
    id: baseData.id,
    item_name: baseData.name,
    coupon: baseData.coupon,
    discount: baseData.discount,
    index: cartLine.itemPosition,
    item_brand: baseData.brand,
    item_category: baseData.category,
    item_category2: baseData.name,
    item_list_id: '',
    item_list_name: cartLine.itemListName,
    item_variant: baseData.variant,
    price: baseData.price,
    quantity: baseData.quantity,
    sku: baseData.sku ?? '',
    dimension6: baseData.dimension6,
  };
};
