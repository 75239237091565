import { Formik, useFormikContext } from 'formik';
import omit from 'lodash/omit';
import { AddressToSend, DeliveryContactInfoInput, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { StatesList } from '@constants/delivery';
import { useManualAddressValidation } from '@components/ManualAddressForm/hooks/useManualAddressValidation';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { ManualAddressFormContent } from './components/ManualAddressFormContent/ManualAddressFormContent';

interface ManualAddressFormProps {
  onChange: (value: AddressToSend) => void;
  withSubmitButton?: boolean;
  serviceType?: SERVICE_TYPE;
}

const getInitialValues = (serviceType: SERVICE_TYPE) => ({
  address1: '',
  apartment: '',
  city: '',
  state: StatesList.newYork.code,
  zipCode: '',
  serviceType,
});

export const ManualAddressForm = ({
  onChange,
  withSubmitButton,
  serviceType = SERVICE_TYPE.HOME,
}: ManualAddressFormProps) => {
  const { getServerValidation, getClientValidation } = useManualAddressValidation();
  const parentFormikContext = useFormikContext<DeliveryContactInfoInput>();

  const handleFormValidation = async (values: AddressToSend) => {
    const clientErrors = getClientValidation(values);
    if (Object.keys(clientErrors).length) return clientErrors;
    sendGtmEvent(
      GtmCustomEvent({
        /* eslint-disable @typescript-eslint/naming-convention */
        event: 'zipcode-entered',
        event_name: 'lightbox_zipcode_entered',
        ua_category: 'Lightbox Tracking',
        ua_action: 'zipcode-entered',
        /* eslint-enable @typescript-eslint/naming-convention */
      }),
    );
    const [serverErrors, validationResponse] = await getServerValidation(values);

    const skipErrors =
      validationResponse.isCommercialAddress || validationResponse.isResidentialAddress;
    if (validationResponse.isSuccess || skipErrors) {
      onChange(values);
      return omit(serverErrors, 'address1');
    }
    return serverErrors;
  };

  return (
    <Formik
      initialValues={getInitialValues(serviceType)}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => undefined}
      validate={handleFormValidation}
    >
      <ManualAddressFormContent
        withSubmitButton={withSubmitButton}
        parentFormikContext={parentFormikContext}
        serviceType={serviceType}
      />
    </Formik>
  );
};
