import { useUpdateEffect } from 'usehooks-ts';
import { getCurrentPageType } from '@modules/ga/utils/getPageType';
import { fireExpressOnEvent } from '@modules/ga/events/custom/express-on/expressOnCreator';
import { fireExpressOffEvent } from '@modules/ga/events/custom/express-off/expressOffCreator';
import { useExpressToggleEventId } from '@modules/ga/hooks/useExpressToggleEventId';
import { useEcomCart } from '@modules/ga/hooks/useEcomCart';

export const useExpressEvent = (enabled: boolean) => {
  const { refetch } = useExpressToggleEventId();
  const trackEcomCart = useEcomCart();

  const handleExpressOnEvent = async (pageType: string) => {
    const response = await refetch();
    const eventId = response?.data?.expressToggleEventId ?? '';

    fireExpressOnEvent({ pageType, eventId });
  };

  useUpdateEffect(() => {
    const pageType = getCurrentPageType();

    if (enabled) {
      handleExpressOnEvent(pageType);
    } else {
      fireExpressOffEvent({ pageType });
    }
    trackEcomCart();
  }, [enabled]);
};
