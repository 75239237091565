import { useState, createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { Emitter, IEmitter, On, Emit } from '@utils/emitter';
import { useGetCreditsOrderHistory } from '@hooks/credits';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { UserInfo } from '@modules/auth/types';
import { GenesysChatService } from '@utils/genesysChatService';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';

const DynamicContactUsModal = dynamic(
  () => import('@components/Modals/ContactUsModal/ContactUsModal'),
  {
    ssr: false,
  },
);

type Action = 'open' | 'close' | 'back';

const ContactUsModalContext = createContext<
  | {
      isOpen: boolean;
      on: On<Action>;
      emit: Emit<Action>;
      userInfo?: UserInfo;
    }
  | undefined
>(undefined);

function ContactUsModalProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const { isKnownUser: isLoggedIn, isRecognizedUser, userInfo } = useAuthContext();
  const { data } = useGetCreditsOrderHistory();
  const { hasOrders } = data;
  const isNotGuest = isRecognizedUser || isLoggedIn;
  const eventEmitterRef = useRef<IEmitter<Action>>(Emitter<Action>());
  const { on, emit } = eventEmitterRef.current;

  useEffect(() => {
    const genesysScriptElement = document.getElementById('genesys-script');
    if (userInfo?.fullName && !!window.Genesys && genesysScriptElement) {
      GenesysChatService().init({
        attributes: { Name: userInfo?.fullName, Email: userInfo?.email },
      });

      sessionStorageService?.put(STORAGE_KEYS.IS_CHAT_INITIALIZED, true);
      sessionStorageService?.put(STORAGE_KEYS.CHAT_INFO, {
        fullName: userInfo?.fullName ?? '',
        email: userInfo?.email ?? '',
      });
    }
  }, [userInfo]);

  useEffect(
    () =>
      on((event) => {
        if (event === 'open') {
          setIsOpen(true);
        }

        if (event === 'close' || event === 'back') {
          setIsOpen(false);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ContactUsModalContext.Provider value={{ isOpen, on, emit, userInfo }}>
      {isOpen && (
        <DynamicContactUsModal
          isOpen={isOpen}
          onClose={() => {
            emit('close');
          }}
          onBack={() => {
            emit('back');
          }}
          requestCredit={hasOrders && isNotGuest}
        />
      )}
      {children}
    </ContactUsModalContext.Provider>
  );
}

function useContactUsModal() {
  const context = useContext(ContactUsModalContext);
  if (context === undefined) {
    throw new Error('useContactUsModal must be used within a ContactUsModalProvider');
  }
  return context;
}

export { ContactUsModalProvider, useContactUsModal };
