import { useCallback } from 'react';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { useAuthModalContext } from '@context/AuthModalContext';

export const useAuthModal = () => {
  const { state, dispatch, toggleAuthModal, openAuthModal, on } = useAuthModalContext();

  const openModal = useCallback((type: AUTH_MODAL_TYPES) => dispatch({ type }), [dispatch]);

  return {
    state,
    openModal,
    on,
    toggleAuthModal,
    openAuthModal,
  };
};
