import { useEffect, useState } from 'react';

export const useLoadHtml = (url?: string | null) => {
  const [htmlText, setHtmlText] = useState('');
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (!url || !isMounted) {
      return;
    }

    fetch(url)
      .then((response) => response.text())
      .then((value) => {
        if (isMounted) {
          setHtmlText(value);
        }
      })
      .catch((e) => setHtmlText(e.message));

    return () => setIsMounted(false);
  }, [url, isMounted]);

  return htmlText;
};
