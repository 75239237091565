import { useRef } from 'react';
import { ClickAwayListener, Popper, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useLocalization } from '@hooks/useLocalization';
import { noLoginRoutes } from '@constants/routing';
import { ContactUsModalProvider } from '@context/contactUsModalContext';
import { AccountAuthControls } from './AccountAuthControls';
import { AccountName } from './AccountName';
import { AccountMenu } from './AccountMenu';
import { useAccountKeyboardNavigation } from './useAccountKeyboardNavigation';
import styles from './Account.module.scss';

interface AccountProps {
  tabIndex?: number;
}

export const Account = ({ tabIndex }: AccountProps) => {
  const accountRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const { anchorEl, setAnchorEl } = useAccountKeyboardNavigation({
    accountRef,
    popperRef,
  });
  const { isKnownUser: isLoggedIn, userInfo: customer, isUserInfoLoading } = useAuthContext();
  const shortName = customer?.shortName || '';
  const memberSinceYear = customer?.memberSinceYear || '';
  const hasAccountMenu = isLoggedIn || isUserInfoLoading;
  const userName = isUserInfoLoading ? (
    <Skeleton className={styles.account_name_loader} />
  ) : (
    <AccountName isOpen={!!anchorEl} name={shortName} />
  );
  const { t } = useLocalization('components');

  const router = useRouter();
  const currentRoute = router.route || '';
  const isNoLoginRoute = noLoginRoutes.some((noLoginRoute) =>
    currentRoute.startsWith(noLoginRoute),
  );

  const toggleMenu = () => {
    if (!hasAccountMenu) {
      return;
    }
    if (!!anchorEl) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const handleClose = () => {
    if (!hasAccountMenu) {
      return;
    }
    setAnchorEl(null);
  };

  const handleOpen = () => {
    if (!hasAccountMenu) {
      return;
    }
    setAnchorEl(accountRef.current);
  };

  const onSelect = () => {
    setAnchorEl(null);
  };

  if (isNoLoginRoute) return null;

  return (
    <ContactUsModalProvider>
      <div className={styles.wrapper} data-testid="account-section" ref={popperRef}>
        {hasAccountMenu ? (
          /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
          <div
            className={styles.account}
            role="button"
            aria-expanded={!!anchorEl}
            tabIndex={0}
            onClick={toggleMenu}
            onTouchEnd={toggleMenu}
            ref={accountRef}
          >
            <p className={styles.title}>Account</p>
            {userName}
          </div>
        ) : (
          <div className={styles.account}>
            <p className={styles.title}>Account</p>
            <AccountAuthControls tabIndex={tabIndex} />
          </div>
        )}
        {!!anchorEl && (
          <ClickAwayListener onClickAway={handleClose}>
            <Popper
              anchorEl={anchorEl}
              className={styles.popper}
              placement="bottom-end"
              open={!!anchorEl}
              disablePortal
              sx={{ zIndex: 'tooltip' }}
              role="dialog"
              aria-modal="false"
              aria-labelledby={undefined}
              aria-label={t('account.areaLabel')}
            >
              {isLoggedIn && (
                <AccountMenu username={shortName} since={memberSinceYear} onSelect={onSelect} />
              )}
            </Popper>
          </ClickAwayListener>
        )}
      </div>
    </ContactUsModalProvider>
  );
};
