import { NutritionMetaItem } from '@commons/product';

export const getNutritionMetaItems = (nutritionMeta: NutritionMetaItem[] | null) => {
  let metaItemsObject: { [key: string]: NutritionMetaItem | undefined } = {};
  if (nutritionMeta?.length) {
    metaItemsObject = nutritionMeta.reduce(
      (obj, item) => ({
        ...obj,
        [item['type'] as string]: item,
      }),
      metaItemsObject,
    );
  }
  return metaItemsObject;
};
