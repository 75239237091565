import { CART_MODAL_TYPES } from '@components/OrderFooter/types';
import { externalContent } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import { IframeModalContent } from '../components/IframeModalContent/IframeModalContent';
import { Taxes } from './components/Taxes';
import { EstimatedPrice } from './components/EstimatedPrice';

interface LegalOverlayProps {
  type: CART_MODAL_TYPES;
  handleClose: () => void;
}

export const LegalOverlay = ({ type, handleClose }: LegalOverlayProps) => {
  const { t } = useLocalization();
  const overlayMap = {
    [CART_MODAL_TYPES.ESTIMATED_PRICE]: <EstimatedPrice handleClose={handleClose} />,
    [CART_MODAL_TYPES.TAXES]: <Taxes handleClose={handleClose} />,
    [CART_MODAL_TYPES.CUSTOMER_AGREEMENT]: (
      <IframeModalContent
        url={externalContent.customerAgreement}
        title={t('customerAgreement')}
        dataTestId={'customer-agreement'}
      />
    ),
    [CART_MODAL_TYPES.PRIVACY_POLICY]: (
      <IframeModalContent
        url={externalContent.privacyPolicy}
        title={t('privacyPolicy')}
        dataTestId={'privacy-policy'}
      />
    ),
    [CART_MODAL_TYPES.TERMS_AND_CONDITIONS]: (
      <IframeModalContent
        url={externalContent.termsAndConditions}
        title={t('termsAndConditions')}
        dataTestId={'terms-and-conditions'}
      />
    ),
    [CART_MODAL_TYPES.PRIVACY_SMS]: (
      <IframeModalContent
        url={externalContent.privacySMS}
        title={t('privacy')}
        dataTestId={'privacy'}
      />
    ),
    [CART_MODAL_TYPES.TERMS_SMS]: (
      <IframeModalContent url={externalContent.termsSMS} title={t('terms')} dataTestId={'terms'} />
    ),
    [CART_MODAL_TYPES.GIFT_CARD_TERMS_AND_CONDITIONS]: (
      <IframeModalContent
        url={externalContent.giftCardTermsAndConditions}
        title={t('giftCardDisclaimer.btnTitle')}
        dataTestId={'giftCardTermsAndConditions'}
      />
    ),
    [CART_MODAL_TYPES.ADVANCED_PRODUCTS]: (
      <IframeModalContent
        url={externalContent.advancedProducts}
        title={t('delivery.advancedProductsModal.title')}
        dataTestId={'advanced-order'}
      />
    ),
    [CART_MODAL_TYPES.EMPTY]: null,
  };

  return overlayMap[type];
};
