import {
  getDeliveryPass,
  getDeliveryPassTerm,
  getDeliveryPassType,
} from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { getAnalyticDeliveryPassStatus } from '@modules/ga/eventBodyGetters/utils/ga-delivery-pass';
import { AccountPreferences, UserDetailsInfo } from '@commons/account';
import { GaCustomer, IGaCustomer } from '@modules/ga/eventBodyGetters/utils/ga-customer';
import { GeolocationResult, MinimumDeliveryAddress } from '@commons/deliveryAddresses';
import { getUserType } from '@modules/ga/utils/getUserType';
import { getLoginType } from '@modules/ga/utils/getLoginType';
import { AUTH_SERVICE_TYPES } from '@commons/auth';

export interface GetCustomerDataProps {
  userDetails: UserDetailsInfo;
  userStatus: string;
  geoData: GeolocationResult;
  minimumAccountPreferences: AccountPreferences;
  address?: MinimumDeliveryAddress;
}

export const getCustomerData = ({
  userDetails,
  userStatus,
  address,
  geoData,
  minimumAccountPreferences,
}: GetCustomerDataProps): IGaCustomer => {
  /* eslint-disable @typescript-eslint/naming-convention */
  return GaCustomer({
    fduserid: userDetails?.userId,
    customer_id: userDetails?.erpId,
    cohort: userDetails.cohort,
    order_count: userDetails.orderCount,
    order_count_residential: userDetails.orderCountResidential,
    order_count_corporate: userDetails.orderCountCorporate,
    customer_email: minimumAccountPreferences?.email ?? '',
    express_eligible: userDetails?.expressEligible,
    modifyMode: userDetails.modifyMode,
    timeslot_reservation_eligible: userDetails.timeslotReservationEligible,
    timeslot_reservation: userDetails.timeslotReservation,
    county: userDetails.county,
    state: userDetails.state,
    city: userDetails.city,
    zip_code: Number(address?.address?.zipCode) || Number(geoData?.address?.zipCode) || 0,
    user_type: getUserType(userDetails.orderCount),
    user_status: userStatus,
    delivery_type: minimumAccountPreferences?.serviceType || AUTH_SERVICE_TYPES.HOME,
    login_type: getLoginType(minimumAccountPreferences),
    deliverypass_type: getDeliveryPassType(userDetails.deliveryPassInfo.name),
    deliverypass: getDeliveryPass(userDetails.deliveryPassInfo),
    delivery_pass_status: getAnalyticDeliveryPassStatus(userDetails.deliveryPassInfo),
    deliverypass_term: getDeliveryPassTerm(userDetails.deliveryPassInfo.name),
    referral_signup: userDetails?.referralSignup,
  });
  /* eslint-enable @typescript-eslint/naming-convention */
};
