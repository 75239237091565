import { gql } from '@apollo/client';
import { STANDING_ORDER_FRAGMENT } from '@graphql/fragments/standingOrder/standingOrderFragment';

export const STANDING_ORDERS = gql`
  query GetStandingOrders {
    standingOrders {
      ...standingOrderFragment
    }
  }
  ${STANDING_ORDER_FRAGMENT}
`;
