import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { routing } from '@constants/routing';
import { localStorageService, STORAGE_KEYS } from '@utils/storageService';
import { useApplyPromo } from '@hooks/promo/useApplyPromo';
import { useAppliedPromo } from '@hooks/cart/useAppliedPromo';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useAPC = () => {
  const router = useRouter();
  const { isKnownUser: isLoggedIn, isRecognizedUser } = useAuthContext();
  const [apply] = useApplyPromo();
  const { data } = useAppliedPromo();
  const appliedAPCPromo = localStorageService?.read(STORAGE_KEYS.APPLIED_APC_PROMO);
  const isCart = router.pathname === routing.cart;

  useEffect(() => {
    const isKnownUser = isRecognizedUser || isLoggedIn;
    const shouldApplyAPCPromo = isCart && appliedAPCPromo && !data.promotionCode && isKnownUser;

    if (shouldApplyAPCPromo) {
      apply({
        variables: {
          promo: appliedAPCPromo,
        },
      });
    }
  }, [apply, appliedAPCPromo, isRecognizedUser, isLoggedIn, isCart, data.promotionCode]);

  useEffect(() => {
    const promoCodeFromURL = String(router.query.apc ?? '');
    const applyPromo = (promo: string) => {
      apply({
        variables: {
          promo,
        },
      });
      isLoggedIn && localStorageService?.put(STORAGE_KEYS.APPLIED_APC_PROMO, promo);
    };

    if (promoCodeFromURL) {
      localStorageService?.put(STORAGE_KEYS.APC_PROMO_CODE, promoCodeFromURL);
      if (isCart) {
        applyPromo(promoCodeFromURL);
      }
    }

    if (!promoCodeFromURL) {
      const promoFromStorage = localStorageService?.read(STORAGE_KEYS.APC_PROMO_CODE);
      promoFromStorage && localStorageService?.remove(STORAGE_KEYS.APC_PROMO_CODE);
      promoFromStorage && applyPromo(promoFromStorage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
