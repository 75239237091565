import { Grid, Typography } from '@mui/material';
import React from 'react';
import cx from 'classnames';
import { Button } from '@components/UI/Button/Button';
import { BaseLink } from '@components/UI/BaseLink/BaseLink';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { deliveryPass as DeliveryPass } from '@assets/icons/logos';
import { useLocalization } from '@hooks/useLocalization';
import { useFetchHtml } from '@hooks/useFetchHtml';
import { routing } from '@constants/routing';
import { useAuthModalContext } from '@context/AuthModalContext/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { useActivateFreeTrialDeliveryPassAtCheckout } from '@hooks/deliveryPass/useActivateFreeTrialDeliveryPassAtCheckout';
import { ActionResult as ActionResultApi } from '@api';
import {
  activatedDeliveryPassModalShown,
  notifyDeliveryPassIsActivated,
} from '@graphql/variables/activatedDeliveryPassModal';
import { openDeliveryPassRelatedErrorModal } from '@modules/modals/events/deliveryPassRelatedErrorModal';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { TermsAndConditionsButton } from '@components/TermsAndConditionsButton/TermsAndConditionsButton';
import { TermsAndConditionsModalProvider } from '@context/TermsAndConditionsModalContext/TermsAndConditionsModalProvider';
import styles from './DeliveryPassModal.module.scss';

const DELIVERY_FREE_LINK = '/media/editorial/picks/deliverypass/free_pass.html';
const ONE_YEAR_FREE_DELIVERY_LINK = '/media/editorial/picks/deliverypass/free_pass_MKT9000228.html';

interface DeliveryPassModalProps {
  onClose: () => void;
  open: boolean;
  freeOneYearAvailable?: boolean;
  twoMonthsTrialAvailable?: boolean;
  freeTrialOptinSku?: string | null;
}

export const DeliveryPassModal = ({
  onClose,
  open,
  freeOneYearAvailable,
  freeTrialOptinSku,
}: DeliveryPassModalProps) => {
  const { t } = useLocalization('modals');
  const mediaLink = freeOneYearAvailable ? ONE_YEAR_FREE_DELIVERY_LINK : DELIVERY_FREE_LINK;
  const { data } = useFetchHtml(mediaLink);
  const { isKnownUser: loggedIn } = useAuthContext();
  const { openAuthModal } = useAuthModalContext();

  const [activateTrialDeliveryPass] = useActivateFreeTrialDeliveryPassAtCheckout({
    onCompleted: (response: { activateFreeTrialDeliveryPass: ActionResultApi }) => {
      if (response.activateFreeTrialDeliveryPass.success) {
        activatedDeliveryPassModalShown(true);
        notifyDeliveryPassIsActivated(true);
      } else {
        const errorMessage =
          response?.activateFreeTrialDeliveryPass?.errors?.[0]?.description ||
          `${t('errors.somethingWentWrong')}`;
        openDeliveryPassRelatedErrorModal(errorMessage);
      }
    },
  });

  const activateDeliveryPass = () => {
    if (!loggedIn) {
      onClose();
      openAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
      return;
    }

    activateTrialDeliveryPass({
      variables: {
        freeTrialOptinSku: freeTrialOptinSku,
      },
    });
    onClose();
  };

  if (!data) return null;

  return (
    <ModalWindow
      open={open}
      onClose={onClose}
      className={styles.container}
      labelId={MODAL_TITLE_ID.DELIVERY_PASS_MODAL}
    >
      <div className={cx(styles.wrapper, { [styles.one_year_modal]: freeOneYearAvailable })}>
        <DeliveryPass />
        <h2 className={styles.title} id={MODAL_TITLE_ID.DELIVERY_PASS_MODAL}>
          {freeOneYearAvailable ? t('oneYearFreeDelivery.title') : t('freeDelivery.title')}{' '}
        </h2>
        <div className={styles.sub_title}>
          <Typography variant="body">{t('freeDelivery.subTitle')}</Typography>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data }} />

        <TermsAndConditionsModalProvider>
          <TermsAndConditionsButton
            wrapperClass={styles.terms}
            buttonClass={styles.terms_text}
            buttonMode="orange"
            title={`${t('deliveryPassTerms.termsAndConditionsAnnotation')} `}
            buttonText={t('deliveryPassTerms.termsAndConditions')}
            extraText={`. ${t('deliveryPassTerms.standardTerms')}`}
            freeOneYearEligible={freeOneYearAvailable}
          />
        </TermsAndConditionsModalProvider>

        <Grid container spacing={2} className={styles.controls}>
          <Grid item xs={7}>
            <Button
              variant="contained"
              mode="orange"
              onClick={() => activateDeliveryPass()}
              isFullWidth
            >
              {freeOneYearAvailable
                ? t('oneYearFreeDelivery.startFree')
                : t('freeDelivery.startFree')}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <BaseLink href={routing.deliveryPass} passHref>
              <Button mode="orange" variant="outlined" isFullWidth>
                {freeOneYearAvailable
                  ? t('oneYearFreeDelivery.learMore')
                  : t('freeDelivery.learMore')}
              </Button>
            </BaseLink>
          </Grid>
        </Grid>
      </div>
    </ModalWindow>
  );
};
