import { gql } from '@apollo/client';
import { DELIVERY_CONTACT_FRAGMENT } from '@graphql/fragments/account/deliveryContact';
import { PAYMENT_FRAGMENT } from '@graphql/fragments/account/payment';
import { ADDRESS_FRAGMENT } from '@graphql/fragments/common/address';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const standingOrderFragmentName = 'standingOrderFragment';

export const STANDING_ORDER_FRAGMENT = gql`
  fragment standingOrderFragment on StandingOrder {
    id
    title
    isWithoutItems
    orderMinimum
    currentDelivery {
      orderId
    }
    deliveryDetails {
      address {
        id
        address1
        apartment
        city
        state
        zipCode
      }
      contact {
        ...deliveryContactFragment
      }
    }
    payment {
      ...paymentFragment
    }
    deliveryFrequency {
      description
      timeSlot
    }
    error {
      code
      message
      detail
    }
    warning {
      code
      message
      detail
    }
    currentDelivery {
      orderId
      dayOfWeek
      date
      timeSlot
    }
    upcomingDelivery {
      cutOffDeliveryDate
      cutOffDeliveryTime
      isPendingActivation
      isReadyForActivation
      isFinalDelivery
      dayOfWeek
      date
      timeSlot
      address {
        ...addressFragment
      }
      displayPrice {
        ...priceFragment
      }
    }
    isProcessed
    isPendingActivation
    isReadyForActivation
    upcomingDeliveryDates
    deleteDate
    isDeleted
  }
  ${DELIVERY_CONTACT_FRAGMENT}
  ${PAYMENT_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${PRICE_FRAGMENT}
`;
