import { AutosuggestProduct, ProductTile } from '@commons/product';
import { InitialValues } from '@hooks/cart';
import { SALES_UNIT_ID_FIELD } from '@constants/salesUnits';

export function getShoppingListProductInitialValues(
  product: ProductTile | AutosuggestProduct,
): InitialValues {
  const salesUnit =
    product.configuration.find(
      ({ characteristicName }) => characteristicName === SALES_UNIT_ID_FIELD,
    )?.characteristicValue ?? '';

  if (!salesUnit) {
    return {};
  }

  return {
    salesUnit: salesUnit,
    variables: product.configuration
      .filter(({ characteristicName }) => characteristicName !== SALES_UNIT_ID_FIELD)
      .map(({ characteristicName, characteristicValue }) => ({
        name: characteristicName,
        value: characteristicValue ?? '',
      })),
  };
}
