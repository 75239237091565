import { useState } from 'react';
import { Product } from '@commons/product';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { InitialValues } from '@hooks/cart/useProductInfoFromCart';

export interface ChosenProduct {
  cartLineId: string | null;
  product: Product;
  variant: MODAL_TYPE;
  initialValues: InitialValues;
}

export const useConfigurationModal = () => {
  const [shouldRenderProductModal, setShouldRenderProductModal] = useState(false);
  const [chosenProduct, setChosenProduct] = useState<ChosenProduct | null>(null);

  const openModal = (productData: ChosenProduct) => {
    setChosenProduct(productData);
    setShouldRenderProductModal(true);
  };

  const closeModal = () => {
    setChosenProduct(null);
    setShouldRenderProductModal(false);
  };

  return { chosenProduct, shouldRenderProductModal, openModal, closeModal };
};
