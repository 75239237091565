import React, { forwardRef } from 'react';
import cx from 'classnames';
import { ClickAwayListener, IconButton, Popper, Typography } from '@mui/material';
import { close as CloseIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { ProductTile } from '@components/Tiles/ProductTile/ProductTile';
import { ProductTile as ProductTileType } from '@commons/product';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import styles from './AlternativeTilePopup.module.scss';

interface AlternativeTilePopupProps {
  alternativeProduct: ProductTileType;
  anchorEl: HTMLDivElement;
  onClose: () => void;
  isV2?: boolean;
}

export const AlternativeTilePopup = forwardRef(
  (
    { alternativeProduct, anchorEl, onClose, isV2 = false }: AlternativeTilePopupProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const { t } = useLocalization();
    const { isDesktop, isLaptop } = useHeaderContext();
    const { isStandingOrderAvailable } = useMainLayoutContext();
    const isSOBtnAvailable = isStandingOrderAvailable && (isLaptop || isDesktop);

    return (
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClose}>
        <Popper
          data-testid="alternative-tile-popup"
          anchorEl={anchorEl}
          className={cx(styles.wrapper, isSOBtnAvailable && styles.with_bottom_padding, {
            [styles.v2]: isV2,
          })}
          placement="right"
          open={!!anchorEl}
          disablePortal
          role="dialog"
          aria-modal="false"
          aria-labelledby={`alternative-product-title-${alternativeProduct?.productId}`}
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: { offset: [0, 12] },
              },
            ],
          }}
        >
          <div className={styles.top_line}>
            <IconButton
              onClick={onClose}
              data-testid="close-popup-button"
              aria-label="close"
              ref={ref}
            >
              <CloseIcon width={16} height={16} />
            </IconButton>
          </div>
          <Typography
            className={styles.you_might_like_title}
            id={`alternative-product-title-${alternativeProduct?.productId}`}
          >
            {t('recommendations.youMightLikeIt')}
          </Typography>
          <div className={styles.product_wrapper}>
            <ProductTile product={alternativeProduct} isV2={isV2} />
          </div>
        </Popper>
      </ClickAwayListener>
    );
  },
);

AlternativeTilePopup.displayName = 'AlternativeTilePopup';
