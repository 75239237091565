import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useGiftCardPurchaseInfo } from '@hooks/giftCard/useGiftCardPurchaseInfo';
import { GiftCardDeliveryType } from '@api';
import { useUpdateGiftCardInCart } from '@hooks/giftCard/useUpdateGiftCardInCart';
import { useLocalization } from '@hooks/useLocalization';
import { useGiftCardModal } from '@hooks/giftCard/useGiftCardModal';
import { MAX_BULK_GIFT_CARD_QTY_AMOUNT } from '@utils/validation';
import { MODAL_TITLE_ID } from '../Modal';
import styles from './GiftCardModal.module.scss';
import { Values } from './types';
import { BulkGiftCardForm } from './components/BulkGiftCardForm/BulkGiftCardForm';
import { CUSTOM_AMOUNT_INPUT_VALUE } from './constants';

interface EditBulkGiftCardModalProps {
  open: boolean;
  onClose: () => void;
  values: Values;
}

export const EditBulkGiftCardModal = ({ open, onClose, values }: EditBulkGiftCardModalProps) => {
  const { t } = useLocalization('giftCards');
  const { data, loading } = useGiftCardPurchaseInfo();
  const [updateGiftCard] = useUpdateGiftCardInCart();
  const { isCustomAmountEditShown, formRef, handleBack, handleClose, handleOpen } =
    useGiftCardModal();

  const closeModalHandler = () => {
    handleClose();
    onClose();
  };

  const updateValues = (newValues: Values) => {
    const updatedAmount = `${newValues.giftCardAmount}.00`;
    const isDefaultAmount = data.gcAmtOptions.includes(updatedAmount);
    return {
      ...newValues,
      giftCardAmount: updatedAmount,
      giftCardDefaultAmount: isDefaultAmount ? updatedAmount : CUSTOM_AMOUNT_INPUT_VALUE,
      giftCardCustomAmount: isDefaultAmount ? '' : newValues.giftCardAmount,
    };
  };

  const handleBulkGiftCardSubmit = (editValues: Values) => {
    updateGiftCard({
      variables: {
        input: {
          templateId: editValues.giftCardTemplateId,
          amount: (editValues.giftCardAmount || editValues.giftCardDefaultAmount) ?? '',
          deliveryMethod: editValues.giftCardType as GiftCardDeliveryType,
          quantity: Number(editValues.quantity),
          senderEmail: editValues.senderEmail,
          senderName: editValues.senderName,
          message: editValues.recipientMessage ?? '',
          recipientName: editValues.senderName,
          recipientEmail: editValues.senderEmail,
          id: editValues.giftCardId,
          isBulk: editValues.isBulk,
        },
      },
      onCompleted: onClose,
      onError() {
        formRef.current?.setFieldError(
          'quantity',
          t('giftCardModal.errors.quantityMaxInput', { value: MAX_BULK_GIFT_CARD_QTY_AMOUNT }),
        );
      },
    });
  };

  return (
    <ModalWindow
      open={open}
      onClose={closeModalHandler}
      className={styles.modal_wrapper}
      onBack={isCustomAmountEditShown ? handleBack : undefined}
      labelId={MODAL_TITLE_ID.BULK_GIFT_CARD_MODAL}
    >
      <>
        {!isCustomAmountEditShown && (
          <Typography
            variant="h3"
            component="h3"
            className={styles.title}
            id={MODAL_TITLE_ID.BULK_GIFT_CARD_MODAL}
          >
            {t('giftCardModal.editTitle')}
          </Typography>
        )}
        <BulkGiftCardForm
          values={updateValues(values)}
          templateData={data}
          isCustomAmountEditShown={isCustomAmountEditShown}
          onOpenCustomAmountForm={handleOpen}
          onCloseCustomAmountForm={handleClose}
          onSubmit={handleBulkGiftCardSubmit}
          loading={loading}
          submitButtonText={t('giftCardModal.editButton')}
          formRef={formRef}
        />
      </>
    </ModalWindow>
  );
};
