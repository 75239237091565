import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Trans from 'next-translate/Trans';
import isEqual from 'lodash/isEqual';
import { Alert, Button, DropDown, Link } from '@components/UI';
import { Product, ProductTile, SelectedConfiguration } from '@commons/product';
import { calculateTotalPrice, getSelectedSalesUnit } from '@utils/calculateTotalPrice';
import { useLocalization } from '@hooks/useLocalization';
import { getPrice } from '@utils/getPrice';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { getProductTitle } from '@utils/getProductTitle';
import { InitialValues } from '@hooks/cart';
import { SimpleCounter, useCounter } from '@components/SimpleCounter';
import { calculateEstimatedWeight } from '@utils/calculateEstimatedWeight';
import { Grid } from '@components/UI/Grid/Grid';
import { AddToCartProps } from '@commons/addToCartProps';
import { isMealBundle } from '@utils/productHelper';
import { getInitialQuantity } from '@components/ProductConfigurationModal/utils/getInitialQuantity';
import { ShoppingListProductInfo } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';
import { isEligibleForAddingToShoppingList } from '@utils/isEligibleForAddingToShoppingList';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { getProductConfigurationVariables } from '@utils/getProductConfigurationVariables';
import { useSelectItemEvent } from '@modules/ga/hooks/useSelectItemEvent';
import { getConfigurableProduct } from '@hooks/cart/useAddToCart';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductPositionContext } from '@modules/ga/context/position';
import { AddToShoppingListButton } from '@components/AddToShoppingListButton/AddToShoppingListButton';
import { StandingOrder } from '@commons/standingOrder';
import { getStandingOrdersDropdownOptions } from '@components/ProductConfigurationModal/components/Content/utils';
import { LegalInterstitial } from '@features/LegalPage/LegalInterstitial';
import { isRestrictedProduct } from '@utils/isRestrictedProduct';
import { warning as WarningIcon } from '@assets/icons/system';
import { useSideBagDrawerManager } from '@hooks/cart/useSideBagDrawerManager';
import {
  ConfigurationDropdowns,
  ValidateHandle,
} from '../ConfigurationDropdowns/ConfigurationDropdowns';
import { TotalPrice } from './components/TotalPrice/TotalPrice';
import { TileHeader } from './components/TileHeader/TileHeader';
import styles from './ConfigurationContent.module.scss';
import { HasVariations } from './components/hasVariations/HasVariations';

export interface ConfigurationChangeHandlerProps {
  salesUnit?: string;
  configs?: SelectedConfiguration[];
}

interface ConfigurationContentProps {
  children?: never;
  product: Product | ProductTile;
  standingOrders?: StandingOrder[];
  className?: string;
  initialValues?: InitialValues;
  onOrder: (props: AddToCartProps) => void;
  variant: MODAL_TYPE;
  onMoreInfoClick?: (link: string, title?: string) => void;
  onShowVariationInfo?: (id: string) => void;
  onClose?: () => void;
  onAddToShoppingList?: (product: ShoppingListProductInfo) => void;
  hideComponent?: boolean;
  withImageTile?: boolean;
  loading?: boolean;
  onReplace?: (configs: SelectedConfiguration[], replacedQnty?: number) => void;
  isDisabledControls?: boolean;
  orderErrorMessage?: string;
  labelId?: string;
  productQuantity?: number;
}

const byNameAndValue = ({ name, value }: SelectedConfiguration) => name && value;

const checkIsSaleUnitChanged = (salesUnit: string, initialSalesUnit?: string) =>
  !!salesUnit && initialSalesUnit !== salesUnit;

export const ConfigurationContent = ({
  product,
  standingOrders,
  className,
  initialValues,
  variant,
  withImageTile = false,
  onMoreInfoClick,
  onOrder,
  onShowVariationInfo,
  onClose,
  onAddToShoppingList,
  hideComponent,
  loading = false,
  onReplace,
  isDisabledControls,
  orderErrorMessage,
  labelId,
  productQuantity,
}: ConfigurationContentProps) => {
  const isBundle = isMealBundle(product);
  const initialQuantity = getInitialQuantity(initialValues?.count, productQuantity);
  const { t } = useLocalization('product');
  const [totalPrice, setTotalPrice] = useState<number>(product.price.value || 0);
  const { salesUnits } = product;

  const [alternateSalesUnit, setAlternateSalesUnit] = useState<string>(
    initialValues?.salesUnit ?? salesUnits[0]?.alternateSalesUnit ?? '',
  );
  const [configs, setConfigs] = useState<SelectedConfiguration[]>(initialValues?.variables ?? []);
  const { minQuantity: minQty, maxQuantity: maxQty } = product.quantity;

  const { getListName } = usePageListName();
  const { enabled: expressEnabled } = useExpressContext();
  const { trackSelectItem } = useSelectItemEvent();
  const { getProductPosition } = useProductPositionContext();

  const standingOrdersOptions = getStandingOrdersDropdownOptions(standingOrders);
  const [selectedStandingOrderId, setSelectedStandingOrderId] = useState<string | undefined>();
  const [isProductRestricted, setIsProductRestricted] = useState(
    isRestrictedProduct(product, undefined, selectedStandingOrderId),
  );
  const { closeSideBagDrawer, isSideBagDrawerOpen } = useSideBagDrawerManager();

  useEffect(() => {
    if (standingOrdersOptions.length && !selectedStandingOrderId) {
      setSelectedStandingOrderId(standingOrdersOptions[0].value);
    }
  }, [selectedStandingOrderId, standingOrdersOptions]);

  useEffect(() => {
    setIsProductRestricted(isRestrictedProduct(product, undefined, selectedStandingOrderId));
  }, [product, selectedStandingOrderId]);

  useEffect(() => {
    if (expressEnabled) {
      const expressConfigs = product.variations
        .map(({ name, values }) => {
          return {
            name,
            value: values[0]?.name ?? '',
          };
        })
        .filter((item) => !!item.value);
      setConfigs(expressConfigs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expressEnabled]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const {
    handleCounterInput,
    handleDecrease,
    handleIncrease,
    quantity,
    increment,
    minValue,
    maxValue,
    errorMessage,
  } = useCounter({
    initialQuantity,
    increment: product.quantity.quantityIncrement,
    minValue: minQty,
    maxValue: maxQty,
    setShowAlert,
  });
  const dropdownsRef = useRef<ValidateHandle>(null);
  const isCartModal = variant === MODAL_TYPE.CART_EDIT;
  const isCartProductModal = variant === MODAL_TYPE.CART_MINI_PDP;
  const isAddToStandingOrderModal = variant === MODAL_TYPE.ADD_TO_STANDING_ORDER;
  const ctaButtonMode = isAddToStandingOrderModal ? 'orange' : 'light';
  const isCartPage = isCartModal || isCartProductModal;
  const hasVariations = !!product.variations.length || product.salesUnits.length > 1;
  const hasWarnings = !!product.availability.length;
  const unitPrice =
    getPrice(product.price.value, product.price.currency) + '/' + product?.scaleUnit;
  const isRestrictedToAddToStandingOrder = isProductRestricted && isAddToStandingOrderModal;

  const configurationChangeHandler = ({
    salesUnit: changedSalesUnit,
    configs: changedConfigs,
  }: ConfigurationChangeHandlerProps) => {
    if (changedConfigs) setConfigs(changedConfigs.filter(byNameAndValue));
    if (changedSalesUnit) setAlternateSalesUnit(changedSalesUnit);
  };
  const isSaleUnitChanged = checkIsSaleUnitChanged(alternateSalesUnit, initialValues?.salesUnit);
  const areDropdownsChanged = !isEqual(initialValues?.variables || [], configs);
  const isCountChanged = () => {
    let isChanged = false;
    if (initialValues?.count) {
      isChanged = initialValues.count !== quantity;
    }
    return isChanged;
  };
  const isFormChanged = areDropdownsChanged || isCountChanged() || isSaleUnitChanged;

  const getSelectedAlternateSalesUnit = () => {
    return product.salesUnits.find((item) => item.alternateSalesUnit === alternateSalesUnit);
  };

  useEffect(() => {
    setTotalPrice(
      calculateTotalPrice(product, {
        ratio: getSelectedSalesUnit(product.salesUnits, alternateSalesUnit)?.ratio || 1,
        quantity,
        selectedConfigurations: configs,
      }),
    );
  }, [configs, quantity, product, alternateSalesUnit]);

  const buttonClickHandler = () => {
    const isAllowToSend = !dropdownsRef?.current?.validate || dropdownsRef.current.validate();
    if (isAllowToSend) {
      if (onReplace) {
        onReplace(configs, quantity);
        onClose?.();
        return;
      }

      const selectedStandingOrder = standingOrders?.find(
        (order) => order.id === selectedStandingOrderId,
      );

      onOrder({
        product,
        quantity,
        standingOrder: selectedStandingOrder,
        salesUnit: alternateSalesUnit,
        configuration: getProductConfigurationVariables(configs),
      });
    }
  };

  const handleAddToShoppingList = () => {
    const isAllowToSend = !dropdownsRef?.current?.validate || dropdownsRef.current.validate();

    if (!isAllowToSend) {
      return;
    }

    const itemListName = getListName(product);
    const itemPosition = getProductPosition(product.productId);

    const configuratedProduct = getConfigurableProduct({
      product,
      quantity,
      configuration: getProductConfigurationVariables(configs),
      itemPosition,
      salesUnit: alternateSalesUnit,
      itemListName,
    });

    onAddToShoppingList?.({
      productDescription: product.productDescription,
      productName: product.productName,
      productId: product.productId,
      productImage: product.productImage,
      addToCartInput: configuratedProduct,
    });
    onClose?.();
  };

  const getCartConfirmText = () => {
    return isFormChanged ? t('buttons.update') : t('buttons.confirm');
  };

  const getConfirmButtonText = () => {
    if (isCartPage) {
      return getCartConfirmText();
    }
    if (isAddToStandingOrderModal) {
      return t('buttons.addToStandingOrder');
    }
    return t('buttons.addToBasket');
  };

  const handleNavigateToPDP = () => {
    if (isSideBagDrawerOpen) {
      closeSideBagDrawer();
    }
    trackSelectItem(product);
  };

  const handleAgreeLegalInterstitial = () => {
    setIsProductRestricted(false);
    buttonClickHandler();
  };

  const labelIdProp = {
    ...(labelId && { id: labelId }),
  };

  const renderStandingOrdersDropdown = () => {
    const isStandingOrderDropdownVisible = isAddToStandingOrderModal && !!selectedStandingOrderId;

    return (
      isStandingOrderDropdownVisible && (
        <Grid item xs={4}>
          <DropDown
            data-testid="select stading order"
            className={styles.standing_orders_dropdown}
            title={t('configurationModal.selectStandingOrderTitle').toUpperCase()}
            value={selectedStandingOrderId}
            options={standingOrdersOptions}
            onChange={setSelectedStandingOrderId}
          />
        </Grid>
      )
    );
  };

  const renderBundleTitle = () =>
    isBundle && (
      <Grid item xs={4}>
        <Typography component="h2" variant="h3" className={styles.title} {...labelIdProp}>
          {t('configurationModal.bundleTitle')}
        </Typography>
      </Grid>
    );

  const renderWarningsAlert = () =>
    hasWarnings && (
      <Grid item xs={4}>
        <div className={styles.warnings}>
          <Alert type="warning" hasShowIcon={false} isFullWidth withoutBorder>
            {product.availability?.map((warning, index) => (
              <span key={index}>{warning}</span>
            ))}
          </Alert>
        </div>
      </Grid>
    );

  const renderErrorAlert = () => {
    const isErrorAlertVisible = showAlert && !!errorMessage;

    return (
      isErrorAlertVisible && (
        <Grid item>
          <Alert type="error" className={styles.alert} onClose={() => setShowAlert(false)}>
            {errorMessage}
          </Alert>
        </Grid>
      )
    );
  };

  const renderOrderErrorMessage = () =>
    orderErrorMessage && (
      <Grid item xs={4}>
        <Typography variant="body" className={styles.error_message}>
          <WarningIcon width={12} height={12} className={styles.error_message_icon} />
          <span>{orderErrorMessage}</span>
        </Typography>
      </Grid>
    );

  const renderLinkToProduct = () =>
    isCartModal && (
      <Grid item xs={4}>
        <div className={styles.product_link}>
          <Link legacyBehavior href={product.productPageUrl} onClick={handleNavigateToPDP}>
            {t('links.toProduct')}
          </Link>
        </div>
      </Grid>
    );

  return (
    <div className={className}>
      <Grid container columns={{ xs: 4 }}>
        {!isCartProductModal && !isBundle && (
          <>
            {withImageTile && (
              <Grid item xs={4}>
                <TileHeader
                  url={product.productImage.ref}
                  alt={
                    product.productImage.alt ||
                    getProductTitle(product.productName, product.brandName)
                  }
                  unitSize={product.unitSize}
                  labelId={labelId}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <div className={styles.price_container}>
                <Typography
                  component="span"
                  className={styles.price}
                  data-testid="configuration-unit-price"
                >
                  {unitPrice}
                </Typography>{' '}
              </div>
            </Grid>
          </>
        )}
        {renderBundleTitle()}
        {renderWarningsAlert()}

        <Typography variant="body" component="p" className={styles.required_note}>
          <Trans i18nKey="common:requiredNote" components={{ b: <b /> }} />
        </Typography>

        <HasVariations hasVariations={hasVariations}>
          <Grid item xs={4}>
            <ConfigurationDropdowns
              variations={product.variations}
              bundleVariations={product.bundle.variationGroups}
              salesUnits={product.salesUnits}
              onChange={configurationChangeHandler}
              initialValues={initialValues}
              ref={dropdownsRef}
              onMoreInfoClick={onMoreInfoClick}
              isBundle={isBundle}
              hideComponent={hideComponent}
              onShowVariationInfo={onShowVariationInfo}
            />
          </Grid>
        </HasVariations>
        <Grid item xs={4}>
          <TotalPrice
            estimatedWeight={calculateEstimatedWeight(
              product,
              quantity,
              getSelectedAlternateSalesUnit(),
            )}
            estimatedWeightDisclaimer={product.estimatedWeightDisclaimer}
            onMoreInfoClick={() => {
              if (product.estimatedWeightDisclaimer && onMoreInfoClick)
                onMoreInfoClick(
                  product.estimatedWeightDisclaimer,
                  t('configurationModal.moreInfo.weightTitle'),
                );
            }}
            totalPrice={getPrice(totalPrice, product.price.currency)}
          />
        </Grid>

        {renderErrorAlert()}

        {!product.soldBySalesUnit && (
          <Grid item xs={4}>
            <SimpleCounter
              className={styles.counter}
              size="extraLarge"
              value={quantity}
              step={increment}
              onMinusClick={handleDecrease}
              onPlusClick={handleIncrease}
              onChange={handleCounterInput}
              isFullWidth
              minValue={minValue}
              maxValue={maxValue}
              buttonArialLabel={product.productName}
              isRemoveDisabled={isDisabledControls}
              isPlusDisabled={isDisabledControls}
              isMinusDisabled={isDisabledControls}
              isInputDisabled={isDisabledControls}
            />
          </Grid>
        )}
        {renderStandingOrdersDropdown()}
        {renderOrderErrorMessage()}

        <Grid item xs={4}>
          <LegalInterstitial
            isRestricted={isRestrictedToAddToStandingOrder}
            scopeId={selectedStandingOrderId}
            onAgree={handleAgreeLegalInterstitial}
          >
            <Button
              onClick={buttonClickHandler}
              size="large"
              isFullWidth
              loading={loading}
              mode={ctaButtonMode}
              disabled={isDisabledControls}
            >
              {getConfirmButtonText()}
            </Button>
          </LegalInterstitial>

          <AddToShoppingListButton
            product={product}
            listLabel={t('common:shoppingList.addToList')}
            classNames={styles.add_to_shopping_list_button}
            handleAddToShoppingList={handleAddToShoppingList}
            shouldRenderAddToShoppingListButton={
              isEligibleForAddingToShoppingList(product) && !!onAddToShoppingList
            }
          />
        </Grid>

        {renderLinkToProduct()}
      </Grid>
    </div>
  );
};
