import cn from 'classnames';
import { CircularProgress } from '@mui/material';
import { IconType } from '@commons/icon';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase/ButtonBase';
import { ButtonBaseIcon } from '../ButtonBase/ButtonBaseIcon';
import styles from './ButtonV2.module.scss';

interface ButtonV2Props extends Omit<ButtonBaseProps, 'className'> {
  type?: 'primary' | 'secondary';
  iconComponent?: IconType;
  isLoading?: boolean;
}

export const ButtonV2 = ({
  type = 'primary',
  children,
  iconComponent: Icon,
  isLoading,
  ...restProps
}: ButtonV2Props) => {
  const className = cn(styles.button, styles[type]);

  return (
    <ButtonBase {...restProps} className={className}>
      {Icon && <ButtonBaseIcon icon={Icon} />}
      {isLoading ? <CircularProgress size={20} className={styles.loading_icon} /> : children}
    </ButtonBase>
  );
};
