import { useQuery } from '@apollo/client';
import { USER_DETAILS } from '@graphql/common/queries/userDetails';
import { getUserDetails } from '@adapters/accountAdapter';

export const useUserDetails = () => {
  const { data, ...queryParams } = useQuery(USER_DETAILS, {
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...queryParams,
    data: getUserDetails(data?.userDetails),
  };
};
