import cx from 'classnames';
import { Typography } from '@mui/material';
import { BaseLink as Link } from '@components/UI';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useLocalization } from '@hooks/useLocalization';
import productStyles from '../../ProductTile/ProductTile.module.scss';
import styles from './TileName.module.scss';

export interface TileNameProps {
  name: string;
  url?: string | null;
  children?: never;
  brand?: string | null;
  isHighlighted?: boolean;
  onProductClick?: (listName: string) => void;
  linkTarget?: string;
  nonFocusable?: boolean;
  isUnavailable?: boolean;
  isV2?: boolean;
}

interface TileNameWrapperProps {
  children: React.ReactChild;
  url?: string | null;
  onProductClick?: () => void;
  linkTarget?: string;
  nonFocusable?: boolean;
}

const TileNameWrapper = ({
  children,
  url,
  onProductClick,
  linkTarget,
  nonFocusable,
}: TileNameWrapperProps) => {
  const additionalProps = {
    ...(nonFocusable && { tabIndex: -1 }),
  };

  if (url) {
    return (
      <Link legacyBehavior href={url}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a
          className={styles.link}
          target={linkTarget}
          data-qa="tile_product_name_link"
          onClick={onProductClick}
          {...additionalProps}
        >
          {children}
        </a>
      </Link>
    );
  }

  return <div>{children}</div>;
};

export const TileName = ({
  name,
  brand,
  url,
  isHighlighted,
  onProductClick,
  linkTarget,
  nonFocusable,
  isUnavailable = false,
  isV2 = false,
}: TileNameProps) => {
  const { getListName } = usePageListName();
  const { t } = useLocalization();

  return (
    <TileNameWrapper
      url={url}
      onProductClick={() => onProductClick?.(getListName())}
      linkTarget={linkTarget}
      nonFocusable={nonFocusable}
    >
      <Typography
        className={cx(styles.tile_name, productStyles.hovered_tile_name, {
          [styles.hover]: isHighlighted,
          [styles.v2]: isV2,
        })}
        data-testid="Tile title"
        data-qa="tile_product_name"
      >
        {!!brand && (
          <Typography
            variant="body"
            component="span"
            className={styles.tile_name_brand}
            data-qa="tile_product_name_brand"
            data-testid="Tile brand"
          >
            {brand}
          </Typography>
        )}
        <Typography
          className={styles.tile_name_text}
          component="span"
          variant="body"
          data-qa="tile_product_title"
          dangerouslySetInnerHTML={{ __html: name }}
          data-testid="Tile name"
        />
        {isUnavailable && (
          <Typography
            variant="body"
            component="span"
            className={styles.unavailable_text}
            data-testid="tile-product-name-unavailable"
          >
            {' '}
            {t('unavailable')}
          </Typography>
        )}
      </Typography>
    </TileNameWrapper>
  );
};
