import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getViewItemEvent } from '@modules/ga/events/ecommerce/view-item/utils/getViewItemEvent';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { ViewItemEventProps } from '@modules/ga/events/ecommerce/view-item/viewItemCreator';
import { getBreadcrumbs } from '@modules/ga/utils/getBreadcrumbs';

/* eslint-disable @typescript-eslint/naming-convention */

interface ViewItemHandlerProps extends ViewItemEventProps {
  commonAnalyticsData: AnalyticsData;
}

export const viewItemHandler = ({
  product,
  listName,
  itemPosition,
  commonAnalyticsData,
}: ViewItemHandlerProps) => {
  const { userDetails } = commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getViewItemEvent({
      list_name: listName,
      product,
      currencyCode: getCurrencyCodeFromSymbol(product?.price?.currency),
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
      breadcrumb: getBreadcrumbs(),
      itemPosition,
    }),
  );
};
/* eslint-enable @typescript-eslint/naming-convention */
