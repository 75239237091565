import { useMutation } from '@apollo/client';
import { DELETE_ALCOHOL } from '@graphql/cart/mutations/deleteAlcohol';
import { useRemoveFromCartEvent } from '@modules/ga/hooks/useRemoveFromCart';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { usePrevious } from '@hooks/usePrevious';
import { updateCartCache } from './updateCartCache';

export const useDeleteAlcohol = () => {
  const { trackRemoveListFromCart } = useRemoveFromCartEvent();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);

  return useMutation(DELETE_ALCOHOL, {
    update(cache, { data: { deleteAlcohol: cart } }) {
      updateCartCache(cache, cart);
    },
    onCompleted({ deleteAlcohol: newCart }) {
      if (oldCart) {
        trackRemoveListFromCart(oldCart, newCart);
      }
    },
  });
};
