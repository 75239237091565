import { useState, ReactNode, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { on, emit } from '@modules/emitter';
import { Hide, IHide, THide } from '@modules/hide';
import { IShow, TShow } from '@modules/show';
import { TermsAndConditionsModalContext } from './TermsAndConditionsModalContext';

const DynamicTermsAndConditionsModal = dynamic(
  () => import('@components/Modals/TermsAndConditionsModal/TermsAndConditionsModal'),
  {
    ssr: false,
  },
);

interface TermsAndConditionsModalProviderProps {
  children: ReactNode;
}

export const TermsAndConditionsModalProvider = ({
  children,
}: TermsAndConditionsModalProviderProps) => {
  const [opened, setOpened] = useState(false);
  const [oneYearTermsOpened, setOneYearTermsOpened] = useState(false);

  useEffect(
    () =>
      on((action) => {
        if (action.type === TShow && (action as IShow).id === 'termsAndConditionsModal') {
          setOpened(true);
        }
        if (action.type === THide && (action as IHide).id === 'termsAndConditionsModal') {
          setOpened(false);
        }
        if (
          action.type === TShow &&
          (action as IShow).id === 'freeOneYearTermsAndConditionsModal'
        ) {
          setOneYearTermsOpened(true);
        }
        if (
          action.type === THide &&
          (action as IHide).id === 'freeOneYearTermsAndConditionsModal'
        ) {
          setOneYearTermsOpened(false);
        }
      }),

    [],
  );

  return (
    <TermsAndConditionsModalContext.Provider value={{ opened, oneYearTermsOpened, on, emit }}>
      {opened && (
        <DynamicTermsAndConditionsModal
          onClose={() => {
            emit(
              Hide({
                id: 'termsAndConditionsModal',
              }),
            );
          }}
          opened={opened}
        />
      )}
      {oneYearTermsOpened && (
        <DynamicTermsAndConditionsModal
          onClose={() => {
            emit(
              Hide({
                id: 'freeOneYearTermsAndConditionsModal',
              }),
            );
          }}
          opened={oneYearTermsOpened}
          openOneYearTerms={oneYearTermsOpened}
        />
      )}
      {children}
    </TermsAndConditionsModalContext.Provider>
  );
};
