import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs as MuiTabs, Typography } from '@mui/material';
import {
  Nutrition,
  NutritionV2,
  NutritionLine,
  WineData,
  ProductExtraDetails,
} from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import { Link } from '@components/UI';
import { NutritionTable } from '@features/ProductPage/components/Nutrition/Nutrition';
import { TABS } from '@features/ProductPage/constants';
import { TabItem } from '../TabItem/TabItem';
import { TabPanel } from '../TabPanel/TabPanel';
import { DescriptionTabContent } from '../DescriptionTabContent/DescriptionTabContent';
import {
  ALCOHOL_KEYS,
  ALCOHOL_TITLE_TRANSLATIONS,
  WineDataKeys,
} from '../../constants/alcoholTitles';
import styles from '../../AdditionalInfoModal.module.scss';

export interface ModalContentProps {
  preselectedTab: TABS;
  productName?: string;
  description?: string | null;
  nutrition: Nutrition | NutritionV2;
  oldNutritionContent?: NutritionLine[] | null;
  ingredients?: string | null;
  instructions: string | null;
  link: string | null;
  allergens: string | null;
  alcohol: WineData;
  extraDetails: ProductExtraDetails;
  productQualityNote: string | null;
  bundleformat?: boolean;
}

export const ModalContent = ({
  preselectedTab,
  productName,
  description,
  nutrition,
  oldNutritionContent,
  instructions,
  extraDetails,
  allergens,
  link,
  alcohol,
  productQualityNote,
  bundleformat = false,
}: ModalContentProps) => {
  const [value, setValue] = useState<TABS>(TABS.EMPTY);
  const { t } = useLocalization();
  const { ingredients } = extraDetails;
  const hasInstructionInfo = link;
  const hasNutrition =
    ('id' in nutrition && nutrition.id) ||
    ('panelType' in nutrition && nutrition.panelType) ||
    oldNutritionContent?.length;

  const getIsTabs = () =>
    description || hasNutrition || ingredients || alcohol?.alcoholGrade || hasInstructionInfo;

  const getTabItemValue = (tabValue: string | string[]) => {
    if (typeof tabValue === 'string') {
      return !!tabValue ? [tabValue] : [];
    }
    return tabValue;
  };

  const memoizedAlcoholData = useMemo(() => {
    const filterAlcoholValue = (key: WineDataKeys) => {
      const item = alcohol[key];
      return typeof item === 'string' ? !!item : item.length > 0;
    };

    return ALCOHOL_KEYS.filter(filterAlcoholValue).map((key) => (
      <TabItem
        key={key}
        values={getTabItemValue(alcohol[key])}
        title={t(ALCOHOL_TITLE_TRANSLATIONS[key])}
      />
    ));
  }, [t, alcohol]);

  useEffect(() => {
    setValue(preselectedTab);
  }, [preselectedTab]);

  const handleChange = (event: SyntheticEvent, newValue: TABS) => {
    setValue(newValue);
  };

  return (
    <>
      {bundleformat && (
        <Typography variant="h2" className={styles.title_with_bundle}>
          {productName}
        </Typography>
      )}
      {getIsTabs() && (
        <div className={styles.divider}>
          <MuiTabs
            value={value}
            onChange={handleChange}
            aria-label="Tabs"
            className={styles.tabs_wrapper}
          >
            {description && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.DESCRIPTION}
                label={t('additionalInfo.tabs.description')}
                disableRipple
              />
            )}
            {hasNutrition && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.NUTRITION}
                label={t('additionalInfo.tabs.nutrition')}
                disableRipple
              />
            )}
            {ingredients && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.INGREDIENTS}
                label={t('additionalInfo.tabs.ingredients')}
                disableRipple
              />
            )}
            {alcohol?.alcoholGrade && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.ALCOHOL}
                label={t('additionalInfo.tabs.alcoholInfo')}
                disableRipple
              />
            )}
            {hasInstructionInfo && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.INSTRUCTIONS}
                label={t('additionalInfo.tabs.instructions')}
                disableRipple
              />
            )}
          </MuiTabs>
        </div>
      )}
      <TabPanel value={value} index={TABS.DESCRIPTION}>
        <DescriptionTabContent
          description={description}
          extraDetails={extraDetails}
          allergens={allergens}
          instructions={instructions}
          productQualityNote={productQualityNote}
        />
      </TabPanel>
      {hasNutrition && (
        <TabPanel value={value} index={TABS.NUTRITION}>
          <NutritionTable nutrition={nutrition} oldNutritionContent={oldNutritionContent} />
        </TabPanel>
      )}
      {ingredients && (
        <TabPanel value={value} index={TABS.INGREDIENTS}>
          <div dangerouslySetInnerHTML={{ __html: ingredients }} />
        </TabPanel>
      )}
      {link && (
        <TabPanel value={value} index={TABS.INSTRUCTIONS}>
          <Link href={link} target="_blank" rel="noreferrer">
            {t('additionalInfo.download')}
          </Link>
        </TabPanel>
      )}
      {memoizedAlcoholData && (
        <TabPanel value={value} index={TABS.ALCOHOL}>
          <div className={styles.tab_alcohol_wrapper}>{memoizedAlcoholData}</div>
        </TabPanel>
      )}
    </>
  );
};
