import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { NetworkStatus } from '@apollo/client';
import { Grid } from '@components/UI/Grid/Grid';
import { Link as MuiLink } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useSettings } from '@hooks/useSettings/useSettings';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { routing } from '@constants/routing';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { AdBlock } from '@components/AdBlock/AdBlock';
import { AD_BLOCK_DIMENSIONS } from '@commons/dfp';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { Paragraph } from './components/Paragraph/Paragraph';
import styles from './AccountPageChefTable.module.scss';

export const AccountPageChefTable = () => {
  const { isSignedIn } = useAuthContext();
  const { t } = useLocalization('account');
  const { isMobile, isTablet } = useHeaderContext();
  const isSmallDevice = !!isMobile || !!isTablet;

  const { push } = useRouter();
  const {
    data: { chefTablePhoneNumber, chefTableEmail },
    loading: settingsLoading,
  } = useSettings();
  const {
    minimumAccountPreferences: { isChefTableAvailable, email },
    loading: accountPreferencesLoading,
    networkStatus,
  } = useMinimumAccountPreferences();

  const restrictPage =
    networkStatus === NetworkStatus.ready && email && !isChefTableAvailable && isSignedIn;

  useEffect(() => {
    if (restrictPage) {
      push(routing.home);
    }
  }, [push, restrictPage]);

  if (accountPreferencesLoading || settingsLoading || !isChefTableAvailable) {
    return <AccountPageLoader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Grid container>
          <Paragraph title={t('chefTable.paragraph1.title')} hideTitle={isSmallDevice} isMainTitle>
            {t('chefTable.paragraph1.content')}
          </Paragraph>
          <Paragraph title={t('chefTable.paragraph2.title')} hideMargin>
            {t('chefTable.paragraph2.content')}
          </Paragraph>
          <Grid item xs={12} sm={8} lg={7} xl={8} className={styles.banner_container}>
            <AdBlock
              adBlockId="ChefsTable"
              adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
              collapseConfig={{
                collapseEnabled: true,
                heights: { dekstop: 90, mobile: 50 },
              }}
            />
          </Grid>
          <Paragraph title={t('chefTable.paragraph3.title')}>
            {t('chefTable.paragraph3.content')}
          </Paragraph>
          <Paragraph title={t('chefTable.paragraph4.title')}>
            {t('chefTable.paragraph4.content')}
          </Paragraph>
          <Paragraph title={t('chefTable.paragraph5.title')}>
            {t('chefTable.paragraph5.content.part1')}
            <MuiLink href={`mailto:${chefTableEmail}`} classNameLinkText={styles.link}>
              {chefTableEmail}
            </MuiLink>
            {t('chefTable.paragraph5.content.part2')}
            <MuiLink href={`tel:${chefTablePhoneNumber}`} classNameLinkText={styles.link}>
              {chefTablePhoneNumber}
            </MuiLink>
          </Paragraph>
        </Grid>
      </div>
    </div>
  );
};
