import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import { KeyboardEvent } from 'react';

export enum VALIDATION_RULES {
  IS_REQUIRED = 'IS_REQUIRED',
  IS_EMAIL = 'IS_EMAIL',
  IS_PHONE = 'IS_PHONE',
  IS_NUMBER = 'IS_NUMBER',
  MAX_NUMBER = 'MAX_NUMBER',
  MIN_NUMBER = 'MIN_NUMBER',
  MIN_LENGTH = 'MIN_LENGTH',
}

export interface ValidationRules {
  [VALIDATION_RULES.IS_REQUIRED]?: boolean;
  [VALIDATION_RULES.IS_EMAIL]?: boolean;
  [VALIDATION_RULES.IS_PHONE]?: boolean;
  [VALIDATION_RULES.IS_NUMBER]?: boolean;
  [VALIDATION_RULES.MAX_NUMBER]?: number;
  [VALIDATION_RULES.MIN_NUMBER]?: number;
  [VALIDATION_RULES.MIN_LENGTH]?: number;
}

const errorMessages = {
  [VALIDATION_RULES.IS_EMAIL]: 'Invalid email format',
  [VALIDATION_RULES.IS_PHONE]: 'Invalid phone format',
  [VALIDATION_RULES.MIN_LENGTH]: (minLength: number) => `Minimum ${minLength} characters`,
};

export const EXTERNAL_LINK = /https?/;

export const REG_EXP_EBT_CARD_NUMBER = /((\d{4})\s){4}$|((\d{4})\s){4}(\d{2,3})$/;

export const EMAIL_REGEX = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,63})$/;

export const EMAIL_REGEX_ALLOWING_PLUS =
  /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,63})$/;

export const UNALLOWED_SYMBOLS_REGEXP = /[\&\<\>\"\'\#\%\=\$\@]/;

export const ONLY_DIGITS_REGEXP = /^[0-9]*$/;

export const ONLY_INTEGER_REGEXP = /^([-]?([0-9]*(\.?))(([0-9]*))?)$/;

export const PHONE_MASK = '(___) ___-____';

export const EBT_CARD_MASK = '9999 9999 9999 9999 999';

export const INVALID_ZIPCODE_VALUE = '00000';

export const SMALL_FIELD_LENGTH = 20;
export const MEDIUM_FIELD_LENGTH = 55;
export const LARGE_FIELD_LENGTH = 255;

export const MIN_TEXT_FIELD_LENGTH = 1;
export const MIN_CVC_FIELD_LENGTH = 3;
export const MAX_CVC_FIELD_LENGTH = 4;
export const MAX_TEXT_FIELD_LENGTH = 248;
export const MAX_COMPANY_NAME_FIELD_LENGTH = 128;
export const ZIPCODE_FIELD_LENGTH = 5;
export const NAME_FIELD_LENGTH = 80;
export const EMAIL_FIELD_LENGTH = 80;
export const ROUTING_NUMBER_FIELD_LENGTH = 9;
export const MAX_EXTENSION_FIELD_LENGTH = 5;

export const MAX_INPUT_FIELD_LENGTH = 60;
export const MAX_EMAIL_FIELD_LENGTH = 85;
export const MAX_TEXTAREA_FIELD_LENGTH = 256;

export const MAX_BULK_GIFT_CARD_QTY_AMOUNT = 499;

export const hasMoreRequiredSymbols = (value: string, number: number) => value.length > number;

export const hasOnlyNumbers = (value: string) => ONLY_DIGITS_REGEXP.test(value);

export const hasUnallowedAddressSymbols = (string: string) => UNALLOWED_SYMBOLS_REGEXP.test(string);

export const isValidEbtCard = (cardNum: string) => REG_EXP_EBT_CARD_NUMBER.test(cardNum);

export const allSymbolsAreIndentical = (string: string) => new Set(string.split('')).size === 1;

export const hasExactLength = (value: string, length: number) => value.trim().length === length;

export const isValidZipCode = (string: string) =>
  ONLY_DIGITS_REGEXP.test(string) &&
  !(allSymbolsAreIndentical(string) && string[0] === '0') &&
  string !== '0' &&
  string.length === 5;

const hasRule = (rule: unknown): boolean => {
  return !isNil(rule);
};

const checkNumber = (rule: unknown): boolean => {
  return hasRule(rule) && isNumber(rule);
};

export const isValidEmail = (email: string) => email.trim() !== '' && EMAIL_REGEX.test(email);

const validateEmail = (value: string, errors: string[]): boolean => {
  const isEmail = isValidEmail(value);

  if (!isEmail) {
    errors.push(errorMessages[VALIDATION_RULES.IS_EMAIL]);
  }

  return isEmail;
};

const validateMinLength = (value: string, minLength: number, errors: string[]) => {
  const valueLength = value.toString().length;
  const isValidValue = valueLength >= minLength && value.trim() !== '';

  if (!isValidValue) {
    errors.push(errorMessages[VALIDATION_RULES.MIN_LENGTH](minLength));
  }

  return valueLength >= minLength;
};

const validateNumber = (
  value: string | number | boolean,
  validationRules: ValidationRules = {},
) => {
  let isValid = true;
  const errors: string[] = [];

  if (checkNumber(validationRules[VALIDATION_RULES.MAX_NUMBER])) {
    isValid = isValid && (value as number) <= (validationRules[VALIDATION_RULES.MAX_NUMBER] || 0);
  }

  if (checkNumber(validationRules[VALIDATION_RULES.MIN_NUMBER])) {
    isValid = isValid && (value as number) >= (validationRules[VALIDATION_RULES.MIN_NUMBER] || 0);
  }

  if (checkNumber(validationRules[VALIDATION_RULES.MIN_LENGTH])) {
    const minLength = validationRules[VALIDATION_RULES.MIN_LENGTH] || 0;
    isValid = isValid && validateMinLength(value.toString().trim(), minLength, errors);
  }

  return {
    isValid,
    errors,
  };
};

export const validate = (
  value: string | number | boolean,
  validationRules: ValidationRules = {},
) => {
  let isValid = true;
  const errors: string[] = [];

  if (validationRules[VALIDATION_RULES.IS_REQUIRED]) {
    const valueLength = value.toString().length;
    isValid = isValid && !!valueLength;
  }

  if (validationRules[VALIDATION_RULES.IS_NUMBER]) {
    const numRegExp = /-?\d+$/gi;

    isValid = isValid && numRegExp.test(value.toString());
  }

  if (validationRules[VALIDATION_RULES.IS_PHONE]) {
    const numRegExp = /-?\d{11}$/gi;

    isValid = isValid && numRegExp.test(value.toString());
  }

  if (validationRules[VALIDATION_RULES.IS_EMAIL]) {
    isValid = isValid && validateEmail(value.toString(), errors);
  }

  const numberValidationResults = validateNumber(value, validationRules);

  isValid = isValid && numberValidationResults.isValid;

  errors.push(...numberValidationResults.errors);

  return {
    isValid,
    errors,
  };
};

export const handlePreventNotNumbers = (e: KeyboardEvent<HTMLImageElement>) => {
  if (!ONLY_DIGITS_REGEXP.test(e.key)) e.preventDefault();
};
