import { CookieValueTypes } from 'cookies-next';
import { ApolloClient, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { disableFragmentWarnings } from 'graphql-tag';
import { logger } from '@logger';
import { LAST_DELIVERED_ORDERS_OPERATION_NAME } from '@graphql/order/queries/lastDeliveredOrders';
import { copyWebTrackingCodesLink } from '@modules/web-tracking';
import { IPreviewModeHeaders } from '@modules/preview-mode';
import { maintenanceLink } from '@graphql/links/maintenanceLink';
import {
  recognizedUserLink,
  resetRecognizedUserLink,
} from '@modules/auth/links/recognizedUserLink';
import { gqlOperationLink } from '@graphql/links/gqlOperationLink';
import { httpLink } from './links/httpLink';
import { expressCheckoutLink } from './links/expressCheckoutLink';
import { cacheConfig } from './cache';
import { authLink } from './links/authLink';
import { knockingLink } from './links/knockingLink';
import { batchHttpLink } from './links/batchHttpLink';
import { apiErrorLink } from './links/apiErrorLink';
import { MASQUERADE_BAR_DATA_OPERATION_NAME } from './masquerade/queries/masqueradeBarData';
import { applyRegionIdLink } from './links/regionIdLink';
import { previewModeLink } from './links/previewModeLink';
import { expressDeliveryFilterLink } from './links/expressDeliveryFilterLink';
import { roundTripLink } from './links/roundTripLink';
import { applyServiceTypeLink } from './links/serviceTypeLink';
import { securityHeaderLink } from './links/securityHeaderLink';

disableFragmentWarnings();

const isServer = typeof window === 'undefined';

let CLIENT: ApolloClient<NormalizedCacheObject>;

const separatedOperations = [
  LAST_DELIVERED_ORDERS_OPERATION_NAME,
  MASQUERADE_BAR_DATA_OPERATION_NAME,
  'pastAndActiveOrderHistory',
  'GetCart',
  'Logout',
  'StoreCredits',
  'SalesCarousels',
  'GlobalHeader',
  'Footer',
  'Product',
  'HomeFeed',
  'ReorderFeed',
  'MostPopularCarousel',
  'ReorderCarousel',
  'SuperDepartmentInfo',
  'SuperDepartmentSearch',
  'CategorySearch',
  'SearchDepartment',
  'saleProductSearch',
  'ModifyOrder',
  'AnonUserDeliveryAddresses',
  'UserDeliveryAddressesProtected',
];

interface GetApolloClientOptions {
  forceNew: boolean;
  valuePerHeaderKey: Record<string, string>;
  regionId?: string;
  serviceType?: CookieValueTypes;
  previewModeHeaders?: IPreviewModeHeaders;
  cookies?: string[];
  isExpress?: CookieValueTypes;
  cacheData?: NormalizedCacheObject;
}

export function getApolloClient({
  forceNew,
  valuePerHeaderKey,
  regionId,
  serviceType,
  cookies,
  isExpress,
  previewModeHeaders,
  cacheData,
}: GetApolloClientOptions) {
  if (!CLIENT || forceNew) {
    const useProxy = process.env.NEXT_PUBLIC_USE_LOCAL_PROXY;
    const relativePath = useProxy === 'true' ? '/api/graphql' : '/graphql';
    const gqlhost = () => process.env.NEXT_PUBLIC_GRAPHQL_URL ?? relativePath;
    logger.debug('NEXT_HOST cli-init-resolved:' + gqlhost());

    CLIENT = new ApolloClient({
      ssrMode: isServer,
      credentials: 'include',
      uri: gqlhost(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cache: isServer
        ? new InMemoryCache(cacheConfig)
        : new InMemoryCache(cacheConfig).restore(cacheData ?? {}),
      link: from([
        roundTripLink,
        maintenanceLink,
        copyWebTrackingCodesLink(valuePerHeaderKey),
        applyRegionIdLink(regionId),
        applyServiceTypeLink(serviceType),
        authLink(cookies),
        expressDeliveryFilterLink(isExpress),
        expressCheckoutLink,
        recognizedUserLink,
        resetRecognizedUserLink,
        apiErrorLink,
        previewModeLink(previewModeHeaders),
        gqlOperationLink,
        securityHeaderLink,
        knockingLink(gqlhost()),
      ]).split(
        ({ operationName }) => isServer || separatedOperations.includes(operationName),
        httpLink(),
        batchHttpLink(),
      ),
      devtools: {
        enabled: true,
      },
    });
  }

  return CLIENT;
}
