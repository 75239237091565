import { gql } from '@apollo/client';

export const CSR_DATA_FRAGMENT = gql`
  fragment csrDataFragment on CsrData {
    isDeliveryForceWaived
    isPremiumDeliveryForceWaived
    isPhoneHandlingChargeWaived
    isEmailInvoiceSendingSkipped
    customerInvoiceMessage
    phoneCharge
    deliveryCharge
    deliveryChargePremium
    complaintReasons {
      id
      departmentCode
      departmentName
      reason
      priority
      subjectCode
      dlvIssueType
    }
  }
`;
