import { gql } from '@apollo/client';
import { PRODUCT_BASIC_FRAGMENT } from '@graphql/fragments/product/productBasicFragment';

export const SUGGESTIONS_QUERY = gql`
  query suggest($text: String!) {
    suggest(text: $text) {
      suggestions
      purchasedBefore {
        ...productBasicFragment
      }
      notPurchasedBefore {
        ...productBasicFragment
      }
      exactMatch {
        ...productBasicFragment
      }
    }
  }
  ${PRODUCT_BASIC_FRAGMENT}
`;
