import { routing } from '@constants/routing';
import {
  GaCustomer,
  IGaCustomer,
  PGaCustomer,
} from '@modules/ga/eventBodyGetters/utils/ga-customer';

/* eslint-disable @typescript-eslint/naming-convention*/
export interface PGaPageView {
  customer?: PGaCustomer;
  page_type?: string;
  page_name?: string;
  shopping_mode?: string;
  site_version?: string;
  pageURL: string;
  pageViewPixelEventId?: string;
}

export interface IGaPageView {
  event: string;
  customer: IGaCustomer;
  page_type: string;
  page_name: string;
  'shopping-mode': string;
  site_version: string;
  pageURL: string;
  pageViewPixelEventId: string;
}

export function GaPageView(p: PGaPageView): IGaPageView {
  const event = 'custom_pageview';
  let pageURL = p.pageURL;
  const customer = GaCustomer(p.customer || {});
  const page_type = p.page_type ?? 'NOT_RECOGNIZED';
  const page_name = p.page_name ?? '';
  const shopping_mode = p.shopping_mode ?? '';
  const site_version = p.site_version ?? 'new';
  const pageViewPixelEventId = p.pageViewPixelEventId ?? '';

  if (pageURL.includes(routing.retrievePassword)) {
    pageURL = pageURL.replace(/email=.+&/gi, '');
  }

  return {
    event,
    pageURL,
    customer,
    page_type,
    page_name,
    'shopping-mode': shopping_mode,
    site_version,
    pageViewPixelEventId,
  };
}
