import { useRouter } from 'next/router';
import cx from 'classnames';
import { GiftCardsCart } from '@adapters/giftCardAdatper';
import { routing } from '@constants/routing';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/index';
import { ListView } from './components/ListView/ListView';
import { SingleView } from './components/SingleView/SingleView';
import styles from './GiftCardBag.module.scss';

interface GiftCardBagProps {
  cart: GiftCardsCart;
  onCloseSideBagDrawer?: () => void;
}

export const GiftCardBag = ({ cart, onCloseSideBagDrawer }: GiftCardBagProps) => {
  const router = useRouter();
  const { t } = useLocalization('cart');
  const hasNoGiftCards = !cart || cart.cardsCount === 0;
  const hasMoreThanOneGiftCards = cart.cardsCount > 1;
  const bulkGiftCard = cart.cards[0].isBulk;
  const listView = hasMoreThanOneGiftCards || bulkGiftCard;
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();

  const getBagContent = () => {
    if (hasNoGiftCards) return null;
    return listView ? (
      <ListView giftCards={cart.cards} isBulk={bulkGiftCard} />
    ) : (
      <SingleView giftCard={cart.cards[0]} isSideBag={isSideBagDrawerOpen} />
    );
  };

  const handleNavigateToCart = () => {
    onCloseSideBagDrawer?.();
    router.push(routing.cart);
  };

  const renderCta = () => {
    if (isSideBagDrawerOpen) {
      return (
        <div
          className={cx(styles.bottom_panel_wrapper, {
            [styles.drawer_opened]: isSideBagDrawerOpen,
          })}
        >
          <div
            className={cx(styles.bottom_panel_inner, {
              [styles.drawer_opened]: isSideBagDrawerOpen,
            })}
          >
            <Button
              variant="contained"
              onClick={handleNavigateToCart}
              size="large"
              isFullWidth
              className={styles.cta_button}
              data-testid="cart-drawer-go-to-bag"
            >
              {t('goToBag')}
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={cx(styles.content_wrapper, {
          [styles.drawer_opened]: isSideBagDrawerOpen,
        })}
      >
        {getBagContent()}
      </div>
      {renderCta()}
    </>
  );
};
