import { Typography } from '@mui/material';

interface ButterflyFooterTextProps {
  optionalFooterText?: string | null;
  optionalFooterBackgroundColor?: string | null;
  optionalFooterTextColor?: string | null;
}

export const ButterflyFooterText = ({
  optionalFooterText,
  optionalFooterBackgroundColor,
  optionalFooterTextColor,
}: ButterflyFooterTextProps) => {
  return !!optionalFooterText ? (
    <Typography
      component="p"
      style={{
        backgroundColor: optionalFooterBackgroundColor ?? '',
        color: optionalFooterTextColor ?? '',
      }}
    >
      {optionalFooterText}
    </Typography>
  ) : null;
};
