import { ApolloLink } from '@apollo/client';
import { regionIdHeaderKey } from '@modules/region-id';

export const applyRegionIdLink = (regionId?: string) => {
  return new ApolloLink((operation, forward) => {
    if (regionId) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      operation.setContext((oldContext: Record<string, any>) => ({
        ...oldContext,
        headers: {
          ...oldContext.headers,
          [regionIdHeaderKey]: regionId,
        },
      }));
    }
    return forward(operation);
  });
};
