import cx from 'classnames';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { check as CheckIcon } from '@assets/icons/system';
import styles from './BaseTile.module.scss';

interface BaseTileProps extends ButtonHTMLAttributes<HTMLInputElement> {
  children: ReactNode;
  wrapperClassName?: string;
  inputClassName?: string;
  contentClassName?: string;
  checked?: boolean;
  dataTestId?: string;
  iconWrapperClassName?: string;
  containerClassName?: string;
  handleFocus?: () => void;
}

export const BaseTile = forwardRef<HTMLLabelElement | null, BaseTileProps>(
  (
    {
      containerClassName,
      style,
      inputClassName,
      wrapperClassName,
      contentClassName,
      id,
      children,
      iconWrapperClassName,
      handleFocus,
      ...props
    },
    ref,
  ) => (
    <label
      htmlFor={id}
      style={style}
      className={cx(styles.wrapper, wrapperClassName)}
      ref={ref}
      onMouseOver={(e) => {
        e.currentTarget.focus();
      }}
      onFocus={handleFocus}
    >
      <input
        {...props}
        id={id}
        type="radio"
        className={cx(styles.radio_input, inputClassName, 'button')}
      />
      <span className={cx(styles.container, containerClassName)} aria-describedby={id}>
        <span className={cx(styles.content, contentClassName)}>{children}</span>
        <span className={cx(styles.icon_wrapper, iconWrapperClassName, styles.check_icon)}>
          <CheckIcon width={12} height={9} />
        </span>
      </span>
    </label>
  ),
);

BaseTile.displayName = 'BaseTile';
