import { gql } from '@apollo/client';
import { MASQUERADE_CART_FRAGMENT } from '../../fragments/masquerade/masqueradeCartFragment';

export const APPLY_CSR = gql`
  mutation applyCsr($input: CsrInput!) {
    applyCsr(input: $input) {
      ...masqueradeCartFragment
    }
  }
  ${MASQUERADE_CART_FRAGMENT}
`;
