import { useLazyQuery } from '@apollo/client';
import { getProductTile } from '@adapters/product/getProductTile';
import {
  OptionalProductsData,
  ProductOptionalProductsDataQuery,
  ProductOptionalProductsDataQueryVariables,
} from '@api';
import { ProductTile } from '@commons/product';
import { GET_OPTIONAL_PRODUCTS_BY_ID } from '@graphql/product/queries/optionalProducts';

export interface IOptionalProductsData {
  items: ProductTile[];
  title: string;
}

const prepareData = (
  data?: DeepPartial<OptionalProductsData> | null,
): IOptionalProductsData | null => {
  if (data) {
    return {
      title: data?.title ?? '',
      items: data?.items?.map((item) => getProductTile(item)) ?? [],
    };
  }
  return null;
};

export const useLazyOptionalAdditions = () => {
  const [fetchData] = useLazyQuery<
    ProductOptionalProductsDataQuery,
    ProductOptionalProductsDataQueryVariables
  >(GET_OPTIONAL_PRODUCTS_BY_ID, {
    ssr: false,
  });

  const fetchOptionalAdditions = async (productId: string) => {
    const { data } = await fetchData({
      variables: {
        productId,
      },
    });
    return prepareData(data?.product?.optionalProductsData);
  };

  return {
    fetchOptionalAdditions,
  };
};
