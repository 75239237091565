import cx from 'classnames';
import { renderString } from 'nunjucks';
import Markdown from 'react-markdown';
import { logger } from '@logger';
import { useFetchHtml } from '@hooks/useFetchHtml';

export enum MEDIA_TYPE {
  TEXT = 'text',
  HTML = 'html',
  MARKDOWN = 'markdown',
}

interface MediaProps {
  url?: string;
  content?: string;
  type?: MEDIA_TYPE;
  process?: boolean;
  context?: object;
  className?: string;
  children?: React.ReactNode;
}

const getMediaTypeForUrl = (url: string) => {
  const extension = url.split('.').pop();
  switch (extension) {
    case 'txt':
      return MEDIA_TYPE.TEXT;
    case 'md':
      return MEDIA_TYPE.MARKDOWN;
    default:
      return MEDIA_TYPE.HTML;
  }
};

export const Media = ({
  url,
  content,
  type,
  process,
  context,
  className,
  children,
}: MediaProps) => {
  const { data: mediaContent, error } = useFetchHtml(url);

  if (error) {
    logger.error(error);
  }

  content = mediaContent || content;

  if (!content) {
    // use children as fallback
    return (
      <div className={cx(className, 'media', { ['dev-mark']: !!error })} data-dev-note={error}>
        {children}
      </div>
    );
  }

  if (!type) {
    type = MEDIA_TYPE.HTML;
    if (url) {
      type = getMediaTypeForUrl(url);
    }
  }

  process = !!process || !!context;

  if (process && content) {
    try {
      content = renderString(content, context || {});
    } catch (o_0) {
      logger.error('Failed to process media content', o_0);
    }
  }

  if (type === MEDIA_TYPE.TEXT) {
    return <div className={cx(className, 'media')}>{content}</div>;
  } else if (type === MEDIA_TYPE.MARKDOWN) {
    return (
      <div className={cx(className, 'media')}>
        <Markdown>{content}</Markdown>
      </div>
    );
  }

  return (
    <div className={cx(className, 'media')} dangerouslySetInnerHTML={{ __html: content || '' }} />
  );
};
