import React from 'react';
import { Typography } from '@mui/material';
import { Button } from '@components/UI/Button/Button';
import { Show } from '@modules/show';
import { UITheme } from '@commons/uiTheme';
import { useTermsAndConditionsModalContext } from '@context/TermsAndConditionsModalContext/TermsAndConditionsModalContext';

export interface TermsAndConditionsButtonProps {
  freeOneYearEligible?: boolean;
  buttonClass: string;
  wrapperClass: string;
  title?: string;
  buttonText: string;
  extraText?: string;
  isSmallBody?: boolean;
  buttonMode?: UITheme;
  id?: string;
}

export const TermsAndConditionsButton = ({
  id,
  title,
  buttonClass,
  wrapperClass,
  freeOneYearEligible,
  buttonText,
  buttonMode,
  extraText,
  isSmallBody,
}: TermsAndConditionsButtonProps) => {
  const termsAndConditionsModal = useTermsAndConditionsModalContext();

  const handleTermsAndConditionsLinkClick = () => {
    termsAndConditionsModal.emit(
      Show({
        id: `${
          freeOneYearEligible ? 'freeOneYearTermsAndConditionsModal' : 'termsAndConditionsModal'
        }`,
      }),
    );
  };

  return (
    <div className={wrapperClass} id={id}>
      <div>
        {!!title && title}
        <Button
          className={buttonClass}
          size="small"
          variant="underline"
          mode={buttonMode ?? 'light'}
          onClick={handleTermsAndConditionsLinkClick}
        >
          <Typography variant={isSmallBody ? 'smallBody' : 'body'}>{buttonText}</Typography>
        </Button>
        {extraText ?? extraText}
      </div>
    </div>
  );
};
