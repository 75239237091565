import { useMemo } from 'react';
import { ScreenSizeProps, WindowSize } from './useWindowDimensions';

export const useProductsPageSize = (
  isGridLargeWithTile = false,
  screenSize?: ScreenSizeProps,
  windowSize?: WindowSize,
) => {
  const width = windowSize?.width ?? 0;

  const skeletonCountByDevice = {
    mobile: 2,
    tablet: 4,
    desktop: 6,
  };

  const stepByDevice = {
    mobile: isGridLargeWithTile ? 11 : 12,
    tablet: isGridLargeWithTile ? 17 : 20,
    desktop: isGridLargeWithTile ? 25 : 30,
  };

  const pageLimits = useMemo(() => {
    if (screenSize?.isDesktop || screenSize?.isLaptop) {
      return {
        productsPageSize: stepByDevice.desktop,
        skeletonPageSize: skeletonCountByDevice.desktop,
      };
    } else if (screenSize?.isLargeTablet || screenSize?.isTablet) {
      return {
        productsPageSize: stepByDevice.tablet,
        skeletonPageSize: skeletonCountByDevice.tablet,
      };
    } else {
      return {
        productsPageSize: stepByDevice.mobile,
        skeletonPageSize: skeletonCountByDevice.mobile,
      };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize, width]);

  return {
    ...pageLimits,
    hasWidth: width !== 0,
  };
};
