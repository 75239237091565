import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { RadioGroupField, TextField } from '@components/FormikFields';
import { useLocalization } from '@hooks/useLocalization';
import {
  MAX_EMAIL_FIELD_LENGTH,
  MAX_INPUT_FIELD_LENGTH,
  MAX_TEXTAREA_FIELD_LENGTH,
} from '@utils/validation';
import { ErrorBlock } from '@components/ErrorBlock/ErrorBlock';
import { radioGroupOptions } from '../../constants';
import { Values, GIFTCARD_FIELDS } from '../../types';
import styles from './Controls.module.scss';

interface ControlsProps {
  isResend?: boolean;
}

export const Controls = ({ isResend = false }: ControlsProps) => {
  const { t } = useLocalization('giftCards');
  const { values, errors, touched, handleBlur, setFieldValue } = useFormikContext<Values>();

  return (
    <Grid container flexDirection="column" className={styles.controls}>
      {!isResend && (
        <>
          <Typography className={styles.subtitle}>{t('giftCardModal.fromSubtitle')}</Typography>
          <Grid item marginTop={4} marginBottom={4}>
            <Field
              name={GIFTCARD_FIELDS.SENDER_NAME}
              component={TextField}
              label={t('giftCardModal.fields.name')}
              error={touched[GIFTCARD_FIELDS.SENDER_NAME] && errors[GIFTCARD_FIELDS.SENDER_NAME]}
              helperText={
                touched[GIFTCARD_FIELDS.SENDER_NAME] && errors[GIFTCARD_FIELDS.SENDER_NAME]
              }
              onBlur={handleBlur}
              maxLength={MAX_INPUT_FIELD_LENGTH}
              id={GIFTCARD_FIELDS.SENDER_NAME}
              autoComplete="name"
            />
          </Grid>
        </>
      )}
      <Grid container item marginTop={6} rowSpacing={4} flexDirection="column">
        <Typography className={styles.subtitle}>{t('giftCardModal.toSubtitle')}</Typography>
        <Grid item>
          <Field
            name={GIFTCARD_FIELDS.RECIPIENT_NAME}
            component={TextField}
            label={t('giftCardModal.fields.recipientName')}
            error={
              touched[GIFTCARD_FIELDS.RECIPIENT_NAME] && errors[GIFTCARD_FIELDS.RECIPIENT_NAME]
            }
            helperText={
              touched[GIFTCARD_FIELDS.RECIPIENT_NAME] && errors[GIFTCARD_FIELDS.RECIPIENT_NAME]
            }
            onBlur={handleBlur}
            maxLength={MAX_INPUT_FIELD_LENGTH}
            id={GIFTCARD_FIELDS.RECIPIENT_NAME}
          />
        </Grid>
        <Grid item>
          <Field
            name={GIFTCARD_FIELDS.RECIPIENT_EMAIL}
            component={TextField}
            label={t('giftCardModal.fields.recipientEmail')}
            error={
              touched[GIFTCARD_FIELDS.RECIPIENT_EMAIL] && errors[GIFTCARD_FIELDS.RECIPIENT_EMAIL]
            }
            helperText={
              touched[GIFTCARD_FIELDS.RECIPIENT_EMAIL] && errors[GIFTCARD_FIELDS.RECIPIENT_EMAIL]
            }
            onBlur={handleBlur}
            maxLength={MAX_EMAIL_FIELD_LENGTH}
            id={GIFTCARD_FIELDS.RECIPIENT_EMAIL}
          />
        </Grid>
        <Grid item>
          <Field
            name={GIFTCARD_FIELDS.RECIPIENT_MESSAGE}
            label={t('giftCardModal.fields.recipientMessage')}
            component={TextField}
            error={
              touched[GIFTCARD_FIELDS.RECIPIENT_MESSAGE] &&
              errors[GIFTCARD_FIELDS.RECIPIENT_MESSAGE]
            }
            helperText={
              touched[GIFTCARD_FIELDS.RECIPIENT_MESSAGE] &&
              errors[GIFTCARD_FIELDS.RECIPIENT_MESSAGE]
            }
            onBlur={handleBlur}
            maxLength={MAX_TEXTAREA_FIELD_LENGTH}
            multiline
            classes={{ root: styles.recipientMessage }}
            id={GIFTCARD_FIELDS.RECIPIENT_MESSAGE}
          />
        </Grid>
      </Grid>
      {!isResend && (
        <Grid
          role="group"
          container
          item
          marginTop={6}
          marginBottom={8}
          rowSpacing={2}
          flexDirection="column"
        >
          <Grid item>
            <Field
              name={GIFTCARD_FIELDS.GIFTCARD_TYPE}
              component={RadioGroupField}
              options={radioGroupOptions.map(({ value, label }) => ({
                value: value,
                label: (
                  <Typography variant="body" component="p">
                    {t(label)}
                  </Typography>
                ),
              }))}
              value={values[GIFTCARD_FIELDS.GIFTCARD_TYPE]}
              onChange={(value: string) => {
                setFieldValue(GIFTCARD_FIELDS.GIFTCARD_TYPE, value);
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container direction="column" aria-live={'polite'}>
        {touched[GIFTCARD_FIELDS.GIFTCARD_AMOUNT] && errors[GIFTCARD_FIELDS.GIFTCARD_AMOUNT] && (
          <div className={styles.error}>
            <ErrorBlock message={errors[GIFTCARD_FIELDS.GIFTCARD_AMOUNT] ?? ''} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};
