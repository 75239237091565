import { Maybe, Variation, VariationGroup } from '@api';
import { SelectedConfiguration, ProductTileVariations } from '@commons/product';
import { PreparedDropdown } from '../types';

export const prepareVariations = (
  variations: ProductTileVariations[],
  initialValues?: SelectedConfiguration[],
): PreparedDropdown[] => {
  if (variations.length > 0) {
    return variations
      .filter(({ name, values }) => !!name && !!values.length)
      .map((variation) => {
        const foundInitialConfiguration = initialValues?.length
          ? initialValues.find(({ name }) => name === variation.name)
          : null;
        const foundSelectedValue =
          variation.values.length === 1
            ? variation.values[0].name
            : variation.values.find((value) => value.selected)?.name || null;
        return {
          ...variation,
          name: variation.name,
          label: variation.label,
          optional: variation.optional,
          underLabel: variation.underLabel,
          descrMedia: variation.descrMedia,
          values: variation.values,
          selectedValue: foundInitialConfiguration?.value || foundSelectedValue,
          hasError: false,
        };
      });
  }

  return [];
};

export const prepareBundleVariations = (
  bundleVariations: VariationGroup[] | undefined,
  initialValues?: SelectedConfiguration[],
) => {
  const bundles: PreparedDropdown[] = [];
  bundleVariations?.map((i) => {
    i.variations?.map((item: Maybe<Variation>) => {
      const selectedValue = getSelectedValue(item, initialValues);
      bundles.push({
        name: item?.name ?? '',
        optional: item?.optional ?? false,
        selectedValue,
        isOpen: false,
        hasError: false,
        values:
          item?.values?.map((value, index) => ({
            label: value?.label ?? '',
            value: value?.name ?? '',
            selected: false,
            name: value?.name ?? '',
            imagePath: value?.imagePath,
            variationItemProductData: value?.variationItemProductData ?? {},
            index,
          })) ?? [],
        label: item?.label ?? '',
        underLabel: item?.underLabel ?? '',
        descrMedia: item?.descrMedia ?? '',
      });
    });
  });

  return bundles;
};

const getSelectedValue = (item: Maybe<Variation>, initialValues?: SelectedConfiguration[]) => {
  const foundInitialConfiguration = initialValues?.length
    ? initialValues.find(({ name }) => name === item?.name)
    : null;
  const selectedName = item?.values?.length === 1 ? item?.values?.[0]?.name ?? '' : null;
  const selectedValue = initialValues
    ? foundInitialConfiguration?.value || selectedName
    : selectedName;
  return selectedValue;
};
