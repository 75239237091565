import { useFormikContext } from 'formik';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { GiftCardTemplateSkeleton } from '@components/Skeletons/GiftCardTemplateSkeleton/GiftCardTemplateSkeleton';
import { useLocalization } from '@hooks/useLocalization';
import { GiftCardTemplate } from '@adapters/giftCardAdatper';
import { GiftCardCarousel } from '../Carousel/Carousel';
import { Values, GIFTCARD_FIELDS } from '../../types';
import styles from './Templates.module.scss';

interface GiftCardTemplatesProps {
  templates: GiftCardTemplate[];
  loadingTemplates: boolean;
}

export const GiftCardTemplates = ({
  templates,
  loadingTemplates: loading,
}: GiftCardTemplatesProps) => {
  const { setFieldValue, values } = useFormikContext<Values>();
  const { t } = useLocalization('giftCards');
  const existingValue = values.giftCardTemplateId;
  const firstItemId = !!templates?.length ? templates[0].gcTemplateId : '';
  useEffect(() => {
    if (firstItemId) {
      setFieldValue(
        GIFTCARD_FIELDS.GIFTCARD_TEMPLATE_ID,
        existingValue ? existingValue : firstItemId,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstItemId]);

  const getTemplateId = (index: number) =>
    !!templates?.length ? templates[index].gcTemplateId : '';

  const getInitialSlideIndex = () => {
    if (existingValue && templates) {
      return templates.findIndex(({ gcTemplateId }) => gcTemplateId === existingValue);
    }
    return 0;
  };

  if (loading) {
    return <GiftCardTemplateSkeleton />;
  }

  if (templates && templates.length !== 0) {
    return (
      <GiftCardCarousel
        initialSlide={getInitialSlideIndex()}
        giftCardTemplates={templates}
        handleChange={(index) =>
          setFieldValue(GIFTCARD_FIELDS.GIFTCARD_TEMPLATE_ID, getTemplateId(index))
        }
      />
    );
  }

  return (
    <Typography variant="h5" className={styles.fallbackSubtitle}>
      {t('giftCardModal.noGiftCards')}
    </Typography>
  );
};
