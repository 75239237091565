import React, { forwardRef } from 'react';
import { search as Search } from '@assets/icons/system';
import { InputProps, Input } from '../Input/Input';
import styles from './InputSearch.module.scss';

export const InputSearch = forwardRef(({ ...restProps }: InputProps, ref) => {
  if (restProps.inputProps?.['aria-expanded'] === false) {
    delete restProps.inputProps['aria-expanded'];
  }

  return (
    <Input
      {...restProps}
      ref={ref}
      icon={<Search width={14} height={14} className={styles.search_icon} />}
      iconPosition="start"
      type="search"
      variant="search"
      propStyles={styles.search_input}
      autoCompleteText={restProps.autoCompleteText}
    />
  );
});

InputSearch.displayName = 'InputSearch';
