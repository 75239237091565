import { onError } from '@apollo/client/link/error';
import { graphqlErrorList } from '@commons/graphqlErrors';
import { AUTH_ERROR_MESSAGE, ERROR_SPECIFICATIONS } from '@constants/errorCodes';
import { emit } from '@modules/emitter';
import { Fail } from '@modules/fail';
import { ExtendedNetworkError } from '@commons/graphql';
import { handleInconsistentExpressState, resolveNetworkError } from '@graphql/links/utils';

export const apiErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  (Array.isArray(graphQLErrors) ? graphQLErrors : []).forEach(({ message, extensions }) => {
    if (
      message !== AUTH_ERROR_MESSAGE &&
      (graphqlErrorList.includes(extensions.classification) ||
        graphqlErrorList.includes(operation.extensions.classification))
    ) {
      emit(
        Fail({
          message: extensions.classification,
        }),
      );
    }
    if (extensions.classification === ERROR_SPECIFICATIONS.INCONSISTENT_EXPRESS_STATE) {
      return handleInconsistentExpressState(operation, forward);
    }
  });

  if (networkError) {
    return resolveNetworkError({
      networkError: networkError as ExtendedNetworkError,
      operation,
      forward,
    });
  }
});
