import { SettingsQuery } from '@api';
import { DEFAULT_CHEF_TABLE_EMAIL, CUSTOMER_SERVICE_PHONE_NUMBER } from '@constants/account';

export const getSettings = (data?: SettingsQuery) => {
  return {
    expressNotificationCountThreshold: data?.settings?.expressNotificationCountThreshold ?? 0,
    // Default value get from story: https://freshdirectconfluence.atlassian.net/wiki/spaces/TEVonA/pages/409600001/mvpp+R11.1+ATC+Log-in+reminder
    atcRetentionThreshold: data?.settings?.atcRetentionThreshold ?? 2,
    chefTablePhoneNumber:
      data?.settings?.chefTablePhoneNumber?.replace('1-', '') ?? CUSTOMER_SERVICE_PHONE_NUMBER,
    chefTableEmail: data?.settings?.chefTableEmail ?? DEFAULT_CHEF_TABLE_EMAIL,
    features: data?.settings?.features ?? [],
  };
};
