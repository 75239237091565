import dynamic from 'next/dynamic';
import { useAuthModalContext } from '@context/AuthModalContext';

const DynamicModal = dynamic(() => import('./AuthModal'), {
  ssr: false,
});

export const DynamicAuthModal = () => {
  const { isAuthModalOpen } = useAuthModalContext();

  return isAuthModalOpen ? <DynamicModal /> : null;
};
