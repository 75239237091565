import { useEffect, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { BACKGROUND_MODE } from '@commons/modals';
import { useLocalization } from '@hooks/useLocalization';
import { usePaymentMethod } from '@hooks/usePaymentMethods/usePaymentMethod';
import { useEditEbtCard } from '@hooks/payments/useEditEbtCard';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { useModal } from '@hooks/common';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLoadHtml } from '@hooks/useLoadHtml/useLoadHtml';
import { Button, FormikInput } from '@components/UI';
import { PhoneField, RadioGroupField } from '@components/FormikFields';
import { ALERT_TYPES } from '@commons/account';
import { LEARN_MORE_MODAL_CONTENT } from '@constants/payments';
import {
  FIELDS,
  NEW_ADDRESS,
  SetFieldValue,
  useEbtTabContent,
} from '@components/PaymentsAddTabs/components/EbtTabContent/useEbtTabContent';
import { DEFAULT_COUNTRY_NAME, DEFAULT_STATE_NAME } from '@constants/delivery';
import {
  getBillingAddress,
  getNewBillingAddress,
} from '@components/PaymentsAddTabs/components/EbtTabContent/getters';
import { NewAddressFields } from '@components/PaymentsAddTabs/components/EbtTabContent/NewAddressFields';
import { RecaptchaDisclaimer } from '@components/RecaptchaDisclaimer/RecaptchaDisclaimer';
import styles from './EditEbtCardModal.module.scss';

interface EditEbtCardModalProps {
  opened: boolean;
  closeModal: () => void;
  id: string;
  backgroundMode?: BACKGROUND_MODE;
}

export const EditEbtCardModal = ({
  opened,
  closeModal,
  id,
  backgroundMode,
}: EditEbtCardModalProps) => {
  const { t } = useLocalization('account');
  const { data: editCardInfo } = usePaymentMethod(id);
  const [editEbtCard] = useEditEbtCard();
  const { dispatchAlert } = useAccountAlertContext();
  const [errorMessage, setErrorMessage] = useState('');
  const learnMoreModal = useModal();
  const htmlText = useLoadHtml(LEARN_MORE_MODAL_CONTENT);
  const [addAddressClicked, setAddAddressClicked] = useState(false);
  const { countryOptions, stateOptions, validate, addressOptions, addresses } = useEbtTabContent();
  // must compare address fields cause billing address does not have id
  const prevChosenAddress = addresses.find(
    ({ address }) =>
      address.address1 === editCardInfo.billingAddress.address1 &&
      address.apartment === editCardInfo.billingAddress.apartment &&
      address.city === editCardInfo.billingAddress.city &&
      address.state === editCardInfo.billingAddress.state &&
      address.zipCode === editCardInfo.billingAddress.zipCode,
  );
  const initialAddressId =
    prevChosenAddress?.address?.id ?? addressOptions[addressOptions.length - 1].value;

  useEffect(() => {
    if (initialAddressId !== NEW_ADDRESS) {
      setAddAddressClicked(false);
    } else {
      setAddAddressClicked(true);
    }
  }, [initialAddressId]);

  const initialValues = {
    [FIELDS.CARDHOLDER]: editCardInfo.nameOnCard,
    [FIELDS.CARD_NUMBER]: `•••• •••• •••• ${editCardInfo.cardNumber}`,
    [FIELDS.PHONE]: editCardInfo.phoneNumber,
    [FIELDS.BILLING_ADDRESS]: initialAddressId,
    [FIELDS.NEW_BILLING_ADDRESS]: editCardInfo.billingAddress.address1,
    [FIELDS.COUNTRY]: editCardInfo.billingAddress.country || DEFAULT_COUNTRY_NAME,
    [FIELDS.CITY]: editCardInfo.billingAddress.city,
    [FIELDS.ZIP_CODE]: editCardInfo.billingAddress.zipCode,
    [FIELDS.APARTMENT]: editCardInfo.billingAddress.apartment,
    [FIELDS.STATE]: editCardInfo.billingAddress.state || DEFAULT_STATE_NAME,
  };

  const handleSubmit = async (values: { [key in FIELDS]: string }) => {
    const deliveryAddress = addresses.find((value) => value.address.id === values.billing_address);
    const billingAddressDTO = addAddressClicked
      ? getNewBillingAddress(values)
      : getBillingAddress(deliveryAddress);
    const input = {
      id,
      cardHolderName: values[FIELDS.CARDHOLDER],
      billingAddress: billingAddressDTO,
      phone: values[FIELDS.PHONE],
    };
    await editEbtCard({
      variables: {
        input,
      },
      onCompleted: (res) => {
        if (!!res?.editEbt?.validationErrors?.length) {
          setErrorMessage(res.editEbt.validationErrors[0].error);
          return;
        }

        dispatchAlert(
          ALERT_TYPES.SUCCESS,
          t('payments.successMessages.editCard', {
            cardType: editCardInfo.cardType,
            cardNumber: editCardInfo.cardNumber,
          }),
        );
        closeModal();
      },
    });
  };

  const handleBillingAddressChange = (value: string, setField: SetFieldValue) => {
    if (value === NEW_ADDRESS) {
      setField(FIELDS.BILLING_ADDRESS, value);
      setAddAddressClicked(true);
      return;
    }
    setField(FIELDS.BILLING_ADDRESS, value);
    setAddAddressClicked(false);
  };

  return (
    <ModalWindow
      title={t('payments.editEbtCard')}
      open={opened}
      className={styles.edit_modal}
      onClose={closeModal}
      backgroundMode={backgroundMode}
      errorMessage={errorMessage}
    >
      <>
        <div className={styles.header}>
          <Typography variant="body" className={styles.title}>
            {t('payments.modalTabs.ebtTitle')}
          </Typography>
          <Button onClick={learnMoreModal.openModal} variant="underline" className={styles.link}>
            <Typography variant="body">{t('payments.modalTabs.ebtLink')}</Typography>
          </Button>
          <ModalWindow onClose={learnMoreModal.closeModal} open={learnMoreModal.isOpen}>
            <div
              dangerouslySetInnerHTML={{
                __html: htmlText,
              }}
            />
          </ModalWindow>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          enableReinitialize
        >
          {({ isValid, values, errors, setFieldValue, validateField }) => {
            return (
              <Form>
                <Field
                  className={styles.input}
                  label={t('payments.editModal.cardholder')}
                  type="text"
                  name={FIELDS.CARDHOLDER}
                  component={FormikInput}
                  value={values[FIELDS.CARDHOLDER]}
                  validate={validate[FIELDS.CARDHOLDER]}
                  error={!!errors[FIELDS.CARDHOLDER]}
                  helperText={errors[FIELDS.CARDHOLDER]}
                  onBlur={() => validateField(FIELDS.CARDHOLDER)}
                  id={FIELDS.CARDHOLDER}
                />
                <Field
                  control="input"
                  type="text"
                  className={styles.input}
                  label={t('payments.editModal.cardNumber')}
                  name={FIELDS.CARD_NUMBER}
                  component={FormikInput}
                  readOnly
                  id={FIELDS.CARD_NUMBER}
                />
                <Field
                  name={FIELDS.PHONE}
                  label={t(`payments.editModal.phone`)}
                  component={PhoneField}
                  value={values.phone}
                  validate={validate[FIELDS.PHONE]}
                  error={!!errors[FIELDS.PHONE]}
                  helperText={errors[FIELDS.PHONE]}
                  onBlur={() => validateField(FIELDS.PHONE)}
                  id={FIELDS.PHONE}
                />
                <Divider className={styles.divider} />
                <div className={styles.addresses_wrapper}>
                  <Typography className={styles.address_header} variant="h5">
                    {t('payments.editModal.billingAddress')}
                  </Typography>
                  <Field
                    name={FIELDS.BILLING_ADDRESS}
                    component={RadioGroupField}
                    options={addressOptions.map(({ value, label }) => ({
                      value,
                      label: (
                        <Typography variant="body" className={styles.address}>
                          {label}
                        </Typography>
                      ),
                    }))}
                    value={values[FIELDS.BILLING_ADDRESS]}
                    onChange={(value: string) => handleBillingAddressChange(value, setFieldValue)}
                  />
                  {addAddressClicked && (
                    <NewAddressFields
                      validate={validate}
                      validateField={validateField}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      countryOptions={countryOptions}
                      stateOptions={stateOptions}
                    />
                  )}
                </div>
                <Button
                  type="submit"
                  size="large"
                  fullWidth
                  onClick={() => {
                    !isValid &&
                      dispatchAlert(ALERT_TYPES.ERROR, t('preferences.alerts.message.userName'));
                  }}
                  className={styles.button}
                >
                  {t('payments.updatePaymentMethod')}
                </Button>
                <RecaptchaDisclaimer />
              </Form>
            );
          }}
        </Formik>
      </>
    </ModalWindow>
  );
};
