import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_ERROR_FRAGMENT } from '@graphql/fragments/account/actionError';
import { ACCOUNT_ACTION_WARNING_FRAGMENT } from '@graphql/fragments/account/actionWarning';

export const ACCOUNT_ACTION_RESULT_FRAGMENT = gql`
  fragment actionResultFragment on ActionResult {
    success
    warnings {
      ...actionWarningFragment
    }
    errors {
      ...actionErrorFragment
    }
  }
  ${ACCOUNT_ACTION_ERROR_FRAGMENT}
  ${ACCOUNT_ACTION_WARNING_FRAGMENT}
`;
