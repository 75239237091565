import React, { RefObject } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { bulkInitialValues } from '@components/Modals/GiftCardModals/constants';
import { EMAIL_REGEX, MAX_BULK_GIFT_CARD_QTY_AMOUNT } from '@utils/validation';
import { EditView } from '@components/Modals/GiftCardModals/components/EditView/EditView';
import { PurchaseView } from '@components/Modals/GiftCardModals/components/PurchaseView/PurchaseView';
import { Values, GIFTCARD_FIELDS } from '@components/Modals/GiftCardModals/types';
import { useLocalization } from '@hooks/useLocalization';
import { UseGiftCardPurchaseInfoData } from '@hooks/giftCard/useGiftCardPurchaseInfo';
import { Button } from '@components/UI';
import styles from './BulkGiftCardForm.module.scss';

interface BulkGiftCardFormProps {
  values?: Values;
  templateData: UseGiftCardPurchaseInfoData;
  onSubmit: (values: Values) => void;
  isCustomAmountEditShown?: boolean;
  onCloseCustomAmountForm: () => void;
  onOpenCustomAmountForm: () => void;
  loading: boolean;
  submitButtonText: string;
  formRef: RefObject<FormikProps<Values>>;
}

export const BulkGiftCardForm = ({
  values,
  templateData,
  isCustomAmountEditShown,
  onCloseCustomAmountForm,
  onOpenCustomAmountForm,
  onSubmit,
  loading,
  submitButtonText,
  formRef,
}: BulkGiftCardFormProps) => {
  const { t } = useLocalization('giftCards');
  const handleCustomAmountClick = () => {
    formRef.current?.setFieldTouched(
      GIFTCARD_FIELDS.GIFTCARD_CUSTOM_AMOUNT,
      !!formRef.current?.values[GIFTCARD_FIELDS.GIFTCARD_CUSTOM_AMOUNT],
    );
    onOpenCustomAmountForm();
  };
  return (
    <Formik
      initialValues={values ?? bulkInitialValues}
      validateOnChange={false}
      validateOnBlur={true}
      innerRef={formRef}
      onSubmit={(formikValues) => {
        onSubmit({ ...formikValues });
      }}
      validationSchema={Yup.object({
        senderName: Yup.string().required(t('giftCardModal.errors.required')),
        senderEmail: Yup.string()
          .matches(EMAIL_REGEX, t('giftCardModal.errors.incompleteEmail'))
          .required(t('giftCardModal.errors.required')),
        recipientMessage: Yup.string().optional(),
        giftCardAmount: Yup.string().required(t('giftCardModal.errors.giftCardAmount')),
        giftCardCustomAmount: Yup.number()
          [isCustomAmountEditShown ? 'required' : 'optional'](t('giftCardModal.errors.required'))
          .integer(t('giftCardModal.errors.integer'))
          .min(
            templateData.minAmount,
            t('giftCardModal.errors.minAmount', { value: templateData.minAmount }),
          )
          .max(
            templateData.maxAmount,
            t('giftCardModal.errors.maxAmount', { value: templateData.maxAmount }),
          )
          .typeError(t('giftCardModal.errors.badInput')),
        quantity: Yup.number()
          .min(1, t('giftCardModal.errors.quantityInput', { value: 1 }))
          .max(
            MAX_BULK_GIFT_CARD_QTY_AMOUNT,
            t('giftCardModal.errors.quantityMaxInput', { value: MAX_BULK_GIFT_CARD_QTY_AMOUNT }),
          )
          .required(t('giftCardModal.errors.required')),
        giftCardTemplateId: Yup.string(),
      })}
      enableReinitialize
    >
      <Form>
        {isCustomAmountEditShown ? (
          <EditView onSave={onCloseCustomAmountForm} minCustomAmount={templateData.minAmount} />
        ) : (
          <PurchaseView
            templates={templateData.gcTemplates}
            loadingAmountOptions={loading}
            loadingTemplates={loading}
            amountOptions={templateData.gcAmtOptions}
            onClickCustomAmount={handleCustomAmountClick}
            addBulkGiftCard
          />
        )}
        {!isCustomAmountEditShown && (
          <Button type="submit" size="large" className={styles.submit_button} isFullWidth>
            {t(submitButtonText)}
          </Button>
        )}
      </Form>
    </Formik>
  );
};
