import { Skeleton } from '@mui/material';
import { trash as TrashIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { IconButton } from '@components/UI';
import styles from './ControlsContent.module.scss';

interface DeleteButtonProps {
  deleteInProgress: boolean;
  cartlineProductName?: string;
  onDelete: () => void;
}

export const DeleteButton = ({
  deleteInProgress,
  cartlineProductName,
  onDelete,
}: DeleteButtonProps) => {
  const { t } = useLocalization('components');

  if (deleteInProgress) {
    return <Skeleton className={styles.button_loader} variant="circular" width={36} height={36} />;
  }

  return (
    <IconButton
      data-testid="delete-button"
      size="medium"
      className={styles.button}
      onClick={onDelete}
      ariaLabel={`${t('cart:delete')} ${cartlineProductName}`}
    >
      <TrashIcon className={styles.icon} />
    </IconButton>
  );
};
