import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';

export const VALIDATION_DELIVERY_ADDRESS = gql`
  query ValidateDeliveryAddress($deliveryAddress: DeliveryAddressInput!) {
    validateDeliveryAddress(deliveryAddress: $deliveryAddress) {
      isUnattendedDelivery
      validationErrors {
        ...validationErrorFragment
      }
      actionResult {
        ...actionResultFragment
      }
      availableServiceTypes
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
`;
