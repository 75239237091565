import { Typography } from '@mui/material';
import cx from 'classnames';
import { Grid } from '@components/UI/Grid/Grid';
import { Title } from '@features/AccountPage/components/Title';
import styles from './Paragraph.module.scss';
import { ParagraphTypes } from './Paragraph.types';

export const Paragraph = ({
  title,
  hideTitle,
  isMainTitle,
  hideMargin,
  children,
}: ParagraphTypes) => (
  <>
    {!hideTitle && (
      <Grid item xs={12}>
        {isMainTitle ? (
          <Title className={styles.title}>{title}</Title>
        ) : (
          <Typography component="h4" className={cx(styles.title, styles.small_title)}>
            {title}
          </Typography>
        )}
      </Grid>
    )}
    <Grid
      item
      xs={12}
      sm={8}
      lg={7}
      xl={8}
      className={cx({ [styles.content_container]: !hideMargin })}
    >
      <Typography variant="body">{children}</Typography>
    </Grid>
  </>
);
