import { dateAdapter } from '@adapters/dateAdapter';
import { HandledDate } from '@commons/dates';

const { getShortWeekday, getHandledDate } = dateAdapter();

export const TODAY_TEXT = 'Today';
export const TOMORROW_TEXT = 'Tomorrow';
const todayDate = getHandledDate(new Date());

export const getWeekdayString = (date: HandledDate, callback = getShortWeekday) => {
  const isCurrentYear = todayDate.year === date.year;
  const isCurrentMonth = todayDate.month === date.month;
  const isToday = isCurrentYear && isCurrentMonth && todayDate.date === date.date;
  const isTomorrow = isCurrentYear && isCurrentMonth && todayDate.date + 1 === date.date;
  const weekDay = date.weekday;

  if (isToday) {
    return TODAY_TEXT;
  }
  if (isTomorrow) {
    return TOMORROW_TEXT;
  }

  return callback(weekDay);
};
