import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query Settings {
    settings {
      expressNotificationCountThreshold
      atcRetentionThreshold
      chefTablePhoneNumber
      chefTableEmail
      features {
        enabled
        name
        options {
          key
          value
        }
      }
    }
  }
`;
