import { useMutation } from '@apollo/client';
import { DeleteAccountWorkPhoneMutation, DeleteAccountWorkPhoneMutationVariables } from '@api';
import { DELETE_ACCOUNT_WORK_PHONE } from '@graphql/account/mutations/deleteAccountWorkPhone';
import { updateAccountPreferencesCache } from '@hooks/account/updateAccountPreferencesCache';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useDeleteWorkPhone = () => {
  const { updateAuthUser } = useAuthContext();

  const [deleteWorkPhoneMutation] = useMutation<
    DeleteAccountWorkPhoneMutation,
    DeleteAccountWorkPhoneMutationVariables
  >(DELETE_ACCOUNT_WORK_PHONE);

  const deleteWorkPhone = () => {
    return deleteWorkPhoneMutation({
      update(cache, { data }) {
        if (!data?.deleteAccountWorkPhone.success) return;
        const newWorkPhone = {
          workPhoneNumber: '',
          workPhoneExtension: '',
        };
        updateAccountPreferencesCache(cache, newWorkPhone);
        updateAuthUser(newWorkPhone);
      },
    });
  };

  return { deleteWorkPhone };
};
