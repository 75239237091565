import { useEffect } from 'react';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { venmoText as VenmoText } from '@assets/icons/payments';
import { EWalletType } from '@api';
import { renderVenmo } from '@utils/renderVenmo';
import { useInitEWallet } from '@hooks/payments/useInitEWallet';
import { useLinkEWallet } from '@hooks/payments';
import { VenmoPayload } from '@commons/venmo';
import { EWALLET_PAYMENT_TYPE } from '@commons/payment';
import { PaymentTabTitle } from '../PaymentTabTitle/PaymentTabTitle';
import styles from './VenmoTabContent.module.scss';

interface VenmoTabContentProps {
  onSuccess?: (type: EWALLET_PAYMENT_TYPE, message?: string) => void;
}

const VENMO_CONTAINER_ID = 'venmo_container';

export const VenmoTabContent = ({ onSuccess = () => void 0 }: VenmoTabContentProps) => {
  const { t } = useLocalization('account');
  const { token: venmoToken } = useInitEWallet(EWalletType.VE);
  const [linkEWallet] = useLinkEWallet();

  const handleVenmoApprove = (payload: VenmoPayload) => {
    const linkVenmoDTO = {
      eWalletType: EWalletType.VE,
      paymentMethodNonce: payload.nonce,
      email: payload.details.username,
    };

    linkEWallet({
      variables: {
        eWalletInput: linkVenmoDTO,
      },
      onCompleted: () => {
        onSuccess(EWALLET_PAYMENT_TYPE.VENMO, t('payments.successMessages.addVenmo'));
      },
    });
  };

  useEffect(() => {
    if (venmoToken) {
      renderVenmo(venmoToken, VENMO_CONTAINER_ID, handleVenmoApprove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venmoToken]);

  return (
    <>
      <PaymentTabTitle messageCode="payments.modalTabs.venmoTitle" className={styles.venmo_title} />
      <Button
        size="large"
        id={VENMO_CONTAINER_ID}
        className={styles.venmo_button}
        fullWidth
        aria-label={t('payments.modalTabs.venmoTitle')}
      >
        <VenmoText />
      </Button>
    </>
  );
};
