import { useState } from 'react';

export const useClearCartConfirmModal = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  return {
    isConfirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
  };
};
