import { Typography } from '@mui/material';
import cx from 'classnames';
import { useLocalization } from '@hooks/useLocalization';
import styles from './PaymentTabTitle.module.scss';

interface PaymentTabTitleProps {
  messageCode: string;
  className?: string;
}

export const PaymentTabTitle = ({ messageCode, className }: PaymentTabTitleProps) => {
  const { t } = useLocalization('account');
  const classNames = cx(styles.title, className);

  return <Typography className={classNames}>{t(messageCode)}</Typography>;
};
