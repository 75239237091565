import { Grid, Typography } from '@mui/material';
import { DepartmentItem, Maybe } from '@api';
import { CategoriesSectionGrid } from './CategoriesSectionGrid';
import { CategoryLink } from './CategoryLink';
import { CategoriesSectionSeeAllLinks } from './CategoriesSectionSeeAllLinks';
import { getSingleViewSectionRows } from './getters';
import { MAX_COLUMNS } from './constants';

interface CategoriesSectionSingleViewProps {
  department: DepartmentItem;
  showHeadlines?: boolean;
  onClick?: () => void;
  headlines: Array<Maybe<string> | undefined>;
}

export const CategoriesSectionSingleView = ({
  department: { dropDownCategories, url },
  showHeadlines = true,
  onClick,
  headlines,
}: CategoriesSectionSingleViewProps) => {
  if (!dropDownCategories) return null;
  const sectionColumns = MAX_COLUMNS;
  const { rowsCount, categories } = getSingleViewSectionRows(dropDownCategories, sectionColumns);
  const headline = headlines[0];

  return (
    <>
      <Grid key={JSON.stringify(categories)} item lg={sectionColumns} pl={5}>
        <Typography fontWeight="bold" variant="body" component="h2">
          {showHeadlines && headline}&nbsp;
        </Typography>
        <CategoriesSectionGrid rows={rowsCount} columns={sectionColumns}>
          {categories?.map(({ id: categoryId, name, url: categoryUrl }) => {
            return (
              <CategoryLink
                key={categoryId}
                name={name}
                tabIndex={-1}
                headline={headline}
                href={categoryUrl}
                onClick={onClick}
              />
            );
          })}
        </CategoriesSectionGrid>
      </Grid>
      <CategoriesSectionSeeAllLinks
        headlines={headlines}
        sectionColumns={sectionColumns}
        url={url}
      />
    </>
  );
};
