import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { getSeoData } from '@adapters/seoDataAdapter';
import { SeoDataQuery } from '@api';
import { GET_SEODATA } from '@graphql/common/queries/getSeoData';

export const useSeoData = () => {
  const { asPath } = useRouter();
  const { data, loading } = useQuery<SeoDataQuery>(GET_SEODATA, {
    variables: {
      pageUri: asPath,
    },
    fetchPolicy: 'cache-first',
  });

  return { data: getSeoData(data?.seoData), loading };
};
