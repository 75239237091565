import { Typography } from '@mui/material';
import { NextRouter, useRouter } from 'next/router';
import { Coupon } from '@components/UI/Coupon/Coupon';
import { getPrice } from '@utils/getPrice';
import { Product } from '@commons/product';
import { Coupon as CouponType, Discount, Variant } from '@commons/cart';
import { useLocalization } from '@hooks/useLocalization';
import { getSelectedProductsNames } from '@utils/getSelectedProductsNames';
import { routing } from '@constants/routing';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { CartTileQuantity } from '../../../components/CartTileQuantity/CartTileQuantity';
import { TileQuantity } from '../../../components/TileQuantity/TileQuantity';
import { isCouponAvailable } from './helpers';
import styles from './AdditionalInfo.module.scss';

const isCartOrModifyPage = (router: NextRouter, isSideBagDrawerOpen: boolean) => {
  return (
    router.pathname === routing.cart ||
    router.pathname.includes(routing.modifyOrder) ||
    isSideBagDrawerOpen
  );
};

export interface AdditionalInfoProps {
  product: Product;
  promoApplied: boolean;
  promoDescription: string;
  discount?: Discount;
  coupon?: CouponType;
  variants?: Variant[];
  selectedSalesUnitLabel?: string | null;
  configurationDescription?: string | null;
  sampleProduct?: boolean;
  isCartTileUpdating?: boolean;
}

export const AdditionalInfo = ({
  product,
  discount,
  coupon,
  promoApplied,
  promoDescription,
  variants,
  selectedSalesUnitLabel,
  configurationDescription,
  sampleProduct = false,
  isCartTileUpdating = false,
}: AdditionalInfoProps) => {
  const {
    roughPricePerUnit,
    defaultScaleUnit,
    pricePerScaleUnit,
    unitSize,
    unitPrice,
    salesUnits,
  } = product;
  const router = useRouter();
  const { t } = useLocalization('components');
  const selectedScaleUnit =
    (selectedSalesUnitLabel &&
      salesUnits?.find((salesUnit) => salesUnit.alternateSalesUnit === selectedSalesUnitLabel)
        ?.name) ||
    defaultScaleUnit;

  const hasGroupScale = !!(discount?.discountPrice?.value && discount?.discountPrice?.currency);
  const shouldRenderGroupScale = hasGroupScale && !promoApplied;
  const selectedProductsNames = getSelectedProductsNames(variants, product);
  const couponErrorMessage = t('couponQuantityNotMet', { quantity: coupon?.quantity });
  const shouldShowCoupons = !!coupon?.couponId && isCouponAvailable(coupon.displayStatus.name);
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();

  if (sampleProduct) {
    return (
      <Typography component="span" className={styles.sample_product_text}>
        {t('sampleProduct')}
      </Typography>
    );
  }

  return (
    <div className={styles.additional_info}>
      <div className={styles.cart_quantity}>
        {isCartOrModifyPage(router, isSideBagDrawerOpen) ? (
          <CartTileQuantity
            unitPrice={unitPrice}
            roughPricePerUnit={roughPricePerUnit}
            pricePerScaleUnit={pricePerScaleUnit}
            formattedCurrentPrice={product.formattedCurrentPrice}
            configurationDescription={configurationDescription}
          />
        ) : (
          <TileQuantity
            unitSize={unitSize}
            unitPrice={unitPrice}
            roughPricePerUnit={roughPricePerUnit}
            selectedScaleUnit={selectedScaleUnit}
            defaultScaleUnit={defaultScaleUnit}
            pricePerScaleUnit={pricePerScaleUnit}
            selectedProductsNames={selectedProductsNames}
            formattedCurrentPrice={product.formattedCurrentPrice}
          />
        )}
        {promoApplied && (
          <div className={styles.promo_container}>
            {promoApplied && discount?.description && (
              <Typography component="span" className={styles.promo_discount_description}>
                {discount?.description}{' '}
              </Typography>
            )}
            {promoApplied && promoDescription && (
              <Typography component="span" variant="smallBody" className={styles.promo_description}>
                {promoDescription}
              </Typography>
            )}
          </div>
        )}
      </div>

      {shouldShowCoupons && (
        <Coupon
          productId={product.productId}
          className={styles.coupon}
          title={coupon?.displayDescription}
          id={coupon.couponId}
          isActive={coupon.isActive}
          errorMessage={coupon.hasError ? couponErrorMessage : ''}
          prompt={coupon.detailedDescription}
          expirationDate={coupon.expirationDate}
          highlighted={!coupon.isActive}
          isCartTileUpdating={isCartTileUpdating}
        />
      )}
      {shouldRenderGroupScale && (
        <div className={styles.group_scale}>
          <Typography component="span" variant="smallBody" className={styles.group_scale_label}>
            {t('cartTile.groupDiscount.label')}
          </Typography>{' '}
          <Typography component="span" variant="smallBody" className={styles.group_scale_value}>
            {discount?.skuLimit > 0 && t('cartTile.groupDiscount.count', [discount?.skuLimit])}{' '}
            {t('cartTile.groupDiscount.save', [
              discount?.discountPrice?.value &&
                getPrice(discount?.discountPrice?.value, discount?.discountPrice?.currency),
            ])}
          </Typography>
        </div>
      )}
    </div>
  );
};
