import cx from 'classnames';
import { IconButton } from '@components/UI';
import { trash as TrashIcon, pencil as PencilIcon } from '@assets/icons/system';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { GiftCard } from '@adapters/giftCardAdatper';
import { MAX_BULK_GIFT_CARD_QTY_AMOUNT } from '@utils/validation';
import styles from './GiftCardControllers.module.scss';
import { QuantityCounter } from './QuantityCounter/QuantityCounter';

export interface AlertType {
  active: boolean;
  message: string;
}
interface GiftCardControllersProps {
  giftCard: GiftCard;
  onDeleteIconClick: (giftCardId: string) => void;
  onEditIconClick: (giftCard: GiftCard) => void;
  bulk?: boolean;
  disableQuantityPlus?: boolean;
}

export const GiftCardControllers = ({
  giftCard,
  onEditIconClick,
  onDeleteIconClick,
  bulk = false,
  disableQuantityPlus = false,
}: GiftCardControllersProps) => {
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();
  return (
    <div
      className={cx(styles.controllers, {
        [styles.drawer_opened]: isSideBagDrawerOpen,
      })}
    >
      <div className={styles.buttons_wrapper}>
        <IconButton
          data-testid="edit-button"
          size="medium"
          className={styles.button}
          onClick={() => {
            onEditIconClick(giftCard);
          }}
        >
          <PencilIcon className={styles.icon} />
        </IconButton>
        <IconButton
          size="medium"
          className={styles.button}
          onClick={() => {
            onDeleteIconClick(giftCard.id);
          }}
        >
          <TrashIcon className={styles.icon} />
        </IconButton>
        {bulk && (
          <QuantityCounter
            className={styles.counter}
            onDelete={onDeleteIconClick}
            giftCard={giftCard}
            maxValue={MAX_BULK_GIFT_CARD_QTY_AMOUNT}
            isPlusDisabled={disableQuantityPlus}
            buttonArialLabel="gift card"
          />
        )}
      </div>
      <span className={styles.price}>{giftCard.displayPrice.formattedPrice}</span>
    </div>
  );
};
