import { Typography } from '@mui/material';
import { Media } from '@components/Media';
import styles from './ReferAFriendFooter.module.scss';

interface ReferAFriendFooterProps {
  rafPromoData?: {
    promotionActivationThreshold: number;
    legalInfo: string;
    refererCredit?: number;
  };
  inviteSuccessCallback?: () => void;
}

export const ReferAFriendFooter = ({ rafPromoData }: ReferAFriendFooterProps) => {
  const mediaContext = {
    credit: '$' + (rafPromoData?.refererCredit || 50),
    promoThreshold: '$' + (rafPromoData?.promotionActivationThreshold || 99),
  };

  return (
    <>
      <Media
        url="/media/editorial/site_pages/raf/raf_footer.html"
        context={mediaContext}
        className={styles.footer}
      />
      {rafPromoData?.legalInfo && (
        <div className={styles.terms_and_conditions}>
          <Typography className={styles.terms_and_conditions_title} component="p">
            {rafPromoData.legalInfo}
          </Typography>
        </div>
      )}
    </>
  );
};
