import { useEffect } from 'react';
import { useLocalization } from '@hooks/useLocalization';
import { EWalletType } from '@api';
import { useInitEWallet } from '@hooks/payments/useInitEWallet';
import { useLinkEWallet } from '@hooks/payments';
import { EWALLET_PAYMENT_TYPE } from '@commons/payment';
import { PaypalLinkResult } from '@commons/paypal';
import { usePaypalButton } from '@hooks/paypal';
import { PaymentTabTitle } from '../PaymentTabTitle/PaymentTabTitle';

interface PaypalTabContentProps {
  onSuccess?: (type: EWALLET_PAYMENT_TYPE, message?: string) => void;
}

const PAYPAL_CONTAINER_ID = 'paypal_container';

export const PaypalTabContent = ({ onSuccess = () => void 0 }: PaypalTabContentProps) => {
  const { t } = useLocalization('account');
  const { renderPaypalButton } = usePaypalButton();
  const { token: paypalToken } = useInitEWallet(EWalletType.PP);
  const [linkEWallet] = useLinkEWallet();

  const handlePaypalApprove = (payload: PaypalLinkResult) => {
    const linkPaypalDTO = {
      eWalletType: EWalletType.PP,
      paymentMethodNonce: payload.nonce,
      email: payload.details.email,
      firstName: payload.details.firstName,
      lastName: payload.details.lastName,
    };

    linkEWallet({
      variables: {
        eWalletInput: linkPaypalDTO,
      },
      onCompleted: () => {
        onSuccess(EWALLET_PAYMENT_TYPE.PAYPAL, t('payments.successMessages.addPaypal'));
      },
    });
  };

  useEffect(() => {
    if (paypalToken) {
      renderPaypalButton(paypalToken, `#${PAYPAL_CONTAINER_ID}`, handlePaypalApprove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paypalToken]);

  return (
    <>
      <PaymentTabTitle messageCode="payments.modalTabs.paypalTitle" />
      <div id={PAYPAL_CONTAINER_ID} />
    </>
  );
};
