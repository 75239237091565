import React, { ReactElement, SVGProps, useEffect } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { useLazyReferAFriend } from '@hooks/referAFriend/useReferAFriend';
import { BaseLink as Link, Button } from '@components/UI';
import { useContactUsModal } from '@context/contactUsModalContext';
import { ACCOUNT_MANAGEMENT_INFO, ACCOUNT_MANAGEMENT_PAGE } from '@commons/account';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { useDeliveryPassInfo } from '@hooks/deliveryPass/useDeliveryPassInfo';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { useAccountPageContext } from '@features/AccountPage/context/AccountPageContext';
import { routing } from '@constants/routing';
import styles from './AccountMenu.module.scss';

export enum CALLBACK {
  OPEN_CONTACT_US = 'OPEN_CONTACT_US',
  OPEN_CHAT = 'OPEN_CHAT',
}

export interface DropDownLink {
  name: string;
  icon: (props: SVGProps<SVGElement>) => ReactElement;
  id: ACCOUNT_MANAGEMENT_PAGE | ACCOUNT_MANAGEMENT_INFO;
  link: string;
  callback?: CALLBACK;
}

export interface DropDownInfoLink {
  name: string;
  icon: (props: SVGProps<SVGElement>) => ReactElement;
  id: ACCOUNT_MANAGEMENT_INFO | ACCOUNT_MANAGEMENT_PAGE;
  link: string;
  callback?: CALLBACK;
}

export interface RenderListDropDownProps {
  list: Array<DropDownLink | DropDownInfoLink>;
  onSelect?: () => void;
}

export const AccountMenuSection = ({ list, onSelect }: RenderListDropDownProps) => {
  const router = useRouter();
  const { fetchPageData } = useAccountPageContext();
  const { emit } = useContactUsModal();
  const {
    data: { wasMembershipActivated },
  } = useDeliveryPassInfo();
  const {
    minimumAccountPreferences: { isChefTableAvailable },
  } = useMinimumAccountPreferences();
  const createCallback = (callback: CALLBACK) => {
    if (callback === CALLBACK.OPEN_CONTACT_US) {
      onSelect?.();
      return emit('open');
    }
  };

  const { refetch: fetchReferAFriend, data: referAFriendData } = useLazyReferAFriend();
  const isReferAFriendEnabled = isFeatureEnabled(DYNAMIC_FEATURES.REFER_A_FRIEND);
  const showRaf = referAFriendData?.referAFriend?.enabled && isReferAFriendEnabled;

  useEffect(() => {
    if (isReferAFriendEnabled) {
      fetchReferAFriend();
    }
  }, [fetchReferAFriend, isReferAFriendEnabled]);

  const handleNavigate = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: ACCOUNT_MANAGEMENT_PAGE | ACCOUNT_MANAGEMENT_INFO,
  ) => {
    const shouldFetchAccountPage =
      router.pathname.includes(routing.account) &&
      Object.values<string>(ACCOUNT_MANAGEMENT_PAGE).includes(id);
    if (shouldFetchAccountPage) {
      e.preventDefault();
      fetchPageData(id as ACCOUNT_MANAGEMENT_PAGE, true);
    }
    onSelect?.();
  };

  const buttonClasses = cx(styles.item, 'button');
  const linkClasses = cx(styles.item, 'link');

  return (
    <>
      {list.map(({ name, icon: Icon, link, callback, id }, index) => {
        if (id === ACCOUNT_MANAGEMENT_PAGE.DELIVERY_PASS && !wasMembershipActivated) return null;
        if (id === ACCOUNT_MANAGEMENT_PAGE.CHEF_TABLE && !isChefTableAvailable) return null;
        if (id === ACCOUNT_MANAGEMENT_PAGE.REFER_A_FRIEND && !showRaf) return null;

        const isLastElement = index === list.length - 1;

        return callback ? (
          <li
            data-qa={`menu-account-${id}`}
            className={cx(styles.listItem, buttonClasses, {
              [styles.withBorder]: isLastElement,
            })}
            key={id}
          >
            <Button
              className={buttonClasses}
              variant="underline"
              onClick={() => createCallback(callback)}
              role="menuitem"
            >
              <Icon height={18} width={18} />
              <span>{name}</span>
            </Button>
          </li>
        ) : (
          <li
            data-qa={`menu-account-${id}`}
            className={cx(styles.listItem, linkClasses, {
              [styles.withBorder]: isLastElement,
            })}
            key={id}
          >
            <Link legacyBehavior href={link} passHref>
              <a
                href="replaced_with_passHref"
                className={linkClasses}
                onClick={(e) => handleNavigate(e, id)}
                role="menuitem"
              >
                <Icon height={18} width={18} />
                <span>{name}</span>
              </a>
            </Link>
          </li>
        );
      })}
    </>
  );
};
