import { deleteCookie, hasCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

const LEGAL_AGREEMENT_COOKIE = 'freshdirect.healthwarning';

const getLegalAgreementScopedCookie = (scopeId?: string) =>
  scopeId ? `${LEGAL_AGREEMENT_COOKIE}.scope.${scopeId}` : LEGAL_AGREEMENT_COOKIE;

export const hasLegalAgreement = (options?: OptionsType, scopeId?: string) => {
  const scopedCookie = getLegalAgreementScopedCookie(scopeId);

  return hasCookie(scopedCookie, options);
};

export const setLegalAgreement = (scopeId?: string) => {
  const scopedCookie = getLegalAgreementScopedCookie(scopeId);

  return setCookie(scopedCookie, 1);
};

export const removeLegalAgreement = (scopeId?: string) => {
  const scopedCookie = getLegalAgreementScopedCookie(scopeId);

  deleteCookie(scopedCookie);
};
