import { CategoryData, DropDownCategory, Maybe } from '@api';
import { MAX_COLUMNS, MIN_CATEGORY_PER_COLUMN } from './constants';

const getCategoryData = (dropDownCategory: DropDownCategory) =>
  dropDownCategory.categoryMap?.flatMap(({ categories }) => categories ?? []) ?? [];

export const getHeadline = (dropDownCategory: DropDownCategory) =>
  dropDownCategory?.categoryMap?.find(({ headline }) => Boolean(headline?.trim()))?.headline;

export const getSectionColumns = (dropDownCategories: DropDownCategory[]) =>
  Math.floor(MAX_COLUMNS / dropDownCategories.length);

export const getSectionRows = (
  categories: Maybe<Array<CategoryData>> | undefined,
  sectionColumns: number,
) => {
  if (!categories) return MIN_CATEGORY_PER_COLUMN;
  const countedRows = Math.ceil(categories.length / sectionColumns);
  return countedRows > 2 ? countedRows : MIN_CATEGORY_PER_COLUMN;
};

export const getSingleViewSectionRows = (
  dropDownCategories: DropDownCategory[],
  sectionColumns: number,
) => {
  const mergedCategories = dropDownCategories.flatMap((dropDownCategory) =>
    getCategoryData(dropDownCategory),
  );
  const calculatedRowsCount = Math.ceil(mergedCategories.length / sectionColumns);
  return {
    rowsCount: calculatedRowsCount < 2 ? MIN_CATEGORY_PER_COLUMN : calculatedRowsCount,
    categories: mergedCategories,
  };
};
