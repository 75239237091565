import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const REPLACE_CART_ITEM = gql`
  mutation ReplaceCartItem($cartLineId: ID!, $addToCartInput: AddToCartDataInput!) {
    addResponse: addToCart(addToCartInput: $addToCartInput) {
      validationResult {
        success
      }
      atcEventIds
    }
    deleteResponse: deleteCartLine(cartLineId: $cartLineId) {
      ...cartFragment
    }
  }
  ${CART_FRAGMENT}
`;
