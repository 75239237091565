import { QueryHookOptions } from '@apollo/client';
import { accountAdapter } from '@adapters/accountAdapter';
import { useUserDeliveryAddresses } from '@hooks/deliveryAddress/useUserDeliveryAddresses';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';
import {
  AnonUserDeliveryAddressesQuery,
  AnonUserDeliveryAddressesQueryVariables,
  StatusLevelType,
  UserDeliveryAddressesProtectedQuery,
  UserDeliveryAddressesProtectedQueryVariables,
} from '@api';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';

interface UseSelectedAddressArgs {
  queryOptions?: QueryHookOptions<
    UserDeliveryAddressesProtectedQuery | AnonUserDeliveryAddressesQuery,
    UserDeliveryAddressesProtectedQueryVariables | AnonUserDeliveryAddressesQueryVariables
  >;
  forGuestUser?: boolean;
  isStandingOrder?: boolean;
}

const getSelectedAddress = (
  data?: AnonUserDeliveryAddressesQuery | UserDeliveryAddressesProtectedQuery,
  isStandingOrder?: boolean,
  isCosEnabled?: boolean,
) => {
  if (data && data.userDeliveryAddresses) {
    const { temporaryAddress, homeAddresses, corpAddresses, selectedAddress } =
      data.userDeliveryAddresses;
    const checkAreAddressesEmpty = () => {
      if (isStandingOrder) return !corpAddresses?.length;
      if (isCosEnabled) return !corpAddresses?.length && !homeAddresses?.length;
      return !homeAddresses.length;
    };
    const isEmptyAddresses = checkAreAddressesEmpty();
    const isEmptyTemporaryAddress = !temporaryAddress;
    const isPickUpAddress =
      selectedAddress?.address?.serviceType?.toUpperCase() === SERVICE_TYPE.PICKUP;
    const isEmptySelectedAddress = isEmptyAddresses && isEmptyTemporaryAddress && !isPickUpAddress;

    if (isEmptySelectedAddress) {
      return accountAdapter().getDeliveryAddress(null);
    }
  }
  return accountAdapter().getDeliveryAddress(data?.userDeliveryAddresses?.selectedAddress);
};

export const useSelectedAddress = ({
  queryOptions = {},
  forGuestUser,
  isStandingOrder = false,
}: UseSelectedAddressArgs = {}) => {
  const { userStatus } = useAuthContext();
  const { getFeature } = useHeaderContext();
  const cosFeatureEnabled = !!getFeature?.(FEATURE.COS);
  const isSignedIn = userStatus === StatusLevelType.SIGNED_IN;
  const { data, error, ...rest } = useUserDeliveryAddresses({
    queryOptions: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      ...queryOptions,
    },
    forLoggedInUser: forGuestUser ? false : isSignedIn,
  });

  return {
    ...rest,
    data: getSelectedAddress(data, isStandingOrder, cosFeatureEnabled),
    rawData: data,
    error,
  };
};
