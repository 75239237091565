import { setCookie, getCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';
import { Banner, StringBannerListPair } from '@api';
import { COOKIES_LIST } from '@commons/cookies';

let bannerSequenceNumber: number | null = null;

const updateBannerSequenceCookie = () => {
  setCookie(COOKIES_LIST.BANNERSQNR, (bannerSequenceNumber || 0).toString(), {
    maxAge: 3600 * 24 * 365,
  });
};

const initializeSequenceCounter = (ctx?: GetServerSidePropsContext) => {
  if (bannerSequenceNumber === null) {
    bannerSequenceNumber = (Number(getCookie(COOKIES_LIST.BANNERSQNR, ctx)) || 0) + 1;

    if (typeof window !== 'undefined') {
      // @ts-expect-error navigation is missing from window declaration
      window.navigation.addEventListener('navigate', (e) => {
        if (e?.navigationType !== 'replace') {
          bannerSequenceNumber = (bannerSequenceNumber || 0) + 1;
          updateBannerSequenceCookie();
        }
      });
      updateBannerSequenceCookie();
    }
  }
};

export const setBannerSequenceNumber = (sequenceNumber: number) => {
  initializeSequenceCounter();
  bannerSequenceNumber = sequenceNumber + 1;
  updateBannerSequenceCookie();
};

export const getBannerSequenceNumber = (ctx?: GetServerSidePropsContext): number => {
  initializeSequenceCounter(ctx);
  return bannerSequenceNumber || 0;
};

export const updateBanners = (banners: Array<StringBannerListPair>) => {
  banners.forEach((b: StringBannerListPair) => {
    if (!!b.value) {
      if (b.value.length > 0) {
        BANNERCACHE.banners.set(b.key, b.value);
      } else {
        BANNERCACHE.banners.delete(b.key);
      }
    }
  });
};

export const checkBannerCache = (path: string) => {
  if (BANNERCACHE.path !== path) {
    BANNERCACHE.path = path;
    return true;
  }
  return false;
};

export const serializeBanners = () => {
  const banners: Array<StringBannerListPair> = [];
  BANNERCACHE.banners.forEach((value, key) => {
    banners.push({ key, value });
  });
  return banners;
};

export const BANNERCACHE = {
  path: '',
  banners: new Map<string, Banner[]>(),
};
