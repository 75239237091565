
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.scss';
import 'swiper/scss';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CookieValueTypes } from 'cookies-next';
import { MasqueradeQueryParams } from '@modules/masquerade-query-params';
import { getApolloClient } from '@graphql/client';
import { AuthModalProvider } from '@context/AuthModalContext/AuthModalProvider';
import { DynamicFeatureProvider } from '@context/DynamicFeatureContext/DynamicFeatureProvider';
import { LAYOUTS } from '@commons/layouts';
import { WelcomeHandler } from '@layouts/Welcome/WelcomeHandler';
import { RequiredScripts } from '@components/RequiredScripts/RequiredScripts';
import { ContentPaintPriorityIncreaser } from '@components/ContentPaintPriorityIncreaser';
import { updateHistory } from '@hooks/common/useRouterHistory';
import { DevTools } from '@components/DevTools/DevTools';
import { ProductConfigurationModalProvider } from '@context/productConfigurationModalContext';
import { PageValidationErrorProvider } from '@context/pageValidationErrorContext';
import { CommonSeoMarkup } from '@components/CommonSeoMarkup/CommonSeoMarkup';
import { AdServerProvider } from '@context/AdServerContext/AdServerContext';
import { DynamicAuthModal } from '@layouts/AuthModal/DynamicAuthModal';
import { createEmotionCache } from '@utils/createEmotionCache';
import { ApiErrorBoundary } from '@components/ApiErrorBoundary/ApiErrorBoundary';
import { OrderDeliveryModalProvider } from '@context/OrderDeliveryModalContext/OrderDeliveryModalProvider';
import { OptionalAdditionsHandler } from '@features/ProductPage/components/OptionalAdditionsHandler/OptionalAdditionsHandler';
import { routing } from '@constants/routing';
import { ModifyOrderProvider } from '@context/ModifyOrderContext/ModifyOrderProvider';
import { ExpressProvider } from '@context/ExpressContext/ExpressProvider';
import { localStorageService, sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { initMasqueradeMode } from '@utils/initMasqueradeMode';
import { getDeviceType } from '@utils/getDeviceType';
import {
  DynamicFeature,
  replaceFeatures,
  DYNAMIC_FEATURES,
  isFeatureEnabled,
} from '@utils/dynamicFeatures';
import { AddToCartContextProvider } from '@context/AddToCartContext/AddToCartContext';
import { getRegionId } from '@modules/region-id';
import { getServiceType } from '@modules/service-type';
import { getPreviewHeaders, IPreviewModeHeaders } from '@modules/preview-mode';
import { getExpressHeader } from '@modules/express-header';
import { GAHandler } from '@modules/ga/components/GAHandler';
import { ModalsManager } from '@modules/modals/components/ModalsManager';
import { HeaderContextProvider } from '@context/HeaderContext/HeaderContext';
import { AuthProvider } from '@modules/auth/context/AuthContextProvider';
import { ProductListPositionProvider } from '@modules/ga/context/position';
import { AnalyticsDataProvider } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { fireCommonCustomPageView } from '@modules/ga/events/custom/custom-page-view/customPageViewCreator';
import { clearDataLayer } from '@modules/ga/gtm-event';
import { SeoTagsContainer } from '@components/SeoTags/SeoTagsContainer';
import { getWebTrackingValuePerHeaderKey } from '@modules/web-tracking';
import { CLIENT_DEVICE_TYPE } from '@modules/pageSize/pageSize.constants';
import { setClientDeviceType } from '@modules/pageSize/pageSize.helpers';
import { AccountLayout } from '@layouts/AccoutLayout/AccountLayout';
import { FontsWrapper } from '@layouts/FontsWrapper/FontsWrapper';
import { removeHashFromURL } from '@utils/removeHashFromURL';
import { PreviewBox } from '@components/PreviewBox/PreviewBox';
import { DynamicAppRedirectionModal } from '@layouts/AppRedirectionModal/DynamicAppRedirectionModal';
import { LeftNavLayout } from '@layouts/LeftNavLayout/LeftNavLayout';
import { GenesysChatService } from '@utils/genesysChatService';
import { StringBannerListPair } from '@api';
import { updateBanners, setBannerSequenceNumber } from '@utils/bannerCache';
import { CartLayout, MainLayout } from '../layouts';
import { defaultTheme } from '../theme/defaultTheme';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: {
    layout: string;
    referer?: string;
    meta?: {
      title: string;
      description: string;
    };
    masqueradeModeData: MasqueradeQueryParams;
    apolloState?: NormalizedCacheObject;
    bannerCache?: Array<StringBannerListPair>;
    bannerSqNr: number;
    dynamicFeatures?: Array<DynamicFeature>;
  };
  webTrackingValuePerHeaderKey: Record<string, string>;
  isExpress: CookieValueTypes;
  regionId?: string;
  serviceType?: string;
  previewModeHeaders?: IPreviewModeHeaders;
  clientDeviceType: CLIENT_DEVICE_TYPE;
}

function MyApp({
  Component,
  pageProps,
  router,
  emotionCache = clientSideEmotionCache,
  webTrackingValuePerHeaderKey,
  previewModeHeaders,
  isExpress,
  regionId,
  serviceType,
  clientDeviceType,
}: MyAppProps) {
  const { layout, meta, masqueradeModeData } = pageProps;
  const isMasqueradeURL = router.pathname === routing.masqueradeModeInitialization;
  const genesysService = GenesysChatService();

  const [client] = useState<ApolloClient<NormalizedCacheObject>>(
    getApolloClient({
      forceNew: false,
      regionId,
      serviceType,
      previewModeHeaders,
      valuePerHeaderKey: webTrackingValuePerHeaderKey,
      isExpress,
      cacheData: pageProps.apolloState,
    }),
  );

  if (pageProps.bannerCache) {
    updateBanners(pageProps.bannerCache);
  }
  if (pageProps.bannerSqNr) {
    setBannerSequenceNumber(pageProps.bannerSqNr);
  }
  if (pageProps.dynamicFeatures) {
    replaceFeatures(pageProps.dynamicFeatures);
  }

  setClientDeviceType(client, clientDeviceType);
  const masqueradeInitCalled = useRef(false);
  const isInitRoute = useRef(false);
  const isPopStateHappend = useRef(false);

  useEffect(() => {
    const userInfo = sessionStorageService?.read(STORAGE_KEYS.CHAT_INFO);

    if (sessionStorageService?.isKeyExists(STORAGE_KEYS.IS_CHAT_INITIALIZED)) {
      genesysService.init({ attributes: { Name: userInfo?.fullName, Email: userInfo?.email } });
    }
  }, []);

  useEffect(() => {
    if (!!sessionStorageService?.read(STORAGE_KEYS.LAST_URL)) {
      const count = localStorageService?.read(STORAGE_KEYS.PAGE_REFRESH_COUNT) ?? 0;
      localStorageService?.put(STORAGE_KEYS.PAGE_REFRESH_COUNT, count + 1);
    }

    if (!isInitRoute.current) {
      isInitRoute.current = true;
      updateHistory(router.asPath);
    }

    router.events.on('routeChangeStart', updateHistory);
    return () => {
      router.events.off('routeChangeStart', updateHistory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    removeHashFromURL(router);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMasqueradeURL && !masqueradeInitCalled.current) {
      masqueradeInitCalled.current = true;
      initMasqueradeMode({ client, router, masqueradeModeData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (isPopStateHappend.current) {
        clearDataLayer();
        fireCommonCustomPageView({
          url,
        });
        isPopStateHappend.current = false;
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    router.beforePopState(() => {
      isPopStateHappend.current = true;
      return true;
    });
  }, [router]);

  const renderLayout = () => {
    if (router.query.connectionToken) {
      return null;
    }

    const displayedComponent = <Component {...pageProps} />;

    switch (layout) {
      case LAYOUTS.CART:
        return <CartLayout {...pageProps}>{displayedComponent}</CartLayout>;
      case LAYOUTS.EMPTY:
        return displayedComponent;
      case LAYOUTS.ACCOUNT:
        return <AccountLayout {...pageProps}>{displayedComponent}</AccountLayout>;
      case LAYOUTS.LEFT_NAV:
        const isLeftLayoutEnabled = isFeatureEnabled(DYNAMIC_FEATURES.LEFT_NAV);
        if (isLeftLayoutEnabled) {
          return <LeftNavLayout {...pageProps}>{displayedComponent}</LeftNavLayout>;
        } else {
          return <MainLayout {...pageProps}>{displayedComponent}</MainLayout>;
        }
      case LAYOUTS.FULL_SCREEN:
        return (
          <MainLayout isContainer={false} {...pageProps}>
            {displayedComponent}
          </MainLayout>
        );
      default:
        return <MainLayout {...pageProps}>{displayedComponent}</MainLayout>;
    }
  };

  if (isMasqueradeURL) {
    return null;
  }

  const defaultSeoData = {
    pageTitle: meta?.title ?? '',
    metaDescription: meta?.description ?? '',
  };

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            key="viewport"
          />
          <link rel="icon" type="image/x-icon" href="/static/next/favicon.ico" />
        </Head>
        <CommonSeoMarkup />
        <ApolloProvider client={client}>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <ApiErrorBoundary>
              <DynamicFeatureProvider>
                <FontsWrapper>
                  <SeoTagsContainer defaultSeoData={defaultSeoData} />
                  <ExpressProvider>
                    <AuthModalProvider>
                      <AuthProvider>
                        <HeaderContextProvider>
                          <ProductListPositionProvider>
                            <ProductConfigurationModalProvider>
                              <ModifyOrderProvider>
                                <PageValidationErrorProvider>
                                  <AdServerProvider>
                                    <OrderDeliveryModalProvider>
                                      <WelcomeHandler />
                                      <DynamicAuthModal />
                                      <AddToCartContextProvider>
                                        <OptionalAdditionsHandler />
                                        <ModalsManager />
                                        <AnalyticsDataProvider>
                                          <GAHandler />
                                        </AnalyticsDataProvider>
                                        {renderLayout()}
                                      </AddToCartContextProvider>
                                      <ContentPaintPriorityIncreaser />
                                    </OrderDeliveryModalProvider>
                                  </AdServerProvider>
                                </PageValidationErrorProvider>
                              </ModifyOrderProvider>
                            </ProductConfigurationModalProvider>
                          </ProductListPositionProvider>

                          <DynamicAppRedirectionModal />
                        </HeaderContextProvider>
                        <RequiredScripts />
                        <DevTools />
                      </AuthProvider>
                    </AuthModalProvider>
                  </ExpressProvider>
                </FontsWrapper>
              </DynamicFeatureProvider>
            </ApiErrorBoundary>
          </ThemeProvider>
        </ApolloProvider>
      </CacheProvider>
      {previewModeHeaders && <PreviewBox headers={previewModeHeaders} />}
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const oldInitialProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;
  const { res, req } = ctx;
  const userAgent = req ? req.headers['user-agent'] : navigator?.userAgent;
  const clientDeviceType = getDeviceType(userAgent);

  return {
    ...oldInitialProps,
    webTrackingValuePerHeaderKey: getWebTrackingValuePerHeaderKey(res),
    regionId: getRegionId(res),
    serviceType: getServiceType(ctx),
    previewModeHeaders: getPreviewHeaders(res),
    isExpress: getExpressHeader(ctx),
    clientDeviceType,
  };
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  