import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const UPDATE_ACCOUNT_MOBILE_NOTIFICATIONS = gql`
  mutation UpdateAccountMobileNotifications($input: AccountMobileNotificationsInput!) {
    updateAccountMobileNotifications(input: $input) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
