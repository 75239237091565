import { ApolloLink } from '@apollo/client';
import isUndefined from 'lodash/isUndefined';
import { CookieValueTypes, getCookie } from 'cookies-next';
import { serviceTypeHeaderKey } from '@modules/service-type';
import { isClient } from '@utils/isClient';
import { isServiceTypeParamsValid } from '@utils/isServiceTypeParamsValid';
import { COOKIES_LIST } from '@commons/cookies';

export const applyServiceTypeLink = (serviceTypeInitial: CookieValueTypes) => {
  let isInited = false;
  return new ApolloLink((operation, forward) => {
    let serviceTypeHeaderValue = getCookie(COOKIES_LIST.SERVICE_TYPE);

    if (
      !isUndefined(serviceTypeInitial) &&
      !isInited &&
      isServiceTypeParamsValid(serviceTypeInitial)
    ) {
      serviceTypeHeaderValue = serviceTypeInitial;

      if (isClient()) {
        isInited = true;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.setContext((oldContext: Record<string, any>) => ({
      ...oldContext,
      headers: {
        ...oldContext.headers,
        [serviceTypeHeaderKey]: serviceTypeHeaderValue,
      },
    }));

    return forward(operation);
  });
};
