import { Typography } from '@mui/material';
import { ProductPrice } from '@commons/product';
import { BaseLink, Button } from '@components/UI';
import { useLocalization } from '@hooks/index';
import { getPrice } from '@utils/getPrice';
import { routing } from '@constants/routing';
import { getDate } from '@utils/getDate';
import { CREDIT_STATUSES } from '@commons/credits';
import { useModal } from '@hooks/common';
import { useLazyPaymentMethods } from '@hooks/payments/useLazyPaymentMethods';
import { PaymentsType } from '@commons/payment';
import { EditPaymentModal } from '@components/OrderInfo/EditPaymentButton/components/EditPaymentModal/EditPaymentModal';
import { getStatusStringTemplate } from './utils';
import styles from './CreditItem.module.scss';

interface CreditItemProps {
  children?: never;
  price: ProductPrice;
  code: string;
  date: string;
  status?: string;
  isModifying?: boolean;
  creditId?: string;
  creditNumber?: string;
}

export const CreditItem = ({
  price,
  code,
  date,
  status,
  isModifying,
  creditId,
  creditNumber,
}: CreditItemProps) => {
  const { t } = useLocalization('account');
  const { fetchPayments, data: fullPaymentsData } = useLazyPaymentMethods();
  const editPaymentModal = useModal();
  const unlinkPaymentModal = useModal();

  const statusString = status ? getStatusStringTemplate(status, creditNumber) : '';
  const isRefundFailed = status === CREDIT_STATUSES.REFUND_FAILED;
  const formattedDate = getDate(date, 'dddd, MMM D');
  const orderDateInfo = code ? `${formattedDate}, #${code}` : formattedDate;

  const handleSelectPaymentClickEvent = () => {
    fetchPayments();
    editPaymentModal.openModal();
  };

  const handleUnlinkModalOpen = (type: PaymentsType) => {
    unlinkPaymentModal.openModal();
  };

  return (
    <div className={styles.credits_item_wrapper} data-testid="credit-item">
      <div>
        <div className={styles.price_info}>
          <Typography className={styles.title} variant="body">
            {statusString} {getPrice(price.value || 0, price.currency)}
          </Typography>
        </div>
        <div className={styles.date_info}>
          <Typography className={styles.subtitle} variant="smallBody">
            {orderDateInfo}
          </Typography>
        </div>
      </div>

      {isRefundFailed && (
        <Button
          variant="outlined"
          className={styles.select_payment}
          aria-label={t('checkout:payment.selectPaymentMethod.title')}
          onClick={handleSelectPaymentClickEvent}
        >
          {t('checkout:payment.selectPaymentMethod.title')}
        </Button>
      )}

      {code && (
        <BaseLink passHref href={`${routing.orderDetails}/${code}`}>
          <Button
            className={styles.see_details}
            variant="outlined"
            aria-label={`${t('common:buttons.seeDetails')} order${code && '#'} ${code}`}
          >
            {t('common:buttons.seeDetails')}
          </Button>
        </BaseLink>
      )}

      {editPaymentModal.isOpen && (
        <EditPaymentModal
          onClose={editPaymentModal.closeModal}
          onUnlinkModalOpen={handleUnlinkModalOpen}
          opened={editPaymentModal.isOpen}
          payments={fullPaymentsData}
          isModifying={isModifying}
          isCreditDebitCardOnly
          creditRequestId={creditId}
        />
      )}
    </div>
  );
};
