import { fireEvent } from '@modules/ga/emitter';
import { RemoveFromCartOptions } from '@modules/ga/events/ecommerce/add-to-cart/utils/getRemoveFromCartEvent';
import { GA_ECOM_EVENT_ACTION } from '@modules/ga/type';
import { Cart as CartApi, ProductBasicData } from '@api';
import { CartLineLightWithGA } from '@commons/cart';
import { Product, ProductTile } from '@commons/product';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

export interface AddToCartDataOptions {
  products: (Product | ProductBasicData | ProductTile | CartLineLightWithGA)[];
  cartPosition: string;
  listName: string;
  breadcrumb: string;
  pixelEventIds?: CartApi['atcEventIds'];
  itemPosition: number;
  sponsored?: boolean;
}

export const fireAddToCartGAEvent = (data: AddToCartDataOptions) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_ECOM_EVENT_ACTION.ADD_TO_CART,
    data,
  });

export const fireRemoveFromCartGAEvent = (data: Omit<RemoveFromCartOptions, 'customer'>) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_ECOM_EVENT_ACTION.REMOVE_FROM_CART,
    data,
  });
