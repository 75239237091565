import { ApolloError, makeVar, useReactiveVar } from '@apollo/client';

type Error = ApolloError | null;

const socialLoginErrorVar = makeVar<Error>(null);

export const useSocialLoginError = (): [Error, (error: Error) => void] => {
  const setError = (state: Error) => {
    socialLoginErrorVar(state);
  };
  const error = useReactiveVar(socialLoginErrorVar);

  return [error, setError];
};
