import cx from 'classnames';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { LegalOverlay } from '@components/Modals/LegalOverlay/LegalOverlay';
import { ClearCartConfirmModal } from '@features/CartPage/components/Bag/components/FilledBag/components/ClearBagModal/ClearCartConfirmModal';
import { definitionList, triggerList } from './constants';
import { useClearCartConfirmModal } from './hooks/useClearCartConfirmModal';
import { useLegalModal } from './hooks/useLegalModal';
import styles from './OrderFooter.module.scss';

export const OrderFooter = () => {
  const { t } = useLocalization('cart');
  const { isConfirmModalOpen, openConfirmModal, closeConfirmModal } = useClearCartConfirmModal();
  const { isLegalModalOpen, modalType, openLegalModal, closeLegalModal, setIsLegalModalOpen } =
    useLegalModal();

  return (
    <>
      <div className={cx(styles.wrapper)}>
        <Button
          variant="underline"
          className={styles.empty_cart}
          onClick={openConfirmModal}
          id="empty-bag-button"
        >
          {t('emptyBagButton')}
        </Button>
        <div className={styles.note}>
          <div className={styles.definition_list}>
            {definitionList.map((item) => (
              <span key={item} className={styles.note_item}>
                {t(item)}
              </span>
            ))}
          </div>
          <ul className={styles.trigger_list}>
            {triggerList.map((item, index) => (
              <li key={`order-footer-${index}`} className={styles.note_item}>
                <Button
                  variant="underline"
                  key={item.text}
                  onClick={() => {
                    openLegalModal(item.modal);
                  }}
                >
                  {t(item.text)}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isLegalModalOpen && (
        <ModalWindow
          onClose={() => setIsLegalModalOpen(false)}
          open={isLegalModalOpen}
          className={styles.modal}
        >
          <LegalOverlay type={modalType} handleClose={closeLegalModal} />
        </ModalWindow>
      )}
      <ClearCartConfirmModal open={isConfirmModalOpen} onClose={closeConfirmModal} />
    </>
  );
};
