import { createContext, Dispatch, FC, useState, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';
import { MutationFunctionOptions } from '@apollo/client/react/types/types';
import { useRouter } from 'next/router';
import isNil from 'lodash/isNil';
import { useSetProductToAddToCartVar } from '@hooks/cart/useAddToCart';
import { useSetProductToAddToOrder } from '@hooks/order/useSetProductToAddToOrder';
import { AUTH_ERROR_MESSAGE } from '@constants/errorCodes';
import { cartAdapter } from '@adapters/cartAdapter';
import { Cart, CartLight } from '@commons/cart';
import {
  getAddToCartParams,
  getAddToOrderParams,
  getProductInfoFromCartLinesLight,
  IGetProductInfoFromCartLight,
  Params,
  ParamsContext,
} from '@context/AddToCartContext/AddToCartContext.utils';
import { addTask } from '@modules/queue/queue';
import {
  useAddToCartLightForContext,
  useModifiedAddToCartLightForContext,
} from '@hooks/cart/useAddToCartLight';
import { useLightCart } from '@hooks/cart/useLightCart';
import { useSetProductQuantityInCartLight } from '@hooks/cart/useSetProductQuantityInCartLight';
import { setShowPendingOrdersModalVar } from '@graphql/variables/showPendingOrdersModalVar';
import {
  GetLightCartQuery,
  GetLightCartQueryVariables,
  LightweightCart,
  SetProductQuantityInCartLightMutation,
  SetProductQuantityInCartLightMutationVariables,
} from '@api';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { useAddToCartLightEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { routing } from '@constants/routing';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useLightCartOptimisticResponse } from './hooks/useLightCartOptimisticResponse';
import { useGetPendingOrdersForPage } from './hooks/useGetPendingOrdersForPage';

const { getLightCart } = cartAdapter();

interface IAddToCartContext {
  handleAddToCart: (
    params: Params & { paramsContext: ParamsContext },
    callback?: () => void,
  ) => Promise<void>;
  getProductInfoFromCart: (
    productId: string,
    soldBySalesUnit?: boolean,
  ) => IGetProductInfoFromCartLight;
  cart: CartLight | Cart;
  setProductQuantityInCart: (
    options: MutationFunctionOptions<
      SetProductQuantityInCartLightMutation,
      SetProductQuantityInCartLightMutationVariables
    >,
  ) => Promise<void>;
  addedProductId?: string;
  setAddedProductId?: Dispatch<SetStateAction<string>>;
  lastAction?: string;
  setLastAction?: Dispatch<SetStateAction<string>>;
}

export const AddToCartContext = createContext<IAddToCartContext>({
  handleAddToCart: async () => undefined,
  getProductInfoFromCart: (
    productId: string,
    soldBySalesUnit?: boolean,
  ): IGetProductInfoFromCartLight => ({
    loading: false,
    data: {
      cartLineId: '',
      count: 0,
      salesUnit: undefined,
    },
  }),
  cart: getLightCart(),
  setProductQuantityInCart: async () => Promise.resolve(),
});

export const AddToCartContextProvider: FC = ({ children }) => {
  useQuery<GetLightCartQuery, GetLightCartQueryVariables>(GET_LIGHT_CART, { ssr: false });
  const [addedProductId, setAddedProductId] = useState('');
  const [lastAction, setLastAction] = useState('');
  const [addToCartLight] = useAddToCartLightForContext();
  const [modifiedAddToCart] = useModifiedAddToCartLightForContext();
  const { data: cartData, loading: cartLoading } = useLightCart();
  const getPendingOrdersForPage = useGetPendingOrdersForPage();
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const { setProductToAddToCart } = useSetProductToAddToCartVar();
  const { setProductToAddToOrder } = useSetProductToAddToOrder();
  const [setProductQuantityInCart] = useSetProductQuantityInCartLight();
  const { trackAddToLightCart } = useAddToCartLightEvent();
  const { getAddToCartLightOptimisticResponse } = useLightCartOptimisticResponse();
  const router = useRouter();
  const pageFromWhiteList =
    router.pathname === routing.cart ||
    router.pathname === routing.modifyOrder ||
    router.pathname.includes(routing.orderConfirmation);

  const getProductInfoFromCart = (productId: string, soldBySalesUnit = false) => {
    return {
      data: getProductInfoFromCartLinesLight(cartData.cartLines, productId, soldBySalesUnit),
      loading: cartLoading,
    };
  };

  async function addToCartAndTrack(params: Params & { paramsContext: ParamsContext }) {
    const { quantity, configuration, salesUnit, product, paramsContext, eventSource } = params;
    const newCartApi = await addToCartLight({
      variables: {
        ...getAddToCartParams({
          product,
          quantity,
          configuration,
          itemListName: paramsContext.listName,
          itemPosition: paramsContext.itemPosition,
          salesUnit,
          eventSource,
        }),
      },
      optimisticResponse: getAddToCartLightOptimisticResponse({
        product,
        quantity,
        salesUnit,
        paramsContext,
      }),
    });
    trackAddToLightCart(getLightCart(newCartApi.data?.addToCartLight), cartData, {
      ...paramsContext,
      sponsored: params.product.marketingTags.sponsored,
    });
  }

  async function addToCartWithPendingOrdersCheck(
    params: Params & { paramsContext: ParamsContext },
  ) {
    const { paramsContext } = params;

    try {
      const response = await getPendingOrdersForPage();

      const shouldShowPendingOrdersModal =
        response?.pendingOrdersInfo?.showPendingOrders &&
        !!response?.pendingOrdersInfo?.pendingOrders?.length;

      if (shouldShowPendingOrdersModal) {
        setShowPendingOrdersModalVar(true);
      } else {
        const newCartApi = await modifiedAddToCart();
        trackAddToLightCart(getLightCart(newCartApi.data?.addToCartLight), cartData, {
          ...paramsContext,
          sponsored: params.product.marketingTags.sponsored,
        });
      }
    } catch (error) {
      if (error instanceof Error && error.message === AUTH_ERROR_MESSAGE) {
        await addToCartAndTrack(params);
      }
    }
  }

  async function prepareAndAddToCartWithPendingOrdersCheck(
    params: Params & {
      paramsContext: ParamsContext;
    },
  ) {
    const { quantity, configuration, salesUnit, product, paramsContext } = params;

    setProductToAddToOrder({
      ...getAddToOrderParams({
        product,
        quantity,
        configuration,
        salesUnit,
      }),
    });
    setProductToAddToCart({
      ...getAddToCartParams({
        product,
        quantity,
        configuration,
        itemListName: paramsContext.listName,
        itemPosition: paramsContext.itemPosition,
        salesUnit,
      }),
    });
    await addToCartWithPendingOrdersCheck(params);
  }

  const handleAddToCart = async (
    params: Params & { paramsContext: ParamsContext },
    callback?: () => void,
  ) => {
    await addTask(async () => {
      if (pageFromWhiteList || !isLoggedIn) {
        await addToCartAndTrack(params);
      } else {
        await prepareAndAddToCartWithPendingOrdersCheck(params);
      }
      if (callback) callback();
    });
  };

  const handleSetProductQuantityInCart = async (
    options: MutationFunctionOptions<
      SetProductQuantityInCartLightMutation,
      SetProductQuantityInCartLightMutationVariables
    >,
  ) => {
    if (options.variables?.productDataInput) {
      const newCartApi = await setProductQuantityInCart(options);
      const {
        itemPosition,
        itemListName: listName,
        productId,
      } = options.variables.productDataInput;

      if (!isNil(itemPosition) && !isNil(listName)) {
        const newCart = getLightCart(
          newCartApi.data?.changeCartItemQuantityLight as LightweightCart,
        );

        trackAddToLightCart(newCart, cartData, {
          itemPosition,
          listName,
          productIds: [productId],
        });
      }
    }
  };

  return (
    <AddToCartContext.Provider
      value={{
        handleAddToCart,
        cart: cartData,
        getProductInfoFromCart,
        setProductQuantityInCart: handleSetProductQuantityInCart,
        addedProductId,
        setAddedProductId,
        lastAction,
        setLastAction,
      }}
    >
      {children}
    </AddToCartContext.Provider>
  );
};
