import cx from 'classnames';
import { Divider, Typography } from '@mui/material';
import { LinkV2 } from '@components/UI';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import { useReservations } from '@hooks/account/useReservations';
import { useHomeAddresses } from '@hooks/deliveryAddress/useHomeAddresses';
import { DeliveryPassInfo } from '@commons/deliveryPass';
import { dateAdapter } from '@adapters/dateAdapter';
import { getDate } from '@utils/getDate';
import { REGULAR_SERVICE_PHONE_NUMBER } from '@constants/account';
import { useCustomerServicePhoneNumber } from '@hooks/common/useCustomerServicePhoneNumber';
import { PlanOverview } from './components/PlanOverview/PlanOverview';
import { PaymentMethod } from './components/PaymentMethod/PaymentMethod';
import { ChangeOrCancel } from './components/ChangeOrCancel/ChangeOrCancel';
import { ReservedTimeSlot } from './components/ReservedTimeSlot/ReservedTimeSlot';
import { RenewPlan } from './components/RenewPlan/RenewPlan';
import { AutoRenewal } from './components/AutoRenewal/AutoRenewal';
import styles from './PlanCard.module.scss';

interface PlanCardProps {
  htmlContent: string;
  deliveryPassInfo: DeliveryPassInfo;
}

export const PlanCard = ({ htmlContent, deliveryPassInfo }: PlanCardProps) => {
  const { t } = useLocalization('account');
  const { getHandledDate, getFullMonth } = dateAdapter();
  const { data: homeAddresses } = useHomeAddresses();
  const { data: reservationInfo } = useReservations();
  const { getCustomerServicePhoneNumber } = useCustomerServicePhoneNumber();
  const { isExpired, isCanceled, dpTypeSku } = deliveryPassInfo;
  const planEnded = isExpired || isCanceled;
  const reservedTimeSlotVisible =
    !!homeAddresses.length && reservationInfo.eligiblePreReservation && !planEnded;
  const planEndDate = getHandledDate(new Date(deliveryPassInfo.expiration));
  const planEndDateSlashed = getDate(deliveryPassInfo.expiration, 'M/D/YYYY');
  const isOneYearFreeDeliveryPass = dpTypeSku === 'MKT9000228';
  const date = `${planEndDate.date} ${getFullMonth(planEndDate.month)}, ${planEndDate.year}`;
  const customerServicePhoneNumber = getCustomerServicePhoneNumber(REGULAR_SERVICE_PHONE_NUMBER);
  const isTwoMonthsTrial = dpTypeSku === 'MKT0072335';
  const showSeeAllPlans = isTwoMonthsTrial || isOneYearFreeDeliveryPass;
  return (
    <>
      {!planEnded && (
        <>
          <Typography
            variant="h2"
            className={cx(styles.title, isOneYearFreeDeliveryPass && styles.free_one_year_title)}
          >
            {t('deliveryPass.yourMembership')}
          </Typography>
        </>
      )}
      <div className={styles.card}>
        {htmlContent && (
          <div className={styles.plan_wrapper}>
            {planEnded && (
              <Typography className={styles.expired_title}>
                {t(isCanceled ? 'deliveryPass.planCancelledOn' : 'deliveryPass.planExpiredOn', {
                  date,
                })}
              </Typography>
            )}
            <PlanOverview
              htmlContent={htmlContent}
              isOneYearFreeDeliveryPass={isOneYearFreeDeliveryPass}
            />
            <Divider role="presentation" className={styles.divider} />
          </div>
        )}
        <div className={styles.plan_details}>
          {planEnded && !isOneYearFreeDeliveryPass && (
            <>
              <RenewPlan />
              <Divider role="presentation" className={styles.divider} />
            </>
          )}
          {planEnded && showSeeAllPlans && (
            <div className={styles.plans_section}>
              <LinkV2 legacyBehavior href={routing.deliveryPass} className={styles.link}>
                {t('common:buttons.seeAllPlans')}
              </LinkV2>
            </div>
          )}
          {!planEnded && !isOneYearFreeDeliveryPass && (
            <>
              <AutoRenewal
                enabled={deliveryPassInfo.autoRenewalEnabled}
                planEndDate={planEndDateSlashed}
                mostRecentDPInvoiceId={deliveryPassInfo.mostRecentDPInvoiceId}
                freeTrialAvailable={deliveryPassInfo.isFreeTrial}
                customerServicePhoneNumber={customerServicePhoneNumber}
              />
              <Divider role="presentation" className={styles.divider} />
            </>
          )}
          {!isOneYearFreeDeliveryPass && <PaymentMethod />}
          {!isOneYearFreeDeliveryPass && <Divider role="presentation" className={styles.divider} />}
          {reservedTimeSlotVisible && (
            <>
              <ReservedTimeSlot reservationInfo={reservationInfo} />
              <Divider role="presentation" className={styles.divider} />
            </>
          )}
          {!planEnded && !isOneYearFreeDeliveryPass && (
            <ChangeOrCancel customerServicePhoneNumber={customerServicePhoneNumber} />
          )}
        </div>
      </div>
    </>
  );
};
