import omit from 'lodash/omit';
import { CustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import {
  EcommerceEvent,
  getProductMetrics,
  ProductMetrics,
} from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { ProductTileWithPositionAndList } from '@modules/ga/context/position';
import { getProductExternalData } from '@adapters/product/getProductTile';

/* eslint-disable @typescript-eslint/naming-convention */
export interface ViewItemListOptions {
  products: ProductTileWithPositionAndList[];
  customer: CustomerData;
  list_name: string;
  list_id: string;
  breadcrumb: string;
  current_page: number;
  total_page: number;
  firstOffset: number;
}

interface ViewItemListProduct extends Omit<ProductMetrics, 'quantity'> {
  index: number;
  item_list_id: string;
}

interface ViewItemListEvent extends EcommerceEvent {
  list_name: string;
  breadcrumb: string;
  current_page: number;
  total_page: number;
  ecommerce: {
    currencyCode: string;
    impressions: ViewItemListProduct[];
  };
}

export const getViewItemListEvent = ({
  products,
  customer,
  list_name,
  list_id,
  breadcrumb,
  current_page,
  total_page,
}: ViewItemListOptions): ViewItemListEvent => {
  return {
    event: 'productImpressions',
    event_name: 'view_item_list',
    customer: {
      deliverypass_type: customer.deliveryPassType,
      deliverypass_term: customer.deliveryPassTerm,
      express_eligible: customer.expressEligible,
    },
    ua_category: 'Ecommerce Action',
    ua_action: 'View item list',
    ua_label: list_name ?? '',
    breadcrumb,
    list_name: list_name ?? '',
    current_page: current_page ?? '1',
    total_page: total_page ?? '1',
    ecommerce: {
      currencyCode: getCurrencyCodeFromSymbol(products?.[0]?.price?.currency),
      impressions: products.map((product) => {
        const productMetrics = omit(
          getProductMetrics({
            ...product,
            position: product.position ?? 1,
            listName: product.list ?? '',
          }),
          ['quantity'],
        );

        return {
          ...productMetrics,
          ...getProductExternalData(product?.externalData),
          index: product.position ?? 1,
          item_list_id: list_id ?? '',
        };
      }),
    },
  };
};
