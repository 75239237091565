import { HandledDate } from './dates';
import { ProductPrice } from './product';

export interface StoreCredits {
  pendingCredits?: Credit[];
  approvedCredits?: Credit[];
  totalAmount: ProductPrice;
  totalAmountFD: ProductPrice;
  totalAmountFK: ProductPrice;
  isCreditRestricted: boolean;
}

export interface Credit {
  amount: ProductPrice;
  order: string;
  store: string;
  status?: string;
  type: string;
  date: string;
  creditId?: string;
  accountNum?: string;
}

export interface CreditsOrderHistory {
  orders?: CreditOrder[];
}

export interface CreditOrder {
  orderId: string;
  requestedDate: HandledDate;
  deliveryStart: HandledDate;
  deliveryEnd: HandledDate;
  standingOrderName: string;
}

export interface CalculationResult {
  totalTax: number;
  totalPromo: number;
  totalPrice: number;
}

export interface CreditsOrderDetails {
  customerServiceContact: string;
  orderLines: CreditOrderLine[];
  calculationResult: CalculationResult;
}

export interface RequestedItemInput {
  orderLineId: string;
  complainReasonId: string;
  quantity: number;
  cartonNumbers: string[];
}

export interface CreditOrderLine {
  id: string;
  brand: string;
  productName: string;
  quantity: number;
  productImage: string;
  issues: CreditIssue[];
  basePrice: number;
  basePriceUnit: string;
  configurationDescription: string;
  sample: boolean;
  free: boolean;
  isIneligible: boolean;
  cartonNumbers: string[];
  finalPrice: number;
  isSubstituted: boolean;
  taxDepositSum: number;
  savedAmount: number;
  formState: {
    quantity: number;
    complainReasonId: string;
  };
}

export interface CreditIssue {
  id: string;
  issue: string;
}

export enum CREDIT_STATUSES_RESPONSES {
  REDEEMED = 'Credit redeemed',
  ISSUED = 'Credit Issued',
  REFUND = 'Refunded',
  REFUND_FAILED = 'Refund Failed',
  EXPIRED = 'Expired',
  REFERAL = 'Referral Credit',
}

export enum CREDIT_STATUSES {
  REDEEMED = 'Redeemed',
  ISSUED = 'Issued',
  REFUND = 'Refunded',
  REFUND_FAILED = 'Refund Failed',
  EXPIRED = 'Expired',
}

export type CreditOrderLineProductType = 'eligible' | 'ineligible' | 'requested';
