import { gql } from '@apollo/client';
import { GIFT_CARDS_CART_FRAGMENT } from '../../fragments/cart/common/giftCardsCart';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '../../fragments/account/actionResult';

export const ADD_GIFT_CART_TO_CART = gql`
  mutation AddGiftCardToCart($input: GiftCardInput!) {
    addToGiftCardsCart(input: $input) {
      actionResult {
        ...actionResultFragment
      }
      cart {
        ...giftCardsCartFragment
      }
    }
  }
  ${GIFT_CARDS_CART_FRAGMENT}
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
