import { IOptionalProductsData } from '@hooks/cart/useOptionalAdditions';
import { emit } from '@modules/emitter';
import { Open } from '@modules/open';
import { Close } from '@modules/close';

export const OPTIONAL_ADDITIONS_MESSAGE = 'optionalAdditions';

interface SendProductOptionalAdditions {
  product: IOptionalProductsData;
  channel?: string;
  location?: string;
  title?: string;
}

export const sendProductOptionalAdditions = (optionalAdditions: SendProductOptionalAdditions) => {
  emit(
    Open({
      message: OPTIONAL_ADDITIONS_MESSAGE,
      data: optionalAdditions,
    }),
  );
};

export const closeProductOptionalAdditions = () => {
  emit(Close({ message: OPTIONAL_ADDITIONS_MESSAGE }));
};
