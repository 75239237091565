import { FC, KeyboardEventHandler, ReactEventHandler, SyntheticEvent } from 'react';
import { openAlcoholRestrictionsAgreementModal } from '@modules/modals/events/alcoholRestrictionsAgreementModal';

interface LegalInterstitialProps {
  isRestricted: boolean;
  scopeId?: string;
  onAgree(): void;
}

export const LegalInterstitial: FC<LegalInterstitialProps> = ({
  children,
  isRestricted,
  scopeId,
  onAgree,
}) => {
  const checkRestrictions = (event: SyntheticEvent) => {
    if (isRestricted) {
      event.stopPropagation();
      openAlcoholRestrictionsAgreementModal({
        scopeId,
        onAgree,
      });
    }
  };

  const handleClick: ReactEventHandler = (event) => {
    checkRestrictions(event);
  };

  const handleEnter: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') checkRestrictions(event);
  };

  return isRestricted ? (
    <>
      <div onClickCapture={handleClick} onKeyDownCapture={handleEnter}>
        {children}
      </div>
    </>
  ) : (
    <>{children}</>
  );
};
