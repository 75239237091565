import { CartLine } from '@commons/cart';
import { GA_EVENT_TYPE } from '../../../constants';
import { fireEvent } from '../../../emitter';
import { GA_ECOM_EVENT_ACTION } from '../../../type';

export interface BeginCheckoutOptions {
  cartLines: CartLine[];
}

export const fireBeginCheckoutGAEvent = (data: BeginCheckoutOptions) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_ECOM_EVENT_ACTION.BEGIN_CHECKOUT,
    data,
  });
