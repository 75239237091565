import { Typography, Grid } from '@mui/material';
import { Button } from '@components/UI';
import { email as EmailIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { useContactUsModal } from '@context/contactUsModalContext';
import { useContactUsFormModalContext } from '@context/ContactUsFormModalContext/ContactUsFormModalContext';
import styles from './Email.module.scss';

export const Email = () => {
  const { t } = useLocalization();
  const contactUsModal = useContactUsModal();
  const contactUsFormModal = useContactUsFormModalContext();
  const handleClick = () => {
    contactUsFormModal.emit('open');
    contactUsModal.emit('close');
  };

  return (
    <div className={styles.button_group_content}>
      <EmailIcon className={styles.icon} />
      <Grid flexDirection="column" container>
        <Grid item container>
          <Button onClick={handleClick} className={styles.send_btn} variant="underline">
            <Typography className={styles.text}>{t('contactUs.emailBlock.sendText')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
