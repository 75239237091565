import { Action, IAction, PAction } from './action';

export interface PFail extends PAction {
  message?: string;
}

export interface IFail extends IAction {
  message: string;
}

export const TFail = 'Fail';

export function Fail(p: PFail): IFail {
  const type = p.type ?? TFail;
  const action = Action({
    ...p,
    type,
  });
  const message = p.message ?? '';
  return {
    ...action,
    message,
  };
}
