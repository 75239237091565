import { IncomingMessage, ServerResponse } from 'http';
import { getCookie } from 'cookies-next';
import { COOKIES_LIST } from '@commons/cookies';

let SSR_SERVICE_TYPE: string | null = null;

export const updateSSRServiceType = ({
  req,
  res,
}: {
  req?: IncomingMessage;
  res?: ServerResponse;
}) => {
  const cookieServiceType = getCookie(COOKIES_LIST.SERVICE_TYPE, { req, res });

  if (cookieServiceType) {
    SSR_SERVICE_TYPE = cookieServiceType as string;
  } else {
    SSR_SERVICE_TYPE = null;
  }
};

export const getSSRServiceType = () => SSR_SERVICE_TYPE;
