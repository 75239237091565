import { useState, useEffect, useMemo } from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';
import { useLocalization } from '@hooks/useLocalization';
import { Loader } from '@components/Loader/Loader';
import { Button } from '@components/UI';
import { AddGiftCardModal } from '@components/Modals/GiftCardModals/AddGiftCardModal';
import { getPrice } from '@utils/getPrice';
import { giftCardAdapter } from '@adapters/giftCardAdatper';
import { GiftCardPurchaseInfo, GiftCards } from '@commons/giftCards';
import { useModal } from '@hooks/index';
import { useLazyGiftCards } from '@hooks/giftCard/useLazyGiftCards';
import {
  DEFAULT_TOTAL_GIFT_CARDS_COUNT,
  DEFAULT_TOTAL_PURCHASED_GIFT_CARDS_COUNT,
} from '@constants/account';
import { GiftCardDisclaimer } from '@components/GiftCardDisclaimer/GiftCardDisclaimer';
import { AccountAlert } from '../AccountAlert/AccountAlert';
import { GiftCard } from './components/GiftCard/GiftCard';
import styles from './AccountPageGiftCards.module.scss';
import { RedeemGiftCard } from './components/RedeemGiftCard/RedeemGiftCard';
import { PurchasedGiftCard } from './components/PurchasedGiftCard/PurchasedGiftCard';
interface AccountPageGiftCardsProps {
  giftCards: GiftCards;
  giftCardPurchaseInfo: GiftCardPurchaseInfo;
  loading: boolean;
}

interface GiftCardsHandler {
  showAllReceived?: boolean;
  showAllPurchased?: boolean;
}

export const AccountPageGiftCards = ({
  giftCards,
  giftCardPurchaseInfo,
  loading,
}: AccountPageGiftCardsProps) => {
  const { isOpen, closeModal: handleCloseModal, openModal: handleOpenModal } = useModal();
  const [localGiftCards, setLocalGiftCards] = useState<GiftCards>(giftCards);
  const [showAllReceivedCards, setShowAllRecievedCards] = useState<boolean>(false);
  const [showAllPurchasedCards, setShowAllPurchasedCards] = useState<boolean>(false);

  const showAllGiftCardBtn =
    giftCards.receivedCards.totalCardsCount > DEFAULT_TOTAL_GIFT_CARDS_COUNT &&
    localGiftCards?.receivedCards?.cards.length <= DEFAULT_TOTAL_GIFT_CARDS_COUNT;
  const shouldShowAllPurchasedGiftCard =
    !showAllPurchasedCards &&
    localGiftCards.purchasedCards.length >= DEFAULT_TOTAL_PURCHASED_GIFT_CARDS_COUNT;
  const { t } = useLocalization('account');
  const { getGiftCards } = giftCardAdapter();

  useEffect(() => {
    setLocalGiftCards(giftCards);
  }, [giftCards]);

  const { fetchGiftCards, loading: loadingAllGiftCards } = useLazyGiftCards();

  const fetchAllGiftCards = ({
    showAllReceived = false,
    showAllPurchased = false,
  }: GiftCardsHandler) => {
    fetchGiftCards({
      variables: {
        showAllReceived,
        showAllPurchased,
      },
      onCompleted: ({ giftCards: allGiftCards }) => {
        if (showAllPurchased) {
          setShowAllPurchasedCards(showAllPurchased);
        }
        if (showAllReceived) {
          setShowAllRecievedCards(showAllReceived);
        }

        setLocalGiftCards(getGiftCards(allGiftCards));
      },
    });
  };

  const purchasedGiftCards = useMemo(
    () =>
      localGiftCards?.purchasedCards?.map((card) => {
        return <PurchasedGiftCard card={card} key={card.certificateNumber} />;
      }),
    [localGiftCards],
  );

  const showAllGiftCardsHandler = (args: GiftCardsHandler) => () => {
    fetchAllGiftCards(args);
  };
  const purchaseTitleClass = cx(styles.titleAmount, styles.purchaseTitle, {
    [styles.noMargin]: !localGiftCards?.purchasedCards?.length,
  });
  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.pageTitleWrapper}>
          <div>
            <Typography className={styles.title} variant="h2">
              {t('giftCards.pageTitle')}
            </Typography>
            <Typography className={styles.titleDescriptions} variant="body" component="p">
              {t('giftCards.pageTitleDescriptions')}
            </Typography>
          </div>
          <div className={styles.purchase_wrapper}>
            <Button className={styles.button} size="large" onClick={handleOpenModal}>
              {t('giftCards.purchaseBtn')}
            </Button>
            <GiftCardDisclaimer />
          </div>
        </div>
        <AccountAlert className={styles.alert} />
        <div className={styles.pageSubTitleWrapper}>
          <div>
            <Typography className={styles.titleAmount} variant="h2" id="gc_balance_identifier">
              {t('giftCards.totalAmount')}
            </Typography>
            <Typography
              className={styles.amount}
              component="span"
              aria-describedby="gc_balance_identifier"
            >
              {getPrice(
                localGiftCards.receivedCards.totalAmount.value,
                localGiftCards.receivedCards.totalAmount.currency,
              )}
            </Typography>
          </div>
          <RedeemGiftCard />
        </div>
        {localGiftCards?.receivedCards?.cards?.map((card) => {
          return <GiftCard card={card} key={card.id} />;
        })}
        {showAllGiftCardBtn && (
          <Button
            onClick={showAllGiftCardsHandler({
              showAllReceived: true,
              showAllPurchased: showAllPurchasedCards,
            })}
            variant="underline"
            className={styles.allGiftCardBtn}
          >
            {t('giftCards.showAllGiftCards')}
          </Button>
        )}
        {!localGiftCards?.receivedCards?.cards?.length && (
          <Typography variant="subtitle1" component="p">
            {t('giftCards.noRecievedCardsText')}
          </Typography>
        )}
        {loadingAllGiftCards && <Loader />}
        <div className={styles.pageSubTitleWrapper}>
          <div>
            <Typography className={purchaseTitleClass} variant="h2">
              {t('giftCards.purchaseTitle')}
            </Typography>
          </div>
        </div>
        {purchasedGiftCards}
        {shouldShowAllPurchasedGiftCard && (
          <Button
            onClick={showAllGiftCardsHandler({
              showAllPurchased: true,
              showAllReceived: showAllReceivedCards,
            })}
            variant="underline"
            className={styles.allGiftCardBtn}
          >
            {t('giftCards.showAllPurchasedCardsBtn')}
          </Button>
        )}
        {!localGiftCards?.purchasedCards?.length && (
          <Typography variant="subtitle1" component="p">
            {t('giftCards.noPurchasedCardsText')}
          </Typography>
        )}
        <AddGiftCardModal
          data={giftCardPurchaseInfo}
          loading={loading}
          open={isOpen}
          onClose={handleCloseModal}
        />
      </div>
    </div>
  );
};
