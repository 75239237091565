import { gql } from '@apollo/client';

export const GET_GIFT_CARD_PDF = gql`
  query GiftCardPdf($saleId: String!, $certNum: String!) {
    giftCardPdf(saleId: $saleId, certNum: $certNum) {
      pdfBase64String
      name
    }
  }
`;
