import { gql } from '@apollo/client';
import { GIFT_CARDS_CART_FRAGMENT } from '../../fragments/cart/common/giftCardsCart';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '../../fragments/account/actionResult';

export const UPDATE_GIFT_CARD_IN_CART = gql`
  mutation UpdateGiftCardInCart($input: GiftCardInput!) {
    updateGiftCardInCart(input: $input) {
      cart {
        ...giftCardsCartFragment
      }
      actionResult {
        ...actionResultFragment
      }
    }
  }
  ${GIFT_CARDS_CART_FRAGMENT}
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
