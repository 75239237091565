import { HTMLAttributes } from 'react';
import cx from 'classnames';
import { express as Express } from '@assets/icons/logos';
import styles from './ExpressIcon.module.scss';

interface ExpressIconProps extends HTMLAttributes<HTMLDivElement> {
  isInlineElement?: boolean;
  ariaLabel?: string;
}

export const ExpressIcon = ({
  isInlineElement = false,
  ariaLabel,
  className,
  ...rest
}: ExpressIconProps) => {
  const viewBoxValue = isInlineElement ? '0 0 50 8' : '0 0 50 10';
  const wrapperFormatClass = isInlineElement ? styles.wrapper_inline : styles.wrapper_block;

  return (
    // TODO: request express icon without paddings inside viewbox & replace it here
    <span {...rest} className={cx(styles.wrapper, wrapperFormatClass, className)}>
      <Express
        aria-label={ariaLabel}
        height={10}
        width={50}
        viewBox={viewBoxValue}
        className={styles.icon}
      />
    </span>
  );
};
