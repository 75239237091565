import cx from 'classnames';
import React from 'react';
import { Nutrition as NutritionAPI, NutritionV2, NutritionLine } from '@commons/product';
import { ThreeColumnTable } from './components/ThreeColumnTable/ThreeColumnTable';
import { NutritionV2Table } from './components/NutritionV2Table/NutritionV2Table';
import { MultiColumnNutritionTable } from './components/MultiColumnTable/MultiColumnNutritionTable';
import { SingleColumnNutritionTable } from './components/SingleColumnNutritionTable/SingleColumnNutritionTable';
import { OldNutritionTable } from './components/OldNutritionTable/OldNutritionTable';
import styles from './Nutrition.module.scss';

const SINGLE = 'SINGLE';

const SERVING_CONTAINER = 'SERVING_CONTAINER';

const MULTI_COLUMN = 'MULTI_COLUMN';

const isNutritionV2 = (nutrition: NutritionAPI | NutritionV2): nutrition is NutritionV2 =>
  !!(nutrition as NutritionV2).skuCode;

interface NutritionTableProps {
  nutrition: NutritionAPI | NutritionV2;
  oldNutritionContent?: NutritionLine[] | null;
}

export const NutritionTable = ({ nutrition, oldNutritionContent }: NutritionTableProps) => {
  if (oldNutritionContent?.length) {
    return (
      <div className={cx(styles.html_nutrition)}>
        <OldNutritionTable oldNutritionContent={oldNutritionContent} />
      </div>
    );
  }

  if (isNutritionV2(nutrition)) {
    return (
      <div className={cx(styles.table, styles.v2_column)}>
        <NutritionV2Table nutrition={nutrition} />
      </div>
    );
  }

  if (!isNutritionV2(nutrition) && nutrition?.panelType === MULTI_COLUMN && nutrition?.columns) {
    return (
      <div className={cx(styles.table, styles.multi_column)}>
        <MultiColumnNutritionTable nutrition={nutrition} index={nutrition.columns} />
      </div>
    );
  }

  if (!isNutritionV2(nutrition) && nutrition?.panelType === SINGLE) {
    return (
      <div className={cx(styles.table, styles.single_column)}>
        <SingleColumnNutritionTable nutrition={nutrition} />
      </div>
    );
  }

  if (!isNutritionV2(nutrition) && nutrition?.panelType === SERVING_CONTAINER) {
    return (
      <div className={cx(styles.table)}>
        <ThreeColumnTable nutrition={nutrition} />
      </div>
    );
  }

  return null;
};
