import { Field, Form, Formik, FormikHelpers } from 'formik';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { FetchResult } from '@apollo/client';
import cx from 'classnames';
import Trans from 'next-translate/Trans';
import { Typography } from '@mui/material';
import { PhoneField } from '@components/FormikFields';
import { useAccountPreferences } from '@hooks/useAccountPreferences';
import { useLocalization } from '@hooks/useLocalization';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useCustomerServicePhoneNumber } from '@hooks/common/useCustomerServicePhoneNumber';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ALERT_TYPES } from '@commons/account';
import { responseErrorHandler } from '@utils/responseErrorHandler';
import { Alert, Button } from '@components/UI';
import ContactUsModal from '@components/Modals/ContactUsModal/ContactUsModal';
import { CustomCheckbox } from '../CustomCheckbox/CustomCheckbox';
import { PageBlock } from '../PageBlock/PageBlock';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import { CheckboxDisclaimer } from '../CheckboxDisclaimer/CheckboxDisclaimer';
import { WorkPhone } from './components/WorkPhone/WorkPhone';
import { validatePhone } from './helpers/validation';
import { FIELDS, PhoneBlockValues } from './types';
import { useDeleteWorkPhone } from './hooks/useDeleteAccountWorkPhone';
import { getFormValues } from './helpers/getFormValues';
import { MISSING_WORKPHONE_ERROR } from './helpers/errorCodes';
import styles from './PhoneBlock.module.scss';

interface PhoneBlockProps {
  isPhoneOtpLogin?: boolean;
}

export const PhoneBlock = ({ isPhoneOtpLogin }: PhoneBlockProps) => {
  const { updateAccountWorkPhone, updateAccountMobileNotifications, accountPreferencesLoading } =
    useAccountPreferences();
  const { userInfo } = useAuthContext();
  const [showWorkPhoneFields, setShowWorkPhoneFields] = useState(!!userInfo?.workPhoneNumber);
  const { t } = useLocalization('account');
  const { dispatchAlert } = useAccountAlertContext();
  const { deleteWorkPhone } = useDeleteWorkPhone();
  const [formValues, setFormValues] = useState(getFormValues(userInfo));
  const titleRef = useRef<HTMLDivElement>(null);
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const { getCustomerServicePhoneNumber } = useCustomerServicePhoneNumber();
  const smsDeliveryUpdatesPhoneText = getCustomerServicePhoneNumber(
    t('preferences.forms.phone.fields.smsDeliveryUpdates.phone'),
  );
  const smsOffersPhoneText = getCustomerServicePhoneNumber(
    t('preferences.forms.phone.fields.smsOffers.phone'),
  );

  useEffect(() => {
    setShowWorkPhoneFields(!!userInfo?.workPhoneNumber);
  }, [userInfo?.workPhoneNumber]);

  useEffect(() => {
    if (!accountPreferencesLoading && userInfo) {
      setFormValues(getFormValues(userInfo));
    }
  }, [accountPreferencesLoading, userInfo]);

  const handleDeleteWorkPhone = async (
    values: PhoneBlockValues,
    setValues: (values: SetStateAction<PhoneBlockValues>) => void,
  ) => {
    try {
      await deleteWorkPhone();
      setShowWorkPhoneFields(false);
      setValues({
        ...values,
        workPhoneNumber: '',
        workPhoneExtension: '',
      });
    } catch (error) {
      if (error instanceof Error) {
        dispatchAlert(ALERT_TYPES.ERROR, error.message);
      }
    }
  };

  const handleUpdateWorkPhone = async ({
    workPhoneNumber,
    workPhoneExtension,
    setValues,
    currentformValues,
  }: {
    workPhoneNumber: string;
    workPhoneExtension: string;
    setValues: (values: SetStateAction<PhoneBlockValues>) => void;
    currentformValues: PhoneBlockValues;
  }) => {
    try {
      await updateAccountWorkPhone({ workPhoneNumber, workPhoneExtension });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === MISSING_WORKPHONE_ERROR) {
          setValues({
            ...currentformValues,
            [FIELDS.WORK_PHONE_NUMBER]: userInfo?.workPhoneNumber ?? '',
            [FIELDS.WORK_PHONE_EXTENSION]: userInfo?.workPhoneExtension ?? '',
          });
          return;
        }
        responseErrorHandler(error.message, dispatchAlert);
      }
    }
  };

  const handleSubmit = async (
    values: PhoneBlockValues,
    { setValues }: FormikHelpers<PhoneBlockValues>,
  ) => {
    const mobileNumber = values[FIELDS.PHONE];
    const isOrderedNotices = values[FIELDS.SMS_DELIVERY_UPDATES];
    const isSentOffers = values[FIELDS.SMS_OFFERS];
    const workPhoneNumber = values[FIELDS.WORK_PHONE_NUMBER];
    const workPhoneExtension = values[FIELDS.WORK_PHONE_EXTENSION].trim() ?? '';
    const isSameMobileNumber = userInfo?.phoneNumber === mobileNumber;
    const isSameOrderedNotices = isOrderedNotices === userInfo?.notifications?.orderNotices;
    const isSameSentOffers = isSentOffers === userInfo?.notifications?.offers;
    const isSameWorkPhoneNumber = userInfo?.workPhoneNumber === workPhoneNumber;
    const isSameWorkPhoneExtension = userInfo?.workPhoneExtension === workPhoneExtension;
    const shouldUpdateAccountMobileNotifications =
      !isSameMobileNumber || !isSameOrderedNotices || !isSameSentOffers;
    const shouldUpdateWorkPhone =
      showWorkPhoneFields && (!isSameWorkPhoneNumber || !isSameWorkPhoneExtension);

    if (!shouldUpdateAccountMobileNotifications && !shouldUpdateWorkPhone) {
      return;
    }

    const result: Promise<FetchResult | void>[] = [];
    if (shouldUpdateAccountMobileNotifications) {
      result.push(
        updateAccountMobileNotifications({
          mobileNumber,
          orderNotices: isOrderedNotices,
          offers: isSentOffers,
        }),
      );
    }

    if (shouldUpdateWorkPhone) {
      result.push(
        handleUpdateWorkPhone({
          workPhoneNumber,
          workPhoneExtension,
          setValues,
          currentformValues: values,
        }),
      );
    }

    return Promise.all(result);
  };

  const handleValidationError = (isValid: boolean) => {
    !isValid && dispatchAlert(ALERT_TYPES.ERROR, t('preferences.alerts.message.invalidPhone'));
    titleRef?.current?.focus();
  };

  const handleCloseContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={formValues}
        enableReinitialize
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, setValues, isValid }) => {
          return (
            <PageBlock
              title={t('preferences.forms.phone.title')}
              anchor="phone_section"
              ref={titleRef}
            >
              <Form>
                {isPhoneOtpLogin ? (
                  <>
                    <Typography component="label" className={styles.phone_number_label}>
                      {t(`preferences.forms.phone.fields.phone.label`)}
                    </Typography>
                    <Typography component="h5" className={styles.phone_number}>
                      {values.phone}
                    </Typography>
                    <Alert className={cx(styles.alert, styles.success)} size="medium">
                      <Trans
                        i18nKey="account:preferences.phoneOtpEnabled"
                        components={{
                          contactUsComponent: (
                            <Button
                              variant="underline"
                              className={styles.contact_us}
                              onClick={() => {
                                setIsContactUsModalOpen(true);
                              }}
                            ></Button>
                          ),
                        }}
                      />
                    </Alert>
                  </>
                ) : (
                  <Field
                    name={FIELDS.PHONE}
                    className={styles.input}
                    label={t(`preferences.forms.phone.fields.phone.label`)}
                    component={PhoneField}
                    value={values.phone}
                    validate={validatePhone(t)}
                    error={!!errors[FIELDS.PHONE]}
                    helperText={errors[FIELDS.PHONE]}
                    disabled={isSubmitting}
                    id={FIELDS.PHONE}
                    autoComplete="tel"
                  />
                )}
                <Field
                  className={styles.checkbox}
                  name={FIELDS.SMS_OFFERS}
                  id={FIELDS.SMS_OFFERS}
                  type="checkbox"
                  label={t('preferences.forms.phone.fields.smsOffers.label')}
                  additionalText={
                    <CheckboxDisclaimer
                      description={t('preferences.forms.phone.fields.smsOffers.additionalText')}
                      phone={smsOffersPhoneText}
                      ariaId={FIELDS.SMS_OFFERS}
                      displayFullDescriptionText
                    />
                  }
                  component={CustomCheckbox}
                  disabled={isSubmitting}
                />
                <Field
                  className={styles.checkbox}
                  name={FIELDS.SMS_DELIVERY_UPDATES}
                  id={FIELDS.SMS_DELIVERY_UPDATES}
                  type="checkbox"
                  label={t('preferences.forms.phone.fields.smsDeliveryUpdates.label')}
                  additionalText={
                    <CheckboxDisclaimer
                      description={t(
                        'preferences.forms.phone.fields.smsDeliveryUpdates.additionalText',
                      )}
                      phone={smsDeliveryUpdatesPhoneText}
                      ariaId={FIELDS.SMS_DELIVERY_UPDATES}
                      displayFullDescriptionText
                    />
                  }
                  component={CustomCheckbox}
                  disabled={isSubmitting}
                />
                <WorkPhone
                  values={values}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  showWorkPhoneFields={showWorkPhoneFields}
                  toggleFieldsShowing={setShowWorkPhoneFields}
                  onDeleteWorkPhone={() => handleDeleteWorkPhone(values, setValues)}
                />

                <SubmitButton
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  aria-label={t('preferences.forms.phone.button.text')}
                  onClick={() => handleValidationError(isValid)}
                >
                  {t('preferences.forms.phone.button.text')}
                </SubmitButton>
              </Form>
            </PageBlock>
          );
        }}
      </Formik>
      <ContactUsModal
        isOpen={isContactUsModalOpen}
        onClose={handleCloseContactUsModal}
        onBack={handleCloseContactUsModal}
      />
    </>
  );
};
