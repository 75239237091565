import { Divider, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, FormikInput } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { ALERT_TYPES } from '@commons/account';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { useModal } from '@hooks/common';
import { CardField, PhoneField, RadioGroupField } from '@components/FormikFields';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useAddEbtCard } from '@hooks/payments/useAddEbtCard';
import { AddEbtMutation } from '@api';
import { useLoadHtml } from '@hooks/useLoadHtml/useLoadHtml';
import { PAYMENT_TYPE } from '@commons/payment';
import { LEARN_MORE_MODAL_CONTENT } from '@constants/payments';
import { DEFAULT_COUNTRY_NAME, DEFAULT_STATE_NAME } from '@constants/delivery';
import { NewAddressFields } from '@components/PaymentsAddTabs/components/EbtTabContent/NewAddressFields';
import { RecaptchaDisclaimer } from '@components/RecaptchaDisclaimer/RecaptchaDisclaimer';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { PaymentsOnSuccess } from '@components/PaymentsAddTabs/PaymentAddTabs.types';
import { getBillingAddress, getNewBillingAddress } from './getters';
import { FIELDS, NEW_ADDRESS, SetFieldValue, useEbtTabContent } from './useEbtTabContent';
import styles from './EbtTabContent.module.scss';

interface EbtTabContentProps {
  onSuccess?: PaymentsOnSuccess;
  onError?: (err: string) => void;
}

export const EbtTabContent = ({
  onSuccess = () => void 0,
  onError = () => void 0,
}: EbtTabContentProps) => {
  const { t } = useLocalization('account');
  const { userInfo: customer } = useAuthContext();
  const { dispatchAlert } = useAccountAlertContext();
  const { countryOptions, stateOptions, validate, addressOptions, addresses } = useEbtTabContent();
  const [addEbtCard] = useAddEbtCard();

  const learnMoreModal = useModal();
  const [addAddressClicked, setAddAddressClicked] = useState(false);
  const htmlText = useLoadHtml(LEARN_MORE_MODAL_CONTENT);

  const getPaymentInfo = (paymentInfo: AddEbtMutation) => {
    const payment = paymentInfo.addEbt.data?.payments?.find(({ isDefault }) => isDefault);
    return {
      accountNumber: payment?.accountNumber?.slice(4) || '',
      payment,
    };
  };

  const hasErrors = (res: AddEbtMutation) => !!res?.addEbt?.validationErrors?.length;

  const getErrorMessage = (res: AddEbtMutation) => res.addEbt.validationErrors[0].error;

  const handleSubmit = async (values: { [key in FIELDS]: string }) => {
    const deliveryAddress = addresses.find((value) => value.address.id === values.billing_address);
    const billingAddressDTO = addAddressClicked
      ? getNewBillingAddress(values)
      : getBillingAddress(deliveryAddress);

    const input = {
      cardNumber: values.card_number.replace(/\s+/g, ''),
      cardHolderName: values[FIELDS.CARDHOLDER],
      billingAddress: billingAddressDTO,
      phone: values[FIELDS.PHONE],
    };

    addEbtCard({
      variables: {
        input,
      },
      onCompleted: (res: AddEbtMutation) => {
        if (hasErrors(res)) {
          onError(getErrorMessage(res));
          return;
        }
        const { accountNumber, payment } = getPaymentInfo(res);
        onSuccess(
          PAYMENT_TYPE.EBT,
          t('payments.successMessages.addEbtCard', { accountNumber }),
          payment,
        );
      },
    });
  };
  const handleValidationError = (isValid: boolean) => {
    !isValid && dispatchAlert(ALERT_TYPES.ERROR, t('preferences.alerts.message.userName'));
  };

  const initialAddressId = addressOptions[0].value || '';
  const initialValues = {
    [FIELDS.CARDHOLDER]: '',
    [FIELDS.CARD_NUMBER]: '',
    [FIELDS.PHONE]: customer?.phoneNumber ?? '',
    [FIELDS.BILLING_ADDRESS]: initialAddressId,
    [FIELDS.NEW_BILLING_ADDRESS]: '',
    [FIELDS.COUNTRY]: DEFAULT_COUNTRY_NAME,
    [FIELDS.CITY]: '',
    [FIELDS.ZIP_CODE]: '',
    [FIELDS.APARTMENT]: '',
    [FIELDS.STATE]: DEFAULT_STATE_NAME,
  };

  const handleBillingAddressChange = (value: string, setField: SetFieldValue) => {
    if (value === NEW_ADDRESS) {
      setField(FIELDS.BILLING_ADDRESS, value);
      setAddAddressClicked(true);
      return;
    }
    setField(FIELDS.BILLING_ADDRESS, value);
    setAddAddressClicked(false);
  };

  useEffect(() => {
    if (!(addressOptions?.length > 1)) {
      setAddAddressClicked(true);
    }
  }, [addressOptions]);

  return (
    <>
      <div className={styles.header}>
        <Typography variant="body" className={styles.title}>
          {t('payments.modalTabs.ebtTitle')}
        </Typography>
        <Button onClick={learnMoreModal.openModal} variant="underline" className={styles.link}>
          <Typography variant="body">{t('payments.modalTabs.ebtLink')}</Typography>
        </Button>
        <ModalWindow onClose={learnMoreModal.closeModal} open={learnMoreModal.isOpen} isScrollable>
          <div
            dangerouslySetInnerHTML={{
              __html: htmlText,
            }}
          />
        </ModalWindow>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {({ errors, isValid, setFieldValue, validateField, values }) => {
          return (
            <Form className={styles.form}>
              <Field
                className={styles.input}
                label={t('payments.editModal.cardholder')}
                type="text"
                name={FIELDS.CARDHOLDER}
                component={FormikInput}
                value={values[FIELDS.CARDHOLDER]}
                validate={validate[FIELDS.CARDHOLDER]}
                error={!!errors[FIELDS.CARDHOLDER]}
                helperText={errors[FIELDS.CARDHOLDER]}
                onBlur={() => validateField(FIELDS.CARDHOLDER)}
                id={FIELDS.CARDHOLDER}
              />
              <Field
                control="input"
                type="text"
                className={styles.input}
                label={t('payments.editModal.cardNumber')}
                name={FIELDS.CARD_NUMBER}
                component={CardField}
                value={values[FIELDS.CARD_NUMBER]}
                validate={validate[FIELDS.CARD_NUMBER]}
                error={errors[FIELDS.CARD_NUMBER]}
                helperText={errors[FIELDS.CARD_NUMBER]}
                onBlur={() => validateField(FIELDS.CARD_NUMBER)}
                id={FIELDS.CARD_NUMBER}
              />
              <Field
                name={FIELDS.PHONE}
                label={t(`payments.editModal.phone`)}
                component={PhoneField}
                value={values.phone}
                validate={validate[FIELDS.PHONE]}
                error={!!errors[FIELDS.PHONE]}
                helperText={errors[FIELDS.PHONE]}
                onBlur={() => validateField(FIELDS.PHONE)}
                id={FIELDS.PHONE}
              />
              <Divider className={styles.divider} />
              <div className={styles.addresses_wrapper}>
                <Typography className={styles.address_header} variant="h5">
                  {t('payments.editModal.billingAddress')}
                </Typography>
                <Field
                  name={FIELDS.BILLING_ADDRESS}
                  component={RadioGroupField}
                  options={addressOptions.map(({ value, label }) => ({
                    value,
                    label: (
                      <Typography variant="body" className={styles.address}>
                        {label}
                      </Typography>
                    ),
                  }))}
                  value={values[FIELDS.BILLING_ADDRESS]}
                  onChange={(value: string) => handleBillingAddressChange(value, setFieldValue)}
                />
                {addAddressClicked && (
                  <NewAddressFields
                    validate={validate}
                    validateField={validateField}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    countryOptions={countryOptions}
                    stateOptions={stateOptions}
                  />
                )}
              </div>
              <Button
                type="submit"
                size="large"
                fullWidth
                onClick={() => handleValidationError(isValid)}
                className={styles.button}
              >
                {t('payments.addPaymentMethod')}
              </Button>
              <RecaptchaDisclaimer />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
