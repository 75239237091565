import { createContext, Dispatch, useContext } from 'react';
import { Action, AUTH_MODAL_TYPES, AuthModalStateProps } from '@commons/auth';

export type IAuthModalAction =
  | { type: 'close' }
  | { type: 'back' }
  | { type: 'signIn' }
  | { type: 'signUp' }
  | { type: 'signOut' }
  | { type: 'tcAgreed' }
  | { type: 'welcomeClose' };

interface AuthModalContextInterface {
  toggleAuthModal: (modalType?: AUTH_MODAL_TYPES) => void;
  openAuthModal: (modalType?: AUTH_MODAL_TYPES, cb?: () => void) => void;
  closeAuthModal: () => void;
  isAuthModalOpen: boolean;
  state: AuthModalStateProps;
  dispatch: Dispatch<Action>;
  on?: (handler: (value: IAuthModalAction) => void) => () => void;
  emit?: (value: IAuthModalAction) => void;
}

// TODO: keep only necessary flags, remove redundant ones.
export const initAuthModalState: AuthModalStateProps = {
  isAuthModals: false,
  isContactUs: false,
  isCreateAccountModal: false,
  isCreateAccountWelcomeModal: false,
  isMagicLinkSentModal: false,
  isForgotPassword: false,
  isSignInModal: false,
  isSignInEmailModal: false,
  isSignInPasswordModal: false,
  isSignInPhoneModal: false,
  isSignInWelcomeModal: false,
  isWelcomeModal: false,
  isSentRequestModal: false,
  isResetPasswordModals: false,
  isSocialSignInModal: false,
  isSocialCreateAccountModal: false,
  isSocialsModal: false,
  isTCAcknowledgementModal: false,
  isOtpModal: false,
  shouldShowSignInSwitch: false,
  shouldShowSignUpSwitch: false,
  currentModal: AUTH_MODAL_TYPES.SOCIAL_SIGN_IN,
};

export const AuthModalContext = createContext<AuthModalContextInterface>({
  toggleAuthModal: () => undefined,
  openAuthModal: () => undefined,
  closeAuthModal: () => undefined,
  isAuthModalOpen: false,
  state: initAuthModalState,
  dispatch: () => undefined,
  on: undefined,
  emit: undefined,
});

AuthModalContext.displayName = 'AuthModalContext';

export const useAuthModalContext = () => {
  const ctx = useContext(AuthModalContext);

  if (ctx === undefined) {
    throw new Error('useAuthModalContext must be used within a AuthModalProvider');
  }

  return ctx;
};
