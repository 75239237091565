import { SelectedConfiguration } from '@commons/product';
import { useCartLineItem } from './useCartLineItem';

export interface InitialValues {
  cartLineId?: string;
  variables?: SelectedConfiguration[];
  salesUnit?: string;
  count?: number;
}

export const useProductInfoFromCart = (productId: string, soldBySalesUnit = false) => {
  const { data: cartLineItem, ...rest } = useCartLineItem(productId, soldBySalesUnit);
  const getProductCartInfo = () => {
    return {
      cartLineId: cartLineItem?.id || '',
      salesUnit: cartLineItem?.selectedSalesUnit || undefined,
      variables:
        cartLineItem?.confOptions.map((item) => ({
          name: item?.characteristicName,
          value: item?.characteristicValue,
        })) || [],
      count: rest.count,
    };
  };

  return { ...rest, data: getProductCartInfo() };
};
