/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { getPrice } from '@utils/getPrice';
import { routing } from '@constants/routing';
import { StoreCredits } from '@commons/credits';
import { useGetCreditsOrderHistory } from '@hooks/credits';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { openRequestCreditModal } from '@modules/modals/events/requestCreditModal';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { useLazyReferAFriend } from '@hooks/referAFriend/useReferAFriend';
import { useReferralPromotionData } from '@hooks/referAFriend/useReferralPromotionData';
import { Title } from '../Title';
import { CreditList } from './components/CreditList/CreditList';
import styles from './AccountPageCredits.module.scss';

interface AccountPageCreditsProps {
  credits?: StoreCredits;
  loading: boolean;
}

export const AccountPageCredits = ({ credits, loading }: AccountPageCreditsProps) => {
  const { data: orderHistoryCredits } = useGetCreditsOrderHistory();
  const { fetchLazyReferAFriend, data: referAFriendData } = useLazyReferAFriend();
  const { t } = useLocalization('account');
  const isReferAFriendEnabled = isFeatureEnabled(DYNAMIC_FEATURES.REFER_A_FRIEND);
  const { data: rafPromoData, refetch: fetchReferralPromotionData } = useReferralPromotionData();
  const showInvite = referAFriendData?.referAFriend?.enabled && isReferAFriendEnabled;

  useEffect(() => {
    if (isReferAFriendEnabled) {
      fetchLazyReferAFriend();
      fetchReferralPromotionData();
    }
  }, [fetchLazyReferAFriend, isReferAFriendEnabled, fetchReferralPromotionData]);

  const openModal = () => {
    openRequestCreditModal();
    sendGtmEvent(
      GtmCustomEvent({
        event: 'help-click',
        event_name: 'credit_request_1_refund_request',
        ua_category: 'credit request',
        ua_action: 'step 1',
        ua_label: 'refund request',
      }),
    );
  };

  if (loading) {
    return <AccountPageLoader />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Title className={styles.title}>{t('credit.page.title')}</Title>
          <Typography
            className={styles.amount}
            variant="h2"
            data-testid="store-credit-general-amount"
          >
            {getPrice(credits?.totalAmount?.value, credits?.totalAmount?.currency)}
          </Typography>
          <Typography className={styles.info_text} variant="body" component="p">
            {t('credit.page.titleDescription')}
          </Typography>
          {!!orderHistoryCredits?.orders?.length && (
            <Button className={styles.button} size="large" onClick={openModal}>
              {t('credit.page.requestCredit')}
            </Button>
          )}
          {showInvite && rafPromoData?.referralPromotionData?.refererCredit && (
            <a href={routing.referAFriend} className={styles.refer_friend}>
              {t('inviteFriends', {
                refererCredit: rafPromoData.referralPromotionData.refererCredit,
              })}
            </a>
          )}
          {credits?.pendingCredits && (
            <CreditList
              dataTestId="pending credits"
              title={t('credit.page.pendingCredits')}
              creditItems={credits?.pendingCredits}
            />
          )}
          {credits?.approvedCredits && (
            <CreditList
              dataTestId="all credits"
              title={t('credit.page.allCredits')}
              creditItems={credits?.approvedCredits}
            />
          )}
        </div>
      </div>
    </div>
  );
};
