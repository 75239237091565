import { ProductTile } from './product';
import { Facet } from './filters';

export interface Carousel {
  products: ProductTile[];
}

export interface SaleCarousel extends Carousel {
  title: string;
  facet: Facet;
}

export enum CAROUSEL_TYPES {
  CATEGORY = 'category',
  COUPON = 'coupon',
  FRESH_DEALS = 'fresh_deals',
  MODULE_DEFAULT = 'module_default',
  MODULE_REORDER = 'module_reorder',
  MOST_POPULAR = 'most_popular',
  REORDER = 'reorder',
  SALES = 'sales',
  RECOMMENDATIONS = 'recommendations',
  CRITEO = 'criteo',
  UPSELL = 'up_sell',
  CROSSSELL = 'cross_sell',
}
