import { gql } from '@apollo/client';
import { ORDER_INFO_FRAGMENT } from '@graphql/fragments/account/orderInfo';

export const GET_PAST_AND_ACTIVE_ORDER_HISTORY = gql`
  query pastAndActiveOrderHistory {
    ordersHistory {
      modifyingOrderId
      ordersInfo {
        ...orderInfoFragment
      }
    }
    activeOrdersHistory {
      modifyingOrderId
      ordersInfo {
        ...orderInfoFragment
      }
    }
  }
  ${ORDER_INFO_FRAGMENT}
`;
