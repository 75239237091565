import { Box, Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { info as InfoIcon } from '@assets/icons/system';
import styles from './RequiredNote.module.scss';

export interface RequiredNoteProps {
  optionalNote?: boolean;
}

export const RequiredNote = ({ optionalNote }: RequiredNoteProps) => {
  return (
    <Box className={styles.container}>
      <InfoIcon className={styles.annotation_icon} />
      <Typography variant="body" component="span" className={styles.required_note}>
        {optionalNote ? (
          <Trans i18nKey="common:requiredNoteWithOptional" components={{ b: <b /> }} />
        ) : (
          <Trans i18nKey="common:requiredNote" components={{ b: <b /> }} />
        )}
      </Typography>
    </Box>
  );
};
