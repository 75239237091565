import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Product, SelectedConfiguration } from '@commons/product';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { InitialValues } from '@hooks/cart/useProductInfoFromCart';
import { ShoppingListProductInfo } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';
import { AddToCartProps } from '@commons/addToCartProps';
import { StandingOrder } from '@commons/standingOrder';
import { TileTagList } from '@components/Tiles/components/TiletagList/TileTagList';
import { FreshBlock } from '@features/ProductPage/components/PreviewBlock/components/FreshBlock/FreshBlock';
import { SimpleCounter } from '@components/SimpleCounter';
import { useCartLineControlsState } from '@components/Tiles/CartTile/components/Controllers/hooks/useCartLineControlsState';
import { TotalPrice } from '@components/ProductConfigurationModal/components/Content/components/TotalPrice/TotalPrice';
import { calculateEstimatedWeight } from '@utils/calculateEstimatedWeight';
import { getPrice } from '@utils/getPrice';
import { calculateTotalPrice, getSelectedSalesUnit } from '@utils/calculateTotalPrice';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import {
  AdditionalInfo,
  AdditionalInfoModal,
  ProductPageImageBlock,
} from '@features/ProductPage/components';
import { useLocalization } from '@hooks/useLocalization';
import { CartTileQuantity } from '@components/Tiles/components/CartTileQuantity/CartTileQuantity';
import { useChangeCartLineQuantity } from '@components/Tiles/CartTile/components/Controllers/hooks/cartHooks/useChangeCartLineQuantity';
import { INFO_TYPE } from '../ProductConfigurationModalContent/ProductConfigurationModalContent';
import { ProductPageMainComponentSkeleton } from '../../../../features/ProductPage/components/ProductPageMainComponent/ProductPageMainComponentSkeleton';
import styles from './MiniProductPageModal.module.scss';

export interface MiniProductPageModalProps {
  cartLineId: string;
  count: number;
  productInfo: Product;
  initialValues?: InitialValues;
  variant: MODAL_TYPE;
  withImageTile?: boolean;
  loading?: boolean;
  shouldShowInfo?: boolean;
  standingOrders?: StandingOrder[];
  isDisabledControls?: boolean;
  orderErrorMessage?: string;
  labelId?: string;
  onAddToShoppingList?: (params: ShoppingListProductInfo) => void;
  onReplace?: (configs: SelectedConfiguration[], replacedQnty?: number) => void;
  onShowInfo?: (infoType: INFO_TYPE) => void;
  onOrder: (props: AddToCartProps) => void;
  onClose: () => void;
  isUnavailableCartline?: boolean;
}

export const MiniProductPageModal = forwardRef(
  (
    {
      cartLineId,
      count,
      productInfo,
      initialValues,
      labelId,
      loading,
      isUnavailableCartline,
    }: MiniProductPageModalProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { t } = useLocalization('product');
    const { currentQuantity, setCurrentQuantity } = useCartLineControlsState({
      defaultQuantity: count,
    });
    const { minQuantity, maxQuantity, quantityIncrement: step } = productInfo.quantity;
    const [totalPrice, setTotalPrice] = useState<number>(productInfo.price.value);
    const { salesUnits } = productInfo;
    const alternateSalesUnit = initialValues?.salesUnit ?? salesUnits[0]?.alternateSalesUnit ?? '';
    const [configs, setConfigs] = useState<SelectedConfiguration[]>(initialValues?.variables ?? []);
    const { enabled: expressEnabled } = useExpressContext();
    const oldNutritionContent = productInfo?.oldNutritionContent?.length
      ? productInfo.oldNutritionContent
      : null;

    const { change } = useChangeCartLineQuantity({
      productCouponId: productInfo?.coupon.couponId ?? '',
      cartLineId,
      quantity: currentQuantity,
      maxQuantity,
      minQuantity,
      setQuantity: setCurrentQuantity,
    });

    useEffect(() => {
      setTotalPrice(
        calculateTotalPrice(productInfo, {
          ratio: getSelectedSalesUnit(productInfo.salesUnits, alternateSalesUnit)?.ratio || 1,
          quantity: currentQuantity,
          selectedConfigurations: configs,
        }),
      );
    }, [configs, currentQuantity, productInfo, alternateSalesUnit]);

    useEffect(() => {
      if (expressEnabled) {
        const expressConfigs = productInfo.variations
          .map(({ name, values }) => {
            return {
              name,
              value: values[0]?.name ?? '',
            };
          })
          .filter((item) => !!item.value);
        setConfigs(expressConfigs);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expressEnabled]);

    const productDescription = productInfo?.extraDetails?.extraDescription ?? '';
    let currentQuantityLabel = t('itemsInBag_one', { count: currentQuantity });

    if (currentQuantity > 1) {
      currentQuantityLabel = t('itemsInBag_other', { count: currentQuantity });
    }

    if (loading) {
      return <ProductPageMainComponentSkeleton />;
    }

    if (productInfo) {
      return (
        <>
          <div className={styles.layout}>
            <div className={styles.layout_summary}>
              <Typography
                component="h2"
                variant="h2"
                className={styles.product_name}
                data-testid="product-name"
                id={labelId}
              >
                {productInfo.productDescription}
              </Typography>
              {!productInfo.marketingTags?.soldOut && (
                <>
                  <CartTileQuantity
                    unitPrice={productInfo?.unitPrice}
                    roughPricePerUnit={productInfo.roughPricePerUnit}
                    pricePerScaleUnit={productInfo.pricePerScaleUnit}
                    formattedCurrentPrice={productInfo.formattedCurrentPrice}
                  ></CartTileQuantity>
                  <TileTagList
                    peakQuality={productInfo?.featureTags?.topPick}
                    expressEligible={productInfo?.featureTags?.expressEligible}
                  />

                  <Typography variant="body" component="p" className={styles.weight_disclaimer}>
                    {productInfo?.extraDetails?.weightDisclaimer}
                  </Typography>

                  <FreshBlock freshnessGuarantee={productInfo?.extraDetails.freshnessGuarantee} />
                </>
              )}
            </div>
            {productInfo.marketingTags?.soldOut && (
              <Typography variant={'h5'}>{t('outOfStock.soldOut')}</Typography>
            )}
            {!productInfo.marketingTags?.soldOut && !isUnavailableCartline && (
              <div className={styles.layout_actions}>
                <>
                  <div className={styles.infobox}>
                    <TotalPrice
                      estimatedWeight={calculateEstimatedWeight(productInfo, currentQuantity)}
                      estimatedWeightDisclaimer={productInfo.estimatedWeightDisclaimer}
                      totalPrice={getPrice(totalPrice, productInfo.price.currency)}
                    />
                    <Typography component="span" variant="smallBody" className={styles.info_count}>
                      {currentQuantityLabel}
                    </Typography>
                  </div>

                  <SimpleCounter
                    value={currentQuantity}
                    step={step}
                    size="large"
                    isFullWidth={true}
                    minValue={minQuantity}
                    maxValue={maxQuantity}
                    buttonArialLabel={productInfo?.productName}
                    onMinusClick={() => change(currentQuantity - step)}
                    onPlusClick={() => change(currentQuantity + step)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => change(Number(e.target.value))}
                  />
                </>
              </div>
            )}
            <div className={styles.layout_image} data-testid="product-image">
              <ProductPageImageBlock
                image={productInfo.productImage}
                imageCarousel={productInfo?.productImageCarousel}
              />
            </div>

            <div className={styles.layout_description}>
              <div
                dangerouslySetInnerHTML={{
                  __html: productDescription,
                }}
              ></div>
            </div>

            <div className={styles.layout_info} data-testid="additional-info">
              <AdditionalInfo
                nutrition={productInfo?.nutrition}
                nutritionV2={productInfo?.nutritionV2}
                oldNutritionContent={oldNutritionContent}
                productDescription={productDescription}
                ingredients={productInfo?.extraDetails?.ingredients}
              />
            </div>
            <div>
              <AdditionalInfoModal
                extraDetails={productInfo?.extraDetails}
                ingredients={productInfo?.extraDetails?.ingredients}
                productQualityNote={productInfo?.productQualityNote}
                nutrition={{
                  ...productInfo?.nutritionV2,
                  ...productInfo?.nutrition,
                }}
                oldNutritionContent={oldNutritionContent}
                description={productInfo?.extraDetails?.extraDescription}
                instructions={productInfo?.instruction}
                allergens={productInfo?.allergens?.join(', ')}
                link={productInfo?.instructionLink}
              />
            </div>
          </div>
        </>
      );
    }
    return null;
  },
);

MiniProductPageModal.displayName = 'MiniProductPageModal';
