import { gql } from '@apollo/client';

export const WINE_AND_SPIRITS_FRAGMENT = gql`
  fragment wineAndSpiritsFragment on WineData {
    alcoholGrade
    country
    grape
    region
    types
    vintage
    varietals
    city
    classification
    importer
    agingNotes
  }
`;
