import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUpdateEffect } from 'usehooks-ts';
import { useAuthModalContext } from '@context/AuthModalContext/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { useRouterQuery } from '@hooks/useRouterQuery';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { MAIN_INFO_QUERIES } from '@modules/auth/constants';
import { useSocialLoginError } from '@hooks/useSocialLoginError';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { AuthUser } from '@modules/auth/types';

export const WelcomeHandler = () => {
  const router = useRouter();
  const [, setSocialLoginError] = useSocialLoginError();
  const { removeQueryParams } = useRouterQuery();
  const { minimumAccountPreferences, loading } = useMinimumAccountPreferences();
  const { isSignIn, connectionToken } = router.query;
  const authModalContext = useAuthModalContext();
  const { toggleAuthModal } = authModalContext;
  const { updateAuthUser: updateCustomerInfo, socialLogin, rememberMe } = useAuthContext();
  const { SOCIAL_SIGN_IN } = AUTH_MODAL_TYPES;

  useEffect(() => {
    if (router.query.hasOwnProperty('connectionToken') && !loading) {
      if (connectionToken) {
        // macrotask is needed for socialLogin mutation to be sent after redirect
        setTimeout(() => {
          (async () => {
            try {
              await loginHandler();
            } catch (e) {
              errorHandler();
            }
          })();
        });
      } else {
        errorHandler();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionToken, loading]);

  useUpdateEffect(() => {
    if (!connectionToken) {
      updateCustomerInfo(minimumAccountPreferences as AuthUser);
    }
  }, [minimumAccountPreferences.firstName]);

  function errorHandler() {
    toggleAuthModal(
      isSignIn === 'true'
        ? AUTH_MODAL_TYPES.SOCIAL_SIGN_IN
        : AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT,
    );
    removeQueryParams(['isSignIn', 'connectionToken']);
  }

  async function loginHandler() {
    socialLogin({
      variables: {
        connectionToken,
        rememberMe,
      },
      refetchQueries: MAIN_INFO_QUERIES,
      onCompleted: (data) => {
        removeQueryParams(['isSignIn', 'connectionToken']);
      },
      onError: (error) => {
        setSocialLoginError(error);
        toggleAuthModal(SOCIAL_SIGN_IN);
        removeQueryParams(['isSignIn', 'connectionToken']);
      },
    });
  }

  return <></>;
};
