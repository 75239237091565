import { gql } from '@apollo/client';

export const CUSTOMER_FRAGMENT = gql`
  fragment customerFragment on Customer {
    firstName
    lastName
    email
    memberSinceYear
    hasJustSignUp
    tcAcknowledged
  }
`;
