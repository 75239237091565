import { gql } from '@apollo/client';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';
import { DELIVERY_ADDRESS_RESULT_FRAGMENT } from '@graphql/fragments/account/deliveryAddressResult';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const SET_DEFAULT_DELIVERY_ADDRESS = gql`
  mutation SetDefaultDeliveryAddressV2(
    $id: ID!
    $accountTab: Boolean = false
    $standingOrderMode: StandingOrderMode
  ) {
    setDefaultDeliveryAddressV2(
      newDefaultAddressId: $id
      accountTab: $accountTab
      standingOrderMode: $standingOrderMode
    ) {
      deliveryAddresses {
        ...deliveryAddressResultFragment
      }
      isUnattendedDelivery
      expressPlantIdChanged
      validationErrors {
        ...validationErrorFragment
      }
      actionResult {
        ...actionResultFragment
      }
    }
  }
  ${DELIVERY_ADDRESS_RESULT_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
