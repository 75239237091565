import { useEffect, useRef } from 'react';

export const useEffectOnceWithCondition = (callback: () => void, condition: boolean) => {
  const showed = useRef(false);
  useEffect(() => {
    if (condition && !showed.current) {
      showed.current = true;
      callback();
    }
  }, [callback, condition]);
};
