import { createContext, useContext } from 'react';
import { DYNAMIC_FEATURES } from '@utils/dynamicFeatures';

export { DYNAMIC_FEATURES } from '@utils/dynamicFeatures';

interface DynamicFeatureContextInterface {
  isFeatureEnabled: (featureName: DYNAMIC_FEATURES) => boolean;
  enableFeature: (featureName: DYNAMIC_FEATURES, options?: Record<string, string>) => void;
  disableFeature: (featureName: DYNAMIC_FEATURES) => void;
  resetAllFeatures: () => void;
  loading: boolean;
}

export const DynamicFeatureContext = createContext<DynamicFeatureContextInterface>({
  isFeatureEnabled: () => false,
  enableFeature: () => undefined,
  disableFeature: () => undefined,
  resetAllFeatures: () => undefined,
  loading: true,
});

export const useDynamicFeatureContext = () => useContext(DynamicFeatureContext);
