import { gql } from '@apollo/client';

export const COUPON_FRAGMENT = gql`
  fragment couponFragment on Coupon {
    couponId
    version
    displayDescription
    detailedDescription
    value
    quantity
    expirationDate
    offerType {
      name
      description
    }
    status {
      name
      description
      displayMessage
    }
    displayStatus {
      name
      description
    }
    couponProductInfo {
      catId
      productId
      skuCode
      upc
    }
    displayStatusMessage
    context {
      name
      description
    }
  }
`;
