import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const UPDATE_ACCOUNT_NAME = gql`
  mutation UpdateAccountName($firstName: String!, $lastName: String!) {
    updateAccountName(firstName: $firstName, lastName: $lastName) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
