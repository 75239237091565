import {
  AccountPreferences as AccountPreferenceAPI,
  DeliveryAddress as DeliveryAddressApi,
  DeliveryAddressesResult as DeliveryAddressesResultApi,
  DeliveryContactInfo as DeliveryContactInfoAPI,
  OtpEnabledLoginType,
  Payment as PaymentAPI,
  Payments as PaymentsAPI,
  ServiceType,
  StatusLevelType,
  UserDetails as UserDetailsApi,
} from '@api';
import { AccountPreferences, UserDetailsInfo } from '@commons/account';
import {
  Address,
  AddressForm,
  AddressToSend,
  ALT_DELIVERY_OPTION,
  BackupDeliveryInfo,
  DeliveryAddress,
  DeliveryAddresses,
  DeliveryContactInfo,
  DeliveryContactInfoForm,
  PHONE_TYPE,
  SERVICE_TYPE,
  ShortDeliveryAddress,
} from '@commons/deliveryAddresses';
import {
  EWALLET_PAYMENT_TYPE,
  FullPayments,
  Payment,
  PAYMENT_TYPE,
  PaymentBankInfo,
  PaymentEWalletInfo,
  PaymentGeneralInfo,
  PaymentLogos,
  PaymentsType,
} from '@commons/payment';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { addressToString } from '@utils/addressToString';
import { addressAdapter } from './addressAdapter';
import { getGoogleAnalyticsCustomerData } from './analyticsAdapter';

type GetAccountPreferenceParams = AccountPreferenceAPI | null;
type DeliveryAddressAPI = DeepPartial<DeliveryAddressApi>;
type DeliveryContactInfoApi = DeepPartial<DeliveryContactInfoAPI>;
type PaymentApi = DeepPartial<PaymentAPI | null | undefined>;
type PaymentsApi = DeepPartial<PaymentsAPI | null | undefined>;

interface AccountAdapter {
  getAccountPreferences: (accountPreferences?: GetAccountPreferenceParams) => AccountPreferences;
  getDeliveryAddresses: (deliveryData: DeliveryAddressesResultApi) => DeliveryAddresses;
  getDeliveryAddressArr: (deliveryAddress?: DeliveryAddressAPI[] | null) => DeliveryAddress[];
  getShortDeliveryAddress: (address?: ShortDeliveryAddress | null) => AddressForm;
  getAddressFromSuggestion: (suggestion: Suggestion) => AddressToSend;
  getSuggestionFromAddress: (address: Address) => Suggestion;
  getLocalAddress: (address: Address) => AddressToSend;
  getFullPaymentData: (payments: PaymentsApi) => FullPayments;
  getInputAddressFromResponse: (address: Address) => AddressToSend;
  getPaymentFullInfo: (payment?: PaymentApi) => Payment;
  getDeliveryAddress: (deliveryAddress?: DeliveryAddressAPI | null) => DeliveryAddress;
  getUserDetails: (userDetails: UserDetailsApi) => UserDetailsInfo;
}

const { getAddress } = addressAdapter();

export const getAccountPreferences = (
  accountPreferences?: GetAccountPreferenceParams,
): AccountPreferences => {
  const serviceType = accountPreferences?.serviceType || ServiceType.PICKUP;
  const notifications = {
    sendNewsLetter: !!accountPreferences?.notifications?.sendNewsLetter,
    orderNotices: !!accountPreferences?.notifications?.orderNotices,
    offers: !!accountPreferences?.notifications?.offers,
  };
  const socialProviders = accountPreferences?.linkedSocialProviders?.length
    ? accountPreferences.linkedSocialProviders
    : [];
  const isMasqueradeMode = accountPreferences?.isMasqueradeMode || false;
  const memberSinceYear = String(accountPreferences?.memberSinceYear) || '';
  const statusLevel = accountPreferences?.statusLevel as StatusLevelType;
  const isOnFDAccount = accountPreferences?.isOnFDAccount || false;
  const isOtpLoginEnabled = accountPreferences?.isOtpLoginEnabled || false;
  const otpLoginType = accountPreferences?.otpLoginType || OtpEnabledLoginType.NONE;

  return {
    firstName: accountPreferences?.firstName || null,
    lastName: accountPreferences?.lastName || null,
    email: accountPreferences?.emailAddress || null,
    phoneNumber: accountPreferences?.phoneNumber || null,
    workPhoneNumber: accountPreferences?.workPhoneNumber || null,
    workPhoneExtension: accountPreferences?.workPhoneExtension || null,
    socialProviders: socialProviders || null,
    isChefTableAvailable: !!accountPreferences?.isChefTableAvailable,
    isSocialOnly: accountPreferences?.isSocialLoginOnly || false,
    isEligibleForPreReservation: !!accountPreferences?.isEligibleForPreReservation,
    isStandingOrderEnabled: !!accountPreferences?.isStandingOrderEnabled,
    hasStandingOrders: !!accountPreferences?.isStandingOrdersExist,
    notifications,
    serviceType,
    isMasqueradeMode,
    memberSinceYear,
    statusLevel,
    isOnFDAccount,
    otpLoginType,
    isOtpLoginEnabled,
  };
};

const getDeliveryContactInfo = (
  deliveryContactInfo?: DeliveryContactInfoApi | null,
): DeliveryContactInfo => ({
  firstName: deliveryContactInfo?.firstName || null,
  instructions: deliveryContactInfo?.instructions || null,
  lastName: deliveryContactInfo?.lastName || null,
  phoneNumber: deliveryContactInfo?.phoneNumber?.length
    ? deliveryContactInfo.phoneNumber.map((phone) => ({
        extension: phone?.extension ?? null,
        phone: phone?.phone ?? null,
        type: phone?.type ? (phone.type as unknown as PHONE_TYPE) : null,
      }))
    : [],
  companyName: deliveryContactInfo?.companyName || null,
});

const getBackupInfo = (backupInfo?: DeliveryAddressAPI['backupInfo']): BackupDeliveryInfo => ({
  contact: getDeliveryContactInfo(backupInfo?.contact),
  hasUnattendedDeliveryOption: !!backupInfo?.hasUnattendedDeliveryOption,
  setting: backupInfo?.setting ? (backupInfo.setting as unknown as ALT_DELIVERY_OPTION) : null,
  address: getAddress(backupInfo?.address),
});

export const getDeliveryAddress = (
  deliveryAddress?: DeliveryAddressAPI | null,
): DeliveryAddress => ({
  address: getAddress(deliveryAddress?.address),
  backupInfo: getBackupInfo(deliveryAddress?.backupInfo),
  contact: getDeliveryContactInfo(deliveryAddress?.contact),
  defaultZip: !!deliveryAddress?.defaultZip,
  isTemporary: deliveryAddress?.isTemporary || false,
  expressEnabled: !!deliveryAddress?.expressEnabled,
  selected: !!deliveryAddress?.selected,
});

const getDeliveryAddressArr = (deliveryAddress?: DeliveryAddressAPI[] | null): DeliveryAddress[] =>
  deliveryAddress?.length ? deliveryAddress.map((address) => getDeliveryAddress(address)) : [];

export const getDeliveryAddresses = (
  deliveryData: DeliveryAddressesResultApi,
): DeliveryAddresses => {
  return {
    corpAddresses: getDeliveryAddressArr(deliveryData?.corpAddresses),
    homeAddresses: getDeliveryAddressArr(deliveryData?.homeAddresses),
    pickUpDepotsAddresses: getDeliveryAddressArr(deliveryData?.pickUpDepotsAddresses),
    selectedAddress: getDeliveryAddress(deliveryData?.selectedAddress),
    guestUser: !!deliveryData?.guestUser,
    userExpressEnabled: !!deliveryData?.userExpressEnabled,
    futureZoneNotification: !!deliveryData?.futureZoneNotification,
    showRecaptcha: !!deliveryData?.showRecaptcha,
  };
};

const getLocalAddress = (address?: Address): AddressToSend => ({
  address1: address?.address1 ?? '',
  apartment: address?.apartment ?? '',
  city: address?.city ?? '',
  serviceType: address?.serviceType ?? SERVICE_TYPE.HOME,
  state: address?.state ?? '',
  zipCode: address?.zipCode ?? '',
  companyName: address?.companyName ?? '',
});

const getLocalContact = (contact?: DeliveryContactInfo): DeliveryContactInfoForm => ({
  firstName: contact?.firstName ?? '',
  lastName: contact?.lastName ?? '',
  instructions: contact?.instructions ?? '',
  companyName: contact?.companyName ?? '',
  phoneNumber: {
    phone: contact?.phoneNumber.length ? contact?.phoneNumber[0].phone ?? '' : '',
    extension: contact?.phoneNumber.length ? contact?.phoneNumber[0].extension ?? '' : '',
    type: contact?.phoneNumber.length
      ? contact?.phoneNumber[0].type ?? PHONE_TYPE.MOBILE
      : PHONE_TYPE.MOBILE,
  },
  email: contact?.email ?? '',
});

const getShortDeliveryAddress = (address?: ShortDeliveryAddress | null): AddressForm => ({
  address: getLocalAddress(address?.address),
  contact: getLocalContact(address?.contact),
  backupInfo: {
    contact: getLocalContact(address?.backupInfo?.contact),
    setting: address?.backupInfo?.setting ?? ALT_DELIVERY_OPTION.NONE,
    address: getLocalAddress(address?.backupInfo?.address),
    hasUnattendedDeliveryOption: !!address?.backupInfo?.hasUnattendedDeliveryOption,
  },
});

const getAddressFromSuggestion = (suggestion: Suggestion): AddressToSend => ({
  address1: suggestion.streetLine,
  apartment: '',
  city: suggestion.city,
  serviceType: suggestion.serviceType ?? SERVICE_TYPE.HOME,
  state: suggestion.state,
  zipCode: suggestion.zipcode,
  companyName: '',
});

const getSuggestionFromAddress = ({
  address1,
  apartment,
  city,
  state,
  zipCode,
  id,
  serviceType,
}: Address): Suggestion => {
  const suggestion = {
    streetLine: address1 || '',
    entries: Number(apartment),
    secondary: apartment || '',
    city: city || '',
    state: state || '',
    zipcode: zipCode || '',
    id: id || null,
    serviceType: serviceType ?? SERVICE_TYPE.HOME,
  };
  return {
    ...suggestion,
    label: addressToString(suggestion.streetLine, suggestion.city, suggestion.state),
  };
};

const getPaymentGeneralInfo = (payment?: PaymentApi): PaymentGeneralInfo => {
  return {
    id: payment?.id ?? '',
    email: payment?.email ?? null,
    canDelete: payment?.canDelete ?? false,
    isDefault: payment?.isDefault ?? false,
    isExpired: payment?.isExpired ?? false,
    selected: payment?.selected ?? false,
    title: payment?.title ?? null,
    type: (payment?.type as PaymentsType) ?? PAYMENT_TYPE.NULL,
    billingAddress: getAddress(payment?.billingAddress),
    paymentTypeDescription: payment?.paymentTypeDescription ?? null,
    referencedOrderId: payment?.referencedOrderId ?? null,
  };
};

const getPaymentLogos = (payment?: PaymentApi): PaymentLogos => {
  return {
    mpLogoURL: payment?.mpLogoURL ?? null,
    ppLogoURL: payment?.ppLogoURL ?? null,
  };
};

const getBankMethodsInfo = (payment?: PaymentApi): PaymentBankInfo => {
  return {
    bankName: payment?.bankName ?? null,
    bankAccountType: payment?.bankAccountType ?? null,
    accountNumber: payment?.accountNumber ?? null,
    nameOnCard: payment?.nameOnCard ?? null,
    expiration: payment?.expiration ?? null,
    abaRouteNumber: payment?.abaRouteNumber ?? null,
    bestNumber: payment?.bestNumber ?? null,
    isDebit: payment?.isDebit ?? false,
  };
};

const getEWalletInfo = (payment?: PaymentApi): PaymentEWalletInfo => {
  return {
    eWalletID: payment?.eWalletID ?? null,
    vendorEWalletID: payment?.vendorEWalletID ?? null,
  };
};

export const getPaymentFullInfo = (payment?: PaymentApi): Payment => {
  return {
    ...getPaymentGeneralInfo(payment),
    ...getBankMethodsInfo(payment),
    ...getPaymentLogos(payment),
    ...getEWalletInfo(payment),
  };
};

const getPaymentMethods = (payments?: PaymentApi[]): Payment[] => {
  if (!payments?.length) return [];

  return payments.map((payment) => getPaymentFullInfo(payment));
};

export const getDefaultPayment = (payments?: PaymentApi[]): Payment | null => {
  const defaultPayment = payments?.find((paymentItem) => paymentItem?.isDefault);
  return defaultPayment ? getPaymentFullInfo(defaultPayment) : null;
};

export const getSelectedPayment = (payments: PaymentApi[] = []): Payment | null => {
  const selectedPayment = payments?.find((paymentItem) => paymentItem?.selected);
  return selectedPayment ? getPaymentFullInfo(selectedPayment) : null;
};

export const getFullPaymentData = (paymentItems: PaymentsApi): FullPayments => {
  const payments = paymentItems?.payments?.length ? getPaymentMethods(paymentItems?.payments) : [];
  return {
    default: paymentItems?.default ?? null,
    selected: paymentItems?.selected ?? null,
    payments,
    defaultPayment: paymentItems?.payments?.length
      ? getDefaultPayment(paymentItems?.payments)
      : null,
    selectedPayment: paymentItems?.payments?.length
      ? getSelectedPayment(paymentItems?.payments)
      : null,
    hasPayments: !!paymentItems?.payments?.length,
    hasPaypalWallet: !!payments.find((payment) => payment.type === EWALLET_PAYMENT_TYPE.PAYPAL),
    hasVenmoWallet: !!payments.find((payment) => payment.type === EWALLET_PAYMENT_TYPE.VENMO),
  };
};

const getInputAddressFromResponse = (address: Address): AddressToSend => ({
  address1: address?.address1 ?? '',
  apartment: address?.apartment ?? '',
  city: address?.city ?? '',
  serviceType: (address?.serviceType as unknown as SERVICE_TYPE) ?? SERVICE_TYPE.HOME,
  state: address?.state ?? '',
  zipCode: address?.zipCode ?? '',
});

export const getUserDetails = (userDetails: UserDetailsApi): UserDetailsInfo => ({
  cohort: userDetails?.cohort ?? '',
  erpId: userDetails?.gaCustomerData?.erpId ?? '',
  userId: userDetails?.gaCustomerData?.userId ?? '',
  ...getGoogleAnalyticsCustomerData(userDetails?.gaCustomerData),
  expressEnabled: userDetails?.expressEnabled ?? false,
  referralSignup: userDetails?.referralSignup ?? false,
  displayRafBanner: userDetails?.displayRafBanner ?? false,
});

export const accountAdapter = (): AccountAdapter => {
  return {
    getAccountPreferences,
    getDeliveryAddresses,
    getDeliveryAddressArr,
    getSuggestionFromAddress,
    getShortDeliveryAddress,
    getAddressFromSuggestion,
    getFullPaymentData,
    getLocalAddress,
    getInputAddressFromResponse,
    getPaymentFullInfo,
    getDeliveryAddress,
    getUserDetails,
  };
};
