import { LazyQueryResult, useLazyQuery } from '@apollo/client';
import { AD_SERVER } from '@graphql/adServer/adServer';
import { AdServerQuery, AdServerQueryVariables } from '@api';

export type AdServerLazyQueryResult = LazyQueryResult<AdServerQuery, AdServerQueryVariables>;

export const useAdServerLazy = () =>
  useLazyQuery<AdServerQuery, AdServerQueryVariables>(AD_SERVER, {
    ssr: false,
    fetchPolicy: 'network-only',
  });
