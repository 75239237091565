export const routing = {
  home: '/',
  loginLegacy: '/login/login',
  loginUXE: '/login',
  superDepartment: '/super-department',
  department: '/department',
  category: '/category',
  subcategory: '/subcategory',
  product: '/product',
  products: '/products',
  search: '/search',
  account: '/account',
  reorderItems: '/quickshop/items',
  reorderOrders: '/quickshop/orders',
  cart: '/expressco/view_cart',
  shoppingLists: '/quickshop/shopping_lists',
  standingOrders: '/quickshop/standing_orders',
  editStandingOrder: '/quickshop/standing_order',
  createStandingOrder: '/quickshop/standing_order/create',
  modifyOrder: '/order-modify',
  checkout: '/expressco/checkout',
  help: '/help',
  faq: '/help/faq',
  faqSearch: '/help/faq_search',
  issuesBottles: '/help/faq/faq_issues_bottles',
  issuesFees: '/help/faq/faq_issues_fees',
  deliveryPass: '/deliverypass',
  requestCredit: '/account/request-credit',
  preferences: '/account/preferences',
  delivery: '/account/delivery',
  payment: '/account/payment',
  orderHistory: '/account/history',
  orderDetails: '/account/order_details',
  giftCards: '/account/gift_cards',
  referAFriend: '/account/refer_a_friend',
  credits: '/account/credits',
  deleteConfirm: '/account/delete_confirm',
  accountDeliveryPass: '/account/delivery_pass',
  chefTable: '/account/chef_table',
  dealsAndSale: '/deals_sale',
  freshDeals: '/fresh_deals',
  coupons: '/ecoupon',
  allSales: '/all_sale',
  orderConfirmation: '/expressco/success',
  legal: '/health_warning',
  browse: '/browse',
  notFound: '/404',
  serverError: '/_error',
  deliveryInfo: '/help/delivery_info',
  retrievePassword: '/login/retrieve_password',
  masqueradeModeInitialization:
    '/980ff88b1adf961750ca413752af6f10/d56b699830e77ba53855679cb1d252da',
  giftCard: '/gift_card',
  bulkGiftCard: '/bulk_gift_card',
  shopInApp: '/shop_in_app',
  referAFriendPage: '/refer_a_friend',
};

export const noLoginRoutes = [routing.notFound];

export const sensitiveRoutes = [
  routing.checkout,
  routing.orderConfirmation,
  routing.preferences,
  routing.chefTable,
  routing.delivery,
  routing.payment,
  routing.orderHistory,
  routing.orderDetails,
  routing.credits,
  routing.accountDeliveryPass,
  routing.modifyOrder,
  routing.reorderItems,
  routing.reorderOrders,
  routing.requestCredit,
  routing.shoppingLists,
  routing.standingOrders,
  routing.deleteConfirm,
];

export const externalContent = {
  specialInstructionsInfo: '/media/editorial/site_pages/unattended_delivery/about.html',
  customerAgreement:
    '/media/editorial/site_pages/customer_agreement/customer_agreement_with_blank_lang_link.html',
  privacyPolicy: `/media/editorial/site_pages/privacy_policy/privacy_policy.html`,
  termsAndConditions: '/media/editorial/picks/deliverypass/dp_tc.html',
  privacySMS: '/media/editorial/site_pages/privacy_policy/privacy_policy_sms.html',
  termsSMS: '/media/editorial/site_pages/sms/terms_long.html',
  giftCardTermsAndConditions: '/media/editorial/giftcards/media_includes/terms_and_conditions.html',
  oneYearFreeDeliveryPassTermsAndConditions:
    '/media/editorial/picks/deliverypass/dp_tc_MKT9000228.html',
  createStandingOrder: '/media/editorial/site_pages/standing_orders/create_standing_order.html',
  newStandingOrder: '/media/editorial/site_pages/standing_orders/newuxe_first_standing_order.html',
  advancedProducts: '/media/editorial/holiday/advance_order/delivbar_adv_msg.html',
  howWeRate: '/media/editorial/site_pages/peak_quality/peak_quality_overlay.html',
};
