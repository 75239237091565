import { useReactiveVar } from '@apollo/client';
import { Drawer, IconButton, Popper } from '@mui/material';
import cx from 'classnames';
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useNavigationTracking } from '@hooks/header/useNavigationTracking';
import { reorder as ReorderIcon } from '@assets/icons/informational';
import {
  freshDirect as FreshDirectIcon,
  freshDirectCorporate as FreshDirectCorporateIcon,
} from '@assets/icons/logos';
import { menu as MenuIcon } from '@assets/icons/system';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { OrderModification } from '@components/Modals/OrderModification/OrderModification';
import { BaseLink, Button } from '@components/UI';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { PopUp } from '@components/UI/PopUp/PopUp';
import { routing } from '@constants/routing';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useModal } from '@hooks/common';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useDeliveryAlert } from '@hooks/deliveryAddress/useDeliveryAlert';
import { NavigationData } from '@hooks/header/useNavigationData';
import { useLocalization } from '@hooks/index';
import { AddressButton } from '@layouts/Header/components/TopLine/components/Address/AddressButton';
import { ActivatedDeliveryPass } from '@components/Modals/ActivatedDeliveryPass/ActivatedDeliveryPass';
import { activatedDeliveryPassModalShown } from '@graphql/variables/activatedDeliveryPassModal';
import { KEYBOARD_CODE } from '@constants/keyboard';
import { hasPastOrders, useAuthContext } from '@modules/auth/context';
import { getShowPendingOrdersModalVar } from '@graphql/variables/showPendingOrdersModalVar';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import { useServiceType } from '@hooks/useServiceType';
import { FeatureCheck } from '@modules/featureCheck/FeatureCheck';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useModifyMode } from '@hooks/orderModification/useModifyMode';
import { Account, Cart, DeliveryAlert, Hamburger, Search } from './components';
import { GoToFDOffice } from './components/GoToFDOffice/GoToFDOffice';
import { StandingOrdersButton } from './components/StandingOrdersButton/StandingOrdersButton';
import styles from './TopLine.module.scss';

const DynamicDelivery = dynamic(
  () => import('./components/Delivery/Delivery').then((lib) => lib.Delivery),
  { ssr: false },
);

interface TopLineProps {
  navigationData: NavigationData;
}

export const TopLine = ({ navigationData }: TopLineProps) => {
  const router = useRouter();
  const { t } = useLocalization('header');
  const { shouldShowAlert, closeAlert } = useDeliveryAlert();
  const { userInfo, isKnownUser: isLoggedIn } = useAuthContext();
  const { screenSize } = useHeaderContext();
  const isMobile = !!screenSize?.isMobile;
  const isLargeTablet = !!screenSize?.isLargeTablet;
  const { isStandingOrderVisible } = useMainLayoutContext();
  const { isCorporateServiceType } = useServiceType();
  const LogoIcon = isCorporateServiceType ? FreshDirectCorporateIcon : FreshDirectIcon;
  const logoLabel = isCorporateServiceType ? t('logo.corpLabel') : t('logo.label');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isHamburgerOpened, setIsHamburgerOpened] = useState<boolean>();
  const { data: modifyMode } = useModifyMode();

  const shouldShowModal = useReactiveVar(getShowPendingOrdersModalVar());
  const logoRef = useRef(null);
  const addressRef = useRef(null);
  const reorderRef = useRef(null);
  const anchorElForAlert = logoRef.current;

  const authModalContext = useAuthModalContext();

  const deliveryPassModalShown = useReactiveVar(activatedDeliveryPassModalShown);

  const isFocusedSearchOnLargeTablet = isLargeTablet && isSearchActive;

  // Temporary requirement TUE-15319
  const disableDeliveryAlertFeature = true;

  const renderActivatedDeliveryPassModal = () => {
    if (isMobile) {
      return (
        <ModalWindow
          open={deliveryPassModalShown}
          onClose={() => activatedDeliveryPassModalShown(false)}
        >
          <ActivatedDeliveryPass onClose={() => activatedDeliveryPassModalShown(false)} />
        </ModalWindow>
      );
    }
    return (
      <PopUp
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={deliveryPassModalShown}
        className={styles.delivery_pass_popup}
        onClose={() => activatedDeliveryPassModalShown(false)}
      >
        <ActivatedDeliveryPass onClose={() => activatedDeliveryPassModalShown(false)} />
      </PopUp>
    );
  };

  const {
    isOpen: isOpenDeliveryAddress,
    closeModal: closeModalDeliveryAddress,
    openModal: openModalDeliveryAddress,
  } = useModal('delivery');

  const reorderGtmProps = useNavigationTracking('Reorder');
  const homeGtmProps = useNavigationTracking('Home');

  const { state: authModalState, dispatch, toggleAuthModal, on } = authModalContext;

  const handleModalBack = () => {
    const { SIGN_IN, FORGOT_PASSWORD, SENT_REQUEST } = AUTH_MODAL_TYPES;

    switch (authModalState.currentModal) {
      case FORGOT_PASSWORD:
        dispatch({ type: SIGN_IN });
        break;
      case SENT_REQUEST:
        dispatch({ type: SIGN_IN });
        break;
      default:
        toggleAuthModal();
        setDrawer(true);
    }
  };

  useEffect(
    () =>
      on?.((event) => {
        if (event.type === 'signIn') closeModalDeliveryAddress();
        if (event.type === 'close' || event.type === 'welcomeClose') setDrawer(false);
        if (event.type === 'back') handleModalBack();
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAddressButtonClick = () => {
    openModalDeliveryAddress();
    closeAlert();
  };

  const handleLogoKeyDown = (event: KeyboardEvent) => {
    if (event.code === KEYBOARD_CODE.ENTER && isLoggedIn) {
      router.push(routing.home);
    }
  };

  const onAddressButtonKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === KEYBOARD_CODE.ENTER) {
      openModalDeliveryAddress();
      closeAlert();
    }
  };

  const setDrawer = (open: boolean, event?: KeyboardEvent) => {
    if (event?.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsHamburgerOpened(open);
  };

  const openDrawer = useCallback(() => {
    setDrawer(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setDrawer(false);
  }, []);

  const alertPosition = isMobile ? 'bottom' : 'left-end';
  const onAlertClick = () => {
    openModalDeliveryAddress();
    closeAlert();
  };

  return (
    <div data-testid="top_line" className={styles.top_line}>
      <div className={styles.layout}>
        <div className={styles.layout_menu}>
          <div className={styles.top_line_menu_icon}>
            <IconButton onClick={openDrawer}>
              <MenuIcon width={20} height={10} aria-label="side menu" />
            </IconButton>
          </div>
          <Drawer onClose={closeDrawer} open={isHamburgerOpened} className={styles.drawer}>
            <Hamburger
              onAddressButtonClick={() => openModalDeliveryAddress()}
              setDrawer={setDrawer}
              navigationData={navigationData}
              isModifyMode={modifyMode.active}
            />
          </Drawer>
        </div>
        <div
          className={cx(styles.layout_start, {
            [styles.hide]: isFocusedSearchOnLargeTablet,
            [styles.show]: !isFocusedSearchOnLargeTablet,
          })}
        >
          <BaseLink href={routing.home}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <div
              data-testid="logo"
              className={styles.logo_wrapper}
              onKeyDown={handleLogoKeyDown}
              role="group"
              //eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
            >
              <FeatureCheck
                featureName={FEATURE.COS}
                enabled={() => (
                  <a
                    {...homeGtmProps}
                    ref={logoRef}
                    className={isCorporateServiceType ? styles.cosLogo : styles.logo}
                    data-cy="home-nav-link"
                  >
                    <LogoIcon aria-label={logoLabel} role="img" />
                  </a>
                )}
                disabled={() => (
                  <a
                    {...homeGtmProps}
                    ref={logoRef}
                    className={styles.logo}
                    data-cy="home-nav-link"
                  >
                    <FreshDirectIcon aria-label="Freshdirect logo" role="img" />
                  </a>
                )}
              />
            </div>
          </BaseLink>
          <div
            className={cx(styles.delivery, { [styles.hidden]: modifyMode.active })}
            data-testid="delivery-section"
          >
            <p className={styles.delivery_title}>{t('header:deliveryTitle')}</p>
            <AddressButton
              onClick={onAddressButtonClick}
              onKeyDown={onAddressButtonKeyDown}
              ref={addressRef}
              isExpanded={isOpenDeliveryAddress}
              showIcon
            />
          </div>
        </div>
        <div className={styles.layout_search} data-testid="search-bar">
          <Search onFocus={() => setIsSearchActive(true)} onBlur={() => setIsSearchActive(false)} />
        </div>
        <div
          ref={reorderRef}
          className={cx(styles.layout_end, {
            [styles.hide]: isFocusedSearchOnLargeTablet,
            [styles.show]: !isFocusedSearchOnLargeTablet,
          })}
        >
          <div className={styles.fdSwitch}>
            <GoToFDOffice />
          </div>
          {isStandingOrderVisible && <StandingOrdersButton />}
          {hasPastOrders(userInfo) && (
            <div className={styles.reorder}>
              <BaseLink passHref href={routing.reorderItems}>
                <Button variant="text" {...reorderGtmProps}>
                  <ReorderIcon width={32} height={32} />
                  <span className={styles.reorder_title}>{t('reorder')}</span>
                </Button>
              </BaseLink>
            </div>
          )}
          <div className={styles.account}>
            <Account />
          </div>
          <Cart />
          {shouldShowModal && <OrderModification shouldShowPendingOrdersModal={shouldShowModal} />}
          {deliveryPassModalShown && renderActivatedDeliveryPassModal()}
        </div>
      </div>

      {isOpenDeliveryAddress && (
        <DynamicDelivery
          closeModal={() => {
            closeModalDeliveryAddress();
            isMobile && setIsHamburgerOpened(false);
          }}
          closeSubModal={() => {
            closeModalDeliveryAddress();
            isMobile && setIsHamburgerOpened(true);
          }}
          isOpen={isOpenDeliveryAddress}
        />
      )}

      {!disableDeliveryAlertFeature && (
        <Popper
          placement={alertPosition}
          sx={{ zIndex: 'tooltip' }}
          anchorEl={anchorElForAlert}
          open={shouldShowAlert}
          disablePortal
        >
          <DeliveryAlert onClose={closeAlert} onClick={onAlertClick} />
        </Popper>
      )}
    </div>
  );
};
