import { Typography, TypographyProps } from '@mui/material';
import cx from 'classnames';
import styles from './Title.module.scss';

interface AccountPageTitle extends TypographyProps {
  testId?: string;
  component?: React.ElementType;
}

export const Title = ({ children, className, testId, component }: AccountPageTitle) => {
  return (
    <Typography
      component={component ? component : 'h3'}
      data-testid={testId}
      className={cx(styles.title, className)}
    >
      {children}
    </Typography>
  );
};
