import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';
import { AuthEventResult } from '@api';

interface FireLoginSuccessEventProps {
  authEvent?: AuthEventResult | null;
}

export const fireLoginSuccessEvent = (data: FireLoginSuccessEventProps) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.LOGIN_SUCCESS,
    data,
  });
};
