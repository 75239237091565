/* eslint-disable @typescript-eslint/naming-convention*/

export interface PGaCustomer {
  deliverypass?: string;
  deliverypass_type?: string;
  delivery_pass_status?: string;
  customer_id?: string;
  fduserid?: string;
  cohort?: string;
  order_count?: number | string;
  order_count_residential?: number | string;
  order_count_corporate?: number | string;
  customer_email?: string | null;
  modifyMode?: boolean;
  timeslot_reservation_eligible?: boolean;
  timeslot_reservation?: boolean;
  county?: string;
  state?: string;
  city?: string;
  zip_code?: number | string;
  user_type?: string;
  user_status?: string;
  delivery_type?: string;
  login_type?: string | null;
  express_eligible?: boolean | null;
  deliverypass_term?: string;
  referral_signup?: boolean;
}

interface IGaCustomerDeliveryPass {
  deliverypass: string;
  deliverypass_type?: string;
  deliverypass_term?: string;
  delivery_pass_status: string;
}

interface IGaCustomerOrderCount {
  order_count: string;
  order_count_residential: string;
  order_count_corporate: string;
}

interface IGaCustomerLocation {
  county: string;
  state: string;
  city: string;
  zip_code: string;
}

export interface IGaCustomer extends IGaCustomerDeliveryPass, IGaCustomerOrderCount {
  customer_id: string;
  fduserid: string;
  cohort: string;
  customer_email: string;
  modifyMode: boolean;
  timeslot_reservation_eligible: boolean;
  timeslot_reservation: boolean;
  county: string;
  state: string;
  city: string;
  zip_code: string;
  user_type: string;
  user_status: string;
  delivery_type: string;
  login_type: string;
  express_eligible: boolean;
  referral_signup?: boolean;
}

// NOTE: This constructor is extracted to reduce complexity
const GaCustomerDeliveryPass = (p: PGaCustomer): IGaCustomerDeliveryPass => ({
  deliverypass: p.deliverypass ?? '',
  deliverypass_type: p.deliverypass_type ?? '',
  delivery_pass_status: p.delivery_pass_status ?? '',
  deliverypass_term: p.deliverypass_term ?? '',
});

// NOTE: This constructor is extracted to reduce complexity
const GaCustomerOrderCount = (p: PGaCustomer): IGaCustomerOrderCount => ({
  order_count: p.order_count?.toString() ?? '',
  order_count_residential: p.order_count_residential?.toString() ?? '',
  order_count_corporate: p.order_count_corporate?.toString() ?? '',
});

// TODO: These functions are extracted to reduce complexity. It should be refactored to get rid of extra functions
const GaCustomerLocation = (p: PGaCustomer): IGaCustomerLocation => ({
  county: p.county ?? '',
  state: p.state ?? '',
  city: p.city ?? '',
  zip_code: p.zip_code?.toString() ?? '',
});

export const GaCustomer = (p: PGaCustomer): IGaCustomer => ({
  ...GaCustomerDeliveryPass(p),
  ...GaCustomerOrderCount(p),
  ...GaCustomerLocation(p),
  customer_id: p.customer_id ?? '',
  fduserid: p.fduserid ?? '',
  cohort: p.cohort ?? '',
  customer_email: p.customer_email ?? '',
  modifyMode: p.modifyMode ?? false,
  timeslot_reservation_eligible: p.timeslot_reservation_eligible ?? false,
  timeslot_reservation: p.timeslot_reservation ?? false,
  user_type: p.user_type ?? '',
  user_status: p.user_status ?? '',
  delivery_type: p.delivery_type ?? '',
  login_type: p.login_type ?? '',
  express_eligible: p.express_eligible ?? false,
  referral_signup: p.referral_signup ?? false,
});
