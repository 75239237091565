import { CAROUSELS_CACHE_FIELDS, PRODUCT_CACHE_FIELDS } from '@constants/cacheFields';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { CART_CACHE_FIELD } from '@graphql/cart/queries/getCart';

export const FIELDS_DEPEND_ON_DEFAULT_ADDRESS = [
  ...PRODUCT_CACHE_FIELDS,
  ...CAROUSELS_CACHE_FIELDS,
  LIGHT_CART_CACHE_FIELD,
  CART_CACHE_FIELD,
  'paymentAvailabilities',
  'userDetails',
  'accountPreferences',
  'pendingOrdersAlertInfo',
  'timeslots',
];
