import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { useSettings } from '@hooks/useSettings/useSettings';
import { useAuthContext } from '@modules/auth/context/AuthContext';

interface UseOpenLoginReminderProps {
  itemNumber: number;
  loading: boolean;
}

const shouldOpenLoginRemainder = () =>
  sessionStorageService?.read(STORAGE_KEYS.OPEN_LOGIN_REMINDER) ?? true;

export const useOpenLoginReminder = ({ loading, itemNumber }: UseOpenLoginReminderProps) => {
  const router = useRouter();
  const { isRecognizedUser } = useAuthContext();
  const {
    data: { atcRetentionThreshold: cartLinesLimit },
    loading: loadingSettings,
  } = useSettings();
  const commonLoading = loading || loadingSettings;
  const [isOpen, setIsOpen] = useState(false);
  const [initItemNumber, setInitItemNumber] = useState(itemNumber);
  const [initialized, setInitialized] = useState(false);

  const closeReminder = () => {
    setIsOpen(false);
  };

  const openReminder = () => {
    setIsOpen(true);
    sessionStorageService?.put(STORAGE_KEYS.OPEN_LOGIN_REMINDER, false);
  };

  useEffect(() => {
    if (!commonLoading) {
      setInitItemNumber(itemNumber);
      setInitialized(true);

      return;
    }
    setInitialized(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonLoading]);

  useEffect(() => {
    if (commonLoading || !initialized || !shouldOpenLoginRemainder()) {
      return;
    }

    // Recognized flow
    if (isRecognizedUser) {
      const limit = initItemNumber + cartLinesLimit;

      // If number of products in cart has changed to initial number + limit, then open reminder
      if (itemNumber >= limit) {
        openReminder();
        return;
      }

      closeReminder();
      return;
    }

    // Guest flow
    // Checking that our initial value of products in cart is less than or equal to limit
    if (initItemNumber <= cartLinesLimit) {
      // Checking that number of products in cart is greater than or equal to limit
      if (itemNumber >= cartLinesLimit) {
        openReminder();
        return;
      }

      closeReminder();
      return;
    }
    // If initial value was greater than limit, then check that number of products in cart has changed to open reminder
    if (initItemNumber !== itemNumber) {
      openReminder();
      return;
    }

    closeReminder();

    // Follow only number of products' changes in cart
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemNumber]);

  useEffect(() => {
    const handleRouteChange = () => {
      closeReminder();
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeReminder]);

  return {
    closeReminder,
    isOpen,
  };
};
