import { Price } from '@commons/price';

interface GetFormattedProductPriceProps {
  price: Price;
  displayPrice: number;
  quantity: number;
}

export const getFormattedProductPrice = ({
  displayPrice,
  quantity,
  price,
}: GetFormattedProductPriceProps): Price => ({
  ...price,
  currency: price?.currency ?? '',
  formattedPrice: price?.formattedPrice ?? '',
  value: displayPrice / quantity,
});
