import { useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { productAdapter } from '@adapters/productAdapter';
import { ProductBasicData } from '@api';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { AUTOSUGGEST_PRODUCT_LABEL } from '@commons/product';
import { SUGGESTIONS_QUERY } from '@graphql/product/queries/suggestions';

const MAX_PURCHASED_BEFORE_SUGGESTIONS_COUNT = {
  MOBILE: 2,
  DESKTOP: 3,
};

export const useLazyProductSuggestions = (query: string) => {
  const { screenSize } = useHeaderContext();
  const isMobile = !!screenSize?.isMobile;
  const { getProduct } = productAdapter();
  const [fetchSuggestions, { data, loading }] = useLazyQuery(SUGGESTIONS_QUERY, {
    ssr: false,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: {
      text: query,
    },
  });

  return useMemo(() => {
    const maxPurcahsedBeforeSuggestionsCount = isMobile
      ? MAX_PURCHASED_BEFORE_SUGGESTIONS_COUNT.MOBILE
      : MAX_PURCHASED_BEFORE_SUGGESTIONS_COUNT.DESKTOP;
    const exactMatch = data?.suggest.exactMatch
      ? [{ ...getProduct(data.suggest.exactMatch), label: AUTOSUGGEST_PRODUCT_LABEL.EXACT_MATCH }]
      : [];
    const purchasedBefore = (data?.suggest.purchasedBefore ?? [])
      .slice(0, maxPurcahsedBeforeSuggestionsCount)
      .map((product: ProductBasicData) => ({
        ...getProduct(product),
        label: AUTOSUGGEST_PRODUCT_LABEL.PURCHASED_BEFORE,
      }));
    const notPurchasedBefore = (data?.suggest.notPurchasedBefore ?? []).map(getProduct);
    const suggestions = data?.suggest.suggestions ?? [];

    return {
      suggestions: [...exactMatch, ...purchasedBefore, ...notPurchasedBefore, ...suggestions],
      loading,
      fetchSuggestions,
    };
  }, [data, loading, isMobile, fetchSuggestions, getProduct]);
};
