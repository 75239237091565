import { useQuery } from '@apollo/client';
import { GET_REFERRAL_PROMOTION_DATA } from '@graphql/account/queries/referralPromotionData';

export const useReferralPromotionData = () => {
  const { data, refetch, ...queryResult } = useQuery(GET_REFERRAL_PROMOTION_DATA, {
    fetchPolicy: 'cache-and-network',
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...queryResult,
    data,
    refetch,
  };
};
