import { useMutation } from '@apollo/client';
import { REMOVE_PROMO } from '@graphql/promo/mutations/removePromo';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { RemovePromocodeMutation, RemovePromocodeMutationVariables } from '@api';
import { CART_CACHE_FIELD } from '@graphql/cart/queries/getCart';

export const useRemovePromo = () => {
  const { clearCacheFields } = useCacheFieldsInvalidate();
  return useMutation<RemovePromocodeMutation, RemovePromocodeMutationVariables>(REMOVE_PROMO, {
    onCompleted() {
      clearCacheFields([CART_CACHE_FIELD, 'initModifyOrderCheckout', 'appliedPromotion']);
    },
  });
};
