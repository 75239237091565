import Head from 'next/head';
import { HTMLAttributes } from 'react';

interface JsonLdScriptProps extends HTMLAttributes<HTMLScriptElement> {
  data: Record<string, unknown>;
}

export const JsonLdScript = ({ data, ...props }: JsonLdScriptProps) => {
  return (
    <Head>
      <script
        {...props}
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        type="application/ld+json"
      />
    </Head>
  );
};
