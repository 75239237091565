import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cx from 'classnames';
import {
  arrowRight as ArrowRightIcon,
  arrowRightV2 as ArrowRightIconV2,
} from '@assets/icons/system';
import styles from './GroupScaleLabel.module.scss';

interface GroupScaleLabelProps {
  className?: string;
  children?: never;
  message: string;
  isActive: boolean;
  onClick: (e?: { currentTarget: { id: string | undefined } }) => void;
  size?: 'medium' | 'large';
  id?: string;
  isV2?: boolean;
}

export const GroupScaleLabel = ({
  message,
  isActive,
  onClick,
  className,
  size = 'medium',
  id,
  isV2 = false,
}: GroupScaleLabelProps) => {
  const labelKeyPressHandler = (
    event: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  ) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  //Need to start with capital letter otherwise TypeScript would yell
  const ArrowIcon = isV2 ? ArrowRightIconV2 : ArrowRightIcon;

  return (
    <div
      className={cx(className, styles.group_scale_label, styles[size], {
        [styles.active]: isActive,
        [styles.v2]: isV2,
      })}
      onClick={onClick}
      role={'link'}
      tabIndex={0}
      onKeyPress={labelKeyPressHandler}
      data-qa="pdp_group_scale_label"
      id={id}
    >
      <p
        className={cx(styles.group_scale_label_message, styles[size])}
        aria-label="Group scale text"
      >
        {message}
      </p>
      <ArrowIcon className={styles.group_scale_label_icon} aria-label="Group scale icon" />
    </div>
  );
};
