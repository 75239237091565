import { useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { REQUEST_DEBOUNCE_TIME } from '@constants/debounceTime';
import { GiftCard } from '@adapters/giftCardAdatper';
import { useLocalization } from '@hooks/useLocalization';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { useUpdateGiftCardInCart } from '@hooks/giftCard/useUpdateGiftCardInCart';
import { GET_GIFT_CART } from '@graphql/cart/queries/getGiftCart';
import { MAX_BULK_GIFT_CARD_QTY_AMOUNT } from '@utils/validation';
import { ERROR_SPECIFICATIONS } from '@constants/errorCodes';
import { AlertType } from '../GiftCardControllers';

interface RequestItem {
  prevQuantity: number;
  newQuantity: number;
}

interface UseCartLineControlsActions {
  giftCard: GiftCard;
  quantity: number;
  maxQuantity: number;
  minQuantity: number;
  setQuantity: (quantity: number) => void;
  setAlert: (alert: AlertType) => void;
  onDelete: (id: string) => void;
}

export const useGiftCardControlsActions = ({
  quantity,
  maxQuantity,
  minQuantity,
  setQuantity,
  setAlert,
  giftCard,
  onDelete,
}: UseCartLineControlsActions) => {
  const { t } = useLocalization('components');
  const [currentRequest, setCurrentRequest] = useState<RequestItem>();
  const completedRequest = useDebounce<RequestItem | undefined>(
    currentRequest,
    REQUEST_DEBOUNCE_TIME,
  );
  const [updateGiftCard] = useUpdateGiftCardInCart();
  const updateCartLineQuantity = () => {
    updateGiftCard({
      onError(error) {
        setQuantity(currentRequest?.prevQuantity ?? 0);
        const errorMessageTranslateProp =
          error.graphQLErrors[0].extensions?.classification ===
          ERROR_SPECIFICATIONS.CART_LINES_LIMIT_EXCEEDED
            ? 'cartTile.alerts.quantityExceed'
            : 'cartTile.alerts.cantUpdateQuantity';
        setAlert({
          active: true,
          message: t(errorMessageTranslateProp, { value: MAX_BULK_GIFT_CARD_QTY_AMOUNT }),
        });
      },
      variables: {
        input: {
          id: giftCard.id,
          templateId: giftCard.gcTemplate.gcTemplateId,
          amount: giftCard.amount.value.toString(),
          deliveryMethod: giftCard.deliveryMethod,
          recipientName: giftCard.senderName,
          recipientEmail: giftCard.senderEmail,
          senderName: giftCard.senderName,
          senderEmail: giftCard.senderEmail,
          message: giftCard.message ?? '',
          quantity: quantity,
          isBulk: giftCard.isBulk,
        },
      },
      refetchQueries: [GET_GIFT_CART],
    });
  };
  useUpdateEffect(() => {
    if (completedRequest) {
      updateCartLineQuantity();
    }
  }, [completedRequest]);
  const validateMinLimit = (current: number) => {
    const isInvalid = current < minQuantity;
    if (isInvalid) {
      setAlert({
        active: true,
        message: t('cartTile.alerts.minimum', { minQuantity }),
      });
    }
    return !isInvalid;
  };
  const validateMaxLimit = (current: number) => {
    const isInvalid = current > maxQuantity;
    if (isInvalid) {
      sendGtmEvent(
        GtmCustomEvent({
          /* eslint-disable @typescript-eslint/naming-convention */
          event: 'item_limit_reached',
          event_name: 'item_limit_reached',
          ua_category: 'item limit',
          ua_action: 'item limit reached',
          /* eslint-enable @typescript-eslint/naming-convention */
        }),
      );
      setAlert({
        active: true,
        message: t('cartTile.alerts.maximum', { maxQuantity }),
      });
    }
    return !isInvalid;
  };
  const change = (newQuantity: number): void => {
    if (minQuantity < 1 && newQuantity === 0) {
      onDelete(giftCard.id);
      return;
    }
    if (!validateMinLimit(newQuantity)) {
      setQuantity(minQuantity);
      return;
    }
    if (!validateMaxLimit(newQuantity)) {
      setQuantity(maxQuantity);
      return;
    }
    setQuantity(newQuantity);
    setCurrentRequest((oldRequest?: RequestItem) => {
      return oldRequest
        ? {
            ...oldRequest,
            newQuantity,
          }
        : {
            prevQuantity: quantity,
            newQuantity,
          };
    });
  };

  return { change };
};
