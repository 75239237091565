import { Typography } from '@mui/material';
import cx from 'classnames';
import { useState } from 'react';
import { useLocalization, useModal } from '@hooks/index';
import { Button } from '@components/UI';
import { GiftCard } from '@adapters/giftCardAdatper';
import { useDeleteFromGiftCards } from '@hooks/giftCard/useDeleteFromGiftCards';
import { EditGiftCardModal } from '@components/Modals/GiftCardModals/EditGiftCardModal';
import { Values } from '@components/Modals/GiftCardModals/types';
import { GiftCardSingleView } from '@components/GiftCard/GiftCardSingleView/GiftCardSingleView';
import styles from './SingleView.module.scss';

interface SingleViewProps {
  giftCard: GiftCard;
  isSideBag?: boolean;
}

export const SingleView = ({ giftCard, isSideBag }: SingleViewProps) => {
  const { t } = useLocalization('giftCards');
  const [deleteFromGiftCards, { loading }] = useDeleteFromGiftCards();
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();
  const [giftCardToEdit, setGiftCardToEdit] = useState<Values>();

  const handleRemoveButtonClick = () => {
    deleteFromGiftCards({
      variables: {
        giftCardId: giftCard.id,
      },
    });
  };

  const handleEditGiftCardButtonClick = () => {
    setGiftCardToEdit({
      giftCardId: giftCard.id,
      giftCardAmount: String(giftCard.amount.value),
      senderName: giftCard.senderName,
      recipientName: giftCard.recipientName,
      recipientEmail: giftCard.recipientEmail,
      recipientMessage: giftCard.message,
      giftCardType: giftCard.deliveryMethod,
      giftCardTemplateId: giftCard.gcTemplate.gcTemplateId,
      quantity: giftCard.quantity,
      senderEmail: giftCard.senderEmail,
      isBulk: false,
    });
    openEditModal();
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.container_loading]: loading,
        [styles.horizontal_padding]: isSideBag,
      })}
    >
      <div className={styles.heading}>
        <Typography component="h2" variant="h4">
          <span>
            {giftCard.amount.formattedPrice.split('.')[0]} {t('giftCardBag.giftCardTitle')}
          </span>
        </Typography>
        <Button
          aria-label={`${t('giftCardBag.edit')} ${t('giftCardBag.giftCardTitle')}`}
          variant="underline"
          className={styles.edit}
          onClick={handleEditGiftCardButtonClick}
        >
          {t('giftCardBag.edit')}
        </Button>
      </div>
      <GiftCardSingleView giftCard={giftCard} />
      <div className={styles.footer}>
        <Button
          aria-label={`${t('giftCardBag.remove')} ${t('giftCardBag.giftCardTitle')}`}
          variant="underline"
          className={styles.remove_btn}
          onClick={handleRemoveButtonClick}
        >
          {t('giftCardBag.remove')}
        </Button>
      </div>
      {giftCardToEdit && (
        <EditGiftCardModal
          open={isEditModalOpen}
          onClose={closeEditModal}
          values={giftCardToEdit}
        />
      )}
    </div>
  );
};
