/* eslint-disable @typescript-eslint/naming-convention */
import { ProductMetrics } from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { IGaCustomer } from '@modules/ga/eventBodyGetters/utils/ga-customer';

export interface PurchaseEventOptions {
  currencyCode: string;
  products: ProductMetrics[];
  customer: IGaCustomer;
  orderType: string;
  transactionId: string;
  revenue: number;
  tax: number;
  shipping: number;
  etipping: number;
  coDiscountamount: number;
  paymentType: string;
  purchaseCoupon: string[];
  pixelEventId: string;
  shoppingMode: string;
  isExpressOrder: boolean;
  deliveryServiceType: string;
  deliveryDate: string;
}

interface PurchaseEvent {
  event: string;
  event_name: string;
  'shopping-mode': string;
  ecommerce: {
    currencyCode: string;
    isExpressOrder: boolean;
    deliveryServiceType: string;
    deliveryDate: string;
    purchase: {
      actionField: {
        id: string;
        revenue: number;
        tax: number;
        shipping: number;
        etipping: number;
        payment_type: string;
        order_type: string;
        'co-discountamount': string;
        coupon: string[];
      };
      products: ProductMetrics[];
    };
  };
  pixelEventId: string;
  ua_category: string;
  ua_action: string;
  ua_label?: string;
  customer: IGaCustomer;
}

export const getPurchaseEvent = ({
  products,
  currencyCode,
  customer,
  transactionId,
  revenue,
  orderType,
  tax,
  shipping,
  etipping,
  paymentType,
  purchaseCoupon,
  pixelEventId,
  coDiscountamount,
  shoppingMode,
  isExpressOrder,
  deliveryServiceType,
  deliveryDate,
}: PurchaseEventOptions): PurchaseEvent => {
  return {
    event: 'checkout-success',
    event_name: 'purchase',
    customer,
    ua_category: 'Ecommerce Action',
    ua_action: 'Purchase',
    'shopping-mode': shoppingMode,
    ecommerce: {
      currencyCode: currencyCode,
      isExpressOrder: isExpressOrder,
      deliveryServiceType: deliveryServiceType,
      deliveryDate: deliveryDate,
      purchase: {
        actionField: {
          id: transactionId,
          revenue,
          tax,
          shipping,
          etipping,
          order_type: orderType,
          payment_type: paymentType,
          coupon: purchaseCoupon,
          'co-discountamount': coDiscountamount.toFixed(1),
        },
        products,
      },
    },
    pixelEventId,
  };
};
