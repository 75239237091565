import { useQuery } from '@apollo/client';
import { CREDITS_ORDER_HISTORY } from '@graphql/account/queries/creditsOrderHistory';
import { creditAdapter } from '@adapters/creditAdapter';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useGetCreditsOrderHistory = () => {
  const { getCreditsOrderHistory } = creditAdapter();
  const { isKnownUser: isLoggedIn, isRecognizedUser } = useAuthContext();
  const isGuestUser = !isLoggedIn || isRecognizedUser;

  const { data, loading, error } = useQuery(CREDITS_ORDER_HISTORY, {
    skip: isGuestUser,
    errorPolicy: 'all',
  });

  const strongOrders = getCreditsOrderHistory(data?.creditsOrderHistory);
  const hasOrders = !!data?.creditsOrderHistory?.hasEligibleOrders;

  return {
    data: {
      ...strongOrders,
      hasOrders,
    },
    loading,
    error,
  };
};
