import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { DepartmentUnion } from '@api';
import { isSuperDepartmentItem } from '../../../../../../isSuperDepartmentItem';
import styles from '../../Hamburger.module.scss';
import { NavIcon } from './NavIcon';
import { SubMenuModalData } from './SubMenuModalData';

export interface DepartmentListItemProps {
  superDepartmentName?: string;
  department: DepartmentUnion;
  icon?: string | null;
  openSubMenu(data: SubMenuModalData): void;
}

export const DepartmentListItem: FC<DepartmentListItemProps> = ({
  superDepartmentName,
  department,
  icon,
  openSubMenu,
}) => {
  const handleClick = useCallback(
    () => openSubMenu({ superDepartmentName, department }),
    [department, openSubMenu, superDepartmentName],
  );
  if (isSuperDepartmentItem(department)) return null;

  return (
    <ListItemButton className={styles.list_button} onClick={handleClick}>
      {!superDepartmentName && <NavIcon icon={icon} alt={`${department.name} icon`} />}
      <Typography noWrap className={styles.list_text}>
        {department.name}
      </Typography>
    </ListItemButton>
  );
};
