import { useRouter } from 'next/router';
import {
  getCartLineByProductId,
  getLightCartLineByProductId,
} from '@modules/ga/eventBodyGetters/utils/getCartLine';
import { getCartPosition } from '@modules/ga/eventBodyGetters/utils/getCartPosition';
import {
  fireAddToCartGAEvent,
  fireRemoveFromCartGAEvent,
} from '@modules/ga/events/ecommerce/add-to-cart/addToCartEventCreator';
import { Cart, CartLight, CartLineLightWithGA } from '@commons/cart';
import { calcLightCartQuantityDifference } from '@modules/ga/events/ecommerce/add-to-cart/utils/calcLightCartQuantityDifference';
import { calcQuantityDifference } from '@modules/ga/events/ecommerce/add-to-cart/utils/calcCartQuantityDifference';
import { ParamsContext } from '@context/AddToCartContext/AddToCartContext.utils';
import { getPixelEventIds } from '@modules/ga/utils/getPixelEventIds';
import { getFormattedProductPrice } from '@modules/ga/utils/getFormattedProductPrice';
import { Price } from '@commons/price';
import { getDiscount } from '@modules/ga/eventBodyGetters/utils/getDiscount';
import { Product } from '@commons/product';
import { ProductQuantity } from '@api';
import { emit } from '@modules/emitter';
import { GtmAddToCart } from '@modules/ga/eventBodyGetters/gtm-add-to-cart';
import { getBreadcrumbs } from '@modules/ga/utils/getBreadcrumbs';

export interface TrackAddToCartParams {
  newCart: Cart;
  oldCart: Cart;
  productIds: string[];
  itemPosition: number;
  listName: string;
}

export interface TrackAddToLightCartParams {
  newCart: CartLight;
  oldCart: CartLight;
  productId: string;
  itemPosition: number;
}

export const useAddToCartEvent = () => {
  const router = useRouter();
  const breadcrumb = getBreadcrumbs();
  const baseData = {
    cartPosition: getCartPosition(router),
    breadcrumb,
  };

  const trackAddToCart = ({
    newCart,
    oldCart,
    productIds,
    itemPosition,
    listName,
  }: TrackAddToCartParams): void => {
    const products = productIds.reduce<Product[]>((previousValue, productId) => {
      const gaCartLine = getCartLineByProductId(newCart, productId);
      const quantityDifference = calcQuantityDifference(newCart, oldCart, productId);

      if (!gaCartLine || quantityDifference === 0) {
        return { ...previousValue };
      }

      const product = {
        ...gaCartLine.product,
        discountAmount: getDiscount(gaCartLine),
        price: <Price>getFormattedProductPrice({
          price: gaCartLine.product.price,
          quantity: gaCartLine.quantitySelector.quantity,
          displayPrice: gaCartLine.displayPrice.value,
        }),
        quantity: {
          ...gaCartLine.product.quantity,
          quantity: quantityDifference,
        },
      };

      return [...previousValue, product];
    }, []);

    if (!products.length) {
      return;
    }

    if (products.every((product) => product.quantity.quantity < 0)) {
      fireRemoveFromCartGAEvent({
        ...baseData,
        itemListName: listName,
        products,
        itemPosition,
      });
    } else if (products.every((product) => product.quantity.quantity > 0)) {
      fireAddToCartGAEvent({
        ...baseData,
        products,
        itemPosition,
        listName,
        pixelEventIds: getPixelEventIds(newCart),
      });

      emit(
        GtmAddToCart({
          pixelEventIds: getPixelEventIds(newCart),
        }),
      );
    }
  };

  return {
    trackAddToCart,
  };
};

export const useAddToCartLightEvent = () => {
  const router = useRouter();
  const breadcrumb = getBreadcrumbs();
  const baseData = {
    cartPosition: getCartPosition(router),
    breadcrumb,
  };

  const trackAddToLightCart = (
    newCart: CartLight,
    oldCart: CartLight,
    paramsContext: ParamsContext,
  ): void => {
    const { listName, productIds, itemPosition, sponsored } = paramsContext;
    const products = productIds.reduce<(CartLineLightWithGA & { quantity: ProductQuantity })[]>(
      (previousValue, productId) => {
        const gaCartLine = getLightCartLineByProductId(newCart, productId);
        const quantityDifference = calcLightCartQuantityDifference(newCart, oldCart, productId);

        if (!gaCartLine || quantityDifference === 0) {
          return [...previousValue];
        }

        const product = {
          ...gaCartLine,
          quantity: {
            quantity: quantityDifference,
            maxQuantity: gaCartLine.quantity?.maxQuantity ?? 0,
            minQuantity: gaCartLine.quantity?.minQuantity ?? 0,
            quantityIncrement: gaCartLine.quantity?.quantityIncrement ?? 0,
          },
        };

        return [...previousValue, product];
      },
      [],
    );

    if (!products.length) {
      return;
    }

    if (products.every((product) => product.quantity.quantity < 0)) {
      fireRemoveFromCartGAEvent({
        ...baseData,
        itemListName: listName,
        itemPosition,
        products,
      });
    } else if (products.every((product) => product.quantity.quantity > 0)) {
      fireAddToCartGAEvent({
        ...baseData,
        itemPosition,
        listName,
        products,
        pixelEventIds: getPixelEventIds(newCart),
        sponsored,
      });
    }
  };
  return {
    trackAddToLightCart,
  };
};
