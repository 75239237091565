import { FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { GIFTCARD_FIELDS, Values } from '@components/Modals/GiftCardModals/types';
import { CUSTOM_AMOUNT_INPUT_VALUE } from '@components/Modals/GiftCardModals/constants';

export const useGiftCardModal = () => {
  const [isCustomAmountEditShown, setIsCustomAmountEditShown] = useState(false);
  const formRef = useRef<FormikProps<Values>>(null);

  const handleClose = () => {
    setIsCustomAmountEditShown(false);
  };

  const handleOpen = () => {
    setIsCustomAmountEditShown(true);
  };

  const handleBack = () => {
    const isCustomAmountTileAlreadySelected =
      formRef.current?.values[GIFTCARD_FIELDS.GIFTCARD_DEFAULT_AMOUNT] ===
      CUSTOM_AMOUNT_INPUT_VALUE;
    const newValue = isCustomAmountTileAlreadySelected
      ? formRef.current?.values[GIFTCARD_FIELDS.GIFTCARD_AMOUNT]
      : '';

    formRef.current?.setFieldValue(GIFTCARD_FIELDS.GIFTCARD_CUSTOM_AMOUNT, newValue);
    setIsCustomAmountEditShown(false);
  };

  return {
    isCustomAmountEditShown,
    formRef,
    handleBack,
    handleClose,
    handleOpen,
  };
};
