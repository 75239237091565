import MuiButton from '@mui/base/Button';
import cn from 'classnames';
import { MouseEvent, ReactNode, ElementType } from 'react';
import styles from './ButtonText.module.scss';

export interface ButtonTextProps {
  children: ReactNode;
  type?: 'primary' | 'secondary';
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  iconComponent?: ElementType<{
    width: number | string;
    height: number | string;
    className: string;
  }>;
  id?: string;
  className?: string;
}

export const ButtonText = ({
  type = 'primary',
  disabled = false,
  children,
  iconComponent: IconComponent,
  id,
  className,
  ...restProps
}: ButtonTextProps) => {
  return (
    <MuiButton
      {...restProps}
      className={cn(styles.buttonText, className, {
        [styles.primary]: type === 'primary',
        [styles.secondary]: type === 'secondary',
      })}
      disabled={disabled}
      id={id}
    >
      {children}
      {IconComponent && (
        <span className={styles.icon_container}>
          <IconComponent width={12} height={12} className={styles.icon} />
        </span>
      )}
    </MuiButton>
  );
};
