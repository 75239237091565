import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';

export const addressChangingVar = makeVar<boolean>(false);

export const useDeliveryAddressChange = () => {
  const setAddressChangingStateTo = useCallback((state: boolean) => {
    addressChangingVar(state);
  }, []);
  const isAddressRequesting = useReactiveVar(addressChangingVar);

  return { isAddressRequesting, setAddressChangingStateTo };
};
