import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI/Button/Button';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './DeleteReceivedGiftCardModal.module.scss';

interface DeleteReceivedGiftCardModalProps {
  opened: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteReceivedGiftCardModal = ({
  opened,
  onClose,
  onDelete,
}: DeleteReceivedGiftCardModalProps) => {
  const { t } = useLocalization('account');

  return (
    <ModalWindow
      open={opened}
      onClose={onClose}
      className={styles.modal}
      labelId={MODAL_TITLE_ID.DELETE_RECEIVED_GIFTCARD}
    >
      <div className={styles.modal_wrapper}>
        <Typography
          variant="h3"
          component="h2"
          className={styles.heading}
          id={MODAL_TITLE_ID.DELETE_RECEIVED_GIFTCARD}
        >
          {t('delivery.deleteModal.areYouSure')}
        </Typography>
        <Typography className={styles.info_text} variant="body" component="div">
          {t('giftCards.deleteModal.deleteInfo')}
        </Typography>
        <Button className={styles.button} onClick={onDelete} size="large" isFullWidth>
          {t('giftCards.deleteModal.deleteGiftCard')}
        </Button>
        <Button onClick={onClose} variant="outlined" size="large" isFullWidth>
          {t('giftCards.deleteModal.keepGiftCard')}
        </Button>
      </div>
    </ModalWindow>
  );
};
