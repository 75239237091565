import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { AuthEventResult } from '@api';

export interface GetLoginSuccessEventProps {
  authEvent?: AuthEventResult | null;
  commonAnalyticsData: AnalyticsData;
}
/* eslint-disable @typescript-eslint/naming-convention */

export const getLoginSuccessEvent = (p: GetLoginSuccessEventProps) => ({
  event: 'login-success',
  event_name: 'login',
  ua_category: 'Login',
  ua_action: 'login-success',
  customer: getCustomerData({
    userDetails: p.commonAnalyticsData.userDetails,
    minimumAccountPreferences: p.commonAnalyticsData.minimumAccountPreferences,
    userStatus: p.commonAnalyticsData.userStatus,
    address: p.commonAnalyticsData.addressData,
    geoData: p.commonAnalyticsData.geoData,
  }),
  pixelEventId: p?.authEvent?.loginId ?? '',
});
/* eslint-enable @typescript-eslint/naming-convention */
