import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import styles from '@components/ProductsGrid/ProductsGrid.module.scss';

type SkeletonWrapperProps = {
  count: number;
  skeleton: ReactNode;
};

export const SkeletonWrapper = ({ count, skeleton }: SkeletonWrapperProps) => {
  const items = Array.from(Array(count).keys());

  return (
    <Grid container className={styles.grid}>
      {items.map((item) => (
        <Grid item xs={6} sm={3} lg={2} key={item} className={styles.grid_item}>
          {skeleton}
        </Grid>
      ))}
    </Grid>
  );
};
