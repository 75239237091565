import { useMutation } from '@apollo/client';
import { EDIT_EBT_CARD } from '@graphql/account/mutations/editEbtCard';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';

export const useEditEbtCard = () => {
  return useMutation(EDIT_EBT_CARD, {
    update(
      cache,
      {
        data: {
          editEbt: { data: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
};
