import { gql } from '@apollo/client';
import { LIGHT_CART_MUTATION_FRAGMENT } from '@graphql/fragments/cart/common/lightCartMutationFragment';

export const ADD_TO_CART_LIGHT = gql`
  mutation AddToCartLight($addToCartInput: AddToCartDataInput!) {
    addToCartLight(addToCartInput: $addToCartInput) {
      ...lightCartMutationFragment
    }
  }
  ${LIGHT_CART_MUTATION_FRAGMENT}
`;
