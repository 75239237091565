import { Typography } from '@mui/material';
import { signOut as SignOutIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/index';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import { DATA_QA } from '@constants/dataQA';
import {
  getAccountSettingsMain,
  accountSettingsInfo,
} from '../../../StaticNavigationData/accountSettings';
import { AccountMenuSection } from './AccountMenuSection';
import styles from './AccountMenu.module.scss';

interface DropDownAccountProps {
  username?: string | null;
  since: string;
  onSelect?: () => void;
}

export const AccountMenu = ({ username, since, onSelect }: DropDownAccountProps) => {
  const { t } = useLocalization('auth');
  const { logout } = useAuthContext();
  const { isStandingOrderVisible: showStandingOrders } = useMainLayoutContext();
  const hasSince = !!since && since !== 'null';

  return (
    <div
      data-testid="account-menu"
      aria-label={t('components:account.menuAreaLabel')}
      className={styles.wrapper}
      data-qa={DATA_QA.ACCOUNT_MENU_MODAL}
    >
      <div className={styles.innerWrapper}>
        <Typography variant="h2" className={styles.greetings} id="account-menu-lists">
          {t('auth:welcome.welcome')}
          {username ? `, ${username}.` : '.'}
        </Typography>

        {hasSince && <p className={styles.since}>{t('welcome.memberSince', { year: since })}</p>}
        <ul className={styles.links}>
          <AccountMenuSection
            list={getAccountSettingsMain(showStandingOrders)}
            onSelect={onSelect}
          />
          <AccountMenuSection list={accountSettingsInfo} onSelect={onSelect} />
          <li data-qa={`menu-account-${DATA_QA.SIGN_OUT}`} className={styles.listItem}>
            <button className={styles.item} onClick={logout} role="menuitem">
              <SignOutIcon height={18} width={18} />
              <span> {t('auth:signOut')}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
