import cx from 'classnames';
import { useDirectionScroll } from '@hooks/index';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { NavigationData } from '../NavigationData/NavigationData';
import styles from './StickyContent.module.scss';

export const StickyContent = () => {
  const isScrollingToBottom = useDirectionScroll();
  const { isScrolling } = useHeaderContext();

  const contentClasses = cx({
    [styles.is_scrolling]: isScrolling,
  });

  return (
    <div className={contentClasses}>
      <NavigationData bottomLineHidden={isScrollingToBottom} />
    </div>
  );
};
