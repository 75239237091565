import {
  GaPageView,
  IGaPageView,
  PGaPageView,
} from '@modules/ga/events/custom/custom-page-view/ga-page-view';

/* eslint-disable @typescript-eslint/naming-convention*/
export interface PGaPageViewSearchResult extends PGaPageView {
  search_results?: number;
  browse?: {
    url?: string;
    searchParams?: string | null;
  };
}

export interface IGaPageViewSearchResult extends IGaPageView {
  search_results: number;
  browse: {
    url: string;
    searchParams: string | null;
  };
}

export function GaPageViewSearchResult(p: PGaPageViewSearchResult): IGaPageViewSearchResult {
  const pageLoad = GaPageView(p);
  const search_results = p.search_results ?? 0;
  const searchParams = p?.browse?.searchParams ?? '';
  const url = p?.browse?.url ?? '';

  return {
    ...pageLoad,
    search_results,
    browse: {
      url,
      searchParams,
    },
  };
}
