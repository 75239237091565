import { useMutation } from '@apollo/client';
import { ADD_EBT_CARD } from '@graphql/account/mutations/addEbtCard';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';

export const useAddEbtCard = () => {
  return useMutation(ADD_EBT_CARD, {
    refetchQueries: [PAYMENT_METHODS],
    update(
      cache,
      {
        data: {
          addEbt: { data: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
};
