import { gql } from '@apollo/client';
import { COUPON_FRAGMENT } from '@graphql/fragments/common/couponFragment';
import { PRODUCT_COUNTER_FRAGMENT } from '@graphql/fragments/product/productCounterFragment';
import { DISCOUNT_FRAGMENT } from '@graphql/fragments/cart/common/discount';
import { PRICE_FRAGMENT } from '../../common/priceFragment';

export const LIGHT_CART_MUTATION_FRAGMENT = gql`
  fragment lightCartMutationFragment on LightweightCart {
    cartLinesCount
    nonExpressProductCount
    modifyOrder
    atcEventIds
    changeQuantityEventId
    modifiedOrderLinesCount
    productCounter {
      ...productCounterFragment
    }
    subTotal {
      ...priceFragment
    }
    cartLines {
      id
      expressEligible
      isRecent
      isNewItem
      skuCode
      productId
      categoryId
      selectedSalesUnit
      itemPosition
      displayPrice {
        ...priceFragment
      }
      quantitySelector {
        maxQuantity
        minQuantity
        quantity
        quantityIncrement
      }
      coupon {
        ...couponFragment
      }
      discount {
        ...discountFragment
      }
      itemListName
    }
    gaCartLines {
      ... on LightweightCartLineWithGA {
        id
        productName
        brandName
        variantId
        available
        discountPerUnit {
          ...priceFragment
        }
        price {
          ...priceFragment
        }
      }
    }
    expressDeliveryNotification {
      isNotificationRequired
      expressItemsCount
    }
  }
  ${PRICE_FRAGMENT}
  ${COUPON_FRAGMENT}
  ${PRODUCT_COUNTER_FRAGMENT}
  ${DISCOUNT_FRAGMENT}
`;
