import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  CLIENT_DEVICE_TYPE,
  DEVICE_TO_LARGE_TILE_DECREMENT_SIZE,
  DEVICE_TO_PAGE_SIZE,
  DEVICE_TO_SKELETON_SIZE,
  PAGE_SIZES,
} from '@modules/pageSize/pageSize.constants';
import { clientDeviceTypeVar } from '@modules/pageSize/pageSize.variables';

export function getClientDeviceTypeFromStore(
  apolloClient: ApolloClient<NormalizedCacheObject>,
): CLIENT_DEVICE_TYPE {
  const clientDeviceType = clientDeviceTypeVar();
  if (!clientDeviceType) {
    return getDefaultClientDeviceType();
  }

  return clientDeviceType;
}

export function setClientDeviceType(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  clientDevice: CLIENT_DEVICE_TYPE,
) {
  clientDeviceTypeVar(clientDevice);
}

export function getPageSizeFromQuery(query: { pageSize?: string | string[] }) {
  const pageSize = Number(query?.pageSize);

  return !isNaN(pageSize) && pageSize >= PAGE_SIZES.MOBILE ? pageSize : undefined;
}

export function getDefaultPageSize() {
  return DEVICE_TO_PAGE_SIZE[getDefaultClientDeviceType()];
}

export function getDefaultClientDeviceType() {
  return CLIENT_DEVICE_TYPE.DESKTOP;
}

export function getPageSize(
  query: { pageSize?: string | string[] },
  apolloClient: ApolloClient<NormalizedCacheObject>,
) {
  return (
    getPageSizeFromQuery(query) ||
    DEVICE_TO_PAGE_SIZE[getClientDeviceTypeFromStore(apolloClient)] ||
    getDefaultPageSize()
  );
}

export function getProductsPageSizeByDevice(
  pageSize: number,
  device: CLIENT_DEVICE_TYPE,
  isGridLargeWithTile = false,
) {
  if (!isGridLargeWithTile) {
    return pageSize;
  }

  return pageSize - DEVICE_TO_LARGE_TILE_DECREMENT_SIZE[device];
}

export function getPageSizeByDevice(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  query: { pageSize?: string | string[] },
  isGridLargeWithTile = false,
) {
  const pageSize = getPageSize(query, apolloClient);
  const device = getClientDeviceTypeFromStore(apolloClient);

  return {
    productsPageSize: getProductsPageSizeByDevice(pageSize, device, isGridLargeWithTile),
    skeletonPageSize: DEVICE_TO_SKELETON_SIZE[device],
  };
}
