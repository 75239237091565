import { gql } from '@apollo/client';
import { PAYMENT_METHODS_FRAGMENT } from '@graphql/fragments/account/paymentMethods';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation SetDefaultPaymentMethod($paymentMethodId: ID!, $standingOrderMode: StandingOrderMode) {
    setDefaultPaymentMethod(
      paymentMethodId: $paymentMethodId
      standingOrderMode: $standingOrderMode
    ) {
      data {
        ...paymentMethodsFragment
      }
      validationErrors {
        ...validationErrorFragment
      }
    }
  }
  ${PAYMENT_METHODS_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
`;
