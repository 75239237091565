import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useDeletePaymentMethod } from '@hooks/payments';
import { PaymentsType } from '@commons/payment';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import styles from './RemovePaymentMethodButton.module.scss';

interface EditPaymentMethodButtonProps {
  id: string;
  type?: PaymentsType;
  accountNumber?: string | null;
  isModifying?: boolean;
}

export const RemovePaymentMethodButton = ({
  id,
  type,
  accountNumber,
  isModifying,
}: EditPaymentMethodButtonProps) => {
  const { t } = useLocalization('checkout');
  const [deletePaymentMethod] = useDeletePaymentMethod();
  const { clearCacheFields } = useCacheFieldsInvalidate();

  const handleClick = () => {
    deletePaymentMethod({
      variables: { paymentMethodId: id },
    });
    clearCacheFields(isModifying ? ['checkout', 'initModifyOrderCheckout'] : ['checkout']);
  };

  return (
    <div className={styles.remove_button}>
      <Button
        onClick={handleClick}
        variant="underline"
        aria-label={` ${t('removeButton')} ${t(
          'account:deliveryPass.paymentMethod',
        )} ${type} ${accountNumber}`}
      >
        {t('removeButton')}
      </Button>
    </div>
  );
};
