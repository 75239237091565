import { CartSection, NonExpressSection } from '@commons/cart';
import { OUT_OF_STOCK, UNAVAILABLE } from '@constants/cart';

export const getCartSectionsToBeDeleted = (
  cartSectionsList?: CartSection[],
  nonExpressSection?: NonExpressSection,
  expressEnabled?: boolean,
) => {
  const unavailableCartLinesIds =
    cartSectionsList
      ?.filter(({ name }) => name === UNAVAILABLE || name === OUT_OF_STOCK)
      ?.map((cartSection) => cartSection.cartLines.map((cartLine) => cartLine.id))
      .flat() ?? [];
  const unavailableNonExpressSectionsIds =
    nonExpressSection?.cartLines.map((cartLine) => cartLine.id) ?? [];

  if (expressEnabled) {
    return unavailableCartLinesIds;
  }

  return [...unavailableNonExpressSectionsIds, ...unavailableCartLinesIds];
};
