import { FC } from 'react';
import { ErrorBlock } from '@components/ErrorBlock/ErrorBlock';
import styles from './SubmitError.module.scss';

interface SubmitErrorProps {
  message: string | null;
}

export const SubmitError: FC<SubmitErrorProps> = ({ message, children }) => (
  <div className={styles.submit_block} aria-live="polite">
    {message && (
      <div className={styles.submit_error}>
        <ErrorBlock message={message} />
      </div>
    )}
    {children}
  </div>
);
