import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { customFonts } from '@layouts/FontsWrapper/FontsWrapper';

export const createThemeWithTypography = (options: ThemeOptions | undefined): Theme => {
  const typographyTheme = createTheme({
    ...options,
    typography: {
      fontFamily: `${customFonts.style.fontFamily}, 'sans-serif'`,
    },
  });

  typographyTheme.typography.h1 = {
    lineHeight: 1.1,
    letterSpacing: '-0.01em',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '2.25rem',
    [typographyTheme.breakpoints.up('lg')]: {
      fontSize: '3.125rem',
    },
    [typographyTheme.breakpoints.up('xl')]: {
      fontSize: '4rem',
    },
  };

  typographyTheme.typography.h2 = {
    lineHeight: 1.1,
    letterSpacing: '-0.01em',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2rem',
    [typographyTheme.breakpoints.up('lg')]: {
      fontSize: '2.625rem',
    },
    [typographyTheme.breakpoints.up('xl')]: {
      fontSize: '3.5rem',
    },
  };

  typographyTheme.typography.h3 = {
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
    fontStyle: 'normal',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [typographyTheme.breakpoints.up('lg')]: {
      fontSize: '1.75rem',
    },
    [typographyTheme.breakpoints.up('xl')]: {
      fontSize: '2.625rem',
    },
  };

  typographyTheme.typography.h4 = {
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
    fontStyle: 'normal',
    fontSize: '1.25rem',
    fontWeight: 700,
    [typographyTheme.breakpoints.up('lg')]: {
      fontWeight: 500,
      fontSize: '1.5rem',
    },
  };

  typographyTheme.typography.h5 = {
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
    fontWeight: 'normal',
    fontStyle: 'normal',
    [typographyTheme.breakpoints.up('xs')]: {
      fontSize: '1.125rem',
    },
    [typographyTheme.breakpoints.up('xl')]: {
      fontSize: '1.5rem',
    },
  };

  typographyTheme.typography.body = {
    fontFamily: `${customFonts.style.fontFamily}, 'sans-serif'`,
    letterSpacing: '-0.01em',
    fontSize: '0.9375rem',
    lineHeight: 1.3,

    [typographyTheme.breakpoints.up('xl')]: {
      fontSize: '1.0625rem',
      lineHeight: 1.4,
    },
  };

  typographyTheme.typography.smallBody = {
    fontFamily: `${customFonts.style.fontFamily}, 'sans-serif'`,
    letterSpacing: '-0.01em',

    [typographyTheme.breakpoints.up('xs')]: {
      fontSize: '0.8125rem',
      lineHeight: 1.3,
    },
    [typographyTheme.breakpoints.up('xl')]: {
      fontSize: '0.9375rem',
      lineHeight: 1.4,
    },
  };

  typographyTheme.typography.link = {
    letterSpacing: '-0.01em',
    fontSize: '0.9375rem',
    lineHeight: 1.3,

    [typographyTheme.breakpoints.up('xl')]: {
      fontSize: '1.0625rem',
      lineHeight: 1.4,
    },
  };

  typographyTheme.typography.smallLink = {
    ...typographyTheme.typography.link,
    fontSize: '0.8125rem',
  };

  typographyTheme.typography.label = {
    letterSpacing: '-0.01em',
    fontSize: '0.6875rem',
    fontWeight: 700,
    lineHeight: 1.2,

    [typographyTheme.breakpoints.up('lg')]: {
      lineHeight: 1.3,
    },
  };

  return typographyTheme;
};
