import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const DELETE_RECEIVED_GIFT_CARD = gql`
  mutation DeleteReceivedGiftCard($receivedCardId: String!, $returnAllCards: Boolean) {
    deleteReceivedGiftCard(receivedCardId: $receivedCardId, returnAllCards: $returnAllCards) {
      cards {
        id
        senderName
        purchaseDate
        initialAmount {
          ...priceFragment
        }
        currentAmount {
          ...priceFragment
        }
        redeemable
      }
      totalCardsCount
      totalAmount {
        ...priceFragment
      }
    }
  }
  ${PRICE_FRAGMENT}
`;
