import { Dispatch, SetStateAction } from 'react';
import { PAYMENT_TABS } from '@commons/payment';
import { PaymentsOnSuccess } from '@components/PaymentsAddTabs/PaymentAddTabs.types';
import { PaymentError } from '../PaymentError/PaymentError';
import { AddCardTabContent } from '../AddCardTabContent/AddCardTabContent';
import { PaypalTabContent } from '../PaypalTabContent/PaypalTabContent';
import { VenmoTabContent } from '../VenmoTabContent/VenmoTabContent';
import { EbtTabContent } from '../EbtTabContent/EbtTabContent';
import { AddBankAccountTabContent } from '../AddBankAccountTabContent/AddBankAccountTabContent';

interface PaymentFormFactoryProps {
  type: PAYMENT_TABS;
  errorMessage: string;
  onError: Dispatch<SetStateAction<string>>;
  onSuccess: PaymentsOnSuccess;
}

export const PaymentFormFactory = ({
  type,
  errorMessage,
  onError,
  onSuccess,
}: PaymentFormFactoryProps) => {
  const payments = {
    [PAYMENT_TABS.CREDIT_CARD]: <AddCardTabContent onSuccess={onSuccess} onError={onError} />,
    [PAYMENT_TABS.EBT]: <EbtTabContent onSuccess={onSuccess} onError={onError} />,
    [PAYMENT_TABS.BANK_ACCOUNT]: (
      <AddBankAccountTabContent onSuccess={onSuccess} onError={onError} />
    ),
    [PAYMENT_TABS.PAYPAL]: <PaypalTabContent />,
    [PAYMENT_TABS.VENMO]: <VenmoTabContent />,
  };

  return (
    <>
      {!!errorMessage && <PaymentError errorMessage={errorMessage} />}
      {payments[type]}
    </>
  );
};
