import { Translate } from 'next-translate';

const PHONE_MASK = '(___) ___-____';
const requiredError = 'preferences.forms.errors.required';
const patternError = 'preferences.forms.errors.phone';
const isPatternMatch = (value: string) => value === PHONE_MASK || value.includes('_');

export const validatePhone = (t: Translate) => (value: string) => {
  if (!value) {
    return t(requiredError);
  }
  if (isPatternMatch(value)) {
    return t(patternError);
  }
};

export const validateWorkPhone = (t: Translate) => (value: string) => {
  if (isPatternMatch(value)) {
    return t(patternError);
  }
};
