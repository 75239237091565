import { gql } from '@apollo/client';
import { GIFT_CARDS_CART_FRAGMENT } from '../../fragments/cart/common/giftCardsCart';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '../../fragments/account/actionResult';

export const DELETE_FROM_GIFT_CARDS_CART = gql`
  mutation DeleteFromGiftCardsCart($giftCardId: String!) {
    deleteFromGiftCardsCart(giftCardId: $giftCardId) {
      cart {
        ...giftCardsCartFragment
      }
      actionResult {
        ...actionResultFragment
      }
    }
  }
  ${GIFT_CARDS_CART_FRAGMENT}
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
