import cx from 'classnames';
import styles from './OrderCard.module.scss';

interface OrderCardProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const OrderCard = ({ children, className, ...rest }: OrderCardProps) => {
  return (
    <div {...rest} className={cx(styles.wrapper, className)}>
      {children}
    </div>
  );
};
