import { useRouter } from 'next/router';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import { routing } from '@constants/routing';
import { Grid } from '@components/UI/Grid/Grid';
import { Title } from '../../../Title';
import styles from './EmptyOrderHistory.module.scss';

export const EmptyOrderHistory = () => {
  const { t } = useLocalization('account');
  const router = useRouter();

  const redirectToHome = () => {
    router.push(routing.home);
  };

  return (
    <Grid container columns={{ md: 8, lg: 6, xl: 8 }}>
      <Grid item md={6} className={styles.empty_order_history_container}>
        <div className={styles.empty_order_history}>
          <Title className={styles.empty_order_history_title}>
            {t('orderHistory.emptyHistory')}
          </Title>
          <Button
            className={styles.empty_order_history_button}
            size="large"
            onClick={redirectToHome}
            fullWidth
          >
            {t('orderHistory.emptyHistoryButton')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
