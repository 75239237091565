import { useUserDetails } from '@hooks/account/useUserDetails';
import { useGeolocation } from '@hooks/deliveryAddress/useGeolocation';
import { useDeliveryAddresses } from '@hooks/deliveryAddress/useDeliveryAddresses';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { useMinimumAnalyticsData } from '@modules/ga/hooks/useMinimumAnalyticsData';
import { useServiceType } from '@hooks/useServiceType';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';

export const useAnalyticsData = () => {
  const { getFeature } = useHeaderContext();
  const { data: analyticsData, loading: analyticsDataLoading } = useMinimumAnalyticsData();
  const { data: userDetails, loading: loadingUserDetails } = useUserDetails();
  const { data: geoData, loading: loadingGeoData } = useGeolocation();
  const { loading: loadingAddressData, selectedAddress } = useDeliveryAddresses();
  const { loading: loadingMinimumAccountPreferences, minimumAccountPreferences } =
    useMinimumAccountPreferences();
  const { serviceType } = useServiceType();
  const localServiceTypeState = !!getFeature?.(FEATURE.COS) ? serviceType : '';

  const loading =
    loadingUserDetails ||
    loadingGeoData ||
    loadingAddressData ||
    analyticsDataLoading ||
    loadingMinimumAccountPreferences;

  return {
    loading,
    geoData,
    ...(selectedAddress?.address?.id && { addressData: selectedAddress }),
    //TODO refactor all eventHandlers in ga emitter that uses userDetails (use it from AnalyticsData instead of getting it in useEvent hook)
    userDetails,
    pageViewEventId: analyticsData?.pageViewEventId ?? '',
    minimumAccountPreferences: {
      ...minimumAccountPreferences,
      serviceType: minimumAccountPreferences?.serviceType || localServiceTypeState,
    },
  };
};
