import { useMutation } from '@apollo/client';
import { SET_DEFAULT_PAYMENT_METHOD } from '@graphql/account/mutations/setDefaultPaymentMethod';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';

export const useSetDefaultPaymentMethod = () => {
  return useMutation(SET_DEFAULT_PAYMENT_METHOD, {
    update(
      cache,
      {
        data: {
          setDefaultPaymentMethod: { data: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
};
