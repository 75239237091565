import React, { Ref, RefObject } from 'react';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps as muiInputProps,
  InputAdornment,
} from '@mui/material';
import cx from 'classnames';
import { Button } from '@components/UI';
import styles from './InputButton.module.scss';

export interface InputButtonProps extends muiInputProps {
  buttonName: string;
  buttonLabel?: string;
  submittedButton?: string;
  submittedButtonLabel?: string;
  errorText?: string;
  helperText?: string;
  submitted?: boolean;
  className?: string;
  onClick?: (e: React.FormEvent) => void;
  onKeyDown?: (e: React.FormEvent) => void;
  onSubmitted?: () => void;
  loading?: boolean;
  helperTextId?: string;
  inputFieldRef?: RefObject<HTMLInputElement>;
  labelFieldRef?: RefObject<HTMLLabelElement>;
}

export const InputButton = (
  {
    label,
    buttonName,
    buttonLabel,
    submittedButton,
    submittedButtonLabel,
    errorText,
    className,
    error = false,
    helperText,
    submitted = false,
    disabled = false,
    onClick,
    onKeyDown,
    onSubmitted,
    loading,
    id,
    helperTextId,
    inputFieldRef,
    labelFieldRef,
    ...props
  }: InputButtonProps,
  ref: Ref<HTMLLabelElement>,
) => {
  const classes = cx([
    styles.button,
    className,
    {
      [styles.submitted]: submitted,
      [styles.fullWidth]: props.fullWidth,
    },
  ]);
  const inputButtonWrapper = cx([styles.inputButtonWrapper], {
    [styles.error]: error && errorText,
  });
  return (
    <FormControl data-testid="promo-field" className={classes}>
      <div className={inputButtonWrapper}>
        {label && (
          <InputLabel
            className={cx(styles.label, {
              [styles.error]: error,
            })}
            htmlFor={id}
            ref={labelFieldRef}
          >
            {label}
          </InputLabel>
        )}
        <OutlinedInput
          {...props}
          error={error}
          disabled={disabled}
          label={label}
          id={id}
          aria-describedby={helperTextId}
          endAdornment={
            <InputAdornment position="end">
              {submitted && submittedButton ? (
                <Button
                  variant="underline"
                  className={styles.submitted_button}
                  onClick={onSubmitted}
                  onKeyDown={onKeyDown}
                  loading={loading}
                  aria-label={submittedButtonLabel}
                >
                  {submittedButton}
                </Button>
              ) : (
                <Button
                  onKeyDown={onKeyDown}
                  onClick={onClick}
                  disabled={disabled}
                  loading={loading}
                  aria-label={buttonLabel}
                >
                  {buttonName}
                </Button>
              )}
            </InputAdornment>
          }
          inputRef={inputFieldRef}
        />
      </div>
      <div aria-live="polite">
        <FormHelperText error={error} id={helperTextId}>
          {errorText ?? helperText}
        </FormHelperText>
      </div>
    </FormControl>
  );
};

InputButton.displayName = 'InputButton';
