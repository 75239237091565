import {
  GiftCardTemplate as GiftCardTemplateApi,
  GiftCardsCart as GiftCardsCartApi,
  GiftCards as GiftCardsApi,
  GiftCardDeliveryType,
} from '@api';
import { CartLineInfo, Subtotal, TotalSection } from '@commons/cart';
import { ProductBasicPrice } from '@commons/product';
import { GiftCards } from '@commons/giftCards';
import { priceAdapter } from './priceAdapter';
import { cartAdapter } from './cartAdapter';

const { getCartSubtotal, getCartTotalSection } = cartAdapter();

export interface GiftCardTemplate {
  gcTemplateId: string;
  gcTemplateImageLink: string;
  gcTemplateLabel: string;
  gcTemplateTitle: string;
}

interface GiftCardAdapter {
  getGiftCardTemplates: (giftCardTemplates?: (GiftCardTemplateApi | null)[]) => GiftCardTemplate[];
  getAmountOptions: (amountOptions?: (string | null)[]) => string[];
}

export interface GiftCard extends BaseGiftCard {
  senderEmail: string;
  quantity: number;
  displayPrice: ProductBasicPrice;
  isBulk: boolean;
}
export interface BaseGiftCard {
  gcTemplate: GiftCardTemplate;
  amount: ProductBasicPrice;
  deliveryMethod: GiftCardDeliveryType;
  id: string;
  message: string;
  recipientEmail: string;
  recipientName: string;
  senderName: string;
}

export interface GiftCardsCart {
  cardsCount: number;
  cards: GiftCard[];
  estimatedTotal: Subtotal;
  totalSection: TotalSection;
}
export interface ConfirmationGiftCards {
  cardsCount: number;
  cards: BaseGiftCard[];
}

interface GiftCardAdapter {
  getGiftCardTemplates: (giftCardTemplates?: (GiftCardTemplateApi | null)[]) => GiftCardTemplate[];
  getGiftCardsCart: (giftCardCart?: Partial<GiftCardsCartApi> | null) => GiftCardsCart;
  getConfirmationGiftCards: (cartLines: CartLineInfo[]) => ConfirmationGiftCards;
  getGiftCards: (giftCards?: Partial<GiftCardsApi> | null) => GiftCards;
}

const getGiftCardTemplate = (template?: GiftCardTemplateApi | null): GiftCardTemplate => ({
  gcTemplateId: template?.gcTemplateId ?? '',
  gcTemplateImageLink: template?.gcTemplateImageLink ?? '',
  gcTemplateLabel: template?.gcTemplateLabel ?? '',
  gcTemplateTitle: template?.gcTemplateTitle ?? '',
});

const getGiftCardTemplates: GiftCardAdapter['getGiftCardTemplates'] = (giftCardTemplates) => {
  return giftCardTemplates?.map((template) => getGiftCardTemplate(template)) ?? [];
};

const getGiftCardsCart = (giftCardsCart?: Partial<GiftCardsCartApi> | null): GiftCardsCart => {
  const { getBasicPrice } = priceAdapter();
  return {
    cardsCount: giftCardsCart?.cardsCount ?? 0,
    cards:
      giftCardsCart?.cards?.map((card) => ({
        gcTemplate: getGiftCardTemplate(card.gcTemplate),
        amount: getBasicPrice(card.amount),
        deliveryMethod: card.deliveryMethod ?? GiftCardDeliveryType.E,
        id: card.id ?? '',
        message: card.message ?? '',
        recipientEmail: card.recipientEmail ?? '',
        recipientName: card.recipientName ?? '',
        senderName: card.senderName ?? '',
        senderEmail: card.senderEmail ?? '',
        quantity: card.quantity ?? '',
        isBulk: !!card.isBulk,
        displayPrice: getBasicPrice(card.displayPrice),
      })) ?? [],
    estimatedTotal: getCartSubtotal(giftCardsCart?.estimatedTotal),
    totalSection: getCartTotalSection(giftCardsCart?.totalSection),
  };
};

const getConfirmationGiftCards = (cardLines: CartLineInfo[]): ConfirmationGiftCards => {
  const { getBasicPrice } = priceAdapter();

  return {
    cardsCount: cardLines.length,
    cards: cardLines.map(({ giftCard, id }) => ({
      gcTemplate: {
        gcTemplateId: giftCard?.templateId ?? '',
        gcTemplateImageLink: giftCard?.templateImageLink ?? '',
        gcTemplateLabel: 'gift card template image',
        gcTemplateTitle: '',
      },
      amount: getBasicPrice(giftCard?.amount),
      deliveryMethod: giftCard?.deliveryMode ?? GiftCardDeliveryType.E,
      id: id ?? '',
      message: giftCard?.personalMessage ?? '',
      recipientEmail: giftCard?.recipientEmail ?? '',
      recipientName: giftCard?.recipientName ?? '',
      senderName: giftCard?.senderName ?? '',
    })),
  };
};

const getGiftCards = (giftCards?: Partial<GiftCardsApi> | null): GiftCards => {
  const { getPrice } = priceAdapter();
  return {
    purchasedCards:
      giftCards?.purchasedCards?.map((purchasedCard) => ({
        recipientEmail: purchasedCard.recipientEmail,
        recipientName: purchasedCard?.recipientName ?? '',
        personalMessage: purchasedCard.personalMessage ?? '',
        certificateNumber: purchasedCard?.certificateNumber ?? '',
        formattedDate: purchasedCard?.formattedDate ?? '',
        status: purchasedCard?.status ?? '',
        saleId: purchasedCard?.saleId ?? '',
        formattedAmount: purchasedCard?.formattedAmount ?? '',
        template: getGiftCardTemplate(purchasedCard.template),
      })) ?? [],
    receivedCards: {
      cards:
        giftCards?.receivedCards?.cards?.map((card) => ({
          id: card?.id ?? '',
          senderName: card?.senderName ?? '',
          purchaseDate: card?.purchaseDate ?? '',
          initialAmount: getPrice(card?.initialAmount),
          currentAmount: getPrice(card?.currentAmount),
          redeemable: !!card?.redeemable,
          applied: !!card?.applied,
        })) ?? [],
      totalCardsCount: giftCards?.receivedCards?.totalCardsCount ?? 0,
      totalAmount: getPrice(giftCards?.receivedCards?.totalAmount),
    },
  };
};

const getAmountOptions: GiftCardAdapter['getAmountOptions'] = (amountOptions, limit = 4) => {
  // we only need to get 4 first elements.
  return (
    amountOptions
      ?.filter<string>((option): option is string => typeof option === 'string')
      .slice(0, limit) ?? []
  );
};

export const giftCardAdapter = (): GiftCardAdapter => ({
  getGiftCardTemplates,
  getAmountOptions,
  getGiftCardsCart,
  getConfirmationGiftCards,
  getGiftCards,
});
