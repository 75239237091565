import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { fireAddShippingInfoGAEvent } from '@modules/ga/events/ecommerce/add-shipping-info/addShippingInfoCreator';

export const useAddShippingInfoEvent = () => {
  const { cartInfo } = useCartForCheckoutContext();
  const {
    data: { cartLinesList },
  } = cartInfo;

  const trackAddShippingInfo = (): void => {
    fireAddShippingInfoGAEvent({ cartLinesList });
  };

  return {
    trackAddShippingInfo,
  };
};
