import { useEffect } from 'react';
import { onEvent } from '@modules/event';

export const useInterceptIframeHtmlLink = (
  iframe: HTMLIFrameElement | null,
  handler: (evt: Event, link?: string | null) => void,
) => {
  const handleClick = (evt: Event) => {
    const clickTarget = evt?.target as HTMLElement;
    const clickElement = clickTarget.closest('a');
    const link = clickElement?.getAttribute('href');

    handler(evt, link);
  };

  useEffect(() => {
    const target = iframe?.contentWindow;
    let fireCallback: () => void | undefined;
    if (target) {
      fireCallback = onEvent(target.document?.body as EventTarget, 'click', handleClick);
    }
    return () => {
      fireCallback?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
