import { Typography } from '@mui/material';
import { warning as WarningIcon } from '@assets/icons/system';
import styles from './ErrorBlock.module.scss';

interface ErrorBlockProps {
  message: string;
}

export const ErrorBlock = ({ message }: ErrorBlockProps) => {
  return (
    <div>
      <Typography variant="body" className={styles.message}>
        <WarningIcon width={12} height={12} className={styles.icon} />
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </Typography>
    </div>
  );
};
