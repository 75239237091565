import { FieldProps } from 'formik';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Checkbox } from '@components/UI';
import styles from './CustomCheckbox.module.scss';

type CustomCheckboxProps = FieldProps & {
  label: string;
  id: string;
  additionalText: ReactNode;
  ariaLabel: string;
  className: string;
};

export const CustomCheckbox = ({
  field,
  label,
  id,
  additionalText,
  className,
  ...props
}: CustomCheckboxProps) => {
  return (
    <div className={className}>
      <Checkbox
        {...props}
        {...field}
        className={styles.checkbox}
        id={id}
        onChange={(value, event) => {
          field.onChange(event);
        }}
        isChecked={field.value}
      >
        <Typography variant="smallBody" component="p" className={styles.checkbox_text}>
          {label}
        </Typography>
      </Checkbox>
      <>{additionalText}</>
    </div>
  );
};
