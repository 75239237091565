export interface ITask<T> {
  (): Promise<T>;
}

interface IWrappedTask {
  (): Promise<void>;
}

export interface IAddTask {
  <T>(task: ITask<T>): Promise<T>;
}

const queue: Array<IWrappedTask> = [];
let inProgress = false;

export const addTask: IAddTask = async (task) => {
  return new Promise((resolve, reject) => {
    queue.push(async () => {
      try {
        const result = await task();
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
    runTasks();
  });
};

const delay = (timeout = 0): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, timeout));

const runTasks = (): void => {
  if (inProgress) {
    return;
  }

  if (!queue.length) {
    inProgress = false;
    return;
  }

  inProgress = true;

  const task = queue.shift();

  delay(1).then(async () => {
    task && (await task());

    inProgress = false;
    runTasks();
  });
};
