import {
  getViewItemListEvent,
  ViewItemListOptions,
} from '@modules/ga/events/ecommerce/view-item-list/utils/getViewItemListEvent';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';

interface ViewItemListHandlerProps extends Omit<ViewItemListOptions, 'customer'> {
  commonAnalyticsData: AnalyticsData;
}

export const viewItemListHandler = (data: ViewItemListHandlerProps) => {
  const { userDetails } = data.commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;
  sendGtmEvent(
    getViewItemListEvent({
      ...data,
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
    }),
  );
};
