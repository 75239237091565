import React, { useState } from 'react';
import cx from 'classnames';
import { InputButton } from '@components/UI/Inputs/InputButton/InputButton';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ALERT_TYPES } from '@commons/account';
import { validateRequired } from '@utils/validators';
import { useValidator } from '@hooks/validation/useValidation';
import { useLocalization } from '@hooks/useLocalization';
import { useRedeemGiftCard } from '@hooks/giftCard/useRedeemGiftCard';
import { GET_GIFT_CARDS } from '@graphql/account/queries/getGiftCards';
import styles from './RedeemGiftCard.module.scss';

interface RedeemGiftCardProps {
  className?: string;
}

export const RedeemGiftCard = ({ className }: RedeemGiftCardProps) => {
  const { t } = useLocalization('account');
  const [redeemGiftCard] = useRedeemGiftCard();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const { dispatchAlert } = useAccountAlertContext();
  const validate = useValidator(validateRequired);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setInputValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(validate(inputValue) || '');
    if (inputValue) {
      redeemGiftCard({
        variables: { redemptionCode: inputValue.trim() },
        onCompleted: (data) => {
          if (!data?.redeemGiftCard?.success) {
            const message = data?.redeemGiftCard?.actionResult[0]?.error;
            dispatchAlert(ALERT_TYPES.ERROR, message);
            setInputValue('');
          }
          if (data?.redeemGiftCard?.success) {
            setInputValue('');
          }
        },
        refetchQueries: [GET_GIFT_CARDS],
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className={cx(styles.wrapper, className)}>
      <InputButton
        type="number"
        value={inputValue}
        errorText={error}
        error={!!error}
        buttonName={t('giftCards.redeemGiftCard.button')}
        label={t('giftCards.redeemGiftCard.label')}
        onChange={handleChange}
        onClick={handleSubmit}
        fullWidth
        id="redeem-giftcard-input"
        helperTextId="helper-text-redeem-gc"
      />
    </form>
  );
};
