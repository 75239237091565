import {
  ComplaintReason,
  IComplaintReason,
  PComplaintReason,
} from '@modules/masquerade/types/complaint-reason';

export interface PCsrData {
  isDeliveryForceWaived?: boolean | null;
  isPremiumDeliveryForceWaived?: boolean | null;
  isPhoneHandlingChargeWaived?: boolean | null;
  isEmailInvoiceSendingSkipped?: boolean | null;
  customerInvoiceMessage?: string | null;
  phoneCharge?: GLfloat | null;
  deliveryCharge?: GLfloat | null;
  deliveryChargePremium?: GLfloat | null;
  complaintReasons?: DeepPartial<PComplaintReason[]> | null;
}

export interface ICsrData {
  isDeliveryForceWaived: boolean;
  isPremiumDeliveryForceWaived: boolean;
  isPhoneHandlingChargeWaived: boolean;
  isEmailInvoiceSendingSkipped: boolean;
  customerInvoiceMessage: string;
  phoneCharge: GLfloat;
  deliveryCharge: GLfloat;
  deliveryChargePremium: GLfloat;
  complaintReasons: IComplaintReason[];
}

export function CsrData(p: PCsrData): ICsrData {
  return {
    isDeliveryForceWaived: p.isDeliveryForceWaived ?? false,
    isPremiumDeliveryForceWaived: p.isPremiumDeliveryForceWaived ?? false,
    isPhoneHandlingChargeWaived: p.isPhoneHandlingChargeWaived ?? false,
    isEmailInvoiceSendingSkipped: p.isEmailInvoiceSendingSkipped ?? false,
    customerInvoiceMessage: p.customerInvoiceMessage ?? '',
    phoneCharge: p.phoneCharge ?? 0,
    deliveryCharge: p.deliveryCharge ?? 0,
    deliveryChargePremium: p.deliveryChargePremium ?? 0,
    complaintReasons: p.complaintReasons ? p.complaintReasons.map(ComplaintReason) : [],
  };
}
