export const KNOCKING_REQUIRED = [
  'LoginInit',
  'Login',
  'Signup',
  'ForgotPassword',
  'InitializeHostedPayment',
  'InitializeEWallet',
  'EditPayment',
  'EditBankAccount',
  'editEbt',
  'addEbt',
];

export const KNOCKING_ACTIONS: Record<string, string> = {
  logininit: 'login',
  login: 'login',
  signup: 'signup',
  forgotpassword: 'password_reset',
  initializehostedpayment: 'payment_add',
  initializeewallet: 'payment_add',
  editpayment: 'payment_edit',
  editbankaccount: 'payment_edit',
  editebt: 'payment_edit',
  addebt: 'payment_add',
};

export const KNOCKING_HEADER = 'X-Knock-Token';
