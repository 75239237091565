import { useRef, useState, FocusEvent } from 'react';
import { AutosuggestProduct, ProductTile as IProductTile } from '@commons/product';
import { ProductTile } from '@components/Tiles/ProductTile/ProductTile';
import { useAlternativeProduct } from '@hooks/useAlternativeProduct';
import { RecommendedTilePopup } from '@components/RecommendedTilePopup/RecommendedTilePopup';
import { CriteoFormatBeacon } from '@modules/criteo';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import styles from './ProductTileWithRecommended.module.scss';

interface ProductTileWithRecommendedProps {
  children?: never;
  className?: string;
  product: IProductTile;
  isShowImage?: boolean;
  isReorder?: boolean;
  hideGroupScale?: boolean;
  onClick?: (productId?: string) => void;
  onClickCriteo?(): void;
  nonFocusable?: boolean;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  onChange?: (product?: IProductTile | AutosuggestProduct) => void;
  skipAddToCart?: boolean;
  isV2?: boolean;
}

export const ProductTileWithRecommended = ({
  className,
  product,
  isShowImage = true,
  hideGroupScale = false,
  isReorder,
  onClick,
  onClickCriteo,
  nonFocusable,
  criteoFormatBeaconView,
  onChange,
  skipAddToCart,
  isV2 = false,
}: ProductTileWithRecommendedProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { getAlternativeProduct, loading, fetch } = useAlternativeProduct(
    product.alternativeProductId,
  );
  const { isMobile } = useHeaderContext();
  const shouldShowRecommendedProductPopup =
    anchorEl && product?.alternativeProductId && !loading && !isMobile;

  const handleMouseEnter = () => {
    fetch();
    openPopup();
  };

  const handleMouseLeave = () => {
    closePopup();
  };

  const openPopup = () => {
    setAnchorEl(ref.current);
  };

  const closePopup = () => {
    setAnchorEl(null);
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      closePopup();
    }
  };

  const handleFocus = (event: FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      handleMouseEnter();
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref}
      onBlur={handleBlur}
      onFocus={handleFocus}
      className={styles.wrapper}
    >
      <ProductTile
        product={product}
        className={className}
        hideGroupScale={hideGroupScale}
        isShowImage={isShowImage}
        isReorder={isReorder}
        onClick={onClick}
        onClickCriteo={onClickCriteo}
        nonFocusable={nonFocusable}
        criteoFormatBeaconView={criteoFormatBeaconView}
        onChange={onChange}
        skipAddToCart={skipAddToCart}
        isV2={isV2}
      />
      {shouldShowRecommendedProductPopup && (
        <RecommendedTilePopup
          recommendedProduct={getAlternativeProduct()}
          anchorEl={anchorEl}
          criteoFormatBeaconView={criteoFormatBeaconView}
          isV2={isV2}
        />
      )}
    </div>
  );
};
