import { MinimumCartLine } from '@commons/cart';
import { useMinimumCartLinesList } from './useMinimumCartLinesList';

export const getCountForSoldBySalesUnit = (productId: string, cartLines: MinimumCartLine[]) =>
  cartLines.reduce((sum, cartLine) => (productId === cartLine.productId ? sum + 1 : sum), 0);

export const getCountForConfigurableProduct = (productId: string, cartLines: MinimumCartLine[]) =>
  cartLines.reduce(
    (sum, cartLine) =>
      productId === cartLine.productId ? sum + cartLine.quantitySelector.quantity : sum,
    0,
  );

export const useCartLineItem = (productId: string, soldBySalesUnit = false) => {
  const { cartLines, ...rest } = useMinimumCartLinesList();
  const cartLineItem = cartLines.find((cartLine) => cartLine.productId === productId);
  const count = soldBySalesUnit
    ? getCountForSoldBySalesUnit(productId, cartLines)
    : getCountForConfigurableProduct(productId, cartLines);

  return {
    ...rest,
    data: cartLineItem,
    count: count,
  };
};
