import { Address as AddressAPI, DeliveryAddressValidationResult } from '@api';
import {
  Address,
  AddressServerValidationResult,
  MinimumAddress,
  SERVICE_TYPE,
} from '@commons/deliveryAddresses';
import {
  ADDRESS_IS_NOT_SET_UP_ERROR,
  ADDRESS_MUST_BE_IN_DELIVERY_ZONE_ERROR,
  APT_SUIT_REQUIRED,
  ATP_ABSENCE_WARNING,
  ATP_ERROR,
  COMMERCIAL_ADDRESS_ERROR,
  DONT_RECOGNIZED_ADDRESS_ERROR,
  DONT_RECOGNIZED_ERROR,
  ENTER_ONE_OF_THE_FOLLOWING_ADDRESSES_ERROR,
  OUT_OF_DELIVERY_ZONE_ERROR,
  RESIDENTIAL_ADDRESS_ERROR,
  UNABLE_TO_PROCESS_ERROR,
  UNABLE_TO_RECOGNIZE,
} from '@utils/mapAddressServerErrorsToTranslationKey';

type AddressApi = DeepPartial<AddressAPI>;

interface AddressAdapter {
  getAddress: (address?: AddressApi | null) => Address;
  getMinimumAddress: (address?: AddressApi | null) => MinimumAddress;
  getValidationResponse: (
    response?: DeliveryAddressValidationResult,
  ) => AddressServerValidationResult;
}

const getMinimumAddress = (address?: AddressApi | null): MinimumAddress => ({
  id: address?.id ?? '',
  address1: address?.address1 ?? '',
  city: address?.city ?? '',
  state: address?.state ?? '',
  zipCode: address?.zipCode ?? '',
  serviceType: address?.serviceType ?? '',
  apartment: address?.apartment ?? null,
});

const getAddress = (address?: AddressApi | null): Address =>
  <Address>{
    ...getMinimumAddress(address),
    apartment: address?.apartment ?? null,
    isCustomerAnonymousAddress: !!address?.isCustomerAnonymousAddress,
    selected: !!address?.selected,
    serviceType: address?.serviceType ? (address.serviceType as unknown as SERVICE_TYPE) : null,
    zipCode: address?.zipCode ?? '',
    popupUrl: address?.popupUrl ?? '',
    country: address?.country ?? null,
  };

const hasErrorInResponse = (
  errorMessage: string,
  response?: DeliveryAddressValidationResult,
): boolean =>
  !!response?.actionResult?.errors?.some((error) => error?.description === errorMessage);

const getValidationResponse = (
  response?: DeliveryAddressValidationResult,
): AddressServerValidationResult => {
  return {
    isSuccess: !!response?.actionResult?.success,
    isCommercialAddress: hasErrorInResponse(COMMERCIAL_ADDRESS_ERROR, response),
    isResidentialAddress: hasErrorInResponse(RESIDENTIAL_ADDRESS_ERROR, response),
    isApartmentRequired:
      hasErrorInResponse(ATP_ABSENCE_WARNING, response) ||
      hasErrorInResponse(APT_SUIT_REQUIRED, response) ||
      hasErrorInResponse(UNABLE_TO_RECOGNIZE, response),
    isOutOfDeliveryZone:
      hasErrorInResponse(ADDRESS_MUST_BE_IN_DELIVERY_ZONE_ERROR, response) ||
      hasErrorInResponse(ADDRESS_IS_NOT_SET_UP_ERROR, response) ||
      hasErrorInResponse(OUT_OF_DELIVERY_ZONE_ERROR, response),
    isUnrecognizableAddress:
      hasErrorInResponse(DONT_RECOGNIZED_ERROR, response) ||
      hasErrorInResponse(ENTER_ONE_OF_THE_FOLLOWING_ADDRESSES_ERROR, response) ||
      hasErrorInResponse(UNABLE_TO_PROCESS_ERROR, response) ||
      hasErrorInResponse(DONT_RECOGNIZED_ADDRESS_ERROR, response) ||
      hasErrorInResponse(ATP_ERROR, response),
  };
};

export const addressAdapter = (): AddressAdapter => {
  return { getAddress, getMinimumAddress, getValidationResponse };
};
