import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from '@graphql/account/queries/getCountries';

export const useCountries = () => {
  const { data, ...rest } = useQuery(GET_COUNTRIES);

  return {
    ...rest,
    data: data?.countryCodes || [],
  };
};
