import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const EMPTY_CART = gql`
  mutation EmptyCart {
    emptyCart {
      ...cartFragment
    }
  }
  ${CART_FRAGMENT}
`;
