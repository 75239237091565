import { ReactNode, useEffect, useMemo, useReducer } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { StatusLevelType } from '@api';
import { useAcceptTC } from '@modules/auth/hooks/useAcceptTC';
import { useSocialLogin } from '@modules/auth/hooks/useSocialLogin';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useRefererInfo } from '@hooks/referAFriend/useRefererInfo';
import { on } from '@modules/emitter';
import { useProtectedCallback } from '@modules/auth/hooks/useProtectedCallback';
import { STORAGE_KEYS, sessionStorageService } from '@utils/storageService';
import { EVENT_ACTION } from '../constants';
import { useLogin } from '../hooks/useLogin';
import { useOtp } from '../hooks/useOtp';
import { useLogout } from '../hooks/useLogout';
import { useSignup } from '../hooks/useSignup';
import { useSetInitialAuthInfo } from '../hooks/useSetInitialAuthInfo';
import { AuthContext } from './AuthContext';
import { AUTH_USER_ACTIONS_TYPES, authUserReducer, initialAuthUserState } from './AuthUserReducer';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { isAuthModalOpen } = useAuthModalContext();
  const [state, dispatch] = useReducer(authUserReducer, initialAuthUserState);
  const setProtectedCallback = useProtectedCallback(state.userStatus, state.userAuthStatus);
  const { initAuthInfo, updateUserInfo } = useSetInitialAuthInfo({ dispatch });
  const { acceptTC } = useAcceptTC();
  const { logout } = useLogout({ dispatch });
  const signUpMutations = useSignup({ dispatch });
  const socialLoginMutations = useSocialLogin({ dispatch });
  const loginMutations = useLogin({ dispatch });
  const { removeRefererInfo } = useRefererInfo();
  const otpMutations = useOtp({
    dispatch,
    loginCompletedHandler: loginMutations.loginCompletedHandler,
  });

  const isUserInfoLoading =
    loginMutations.isLoading ||
    socialLoginMutations.isLoading ||
    signUpMutations.isLoading ||
    otpMutations.isLoading ||
    !state.userStatus;

  const isKnownUser =
    !!state.userStatus &&
    [StatusLevelType.SIGNED_IN, StatusLevelType.RECOGNIZED].includes(state.userStatus);

  const userTypes = useMemo(() => {
    return {
      isKnownUser,
      isRecognizedUser: state.userStatus === StatusLevelType.RECOGNIZED,
      isSignedIn: state.userStatus === StatusLevelType.SIGNED_IN,
      isGuestUser: !isKnownUser || state.userStatus === StatusLevelType.RECOGNIZED,
    };
  }, [isKnownUser, state.userStatus]);

  const userDetails = useMemo(() => {
    return {
      userInfo: state.userInfo,
      userStatus: state.userStatus,
      loginMode: state.loginMode,
      rememberMe: state.rememberMe,
      recognizedLastOperation: state.recognizedLastOperation,
      requestError: state.requestError,
      userAuthStatus: state.userAuthStatus,
    };
  }, [state]);

  useEffect(() => {
    initAuthInfo();
  }, []);

  useEffect(() => {
    return on((event) => {
      if (event.type === EVENT_ACTION.FAILED_WITH_AUTH_ERROR && userTypes.isSignedIn) {
        dispatch({
          type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS,
          payload: StatusLevelType.RECOGNIZED,
        });
        dispatch({
          type: AUTH_USER_ACTIONS_TYPES.SET_RECOGNIZED_LAST_OPERATION,
          payload: event.data.lastOperation,
        });
        dispatch({ type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS, payload: undefined });
      }
      if (userTypes.isKnownUser) {
        removeRefererInfo();
      }
    });
  }, [state.userStatus]);

  useUpdateEffect(() => {
    if (!isAuthModalOpen) {
      dispatch({ type: AUTH_USER_ACTIONS_TYPES.SET_REMEMBER_ME, payload: false });
      sessionStorageService?.remove(STORAGE_KEYS.REMEMBER_ME);
    }
  }, [isAuthModalOpen]);

  const clearRequestError = () => {
    dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR, payload: null });
  };

  const handleSetRememberMe = (isRememberMe: boolean, isSocialSignInModal?: boolean) => {
    dispatch({ type: AUTH_USER_ACTIONS_TYPES.SET_REMEMBER_ME, payload: isRememberMe });
    if (isSocialSignInModal && isRememberMe) {
      sessionStorageService?.put(STORAGE_KEYS.REMEMBER_ME, true);
    } else {
      sessionStorageService?.remove(STORAGE_KEYS.REMEMBER_ME);
    }
  };

  const resetLoginMode = () => {
    dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_LOGIN_MODE, payload: undefined });
  };

  const authUserContextValue = useMemo(
    () => ({
      ...userTypes,
      ...userDetails,
      ...signUpMutations,
      ...loginMutations,
      ...socialLoginMutations,
      ...otpMutations,
      logout,
      acceptTC,
      clearRequestError,
      resetLoginMode,
      handleSetRememberMe,
      isUserInfoLoading,
      setProtectedCallback,
      updateAuthUser: updateUserInfo,
      dispatch,
      state,
    }),
    [
      userTypes,
      userDetails,
      signUpMutations,
      loginMutations,
      socialLoginMutations,
      otpMutations,
      logout,
      acceptTC,
      clearRequestError,
      resetLoginMode,
      handleSetRememberMe,
      isUserInfoLoading,
      setProtectedCallback,
      updateUserInfo,
      dispatch,
      state,
    ],
  );

  return <AuthContext.Provider value={authUserContextValue}>{children}</AuthContext.Provider>;
};
