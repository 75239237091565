import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Pair, Banner } from '@api';
import { GET_BANNERS, GET_BANNER_BY_ID } from '@graphql/common/queries/getBanners';
import { BANNERCACHE, updateBanners, checkBannerCache } from '@utils/bannerCache';

export { getBannerSequenceNumber } from '@utils/bannerCache';

interface UseBannersProps {
  bannerIds: Array<string>;
  targeting?: Array<Pair>;
}

interface UseBannerPreviewProps {
  id: string;
}

export function useBanner({ bannerIds, targeting }: UseBannersProps) {
  const router = useRouter();
  const targetingQuery = new URLSearchParams();
  targeting?.forEach(({ key, value }) => {
    targetingQuery.append(key, value);
  });
  const targetingString = targetingQuery.toString();
  const { data, refetch } = useQuery(GET_BANNERS, {
    variables: { bannerIds, targeting: targetingString },
  });

  if (checkBannerCache(router.asPath)) {
    refetch();
  }

  if (data?.bannerSlots?.banners) {
    updateBanners(data.bannerSlots.banners);
  }

  return {
    banners: BANNERCACHE.banners,
  };
}

export function useBannerPreview({ id }: UseBannerPreviewProps): Banner {
  const { data } = useQuery(GET_BANNER_BY_ID, {
    variables: { id: id },
  });

  return data?.previewBanner;
}
