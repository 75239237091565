import { core as SmartyCore, usAutocompletePro } from 'smartystreets-javascript-sdk';
import { useState } from 'react';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';

const Lookup = usAutocompletePro.Lookup;
const credentials = new SmartyCore.SharedCredentials(process.env.NEXT_PUBLIC_SMARTY_KEY as string);
const clientBuilder = new SmartyCore.ClientBuilder(credentials);
const client = clientBuilder.buildUsAutocompleteProClient();

export interface Suggestion {
  serviceType?: SERVICE_TYPE;
  city: string;
  entries: number;
  secondary: string;
  state: string;
  streetLine: string;
  zipcode: string;
  label: string;
  id: string | null;
}

export const transformAddressToString = (item: Omit<Suggestion, 'label' | 'id'>) =>
  `${item.streetLine} ${item.city} ${item.state}, ${item.zipcode}`;

export const useLazyAddressSuggestions = () => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const fetch = async (lookup: string) => {
    try {
      setIsLoading(true);
      const response = await client.send(new Lookup(lookup));
      setSuggestions(
        response?.result
          .filter((item) => !item.entries)
          .map((item) => ({
            ...item,
            label: transformAddressToString(item),
            id: null,
          })) || [],
      );
    } catch (e) {
      if (typeof e === 'string') setError(e);
      const message = (e as Error).message;
      if (message) setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetch, data: suggestions, error, isLoading };
};
