import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { Nutrition as NutritionAPI, NutritionItem } from '@commons/product';
import { getNutritionMetaItems } from '@features/ProductPage/utils/getNutritionMetaItems';
import { Grid } from '@components/UI/Grid/Grid';
import {
  MAIN_NUTRITIONS,
  NESTED_NUTRITIONS,
  SUB_NUTRITIONS,
} from '@features/ProductPage/constants';
import styles from './ThreeColumnTable.module.scss';

interface ThreeColumnTableProps {
  nutrition: NutritionAPI;
}

const renderTable = (arr: NutritionItem[]) => {
  return arr.map((nutritionItem, index) => {
    const { t } = useLocalization('product');
    const shouldBeReset = Object.values(MAIN_NUTRITIONS).includes(nutritionItem.id);
    const isNested = Object.values(SUB_NUTRITIONS).includes(nutritionItem.id);
    const isDeepNested = NESTED_NUTRITIONS === nutritionItem.id;
    const isLastEl = index === arr.length - 1;
    const isTotalSugar = nutritionItem.displayName === t('nutrition.totalSugars');

    return (
      <Grid container key={nutritionItem.displayName}>
        <Grid item xs>
          <div
            className={cx(styles.category, {
              [styles.selected]: shouldBeReset,
              [styles.nested]: isNested,
              [styles.deepNested]: isDeepNested,
            })}
            dangerouslySetInnerHTML={{
              __html: nutritionItem.displayName,
            }}
          />
          {!isLastEl && (
            <div
              className={cx(styles.divider_wrapper, styles.padding_right_05, {
                [styles.padding_left_1]: isTotalSugar,
              })}
            >
              <div className={cx(styles.sm_divider)} />
            </div>
          )}
        </Grid>
        <Grid item xs className={styles.table_item} sx={{ pr: 2 }}>
          <div className={styles.item_content}>
            <span>{`${nutritionItem?.value[0]}${nutritionItem?.uom[0]}`}</span>
            <span className={styles.percentage}>
              {`${nutritionItem?.dailyValue[0] || ''}${nutritionItem?.dailyUom[0] || ''}`}
            </span>
          </div>
          {!isLastEl && (
            <div className={styles.divider_wrapper}>
              <div className={cx(styles.sm_divider)} />
            </div>
          )}
        </Grid>
        <Grid item xs className={styles.table_item}>
          <div className={styles.item_content}>
            <span>{`${nutritionItem?.value[1]}${nutritionItem?.uom[1]}`}</span>
            <span className={styles.percentage}>
              {`${nutritionItem?.dailyValue[1] || ''}${nutritionItem?.dailyUom[1] || ''}`}
            </span>
          </div>
          {!isLastEl && (
            <div className={styles.divider_wrapper}>
              <div className={cx(styles.sm_divider)} />
            </div>
          )}
        </Grid>
      </Grid>
    );
  });
};

export const ThreeColumnTable = ({ nutrition }: ThreeColumnTableProps) => {
  const { NUMBER_OF_SERVINGS, SERVING_WEIGHT, SERVING_SIZE, TOTAL_CALORIES } =
    getNutritionMetaItems(nutrition?.meta);
  const { t } = useLocalization('product');
  return (
    <>
      <div className={cx(styles.table_header, styles.single_header)}>
        <h1 className={cx(styles.title, styles.thick_border)}>
          {t('nutrition.nutritionFactsTitle')}
        </h1>
        {NUMBER_OF_SERVINGS && (
          <span className={styles.servings_number}>
            {NUMBER_OF_SERVINGS?.item?.value && NUMBER_OF_SERVINGS?.item?.value[0]}
          </span>
        )}
        {SERVING_SIZE && (
          <div className={styles.servings}>
            <span className={styles.servings_size}>{t('nutrition.servingSize')}</span>
            <div className={styles.servings_size}>
              <span>
                {`${SERVING_SIZE?.item?.value && SERVING_SIZE?.item?.value[0]} 
                ${SERVING_SIZE?.item?.uom && SERVING_SIZE?.item?.uom[0]} `}
              </span>
              <span>
                {`(${SERVING_WEIGHT?.item?.value && SERVING_WEIGHT?.item?.value[0]}
                ${SERVING_WEIGHT?.item?.uom && SERVING_WEIGHT?.item?.uom[0]})`}
              </span>
            </div>
          </div>
        )}
      </div>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs />
        <Grid item xs className={styles.top_column_name} sx={{ pr: 2 }}>
          {t('nutrition.perServing')}
        </Grid>
        <Grid item xs className={styles.top_column_name}>
          {t('nutrition.perContainer')}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs className={styles.top_header_title}>
          {TOTAL_CALORIES?.item?.displayName}
        </Grid>
        <Grid item xs className={styles.top_header_item} sx={{ pr: 2 }}>
          {TOTAL_CALORIES?.item?.value && TOTAL_CALORIES?.item?.value[0]}
        </Grid>
        <Grid item xs className={styles.top_header_item}>
          {TOTAL_CALORIES?.item?.value && TOTAL_CALORIES?.item?.value[1]}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <div className={cx(styles.divider_wrapper, styles.padding_right_05)}>
            <div className={cx(styles.mid_divider)} />
          </div>
        </Grid>
        <Grid item xs className={styles.top_column_name} sx={{ pr: 2 }}>
          <div className={styles.divider_wrapper}>
            <div className={cx(styles.mid_divider)} />
          </div>
        </Grid>
        <Grid item xs className={styles.top_column_name}>
          <div className={styles.divider_wrapper}>
            <div className={cx(styles.mid_divider)} />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs />
        <Grid item xs className={styles.top_column_name} sx={{ pr: 2 }}>
          {t('nutrition.dailyValue')}
        </Grid>
        <Grid item xs className={styles.top_column_name}>
          {t('nutrition.dailyValue')}
        </Grid>
      </Grid>
      {renderTable(nutrition.main as NutritionItem[])}
      <Grid container>
        <Grid item xs>
          <div className={cx(styles.divider_wrapper, styles.padding_right_05)}>
            <div className={styles.high_divider} />
          </div>
        </Grid>
        <Grid item xs className={styles.top_column_name} sx={{ pr: 2 }}>
          <div className={styles.divider_wrapper}>
            <div className={styles.high_divider} />
          </div>
        </Grid>
        <Grid item xs className={styles.top_column_name}>
          <div className={styles.divider_wrapper}>
            <div className={styles.high_divider} />
          </div>
        </Grid>
      </Grid>
      {renderTable(nutrition.extra as NutritionItem[])}
      <Typography className={styles.disclaimer}>{t('nutrition.dailyValueText')}</Typography>
    </>
  );
};
