import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { ButtonBase, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { minus as MinusIcon, plus as PlusIcon, trash as TrashIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { searchAutocompleteVar } from '@graphql/variables/searchAutocompleteVar';
import styles from './SimpleCounter.module.scss';

interface SimpleCounterProps {
  value: number;
  step?: number;
  isMinusDisabled?: boolean;
  isPlusDisabled?: boolean;
  isInputDisabled?: boolean;
  couldBeRemoved?: boolean;
  isRemoveDisabled?: boolean;
  onMinusClick?: () => void;
  onPlusClick?: () => void;
  onRemoveClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  isCollapseAnimate?: boolean;
  isCollapsed?: boolean;
  isFullWidth?: boolean;
  className?: string;
  minValue?: number;
  maxValue?: number;
  buttonArialLabel?: string;
}

export const SimpleCounter = ({
  value,
  isPlusDisabled,
  onPlusClick,
  isMinusDisabled,
  onMinusClick,
  onRemoveClick,
  couldBeRemoved,
  isRemoveDisabled,
  isInputDisabled,
  isCollapseAnimate,
  isCollapsed,
  isFullWidth,
  className,
  size = 'small',
  onChange = () => undefined,
  step = 1,
  minValue = 1,
  maxValue = 99,
  buttonArialLabel,
}: SimpleCounterProps) => {
  const { t } = useLocalization('components');
  const [quantity, setQuantity] = useState<string>(value.toString());
  const quantityInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setQuantity(value.toString());
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = parseInt(e.target.value, 10).toString();
    let val = e.target.value;
    if (+val < minValue) {
      val = '';
    }
    if (+val > maxValue) {
      val = maxValue.toString();
    }
    setQuantity(val);
    onChange(e);
  };

  const handleClick = (event: React.MouseEvent) => {
    (event.target as HTMLInputElement).focus();
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    event.target.value = parseInt(event.target.value, 10).toString();
    (event.target as HTMLInputElement).blur();
    searchAutocompleteVar(false);
  };

  const wrapperClasses = cx(styles.wrapper, styles[size], className, {
    [styles.collapsed]: isCollapseAnimate,
    [styles.full_width]: isFullWidth,
    [styles.collapse_animate]: isCollapseAnimate && !isCollapsed,
  });

  return (
    <div className={wrapperClasses} data-testid="simple-counter-wrapper">
      <Typography component="span" sx={visuallyHidden} aria-live="polite">
        {t('quantityCounter.ariaLabels.productQuantity', { productQuantity: quantity })}
      </Typography>
      {couldBeRemoved ? (
        <ButtonBase
          type="button"
          disableRipple={true}
          className={styles.button}
          disabled={isRemoveDisabled}
          aria-label={t('simpleCounter.ariaLabels.minus', { buttonArialLabel })}
          data-testid="simple-counter-reduce"
          onClick={onRemoveClick}
        >
          <TrashIcon />
        </ButtonBase>
      ) : (
        <ButtonBase
          type="button"
          disableRipple={true}
          className={styles.button}
          disabled={isMinusDisabled}
          aria-label={t('simpleCounter.ariaLabels.minus', { buttonArialLabel })}
          data-testid="simple-counter-reduce"
          onClick={onMinusClick}
        >
          <MinusIcon />
        </ButtonBase>
      )}

      <div className={styles.input_wrapper}>
        <input
          data-testid="quantity-counter"
          value={quantity}
          placeholder={minValue.toString()}
          ref={quantityInputRef}
          onChange={handleChange}
          onClick={handleClick}
          onBlur={handleBlur}
          type="number"
          step={step}
          inputMode="numeric"
          aria-label={t('simpleCounter.ariaLabels.value', { buttonArialLabel })}
          className={styles.input}
          disabled={isInputDisabled}
        />
      </div>
      <ButtonBase
        type="button"
        disableRipple={true}
        className={styles.button}
        aria-label={t('simpleCounter.ariaLabels.plus', { buttonArialLabel })}
        data-testid="simple-counter-add"
        disabled={isPlusDisabled}
        onClick={onPlusClick}
      >
        <PlusIcon />
      </ButtonBase>
    </div>
  );
};
