import { Address } from '@commons/deliveryAddresses';

export const getAddressText = (deliveryAddress: Address): string =>
  !deliveryAddress
    ? ''
    : [
        deliveryAddress.apartment
          ? deliveryAddress.address1
          : deliveryAddress.address1 && `${deliveryAddress.address1},`,
        deliveryAddress.apartment && `, ${deliveryAddress.apartment},`,
        deliveryAddress.city,
        deliveryAddress.state,
        deliveryAddress.zipCode,
      ]
        .filter((value) => value)
        .join(' ');
