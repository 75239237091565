import { useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ProductQuery, QueryProductArgs } from '@api';
import { GET_ALTERNATIVE_PRODUCT_BY_ID } from '@graphql/product/queries/alternativeProduct';
import { getProductTile } from '@adapters/product/getProductTile';

export const useAlternativeProduct = (productId: string) => {
  const [fetch, { data, loading }] = useLazyQuery<ProductQuery, QueryProductArgs>(
    GET_ALTERNATIVE_PRODUCT_BY_ID,
    {
      variables: { productId, skipHealthWarning: true },
    },
  );

  return useMemo(
    () => ({
      getAlternativeProduct: () => getProductTile(data?.product?.productBasicData),
      loading,
      fetch,
    }),
    [data, loading, fetch],
  );
};
