import { formatTimePeriodString } from '@utils/formatTimePeriodString';
import { TimeslotData } from '@commons/timeslots';

export const getAvailableTimeslotValue = (selectedTimeslot?: TimeslotData | null) => {
  if (selectedTimeslot?.timePeriod) {
    const timePeriod = formatTimePeriodString(selectedTimeslot.timePeriod);
    return `${timePeriod} ${selectedTimeslot.startDate.date}/${
      selectedTimeslot.startDate.month + 1
    }/${selectedTimeslot.startDate.year}`;
  }

  return '';
};
