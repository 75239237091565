import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { info as InfoIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import styles from './TotalPrice.module.scss';

export type TotalPriceProps = {
  totalPrice: string;
  estimatedWeight: string | null;
  estimatedWeightDisclaimer: string | null;
  onMoreInfoClick?: () => void;
};

export const TotalPrice = ({
  totalPrice,
  estimatedWeight,
  estimatedWeightDisclaimer,
  onMoreInfoClick,
}: TotalPriceProps) => {
  const { t } = useLocalization('product');

  return (
    <div className={styles.total_price} data-testid="total price">
      <b className={styles.total_price_value}>
        {t('totalPrice', {
          totalPrice,
        })}{' '}
      </b>
      {!!estimatedWeight && (
        <span className={styles.annotation}>
          <Typography
            data-testid={'estimatedWeightData'}
            className={styles.annotation_text}
            component="span"
            variant="smallBody"
          >
            {t('configurationModal.weight', { estimatedWeight })}{' '}
          </Typography>

          {estimatedWeightDisclaimer && onMoreInfoClick && (
            <IconButton
              aria-label={t('configurationModal.moreInfo.weightTitle')}
              onClick={onMoreInfoClick}
              data-qa="pdp_ew_tooltip"
              data-info-button
            >
              <InfoIcon className={styles.annotation_icon} />
            </IconButton>
          )}
        </span>
      )}
    </div>
  );
};
