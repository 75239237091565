import { gql } from '@apollo/client';
import { LIGHT_CART_FRAGMENT } from '../../fragments/cart/common/lightCartFragment';

export const ADD_TO_ORDER_LIGHT_V2 = gql`
  mutation addToOrderLightV2($input: AddToOrderDataInput!) {
    addToOrderLightV2(input: $input) {
      ...lightCartFragment
    }
  }
  ${LIGHT_CART_FRAGMENT}
`;
