import { DeliveryPassInfo } from './deliveryPass';

export enum USER_TYPE {
  NEW = 'new',
  EXISTING = 'existing',
}

export enum SHOPPING_MODE {
  EXPRESS = 'express',
  FRESHDIRECT = 'freshdirect',
}

export enum TIMESLOT_CART_MODAL_EVENT {
  EVENT = 'timeslot-cart-modal',
  UA_ACTION = 'unavailable_timeslot_cart',
  UA_LABEL_YES = `yes`,
  UA_LABEL_NO = 'no',
}

export interface GACustomerData {
  county: string;
  state: string;
  city: string;
  orderCount: number;
  orderCountResidential: number;
  orderCountCorporate: number;
  modifyMode: boolean;
  timeslotReservationEligible: boolean;
  timeslotReservation: boolean;
  deliveryPassInfo: DeliveryPassInfo;
  expressEligible: boolean;
}
