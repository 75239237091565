import { gql, useMutation } from '@apollo/client';
import { ApplyGiftCardMutation, ApplyGiftCardMutationVariables } from '@api';
import { APPLY_GIFT_CARD } from '@graphql/account/mutations/applyGiftCard';

export const useApplyGiftCard = (applied: boolean, giftCardNum: string) => {
  return useMutation<ApplyGiftCardMutation, ApplyGiftCardMutationVariables>(APPLY_GIFT_CARD, {
    update(cache, { data }) {
      cache.writeFragment({
        id: `ReceivedGiftCard:${giftCardNum}`,

        fragment: gql`
          fragment GiftCard on ReceivedGiftCard {
            applied
          }
        `,
        data: { applied },
      });
    },
  });
};
