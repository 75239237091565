import { AnonUserDeliveryAddressesQuery, UserDeliveryAddressesProtectedQuery } from '@api';

export enum ALT_DELIVERY_OPTION {
  NONE = 'NONE',
  DOORMAN = 'DOORMAN',
  NEIGHBOR = 'NEIGHBOR',
  UNATTENDED = 'UNATTENDED',
}

export enum SERVICE_TYPE {
  HOME = 'HOME',
  CORPORATE = 'CORPORATE',
  PICKUP = 'PICKUP',
  NULL = 'NULL',
}

export enum PHONE_TYPE {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  OTHER = 'OTHER',
  WORK = 'WORK',
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface State {
  code: string;
  title: string;
}

export interface AddressServerValidationResult {
  isSuccess: boolean;
  isResidentialAddress: boolean;
  isCommercialAddress: boolean;
  isOutOfDeliveryZone: boolean;
  isApartmentRequired: boolean;
  isUnrecognizableAddress: boolean;
}

export interface MinimumAddress {
  address1: string;
  city: string;
  id: string;
  state: string;
  zipCode: string;
  serviceType: string;
  apartment: string | null;
}

export interface Address extends MinimumAddress {
  isCustomerAnonymousAddress: boolean;
  selected: boolean;
  serviceType: SERVICE_TYPE;
  zipCode: string;
  popupUrl: string;
  companyName?: string;
  country?: string /*ToDo: have to get rid of optional country */;
}

export interface GeolocationResult {
  isSuccess: boolean;
  isDeliverable: boolean;
  expressEnabled: boolean;
  address: Address | null;
}

export interface DeliveryPhoneNumber {
  extension: string | null;
  phone: string | null;
  type: PHONE_TYPE | null;
}

export interface DeliveryContactInfo {
  firstName: string | null;
  instructions: string | null;
  lastName: string | null;
  phoneNumber: DeliveryPhoneNumber[];
  companyName: string | null;
  email?: string | null;
}

export interface BackupDeliveryInfo {
  contact: DeliveryContactInfo;
  hasUnattendedDeliveryOption: boolean;
  setting: ALT_DELIVERY_OPTION | null;
  address: Address;
}

export interface MinimumDeliveryAddress {
  address: MinimumAddress;
  isTemporary: boolean;
  selected: boolean;
  expressEnabled: boolean;
}

export interface DeliveryAddress {
  address: Address;
  isTemporary: boolean;
  selected: boolean;
  backupInfo: BackupDeliveryInfo;
  contact: DeliveryContactInfo;
  defaultZip: boolean;
  expressEnabled: boolean;
}

export type ShortDeliveryAddress = Pick<DeliveryAddress, 'address' | 'backupInfo' | 'contact'>;

export interface DeliveryAddresses {
  corpAddresses: DeliveryAddress[];
  homeAddresses: DeliveryAddress[];
  pickUpDepotsAddresses: DeliveryAddress[];
  selectedAddress: DeliveryAddress;
  guestUser: boolean;
  userExpressEnabled: boolean;
  futureZoneNotification: boolean;
  showRecaptcha: boolean;
}

export interface SelectDeliveryAddress {
  selectDeliveryAddresses: DeliveryAddresses;
}

export interface AddressToSend {
  address1: string;
  apartment: string;
  city: string;
  country?: string;
  serviceType: SERVICE_TYPE;
  state?: string;
  zipCode: string;
  companyName?: string;
}

export interface PhoneNumberInput {
  extension: string;
  phone: string;
  type?: PHONE_TYPE;
}

export interface DeliveryContactInfoInput {
  firstName?: string;
  instructions?: string;
  lastName?: string;
  companyName?: string;
  phoneNumber?: PhoneNumberInput;
  email?: string;
}

export type DeliveryContactInfoForm = Required<DeliveryContactInfoInput>;

export interface BackupDeliveryInfoInput {
  contact?: DeliveryContactInfoInput;
  setting: ALT_DELIVERY_OPTION;
  address?: AddressToSend;
}

export interface BackupDeliveryInfoForm {
  contact: DeliveryContactInfoForm;
  setting: ALT_DELIVERY_OPTION;
  address: AddressToSend;
  hasUnattendedDeliveryOption: boolean;
}

export interface AddressInput {
  address: AddressToSend;
  contact: DeliveryContactInfoInput;
  backupInfo?: BackupDeliveryInfoInput;
}

export interface AddressForm {
  address: AddressToSend;
  contact: DeliveryContactInfoForm;
  backupInfo: BackupDeliveryInfoForm;
}

export interface AddressValidationInput {
  address: AddressToSend;
  backupInfo?: {
    address: AddressToSend;
  };
}

export interface CurrentDeliveryAddress {
  address: Address;
  isTemporary: boolean;
  expressEligibleAddress: boolean;
  addressLoading: boolean;
  data?: UserDeliveryAddressesProtectedQuery | AnonUserDeliveryAddressesQuery;
}

export enum ADDRESS_SERVICE_TYPE {
  RESIDENTIAL = 'residential',
  CORPORATE = 'corporate',
  DUAL = 'dual',
}
