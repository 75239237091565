import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_RECOMMENDATION_CAROUSEL } from '@graphql/cart/queries/recommendedCarousel';
import { getCarousel } from '@adapters/carouselAdapter';

export const useRecommendationCarousel = () => {
  const { data, ...query } = useQuery(GET_RECOMMENDATION_CAROUSEL, {
    ssr: false,
  });

  const recommendationCarousel = useMemo(
    () => getCarousel(data?.cartForCheckout?.recommendationCarousel),
    [data?.cartForCheckout],
  );

  return {
    ...query,
    data: recommendationCarousel,
  };
};
