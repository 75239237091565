import { FieldProps } from 'formik';
import { RadioGroup, RadioGroupProps } from '@components/UI/RadioGroup/RadioGroup';

interface RadioGroupFieldProps extends RadioGroupProps {
  field: Pick<FieldProps, 'field'>;
  form: Pick<FieldProps, 'form'>;
}

export const RadioGroupField = ({ field, form, ...props }: RadioGroupFieldProps) => {
  return <RadioGroup {...field} {...props} />;
};
