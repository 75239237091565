// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ChangeEvent, useEffect, useId, useState } from 'react';
import { useFormikContext } from 'formik';
import { ADDRESS_SERVICE_TYPE, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { Grid } from '@components/UI/Grid/Grid';
import { Alert, Checkbox } from '@components/UI';
import { info as Info } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { FormValues } from '@components/DeliveryOptionsContent/DeliveryOptionsContent';
import styles from './addressOptionInput.module.scss';

interface AddressServiceTypeNotificationProps {
  showServiceTypeInfo?: ADDRESS_SERVICE_TYPE;
  onInputChange?: () => void;
}

export const AddressServiceTypeNotification = ({
  showServiceTypeInfo,
  onInputChange,
}: AddressServiceTypeNotificationProps) => {
  const { t } = useLocalization();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const { setFieldValue, setFieldTouched, values, touched } = useFormikContext<FormValues>();
  const isInputFieldsTouched = touched.address || touched.aptUnit;
  const uniqServiceId = useId();

  useEffect(
    () => () => {
      setFieldTouched('address', false);
      setFieldTouched('aptUnit', false);
      setFieldTouched('serviceType', false);
    },
    [setFieldTouched],
  );

  useEffect(() => {
    if (
      showServiceTypeInfo !== ADDRESS_SERVICE_TYPE.CORPORATE &&
      values.serviceType !== SERVICE_TYPE.HOME
    ) {
      setFieldValue('serviceType', SERVICE_TYPE.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showServiceTypeInfo]);

  useEffect(() => {
    if (
      showServiceTypeInfo === ADDRESS_SERVICE_TYPE.CORPORATE &&
      values.serviceType !== SERVICE_TYPE.CORPORATE
    ) {
      changeServiceType(SERVICE_TYPE.CORPORATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showServiceTypeInfo]);

  useEffect(() => {
    if (values?.serviceType === SERVICE_TYPE.CORPORATE) {
      setIsCheckboxChecked(true);
    }
  }, []);

  const changeServiceType = (newServiceType: SERVICE_TYPE) => {
    setFieldValue('serviceType', newServiceType);
    if (!touched.serviceType) {
      setFieldTouched('serviceType');
    }
  };

  const handleChangeServiceType = (_?: string, e?: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target) {
      return;
    }

    setIsCheckboxChecked(e?.target?.checked);
    const updatedServiceType = e?.target?.checked ? SERVICE_TYPE.CORPORATE : SERVICE_TYPE.HOME;
    changeServiceType(updatedServiceType);
    onInputChange?.();
  };

  const showAlert = showServiceTypeInfo === ADDRESS_SERVICE_TYPE.CORPORATE && isInputFieldsTouched;
  const showCheckbox =
    showServiceTypeInfo !== ADDRESS_SERVICE_TYPE.CORPORATE || !isInputFieldsTouched;

  return (
    <Grid item className={styles.notification_wrapper}>
      {showAlert && (
        <Grid item className={styles.alert_wrapper}>
          <Alert
            data-testid="service-alert"
            icon={<Info width={18} height={16} color="#21691a" />}
            isFullWidth
          >
            {t('delivery.warning.commercialAddress')}
          </Alert>
        </Grid>
      )}
      {showCheckbox && (
        <Grid item>
          <Checkbox
            data-testid="service-checkbox"
            name="serviceType"
            id={`serviceType-${uniqServiceId}`}
            onChange={handleChangeServiceType}
            isChecked={isCheckboxChecked}
          >
            {t('delivery.serviceType')}
          </Checkbox>
        </Grid>
      )}
    </Grid>
  );
};
