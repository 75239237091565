import { Grid as MuiGrid, GridProps as MuiGridProps } from '@mui/material';

export const Grid = (props: MuiGridProps) => {
  const { container, columnSpacing = 5, ...restProps } = props;

  const newProps = container
    ? {
        container,
        columnSpacing,
        ...restProps,
      }
    : props;
  return <MuiGrid {...newProps} />;
};
