import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getCheckoutEvent } from '@modules/ga/eventBodyGetters/getCheckoutEvent';
import { getProductMetrics } from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { AddShippingInfoOptions } from '@modules/ga/events/ecommerce/add-shipping-info/addShippingInfoCreator';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getProductsWithProperQuantity } from '@modules/ga/eventBodyGetters/utils/getProductsWithProperQuantity';

export interface AddShippingInfoOptionsWithAnalytics extends AddShippingInfoOptions {
  commonAnalyticsData: AnalyticsData;
}

export const addShippingInfoHandler = ({
  cartLinesList,
  commonAnalyticsData,
}: AddShippingInfoOptionsWithAnalytics) => {
  const products = getProductsWithProperQuantity(cartLinesList);
  const { userDetails } = commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getCheckoutEvent({
      products:
        products.map((product) =>
          getProductMetrics({
            ...product,
            position: product.itemPosition,
            listName: product.itemListName,
          }),
        ) ?? [],
      currencyCode: getCurrencyCodeFromSymbol(products?.[0]?.price?.currency),
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
      event: 'checkoutStep-2',
      eventName: 'add_shipping_info',
      uaLabel: 'Step 2',
      step: 2,
      option: 'add_shipping_info',
    }),
  );
};
