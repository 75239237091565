import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { GET_REFER_A_FRIEND } from '@graphql/account/queries/referAFriend';

export const useReferAFriend = () => {
  const { data, refetch, ...queryResult } = useQuery(GET_REFER_A_FRIEND, {
    fetchPolicy: 'cache-and-network',
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...queryResult,
    data,
    refetch,
  };
};

export const useLazyReferAFriend = (options: LazyQueryHookOptions = {}) => {
  const [fetchLazyReferAFriend, { data, ...queryResult }] = useLazyQuery(GET_REFER_A_FRIEND, {
    ...options,
    fetchPolicy: 'cache-and-network',
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...queryResult,
    data,
    fetchLazyReferAFriend,
  };
};
