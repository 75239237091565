import { useEffect, useMemo, useState } from 'react';
import { useGroupScaleProducts } from '@components/GroupScale/hooks';
import { getPrice } from '@utils/getPrice';
import { CartLineLight } from '@commons/cart';
import { useLightCartCache } from '@hooks/cart/useLightCart';

interface UseApplyScalePrice {
  scaleCount: number | null;
  grpVersion: string | null;
  grpId: string | null;
}

export const useApplyScalePrice = ({ scaleCount, grpVersion, grpId }: UseApplyScalePrice) => {
  const [itemsAmountForDiscount, setItemsAmountForDiscount] = useState<number | null>(scaleCount);
  const { products, error, isLoading } = useGroupScaleProducts(grpId, grpVersion);
  const { data } = useLightCartCache();
  const isDiscountApplied = itemsAmountForDiscount === 0;
  const scaleProductIDs = products?.map((product) => product.productId) || [];

  const getScaledProducts = () =>
    data.cartLines?.filter((cartLine) => scaleProductIDs.includes(cartLine.productId));

  const getScaleProductsAmount = () =>
    getScaledProducts().reduce(
      (accum, cartLine) => accum + (cartLine.quantitySelector?.quantity || 0),
      0,
    );

  const calcDiscountValue = (cartLines: CartLineLight[]) =>
    +cartLines
      .reduce((accum, product) => {
        return accum + (product.discount?.discountPrice?.value || 0);
      }, 0)
      .toFixed(2);

  const getSavedMoney = () => {
    const scaledProducts = getScaledProducts();
    if (scaledProducts.length) {
      const currency = scaledProducts?.[0]?.discount?.discountPrice?.currency ?? '';

      return getPrice(calcDiscountValue(scaledProducts), currency);
    }
  };

  const scaleProductsInCart = useMemo(getScaleProductsAmount, [
    products,
    data.cartLines,
    getScaleProductsAmount,
  ]);

  useEffect(() => {
    if (scaleCount) {
      if (scaleProductsInCart >= scaleCount) {
        return setItemsAmountForDiscount(0);
      }
      return setItemsAmountForDiscount(scaleCount - scaleProductsInCart);
    }
  }, [scaleProductsInCart, scaleCount]);

  return {
    products,
    error,
    isLoading,
    isDiscountApplied,
    itemsAmountForDiscount,
    savedMoney: getSavedMoney(),
  };
};
