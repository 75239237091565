import { gql } from '@apollo/client';
import { LIGHT_CART_FRAGMENT } from '@graphql/fragments/cart/common/lightCartFragment';

export const GET_LIGHT_CART = gql`
  query GetLightCart {
    lightweightCart {
      ...lightCartFragment
    }
  }
  ${LIGHT_CART_FRAGMENT}
`;

export const LIGHT_CART_CACHE_FIELD = 'lightweightCart';
