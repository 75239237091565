import { gql } from '@apollo/client';

export const GET_FEATURE_ELIGIBILITIES = gql`
  query GetFeatureEligibilities($features: [String!]!) {
    featureEligibilities(features: $features) {
      key
      value
    }
  }
`;

export const GET_FEATURE_ELIGIBILITIES_CACHE_FIELD = 'featureEligibilities';
