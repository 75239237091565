import { useEffect, useState } from 'react';
import { hasCookie, setCookie } from 'cookies-next';
import { COOKIES_LIST } from '@commons/cookies';
import { useCurrentDeliveryAddress } from '@hooks/deliveryAddress/useCurrentDeliveryAddress';
import { useGeolocation } from './useGeolocation';

export const useDeliveryAlert = () => {
  const { address, addressLoading } = useCurrentDeliveryAddress();
  const { data: geolocationData, error, loading } = useGeolocation();
  const [shouldShowAlert, setShouldShowAlert] = useState(false);

  const closeAlert = () => {
    setShouldShowAlert(false);
    setCookie(COOKIES_LIST.DELIVERY_ALERT, 'true');
  };

  useEffect(() => {
    const hasSelectedAddress = !!address.address1;
    const shouldUpdateAlertState = !hasCookie(COOKIES_LIST.DELIVERY_ALERT) && !addressLoading;

    if (shouldUpdateAlertState) {
      const hasGeolocationError = !!error || !geolocationData.isSuccess;
      const isNotInDeliveryZone = !geolocationData.isDeliverable && !loading;
      const shouldShowDeliveryAlert =
        !hasSelectedAddress && (hasGeolocationError || isNotInDeliveryZone);
      setShouldShowAlert(() => shouldShowDeliveryAlert);
    }
  }, [error, loading, geolocationData, address.address1, addressLoading]);

  return {
    shouldShowAlert,
    closeAlert,
  };
};
