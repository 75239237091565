import { gql } from '@apollo/client';
import { PAYMENT_METHODS_FRAGMENT } from '@graphql/fragments/account/paymentMethods';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';

export const ADD_EBT_CARD = gql`
  mutation addEbt($input: EbtInput!) {
    addEbt(input: $input) {
      data {
        ...paymentMethodsFragment
      }
      validationErrors {
        ...validationErrorFragment
      }
    }
  }
  ${PAYMENT_METHODS_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
`;
