import { gql } from '@apollo/client';
import { CART_SUBTOTAL_BOX_FRAGMENT } from '../../fragments/cart/common/cartSubtotalBox';
import { PRICE_FRAGMENT } from '../../fragments/common/priceFragment';

export const ORDER_INFO_FRAGMENT = gql`
  fragment orderInfoFragment on OrderInfo {
    billingRef
    modifiedOrderLinesCount
    requestedDate
    orderId
    orderTotal
    orderStatus
    orderState {
      isTrackable
      isCancelable
      isDelivered
      isModifiable
      isPending
      isCreditEligible
    }
    orderType
    reorder
    isActive
    deliveryStart
    deliveryEnd
    deliveryCutOffTime
    deliveredAtDateTime
    orderType
    isExpressOrder
    soTemplateName
    discounts {
      text
      value {
        ...priceFragment
      }
    }
    totalSection {
      tip {
        ...cartSubtotalBoxFragment
      }
      totalTax {
        ...cartSubtotalBoxFragment
      }
      deliveryFee {
        ...cartSubtotalBoxFragment
      }
    }
  }
  ${CART_SUBTOTAL_BOX_FRAGMENT}
  ${PRICE_FRAGMENT}
`;
