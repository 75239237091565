import {
  Action,
  AUTH_MODAL_TYPES,
  AuthModalStateProps,
  ErrorStateDataAction,
  FlowTypeAction,
  MagicLinkAction,
  OtpAction,
  ServiceAction,
} from '@commons/auth';
import { initAuthModalState } from '@context/AuthModalContext/AuthModalContext';

export const authModalReducer = (state: AuthModalStateProps, action: Action) => {
  switch (action.type) {
    case AUTH_MODAL_TYPES.FORGOT_PASSWORD: {
      return {
        ...initAuthModalState,
        isForgotPassword: true,
        isResetPasswordModals: true,
        currentModal: action.type,
      };
    }

    case AUTH_MODAL_TYPES.CREATE_ACCOUNT: {
      return {
        ...initAuthModalState,
        isCreateAccountModal: true,
        isAuthModals: true,
        currentModal: action.type,
        shouldShowSignInSwitch: true,
      };
    }

    case AUTH_MODAL_TYPES.CREATE_ACCOUNT_WELCOME: {
      return {
        ...initAuthModalState,
        isCreateAccountWelcomeModal: true,
        isWelcomeModal: true,
        isAuthModals: true,
        currentModal: action.type,
        serviceType: (action as ServiceAction).data,
      };
    }

    case AUTH_MODAL_TYPES.SIGN_IN: {
      return {
        ...initAuthModalState,
        isSocialsModal: true,
        isSignInModal: true,
        isSignInEmailModal: true,
        isAuthModals: true,
        currentModal: action.type,
        shouldShowSignUpSwitch: true,
      };
    }

    case AUTH_MODAL_TYPES.SIGN_IN_PASSWORD: {
      return {
        ...initAuthModalState,
        isSignInModal: true,
        isSignInEmailModal: true,
        isSignInPasswordModal: true,
        isAuthModals: true,
        currentModal: action.type,
        shouldShowSignUpSwitch: true,
        otpData: (action as OtpAction).data,
      };
    }

    case AUTH_MODAL_TYPES.SIGN_IN_PHONE: {
      return {
        ...initAuthModalState,
        isSocialsModal: true,
        isSignInModal: true,
        isSignInPhoneModal: true,
        isAuthModals: true,
        currentModal: action.type,
        shouldShowSignUpSwitch: true,
      };
    }

    case AUTH_MODAL_TYPES.SIGN_IN_WELCOME: {
      return {
        ...initAuthModalState,
        isSignInWelcomeModal: true,
        isWelcomeModal: true,
        isAuthModals: true,
        currentModal: action.type,
      };
    }

    case AUTH_MODAL_TYPES.SENT_REQUEST: {
      return {
        ...initAuthModalState,
        isSentRequestModal: true,
        isResetPasswordModals: true,
        currentModal: action.type,
      };
    }

    case AUTH_MODAL_TYPES.SOCIAL_SIGN_IN: {
      return {
        ...initAuthModalState,
        isSocialSignInModal: true,
        isSocialsModal: true,
        isAuthModals: true,
        currentModal: action.type,
        shouldShowSignUpSwitch: true,
        errorStateData: (action as ErrorStateDataAction).data,
        initialFlowType: (action as FlowTypeAction).data,
      };
    }

    case AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT: {
      return {
        ...initAuthModalState,
        isSocialCreateAccountModal: true,
        isSocialsModal: true,
        isAuthModals: true,
        currentModal: action.type,
        shouldShowSignInSwitch: true,
        errorStateData: (action as ErrorStateDataAction).data,
        initialFlowType: (action as FlowTypeAction).data,
      };
    }

    case AUTH_MODAL_TYPES.TCACKNOWLEDGEMENT: {
      return {
        ...initAuthModalState,
        isTCAcknowledgementModal: true,
        currentModal: action.type,
      };
    }

    case AUTH_MODAL_TYPES.OTP_VERIFICATION: {
      return {
        ...initAuthModalState,
        currentModal: action.type,
        isOtpModal: true,
        otpData: (action as OtpAction).data,
      };
    }

    case AUTH_MODAL_TYPES.MAGIC_LINK_SENT: {
      return {
        ...initAuthModalState,
        currentModal: action.type,
        isMagicLinkSentModal: true,
        magicLinkSentModalData: (action as MagicLinkAction).data,
      };
    }

    default: {
      return state;
    }
  }
};
