import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useRouter } from 'next/router';
import { useReactiveVar } from '@apollo/client';
import cx from 'classnames';
import { plus as PlusIcon, plusV2 as PlusIconV2 } from '@assets/icons/system';
import { Button } from '@components/UI/Button/Button';
import { AutosuggestProduct, ProductTile, SelectedConfiguration } from '@commons/product';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { useLocalization } from '@hooks/index';
import { useProductConfigurationModal } from '@context/productConfigurationModalContext';
import { ReorderCounter } from '@components/ReorderCounter/ReorderCounter';
import { InitialValuesLight } from '@hooks/cart/useProductInfoFromCartLight';
import { routing } from '@constants/routing';
import { InitialValues } from '@hooks/cart';
import { getShoppingListProductInitialValues } from '@features/ShoppingListsPage/utils';
import { useProductPositionContext } from '@modules/ga/context/position';
import { useProductListChannel } from '@modules/ga/context/channel';
import { useProductListTitle } from '@modules/ga/context/title';
import { useProductListLocation } from '@modules/ga/context/location';
import { ConfOptionInput } from '@commons/order';
import { addCustomOrderBtn } from '@graphql/variables/addCustomOrderBtn';
import styles from './TileCustomOrder.module.scss';

interface TileCustomOrderProps {
  children?: never;
  className?: string;
  product: ProductTile | AutosuggestProduct;
  initialValue?: InitialValuesLight;
  hasSuggestion?: boolean;
  productCount?: number;
  isReorder?: boolean;
  isReplaced?: boolean;
  lineToReplace?: string;
  onReplace?: (configs: SelectedConfiguration[], replacedQnty?: number) => void;
  nonFocusable?: boolean;
  onClose?: (product?: ProductTile | AutosuggestProduct, shouldNotRedirect?: boolean) => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
  isV2?: boolean;
}

const TileCustomOrder = forwardRef<HTMLDivElement, TileCustomOrderProps>(
  (
    {
      className,
      product,
      initialValue,
      hasSuggestion,
      isReorder,
      productCount = 0,
      isReplaced,
      lineToReplace,
      onReplace,
      nonFocusable,
      onClose,
      skipAddToCart,
      isReorderItem,
      isV2 = false,
      ...props
    },
    ref,
  ) => {
    const router = useRouter();
    const { t } = useLocalization('product');
    const { openConfigurationModal } = useProductConfigurationModal();
    const { getChannel } = useProductListChannel();
    const { getLocation } = useProductListLocation();
    const { getTitle } = useProductListTitle();
    const { getProductPosition } = useProductPositionContext();
    const innerRef = useRef<HTMLDivElement>(null);
    const currentAddCustomOrderBtn = useReactiveVar(addCustomOrderBtn);

    useImperativeHandle(ref, () => innerRef.current!, []);

    useEffect(() => {
      const addBtn = innerRef?.current?.querySelector('button');
      if (currentAddCustomOrderBtn === product.productName && addBtn) {
        setTimeout(() => {
          addCustomOrderBtn('');
        }, 300);
      }
    }, [currentAddCustomOrderBtn]);

    const getInitialValues = (): InitialValues | undefined => {
      const isShoppingListsPage = router.asPath.includes(routing.shoppingLists);

      if (isShoppingListsPage) {
        return getShoppingListProductInitialValues(product);
      }

      return {
        salesUnit: product.selectedSalesUnit,
        variables: product.configuration?.map(({ characteristicName, characteristicValue }) => ({
          name: characteristicName,
          value: characteristicValue ?? '',
        })),
      };
    };

    const variant = !!isReplaced ? MODAL_TYPE.CART_EDIT : MODAL_TYPE.PRODUCT;
    const handleClick = () => {
      const position = getProductPosition(product.productId);

      openConfigurationModal({
        product,
        hasSuggestion,
        open: true,
        withImageTile: true,
        initialValues: getInitialValues(),
        variant,
        channel: getChannel(product),
        location: getLocation(product),
        title: getTitle(product),
        position,
        chosenCartLineId: lineToReplace,
        onReplace,
        onClose: (conf?: ConfOptionInput[], quantity?: number) => {
          innerRef?.current?.querySelector('button')?.focus();
          onClose?.(
            {
              ...product,
              configuration: conf ?? [],
              quantity: { ...product.quantity, quantity: quantity ?? product.quantity.quantity },
            },
            !quantity,
          );
        },
        skipAddToCart,
        isReorderItem,
      });
    };

    const buttonVariant = Number(initialValue?.count) > 0 ? 'contained' : 'outlined';
    const icon = isV2 ? (
      <PlusIconV2 width={11} height={11} className={styles.icon} />
    ) : (
      <PlusIcon width={14} height={14} className={styles.icon} />
    );
    const buttonContent = Number(initialValue?.count) > 0 ? initialValue?.count : icon;
    const showReorderCounter = isReorder && Number(initialValue?.count) === 0;

    return (
      <div
        {...props}
        className={cx(className, {
          [styles.v2]: isV2,
        })}
        data-testid={t('orderCustomisableProduct')}
        ref={innerRef}
      >
        {!showReorderCounter ? (
          <Button
            onClick={handleClick}
            className={cx(styles.quantity, {
              [styles.collapse_button]: !(Number(initialValue?.count) > 0),
              [styles.v2]: isV2,
              [styles.initial]: !(Number(initialValue?.count) > 0) && !isV2,
            })}
            aria-label={`${t('buttons.addToBasket')} ${product?.brandName} ${product?.productName}`}
            disabled={nonFocusable}
            data-testid={t('buttons.addToBasket')}
            variant={buttonVariant}
          >
            {buttonContent}
          </Button>
        ) : (
          <ReorderCounter
            productCount={productCount}
            onClick={handleClick}
            buttonArialLabel={product?.productName}
          />
        )}
      </div>
    );
  },
);

TileCustomOrder.displayName = 'TileCustomOrder';

export { TileCustomOrder };
