import { getPurchaseEvent } from '@modules/ga/events/ecommerce/purchage/utils/getPurchaseEvent';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { ConfirmationOrder } from '@commons/order';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getProductMetrics } from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { CartLineInfo } from '@commons/cart';
import { productAdapter } from '@adapters/productAdapter';
import {
  getItemListName,
  getItemPosition,
} from '@modules/ga/eventBodyGetters/utils/getItemListName';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { getShoppingMode } from '@modules/ga/utils/getShoppingMode';
import { getDate } from '@utils/getDate';

const { getProduct } = productAdapter();

interface PurchaseEventProps {
  confirmation: ConfirmationOrder;
  commonAnalyticsData: AnalyticsData;
}

export const purchaseHandler = (data: PurchaseEventProps) => {
  const { confirmation, commonAnalyticsData } = data;
  const storedCartLines = sessionStorageService?.read(STORAGE_KEYS.CART_LINES_BEFORE_PURCHASE);

  const coDiscountamount = confirmation.info.discounts.reduce((sum, item) => {
    return sum + item.value.value;
  }, 0);
  const orderType = confirmation.modified ? 'modified' : 'initial';
  const transactionId = confirmation.modifyOrderCount
    ? `${confirmation.info.orderId}-${confirmation.modifyOrderCount}`
    : confirmation.info.orderId;
  const deliveryDate = getDate(confirmation.info.requestedDate, 'M/D/YYYY');

  sendGtmEvent(
    getPurchaseEvent({
      products:
        confirmation.cartLines.map((cartLineInfo: CartLineInfo) => {
          const product = getProduct({
            ...cartLineInfo.product,
            discountAmount: {
              value: cartLineInfo.product.discountAmount,
            },
            quantity: {
              ...cartLineInfo.product.quantity,
              quantity: cartLineInfo.quantity ?? 1,
            },
            coupon: {
              couponId: cartLineInfo.couponId,
            },
          });

          return getProductMetrics({
            ...product,
            position: getItemPosition(storedCartLines, cartLineInfo.id),
            listName: getItemListName(storedCartLines, cartLineInfo.id),
          });
        }) ?? [],
      currencyCode: getCurrencyCodeFromSymbol(
        confirmation?.cartLines?.[0]?.product?.price?.currency,
      ),
      customer: getCustomerData({
        userDetails: commonAnalyticsData.userDetails,
        minimumAccountPreferences: commonAnalyticsData.minimumAccountPreferences,
        userStatus: commonAnalyticsData.userStatus,
        address: commonAnalyticsData.addressData,
        geoData: commonAnalyticsData.geoData,
      }),
      transactionId,
      orderType,
      revenue: confirmation.estimatedTotal.value,
      tax: confirmation.info.totalSection.totalTax.value,
      shipping: confirmation.info.totalSection.deliveryFee.value,
      etipping: confirmation.info.totalSection.tip.value,
      paymentType: confirmation.payment.type,
      purchaseCoupon: confirmation.promoCodes,
      pixelEventId: confirmation.orderSuccessEventId,
      coDiscountamount: coDiscountamount,
      isExpressOrder: confirmation.info.isExpressOrder,
      deliveryServiceType: confirmation.delivery.address.serviceType,
      deliveryDate: deliveryDate,
      shoppingMode: getShoppingMode(),
    }),
  );
};
