/**
 * @deprecated
 * TODO: Refactor to "modules/emitter"
 */
export type On<T> = (handler: (value: T) => void) => () => void;

/**
 * @deprecated
 * TODO: Refactor to "modules/emitter"
 */
export type Emit<T> = (value: T) => void;

/**
 * @deprecated
 * TODO: Refactor to "modules/emitter"
 */
export interface IEmitter<T> {
  on: On<T>;
  emit: Emit<T>;
}

/**
 * @deprecated
 * TODO: Refactor to "modules/emitter"
 */
export function Emitter<T>(): IEmitter<T> {
  const handlers: Array<(value: T) => void> = [];
  const on = (handler: (value: T) => void) => {
    handlers.push(handler);
    return () => {
      if (handlers.includes(handler)) {
        handlers.splice(handlers.indexOf(handler), 1);
      }
    };
  };
  const emit = (value: T) => {
    handlers.forEach((handler) => {
      handler(value);
    });
  };
  return {
    on,
    emit,
  };
}
