import { QueryHookOptions, useApolloClient, useLazyQuery } from '@apollo/client';
import { MODIFY_CHECKOUT } from '@graphql/checkout/query/modifyCheckout';
import { getCheckOutResult } from '@adapters/checkoutAdapter';
import { getFullPaymentData } from '@adapters/accountAdapter';
import { ModifyCheckoutQuery, ModifyCheckoutQueryVariables } from '@api';
import { GET_MASQUERADE_CART } from '@graphql/masquerade/queries/masqueradeCart';
import { GET_CART } from '@graphql/cart';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useOrderCheckoutData = (
  options: QueryHookOptions<ModifyCheckoutQuery, ModifyCheckoutQueryVariables> = {},
) => {
  const client = useApolloClient();
  const { userInfo } = useAuthContext();
  const isMasquerade = !!userInfo?.isMasqueradeMode;
  return useLazyQuery<ModifyCheckoutQuery, ModifyCheckoutQueryVariables>(MODIFY_CHECKOUT, {
    ...options,
    variables: {
      ...options.variables,
      isMasquerade,
    },
    ssr: false,
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const newCart = response?.initModifyOrderCheckout?.cart || {};
      const cartQuery = isMasquerade ? GET_MASQUERADE_CART : GET_CART;
      client.writeQuery({
        query: cartQuery,
        data: {
          cartForCheckout: {
            ...newCart,
          },
        },
      });
    },
  });
};

export const getPayments = (data?: ModifyCheckoutQuery) => {
  return getFullPaymentData(data?.initModifyOrderCheckout?.payments);
};

export const getCheckoutData = (data?: ModifyCheckoutQuery) => {
  return getCheckOutResult(data?.initModifyOrderCheckout);
};
