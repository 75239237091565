import { AD_BLOCK_DIMENSIONS } from '@commons/dfp';
import { DFPBlock } from '@components/DFPBlock/DFPBlock';
import { Banner } from '@components/Banner/Banner';
import { Pair } from '@api';
import { isFeatureEnabled, getFeatureOptions, DYNAMIC_FEATURES } from '@utils/dynamicFeatures';

// TODO TUE-19103 remove feature and all DFP related code when all slots are migrated

interface AdBlockProps {
  adBlockId: string;
  targeting?: Pair[];

  // DFP only props
  adBlockDimensions?: {
    desktop: AD_BLOCK_DIMENSIONS;
    mobile: AD_BLOCK_DIMENSIONS;
  };
  collapseConfig?: {
    collapseEnabled: boolean;
    collapsTime?: number;
    heights?: {
      dekstop: number;
      mobile: number;
    };
  };
  onCollapse?: () => void;
  onAdBlockRender?: (isVisible: boolean) => void;
}

const AdBlock = (props: AdBlockProps) => {
  if (isFeatureEnabled(DYNAMIC_FEATURES.BANNERSERVICE)) {
    const enabledSlots = Object.keys(getFeatureOptions(DYNAMIC_FEATURES.BANNERSERVICE));

    if (enabledSlots.includes(props.adBlockId)) {
      return <Banner {...props} />;
    }
  }

  return <DFPBlock {...props} />;
};

export { AdBlock };
