import { useQuery } from '@apollo/client';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { cartAdapter } from '@adapters/cartAdapter';

const { getLightCart } = cartAdapter();

export const useMinimumCartLinesListLight = () => {
  const { data, ...rest } = useQuery(GET_LIGHT_CART, {
    ssr: false,
    fetchPolicy: 'cache-first',
  });

  const cartLines = getLightCart(data?.lightweightCart).cartLines;

  return { cartLines, ...rest };
};
