import { gql } from '@apollo/client';
import { SUBTOTAL_BOX_FRAGMENT } from '@graphql/fragments/product/common/subtotalBoxFragment';

export const TOTAL_SECTION_FRAGMENT = gql`
  fragment totalSectionFragment on CartTotalSectionData {
    subTotal {
      ...subtotalBoxFragment
    }
    stateBottleDeposit {
      ...subtotalBoxFragment
    }
    fuelSurcharge {
      ...subtotalBoxFragment
    }
    tip {
      ...subtotalBoxFragment
    }
    deliveryFee {
      ...subtotalBoxFragment
    }
    giftCardBalance {
      ...subtotalBoxFragment
    }
    credits {
      ...subtotalBoxFragment
    }
    totalAvalaraTax {
      ...subtotalBoxFragment
    }
    totalTax {
      ...subtotalBoxFragment
    }
    promotion {
      ...subtotalBoxFragment
    }
    automaticHeaderDiscount {
      ...subtotalBoxFragment
    }
    premiumFee {
      ...subtotalBoxFragment
    }
  }
  ${SUBTOTAL_BOX_FRAGMENT}
`;
