import { Payment, PAYMENT_TYPE } from '@commons/payment';
import { getLastFourNumbers } from '@utils/getCardNumber';

export const getPaymentStringFormat = (payment: Payment | null) => {
  const isBankAccountType = payment?.type === PAYMENT_TYPE.ECHECK;
  const paymentTypeText = isBankAccountType ? payment?.bankAccountType : payment?.type;
  return payment?.eWalletID
    ? payment?.type
    : `${paymentTypeText} ${getLastFourNumbers(payment?.accountNumber)}`;
};
