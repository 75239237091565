import { AuthContextGlance } from './AuthContextGlance';
import { ExpressContextGlance } from './ExpressContextGlance';

const CONTENT = [
  {
    title: 'Auth',
    component: AuthContextGlance,
  },
  {
    title: 'Express',
    component: ExpressContextGlance,
  },
];

export const Contexts = () => {
  return (
    <>
      {/* @ts-expect-error details support is lacking */}
      <details name="devtab">
        <summary>Contexts</summary>
        <div>
          {CONTENT.map(({ title, component: Component }) => {
            return (
              // @ts-expect-error details support is lacking
              <details name="context" key={title}>
                <summary>{title}</summary>
                <Component />
              </details>
            );
          })}
        </div>
      </details>
    </>
  );
};
