import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { ChangeEvent, useState } from 'react';
import { GiftCard } from '@adapters/giftCardAdatper';
import { SimpleCounter } from '@components/SimpleCounter';
import { Alert } from '@components/UI';
import { REQUEST_DEBOUNCE_TIME } from '@constants/debounceTime';
import { useGiftCardControlsActions } from '../hooks/useGiftCardControlsActions';
import styles from './QuantityCounter.module.scss';

interface AlertType {
  active: boolean;
  message: string;
}

const initialAlertState = {
  active: false,
  message: '',
};

interface QuantityCounterProps {
  className: string;
  onDelete: (id: string) => void;
  giftCard: GiftCard;
  minValue?: number;
  maxValue?: number;
  isPlusDisabled?: boolean;
  isMinusDisabled?: boolean;
  buttonArialLabel?: string;
}

const GIFT_CARD_QUANTITY_STEP = 1;
const MIN_GIFT_CARD_QUANTITY = 1;
const MAX_GIFT_CARD_QUANTITY = 999;

export const QuantityCounter = ({
  className,
  onDelete,
  giftCard,
  minValue,
  maxValue,
  isMinusDisabled,
  isPlusDisabled,
  buttonArialLabel,
}: QuantityCounterProps) => {
  const [alert, setAlert] = useState<AlertType>(initialAlertState);
  const [currentQuantity, setCurrentQuantity] = useState(giftCard.quantity ?? 0);
  const debouncedDefaultQuantity = useDebounce(giftCard.quantity, REQUEST_DEBOUNCE_TIME);

  const minQuantity = minValue || MIN_GIFT_CARD_QUANTITY;
  const maxQuantity = maxValue || MAX_GIFT_CARD_QUANTITY;

  useUpdateEffect(() => {
    setCurrentQuantity(giftCard.quantity || currentQuantity);
  }, [debouncedDefaultQuantity]);

  const { change } = useGiftCardControlsActions({
    giftCard,
    quantity: currentQuantity,
    maxQuantity,
    minQuantity,
    setQuantity: setCurrentQuantity,
    setAlert,
    onDelete,
  });

  return (
    <>
      {alert.active && (
        <Alert type="error" className={styles.alert} onClose={() => setAlert(initialAlertState)}>
          {alert.message}
        </Alert>
      )}
      <SimpleCounter
        value={currentQuantity}
        step={GIFT_CARD_QUANTITY_STEP}
        size="medium"
        minValue={minQuantity}
        maxValue={maxQuantity}
        onMinusClick={() => {
          change(currentQuantity - GIFT_CARD_QUANTITY_STEP);
        }}
        onPlusClick={() => {
          change(currentQuantity + GIFT_CARD_QUANTITY_STEP);
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => change(e.target.valueAsNumber)}
        className={className}
        isMinusDisabled={isMinusDisabled}
        isPlusDisabled={isPlusDisabled}
        buttonArialLabel={buttonArialLabel}
      />
    </>
  );
};
