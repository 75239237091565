import { useQuery } from '@apollo/client';
import { giftCardAdapter, GiftCardTemplate } from '@adapters/giftCardAdatper';
import { GiftCardPurchaseInfoQuery, GiftCardPurchaseInfoQueryVariables } from '@api';
import { GET_GIFT_CARD_PURCHASE_INFO } from '@graphql/giftCard/queries/giftCardPurchaseInfo';

export interface UseGiftCardPurchaseInfoData {
  minAmount: number;
  maxAmount: number;
  gcTemplates: GiftCardTemplate[];
  gcAmtOptions: string[];
}

export const useGiftCardPurchaseInfo = () => {
  const query = useQuery<GiftCardPurchaseInfoQuery, GiftCardPurchaseInfoQueryVariables>(
    GET_GIFT_CARD_PURCHASE_INFO,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      ssr: false,
    },
  );

  const { getGiftCardTemplates, getAmountOptions } = giftCardAdapter();

  return {
    ...query,
    data: {
      minAmount: query?.data?.giftCardProducts?.minAmount ?? 0.0,
      maxAmount: query?.data?.giftCardProducts?.maxAmount ?? 0.0,
      gcTemplates: getGiftCardTemplates(query?.data?.giftCardProducts?.gcTemplates),
      gcAmtOptions: getAmountOptions(query?.data?.giftCardProducts.gcAmtOptions),
      gcTemplateIdToShow: query.data?.giftCardProducts.gcTemplateIdToShow ?? '',
    },
  };
};
