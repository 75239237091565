enum AD_BLOCK_DIMENSIONS {
  S = 's',
  SM = 'sm',
  M = 'm',
  XXM = 'xxm',
  L = 'l',
}

const AD_BLOCK_COLLAPSE_TIME = 10000;

export { AD_BLOCK_DIMENSIONS, AD_BLOCK_COLLAPSE_TIME };
