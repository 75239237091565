import { useReactiveVar } from '@apollo/client';
import { sectionsToBeDeletedVar } from '@graphql/variables/sectionsToBeDeletedVar';
import { usePageValidationError } from '@context/pageValidationErrorContext';

export const useRemoveUnavailableCartLine = () => {
  const unavailableCartLineIds = useReactiveVar(sectionsToBeDeletedVar);
  const { clear: clearErrors } = usePageValidationError();

  return (cartLineId: string) => {
    if (!unavailableCartLineIds) {
      return;
    }

    const remainingUnavailableCartLineIds = unavailableCartLineIds?.filter(
      (id) => id !== cartLineId,
    );
    sectionsToBeDeletedVar(remainingUnavailableCartLineIds);
    if (!remainingUnavailableCartLineIds.length) {
      clearErrors();
    }
  };
};
