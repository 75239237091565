import { useEffect, useState } from 'react';

export const useScrolling = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.scrollY === 0 && isScrolling) || (window.scrollY !== 0 && !isScrolling)) {
        setIsScrolling(!isScrolling);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling]);

  return isScrolling;
};
