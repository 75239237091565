import { useSettings } from '@hooks/useSettings/useSettings';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';

export const useCustomerServicePhoneNumber = () => {
  const {
    minimumAccountPreferences: { isChefTableAvailable },
  } = useMinimumAccountPreferences();
  const {
    data: { chefTablePhoneNumber },
  } = useSettings(!isChefTableAvailable);

  const getCustomerServicePhoneNumber = (fallbackNumbers: string) =>
    isChefTableAvailable ? chefTablePhoneNumber : fallbackNumbers;

  return { getCustomerServicePhoneNumber };
};
