import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import { useSelectedAddress } from '@hooks/deliveryAddress/useSelectedAddress';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { useRouterQuery } from '@hooks/useRouterQuery';
import { COOKIES_LIST } from '@commons/cookies';
import { DeliveryAddress, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { isServiceTypeParamsValid } from '@utils/isServiceTypeParamsValid';
import { AD_SERVER_CACHE_FIELD } from '@graphql/adServer/adServer';
import { getSSRServiceType } from '@utils/ssrServiceType';

export const CACHE_FIELDS_DEPEND_ON_SERVICE_TYPE = [
  'globalHeader',
  'globalFooter',
  'homepageFeed',
  AD_SERVER_CACHE_FIELD,
];

const getServiceTypeFromSelectedAddress = (selectedAddress: DeliveryAddress) => {
  if (!selectedAddress.address.serviceType) return SERVICE_TYPE.HOME;

  if (selectedAddress.address.serviceType === SERVICE_TYPE.CORPORATE) {
    return SERVICE_TYPE.CORPORATE;
  }

  return SERVICE_TYPE.HOME;
};

export const getServiceTypeFromCookie = () => {
  const cookieServiceType = getCookie(COOKIES_LIST.SERVICE_TYPE);
  if (cookieServiceType) return cookieServiceType as string;
  return null;
};

export const changeRelatedCacheIfServiceTypeChanged = (
  serviceType: string,
  clearCacheFields: (cacheFields: string[]) => void,
) => {
  const cookieServiceType = getCookie(COOKIES_LIST.SERVICE_TYPE);
  const isInitialLoading = cookieServiceType === undefined && serviceType === SERVICE_TYPE.HOME;

  if (cookieServiceType !== serviceType && !isInitialLoading) {
    clearCacheFields(CACHE_FIELDS_DEPEND_ON_SERVICE_TYPE);
  }
};

export const useServiceType = () => {
  const router = useRouter();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const { removeQueryParams } = useRouterQuery();
  const { data: selectedAddress } = useSelectedAddress({
    forGuestUser: true,
    queryOptions: {
      fetchPolicy: 'cache-first',
    },
  });
  const serviceTypeParams =
    'string' === typeof router?.query?.serviceType ? router?.query?.serviceType : '';

  const serviceType =
    serviceTypeParams ||
    getSSRServiceType() ||
    getServiceTypeFromCookie() ||
    getServiceTypeFromSelectedAddress(selectedAddress);

  const isCorporateServiceType = serviceType === SERVICE_TYPE.CORPORATE;

  useEffect(() => {
    if (serviceTypeParams && !isServiceTypeParamsValid(serviceTypeParams)) {
      removeQueryParams([COOKIES_LIST.SERVICE_TYPE]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serviceType) {
      changeRelatedCacheIfServiceTypeChanged(serviceType, clearCacheFields);

      if (setCookie) {
        setCookie(COOKIES_LIST.SERVICE_TYPE, serviceType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceType]);

  return {
    serviceType,
    serviceTypeFromSelectedAddress: getServiceTypeFromSelectedAddress(selectedAddress),
    isCorporateServiceType,
  };
};
