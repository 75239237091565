import { useReactiveVar } from '@apollo/client';
import { isGiftCardCartVar } from '@graphql/variables/isGiftCardCartVar';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useModal } from '@hooks/common';
import { AddPaymentsModal } from '@features/AccountPage/components/AccountPagePayments/components';
import { BACKGROUND_MODE } from '@commons/modals';
import { PaymentAvailabilities } from '@commons/payment';
import { useAccountPreferences } from '@hooks/useAccountPreferences';
import styles from './AddPaymentMethodsButton.module.scss';

interface AddPaymentMethodsButtonProps {
  paymentAvailabilities: PaymentAvailabilities;
  hasPaypalWallet: boolean;
  hasVenmoWallet: boolean;
  onSuccess?: () => void;
  isCreditDebitCardOnly?: boolean;
}

export const AddPaymentMethodsButton = ({
  hasPaypalWallet,
  hasVenmoWallet,
  paymentAvailabilities,
  onSuccess = () => void 0,
  isCreditDebitCardOnly,
}: AddPaymentMethodsButtonProps) => {
  const { t } = useLocalization('checkout');
  const {
    accountPreferences: { isMasqueradeMode },
  } = useAccountPreferences();
  const addPaymentMethods = useModal();
  const isGiftCardCart = useReactiveVar(isGiftCardCartVar);

  if (isMasqueradeMode) {
    return null;
  }

  return (
    <>
      <Button
        className={styles.margin_bottom}
        variant="outlined"
        size="large"
        onClick={addPaymentMethods.openModal}
        fullWidth
      >
        {t('payment.editPaymentModal.addNewPayment')}
      </Button>
      {addPaymentMethods.isOpen && (
        <AddPaymentsModal
          closeModal={addPaymentMethods.closeModal}
          onSuccess={onSuccess}
          backgroundMode={BACKGROUND_MODE.TRANSPARENT}
          isOpen={addPaymentMethods.isOpen}
          showPaypal={!hasPaypalWallet && !isCreditDebitCardOnly}
          showVenmo={!hasVenmoWallet && !isCreditDebitCardOnly}
          showBankAccount={paymentAvailabilities.showBankAccount && !isCreditDebitCardOnly}
          showEBT={paymentAvailabilities.showEbt && !isCreditDebitCardOnly && !isGiftCardCart}
        />
      )}
    </>
  );
};
