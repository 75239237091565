import React from 'react';
import cx from 'classnames';
import { NutritionLine } from '@commons/product';
import styles from '../../Nutrition.module.scss';

interface NutritionTableProps {
  oldNutritionContent: NutritionLine[];
}
interface NutritionLineData {
  value: number;
  uom: string;
}

export const OldNutritionTable = ({ oldNutritionContent }: NutritionTableProps) => {
  const hrRender = (height = 1, width = 220) => (
    <hr
      className={cx(styles.border)}
      style={{
        width: width,
        height: height,
      }}
    />
  );

  const nutritionVitamin = ({ value, uom }: NutritionLineData) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumIntegerDigits: 1,
      maximumSignificantDigits: 4,
      maximumFractionDigits: 1,
    }).format(value);
    return (value < 2 ? '**' : '') + formattedValue + uom;
  };

  const nutritionNotVitamin = ({ value, uom }: NutritionLineData) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumIntegerDigits: 1,
      maximumSignificantDigits: 4,
      maximumFractionDigits: 1,
    }).format(value < 0 ? -1 * value : value);

    return (value < 0 ? 'less than ' : '') + formattedValue + uom;
  };

  const nutrition = (lineData: NutritionLineData, vitamin = false) =>
    vitamin ? nutritionVitamin(lineData) : nutritionNotVitamin(lineData);
  const servingSize = ({ value, uom }: NutritionLineData) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumIntegerDigits: 1,
      maximumSignificantDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
    switch (formattedValue) {
      case '0.8':
        return '4/5 ' + uom;
      case '0.75':
        return '3/4 ' + uom;
      case '0.67':
        return '2/3 ' + uom;
      case '0.6':
        return '3/5 ' + uom;
      case '0.5':
        return '1/2 ' + uom;
      case '0.4':
        return '2/5 ' + uom;
      case '0.38':
        return '3/8 ' + uom;
      case '0.33':
        return '1/3 ' + uom;
      case '0.25':
        return '1/4 ' + uom;
      case '0.2':
        return '1/5 ' + uom;
      case '0.17':
        return '1/6 ' + uom;
      case '0.14':
        return '1/7 ' + uom;
      case '0.13':
        return '1/8 ' + uom;
      case '0.11':
        return '1/9 ' + uom;
      case '0.1':
        return '1/10 ' + uom;
      case '0.09':
        return '1/11 ' + uom;
      case '0.08':
        return '1/12 ' + uom;
      case '0.07':
        return '1/15 ' + uom;
      default:
        return (
          new Intl.NumberFormat('en-US', {
            minimumIntegerDigits: 1,
            maximumFractionDigits: 2,
            maximumSignificantDigits: 4,
          }).format(value) +
          ' ' +
          uom
        );
    }
  };

  const servingsPerContainer = ({ value, uom }: NutritionLineData) => {
    return (
      uom +
      (!value
        ? ''
        : ' ' +
          new Intl.NumberFormat('en-US', {
            minimumIntegerDigits: 1,
            maximumFractionDigits: 2,
            maximumSignificantDigits: 4,
          }).format(value))
    );
  };

  const dailyValue = (
    nutritionLineData: NutritionLineData | undefined,
    quantity: number | undefined,
    extraDivisor: number,
  ) => {
    let returnString = '';
    if (nutritionLineData !== undefined) {
      returnString = nutrition(nutritionLineData, false);
    } else if (quantity !== undefined) {
      returnString =
        new Intl.NumberFormat('en-US', {
          minimumIntegerDigits: 1,
          maximumFractionDigits: 0,
          maximumSignificantDigits: 3,
        }).format((quantity / extraDivisor) * 100) + '%';
    } else {
      returnString = '0%';
    }

    return returnString;
  };

  const vitaminRowRenderer = (index: number | undefined, title: string) => (
    <>
      {index !== undefined && (
        <>
          <tr>
            <td>{hrRender(1, 110)}</td>
          </tr>
          <tr>
            <td className="text9" width="110">
              {title + ' ' + nutrition(oldNutritionContent[index], true)}
            </td>
          </tr>
        </>
      )}
    </>
  );

  const cellRenderer = (
    index: number | undefined,
    title: string,
    defaultValue: number | string | undefined,
    isBold: boolean = false,
    isVitamin: boolean = false,
    align: 'left' | 'center' | 'right' | 'justify' | 'char' | undefined = undefined,
    width: number | undefined = undefined,
  ) => {
    return (
      <td className="text9" align={align} width={width}>
        {isBold ? <b>{title}</b> : title}
        {' ' +
          (index !== undefined ? nutrition(oldNutritionContent[index], isVitamin) : defaultValue)}
      </td>
    );
  };

  const indexes: Record<string, number> = {};

  oldNutritionContent?.forEach((e, i) => {
    indexes[e.name] = i;
  });

  return (
    <>
      {indexes['Weight Watchers Points'] !== undefined && (
        <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width={220}>
          <tr>
            <td className="text9" colSpan={2} align="center">
              <b>Weight Watchers PointsPlus:</b>
              {' ' + nutrition(oldNutritionContent[indexes['Weight Watchers Points']], false)}
            </td>
          </tr>
        </table>
      )}

      <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width={220}>
        <tr className={styles.vertical_align_top}>
          <td className="title18" align="center">
            Nutrition Facts
          </td>
        </tr>
        <tr>
          <td className="text9">
            {'Serv. Size ' +
              servingSize(oldNutritionContent[indexes['Serving Size']]) +
              ' (' +
              nutrition(oldNutritionContent[indexes['Serving Weight']], false) +
              ')'}
          </td>
        </tr>
        <tr>
          <td className="text9">
            {'Servings ' +
              servingsPerContainer(oldNutritionContent[indexes['Servings Per Container']])}
          </td>
        </tr>
        <tr>
          <td>{hrRender(6)}</td>
        </tr>
      </table>

      <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width={220}>
        <tr>
          <td colSpan={2} className="text9">
            <b>Amount Per Serving</b> <br />
            {hrRender()}
          </td>
        </tr>
        <tr>
          {cellRenderer(indexes['Calories'], 'Calories', 0, true, false)}
          {cellRenderer(
            indexes['Calories From Fat'],
            'Calories From Fat',
            0,
            false,
            false,
            'right',
          )}
        </tr>
        <tr>
          <td colSpan={2}>{hrRender(4)}</td>
        </tr>
      </table>

      <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width={220}>
        <tr>
          <td colSpan={2} align="right" className="text8" width="220">
            % Daily Value *
          </td>
        </tr>
        <tr>
          <td colSpan={2}>{hrRender()}</td>
        </tr>
        <tr>
          {cellRenderer(indexes['Total Fat quantity'], 'Total Fat quantity', 0, true, false)}
          <td align="right" className="text9">
            <b>
              {dailyValue(
                oldNutritionContent[indexes['Total Fat value']],
                oldNutritionContent[indexes['Total Fat quantity']]?.value,
                65,
              )}
            </b>
          </td>
        </tr>
        <tr>
          <td colSpan={2} align="right">
            {hrRender()}
          </td>
        </tr>
        <tr>
          <td className={cx('text9', styles.padding_left_10)}>
            {'Saturated Fat ' +
              (indexes['Saturated Fat quantity'] !== undefined
                ? nutrition(oldNutritionContent[indexes['Saturated Fat quantity']], false)
                : '0g')}
          </td>
          <td align="right" className="text9">
            <b>
              {dailyValue(
                oldNutritionContent[indexes['Saturated Fat value']],
                oldNutritionContent[indexes['Saturated Fat quantity']]?.value,
                20,
              )}
            </b>
          </td>
        </tr>
        {indexes['Trans Fat quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 210)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_10)}>
                {'Trans Fat ' +
                  nutrition(oldNutritionContent[indexes['Trans Fat quantity']], false)}
              </td>
              <td align="right" className="text9">
                {' '}
              </td>
            </tr>
          </>
        )}
        {indexes['Stearic Acid quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 210)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_10)}>
                {'Stearic Acid ' +
                  nutrition(oldNutritionContent[indexes['Stearic Acid quantity']], false)}
              </td>
              <td> </td>
            </tr>
          </>
        )}
        {indexes['Polyunsaturated Fat quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 210)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_10)}>
                {'Polyunsaturated Fat ' +
                  nutrition(oldNutritionContent[indexes['Polyunsaturated Fat quantity']], false)}
              </td>
              <td> </td>
            </tr>
          </>
        )}
        {indexes['Monounsaturated Fat quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 210)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_10)}>
                {'Monounsaturated Fat ' +
                  nutrition(oldNutritionContent[indexes['Monounsaturated Fat quantity']], false)}
              </td>
              <td> </td>
            </tr>
          </>
        )}
        <tr>
          <td colSpan={2}>{hrRender()}</td>
        </tr>
        <tr>
          {cellRenderer(indexes['Cholesterol quantity'], 'Cholesterol', '0mg', true, false)}
          <td align="right" className="text9">
            <b>
              {dailyValue(
                oldNutritionContent[indexes['Cholesterol value']],
                oldNutritionContent[indexes['Cholesterol quantity']]?.value,
                300,
              )}
            </b>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>{hrRender()}</td>
        </tr>
        <tr>
          {cellRenderer(indexes['Sodium quantity'], 'Sodium', '0mg', true, false)}
          <td align="right" className="text9">
            <b>
              {dailyValue(
                oldNutritionContent[indexes['Sodium value']],
                oldNutritionContent[indexes['Sodium quantity']]?.value,
                2400,
              )}
            </b>
          </td>
        </tr>
        {indexes['Potassium quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2}>{hrRender()}</td>
            </tr>
            <tr>
              {cellRenderer(indexes['Potassium quantity'], 'Potassium', '0mg', true, false)}

              <td align="right" className="text9">
                <b>
                  {dailyValue(
                    oldNutritionContent[indexes['Potassium value']],
                    oldNutritionContent[indexes['Potassium quantity']]?.value,
                    3500,
                  )}
                </b>
              </td>
            </tr>
          </>
        )}
        <tr>
          <td colSpan={2}>{hrRender()}</td>
        </tr>
        <tr>
          {cellRenderer(
            indexes['Total Carbohydrate quantity'],
            'Total Carbohydrate',
            '0g',
            true,
            false,
          )}
          <td align="right" className="text9">
            <b>
              {dailyValue(
                oldNutritionContent[indexes['Total Carbohydrate value']],
                oldNutritionContent[indexes['Total Carbohydrate quantity']]?.value,
                300,
              )}
            </b>
          </td>
        </tr>
        <tr>
          <td colSpan={2} align="right">
            {hrRender(1, 210)}
          </td>
        </tr>
        <tr>
          <td className={cx('text9', styles.padding_left_10)}>
            Dietary Fiber
            {' ' +
              (indexes['Dietary Fiber quantity'] !== undefined
                ? nutrition(oldNutritionContent[indexes['Dietary Fiber quantity']], false)
                : '0g')}
          </td>
          <td align="right" className="text9">
            <b>
              {dailyValue(
                oldNutritionContent[indexes['Dietary Fiber value']],
                oldNutritionContent[indexes['Dietary Fiber quantity']]?.value,
                25,
              )}
            </b>
          </td>
        </tr>
        {indexes['Soluble Fiber quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 200)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_20)}>
                Soluble Fiber
                {' ' + nutrition(oldNutritionContent[indexes['Soluble Fiber quantity']], false)}
              </td>
              <td> </td>
            </tr>
          </>
        )}
        {indexes['Insoluble Fiber quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 200)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_20)}>
                Insoluble Fiber
                {' ' + nutrition(oldNutritionContent[indexes['Insoluble Fiber quantity']], false)}
              </td>
              <td> </td>
            </tr>
          </>
        )}
        <tr>
          <td colSpan={2} align="right">
            {hrRender(1, 210)}
          </td>
        </tr>
        <tr>
          <td className={cx('text9', styles.padding_left_10)}>
            Sugars
            {' ' +
              (indexes['Sugars quantity'] !== undefined
                ? nutrition(oldNutritionContent[indexes['Sugars quantity']], false)
                : '0g')}
          </td>
          <td align="right" className="text9">
            {' '}
          </td>
        </tr>
        {indexes['Sugar Alcohol quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 210)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_10)}>
                Sugar Alcohol
                {' ' + nutrition(oldNutritionContent[indexes['Sugar Alcohol quantity']], false)}
              </td>
              <td> </td>
            </tr>
          </>
        )}
        {indexes['Other Carbohydrates quantity'] !== undefined && (
          <>
            <tr>
              <td colSpan={2} align="right">
                {hrRender(1, 210)}
              </td>
            </tr>
            <tr>
              <td className={cx('text9', styles.padding_left_10)}>
                Other Carbohydrates
                {' ' +
                  nutrition(oldNutritionContent[indexes['Other Carbohydrates quantity']], false)}
              </td>
              <td> </td>
            </tr>
          </>
        )}
        <tr>
          <td colSpan={2}>{hrRender()}</td>
        </tr>
        <tr>
          {cellRenderer(indexes['Protein quantity'], 'Protein', '0g', true, false)}
          <td align="right" className="text9">
            {' '}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>{hrRender(6)}</td>
        </tr>
      </table>

      <table className={styles.basic_table} cellSpacing={0} cellPadding="0" width={220}>
        <tr>
          <td valign="top">
            <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width={110}>
              <tr>
                {cellRenderer(indexes['Vitamin A'], 'Vitamin A', '0%', false, true, undefined, 110)}
              </tr>
              <tr>
                <td>{hrRender(1, 110)}</td>
              </tr>
              <tr>
                {cellRenderer(indexes['Calcium'], 'Calcium', '0%', false, true, undefined, 110)}
              </tr>
              {vitaminRowRenderer(indexes['Vitamin D'], 'Vitamin D')}
              {vitaminRowRenderer(indexes['Vitamin K'], 'Vitamin K')}
              {vitaminRowRenderer(indexes['Riboflavin'], 'Riboflavin')}
              {vitaminRowRenderer(indexes['Vitamin B6'], 'Vitamin B6')}
              {vitaminRowRenderer(indexes['Vitamin B12'], 'Vitamin B12')}
              {vitaminRowRenderer(indexes['Pantothenic Acid'], 'Pantothenic Acid')}
              {vitaminRowRenderer(indexes['Iodine'], 'Iodine')}
              {vitaminRowRenderer(indexes['Zinc'], 'Zinc')}
              {vitaminRowRenderer(indexes['Copper'], 'Copper')}
              {vitaminRowRenderer(indexes['Chromium'], 'Chromium')}
              {vitaminRowRenderer(indexes['Chloride'], 'Chloride')}
            </table>
          </td>
          <td valign="top">
            <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width={110}>
              <tr>
                {cellRenderer(indexes['Vitamin C'], 'Vitamin C', '0%', false, true, undefined, 110)}
              </tr>
              <tr>
                <td>{hrRender(1, 110)}</td>
              </tr>
              <tr>{cellRenderer(indexes['Iron'], 'Iron', '0%', false, true, undefined, 110)}</tr>
              {vitaminRowRenderer(indexes['Vitamin E'], 'Vitamin E')}
              {vitaminRowRenderer(indexes['Thiamin'], 'Thiamin')}
              {vitaminRowRenderer(indexes['Niacin'], 'Niacin')}
              {vitaminRowRenderer(indexes['Folate'], 'Folate')}
              {vitaminRowRenderer(indexes['Biotin'], 'Biotin')}
              {vitaminRowRenderer(indexes['Phosphorous'], 'Phosphorous')}
              {vitaminRowRenderer(indexes['Magnesium'], 'Magnesium')}
              {vitaminRowRenderer(indexes['Selenium'], 'Selenium')}
              {vitaminRowRenderer(indexes['Manganese'], 'Manganese')}
              {vitaminRowRenderer(indexes['Molybdenum'], 'Molybdenum')}
            </table>
          </td>
        </tr>
      </table>

      <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width={220}>
        <tr>{hrRender()}</tr>
        <tr>
          <td className="text9">
            ** Contains less than 2 percent of the Daily Value of these nutrients.
          </td>
        </tr>
        <tr>
          <td>{hrRender()}</td>
        </tr>
        <tr>
          <td className="text9">
            * Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be
            higher or lower depending on your calorie needs:
          </td>
        </tr>
      </table>

      <table className={styles.basic_table} cellSpacing={0} cellPadding="1" width="220">
        <tr>
          <td className="text9"> </td>
          <td className="text9">Calories:</td>
          <td className="text9">2,000</td>
          <td className="text9">2,500</td>
        </tr>
        <tr>
          <td colSpan={4}>{hrRender()}</td>
        </tr>
        <tr>
          <td className="text9">Total&nbsp;Fat</td>
          <td className="text9">Less&nbsp;than</td>
          <td className="text9">65g</td>
          <td className="text9">80g</td>
        </tr>
        <tr>
          <td className="text9">Saturated&nbsp;Fat</td>
          <td className="text9">Less&nbsp;than</td>
          <td className="text9">20g</td>
          <td className="text9">25g</td>
        </tr>
        <tr>
          <td className="text9">Cholesterol</td>
          <td className="text9">Less&nbsp;than</td>
          <td className="text9">300mg</td>
          <td className="text9">300mg</td>
        </tr>
        <tr>
          <td className="text9">Sodium</td>
          <td className="text9">Less&nbsp;than</td>
          <td className="text9">2,400mg</td>
          <td className="text9">2,400mg</td>
        </tr>
        <tr>
          <td className="text9" colSpan={2}>
            Total&nbsp;Carbohydrate
          </td>
          <td className="text9">300g</td>
          <td className="text9">375g</td>
        </tr>
        <tr>
          <td className="text9" colSpan={2}>
            Dietary&nbsp;Fiber
          </td>
          <td className="text9">25g</td>
          <td className="text9">30g</td>
        </tr>
        <tr>
          <td colSpan={4}>{hrRender()}</td>
        </tr>
        <tr>
          <td className="text9" colSpan={4}>
            Calories per gram:
          </td>
        </tr>
        <tr>
          <td className="text9" colSpan={4} align="center">
            Fat 9 &#183; Carbohydrate 4 &#183; Protein 4
          </td>
        </tr>
      </table>
    </>
  );
};
