import { ReactElement } from 'react';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { PopUp } from '@components/UI/PopUp/PopUp';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import styles from './OrderModificationContainer.module.scss';

interface OrderModificationContainerProps {
  children: ReactElement;
  open: boolean;
  onClose: () => void;
  titleId?: string;
}

export const OrderModificationContainer = ({
  children,
  open,
  onClose,
  titleId,
}: OrderModificationContainerProps) => {
  const { isMobile } = useHeaderContext();

  if (isMobile) {
    return (
      <ModalWindow open={open} onClose={onClose} modalClassName={styles.modal} labelId={titleId}>
        {children}
      </ModalWindow>
    );
  }

  return (
    <PopUp
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      className={styles.popup}
      onClose={onClose}
    >
      {children}
    </PopUp>
  );
};
