import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI/Button/Button';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './RemoveConfirmationModal.module.scss';

interface RemoveConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const RemoveConfirmationModal = ({
  open,
  onClose,
  onSubmit,
}: RemoveConfirmationModalProps) => {
  const { t } = useLocalization('giftCards');
  return (
    <ModalWindow
      open={open}
      onClose={onClose}
      className={styles.modal}
      labelId={MODAL_TITLE_ID.REMOVE_CONFIRMATION}
    >
      <>
        <Typography
          className={styles.title}
          variant="h3"
          component="h2"
          id={MODAL_TITLE_ID.REMOVE_CONFIRMATION}
        >
          <Trans
            i18nKey="cart:emptyCartModal.title"
            components={{
              separator: <br />,
            }}
          />
        </Typography>
        <Typography className={styles.message} variant="body" component="p">
          {t('confirmationModal.text')}
        </Typography>
        <Button size="large" onClick={onSubmit} isFullWidth>
          {t('confirmationModal.button')}
        </Button>
      </>
    </ModalWindow>
  );
};
