import { VenmoPayload } from '@commons/venmo';

// eslint-disable-next-line
type VenmoInstance = any;
type ClientInstance = unknown;

const displayVenmoButton = (
  venmoInstance: VenmoInstance,
  containerId: string,
  handleApprove: (payload: VenmoPayload) => void,
) => {
  const venmoButton = document.getElementById(containerId);
  if (venmoButton) {
    venmoButton.addEventListener('click', function () {
      venmoInstance.tokenize(function (_: unknown, payload: VenmoPayload) {
        venmoButton.removeAttribute('disabled');
        if (payload) {
          handleApprove(payload);
        }
      });
    });
  }
};

export const renderVenmo = (
  token: string,
  containerId: string,
  onApprove: (payload: VenmoPayload) => void,
) => {
  if (window.braintree) {
    window.braintree.client
      .create({ authorization: token })
      .then((clientInstance: ClientInstance) => {
        window.braintree.dataCollector.create({
          client: clientInstance,
          paypal: true,
        });
        return clientInstance;
      })
      .then((clientInstance: ClientInstance) => {
        return window.braintree.venmo.create({
          client: clientInstance,
          allowDesktop: true,
          paymentMethodUsage: 'multi_use',
        });
      })
      .then((venmoInstance: VenmoInstance) => {
        if (!venmoInstance.isBrowserSupported()) {
          return;
        }

        displayVenmoButton(venmoInstance, containerId, onApprove);
      });
  }
};
