import { Alert } from '@components/UI';
import { useGeolocation } from '@hooks/deliveryAddress/useGeolocation';
import styles from './DeliveryAlert.module.scss';
import { GeolocationNotInDeliverableZone, GeolocationError } from './components';

interface DeliveryAlertProps {
  onClose: () => void;
  onClick?: () => void;
}

export const DeliveryAlert = ({ onClose, onClick }: DeliveryAlertProps) => {
  const { data: userGeolocationAddress, error } = useGeolocation();

  const getAlertText = () => {
    const { isSuccess, isDeliverable, address } = userGeolocationAddress;
    const isNotInDeliverableZone = !isDeliverable && isSuccess && address?.zipCode;
    const isFailed = error || !isSuccess;

    if (isFailed) {
      return <GeolocationError onClick={onClick} />;
    }

    if (isNotInDeliverableZone) {
      return <GeolocationNotInDeliverableZone zipCode={address?.zipCode} onClick={onClick} />;
    }
  };
  return (
    <Alert type="error" size="medium" onClose={onClose} className={styles.alert}>
      {getAlertText()}
    </Alert>
  );
};
