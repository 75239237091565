import { Field } from 'formik';
import cx from 'classnames';
import omit from 'lodash/omit';
import { Box, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useRef } from 'react';
import { AddressToSend, ALT_DELIVERY_OPTION } from '@commons/deliveryAddresses';
import { addressToString } from '@utils/addressToString';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { useLocalization, useModal } from '@hooks/index';
import { accountAdapter } from '@adapters/accountAdapter';
import {
  PhoneField,
  RadioGroupField,
  TextField,
  AddAddressFieldForNeighbour,
} from '@components/FormikFields';
import { useResidentialAddressFormValidation } from '@components/ResidentialAddressForm/hooks/useResidentialAddressFormValidation';
import { MEDIUM_FIELD_LENGTH, LARGE_FIELD_LENGTH } from '@utils/validation';
import { Button } from '@components/UI/Button/Button';
import { SpecialInstructionsInfoModal } from '../Modals/SpecialInstructionsInfoModal/SpecialInstructionsInfoModal';
import styles from './SpecialInstructions.module.scss';

enum INSTRUCTIONS_FIELDS {
  DELIVERY_OPTION = 'deliveryOption',
  FIRST_NAME = 'neighborFirstName',
  LAST_NAME = 'neighborLastName',
  ADDRESS = 'neighborAddress',
  PHONE = 'neighborPhone',
  INSTRUCTIONS = 'unattendedInstructions',
}

const RADIO_VALUES = Object.values(ALT_DELIVERY_OPTION);

interface SpecialInstructionsProps {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  initialNeighborDeliveryAddress: Suggestion | null;
  isUnitAvailable: boolean;
  apiError: string | null;
  onIsUnitAvailable: (isAvailable: boolean) => void;
  onAddressSuggestion: (suggestion: Suggestion | null) => void;
  onApiError: (error: string | null) => void;
  hasUnattendedDeliveryOption?: boolean | null;
}

export const SpecialInstructions = ({
  className,
  formik,
  initialNeighborDeliveryAddress,
  isUnitAvailable,
  apiError,
  onIsUnitAvailable,
  onAddressSuggestion,
  onApiError,
  hasUnattendedDeliveryOption,
}: SpecialInstructionsProps) => {
  const { values, errors, setFieldError, setFieldValue, validateField, setErrors } = formik;
  const {
    validateNameForNeighbor,
    validateAddressForNeighbor,
    validateAptUnitForNeighbor,
    validatePhoneForNeighbor,
    validateUnattendedInstructions,
  } = useResidentialAddressFormValidation();
  const { getSuggestionFromAddress } = accountAdapter();
  const { t } = useLocalization();
  const { isOpen, openModal: openSpecialInstructionsInfoModal, closeModal } = useModal();
  const neighborDeliveryAddressSuggestion = useRef(initialNeighborDeliveryAddress);
  const unattendedInstructionsRef = useRef<HTMLTextAreaElement>(null);

  const setNeighborDeliveryAddressSuggestion = (suggestion: Suggestion | null) => {
    neighborDeliveryAddressSuggestion.current = suggestion;
    onAddressSuggestion(suggestion);
  };

  return (
    <div className={className}>
      <Box sx={visuallyHidden}>
        {t(`account:delivery.addressModal.offScreenDeliveryNotification`)}
      </Box>
      <Box role="group" aria-labelledby="delivery_instruction_group">
        <Typography className={'sr-only'} component="p" id="delivery_instruction_group">
          {t(`account:delivery.addressModal.instructionsTitle`)}
        </Typography>
        <Field
          name={INSTRUCTIONS_FIELDS.DELIVERY_OPTION}
          options={RADIO_VALUES.filter((value) => {
            if (value === ALT_DELIVERY_OPTION.UNATTENDED) {
              return hasUnattendedDeliveryOption;
            }
            return true;
          }).map((value) => ({
            value,
            label: t(`account:delivery.addressModal.deliveryOptions.${value}`),
          }))}
          component={RadioGroupField}
          onChange={(value: ALT_DELIVERY_OPTION) => {
            setFieldValue(INSTRUCTIONS_FIELDS.DELIVERY_OPTION, value);
            setErrors({
              ...omit(errors, [
                INSTRUCTIONS_FIELDS.FIRST_NAME,
                INSTRUCTIONS_FIELDS.LAST_NAME,
                INSTRUCTIONS_FIELDS.ADDRESS,
                INSTRUCTIONS_FIELDS.PHONE,
              ]),
            });
            setNeighborDeliveryAddressSuggestion(
              value === ALT_DELIVERY_OPTION.NEIGHBOR ? initialNeighborDeliveryAddress : null,
            );
          }}
          error={!!errors.deliveryOption}
          helperText={errors.deliveryOption}
        />
      </Box>
      <div
        className={cx({
          [styles.hide_fields]: values.deliveryOption !== ALT_DELIVERY_OPTION.NEIGHBOR,
          [styles.hide_fields_opened]: values.deliveryOption === ALT_DELIVERY_OPTION.NEIGHBOR,
        })}
      >
        <>
          <Field
            name={INSTRUCTIONS_FIELDS.FIRST_NAME}
            className={styles.field}
            label={t(`account:delivery.addressModal.fields.neighborFirstName.fieldName`)}
            component={TextField}
            maxLength={MEDIUM_FIELD_LENGTH}
            error={!!errors.neighborFirstName}
            helperText={errors.neighborFirstName}
            validate={(value: string) => validateNameForNeighbor(value, values)}
            id={INSTRUCTIONS_FIELDS.FIRST_NAME}
          />
          <Field
            name={INSTRUCTIONS_FIELDS.LAST_NAME}
            className={styles.field}
            label={t(`account:delivery.addressModal.fields.neighborLastName.fieldName`)}
            component={TextField}
            maxLength={MEDIUM_FIELD_LENGTH}
            error={!!errors.neighborLastName}
            helperText={errors.neighborLastName}
            validate={(value: string) => validateNameForNeighbor(value, values)}
            id={INSTRUCTIONS_FIELDS.LAST_NAME}
          />
          <Field
            name={INSTRUCTIONS_FIELDS.ADDRESS}
            component={AddAddressFieldForNeighbour}
            onChange={(value: AddressToSend) => {
              // TODO: Refactor to use "AddressToSend"
              setNeighborDeliveryAddressSuggestion(
                getSuggestionFromAddress({
                  ...value,
                  state: value.state as string,
                  id: '',
                  isCustomerAnonymousAddress: false,
                  selected: false,
                  popupUrl: '',
                }),
              );

              setFieldValue(
                INSTRUCTIONS_FIELDS.ADDRESS,
                addressToString(value.address1, value.city, value.state || ''),
              );
              setFieldValue('neighborApt', value.apartment || values.neighborApt || '');
              validateField(INSTRUCTIONS_FIELDS.ADDRESS);
            }}
            validate={async () => {
              if (apiError) return t('delivery.error.somethingWentWrong');

              const neighbourAddressError = await validateAddressForNeighbor(
                values,
                setFieldError,
                neighborDeliveryAddressSuggestion.current,
                onIsUnitAvailable,
              );

              const neighbourAptError = await validateAptUnitForNeighbor(
                values,
                setFieldError,
                neighborDeliveryAddressSuggestion.current,
              );

              if (neighbourAptError) {
                setTimeout(() => {
                  setFieldError('neighborApt', neighbourAptError);
                }, 0);
              }

              return neighbourAddressError;
            }}
            // DEPRECATED:BEGIN
            isAptUnitAvailable={isUnitAvailable}
            inputAddress={values.neighborAddress || ''}
            setInputAddress={(value: string) => {
              setFieldValue(INSTRUCTIONS_FIELDS.ADDRESS, value || '');
              setNeighborDeliveryAddressSuggestion(null);
              setFieldValue('neighborApt', '');
              onIsUnitAvailable(false);
            }}
            isAddressSelected={!!neighborDeliveryAddressSuggestion.current}
            setIsAptUnitAvailable={onIsUnitAvailable}
            setAPIErrorMessage={onApiError}
            formik={formik}
            // DEPRECATED:END
          />
          <Field
            name={INSTRUCTIONS_FIELDS.PHONE}
            className={styles.field}
            label={t(`account:delivery.addressModal.fields.neighborPhone.fieldName`)}
            component={PhoneField}
            error={!!errors.neighborPhone}
            helperText={errors.neighborPhone}
            validate={(value: string) => validatePhoneForNeighbor(value, values)}
            id={INSTRUCTIONS_FIELDS.PHONE}
          />
        </>
      </div>
      <div
        className={cx({
          [styles.hide_fields]: values.deliveryOption !== ALT_DELIVERY_OPTION.UNATTENDED,
          [styles.hide_fields_opened]: values.deliveryOption === ALT_DELIVERY_OPTION.UNATTENDED,
        })}
      >
        <div className={styles.unattended_annotation}>
          <Typography className={styles.unattended_annotation_title} component="h2">
            {t('account:delivery.addressModal.outside_annotation.title')}
          </Typography>
          <Typography>{t('account:delivery.addressModal.outside_annotation.text')}</Typography>
          <Button
            variant="underline"
            onClick={() => {
              openSpecialInstructionsInfoModal();
            }}
          >
            {t('common:buttons.learnMore')}
          </Button>
        </div>
        <Field
          inputRef={unattendedInstructionsRef}
          name={INSTRUCTIONS_FIELDS.INSTRUCTIONS}
          label={t(`account:delivery.addressModal.fields.unattendedInstructions.fieldName`)}
          component={TextField}
          error={!!errors.unattendedInstructions}
          helperText={errors.unattendedInstructions}
          validate={(value: string) => {
            // NOTE focus-on-error does not work for multiline fields, need to focus manually
            const error = validateUnattendedInstructions(value, values);

            if (error && unattendedInstructionsRef.current) {
              unattendedInstructionsRef.current.focus();
            }

            return error;
          }}
          maxLength={LARGE_FIELD_LENGTH}
          multiline
          id={INSTRUCTIONS_FIELDS.INSTRUCTIONS}
        />
      </div>
      <SpecialInstructionsInfoModal open={isOpen} onClose={closeModal} />
    </div>
  );
};
