import { SelectedConfiguration } from '@commons/product';
import { ConfOptionInput } from '@commons/order';

export const getProductConfigurationVariables = (
  configs: SelectedConfiguration[] = [],
): ConfOptionInput[] =>
  configs.map(({ value, name }) => ({
    characteristicName: name,
    characteristicValue: value,
  }));
