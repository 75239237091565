import { AUTH_MODAL_TYPES } from '@commons/auth';
import { Button } from '@components/UI/Button/Button';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useLocalization } from '@hooks/useLocalization';
import styles from './AccountAuthControls.module.scss';

interface DisplayedLogInProps {
  tabIndex?: number;
}

export const AccountAuthControls = ({ tabIndex }: DisplayedLogInProps) => {
  const { toggleAuthModal } = useAuthModalContext();
  const { t } = useLocalization('header');
  const { SOCIAL_SIGN_IN, SOCIAL_CREATE_ACCOUNT } = AUTH_MODAL_TYPES;

  return (
    <p aria-label="Sign In or Create account" className={styles.list_link}>
      <Button
        variant="underline"
        onClick={() => {
          toggleAuthModal(SOCIAL_SIGN_IN);
        }}
        className={styles.link}
        data-qa="user_sign_in"
      >
        {t('signIn')}
      </Button>

      <span className={styles.separator}>{t('or')}</span>

      <Button
        variant="underline"
        onClick={() => {
          toggleAuthModal(SOCIAL_CREATE_ACCOUNT);
        }}
        tabIndex={tabIndex && tabIndex + 1}
        className={styles.link}
        data-qa="user_create_account"
      >
        {t('createAccount')}
      </Button>
    </p>
  );
};
