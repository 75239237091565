import { Typography } from '@mui/material';
import cx from 'classnames';
import { Button } from '@components/UI';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import styles from './NoResultsTemplate.module.scss';

interface NoResultsTemplateProps {
  title: string;
  buttonText?: string;
  subtitle?: React.ReactNode;
  onClick?: () => void;
}

export const NoResultsTemplate = ({
  title,
  subtitle,
  buttonText,
  onClick = () => void 0,
}: NoResultsTemplateProps) => {
  const isRecommendedBottomCarouselEnabled = isFeatureEnabled(
    DYNAMIC_FEATURES.RECOMMENDED_BOTTOM_CAROUSEL,
  );

  return (
    <div
      className={cx(styles.no_results_wrapper, {
        [styles.with_recommended_carousel]: isRecommendedBottomCarouselEnabled,
      })}
    >
      <div className={styles.no_results}>
        <Typography variant="h1" className={styles.no_results_title}>
          {title}
        </Typography>
        <div className={styles.no_results_description}>{subtitle}</div>
        {buttonText && (
          <div className={styles.no_results_button_wrapper}>
            <Button size="large" onClick={onClick}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
