export enum FORM_FIELDS {
  ADDRESS = 'address',
  APT_UNIT = 'aptUnit',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  NEIGHBOR_APT = 'neighborApt',
  NEIGHBOR_ADDRESS = 'neighborAddress',
  INSTRUCTIONS = 'instructions',
  EMAIL = 'email',
}
