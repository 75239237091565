import { IAction } from '@modules/action';
import { emit, Off, on } from '@modules/emitter';
import { AnalyticsCallback } from '@modules/ga/context/AnalyticsData/AnalyticsDataContext';
import { GA_EVENT_TYPE, GAEvents } from '@modules/ga/constants';
import { GAAction } from '@modules/ga/type';

export const onGAEvent = (addEventHandler: (callback: AnalyticsCallback) => void): Off =>
  on((action) => {
    if (action.type !== GA_EVENT_TYPE) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { message, data } = action as GAAction<any>;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    addEventHandler((commonAnalyticsData) => GAEvents[message]({ ...data, commonAnalyticsData }));
  });

export const fireEvent = (props: IAction) => emit(props);
