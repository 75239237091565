import { gql } from '@apollo/client';
import { ACCOUNT_PREFERENCES_FRAGMENT } from '../../fragments/account/accountPreferences';

export const ACCOUNT_PREFERENCES = gql`
  query AccountPreferences {
    accountPreferences {
      ...accountPreferencesFragment
    }
  }
  ${ACCOUNT_PREFERENCES_FRAGMENT}
`;
