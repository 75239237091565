import { ListItem, ListItemIcon } from '@mui/material';
import React, { ReactElement, SVGProps } from 'react';
import cx from 'classnames';
import { BaseLink as Link, Button } from '@components/UI';
import styles from '../AccountNavigationMenu/AccountNavigationMenu.module.scss';

interface AccountSettingsInfoItemProps {
  children?: never;
  name: string;
  href?: string;
  onClick: () => void;
  icon: (props: SVGProps<SVGElement>) => ReactElement;
}

export const AccountSettingsInfoItem = ({
  name,
  icon,
  onClick,
  href,
}: AccountSettingsInfoItemProps) => {
  const AccountIcon = icon;
  if (href) {
    return (
      <Link legacyBehavior key={name} href={`/${href}`}>
        <ListItem className={cx(styles.list_item, styles.account_list_item, styles.account_item)}>
          <ListItemIcon className={styles.list_image}>
            <AccountIcon width={32} height={32} />
          </ListItemIcon>
          <a href={href} className={styles.list_text} onClick={onClick}>
            {name}
          </a>
        </ListItem>
      </Link>
    );
  }

  return (
    <ListItem className={cx(styles.list_item, styles.account_list_item, styles.account_item)}>
      <ListItemIcon className={styles.list_image}>
        <AccountIcon width={32} height={32} />
      </ListItemIcon>
      <Button onClick={onClick} className={styles.list_text}>
        {name}
      </Button>
    </ListItem>
  );
};
