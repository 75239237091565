import { gql } from '@apollo/client';

export const TEMPLATES_FRAGMENT = gql`
  fragment templatesFragment on GiftCardDefaultData {
    gcTemplates {
      gcTemplateId
      gcTemplateTitle
      gcTemplateLabel
      gcTemplateImageLink
    }
  }
`;
