import { FieldProps } from 'formik';
import { Input } from '@components/UI';

interface NumberInputProps {
  min?: number;
  max?: number;
}

type NumberFieldProps = FieldProps & NumberInputProps;

export const NumberField = ({ field, form, min, max, ...props }: NumberFieldProps) => {
  const inputProps: NumberInputProps = {};
  if (min) inputProps.min = min;
  if (max) inputProps.max = max;

  return <Input {...field} {...props} inputProps={{ ...inputProps, type: 'number' }} />;
};
