import { MasqueradeInitInput } from '@api';
import { MasqueradeQueryParams } from '@modules/masquerade-query-params';
import { formatInitMasqueradeVariables } from '@utils/formatInitMasqueradeVariables';

// TODO: Make dependency flow one way: DTO -> Entity -> etc. (MasqueradeInitInput from @api cannot be an output interface here)
export const getInitMasqueradeModeInput = (input: MasqueradeQueryParams): MasqueradeInitInput => {
  const formattedInput = formatInitMasqueradeVariables(input);
  const {
    case: isCase,
    forceOrderAvailable: isForceOrderAvailable,
    autoApproveAuthorized: isAutoApproveAuthorized,
    ...rest
  } = formattedInput;
  return {
    isCase,
    isForceOrderAvailable,
    isAutoApproveAuthorized,
    // TODO: Move spread operator usage to the beginning and NEVER use it at the end
    ...rest,
  };
};
