import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { DeliveryPassRelatedErrorModalProps } from '@modules/modals/types/deliveryPassRelatedErrorModal/deliveryPassRelatedErrorModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';

export const openDeliveryPassRelatedErrorModal = (errorMessage?: string) => {
  fireOpenModal<DeliveryPassRelatedErrorModalProps>(MODALS_LIST.DELIVERY_PASS_ERROR, {
    errorMessage,
  });
};

export const closeDeliveryPassRelatedErrorModal = () => {
  fireCloseModal(MODALS_LIST.DELIVERY_PASS_ERROR);
};
