import { ReactNode, createContext, useContext } from 'react';
import { ACCOUNT_MANAGEMENT_PAGE } from '@commons/account';
import { useAccountPage, UseAccountPageType } from '../hooks/useAccountPage';

interface AccountPageContextProviderProps {
  children: ReactNode;
}

export const AccountPageContext = createContext<UseAccountPageType>({
  getPageComponent: () => undefined,
  fetchPageData: () => undefined,
  currentPage: ACCOUNT_MANAGEMENT_PAGE.EMPTY,
  isLoading: false,
});

export const useAccountPageContext = () => {
  const context = useContext(AccountPageContext);
  if (context === undefined) {
    throw new Error('useAccountPageContext must be used within a AccountPageContextProvider');
  }
  return context;
};

export const AccountPageContextProvider = ({ children }: AccountPageContextProviderProps) => {
  const accountPage = useAccountPage();
  return <AccountPageContext.Provider value={accountPage}>{children}</AccountPageContext.Provider>;
};
