import { ApolloLink } from '@apollo/client';
import { IPreviewModeHeaders } from '@modules/preview-mode';

export const previewModeLink = (previewHeaders?: IPreviewModeHeaders) => {
  return new ApolloLink((operation, forward) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.setContext((ctx: Record<string, any>) => {
      const headers = previewHeaders || {};
      return {
        ...ctx,
        headers: {
          ...ctx.headers,
          ...headers,
        },
      };
    });
    return forward(operation);
  });
};
