import styles from '../DevTools.module.scss';

const GITHUB_ROOT = 'https://github.com/freshdirectllc/FreshDirect-WebUI';

export const BuildInfo = () => {
  const buildId = window.__NEXT_DATA__.buildId;

  if (buildId === 'development') {
    return <div>Dev server is running</div>;
  }

  const [branch, commit] = buildId.split('__');

  return (
    <>
      {/* @ts-expect-error details support is lacking */}
      <details name="devtab">
        <summary>Build info</summary>
        <div>
          <dl className={styles.buildinfo}>
            <div>
              <dt>Branch</dt>
              <dd>
                <a target="_blank" href={`${GITHUB_ROOT}/tree/${branch}`}>
                  {branch}
                </a>
              </dd>
            </div>
            <div>
              <dt>Commit</dt>
              <dd>
                <a target="_blank" href={`${GITHUB_ROOT}/commit/${commit}`}>
                  {commit}
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </details>
    </>
  );
};
