import classNames from 'classnames';
import { Translate } from 'next-translate';
import { PRODUCT_TAGS } from '@commons/product';
import { topPick as TopPickIcon } from '@assets/icons/system';
import { fdWineAndSpiritsTwoColumn as WineAndSpiritsLogo } from '@assets/icons/logos';
import { ExpressIcon } from '@components/UI/ExpressIcon/ExpressIcon';
import styles from './Tag.module.scss';

interface PeakQualityProps {
  t: Translate;
  className?: string;
  isV2?: boolean;
}

interface ExpressEligibleProps {
  t: Translate;
  className?: string;
  isV2?: boolean;
}

interface WineAndSpiritsProps {
  t: Translate;
  className?: string;
  isSelectMode?: boolean;
}

interface TagProps {
  [PRODUCT_TAGS.PEAK_QUALITY]: (props: PeakQualityProps) => JSX.Element;
  [PRODUCT_TAGS.EXPRESS_ELIGIBLE]: (props: ExpressEligibleProps) => JSX.Element;
  [PRODUCT_TAGS.WINE_AND_SPIRITS]: (props: WineAndSpiritsProps) => JSX.Element;
}

export const Tag = {} as TagProps;

Tag[PRODUCT_TAGS.PEAK_QUALITY] = ({ t, className, isV2 = false }) => (
  <div
    className={classNames(styles.tag, className, styles.peak_quality, { [styles.v2]: isV2 })}
    data-qa="tile_peak_quality_tag"
    data-testid="peak-quality-tag"
  >
    <TopPickIcon className={styles.tag_icon} aria-label={t('aria.peakQualitySvgLabel')} />
    <span aria-hidden className={styles.tag_text}>
      Peak Quality
    </span>
  </div>
);

Tag[PRODUCT_TAGS.EXPRESS_ELIGIBLE] = ({ t, className, isV2 = false }) => (
  <div
    className={classNames(styles.tag, className, styles.express_eligible, { [styles.v2]: isV2 })}
    aria-label={t('aria.expressEligibleTagLabel')}
    data-qa="express_eligible_tag"
  >
    <ExpressIcon ariaLabel={t('aria.expressEligibleSvgLabel')} />
  </div>
);

Tag[PRODUCT_TAGS.WINE_AND_SPIRITS] = ({ t, className, isSelectMode = false }) => (
  <div
    className={classNames(styles.tag, className, styles.wine_logo, {
      [styles.wine_logo_select_mode]: isSelectMode,
    })}
    aria-label={t('ariaLabels.wineLogo')}
  >
    <WineAndSpiritsLogo width={58} height={21} />
  </div>
);
