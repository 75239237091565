import { Divider, Grid, Typography } from '@mui/material';
import Image from 'next/image';
import { Button } from '@components/UI';
import { giftCardAdapter } from '@adapters/giftCardAdatper';
import { getPrice } from '@utils/getPrice';
import { Controls } from '../Controls/Controls';
import { GiftCardCustomRenderProps } from '../GiftCardForm/GiftCardForm';
import styles from './ResendView.module.scss';

export const ResendView = ({
  values,
  t,
  submitButtonText,
  templateData,
}: GiftCardCustomRenderProps) => {
  const { getGiftCardTemplates } = giftCardAdapter();
  const template = getGiftCardTemplates(templateData?.gcTemplates)[0];
  return (
    <div>
      <Grid className={styles.imageContainer}>
        <Image
          className={styles.image}
          src={template.gcTemplateImageLink}
          alt={template.gcTemplateTitle}
          fill
          priority
        />
      </Grid>
      <Grid container flexDirection="row" justifyContent="space-between" alignItems="baseline">
        <Grid item>
          <Typography variant="subtitle1" component="h4" className={styles.subtitle}>
            {t('giftCardModal.giftCardAmountSubtitle')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" component="p">
            {getPrice(Number(values?.giftCardAmount))}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={styles.divider} />
      <Controls isResend />
      <Typography variant="subtitle1" component="p" className={styles.subtitle}>
        {t('giftCardModal.note')}
      </Typography>
      <Button type="submit" size="large" className={styles.submit_button} isFullWidth>
        {t(submitButtonText ?? '')}
      </Button>
    </div>
  );
};
