import { gql } from '@apollo/client';
import { SUBTOTAL_BOX_FRAGMENT } from '@graphql/fragments/product/common/subtotalBoxFragment';
import { TOTAL_SECTION_FRAGMENT } from '@graphql/fragments/product/common/totalSectionFragment';
import { GIFT_CARD_FRAGMENT } from './giftCard';

export const GIFT_CARDS_CART_FRAGMENT = gql`
  fragment giftCardsCartFragment on GiftCardsCart {
    cardsCount
    cards {
      ...giftCardFragment
    }
    totalSection {
      ...totalSectionFragment
    }
    estimatedTotal {
      ...subtotalBoxFragment
    }
  }
  ${GIFT_CARD_FRAGMENT}
  ${SUBTOTAL_BOX_FRAGMENT}
  ${TOTAL_SECTION_FRAGMENT}
`;
