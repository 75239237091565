interface Address {
  address1: string;
  city: string;
  state: string;
  apartment: string | null;
}

export const getAddressLabel = (address: Address) => {
  const apartmentText = address.apartment ? `${address.apartment}, ` : '';
  return `${address.address1?.toLowerCase()}, ${apartmentText}${address.city} ${address.state}`;
};
