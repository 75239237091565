import { useEffect } from 'react';
import { PaymentMethodType } from '@api';
import { useInitHostedPayment } from '@hooks/payments/useInitHostedPayment';
import { PaymentsOnSuccess } from '@components/PaymentsAddTabs/PaymentAddTabs.types';
import { AddBankAccountForm } from './components/AddCardForm/AddBankAccountForm';

interface AddBankAccountTabContentProps {
  onSuccess?: PaymentsOnSuccess;
  onError: (err: string) => void;
}

export const AddBankAccountTabContent = ({ onSuccess, onError }: AddBankAccountTabContentProps) => {
  const { data, refetch } = useInitHostedPayment(PaymentMethodType.EC);
  const hostedPaymentURL = data?.initializeHostedPayment?.url;

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (!hostedPaymentURL) {
    return null;
  }

  return <AddBankAccountForm url={hostedPaymentURL} onSuccess={onSuccess} onError={onError} />;
};
