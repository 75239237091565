import { gql } from '@apollo/client';
import { GIFT_CARDS_CART_FRAGMENT } from '../../fragments/cart/common/giftCardsCart';

export const EMPTY_GIFT_CARDS_CART = gql`
  mutation EmptyGiftCardsCart {
    emptyGiftCardsCart {
      ...giftCardsCartFragment
    }
  }
  ${GIFT_CARDS_CART_FRAGMENT}
`;
