import { useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { LightweightCart } from '@api';
import { useRemoveFromCartEvent } from '@modules/ga/hooks/useRemoveFromCart';
import { DELETE_CART_LINE_LIGHT } from '@graphql/cart/mutations/deleteCartLineLight';
import { updateLightCartCache } from '@hooks/cart/updateLightCartCache';
import { CART_LINE_NOT_FOUND } from '@constants/errorCodes';
import { GET_LIGHT_CART, LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { cartAdapter } from '@adapters/cartAdapter';
import { CartLight } from '@commons/cart';
import { updateModifyingOrderAlertCache } from '@hooks/account/updateModifyingOrderAlertCache';

const { getLightCart } = cartAdapter();

export const useDeleteFromCartLight = (cartLineId?: string) => {
  const cartBeforeMutation = useRef<CartLight | null>(null);
  const { trackRemoveFromLightCart } = useRemoveFromCartEvent();
  const client = useApolloClient();

  return useMutation(DELETE_CART_LINE_LIGHT, {
    variables: { cartLineId },
    update(cache, { data: { deleteCartLineLight: cart } }) {
      const cachedCart = cache.readQuery<{ lightweightCart: LightweightCart }>({
        query: GET_LIGHT_CART,
      });
      cartBeforeMutation.current = getLightCart(cachedCart?.lightweightCart);
      updateModifyingOrderAlertCache(cache, cart);
      updateLightCartCache(cache, cart);
    },
    onCompleted(_, options) {
      if (cartBeforeMutation.current) {
        const lineId = options?.variables?.cartLineId ?? '';
        const cart = cartBeforeMutation.current;

        trackRemoveFromLightCart(cart, lineId);
        cartBeforeMutation.current = null;
      }
    },
    onError: (error) => {
      if (error.message === CART_LINE_NOT_FOUND) {
        client.cache.evict({ fieldName: LIGHT_CART_CACHE_FIELD });
        client.cache.gc();
      }
    },
  });
};
