import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import {
  Nutrition,
  NutritionV2,
  NutritionLine,
  WineData,
  ProductExtraDetails,
} from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import { Link } from '@components/UI';
import { NutritionTable } from '@features/ProductPage/components/Nutrition/Nutrition';
import { isServerRendering } from '@utils/isServerRendering';
import { TABS, TAB_HREFS } from '../../constants';
import { TabItem } from './components/TabItem/TabItem';
import { TabPanel } from './components/TabPanel/TabPanel';
import styles from './AdditionalInfoModal.module.scss';
import { DescriptionTabContent } from './components/DescriptionTabContent/DescriptionTabContent';
import { ALCOHOL_KEYS, ALCOHOL_TITLE_TRANSLATIONS, WineDataKeys } from './constants/alcoholTitles';

export interface AdditionalInfoModalProps {
  description?: string | null;
  nutrition: Nutrition | NutritionV2;
  oldNutritionContent?: NutritionLine[] | null;
  ingredients?: string | null;
  instructions: string | null;
  toggleClose?: () => void;
  link: string | null;
  allergens: string | null;
  alcohol?: WineData;
  extraDetails: ProductExtraDetails;
  productQualityNote: string | null;
}

const setInnerHtml = (html: string) => {
  return { __html: html };
};

export const AdditionalInfoModal = ({
  description,
  nutrition,
  oldNutritionContent,
  instructions,
  extraDetails,
  toggleClose,
  allergens,
  link,
  alcohol,
  productQualityNote,
  ingredients,
}: AdditionalInfoModalProps) => {
  const getTabFromHash = () => {
    const hash = !isServerRendering() && window?.location?.hash;
    return (Object.entries(TAB_HREFS).find(([, v]) => v === hash)?.[0] as TABS) ?? TABS.EMPTY;
  };

  const initialTab = getTabFromHash();
  const [value, setValue] = useState<TABS>(initialTab);
  const { t } = useLocalization();
  const hasInstructionInfo = link;
  const hasNutrition =
    ('id' in nutrition && nutrition?.id) ||
    ('panelType' in nutrition && nutrition?.panelType) ||
    oldNutritionContent?.length;
  const getTabItemValue = (tabValue: string | string[]) => {
    if (typeof tabValue === 'string') {
      return !!tabValue ? [tabValue] : [];
    }
    return tabValue;
  };
  const isOpen = value !== TABS.EMPTY;

  const memoizedAlcoholData = useMemo(() => {
    if (!alcohol) return null;
    const filterAlcoholValue = (key: WineDataKeys) => {
      const item = alcohol[key];
      return typeof item === 'string' ? !!item : item.length > 0;
    };

    return ALCOHOL_KEYS.filter(filterAlcoholValue).map((key) => (
      <TabItem
        key={key}
        values={getTabItemValue(alcohol[key])}
        title={t(ALCOHOL_TITLE_TRANSLATIONS[key])}
      />
    ));
  }, [t, alcohol]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleHashChange = () => {
        setValue(getTabFromHash());
      };

      window.addEventListener('hashchange', handleHashChange);

      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    }
  }, [setValue]);

  const handleClose = () => {
    if (typeof window !== 'undefined') {
      window.location.hash = '';
    }
    setValue(TABS.EMPTY);
    toggleClose?.();
  };

  return (
    <ModalWindow className={styles.modal_wrapper} open={isOpen} onClose={handleClose}>
      <>
        <div className={styles.divider}>
          <MuiTabs value={value} aria-label="Tabs" className={styles.tabs_wrapper}>
            {description && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.DESCRIPTION}
                label={t('additionalInfo.tabs.description')}
                href={TAB_HREFS[TABS.DESCRIPTION]}
                data-testid="tab-description"
                disableRipple
              />
            )}
            {hasNutrition && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.NUTRITION}
                label={t('additionalInfo.tabs.nutrition')}
                href={TAB_HREFS[TABS.NUTRITION]}
                data-testid="tab-nutrition"
                disableRipple
              />
            )}
            {ingredients && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.INGREDIENTS}
                label={t('additionalInfo.tabs.ingredients')}
                href={TAB_HREFS[TABS.INGREDIENTS]}
                data-testid="tab-ingredients"
                disableRipple
              />
            )}
            {alcohol?.alcoholGrade && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.ALCOHOL}
                label={t('additionalInfo.tabs.alcoholInfo')}
                href={TAB_HREFS[TABS.ALCOHOL]}
                data-testid="tab-alcohol"
                disableRipple
              />
            )}
            {hasInstructionInfo && (
              <Tab
                className={styles.tab}
                tabIndex={0}
                value={TABS.INSTRUCTIONS}
                label={t('additionalInfo.tabs.instructions')}
                href={TAB_HREFS[TABS.INSTRUCTIONS]}
                data-testid="tab-instructions"
                disableRipple
              />
            )}
          </MuiTabs>
        </div>
        <TabPanel value={value} index={TABS.DESCRIPTION}>
          <DescriptionTabContent
            description={description}
            extraDetails={extraDetails}
            allergens={allergens}
            instructions={instructions}
            productQualityNote={productQualityNote}
          />
        </TabPanel>
        <TabPanel value={value} index={TABS.NUTRITION}>
          {(nutrition || oldNutritionContent?.length) && (
            <NutritionTable nutrition={nutrition} oldNutritionContent={oldNutritionContent} />
          )}
        </TabPanel>
        <TabPanel value={value} index={TABS.INGREDIENTS}>
          {ingredients && <div dangerouslySetInnerHTML={setInnerHtml(ingredients)} />}
        </TabPanel>
        <TabPanel value={value} index={TABS.INSTRUCTIONS}>
          {link && (
            <Link legacyBehavior href={link} target="_blank" rel="noreferrer">
              {t('additionalInfo.download')}
            </Link>
          )}
        </TabPanel>
        <TabPanel value={value} index={TABS.ALCOHOL}>
          <div className={styles.tab_alcohol_wrapper}>{memoizedAlcoholData}</div>
        </TabPanel>
      </>
    </ModalWindow>
  );
};
