import { Typography } from '@mui/material';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { GiftCardTermsModal } from '@components/Modals/GiftCardModals/GiftCardTermsModal/GiftCardTermsModal';
import { useModal } from '@hooks/common';
import styles from './GiftCardDisclaimer.module.scss';

export const GiftCardDisclaimer = () => {
  const { t } = useLocalization();
  const { openModal, closeModal, isOpen } = useModal();

  return (
    <>
      <Typography variant="body" component="p">
        {t('giftCardDisclaimer.title')}
      </Typography>
      <Button className={styles.btn} variant="text" onClick={openModal}>
        {t('giftCardDisclaimer.btnTitle')}
      </Button>
      <GiftCardTermsModal opened={isOpen} onClose={closeModal} />
    </>
  );
};
