import { makeVar, useReactiveVar } from '@apollo/client';

type OrderModalState = 'MODIFYING' | 'PENDING' | '';

export const orderModalStateVar = makeVar<OrderModalState>('');

export const useOrderModalState = () => {
  const setOrderModalState = (state: OrderModalState) => {
    orderModalStateVar(state);
  };
  const orderModalState = useReactiveVar(orderModalStateVar);

  return { orderModalState, setOrderModalState };
};
