import createCache from '@emotion/cache';

const isBrowser = typeof document !== 'undefined';

export function createEmotionCache() {
  let insertionPoint;

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector<HTMLMetaElement>(
      'meta[name="emotion-insertion-point"]',
    );
    insertionPoint = emotionInsertionPoint ?? undefined;
  }

  // documentation for createCache https://github.com/emotion-js/emotion/blob/main/packages/sheet/README.md
  return createCache({
    key: 'fd-styles',
    insertionPoint,
  });
}
