import { Typography } from '@mui/material';
import { IconButton, Checkbox } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { trash as TrashIcon } from '@assets/icons/system';
import { getPrice } from '@utils/getPrice';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ReceivedGiftCard } from '@commons/giftCards';
import { useModal } from '@hooks/common';
import { useDeleteRecivedGiftCard } from '@hooks/giftCard/useDeleteRecivedGiftCard';
import { useApplyGiftCard } from '@hooks/giftCard/useApplyGiftCard';
import { responseErrorHandler } from '@utils/responseErrorHandler';
import { getGiftCardFormattingDate } from '../../helpers';
import { DeleteReceivedGiftCardModal } from '../DeleteReceivedGiftCardModal/DeleteReceivedGiftCardModal';
import styles from './GiftCard.module.scss';

interface GiftCardProps {
  card: ReceivedGiftCard;
}

export const GiftCard = ({ card }: GiftCardProps) => {
  const cancelConfirmationModal = useModal();
  const [deleteRecivedGiftCard] = useDeleteRecivedGiftCard();
  const [applyGiftCard] = useApplyGiftCard(!card.applied, card.id);
  const { dispatchAlert } = useAccountAlertContext();
  const { t } = useLocalization('account');
  const isEmptyGiftCard = card?.currentAmount?.value === 0;
  const isCancelledGiftCard = !card?.redeemable;
  const activeGiftCard = !isEmptyGiftCard && !isCancelledGiftCard;

  const currentAmount = getPrice(card.currentAmount.value, card.currentAmount.currency);
  const initialAmount = getPrice(card.initialAmount.value, card.initialAmount.currency);

  const handleDeleteGiftCard = () => {
    deleteRecivedGiftCard({
      variables: {
        receivedCardId: card.id,
        returnAllCards: true,
      },
      onCompleted: () => cancelConfirmationModal.closeModal(),
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });
  };

  const handleApplyToOrderGiftCard = () => {
    applyGiftCard({
      variables: {
        giftCardNum: card.id,
        apply: !card.applied,
      },
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });
  };

  return (
    <>
      <div className={activeGiftCard ? styles.giftCardWrapper : styles.giftCardWrapperEmpty}>
        <div className={styles.giftCardFlexWrapper}>
          <div id={t('giftCards.ariaLabelBalance', { giftCardId: card.id })}>
            <Typography className={styles.giftCardFrom} variant="body">
              {t('giftCards.giftCardFrom', { senderName: card.senderName })}
            </Typography>
            <div className={styles.giftCardFromInfo}>
              {getGiftCardFormattingDate(card.purchaseDate)}, #{card.id}
            </div>
          </div>
          <div className={styles.flexWrapper}>
            <div className={activeGiftCard ? styles.giftCardAmount : styles.giftCardAmountEmpty}>
              {`${currentAmount} / ${initialAmount}`}
            </div>
            <IconButton
              data-testid="delete-button"
              size="medium"
              onClick={cancelConfirmationModal.openModal}
              aria-describedby={t('giftCards.ariaLabelBalance', { giftCardId: card.id })}
            >
              <TrashIcon className={styles.icon} />
              <span className="sr_only">Delete</span>
            </IconButton>
          </div>
        </div>
        <Checkbox
          id={card.id}
          onChange={handleApplyToOrderGiftCard}
          isChecked={card.applied && !isEmptyGiftCard}
          isDisabled={isEmptyGiftCard || isCancelledGiftCard}
          className={styles.checkbox}
        >
          <Typography variant="smallBody" component="p">
            {t('giftCards.applyToOrder')}
          </Typography>
        </Checkbox>
      </div>
      <DeleteReceivedGiftCardModal
        opened={cancelConfirmationModal.isOpen}
        onClose={cancelConfirmationModal.closeModal}
        onDelete={handleDeleteGiftCard}
      />
    </>
  );
};
