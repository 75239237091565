import { routing } from '@constants/routing';
import { setHasAccessToAccountPages } from './useHasAccessToAccountPages';

const routerHistory: string[] = [];

export const updateHistory = (nextRoute: string) => {
  if (routerHistory.length === 0 || routerHistory[routerHistory.length - 1] !== nextRoute) {
    routerHistory.push(nextRoute);
    if (!nextRoute.includes(routing.account)) {
      setHasAccessToAccountPages(false);
    }
  }
};

export const isEmptyRouterHistory = () => {
  return routerHistory.length <= 1;
};

export const getRouterHistory = () => {
  return routerHistory;
};

export const getPreviousPage = () => {
  if (routerHistory.length > 1) {
    const currentPage = routerHistory[routerHistory.length - 1];
    const previousPage = routerHistory[routerHistory.length - 2];
    if (currentPage !== previousPage) {
      return previousPage;
    }
  }

  return routing.home;
};
