import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const VIEWED_FEATURE_IMPRESSIONS = gql`
  mutation ViewedFeatureImpressions($feature: String!) {
    viewedFeatureImpressions(feature: $feature) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
