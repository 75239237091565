import { usePaymentAvailabilities } from '@hooks/payments/usePaymentAvailabilities';
import { Payment, PAYMENT_TYPE } from '@commons/payment';

export const useFilterPayments = (payments: Payment[]) => {
  const {
    data: { showEbt },
  } = usePaymentAvailabilities();

  return payments?.filter((p) => !(!showEbt && p.type === PAYMENT_TYPE.EBT));
};
