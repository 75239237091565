import { useReactiveVar } from '@apollo/client';
import { cartPartialDisabledVar } from '@graphql/variables/cartPartialDisabledVar';

export const usePartialDisableCartPage = () => {
  const disabled = useReactiveVar(cartPartialDisabledVar);

  return {
    disabled,
    enable: () => cartPartialDisabledVar(false),
    disable: () => cartPartialDisabledVar(true),
  };
};
