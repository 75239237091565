import omit from 'lodash/omit';
import {
  getProductMetrics,
  ProductMetrics,
} from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { CartLight } from '@commons/cart';
import { getFormattedProductPrice } from '@modules/ga/utils/getFormattedProductPrice';

interface EcomCart {
  ecommerce: {
    cart: {
      products: Omit<ProductMetrics, 'list' | 'position'>[];
    };
  };
}

export const getEcomCart = (cart: CartLight): EcomCart => ({
  ecommerce: {
    cart: {
      products: cart.cartLines.map((cartLine) => {
        const productMetrics = omit(
          getProductMetrics({
            ...cartLine,
            price: getFormattedProductPrice({
              price: cartLine.price,
              quantity: cartLine.quantitySelector.quantity,
              displayPrice: cartLine.displayPrice.value,
            }),
          }),
          ['list', 'position'],
        );

        return {
          ...productMetrics,
          lineItemTotal: cartLine.displayPrice?.value,
        };
      }),
    },
  },
});
