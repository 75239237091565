import cx from 'classnames';
import { Typography } from '@mui/material';
import { Fragment } from 'react';
import { check as CheckIcon } from '@assets/icons/system';
import { Button } from '@components/UI';
import {
  Address,
  ALT_DELIVERY_OPTION,
  DeliveryPhoneNumber,
  SERVICE_TYPE,
} from '@commons/deliveryAddresses';
import { useLocalization } from '@hooks/useLocalization';
import { KEYBOARD_CODE } from '@constants/keyboard';
import styles from './DeliveryCard.module.scss';

export interface DeliveryCardProps {
  address: Address;
  phone: DeliveryPhoneNumber[];
  details: ALT_DELIVERY_OPTION | null;
  isActive: boolean;
  isSingleAddress: boolean;
  onClick: (addressId: Address['id']) => void;
  onEdit: () => void;
  onDelete: (addressId: Address['id']) => void;
  className?: string;
  expressEnabled: boolean;
  disabled: boolean;
  customInstruction?: string | null;
  backupInstruction?: string | null;
  companyName: string | null;
  fullName: string | null;
}

export const DeliveryCard = ({
  address,
  phone,
  details,
  isActive,
  isSingleAddress,
  onClick,
  onEdit,
  onDelete,
  className,
  disabled,
  customInstruction,
  backupInstruction,
  companyName,
  fullName,
}: DeliveryCardProps) => {
  const { t } = useLocalization('account');
  const wrapperClasses = cx(styles.wrapper, { [styles.active]: isActive }, className);
  const apartmentsText = address.apartment ? `, ${address.apartment}` : '';
  const isCorporateAddress = address.serviceType === SERVICE_TYPE.CORPORATE;
  const isCompanyNameVisible = isCorporateAddress && companyName;
  const showDeliveryInstructions = !(isCorporateAddress && !customInstruction);

  const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KEYBOARD_CODE.ENTER) onClick(address?.id);
  };
  const handleClick = () => {
    if (!disabled) onClick(address?.id);
  };
  const handleDelete = () => onDelete(address?.id);

  const getDeliveryInstructionDetail = () => {
    const { NONE, UNATTENDED } = ALT_DELIVERY_OPTION;
    const hideDeliveryOption = customInstruction && details === NONE;

    if (hideDeliveryOption) {
      return `"${customInstruction}"`;
    }

    if (details === UNATTENDED) {
      return (
        <div className={styles.instructions}>
          <span>{t(`account:delivery.addressModal.unattendedSpecialInstructions`)}</span>
          <span>{backupInstruction ? `"${backupInstruction}"` : ''}</span>
          <span>{customInstruction ? `"${customInstruction}"` : ''}</span>
        </div>
      );
    }

    return (
      <>
        {t(`account:delivery.addressModal.deliveryCardOptions.${details}`)}
        <br />
        {customInstruction ? `"${customInstruction}"` : ''}
      </>
    );
  };

  return (
    <div className={cx(styles.wrapper, wrapperClasses)} data-testid="delivery-card">
      {disabled && <div className={styles.disabled_card} />}
      <div
        onKeyDown={keyDownHandler}
        tabIndex={disabled ? -1 : 0}
        role="radio"
        aria-checked={isActive}
        onClick={handleClick}
        aria-label={`${
          isActive
            ? t('delivery.card.ariaLabels.selectedCurrentData')
            : t('delivery.card.ariaLabels.selectCurrentData')
        } ${address.address1} ${apartmentsText} ${address.city}, ${address.state} ${
          address.zipCode
        }`}
        className={styles.content}
        data-testid={t('delivery.card.ariaLabels.selectCurrentData')}
      >
        {isCompanyNameVisible && (
          <div className={styles.company_name}>
            <Typography
              aria-label={t('delivery.card.ariaLabels.companyName')}
              variant="body"
              component="p"
              fontWeight="bold"
            >
              {companyName}
            </Typography>
          </div>
        )}
        <p aria-label={t('delivery.card.ariaLabels.fullName')} className={styles.name}>
          {fullName}
        </p>
        <p aria-label={t('delivery.card.ariaLabels.address')} className={styles.address}>
          {`${address.address1}${apartmentsText}`}
        </p>
        <p aria-label={t('delivery.card.ariaLabels.address')} className={styles.address}>
          {`${address.city}, ${address.state} ${address.zipCode}`}
        </p>
        <p aria-label={t('delivery.card.ariaLabels.phone')} className={styles.phone}>
          {phone.map((phoneItem, index) => (
            <Fragment key={`${phoneItem.phone}-${phoneItem.extension}-${index}`}>
              {phoneItem.phone}{' '}
              {phoneItem.extension && `${t('delivery.card.ariaLabels.ext')} ${phoneItem.extension}`}
            </Fragment>
          ))}
        </p>
        {showDeliveryInstructions && (
          <p aria-label={t('delivery.card.ariaLabels.details')} className={styles.details}>
            {getDeliveryInstructionDetail()}
          </p>
        )}
        {isActive && <CheckIcon className={styles.check} data-testid="active-delivery" />}
      </div>
      <div>
        {onEdit && (
          <Button
            data-testid="edit_delivery_address"
            className={styles.button}
            aria-label={`${t('delivery.editAddressLabel')} ${address.address1}${apartmentsText} ${
              address.city
            }, ${address.state}, ${address.zipCode}`}
            onClick={onEdit}
            variant="underline"
          >
            {t('delivery.edit')}
          </Button>
        )}
        {!isSingleAddress && (
          <>
            <Button
              className={styles.button}
              aria-label={`${t('delivery.deleteAddressLabel')} ${
                address.address1
              }${apartmentsText} ${address.city}, ${address.state}, ${address.zipCode}`}
              onClick={handleDelete}
              variant="underline"
            >
              {t('delivery.delete')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
