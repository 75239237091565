export interface CustomerData {
  deliveryPassType: string;
  deliveryPassTerm: string;
  expressEligible: boolean;
}

interface CustomerDataProps {
  name: string;
  expressEligible: boolean;
}

export type GetDeliveryPass = (p: { wasMembershipActivated?: boolean }) => string;
export const getDeliveryPass: GetDeliveryPass = ({ wasMembershipActivated }) =>
  wasMembershipActivated ? 'true' : 'false';

export const getDeliveryPassTerm = (deliveryPassType: string) => {
  if (deliveryPassType.includes('One-Month')) {
    return '1 month';
  }
  if (deliveryPassType.includes('Two-Month')) {
    return '2 months';
  }
  if (deliveryPassType.includes('Six-Month')) {
    return '6 months';
  }
  if (deliveryPassType.includes('One-Year')) {
    return '1 year';
  }
  return '';
};

export const getDeliveryPassType = (deliveryPassProductName: string) => {
  if (!deliveryPassProductName) {
    return '';
  }
  return deliveryPassProductName.includes('Midweek') ? 'midweek' : 'fullweek';
};

export const getCustomerData = ({ name, expressEligible }: CustomerDataProps): CustomerData => {
  return {
    deliveryPassType: getDeliveryPassType(name),
    deliveryPassTerm: getDeliveryPassTerm(name),
    expressEligible,
  };
};
