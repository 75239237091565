import omit from 'lodash/omit';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { Typography } from '@mui/material';
import { BaseTile } from '@components/Timeslots/BaseTile/BaseTile';
import { useLocalization } from '@hooks/useLocalization';
import { GiftCardAmountTilesSkeleton } from '@components/Skeletons/GiftCardAmountTilesSkeleton/GiftCardAmountTilesSkeleton';
import { CUSTOM_AMOUNT_INPUT_VALUE } from '../../constants';
import { GIFTCARD_FIELDS, Values } from '../../types';
import styles from './AmountTiles.module.scss';

interface GiftCardAmountTilesProps {
  amountOptions: string[];
  loadingAmountOptions: boolean;
  onClickCustomAmount: () => void;
}

export const GiftCardAmountTiles = ({
  amountOptions,
  loadingAmountOptions,
  onClickCustomAmount,
}: GiftCardAmountTilesProps) => {
  const { t } = useLocalization('giftCards');
  const { values, setFieldValue, setErrors, errors } = useFormikContext<Values>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFieldValue(GIFTCARD_FIELDS.GIFTCARD_DEFAULT_AMOUNT, value);
    setFieldValue(GIFTCARD_FIELDS.GIFTCARD_AMOUNT, value);
    setFieldValue(GIFTCARD_FIELDS.GIFTCARD_CUSTOM_AMOUNT, '');

    const shouldUnsetGiftCartError = value && errors[GIFTCARD_FIELDS.GIFTCARD_AMOUNT];
    if (shouldUnsetGiftCartError) {
      setErrors({ ...omit(errors, [GIFTCARD_FIELDS.GIFTCARD_AMOUNT]) });
    }
  };

  const handleClick = (amount: string) => () => {
    const shouldUnsetGiftCartAmount = values[GIFTCARD_FIELDS.GIFTCARD_DEFAULT_AMOUNT] === amount;
    if (shouldUnsetGiftCartAmount) {
      setFieldValue(GIFTCARD_FIELDS.GIFTCARD_DEFAULT_AMOUNT, '');
      setFieldValue(GIFTCARD_FIELDS.GIFTCARD_AMOUNT, '');

      setErrors({
        ...errors,
        [GIFTCARD_FIELDS.GIFTCARD_AMOUNT]: t('giftCardModal.errors.giftCardAmount'),
      });
    }
  };

  if (loadingAmountOptions) {
    return <GiftCardAmountTilesSkeleton />;
  }

  if (amountOptions && amountOptions.length !== 0) {
    return (
      <div className={styles.tiles} role="radiogroup" aria-labelledby="amount-tile-radio-container">
        {amountOptions.map((amount) => (
          <BaseTile
            wrapperClassName={styles.label_wrapper}
            inputClassName={styles.input}
            containerClassName={styles.tile}
            contentClassName={styles.tile_content}
            key={amount}
            id={amount}
            value={amount}
            name="giftCardAmount"
            onChange={handleChange}
            onClick={handleClick(amount)}
            checked={amount === values[GIFTCARD_FIELDS.GIFTCARD_DEFAULT_AMOUNT]}
          >
            <Typography variant="body" component="p" className={styles.tile_text}>
              ${Number(amount)}
            </Typography>
          </BaseTile>
        ))}
        <BaseTile
          wrapperClassName={styles.label_wrapper}
          inputClassName={styles.input}
          containerClassName={styles.tile}
          contentClassName={styles.tile_content}
          id={CUSTOM_AMOUNT_INPUT_VALUE}
          value={CUSTOM_AMOUNT_INPUT_VALUE}
          name="giftCardAmount"
          checked={CUSTOM_AMOUNT_INPUT_VALUE === values[GIFTCARD_FIELDS.GIFTCARD_DEFAULT_AMOUNT]}
          onClick={onClickCustomAmount}
        >
          <Typography variant="body" component="p" className={styles.custom_amount_subtitle}>
            {t('giftCardModal.customTileText')}
          </Typography>
        </BaseTile>
      </div>
    );
  }

  return (
    <Typography variant="h5" className={styles.fallback_subtitle}>
      {t('giftCardModal.noAmountTiles')}
    </Typography>
  );
};
