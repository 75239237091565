import { getPrice } from '@utils/getPrice';

export const calculateRemainingOrderPrice = ({
  current,
  min,
  currency,
}: {
  current: number;
  min: number;
  currency: string;
}) => {
  const remainingPrice =
    current > min ? 0 : (Number(min.toFixed(2)) * 100 - Number(current.toFixed(2)) * 100) / 100;

  return getPrice(remainingPrice, currency);
};
