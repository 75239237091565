import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { DepartmentItem, SuperDepartmentItem } from '@api';
import { TabPanel } from '@components/UI/Tabs/TabPanel';
import { isSuperDepartmentItem } from '../../../../isSuperDepartmentItem';
import { CategoriesSection } from '../CategoriesSection';
import styles from './NavTabs.module.scss';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

interface NavTabsProps {
  superDepartment: SuperDepartmentItem;
  onClick?: () => void;
}

export const NavTabs = ({ superDepartment: { departments }, onClick }: NavTabsProps) => {
  const { push: redirect } = useRouter();
  const [tab, setTab] = useState(0);
  const changeTab = useCallback((index: number) => setTab(index), []);
  const filteredDepartments = useMemo(
    () =>
      (departments?.filter(
        (department) => Boolean(department) && !isSuperDepartmentItem(department),
      ) || []) as Array<DepartmentItem>,
    [departments],
  );

  const handleClick = (url: string) => {
    onClick?.();
    redirect(url);
  };

  return (
    <div style={{ width: '100%' }}>
      <Tabs
        className={styles.tab_titles}
        value={tab}
        onChange={(_, index) => changeTab(index)}
        aria-label="tab-buttons"
        centered
      >
        {filteredDepartments.map(({ name, id, url }, index) => {
          return (
            <Tab
              {...a11yProps(index)}
              className={cx(styles.title)}
              key={id}
              label={name}
              tabIndex={-1}
              onMouseOver={() => setTab(index)}
              onClick={() => handleClick(url)}
              onFocus={() => setTab(index)}
              disableRipple
              data-testid="nav-tab"
            />
          );
        })}
      </Tabs>
      {filteredDepartments.map((department, index) => (
        <TabPanel key={department.id} value={tab} index={index}>
          <CategoriesSection department={department} onClick={onClick} />
        </TabPanel>
      ))}
    </div>
  );
};
