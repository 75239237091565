import { useRef, useState } from 'react';
import { CriteoButterfly as ICriteoButterfly } from '@commons/criteoBanner';
import { getProductKey } from '@components/ProductsGrid/getProductKey';
import { Grid } from '@components/UI/Grid/Grid';
import { useCriteoBeacon } from '@hooks/criteo/useCriteoBeacon';
import { ButterflyProductButton } from '@components/Tiles/ButterflyProductTile/components/ButterflyProductButton';
import { ButterflyFooterText } from '@components/Tiles/ButterflyProductTile/components/ButterflyFooterText';
import { ButterflyFooterButton } from '@components/Tiles/ButterflyProductTile/components/ButterflyFooterButton';
import { MainProductTile } from '@components/Tiles/MainProductTile';
import { ProductTile as IProductTile } from '@commons/product';
import { CriteoFormatBeacon } from '@modules/criteo';
import styles from './ButterflyProductTile.module.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ButterflyProductTileProps {
  criteoButterfly: ICriteoButterfly;
  size: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  gridItemProps?: any;
  customTileComponent?: (p: IProductTile) => JSX.Element;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  isV2?: boolean;
}
/* eslint-enable */

export const ButterflyProductTile = ({
  criteoButterfly,
  size,
  customTileComponent,
  criteoFormatBeaconView,
  isV2 = false,
}: ButterflyProductTileProps) => {
  const bannerRef = useRef(null);
  const [currentProduct, setCurrentProduct] = useState(0);
  const { handleClick: handleCriteoProductClick } = useCriteoBeacon({
    onClickBeacon: criteoButterfly.products[currentProduct].onClickBeacon,
  });
  const { handleClick } = useCriteoBeacon({
    onClickBeacon: criteoButterfly.onClickBeacon,
    onLoadBeacon: criteoButterfly.onLoadBeacon,
    onViewBeacon: criteoButterfly.onViewBeacon,
    componentRef: bannerRef,
    criteoFormatBeaconView,
  });

  const {
    optionalFooterBackgroundColor,
    optionalFooterTextColor,
    optionalFooterText,
    optionalFooterRedirectTarget,
    optionalFooterRedirectUrl,
  } = criteoButterfly?.info;

  return (
    <Grid item className={styles.wrapper}>
      <Grid
        item
        className={styles.banner}
        style={{ backgroundImage: `url(${criteoButterfly.info?.backgroundImage})` }}
        {...size}
      >
        <div className={styles.safety_zone} />
        <div className={styles.banner_product_container} ref={bannerRef}>
          {criteoButterfly.products.map((item, index) => (
            <ButterflyProductButton
              index={index}
              criteoButterfly={criteoButterfly}
              currentProduct={currentProduct}
              handleClick={() => setCurrentProduct(index)}
              key={`criteo-${getProductKey(item.productData)}`}
            />
          ))}
        </div>
        {criteoButterfly.info?.optionalFooterRedirectUrl ? (
          <ButterflyFooterButton
            handleClick={handleClick}
            optionalFooterBackgroundColor={optionalFooterBackgroundColor}
            optionalFooterTextColor={optionalFooterTextColor}
            optionalFooterText={optionalFooterText}
            optionalFooterRedirectTarget={optionalFooterRedirectTarget}
            optionalFooterRedirectUrl={optionalFooterRedirectUrl}
          />
        ) : (
          <ButterflyFooterText
            optionalFooterText={optionalFooterText}
            optionalFooterTextColor={optionalFooterTextColor}
            optionalFooterBackgroundColor={optionalFooterBackgroundColor}
          />
        )}
      </Grid>
      {!!criteoButterfly.products[currentProduct] && (
        <Grid
          data-testid={criteoButterfly.products[currentProduct].productData.productId}
          item
          {...size}
        >
          <MainProductTile
            product={criteoButterfly.products[currentProduct].productData}
            onClickCriteo={handleCriteoProductClick}
            customTileComponent={customTileComponent}
            criteoFormatBeaconView={criteoFormatBeaconView}
            isV2={isV2}
          />
        </Grid>
      )}
    </Grid>
  );
};
