import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import styles from './AddNewPaymentMobile.module.scss';

interface AddNewPaymentMobileProps {
  onClick: () => void;
  hasPayments: boolean;
}

export const AddNewPaymentMobile = ({ onClick, hasPayments }: AddNewPaymentMobileProps) => {
  const { t } = useLocalization('account');

  return (
    <div className={styles.button_wrapper}>
      <Button
        variant={hasPayments ? 'outlined' : 'contained'}
        size="large"
        className={styles.button}
        onClick={onClick}
        fullWidth
      >
        {t('payments.addNewPayment')}
      </Button>
    </div>
  );
};
