import { useMutation } from '@apollo/client';
import { AddGiftCardToCartMutation, AddGiftCardToCartMutationVariables } from '@api';
import { ADD_GIFT_CART_TO_CART } from '@graphql/giftCard/mutations/addGiftCardToCart';
import { GET_GIFT_CARDS_CART_LINES_COUNT } from '@graphql/giftCard/queries/giftCardsCartLinesCount';
import { GET_GIFT_CART } from '@graphql/cart/queries/getGiftCart';

export const useAddGiftCardToCart = () => {
  return useMutation<AddGiftCardToCartMutation, AddGiftCardToCartMutationVariables>(
    ADD_GIFT_CART_TO_CART,
    {
      refetchQueries: [{ query: GET_GIFT_CARDS_CART_LINES_COUNT }],
      update(cache, { data }) {
        if (data) {
          cache.writeQuery({
            query: GET_GIFT_CART,
            data: { giftCardsCart: data.addToGiftCardsCart.cart },
          });
        }
      },
    },
  );
};
