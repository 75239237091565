import { gql } from '@apollo/client';
import { PRODUCT_BASIC_FRAGMENT } from '@graphql/fragments/product/productBasicFragment';

export const ATP_LINE_FRAGMENT = gql`
  fragment ATPLineFragment on ATPLine {
    availableQuantity
    cartLineId
    recommendedProducts {
      ...productBasicFragment
    }
    description
  }
  ${PRODUCT_BASIC_FRAGMENT}
`;
