import { gql } from '@apollo/client';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';
import { ATP_LINE_FRAGMENT } from '@graphql/fragments/cart/common/ATPLine';

export const VALIDATION_RESULT_FRAGMENT = gql`
  fragment validationResultFragment on ValidationResult {
    success
    errors {
      ...validationErrorFragment
    }
    atpErrors {
      deliveryDate
      deliveryTimeSlot
      notMetMinAmount
      nonReplaceableLines {
        ...ATPLineFragment
      }
      replaceableLines {
        ...ATPLineFragment
      }
    }
  }
  ${VALIDATION_ERROR_FRAGMENT}
  ${ATP_LINE_FRAGMENT}
`;
