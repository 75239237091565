import { gql } from '@apollo/client';

const NUTRITION_ITEM_FRAGMENT = gql`
  fragment nutritionItemFragment on NutritionItem {
    id
    displayName
    priority
    value
    uom
    lessThan
    dailyUom
    dailyValue
    dailyLessThan
  }
`;

export const NUTRITION_FRAGMENT = gql`
  fragment nutritionFragment on Nutrition {
    panelType
    columns
    meta {
      type
      item {
        ...nutritionItemFragment
      }
    }
    main {
      ...nutritionItemFragment
    }
    extra {
      ...nutritionItemFragment
    }
  }
  ${NUTRITION_ITEM_FRAGMENT}
`;

export const NUTRITION_V2_FRAGMENT = gql`
  fragment nutritionV2Fragment on NutritionV2 {
    id
    skuCode
    lastModifiedDate
    section {
      id
      title
      position
      columnPosition
      importance
      type
      item {
        id
        value1
        value2
        ingredientValue
        uom
        position
        bulleted
        important
        newLine
        separator
      }
    }
    type
  }
`;
