import Image from 'next/image';
import { ProductExtraDetails } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import { Link } from '@components/UI';
import styles from './DescriptionTabContent.module.scss';
import { DescriptionTabContentItem } from './components/DescriptionTabContentItem/DescriptionTabContentItem';

interface DescriptionTabContentProps {
  description?: string | null;
  instructions: string | null;
  allergens: string | null;
  extraDetails: ProductExtraDetails;
  productQualityNote: string | null;
}

export const DescriptionTabContent = ({
  description,
  extraDetails,
  instructions,
  allergens,
  productQualityNote,
}: DescriptionTabContentProps) => {
  const { t } = useLocalization('product');
  const {
    claims,
    organicClaims,
    kosherSymbol,
    kosherType,
    kosherIconPath,
    productDescriptionNote,
    origin,
    seasonText,
    productAboutMediaPath,
  } = extraDetails;
  const isKosher = kosherSymbol || kosherType;
  return (
    <>
      {description && <span dangerouslySetInnerHTML={{ __html: description }} />}
      <DescriptionTabContentItem title={t('descriptionTab.claims')} value={claims} />
      <DescriptionTabContentItem
        title={t('descriptionTab.heatingInstructions')}
        value={instructions}
      />
      <DescriptionTabContentItem
        title={t('descriptionTab.organicStatements')}
        value={organicClaims}
      />
      {isKosher && (
        <div className={styles.kosher}>
          {kosherIconPath && (
            <div className={styles.kosher_icon}>
              <Image layout="fill" objectFit="contain" src={kosherIconPath} alt="Kosher icon" />
            </div>
          )}
          {kosherType}
        </div>
      )}
      <DescriptionTabContentItem value={productDescriptionNote} />
      <DescriptionTabContentItem value={productQualityNote} />
      {productAboutMediaPath && (
        <Link legacyBehavior href={productAboutMediaPath} className={styles.link} target="_blank">
          {t('descriptionTab.productAboutMediaLink')}
        </Link>
      )}
      <DescriptionTabContentItem title={t('descriptionTab.origin')} value={origin} />
      <DescriptionTabContentItem value={seasonText} />
      <DescriptionTabContentItem
        title={t('descriptionTab.allergens')}
        value={allergens}
        contentClassName={styles.allergens}
      />
    </>
  );
};
