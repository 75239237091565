import { fireOpenModal } from '../fireOpenModal';
import { MODALS_LIST } from '../../types/core';
import { fireCloseModal } from '../fireCloseModal';
import { SampleProductsModalProps } from '../../types/sampleProductsModal/sampleProductsModal';

export const openSampleProductsModal = (props: SampleProductsModalProps) => {
  fireOpenModal(MODALS_LIST.SAMPLE_PRODUCTS_MODAL, { ...props });
};

export const closeSampleProductsModal = () => {
  fireCloseModal(MODALS_LIST.SAMPLE_PRODUCTS_MODAL);
};
