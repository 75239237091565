import { default as NextLink, LinkProps } from 'next/link';

export interface BaseLinkProps extends LinkProps {
  children?: React.ReactNode;
  className?: string;
}

export const BaseLink = ({ children, ...restProps }: BaseLinkProps) => {
  return (
    <NextLink legacyBehavior {...restProps} prefetch={false}>
      {children}
    </NextLink>
  );
};
