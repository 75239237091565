import { ReactNode } from 'react';
import localFont from 'next/font/local';

interface FontsWrapperProps {
  children: ReactNode;
}

export const customFonts = localFont({
  src: [
    {
      path: '../../../public/static/next/fonts/CircularStd-Book.woff2',
      style: 'normal',
      weight: '400',
    },
    {
      path: '../../../public/static/next/fonts/CircularStd-BookItalic.woff2',
      style: 'italic',
      weight: '400',
    },
    {
      path: '../../../public/static/next/fonts/CircularStd-Medium.woff2',
      style: 'normal',
      weight: '500',
    },
    {
      path: '../../../public/static/next/fonts/CircularStd-MediumItalic.woff2',
      style: 'italic',
      weight: '500',
    },
    {
      path: '../../../public/static/next/fonts/CircularStd-Bold.woff2',
      style: 'normal',
      weight: '700',
    },
    {
      path: '../../../public/static/next/fonts/CircularStd-BoldItalic.woff2',
      style: 'italic',
      weight: '700',
    },
    {
      path: '../../../public/static/next/fonts/CircularStd-Black.woff2',
      style: 'normal',
      weight: '900',
    },
    {
      path: '../../../public/static/next/fonts/CircularStd-BlackItalic.woff2',
      style: 'italic',
      weight: '900',
    },
  ],
  display: 'swap',
  variable: '--font-circular-std',
});

export const FontsWrapper = ({ children }: FontsWrapperProps) => {
  return <div className={customFonts.variable}>{children}</div>;
};
