import { FC } from 'react';
import { Button, ButtonProps } from '@components/UI/Button/Button';
import styles from './SubmitButton.module.scss';

export const SubmitButton: FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button {...rest} size="large" type="submit" className={styles.button} isFullWidth>
      {children}
    </Button>
  );
};
