import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const ADD_TO_CART = gql`
  mutation AddToCart($addToCartInput: AddToCartDataInput!) {
    addToCart(addToCartInput: $addToCartInput) {
      ...cartFragment
      atcEventIds
    }
  }
  ${CART_FRAGMENT}
`;

export const ADD_TO_CART_PROTECTED = gql`
  mutation AddToCartProtected($addToCartInput: AddToCartDataInput!) {
    addToCart(addToCartInput: $addToCartInput) {
      ...cartFragment
      atcEventIds
      signedUser
    }
  }
  ${CART_FRAGMENT}
`;
