import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { gql, Operation } from '@apollo/client';
import { AUTH_OPERATIONS } from '@modules/auth/constants';
import { logger } from '@logger';
import { getGQLOperationNameFromDocumentNode } from '@graphql/links/utils';

export const batchHttpLink = () =>
  new BatchHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    fetch: customFetch,
    credentials: 'include',
    batchKey: (operation: Operation) => {
      return Object.values<string>(AUTH_OPERATIONS).includes(operation.operationName)
        ? 'batch1'
        : 'batch2';
    },
  });

async function customFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  let operationNames: string[] = [];

  if (typeof init?.body !== 'string') {
    logger.debug('impossible to parse body');
  } else {
    try {
      const parsedBody = JSON.parse(init.body);
      operationNames = parsedBody.map((operation: { query: string }) =>
        getGQLOperationNameFromDocumentNode(
          gql`
            ${operation.query}
          `,
        ),
      );
    } catch (e) {
      logger.debug(e);
    }
  }

  const sortInAlphabeticalOrder = (a: string, b: string) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  };

  const sortedOperationNames = [...operationNames].sort(sortInAlphabeticalOrder);

  const headers = init?.headers
    ? {
        ...init.headers,
        gqloperation: sortedOperationNames.join(','),
      }
    : {};

  return fetch(input, { ...init, headers });
}
