import { gql } from '@apollo/client';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';
import { DELIVERY_ADDRESS_FRAGMENT } from '@graphql/fragments/account/deliveryAddress';

export const SET_SO_DEFAULT_DELIVERY_ADDRESS = gql`
  mutation SetDefaultSODeliveryAddressV2(
    $id: ID!
    $accountTab: Boolean = false
    $standingOrderMode: StandingOrderMode
  ) {
    setDefaultDeliveryAddressV2(
      newDefaultAddressId: $id
      accountTab: $accountTab
      standingOrderMode: $standingOrderMode
    ) {
      deliveryAddresses {
        corpAddresses {
          ...deliveryAddressFragment
        }
        selectedAddress {
          ...deliveryAddressFragment
        }
      }
      isUnattendedDelivery
      expressPlantIdChanged
      validationErrors {
        ...validationErrorFragment
      }
      actionResult {
        ...actionResultFragment
      }
    }
  }
  ${DELIVERY_ADDRESS_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
