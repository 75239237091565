import cx from 'classnames';
import { FC } from 'react';
import styles from './CategoriesSection.module.scss';

interface CategoriesSectionDividerProps {
  isShown?: boolean;
}

export const CategoriesSectionDivider: FC<CategoriesSectionDividerProps> = ({
  isShown = false,
  children,
}) => (
  <div
    className={cx(styles.categoriesSection, {
      [styles.divider]: isShown,
    })}
  >
    {children}
  </div>
);
