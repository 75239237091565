import { useMutation } from '@apollo/client';
import { LINK_E_WALLET } from '@graphql/account/mutations/linkEWallet';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';

export const useLinkEWallet = () => {
  return useMutation(LINK_E_WALLET, {
    refetchQueries: [PAYMENT_METHODS],
    update(
      cache,
      {
        data: {
          linkEWallet: { data: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
};
