import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { GetMasqueradeCartQuery, GetMasqueradeCartQueryVariables } from '@api';
import { GET_MASQUERADE_CART } from '@graphql/masquerade/queries/masqueradeCart';

export const useLazyGetMasqueradeCart = (
  options?: QueryHookOptions<GetMasqueradeCartQuery, GetMasqueradeCartQueryVariables>,
) =>
  useLazyQuery<GetMasqueradeCartQuery, GetMasqueradeCartQueryVariables>(
    GET_MASQUERADE_CART,
    options,
  );
