import Typography from '@mui/material/Typography/Typography';
import { useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import cx from 'classnames';
import { Button } from '@components/UI/Button/Button';
import { DropDown } from '@components/UI/DropDown/DropDown';
import { useLocalization } from '@hooks/useLocalization';
import { IComplaintReason } from '@modules/masquerade/types/complaint-reason';
import { useMakeGoodReason } from '@modules/masquerade/hooks/useMakeGoodReason';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { getMakeGoodButton, getMakeGoodTitle } from './MakeGoodControls.utils';
import styles from './MakeGoodControls.module.scss';

interface MakeGoodControllersProps {
  isFree: boolean;
  complaintReason: IComplaintReason | null;
  complaintReasons: IComplaintReason[];
  cartLineId: string;
  cartLineIds: string[];
  isUnavailable?: boolean;
  isModifyOrder?: boolean;
  checkoutClicked?: boolean;
}

export const MakeGoodControls = ({
  isFree,
  complaintReason,
  complaintReasons,
  cartLineId,
  cartLineIds,
  isUnavailable,
  checkoutClicked,
}: MakeGoodControllersProps) => {
  const { t } = useLocalization('cart');
  const [errorShown, setErrorShown] = useState(false);
  const setReason = useMakeGoodReason();
  const hasComplaintReason = !!complaintReason;
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();

  // @TODO: find out better way to inject checking
  useUpdateEffect(() => {
    setErrorShown(!hasComplaintReason);
  }, [hasComplaintReason, checkoutClicked]);

  const handleReasonApply = () => {
    if (!complaintReason) {
      setErrorShown(true);
      return;
    }
    setReason({ cartLineIds, complaintReasonId: complaintReason?.id });
  };

  const handleDropDownChange = async (complaintReasonId: string) => {
    await setReason({
      cartLineIds: [cartLineId],
      complaintReasonId: complaintReasonId,
    });
  };

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.drawer_opened]: isSideBagDrawerOpen,
      })}
    >
      <Typography variant="body" className={styles.title}>
        {t(getMakeGoodTitle(isFree))}
      </Typography>
      <DropDown
        className={styles.dropdown}
        value={complaintReason?.id ?? ''}
        size="mini"
        title={t('makeGood.dropdownTitle')}
        error={errorShown && !isUnavailable ? t('makeGood.notChoosenItemError') : undefined}
        onChange={handleDropDownChange}
        options={complaintReasons?.map(({ id, reason }) => ({ value: id, label: reason }))}
        id="make-good-dropdown"
      />
      <Button
        size="small"
        variant="underline"
        onClick={handleReasonApply}
        className={styles.button}
      >
        <Typography variant="body" className={styles.button}>
          {t(getMakeGoodButton(isFree))}
        </Typography>
      </Button>
      {isFree && <span className={styles.freeItemMark}>F</span>}
    </div>
  );
};
