import { MinimumDeliveryAddress } from '@commons/deliveryAddresses';
import { UserDeliveryAddressesQuery, AnonUserDeliveryAddressesQuery } from '@api';
import { useUserDeliveryAddresses } from '@hooks/deliveryAddress/useUserDeliveryAddresses';
import { addressAdapter } from '@adapters/addressAdapter';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';

const { getMinimumAddress } = addressAdapter();

type Addresses =
  | UserDeliveryAddressesQuery['userDeliveryAddresses']['homeAddresses']
  | UserDeliveryAddressesQuery['userDeliveryAddresses']['corpAddresses']
  | AnonUserDeliveryAddressesQuery['userDeliveryAddresses']['homeAddresses']
  | AnonUserDeliveryAddressesQuery['userDeliveryAddresses']['corpAddresses'];

const getMinimumDeliveryAddresses = (addresses?: Addresses): MinimumDeliveryAddress[] => {
  return addresses
    ? addresses.map(({ address, isTemporary, selected, expressEnabled }) => ({
        isTemporary,
        selected,
        expressEnabled: !!expressEnabled,
        address: getMinimumAddress(address),
      }))
    : [];
};

export const useDeliveryAddresses = () => {
  const standingOrderMode = useStandingOrderMode();
  const { data, ...queryResult } = useUserDeliveryAddresses({
    queryOptions: {
      variables: {
        standingOrderMode,
      },
    },
  });

  return {
    ...queryResult,
    homeAddresses: getMinimumDeliveryAddresses(data?.userDeliveryAddresses.homeAddresses),
    corpAddresses: getMinimumDeliveryAddresses(data?.userDeliveryAddresses.corpAddresses),
    selectedAddress: {
      ...data?.userDeliveryAddresses?.selectedAddress,
      address: getMinimumAddress(data?.userDeliveryAddresses?.selectedAddress?.address),
    } as MinimumDeliveryAddress,
  };
};
