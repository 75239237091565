import React from 'react';
import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI/Button/Button';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './CancelNotificationModal.module.scss';

interface OrderCancelledModalProps {
  opened: boolean;
  onClose: () => void;
}

export const CancelNotificationModal = ({ opened, onClose }: OrderCancelledModalProps) => {
  const { t } = useLocalization('account');

  return (
    <ModalWindow
      open={opened}
      onClose={onClose}
      className={styles.modal_content}
      labelId={MODAL_TITLE_ID.CANCEL_NOTIFICATION}
    >
      <>
        <Typography
          className={styles.heading}
          variant="h4"
          component="h2"
          id={MODAL_TITLE_ID.CANCEL_NOTIFICATION}
        >
          {t('orderHistory.orderCancelledTitle')}
        </Typography>
        <Typography className={styles.text} variant="body">
          {t('orderHistory.orderCancelledMessage')}
        </Typography>
        <Button onClick={onClose} size="large" isFullWidth>
          {t('orderHistory.okay')}
        </Button>
      </>
    </ModalWindow>
  );
};
