import { ApolloCache } from '@apollo/client';
import { Cart } from '@api';
import { expressItemsCountVar } from '@graphql/variables/expressItemsNotification';

export const updateCartCache = (
  cache: ApolloCache<unknown>,
  newCart: DeepPartial<Cart> | undefined,
) => {
  cache.modify({
    fields: {
      cartForCheckout(_, { DELETE }) {
        expressItemsCountVar(newCart?.expressDeliveryNotification?.expressItemsCount ?? null);
        return newCart
          ? {
              ...newCart,
            }
          : DELETE;
      },
    },
  });
};
