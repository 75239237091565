import MuiButton from '@mui/base/Button';
import { MouseEvent, ReactNode, forwardRef } from 'react';
import cn from 'classnames';
import styles from './ButtonBase.module.scss';

export interface ButtonBaseProps {
  children: ReactNode;
  size?: 'extraSmall' | 'small' | 'medium';
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  id?: string;
  isSubmit?: boolean;
}

export const ButtonBase = forwardRef(
  (
    {
      size = 'medium',
      fullWidth = false,
      disabled = false,
      isSubmit = false,
      children,
      className,
      ...restProps
    }: ButtonBaseProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const internalClassName = cn(styles.buttonBase, {
      [styles.extra_small]: size === 'extraSmall',
      [styles.small]: size === 'small',
      [styles.full_width]: fullWidth,
    });

    const type = isSubmit ? 'submit' : 'button';

    return (
      <MuiButton
        {...restProps}
        className={cn(internalClassName, className)}
        disabled={disabled}
        ref={ref}
        type={type}
      >
        {children}
      </MuiButton>
    );
  },
);

ButtonBase.displayName = 'ButtonBase';
