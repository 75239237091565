import { Typography } from '@mui/material';
import cx from 'classnames';
import { useRef } from 'react';
import { Alert, Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { PurchasedGiftCard as PurchasedGiftCardType } from '@commons/giftCards';
import { ResendGiftCardModal } from '@components/Modals/GiftCardModals/ResendGiftCardModal';
import { GiftCardDeliveryType } from '@api';
import { check as CheckIcon } from '@assets/icons/system';
import { makeUrlFromBase64 } from '@utils/makeUrlFromBase64';
import { usePurchasedGiftCard } from '@hooks/account/usePurchasedGiftCard';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import styles from './PurchasedGiftCard.module.scss';

interface GiftCardProps {
  card: PurchasedGiftCardType;
}

export const PurchasedGiftCard = ({ card }: GiftCardProps) => {
  const { t } = useLocalization('account');
  const { isMobile } = useHeaderContext();
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const {
    getGiftCardPdf,
    resendGiftCardModalProps,
    alertOpened,
    handleSuccessResend,
    loadingGiftCardPdf,
  } = usePurchasedGiftCard(card);

  const handleViewPdf = () => {
    getGiftCardPdf({
      onCompleted(data) {
        const url = makeUrlFromBase64(data.giftCardPdf.pdfBase64String);
        if (!!isMobile && linkRef.current !== null) {
          linkRef.current.href = url;
          linkRef.current?.click();
        } else {
          window.open(url, '_blank');
        }
      },
    });
  };

  return (
    <>
      <div className={styles.giftCardWrapper}>
        {alertOpened && (
          <Alert size="medium" className={styles.successAlert}>
            <CheckIcon className={styles.checkIcon} />
            <Typography variant="caption" component="p">
              {t('giftCards.successAlert')}
            </Typography>
          </Alert>
        )}
        <div className={styles.giftCardFlexWrapper}>
          <div id={t('giftCards.ariaLabelPurchase', { giftCardId: card.certificateNumber })}>
            <Typography className={styles.giftCardTitle} variant="body">
              {t('giftCards.giftCardTo', { recepientName: card.recipientName })}
            </Typography>
            <Typography variant="caption" component="p" className={styles.giftCardInfo}>
              {card.formattedDate}, #{card.certificateNumber}
            </Typography>
          </div>
          <div className={styles.flexWrapper}>
            <div className={styles.giftCardAmount}>${card.formattedAmount}</div>
            <div className={styles.controlsContainer}>
              {!!card.certificateNumber && (
                <>
                  <a className="sr-only" href="#pdf" ref={linkRef} tabIndex={-1}>
                    {t('giftCards.viewPdfBtn')}
                  </a>
                  <Button
                    data-testid="view-pdf-button"
                    size="small"
                    variant="outlined"
                    className={cx(styles.purchaseGiftCardButton, styles.viewPdfButton)}
                    loading={loadingGiftCardPdf}
                    onClick={handleViewPdf}
                    aria-describedby={t('giftCards.ariaLabelPurchase', {
                      giftCardId: card.certificateNumber,
                    })}
                  >
                    {t('giftCards.viewPdfBtn')}
                  </Button>
                </>
              )}
              <Button
                data-testid="resend-button"
                size="small"
                variant="outlined"
                onClick={resendGiftCardModalProps.openModal}
                className={styles.purchaseGiftCardButton}
                aria-describedby={t('giftCards.ariaLabelPurchase', {
                  giftCardId: card.certificateNumber,
                })}
              >
                {t('giftCards.resendBtn')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ResendGiftCardModal
        open={resendGiftCardModalProps.isOpen}
        onClose={resendGiftCardModalProps.closeModal}
        onSuccess={handleSuccessResend}
        templateData={{
          minAmount: 0,
          maxAmount: 0,
          gcTemplates: [card.template],
          gcAmtOptions: [],
        }}
        saleId={card.saleId}
        values={{
          giftCardId: card.certificateNumber,
          giftCardAmount: card.formattedAmount,
          giftCardTemplateId: card.template.gcTemplateId,
          recipientName: card.recipientName,
          recipientEmail: card.recipientEmail,
          recipientMessage: card.personalMessage,
          senderName: 'empty',
          senderEmail: 'empty',
          giftCardType: GiftCardDeliveryType.E,
          quantity: 1,
          isBulk: false,
        }}
      />
    </>
  );
};
