import { Field, FormikErrors } from 'formik';
import { ChangeEvent, WheelEvent } from 'react';
import { Stack } from '@mui/material';
import styles from '@components/PaymentsAddTabs/components/EbtTabContent/EbtTabContent.module.scss';
import { FIELDS } from '@components/PaymentsAddTabs/components/EbtTabContent/useEbtTabContent';
import {
  handlePreventNotNumbers,
  MEDIUM_FIELD_LENGTH,
  SMALL_FIELD_LENGTH,
  ZIPCODE_FIELD_LENGTH,
} from '@utils/validation';
import { DropDown, FormikInput } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { DropdownOptionsType } from '@commons/account';

interface Values {
  [FIELDS.CARDHOLDER]: string;
  [FIELDS.CARD_NUMBER]: string;
  [FIELDS.BILLING_ADDRESS]: string;
  [FIELDS.NEW_BILLING_ADDRESS]: string;
  [FIELDS.COUNTRY]: string;
  [FIELDS.CITY]: string;
  [FIELDS.ZIP_CODE]: string;
  [FIELDS.APARTMENT]: string;
  [FIELDS.STATE]: string;
}
type SetFieldValue = (field: string, value?: string) => void;

interface NewAddressFieldsProps {
  validate: {
    [FIELDS.CARD_NUMBER]: (value: string) => string | false;
    [FIELDS.CARDHOLDER]: (value: string) => string | false;
    [FIELDS.APARTMENT]: (value: string) => string | false;
    [FIELDS.NEW_BILLING_ADDRESS]: (value: string) => string | false;
    [FIELDS.CITY]: (value: string) => string | false;
    [FIELDS.ZIP_CODE]: (value: string) => string | false;
    [FIELDS.COUNTRY]: (value: string) => string | false;
    [FIELDS.STATE]: (value: string) => string | false;
  };
  validateField: (field: string) => void;
  values: Values;
  setFieldValue: SetFieldValue;
  errors: FormikErrors<Values>;
  countryOptions: DropdownOptionsType[];
  stateOptions: DropdownOptionsType[];
}

export const NewAddressFields = ({
  validate,
  validateField,
  values,
  setFieldValue,
  errors,
  countryOptions,
  stateOptions,
}: NewAddressFieldsProps) => {
  const { t } = useLocalization('account');
  const onChangeZipcode = (e: ChangeEvent<HTMLInputElement>, setField: SetFieldValue) => {
    setField(FIELDS.ZIP_CODE, e.target.value);
  };

  return (
    <>
      <Field
        className={styles.input}
        name={FIELDS.NEW_BILLING_ADDRESS}
        control="input"
        type="text"
        maxLength={MEDIUM_FIELD_LENGTH}
        label={t('payments.editModal.billingAddress')}
        component={FormikInput}
        validate={validate[FIELDS.NEW_BILLING_ADDRESS]}
        error={!!errors[FIELDS.NEW_BILLING_ADDRESS]}
        helperText={errors[FIELDS.NEW_BILLING_ADDRESS]}
        onBlur={() => validateField(FIELDS.NEW_BILLING_ADDRESS)}
        id={FIELDS.NEW_BILLING_ADDRESS}
      />
      <Stack direction="row" spacing={2}>
        <Field
          className={styles.input}
          name={FIELDS.APARTMENT}
          control="input"
          type="text"
          maxLength={SMALL_FIELD_LENGTH}
          label={t('payments.editModal.apartment')}
          validate={validate[FIELDS.APARTMENT]}
          component={FormikInput}
          error={!!errors[FIELDS.APARTMENT]}
          helperText={errors[FIELDS.APARTMENT]}
          onBlur={() => validateField(FIELDS.APARTMENT)}
          id={FIELDS.APARTMENT}
        />
        <Field
          id={FIELDS.COUNTRY}
          className={styles.input}
          name={FIELDS.COUNTRY}
          title={t('payments.editModal.country')}
          component={DropDown}
          value={values[FIELDS.COUNTRY]}
          options={countryOptions}
          onChange={(newValue: string) => setFieldValue(FIELDS.COUNTRY, newValue)}
          validate={validate[FIELDS.COUNTRY]}
          error={!!errors[FIELDS.COUNTRY]}
          helperText={errors[FIELDS.COUNTRY]}
          onBlur={() => validateField(FIELDS.COUNTRY)}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Field
          id={FIELDS.STATE}
          className={styles.input}
          name={FIELDS.STATE}
          title={t('payments.editModal.state')}
          component={DropDown}
          value={values[FIELDS.STATE]}
          options={stateOptions}
          onChange={(newValue: string) => setFieldValue(FIELDS.STATE, newValue)}
          validate={validate[FIELDS.STATE]}
          error={!!errors[FIELDS.STATE]}
          helperText={errors[FIELDS.STATE]}
          onBlur={() => validateField(FIELDS.STATE)}
        />
        <Field
          className={styles.input}
          name={FIELDS.CITY}
          control="input"
          type="text"
          maxLength={MEDIUM_FIELD_LENGTH}
          validate={validate[FIELDS.CITY]}
          label={t('payments.editModal.city')}
          component={FormikInput}
          error={!!errors[FIELDS.CITY]}
          helperText={errors[FIELDS.CITY]}
          onBlur={() => validateField(FIELDS.CITY)}
          id={FIELDS.CITY}
        />
        <Field
          className={styles.input}
          name={FIELDS.ZIP_CODE}
          control="input"
          type="text"
          maxLength={ZIPCODE_FIELD_LENGTH}
          label={t('payments.editModal.zipCode')}
          component={FormikInput}
          validate={validate[FIELDS.ZIP_CODE]}
          error={!!errors[FIELDS.ZIP_CODE]}
          helperText={errors[FIELDS.ZIP_CODE]}
          onBlur={() => validateField(FIELDS.ZIP_CODE)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeZipcode(e, setFieldValue)}
          onKeyPress={handlePreventNotNumbers}
          onWheel={(e: WheelEvent) => (e.target as HTMLInputElement).blur()}
          id={FIELDS.ZIP_CODE}
        />
      </Stack>
    </>
  );
};
