import { Variant } from '@commons/cart';
import { ProductQuantity } from '@commons/product';
import { InitialValues } from '@hooks/cart/useProductInfoFromCart';

export const getInitialValues = (
  variants: Variant[],
  quantitySelector: ProductQuantity,
  selectedSalesUnit: string | null,
) => {
  const initialValues: InitialValues = {};
  const variables = variants.map((item) => ({
    value: item.characteristicValue,
    name: item.characteristicName,
  }));
  const count = quantitySelector.quantity;

  if (count) {
    initialValues.count = count;
  }

  if (selectedSalesUnit) {
    initialValues.salesUnit = selectedSalesUnit;
  }

  if (variables?.length) {
    initialValues.variables = variables;
  }

  return initialValues;
};
