import { useQuery } from '@apollo/client';
import { GET_REFER_A_FRIEND_INVITES } from '@graphql/account/queries/referAFriend';

export const useReferAFriendInvites = () => {
  const { data, loading, refetch, ...queryResult } = useQuery(GET_REFER_A_FRIEND_INVITES, {
    fetchPolicy: 'network-only',
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...queryResult,
    data,
    loading,
    refetch,
  };
};
