import { gql } from '@apollo/client';
import { TEMPLATES_FRAGMENT } from '@graphql/fragments/giftCard/templates';

export const GET_GIFT_CARD_PURCHASE_INFO = gql`
  query GiftCardPurchaseInfo {
    giftCardProducts {
      minAmount
      maxAmount
      gcAmtOptions
      gcTemplateIdToShow
      ...templatesFragment
    }
  }
  ${TEMPLATES_FRAGMENT}
`;
