import { makeVar, useReactiveVar } from '@apollo/client';

export const recommenderViewVar = makeVar<boolean>(false);

export const useRecommenderWidgetViewVar = () => {
  const setRecommenderWidgetViewState = (state: boolean) => {
    recommenderViewVar(state);
  };
  const isRecommenderWidgetViewed = useReactiveVar(recommenderViewVar);

  return { isRecommenderWidgetViewed, setRecommenderWidgetViewState };
};
