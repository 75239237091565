import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { TextField } from '@components/FormikFields';
import { useLocalization } from '@hooks/useLocalization';
import { ONLY_INTEGER_REGEXP } from '@utils/validation';
import { Button } from '@components/UI/Button/Button';
import { CUSTOM_AMOUNT_INPUT_VALUE } from '../../constants';
import { GIFTCARD_FIELDS, Values } from '../../types';
import styles from './CustomAmountControls.module.scss';

interface CustomAmountControlsProps {
  onSave: () => void;
}

export const CustomAmountControls = ({ onSave }: CustomAmountControlsProps) => {
  const { t } = useLocalization('giftCards');
  const {
    touched,
    errors,
    values,
    handleChange: handleFormikChange,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<Values>();

  const giftCardCustomAmount = GIFTCARD_FIELDS.GIFTCARD_CUSTOM_AMOUNT;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.match(ONLY_INTEGER_REGEXP) || value.length === 0) {
      handleFormikChange(event);
    }
  };

  const handleSave = () => {
    setFieldTouched(giftCardCustomAmount, true, true);
    if (touched[giftCardCustomAmount] && !errors[giftCardCustomAmount]) {
      setFieldValue(GIFTCARD_FIELDS.GIFTCARD_DEFAULT_AMOUNT, CUSTOM_AMOUNT_INPUT_VALUE);
      setFieldValue(GIFTCARD_FIELDS.GIFTCARD_AMOUNT, values[giftCardCustomAmount]);
      setFieldValue(giftCardCustomAmount, Number(values[giftCardCustomAmount] ?? ''));
      onSave();
    }
  };

  return (
    <Grid container className={styles.content}>
      <Grid item container alignItems="center" gap={2}>
        <Grid item flexGrow={1} className={styles.input_wrapper}>
          <Field
            name={giftCardCustomAmount}
            component={TextField}
            label={t('giftCardModal.fields.customAmount')}
            error={touched[giftCardCustomAmount] && errors[giftCardCustomAmount]}
            helperText={touched[giftCardCustomAmount] && errors[giftCardCustomAmount]}
            onChange={handleChange}
            icon={<span>$</span>}
            iconPosition="start"
            classes={{ input: styles.input }}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid item flexGrow={1}>
        <Button onClick={handleSave} size="large" className={styles.save_button}>
          {t('giftCardModal.saveCustomAmount')}
        </Button>
      </Grid>
    </Grid>
  );
};
