import { useRef, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { reorder as ReorderIcon } from '@assets/icons/informational/with-spacing';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import { useAuthContext, hasPastOrders } from '@modules/auth/context';
import { NavigationData } from '@hooks/header/useNavigationData';
import { NavigationMenuItem } from '@api';
import { BaseLink } from '@components/UI';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { thanksGivingPageIds } from '@commons/layouts';
import styles from './GlobalNav.module.scss';

interface NavigationProps {
  navigationData: NavigationData;
}

const ICONS = {
  [routing.reorderItems]: <ReorderIcon />,
  [routing.shoppingLists]: <ReorderIcon />,
};

const REORDER_ID = 'quickshop';

const getIcon = (link?: string | null, icon?: string | null) => {
  return (
    ICONS[link || ''] || (
      <Image src={icon || ''} className={styles.nav_item_icon} alt="" width={42} height={42} />
    )
  );
};

const getMenuItem = (navItem: NavigationMenuItem, isActive?: boolean, holidayLink?: string) => {
  const link = navItem.link?.trim() ?? holidayLink;

  return link ? (
    <BaseLink href={link}>
      <a className={styles.nav_item_link} data-testid={navItem.id}>
        <Typography
          className={styles.nav_item_link_text}
          width="100%"
          textAlign="center"
          variant="body"
        >
          {navItem?.name}
        </Typography>
        {isActive && <span className="sr_only">Selected</span>}
      </a>
    </BaseLink>
  ) : (
    <Typography
      className={styles.nav_item_nolink_text}
      width="100%"
      textAlign="center"
      variant="body"
    >
      {navItem?.name}
    </Typography>
  );
};

export const GlobalNavV2 = ({
  navigationData: { primaryNavigation, additionalMenus },
}: NavigationProps) => {
  const { t } = useLocalization('header');
  const { isKnownUser, userInfo } = useAuthContext();
  const userHasPastOrders = hasPastOrders(userInfo);
  const navListRef = useRef<HTMLUListElement>(null);
  const { asPath, push: goToPage } = useRouter();
  const { breadcrumbInfo } = useHeaderContext();
  const [routePath, setRoutePath] = useState(asPath);

  useEffect(() => {
    if (!!breadcrumbInfo && breadcrumbInfo.length > 0) {
      const bredCrumbUrl = breadcrumbInfo[breadcrumbInfo.length - 1].url;
      if (bredCrumbUrl !== asPath) {
        setRoutePath(bredCrumbUrl);
      }
    }
  }, [breadcrumbInfo]);

  useEffect(() => {
    setRoutePath(asPath);
  }, [asPath]);

  const isActive = useCallback(
    (navItem: NavigationMenuItem) => {
      if (asPath === routing.home) {
        return false;
      }

      const urlId = asPath.match(/[?&]id=([^&]+)/);
      // exact link match
      if (
        navItem.link?.trim() === routePath ||
        (!!navItem.link && routePath.includes(navItem.link?.trim())) ||
        (!navItem.link &&
          navItem.linkId === additionalMenus?.[0]?.id &&
          urlId &&
          thanksGivingPageIds.includes(urlId[1]))
      ) {
        return true;
      }

      // check first part of the path
      const pathStart = routePath.split('/')[1];

      return pathStart === navItem.id;
    },
    [routePath],
  );

  const navItems = (primaryNavigation ?? []).filter(
    (item) => item?.link || item?.linkId,
  ) as NavigationMenuItem[];

  const reorderNavItem: NavigationMenuItem = {
    name: userHasPastOrders ? t('reorderList') : t('shoppingLists'),
    link: userHasPastOrders ? routing.reorderItems : routing.shoppingLists,
    id: REORDER_ID,
  };
  if (isKnownUser) {
    navItems.push(reorderNavItem);
  }

  const getNavClass = (navItem: NavigationMenuItem) => {
    return cx(styles.nav_item, {
      [styles.active]: isActive(navItem),
      [styles.reorder]: navItem.id === REORDER_ID,
    });
  };

  return (
    <nav className={styles.navigation} data-testid="header-navigation">
      <ul className={styles.navbar} ref={navListRef} data-testid="nav-panel">
        {navItems.map((navItem, index) => {
          const link = navItem.link?.trim();
          let holidayLink = link;
          const additionalMenuItems = additionalMenus?.[0];

          if (
            !link &&
            additionalMenuItems?.menuItems?.[0] &&
            navItem?.linkId === additionalMenuItems?.id
          ) {
            holidayLink = additionalMenuItems?.menuItems?.[0].link as string;
          }

          return (
            <li
              key={index}
              data-key={index}
              data-testid="primary-nav-item"
              className={getNavClass(navItem)}
              role="none"
              onClick={() => link && goToPage(link)}
            >
              <div className={styles.nav_item_icon}>{getIcon(link, navItem.icon)}</div>
              <div className={styles.nav_item_controls}>
                {getMenuItem(navItem, isActive(navItem), holidayLink)}
              </div>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
