import getConfig from 'next/config';
import styles from '../DevTools.module.scss';

const { publicRuntimeConfig } = getConfig();

export const RunTimeConfig = () => {
  return (
    <>
      {/* @ts-expect-error details support is lacking */}
      <details name="devtab">
        <summary>Runtime config</summary>
        <div>
          <dl className={styles.runtimeconfig}>
            {Object.entries(publicRuntimeConfig).map(([key, value]) => (
              <div key={key}>
                <dt>{key}</dt>
                <dd>{value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </details>
    </>
  );
};
