import { useQuery } from '@apollo/client';
import { useUpdateEffect } from 'usehooks-ts';
import { DELIVERY_PASS_PLANS } from '@graphql/deliveryPass/queries/deliveryPass';
import { DeliveryPassPlansQuery } from '@api';
import { getDeliveryPassPlans } from '@adapters/deliveryPassAdapter';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useDeliveryPass = () => {
  const { data, previousData, refetch, ...queryParams } = useQuery<DeliveryPassPlansQuery>(
    DELIVERY_PASS_PLANS,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { userInfo: customer } = useAuthContext();

  useUpdateEffect(() => {
    refetch();
  }, [customer?.email]);

  const plans = getDeliveryPassPlans(data?.deliveryPasses.deliveryPasses ?? []);

  return {
    ...queryParams,
    refetch,
    previousFreeTrialAvailable: !!previousData?.deliveryPasses?.freeTrialEligible,
    previousFreeOneYearAvailable:
      !!previousData?.deliveryPasses?.freeTrialEligible &&
      previousData?.deliveryPasses?.freeTrialOptinSku === 'MKT9000228',
    previousTwoMonthsTrialAvailable:
      !!previousData?.deliveryPasses?.freeTrialEligible &&
      previousData?.deliveryPasses?.freeTrialOptinSku === 'MKT0072335',
    freeTrialEligible: !!data?.deliveryPasses.freeTrialEligible,
    bestValuePlan: plans.find((plan) => plan.isBestValue),
    plans,
    freeTrialOptinSku: data?.deliveryPasses?.freeTrialOptinSku,
    freeOneYearEligible:
      !!data?.deliveryPasses.freeTrialEligible &&
      data?.deliveryPasses?.freeTrialOptinSku === 'MKT9000228',
    twoMonthsTrialEligible:
      !!data?.deliveryPasses.freeTrialEligible &&
      data?.deliveryPasses?.freeTrialOptinSku === 'MKT0072335',
    displayFreeTrialOverlay: data?.deliveryPasses?.displayOverlay,
  };
};
