import { IAction, PAction } from '@modules/action';
import { ICheckoutActionData } from '@modules/actions/checkout-click-action/ICheckoutActionData';
import { emit, Off, on } from '../../emitter';

interface PCheckoutAction extends PAction {
  data: ICheckoutActionData;
}

interface ICheckoutAction extends IAction {
  data: ICheckoutActionData;
}

const TCheckoutAction = 'CheckoutAction';

function CheckoutAction(p: PCheckoutAction): ICheckoutAction {
  const type = p.type ?? TCheckoutAction;
  return {
    type,
    data: p.data,
  };
}

export const onCheckoutClickAction = (callback: (event: ICheckoutActionData) => void): Off =>
  on((action) => {
    if (action.type === TCheckoutAction) {
      callback((action as PCheckoutAction).data);
    }
  });

export const emitCheckoutClickAction = (data: ICheckoutActionData): void => {
  emit(CheckoutAction({ type: TCheckoutAction, data }));
};
