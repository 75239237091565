import { useState, ReactNode } from 'react';
import { MobilePromoModalContext } from './MobilePromoModalContext';

interface MobilePromoModalProviderProps {
  children: ReactNode;
}

export const MobilePromoModalProvider = ({ children }: MobilePromoModalProviderProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <MobilePromoModalContext.Provider
      value={{
        opened,
        onOpen: () => setOpened(true),
        onClose: () => setOpened(false),
      }}
    >
      {children}
    </MobilePromoModalContext.Provider>
  );
};
