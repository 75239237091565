import { useLazyQuery } from '@apollo/client';
import { AddressValidationInput } from '@commons/deliveryAddresses';
import { VALIDATION_DELIVERY_ADDRESS } from '@graphql/account/queries/validationAddress';

export const useValidateDeliveryAddress = (params?: AddressValidationInput) => {
  const [validateAddress, { data, ...rest }] = useLazyQuery(VALIDATION_DELIVERY_ADDRESS, {
    variables: { deliveryAddress: params },
    ssr: false,
  });
  return { validateAddress, data, ...rest };
};
