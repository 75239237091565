import orderBy from 'lodash/orderBy';
import inRange from 'lodash/inRange';
import {
  Product,
  ProductRangePrice,
  SelectedProperties,
  SelectedConfiguration,
  CvPrice,
  ProductTile,
  SalesUnit,
} from '@commons/product';

const APPLY_WITH_QUANTITY = 1;
const SCALE_UNIT_TO_NOT_APPLY_RATIO = 'CS';

const findAppropriateRange = (
  priceList: ProductRangePrice[] | null,
  maxAmount: number,
): number | null => {
  const sortedRange = orderBy(priceList, ['scaleLowerBound']);
  const appropriateRange = sortedRange.find((priceListItem: ProductRangePrice) =>
    inRange(maxAmount, priceListItem.scaleLowerBound ?? 0, priceListItem.scaleUpperBound ?? 0),
  );

  if (appropriateRange) {
    return appropriateRange?.price;
  }
  return 0;
};

const calculateBasePrice = (
  grpPrices: ProductRangePrice[] | null,
  availablePrices: ProductRangePrice[],
  amount: number,
  quantity: number,
) => {
  return (
    findAppropriateRange(grpPrices, Math.max(amount, quantity)) ||
    findAppropriateRange(availablePrices, amount)
  );
};

const calculateConfigurationPrice = (
  cvPrices: CvPrice[],
  selectedConfiguration: SelectedConfiguration[],
  amount: number,
  quantity: number,
) => {
  if (cvPrices.length < 1) {
    return 0;
  }

  let configurationPrice = 0;
  selectedConfiguration.forEach((singleConfig: SelectedConfiguration) => {
    const singleCvPrice = cvPrices.find(
      (currentConfigVariant: CvPrice) => currentConfigVariant.charValueName === singleConfig.value,
    );

    if (!singleCvPrice || !singleCvPrice.price) {
      return configurationPrice;
    }

    const currentConfigSum =
      singleCvPrice?.applyHow === APPLY_WITH_QUANTITY
        ? quantity * singleCvPrice.price
        : amount * singleCvPrice.price;

    configurationPrice = configurationPrice + currentConfigSum;
  });

  return configurationPrice;
};

export const calculateTotalPrice = (
  product: Product | ProductTile,
  configs: SelectedProperties,
) => {
  const amount =
    product.scaleUnit?.toUpperCase() === SCALE_UNIT_TO_NOT_APPLY_RATIO
      ? configs.quantity
      : configs.quantity * configs.ratio;
  const basePrice =
    calculateBasePrice(product.grpPrices, product.availMaterialPrices, amount, configs.quantity) ||
    product.price.value ||
    0;
  const intermediatePrice = Math.round(100 * amount * basePrice) / 100;
  const configurationPrice = calculateConfigurationPrice(
    product.cvPrices,
    configs.selectedConfigurations,
    amount,
    configs.quantity,
  );
  return configurationPrice + intermediatePrice;
};

export const getSelectedSalesUnit = (salesUnits: SalesUnit[], salesUnit: string) => {
  return salesUnits.find((value) => value?.alternateSalesUnit === salesUnit);
};
