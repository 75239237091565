import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './ModifyOrderErrorModal.module.scss';

interface ModifyOrderErrorModalProps {
  onClose: () => void;
  opened: boolean;
  isExpressOrder?: boolean;
}

export const ModifyOrderErrorModal = ({
  onClose,
  opened,
  isExpressOrder = false,
}: ModifyOrderErrorModalProps) => {
  const { t } = useLocalization();

  return (
    <ModalWindow
      className={styles.modal}
      onClose={onClose}
      open={opened}
      labelId={MODAL_TITLE_ID.MODIFY_ORDER_ERROR}
    >
      <>
        <Typography
          variant="h3"
          component="h2"
          className={styles.title}
          id={MODAL_TITLE_ID.MODIFY_ORDER_ERROR}
        >
          {t(
            isExpressOrder
              ? 'modifyingModal.errorModal.titleExpress'
              : 'modifyingModal.errorModal.title',
          )}
        </Typography>
        <Typography variant="body" component="p" className={styles.subtitle}>
          {t(
            isExpressOrder
              ? 'modifyingModal.errorModal.subtitleExpress'
              : 'modifyingModal.errorModal.subtitle',
          )}
        </Typography>
        <Button onClick={onClose} isFullWidth>
          {t('modifyingModal.errorModal.okay')}
        </Button>
      </>
    </ModalWindow>
  );
};
