import { FeatureCheckProps } from '@modules/featureCheck/FeatureCheck.types';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';

export const FeatureCheck = ({ featureName, enabled, disabled }: FeatureCheckProps) => {
  const { getFeature } = useHeaderContext();

  const featuresMap = {
    [FEATURE.COS]: () => !!getFeature?.(FEATURE.COS),
    [FEATURE.STANDING_ORDERS]: () => !!getFeature?.(FEATURE.STANDING_ORDERS),
    [FEATURE.EXPRESS_TOGGLE]: () => !!getFeature?.(FEATURE.EXPRESS_TOGGLE),
    [FEATURE.REFER_A_FRIEND]: () => !!getFeature?.(FEATURE.REFER_A_FRIEND),
    [FEATURE.NEW_PRODUCT_TILES]: () => !!getFeature?.(FEATURE.NEW_PRODUCT_TILES),
  };
  return featuresMap[featureName]() ? enabled() : disabled();
};
