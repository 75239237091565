export enum GRAPHQL_ERRORS {
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',
  OPERATION_RESOLUTION_FAILURE = 'OPERATION_RESOLUTION_FAILURE',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNEXPECTED_BACKEND_EXCEPTION = 'UNEXPECTED_BACKEND_EXCEPTION',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  NOT_FOUND = 'DATA_NOT_FOUND',
  SERVER_UNAVAILABLE = 'SERVER_UNAVAILABLE',
  NULL = 'NULL',
}

export const graphqlErrorList = [
  GRAPHQL_ERRORS.GRAPHQL_PARSE_FAILED,
  GRAPHQL_ERRORS.GRAPHQL_VALIDATION_FAILED,
  GRAPHQL_ERRORS.PERSISTED_QUERY_NOT_FOUND,
  GRAPHQL_ERRORS.PERSISTED_QUERY_NOT_SUPPORTED,
  GRAPHQL_ERRORS.OPERATION_RESOLUTION_FAILURE,
  GRAPHQL_ERRORS.INTERNAL_SERVER_ERROR,
  GRAPHQL_ERRORS.UNEXPECTED_BACKEND_EXCEPTION,
  GRAPHQL_ERRORS.TOO_MANY_REQUESTS,
];
