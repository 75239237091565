import Grid from '@mui/material/Grid';
import { ProductTileSkeleton } from '@components/Skeletons/ProductTileSkeleton/ProductTileSkeleton';
import styles from './RecommendedProductCarouselSkeleton.module.scss';

const RECOMMENDED_PRODUCT_CAROUSEL_SKELETON_COUNT = 4;

export const RecommendedProductCarouselSkeleton = () => {
  const items = Array.from(Array(RECOMMENDED_PRODUCT_CAROUSEL_SKELETON_COUNT).keys());

  return (
    <Grid container className={styles.grid}>
      {items.map((item) => (
        <Grid item xs={6} sm={3} key={item} className={styles.grid_item}>
          <ProductTileSkeleton />
        </Grid>
      ))}
    </Grid>
  );
};
