import { useRouter } from 'next/router';
import { useEffectOnceWithCondition } from '@hooks/useEffectOnceWithCondition';
import { getAvailableTimeslotValue } from '@utils/getAvailableTimeslotValue';
import { hasUnavailableTimeslots } from '@utils/hasUnavailableTimeslots';
import { fireCartCustomPageView } from '@modules/ga/events/custom/custom-page-view/customPageViewCreator';
import { Timeslot, TimeslotData } from '@commons/timeslots';
import { useEcomCart } from '@modules/ga/hooks/useEcomCart';

interface UseCartPageViewProps {
  selectedTimeslot?: TimeslotData | null;
  timeslot?: Timeslot;
  loading: boolean;
}

const CUSTOM_PAGEVIEW = 'custom_pageview';
const isCustomPageviewEventExist = () => {
  if (typeof window !== 'undefined') {
    return window.dataLayer?.some(({ event }: { event: string }) => event === CUSTOM_PAGEVIEW);
  }
};

export const useCartPageView = ({ loading, selectedTimeslot, timeslot }: UseCartPageViewProps) => {
  const router = useRouter();
  const trackEcomCart = useEcomCart();

  useEffectOnceWithCondition(() => {
    const availableTimeslotValue = getAvailableTimeslotValue(selectedTimeslot);
    const unavailableTimeslotPresent = hasUnavailableTimeslots(timeslot) ? 'yes' : 'no';
    fireCartCustomPageView({
      url: router.asPath,
      availableTimeslotValue,
      unavailableTimeslotPresent,
    });
    trackEcomCart();
  }, !loading && !isCustomPageviewEventExist());
};
