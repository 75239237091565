import {
  useLazyQuery,
  OperationVariables,
  LazyQueryResult,
  QueryLazyOptions,
} from '@apollo/client';
import { GET_PAST_AND_ACTIVE_ORDER_HISTORY } from '@graphql/account/queries/getPastAndActiveOrderHistory';
import { orderAdapter } from '@adapters/orderAdapter';
import { Order } from '@commons/order';

interface QueryResult<TData, TVariables extends OperationVariables>
  extends LazyQueryResult<TData, TVariables> {
  data: TData;
}

type QueryTuple<TData, TVariables extends OperationVariables> = [
  (options?: QueryLazyOptions<TVariables>) => Promise<LazyQueryResult<TData, TVariables>>,
  QueryResult<TData, TVariables>,
];

export const useLazyPastAndActiveOrderHistory = (): QueryTuple<
  { pastOrders: Order[]; activeOrders: Order[]; modifyingOrderId: string },
  OperationVariables
> => {
  const { getOrderHistory } = orderAdapter();
  const [fetch, { data, ...params }] = useLazyQuery(GET_PAST_AND_ACTIVE_ORDER_HISTORY, {
    fetchPolicy: 'network-only',
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });
  return [
    fetch,
    {
      ...params,
      data: {
        activeOrders: getOrderHistory(data?.activeOrdersHistory?.ordersInfo),
        pastOrders: getOrderHistory(data?.ordersHistory?.ordersInfo),
        modifyingOrderId: data?.activeOrdersHistory?.modifyingOrderId,
      },
    },
  ];
};
