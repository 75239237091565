import { Title } from '../Title';
import styles from './TitleWithCounter.module.scss';

interface TitleWithCounterProps {
  children: string;
  className?: string;
  counter: number;
  component?: React.ElementType;
}

export const TitleWithCounter = ({
  children,
  className,
  counter,
  component,
  ...rest
}: TitleWithCounterProps) => {
  return (
    <Title {...rest} component={component} className={className}>
      {children} {<span className={styles.counter}>({counter})</span>}
    </Title>
  );
};
