import { useEffect } from 'react';
import Trans from 'next-translate/Trans';
import { useReactiveVar } from '@apollo/client';
import cx from 'classnames';
import { BagFooter } from '@features/CartPage/components/Bag/components/BagFooter/BagFooter';
import { useLocalization } from '@hooks/index';
import {
  CartLine,
  CartSection,
  NonExpressSection,
  PriceIndicators,
  Subtotal as ISubtotal,
  ExpressOnlySection,
} from '@commons/cart';
import { ProductConfigurationModal } from '@components/ProductConfigurationModal/ProductConfigurationModal';
import { useIsShowTimeslots, useTimeslots } from '@hooks/timeslots';
import { sectionsToBeDeletedVar } from '@graphql/variables/sectionsToBeDeletedVar';
import {
  ChosenProduct,
  useConfigurationModal,
} from '@hooks/configurationModal/useConfigurationModal';
import { OUT_OF_STOCK, UNAVAILABLE } from '@constants/cart';
import { OrderFooter } from '@components/OrderFooter/OrderFooter';
import { OrderList } from '@components/OrderList/OrderList';
import { getCartSectionsToBeDeleted } from '@utils/getCartSectionsToBeDeleted';
import { Price } from '@commons/price';
import { ExpressIcon } from '@components/UI/ExpressIcon/ExpressIcon';
import { localStorageService, STORAGE_KEYS } from '@utils/storageService';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useCartPageView } from '@modules/ga/hooks/useCartPageView';
import { CouponModalContainer } from '@components/OrderInfo/CouponModal/CouponModalContainer';
import { getSectionTitle } from './FilledBag.utils';
import { MasquaradeIndicators } from './components/MasquaradeIndicators/MasquaradeIndicators';
import { UnavailableCartLines } from './components/UnavailableCartLines/UnavailableCartLines';
import styles from './FilledBag.module.scss';

interface FilledBagProps {
  subtotal: ISubtotal;
  totalWithoutDiscount: Price;
  lineItemPromoApplied: boolean;
  hasCouponError: boolean;
  hideTimeslot?: boolean;
  deliveryFee: ISubtotal;
  alcoholRestricted: boolean;
  orderMinimum: Price;
  cartSections?: CartSection[];
  onOpenDeliveryOptions?: () => void;
  cartTotalPriceIndicators: PriceIndicators[];
  nonExpressCartTotalPriceIndicators: PriceIndicators;
  nonExpressSection?: NonExpressSection;
  filledBagClassName?: string;
  omitTestIds?: boolean;
  renderAdditionalControlsForLineItem?:
    | ((cartLine: CartLine, isUnavailable?: boolean) => JSX.Element)
    | null;
  onCloseSideBagDrawer?: () => void;
  expressOnlySection?: ExpressOnlySection;
  shouldShowRecommendedCarousel?: boolean;
}

const DEFAULT_ORDER_LIST_KEY = 'default-order-list-key';

export const FilledBag = ({
  subtotal,
  hasCouponError,
  totalWithoutDiscount,
  alcoholRestricted,
  orderMinimum,
  cartSections,
  cartTotalPriceIndicators,
  nonExpressCartTotalPriceIndicators,
  onOpenDeliveryOptions,
  hideTimeslot,
  nonExpressSection,
  filledBagClassName,
  renderAdditionalControlsForLineItem,
  onCloseSideBagDrawer,
  expressOnlySection,
  omitTestIds = false,
  shouldShowRecommendedCarousel,
}: FilledBagProps) => {
  const { t } = useLocalization('cart');
  const unavailableCartLineIds = useReactiveVar(sectionsToBeDeletedVar);

  const {
    chosenProduct,
    shouldRenderProductModal,
    openModal: openConfigurationModal,
    closeModal: closeConfigurationModal,
  } = useConfigurationModal();
  const {
    minimumAccountPreferences: { isMasqueradeMode },
  } = useMinimumAccountPreferences();

  const { enabled: expressEnabled } = useExpressContext();
  const { openTimeslots } = useIsShowTimeslots();
  const openDeliveryWithTimeSlots = () => {
    onOpenDeliveryOptions?.();
    openTimeslots();
  };

  useEffect(() => {
    if (!!localStorageService?.read(STORAGE_KEYS.EDIT_DELIVERY)) {
      openDeliveryWithTimeSlots();
      localStorageService?.put(STORAGE_KEYS.EDIT_DELIVERY, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseConfigurationModal = () => {
    const productToFocus = chosenProduct?.product?.productId;
    closeConfigurationModal();
    setTimeout(() => {
      const editBtn = document.querySelector(
        `[data-edit-product="${productToFocus}"]`,
      ) as HTMLButtonElement;
      editBtn?.focus();
    }, 100);
  };

  const { data: timeslots, selectedTimeslot, loading: loadingTimeslots } = useTimeslots();

  const singleSection = (cartSections?.length ?? 0) === 1;

  useCartPageView({
    loading: loadingTimeslots,
    selectedTimeslot,
    timeslot: timeslots.timeslots[0],
  });

  useEffect(() => {
    sectionsToBeDeletedVar(
      getCartSectionsToBeDeleted(cartSections, nonExpressSection, expressEnabled),
    );
  }, [cartSections, nonExpressSection, expressEnabled]);

  const getSubtotalLabel = (unavailable: boolean, outOfStock: boolean, label: string) => {
    if (unavailable) {
      return t('subtotal.unavailableTitle');
    }
    if (outOfStock) return t('subtotal.outOfStockTitle');
    if (label) return t('subtotal.label', [label]);
    return t('subtotal.defaultLabel');
  };

  const isUnavailableCartline = (product: ChosenProduct) => {
    return !!nonExpressSection?.cartLines?.find((cartLine) => {
      return cartLine.id === product?.cartLineId;
    });
  };

  const expressOnlySubtotalTitle = (
    <Trans
      i18nKey="cart:subtotal.expressOnlyUnavailableTitle"
      components={[<ExpressIcon key="express" isInlineElement />, <strong key="strong" />]}
    />
  );

  return (
    <>
      <div
        className={cx(styles.content_wrapper, {
          [styles.has_recommended_carousel]: shouldShowRecommendedCarousel,
        })}
      >
        {hasCouponError && (
          <div className={styles.error_wrapper}>
            <CouponModalContainer />
          </div>
        )}
        <UnavailableCartLines
          dataTestId="express-only-cart-items-section"
          title={
            <Trans
              i18nKey="cart:expressOnlySectionTitle"
              components={[<ExpressIcon key="express" isInlineElement />, <strong key="strong" />]}
            />
          }
          subtotalLabel={expressOnlySubtotalTitle}
          subtotal={expressOnlySection?.subTotal}
          cartLines={expressOnlySection?.cartLines}
          alcoholRestricted={alcoholRestricted}
          priceIndicators={nonExpressCartTotalPriceIndicators}
          shouldShowSubtotal={true}
          isExpressOnlyCartLines={true}
        />
        <UnavailableCartLines
          dataTestId="non-express-cart-items-section"
          title={
            <Trans
              i18nKey="cart:nonExpressSectionTitle"
              components={[<ExpressIcon key="express" isInlineElement />, <strong key="strong" />]}
            />
          }
          subtotalLabel={t('subtotal.unavailableTitle')}
          subtotal={nonExpressSection?.subTotal}
          cartLines={nonExpressSection?.cartLines}
          alcoholRestricted={alcoholRestricted}
          priceIndicators={nonExpressCartTotalPriceIndicators}
          shouldShowSubtotal={true}
          openProductModal={openConfigurationModal}
        />
        {!!cartSections?.length &&
          cartSections?.map((section, index) => {
            const wineAndSpirits = section?.sectionInfo.wine;
            const wineDescription = wineAndSpirits ? t('wineDisclaimer') : undefined;
            const filled = !!section?.cartLines?.length;
            const unavailable = section.name === UNAVAILABLE;
            const outOfStock = section.name === OUT_OF_STOCK;
            const isUnavailableOnPage = !!unavailableCartLineIds?.length;

            const title = wineAndSpirits
              ? section.name
              : t(
                  `${getSectionTitle(
                    hasCouponError,
                    unavailable,
                    outOfStock,
                    isUnavailableOnPage,
                    section.name,
                  )}`,
                );

            const subtotalLabel = getSubtotalLabel(unavailable, outOfStock, section.name);
            const shouldShowSubtotal =
              !singleSection || unavailable || outOfStock || wineAndSpirits;

            return (
              filled && (
                <OrderList
                  orderListClassName={filledBagClassName}
                  key={section.name || DEFAULT_ORDER_LIST_KEY}
                  dataTestId="cart-items-section"
                  title={title}
                  subtotalLabel={subtotalLabel}
                  subtotal={section?.sectionInfo?.subTotal}
                  cartLines={section?.cartLines}
                  note={wineDescription}
                  openProductModal={openConfigurationModal}
                  isUnavailable={unavailable}
                  isOutOfStock={outOfStock}
                  alcoholRestricted={alcoholRestricted}
                  cartTotalPriceIndicators={cartTotalPriceIndicators[index]}
                  shouldShowSubtotal={shouldShowSubtotal}
                  renderAdditionalControlsForLineItem={renderAdditionalControlsForLineItem}
                />
              )
            );
          })}
        <div className={styles.order_footer}>
          <OrderFooter />
        </div>
        {isMasqueradeMode && <MasquaradeIndicators />}
      </div>
      {chosenProduct && (
        <ProductConfigurationModal
          chosenCartLineId={chosenProduct.cartLineId || ''}
          product={chosenProduct.product}
          variant={chosenProduct.variant}
          open={shouldRenderProductModal}
          onClose={onCloseConfigurationModal}
          initialValues={chosenProduct.initialValues}
          withImageTile
          isUnavailableCartline={isUnavailableCartline(chosenProduct)}
        />
      )}
      <BagFooter
        hideTimeslot={hideTimeslot}
        onCloseSideBagDrawer={onCloseSideBagDrawer}
        onOpenDeliveryOptions={onOpenDeliveryOptions}
        orderMinimum={orderMinimum}
        subtotal={subtotal}
        totalWithoutDiscount={totalWithoutDiscount}
        omitTestIds={omitTestIds}
      />
    </>
  );
};
