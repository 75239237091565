import { Dispatch, createContext, useContext } from 'react';
import { ApolloError, MutationHookOptions, Operation } from '@apollo/client';
import { LoginMode, SignUpInput, StatusLevelType, VerifyOtpInput, OtpType } from '@api';
import { AUTH_SERVICE_TYPES, LoginInput } from '@commons/auth';
import { AuthUser, USER_AUTH_STATUS } from '@modules/auth/types';
import { CallbackFunction } from '@modules/auth/hooks/useProtectedCallback';
import { STORAGE_KEYS, sessionStorageService } from '@utils/storageService';
import { AuthUserActions, AuthUserState } from './AuthUserReducer';

export interface IAuthContext {
  userInfo?: AuthUser;
  userStatus?: StatusLevelType;
  loginMode?: LoginMode;
  signup: (input: SignUpInput, callback: () => void) => void;
  signUpRequestOtp: (
    input: SignUpInput,
    successCallback?: () => void,
    failureCallback?: () => void,
  ) => void;
  verifyOtp: (
    input: VerifyOtpInput,
    serviceType?: AUTH_SERVICE_TYPES,
    successCallback?: () => void,
    failureCallback?: () => void,
  ) => void;
  sendOtp: (input: LoginInput, type: OtpType, callback?: () => void) => void;
  loginInit: (input: LoginInput, callback?: () => void) => void;
  login: (input: LoginInput, callback?: () => void) => void;
  socialLogin: (options: MutationHookOptions) => void;
  logout: () => void;
  acceptTC: () => Promise<void> | void;
  requestError: ApolloError | null;
  clearRequestError: () => void;
  resetLoginMode: () => void;
  updateAuthUser: (incomingUserInfo: AuthUser) => void;
  setProtectedCallback: (callbackFn: CallbackFunction, refusedAuthCbFn?: () => void) => void;
  isUserInfoLoading: boolean;
  isKnownUser: boolean;
  isRecognizedUser: boolean;
  isGuestUser: boolean;
  isSignedIn: boolean;
  recognizedLastOperation?: Operation;
  userAuthStatus: USER_AUTH_STATUS | undefined;
  rememberMe: boolean;
  handleSetRememberMe?: (isRememberMe: boolean, isSocialSignInModal?: boolean) => void;
  dispatch?: Dispatch<AuthUserActions>;
  state?: AuthUserState;
}

export const AuthContext = createContext<IAuthContext>({
  userStatus: undefined,
  userInfo: undefined,
  signup: () => undefined,
  signUpRequestOtp: () => undefined,
  sendOtp: () => undefined,
  verifyOtp: () => undefined,
  loginInit: () => undefined,
  login: () => undefined,
  socialLogin: () => undefined,
  logout: () => undefined,
  acceptTC: () => undefined,
  clearRequestError: () => undefined,
  resetLoginMode: () => undefined,
  updateAuthUser: () => undefined,
  setProtectedCallback: () => undefined,
  isUserInfoLoading: false,
  requestError: null,
  isKnownUser: false,
  isRecognizedUser: false,
  isGuestUser: false,
  isSignedIn: false,
  recognizedLastOperation: undefined,
  userAuthStatus: undefined,
  rememberMe: sessionStorageService?.read(STORAGE_KEYS.REMEMBER_ME) ?? false,
});

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};
