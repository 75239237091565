import React, { forwardRef, useRef, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps as muiInputProps,
} from '@mui/material';
import cx from 'classnames';
import {
  hidePassword as HidePasswordIcon,
  showPassword as ShowPasswordIcon,
} from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { AutosuggestProduct } from '@commons/product';
import styles from './Input.module.scss';

type IconPosition = 'start' | 'end';

export interface InputProps extends muiInputProps {
  propStyles?: string;
  icon?: JSX.Element;
  helperText?: string | JSX.Element;
  iconPosition?: IconPosition;
  variant?: 'default' | 'search';
  className?: string;
  maxLength?: number;
  isActive?: boolean;
  min?: number;
  max?: number;
  autoCompleteText?: AutosuggestProduct | string | null;
}

const getInputAdornment = (icon: JSX.Element, iconPosition: IconPosition, styles: string) => {
  return {
    [`${iconPosition}Adornment`]: (
      <InputAdornment className={styles} position={iconPosition}>
        {icon}
      </InputAdornment>
    ),
  };
};

const sendChangeEvent = (input: HTMLInputElement | null) => {
  if (input) {
    const changeEvent = new Event('change', { bubbles: true, cancelable: true });
    input.value = '' + input.value;
    input.dispatchEvent(changeEvent);
  }
};

export const Input = forwardRef(
  (
    {
      label,
      maxLength,
      helperText,
      disabled = false,
      error = false,
      multiline = false,
      icon,
      propStyles,
      iconPosition = 'end',
      variant = 'default',
      value,
      type,
      isActive,
      className,
      min,
      max,
      id,
      autoCompleteText,
      ...restProps
    }: InputProps,
    ref,
  ) => {
    const [shouldShowPassword, setShowPassword] = useState(false);
    const isPassword = type === 'password';
    const isSearch = type == 'search';
    const { t } = useLocalization();
    const textWhenPasswordShowed = t('common:hidePassword');
    const textWhenPasswordHidden = t('common:showPassword');
    const passwordVisibilityLabel = shouldShowPassword
      ? textWhenPasswordShowed
      : textWhenPasswordHidden;

    const inputRef = useRef<HTMLInputElement>(null);

    const togglePassword = () => {
      setShowPassword((prev) => !prev);
      setTimeout(() => {
        sendChangeEvent(inputRef.current);
      }, 0);
    };

    const getPasswordIcon = () => {
      const iconProps = {
        width: 16,
        height: 16,
      };

      return shouldShowPassword ? (
        <ShowPasswordIcon {...iconProps} aria-label={textWhenPasswordShowed} />
      ) : (
        <HidePasswordIcon {...iconProps} aria-label={textWhenPasswordHidden} />
      );
    };

    const isPasswordTogglerVisible = isPassword && value;

    const getInputType = () => {
      if (isPassword && shouldShowPassword) {
        return 'text';
      }
      return type;
    };

    const classes = cx(
      propStyles,
      {
        [styles.default_input]: variant === 'default',
        [styles.search_input]: variant === 'search',
        [styles.password_input]: type === 'password',
        [styles.is_multiline]: multiline,
      },
      className,
    );
    const wrapperClasses = cx(styles.input_wrapper, {
      [styles.error_text]: error,
    });
    const iconClasses = cx(styles.icon_true, {
      [styles.icon_false]: variant === 'default',
    });

    const srOnly = cx({
      ['sr_only']: isSearch && !(autoCompleteText || value),
    });

    const additionalProps = {
      ...(helperText && {
        'aria-describedby': `helper-text-${id}`,
      }),
    };
    return (
      <FormControl className={classes}>
        <div className={wrapperClasses}>
          {label && (
            <InputLabel
              data-shrink={isActive}
              className={cx(srOnly, {
                [styles.shrink]: isActive,
              })}
              htmlFor={id}
            >
              {label}
            </InputLabel>
          )}
          <OutlinedInput
            id={id}
            ref={ref}
            value={value}
            type={getInputType()}
            className={cx(styles.default_input, { [styles.shirked]: isActive })}
            label={label}
            disabled={disabled}
            multiline={multiline}
            inputRef={inputRef}
            inputProps={{ maxLength, min, max }}
            {...(icon && !disabled ? getInputAdornment(icon, iconPosition, iconClasses) : null)}
            {...restProps}
            {...additionalProps}
          />
          {isPasswordTogglerVisible && (
            <button
              type="button"
              onClick={togglePassword}
              className={styles.password_icon}
              data-qa="user_toggle_password"
              aria-label={passwordVisibilityLabel}
            >
              {getPasswordIcon()}
            </button>
          )}
        </div>
        {helperText && (
          <FormHelperText
            className={styles.formHelperText}
            error={Boolean(error)}
            data-qa="field_error"
            id={`helper-text-${id}`}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

Input.displayName = 'Input';
