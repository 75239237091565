import { useApolloClient, useMutation } from '@apollo/client';
import { DELETE_CART_LINE } from '@graphql/cart/mutations/deleteCartLine';
import { useRemoveFromCartEvent } from '@modules/ga/hooks/useRemoveFromCart';
import { CART_LINE_NOT_FOUND } from '@constants/errorCodes';
import { updateOrderModificationsCache } from '@hooks/cart/updateOrderModificationsCache';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { CART_CACHE_FIELD } from '@graphql/cart/queries/getCart';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { usePrevious } from '@hooks/usePrevious';
import { updateCartCache } from './updateCartCache';

export const useDeleteFromCart = (cartLineId?: string, inModifyMode?: boolean) => {
  const client = useApolloClient();
  const { trackRemoveFromCart } = useRemoveFromCartEvent();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);

  return useMutation(DELETE_CART_LINE, {
    variables: { cartLineId, inModifyMode: !!inModifyMode },
    update(cache, { data: { deleteCartLine: cart } }) {
      cache.evict({ fieldName: LIGHT_CART_CACHE_FIELD });
      cache.gc();
      updateOrderModificationsCache(cache, cart.orderModifications);
      updateCartCache(cache, cart);
    },
    onCompleted(_, options) {
      if (oldCart) {
        const lineId = options?.variables?.cartLineId ?? '';

        trackRemoveFromCart(oldCart, lineId);
      }
    },
    onError: (error) => {
      if (error.message === CART_LINE_NOT_FOUND) {
        client.cache.evict({ fieldName: CART_CACHE_FIELD });
        client.cache.gc();
      }
    },
  });
};
