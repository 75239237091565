import { useQuery } from '@apollo/client';
import { MODIFYING_ORDER_ALERT_INFO } from '@graphql/common/queries/modifyingOrderAlertInfo';
import { useOrderModalState } from '@graphql/variables/orderModalStateVar';
import { ORDER_MODAL_STATE } from '@constants/orderModalState';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useGetModifyingOrderAlertInfo = (skip?: boolean) => {
  const { setOrderModalState } = useOrderModalState();
  const { isSignedIn } = useAuthContext();
  const { data, ...restParams } = useQuery(MODIFYING_ORDER_ALERT_INFO, {
    ssr: false,
    onCompleted: (response) =>
      response.modifyingOrderAlertInfo
        ? setOrderModalState(ORDER_MODAL_STATE.MODIFYING)
        : setOrderModalState(''),
    skip: skip || !isSignedIn,
  });
  return { data: data?.modifyingOrderAlertInfo, ...restParams };
};
