import { Breadcrumb } from '@api';
import { BreadcrumbItem } from '@commons/breadcrumbs';

export const getBreadcrumbs = (breadcrumbs?: DeepPartial<Breadcrumb[]>): BreadcrumbItem[] => {
  return (breadcrumbs || []).map((breadcrumb) => ({
    id: breadcrumb?.id ?? '',
    value: breadcrumb?.value ?? '',
    url: breadcrumb?.url ?? '',
  }));
};
