import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useCart } from '@hooks/cart';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { useResetUnavailableCartItemsState } from '@hooks/cart/useResetUnavailableCartItemsState';
import { cartAdapter } from '@adapters/cartAdapter';

interface ICartForCheckoutContext {
  cartInfo: ReturnType<typeof useCart>;
}

interface ICartForCheckoutContextProvider {
  children: ReactNode;
}

enum RESET_UNAVAILABLE_DATA_STATUS {
  UNRESETTED,
  RESETTED,
  IN_PROGGRESS,
}

const { getCart } = cartAdapter();
const initialCartData = getCart();

const CartForCheckoutContext = createContext<ICartForCheckoutContext>({
  cartInfo: {
    loading: false,
    data: initialCartData,
    previousData: initialCartData,
  } as ReturnType<typeof useCart>,
});

export const useCartForCheckoutContext = () => {
  const context = useContext(CartForCheckoutContext);

  if (context === undefined) {
    throw new Error(
      'useCartForCheckoutContext must be used within a CartForCheckoutContextProvider',
    );
  }

  return context;
};

export const CartForCheckoutContextProvider = ({ children }: ICartForCheckoutContextProvider) => {
  const client = useApolloClient();
  const cartInfo = useCart();
  const [resetUnavailableCartItemsState] = useResetUnavailableCartItemsState();
  const [resetUnavailabilityCartItemsState, setResetUnavailabilityCartItensState] = useState(
    RESET_UNAVAILABLE_DATA_STATUS.UNRESETTED,
  );

  useEffect(() => {
    if (resetUnavailabilityCartItemsState === RESET_UNAVAILABLE_DATA_STATUS.UNRESETTED) {
      setResetUnavailabilityCartItensState(RESET_UNAVAILABLE_DATA_STATUS.IN_PROGGRESS);
      resetUnavailableCartItemsState({
        onCompleted: () => {
          setResetUnavailabilityCartItensState(RESET_UNAVAILABLE_DATA_STATUS.RESETTED);
        },
      });
    }
    return () => {
      resetUnavailableCartItemsState({
        onCompleted: () => {
          client.refetchQueries({ include: [GET_LIGHT_CART] });
        },
        onError: () => {
          setResetUnavailabilityCartItensState(RESET_UNAVAILABLE_DATA_STATUS.RESETTED);
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CartForCheckoutContext.Provider value={{ cartInfo }}>
      {children}
    </CartForCheckoutContext.Provider>
  );
};
