import { useQuery } from '@apollo/client';
import { Query } from '@api';
import { accountAdapter } from '@adapters/accountAdapter';
import { MINIMUM_ACCOUNT_PREFERENCES } from '@graphql/account/queries/minimumAccountPreferences';

export const useMinimumAccountPreferences = () => {
  const { getAccountPreferences } = accountAdapter();
  const { data, ...queryResult } = useQuery<Query>(MINIMUM_ACCOUNT_PREFERENCES, {
    ssr: false,
    errorPolicy: 'all',
  });

  return {
    ...queryResult,
    minimumAccountPreferences: getAccountPreferences(data?.accountPreferences),
  };
};
