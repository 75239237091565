export const AUTH_ERROR_MESSAGE = 'authorization.invalid';
export const AUTH_SIGNUP_NOT_COMPLETE = 'user.otp.signup.not.complete';
export const ORDER_TRANSACTION_FAILED = 'order.transaction.failed';
export const ORDER_ID_INVALID = 'order.id.invalid';
export const HEALTH_WARNING_ERROR_MESSAGE = 'product.health.warning.no.agreement';
export const NO_PERRMISSION_ERROR_MESSAGE = 'permission.denied';
export const PRODUCT_NOT_FOUND = 'product.not.found';
export const CART_LINE_NOT_FOUND = 'cart.line.not.found';
export const PRODUCT_DATA_INVALID = 'product.data.invalid';
export const REDIRECT = 'redirect';
export const EXCEPTION_DURING_RESERVATION = 'Exception during reservation.';
export const EMPTY_ADDRESS_ERROR =
  'You must select a residental address to reserve. Please select one from below.';
export const DELETE_DELIVERY_ADDRESS_ERROR = 'delivery.address.used.in.active.orders';

export const UNAUTHORIZED_CART_OPERATION = 'unauthorized.cart.operation';

export enum ERROR_SPECIFICATIONS {
  INCONSISTENT_EXPRESS_STATE = 'INCONSISTENT_EXPRESS_STATE',
  CART_LINES_LIMIT_EXCEEDED = 'CART_LINES_LIMIT_EXCEEDED',
}

export enum CHECKOUT_ERROR_TYPES {
  ORDER_SUBMITTING_FAILED = 'ORDER_SUBMITTING_FAILED',
  ADDRESS_NOT_SELECTED = 'checkout.address.not.selected',
  CHECKOUT_TIMESLOT_NOT_SELECTED = 'checkout.timeslot.not.selected',
  CHECKOUT_TIMESLOT_RESERVATION_FAILED = 'checkout.timeslot.reservation.failed',
  ALCOHOL_IS_RESTRICTED = 'checkout.alcohol.is.restricted',
  PAYMENT_METHOD_LIST = 'paymentMethodList',
  EXPIRATION = 'expiration',
  PAYMENT_METHOD = 'payment_method',
  AUTHORIZATION_INVALID = 'authorization.invalid',
  TIMESLOT_RESERVATION_FAILED = 'timeslot.reservation.failed',
  SMS_SETTINGS_SAVE_FAILED = 'SMS_SETTINGS_SAVE_FAILED',
  UNAVAILABLE_ITEMS = 'UNAVAILABLE_ITEMS',
  ATP_FAILURE = 'atpFailure',
  EBT = 'ebt',
  AUTHORIZATION_EXCEPTION = 'AUTHORIZATION_EXCEPTION',
  CHECKOUT_ALCOHOL_IS_RESTRICTED = 'CHECKOUT_ALCOHOL_IS_RESTRICTED',
  CHECKOUT_TIMESLOT_NOT_SELECTED_UPPERCASE = 'CHECKOUT_TIMESLOT_NOT_SELECTED',
  ORDER_SUBMIT = 'orderSubmit',
  EBT_ADDRESS_RESTRICTION = 'ebtAddressRestriction',
  CHECKOUT_DLV_PASS_ONLY_ORDER_MODIFICATION = 'checkout.dlvpass.only.order.modify',
}

export enum AUTH_ERROR_TYPES {
  SIGN_UP_DUPLICATE_USER = 'validation.user.duplicate.email',
  SIGN_UP_DUPLICATE_PHONE = 'validation.user.duplicate.phone',
  SIGN_UP_FRAUD = 'validation.fraud.exception',
}
