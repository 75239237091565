import { Typography } from '@mui/material';
import { Button, Link } from '@components/UI';
import { routing } from '@constants/routing';
import styles from '../LegalOverlay.module.scss';

interface TaxesProps {
  handleClose: () => void;
}

export const Taxes = ({ handleClose }: TaxesProps) => {
  return (
    <>
      <Typography variant="h3" className={styles.title}>
        Taxes, Deposits & Fees
      </Typography>
      <div className={styles.content}>
        <p className={styles.content_text}>
          In accordance with state law, FreshDirect charges sales tax on certain items. The tax rate
          applied may vary depending on your delivery area.
        </p>
        <p className={styles.content_text}>
          Bottle deposits are charged on relevant beverage items as required by your state.
          <Link className={styles.content_link} href={routing.issuesBottles} target="_blank">
            Redemption details
          </Link>
        </p>
        <p className={styles.content_text}>
          Delivery fees may vary depending on your delivery area.
          <Link
            className={styles.content_link}
            legacyBehavior
            href={routing.issuesFees}
            target="_blank"
          >
            Delivery fee details
          </Link>
        </p>
        <p className={styles.content_text}>
          Taxes, deposits, and fees are displayed as separate line items in your shopping cart and
          on your invoice.
        </p>
      </div>
      <Button size="large" className={styles.button} onClick={handleClose}>
        Okay
      </Button>
    </>
  );
};
