/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'react';
import router from 'next/router';
import { setCookie } from 'cookies-next';
import { useMutation } from '@apollo/client';
import { LoginInput, LoginResult, SignUpInput } from '@api';
import { logger } from '@logger';
import { useRefererInfo } from '@hooks/referAFriend/useRefererInfo';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { useEcomCart } from '@modules/ga/hooks/useEcomCart';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { openWelcomeCreateAccountModal } from '@modules/modals/events/welcomeCreateAccountModal';
import { fireUserSignupSuccessEvent } from '@modules/ga/events/custom/user-signup-success/userSignupSuccessCreator';
import { AUTH_SERVICE_TYPES } from '@commons/auth';
import { COOKIES_LIST } from '@commons/cookies';
import { AuthUser, USER_AUTH_STATUS } from '../types';
import { SIGN_UP } from '../operations/mutations/Signup';
import { homeRedirect } from '../utils/loginRedirections';
import { SERVICE_TYPE_QUERY_KEY, SIGN_UP_CACHE_FIELDS } from '../constants';
import { AUTH_USER_ACTIONS_TYPES, AuthUserActions } from '../context/AuthUserReducer';
import { handleSuccessfulAuthorization } from '../utils/handleSuccessfulAuthorization';

interface SignUpProps {
  dispatch?: Dispatch<AuthUserActions>;
}

export const useSignup = ({ dispatch }: SignUpProps) => {
  const trackEcomCart = useEcomCart();
  const { closeAuthModal } = useAuthModalContext();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const [signupMutation, { loading: signupMutationLoading }] = useMutation(SIGN_UP);
  const { refererInfo } = useRefererInfo();

  const signup = (
    { login: loginInput, serviceType, corporateInfo }: SignUpInput,
    callback: () => void,
  ) => {
    const { email, password } = loginInput as LoginInput;

    const reqObj: SignUpInput = {
      login: { email, password },
      corporateInfo: serviceType === AUTH_SERVICE_TYPES.CORPORATE ? corporateInfo : undefined,
      serviceType,
      ...(refererInfo.refererInvite && {
        referral: {
          referralPromoCode: refererInfo.refererInvite,
          referralUserCode: refererInfo.refererName,
        },
      }),
    };

    dispatch?.({
      type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
      payload: USER_AUTH_STATUS.AUTH_BEGIN,
    });
    signupMutation({
      variables: { input: reqObj },
      onError: (error) => {
        callback();
        dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR, payload: error });
        sendGtmEvent(
          GtmCustomEvent({
            event: 'user-signup-failure',
            event_name: 'signup_failure',
            ua_category: 'Signup',
            ua_action: 'signup-failure',
          }),
        );
      },
      onCompleted: (response: { signup: LoginResult }) => {
        homeRedirect();
        handleSuccessfulAuthorization(response.signup.customer as AuthUser, dispatch);
        callback();
        openWelcomeCreateAccountModal({
          onClose: () => {
            if (serviceType === AUTH_SERVICE_TYPES.CORPORATE) {
              router.push({
                query: { ...router.query, [SERVICE_TYPE_QUERY_KEY]: AUTH_SERVICE_TYPES.CORPORATE },
              });
            }
          },
        });
        closeAuthModal();
        // Fix problem with task queue after signup
        setTimeout(() => fireUserSignupSuccessEvent({ authEvent: response.signup.authEvent }), 0);
        trackEcomCart();

        if (serviceType === AUTH_SERVICE_TYPES.HOME) {
          setCookie(COOKIES_LIST.SERVICE_TYPE, serviceType);
        }

        clearCacheFields(
          serviceType === AUTH_SERVICE_TYPES.HOME
            ? [...SIGN_UP_CACHE_FIELDS, 'globalHeader', 'globalFooter']
            : SIGN_UP_CACHE_FIELDS,
        );
        dispatch?.({
          type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
          payload: USER_AUTH_STATUS.AUTH_COMPLETE,
        });
      },
    });
  };

  const signUpRequestOtp = (
    { login: loginInput, serviceType, corporateInfo }: SignUpInput,
    successCallback?: () => void,
    failureCallback?: () => void,
  ) => {
    const { email, phoneNumber } = loginInput as LoginInput;

    const reqObj: SignUpInput = {
      login: { phoneNumber, email },
      corporateInfo: serviceType === AUTH_SERVICE_TYPES.CORPORATE ? corporateInfo : undefined,
      serviceType,
      ...(refererInfo.refererInvite && {
        referral: {
          referralPromoCode: refererInfo.refererInvite,
          referralUserCode: refererInfo.refererName,
        },
      }),
    };

    signupMutation({
      variables: { input: reqObj },
      onError: (error) => {
        failureCallback?.();
        dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR, payload: error });
        logger.error(error);
        sendGtmEvent(
          GtmCustomEvent({
            event: 'user-signup-failure',
            event_name: 'signup_failure',
            ua_category: 'Signup',
            ua_action: 'signup-failure',
          }),
        );
      },
      onCompleted: () => {
        successCallback?.();
      },
    });
  };

  return {
    signup,
    signUpRequestOtp,
    isLoading: signupMutationLoading,
  };
};
