import { JSXElementConstructor, ReactElement, KeyboardEvent } from 'react';
import { Modal, Typography } from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import cx from 'classnames';
import { IconButton } from '@components/UI';
import {
  arrowLeft as ArrowLeftIcon,
  close as CloseIcon,
  warning as Warning,
} from '@assets/icons/system';
import { BACKGROUND_MODE } from '@commons/modals';
import { DATA_QA } from '@constants/dataQA';
import styles from './ModalWindow.module.scss';

export interface ModalProps {
  children: ReactElement<string | JSXElementConstructor<never>>;
  className?: string;
  titleClassName?: string;
  wrapperClassName?: string;
  modalClassName?: string;
  open: boolean;
  title?: string | JSX.Element;
  subtitle?: string | null;
  isScrollable?: boolean;
  classNameInner?: string;
  innerContentClassName?: string;
  labelId?: string;
  showCloseButton?: boolean;
  onClose: () => void;
  onKeyDownClose?: (event: KeyboardEvent<HTMLButtonElement>) => void;
  onBack?: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  errorMessage?: string;
  backgroundMode?: BACKGROUND_MODE;
  closeButtonSize?: {
    width: number;
    height: number;
  };
  hasPaddingContentHeader?: boolean;
  dataQA?: string;
}

export const ModalWindow = ({
  open,
  isScrollable,
  children,
  showCloseButton = true,
  onClose,
  onKeyDownClose,
  title,
  subtitle,
  onBack,
  className,
  titleClassName,
  wrapperClassName,
  modalClassName,
  innerContentClassName,
  labelId,
  errorMessage,
  backgroundMode,
  closeButtonSize,
  hasPaddingContentHeader,
  dataQA,
  ...props
}: ModalProps) => {
  const hasBackButton = !!onBack;
  const modalTitleID = uniqueId('modal_title_');
  const classNameInner = cx(
    styles.modal_content_inner,
    {
      [styles.modal_content_inner_small]: !hasBackButton,
    },
    innerContentClassName,
  );

  const scrollElementToTop = (el: HTMLDivElement | null) => {
    el?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const additionalProps = {
    ...((labelId && { 'aria-labelledby': labelId }) ||
      (title && { 'aria-labelledby': modalTitleID })),
  };

  return (
    <Modal
      data-testid="modal"
      {...props}
      className={cx(
        styles.modal,
        {
          [styles.transparent]: backgroundMode === BACKGROUND_MODE.TRANSPARENT,
        },
        modalClassName,
      )}
      open={open}
      onClose={onClose}
    >
      <div
        role="dialog"
        aria-modal="true"
        className={cx(styles.modal_inner, className)}
        data-qa={dataQA ?? DATA_QA.MODAL_WINDOW}
        {...additionalProps}
      >
        {hasBackButton && (
          <IconButton
            size="small"
            className={cx(styles.modal_button, styles.modal_back_button)}
            onClick={onBack}
            aria-label="back"
          >
            <ArrowLeftIcon className={styles.modal_back_button_icon} />
          </IconButton>
        )}
        {showCloseButton && (
          <IconButton
            size="small"
            className={cx(styles.modal_button, styles.modal_exit_button)}
            onClick={onClose}
            onKeyDown={onKeyDownClose}
            data-qa="popup_close_btn"
            data-testid="modal-close-button"
            aria-label="close"
          >
            <CloseIcon
              width={closeButtonSize?.width ? closeButtonSize.width : 16}
              height={closeButtonSize?.height ? closeButtonSize.height : 16}
            />
          </IconButton>
        )}
        <div
          className={cx(styles.modal_content_wrapper, props.classNameInner, wrapperClassName)}
          data-qa="popup"
          ref={hasBackButton ? scrollElementToTop : undefined}
        >
          <div id="modal-content-inner" className={classNameInner}>
            {title && (
              <div
                className={cx(styles.modal_content_inner_header, {
                  [styles.modal_content_inner_noPadding_header]: hasPaddingContentHeader,
                })}
                id="modal-content-header"
                data-testid="modal-content-header"
              >
                {!!subtitle && (
                  <p
                    className={styles.modal_content_inner_header_subtitle}
                    data-qa="popup_subtitle"
                  >
                    {subtitle}
                  </p>
                )}
                <h2
                  className={cx(styles.modal_content_inner_header_title, titleClassName)}
                  data-qa="popup_title"
                  id={modalTitleID}
                >
                  {title}
                </h2>
              </div>
            )}
            <div className={styles.error_wrapper} aria-live="polite">
              {errorMessage && (
                <>
                  <Warning width={12} height={12} className={styles.icon} />
                  <Typography className={styles.error_message} variant="body">
                    {errorMessage}
                  </Typography>
                </>
              )}
            </div>

            <div className={cx(styles.modal_content)} data-testid="modal-content">
              {children}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
