import { useRouter } from 'next/router';
import { MouseEvent, useState } from 'react';
import cx from 'classnames';
import { CircularProgress } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { useLocalization } from '@hooks/useLocalization';
import { Button, ButtonProps } from '@components/UI/Button/Button';
import { routing } from '@constants/routing';
import { Product, ProductTile } from '@commons/product';
import { isMealBundle } from '@utils/productHelper';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import { openAddToStandingOrderModal } from '@modules/modals/events/addToStandingOrder';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useCustomStandingOrders } from '@hooks/standingOrders/useStandingOrders';
import { plusV2 as PlusIcon } from '@assets/icons/system';
import styles from './StandingOrderButton.module.scss';

interface StandingOrderButtonProps extends ButtonProps {
  hidden?: boolean;
  product: Product | ProductTile;
  includeWrapper?: boolean;
  isV2?: boolean;
}

export const StandingOrderButton = ({
  product,
  onClick,
  variant = 'outlined',
  mode = 'elevated',
  isFullWidth = true,
  hidden,
  className,
  includeWrapper = false,
  isV2 = false,
  ...props
}: StandingOrderButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { setProtectedCallback } = useAuthContext();
  const fetchStandingOrders = useCustomStandingOrders();
  const { isStandingOrderAvailable, hasValidStandingOrders } = useMainLayoutContext();
  const { t } = useLocalization('standingOrder');
  const buttonText = hasValidStandingOrders ? t('addToSObutton') : t('createSObutton');
  const shouldRenderStandingOrderButton = !isMealBundle(product) && isStandingOrderAvailable;

  const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    onClick?.(e);

    if (hasValidStandingOrders) {
      setProtectedCallback(async (failure) => {
        setLoading(true);
        try {
          const { data } = await fetchStandingOrders();
          openAddToStandingOrderModal({ product, standingOrders: data });
        } catch (error) {
          failure(error as ApolloError);
        } finally {
          setLoading(false);
        }
      });
    } else {
      router.push(routing.createStandingOrder);
    }
  };
  const router = useRouter();

  if (!shouldRenderStandingOrderButton) {
    return null;
  }

  const standingOrderButton = (
    <Button
      onClick={handleClick}
      mode={mode}
      variant={variant}
      isFullWidth={isFullWidth}
      className={cx(styles.create_so_button, className, {
        [styles.is_hidden]: hidden,
        [styles.v2]: isV2,
        [styles.loading]: loading,
      })}
      loading={isV2 ? false : loading}
      {...props}
    >
      {isV2 ? (
        <>
          {!loading && <PlusIcon width={6} height={6} className={styles.label_icon} />}
          {loading && <CircularProgress size={8} className={styles.label_icon} />}
          <p className={cx(styles.label)}>{buttonText}</p>
        </>
      ) : (
        buttonText
      )}
    </Button>
  );

  if (!includeWrapper || isV2) {
    return standingOrderButton;
  }

  return <div className={styles.create_so_button_wrapper}>{standingOrderButton}</div>;
};
