import { Typography } from '@mui/material';
import { BaseLink } from '@components/UI/BaseLink/BaseLink';
import { Button } from '@components/UI/Button/Button';
import { useLocalization } from '@hooks/index';
import styles from './RenewPlan.module.scss';

export const RenewPlan = () => {
  const { t } = useLocalization('account');

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography component="p" variant="smallBody" className={styles.title}>
          {t('deliveryPass.renewPlan')}
        </Typography>
      </div>
      <div className={styles.buttons}>
        <BaseLink passHref href="/deliverypass">
          <Button variant="outlined">{t('deliveryPass.seePlans')}</Button>
        </BaseLink>
      </div>
    </div>
  );
};
