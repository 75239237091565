import Box from '@mui/material/Box';
import { TabPanelProps } from '@components/UI/Tabs/TabPanel.types';

export const TabPanel = ({ children, value, index, ...restProps }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tab-panel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...restProps}
  >
    {value === index && <Box data-testid={`tab-panel-index-${index}`}>{children}</Box>}
  </Box>
);
