import { Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import React, { FC } from 'react';
import { useNavigationTracking } from '@hooks/header/useNavigationTracking';
import { CategoryData } from '@api';
import styles from './Categories.module.scss';

export interface CategoryLinkProps {
  category: CategoryData;
  departmentName?: string | null;
  url: string;
  onCloseAll(): void;
}

export const CategoryLink: FC<CategoryLinkProps> = ({
  category,
  departmentName,
  url,
  onCloseAll,
}) => {
  const gtmProps = useNavigationTracking(`${departmentName}${category.id}`);

  return (
    <ListItem onClick={onCloseAll} className={styles.list_item}>
      <a className={styles.list_text} {...gtmProps} key={category.id} href={url}>
        <Typography noWrap>{category.name}</Typography>
      </a>
    </ListItem>
  );
};
