import { gql } from '@apollo/client';
import { RESERVED_TIMESLOT_FRAGMENT } from '../fragments/reservationTimeslotFragment';

export const RESERVED_TIMESLOTS = gql`
  query Reservations($isForce: Boolean, $includeExpress: Boolean) {
    reservations(isForce: $isForce, includeExpress: $includeExpress) {
      ...reservationTimeslotFragment
    }
  }
  ${RESERVED_TIMESLOT_FRAGMENT}
`;
