import React from 'react';
import cx from 'classnames';
import { Nutrition as NutritionAPI } from '@commons/product';
import styles from '@features/ProductPage/components/Nutrition/Nutrition.module.scss';
import { MAIN_NUTRITIONS, NESTED_NUTRITIONS } from '@features/ProductPage/constants';

export const MainItemsSingle = (nutrition: NutritionAPI) => {
  return (
    <table className={styles.table_main_section}>
      <tbody>
        {nutrition?.main?.map((nutritionItem) => {
          const shouldBeReseted = Object.values(MAIN_NUTRITIONS).includes(nutritionItem.id);
          const isDeepNested = NESTED_NUTRITIONS === nutritionItem.id;
          return (
            <tr
              key={nutritionItem.displayName}
              className={cx(styles.table_item, {
                [styles.reseted_item]: shouldBeReseted,
                [styles.deep_nested_item]: isDeepNested,
              })}
            >
              <th>
                {nutritionItem?.displayName && (
                  <span
                    className={cx({ [styles.nested]: !shouldBeReseted })}
                    dangerouslySetInnerHTML={{
                      __html: nutritionItem.displayName,
                    }}
                  />
                )}
                <span className={styles.table_item_weight}>
                  {`${nutritionItem.value}${nutritionItem.uom}`}
                </span>
              </th>
              <td>{`${nutritionItem.dailyValue} ${nutritionItem.dailyUom}`}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
