import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const DISCOUNT_FRAGMENT = gql`
  fragment discountFragment on Discount {
    amount {
      ...priceFragment
    }
    promotionDescription
    skuLimit
  }
  ${PRICE_FRAGMENT}
`;
