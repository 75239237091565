import { useMutation, useReactiveVar } from '@apollo/client';
import { useRef } from 'react';
import {
  shouldAddToCartVar,
  getShowPendingOrdersModalVar,
  setShowPendingOrdersModalVar,
  setReorderErrMsgVar,
} from '@graphql/variables/showPendingOrdersModalVar';
import { useSetPendingOrders } from '@hooks/cart/useSetShowPendingOrders';
import { useAddToCartLightEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { ADD_TO_CART_LIGHT } from '@graphql/product/mutations/addToCartLight';
import { updateLightCartCache } from '@hooks/cart/updateLightCartCache';
import {
  getAddToCartVariables,
  Params,
  useSetProductToAddToCartVar,
} from '@hooks/cart/useAddToCart';
import { areItemsAddedToCartInCurrentSession } from '@graphql/variables/areItemsAddedToCartInCurrentSession';
import { cartAdapter } from '@adapters/cartAdapter';
import { LightweightCart } from '@api';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { CartLight } from '@commons/cart';
import { updateModifyingOrderAlertCache } from '@hooks/account/updateModifyingOrderAlertCache';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { getProductIdList } from '@modules/ga/utils/getProductIdList';

const { getLightCart } = cartAdapter();

export const useAddToCartLight = (config: Params = {}) => {
  const cartBeforeMutation = useRef<CartLight | null>(null);
  const { trackAddToLightCart } = useAddToCartLightEvent();

  return useMutation(ADD_TO_CART_LIGHT, {
    variables: {
      ...getAddToCartVariables(config),
    },
    update(cache, { data: { addToCartLight: cart } }) {
      const cachedCart = cache.readQuery<{ lightweightCart: LightweightCart }>({
        query: GET_LIGHT_CART,
      });

      cartBeforeMutation.current = getLightCart(cachedCart?.lightweightCart);
      updateModifyingOrderAlertCache(cache, cart);
      updateLightCartCache(cache, cart);
    },
    onCompleted({ addToCartLight: cart }, options) {
      if (cartBeforeMutation.current) {
        const newCart = getLightCart(cart);
        const oldCart = cartBeforeMutation.current;
        const productIds = getProductIdList(options?.variables?.addToCartInput?.products ?? []);
        const itemPosition = options?.variables?.addToCartInput?.products?.[0]?.itemPosition;
        const listName = options?.variables?.addToCartInput?.products?.[0]?.itemListName;

        areItemsAddedToCartInCurrentSession(true);
        trackAddToLightCart(newCart, oldCart, { productIds, itemPosition, listName });
      }
    },
  });
};

export const useModifiedAddToCartLight = () => {
  const cartBeforeMutation = useRef<CartLight | null>(null);
  const [setIsShown] = useSetPendingOrders();
  const { productToAddToCart } = useSetProductToAddToCartVar();
  const { trackAddToLightCart } = useAddToCartLightEvent();
  const { clearCacheFields } = useCacheFieldsInvalidate();

  return useMutation(ADD_TO_CART_LIGHT, {
    // TODO: resolve issues with Interfaces of cart options
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    variables: {
      ...productToAddToCart,
    },
    update(cache, { data: { addToCartLight } }) {
      const cachedCart = cache.readQuery<{ lightweightCart: LightweightCart }>({
        query: GET_LIGHT_CART,
      });

      cartBeforeMutation.current = getLightCart(cachedCart?.lightweightCart);
      updateModifyingOrderAlertCache(cache, addToCartLight);
      updateLightCartCache(cache, addToCartLight);
    },
    onCompleted: ({ addToCartLight: cart }, options) => {
      areItemsAddedToCartInCurrentSession(true);
      shouldAddToCartVar(true);
      setShowPendingOrdersModalVar(false);
      setIsShown({
        variables: {
          isShown: true,
        },
      });

      if (cartBeforeMutation.current) {
        const newCart = getLightCart(cart);
        const oldCart = cartBeforeMutation.current;
        const productIds = getProductIdList(options?.variables?.addToCartInput?.products ?? []);
        const itemPosition = options?.variables?.addToCartInput?.products?.[0]?.itemPosition;
        const listName = options?.variables?.addToCartInput?.products?.[0]?.itemListName;

        trackAddToLightCart(newCart, oldCart, { productIds, itemPosition, listName });
      }
      clearCacheFields(['orderModifications']);
    },
    onError: (err) => {
      const errMsgsArray = err?.graphQLErrors?.[0]?.extensions?.messageArgs;
      if (!!errMsgsArray) {
        setReorderErrMsgVar(errMsgsArray as [string]);
        setShowPendingOrdersModalVar(false);
      }
    },
  });
};

export const useAddToCartLightForContext = (config: Params = {}) => {
  return useMutation(ADD_TO_CART_LIGHT, {
    variables: {
      ...getAddToCartVariables(config),
    },
    update(cache, { data: { addToCartLight: cart } }) {
      updateLightCartCache(cache, cart);
    },
  });
};

export const useModifiedAddToCartLightForContext = () => {
  const shouldUpdateModal = useReactiveVar(getShowPendingOrdersModalVar());
  const [setIsShown] = useSetPendingOrders();
  const { productToAddToCart } = useSetProductToAddToCartVar();

  return useMutation(ADD_TO_CART_LIGHT, {
    // TODO: resolve issues with Interfaces of cart options
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    variables: {
      ...productToAddToCart,
    },
    update(cache, { data: { addToCartLight } }) {
      updateModifyingOrderAlertCache(cache, addToCartLight);
      updateLightCartCache(cache, addToCartLight);
    },
    onCompleted: () => {
      shouldAddToCartVar(true);
      if (shouldUpdateModal) {
        setShowPendingOrdersModalVar(false);
        setIsShown({
          variables: {
            isShown: true,
          },
        });
      }
    },
  });
};
