import { Typography } from '@mui/material';
import cx from 'classnames';
import { Subtotal as ISubtotal } from '@commons/cart';
import { useLocalization } from '@hooks/useLocalization';
import { ProductPrice } from '@commons/product';
import { SubtotalValue } from './SubtotalValue';
import styles from './Subtotal.module.scss';

interface SubtotalProps {
  current: ISubtotal;
  previous: ProductPrice;
  dataTestId?: string;
}

export const Subtotal = ({ current, previous, dataTestId }: SubtotalProps) => {
  const { t } = useLocalization('cart');

  return (
    <div data-testid={dataTestId} className={styles.subtotal}>
      {current?.text && (
        <Typography variant="body" component="span" className={styles.subtotal_label}>
          {current.text}
        </Typography>
      )}
      <Typography
        variant="body"
        component="span"
        className={cx(styles.subtotal_label, styles.subtotal_clarification)}
      >
        {t('subtotal.clarification')}
      </Typography>
      <SubtotalValue current={current} previous={previous} />
    </div>
  );
};
