import { useMutation } from '@apollo/client';
import { updateCartCache } from '@hooks/cart/updateCartCache';
import { RESET_UNAVAILABLE_DATA } from '@graphql/cart/mutations/resetUnavailableData';

export const useResetUnavailableCartItemsState = () =>
  useMutation(RESET_UNAVAILABLE_DATA, {
    update(cache, { data: { resetUnavailabilityData } }) {
      updateCartCache(cache, resetUnavailabilityData);
    },
  });
