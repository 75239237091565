import dynamic from 'next/dynamic';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';

const DynamicModal = dynamic(() => import('./AppRedirectionModal'), {
  ssr: false,
});

export const DynamicAppRedirectionModal = () => {
  const { screenSize } = useHeaderContext();

  return screenSize?.isMobile || screenSize?.isTablet ? <DynamicModal /> : null;
};
