import { gql } from '@apollo/client';

export const ACCOUNT_PREFERENCES_FRAGMENT = gql`
  fragment accountPreferencesFragment on AccountPreferences {
    firstName
    lastName
    emailAddress
    phoneNumber
    workPhoneNumber
    workPhoneExtension
    linkedSocialProviders
    serviceType
    isChefTableAvailable
    isSocialLoginOnly
    isEligibleForPreReservation
    isMasqueradeMode
    memberSinceYear
    statusLevel
    isOnFDAccount
    notifications {
      offers
      orderNotices
      sendNewsLetter
    }
    otpLoginType
    isOtpLoginEnabled
    isStandingOrderEnabled
    isStandingOrdersExist
  }
`;
