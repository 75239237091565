import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { AddResidentialAddressModalProps } from '@modules/modals/types/addResidentailAddressModal/addResidentailAddressModal';

export const openAddResidentialAddressModal = (props: AddResidentialAddressModalProps = {}) =>
  fireOpenModal(MODALS_LIST.ADD_RESIDENTIAL_ADDRESS_MODAL, props);

export const closeAddResidentialAddressModal = () =>
  fireCloseModal(MODALS_LIST.ADD_RESIDENTIAL_ADDRESS_MODAL);
