export enum FORM_FIELDS {
  COMPANY_NAME = 'companyName',
  ADDRESS = 'address',
  APT_UNIT = 'aptUnit',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  EXTENSION = 'extension',
  INSTRUCTIONS = 'instructions',
  EMAIL = 'email',
}
