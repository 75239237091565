import { gql } from '@apollo/client';

export const PRODUCT_QUANTITY_FRAGMENT = gql`
  fragment productQuantityFragment on ProductQuantity {
    quantity
    minQuantity
    maxQuantity
    quantityIncrement
  }
`;
