import { gql } from '@apollo/client';
import { DELIVERY_PASS_INFO_FRAGMENT } from '@graphql/fragments/common/deliveryPassInfoFragment';

export const DELIVERY_PASS_INFO = gql`
  query DeliveryPassInfo {
    deliveryPassInfo {
      ...deliveryPassInfoFragment
    }
  }
  ${DELIVERY_PASS_INFO_FRAGMENT}
`;
