import { useCallback, useEffect, useState } from 'react';
import { GraphQLFormattedError } from 'graphql';
import { StatusLevelType } from '@api';
import { useAuthModalContext } from '@context/AuthModalContext';
import { AUTH_ERROR_MESSAGE, AUTH_SIGNUP_NOT_COMPLETE } from '@constants/errorCodes';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { USER_AUTH_STATUS } from '@modules/auth/types';
import { usePrevious } from '@hooks/usePrevious';
import { STORAGE_KEYS, sessionStorageService } from '@utils/storageService';

export interface FailureFunction {
  (error: Error | GraphQLFormattedError): void;
}

export interface CallbackFunction {
  (failure: FailureFunction): Promise<unknown>;
}

export const useProtectedCallback = (
  userStatus: StatusLevelType | undefined,
  userAuthStatus: USER_AUTH_STATUS | undefined,
) => {
  const [callback, setCallback] = useState<CallbackFunction | undefined>(undefined);
  const [refusedAuthCb, setRefusedAuthCb] = useState<() => void | undefined>(() => undefined);
  const { openAuthModal, isAuthModalOpen } = useAuthModalContext();
  const previousAuthModalOpen = usePrevious(isAuthModalOpen);

  const clearCallback = () => {
    setCallback(undefined);
  };

  useEffect(() => {
    const shouldClearCallback =
      !isAuthModalOpen && userStatus !== StatusLevelType.SIGNED_IN && previousAuthModalOpen;

    if (!callback) {
      return;
    }

    if (shouldClearCallback) {
      clearCallback();
    }
  }, [callback, isAuthModalOpen, previousAuthModalOpen, userStatus]);

  useEffect(
    () => {
      const shouldInvokeCallback =
        userStatus === StatusLevelType.SIGNED_IN &&
        userAuthStatus === USER_AUTH_STATUS.AUTH_COMPLETE;
      const shouldShowAuthModal =
        userStatus !== StatusLevelType.SIGNED_IN && userAuthStatus !== USER_AUTH_STATUS.AUTH_BEGIN;

      if (!callback) {
        return;
      }

      const handleError: FailureFunction = (error: Error | GraphQLFormattedError) => {
        if (error.message === AUTH_ERROR_MESSAGE) {
          openAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
          throw error;
        }
        if (error.message === AUTH_SIGNUP_NOT_COMPLETE) {
          openAuthModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT);
          throw error;
        }
      };

      if (shouldInvokeCallback) {
        callback(handleError)
          .then(clearCallback)
          .catch(() => {
            // need to hide Unhandled runtime error screen in DEV mode
          });
      } else if (shouldShowAuthModal) {
        if (sessionStorageService?.read(STORAGE_KEYS.AUTH_NOT_COMPLETE)) {
          openAuthModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT);
        } else {
          openAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, userStatus, userAuthStatus],
  );

  useEffect(() => {
    const isRefusedAuth =
      !isAuthModalOpen &&
      userStatus !== StatusLevelType.SIGNED_IN &&
      previousAuthModalOpen &&
      refusedAuthCb;
    if (isRefusedAuth) {
      refusedAuthCb();
    }
  }, [isAuthModalOpen, userStatus, refusedAuthCb, previousAuthModalOpen]);

  const setProtectedCallback = useCallback(
    (callbackFn: CallbackFunction, refusedAuthCbFn?: () => void) => {
      setCallback(() => callbackFn);
      if (refusedAuthCbFn) {
        setRefusedAuthCb(() => refusedAuthCbFn);
      }
    },
    [],
  );

  return setProtectedCallback;
};
