import {
  Button as MuiButton,
  ButtonProps as MUIButtonProps,
  CircularProgress,
} from '@mui/material';
import cx from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { UITheme } from '@commons/uiTheme';
import styles from './Button.module.scss';

export type ButtonVariant = 'contained' | 'outlined' | 'text' | 'underline';
export type TypographyVariant = 'body' | 'btn_small_bold';

export interface ButtonProps extends Omit<MUIButtonProps, 'variant'> {
  size?: 'small' | 'large';
  variant?: ButtonVariant;
  mode?: UITheme;
  isFullWidth?: boolean;
  loading?: boolean;
}

/**
 * @deprecated
 * Use ButtonV2 | ButtonOutlined | ButtonText instead
 */
export const Button = forwardRef(
  (
    {
      size = 'small',
      variant = 'contained',
      mode = 'light',
      isFullWidth = false,
      className,
      loading,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement | null>,
  ) => {
    const classes = cx(
      styles.button,
      styles[size],
      styles[`${variant}_${mode}`],
      {
        [styles.full_width]: isFullWidth,
      },
      className,
    );

    return (
      <MuiButton className={classes} disableRipple={true} ref={ref} {...props}>
        {loading ? (
          <CircularProgress data-testid="loader" size={20} className={styles.loading_icon} />
        ) : (
          props.children
        )}
      </MuiButton>
    );
  },
);

Button.displayName = 'Button';
