import { useCallback } from 'react';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';

export const useSideBagDrawerManager = () => {
  const { isSideBagDrawerOpen, setSideBagDrawerOpenStateTo } = useSideBagDrawerOpenState();
  const { cartInfo } = useCartForCheckoutContext();
  const { refetch: refetchCart } = cartInfo;
  const setSideBagDrawer = (open: boolean, event?: KeyboardEvent) => {
    if (event?.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setSideBagDrawerOpenStateTo(open);
  };
  const openSideBagDrawer = useCallback(() => {
    refetchCart();
    setSideBagDrawer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeSideBagDrawer = useCallback(() => {
    setSideBagDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    openSideBagDrawer,
    closeSideBagDrawer,
    isSideBagDrawerOpen,
  };
};
