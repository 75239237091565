import { useRef } from 'react';
import { CriteoButterfly as ICriteoButterfly } from '@commons/criteoBanner';
import { useCriteoBeacon } from '@hooks/criteo/useCriteoBeacon';
import styles from '../ButterflyProductTile.module.scss';

interface ButterflyProductButtonProps {
  index: number;
  criteoButterfly: ICriteoButterfly;
  currentProduct: number;
  handleClick: () => void;
}

export const ButterflyProductButton = ({
  index,
  criteoButterfly,
  currentProduct,
  handleClick,
}: ButterflyProductButtonProps) => {
  const buttonRef = useRef(null);

  useCriteoBeacon({
    onLoadBeacon: criteoButterfly.products[index].onImageBeacon,
    onViewBeacon: criteoButterfly.products[index].onViewBeacon,
    componentRef: buttonRef,
    isProductSelected: index === currentProduct,
  });

  const btnBgColor =
    index === currentProduct
      ? criteoButterfly.info.selectedButtonColor
      : criteoButterfly.info.buttonColor;
  const btnTextColor =
    index === currentProduct
      ? criteoButterfly.info.selectedButtonTextColor
      : criteoButterfly.info.buttonTextColor;

  return (
    <button
      onClick={handleClick}
      className={styles.banner_button}
      style={{
        backgroundColor: btnBgColor ?? '',
        color: btnTextColor ?? '',
      }}
      ref={buttonRef}
    >
      {criteoButterfly.products[index].buttonName}
    </button>
  );
};
