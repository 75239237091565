export const INIT_MASQUERADE_KEYS = [
  'agentId',
  'autoApprovalLimit',
  'customerId',
  'autoApproveAuthorized',
  'case',
  'forceOrderAvailable',
  'loginKey',
  'destination',
  'makeGoodFromOrderId',
  'modifyOrderId',
  'parentOrderId',
  'paymentId',
  'shopFromOrderId',
  'trackingOrderId',
];
