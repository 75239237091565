import { useState } from 'react';
import { PaypalLinkResult } from '@commons/paypal';

// There is no type for paypalCheckoutInstance
// eslint-disable-next-line
type PaypalCheckoutInstance = any;

export const usePaypalButton = () => {
  const [error, setError] = useState<Error | null>(null);

  const renderPaypalButton = (
    token: string,
    containerSelector: string,
    onApprove: (payload: PaypalLinkResult) => void,
  ) => {
    if (window.braintree) {
      window.braintree.client
        .create({
          authorization: token,
        })
        .then(function (clientInstance: unknown) {
          return window.braintree.paypalCheckout.create({
            client: clientInstance,
          });
        })
        .then(function (paypalCheckoutInstance: PaypalCheckoutInstance) {
          return paypalCheckoutInstance.loadPayPalSDK({ vault: true });
        })
        .then(function (paypalCheckoutInstance: PaypalCheckoutInstance) {
          return window.paypal
            .Buttons({
              style: {
                color: 'blue',
                shape: 'pill',
              },
              fundingSource: window.paypal.FUNDING.PAYPAL,

              createBillingAgreement: function () {
                return paypalCheckoutInstance.createPayment({
                  flow: 'vault', // Required
                });
              },

              onApprove: function (data: unknown) {
                return paypalCheckoutInstance
                  .tokenizePayment(data)
                  .then(function (payload: PaypalLinkResult) {
                    onApprove(payload);
                  });
              },

              onError: function (err: Error) {
                setError(err);
              },
            })
            .render(containerSelector);
        })
        .catch(function (err: Error) {
          setError(err);
        });
    }
  };

  return {
    error,
    renderPaypalButton,
  };
};
