import { ERRORS } from '@utils/mapAddressServerErrorsToTranslationKey';
import { useLocalization } from '@hooks/index';
import { ADDRESS_SERVICE_TYPE, ALT_DELIVERY_OPTION } from '@commons/deliveryAddresses';
import { validateInstructions } from '@utils/validateOtherInsctuctions';
import { validateUserName } from '@utils/validateUserName';
import { useAddressValidation } from '@hooks/useAddressValidation/useAddressValidation';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { PHONE_MASK } from '@utils/validation';
import { useValidator } from '@hooks/validation/useValidation';
import { validateApartmentRequired } from '@utils/validators';
import { ResidentialFormValues as FormValues } from '../ResidentialAddressForm.types';
import { FORM_FIELDS } from '../ResidentialAddressForm.enums';

type SetFieldError = (field: string, value: string | undefined) => void;
type SetFieldErrorHandler = (field: string) => void;

export const useResidentialAddressFormValidation = () => {
  const { validate } = useAddressValidation();
  const { t } = useLocalization();

  const requireErrorText = t('delivery.error.required');
  const notRecognizedErrorText = t('delivery.error.notRecognized');
  const validateNameErrorText = t('delivery.error.validateName');

  const formatError = (key: string) => {
    return !!ERRORS[key] ? t('delivery.error.' + ERRORS[key]) : key;
  };

  const validateAptFormat = useValidator(validateApartmentRequired);

  const isRequiredForNeighbor = (values: FormValues) =>
    values?.deliveryOption === ALT_DELIVERY_OPTION.NEIGHBOR;

  const isRequiredForUnattendedInstructions = (values: FormValues) =>
    values?.deliveryOption === ALT_DELIVERY_OPTION.UNATTENDED;

  const validateUnattendedInstructions = (value: string, values: FormValues) => {
    if (!isRequiredForUnattendedInstructions(values)) return;
    if (!value) return requireErrorText;
  };

  const validateName = (value: string) => {
    if (!value.trim()) return requireErrorText;
    if (validateUserName(value)) return validateNameErrorText;
  };

  const validateNameForNeighbor = (value: string, values: FormValues) => {
    if (!isRequiredForNeighbor(values)) return;
    return validateName(value);
  };

  const validatePhone = (value: string) => {
    if (!value) return requireErrorText;
    if (value === PHONE_MASK) return requireErrorText;
    if (value.includes('_')) return t('delivery.error.phoneNumberInvalid');
  };

  const validatePhoneForNeighbor = (value: string, values: FormValues) => {
    if (!isRequiredForNeighbor(values)) return;
    return validatePhone(value);
  };

  const validateAddress = async (
    addressString: string,
    aptUnit: string | null,
    deliveryAddress: Suggestion | null,
    setFieldError: SetFieldErrorHandler,
    setIsAptUnitAvailable: (value: boolean) => void,
    setWarnings?: (warnings: { address: string }) => void,
    aptFormatError?: string | false,
  ) => {
    if (!addressString) return requireErrorText;

    const {
      serviceTypeMessageKey,
      textError: errorKey,
      isErrorAtpUnit,
      isAptUnitAvailable,
      addressServiceType,
    } = await validate({
      aptUnit,
      address: deliveryAddress,
    });

    if (serviceTypeMessageKey) {
      setWarnings?.({ address: serviceTypeMessageKey });
    } else {
      setWarnings?.({ address: '' });
    }
    if (isAptUnitAvailable) setIsAptUnitAvailable(!aptUnit && isAptUnitAvailable);

    if (errorKey && addressString.length > 0 && !deliveryAddress) return notRecognizedErrorText;

    if (
      addressServiceType === ADDRESS_SERVICE_TYPE.CORPORATE &&
      ERRORS[errorKey] === 'commercialAddress'
    ) {
      return;
    }

    if (errorKey && !isErrorAtpUnit) {
      return formatError(errorKey);
    }

    return setFieldError('');
  };

  const validateAddressForNeighbor = async (
    values: FormValues,
    setFieldError: SetFieldError,
    neighborAddressSuggestion: Suggestion | null,
    setIsNeighborAptUnitAvailable: (value: boolean) => void,
    setWarnings?: (warnings: { address: string }) => void,
  ) => {
    if (!isRequiredForNeighbor(values)) return;

    return validateAddress(
      values?.neighborAddress,
      values?.neighborApt,
      neighborAddressSuggestion,
      (message) => setFieldError(FORM_FIELDS.NEIGHBOR_APT, message),
      setIsNeighborAptUnitAvailable,
      setWarnings,
    );
  };

  const validateAptUnit = async (
    aptUnit: string | null,
    addressString: string,
    deliveryAddress: Suggestion | null,
    setFieldError: SetFieldErrorHandler,
    aptFormatError?: string | false,
    setWarnings?: (warnings: { address: string }) => void,
  ) => {
    if (!aptUnit) return requireErrorText;

    const {
      serviceTypeMessageKey,
      textError: errorKey,
      isErrorAtpUnit,
    } = await validate({
      aptUnit,
      address: deliveryAddress,
    });

    if (serviceTypeMessageKey) {
      setWarnings?.({ address: serviceTypeMessageKey });
    } else {
      setWarnings?.({ address: '' });
    }

    if (isErrorAtpUnit) {
      return aptFormatError ? aptFormatError : formatError(errorKey);
    }

    if (errorKey) setFieldError(formatError(errorKey));
  };

  const validateAptUnitForNeighbor = async (
    values: FormValues,
    setFieldError: SetFieldError,
    neighborAddressSuggestion: Suggestion | null,
  ) => {
    if (!isRequiredForNeighbor(values)) return;

    return validateAptUnit(
      values?.neighborApt,
      values?.neighborAddress,
      neighborAddressSuggestion,
      (message) => setFieldError(FORM_FIELDS.NEIGHBOR_ADDRESS, message),
    );
  };

  const validateOtherInstructions = (value: string) => {
    if (validateInstructions(value)) return validateNameErrorText;
  };

  const validateUnattendedDelivery = async (
    deliveryAddress: Suggestion | null,
    aptUnit: string | null,
  ) => {
    const { isUnattendedDeliveryAvailable } = await validate({
      address: deliveryAddress,
      aptUnit,
    });

    return isUnattendedDeliveryAvailable;
  };

  return {
    validateAptFormat,
    validateAddress,
    validateAptUnit,
    validateName,
    validatePhone,
    validateUnattendedInstructions,
    validateAddressForNeighbor,
    validateAptUnitForNeighbor,
    validateNameForNeighbor,
    validatePhoneForNeighbor,
    validateOtherInstructions,
    validateUnattendedDelivery,
  };
};
