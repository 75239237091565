import { gql } from '@apollo/client';
import { LIGHT_CART_FRAGMENT } from '@graphql/fragments/cart/common/lightCartFragment';
import { CSR_DATA_FRAGMENT } from '@graphql/fragments/cart/common/csrData';

export const GET_MASQUERADE_CART_LIGHT = gql`
  query GetMasqueradeCartLight {
    lightweightCart {
      ...lightCartFragment
      csrData {
        ...csrDataFragment
      }
    }
  }
  ${LIGHT_CART_FRAGMENT}
  ${CSR_DATA_FRAGMENT}
`;
