import { createContext, ReactNode, useContext, useState } from 'react';
import dynamic from 'next/dynamic';
import type { ProductConfigurationModalProps } from '@components/ProductConfigurationModal/ProductConfigurationModal';
import { searchAutocompleteVar } from '@graphql/variables/searchAutocompleteVar';
import { ShoppingListProductInfo } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';
import { ProductListChannelProvider } from '@modules/ga/context/channel';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { ProductListLocationProvider } from '@modules/ga/context/location';
import { ConfOptionInput } from '@commons/order';

const DynamicProductConfigurationModal = dynamic(
  () => import('@components/ProductConfigurationModal/ProductConfigurationModal'),
  {
    ssr: false,
  },
);

type ProductConfigurationModalContextProps = Omit<ProductConfigurationModalProps, 'onClose'> & {
  hasSuggestion?: boolean;
  position?: number;
  channel?: string;
  onAddToShoppingList?: (params: ShoppingListProductInfo) => void;
  location?: string;
  title?: string;
  onClose?: (conf?: ConfOptionInput[], quantity?: number) => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
};

const ProductConfigurationModalContext = createContext<
  | {
      productConfigurationProps: ProductConfigurationModalContextProps | null;
      openConfigurationModal: (props: ProductConfigurationModalContextProps) => void;
    }
  | undefined
>(undefined);

function ProductConfigurationModalProvider({ children }: { children: ReactNode }) {
  const [productConfigurationProps, setProductConfigurationProps] =
    useState<ProductConfigurationModalContextProps | null>(null);

  const openConfigurationModal = (props: ProductConfigurationModalContextProps) => {
    setProductConfigurationProps(props);
  };

  return (
    <ProductConfigurationModalContext.Provider
      value={{ productConfigurationProps, openConfigurationModal }}
    >
      {productConfigurationProps?.product && (
        <ProductListChannelProvider channel={productConfigurationProps?.channel}>
          <ProductListLocationProvider location={productConfigurationProps?.location}>
            <ProductListTitleProvider title={productConfigurationProps?.title}>
              <DynamicProductConfigurationModal
                open={productConfigurationProps.open}
                product={productConfigurationProps.product}
                variant={productConfigurationProps.variant}
                initialValues={productConfigurationProps.initialValues}
                withImageTile={productConfigurationProps.withImageTile}
                chosenCartLineId={productConfigurationProps.chosenCartLineId}
                onAddToShoppingList={productConfigurationProps.onAddToShoppingList}
                onReplace={productConfigurationProps.onReplace}
                skipAddToCart={!!productConfigurationProps?.skipAddToCart}
                isReorderItem={productConfigurationProps.isReorderItem}
                onClose={(conf?: ConfOptionInput[], quantity?: number) => {
                  setProductConfigurationProps(null);
                  searchAutocompleteVar(productConfigurationProps.hasSuggestion);
                  productConfigurationProps.onClose?.(conf, quantity);
                }}
              />
            </ProductListTitleProvider>
          </ProductListLocationProvider>
        </ProductListChannelProvider>
      )}
      {children}
    </ProductConfigurationModalContext.Provider>
  );
}

function useProductConfigurationModal() {
  const context = useContext(ProductConfigurationModalContext);
  if (context === undefined) {
    throw new Error(
      'useProductConfigurationModal must be used within a ProductConfigurationModalProvider',
    );
  }
  return context;
}

export { ProductConfigurationModalProvider, useProductConfigurationModal };
