import { useQuery } from '@apollo/client';
import { PENDING_ORDERS_ALERTS_INFO } from '@graphql/common/queries/pendingOrdersAlertInfo';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const usePendingOrderAlerts = ({ skip = false, ...restOptions }) => {
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const { data, ...params } = useQuery(PENDING_ORDERS_ALERTS_INFO, {
    fetchPolicy: 'network-only',
    ssr: false,
    skip: !isLoggedIn || skip,
    ...restOptions,
  });

  return { ...params, data: data?.pendingOrdersAlertInfo };
};
