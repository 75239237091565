import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { Grid } from '@components/UI/Grid/Grid';
import { Alert } from '@components/UI';
import { useExpressNotification } from '@hooks/useExpressNotification';
import { ExpressIcon } from '@components/UI/ExpressIcon/ExpressIcon';
import styles from './ExpressNotificationAlert.module.scss';

export const ExpressNotificationAlert = () => {
  const { expressItemsCount, expressNotificationClosed, handleCloseNotification } =
    useExpressNotification();

  if (expressNotificationClosed || expressItemsCount === 0) return null;

  return (
    <Alert
      className={styles.alert}
      icon={false}
      onClose={handleCloseNotification}
      classes={{ action: styles.action }}
    >
      <Grid container flexDirection="column" spacing={1}>
        <Grid item>
          <ExpressIcon />
        </Grid>
        <Grid item>
          <Typography component="p" variant="caption" className={styles.text}>
            <Trans
              i18nKey="components:express.alerts.expressItems"
              values={{ count: expressItemsCount }}
              components={[
                <Typography
                  key="expressItemsCount"
                  component="span"
                  variant="caption"
                  className={styles.highlight}
                />,
              ]}
            ></Trans>
          </Typography>
        </Grid>
      </Grid>
    </Alert>
  );
};
