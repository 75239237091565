import styles from '@components/Tiles/ButterflyProductTile/ButterflyProductTile.module.scss';
interface ButterflyFooterButtonProps {
  optionalFooterRedirectUrl?: string | null;
  optionalFooterRedirectTarget?: string | null;
  optionalFooterText?: string | null;
  optionalFooterBackgroundColor?: string | null;
  optionalFooterTextColor?: string | null;
  handleClick: () => void;
}

export const ButterflyFooterButton = ({
  optionalFooterRedirectUrl,
  optionalFooterRedirectTarget,
  optionalFooterText,
  optionalFooterTextColor,
  optionalFooterBackgroundColor,
  handleClick,
}: ButterflyFooterButtonProps) => {
  return optionalFooterRedirectUrl && optionalFooterText ? (
    <a
      href={optionalFooterRedirectUrl}
      target={optionalFooterRedirectTarget ?? ''}
      className={styles.banner_button}
      style={{
        backgroundColor: optionalFooterBackgroundColor ?? '',
        color: optionalFooterTextColor ?? '',
      }}
      onClick={handleClick}
    >
      {optionalFooterText}
    </a>
  ) : null;
};
