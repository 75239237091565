import { useState } from 'react';
import { CART_MODAL_TYPES } from '../types';

export const useLegalModal = () => {
  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false);
  const [modalType, setModalType] = useState<CART_MODAL_TYPES>(CART_MODAL_TYPES.EMPTY);

  const openLegalModal = (type: CART_MODAL_TYPES) => {
    setIsLegalModalOpen(true);
    setModalType(type);
  };

  const closeLegalModal = () => {
    setIsLegalModalOpen(false);
    setModalType(CART_MODAL_TYPES.EMPTY);
  };

  return {
    isLegalModalOpen,
    modalType,
    openLegalModal,
    closeLegalModal,
    setIsLegalModalOpen,
  };
};
