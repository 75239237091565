import { StatusLevelType, ServiceType, OtpEnabledLoginType } from '@api';
import { GACustomerData } from './analytics';

export enum ACCOUNT_MANAGEMENT_PAGE {
  PREFERENCES = 'preferences',
  CREDITS = 'credits',
  PAYMENT = 'payment',
  ORDER_HISTORY = 'history',
  DELIVERY_PASS = 'delivery_pass',
  CHEF_TABLE = 'chef_table',
  DELIVERY_ADDRESSES = 'delivery',
  GIFT_CARDS = 'gift_cards',
  DELETE_CONFIRM = 'delete_confirm',
  REFER_A_FRIEND = 'refer_a_friend',
  EMPTY = '',
}

export enum ACCOUNT_MANAGEMENT_INFO {
  STANDING_ORDERS = 'standingOrders',
  FAQ = 'faq',
  STORE_CREDIT = 'storeCredit',
  CHAT = 'chat',
  CONTACT = 'contact',
}

export enum ALERT_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface AccountPreferences {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  workPhoneNumber: string | null;
  workPhoneExtension: string | null;
  serviceType: ServiceType;
  notifications: AccountNotifications;
  socialProviders: Array<string | null>;
  isChefTableAvailable: boolean;
  isSocialOnly: boolean;
  isEligibleForPreReservation: boolean;
  isMasqueradeMode: boolean;
  memberSinceYear: string;
  statusLevel: StatusLevelType;
  isOnFDAccount?: boolean;
  otpLoginType: OtpEnabledLoginType;
  isOtpLoginEnabled: boolean;
  isStandingOrderEnabled: boolean;
  hasStandingOrders: boolean;
}

export interface AccountNotifications {
  sendNewsLetter: boolean;
  orderNotices: boolean;
  offers: boolean;
}

export interface ActionResult {
  success: boolean;
  errors: ActionError;
  warnings: ActionWarning;
}

export interface ActionError {
  type: string;
  description: string;
}

export interface ActionWarning {
  type: string;
  description: string;
}

export interface AccountPasswordInput {
  password: string;
  confirmPassword: string;
}

export interface AccountMobileNotificationsInput {
  mobileNumber: string;
  orderNotices: boolean;
  offers: boolean;
}

export interface AddressInput {
  address1: string | null;
  apartment: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  zipCode: string | null;
}

export interface PhoneNumberInput {
  phone: string | null;
  extension: string | null;
}

export interface DeliveryContactInfoInput {
  firstName: string | null;
  lastName: string | null;
  phoneNumber: PhoneNumberInput[];
  instructions: string | null;
}

enum DELIVERY_SETTING {
  NONE,
  DOORMAN,
  NEIGHBOR,
  UNATTENDED,
}

export interface BackupDeliveryInfoInput {
  setting: DELIVERY_SETTING;
  contact: DeliveryContactInfoInput;
}

export interface DeliveryAddressInput {
  address: AddressInput;
  contact: DeliveryContactInfoInput;
  backupInfo: BackupDeliveryInfoInput;
}

export interface DropdownOptionsType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
}

export interface UserDetailsInfo extends GACustomerData {
  cohort: string;
  erpId: string;
  userId: string;
  expressEnabled: boolean;
  referralSignup?: boolean;
  displayRafBanner?: boolean;
}
