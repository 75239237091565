import { StandingOrder } from '@commons/standingOrder';
import { OptionType } from '@components/UI/DropDown/DropDown';

export const getStandingOrdersDropdownOptions = (
  standingOrders: StandingOrder[] = [],
): OptionType[] =>
  standingOrders.map((standingOrder) => ({
    label: standingOrder.title,
    value: standingOrder.id,
  }));
