import { useState } from 'react';
import { AlertType } from '../types';

const initialAlertState = {
  active: false,
  message: '',
};

export const useValidationAlert = () => {
  const [alert, setAlert] = useState<AlertType>(initialAlertState);
  const resetAlert = () => setAlert(initialAlertState);

  return {
    alert,
    setAlert,
    resetAlert,
  };
};
