import { Typography } from '@mui/material';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { LastDeliveredOrderInfo } from '@commons/order';
import { useContactUsModal } from '@context/contactUsModalContext';
import { Animation } from '../Animation/Animation';
import styles from './Content.module.scss';

interface OrderDeliveryModalContentProps {
  onClose: () => void;
  onKeyDown: () => void;
  onOpenContactUsModal: () => void;
  deliveredOrders: LastDeliveredOrderInfo[];
}

export const OrderDeliveryModalContent = ({
  deliveredOrders,
  onClose,
  onKeyDown,
  onOpenContactUsModal,
}: OrderDeliveryModalContentProps) => {
  const { t } = useLocalization();
  const { emit: emitContactUsModal } = useContactUsModal();
  const orderDeliveryModalTitle =
    deliveredOrders.length > 1
      ? t('orderDeliveryModal.title.other')
      : t('orderDeliveryModal.title.one');

  const handleContactUsButtonClick = () => {
    onOpenContactUsModal();
    emitContactUsModal('open');
  };

  return (
    <div className={styles.content_container}>
      <div className={styles.puller}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => onClose()}
          onKeyDown={() => onKeyDown()}
          className={styles.swipableButton}
        >
          <span className={styles.closeIcon} />
        </div>
      </div>
      <Animation />
      <div className={styles.content_wrapper}>
        <Typography variant="h4" className={styles.heading}>
          {orderDeliveryModalTitle}
        </Typography>
        {deliveredOrders.map(({ orderId }) => (
          <Typography key={orderId} variant="h4" className={styles.heading}>
            {`#${orderId}`}
          </Typography>
        ))}
        <Typography variant="smallBody" className={styles.info_text}>
          {t('orderDeliveryModal.subtitle')}
        </Typography>
        <div className={styles.controls_wrapper}>
          <Button
            className={styles.button}
            onClick={() => onClose()}
            variant="contained"
            size="large"
          >
            {t('orderDeliveryModal.ok')}
          </Button>
          <Button
            variant="outlined"
            size="large"
            className={styles.button}
            onClick={handleContactUsButtonClick}
          >
            {t('orderDeliveryModal.notOk')}
          </Button>
        </div>
      </div>
    </div>
  );
};
