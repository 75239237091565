import { gql } from '@apollo/client';

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($deletionToken: String!) {
    deleteAccount(deletionToken: $deletionToken) {
      success
      errors {
        type
        description
      }
      warnings {
        type
        description
      }
    }
  }
`;
