import { useQuery } from '@apollo/client';
import { ReservationsQuery, ReservationTimeslotData } from '@api';
import { timeslotsAdapter } from '@adapters/timeslotsAdapter';
import { RESERVED_TIMESLOTS } from '@graphql/timeslots/queries/reservedTimeslots';

export const useReservations = () => {
  const { data, ...queryParams } = useQuery<ReservationsQuery>(RESERVED_TIMESLOTS);
  const { getReservationInfo } = timeslotsAdapter();

  const reservationInfo = getReservationInfo(data?.reservations as ReservationTimeslotData);

  return {
    ...queryParams,
    data: reservationInfo,
  };
};
