import { useEffect, useState } from 'react';

export const useFetchHtml = (url?: string) => {
  const [data, setData] = useState('');
  const [error, setError] = useState<null | unknown>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (url) {
      (async function () {
        try {
          setLoading(true);
          const response = await fetch(url);
          if (response.ok) {
            const htmlContent = await response.text();
            setData(htmlContent);
          } else {
            throw new Error(`Failed to fetch ${url} (${response.status})`);
          }
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [url]);

  return { data, error, loading };
};
