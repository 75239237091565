import { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDynamicFeatureContext } from '@context/DynamicFeatureContext/DynamicFeatureContext';
import { DYNAMIC_FEATURES, getFeatureOptions, getInitialFeature } from '@utils/dynamicFeatures';
import styles from '../DevTools.module.scss';

export const Features = () => {
  const [features, setFeatures] = useState({
    selected: '',
    changed: false,
    options: '',
    invalidOptions: false,
  });
  const { enableFeature, disableFeature, isFeatureEnabled, resetAllFeatures, loading } =
    useDynamicFeatureContext();

  useEffect(() => {
    if (features.changed) {
      setFeatures((prev) => ({
        ...prev,
        changed: false,
      }));
    }
  }, [features, setFeatures]);

  const applyChanges = () => {
    if (Object.values(DYNAMIC_FEATURES).includes(features.selected as DYNAMIC_FEATURES)) {
      try {
        enableFeature(features.selected as DYNAMIC_FEATURES, JSON.parse(features.options));
        setFeatures({
          changed: true,
          selected: '',
          options: '',
          invalidOptions: false,
        });
      } catch (e) {
        setFeatures((prev) => ({
          ...prev,
          invalidOptions: true,
        }));
      }
    }
  };

  return (
    <>
      {/* @ts-expect-error details support is lacking */}
      <details name="devtab">
        <summary>Features</summary>
        <div>
          <div>
            <button
              className={styles.feature_reset}
              onClick={() => {
                resetAllFeatures();
                setFeatures((prev) => ({
                  ...prev,
                  changed: true,
                }));
              }}
            >
              Reset all
            </button>
          </div>
          <dl className={styles.features}>
            {Object.entries(DYNAMIC_FEATURES).map(([key, feature]) => {
              const options = getFeatureOptions(feature);
              const enabled = isFeatureEnabled(feature);
              const initial = getInitialFeature(feature);
              const changed =
                initial?.enabled !== enabled &&
                JSON.stringify(initial?.options) !== JSON.stringify(options);
              return (
                <div key={feature}>
                  <dt>
                    <label className={cx({ [styles.changed_feature]: changed })}>
                      <input
                        type="checkbox"
                        id="feature_{feature}"
                        checked={enabled}
                        disabled={loading}
                        onChange={() => {
                          if (enabled) {
                            disableFeature(feature);
                          } else {
                            enableFeature(feature);
                          }
                          setFeatures((prev) => ({
                            ...prev,
                            changed: true,
                          }));
                        }}
                      />
                      {key}
                    </label>
                  </dt>
                  <dd>
                    {Object.keys(options).length !== 0 && (
                      <div>
                        <code>{JSON.stringify(options)}</code>
                      </div>
                    )}
                  </dd>
                </div>
              );
            })}
          </dl>
          <hr />
          <div>
            <select
              className={styles.feature_select}
              value={features.selected}
              onChange={(e) => {
                setFeatures((prev) => ({
                  ...prev,
                  selected: e.target.value,
                  options: JSON.stringify(
                    getFeatureOptions(e.target.value as DYNAMIC_FEATURES),
                    null,
                    2,
                  ),
                  invalidOptions: false,
                }));
              }}
            >
              <option value="">Edit options</option>
              {Object.entries(DYNAMIC_FEATURES).map(([key, feature]) => (
                <option key={feature} value={feature}>
                  {key}
                </option>
              ))}
            </select>
          </div>
          <div>
            <textarea
              className={cx(styles.feature_options, {
                [styles.invalid_options]: features.invalidOptions,
              })}
              value={features.options}
              onChange={(e) => setFeatures((prev) => ({ ...prev, options: e.target.value }))}
            ></textarea>
          </div>
          <div>
            <button onClick={applyChanges}>Apply</button>
          </div>
        </div>
      </details>
    </>
  );
};
