import { Typography } from '@mui/material';
import { useLoadHtml } from '@hooks/useLoadHtml/useLoadHtml';
import styles from './InfoContent.module.scss';

interface InfoContentProps {
  children?: never;
  url: string;
  title?: string;
  className?: string;
  titleId?: string;
}

export const InfoContent = ({ url, title, className, titleId }: InfoContentProps) => {
  const htmlText = useLoadHtml(url);

  return (
    <div className={className ? className : styles.additional_info} data-testid="Additional info">
      {title && (
        <Typography
          component="h2"
          variant="h3"
          className={styles.title}
          data-testid="modal-title"
          id={titleId}
        >
          {title}
        </Typography>
      )}
      {htmlText && (
        <div
          dangerouslySetInnerHTML={{
            __html: htmlText,
          }}
        />
      )}
    </div>
  );
};
