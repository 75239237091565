import cx from 'classnames';
import { Skeleton, Typography } from '@mui/material';
import { getCurrencySymbol } from '@utils/productHelper';
import { useLocalization } from '@hooks/useLocalization';
import styles from './CartTileQuantity.module.scss';

export interface CartTileQuantityProps {
  children?: never;
  className?: string;
  unitPrice?: string | null;
  currency?: string | null;
  configurationDescription?: string | null;
  roughPricePerUnit?: string;
  pricePerScaleUnit?: string;
  formattedCurrentPrice?: string;
  loading?: boolean;
}

export const CartTileQuantity = ({
  className,
  unitPrice,
  currency,
  roughPricePerUnit,
  pricePerScaleUnit,
  loading,
  formattedCurrentPrice,
  configurationDescription,
}: CartTileQuantityProps) => {
  const { t } = useLocalization('productTile');
  const displayPricePerScaleUnit = pricePerScaleUnit || formattedCurrentPrice;
  const noData = !(roughPricePerUnit || unitPrice || displayPricePerScaleUnit);

  const renderConfigurationDescription = () => {
    const splitConfigurationDescription = configurationDescription
      ?.split('|')
      .map((description) => description.trim())
      .filter(Boolean);

    if (splitConfigurationDescription?.length === 0) {
      return null;
    }

    return splitConfigurationDescription?.map((description) => {
      return (
        <Typography
          data-testid={t('tileQuantity.ariaLabels.configurationDescription')}
          key={description}
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {description}
        </Typography>
      );
    });
  };

  if (loading) {
    return <Skeleton variant="text" />;
  }

  if (noData) {
    return null;
  }

  return (
    <div
      className={cx(styles.tile_quantity, className)}
      data-testid={t('tileQuantity.ariaLabels.quantity')}
    >
      {renderConfigurationDescription()}
      {roughPricePerUnit && (
        <Typography
          aria-label={t('tileQuantity.ariaLabels.pricePerUnit')}
          data-qa="tile_price_per_unit"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {roughPricePerUnit}
        </Typography>
      )}
      {unitPrice && (
        <Typography
          data-testid={t('tileQuantity.ariaLabels.unitPrice')}
          data-qa="tile_unit_price"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {getCurrencySymbol(currency)}
          {unitPrice}
        </Typography>
      )}
      {displayPricePerScaleUnit && (
        <Typography
          aria-label={t('tileQuantity.ariaLabels.pricePerScaleUnit')}
          data-qa="tile_price_per_scale_unit"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {displayPricePerScaleUnit}
        </Typography>
      )}
    </div>
  );
};
