import { ApolloLink } from '@apollo/client';
import { logger } from '@logger';
import { getFDUser } from '@utils/cookiesUtils';

export const authLink = (cookies?: string[]): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    logger.debug(`Operation ${operation.operationName} begins : FDUser=${getFDUser(cookies)}`);
    logger.debug('set cookie: ' + JSON.stringify(cookies));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.setContext((ctx: Record<string, any>) => {
      return {
        ...ctx,
        headers: {
          ...ctx.headers,
          Cookie: cookies?.join('; '),
        },
      };
    });
    return forward(operation);
  });
};
