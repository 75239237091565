import { getCookie, deleteCookie, setCookie } from 'cookies-next';
import { FetchResult, Operation } from '@apollo/client';
import { COOKIES_LIST } from '@commons/cookies';
import { emit } from '@modules/emitter';
import { emitAuthError, emitAuthSuccess } from '@modules/auth/utils';

export type IRecognizeServiceAction = {
  type: 'needLogin';
};

export const handleAuthError = (operation: Operation) => {
  emitAuthError(operation);
  emit({ type: 'needLogin' });
};

export const handleLogoutOperation = () => {
  deleteCookie(COOKIES_LIST.IS_SAME_USER);
};

export const handleAuthorizationOperation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>,
) => {
  if (data?.data === null) {
    return;
  }
  emitAuthSuccess();
  const prevUserId = getCookie(COOKIES_LIST.PREV_USER_ID);
  const userId = (data?.data?.login?.id || data?.data?.socialLogin?.id) ?? '';
  const isSameUser = prevUserId === userId;

  setCookie(COOKIES_LIST.IS_SAME_USER, isSameUser);
  setCookie(COOKIES_LIST.PREV_USER_ID, userId);
};
