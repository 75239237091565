import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { AddToStandingOrderModalProps } from '@modules/modals/types/addToStandingOrderModal/addToStandingOrderModal';

export const openAddToStandingOrderModal = (props: AddToStandingOrderModalProps) => {
  fireOpenModal<AddToStandingOrderModalProps>(MODALS_LIST.ADD_TO_STANDING_ORDER, {
    ...props,
  });
};

export const closeAddToStandingOrderModal = () => {
  fireCloseModal(MODALS_LIST.ADD_TO_STANDING_ORDER);
};
