import { Typography } from '@mui/material';
import cx from 'classnames';
import { PreparedDropdown } from '@components/ProductConfigurationModal/types';
import { isSingleDropdown } from '@components/ProductConfigurationModal/utils';
import { Button, DropDown } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { SingleDropdown } from '../SingleDropdown/SingleDropdown';
import { BundleOption } from './BundleOption';
import styles from './ConfigurationDropdowns.module.scss';

interface BundleDropdownsProps {
  hideComponent: boolean;
  preparedDropdown: PreparedDropdown[];
  onMoreInfoClick?: (link: string) => void;
  onShowVariationInfo?: (id: string) => void;
  checkIsShort: (index: number, arrLength: number) => void;
  dropdownChangeHandler: (newValue: string, arrIndex: number) => void;
}

export const BundleDropdowns = ({
  hideComponent,
  preparedDropdown,
  onMoreInfoClick,
  onShowVariationInfo,
  checkIsShort,
  dropdownChangeHandler,
}: BundleDropdownsProps) => {
  const { t } = useLocalization('product');

  return (
    <div className={styles.configurations} data-testid="configuration dropdowns">
      {preparedDropdown.map((variation, index) => {
        if (isSingleDropdown(variation, true)) {
          return (
            <SingleDropdown
              key={`${variation.name}_${index}`}
              className={cx(styles.configurations_dropdown_wrapper, {
                [styles.halfLine]: checkIsShort(index, preparedDropdown.length),
              })}
              variation={variation}
              index={index}
              onShowVariationInfo={onShowVariationInfo}
            />
          );
        }

        const hasMoreLink = onMoreInfoClick && variation.descrMedia;

        if (hideComponent && !hasMoreLink) return null;

        return (
          <div
            className={cx(styles.configurations_dropdown_wrapper, {
              [styles.halfLine]: checkIsShort(index, preparedDropdown.length),
            })}
            key={`${variation.name}_${index}`}
          >
            {!hideComponent && (
              <DropDown
                className={styles.configurations_dropdown}
                aria-label={t('configurationModal.ariaLabels.select')}
                value={variation.selectedValue || ''}
                customOption={BundleOption({ dropdownChangeHandler, onShowVariationInfo })}
                open={variation.isOpen}
                onChange={(value) => {
                  dropdownChangeHandler(value, index);
                }}
                options={variation.values
                  .filter(({ name, label }) => !!name && !!label)
                  .map(({ value, label, variationItemProductData, imagePath }) => ({
                    value: value ?? '',
                    label: label ?? '',
                    imagePath,
                    variationItemProductId: variationItemProductData?.productId ?? '',
                    index,
                  }))}
                title={variation.label ?? t('configurationModal.defaultLabel')}
                error={variation.hasError ? t('configurationModal.errors.required') : ''}
                id={`${variation.name}_${index}`}
              />
            )}
            {hasMoreLink && (
              <div
                className={cx(styles.more_info, {
                  [styles.more_info_without_label]: !variation.underLabel,
                })}
                aria-label={t('configurationModal.ariaLabels.moreMessage')}
              >
                <Typography component="p" variant="body">
                  {`${variation.underLabel} `}
                  <Button
                    aria-label={t('configurationModal.ariaLabels.moreLink')}
                    className={styles.more_info_button}
                    variant="underline"
                    onClick={() => {
                      onMoreInfoClick(variation.descrMedia as string);
                    }}
                  >
                    {t('configurationModal.moreLink')}
                  </Button>
                </Typography>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
