import { useReactiveVar } from '@apollo/client';
import { ConfOptionInput } from '@commons/order';
import { productToAddToOrderVar } from '@graphql/variables/orderVar';

export interface AddToOrderProduct {
  productId: string;
  configuration?: ConfOptionInput[];
  salesUnit: string;
  eventSource: string;
  quantity: number;
  categoryId: string;
  skuCode: string;
  itemListName?: string;
  itemPosition?: number;
}

export type AddToOrderProductInput = Omit<AddToOrderProduct, 'eventSource'>;

export const useSetProductToAddToOrder = () => {
  const productOptions = useReactiveVar(productToAddToOrderVar);
  const setProductToAddToOrder = (product: AddToOrderProduct) => {
    productToAddToOrderVar({
      products: [
        {
          productId: product.productId,
          quantity: product.quantity,
          configuration: product.configuration,
          salesUnit: product.salesUnit,
          categoryId: product.categoryId,
          skuCode: product.skuCode,
          itemListName: product.itemListName,
          itemPosition: product.itemPosition,
        },
      ],
      eventSource: product.eventSource,
    });
  };

  const getProductToAddToOrder = (orderId: string) => {
    return {
      input: {
        addToCartDataInput: productOptions,
        orderId,
      },
    };
  };

  return { getProductToAddToOrder, setProductToAddToOrder };
};
