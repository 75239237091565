import dynamic from 'next/dynamic';
import { MODALS_LIST } from '../types/core';
import { ModalProps } from '../types/modalProps';
import { EditDeliveryAddressProps } from '../types/editDeliveryAddressModal/editDeliveryAddressModal';
import { DeliveryPassRelatedErrorModalProps } from '../types/deliveryPassRelatedErrorModal/deliveryPassRelatedErrorModal';
import { RequestCreditModalProps } from '../types/requestCreditModal/requestCreditModal';
import { ReservationInfoModalProps } from '../types/reservationInfoModal/reservationInfoModal';
import { EditInstructionsModalProps } from '../types/editInstructionsModal/editInstructionsModal';
import { ManageShoppingListModalProps } from '../types/manageShoppingListModal/manageShoppingListModal';
import { ProductVariationModalProps } from '../types/productVariationModal/productVariationModal';
import { AddResidentialAddressModalProps } from '../types/addResidentailAddressModal/addResidentailAddressModal';
import { AddCorporateAddressModalProps } from '../types/addCorporateAddressModal/addCorporateAddressModal';
import { AddToShoppingListModalProps } from '../types/addToShoppingListModal/addToShoppingListModal';
import { CopyProductsToShoppingListModalProps } from '../types/copyProductsToShoppingListModal/copyProductsToShoppingListModal';
import { SelectStandingOrderDeliveryTimeModalProps } from '../types/selectStandingOrderDeliveryTimeModal/selectStandingOrderDeliveryTimeModal';
import { WelcomeSignInModalProps } from '../types/welcomeSignInModal/welcomeSignInModal';
import { WelcomeCreateAccountModalProps } from '../types/welcomeCreateAccountModal/welcomeCreateAccountModal';
import { AddAllToCartModalProps } from '../types/addAllToCartModal/addAllToCartModal';
import { DeleteStandingOrderProps } from '../types/deleteStandingOrderModal/deleteStandingOrderModal';
import { AlcoholRestrictionsAgreementModalProps } from '../types/alcoholRestrictionsAgreementModal/alcoholRestrictionsAgreementModal';
import { SampleProductsModalProps } from '../types/sampleProductsModal/sampleProductsModal';
import { ReceiveSMSModalProps } from '../types/ReceiveSMSModal/ReceiveSMSConfirmationModal';
import { ReceiveSMSConfirmationModalProps } from '../types/ReceiveSMSConfirmationModal/ReceiveSMSConfirmation';
import { ReservationTimeSlotsModalProps } from '../types/reservationTimeSlotsModal/reservationTimeSlotsModal';
import { DeleteAccountProps } from '../types/deleteAccountModal/deleteAccountModal';
import { NotificationAccountDeletionProps } from '../types/notificationAccountDeletionModal/notificationAccountDeletionModal';
import { StandingOrderProductListModalProps } from '../types/standingOrderProductListModal/standingOrderProductListModal';
import { SOPaymentMethodModalProps } from '../types/soPaymentMethodModal/soPaymentMethodModal';
import { AddToStandingOrderModalProps } from '../types/addToStandingOrderModal/addToStandingOrderModal';
import { AddToStandingOrderSuccessModalProps } from '../types/addToStandingOrderSuccessModal/addToStandingOrderSuccessModal';

const DynamicDeliveryPassErrorModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/DeliveryPassRelatedErrorModal/DeliveryPassRelatedErrorModal'
    ),
  {
    ssr: false,
  },
);

const DynamicRequestCreditModal = dynamic(
  () => import('@modules/modals/components/modals/RequestCreditModal/RequestCreditModal'),
  {
    ssr: false,
  },
);

const DynamicAdvancedProductsModal = dynamic(
  () => import('@modules/modals/components/modals/AdvancedProductsModal/AdvancedProductsModal'),
  {
    ssr: false,
  },
);
const DynamicReservationInfoModal = dynamic(
  () => import('@modules/modals/components/modals/ReservationInfoModal/ReservationInfoModal'),
  {
    ssr: false,
  },
);
const DynamicEditInstructionsModal = dynamic(
  () => import('@modules/modals/components/modals/EditInstructionsModal/EditInstructionsModal'),
  {
    ssr: false,
  },
);
const DynamicReceiveSMSModal = dynamic(
  () => import('@modules/modals/components/modals/ReceiveSMSModal/ReceiveSmsModal'),
  {
    ssr: false,
  },
);
const DynamicReceiveSMSConfirmationModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/ReceiveSMSConfirmationModal/ReceiveSMSConfirmationModal'
    ),
  {
    ssr: false,
  },
);

const DynamicManageShoppingListModal = dynamic(
  () => import('@modules/modals/components/modals/ManageShoppingListModal/ManageShoppingListModal'),
  {
    ssr: false,
  },
);

const DynamicReservationTimeSlotsModal = dynamic(
  () =>
    import('@modules/modals/components/modals/ReservationTimeSlotsModal/ReservationTimeSlotsModal'),
  {
    ssr: false,
  },
);

const DynamicDeleteAccountModal = dynamic(
  () => import('@modules/modals/components/modals/DeleteAccountModal/DeleteAccountModal'),
  {
    ssr: false,
  },
);

const DynamicNotificationAccountDeletionModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/NotificationAccountDeletionModal/NotificationAccountDeletionModal'
    ),
  {
    ssr: false,
  },
);

const DynamicProductVariationModal = dynamic(
  () => import('@modules/modals/components/modals/ProductVariationModal/ProductVariationModal'),
  {
    ssr: false,
  },
);

const DynamicAddResidentialAddressModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/AddResidentialAddressModal/AddResidentialAddressModal'
    ),
  {
    ssr: false,
  },
);

const DynamicAddCorporateAddressModal = dynamic(
  () =>
    import('@modules/modals/components/modals/AddCorporateAddressModal/AddCorporateAddressModal'),
  {
    ssr: false,
  },
);

const DynamicAddToShoppingListModal = dynamic(
  () => import('@modules/modals/components/modals/AddToShoppingListModal/AddToShoppingListModal'),
  {
    ssr: false,
  },
);

const DynamicCopyProductsToShoppingListModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/CopyProductsToShoppingListModal/CopyProductsToShoppingListModal'
    ),
  {
    ssr: false,
  },
);
const DynamicStandingOrderProductListModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/StandingOrderProductListModal/StandingOrderProductListModal'
    ),
  {
    ssr: false,
  },
);

const DynamicSelectStandingOrderDeliveryTimeModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/SelectStandingOrderDeliveryTimeModal/SelectStandingOrderDeliveryTimeModal'
    ),
  { ssr: false },
);

const DynamicSOPaymentMethodModal = dynamic(
  () => import('@modules/modals/components/modals/SOPaymentMethodModal/SOPaymentMethodModal'),
  {
    ssr: false,
  },
);

const DynamicWelcomeSignInModal = dynamic(
  () => import('@modules/modals/components/modals/WelcomeSignInModal/WelcomeSignInModal'),
  {
    ssr: false,
  },
);

const DynamicWelcomeCreateAccountModal = dynamic(
  () =>
    import('@modules/modals/components/modals/WelcomeCreateAccountModal/WelcomeCreateAccountModal'),
  {
    ssr: false,
  },
);

const DynamicAddAllToCartModal = dynamic(
  () => import('@modules/modals/components/modals/AddAllToCartModal/AddAllToCartModal'),
  {
    ssr: false,
  },
);

const DynamicDeleteStandingOrderModal = dynamic(
  () =>
    import('@modules/modals/components/modals/DeleteStandingOrderModal/DeleteStandingOrderModal'),
  {
    ssr: false,
  },
);

const DynamicAddToStandingOrderModal = dynamic(
  () => import('@modules/modals/components/modals/AddToStandingOrderModal/AddToStandingOrderModal'),
  {
    ssr: false,
  },
);

const DynamicAddToStandingOrderSuccessModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/AddToStandingOrderSuccessModal/AddToStandingOrderSuccessModal'
    ),

  {
    ssr: false,
  },
);

const DynamicAlcoholRestrictionsAgreementModal = dynamic(
  () =>
    import(
      '@modules/modals/components/modals/AlcoholRestrictionsAgreementModal/AlcoholRestrictionsAgreementModal'
    ),
  {
    ssr: false,
  },
);

const DynamicSampleProductsModal = dynamic(
  () => import('@modules/modals/components/modals/SampleProductsModal/SampleProductsModal'),
  {
    ssr: false,
  },
);

const DynamicEditDeliveryAddressModal = dynamic(
  () =>
    import('@modules/modals/components/modals/EditDeliveryAddressModal/EditDeliveryAddressModal'),
  {
    ssr: false,
  },
);

const DynamicMagicLinkSentModal = dynamic(
  () => import('@modules/modals/components/modals/MagicLinkSentModal/MagicLinkSentModal'),
  {
    ssr: false,
  },
);

interface ModalsFactoryProps {
  data: ModalProps;
}

const modalsMap = {
  [MODALS_LIST.DELIVERY_PASS_ERROR]: (props: DeliveryPassRelatedErrorModalProps) => (
    <DynamicDeliveryPassErrorModal {...props} />
  ),
  [MODALS_LIST.REQUEST_CREDIT]: (props: RequestCreditModalProps) => (
    <DynamicRequestCreditModal {...props} />
  ),
  [MODALS_LIST.ADVANCED_PRODUCTS]: () => <DynamicAdvancedProductsModal />,
  [MODALS_LIST.RESERVATION_INFO]: (props: ReservationInfoModalProps) => (
    <DynamicReservationInfoModal {...props} />
  ),
  [MODALS_LIST.EDIT_INSTRUCTIONS]: (props: EditInstructionsModalProps) => (
    <DynamicEditInstructionsModal {...props} />
  ),
  [MODALS_LIST.MANAGE_SHOPPING_LIST]: (props: ManageShoppingListModalProps) => (
    <DynamicManageShoppingListModal {...props} />
  ),
  [MODALS_LIST.RECEIVE_SMS]: (props: ReceiveSMSModalProps) => <DynamicReceiveSMSModal {...props} />,
  [MODALS_LIST.RECEIVE_SMS_CONFIRMATION]: (props: ReceiveSMSConfirmationModalProps) => (
    <DynamicReceiveSMSConfirmationModal {...props} />
  ),
  [MODALS_LIST.RESERVATION_TIMESLOTS_MODAL]: (props: ReservationTimeSlotsModalProps) => (
    <DynamicReservationTimeSlotsModal {...props} />
  ),
  [MODALS_LIST.DELETE_ACCOUNT_MODAL]: (props: DeleteAccountProps) => (
    <DynamicDeleteAccountModal {...props} />
  ),
  [MODALS_LIST.NOTIFICATION_ACCOUNT_DELETION]: (props: NotificationAccountDeletionProps) => (
    <DynamicNotificationAccountDeletionModal {...props} />
  ),
  [MODALS_LIST.PRODUCT_VARIATION_MODAL]: (props: ProductVariationModalProps) => (
    <DynamicProductVariationModal {...props} />
  ),
  [MODALS_LIST.ADD_RESIDENTIAL_ADDRESS_MODAL]: (props: AddResidentialAddressModalProps) => (
    <DynamicAddResidentialAddressModal {...props} />
  ),
  [MODALS_LIST.ADD_CORPORATE_ADDRESS_MODAL]: (props: AddCorporateAddressModalProps) => (
    <DynamicAddCorporateAddressModal {...props} />
  ),
  [MODALS_LIST.ADD_TO_SHOPPING_LIST_MODAL]: (props: AddToShoppingListModalProps) => (
    <DynamicAddToShoppingListModal {...props} />
  ),
  [MODALS_LIST.COPY_PRODUCTS_TO_SHOPPING_LIST_MODAL]: (
    props: CopyProductsToShoppingListModalProps,
  ) => <DynamicCopyProductsToShoppingListModal {...props} />,
  [MODALS_LIST.STANDING_ORDER_PRODUCT_LIST_MODAL]: (props: StandingOrderProductListModalProps) => (
    <DynamicStandingOrderProductListModal {...props} />
  ),
  [MODALS_LIST.SELECT_STANDING_ORDER_DELIVERY_TIME_MODAL]: (
    props: SelectStandingOrderDeliveryTimeModalProps,
  ) => <DynamicSelectStandingOrderDeliveryTimeModal {...props} />,
  [MODALS_LIST.SO_PAYMENT_METHOD_MODAL]: (props: SOPaymentMethodModalProps) => (
    <DynamicSOPaymentMethodModal {...props} />
  ),
  [MODALS_LIST.WELCOME_SIGN_IN_MODAL]: (props: WelcomeSignInModalProps) => (
    <DynamicWelcomeSignInModal {...props} />
  ),
  [MODALS_LIST.WELCOME_CREATE_ACCOUNT_MODAL]: (props: WelcomeCreateAccountModalProps) => (
    <DynamicWelcomeCreateAccountModal {...props} />
  ),
  [MODALS_LIST.ADD_ALL_TO_CART_MODAL]: (props: AddAllToCartModalProps) => (
    <DynamicAddAllToCartModal {...props} />
  ),
  [MODALS_LIST.DELETE_STANDING_ORDER_MODAL]: (props: DeleteStandingOrderProps) => (
    <DynamicDeleteStandingOrderModal {...props} />
  ),
  [MODALS_LIST.ADD_TO_STANDING_ORDER]: (props: AddToStandingOrderModalProps) => (
    <DynamicAddToStandingOrderModal {...props} />
  ),
  [MODALS_LIST.ADD_TO_STANDING_ORDER_SUCCESS]: (props: AddToStandingOrderSuccessModalProps) => (
    <DynamicAddToStandingOrderSuccessModal {...props} />
  ),
  [MODALS_LIST.ALCOHOL_RESTRICTIONS_AGREEEMENT_MODAL]: (
    props: AlcoholRestrictionsAgreementModalProps,
  ) => <DynamicAlcoholRestrictionsAgreementModal {...props} />,
  [MODALS_LIST.SAMPLE_PRODUCTS_MODAL]: (props: SampleProductsModalProps) => (
    <DynamicSampleProductsModal {...props} />
  ),
  [MODALS_LIST.EDIT_DELIVERY_ADDRESS_MODAL]: (props: EditDeliveryAddressProps) => (
    <DynamicEditDeliveryAddressModal {...props} />
  ),
  [MODALS_LIST.MAGIC_LINK_SENT_MODAL]: () => <DynamicMagicLinkSentModal />,
};

const getModal = (data: ModalProps) => {
  if (!data) {
    return null;
  }
  const type = data.type;
  // TODO: Find way to extract props type without switch\case construction\
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = data.data as any;
  return modalsMap[type](props);
};

export const ModalsFactory = ({ data }: ModalsFactoryProps) => {
  return getModal(data);
};
