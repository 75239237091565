import { useQuery } from '@apollo/client';
import { FOOTER } from '@graphql/footer/queries/footer';

export const useFooterData = () => {
  const { data, ...queryResult } = useQuery(FOOTER);

  return {
    data: data?.globalFooter,
    ...queryResult,
  };
};
