import { useMutation } from '@apollo/client';
import { SET_PRODUCT_QUANTITY_IN_LINE_ITEM } from '@graphql/cart/mutations/setProductQuantityInLineItem';
import { useAddToCartEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { updateCartCache } from '@hooks/cart/updateCartCache';
import { getCartLineByLineId } from '@modules/ga/eventBodyGetters/utils/getCartLine';
import { cartAdapter } from '@adapters/cartAdapter';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { usePrevious } from '@hooks/usePrevious';
import { updateOrderModificationsCache } from '@hooks/cart/updateOrderModificationsCache';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';

const { getCart } = cartAdapter();

export const useSetProductQuantityInLineItem = () => {
  const { trackAddToCart } = useAddToCartEvent();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);

  return useMutation(SET_PRODUCT_QUANTITY_IN_LINE_ITEM, {
    update(cache, { data: { changeCartLineQuantity } }) {
      cache.evict({ fieldName: LIGHT_CART_CACHE_FIELD });
      cache.gc();
      updateOrderModificationsCache(cache, changeCartLineQuantity.orderModifications);
      updateCartCache(cache, changeCartLineQuantity);
    },
    onCompleted({ changeCartLineQuantity: cart }, options) {
      if (oldCart) {
        const newCart = getCart(cart);
        const gaCartLine = getCartLineByLineId(oldCart, options?.variables?.lineItemId);
        const productId = gaCartLine?.product.productId ?? '';
        const itemPosition = gaCartLine?.itemPosition ?? 0;
        const itemListName = gaCartLine?.itemListName ?? '';

        trackAddToCart({
          newCart,
          oldCart,
          productIds: [productId],
          itemPosition,
          listName: itemListName,
        });
      }
    },
  });
};
