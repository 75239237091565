import { useMemo } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { timeslotsAdapter } from '@adapters/timeslotsAdapter';
import { TIMESLOTS } from '@graphql/timeslots/queries/timeslots';
import {
  DeliveryTimeslotData,
  StandingOrderMode,
  TimeslotsQuery,
  TimeslotsQueryVariables,
} from '@api';
import { useModifyOrderContext } from '@context/ModifyOrderContext/ModifyOrderContext';
import { getExpressTimeslot, getNonExpressTimeslots, sortTimeslots } from '@utils/timeslots';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';

interface UseTimeslotsArgs {
  queryOptions?: QueryHookOptions<TimeslotsQuery, TimeslotsQueryVariables>;
}

interface DeliveryTimeslotResponse {
  timeslots: DeliveryTimeslotData;
}

const { getTimeslots, getTimeslotById } = timeslotsAdapter();

export const useTimeslots = (queryOptions: UseTimeslotsArgs = {}) => {
  const standingOrderMode = useStandingOrderMode();
  const { isExpressOrder } = useModifyOrderContext();
  const { data, ...rest } = useQuery<DeliveryTimeslotResponse>(TIMESLOTS, {
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first',
    variables: {
      includeExpress: true,
      standingOrderMode: isExpressOrder ? StandingOrderMode.NONE : standingOrderMode,
    },
    ...queryOptions.queryOptions,
  });

  const timeSlots = getTimeslots(data?.timeslots);
  const selectedTimeslotId = data?.timeslots?.grid?.selectedTimeslotId;
  const expressTimeslot = useMemo(
    () => getExpressTimeslot(timeSlots.timeslots, isExpressOrder),
    [timeSlots.timeslots, isExpressOrder],
  );
  const nonExpressTimeslots = useMemo(
    () => getNonExpressTimeslots(timeSlots, isExpressOrder),
    [timeSlots, isExpressOrder],
  );
  const sortedNonExpressTimeslots = useMemo(
    () => ({
      ...nonExpressTimeslots,
      timeslots: [...nonExpressTimeslots.timeslots].sort(sortTimeslots),
    }),
    [nonExpressTimeslots],
  );

  const selectedFrequency = data?.timeslots.grid?.standingOrder?.selectedFrequency;

  return {
    ...rest,
    data: timeSlots,
    nonExpressTimeslots: sortedNonExpressTimeslots,
    expressTimeslot,
    standingOrderFrequencyOptions: data?.timeslots.grid?.standingOrder?.frequencyOptions ?? [],
    selectedFrequency: !selectedFrequency || selectedFrequency === '0' ? '1' : selectedFrequency,
    selectedTimeslot:
      selectedTimeslotId && timeSlots ? getTimeslotById(timeSlots, selectedTimeslotId) : null,
    selectedTimeslotId,
  };
};
