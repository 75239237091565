import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLocalization } from '@hooks/useLocalization';
import { useGiftCardModal } from '@hooks/giftCard/useGiftCardModal';
import { useResendGiftCard } from '@hooks/giftCard/useResendGiftCard';
import { UseGiftCardPurchaseInfoData } from '@hooks/giftCard/useGiftCardPurchaseInfo';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './GiftCardModal.module.scss';
import { Values } from './types';
import { GiftCardForm } from './components/GiftCardForm/GiftCardForm';
import { ResendView } from './components/ResendView/ResendView';

interface ResendGiftCardModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  values: Values;
  templateData: UseGiftCardPurchaseInfoData;
  saleId: string;
}

export const ResendGiftCardModal = ({
  open,
  onClose,
  onSuccess,
  values,
  templateData,
  saleId,
}: ResendGiftCardModalProps) => {
  const { t } = useLocalization('giftCards');
  const [resendGiftCard] = useResendGiftCard();
  const { formRef, handleClose, handleOpen } = useGiftCardModal();

  const handleSubmit = (savedValues: Values) => {
    resendGiftCard({
      variables: {
        input: {
          certNum: savedValues.giftCardId,
          recipientName: savedValues.recipientName,
          recipientEmail: savedValues.recipientEmail,
          personalMessage: savedValues.recipientMessage ?? '',
          saleId,
        },
      },
      onCompleted: () => onSuccess(),
      onError: () => onClose(),
    });
  };

  return (
    <ModalWindow
      open={open}
      onClose={onClose}
      className={styles.modal_wrapper}
      labelId={MODAL_TITLE_ID.RESEND_GIFT_CARD}
    >
      <>
        <Typography
          variant="h3"
          component="h2"
          className={styles.title}
          id={MODAL_TITLE_ID.RESEND_GIFT_CARD}
        >
          {t('giftCardModal.resendTitle')}
        </Typography>
        <GiftCardForm
          values={values}
          isCustomAmountEditShown={false}
          onOpenCustomAmountForm={handleOpen}
          onCloseCustomAmountForm={handleClose}
          onSubmit={handleSubmit}
          loading={false}
          submitButtonText={'giftCardModal.resendButton'}
          formRef={formRef}
          templateData={templateData}
          customRender={ResendView}
        />
      </>
    </ModalWindow>
  );
};
