import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { AlcoholRestrictionsAgreementModalProps } from '@modules/modals/types/alcoholRestrictionsAgreementModal/alcoholRestrictionsAgreementModal';

export const openAlcoholRestrictionsAgreementModal = (
  props: AlcoholRestrictionsAgreementModalProps,
) => {
  fireOpenModal<AlcoholRestrictionsAgreementModalProps>(
    MODALS_LIST.ALCOHOL_RESTRICTIONS_AGREEEMENT_MODAL,
    {
      ...props,
    },
  );
};

export const closeAlcoholRestrictionsAgreementModal = () => {
  fireCloseModal(MODALS_LIST.ALCOHOL_RESTRICTIONS_AGREEEMENT_MODAL);
};
