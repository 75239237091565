import { gql } from '@apollo/client';
import { BREADCRUMB_FRAGMENT } from '../common/breadcrumbFragment';
import { IMAGE_FRAGMENT } from '../common/imageFragment';
import { NUTRITION_FRAGMENT, NUTRITION_V2_FRAGMENT } from './common/nutritionFragments';
import { WINE_AND_SPIRITS_FRAGMENT } from './common/wineAndSpiritsFragment';
import { PRODUCT_BASIC_FRAGMENT } from './productBasicFragment';

export const PRODUCT_EXTENDED_FRAGMENT = gql`
  fragment productExtendedFragment on ProductExtendedData {
    productId
    breadcrumbs {
      ...breadcrumbFragment
    }
    productBasicData {
      ...productBasicFragment
    }
    allergens
    freshnessGuarantee
    ingredients
    extraDescription
    claims
    organicClaims
    kosherIconPath
    kosherPopupFooterPath
    kosherPopupHeaderPath
    kosherPopupPath
    kosherSymbol
    kosherType
    productDescriptionNote
    origin
    seasonText
    productAboutMediaPath
    nutrition {
      ...nutritionFragment
    }
    nutritionV2 {
      ...nutritionV2Fragment
    }
    oldNutritionContent {
      name
      value
      uom
    }
    heatingInstructions
    instructionLink
    wineData {
      ...wineAndSpiritsFragment
    }
    productImage {
      ...imageFragment
    }
    productImageCarousel {
      ...imageFragment
    }
    adProducts {
      products {
        ...productBasicFragment
      }
      criteoFormatBeaconInfo {
        onViewBeacon
        onClickBeacon
        onLoadBeacon
      }
    }
    productAnnotationData {
      annotationData {
        key
        value
      }
      erpsyLink
    }
    upsellProductsCarousel {
      products {
        ...productBasicFragment
      }
    }
    crossSellProductsCarousel {
      products {
        ...productBasicFragment
      }
    }
  }
  ${BREADCRUMB_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${NUTRITION_FRAGMENT}
  ${NUTRITION_V2_FRAGMENT}
  ${WINE_AND_SPIRITS_FRAGMENT}
  ${PRODUCT_BASIC_FRAGMENT}
`;
