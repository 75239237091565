import {
  chefTable as ChefTableIcon,
  credit as CreditIcon,
  delivery as DeliveryIcon,
  deliveryPass as deliveryPassIcon,
  giftCards as GiftCardsIcon,
  helpCenter as HelpCenterIcon,
  orderHistory as OrderHistoryIcon,
  payment as PaymentIcon,
  phone as Phone,
  preferences as PreferencesIcon,
  standingOrders as StandingOrdersIcon,
} from '@assets/icons/account';
import { ACCOUNT_MANAGEMENT_INFO, ACCOUNT_MANAGEMENT_PAGE } from '@commons/account';
import { routing } from '@constants/routing';
import {
  CALLBACK,
  DropDownInfoLink,
  DropDownLink,
} from '../TopLine/components/Account/AccountMenuSection';

//TODO: implement translations for "name"
export const getAccountSettingsMain: (showStandingOrders?: boolean) => DropDownLink[] = (
  showStandingOrders?: boolean,
) => {
  const accountSettingsMainFirstPart: DropDownLink[] = [
    {
      name: 'Account preferences',
      icon: PreferencesIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.PREFERENCES,
      link: routing.preferences,
    },
    {
      name: 'Delivery addresses',
      icon: DeliveryIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.DELIVERY_ADDRESSES,
      link: routing.delivery,
    },
    {
      name: 'Payment methods',
      icon: PaymentIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.PAYMENT,
      link: routing.payment,
    },
    {
      name: 'Order history',
      icon: OrderHistoryIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.ORDER_HISTORY,
      link: routing.orderHistory,
    },
  ];
  const accountSettingsMainSecondPart: DropDownLink[] = [
    {
      name: 'DeliveryPass',
      icon: deliveryPassIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.DELIVERY_PASS,
      link: routing.accountDeliveryPass,
    },
    {
      name: 'Chef’s Table',
      icon: ChefTableIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.CHEF_TABLE,
      link: routing.chefTable,
    },
    {
      name: 'Store credit',
      icon: CreditIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.CREDITS,
      link: routing.credits,
    },
    {
      name: 'Gift cards',
      icon: GiftCardsIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.GIFT_CARDS,
      link: routing.giftCards,
    },
    {
      name: 'Refer a friend',
      icon: GiftCardsIcon,
      id: ACCOUNT_MANAGEMENT_PAGE.REFER_A_FRIEND,
      link: routing.referAFriend,
    },
  ];
  const standingOrders: DropDownLink = {
    name: 'Standing orders',
    icon: StandingOrdersIcon,
    id: ACCOUNT_MANAGEMENT_INFO.STANDING_ORDERS,
    link: routing.standingOrders,
  };
  return showStandingOrders
    ? [...accountSettingsMainFirstPart, standingOrders, ...accountSettingsMainSecondPart]
    : [...accountSettingsMainFirstPart, ...accountSettingsMainSecondPart];
};

export const accountSettingsInfo: DropDownInfoLink[] = [
  {
    name: 'FAQ',
    icon: HelpCenterIcon,
    id: ACCOUNT_MANAGEMENT_INFO.FAQ,
    link: routing.help,
  },
  {
    name: 'Contact us',
    icon: Phone,
    id: ACCOUNT_MANAGEMENT_INFO.CONTACT,
    link: '#',
    callback: CALLBACK.OPEN_CONTACT_US,
  },
];
