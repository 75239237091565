import { FC } from 'react';
import { DropdownMenu } from '@api';
import { CategoryLink } from '../CategoriesSection/CategoryLink';

interface DropdownMenuContentProps {
  menu: DropdownMenu;
}

export const DropdownMenuContent: FC<DropdownMenuContentProps> = ({
  menu: { menuItems, id: menuId },
}) => {
  if (!menuItems?.length) {
    return null;
  }

  return (
    <>
      {menuItems.map((item, index) => {
        if (!item?.link) {
          return null;
        }
        const { link, name } = item;
        return <CategoryLink key={`nav_linkId_${index}`} name={name} tabIndex={-1} href={link} />;
      })}
    </>
  );
};
