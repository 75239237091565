import { OtpType } from '@api';
import { Customer } from '@commons/customer';

export enum AUTH_SERVICE_TYPES {
  HOME = 'HOME',
  CORPORATE = 'CORPORATE',
}

export enum AUTH_TYPES {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum AUTH_VERIFICATION_TYPES {
  OTP = 'OTP',
  PASSWORD = 'PASSWORD',
}

export enum AUTH_MODAL_TYPES {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  CREATE_ACCOUNT_WELCOME = 'CREATE_ACCOUNT_WELCOME',
  MAGIC_LINK_SENT = 'MAGIC_LINK_SENT',
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_PHONE = 'SIGN_IN_PHONE',
  SIGN_IN_PASSWORD = 'SIGN_IN_PASSWORD',
  SIGN_IN_WELCOME = 'SIGN_IN_WELCOME',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  SENT_REQUEST = 'SENT_REQUEST',
  CONTACT_US = 'CONTACT_US',
  SOCIAL_SIGN_IN = 'SOCIAL_SIGN_IN',
  SOCIAL_CREATE_ACCOUNT = 'SOCIAL_CREATE_ACCOUNT',
  TCACKNOWLEDGEMENT = 'TCACKNOWLEDGEMENT',
  OTP_VERIFICATION = 'OTP_VERIFY',
}

export type OtpDataType = {
  loginInput: {
    email?: string;
    phoneNumber?: string;
    rememberMe?: boolean;
    mfaToken?: string;
  };
  otpType: OtpType;
  serviceType?: AUTH_SERVICE_TYPES;
};

export interface BaseAction {
  type: AUTH_MODAL_TYPES;
}
export interface OtpAction extends BaseAction {
  data?: OtpDataType;
}
export interface ServiceAction extends BaseAction {
  data?: AUTH_SERVICE_TYPES;
}

export interface MagicLinkAction extends BaseAction {
  data?: MagicLinkSentModalDataType;
}

export interface FlowTypeAction extends BaseAction {
  data?: AUTH_TYPES;
}

export interface ErrorStateDataAction extends BaseAction {
  data?: ErrorDataType;
}

export type Action =
  | BaseAction
  | OtpAction
  | ServiceAction
  | MagicLinkAction
  | FlowTypeAction
  | ErrorStateDataAction;

export interface AuthModalStateProps {
  isAuthModals: boolean;
  isContactUs: boolean;
  isCreateAccountModal: boolean;
  isCreateAccountWelcomeModal: boolean;
  isForgotPassword: boolean;
  isSentRequestModal: boolean;
  isSignInModal: boolean;
  isSignInEmailModal: boolean;
  isSignInPhoneModal: boolean;
  isSignInPasswordModal: boolean;
  isSignInWelcomeModal: boolean;
  isWelcomeModal: boolean;
  isResetPasswordModals: boolean;
  isSocialSignInModal: boolean;
  isSocialCreateAccountModal: boolean;
  isSocialsModal: boolean;
  isTCAcknowledgementModal: boolean;
  isOtpModal: boolean;
  isMagicLinkSentModal: boolean;
  currentModal: AUTH_MODAL_TYPES;
  otpData?: OtpDataType;
  magicLinkSentModalData?: MagicLinkSentModalDataType;
  shouldShowSignInSwitch: boolean;
  shouldShowSignUpSwitch: boolean;
  serviceType?: AUTH_SERVICE_TYPES;
  initialFlowType?: AUTH_TYPES;
  errorStateData?: ErrorDataType;
}

export type AuthInfo = Partial<Customer> | null;

export interface LoginInput {
  email?: string;
  password: string;
  mfaToken?: string;
  rememberMe?: boolean;
  phoneNumber?: string;
}

export type MagicLinkSentModalDataType = {
  email: string;
};

export type ErrorDataType = {
  input: string;
};

export interface AuthFormValueTypes {
  companyName: string;
  firstName: string;
  lastName: string;
  workPhone: string;
  zipCode: string;
  serviceType: AUTH_SERVICE_TYPES;
  phoneNumber?: string;
  email?: string;
  password?: string;
}

export enum REDIRECT_STATUS {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}
