import { createContext, Dispatch, useContext } from 'react';
import { ApolloClient } from '@apollo/client';
import { FILTER_IDS } from '@commons/reorder';
import { Facet, FacetValue, FACET_SELECTION_TYPE, FACET_TYPES } from '@commons/filters';

export type ExpressContextState = {
  enabled: boolean;
  eligible: boolean;
  saveExpressFilterToStorage: Dispatch<boolean>;
  getExpressFilterFromStorage: () => void;
  toggle: (value: boolean, shouldCleanCache?: boolean) => void;
  client?: ApolloClient<object>;
};

export const ExpressContext = createContext<ExpressContextState>({
  enabled: false,
  eligible: false,
  saveExpressFilterToStorage: () => {
    // stub
  },
  getExpressFilterFromStorage: () => {
    //  stub
  },
  toggle: () => {
    // stub
  },
});

export const useExpressContext = () => useContext(ExpressContext);

// Represetns the facet group in the Refine filter
// for the context switcher to be displayed at
export const EXPRESS_RELATED_FACET_ID = 'pfg_shop_by';

// The same, but for the Reorder page. There is a different facet group.
export const EXPRESS_REORDER_RELATED_FACET_ID = FILTER_IDS.SHOW_ME_ONLY;

export const EXPRESS_UI_SWITCHER_ID = 'shop_by_express_switcher';

export const SYNTHETIC_EXPRESS_FACET: FacetValue = {
  id: EXPRESS_UI_SWITCHER_ID,
  active: true,
  name: 'Express',
  selected: false,
  count: null,
  synthetic: true,
};

export const SYNTHETIC_SHOP_BY_FACET_GROUP: Facet = {
  id: EXPRESS_RELATED_FACET_ID,
  name: 'Shop By',
  value: [SYNTHETIC_EXPRESS_FACET],
  type: FACET_TYPES.FILTER,
  selectionType: FACET_SELECTION_TYPE.MULTI,
  synthetic: true,
};
