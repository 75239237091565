import { QueryHookOptions, useQuery } from '@apollo/client';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { accountAdapter } from '@adapters/accountAdapter';
import { PaymentMethodsQueryVariables, PaymentMethodsQuery } from '@api';

export const usePaymentMethods = (
  options: QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables> = {
    fetchPolicy: 'network-only',
  },
) => {
  const { getFullPaymentData } = accountAdapter();
  const { data, ...queryResult } = useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PAYMENT_METHODS,
    options,
  );

  const paymentData = getFullPaymentData(data?.paymentMethods);

  return { ...queryResult, data: paymentData };
};
