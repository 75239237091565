import { useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { useAccountPreferences } from '@hooks/useAccountPreferences';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ALERT_TYPES } from '@commons/account';
import { useLocalization } from '@hooks/useLocalization';
import { Button, FormikInput } from '@components/UI';
import { validateUserName } from '@utils/validateUserName';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { PageBlock } from '../PageBlock/PageBlock';
import styles from './PersonalInfo.module.scss';

enum FIELDS {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
}

const MAX_LENGTH = 20;

export const PersonalInfo = () => {
  const { updateAccountName } = useAccountPreferences();
  const { dispatchAlert } = useAccountAlertContext();
  const { userInfo: customer } = useAuthContext();
  const { t } = useLocalization('account');
  const titleRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (values: { [key in FIELDS]: string }) => {
    const firstName = values.first_name;
    const lastName = values.last_name;
    const nameChanged = firstName !== customer?.firstName || lastName !== customer?.lastName;
    if (nameChanged) {
      updateAccountName(firstName, lastName);
    }
  };

  const validateName = (value?: string) => {
    if (!value) return t('preferences.errors.minLength');
    if (validateUserName(value)) return t('preferences.alerts.message.userName');
  };

  const handleValidationError = (isValid: boolean) => {
    !isValid && dispatchAlert(ALERT_TYPES.ERROR, t('preferences.alerts.message.userName'));
    titleRef?.current?.focus();
  };

  return (
    <PageBlock title={t('preferences.name')} ref={titleRef}>
      <Formik
        initialValues={{
          [FIELDS.FIRST_NAME]: customer?.firstName || '',
          [FIELDS.LAST_NAME]: customer?.lastName || '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ errors, validateField, isValid }) => (
          <Form className={styles.form}>
            <Field
              name={FIELDS.FIRST_NAME}
              control="input"
              maxLength={MAX_LENGTH}
              type="text"
              className={styles.input}
              label={t('preferences.firstName')}
              validate={validateName}
              component={FormikInput}
              error={!!errors[FIELDS.FIRST_NAME]}
              helperText={errors[FIELDS.FIRST_NAME]}
              onBlur={() => validateField(FIELDS.FIRST_NAME)}
              id={FIELDS.FIRST_NAME}
              autoComplete="given-name"
            />
            <Field
              name={FIELDS.LAST_NAME}
              control="input"
              type="text"
              className={styles.input}
              maxLength={MAX_LENGTH}
              label={t('preferences.lastName')}
              validate={validateName}
              component={FormikInput}
              error={!!errors[FIELDS.LAST_NAME]}
              helperText={errors[FIELDS.LAST_NAME]}
              onBlur={() => validateField(FIELDS.LAST_NAME)}
              id={FIELDS.LAST_NAME}
              autoComplete="family-name"
            />
            <Button
              data-testid="password-submit"
              type="submit"
              size="large"
              fullWidth
              onClick={() => handleValidationError(isValid)}
              className={styles.button}
            >
              {t('preferences.updateName')}
            </Button>
          </Form>
        )}
      </Formik>
    </PageBlock>
  );
};
