import { sendGtmEvent } from '@modules/ga/gtm-event';
import { addToRefineEvent } from '@modules/ga/eventBodyGetters/addToRefineEvent';

export interface RefineResultOptions {
  category: string;
  label: string;
}

export const refineResultHandler = ({ category, label }: RefineResultOptions) => {
  sendGtmEvent(
    addToRefineEvent({
      category,
      label,
    }),
  );
};
