import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { PaymentsType } from '@commons/payment';
import styles from './UnlinkPaymentMethodButton.module.scss';

interface UnlinkPaymentMethodButtonProps {
  onUnlink: () => void;
  type?: PaymentsType;
  email?: string | null;
}

export const UnlinkPaymentMethodButton = ({
  onUnlink,
  type,
  email,
}: UnlinkPaymentMethodButtonProps) => {
  const { t } = useLocalization('checkout');

  return (
    <Button
      className={styles.button}
      onClick={onUnlink}
      variant="underline"
      aria-label={`${t('unlinkButton')} ${type} ${email}`}
    >
      {t('unlinkButton')}
    </Button>
  );
};
