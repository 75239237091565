import { GET_USER_GEOLOCATION_ADDRESS } from '@graphql/account/queries/userGeolocationAddress';
import { GET_USER_DELIVERY_ADDRESSES } from '@graphql/account/queries/userDeliveryAddresses';
import { GET_PAST_AND_ACTIVE_ORDER_HISTORY } from '@graphql/account/queries/getPastAndActiveOrderHistory';
import { DELIVERY_PASS_INFO } from '@graphql/deliveryPass/queries/deliveryPassInfo';
import { DELIVERY_PASS_PLANS } from '@graphql/deliveryPass/queries/deliveryPass';
import { MODIFYING_ORDER_ALERT_INFO } from '@graphql/common/queries/modifyingOrderAlertInfo';
import { PENDING_ORDERS_ALERTS_INFO } from '@graphql/common/queries/pendingOrdersAlertInfo';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { USER_DETAILS } from '@graphql/common/queries/userDetails';

export const MAIN_INFO_QUERIES = [
  GET_USER_GEOLOCATION_ADDRESS,
  GET_USER_DELIVERY_ADDRESSES,
  GET_PAST_AND_ACTIVE_ORDER_HISTORY,
  DELIVERY_PASS_INFO,
  DELIVERY_PASS_PLANS,
  MODIFYING_ORDER_ALERT_INFO,
  PENDING_ORDERS_ALERTS_INFO,
  GET_LIGHT_CART,
  USER_DETAILS,
];

export const QUERIES_TO_SKIP_ON_LOGIN = ['GetPendingOrders', 'Checkout', 'GetCart'];

export enum AUTH_OPERATIONS {
  LOGIN = 'Login',
  SOCIAL_LOGIN = 'socialLogin',
  SIGNUP = 'Signup',
  OTP = 'VerifyOTP',
}

export const LOGOUT_OPERATION = 'Logout';

export enum EVENT_ACTION {
  FAILED_WITH_AUTH_ERROR = 'FAILED_WITH_AUTH_ERROR',
  SUCCESSFULLY_AUTHORIZED = 'SUCCESSFULLY_AUTHORIZED',
}

export const SERVICE_TYPE_QUERY_KEY = 'serviceType';

export const SIGN_UP_CACHE_FIELDS = [
  'homepageFeed',
  'accountPreferences',
  'selectDeliveryAddresses',
  'userDeliveryAddresses',
  'userDetails',
  'geolocation',
];
