import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './TabPanel.module.scss';

interface TabPanelProps {
  children: ReactNode;
  value: string | null;
  index: string;
  className?: string;
}

export const TabPanel = ({ children, index, value, className, ...restProps }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={cx(className, styles.tab_panel_wrapper)}
      data-testid={`tab-panel-${index}`}
      {...restProps}
    >
      {value === index && <div className={styles.tab_panel_wrapper_content}>{children}</div>}
    </div>
  );
};
