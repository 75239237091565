import React from 'react';
import cx from 'classnames';
import { Popper, Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { ProductTile } from '@components/Tiles/ProductTile/ProductTile';
import { ProductTile as ProductTileType } from '@commons/product';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import { CriteoFormatBeacon } from '@modules/criteo';
import styles from './RecommendedTilePopup.module.scss';

interface RecommendedTilePopupProps {
  recommendedProduct: ProductTileType;
  anchorEl: HTMLDivElement;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  isV2?: boolean;
}

export const RecommendedTilePopup = ({
  recommendedProduct,
  anchorEl,
  criteoFormatBeaconView,
  isV2 = false,
}: RecommendedTilePopupProps) => {
  const { t } = useLocalization();
  const { isDesktop, isLaptop } = useHeaderContext();
  const { isStandingOrderAvailable } = useMainLayoutContext();
  const isSOBtnAvailable = isStandingOrderAvailable && (isLaptop || isDesktop);

  return (
    <Popper
      data-testid="recommended-tile-popup"
      anchorEl={anchorEl}
      className={cx(styles.wrapper, isSOBtnAvailable && styles.with_botttom_padding, {
        [styles.v2]: isV2,
      })}
      placement="right"
      open={!!anchorEl}
      disablePortal
      role="dialog"
      aria-modal="false"
      aria-labelledby={`recommended-product-title-${recommendedProduct?.productId}`}
    >
      <Typography
        className={styles.title}
        id={`recommended-product-title-${recommendedProduct?.productId}`}
      >
        {t('recommendations.tryThis')}
      </Typography>
      <div className={styles.product_wrapper}>
        <ProductTile
          product={recommendedProduct}
          criteoFormatBeaconView={criteoFormatBeaconView}
          isV2={isV2}
        />
      </div>
    </Popper>
  );
};

RecommendedTilePopup.displayName = 'RecommendedTilePopup';
