import cx from 'classnames';
import { Divider } from '@mui/material';
import { PRODUCT_TAGS } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import styles from './TileTagList.module.scss';
import { Tag } from './components/Tag/Tag';

interface TileTagListProps {
  children?: never;
  className?: string;
  peakQuality?: boolean;
  expressEligible?: boolean;
  hasWineData?: boolean;
  isSelectMode?: boolean;
  isV2?: boolean;
}

export const TileTagList = ({
  className,
  peakQuality = false,
  expressEligible = false,
  hasWineData = false,
  isSelectMode = false,
  isV2 = false,
}: TileTagListProps) => {
  const { t } = useLocalization('common');
  const hasTags = peakQuality || expressEligible;

  if (!hasTags && !isV2) {
    return <div className={styles.tile_empty_space} data-testid="tile-tag-list-empty" />;
  }

  const ExpressEligibleTag = Tag[PRODUCT_TAGS.EXPRESS_ELIGIBLE]({ t, className, isV2 });
  const PeakQualityTag = Tag[PRODUCT_TAGS.PEAK_QUALITY]({ t, className, isV2 });
  const WineTag = Tag[PRODUCT_TAGS.WINE_AND_SPIRITS]({ t, className, isSelectMode });

  const renderTags = (
    <>
      {expressEligible && ExpressEligibleTag}
      {expressEligible && peakQuality && !isV2 && (
        <Divider
          className={styles.tile_divider}
          orientation="vertical"
          flexItem
          role="presentation"
        />
      )}
      {hasWineData && WineTag}
      {peakQuality && PeakQualityTag}
    </>
  );

  return (
    <div
      className={cx(styles.tile__tag_list, className, { [styles.v2]: isV2 })}
      data-testid="tile-tag-list"
    >
      {renderTags}
    </div>
  );
};
