/* eslint-disable @typescript-eslint/naming-convention */
import { Product, ProductTile } from '@commons/product';
import { IGtmEvent } from '@modules/ga/gtm-event';
import { CartLineLightWithGA } from '@commons/cart';
import { getDiscount } from '@modules/ga/eventBodyGetters/utils/getDiscount';

const DEFAULT_VARIANT = 'default variant';

export interface EcommerceEvent extends IGtmEvent {
  event: string;
  event_name: string;
  customer: {
    deliverypass_type: string;
    express_eligible: boolean;
    deliverypass_term: string;
  };
  ua_category: string;
  ua_action: string;
  ua_label?: string;
  ecommerce: unknown;
}

export interface ProductMetrics {
  sponsored: boolean | string;
  id: string;
  item_id: string;
  sku: string;
  price: number;
  coupon: string;
  dimension6: boolean;
  name: string;
  brand: string;
  category: string;
  variant: string;
  discount: number;
  quantity: number;
  list: string;
  position: number;
}

export type extendedProductProps = {
  listName?: string;
  position?: number;
  sponsored?: boolean;
};
export type EcommerceEventCartLine = CartLineLightWithGA & extendedProductProps;
export type EcommerceEventProduct = (Product | ProductTile) & extendedProductProps;

const getBaseProductMetrics = (
  product: EcommerceEventProduct | EcommerceEventCartLine,
): Omit<ProductMetrics, 'discount' | 'quantity'> => ({
  sponsored: product?.sponsored ? 'criteo' : false,
  id: product.skuCode ?? '',
  item_id: product.productId ?? '',
  sku: product.skuCode ?? '',
  coupon: product.coupon?.couponId ?? '',
  dimension6: !!product?.available,
  name: product.productName ?? '',
  brand: product.brandName ?? '',
  category: product.categoryId ?? '',
  variant: product.variantId ?? DEFAULT_VARIANT,
  list: product.listName ?? '',
  position: product.position ?? 1,
  price: product.price?.value ?? 0,
});

const getProductMetricsByCartLine = (product: EcommerceEventCartLine): ProductMetrics => {
  return {
    ...getBaseProductMetrics(product),
    price: product.displayPrice?.value ?? product.price?.value ?? 0,
    discount: getDiscount(product),
    quantity: Math.abs(product.quantity?.quantity || product.quantitySelector?.quantity) || 1,
  };
};
const getProductMetricsByProduct = (product: EcommerceEventProduct): ProductMetrics => {
  return {
    ...getBaseProductMetrics(product),
    discount: product.discountAmount ?? 0,
    quantity: Math.abs(product.quantity?.quantity) ?? 1,
  };
};

export const getProductMetrics = (
  product: EcommerceEventProduct | EcommerceEventCartLine,
): ProductMetrics => {
  return (<CartLineLightWithGA>product).typename === 'CartGaLineLight'
    ? getProductMetricsByCartLine(<EcommerceEventCartLine>product)
    : getProductMetricsByProduct(<EcommerceEventProduct>product);
};
