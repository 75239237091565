import { gql } from '@apollo/client';

export const APPLY_PROMO = gql`
  mutation ApplyPromocode($promo: String!) {
    applyPromoCode(promoCode: $promo) {
      name
      error
    }
  }
`;
