import { PriceIndicators as PriceIndicatorsType } from '@commons/cart';
import { useLocalization } from '@hooks/index';
import styles from './PriceIndicators.module.scss';

interface PriceIndicatorsProps {
  values: PriceIndicatorsType;
}

export const PriceIndicators = ({ values }: PriceIndicatorsProps) => {
  const { t } = useLocalization('cart');

  return (
    <>
      {values.hasDepositValue && (
        <span className={styles.price_indicator} data-testid="price-indicator-state-bottle-deposit">
          {t('productIndicators.deposit')}
        </span>
      )}
      {values.hasEstimatedPrice && (
        <span className={styles.price_indicator} data-testid="price-indicator-estimated">
          {t('productIndicators.estimated')}
        </span>
      )}
      {values.hasScaledPricing && (
        <span className={styles.price_indicator} data-testid="price-indicator-special">
          {t('productIndicators.scaled')}
        </span>
      )}
      {values.hasTax && (
        <span className={styles.price_indicator} data-testid="price-indicator-taxable">
          {t('productIndicators.tax')}
        </span>
      )}
    </>
  );
};
