import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { MODIFY_ORDER } from '@graphql/orderModification/mutations/modifyOrder';

const ACTION_QUERY = 'action';
const MODIFY_MODE = 'modify';
const ORDER_ID = 'orderId';

const getQueriesParams = (redirectUrl: string) =>
  new URL(redirectUrl, window.location.origin).searchParams;
const getIsModifyAction = (queriesParams: URLSearchParams) =>
  queriesParams.get(ACTION_QUERY) === MODIFY_MODE;
const getOrderId = (queriesParams: URLSearchParams) => queriesParams.get(ORDER_ID);

const getModifyOrderId = (redirectUrl: string) => {
  const queriesParams = getQueriesParams(redirectUrl);
  const isModifyAction = getIsModifyAction(queriesParams);
  const orderId = getOrderId(queriesParams);

  return isModifyAction && orderId ? orderId : '';
};

export const shouldInitModifyOrder = (redirectUrl: string) => {
  const queriesParams = getQueriesParams(redirectUrl);
  const isModifyAction = getIsModifyAction(queriesParams);
  const orderId = getOrderId(queriesParams);

  return isModifyAction && orderId;
};

export const initModifyOrder = async (
  client: ApolloClient<NormalizedCacheObject>,
  redirectUrl: string,
) => {
  const orderId = getModifyOrderId(redirectUrl);

  await client.mutate({
    mutation: MODIFY_ORDER,
    variables: { orderId },
  });
};
