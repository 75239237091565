import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const DELETE_CART_LINES = gql`
  mutation DeleteCartLines($cartLineIds: [ID!]!) {
    deleteCartLines(cartLineIds: $cartLineIds) {
      ...cartFragment
    }
  }
  ${CART_FRAGMENT}
`;
