import { useMutation, useReactiveVar } from '@apollo/client';
import { ConfOption } from '@api';
import { ADD_TO_CART, ADD_TO_CART_PROTECTED } from '@graphql/product/mutations/addToCart';
import { SalesUnit } from '@commons/product';
import {
  getShowPendingOrdersModalVar,
  productToAddToCartVar,
  setShowPendingOrdersModalVar,
  shouldAddToCartVar,
} from '@graphql/variables/showPendingOrdersModalVar';
import { useSetPendingOrders } from '@hooks/cart/useSetShowPendingOrders';
import { useAddToCartEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { cartAdapter } from '@adapters/cartAdapter';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { getAddToOrderVariables } from '@hooks/order/useAddToOrderLightV2';
import { GetAddToOrderParams } from '@hooks/cart/useAddToCartWithOrdersCheck';
import { updateOrderModificationsCache } from '@hooks/cart/updateOrderModificationsCache';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { usePrevious } from '@hooks/usePrevious';
import { getProductIdList } from '@modules/ga/utils/getProductIdList';
import { AddToOrderProduct } from '@hooks/order/useSetProductToAddToOrder';
import { updateCartCache } from './updateCartCache';

export interface Params {
  productId?: string;
  categoryId?: string | null;
  skuCode?: string | null;
  quantity?: number;
  previousQuantity?: number;
  salesUnit?: SalesUnit['salesUnit'];
  configuration?: ConfOption[];
  itemPosition?: number;
  itemListName?: string;
  eventSource?: string;
}

const { getCart } = cartAdapter();

export const useSetProductToAddToCartVar = () => {
  const setProductToAddToCart = (product = {}) => {
    productToAddToCartVar(product);
  };
  const productToAddToCart = useReactiveVar(productToAddToCartVar);

  return { productToAddToCart, setProductToAddToCart };
};

interface AddToOrderParamsWithGA extends GetAddToOrderParams {
  itemPosition: number;
  itemListName?: string;
}

interface AddToOrderVariablesWithGA extends AddToOrderProduct {
  itemPosition: number;
  itemListName?: string;
}

export const getConfigurableProduct = ({
  product,
  quantity,
  configuration,
  salesUnit,
  itemPosition,
  itemListName,
}: AddToOrderParamsWithGA): AddToOrderVariablesWithGA => ({
  ...getAddToOrderVariables({
    productId: product.productId,
    categoryId: product.categoryId,
    skuCode: product.skuCode,
    salesUnit: salesUnit || product.salesUnits[0].alternateSalesUnit || '',
    quantity,
    configuration,
    eventSource: '',
  }),
  itemPosition,
  itemListName: itemListName ?? '',
});

export const getAddToCartVariables = (config: Params = {}) => {
  const {
    productId,
    skuCode,
    categoryId,
    quantity,
    salesUnit,
    configuration = [],
    itemListName,
    itemPosition,
    eventSource = 'pdp_main',
  } = config;

  return {
    addToCartInput: {
      products: [
        {
          quantity,
          productId,
          categoryId,
          salesUnit,
          skuCode,
          configuration,
          itemPosition,
          itemListName,
        },
      ],
      eventSource,
    },
  };
};

export const useAddToCart = (config?: Params, isProtected = false) => {
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const mutation = isProtected ? ADD_TO_CART_PROTECTED : ADD_TO_CART;
  const { trackAddToCart } = useAddToCartEvent();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);

  return useMutation(mutation, {
    variables: {
      ...getAddToCartVariables(config),
    },
    update(cache, { data: { addToCart: cart } }) {
      updateOrderModificationsCache(cache, cart.orderModifications);
      updateCartCache(cache, cart);
    },
    onCompleted({ addToCart: cart }, options) {
      if (oldCart) {
        const newCart = getCart(cart);
        const productIds = getProductIdList(options?.variables?.addToCartInput?.products ?? []);
        const itemPosition = options?.variables?.addToCartInput?.products?.[0]?.itemPosition;
        const listName = options?.variables?.addToCartInput?.products?.[0]?.itemListName;

        trackAddToCart({ newCart, oldCart, productIds, itemPosition, listName });
        clearCacheFields([LIGHT_CART_CACHE_FIELD]);
      }
    },
  });
};

export const useModifiedAddToCart = () => {
  const shouldUpdateModal = useReactiveVar(getShowPendingOrdersModalVar());
  const [setIsShown] = useSetPendingOrders();
  const { productToAddToCart } = useSetProductToAddToCartVar();
  const { trackAddToCart } = useAddToCartEvent();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);

  return useMutation(ADD_TO_CART, {
    // TODO: resolve issues with Interfaces of cart options
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    variables: {
      ...productToAddToCart,
    },
    update(cache, { data: { addToCart } }) {
      updateCartCache(cache, addToCart);
    },
    onCompleted: ({ addToCart: cart }, options) => {
      shouldAddToCartVar(true);
      if (shouldUpdateModal) {
        setShowPendingOrdersModalVar(false);
        setIsShown({
          variables: {
            isShown: true,
          },
        });
      }

      if (oldCart) {
        const newCart = getCart(cart);
        const productIds = getProductIdList(options?.variables?.addToCartInput?.products ?? []);
        const itemPosition = options?.variables?.addToCartInput?.products?.[0]?.itemPosition;
        const listName = options?.variables?.addToCartInput?.products?.[0]?.itemListName;

        trackAddToCart({ newCart, oldCart, productIds, itemPosition, listName });
      }
    },
  });
};
