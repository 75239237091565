import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { NextPageContext } from 'next/types';
import { GetServerSidePropsContext } from 'next';
import { setCookie, getCookie } from 'cookies-next';
import { COOKIES_LIST } from '@commons/cookies';

export const expressContextQuery = 'expressContext';

export const getExpressInitialStateFromQuery = (query: NextParsedUrlQuery) => {
  if (query.hasOwnProperty(expressContextQuery)) {
    return query[expressContextQuery] === 'true' ? true : false;
  }
  return undefined;
};

export const getExpressHeader = (ctx: NextPageContext | GetServerSidePropsContext) => {
  if (ctx.query.hasOwnProperty(expressContextQuery)) {
    const expressContextQueryValue = String(ctx.query[expressContextQuery]);
    setCookie(COOKIES_LIST.IS_EXPRESS, expressContextQueryValue, {
      res: ctx.res,
      req: ctx.req,
      path: '/',
    });
    return ctx.query[expressContextQuery] as string;
  }
  return getCookie(COOKIES_LIST.IS_EXPRESS, ctx);
};
