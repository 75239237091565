import { useMutation } from '@apollo/client';
import { UPDATE_GIFT_CARD_IN_CART } from '@graphql/giftCard/mutations/updateGiftCardInCart';
import { UpdateGiftCardInCartMutation, UpdateGiftCardInCartMutationVariables } from '@api';
import { GET_GIFT_CART } from '@graphql/cart/queries/getGiftCart';

export const useUpdateGiftCardInCart = () => {
  return useMutation<UpdateGiftCardInCartMutation, UpdateGiftCardInCartMutationVariables>(
    UPDATE_GIFT_CARD_IN_CART,
    {
      update(cache, { data }) {
        if (data) {
          cache.writeQuery({
            query: GET_GIFT_CART,
            data: { giftCardsCart: data.updateGiftCardInCart.cart },
          });
        }
      },
    },
  );
};
