import { Skeleton } from '@mui/material';
import styles from './ProductItemWithPriceSkeleton.module.scss';

export const ProductItemWithPriceSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.product}>
        <Skeleton variant="rectangular" />
        <div className={styles.details}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      </div>
      <div className={styles.price}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>
    </div>
  );
};
