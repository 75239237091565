import { useReactiveVar } from '@apollo/client';
import { areTimeslotsOpenVar } from '@graphql/variables/areTimeslotsOpenVar';

export const useIsShowTimeslots = () => {
  const isShowTimeslots = useReactiveVar(areTimeslotsOpenVar);

  const openTimeslots = () => areTimeslotsOpenVar(true);

  const closeTimeslots = () => areTimeslotsOpenVar(false);

  const toggleTimeslots = () => areTimeslotsOpenVar(!isShowTimeslots);

  return {
    isShowTimeslots,
    openTimeslots,
    closeTimeslots,
    toggleTimeslots,
  };
};
