import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export interface ShopByOptions {
  label: string;
}

export const shopByHandler = ({ label }: ShopByOptions) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'shop-by',
      event_name: 'shop_by_click',
      ua_category: 'shop-by',
      ua_action: 'click',
      ua_label: label,
    }),
  );
};
