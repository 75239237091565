import { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { useUpdateEffect } from 'usehooks-ts';
import { Skeleton, Tooltip, Drawer } from '@mui/material';
import { useLightCartCache } from '@hooks/cart/useLightCart';
import { basket as CartIcon } from '@assets/icons/informational';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useLocalization } from '@hooks/useLocalization';
import { routing } from '@constants/routing';
import { useModifyMode } from '@hooks/orderModification/useModifyMode';
import { useSideBagDrawerManager } from '@hooks/cart/useSideBagDrawerManager';
import { getModifyingOrderLink } from '@utils/getModifyingOrderLink';
import { ButtonProps } from '@components/UI/Button/Button';
import { SignInReminder } from '@components/SignInReminder/SignInReminder';
import { GET_GIFT_CARDS_CART_LINES_COUNT } from '@graphql/giftCard/queries/giftCardsCartLinesCount';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { Bag } from '@features/CartPage/components/Bag/Bag';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { useLazyMinimumAuthInfo } from '@hooks/authHooks/useLazyMinimumAuthInfo';
import { AUTH_USER_ACTIONS_TYPES } from '@modules/auth/context/AuthUserReducer';
import { MobileStatusModal, StatusInfoWindow } from './components';
import styles from './Cart.module.scss';

interface CartProps extends ButtonProps {
  className?: string;
  omitTestIds?: boolean;
  noDrawer?: boolean;
}

export const Cart = ({ className, omitTestIds = false, noDrawer = false }: CartProps) => {
  const router = useRouter();
  const { data: modifyMode } = useModifyMode();
  const {
    isKnownUser: isLoggedIn,
    isRecognizedUser,
    isSignedIn,
    isUserInfoLoading,
    state: authUserState,
    dispatch: authUserDispatch,
  } = useAuthContext();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const [fetchMinimumAuthInfo, updatedMinimumAuthInfo] = useLazyMinimumAuthInfo();
  const shouldShowSignInReminder = !isLoggedIn || isRecognizedUser;
  const { screenSize } = useHeaderContext();
  const isMobile = !!screenSize?.isMobile;
  const isTablet = !!screenSize?.isTablet;
  const isMobileOrTablet = isMobile || isTablet;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const cartRef = useRef(null);
  const { t } = useLocalization('components');
  const {
    data: { cartLinesCount: groceryCartLinesNumber, cartLines: cartLines },
    loading: loadingLightCart,
  } = useLightCartCache();
  const { data: giftCardsCart, loading: loadingGiftCardsCart } = useQuery(
    GET_GIFT_CARDS_CART_LINES_COUNT,
    {
      fetchPolicy: 'cache-first',
      ssr: false,
    },
  );
  const loading =
    loadingGiftCardsCart || loadingLightCart || updatedMinimumAuthInfo.loading || isUserInfoLoading;
  const cartLinesNumber = groceryCartLinesNumber + (giftCardsCart?.giftCardsCart?.cardsCount || 0);

  const hasItemsInCart = cartLinesNumber > 0;

  useUpdateEffect(() => {
    const updateStatusLevel = updatedMinimumAuthInfo?.data?.accountPreferences?.statusLevel;

    if (!!updateStatusLevel && updateStatusLevel !== authUserState?.userStatus) {
      authUserDispatch?.({
        type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS,
        payload: updateStatusLevel,
      });
    }
  }, [updatedMinimumAuthInfo]);

  const showModalOpen = () => {
    setIsModalOpen(true);
  };

  const hideModalOpen = () => {
    setIsModalOpen(false);
  };

  const { openSideBagDrawer, closeSideBagDrawer, isSideBagDrawerOpen } = useSideBagDrawerManager();

  const handleCartButton = () => {
    fetchMinimumAuthInfo();

    if (isMobile) {
      if (modifyMode.active) {
        clearCacheFields(['initModifyOrderCheckout', 'orderModifications']);
        router.replace(getModifyingOrderLink(modifyMode.orderId));
        return;
      }
      if (hasItemsInCart || cartLines.length > 0) {
        router.push(routing.cart);
        return;
      }

      showModalOpen();
    } else {
      if (!(modifyMode.active && modifyMode.orderId && isSignedIn)) {
        clearCacheFields(['initModifyOrderCheckout', 'orderModifications']);
      }
      openSideBagDrawer();
    }
  };

  const handleCloseSideBag = () => {
    closeSideBagDrawer();
  };

  return (
    <div className={cx(styles.wrapper, className)} id="header-cart">
      <Tooltip
        title={
          <>
            {isMobileOrTablet ? (
              <MobileStatusModal isOpen={isModalOpen} onClose={hideModalOpen} />
            ) : (
              <StatusInfoWindow onClose={hideModalOpen} />
            )}
          </>
        }
        leaveDelay={100}
        classes={{ tooltip: styles.tooltip_popper }}
        placement="bottom-end"
        disableHoverListener
        disableFocusListener
        open={isModalOpen}
        TransitionProps={{ timeout: 0 }}
      >
        <button
          className={styles.cart_wrapper}
          aria-label={t('headerCart.cartItems', { count: cartLinesNumber })}
          onClick={handleCartButton}
          ref={cartRef}
          data-testid={!omitTestIds && 'cart-icon'}
        >
          <CartIcon className={styles.icon_shop} aria-label={t('headerCart.cartIcon')} />
          {
            <span
              data-testid={!omitTestIds && 'cart-items-quantity-indicator'}
              className={cx(styles.cart_counter, {
                [styles.loading]: loading,
                [styles.not_visible]: !loading && !hasItemsInCart,
              })}
            >
              {loading ? <Skeleton variant="circular" /> : cartLinesNumber}
            </span>
          }
        </button>
      </Tooltip>
      {shouldShowSignInReminder && (
        <SignInReminder itemNumber={cartLinesNumber} cartRef={cartRef} loading={loading} />
      )}
      {!noDrawer && (
        <Drawer
          anchor="right"
          onClose={handleCloseSideBag}
          open={isSideBagDrawerOpen}
          className={styles.cart_drawer}
        >
          <div
            className={styles.sidecart}
            role="dialog"
            aria-modal="true"
            aria-labelledby={MODAL_TITLE_ID.SIDE_BAG_MODAL_TITLE}
          >
            <Bag
              hasItemsInCart={hasItemsInCart}
              loading={loading}
              bagClassName={styles.cart_drawer_bag}
              onCloseSideBagDrawer={handleCloseSideBag}
              onTabChange={() => {
                /* not required in side bag*/
              }}
            />
          </div>
        </Drawer>
      )}
    </div>
  );
};
