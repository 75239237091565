import { FormikProps } from 'formik';
import { useState } from 'react';
import { AddressOptionInput } from '@features/CartPage/components/CartPageOptions/components/addressOptionInput/addressOptionInput';
import { ManualAddressForm } from '@components/ManualAddressForm/ManualAddressForm';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { ADDRESS_SERVICE_TYPE, AddressToSend, SERVICE_TYPE } from '@commons/deliveryAddresses';

export interface AddressWidgetProps {
  withSubmitButton?: boolean;
  showServiceTypeInfo?: ADDRESS_SERVICE_TYPE;
  onChange: (value: AddressToSend, isManualFormSubmitted?: boolean) => void;
  onBlurred?: () => void;
  serviceType?: SERVICE_TYPE;
  // DEPRECATED:BEGIN
  isAptUnitAvailable: boolean;
  inputAddress: string;
  selectedFormattedAddress: Suggestion | null;
  isAddressSelected: boolean;
  blurOnSelect?: boolean;
  setInputAddress: (value: string) => void;
  setAPIErrorMessage: (value: string | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  // DEPRECATED:END
  shouldValidateAddress?: boolean;
  setShouldValidateAddress?: (isValidated: boolean) => void;
  onInputChange?: () => void;
}

export const AddressWidget = ({
  withSubmitButton,
  showServiceTypeInfo,
  blurOnSelect,
  onChange,
  onBlurred,
  serviceType,
  // DEPRECATED:BEGIN
  isAddressSelected,
  isAptUnitAvailable,
  inputAddress,
  formik,
  selectedFormattedAddress,
  setInputAddress,
  setAPIErrorMessage,
  shouldValidateAddress,
  setShouldValidateAddress,
  onInputChange,
}: // DEPRECATED:END
AddressWidgetProps) => {
  const [isManual, setIsManual] = useState(false);

  const handleChangeOfManualAddressForm = (value: AddressToSend) => {
    onChange(value, true);
    setIsManual(false);
  };

  return isManual ? (
    <ManualAddressForm
      withSubmitButton={withSubmitButton}
      onChange={handleChangeOfManualAddressForm}
      serviceType={serviceType}
    />
  ) : (
    <AddressOptionInput
      isAptUnitAvailable={isAptUnitAvailable}
      blurOnSelect={blurOnSelect}
      inputAddress={inputAddress}
      formik={formik}
      onBlurred={onBlurred}
      selectedAddress={selectedFormattedAddress}
      isAddressSelected={isAddressSelected}
      withSubmitButton={withSubmitButton}
      showServiceTypeInfo={showServiceTypeInfo}
      setInputAddress={setInputAddress}
      setAPIError={setAPIErrorMessage}
      onChange={onChange}
      onChangeMode={() => setIsManual(true)}
      id="add_address_input"
      serviceType={serviceType}
      shouldValidateAddress={shouldValidateAddress}
      setShouldValidateAddress={setShouldValidateAddress}
      onInputChange={onInputChange}
    />
  );
};
