import { Customer } from '@commons/customer';
import { LastDeliveredOrderInfo } from '@commons/order';
import { List } from '@modules/ga/context/position';
import { REDIRECT_STATUS, AUTH_TYPES, AUTH_VERIFICATION_TYPES } from '@commons/auth';
import { DynamicFeature } from '../types/dynamicFeatures';
import { isClient } from './isClient';

export { useLocalStorage, useSessionStorage } from 'usehooks-ts';

export enum STORAGE_KEYS {
  CUSTOMER_INFO = 'customerInfo',
  EDIT_DELIVERY = 'editDelivery',
  DELIVERED_ORDERS_INFO = 'deliveredOrdersInfo',
  OPEN_LOGIN_REMINDER = 'openLoginReminder',
  PAGE_REFRESH_COUNT = 'pageRefreshCount',
  EXPRESS_NOTIFICATION_CLOSED = 'expressNotificationClosed',
  LAST_URL = 'lastUrl',
  LIST_ITEM_POSITION = 'listItemPosition',
  APPLIED_APC_PROMO = 'appliedAPCPromo',
  APC_PROMO_CODE = 'APCPromoCode',
  SAVED_EXPRESS_ITEMS = 'savedExpressItems',
  EXPRESS_FILTER = 'expressFilter',
  HIDDEN_EXPRESS_NOTIFICATION = 'hiddenExpressNotification',
  CART_LINES_BEFORE_PURCHASE = 'cartLinesBeforePurchase',
  BILLING_REFERENCE_MODIFICATION = 'billingReferenceModification',
  REMEMBER_ME = 'rememberMe',
  MODIFY_ORDER_ID = 'modifyOrderId',
  DYNAMIC_FEATURES = 'dynamicFeatures',
  AUTH_NOT_COMPLETE = 'authNotComplete',
  APP_REDIRECT_STATUS = 'appRedirectStatus',
  LOGIN_PREF_PHONE_EMAIL = 'loginPrefPhoneEmail',
  LOGIN_PREF_OTP_PWD = 'loginPrefOtpPwd',
  DEV_WIDGET = 'devWidget',
  REFERER_INFO = 'referInfo',
  HIDE_BAG_REFER_INFO = 'hideBagReferInfo',
  IS_CHAT_INITIALIZED = 'isChatInitialized',
  CHAT_INFO = 'chatInfo',
}

export interface StoredCartLine {
  id: string;
  itemListName: string;
  itemPosition: number;
}

type StorageData = {
  [STORAGE_KEYS.CUSTOMER_INFO]: Customer;
  [STORAGE_KEYS.DELIVERED_ORDERS_INFO]: LastDeliveredOrderInfo[];
  [STORAGE_KEYS.OPEN_LOGIN_REMINDER]: boolean;
  [STORAGE_KEYS.EDIT_DELIVERY]: boolean;
  [STORAGE_KEYS.PAGE_REFRESH_COUNT]: number;
  [STORAGE_KEYS.EXPRESS_NOTIFICATION_CLOSED]: boolean;
  [STORAGE_KEYS.LAST_URL]: string;
  [STORAGE_KEYS.LIST_ITEM_POSITION]: List;
  [STORAGE_KEYS.APPLIED_APC_PROMO]: string;
  [STORAGE_KEYS.APC_PROMO_CODE]: string;
  [STORAGE_KEYS.SAVED_EXPRESS_ITEMS]: number;
  [STORAGE_KEYS.EDIT_DELIVERY]: boolean;
  [STORAGE_KEYS.EXPRESS_FILTER]: boolean;
  [STORAGE_KEYS.HIDDEN_EXPRESS_NOTIFICATION]: boolean;
  [STORAGE_KEYS.CART_LINES_BEFORE_PURCHASE]: StoredCartLine[];
  [STORAGE_KEYS.BILLING_REFERENCE_MODIFICATION]: string;
  [STORAGE_KEYS.REMEMBER_ME]: boolean;
  [STORAGE_KEYS.MODIFY_ORDER_ID]: string;
  [STORAGE_KEYS.DYNAMIC_FEATURES]: Record<string, DynamicFeature>;
  [STORAGE_KEYS.AUTH_NOT_COMPLETE]: boolean;
  [STORAGE_KEYS.APP_REDIRECT_STATUS]: REDIRECT_STATUS;
  [STORAGE_KEYS.LOGIN_PREF_PHONE_EMAIL]: AUTH_TYPES;
  [STORAGE_KEYS.LOGIN_PREF_OTP_PWD]: AUTH_VERIFICATION_TYPES;
  [STORAGE_KEYS.DEV_WIDGET]: Record<string, string | boolean>;
  [STORAGE_KEYS.REFERER_INFO]: { refererName: string; refererInvite: string };
  [STORAGE_KEYS.HIDE_BAG_REFER_INFO]: boolean;
  [STORAGE_KEYS.IS_CHAT_INITIALIZED]: boolean;
  [STORAGE_KEYS.CHAT_INFO]: { fullName: string; email: string };
};

export class StorageService {
  private storage: Storage;
  private eventName: string;

  constructor(storage: Storage, eventName: string = 'storage') {
    this.storage = storage;
    this.eventName = eventName;
  }

  read<T extends STORAGE_KEYS>(key: T): StorageData[T] | null {
    const data = this.storage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  put<T extends STORAGE_KEYS>(key: T, data: StorageData[T]): void {
    this.storage.setItem(key, JSON.stringify(data));
    window.dispatchEvent(new StorageEvent(this.eventName, { key }));
  }

  remove(key: STORAGE_KEYS): void {
    this.storage.removeItem(key);
    window.dispatchEvent(new StorageEvent(this.eventName, { key }));
  }

  isKeyExists<T extends STORAGE_KEYS>(key: T): boolean {
    const keys = Object.keys(sessionStorage);
    return keys?.includes(key);
  }

  putWithExpiry<T extends STORAGE_KEYS>(key: T, data: StorageData[T], expiryDays: number) {
    const now = new Date();
    const expiryDate = new Date(now.getTime() + expiryDays * 24 * 60 * 60 * 1000);

    const item = {
      value: data,
      expiry: expiryDate.getTime(),
    };
    this.storage.setItem(key, JSON.stringify(item));
  }

  readWithExpiry<T extends STORAGE_KEYS>(key: T) {
    const data = this.storage.getItem(key);

    // If the item doesn't exist, return null
    if (!data) {
      return null;
    }

    const item = JSON.parse(data);
    const now = new Date();

    // Compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, remove it from storage and return null
      this.storage.removeItem(key);
      return null;
    }

    return item.value;
  }
}

let storageService = null;
let sessionService = null;

if (isClient()) {
  storageService = new StorageService(localStorage, 'local-storage');
  sessionService = new StorageService(sessionStorage, 'session-storage');
}

export const localStorageService = storageService;
export const sessionStorageService = sessionService;
