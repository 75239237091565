import { useEffect, useState } from 'react';
import { DIFFERENCE_FOR_SCROLL } from '../../constants/useDirectionScroll';

export const useDirectionScroll = () => {
  const [isScrollingToBottom, setIsScrollingToBottom] = useState(false);

  useEffect(() => {
    let prevScrollValue = 0;
    const handleScroll = () => {
      if (Math.abs(prevScrollValue - window.scrollY) > DIFFERENCE_FOR_SCROLL) {
        setIsScrollingToBottom(prevScrollValue < window.scrollY);
        prevScrollValue = window.scrollY;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isScrollingToBottom;
};
