import { useCallback, useState, useEffect } from 'react';
import { useEventListener } from 'usehooks-ts';
import { KEYBOARD_CODE } from '@constants/keyboard';

interface GlobalNavKeyboardNavigationProps {
  navListRef: React.RefObject<HTMLElement>;
  popoverRef: React.RefObject<HTMLElement>;
}

enum FOCUS_DIRECTION {
  FORWARD = 1,
  BACKWARD = -1,
  STAY = 0,
}

export const useGlobalNavKeyboardNavigation = ({
  navListRef,
  popoverRef,
}: GlobalNavKeyboardNavigationProps) => {
  const [focusedNavIndex, setFocusedNavIndex] = useState(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const reset = useCallback(() => {
    setFocusedNavIndex(0);
    setIsPopoverOpen(false);
  }, [setFocusedNavIndex, setIsPopoverOpen]);

  const moveTabFocus = useCallback(
    (direction: FOCUS_DIRECTION) => {
      const tabs = popoverRef.current?.querySelectorAll(
        '[role="tab"]',
      ) as NodeListOf<HTMLButtonElement>;
      if (tabs?.length) {
        const lastTabIndex = tabs.length - 1;
        let tabIndexToFocus = Array.from(tabs).findIndex((el) => el === document.activeElement);

        if (tabIndexToFocus === -1) {
          tabIndexToFocus = 0;
        }

        if (direction === FOCUS_DIRECTION.BACKWARD && tabIndexToFocus === 0) {
          tabIndexToFocus = lastTabIndex;
        } else if (direction === FOCUS_DIRECTION.FORWARD && tabIndexToFocus === lastTabIndex) {
          tabIndexToFocus = 0;
        } else if (direction !== FOCUS_DIRECTION.STAY) {
          tabIndexToFocus =
            direction === FOCUS_DIRECTION.BACKWARD ? tabIndexToFocus - 1 : tabIndexToFocus + 1;
        }

        setTimeout(() => {
          tabs[tabIndexToFocus].focus();
        }, 0);

        return true;
      }

      return false;
    },
    [popoverRef],
  );

  const moveMenuBarFocus = useCallback(
    (direction: FOCUS_DIRECTION) => {
      const navList = navListRef.current;
      if (!navList) return;

      // navbar navigation
      const navItems: NodeListOf<HTMLButtonElement | HTMLLinkElement> =
        navList.querySelectorAll('[role="menuitem"]');
      const lastItemIndex = navItems.length - 1;

      // current focused element including buttons and links
      const currentFocusedElement: HTMLButtonElement | HTMLLinkElement | null =
        document.activeElement as HTMLButtonElement | HTMLLinkElement;

      let currentIndex = Array.from(navItems)
        .filter((el) => !popoverRef.current?.contains(el))
        .findIndex((item: HTMLElement) => item === currentFocusedElement);

      // fallback to menu item based index
      if (currentIndex === -1) {
        if (focusedNavIndex < navItems.length) {
          currentIndex = focusedNavIndex;
        } else {
          currentIndex = 0;
        }
      }

      let newNavIndex = 0;
      if (direction === FOCUS_DIRECTION.STAY) {
        newNavIndex = currentIndex;
      } else if (currentIndex === 0 && direction === FOCUS_DIRECTION.BACKWARD) {
        newNavIndex = lastItemIndex;
      } else if (currentIndex !== lastItemIndex || direction !== FOCUS_DIRECTION.FORWARD) {
        newNavIndex = currentIndex + direction;
      }

      const elToFocus = navItems[newNavIndex];

      elToFocus?.focus();
      setFocusedNavIndex(newNavIndex);
      setIsPopoverOpen(false);
    },
    [focusedNavIndex, setFocusedNavIndex, setIsPopoverOpen, navListRef, popoverRef],
  );

  const moveFocusLR = useCallback(
    (direction: FOCUS_DIRECTION) => {
      const navList = navListRef.current;
      if (!navList) return;

      // check tabs first
      if (moveTabFocus(direction)) {
        return;
      }

      moveMenuBarFocus(direction);
    },
    [navListRef, moveTabFocus, moveMenuBarFocus],
  );

  const moveFocusUD = useCallback(
    (direction: FOCUS_DIRECTION) => {
      if (!isPopoverOpen) return;

      const menuItems = popoverRef.current?.querySelectorAll('[role="menuitem"]') as NodeListOf<
        HTMLButtonElement | HTMLLinkElement
      >;
      if (!menuItems?.length) return;

      const lastItemIndex = menuItems.length - 1;
      let elToFocus;

      // current focused element including buttons and links
      const currentFocusedElement: HTMLButtonElement | HTMLLinkElement | null =
        document.activeElement as HTMLButtonElement | HTMLLinkElement;

      const currentIndex = Array.from(menuItems).findIndex(
        (item: HTMLElement) => item === currentFocusedElement,
      );

      // fallback to first item
      if (currentIndex === -1) {
        menuItems[0].focus();
        return;
      }

      if (direction === FOCUS_DIRECTION.STAY) {
        elToFocus = menuItems[currentIndex];
      } else if (currentIndex === 0 && direction === FOCUS_DIRECTION.BACKWARD) {
        elToFocus = menuItems[lastItemIndex];
      } else if (currentIndex === lastItemIndex && direction === FOCUS_DIRECTION.FORWARD) {
        elToFocus = menuItems[0];
      } else {
        elToFocus =
          menuItems[direction === FOCUS_DIRECTION.BACKWARD ? currentIndex - 1 : currentIndex + 1];
      }

      elToFocus.focus();
    },
    [isPopoverOpen, popoverRef],
  );

  const closePopup = useCallback(() => {
    if (isPopoverOpen) {
      moveMenuBarFocus(FOCUS_DIRECTION.STAY);
    }
  }, [isPopoverOpen, moveMenuBarFocus]);

  const focusPopupContent = useCallback(() => {
    if (!popoverRef.current) return;

    const hasTabs = popoverRef.current.querySelector('[role="tab"]');
    const firstLink = popoverRef.current.querySelector(
      '[role="menuitem"]',
    ) as HTMLLinkElement | null;

    if (hasTabs) {
      moveFocusLR(FOCUS_DIRECTION.STAY);
    } else if (firstLink) {
      firstLink.focus();
    }
  }, [popoverRef, moveFocusLR]);

  const moveFocusHome = useCallback(() => {
    let list = navListRef.current;
    if (isPopoverOpen) {
      list = popoverRef.current;
    }

    const elToFocus: HTMLButtonElement | HTMLLinkElement | null | undefined =
      list?.querySelector('[role="menuitem"]');

    if (elToFocus) {
      elToFocus.focus();
    }
  }, [isPopoverOpen, navListRef, popoverRef]);

  const moveFocusEnd = useCallback(() => {
    let list = navListRef.current;
    if (isPopoverOpen) {
      list = popoverRef.current;
    }

    const elToFocus: HTMLButtonElement | HTMLLinkElement | null | undefined = Array.from(
      list?.querySelectorAll('[role="menuitem"]') as NodeListOf<
        HTMLLinkElement | HTMLButtonElement
      >,
    ).pop();

    if (elToFocus) {
      elToFocus.focus();
    }
  }, [isPopoverOpen, navListRef, popoverRef]);

  useEffect(() => {
    if (isPopoverOpen) {
      // popup opened, focus first link/tab
      focusPopupContent();
    }
  }, [isPopoverOpen, focusPopupContent]);

  const handleKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case KEYBOARD_CODE.ARROW_LEFT:
          event.preventDefault();
          moveFocusLR(FOCUS_DIRECTION.BACKWARD);
          break;
        case KEYBOARD_CODE.ARROW_RIGHT:
          event.preventDefault();
          moveFocusLR(FOCUS_DIRECTION.FORWARD);
          break;
        case KEYBOARD_CODE.ARROW_UP:
          event.preventDefault();
          moveFocusUD(FOCUS_DIRECTION.BACKWARD);
          break;
        case KEYBOARD_CODE.ARROW_DOWN:
          event.preventDefault();
          if (document.activeElement?.matches('button[role="menuitem"]')) {
            (document.activeElement as HTMLButtonElement).click();
          } else {
            moveFocusUD(FOCUS_DIRECTION.FORWARD);
          }
          break;
        case KEYBOARD_CODE.ESCAPE:
          event.preventDefault();
          closePopup();
          break;
        case KEYBOARD_CODE.SPACE:
          event.preventDefault();
          (document.activeElement as HTMLLinkElement | HTMLButtonElement | null)?.click();
          break;
        case KEYBOARD_CODE.HOME:
          event.preventDefault();
          moveFocusHome();
          break;
        case KEYBOARD_CODE.END:
          event.preventDefault();
          moveFocusEnd();
          break;
        case KEYBOARD_CODE.TAB:
          reset();
          break;
      }
    },
    [moveFocusLR, moveFocusUD, moveFocusHome, moveFocusEnd, closePopup, reset],
  );

  useEventListener('keydown', handleKeyDown, navListRef);

  return {
    isPopoverOpen,
    setIsPopoverOpen,
  };
};
