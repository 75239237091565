import { ApolloCache } from '@apollo/client';
import { Payments } from '@api';

export const updatePaymentMethodsCache = (
  cache: ApolloCache<unknown>,
  newPayments?: Payments | null,
) => {
  cache.modify({
    fields: {
      paymentMethods(_, { DELETE }) {
        return newPayments
          ? {
              ...newPayments,
            }
          : DELETE;
      },
    },
  });
};
