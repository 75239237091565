import { createContext, useContext } from 'react';

export interface ModifyOrderContextProps {
  isExpressOrder?: boolean;
  isModifyMode: boolean;
}

export const ModifyOrderContext = createContext<ModifyOrderContextProps>({
  isExpressOrder: undefined,
  isModifyMode: false,
});

export const useModifyOrderContext = () => useContext(ModifyOrderContext);
