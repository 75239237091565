import { gql } from '@apollo/client';
import { CREDIT_FRAGMENT } from '@graphql/fragments/account/credit';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const CREDITS = gql`
  query StoreCredits {
    storeCredits {
      approvedCredits {
        ...creditFragment
      }
      pendingCredits {
        ...creditFragment
      }
      totalAmount {
        ...priceFragment
      }
      totalAmountFD {
        ...priceFragment
      }
      totalAmountFK {
        ...priceFragment
      }
      creditRestricted
    }
  }
  ${CREDIT_FRAGMENT}
  ${PRICE_FRAGMENT}
`;
