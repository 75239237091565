import { sendGtmEvent } from '@modules/ga/gtm-event';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getPageViewEventData } from '@modules/ga/events/custom/custom-page-view/utils/getPageViewEventData';
import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { GaPageViewTimeSlots } from './ga-page-view-time-slots';
import { GaPageViewSearchResult } from './ga-page-view-search-result';
import { GaPageView } from './ga-page-view';

/* eslint-disable @typescript-eslint/naming-convention */
export interface CommonCustomPageViewHandlerProps {
  url: string;
  commonAnalyticsData: AnalyticsData;
}

const getPageData = (data: CommonCustomPageViewHandlerProps) => ({
  pageData: getPageViewEventData(
    data.url,
    getCustomerData({
      userDetails: data.commonAnalyticsData.userDetails,
      userStatus: data.commonAnalyticsData.userStatus,
      address: data.commonAnalyticsData.addressData,
      geoData: data.commonAnalyticsData.geoData,
      minimumAccountPreferences: data.commonAnalyticsData.minimumAccountPreferences,
    }),
    data.commonAnalyticsData.pageViewEventId ?? '',
  ),
});

export const commonCustomPageViewHandler = (data: CommonCustomPageViewHandlerProps) => {
  const { pageData } = getPageData(data);
  sendGtmEvent(GaPageView(pageData));
};

export interface CustomSearchPageViewHandlerProps extends CommonCustomPageViewHandlerProps {
  searchResults: number;
  searchParams: string;
}

export const customSearchPageViewHandler = (data: CustomSearchPageViewHandlerProps) => {
  const { pageData } = getPageData(data);
  sendGtmEvent(
    GaPageViewSearchResult({
      ...pageData,
      search_results: data.searchResults,
      browse: {
        searchParams: data.searchParams,
        url: pageData.pageURL.replace(window.location.origin, '').replace('/search?', ''),
      },
    }),
  );
};

export interface CustomCartPageViewHandlerProps extends CommonCustomPageViewHandlerProps {
  availableTimeslotValue: string;
  unavailableTimeslotPresent: string;
}

export const customCartPageViewHandler = (data: CustomCartPageViewHandlerProps) => {
  const { pageData } = getPageData(data);
  sendGtmEvent(
    GaPageViewTimeSlots({
      ...pageData,
      available_timeslot_value: data.availableTimeslotValue,
      unavailable_timeslot_present: data.unavailableTimeslotPresent,
    }),
  );
};
/* eslint-enable @typescript-eslint/naming-convention */
