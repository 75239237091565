import { useLazyQuery } from '@apollo/client';
import { CREDITS } from '@graphql/account/queries/credits';
import { creditAdapter } from '@adapters/creditAdapter';

export const useCredits = () => {
  const { getCredits } = creditAdapter();

  const [fetchCredits, { data, ...queryResult }] = useLazyQuery(CREDITS, {
    fetchPolicy: 'no-cache',
    ssr: false,
  });

  return { ...queryResult, data: getCredits(data?.storeCredits), fetchCredits };
};
