import { QueryHookOptions, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { ModifyingOrderAlertInfoQuery, ModifyingOrderAlertInfoQueryVariables } from '@api';
import { useOrderModalState } from '@graphql/variables/orderModalStateVar';
import { ORDER_MODAL_STATE } from '@constants/orderModalState';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { MODIFYING_ORDER_ALERT_INFO } from '@graphql/common/queries/modifyingOrderAlertInfo';

interface ModifyMode {
  active: boolean;
  orderId: string;
  requestedDate: string;
}

const getModifyMode = (data?: ModifyingOrderAlertInfoQuery): ModifyMode => {
  const queryData = data?.modifyingOrderAlertInfo;
  return {
    active: !!queryData?.isActive,
    orderId: queryData?.orderId ?? '',
    requestedDate: queryData?.requestedDate ?? '',
  };
};

export const useModifyMode = (
  options?: QueryHookOptions<ModifyingOrderAlertInfoQuery, ModifyingOrderAlertInfoQueryVariables>,
) => {
  const { setOrderModalState } = useOrderModalState();
  const { isSignedIn } = useAuthContext();
  const { data, ...params } = useQuery<
    ModifyingOrderAlertInfoQuery,
    ModifyingOrderAlertInfoQueryVariables
  >(MODIFYING_ORDER_ALERT_INFO, {
    ssr: false,
    fetchPolicy: 'cache-and-network',
    skip: !isSignedIn,
    onCompleted: ({ modifyingOrderAlertInfo }) =>
      !isEmpty(modifyingOrderAlertInfo)
        ? setOrderModalState(ORDER_MODAL_STATE.MODIFYING)
        : setOrderModalState(''),
    ...options,
  });

  return { ...params, data: getModifyMode(data) };
};
