import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { APPLY_PCI_PAL_PAYMENT } from '@graphql/masquerade/mutations/applyPciPalPayment';
import { updateCheckoutPaymentMethodsCache } from '@hooks/payments/updateCheckoutPaymentMethodsCache';
import { updatePaymentMethodsCache } from '@hooks/payments/updatePaymentMethodsCache';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import { Payment } from '@api';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';

export const useApplyPciPalPayment = (onCompleted: (message: string) => void) => {
  const {
    minimumAccountPreferences: { isMasqueradeMode },
  } = useMinimumAccountPreferences();
  const router = useRouter();
  const { t } = useLocalization('account');
  const newPaymentRef = useRef<Payment>();

  const [applyPciPalPayment, { loading }] = useMutation(APPLY_PCI_PAL_PAYMENT, {
    refetchQueries: [PAYMENT_METHODS],
    update(
      cache,
      {
        data: {
          applyPciPalPayment: { payments: payments },
        },
      },
    ) {
      const prevPayments: Payment[] = cache.read({ query: PAYMENT_METHODS, optimistic: false })
        .paymentMethods.payments;
      const newPayments: Payment[] = payments.payments;
      newPaymentRef.current = newPayments.find(
        (payment) => !prevPayments.some((prevPayment) => payment.id === prevPayment.id),
      );

      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
  const hasPciPalPaymentSearchParams = isMasqueradeMode && router.query.sessionID && !loading;

  useEffect(() => {
    if (!hasPciPalPaymentSearchParams) {
      return;
    }

    const input = Object.entries({ ...router.query })
      .filter(([key]) => key !== 'id')
      .map(([key, value]) => ({ key, value }));

    router.replace(routing.payment);

    applyPciPalPayment({
      variables: {
        input,
      },
      onCompleted: () => {
        const newPayment = newPaymentRef.current;
        const message = t('payments.successMessages.pciPal', {
          type: (newPayment?.type ?? newPayment?.bankAccountType) || '',
          number: newPayment?.accountNumber?.slice(4) || '',
        });
        onCompleted(message);
      },
    });
  }, [
    hasPciPalPaymentSearchParams,
    router.query,
    applyPciPalPayment,
    onCompleted,
    router.replace,
    t,
    router,
  ]);
};
