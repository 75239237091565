import { Typography } from '@mui/material';
import { PaymentIcon } from '@components/PaymentIcon/PaymentIcon';
import { usePaymentMethods, useLocalization } from '@hooks/index';
import { Link } from '@components/UI';
import { routing } from '@constants/routing';
import { ACCOUNT_MANAGEMENT_PAGE } from '@commons/account';
import { useAccountPageContext } from '@features/AccountPage/context/AccountPageContext';
import { getPaymentStringFormat } from './helpers';
import styles from './PaymentMethod.module.scss';

export const PaymentMethod = () => {
  const { t } = useLocalization('account');
  const {
    data: { defaultPayment },
  } = usePaymentMethods();
  const { fetchPageData } = useAccountPageContext();
  const shouldNavigate = true;
  const paymentString = getPaymentStringFormat(defaultPayment);

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography component="p" variant="smallBody" className={styles.title}>
          {t('deliveryPass.paymentMethod')}
        </Typography>
        {defaultPayment && !defaultPayment?.isExpired ? (
          <div className={styles.payment_info_container}>
            <PaymentIcon type={defaultPayment?.type} className={styles.payment_icon} />
            <Typography component="span" variant="body">
              {paymentString}
            </Typography>
          </div>
        ) : (
          <Link
            href={routing.payment}
            className={styles.link}
            onClick={() => fetchPageData(ACCOUNT_MANAGEMENT_PAGE.PAYMENT, shouldNavigate)}
          >
            <Typography component="p" variant="body">
              {t('deliveryPass.visitPaymentMethodsPage')}
            </Typography>
          </Link>
        )}
      </div>
    </div>
  );
};
