import { Typography } from '@mui/material';
import { ProductTile as IProductTile, Product } from '@commons/product';
import { shoppingList as ShoppingListIcon } from '@assets/icons/informational/with-spacing';
import { useLocalization } from '@hooks/useLocalization';
import { KEYBOARD_CODE } from '@constants/keyboard';

interface AddToShoppingListButtonProps {
  product: IProductTile | Product;
  classNames: string;
  handleAddToShoppingList: () => void;
  shouldRenderAddToShoppingListButton: boolean | undefined;
  listLabel?: string;
  nonFocusable?: boolean;
}

export const AddToShoppingListButton = ({
  product,
  classNames,
  listLabel,
  handleAddToShoppingList,
  shouldRenderAddToShoppingListButton,
  nonFocusable,
}: AddToShoppingListButtonProps) => {
  const { t } = useLocalization('productTile');

  if (!shouldRenderAddToShoppingListButton) {
    return null;
  }

  const productName = product?.brandName
    ? `${product.brandName} ${product.productName}`
    : product.productName;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KEYBOARD_CODE.ENTER) {
      handleAddToShoppingList();
    }
  };

  return (
    <div
      className={classNames}
      onClick={handleAddToShoppingList}
      onKeyDown={handleKeyDown}
      aria-label={t('common:shoppingList.ariaLabelAddToShoppingList', { product: productName })}
      title={t('common:shoppingList.ariaLabelAddToShoppingList', { product: productName })}
      role="button"
      tabIndex={nonFocusable ? -1 : 0}
    >
      <ShoppingListIcon />
      {listLabel && <Typography component="span">{listLabel}</Typography>}
    </div>
  );
};
