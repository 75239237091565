import { gql } from '@apollo/client';

export const AD_SERVER = gql`
  query AdServer {
    adServer {
      dfpId
      dfpEnabled
      adQuery
      adQueryParams {
        key
        value
      }
    }
  }
`;

export const AD_SERVER_CACHE_FIELD = 'adServer';
