import { MutableRefObject, RefObject } from 'react';
import { CriteoFormatBeacon } from '@modules/criteo';

export const sendBeacon = async (url: string) => {
  if (url.startsWith('//')) {
    url = (window.location.protocol || 'https:') + url;
  }
  try {
    if (navigator?.sendBeacon) {
      navigator.sendBeacon(url);
    } else {
      await fetch(url, {
        method: 'GET',
      });
    }
  } catch (catched) {}
};

export const isShowComponent = (isProductSelected?: boolean) =>
  typeof isProductSelected === 'boolean' ? isProductSelected : true;

interface ManageViewProps {
  viewed: MutableRefObject<boolean>;
  isShow: boolean;
  componentRef?: RefObject<HTMLDivElement | HTMLButtonElement>;
  onViewBeacon?: string;
  criteoFormatBeaconView?: CriteoFormatBeacon;
}

export const manageView = ({
  onViewBeacon,
  viewed,
  isShow,
  componentRef,
  criteoFormatBeaconView,
}: ManageViewProps) => {
  let observer: IntersectionObserver | undefined;
  if (!viewed.current) {
    observer = new IntersectionObserver(
      ([change]) => {
        if (change.isIntersecting && onViewBeacon && isShow) {
          sendBeacon(onViewBeacon);
          viewed.current = true;
          observer?.disconnect();

          if (criteoFormatBeaconView?.onViewBeacon && !criteoFormatBeaconView?.viewed?.current) {
            sendBeacon(criteoFormatBeaconView.onViewBeacon);
            criteoFormatBeaconView?.setViewed?.();
          }
        }
      },
      // current TS version doesn't know about the delay option
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { threshold: 0.5, delay: 1000 } as any,
    );
    if (componentRef && componentRef.current) {
      observer.observe(componentRef.current);
    }
  }
  return observer;
};
