import { useState, useEffect } from 'react';
import { isInHash, addToHash, removeFromHash } from '@utils/urlUtils';

export const useModal = (urlhash?: string) => {
  const initialOpen = !!urlhash && isInHash(urlhash);
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);

  useEffect(() => {
    if (typeof window !== 'undefined' && !!urlhash) {
      const handleHashChange = () => {
        setIsOpen(isInHash(urlhash));
      };

      window.addEventListener('hashchange', handleHashChange);

      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    }
  }, [urlhash, setIsOpen]);

  const closeModal = () => {
    if (!!urlhash && typeof window !== 'undefined') {
      removeFromHash(urlhash);
    } else {
      setIsOpen(false);
    }
  };

  const openModal = () => {
    if (!!urlhash && typeof window !== 'undefined') {
      addToHash(urlhash);
    } else {
      setIsOpen(true);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return { isOpen, closeModal, openModal, toggleModal };
};
