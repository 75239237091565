import cx from 'classnames';
import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { PriceIndicators as PriceIndicatorsType } from '@commons/cart';
import { ProductPrice } from '@commons/product';
import { PriceIndicators } from '@components/PriceIndicators/PriceIndicators';
import styles from './Subtotal.module.scss';

interface SubtotalProps {
  subtotalLabel: string;
  isModifiedSection: boolean;
  modifiedAmount: number;
  subtotal: ProductPrice;
  cartTotalPriceIndicators: PriceIndicatorsType;
  addMarginBeforeValue?: boolean;
}

export const Subtotal = ({
  subtotalLabel,
  isModifiedSection,
  modifiedAmount,
  subtotal,
  cartTotalPriceIndicators,
  addMarginBeforeValue,
}: SubtotalProps) => {
  const { t } = useLocalization('components');

  return (
    <div className={styles.container}>
      <Typography variant="smallBody" className={styles.subtotal_label}>
        {subtotalLabel}
      </Typography>
      {isModifiedSection && (
        <Typography variant="smallBody" fontWeight={400} className={styles.items_added}>
          {t('modifiedAmount', { modifiedAmount })}
        </Typography>
      )}
      <Typography
        variant="body"
        className={cx(
          styles.subtotal_value,
          {
            [styles.highlighted]: isModifiedSection,
          },
          { [styles.margin_left]: addMarginBeforeValue },
        )}
        data-testid="order-list-subtotal-price"
      >
        {subtotal.formattedPrice}
        <PriceIndicators
          data-testid="order-list-price-indicators"
          values={cartTotalPriceIndicators}
        />
      </Typography>
    </div>
  );
};
