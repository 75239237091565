import { forwardRef, useEffect, useState } from 'react';
import cx from 'classnames';
import { InfoContent } from '@components/ProductConfigurationModal/components/InfoContent/InfoContent';
import { Product, ProductTile, SelectedConfiguration } from '@commons/product';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { InitialValues } from '@hooks/cart/useProductInfoFromCart';
import { ModalContent } from '@features/ProductPage/components/AdditionalInfoModal/components/ModalContent/ModalContent';
import { TABS } from '@features/ProductPage/constants';
import { ShoppingListProductInfo } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';
import { AddToCartProps } from '@commons/addToCartProps';
import { StandingOrder } from '@commons/standingOrder';
import { useVariationItem } from '../../hooks/useVariationItem';
import { ConfigurationContent } from '../../components/Content/ConfigurationContent';
import styles from './ProductConfigurationModalContent.module.scss';

export enum INFO_TYPE {
  MORE = 'MORE',
  VARIATION_ITEM = 'VARIATION_ITEM',
}
export interface ProductConfigurationModalContentProps {
  product: Product | ProductTile;
  initialValues?: InitialValues;
  variant: MODAL_TYPE;
  withImageTile?: boolean;
  loading?: boolean;
  shouldShowInfo?: boolean;
  standingOrders?: StandingOrder[];
  isDisabledControls?: boolean;
  orderErrorMessage?: string;
  labelId?: string;
  onAddToShoppingList?: (params: ShoppingListProductInfo) => void;
  onReplace?: (configs: SelectedConfiguration[], replacedQnty?: number) => void;
  onShowInfo?: (infoType: INFO_TYPE) => void;
  onOrder: (props: AddToCartProps) => void;
  onClose: () => void;
  isReorderItem?: boolean;
}

export const ProductConfigurationModalContent = forwardRef(
  (
    {
      product,
      initialValues,
      onAddToShoppingList,
      variant,
      withImageTile,
      loading,
      shouldShowInfo,
      standingOrders,
      isDisabledControls,
      orderErrorMessage,
      labelId,
      onReplace,
      onShowInfo,
      onOrder,
      onClose,
      isReorderItem,
    }: ProductConfigurationModalContentProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const [canShowInfo, setCanShowInfo] = useState(false);
    const [showedVariationItemId, setShowedVariationItemId] = useState<string>();
    const [htmlUrl, setHtmlUrl] = useState<string | null>(null);
    const [moreInfoTitle, setMoreInfoTitle] = useState<string | undefined>();
    const showedVariationItem = useVariationItem(showedVariationItemId);

    useEffect(() => {
      if (!shouldShowInfo) {
        if (showedVariationItemId) setShowedVariationItemId('');
        else if (canShowInfo) setCanShowInfo(false);
      }
    }, [canShowInfo, shouldShowInfo, showedVariationItemId]);

    const toggleInfoContent = (link: string, title?: string) => {
      setHtmlUrl(link);
      setMoreInfoTitle(title);
      modalContentToggle(true);
      onShowInfo?.(INFO_TYPE.MORE);
    };

    const modalContentToggle = (value?: boolean) => {
      setCanShowInfo(value ?? !canShowInfo);
    };

    const handleShowInfo = (id: string) => {
      setShowedVariationItemId(id);
      onShowInfo?.(INFO_TYPE.VARIATION_ITEM);
    };

    return (
      <>
        {showedVariationItemId && showedVariationItem && (
          <div className={styles.item_info_panel}>
            <ModalContent
              preselectedTab={TABS.DESCRIPTION}
              extraDetails={showedVariationItem.extraDetails}
              productQualityNote={showedVariationItem.productQualityNote}
              nutrition={{
                ...showedVariationItem.nutritionV2,
                ...showedVariationItem.nutrition,
              }}
              oldNutritionContent={
                showedVariationItem.oldNutritionContent?.length
                  ? showedVariationItem.oldNutritionContent
                  : null
              }
              description={showedVariationItem.extraDetails.extraDescription}
              instructions={showedVariationItem.instruction}
              link={showedVariationItem.instructionLink}
              allergens={showedVariationItem.allergens?.join(', ')}
              alcohol={showedVariationItem.wineData}
              productName={showedVariationItem.productName}
              bundleformat={true}
            />
          </div>
        )}

        <div className={cx({ [styles.hide]: showedVariationItemId })} tabIndex={-1} ref={ref}>
          {canShowInfo && htmlUrl && <InfoContent url={htmlUrl} title={moreInfoTitle} />}
          {/*We should hide this component by styles to save dropdowns state inside when InfoContent is active*/}
          <ConfigurationContent
            className={cx({
              [styles.hide]: canShowInfo,
            })}
            hideComponent={!!showedVariationItemId}
            onShowVariationInfo={handleShowInfo}
            product={product}
            initialValues={initialValues}
            standingOrders={standingOrders}
            isDisabledControls={isDisabledControls}
            orderErrorMessage={orderErrorMessage}
            labelId={labelId}
            variant={variant}
            onMoreInfoClick={toggleInfoContent}
            onOrder={onOrder}
            loading={loading}
            withImageTile={withImageTile}
            onClose={onClose}
            onAddToShoppingList={onAddToShoppingList}
            onReplace={onReplace}
            productQuantity={
              isReorderItem ? product.quantity.minQuantity : product.quantity.quantity
            }
          />
        </div>
      </>
    );
  },
);

ProductConfigurationModalContent.displayName = 'ProductConfigurationModalContent';
