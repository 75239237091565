import { Popover, PopoverProps } from '@mui/material';
import cx from 'classnames';
import { DATA_QA } from '@constants/dataQA';
import styles from './PopUp.module.scss';

export const PopUp = ({ className, children, ...rest }: PopoverProps) => (
  <Popover
    {...rest}
    data-qa={DATA_QA.POP_UP_MODAL}
    PaperProps={{
      classes: { root: cx(styles.wrapper, className) },
    }}
  >
    {children}
  </Popover>
);
