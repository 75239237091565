import React, { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

export const Transition = forwardRef(function Transition(
  {
    children,
    ...props
  }: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="right" ref={ref} {...props}>
      {children}
    </Slide>
  );
});
