import React from 'react';
import cx from 'classnames';
import { NutritionItemV2, NutritionSection } from '@commons/product';
import styles from '@features/ProductPage/components/Nutrition/Nutrition.module.scss';
import { useLocalization } from '@hooks/useLocalization';
import { V2_TYPES } from '@features/ProductPage/constants/nutrition';

const FREETEXT = 'FREETEXT';
const INGREDIENT = 'INGREDIENT';
const amountPerSeving = 'Amount Per Serving';

export const MainItemsV2 = (section: NutritionSection, type: string | null) => {
  const isBabyFoodType = type === V2_TYPES.baby;
  const isPetType = type === V2_TYPES.pet;
  const { t } = useLocalization('product');

  if (section.type === FREETEXT) {
    return (
      <div className={cx(styles.top_border, styles.v2_main_item)}>
        <div className={styles.freetext_title_wrapper}>
          <span className={styles.freetext_title}>{section.title}</span>
        </div>
        {section?.item?.map((item) => (
          <span
            key={item.id}
            className={cx(styles.section_item, {
              [styles.bulleted]: item.bulleted,
              [styles.important]: item.important,
              [styles.section_item_pet]: isPetType,
            })}
          >
            {item.value1}
          </span>
        ))}
      </div>
    );
  }

  const renderValue2 = (item: NutritionItemV2) => {
    return (
      <>
        <span>{item.value2}</span>
        <span className={styles.ingredient_value}>
          {item.ingredientValue ? item.ingredientValue : ''}
          {item.uom}
        </span>
      </>
    );
  };
  if (section.type === INGREDIENT) {
    return (
      <div className={cx(styles.top_border, styles.v2_main_item)}>
        <span className={styles.daily_value_title}>{section.title}</span>
        {type === V2_TYPES.supplement && (
          <div className={styles.filters}>
            <span className={styles.filters_item}>{t('nutrition.amountPerServing')}</span>
            <span className={cx(styles.filters_item, styles.left_item)}>
              {t('nutrition.dailyValue')}
            </span>
          </div>
        )}
        {section?.item?.map((item) => {
          const hasValue2 = item.value2 !== null;
          if (item.value1 === amountPerSeving) {
            return null;
          }

          return (
            <tr key={item.id} className={cx(styles.table_item, styles.reseted_item)}>
              <td className={styles.nutritionv2_item}>
                <span
                  className={cx(styles.table_item_weight, {
                    [styles.table_item_v2_weight]: isBabyFoodType || isPetType,
                  })}
                >
                  {item.value1}
                </span>
              </td>
              <td className={cx(styles.v2_values)}>
                {hasValue2 ? (
                  renderValue2(item)
                ) : (
                  <>
                    <span>{item.ingredientValue}</span>
                    <span className={styles.ingredient_value}>{'-'}</span>
                  </>
                )}
              </td>
            </tr>
          );
        })}
      </div>
    );
  }
};
