interface GetCurrentButterflyBannerProps {
  index: number;
  productsPageSize: number;
  currentPage: string;
}

const FIRST_PAGE = '1';
const SECOND_PAGE = '2';
const ALL_PAGE = 'all';
const FIRST_BANNER_INDEX = 0;
const SECOND_BANNER_INDEX = 1;
const FIRST_BANNER_POSITION = 5;
const SECOND_BANNER_POSITION = 15;
const PRODUCTS_PAGE_SIZE_FOR_SECOND_BANNER = 18;

export const getCurrentButterflyBannerIndex = ({
  index,
  productsPageSize,
  currentPage,
}: GetCurrentButterflyBannerProps) => {
  const isInitPage = currentPage === FIRST_PAGE || currentPage === ALL_PAGE;
  const isPossibleCreateFirstBanner = index / FIRST_BANNER_POSITION === 1;
  const isPossibleCreateSecondBanner = index / SECOND_BANNER_POSITION === 1;

  if (isInitPage && isPossibleCreateFirstBanner) {
    return FIRST_BANNER_INDEX;
  }

  if (
    (isInitPage && isPossibleCreateSecondBanner) ||
    (currentPage === SECOND_PAGE &&
      productsPageSize < PRODUCTS_PAGE_SIZE_FOR_SECOND_BANNER &&
      isPossibleCreateFirstBanner)
  ) {
    return SECOND_BANNER_INDEX;
  }

  return null;
};
