import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI/Button/Button';
import { openDeleteAccountModal } from '@modules/modals/events/deleteAccountModal';
import { PageBlock } from '../PageBlock/PageBlock';
import styles from './DeleteAccount.module.scss';

export const DeleteAccount = () => {
  const { t } = useLocalization('account');

  const handleOpenModal = () => {
    openDeleteAccountModal();
  };

  return (
    <PageBlock title={t('preferences.deleteAccount.label')}>
      <Typography variant="smallBody">{t('preferences.deleteAccount.message')}</Typography>
      <Button className={styles.delete_button} size="large" onClick={handleOpenModal} isFullWidth>
        {t('preferences.deleteAccount.button')}
      </Button>
    </PageBlock>
  );
};
