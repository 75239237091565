import { Field, Form, Formik } from 'formik';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Trans from 'next-translate/Trans';
import isEqual from 'lodash/isEqual';
import { SubmitError } from '@components/UI/SubmitError/SubmitError';
import { useLocalization } from '@hooks/useLocalization';
import { Button, Link, RequiredNote } from '@components/UI';
import { Address, AddressToSend, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { accountAdapter } from '@adapters/accountAdapter';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { useSelectedAddress } from '@hooks/deliveryAddress/useSelectedAddress';
import { addressToString } from '@utils/addressToString';
import { AddAddressField, PhoneField, TextField } from '@components/FormikFields';
import { SpecialInstructions } from '@components/SpecialInstructions/SpecialInstructions';
import { MEDIUM_FIELD_LENGTH } from '@utils/validation';
import { routing } from '@constants/routing';
import { DELIVERY_CORPORATE_LEGACY_LINK } from '@constants/delivery';
import { ServiceTypeErrorAlert } from '@components/ServiceTypeErrorAlert/ServiceTypeErrorAlert';
import {
  getAddressInput,
  getAddressInString,
  getAptForForm,
  getShouldShowAtpUnit,
  getShouldShowNeighborAtpUnit,
  handleAddressFormValidation,
  handleFormChange,
  setAddressFieldWarnings,
  setAptUnitFieldError,
} from '@utils/addressForm';
import { scrollToErrorField } from '@utils/scrollToErrorField';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useValidator } from '@hooks/validation/useValidation';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { validateRequiredEmail } from '@utils/validators';
import { useResidentialAddressFormValidation } from './hooks/useResidentialAddressFormValidation';
import {
  ResidentialAddressFormProps,
  ResidentialFormKeys as FormKeys,
} from './ResidentialAddressForm.types';
import { FORM_FIELDS } from './ResidentialAddressForm.enums';
import styles from './ResidentialAddressForm.module.scss';

export const ResidentialAddressForm = ({
  initialValues,
  onSubmitForm,
  submitError,
  onServiceTypeErrorClick,
}: ResidentialAddressFormProps) => {
  const { t } = useLocalization();
  const { getFeature } = useHeaderContext();
  const { getAddressFromSuggestion, getSuggestionFromAddress } = accountAdapter();
  const {
    validateAptFormat,
    validateAddress,
    validateAptUnit,
    validateName,
    validatePhone,
    validateUnattendedDelivery,
  } = useResidentialAddressFormValidation();
  const validateEmailField = useValidator(validateRequiredEmail);
  const [formWarnings, setFormWarnings] = useState({});
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const cosFeatureEnabled = !!getFeature?.(FEATURE.COS);

  const { data: selectedAddress } = useSelectedAddress();
  const [serviceTypeError, setServiceTypeError] = useState(false);
  const [hasServiceTypeErrorAlert, setHasServiceTypeErrorAlert] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState<string | null>(null);
  const [inputAddress, setInputAddress] = useState<string | null>(null);

  const selectedFormattedAddress = selectedAddress?.address
    ? getSuggestionFromAddress(selectedAddress?.address)
    : null;

  const buttonSubmitText = initialValues.address.zipCode
    ? t('delivery.done')
    : t('account:delivery.addressModal.submitButton');

  const initialDeliveryAddress = initialValues?.address?.zipCode
    ? getSuggestionFromAddress(initialValues?.address as Address)
    : null;

  const initialNeighborDeliveryAddress = initialValues?.backupInfo?.address?.zipCode
    ? getSuggestionFromAddress(initialValues.backupInfo?.address as Address)
    : null;

  const hasInitialAptUnit = !!initialDeliveryAddress?.secondary;
  const deliveryAddressSuggestion = useRef(initialDeliveryAddress);
  const [hasUnattendedDeliveryOption, setUnattendedDeliveryOption] = useState<boolean | null>(
    !!initialValues?.backupInfo?.hasUnattendedDeliveryOption,
  );
  const [isAptUnitAvailable, setIsAptUnitAvailable] = useState(hasInitialAptUnit);

  const neighborDeliveryAddressSuggestion = useRef(initialNeighborDeliveryAddress);
  const [isNeighborAptUnitAvailable, setIsNeighborAptUnitAvailable] = useState(false);
  const {
    minimumAccountPreferences: { email, phoneNumber },
  } = useMinimumAccountPreferences();

  useEffect(() => {
    if (isSubmittingForm) {
      setTimeout(() => {
        scrollToErrorField();
      }, 100);
      setIsSubmittingForm(false);
    }
  }, [isSubmittingForm]);

  useEffect(() => {
    if (serviceTypeError && !hasServiceTypeErrorAlert && isFormSubmitted) {
      setHasServiceTypeErrorAlert(true);
    }

    if (!serviceTypeError && hasServiceTypeErrorAlert) {
      setHasServiceTypeErrorAlert(false);
    }
  }, [serviceTypeError]);

  const getFormWarnings = (messageKey: string) => {
    if (messageKey && !cosFeatureEnabled) {
      return {
        address: (
          <Trans
            i18nKey={`common:delivery.warning.addressField.${messageKey}`}
            components={{
              a: <Link inheritParentStyle href={DELIVERY_CORPORATE_LEGACY_LINK} />,
            }}
          />
        ),
      };
    }
    return {};
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAddressAptUnitFieldError = (addressInString: any, addressError: string | void) => {
    if (!cosFeatureEnabled && addressError === t('delivery.error.commercialAddress')) {
      return (
        <Trans
          i18nKey={`common:delivery.warning.addressField.corporateAddress`}
          components={{
            a: <Link inheritParentStyle href={DELIVERY_CORPORATE_LEGACY_LINK} />,
          }}
        />
      );
    }

    if (addressError === t('delivery.error.commercialAddress')) {
      setServiceTypeError(true);
    }

    if (cosFeatureEnabled && !addressInString) {
      setServiceTypeError(false);
      setHasServiceTypeErrorAlert(false);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        address: addressToString(
          initialValues.address.address1,
          initialValues.address.city,
          initialValues.address.state || '',
        ),
        aptUnit: initialValues.address.apartment || null,
        firstName: initialValues.contact.firstName,
        lastName: initialValues.contact.lastName,
        phone: initialValues.contact.phoneNumber.phone || phoneNumber,
        instructions: initialValues.contact.instructions,
        neighborFirstName: initialValues.backupInfo?.contact.firstName,
        neighborLastName: initialValues.backupInfo?.contact.lastName,

        neighborAddress: addressToString(
          initialValues.backupInfo?.address.address1,
          initialValues.backupInfo?.address.city,
          initialValues.backupInfo?.address.state || '',
        ),
        neighborApt: initialValues.backupInfo?.address?.apartment,
        neighborPhone: initialValues.backupInfo?.contact.phoneNumber.phone,
        unattendedInstructions: initialValues.backupInfo?.contact.instructions,
        deliveryOption: initialValues.backupInfo?.setting,
        email: email || '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (serviceTypeError && !hasServiceTypeErrorAlert) {
          setSubmitting(false);
          setHasServiceTypeErrorAlert(true);
          return;
        }

        setIsFormSubmitted(true);
        return onSubmitForm(
          getAddressInput({
            values,
            getAddressFromSuggestion,
            deliveryAddressSuggestion,
            neighborDeliveryAddressSuggestion,
          }),
          { setSubmitting },
        );
      }}
    >
      {(formik) => {
        const { values, errors, setFieldValue, setFieldError, validateField } = formik;

        const isErrorVisible = (fieldName: string) => !!errors[fieldName as FormKeys];
        const getHelperText = (fieldName: string) => errors[fieldName as FormKeys];

        if (!isEqual(formik.status?.warnings, formWarnings)) {
          formik.setStatus({ warnings: formWarnings });
        }

        const shouldShowAtpUnit = getShouldShowAtpUnit(isAptUnitAvailable, values);
        const shouldShowNeighborAtpUnit = getShouldShowNeighborAtpUnit(
          isNeighborAptUnitAvailable,
          values,
        );
        const addressInString = getAddressInString(inputAddress, formik.values);

        const validateAddressField = async () => {
          if (APIErrorMessage) return t('delivery.error.somethingWentWrong');

          const aptFormatError = validateAptFormat(values.aptUnit ?? '');

          const addressError = await validateAddress(
            addressInString,
            values.aptUnit,
            deliveryAddressSuggestion.current,
            (message) => setFieldError(FORM_FIELDS.ADDRESS, message),
            setIsAptUnitAvailable,
            setAddressFieldWarnings(
              getFormWarnings,
              setFormWarnings,
              setServiceTypeError,
              cosFeatureEnabled,
            ),
            aptFormatError,
          );

          const aptUnitError = await validateAptUnit(
            values.aptUnit,
            addressInString,
            deliveryAddressSuggestion.current,
            setAptUnitFieldError(setFieldError, SERVICE_TYPE.HOME, t, FORM_FIELDS.APT_UNIT),
            aptFormatError,
            setAddressFieldWarnings(
              getFormWarnings,
              setFormWarnings,
              setServiceTypeError,
              cosFeatureEnabled,
            ),
          );

          const validateUnattendedDeliveryOption = await validateUnattendedDelivery(
            deliveryAddressSuggestion.current,
            values.aptUnit,
          );

          setUnattendedDeliveryOption(validateUnattendedDeliveryOption);

          setTimeout(() => {
            setFieldError(FORM_FIELDS.APT_UNIT, aptUnitError);
          }, 0);

          handleAddressAptUnitFieldError(setFieldError, addressInString);

          return addressError;
        };

        const handleSubmitEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          await validateAddressField().then((res) => {
            if (!!res) {
              formik.setSubmitting(false);
              setFieldError(FORM_FIELDS.ADDRESS, res);
              return false;
            }

            setIsSubmittingForm(true);
            formik.submitForm();
          });
        };

        if (serviceTypeError && formik.isSubmitting) {
          formik.setSubmitting(false);
          return;
        }

        if (!isSubmittingForm && isFormSubmitted) {
          setIsFormSubmitted(false);
        }

        return (
          <Form onChange={handleFormChange(values, isErrorVisible, setFieldError)}>
            <RequiredNote optionalNote />
            <Field
              name={FORM_FIELDS.ADDRESS}
              serviceType={SERVICE_TYPE.HOME}
              component={AddAddressField}
              onChange={(value: AddressToSend, isManualFormSubmitted: boolean) => {
                // TODO: Refactor to use "AddressToSend"
                deliveryAddressSuggestion.current = getSuggestionFromAddress({
                  ...value,
                  state: value.state as string,
                  id: '',
                  isCustomerAnonymousAddress: false,
                  selected: false,
                  popupUrl: '',
                });

                handleAddressFormValidation(
                  setFieldError,
                  FORM_FIELDS,
                  validateField,
                  isManualFormSubmitted,
                );
                setFieldValue(FORM_FIELDS.APT_UNIT, getAptForForm(value.apartment, values.aptUnit));
                setInputAddress(addressToString(value.address1, value.city, value.state || ''));
              }}
              validate={validateAddressField}
              // DEPRECATED:BEGIN
              isAptUnitAvailable={shouldShowAtpUnit}
              inputAddress={addressInString}
              setInputAddress={(value: string) => {
                setInputAddress(value);
                deliveryAddressSuggestion.current = null;
                setFieldValue(FORM_FIELDS.APT_UNIT, '');
                setIsAptUnitAvailable(false);
              }}
              isAddressSelected={!!deliveryAddressSuggestion.current}
              selectedFormattedAddress={selectedFormattedAddress}
              setIsAptUnitAvailable={setIsAptUnitAvailable}
              setAPIErrorMessage={setAPIErrorMessage}
              formik={formik}
              // DEPRECATED:END
            />
            {hasServiceTypeErrorAlert && (
              <ServiceTypeErrorAlert
                mainText={t('common:delivery.warning.addressField.corporateAddressCOS.text')}
                linkText={t('common:delivery.warning.addressField.corporateAddressCOS.link')}
                onLinkClick={() => onServiceTypeErrorClick?.(SERVICE_TYPE.HOME)}
              />
            )}
            <div className={styles.separator} />
            <div className={styles.subtitle_wrapper}>
              <Typography className={styles.subtitle} variant="h5" component="span">
                {t('account:delivery.addressModal.instructionsTitle')}
              </Typography>
              <Button className={styles.questions_link} href={routing.help} variant="underline">
                {t('account:delivery.addressModal.questionsLink')}
              </Button>
            </div>
            <Field
              name={FORM_FIELDS.FIRST_NAME}
              className={styles.field}
              label={t(`account:delivery.addressModal.fields.firstName.fieldName`)}
              component={TextField}
              maxLength={MEDIUM_FIELD_LENGTH}
              error={isErrorVisible(FORM_FIELDS.FIRST_NAME)}
              helperText={getHelperText(FORM_FIELDS.FIRST_NAME)}
              validate={validateName}
              onBlur={() => validateField(FORM_FIELDS.FIRST_NAME)}
              id={FORM_FIELDS.FIRST_NAME}
              autoComplete="given-name"
            />
            <Field
              name={FORM_FIELDS.LAST_NAME}
              className={styles.field}
              label={t(`account:delivery.addressModal.fields.lastName.fieldName`)}
              component={TextField}
              maxLength={MEDIUM_FIELD_LENGTH}
              error={isErrorVisible(FORM_FIELDS.LAST_NAME)}
              helperText={getHelperText(FORM_FIELDS.LAST_NAME)}
              validate={validateName}
              onBlur={() => validateField(FORM_FIELDS.LAST_NAME)}
              id={FORM_FIELDS.LAST_NAME}
              autoComplete="family-name"
            />
            {!email && (
              <Field
                name={FORM_FIELDS.EMAIL}
                className={styles.field}
                label={t(`account:delivery.addressModal.fields.email.fieldName`)}
                component={TextField}
                error={isErrorVisible(FORM_FIELDS.EMAIL)}
                helperText={getHelperText(FORM_FIELDS.EMAIL)}
                onBlur={() => validateField(FORM_FIELDS.EMAIL)}
                validate={validateEmailField}
                id={FORM_FIELDS.EMAIL}
                autoComplete="email"
              />
            )}
            <Field
              name={FORM_FIELDS.PHONE}
              className={styles.field}
              label={t(`account:delivery.addressModal.fields.phone.fieldName`)}
              component={PhoneField}
              error={isErrorVisible(FORM_FIELDS.PHONE)}
              helperText={getHelperText(FORM_FIELDS.PHONE)}
              validate={validatePhone}
              onBlur={() => validateField(FORM_FIELDS.PHONE)}
              id={FORM_FIELDS.PHONE}
              autoComplete="tel"
            />
            <div className={styles.separator} />

            <SpecialInstructions
              formik={formik}
              isUnitAvailable={shouldShowNeighborAtpUnit}
              apiError={APIErrorMessage}
              onIsUnitAvailable={setIsNeighborAptUnitAvailable}
              initialNeighborDeliveryAddress={initialNeighborDeliveryAddress}
              onAddressSuggestion={(suggestion: Suggestion | null) => {
                neighborDeliveryAddressSuggestion.current = suggestion;
              }}
              onApiError={setAPIErrorMessage}
              hasUnattendedDeliveryOption={hasUnattendedDeliveryOption}
            />

            <Field
              name={FORM_FIELDS.INSTRUCTIONS}
              label={t(`account:delivery.addressModal.fields.residentialInstructions.fieldName`)}
              className={styles.instructions_field}
              placeholder={t(
                'account:delivery.addressModal.fields.residentialInstructions.placeholder',
              )}
              component={TextField}
              maxLength={255}
              id={FORM_FIELDS.INSTRUCTIONS}
              multiline
              isActive
            />
            <Box className={styles.sticky_container}>
              <SubmitError message={submitError}>
                <Button
                  className={styles.button}
                  size="large"
                  type="submit"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  onClick={handleSubmitEvent}
                  fullWidth
                >
                  {buttonSubmitText}
                </Button>
              </SubmitError>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
