import React from 'react';
import Image from 'next/image';
import { Typography } from '@mui/material';
import styles from './TileHeader.module.scss';

type TileHeaderProps = {
  url: string;
  alt: string;
  unitSize?: string;
  labelId?: string;
};

export const TileHeader = ({ url, alt, unitSize, labelId }: TileHeaderProps) => {
  const labelIdProp = {
    ...(labelId && { id: labelId, component: 'h2' }),
  };

  return (
    <>
      <div className={styles.product_preview} data-testid="preview image wrapper">
        {url && (
          <div className={styles.image_container}>
            <Image
              data-testid="product-image"
              className={styles.image}
              src={url}
              alt=""
              layout="fill"
              objectFit="contain"
              priority
            />
          </div>
        )}
        <Typography
          data-testid="product-name"
          className={styles.product_name}
          dangerouslySetInnerHTML={{ __html: alt }}
          {...labelIdProp}
        />
      </div>
      {unitSize && <Typography>{unitSize}</Typography>}
    </>
  );
};
