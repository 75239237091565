import { useQuery } from '@apollo/client';
import { geolocationAdapter } from '@adapters/geolocationAdapter';
import { GET_USER_GEOLOCATION_ADDRESS } from '@graphql/account/queries/userGeolocationAddress';

export const useGeolocation = () => {
  const { data, loading, error, ...rest } = useQuery(GET_USER_GEOLOCATION_ADDRESS, { ssr: false });

  const geolocationData = geolocationAdapter(data?.geolocation);
  const isDeliverableZone =
    geolocationData.address?.zipCode && geolocationData.isSuccess && geolocationData.isDeliverable;

  return {
    data: geolocationData,
    loading,
    error,
    isDeliverableZone,
    ...rest,
  };
};
