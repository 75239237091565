import { CART_MODAL_TYPES } from './types';

export const definitionList = [
  'definitions.estimatedPrice',
  'definitions.taxableItem',
  'definitions.stateBottleDeposit',
  'definitions.specialPrice',
];

export const triggerList = [
  {
    text: 'modalTriggers.estimatedPrices',
    modal: CART_MODAL_TYPES.ESTIMATED_PRICE,
  },
  {
    text: 'modalTriggers.taxesDepositsAndFees',
    modal: CART_MODAL_TYPES.TAXES,
  },
  {
    text: 'modalTriggers.customerAgreement',
    modal: CART_MODAL_TYPES.CUSTOMER_AGREEMENT,
  },
];
