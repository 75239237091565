import Script from 'next/script';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const RequiredScripts = () => {
  return (
    <Script
      src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.recaptureKey}`}
      strategy="afterInteractive"
    />
  );
};
