import { ButtonBase, Typography } from '@mui/material';
import { plus as PlusIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import styles from './ReorderCounter.module.scss';

interface ReorderCounterProps {
  productCount?: number;
  onClick?: () => void;
  buttonArialLabel?: string;
}

export const ReorderCounter = ({
  onClick,
  productCount = 0,
  buttonArialLabel,
}: ReorderCounterProps) => {
  const { t } = useLocalization('components');

  return (
    <div className={styles.wrapper} role="presentation" onClick={onClick}>
      <Typography variant="body" className={styles.wrapper_quantity}>
        {productCount}
      </Typography>
      <ButtonBase
        type="button"
        className={styles.wrapper_button}
        aria-label={t('simpleCounter.ariaLabels.plus', { buttonArialLabel })}
        data-testid="simple-counter-add"
      >
        <PlusIcon className={styles.icon} />
      </ButtonBase>
    </div>
  );
};
