import Image from 'next/image';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ProductImage } from '@commons/product';
import { getImageUrl } from '@features/ProductPage/utils/getImageUrl';
import { useLocalization } from '@hooks/index';
import styles from './ProductPageImageCarousel.module.scss';

interface ProductPageImageCarouselProps {
  images: ProductImage[];
  selectedImage: ProductImage | null;
  onChange: (image: ProductImage) => void;
}

export const ProductPageImageCarousel = ({
  images,
  selectedImage,
  onChange,
}: ProductPageImageCarouselProps) => {
  const { t } = useLocalization('product');

  const MAX_ITEMS_COUNT = 4;
  const displayedImages = images.slice(0, MAX_ITEMS_COUNT);
  let altText;

  return (
    <div className={styles.carousel_container} data-qa="pdp_image_carousel">
      {displayedImages.map((image, index) => {
        if (image.alt) {
          altText = `${image.alt}, image ${index + 1} of ${displayedImages.length} slides`;
        } else {
          altText = t('image.alt');
        }
        return (
          <button
            key={image.ref}
            onClick={() => onChange(image)}
            className={cx(styles.carousel_item, {
              [styles.selected]: isEqual(image, selectedImage),
            })}
          >
            <Typography component="span" variant="body" sx={visuallyHidden}>
              {`slide ${index + 1}`}
            </Typography>
            <span className="sr_only" aria-live="polite">
              {isEqual(image, selectedImage) && 'selected'}
            </span>
            <Image
              src={getImageUrl(image)}
              alt={altText}
              fill
              sizes="(max-width: 1400px) 80px, 128px"
              data-qa="pdp_image_carousel_item"
            />
          </button>
        );
      })}
    </div>
  );
};
