import { useQuery } from '@apollo/client';
import { MinimumAnalyticsDataQuery, MinimumAnalyticsDataQueryVariables } from '@api';
import { GET_MINIMUM_ANALYTICS_DATA } from '@graphql/analytics/queries/getAnalyticsData';

export const useMinimumAnalyticsData = () =>
  useQuery<MinimumAnalyticsDataQuery, MinimumAnalyticsDataQueryVariables>(
    GET_MINIMUM_ANALYTICS_DATA,
    {
      ssr: false,
      notifyOnNetworkStatusChange: true,
    },
  );
