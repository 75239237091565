import { DropdownOptionsType } from '@commons/account';

export const getExpirationYearsOptions = (): DropdownOptionsType[] => {
  const yearsArray = [];
  const date = new Date();
  const endYear = 20;

  for (let startYear = 0; startYear <= endYear; startYear++) {
    yearsArray.push({
      value: `${date.getFullYear() + startYear}`,
      label: `${date.getFullYear() + startYear}`,
    });
  }
  return yearsArray;
};
