import { InputAdornment } from '@mui/material';
import InputMask, { Props } from 'react-input-mask';
import React, { useRef } from 'react';
import { EBT_CARD_MASK } from '@utils/validation';
import { Input, InputProps } from '../Input/Input';

export const InputCard = ({
  label,
  helperText,
  error,
  icon,
  disabled,
  placeholder,
  ...props
}: InputProps & Props) => {
  const inputMaskRef = useRef(null);
  return (
    <InputMask {...props} ref={inputMaskRef} mask={EBT_CARD_MASK} maskChar="" disabled={disabled}>
      {(inputProps: InputProps) => (
        <Input
          {...inputProps}
          endAdornment={<InputAdornment position="end">{icon}</InputAdornment>}
          label={label}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          icon={icon}
          disabled={disabled}
        />
      )}
    </InputMask>
  );
};
