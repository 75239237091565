import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './RadioButton.module.scss';

export interface RadioButtonProps {
  id: string;
  children: ReactNode;
  name?: string;
  value?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  isXLarge?: boolean;
  isFullWidthLabel?: boolean;
  onChange?: (value?: string) => void;
  className?: string;
  mode?: string;
  dataTestId?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent) => void;
}

export const RadioButton = (props: RadioButtonProps) => {
  const {
    id,
    name,
    children,
    isDisabled,
    isChecked,
    isXLarge = true,
    isFullWidthLabel = false,
    value,
    className,
    mode,
    dataTestId,
    onChange = () => null,
    onKeyDown = () => null,
    onClick,
  } = props;

  return (
    //eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      data-testid={dataTestId}
      className={classNames(styles.wrapper, className)}
      onClick={onClick}
    >
      <input
        id={id}
        type="radio"
        name={name}
        checked={isChecked}
        disabled={isDisabled}
        className={styles.defaultCheckbox}
        tabIndex={0}
        onChange={() => {
          onChange(value);
        }}
        onKeyDown={onKeyDown}
      />
      <label
        htmlFor={id}
        className={classNames(styles.defaultRadioLabel, {
          [styles.label]: !isXLarge,
          [styles.labelXLarge]: isXLarge,
          [styles.dark]: mode === 'dark',
          [styles.orange]: mode === 'orange',
          [styles.full_width]: isFullWidthLabel,
        })}
      >
        {children}
      </label>
    </div>
  );
};
