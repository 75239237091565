import React from 'react';

export enum LAYOUTS {
  CART = 'cart',
  MAIN = 'main',
  FULL_SCREEN = 'full_screen',
  EMPTY = 'empty',
  ACCOUNT = 'account',
  LEFT_NAV = 'left_nav',
}

export const thanksGivingPageIds = ['thanksgiving', 'holiday_checklist', 'wgd_thx_faq'];

export interface FooterLinks {
  topLineItems: FooterTopLineItem[];
  bottomLine: FooterBottonLineItem;
}

interface FooterTopLineItem {
  title: string;
  id: string;
  gridAreaName: string;
  innerItems: FooterLinkWithIcon[];
}

interface FooterBottonLineItem {
  copyRight: string;
  innerItems: FooterLink[];
}

interface FooterLink {
  name: string;
  id: string;
  link: string;
  shouldBeShown: boolean;
  onClick?: () => void;
}

interface FooterLinkWithIcon extends FooterLink {
  icon?: React.FC<{ className?: string }>;
}
