export const addZerosToPrice = (price: number, maxFractionalChars: number, separator = '.') => {
  const separatorIndex = String(price).indexOf(separator);
  const lengthOfFractionaPart = String(price).length - separatorIndex - 1;
  const countOfZeros = maxFractionalChars - lengthOfFractionaPart;
  const isInvalidNumber = Math.sign(countOfZeros) === -1;

  if (isInvalidNumber) {
    // if number has many symbols in fractional part (for example 22.22222222)
    return price.toFixed(maxFractionalChars);
  }

  if (separatorIndex === -1) {
    // if number has not separator (for example 22)
    return price + separator + '0'.repeat(maxFractionalChars);
  }

  if (countOfZeros) {
    // if number has 1 or more numbers in fraction part (for example 22.2)
    return price + '0'.repeat(countOfZeros);
  }

  // if number already has full fraction part
  return price;
};
