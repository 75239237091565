import { useLazyQuery } from '@apollo/client';
import { Query } from '@api';
import { MINIMUM_ACCOUNT_PREFERENCES } from '@graphql/account/queries/minimumAccountPreferences';

export const useLazyMinimumAuthInfo = () =>
  useLazyQuery<Query>(MINIMUM_ACCOUNT_PREFERENCES, {
    ssr: false,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
