import {
  HandledDate,
  FORMATTED_SHORT_MONTHS,
  FORMATTED_FULL_MONTHS,
  FORMATTED_FULL_WEEKDAYS,
  FORMATTED_SHORT_WEEKDAYS,
} from '@commons/dates';

interface DateAdapter {
  getHandledDate: (date: Date) => HandledDate;
  getFullMonth: (month: number) => string;
  getShortMonth: (month: number) => string;
  getFullWeekday: (weekday: number) => string;
  getShortWeekday: (weekday: number) => string;
  getTime: (date: Date, lang?: string) => string;
  getShortTime: (date: Date, lang?: string) => string;
  getRangeDate: (startDate: string, endDate: string) => string;
  getFullMonthFromShort: (shortMonth: string) => string | null;
}

const fullMonths = Object.values(FORMATTED_FULL_MONTHS);
const shortMonths = Object.values(FORMATTED_SHORT_MONTHS);
const fullWeekdays = Object.values(FORMATTED_FULL_WEEKDAYS);
const shortWeekdays = Object.values(FORMATTED_SHORT_WEEKDAYS);

const getHandledDate = (date: Date): HandledDate => {
  const convertedDate = new Date(
    date.toLocaleString('en-US', {
      timeZone: 'America/New_York',
    }),
  );

  return {
    seconds: convertedDate.getSeconds(),
    minutes: convertedDate.getMinutes(),
    hours: convertedDate.getHours(),
    date: convertedDate.getDate(),
    weekday: convertedDate.getDay(),
    month: convertedDate.getMonth(),
    year: convertedDate.getFullYear(),
  };
};

const getFullMonth = (month: number): string => fullMonths[month];

const getShortMonth = (month: number): string => shortMonths[month];

const getFullWeekday = (weekday: number): string => fullWeekdays[weekday];

const getShortWeekday = (weekday: number): string => shortWeekdays[weekday];

const getTime = (date: Date, lang = 'en'): string => {
  const time = new Date(date)
    .toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })
    .toLowerCase();
  return time[0] === '0' ? time.substring(1, time.length) : time;
};

const getFullMonthFromShort = (shortMonth: string) => {
  //TODO: Remove this line once the BE update the response value.
  const inputMonth = shortMonth === 'Sep' ? 'Sept' : shortMonth;

  const fullMonthKey = Object.entries(FORMATTED_SHORT_MONTHS).find(
    ([, value]) => value === inputMonth,
  )?.[0] as keyof typeof FORMATTED_FULL_MONTHS;

  return fullMonthKey ? FORMATTED_FULL_MONTHS[fullMonthKey] : null;
};

const getShortTime = (date: Date, lang = 'en'): string => {
  const time = new Date(date)
    .toLocaleTimeString(lang, { hour: '2-digit', minute: undefined })
    .toLowerCase();
  return time[0] === '0' ? time.substring(1, time.length) : time;
};

const getRangeDate = (startDate: string, endDate: string) => {
  const start = getHandledDate(new Date(startDate));
  const end = getHandledDate(new Date(endDate));
  if (start.month === end.month) {
    return `${getShortMonth(start.month)} ${start.date} - ${end.date}`;
  }
  return `${getShortMonth(start.month)} ${start.date} - ${getShortMonth(end.month)} ${end.date}`;
};

export const dateAdapter = (): DateAdapter => {
  return {
    getFullMonthFromShort,
    getHandledDate,
    getFullMonth,
    getShortMonth,
    getFullWeekday,
    getShortWeekday,
    getTime,
    getShortTime,
    getRangeDate,
  };
};
