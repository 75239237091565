import { Operation } from '@apollo/client';
import { emit } from '@modules/emitter';
import { EVENT_ACTION } from '@modules/auth/constants';

export const emitAuthError = (operation: Operation) => {
  emit({ type: EVENT_ACTION.FAILED_WITH_AUTH_ERROR, data: { lastOperation: operation } });
};

export const emitAuthSuccess = () => {
  emit({ type: EVENT_ACTION.SUCCESSFULLY_AUTHORIZED });
};
