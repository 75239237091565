export enum CART_MODAL_TYPES {
  EMPTY = '',
  ESTIMATED_PRICE = 'ESTIMATED_PRICE',
  TAXES = 'TAXES',
  CUSTOMER_AGREEMENT = 'CUSTOMER_AGREEMENT',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY_SMS = 'PRIVACY_POLICY_SMS',
  TERMS_SMS = 'TERMS_SMS',
  GIFT_CARD_TERMS_AND_CONDITIONS = 'GIFT_CARD_TERMS_AND_CONDITIONS',
  ADVANCED_PRODUCTS = 'ADVANCED_PRODUCTS',
}
