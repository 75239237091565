import React from 'react';
import cx from 'classnames';
import { MainItemsSingle } from '@features/ProductPage/components/Nutrition/components/SingleColumnNutritionTable/components/MainItemsSingle';
import { Nutrition as NutritionAPI } from '@commons/product';
import styles from '@features/ProductPage/components/Nutrition/Nutrition.module.scss';
import { getNutritionMetaItems } from '@features/ProductPage/utils/getNutritionMetaItems';
import { ExtraItemsSingle } from '@features/ProductPage/components/Nutrition/components/SingleColumnNutritionTable/components/ExtraItemsSingle';
import { useLocalization } from '@hooks/useLocalization';

interface SingleColumnNutritionTableProps {
  nutrition: NutritionAPI;
}

export const SingleColumnNutritionTable = ({ nutrition }: SingleColumnNutritionTableProps) => {
  const { NUMBER_OF_SERVINGS, SERVING_WEIGHT, SERVING_SIZE, TOTAL_CALORIES } =
    getNutritionMetaItems(nutrition?.meta);
  const { t } = useLocalization('product');

  return (
    <>
      <div className={cx(styles.table_header, styles.single_header)}>
        <h1 className={cx(styles.title, styles.thick_border)}>
          {t('nutrition.nutritionFactsTitle')}
        </h1>
        {NUMBER_OF_SERVINGS && (
          <span className={styles.servings_number}>{NUMBER_OF_SERVINGS?.item?.value} </span>
        )}
        {SERVING_SIZE && (
          <div className={styles.servings}>
            <span className={styles.servings_size}>{t('nutrition.servingSize')}</span>
            <div className={styles.servings_size}>
              <span>{`${SERVING_SIZE?.item?.value} ${SERVING_SIZE?.item?.uom}`} </span>
              <span>{`(${SERVING_WEIGHT?.item?.value}${SERVING_WEIGHT?.item?.uom})`}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.medium_border}>
        <span className={styles.single_amount_per_serving}>{t('nutrition.amountPerServing')}</span>
        <div className={styles.servings}>
          <span className={cx(styles.daily_value_title, styles.single_amount_wrapper)}>
            {t('nutrition.calories')}
          </span>
          <span className={styles.single_calories}>{TOTAL_CALORIES?.item?.value}</span>
        </div>
      </div>
      <div className={cx(styles.daily_value_title, styles.right_align)}>
        <span>{t('nutrition.dailyValue')}</span>
      </div>
      {MainItemsSingle(nutrition)}
      {ExtraItemsSingle(nutrition)}
    </>
  );
};
