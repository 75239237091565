import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import debounce from 'lodash/debounce';
import { LightweightCart } from '@api';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { fireEvent } from '@modules/ga/emitter';
import { GA_ECOM_EVENT_ACTION } from '@modules/ga/type';
import { ProductTile } from '@commons/product';
import { GA_EVENT_TYPE } from '@modules/ga/constants';
import { getFormattedTileList, getProductPositionFromLightCart } from './utils';

interface ProductListPositionProviderProps {
  children: ReactNode;
}

export interface PositionData {
  position?: number;
  productId: string;
}

export type List = PositionData[];

interface ListExtraData {
  currentPage: number;
  totalPage: number;
  breadcrumbs: string;
}

export interface ProductTileWithPositionAndList extends ProductTile {
  list: string;
  position?: number;
  reported?: boolean;
}

export type ProductsListsMap = Map<string, ProductTileWithPositionAndList[]>;
export interface SetProductsPositions {
  (productsPosition: ProductsListsMap, listExtraData: ListExtraData): void;
}

interface PositionContextState {
  getProductPosition(productId: string): number;
  setProductPositions: SetProductsPositions;
}

const PositionEventContext = createContext<PositionContextState>({
  getProductPosition: () => 1,
  setProductPositions: () => {
    //  stub
  },
});

function mergeProducts(
  currentProducts: ProductTileWithPositionAndList[],
  additionalProducts: ProductTileWithPositionAndList[],
) {
  const newProducts = new Map(currentProducts.map((product) => [product.productId, product]));

  additionalProducts.forEach((product) => {
    if (!newProducts.has(product.productId)) {
      newProducts.set(product.productId, product);
    }
  });

  return Array.from(newProducts.values());
}

function mergeProductsLists(
  currentProductsLists: ProductsListsMap,
  additionalProductsLists: ProductsListsMap,
) {
  const newLists = new Map(currentProductsLists);

  additionalProductsLists.forEach((newProducts, key) => {
    if (!newLists.has(key)) {
      newLists.set(key, newProducts);
    } else {
      const oldProducts = newLists.get(key) ?? [];
      newLists.set(key, mergeProducts(oldProducts, newProducts));
    }
  });

  newLists.forEach((value) => {
    value.forEach((productPosition, index) => {
      productPosition.position = index + 1;
      productPosition.reported = !!productPosition.reported;
    });
  });
  return newLists;
}

function productInViewPort(product: ProductTileWithPositionAndList) {
  const tile = document.querySelector(
    `[data-ga-tile-list="${product.list}"][data-ga-tile-id="${product.productId}"]`,
  );
  if (!tile) {
    return false;
  }
  const rect = tile.getBoundingClientRect();
  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= window.innerHeight &&
    rect.left <= window.innerWidth
  );
}

export const ProductListPositionProvider = ({ children }: ProductListPositionProviderProps) => {
  const elementsIndexes = useRef(new Map<string, number>());
  const listRef = useRef<ProductsListsMap>(new Map());
  const pageParamsRef = useRef({ currentPage: 1, totalPage: 1, breadcrumbs: '' });
  const client = useApolloClient();
  const router = useRouter();

  const getProductPositionFromCart = (id: string) => {
    const lightCart = client.cache.readQuery<{ lightweightCart: DeepPartial<LightweightCart> }>({
      query: GET_LIGHT_CART,
    });
    return getProductPositionFromLightCart(lightCart?.lightweightCart, id);
  };

  const getProductPosition = (productId: string) => {
    const productPositionFromLightCart = getProductPositionFromCart(productId);

    if (productPositionFromLightCart) {
      return productPositionFromLightCart;
    }

    return elementsIndexes.current.get(productId) ?? 1;
  };

  function sendViewItemListName(productsPosition: ProductsListsMap) {
    /* eslint-disable @typescript-eslint/naming-convention */
    productsPosition.forEach((products, listName) => {
      const productsToReport = products.filter(
        (product) => !product.reported && productInViewPort(product),
      );
      if (productsToReport.length !== 0) {
        productsToReport.forEach((product) => {
          product.reported = true;
        });
        const uniqueProductsToReport = [
          ...new Map(productsToReport.map((item) => [item.productId, item])).values(),
        ];
        fireEvent({
          type: GA_EVENT_TYPE,
          message: GA_ECOM_EVENT_ACTION.VIEW_ITEM_LIST,
          data: {
            list_name: listName,
            products: uniqueProductsToReport,
            total_page: pageParamsRef.current.totalPage,
            current_page: pageParamsRef.current.currentPage,
            list_id: '',
            breadcrumb: pageParamsRef.current.breadcrumbs,
          },
        });
      }
    });
  }

  const setProductPositions: SetProductsPositions = (
    productsPosition: ProductsListsMap,
    { totalPage, currentPage, breadcrumbs },
  ) => {
    listRef.current = mergeProductsLists(listRef.current, productsPosition);
    pageParamsRef.current = { totalPage, currentPage, breadcrumbs };
    sendViewItemListName(listRef.current);
  };

  function setCurrentClickedElementPosition(element: Element, productId: string) {
    const tiles = document.querySelectorAll('[data-ga-tile-list-type="product-tile"]');
    const filteredTiles = getFormattedTileList(Array.from(tiles.entries()));
    for (const [index, value] of filteredTiles) {
      if (value === element) {
        elementsIndexes.current.set(productId, index + 1);
        break;
      }
    }
  }

  useEffect(
    () => {
      const clearPositions = () => {
        listRef.current.clear();
        elementsIndexes.current.clear();
      };

      const listenProductClick = (event: MouseEvent) => {
        const tile =
          (event.target as Element).closest &&
          (event.target as Element).closest('[data-ga-tile-id]');

        if (!tile) {
          return;
        }

        const productId = tile?.getAttribute('data-ga-tile-id') ?? '';
        if (productId) {
          setCurrentClickedElementPosition(tile, productId);
        }
      };

      const reportOnScroll = () => {
        sendViewItemListName(listRef.current);
      };
      const debouncedReportOnScroll = debounce(reportOnScroll, 100);

      addEventListener('scroll', debouncedReportOnScroll);
      addEventListener('click', listenProductClick, { capture: true });
      router.events.on('routeChangeStart', clearPositions);
      return () => {
        removeEventListener('scroll', debouncedReportOnScroll);
        removeEventListener('click', listenProductClick, { capture: true });
        router.events.off('routeChangeStart', clearPositions);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <PositionEventContext.Provider
      value={{
        getProductPosition,
        setProductPositions,
      }}
    >
      {children}
    </PositionEventContext.Provider>
  );
};

export const useProductPositionContext = () => useContext(PositionEventContext);
