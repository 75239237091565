import { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { Link } from '@components/UI';
import styles from '@components/OrderList/OrderList.module.scss';
import { ProductsGrid } from '@components/ProductsGrid';
import { Product, ProductBasic, ProductTile as IProductTile } from '@commons/product';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { SingleStoreCartLine as SingleStoreCartLineType } from '@commons/cart';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { CAROUSEL_TYPES } from '@commons/carousel';
import { OrderListProductTile } from '../OrderListProductTile/OrderListProductTile';

interface Props {
  open: boolean;
  onClose: () => void;
  productToReplace: (Product | ProductBasic)[];
  urlAlternative: string;
  cartLineId: string;
  onReplaceCompleted: (
    selectedProductId: string,
    selectedCartLine?: SingleStoreCartLineType,
  ) => void;
  isNonExpress: boolean;
}

export const ReplaceModal = ({
  open,
  onClose,
  productToReplace,
  urlAlternative,
  cartLineId,
  onReplaceCompleted,
  isNonExpress,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const replacedRef = useRef(false);
  const titleKey = isNonExpress ? 'cart:expressAlternatives' : 'cart:tryAlternatives';

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReplaceItem = async (promise: any) => {
    setLoading(true);
    try {
      await promise;
      replacedRef.current = true;
    } catch (e) {}
    setLoading(false);
  };

  const handleClose = () => {
    replacedRef.current = false;
    onClose();
  };

  return (
    <ModalWindow
      open={open}
      onClose={handleClose}
      isScrollable
      labelId={MODAL_TITLE_ID.REPLACE_PRODUCT}
    >
      <>
        <Typography
          variant="h3"
          component="h2"
          className={styles.alternative_modal_title}
          id={MODAL_TITLE_ID.REPLACE_PRODUCT}
        >
          <Trans
            i18nKey={titleKey}
            components={{
              link: (
                <Link
                  classNameLinkText={styles.alternative_modal_title_link}
                  href={`${urlAlternative}`}
                />
              ),
            }}
          />
        </Typography>
        <ProductsGrid
          xs={6}
          sm={4}
          products={productToReplace as IProductTile[]}
          customTileComponent={(product) => (
            <OrderListProductTile
              product={product}
              isReplaced={replacedRef.current}
              lineToReplace={cartLineId ? cartLineId : undefined}
              setShouldUpdate={setShouldUpdate}
              shouldUpdate={shouldUpdate}
              loading={loading}
              onReplace={handleReplaceItem}
              onReplaceCompleted={onReplaceCompleted}
              productCarouselType={CAROUSEL_TYPES.MODULE_DEFAULT}
            />
          )}
        />
      </>
    </ModalWindow>
  );
};
