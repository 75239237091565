import { FieldProps } from 'formik';
import {
  AddressWidgetForNeighbour,
  AddressWidgetForNeighbourProps,
} from '@components/AddressWidget/AddressWidgetForNeighbour';

interface AddAddressFieldForNeighbourProps extends AddressWidgetForNeighbourProps, FieldProps {}

// TODO: Delete this component when common AddressWidget will be ready

export const AddAddressFieldForNeighbour = ({
  field,
  form,
  ...props
}: AddAddressFieldForNeighbourProps) => {
  return <AddressWidgetForNeighbour {...field} {...props} />;
};
