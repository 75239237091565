import Breadcrumbs from '@mui/material/Breadcrumbs';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useLocalization } from '@hooks/useLocalization';
import { Transition } from '@components/Transition/Transition';
import { arrowLeft as ArrowLeftIcon, close as CloseIcon } from '@assets/icons/system';
import { DepartmentUnion } from '@api';
import { isSuperDepartmentItem } from '../../../../../../isSuperDepartmentItem';
import styles from './Categories.module.scss';
import { CategoryLink } from './CategoryLink';

interface CategoriesProps {
  isOpen: boolean;
  closeSubMenu: () => void;
  closeMenu: () => void;
  superDepartmentName?: string;
  department?: DepartmentUnion;
}

export const Categories = ({
  department,
  superDepartmentName,
  isOpen,
  closeSubMenu,
  closeMenu,
}: CategoriesProps) => {
  const { t } = useLocalization('header');
  const handleCloseAll = () => {
    closeSubMenu();
    closeMenu();
  };

  if (!department || isSuperDepartmentItem(department)) {
    return null;
  }

  const { name } = department;

  return (
    <Dialog
      className={styles.dialogs}
      fullScreen
      open={isOpen}
      onClose={closeSubMenu}
      TransitionComponent={Transition}
    >
      <div className={styles.top_menu}>
        <IconButton
          className={styles.back_button}
          onClick={closeSubMenu}
          aria-label={t('common:buttons.back')}
        >
          <ArrowLeftIcon width={17} height={17} />
        </IconButton>
        <div className={styles.category_header}>
          <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
            {superDepartmentName && (
              <Typography className={styles.breadcrumbs_text}>{superDepartmentName}</Typography>
            )}
            <Typography className={styles.breadcrumbs_text}>{name}</Typography>
          </Breadcrumbs>
          <IconButton
            className={styles.close_button}
            onClick={handleCloseAll}
            aria-label={t('common:buttons.close')}
          >
            <CloseIcon width={13} height={13} />
          </IconButton>
        </div>
      </div>
      <List className={styles.list_wrapper}>
        <ListItem className={styles.list_item} onClick={handleCloseAll}>
          <a href={department.url}>
            <ListItemText
              primaryTypographyProps={{ noWrap: true, color: 'white' }}
              primary={t('seeAll', { headline: name })}
            />
          </a>
        </ListItem>
        {department.dropDownCategories?.map((dropdownCategory) => {
          if (!dropdownCategory) {
            return null;
          }

          return dropdownCategory.categoryMap?.map((categoryMapItem) => {
            if (!categoryMapItem) {
              return null;
            }

            return categoryMapItem.categories?.map((category) => (
              <CategoryLink
                key={category.id}
                departmentName={department?.name}
                category={category}
                url={category.url}
                onCloseAll={handleCloseAll}
              />
            ));
          });
        })}
      </List>
    </Dialog>
  );
};
