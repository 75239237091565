import { getCookie } from 'cookies-next';
import { COOKIES_LIST } from '@commons/cookies';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';

export const ExpressContextGlance = () => {
  const { enabled: expressEnabled, eligible: expressEligible } = useExpressContext();
  const expressCookie = getCookie(COOKIES_LIST.IS_EXPRESS);

  return (
    <dl>
      <dt>eligible</dt>
      <dd>
        <code>{'' + expressEligible}</code>
      </dd>
      <dt>enabled</dt>
      <dd>
        <code>{'' + expressEnabled}</code>
      </dd>
      <dt>isExpress cookie</dt>
      <dd>
        <code>{'' + expressCookie}</code>
      </dd>
    </dl>
  );
};
