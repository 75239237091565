import { sendGtmEvent } from '@modules/ga/gtm-event';
import {
  AddToCartOptions,
  getAddToCartEvent,
} from '@modules/ga/events/ecommerce/add-to-cart/utils/getAddToCartEvent';
import {
  getRemoveFromCartEvent,
  RemoveFromCartOptions,
} from '@modules/ga/events/ecommerce/add-to-cart/utils/getRemoveFromCartEvent';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';

interface AddToCartHandlerProps extends Omit<AddToCartOptions, 'customer'> {
  commonAnalyticsData: AnalyticsData;
}

export const addToCartHandler = (data: AddToCartHandlerProps) => {
  const { userDetails } = data.commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getAddToCartEvent({
      ...data,
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
    }),
  );
};

interface RemoveFromCartHandlerProps extends Omit<RemoveFromCartOptions, 'customer'> {
  commonAnalyticsData: AnalyticsData;
}

export const removeFromCartHandler = (data: RemoveFromCartHandlerProps) => {
  const { userDetails } = data.commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getRemoveFromCartEvent({
      ...data,
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
    }),
  );
};
