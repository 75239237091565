import isUndefined from 'lodash/isUndefined';
import { TimeslotType } from '@api';
import { DeliveryTimeslots, Timeslot, TimeslotDay } from '@commons/timeslots';

export const STANDARD_TIMESLOTS_TEMPLATE = [
  {
    rangeStart: '',
    rangeEnd: '',
    slotType: TimeslotType.STANDARD,
    days: [],
  },
];

export const getExpressTimeslot = (timeslots: Timeslot[], isExpressOrder?: boolean) => {
  const defaultTimeslot = timeslots.find((timeslot) => timeslot.slotType === TimeslotType.EXPRESS);
  const isModifyMode = !isUndefined(isExpressOrder);
  const modifyOrderExpressTimeslot = isExpressOrder ? defaultTimeslot : undefined;

  return isModifyMode ? modifyOrderExpressTimeslot : defaultTimeslot;
};

export const getNonExpressTimeslots = (data: DeliveryTimeslots, isExpressOrder?: boolean) => {
  const defaultTimeslot = data.timeslots.filter(
    (timeslot) => timeslot.slotType !== TimeslotType.EXPRESS,
  );
  const isModifyMode = !isUndefined(isExpressOrder);
  const modifyOrderStandardTimeslot = isExpressOrder
    ? STANDARD_TIMESLOTS_TEMPLATE
    : defaultTimeslot;

  return {
    ...data,
    timeslots: isModifyMode ? modifyOrderStandardTimeslot : defaultTimeslot,
  };
};

export const sortTimeslots = (timeslotA: Timeslot, timeslotB: Timeslot) => {
  const dateA = new Date(timeslotA.rangeStart).getTime();
  const dateB = new Date(timeslotB.rangeStart).getTime();

  return dateA - dateB;
};

export const checkAvailabilityTimeslot = (chosenDay: TimeslotDay) => !!chosenDay?.date?.isAvailable;

export const checkExpressDaysAvailability = (days?: TimeslotDay[]) => {
  if (!days?.length) {
    return false;
  }

  return checkAvailabilityTimeslot(days[0]) || checkAvailabilityTimeslot(days[1]);
};

export const getDayBySelectedTimeslot = (
  timeslotDays: TimeslotDay[],
  selectedTimeslotId?: string,
) => {
  return timeslotDays.find(
    (day) =>
      checkAvailabilityTimeslot(day) && day.slots.find((slot) => slot.id === selectedTimeslotId),
  );
};

const getDayWithFreeTimeslot = (
  timeslotDays: TimeslotDay[],
  timeslotTypeSuggestion?: TimeslotType,
) => {
  const isExpressSuggestedTimeslot = timeslotTypeSuggestion === TimeslotType.EXPRESS;
  const preselectedExpressTimeslot = timeslotDays.find(
    (day) => day.slotType === TimeslotType.EXPRESS && !!day.slots.length,
  );

  if (isExpressSuggestedTimeslot && preselectedExpressTimeslot) {
    return preselectedExpressTimeslot;
  }

  return timeslotDays.find((day) => !!day.slots.length && day.slotType !== TimeslotType.EXPRESS);
};

export const getDefaultChosenDay = (
  timeslotDays: TimeslotDay[],
  selectedTimeslotId?: string,
  timeslotTypeSuggestion?: TimeslotType,
) => {
  const defaultDay = timeslotDays[0];

  return (
    getDayBySelectedTimeslot(timeslotDays, selectedTimeslotId) ||
    getDayWithFreeTimeslot(timeslotDays, timeslotTypeSuggestion) ||
    defaultDay
  );
};

export const getNonExpressAvailableTimeslotDay = (timeslotDays: TimeslotDay[]) =>
  timeslotDays.find((day) => !!day.slots.length);
