import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useGiftCardPurchaseInfo } from '@hooks/giftCard/useGiftCardPurchaseInfo';
import { GiftCardDeliveryType } from '@api';
import { useUpdateGiftCardInCart } from '@hooks/giftCard/useUpdateGiftCardInCart';
import { useLocalization } from '@hooks/useLocalization';
import { useGiftCardModal } from '@hooks/giftCard/useGiftCardModal';
import { MODAL_TITLE_ID } from '../Modal';
import styles from './GiftCardModal.module.scss';
import { Values } from './types';
import { GiftCardForm } from './components/GiftCardForm/GiftCardForm';
import { CUSTOM_AMOUNT_INPUT_VALUE } from './constants';

interface EditGiftCardModalProps {
  open: boolean;
  onClose: () => void;
  values: Values;
}

export const EditGiftCardModal = ({ open, onClose, values }: EditGiftCardModalProps) => {
  const { t } = useLocalization('giftCards');
  const { data, loading } = useGiftCardPurchaseInfo();
  const [updateGiftCard] = useUpdateGiftCardInCart();
  const { isCustomAmountEditShown, formRef, handleBack, handleClose, handleOpen } =
    useGiftCardModal();

  const updateValues = (newValues: Values) => {
    const updatedAmount = `${newValues.giftCardAmount}.00`;
    const isDefaultAmount = data.gcAmtOptions.includes(updatedAmount);
    return {
      ...newValues,
      giftCardAmount: updatedAmount,
      giftCardDefaultAmount: isDefaultAmount ? updatedAmount : CUSTOM_AMOUNT_INPUT_VALUE,
      giftCardCustomAmount: isDefaultAmount ? '' : newValues.giftCardAmount,
    };
  };

  const closeModalHandler = () => {
    handleClose();
    onClose();
  };

  const handleSubmit = (savedValues: Values) => {
    updateGiftCard({
      variables: {
        input: {
          id: savedValues.giftCardId,
          templateId: savedValues.giftCardTemplateId,
          amount: (savedValues.giftCardAmount || savedValues.giftCardDefaultAmount) ?? '',
          deliveryMethod: savedValues.giftCardType as GiftCardDeliveryType,
          recipientName: savedValues.recipientName,
          recipientEmail: savedValues.recipientEmail,
          senderName: savedValues.senderName,
          senderEmail: savedValues.senderEmail,
          message: savedValues.recipientMessage ?? '',
        },
      },
      onCompleted: () => onClose(),
    });
  };

  return (
    <ModalWindow
      open={open}
      onClose={closeModalHandler}
      className={styles.modal_wrapper}
      onBack={isCustomAmountEditShown ? handleBack : undefined}
      labelId={MODAL_TITLE_ID.EDIT_GIFTCARD_MODAL}
    >
      <>
        {!isCustomAmountEditShown && (
          <Typography
            variant="h3"
            component="h2"
            className={styles.title}
            id={MODAL_TITLE_ID.EDIT_GIFTCARD_MODAL}
          >
            {t('giftCardModal.editTitle')}
          </Typography>
        )}
        <GiftCardForm
          values={updateValues(values)}
          templateData={data}
          isCustomAmountEditShown={isCustomAmountEditShown}
          onOpenCustomAmountForm={handleOpen}
          onCloseCustomAmountForm={handleClose}
          onSubmit={handleSubmit}
          loading={loading}
          submitButtonText={'giftCardModal.editButton'}
          formRef={formRef}
        />
      </>
    </ModalWindow>
  );
};
