import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { MINIMUM_ACCOUNT_PREFERENCES } from '@graphql/account/queries/minimumAccountPreferences';
import { cartAdapter } from '@adapters/cartAdapter';
import {
  AccountPreferencesQuery,
  Cart,
  GetCartQuery,
  GetCartQueryVariables,
  GetMasqueradeCartQuery,
  GetMasqueradeCartQueryVariables,
} from '@api';
import { useLazyGetMasqueradeCart } from './useLazyGetMasqueradeCart';
import { useLazyGetCart } from './useLazyGetCart';

const { getCart } = cartAdapter();

type CartQuery = GetMasqueradeCartQuery | GetCartQuery;
type CartQueryVariables = GetMasqueradeCartQueryVariables | GetCartQueryVariables;

export const useCart = ({
  onCompleted,
  variables,
  ...restOptions
}: QueryHookOptions<CartQuery, CartQueryVariables> = {}) => {
  const [fetchMasqueradeCart, masqueradeCartData] = useLazyGetMasqueradeCart();
  const [fetchCart, cartData] = useLazyGetCart();
  const [isFetchingCart, setIsFetchingCart] = useState(true);
  const { data: accountPreferencesData } = useQuery<AccountPreferencesQuery>(
    MINIMUM_ACCOUNT_PREFERENCES,
    {
      ssr: false,
      onCompleted: ({ accountPreferences }) => {
        if (accountPreferences?.isMasqueradeMode) {
          fetchMasqueradeCart({
            ...restOptions,
            errorPolicy: 'all',
            variables,
            onCompleted,
          }).then(() => {
            setIsFetchingCart(false);
          });
        } else {
          fetchCart({
            ...restOptions,
            errorPolicy: 'all',
            variables,
            onCompleted,
          }).then(() => {
            setIsFetchingCart(false);
          });
        }
      },
    },
  );

  const {
    data,
    previousData,
    refetch,
    ...queryResult
  }: QueryResult<
    GetCartQuery | GetMasqueradeCartQuery,
    GetCartQueryVariables | GetMasqueradeCartQueryVariables
  > = accountPreferencesData?.accountPreferences?.isMasqueradeMode ? masqueradeCartData : cartData;

  const memoizedRefetch = useCallback(() => refetch(), [refetch]);

  const cart = useMemo(() => getCart(data?.cartForCheckout as Cart), [data?.cartForCheckout]);
  const previousCart = useMemo(
    () => getCart(previousData?.cartForCheckout as Cart),
    [previousData?.cartForCheckout],
  );
  const isEmpty =
    cart.totalItems === 0 && !cart.nonExpressSection?.cartLines.length && !cart.cartSections.length;

  return {
    ...queryResult,
    refetch: memoizedRefetch,
    rawData: data,
    data: cart,
    previousData: previousCart,
    isEmpty,
    loading: queryResult.loading || isFetchingCart,
  };
};
