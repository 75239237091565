import { CartLine } from '@commons/cart';
import { fireEvent } from '@modules/ga/emitter';
import { GA_EVENT_TYPE } from '@modules/ga/constants';
import { GA_ECOM_EVENT_ACTION } from '@modules/ga/type';

export interface AddShippingInfoOptions {
  cartLinesList: CartLine[];
}

export const fireAddShippingInfoGAEvent = (data: AddShippingInfoOptions) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_ECOM_EVENT_ACTION.ADD_SHIPPING_INFO,
    data,
  });
