export interface HandledDate {
  seconds: number;
  minutes: number;
  hours: number;
  date: number;
  weekday: number;
  month: number;
  year: number;
}

export const FORMATTED_FULL_MONTHS = {
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
};

export const FORMATTED_SHORT_MONTHS = {
  JANUARY: 'Jan',
  FEBRUARY: 'Feb',
  MARCH: 'Mar',
  APRIL: 'Apr',
  MAY: 'May',
  JUNE: 'Jun',
  JULY: 'Jul',
  AUGUST: 'Aug',
  SEPTEMBER: 'Sept',
  OCTOBER: 'Oct',
  NOVEMBER: 'Nov',
  DECEMBER: 'Dec',
};

export const FORMATTED_FULL_WEEKDAYS = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
};

export const FORMATTED_SHORT_WEEKDAYS = {
  SUNDAY: 'Sun',
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
};
