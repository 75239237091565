export const createAndSubmitBlankForm = (url: string, fields: Record<string, string>) => {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  form.setAttribute('target', '_blank');

  Object.entries(fields).forEach(([name, value]) => {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');

    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);

  form.submit();
  form.remove();
};
