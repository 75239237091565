import { ApolloQueryResult, useApolloClient, useLazyQuery } from '@apollo/client';
import { CHECKOUT } from '@graphql/checkout/query/checkout';
import { Payments, TimeslotData, DeliveryAddress } from '@api';

interface UseLazyCheckoutResult {
  deliveryAddress: DeliveryAddress;
  timeslot: TimeslotData;
  payments: Payments;
}

export const useLazyCheckout = () => {
  return useLazyQuery<UseLazyCheckoutResult>(CHECKOUT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
};

// Refer to issue https://github.com/apollographql/apollo-client/issues/9354 for more info.
// It happened when try click checkout as recognized/guest user and success login
export const useCustomLazyCheckout = () => {
  const client = useApolloClient();

  return (): Promise<ApolloQueryResult<UseLazyCheckoutResult>> =>
    client.query({
      query: CHECKOUT,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });
};
