import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { ishare as ShareIcon, copy as CopyIcon } from '@assets/icons/system';
import { twitter as TwitterIcon, facebook as FacebookIcon } from '@assets/icons/socials/simple';
import styles from './ShareWidget.module.scss';

interface ShareWidgetProps {
  children?: React.ReactNode;
  title?: string;
  url?: string;
  text?: string;
  label?: string;
  noIcon?: boolean;
}

export const ShareWidget = ({ children, title, url, text, label, noIcon }: ShareWidgetProps) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { t } = useLocalization('components');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShouldRender(true);
    }
  }, [setShouldRender]);

  if (!shouldRender) {
    return null;
  }

  title = title ?? document.title;
  url = url ?? window.location.href;
  text = text ?? document.title;
  const ariaLabel = label ?? `${t('shareButton.label')} ${title}`;
  label = label ?? t('shareButton.label');

  const fallbackShareButtons = () => {
    return (
      <div className={styles.fallback}>
        <Typography variant="body" component="div" className={styles.label}>
          {label}
        </Typography>
        <button
          className={styles.fallback_button}
          onClick={() => {
            navigator.clipboard.writeText(url);
          }}
          aria-label={ariaLabel + ' to clipboard'}
        >
          {!noIcon && <CopyIcon className={styles.share_icon} />}
          {t('shareButton.copyLink')}
        </button>
        <a
          className={styles.fallback_button}
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            text,
          )}&url=${encodeURIComponent(url)}`}
          target="_blank"
          rel="noreferrer"
          aria-label={ariaLabel + ' on Twitter'}
        >
          {!noIcon && <TwitterIcon className={styles.share_icon} />}
          {t('shareButton.twitter')}
        </a>
        <a
          className={styles.fallback_button}
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
          target="_blank"
          rel="noreferrer"
          aria-label={ariaLabel + 'on Facebook'}
        >
          {!noIcon && <FacebookIcon className={styles.share_icon} />}
          {t('shareButton.facebook')}
        </a>
      </div>
    );
  };

  if (!('share' in navigator)) {
    return fallbackShareButtons();
  }

  const handleShareClick = () => {
    navigator.share({ title, text, url });
  };

  const shareAriaText =
    typeof children === 'string' ? children : label ?? `${t('shareButton.label')} ${title}`;

  return (
    <div className={styles.main}>
      {children && label && (
        <Typography variant="body" component="div" className={styles.label}>
          {label}
        </Typography>
      )}
      <button onClick={handleShareClick} className={styles.share_button} aria-label={shareAriaText}>
        {!noIcon && <ShareIcon className={styles.share_icon} />}
        {children ?? label}
      </button>
    </div>
  );
};
