import { fireEvent } from '@modules/ga/emitter';
import { GA_EVENT_TYPE } from '@modules/ga/constants';
import { GA_ECOM_EVENT_ACTION } from '@modules/ga/type';
import { Product, ProductTile } from '@commons/product';

export interface SelectItemEventOptions {
  itemPosition: number;
  itemListName: string;
  product: ProductTile | Product;
}

export const fireSelectItemEvent = (data: SelectItemEventOptions) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_ECOM_EVENT_ACTION.SELECT_ITEM,
    data,
  });
};
