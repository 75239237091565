import { useMutation } from '@apollo/client';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { MODIFY_ORDER } from '@graphql/orderModification/mutations/modifyOrder';
import { areItemsAddedToCartInCurrentSession } from '@graphql/variables/areItemsAddedToCartInCurrentSession';
import { useRedirect } from '@hooks/useRedirect';
import { ORDER_ID_INVALID } from '@constants/errorCodes';
import { USER_DETAILS } from '@graphql/common/queries/userDetails';

export const useModifyOrder = (isSideBag?: boolean) => {
  const redirect = useRedirect();
  return useMutation(MODIFY_ORDER, {
    onCompleted: (res) => {
      if (res.modifyOrder.success) {
        areItemsAddedToCartInCurrentSession(true);
        return;
      }
      if (!isSideBag) {
        redirect.home();
      }
    },
    onError: (error) => {
      if (error.message === ORDER_ID_INVALID) {
        if (!isSideBag) {
          redirect.cart();
        }
      }
    },
    refetchQueries: [GET_LIGHT_CART, USER_DETAILS],
  });
};
