import { useRouter } from 'next/router';
import { useLocalization } from '@hooks/index';
import { standingOrders as StandingOrdersIcon } from '@assets/icons/informational';
import { routing } from '@constants/routing';
import styles from './StandingOrdersButton.module.scss';

export const StandingOrdersButton = () => {
  const { t } = useLocalization('header');
  const router = useRouter();

  const handleClick = () => {
    router.push(routing.standingOrders);
  };

  return (
    <div className={styles.wrapper}>
      <button className={styles.standing_orders} onClick={handleClick}>
        <span className={styles.icon_container}>
          <StandingOrdersIcon />
        </span>
        {t('standingOrders')}
      </button>
    </div>
  );
};
