import { MouseEventHandler } from 'react';
import { IconButton } from '@mui/material';
import Image from 'next/image';
import { useLocalization } from '@hooks/useLocalization';
import { info as InfoIcon } from '@assets/icons/system';
import styles from './ConfigurationDropdowns.module.scss';

export interface Option {
  value: string;
  label: string;
  index?: number;
  imagePath?: string;
  variationItemProductId?: string;
}

interface BundleOptionProps {
  onShowVariationInfo: ((value: string) => void) | undefined;
  dropdownChangeHandler: (newValue: string, arrIndex: number) => void;
}

type BundleOptionComponentProps = {
  option: Option;
  onOptionClick?: () => void;
};

export const BundleOption = ({ onShowVariationInfo, dropdownChangeHandler }: BundleOptionProps) => {
  const BundleOptionComponent = ({ option, onOptionClick }: BundleOptionComponentProps) => {
    const { t } = useLocalization('product');
    const labelId = `${option.variationItemProductId}_${option.index}`;

    const handleClickEvent: MouseEventHandler = (e) => {
      e.stopPropagation();
      onOptionClick?.();
      dropdownChangeHandler(option.value, option.index ?? 0);
    };

    const handleIconClick: MouseEventHandler = (e) => {
      e.stopPropagation();
      onOptionClick?.();
      onShowVariationInfo?.(option.variationItemProductId ?? '');
    };

    return (
      <div
        className={styles.item_with_pic}
        onClick={handleClickEvent}
        role="presentation"
        aria-labelledby={labelId}
      >
        <div>
          <Image src={option.imagePath ?? ''} alt="" width="54" height="54" />
          <span id={labelId}>{option.label}</span>
        </div>
        <IconButton
          className={styles.info_icon}
          onClick={handleIconClick}
          aria-label={`${t('bundleOptions.informationLabel')} ${option.label}`}
        >
          <InfoIcon />
        </IconButton>
      </div>
    );
  };

  return BundleOptionComponent;
};
