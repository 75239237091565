import ListItemIcon from '@mui/material/ListItemIcon';
import Image from 'next/image';
import { FC } from 'react';
import { useLoadHtml } from '@hooks/useLoadHtml/useLoadHtml';
import styles from '../../Hamburger.module.scss';

export interface NavIconProps {
  icon?: string | null;
  alt: string;
}

export const NavIcon: FC<NavIconProps> = ({ icon, alt }) => {
  const svg = useLoadHtml(icon);

  return (
    <ListItemIcon className={styles.list_image}>
      {svg ? (
        <div dangerouslySetInnerHTML={{ __html: svg }} />
      ) : (
        <Image src="" alt={alt} width={32} height={32} />
      )}
    </ListItemIcon>
  );
};
