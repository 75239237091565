import { ApolloCache } from '@apollo/client';
import { LightweightCart } from '@api';

export const updateModifyingOrderAlertCache = (
  cache: ApolloCache<unknown>,
  newCart: LightweightCart,
) => {
  cache.modify({
    fields: {
      modifyingOrderAlertInfo(alertInfo) {
        return {
          ...alertInfo,
          modifiedOrderLinesCount: newCart.modifiedOrderLinesCount,
        };
      },
    },
  });
};
