import { Typography } from '@mui/material';
import { warning as Warning } from '@assets/icons/system';
import styles from './PaymentError.module.scss';

interface PaymentErrorProps {
  errorMessage: string;
}

export const PaymentError = ({ errorMessage }: PaymentErrorProps) => {
  return (
    <div className={styles.error_wrapper}>
      <Warning width={12} height={12} className={styles.icon} />
      <Typography className={styles.error_message} variant="body">
        {errorMessage}
      </Typography>
    </div>
  );
};
