import { useEffect, useState } from 'react';
import { FetchPolicy } from '@apollo/client';
import { useLazyGetPendingOrders } from '@hooks/cart/useLazyGetPendingOrders';
import { useAuthContext } from '@modules/auth/context';

export function useGetPendingOrdersForPage() {
  const [pageFetchPolicy, setPageFetchPolicy] = useState<FetchPolicy>('network-only');
  const [getPendingOrders] = useLazyGetPendingOrders();
  const { isKnownUser } = useAuthContext();

  useEffect(() => {
    if (isKnownUser) {
      handleUserBecomesRecognized();
    }
  }, [isKnownUser]);

  function handleUserBecomesRecognized() {
    setPageFetchPolicy('network-only');
  }

  async function getPendingOrdersForPage() {
    const { data } = await getPendingOrders({ fetchPolicy: pageFetchPolicy });

    if (!data?.pendingOrdersInfo?.showPendingOrders) {
      setPageFetchPolicy('cache-first');
    }

    return data;
  }

  return getPendingOrdersForPage;
}
