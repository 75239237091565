import { createContext, useContext } from 'react';
import { ProductTile } from '@commons/product';

interface SelectProductContextInterface {
  isSelectMode: boolean;
  selectedProducts: ProductTile[];
  selectProduct: (product: ProductTile, selected: boolean) => void;
  clearSelectedProducts: () => void;
  checkIsSelectedProduct: (product: ProductTile) => boolean;
}

export const SelectProductContext = createContext<SelectProductContextInterface>({
  isSelectMode: false,
  selectedProducts: [],
  selectProduct: () => undefined,
  clearSelectedProducts: () => undefined,
  checkIsSelectedProduct: () => false,
});

export const useSelectProductContext = () => useContext(SelectProductContext);
