import { Maybe, ShoppingList as ShoppingListApi } from '@api';
import { ManageShoppingList, ShoppingList } from '@commons/shoppingList';

const getManageShoppingList = (list?: Maybe<ShoppingListApi>): ManageShoppingList => ({
  id: list?.id ?? '',
  name: list?.name ?? '',
  default: !!list?.default,
  delete: !!list?.delete,
});

export const getShoppingList = (list?: Maybe<ShoppingListApi>): ShoppingList => ({
  ...getManageShoppingList(list),
  productsCount: list?.productsCount ?? 0,
  selected: !!list?.selected,
});

export const getShoppingLists = (lists?: Maybe<ShoppingListApi[]>): ShoppingList[] =>
  (lists ?? []).map(getShoppingList);

export const getManageShoppingLists = (lists?: Maybe<ShoppingListApi[]>): ManageShoppingList[] =>
  (lists ?? []).map(getManageShoppingList);
