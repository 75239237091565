import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { MasqueradeBarData } from '@api';
import { MASQUERADE_BAR_DATA } from '@graphql/masquerade/queries/masqueradeBarData';

export const updateMasqueradeBarDataQuery = (
  client: ApolloClient<NormalizedCacheObject>,
  data: Partial<MasqueradeBarData>,
) => {
  client.writeQuery({
    query: MASQUERADE_BAR_DATA,
    data: {
      masqueradeBarData: {
        ...data,
      },
    },
  });
};
