import { IncomingMessage, ServerResponse } from 'http';
import getConfig from 'next/config';
import { getCookie } from 'cookies-next';
import { COOKIES_LIST } from '@commons/cookies';

const { publicRuntimeConfig } = getConfig();
const DEVELOPER_COOKIE_HASH =
  publicRuntimeConfig.devCookieHash ||
  'b493d48364afe44d11c0165cf470a4164d1e2609911ef998be868d46ade3de4e';

export const isDevMode = async ({
  cb,
  req,
  res,
}: {
  cb?: () => void;
  req?: IncomingMessage;
  res?: ServerResponse;
}) => {
  const devcookie = (getCookie(COOKIES_LIST.DEVELOPER, { req, res }) || '') as string;

  if (!devcookie) {
    return false;
  }

  // validate cookie
  const encoder = new TextEncoder();
  const data = encoder.encode(devcookie);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  if (hash === DEVELOPER_COOKIE_HASH) {
    if (cb) {
      cb();
    }
    return true;
  }

  return false;
};
