import { Typography } from '@mui/material';
import styles from './GeneralInfo.module.scss';

interface GeneralInfoProps {
  title: string;
  value: string | number;
}

export const GeneralInfo = ({ title, value }: GeneralInfoProps) => {
  return (
    <div>
      <Typography variant="body" className={styles.title}>
        {title}
      </Typography>
      <Typography component="p" variant="h3">
        {value}
      </Typography>
    </div>
  );
};
