import { gql } from '@apollo/client';
import { DELIVERY_PASS_INFO_FRAGMENT } from './deliveryPassInfoFragment';

export const GA_CUSTOMER_DATA_FRAGMENT = gql`
  fragment GACustomerDataFragment on GACustomerData {
    county
    state
    city
    orderCount
    orderCountResidential
    orderCountCorporate
    modifyMode
    timeslotReservationEligible
    timeslotReservation
    erpId
    userId
    expressEligible
    deliveryPassInfo {
      ...deliveryPassInfoFragment
    }
  }
  ${DELIVERY_PASS_INFO_FRAGMENT}
`;
