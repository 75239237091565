import { useMutation } from '@apollo/client';
import { updateCartCache } from '@hooks/cart/updateCartCache';
import { CHANGE_CART_LINE_COMPLAINT_REASON } from '@modules/masquerade/graphql/changeCartLineComplaintReason';
import { addTask } from '@modules/queue/queue';

export const useMakeGoodReason = () => {
  const [setReason] = useMutation(CHANGE_CART_LINE_COMPLAINT_REASON, {
    update(cache, { data: { changeCartLineComplaintReason: cart } }) {
      updateCartCache(cache, cart);
    },
  });

  const setReasonHandler = async (variables: {
    cartLineIds: string[];
    complaintReasonId: string;
  }) => {
    await addTask(async () => await setReason({ variables }));
  };

  return setReasonHandler;
};
