import { useRouter } from 'next/router';
import { getRootPath } from '@utils/getRootPath';

export interface RouterReplaceOptions {
  shallow?: boolean;
}

export const useRouterQuery = () => {
  const router = useRouter();

  const updateQueryParams = (
    queryObj: { [key: string]: string },
    options?: RouterReplaceOptions,
  ) => {
    const { keys, ...restQueryParams } = router.query;
    router.push(
      {
        pathname: getRootPath(router.asPath),
        query: {
          ...restQueryParams,
          ...queryObj,
        },
      },
      undefined,
      options,
    );
  };

  const removeQueryParams = (values: string[], options?: RouterReplaceOptions) => {
    const clearedQueryObj = { ...router.query };

    values.forEach((value) => {
      delete clearedQueryObj[value];
    });

    router.push(
      {
        pathname: getRootPath(router.asPath),
        query: {
          ...clearedQueryObj,
        },
      },
      undefined,
      options,
    );
  };

  const resetQueryParams = (options?: RouterReplaceOptions) => {
    router.push(
      {
        pathname: router.pathname,
        query: '',
      },
      undefined,
      options,
    );
  };

  return {
    updateQueryParams,
    removeQueryParams,
    resetQueryParams,
  };
};
