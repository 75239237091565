export const dfpAdBlockSizes = [
  'fluid',
  [186, 216],
  [228, 275],
  [240, 154],
  [273, 60],
  [310, 200],
  [349, 200],
  [480, 75],
  [480, 279],
  [480, 480],
  [578, 216],
  [683, 250],
  [728, 90],
  [774, 95],
  [774, 200],
  [970, 100],
  [970, 329],
  [970, 60],
  [970, 80],
  [970, 150],
  [970, 175],
  [1328, 120],
  [1328, 85],
  [549, 120],
  [549, 85],
  [320, 50],
  [303, 404],
];
