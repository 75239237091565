import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  id: string;
  name?: string;
  children?: JSX.Element | JSX.Element[];
  value?: string;
  wrapperClasses?: string[];
  isDisabled?: boolean;
  isChecked?: boolean;
  isXLarge?: boolean;
  onChange?: (value?: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  checkboxPosition?: 'center' | 'top';
  className?: string;
  reverse?: boolean;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    id,
    children,
    isDisabled,
    isChecked,
    isXLarge,
    value,
    onChange = () => null,
    onKeyDown = () => null,
    className,
    reverse,
    checkboxPosition = 'center',
    name,
    ...rest
  } = props;

  return (
    <div className={classNames(styles.wrapper, className, { [styles.reverse]: reverse })}>
      <input
        id={id}
        name={name}
        type="checkbox"
        data-testid="input-test-id"
        checked={isChecked}
        disabled={isDisabled}
        className={styles.default_checkbox}
        onChange={(e) => {
          onChange(value, e);
        }}
        onKeyDown={onKeyDown}
        {...rest}
      />
      <label
        htmlFor={id}
        className={classNames(styles.default_checkbox_label, styles[checkboxPosition], {
          [styles.label]: !isXLarge,
          [styles.label_xlarge]: isXLarge,
        })}
      >
        <span className={styles.checkmark} aria-hidden>
          ✓
        </span>
        {children}
      </label>
    </div>
  );
};
