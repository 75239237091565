export enum FIELDS {
  PHONE = 'phone',
  SMS_OFFERS = 'smsOffers',
  SMS_DELIVERY_UPDATES = 'smsDeliveryUpdates',
  WORK_PHONE_NUMBER = 'workPhoneNumber',
  WORK_PHONE_EXTENSION = 'workPhoneExtension',
}

export interface PhoneBlockValues {
  [FIELDS.PHONE]: string;
  [FIELDS.SMS_OFFERS]: boolean;
  [FIELDS.SMS_DELIVERY_UPDATES]: boolean;
  [FIELDS.WORK_PHONE_NUMBER]: string;
  [FIELDS.WORK_PHONE_EXTENSION]: string;
}
