import { Autocomplete, FilterOptionsState, InputAdornment } from '@mui/material';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { createRef, KeyboardEventHandler, memo, SyntheticEvent, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import dynamic from 'next/dynamic';
import uniqueId from 'lodash/uniqueId';
import { close as Close } from '@assets/icons/system';
import { InputSearch } from '@components/UI';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useLazyProductSuggestions } from '@hooks/useLazyProductSuggestions';
import { useProductConfigurationModal } from '@context/productConfigurationModalContext';
import { searchAutocompleteVar } from '@graphql/variables/searchAutocompleteVar';
import { searchSourceVar } from '@graphql/variables/searchSourceVar';
import { autosuggestVar } from '@graphql/variables/analyticMetrics';
import { AutosuggestProduct } from '@commons/product';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import styles from './Search.module.scss';

const DynamicSuggestionList = dynamic(
  () => import('./components/SuggestionList/SuggestionList').then((lib) => lib.SuggestionList),
  {
    ssr: false,
  },
);

interface SearchProps {
  tabIndex?: number;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

interface NavigatorWithVirtualKeyboard extends Navigator {
  virtualKeyboard: {
    overlaysContent: boolean;
    addEventListener: (
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | AddEventListenerOptions,
    ) => void;
  };
}

type AutocompleteValue = AutosuggestProduct | string | null;
type AutocompleteOption = Exclude<AutocompleteValue, null>;

const DEFAULT_SEARCH_VALUE = '';
const TIME_DELAY = 200;

const SearchInputElement = ({ className, onFocus, onBlur }: SearchProps) => {
  const router = useRouter();
  const defaultInputValue = (router.query?.search as string) ?? DEFAULT_SEARCH_VALUE;
  const autocompleteRef = createRef<HTMLElement>();
  const [value, setValue] = useState<AutocompleteValue>(null);
  const [inputValue, setInputValue] = useState<string>(defaultInputValue);
  const [typedInputValue, setTypedInputValue] = useState<string>('');
  const [focusWithin, setFocusWithin] = useState(false);

  const { t } = useLocalization('header');
  const { screenSize } = useHeaderContext();
  const isMobile = !!screenSize?.isMobile;
  const isLargeTablet = !!screenSize?.isLargeTablet;

  const debouncedInputValue = useDebounce<string>(inputValue, TIME_DELAY);
  const { fetchSuggestions, loading, suggestions } = useLazyProductSuggestions(debouncedInputValue);
  const { productConfigurationProps } = useProductConfigurationModal();
  const isAutocompleteOpened = useReactiveVar(searchAutocompleteVar);
  const [phraseForSearch, setPhraseForSearch] = useState('');
  const [descriptionId, setDescriptionId] = useState('search_input_description');

  const hasSuggestions = isAutocompleteOpened && suggestions.length;

  useUpdateEffect(() => {
    fetchSuggestions();
  }, [debouncedInputValue]);

  useUpdateEffect(() => {
    if (phraseForSearch) {
      setPhraseForSearch('');
      search();
    }
  }, [phraseForSearch, setPhraseForSearch]);

  useEffect(() => {
    if (descriptionId === 'search_input_description') {
      setDescriptionId(uniqueId('search_input_description_'));
    }
  }, [descriptionId]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!url.includes(routing.search)) {
        setInputValue(DEFAULT_SEARCH_VALUE);
      } else if (url.includes('?')) {
        const searchParams = new URLSearchParams(url.slice(url.indexOf('?')));
        setInputValue(searchParams.get('search') || DEFAULT_SEARCH_VALUE);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const search = () => {
    searchSubmitGAEvent(value, typedInputValue);
    autocompleteRef.current?.querySelector('input')?.blur();
    if (!value) return;
    searchSourceVar('search');

    const isProductSuggestion = typeof value !== 'string';

    if (isProductSuggestion) {
      router.push(value.productPageUrl);
    } else {
      router.push({
        pathname: routing.search,
        query: {
          search: String(value).trim().toLowerCase(),
        },
      });
    }
  };

  const handleOpen = () => {
    if (inputValue) {
      searchAutocompleteVar(true);
    }
  };

  const handleChange = (e: SyntheticEvent, newValue: AutocompleteValue) => {
    setTypedInputValue(inputValue);
    if (typeof value === 'string') {
      autosuggestVar(value);
    }
    if (e.type === 'click') {
      searchSubmitGAEvent(newValue, value);
    }
    setValue(newValue);
  };

  const handleInputChange = (e: SyntheticEvent, newInputValue: string) => {
    setValue(newInputValue);
    setInputValue(newInputValue);
    searchAutocompleteVar(newInputValue.length > 0);
  };

  const handleInputClear = (e: SyntheticEvent) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    setValue(DEFAULT_SEARCH_VALUE);
    setInputValue(DEFAULT_SEARCH_VALUE);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    search();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (e: SyntheticEvent<Element, Event> | any) => {
    if (!(e.relatedTarget && e.type === 'blur')) {
      searchAutocompleteVar(false);
    }
  };

  const getOptionLabel = (option: AutocompleteOption) =>
    typeof option === 'string' ? option : option.productName;

  const filterOptions = (
    options: AutocompleteOption[],
    params: FilterOptionsState<AutocompleteOption>,
  ) => (isMobile && params.inputValue && !suggestions.length ? [t('noResults')] : options);

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key.toLowerCase() === 'enter') {
      e.preventDefault();
      setPhraseForSearch(String(value).trim());
    }
  };

  const handleFocus = () => {
    setFocusWithin(true);
  };

  const handleBlur = () => {
    setFocusWithin(false);
  };

  const styledPopper = styled(Popper)({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '.MuiAutocomplete-paper': {
      boxShadow: '1px 20px 12px rgba(0, 0, 0, 0.04), 0 8px 20px rgba(0, 0, 0, 0.1)',
    },
  });

  const searchSubmitGAEvent = (
    keyword: AutocompleteValue | string,
    autosuggest: AutocompleteValue | string,
  ) => {
    const eventData = {
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'search-submit',
      search_keyword: keyword,
      search_autosuggest: autosuggest ?? '',
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    sendGtmEvent(eventData);
    setTypedInputValue('');
  };

  // android rerender issue fix start
  if (typeof window !== 'undefined' && 'virtualKeyboard' in window.navigator) {
    const navigatorWithVirtualKeyboard = window.navigator as NavigatorWithVirtualKeyboard;
    navigatorWithVirtualKeyboard.virtualKeyboard.overlaysContent = true;
    navigatorWithVirtualKeyboard.virtualKeyboard.addEventListener('geometrychange', () => {
      // android rerender issue fix
    });
  }
  // android rerender issue fix end

  return (
    <form
      className={cx(styles.search_input, { [styles.focus_within]: focusWithin }, className)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onSubmit={handleSubmit}
    >
      <Autocomplete
        ref={autocompleteRef}
        className={cx(styles.search_autocomplete_wrapper, {
          [styles.search_autocomplete_border]: hasSuggestions,
        })}
        classes={{ popper: styles.popper }}
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        open={productConfigurationProps?.hasSuggestion ? true : isAutocompleteOpened}
        onOpen={handleOpen}
        onFocus={onFocus}
        onBlur={onBlur}
        onClose={handleClose}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        options={!loading ? suggestions : []}
        PopperComponent={styledPopper} //required (as far as I can tell) in order to target popper elements for custom styling
        renderOption={(props, option) => {
          return <DynamicSuggestionList {...props} option={option} />;
        }}
        id="topline_search"
        renderInput={(params) => (
          <InputSearch
            onKeyDown={handleInputKeyDown}
            ref={params.InputProps.ref}
            inputProps={{
              maxLength: '255',
              ...params.inputProps,
              role: undefined,
              'aria-describedby': descriptionId,
            }}
            placeholder={t('searchPlaceholder')}
            label="Search"
            id="topline_search"
            endAdornment={
              <InputAdornment
                component="button"
                type="button"
                position="end"
                aria-label={t('closeIconAriaLabel')}
                className={styles.icon_clear}
                onClick={handleInputClear}
              >
                <Close width={isLargeTablet ? 16 : 12} height={isLargeTablet ? 16 : 12} />
              </InputAdornment>
            }
            autoCompleteText={value}
          />
        )}
        freeSolo
      />
      <span className="sr-only" id={descriptionId}>
        {t('searchInputDescription')}
      </span>
    </form>
  );
};

export const Search = memo(SearchInputElement);
