import { Action, IAction, PAction } from './action';

export interface PClose extends PAction {
  message: string;
}

export interface IClose extends IAction {
  message: string;
}

export const TClose = 'Close';

export function Close(p: PClose): IClose {
  const type = p.type ?? TClose;
  const action = Action({
    ...p,
    type,
  });
  const message = p.message;

  return {
    ...action,
    message,
  };
}
