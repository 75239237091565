import { useQuery } from '@apollo/client';
import { INITIALIZE_E_WALLET } from '@graphql/account/queries/initializeEWallet';
import { EWalletType } from '@api';

export const useInitEWallet = (type: EWalletType) => {
  const { data, ...rest } = useQuery(INITIALIZE_E_WALLET, {
    variables: {
      type,
    },
  });

  return {
    data,
    token: data?.initializeEWallet?.token ?? null,
    errors: data?.initializeEWallet?.validationErrors ?? [],
    ...rest,
  };
};
