import { FormikErrors } from 'formik';
import { addressAdapter } from '@adapters/addressAdapter';
import { useLocalization } from '@hooks/index';
import { useValidateDeliveryAddress } from '@hooks/deliveryAddress/useValidateDeliveryAddress';
import { AddressServerValidationResult, AddressToSend } from '@commons/deliveryAddresses';
import { hasUnallowedAddressSymbols, isValidZipCode } from '@utils/validation';

export const useManualAddressValidation = () => {
  const { t } = useLocalization();
  const { validateAddress } = useValidateDeliveryAddress();
  const { getValidationResponse } = addressAdapter();

  const getClientValidation = (values: AddressToSend) => {
    const requiredErrorMessage = t('delivery.error.required');
    const unallowedSymbolsErrorMessage = t('account:payments.editModal.errors.billingAddress');
    const invalidZipcodeErrorMessage = t('account:payments.editModal.errors.invalidZipCode');

    const clientErrors = {} as FormikErrors<AddressToSend>;

    if (!values.address1) clientErrors.address1 = requiredErrorMessage;
    if (hasUnallowedAddressSymbols(values.address1))
      clientErrors.address1 = unallowedSymbolsErrorMessage;

    if (hasUnallowedAddressSymbols(values.apartment))
      clientErrors.apartment = unallowedSymbolsErrorMessage;

    if (!values.city) clientErrors.city = requiredErrorMessage;
    if (hasUnallowedAddressSymbols(values.city)) clientErrors.city = unallowedSymbolsErrorMessage;

    if (!isValidZipCode(String(values.zipCode))) clientErrors.zipCode = invalidZipcodeErrorMessage;
    if (!values.zipCode) clientErrors.zipCode = requiredErrorMessage;

    return clientErrors;
  };

  const getServerValidation = async (
    address: AddressToSend,
  ): Promise<[FormikErrors<AddressToSend>, AddressServerValidationResult]> => {
    const serverErrors = {} as FormikErrors<AddressToSend>;
    let validationResponse = {} as AddressServerValidationResult;
    if (address.address1 && address.city && address.zipCode) {
      const { data, error } = await validateAddress({
        variables: {
          deliveryAddress: { address },
        },
      });

      validationResponse = getValidationResponse(data?.validateDeliveryAddress);
      const {
        isSuccess,
        isCommercialAddress,
        isResidentialAddress,
        isApartmentRequired,
        isOutOfDeliveryZone,
        isUnrecognizableAddress,
      } = validationResponse;

      if (isCommercialAddress) serverErrors.address1 = t('delivery.error.commercialAddress');
      if (isResidentialAddress) serverErrors.address1 = t('delivery.error.residentialAddress');
      if (isApartmentRequired) serverErrors.apartment = t('delivery.error.required');
      if (isOutOfDeliveryZone) serverErrors.address1 = t('delivery.error.outOfDeliveryArea');
      if (error || isUnrecognizableAddress)
        serverErrors.address1 = t('delivery.error.notRecognized');

      validationResponse = {
        ...validationResponse,
        isSuccess: isSuccess && !Object.keys(serverErrors).length,
      };
    }

    return [serverErrors, validationResponse];
  };

  return { getServerValidation, getClientValidation };
};
