import { forwardRef } from 'react';
import { Title } from '../../../Title';
import styles from './PageBlock.module.scss';

interface PageBlockProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  anchor?: string;
}

export const PageBlock = forwardRef<HTMLDivElement, PageBlockProps>(
  ({ children, title, anchor }, ref) => {
    return (
      <div className={styles.wrapper} id={anchor} ref={ref} tabIndex={-1}>
        <Title component="h2" className={styles.title}>
          {title}
        </Title>
        {children}
      </div>
    );
  },
);

PageBlock.displayName = 'PageBlock';
