import {
  CouponStatusName,
  MaterialPrice,
  Maybe,
  NutritionItem as NutritionItemApi,
  NutritionItemV2 as NutritionItemV2Api,
  NutritionMetaItem as NutritionMetaItemApi,
  NutritionSection as NutritionSectionApi,
  OrderItemLine as OrderItemLineAPI,
  ProductBasicData,
  ProductExtendedData,
  ProductQuantity as ProductQuantityApi,
  Variation,
  VariationGroup,
} from '@api';
import { BreadcrumbItem } from '@commons/breadcrumbs';
import { COUPON_CLIPPABLE } from '@commons/cart';
import {
  Nutrition,
  NutritionItem,
  NutritionItemV2,
  NutritionMetaItem,
  NutritionSection,
  NutritionV2,
  Product,
  ProductAnnotationData,
  ProductBasic,
  ProductForTile,
  ProductQuantity,
  ProductRangePrice,
  ProductTile,
  ProductTileVariationValues,
  ReorderProduct,
} from '@commons/product';
import { priceAdapter } from './priceAdapter';
import { getProductExternalData, getProductTile } from './product/getProductTile';

type ProductApi = DeepPartial<ProductExtendedData> | DeepPartial<ProductBasicData>;
type OrderItemLineApi = DeepPartial<OrderItemLineAPI>;

const { getPrice, getBasicPrice } = priceAdapter();

interface ProductAdapter {
  getShortProduct: (product?: ProductApi) => ProductForTile;
  getProduct: (product?: ProductApi) => Product;
  getProducts: (products?: DeepPartial<ProductApi[]>) => Product[];
  getReorderProduct: (product?: OrderItemLineApi) => ReorderProduct;
  getProductQuantity: (quantity?: Maybe<ProductQuantityApi>) => ProductQuantity;
  getProductBreadcrumbs: (product?: ProductApi) => BreadcrumbItem[];
  getProductBasic: (product?: DeepPartial<ProductBasicData>) => ProductBasic;
  getProductExtraDetails: (product?: ProductApi) => Pick<Product, 'extraDetails'>;
  getCriteoProducts: (product?: DeepPartial<ProductExtendedData>) => ProductTile[];
  getUpSellProducts: (product?: DeepPartial<ProductExtendedData>) => ProductTile[];
  getCrossSellProducts: (product?: DeepPartial<ProductExtendedData>) => ProductTile[];
}

function isExtendedProduct(product?: ProductApi): product is DeepPartial<ProductExtendedData> {
  const checkedProduct = product ?? {};
  return checkedProduct.__typename === 'ProductExtendedData';
}

const getBasicData = (product: ProductApi = {}): DeepPartial<ProductBasicData> => {
  if (isExtendedProduct(product)) {
    return product?.productBasicData || {};
  }

  return product;
};

const mapStringArray = (
  array?: Partial<Partial<Maybe<string>>[] | null | undefined>,
): string[] | null => {
  if (array) {
    return array.map((item) => item as string).filter((item) => item !== null);
  }

  return null;
};

const mapDiscountPrice = (price?: Maybe<MaterialPrice>): ProductRangePrice => {
  return {
    scaleLowerBound: price?.scaleLowerBound ?? null,
    scaleUpperBound: price?.scaleUpperBound ?? null,
    price: price?.price ?? null,
  };
};

const getProductBaseData = (
  product?: ProductApi,
): Pick<
  Product,
  | 'productId'
  | 'productName'
  | 'brandName'
  | 'productImage'
  | 'akaName'
  | 'productPageUrl'
  | 'categoryPageUrl'
  | 'deal'
  | 'clickBeacon'
  | 'imageBeacon'
  | 'viewBeacon'
  | 'alternativeProductId'
> => {
  const basicData = getBasicData(product);

  return {
    productId: basicData.productId ?? '',
    productPageUrl: basicData.productPageUrl ?? '',
    categoryPageUrl: basicData.categoryPageUrl ?? '',
    akaName: basicData.akaName ?? '',
    deal: basicData.deal ?? 0,
    productName: basicData.productName ?? '',
    brandName: basicData.brandName ?? '',
    productImage: {
      alt: basicData.productDescription ?? '',
      ref: basicData.productZoomImage ?? '',
      largeRef: basicData.productJumboImage ?? '',
      thumbnailRef: basicData.productAlternateImage ?? '',
    },
    clickBeacon: basicData.clickBeacon ?? '',
    imageBeacon: basicData.imageBeacon ?? '',
    viewBeacon: basicData.viewBeacon ?? '',
    alternativeProductId: basicData?.recommendedProduct?.productId ?? '',
  };
};

const getProductTags = (
  product?: ProductApi,
): Pick<Product, 'marketingTags' | 'featureTags' | 'preparationTime'> => {
  const basicData = getBasicData(product);

  return {
    marketingTags: {
      soldOut: !!basicData.marketingTags?.soldOut,
      sponsored: !!basicData.marketingTags?.sponsored,
      yourFave: !!basicData.marketingTags?.yourFave,
      backOnline: !!basicData.marketingTags?.backOnline,
      new: !!basicData.marketingTags?.new,
    },
    featureTags: {
      topPick: !!basicData.featureTags?.topPick,
      freeSample: !!basicData.featureTags?.topPick,
      expressEligible: !!basicData.featureTags?.expressEligible,
    },
    preparationTime: basicData.preparationTime ?? 0,
  };
};

const getProductPrice = (
  product?: ProductApi,
): Pick<Product, 'price' | 'wasPrice' | 'packCount' | 'unitPrice' | 'unitSize' | 'quantity'> => {
  const basicData = getBasicData(product);

  return {
    price: getPrice(basicData.price),
    wasPrice: getPrice(basicData.wasPrice),
    packCount: basicData.packCount ?? 0,
    unitPrice: basicData.unitPrice ?? '',
    unitSize: basicData.unitSize ?? '',
    quantity: getProductQuantity(basicData.quantity),
  };
};

const getProductDiscount = (
  product?: ProductApi,
): Pick<Product, 'coupon' | 'groupScale' | 'scaleUnit'> => {
  const basicData = getBasicData(product);

  const hasCoupon =
    !!basicData.coupon?.couponId &&
    !!basicData.coupon?.displayDescription &&
    !!basicData.coupon?.value;

  return {
    coupon: {
      couponId: basicData.coupon?.couponId ?? '',
      discount: Number(basicData.coupon?.value) ?? null,
      description: basicData.coupon?.displayDescription ?? '',
      detailedDescription: basicData.coupon?.detailedDescription || '',
      expirationDate: basicData.coupon?.expirationDate || '',
      hasCoupon: hasCoupon,
      isActive:
        basicData.coupon?.displayStatus?.name !== CouponStatusName.COUPON_ACTIVE &&
        basicData.coupon?.displayStatus?.name !== COUPON_CLIPPABLE,
    },
    groupScale: {
      grpId: basicData.groupScale?.grpId ?? '',
      version: basicData.groupScale?.version ?? '',
      grpPrice: basicData.groupScale?.grpPrice ?? '',
      grpDescription: basicData.groupScale?.grpDescription ?? '',
    },
    scaleUnit: basicData.scaleUnit ?? '',
  };
};

const getProductScalePrice = (product?: ProductApi): Pick<Product, 'scalePrice'> => {
  const basicData = getBasicData(product);

  return {
    scalePrice: basicData.scalePrice?.length
      ? basicData.scalePrice.map((price) => ({
          price: getPrice(price?.price),
          promoPrice: getPrice(price?.promoPrice),
          scaleUnit: price?.scaleUnit ?? null,
          pricingUnit: price?.pricingUnit ?? null,
          scaleLowerBound: price?.scaleLowerBound ?? null,
          scaleUpperBound: price?.scaleUpperBound ?? null,
        }))
      : [],
  };
};

const getProductVariationValues = (
  values?: DeepPartial<Variation['values']>,
): ProductTileVariationValues[] => {
  if (values && values?.length) {
    return values.map((value) => ({
      cvp: value?.cvp ?? '',
      description: value?.description ?? '',
      imagePath: value?.imagePath ?? '',
      isLabelValue: !!value?.isLabelValue,
      label: value?.label ?? '',
      name: value?.name ?? '',
      productName: value?.productName ?? '',
      selected: values.length > 1 ? !!value?.selected : true,
      variationItemProductData: {
        categoryId: value?.variationItemProductData?.categoryId ?? '',
        productId: value?.variationItemProductData?.productId ?? '',
      },
    }));
  }
  return [];
};

const getProductVariations = (product?: ProductApi): Pick<Product, 'variations'> => {
  const basicData = getBasicData(product);

  return {
    variations: basicData.variations?.length
      ? basicData.variations.map((item) => {
          const variationValues = getProductVariationValues(item?.values);
          return {
            name: item?.name ?? '',
            label: item?.label ?? '',
            optional: !!item?.optional,
            display: item?.display ?? '',
            underLabel: item?.underLabel ?? '',
            descrPopup: item?.descrPopup ?? '',
            descrMedia: item?.descrMedia ?? '',
            values: getProductVariationValues(item?.values),
            isMultipleValues: variationValues.length > 1,
          };
        })
      : [],
  };
};

const getProductImages = (product?: ProductApi): Pick<Product, 'productImageCarousel'> => {
  if (isExtendedProduct(product)) {
    return {
      productImageCarousel:
        product.productImageCarousel?.map((image) => ({
          alt: image?.alt ?? '',
          ref: image?.ref ?? '',
          largeRef: image?.largeRef ?? '',
          thumbnailRef: image?.thumbnailRef ?? '',
        })) || [],
    };
  }
  return {
    productImageCarousel: [],
  };
};

const getGrpPrices = (product?: ProductApi): Pick<Product, 'grpPrices'> => {
  const basicData = getBasicData(product);

  return {
    grpPrices: basicData.grpPrices?.length
      ? basicData.grpPrices.map((price) => mapDiscountPrice(price))
      : [],
  };
};

const getProductNutritionItemV2 = (
  item?: DeepPartial<NutritionItemV2Api> | null,
): NutritionItemV2 => {
  return {
    id: item?.id ?? '',
    value1: item?.value1 ?? null,
    value2: item?.value2 ?? null,
    ingredientValue: item?.ingredientValue ?? null,
    uom: item?.uom ?? '',
    position: item?.position ?? null,
    bulleted: !!item?.bulleted,
    important: !!item?.important,
    newLine: !!item?.newLine,
    separator: !!item?.separator,
  };
};

const getProductNutritionSection = (
  section?: DeepPartial<NutritionSectionApi> | null,
): NutritionSection => {
  return {
    id: section?.id ?? '',
    title: section?.title ?? null,
    position: section?.position ?? null,
    columnPosition: section?.columnPosition ?? null,
    importance: section?.importance ?? null,
    type: section?.type ?? null,
    item: (section?.item || []).map((item) => getProductNutritionItemV2(item)),
  };
};

const getProductNutitionV2 = (product?: ProductApi): NutritionV2 => {
  if (isExtendedProduct(product)) {
    const { nutritionV2 } = product;
    return {
      id: nutritionV2?.id ?? '',
      skuCode: nutritionV2?.skuCode ?? '',
      lastModifiedDate: nutritionV2?.lastModifiedDate ?? null,
      type: nutritionV2?.type ?? null,
      section: (nutritionV2?.section || []).map((item) => getProductNutritionSection(item)),
    };
  }

  return {
    id: '',
    skuCode: '',
    lastModifiedDate: null,
    type: null,
    section: [],
  };
};

const getProductNutritionItem = (
  nutritionItem: DeepPartial<NutritionItemApi> | null | undefined,
): NutritionItem => {
  return {
    id: nutritionItem?.id ?? '',
    displayName: nutritionItem?.displayName ?? '',
    priority: nutritionItem?.priority ?? null,
    value: nutritionItem?.value?.length ? (nutritionItem.value as string[]) : [],
    uom: nutritionItem?.uom?.length ? (nutritionItem.uom as string[]) : [],
    lessThan: nutritionItem?.lessThan?.length ? (nutritionItem.lessThan as boolean[]) : [],
    dailyValue: nutritionItem?.dailyValue?.length ? (nutritionItem.dailyValue as string[]) : [],
    dailyUom: nutritionItem?.dailyUom?.length ? (nutritionItem.dailyUom as string[]) : [],
    dailyLessThan: nutritionItem?.dailyLessThan?.length
      ? (nutritionItem.dailyLessThan as boolean[])
      : [],
  };
};

const getProductNutritionMeta = (
  meta?: DeepPartial<NutritionMetaItemApi> | null,
): NutritionMetaItem => {
  return {
    type: meta?.type || null,
    item: meta ? getProductNutritionItem(meta.item) : null,
  };
};

const getProductNutrition = (product?: ProductApi): Nutrition => {
  if (isExtendedProduct(product)) {
    const { nutrition } = product;
    return {
      panelType: nutrition?.panelType ?? null,
      columns: nutrition?.columns ?? null,
      meta: (nutrition?.meta || []).map((item) => getProductNutritionMeta(item)),
      main: (nutrition?.main || []).map((item) => getProductNutritionItem(item)),
      extra: (nutrition?.extra || []).map((item) => getProductNutritionItem(item)),
    };
  }

  return {
    panelType: null,
    columns: null,
    meta: [],
    main: [],
    extra: [],
  };
};

const getProductDiscountDetails = (
  product?: ProductApi,
): Pick<Product, 'cvPrices' | 'availMaterialPrices'> => {
  const basicData = getBasicData(product);

  return {
    cvPrices: basicData.cvPrices?.length
      ? basicData.cvPrices.map((price) => ({
          charValueName: price?.charValueName ?? null,
          price: price?.price ?? null,
          applyHow: price?.applyHow ?? null,
        }))
      : [],
    availMaterialPrices: basicData.availMaterialPrices?.length
      ? basicData.availMaterialPrices.map((price) => mapDiscountPrice(price))
      : [],
  };
};

const getProductSalesUnits = (product?: ProductApi): Pick<Product, 'salesUnits'> => {
  const basicData = getBasicData(product);

  return {
    salesUnits: basicData.salesUnits?.length
      ? basicData.salesUnits.map((value) => ({
          alternateSalesUnit: value?.alternateSalesUnit || null,
          name: value?.name || null,
          ratio: value?.ratio || null,
          salesUnit: value?.salesUnit || null,
          selected: value?.selected || null,
        }))
      : [],
  };
};

const getProductBundle = (product?: ProductApi): Pick<ProductBasic, 'bundle'> => {
  const basicData = getBasicData(product);

  return {
    bundle: {
      type: basicData?.bundle?.type ?? '',
      variationGroups:
        (basicData.bundle?.variationGroups ?? []).filter((i): i is VariationGroup => !!i) ?? [],
    },
  };
};

export const getProductExtraDetails = (product?: ProductApi): Pick<Product, 'extraDetails'> => {
  if (isExtendedProduct(product)) {
    return {
      extraDetails: {
        ingredients: product.ingredients ?? null,
        freshnessGuarantee: product.freshnessGuarantee ?? null,
        weightDisclaimer: product?.productBasicData?.weightDisclaimer ?? null,
        extraDescription: product.extraDescription ?? null,
        claims: mapStringArray(product.claims),
        organicClaims: mapStringArray(product.organicClaims),
        kosherIconPath: product.kosherIconPath ?? null,
        kosherSymbol: product.kosherSymbol ?? null,
        kosherType: product.kosherType ?? null,
        productDescriptionNote: product.productDescriptionNote ?? null,
        origin: product.origin ?? null,
        seasonText: product.seasonText ?? null,
        productAboutMediaPath: product.productAboutMediaPath ?? null,
      },
    };
  }
  return {
    extraDetails: {
      ingredients: null,
      freshnessGuarantee: null,
      weightDisclaimer: null,
      extraDescription: null,
      claims: [],
      organicClaims: [],
      kosherIconPath: null,
      kosherSymbol: null,
      kosherType: null,
      productDescriptionNote: null,
      origin: null,
      seasonText: null,
      productAboutMediaPath: null,
    },
  };
};

const getFilteredAvailability = (availability?: Maybe<Partial<Maybe<string>[]>>): string[] => {
  return availability?.filter((item): item is string => !!item) || [];
};

const getProductAllergens = (product?: DeepPartial<ProductExtendedData>): string[] => {
  return product?.allergens?.filter((item): item is string => !!item) ?? [];
};

const getProductAdditionalInfo = (
  product?: ProductApi,
): Pick<
  Product,
  | 'allergens'
  | 'availability'
  | 'extraDetails'
  | 'estimatedWeightDisclaimer'
  | 'productDescription'
  | 'instruction'
  | 'instructionLink'
  | 'productQualityNote'
  | 'discontinuedSoon'
> => {
  if (isExtendedProduct(product)) {
    return {
      ...getProductExtraDetails(product),
      allergens: getProductAllergens(product),
      availability: getFilteredAvailability(product.productBasicData?.availability),
      productQualityNote: product.productBasicData?.productQualityNote ?? '',
      estimatedWeightDisclaimer: product.productBasicData?.estimatedWeightDisclaimer ?? '',
      productDescription: product.productBasicData?.productDescription ?? '',
      instruction: product.heatingInstructions ?? '',
      instructionLink: product.instructionLink ?? '',
      discontinuedSoon: !!product.productBasicData?.discontinuedSoon,
    };
  }
  return {
    ...getProductExtraDetails(product),
    allergens: [],
    availability: getFilteredAvailability(product?.availability),
    productQualityNote: product?.productQualityNote ?? '',
    estimatedWeightDisclaimer: product?.estimatedWeightDisclaimer ?? '',
    productDescription: product?.productDescription ?? '',
    instruction: '',
    instructionLink: '',
    discontinuedSoon: !!product?.discontinuedSoon,
  };
};

const getProductAlcoholInfo = (
  product?: ProductApi,
): Pick<Product, 'wineData' | 'hasWineData' | 'isAlcoholic'> => {
  const basicData = getBasicData(product);
  let productData;
  if (isExtendedProduct(product)) {
    productData = product;
  }
  const wineData = {
    country: productData?.wineData?.country ?? '',
    region: productData?.wineData?.region ?? '',
    alcoholGrade: productData?.wineData?.alcoholGrade ?? '',
    grape: productData?.wineData?.grape ?? '',
    vintage: productData?.wineData?.vintage ?? '',
    city: productData?.wineData?.city ?? '',
    classification: productData?.wineData?.classification ?? '',
    importer: productData?.wineData?.importer ?? '',
    agingNotes: productData?.wineData?.agingNotes ?? '',
    types: productData?.wineData?.types ? (productData?.wineData?.types as string[]) : [],
    varietals: productData?.wineData?.varietals
      ? (productData?.wineData?.varietals as string[])
      : [],
  };
  return {
    ...getIsProductAlcoholic(basicData),
    hasWineData: !!basicData.fdWinesAndSpirits,
    wineData,
  };
};

export const getSelectedSalesUnit = (product?: ProductApi): string => {
  const { salesUnits } = getProductSalesUnits(product);
  const selectedSalesUnit = salesUnits?.find((unit) => unit?.selected)?.alternateSalesUnit;

  return selectedSalesUnit ? selectedSalesUnit : '';
};

const getProductQuantity = (quantity?: Maybe<ProductQuantityApi>): ProductQuantity => ({
  minQuantity: quantity?.minQuantity ?? 0,
  maxQuantity: quantity?.maxQuantity ?? 0,
  quantity: quantity?.quantity ?? 0,
  quantityIncrement: quantity?.quantityIncrement ?? 0,
});

const getShortProduct = (product?: ProductApi): ProductForTile => {
  const basicData = getBasicData(product);
  return {
    ...getGrpPrices(product),
    ...getProductBaseData(product),
    ...getProductTags(product),
    ...getProductDiscount(product),
    ...getProductPrice(product),
    ...getProductVariations(product),
    ...getProductScalePrice(product),
    ...getProductAlcoholInfo(product),
    ...getProductDiscountDetails(product),
    ...getProductSalesUnits(product),
    ...getProductBundle(product),
    deal: basicData.deal ?? 0,
    categoryId: basicData.categoryId ?? '',
    skuCode: basicData.skuCode ?? '',
    formattedCurrentPrice: basicData.formattedCurrentPrice ?? '',
    roughPricePerUnit: basicData.roughPricePerUnit ?? '',
    defaultScaleUnit: basicData.defaultScaleUnit ?? '',
    pricePerScaleUnit: basicData.pricePerScaleUnit ?? '',
    selectedSalesUnit: getSelectedSalesUnit(product),
    available: !!basicData.available,
    savingString: basicData.savingString ?? '',
    soldBySalesUnit: basicData.soldBySalesUnit ?? false,
    listItemLineId: basicData?.listItemLineId ?? '',
    configuration:
      basicData?.configuration?.map((item) => ({
        characteristicName: item?.key ?? '',
        characteristicValue: item?.value ?? '',
      })) ?? [],
    clickBeacon: basicData.clickBeacon ?? '',
  };
};

const getProductDiscountAmount = (product?: ProductApi) => {
  if (isExtendedProduct(product)) {
    return product.productBasicData?.discountAmount?.value ?? 0;
  }

  return product?.discountAmount?.value ?? 0;
};

const getAlternativeProductId = (product?: ProductApi): string => {
  if (isExtendedProduct(product)) {
    return product?.productBasicData?.recommendedProduct?.productId ?? '';
  }
  return product?.recommendedProduct?.productId ?? '';
};

const getProductAnnotationData = (product: Product) => {
  return isExtendedProduct(product)
    ? (product?.productAnnotationData as ProductAnnotationData)
    : null;
};
const getOldNutritionContent = (product: Product) => {
  return isExtendedProduct(product) ? product?.oldNutritionContent : null;
};

const getProduct = (product?: ProductApi): Product => {
  return {
    ...getShortProduct(product),
    ...getProductImages(product),
    ...getProductDiscountDetails(product),
    ...getProductAdditionalInfo(product),
    nutrition: { ...getProductNutrition(product) },
    nutritionV2: { ...getProductNutitionV2(product) },
    ...getProductAlcoholInfo(product),
    ...getProductSalesUnits(product),
    oldNutritionContent: getOldNutritionContent(product as Product),
    variantId: (<ProductBasicData>product)?.variantId ?? '',
    breadcrumbs: getProductBreadcrumbs(product),
    externalData: getProductExternalData((product as ProductBasicData)?.externalData),
    discountAmount: getProductDiscountAmount(product),
    alternativeProductId: getAlternativeProductId(product),
    productAnnotationData: getProductAnnotationData(product as Product),
  };
};

const getProducts = (products?: DeepPartial<ProductApi[]>): Product[] =>
  products?.length ? products.map((product) => getProduct(product)) : [];

const getReorderProduct = (product?: DeepPartial<OrderItemLineApi>): ReorderProduct => {
  return {
    ...getProductTile(product?.productBasicData),
    itemId: product?.itemId ?? '',
    originalLineId: product?.originalLineId ?? '',
    selectedSalesUnit: product?.salesUnit ?? '',
  };
};

const getProductBreadcrumbs = (product?: ProductApi): BreadcrumbItem[] =>
  (isExtendedProduct(product) &&
    product.breadcrumbs?.map((breadcrumb) => ({
      id: breadcrumb?.id ?? '',
      value: breadcrumb?.value ?? '',
      url: breadcrumb?.url ?? '',
    }))) ||
  [];

const getIsProductAlcoholic = (
  product?: DeepPartial<ProductBasicData>,
): Pick<Product, 'isAlcoholic'> => ({
  isAlcoholic: !!product?.alcoholic,
});

export const getProductBasic = (product?: DeepPartial<ProductBasicData>): ProductBasic => {
  return {
    ...getProductTags(product),
    ...getProductDiscountDetails(product),
    ...getProductDiscount(product),
    ...getProductScalePrice(product),
    ...getProductVariations(product),
    ...getProductSalesUnits(product),
    ...getProductBaseData(product),
    ...getProductBundle(product),
    ...getProductPrice(product),
    ...getGrpPrices(product),
    ...getIsProductAlcoholic(product),
    price: getBasicPrice(product?.price),
    wasPrice: getBasicPrice(product?.wasPrice),
    skuCode: product?.skuCode ?? '',
    available: product?.available ?? false,
    availability: getFilteredAvailability(product?.availability),
    productQualityNote: product?.productQualityNote ?? null,
    fdWinesAndSpirits: product?.fdWinesAndSpirits ?? false,
    productDescription: product?.productDescription ?? '',
    formattedCurrentPrice: product?.formattedCurrentPrice ?? '',
    defaultScaleUnit: product?.defaultScaleUnit ?? null,
    pricePerScaleUnit: product?.pricePerScaleUnit ?? '',
    savingString: product?.savingString ?? '',
    soldBySalesUnit: product?.soldBySalesUnit ?? false,
    variantId: product?.variantId ?? null,
    categoryId: product?.categoryId ?? '',
    listItemLineId: product?.listItemLineId ?? '',
    discountAmount: product?.discountAmount?.value ?? 0,
    clickBeacon: product?.clickBeacon ?? '',
    alternativeProductId: getAlternativeProductId(product),
  };
};

export const getCriteoProducts = (product?: DeepPartial<ProductExtendedData>): ProductTile[] => {
  return (product?.adProducts?.products ?? []).map(getProductTile);
};

export const getUpSellProducts = (product?: DeepPartial<ProductExtendedData>): ProductTile[] => {
  return (product?.upsellProductsCarousel?.products ?? []).map(getProductTile);
};

export const getCrossSellProducts = (product?: DeepPartial<ProductExtendedData>): ProductTile[] => {
  return (product?.crossSellProductsCarousel?.products ?? []).map(getProductTile);
};

export const productAdapter = (): ProductAdapter => {
  return {
    getShortProduct,
    getProduct,
    getProducts,
    getReorderProduct,
    getProductQuantity,
    getProductBreadcrumbs,
    getProductBasic,
    getProductExtraDetails,
    getCriteoProducts,
    getUpSellProducts,
    getCrossSellProducts,
  };
};
