import { FetchPolicy } from '@apollo/client';
import {
  formatError,
  getServiceTypeMessageKey,
} from '@utils/mapAddressServerErrorsToTranslationKey';
import { FormValues } from '@components/DeliveryOptionsContent/DeliveryOptionsContent';
import { accountAdapter } from '@adapters/accountAdapter';
import { useValidateDeliveryAddress } from '@hooks/deliveryAddress/useValidateDeliveryAddress';
import { getDeliveryAddressServiceType } from '@utils/getDeliveryAddressServiceType';
import { ADDRESS_SERVICE_TYPE, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { DeliveryAddressValidationResult } from '@api';
import { useLocalization } from './../useLocalization';

export const useAddressValidation = () => {
  const { t } = useLocalization();
  const { getAddressFromSuggestion } = accountAdapter();
  const { validateAddress } = useValidateDeliveryAddress();

  const validate = async (
    values: FormValues,
    fetchPolicy: FetchPolicy = 'cache-first',
    cosFeatureEnabled = false,
  ): Promise<{
    addressServiceType: ADDRESS_SERVICE_TYPE;
    serviceTypeMessageKey: string | null;
    textError: string;
    isAptUnitAvailable: boolean;
    isErrorAtpUnit: boolean;
    isUnattendedDeliveryAvailable: boolean;
    validationResult: DeliveryAddressValidationResult;
  }> => {
    if (!values.address)
      return {
        addressServiceType: ADDRESS_SERVICE_TYPE.RESIDENTIAL,
        serviceTypeMessageKey: null,
        textError: t('delivery.error.required'),
        isAptUnitAvailable: false,
        isErrorAtpUnit: false,
        isUnattendedDeliveryAvailable: false,
        validationResult: {},
      };

    const address = getAddressFromSuggestion(values.address);

    if (values.aptUnit) address.apartment = values.aptUnit;

    const { data: { validateDeliveryAddress } = {} } = await validateAddress({
      variables: {
        deliveryAddress: {
          address,
        },
      },
      fetchPolicy,
    });

    const addressServiceType = getDeliveryAddressServiceType(
      validateDeliveryAddress?.availableServiceTypes,
      cosFeatureEnabled,
    );

    const serviceTypeMessageKey = getServiceTypeMessageKey(
      validateDeliveryAddress?.availableServiceTypes,
      values.address.serviceType ?? SERVICE_TYPE.HOME,
      cosFeatureEnabled,
    );

    const { isErrorAtpUnit, actionError, validationError } = formatError(validateDeliveryAddress);

    return {
      addressServiceType,
      serviceTypeMessageKey,
      textError: actionError || validationError || '',
      isAptUnitAvailable:
        isErrorAtpUnit || (!!values.address?.entries && !actionError && !validationError),
      isErrorAtpUnit,
      isUnattendedDeliveryAvailable: validateDeliveryAddress?.isUnattendedDelivery,
      validationResult: validateDeliveryAddress,
    };
  };

  return { validate };
};
