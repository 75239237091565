import { gql } from '@apollo/client';

export const IMAGE_FRAGMENT = gql`
  fragment imageFragment on Image {
    ref
    thumbnailRef
    largeRef
    alt
  }
`;
