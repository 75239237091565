/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { PromotionType, ValidationError } from '@api';
import { PromoStatus } from '@commons/promo';
import { validateRequired } from '@utils/validators';
import { useValidator } from '@hooks/validation/useValidation';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { useApplyPromo } from './useApplyPromo';
import { useRemovePromo } from './useRemovePromo';
import { isPromoCodeInvalid } from './utils/isPromoCodeInvalid';
import { isPromoCodeExpired } from './utils/isPromoCodeExpired';
import { getInitialStatus } from './utils/getInitialStatus';

interface UsePromoResult {
  status: PromoStatus;
  code?: string;
  cartPromo?: string;
  error?: string;
  apply: (promo: string) => void;
  remove: (func: () => void) => void;
  loading?: boolean;
  promotionApplied: boolean;
  promotionType: PromotionType | null;
}

export const usePromo = (): UsePromoResult => {
  const [applyPromo] = useApplyPromo();
  const [removePromo] = useRemovePromo();
  const { clearCacheFields } = useCacheFieldsInvalidate();

  const { cartInfo } = useCartForCheckoutContext();
  const { data: cart, previousData: previousCart, loading, refetch: refetchCart } = cartInfo;
  const {
    promotionApplied,
    promotionCode,
    promotionInfo: { promotionType },
  } = cart.promotionCode;

  const { promotionApplied: prevPromotionApplied, promotionCode: prevPromotionCode } =
    previousCart.promotionCode;
  const subtotal = cart?.cartSections?.[0]?.sectionInfo?.subTotal?.value;

  const validate = useValidator(validateRequired);

  const [state, setState] = useState<{ status: PromoStatus; code?: string; error?: string }>({
    status: getInitialStatus(promotionApplied, promotionCode),
  });

  const [loadingInProgress, setLoadingInProgress] = useState(false);

  const updateState = (errors: ValidationError[], promo: string) => {
    if (!errors.length) {
      clearCacheFields(['orderModifications']);
      setState({ status: 'applied', code: promo, error: undefined });
      sendGtmEvent(
        GtmCustomEvent({
          event: 'checkout-success',
          event_name: 'promo_checkout_success',
          ua_category: 'checkout success',
          ua_action: 'promo',
          ua_label: promo,
        }),
      );
      refetchCart();
      return;
    }

    const shouldSetInvalidStatus =
      errors.length && (isPromoCodeInvalid(errors, promo) || isPromoCodeExpired(errors, promo));
    if (shouldSetInvalidStatus) {
      setState({ status: 'invalid', code: promo, error: errors[0].error });
      return;
    }
    if (errors.length) {
      setState({ status: 'condition', code: promo, error: errors[0].error });
    }
  };

  const apply = (promo: string) => {
    const error = validate(promo);
    if (error) return setState((prevState) => ({ ...prevState, error }));
    setLoadingInProgress(true);
    setState((prevState) => ({ ...prevState, error: undefined }));
    applyPromo({
      variables: { promo },
      onCompleted: (data) => {
        setLoadingInProgress(false);
        updateState(data.applyPromoCode, promo);
      },
    });
  };

  const remove = (func: () => void) =>
    removePromo({
      onCompleted: (data) => {
        if (!data.removePromoCode) return;
        setState({ status: 'empty', error: undefined, code: undefined });
        func();
        refetchCart();
      },
    });

  const synchronizeState = (applied: boolean, code: string) => {
    if (applied) {
      setState({ status: 'applied', code, error: undefined });
    }
    if (!applied && code) {
      apply(code);
    }
    if (!applied && !code) {
      setState({ status: 'empty', code: undefined, error: undefined });
    }
  };

  useEffect(() => {
    if (!(prevPromotionApplied === promotionApplied && prevPromotionCode === promotionCode)) {
      synchronizeState(promotionApplied, promotionCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionApplied, promotionCode]);

  useUpdateEffect(() => {
    if (!loading && state?.error) {
      apply(promotionCode);
    }
  }, [subtotal, loading]);

  return {
    ...state,
    cartPromo: promotionCode,
    apply,
    remove,
    loading: loadingInProgress,
    promotionApplied,
    promotionType,
  };
};
