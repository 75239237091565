import { gql } from '@apollo/client';
import { PAYMENT_METHODS_FRAGMENT } from '@graphql/fragments/account/paymentMethods';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';

export const EDIT_PAYMENT = gql`
  mutation EditPayment($input: PaymentInput!, $standingOrderMode: StandingOrderMode) {
    editPayment(input: $input, standingOrderMode: $standingOrderMode) {
      data {
        ...paymentMethodsFragment
      }
      validationErrors {
        ...validationErrorFragment
      }
    }
  }
  ${PAYMENT_METHODS_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
`;
