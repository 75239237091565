import { Skeleton } from '@mui/material';
import cx from 'classnames';
import React from 'react';
import {
  description as DescriptionIcon,
  nutrition as NutritionIcon,
  ingredients as IngredientsIcon,
  instructions as InstructionsIcon,
  alcoholInfo as AlcoholIcon,
} from '@assets/icons/informational';
import { useLocalization } from '@hooks/useLocalization';
import { TABS } from '../../constants';
import styles from './AdditionalInfo.module.scss';

interface InfoBlock {
  [key: string]: {
    icon: JSX.Element;
    name: TABS;
    hideAtLargeDevice?: boolean;
    value?: string | null;
  };
}

const ICON_SIZE = 20;

export const AdditionalInfoSkeleton = () => {
  const { t } = useLocalization();

  const INFO_BLOCKS: InfoBlock = {
    productDescription: {
      icon: <DescriptionIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: t('additionalInfo.tabs.description'),
      hideAtLargeDevice: true,
      value: '',
    },
    nutrition: {
      icon: <NutritionIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: t('additionalInfo.tabs.nutrition'),
      value: '',
    },
    ingridients: {
      icon: <IngredientsIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: t('additionalInfo.tabs.ingredients'),
      value: '',
    },
    instruction: {
      icon: <InstructionsIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: t('additionalInfo.tabs.instructions'),
      value: '',
    },
    alcohol: {
      icon: <AlcoholIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: t('additionalInfo.tabs.alcoholInfo'),
      value: '',
    },
  };

  return (
    <div className={cx(styles.wrapper, styles.additional_info)}>
      {Object.keys(INFO_BLOCKS).map((key: string) => {
        return <Skeleton key={key} className={styles.skeleton_item} variant="rectangular" />;
      })}
    </div>
  );
};
