import { Action, IAction, PAction } from '../../action';

export interface PGtmAddToCart extends PAction {
  pixelEventIds?: string[];
}

export interface IGtmAddToCart extends IAction {
  pixelEventIds: Array<string>;
}

export const TGtmAddToCart = 'GtmAddToCart';

export function GtmAddToCart(p: PGtmAddToCart): IGtmAddToCart {
  const type = p.type ?? TGtmAddToCart;
  const action = Action({
    ...p,
    type,
  });
  const pixelEventIds = p.pixelEventIds ?? [];
  return {
    ...action,
    pixelEventIds,
  };
}
