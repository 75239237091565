import { useLazyQuery } from '@apollo/client';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { accountAdapter } from '@adapters/accountAdapter';

export const useLazyPaymentMethods = () => {
  const { getFullPaymentData } = accountAdapter();
  const [fetchPayments, { data, ...queryResult }] = useLazyQuery(PAYMENT_METHODS, {
    ssr: false,
    fetchPolicy: 'network-only',
  });

  return { ...queryResult, data: getFullPaymentData(data?.paymentMethods), fetchPayments };
};
