import { setCookie } from 'cookies-next';
import { Cache } from '@apollo/client';
import { changeRelatedCacheIfServiceTypeChanged } from '@hooks/useServiceType';
import { COOKIES_LIST } from '@commons/cookies';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';

export const handleServiceTypeChange = (
  serviceType: SERVICE_TYPE | '',
  clearCacheFields: (cacheFields: string[], options?: Partial<Cache.ModifyOptions>) => void,
  isServiceTypeParamsPresent = false,
) => {
  if (serviceType && !isServiceTypeParamsPresent) {
    changeRelatedCacheIfServiceTypeChanged(serviceType, clearCacheFields);
    setCookie(COOKIES_LIST.SERVICE_TYPE, serviceType);
  }
};
