import classNames from 'classnames';
import { TAB_TYPE } from '../../Bag';
import styles from './Navigation.module.scss';

export type Tab = {
  id: TAB_TYPE;
  name: string;
  quantity: number;
  isActive?: boolean;
};

interface NavigationProps {
  onNavigate: (id: TAB_TYPE) => void;
  tabs: null | Tab[];
}

export const Navigation = ({ tabs, onNavigate }: NavigationProps) => {
  const activeTab = tabs?.find(({ isActive }) => isActive);

  return (
    <>
      <nav>
        {tabs && (
          <div
            role="tablist"
            className={classNames(styles.nav, {
              [styles.isSingleGiftCard]:
                activeTab?.id === TAB_TYPE.GIFT_CARDS && activeTab?.quantity === 1,
            })}
          >
            {tabs &&
              tabs.map(({ id, name, quantity, isActive }) => (
                <div
                  key={id}
                  className={classNames(styles.nav_item, {
                    [styles.active]: isActive,
                  })}
                >
                  <button
                    className={styles.nav_item_button}
                    role="tab"
                    aria-selected={isActive}
                    onClick={(e) => {
                      e.preventDefault();
                      onNavigate(id);
                    }}
                  >
                    <span className={styles.nav_item_name}>{name}</span>
                    <span className={styles.nav_item_quantity}>{quantity}</span>
                  </button>
                </div>
              ))}
          </div>
        )}
      </nav>
    </>
  );
};
