import { useState, SyntheticEvent, ReactNode, ReactElement } from 'react';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { TabPanel } from '@components/UI/Tabs/TabPanel';
import { TabsProps, Tab, ActiveTab } from '@components/UI/Tabs/Tabs.types';
import styles from './Tabs.module.scss';

export const Tabs = ({ data, defaultActiveTab = 0, externalState }: TabsProps): ReactElement => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleChange = (event: SyntheticEvent, newTab: ActiveTab) =>
    externalState?.onTabChange ? externalState.onTabChange(newTab) : setActiveTab(newTab);

  const selectedTab = externalState?.activeTab ? externalState.activeTab : activeTab;

  return (
    <>
      <MuiTabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="tabs"
        classes={{ flexContainer: styles.tabs_flex_container, root: styles.tabs_root }}
      >
        {data?.map?.(
          ({ title }: Tab, index: number): ReactNode => (
            <MuiTab
              key={`tab-title-${title}-${index}`}
              id={`tab-${index}`}
              aria-controls={`tab-panel-${index}`}
              label={title}
              className={styles.tab}
              disableRipple
              data-testid={`tab-title-${title}-${index}`}
            />
          ),
        )}
      </MuiTabs>
      {data?.map?.(
        ({ title, content }: Tab, index: number): ReactNode => (
          <TabPanel key={`tab-content-${title}-${index}`} value={selectedTab} index={index}>
            {content}
          </TabPanel>
        ),
      )}
    </>
  );
};
