import {
  ConfirmationOrder,
  Order,
  ORDER_STATUS,
  ORDER_TYPE,
  OrderState,
  TotalSection,
} from '@commons/order';
import {
  CartTotalSectionData as OrderTotalSectionApi,
  Order as OrderApi,
  OrderInfo as OrderInfoApi,
  OrderState as OrderStateApi,
} from '@api';
import { cartAdapter } from '@adapters/cartAdapter';
import { accountAdapter } from '@adapters/accountAdapter';
import { priceAdapter } from '@adapters/priceAdapter';
import { getCarousel } from '@adapters/carouselAdapter';

const { getCartLinesInfo } = cartAdapter();
const { getPaymentFullInfo, getDeliveryAddress } = accountAdapter();
const { getBasicPrice } = priceAdapter();

interface OrderAdapter {
  getOrderHistory: (orderList: OrderInfoApi[]) => Order[];
  getOrder: (order?: OrderInfoApi) => Order;
  getConfirmationOrder: (order?: Partial<OrderApi>) => ConfirmationOrder;
}

const getOrderState = (orderState?: OrderStateApi | null): OrderState => ({
  isCancelable: !!orderState?.isCancelable,
  isDelivered: !!orderState?.isDelivered,
  isModifiable: !!orderState?.isModifiable,
  isPending: !!orderState?.isPending,
  isTrackable: !!orderState?.isTrackable,
  isCreditEligible: !!orderState?.isCreditEligible,
});

const getOrderTotalSection = (totalSection?: OrderTotalSectionApi | null): TotalSection => ({
  deliveryFee: getBasicPrice(totalSection?.deliveryFee?.value),
  tip: getBasicPrice(totalSection?.tip?.value),
  totalTax: getBasicPrice(totalSection?.totalTax?.value),
});

export const getOrder = (order?: OrderInfoApi): Order => {
  const newOrder: Order = {
    isActive: !!order?.isActive,
    isExpressOrder: !!order?.isExpressOrder,
    orderId: order?.orderId ?? '',
    orderState: getOrderState(order?.orderState),
    orderTotal: order?.orderTotal ?? '',
    discounts:
      order?.discounts?.map(({ value: { value } }) => ({
        value: {
          value: value || 0,
        },
      })) ?? [],
    reorder: !!order?.reorder,
    requestedDate: order?.requestedDate ?? '',
    deliveryEnd: order?.deliveryEnd ?? '',
    deliveryStart: order?.deliveryStart ?? '',
    totalSection: getOrderTotalSection(order?.totalSection),
    deliveryCutOffTime: order?.deliveryCutOffTime ?? '',
    deliveredAtDateTime: order?.deliveredAtDateTime ?? '',
    deliveryCutOffDateTime: order?.deliveryCutOffDateTime ?? '',
    modifiedOrderLinesCount: order?.modifiedOrderLinesCount ?? 0,
    billingRef: order?.billingRef ?? '',
    soTemplateName: order?.soTemplateName ?? '',
  };
  if (order?.orderStatus) {
    newOrder.orderStatus = order?.orderStatus as unknown as ORDER_STATUS;
  }
  if (order?.orderType) {
    newOrder.orderType = order?.orderType as unknown as ORDER_TYPE;
  }
  return newOrder;
};

const getOrderHistory = (orders: OrderInfoApi[] | null): Order[] => {
  if (!orders) return [];
  return orders.map((order) => getOrder(order)) || [];
};

const getConfirmationOrder = (order?: Partial<OrderApi>): ConfirmationOrder => {
  return {
    cartLines: getCartLinesInfo(order?.cartLines),
    payment: getPaymentFullInfo(order?.payment),
    delivery: getDeliveryAddress(order?.delivery),
    info: getOrder(order?.info),
    firstOrder: !!order?.firstOrder,
    canModify: !!order?.canModify,
    containsDlvPassOnly: !!order?.containsDlvPassOnly,
    containsWineSection: !!order?.containsWineSection,
    estimatedTotal: getBasicPrice(order?.estimatedTotal),
    promoCodes: order?.promoCodes ?? [],
    modified: !!order?.modified,
    modifyOrderCount: order?.modifyOrderCount || 0,
    orderSuccessEventId: order?.orderSuccessEventId ?? '',
    recommendationCarousel: getCarousel(order?.recommendationCarousel),
  };
};

export const orderAdapter = (): OrderAdapter => ({
  getOrderHistory,
  getOrder,
  getConfirmationOrder,
});
