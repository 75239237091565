import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';
import { CSR_DATA_FRAGMENT } from '@graphql/fragments/cart/common/csrData';

export const MASQUERADE_CART_FRAGMENT = gql`
  fragment masqueradeCartFragment on Cart {
    ...cartFragment
    cartSections {
      cartLines {
        freeItem
        complaintReason {
          id
          reason
        }
      }
    }
    csrData {
      ...csrDataFragment
    }
  }
  ${CART_FRAGMENT}
  ${CSR_DATA_FRAGMENT}
`;
