import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocalization } from '@hooks/useLocalization';
import { Order } from '@commons/order';
import { ActiveOrderCard } from '../ActiveOrderCard/ActiveOrderCard';
import { useOrdersListPagination } from '../../useOrdersListPagination';

interface ActiveOrdersProps {
  className?: string;
  items: Order[];
  modifyingOrderId?: string | null;
  orderNotificationModalOpen: () => void;
}

export const ActiveOrders = ({
  items,
  className,
  modifyingOrderId,
  orderNotificationModalOpen,
  ...props
}: ActiveOrdersProps) => {
  const { t } = useLocalization('account');
  const { removeOrder, getMoreOrders, orders, hasMore } = useOrdersListPagination(items);

  const handleOrderCancel = (orderId: string) => {
    orderNotificationModalOpen();
    removeOrder(orderId);
  };

  return (
    <>
      <div {...props} className={className} aria-label={t('orderHistory.activeOrderList')}>
        <InfiniteScroll
          next={getMoreOrders}
          hasMore={hasMore}
          loader={<></>}
          dataLength={orders.length}
        >
          {orders.map((order) => {
            return (
              <ActiveOrderCard
                {...props}
                key={order.orderId}
                order={order}
                modifyingOrderId={modifyingOrderId}
                onCancel={handleOrderCancel}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </>
  );
};
