import { Typography } from '@mui/material';
import { Subtotal } from '@commons/cart';
import { ProductPrice } from '@commons/product';
import styles from './SubtotalValue.module.scss';

interface SubtotalValueProps {
  current: Subtotal;
  previous: ProductPrice;
}

export const SubtotalValue = ({ previous, current }: SubtotalValueProps): JSX.Element | null => {
  const previousPriceRendered =
    previous.value > current.value.value &&
    previous.formattedPrice !== current.value.formattedPrice;
  if (previousPriceRendered) {
    return (
      <div className={styles.subtotal_wrapper}>
        <Typography className={styles.current}>{current.value.formattedPrice}</Typography>
        <Typography className={styles.previous}>{previous.formattedPrice}</Typography>
      </div>
    );
  }

  return (
    <Typography className={styles.subtotal_value} data-testid="cart-subtotal-price">
      {current.value.formattedPrice}
    </Typography>
  );
};
