import {
  isValidEbtCard,
  hasUnallowedAddressSymbols,
  PHONE_MASK,
  INVALID_ZIPCODE_VALUE,
  ZIPCODE_FIELD_LENGTH,
  MIN_CVC_FIELD_LENGTH,
  MIN_TEXT_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
  isValidEmail,
  hasOnlyNumbers,
  hasMoreRequiredSymbols,
  hasExactLength,
  ROUTING_NUMBER_FIELD_LENGTH,
  MAX_COMPANY_NAME_FIELD_LENGTH,
  EMAIL_FIELD_LENGTH,
} from './validation';

export type Validate<T> = (value: T) => string | false;

export const validateRequired: Validate<string> = (value) =>
  !value && 'common:delivery.error.required';

export const validateRequiredSubjectDropdown: Validate<string> = (value) =>
  isNaN(parseInt(value)) && 'common:delivery.error.required';

export const validateShoppingListNameRequired: Validate<string> = (name) =>
  !name.trim() && 'common:shoppingList.error.required';

const validateEmailLength: Validate<string> = (value) =>
  hasMoreRequiredSymbols(value, EMAIL_FIELD_LENGTH) &&
  'account:preferences.forms.errors.invalidMaxLengthEmail';

const validateExtNumberLength: Validate<string> = (value) =>
  hasMoreRequiredSymbols(value, ZIPCODE_FIELD_LENGTH) &&
  'account:preferences.forms.errors.fiveSymbols';

const validateHasOnlyNumbers: Validate<string> = (value) =>
  !hasOnlyNumbers(value) && 'account:preferences.forms.errors.notNumbers';

const validateHasUnallowedSymbols: Validate<string> = (value) =>
  hasUnallowedAddressSymbols(value) && 'account:payments.editModal.errors.billingAddress';

const validateEbtCardLength: Validate<string> = (value) =>
  !isValidEbtCard(value) && 'account:payments.editModal.errors.ebtCardRequired';

const validateRequiredRoutingNumber: Validate<string> = (value) =>
  value.length < ROUTING_NUMBER_FIELD_LENGTH && 'account:payments.editModal.errors.routingNumber';

const validateInvalidRoutingNumber: Validate<string> = (value) =>
  (!hasOnlyNumbers(value) || !hasExactLength(value, ROUTING_NUMBER_FIELD_LENGTH)) &&
  'account:payments.editModal.errors.routingNumber';

const validateHasBankAccountNameUnallowedSymbols: Validate<string> = (value) =>
  hasUnallowedAddressSymbols(value) && 'account:payments.editModal.errors.bankAccountName';

const validateEmptyPhoneNumber: Validate<string> = (phone) =>
  phone === PHONE_MASK && 'common:delivery.error.required';

export const validateInvalidPhoneNumber: Validate<string> = (phone) =>
  phone.includes('_') && 'common:delivery.error.phoneNumberInvalid';

const validateZipCodeAllowedValues: Validate<string> = (zipCode) =>
  zipCode === INVALID_ZIPCODE_VALUE && 'account:payments.editModal.errors.invalidZipCode';

const validateTextFieldMinSymbols: Validate<string> = (value) =>
  value.length === MIN_TEXT_FIELD_LENGTH && 'account:preferences.forms.errors.minTextField';

const validateTextFieldMaxSymbols: Validate<string> = (value) =>
  value.length >= MAX_TEXT_FIELD_LENGTH && 'account:preferences.forms.errors.maxTextField';

const validateZipCodeMinSymbols: Validate<string> = (zipCode) =>
  zipCode.length < ZIPCODE_FIELD_LENGTH && 'account:payments.editModal.errors.invalidZipCode';

const validateCVCMinSymbols: Validate<string> = (code) =>
  code.length < MIN_CVC_FIELD_LENGTH && 'account:payments.editModal.errors.cvcRequired';

const validateCardHolderUnallowedSymbols: Validate<string> = (cardHolder) =>
  hasUnallowedAddressSymbols(cardHolder) && 'account:payments.editModal.errors.cardholder';

export const validateBillingAddress: Validate<string> = (address) =>
  validateRequired(address) || validateHasUnallowedSymbols(address);

export const validateBillingPhone: Validate<string> = (phone) => validateInvalidPhoneNumber(phone);

export const validateCVCCode: Validate<string> = (code) =>
  validateRequired(code) || validateCVCMinSymbols(code);

export const validateUserName: Validate<string> = (name) =>
  validateRequired(name) || validateHasUnallowedSymbols(name);

export const validateCityRequired: Validate<string> = (city) =>
  validateRequired(city) || validateHasUnallowedSymbols(city);

export const validateBankAccountName: Validate<string> = (bankAccountName) =>
  validateRequired(bankAccountName) || validateHasBankAccountNameUnallowedSymbols(bankAccountName);

export const validateRoutingNumber: Validate<string> = (routingNumber) =>
  validateRequiredRoutingNumber(routingNumber) || validateInvalidRoutingNumber(routingNumber);

export const validateCardHolder: Validate<string> = (cardHolder) =>
  validateRequired(cardHolder) || validateCardHolderUnallowedSymbols(cardHolder);

export const validateCity: Validate<string> = (city) => validateHasUnallowedSymbols(city);

export const validateApartmentRequired: Validate<string> = (apartment) =>
  validateRequired(apartment) || validateHasUnallowedSymbols(apartment);

export const validateApartment: Validate<string> = (apartment) =>
  validateHasUnallowedSymbols(apartment);

export const validateName: Validate<string> = (name: string) =>
  validateRequired(name) ||
  validateHasUnallowedSymbols(name) ||
  (!name.trim() && 'account:preferences.forms.errors.required');

export const validateFirstName: Validate<string> = (firstName) => validateName(firstName);

export const validateLastName: Validate<string> = (lastName) => validateName(lastName);

export const validateCompanyName: Validate<string> = (companyName) =>
  validateRequired(companyName) ||
  ((companyName?.length || 0) > MAX_COMPANY_NAME_FIELD_LENGTH &&
    'account:preferences.forms.errors.maxTextField');

export const validateOtherInstructions: Validate<string> = (instructions) =>
  validateHasUnallowedSymbols(instructions);

export const validatePhoneNumber: Validate<string> = (phone) =>
  validateEmptyPhoneNumber(phone) || validateRequired(phone) || validateInvalidPhoneNumber(phone);

export const validateEbtCard: Validate<string> = (cardNum) =>
  validateRequired(cardNum) || validateEbtCardLength(cardNum);

export const validateStreetAddressRequired: Validate<string> = (address) =>
  validateRequired(address) || validateHasUnallowedSymbols(address);

export const validateStreetAddress: Validate<string> = (address) =>
  validateHasUnallowedSymbols(address);

export const validateZipCode: Validate<string> = (zipCode) =>
  validateRequired(zipCode) ||
  validateZipCodeAllowedValues(zipCode) ||
  validateZipCodeMinSymbols(zipCode);

export const validateEmail: Validate<string> = (email) =>
  !isValidEmail(email) && 'account:preferences.forms.errors.invalidEmail';

export const validateRequiredEmail: Validate<string> = (email) =>
  validateRequired(email) || validateEmailLength(email) || validateEmail(email);

export const validateExtNumber: Validate<string> = (value) => {
  if (validateHasOnlyNumbers(value) && validateExtNumberLength(value)) {
    return validateHasOnlyNumbers(value) + ',' + validateExtNumberLength(value);
  }

  return validateHasOnlyNumbers(value) || validateExtNumberLength(value);
};

export const validateTextField: Validate<string> = (value) =>
  validateRequired(value) ||
  validateTextFieldMinSymbols(value) ||
  validateTextFieldMaxSymbols(value);
