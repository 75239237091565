import { Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { NumberField, RadioGroupField, TextField } from '@components/FormikFields';
import { useLocalization } from '@hooks/useLocalization';
import {
  MAX_EMAIL_FIELD_LENGTH,
  MAX_INPUT_FIELD_LENGTH,
  MAX_TEXTAREA_FIELD_LENGTH,
} from '@utils/validation';
import { ErrorBlock } from '@components/ErrorBlock/ErrorBlock';
import { radioGroupOptions } from '../../../constants';
import { Values, GIFTCARD_FIELDS } from '../../../types';
import styles from './BulkControls.module.scss';

export const BulkControls = () => {
  const { t } = useLocalization('giftCards');
  const { values, errors, touched, handleBlur, setFieldValue } = useFormikContext<Values>();
  const pdfDelivery = radioGroupOptions[1];

  return (
    <Grid container flexDirection="column" className={styles.controls}>
      <Grid item>
        <Typography className={styles.subtitle} variant="h5" component="h5">
          {t('giftCardModal.quantity')}
        </Typography>
      </Grid>
      <Grid item marginTop={4}>
        <Field
          name={GIFTCARD_FIELDS.GIFTCARD_QUANTITY}
          component={NumberField}
          label={t('giftCardModal.fields.quantity')}
          error={
            touched[GIFTCARD_FIELDS.GIFTCARD_QUANTITY] && errors[GIFTCARD_FIELDS.GIFTCARD_QUANTITY]
          }
          helperText={
            touched[GIFTCARD_FIELDS.GIFTCARD_QUANTITY] && errors[GIFTCARD_FIELDS.GIFTCARD_QUANTITY]
          }
          onBlur={handleBlur}
        />
      </Grid>
      <Grid container item marginTop={6} rowSpacing={4} flexDirection="column">
        <Grid item>
          <Typography className={styles.subtitle} variant="h5" component="h5">
            {t('giftCardModal.fromSubtitle')}
          </Typography>
        </Grid>
        <Grid item>
          <Field
            name={GIFTCARD_FIELDS.SENDER_NAME}
            component={TextField}
            label={t('giftCardModal.fields.name')}
            error={touched[GIFTCARD_FIELDS.SENDER_NAME] && errors[GIFTCARD_FIELDS.SENDER_NAME]}
            helperText={touched[GIFTCARD_FIELDS.SENDER_NAME] && errors[GIFTCARD_FIELDS.SENDER_NAME]}
            onBlur={handleBlur}
            maxLength={MAX_INPUT_FIELD_LENGTH}
          />
        </Grid>
        <Grid item>
          <Field
            name={GIFTCARD_FIELDS.SENDER_EMAIL}
            component={TextField}
            label={t('giftCardModal.fields.senderEmail')}
            error={touched[GIFTCARD_FIELDS.SENDER_EMAIL] && errors[GIFTCARD_FIELDS.SENDER_EMAIL]}
            helperText={
              touched[GIFTCARD_FIELDS.SENDER_EMAIL] && errors[GIFTCARD_FIELDS.SENDER_EMAIL]
            }
            onBlur={handleBlur}
            maxLength={MAX_EMAIL_FIELD_LENGTH}
          />
        </Grid>
        <Grid item>
          <Field
            name={GIFTCARD_FIELDS.RECIPIENT_MESSAGE}
            label={t('giftCardModal.fields.recipientMessage')}
            component={TextField}
            error={
              touched[GIFTCARD_FIELDS.RECIPIENT_MESSAGE] &&
              errors[GIFTCARD_FIELDS.RECIPIENT_MESSAGE]
            }
            helperText={
              touched[GIFTCARD_FIELDS.RECIPIENT_MESSAGE] &&
              errors[GIFTCARD_FIELDS.RECIPIENT_MESSAGE]
            }
            onBlur={handleBlur}
            maxLength={MAX_TEXTAREA_FIELD_LENGTH}
            multiline
            classes={{ root: styles.recipientMessage }}
          />
        </Grid>
      </Grid>
      <Grid
        role="group"
        container
        item
        marginTop={6}
        marginBottom={8}
        rowSpacing={2}
        flexDirection="column"
      >
        <Grid item>
          <Field
            name={GIFTCARD_FIELDS.GIFTCARD_TYPE}
            component={RadioGroupField}
            options={[
              {
                value: pdfDelivery.value,
                label: (
                  <Typography variant="body" component="p">
                    {t(pdfDelivery.label)}
                  </Typography>
                ),
              },
            ]}
            value={values[GIFTCARD_FIELDS.GIFTCARD_TYPE]}
            onChange={(value: string) => {
              setFieldValue(GIFTCARD_FIELDS.GIFTCARD_TYPE, value);
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="column">
        {touched[GIFTCARD_FIELDS.GIFTCARD_AMOUNT] && errors[GIFTCARD_FIELDS.GIFTCARD_AMOUNT] && (
          <div className={styles.error}>
            <ErrorBlock message={errors[GIFTCARD_FIELDS.GIFTCARD_AMOUNT]} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};
