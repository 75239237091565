export enum KEYBOARD_CODE {
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  ENTER = 'Enter',
  SHIFT_LEFT = 'ShiftLeft',
  SHIFT_RIGHT = 'ShiftRight',
  CONTROL_LEFT = 'ControlLeft',
  CONTROL_RIGHT = 'ControlRight',
  ALT_LEFT = 'AltLeft',
  ALT_RIGHT = 'AltRight',
  ESCAPE = 'Escape',
  SPACE = ' ',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_DOWN = 'ArrowDown',
  PRINT_SCREEN = 'PrintScreen',
  INSERT = 'Insert',
  DELETE = 'Delete',
  HOME = 'Home',
  END = 'End',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',
}
