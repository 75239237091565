import { useMutation } from '@apollo/client';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { REPLACE_CART_ITEM } from '@graphql/product/mutations/replaceCartItem';
import { cartAdapter } from '@adapters/cartAdapter';
import { useAddToCartEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { useRemoveFromCartEvent } from '@modules/ga/hooks/useRemoveFromCart';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { usePrevious } from '@hooks/usePrevious';
import { getProductIdList } from '@modules/ga/utils/getProductIdList';
import { updateCartCache } from './updateCartCache';

const { getCart } = cartAdapter();

export const useReplaceCartItem = () => {
  const { trackAddToCart } = useAddToCartEvent();
  const { trackRemoveFromCart } = useRemoveFromCartEvent();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);

  return useMutation(REPLACE_CART_ITEM, {
    update(cache, { data: { deleteResponse: cart } }) {
      updateCartCache(cache, cart);
    },
    onCompleted({ deleteResponse: deleteCart, addResponse: addCart }, options) {
      if (oldCart) {
        const newCart = getCart({
          ...deleteCart,
          atcEventIds: addCart?.atcEventIds,
        });
        const productIds = getProductIdList(options?.variables?.addToCartInput?.products ?? []);
        const itemPosition = options?.variables?.addToCartInput?.products?.[0]?.itemPosition;
        const listName = options?.variables?.addToCartInput?.products?.[0]?.itemListName;
        const cartLineIdToRemove = options?.variables?.cartLineId ?? '';

        trackRemoveFromCart(oldCart, cartLineIdToRemove);
        trackAddToCart({ newCart, oldCart, productIds, itemPosition, listName });
      }
      clearCacheFields([LIGHT_CART_CACHE_FIELD]);
    },
  });
};

export const useReplaceCartItemForContext = () => {
  const { clearCacheFields } = useCacheFieldsInvalidate();

  return useMutation(REPLACE_CART_ITEM, {
    update(cache, { data: { deleteResponse: cart } }) {
      updateCartCache(cache, cart);
    },
    onCompleted() {
      clearCacheFields([LIGHT_CART_CACHE_FIELD]);
    },
  });
};
