import { gql } from '@apollo/client';

export const PRODUCT_MEAL_BUNDLE_FRAGMENT = gql`
  fragment productMealBundleFragment on Bundle {
    type
    variationGroups {
      name
      label
      variations {
        name
        label
        optional
        display
        underLabel
        descrPopup
        descrMedia
        marinadeData {
          title
        }
        values {
          name
          label
          isLabelValue
          selected
          cvp
          productName
          description
          imagePath
          variationItemProductData {
            categoryId
            productId
          }
        }
      }
    }
  }
`;
