// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PAction<TData = any> {
  type?: string;
  message?: string;
  data?: TData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IAction<TData = any> {
  type: string;
  message?: string;
  data?: TData;
}

export const TAction = 'Action';

export function Action<TData>(p: PAction): IAction<TData> {
  const type = p.type ?? TAction;
  const message = p.message;
  const data = p.data ?? '';
  return {
    type,
    message,
    data,
  };
}
