import { CircularLoader } from '@components/CircularLoader/CircularLoader';
import styles from './AccountPageLoader.module.scss';

export const AccountPageLoader = () => {
  return (
    <div className={styles.loader_wrapper}>
      <CircularLoader />
    </div>
  );
};
