import { useMutation } from '@apollo/client';
import { ADD_HOSTED_PAYMENT } from '@graphql/account/mutations/addHostedPayment';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';

export const useAddHostedPayment = () => {
  return useMutation(ADD_HOSTED_PAYMENT, {
    refetchQueries: [PAYMENT_METHODS],
    update(
      cache,
      {
        data: {
          addHostedPayment: { payments: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
};
