import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';
import { AuthEventResult } from '@api';

interface FireUserSignupSuccessEventProps {
  authEvent?: AuthEventResult | null;
}

export const fireUserSignupSuccessEvent = (data: FireUserSignupSuccessEventProps) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.USER_SIGNUP_SUCCESS,
    data,
  });
};
