import React from 'react';
import { arrowDown as ArrowDown, arrowUp as ArrowUp } from '@assets/icons/system';
import styles from './AccountName.module.scss';

interface AccountNameProps {
  name: string;
  isOpen: boolean;
}

export const AccountName = ({ name, isOpen }: AccountNameProps) => {
  return (
    <p className={styles.name} data-qa="user_displayed_name" data-testid="user_displayed_name">
      {name}
      {isOpen ? <ArrowUp width={6} height={6} /> : <ArrowDown width={6} height={6} />}
    </p>
  );
};
