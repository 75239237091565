import { ApolloError } from '@apollo/client';
import { Product, ProductTile, SelectedConfiguration } from '@commons/product';
import { useProductConfigurationModal } from '@context/productConfigurationModalContext';
import { isConfigurableProduct } from '@features/ProductPage/components/AddToCart/components/AddToCartConfigurableProduct';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { ShoppingListProductInfo } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import {
  GTM_ADD_TO_LIST_EVENTS,
  GtmAddToListEvent,
} from '@modules/ga/eventBodyGetters/gtm-add-to-list-event';
import { getConfigurableProduct } from '@hooks/cart/useAddToCart';
import { useProductPositionContext } from '@modules/ga/context/position';
import { useProductListTitle } from '@modules/ga/context/title';
import { useProductListChannel } from '@modules/ga/context/channel';
import { useProductListLocation } from '@modules/ga/context/location';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useFetchShoppingLists } from '@hooks/shoppingLists/useFetchShoppingLists';
import { CallbackFunction } from '@modules/auth/hooks/useProtectedCallback';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { openAddToShoppingListModal } from '@modules/modals/events/addToShoppingListModal';
import { getShoppingLists } from '@adapters/shoppingListAdapter';

export interface ChooseProductForShoppingListProps {
  product: Product | ProductTile;
  configurationOptions?: SelectedConfiguration[];
  salesUnit?: string;
  quantity?: number;
}

export const useChooseProductForShoppingList = () => {
  const fetchShoppingLists = useFetchShoppingLists();
  const { setProtectedCallback } = useAuthContext();
  const { openConfigurationModal } = useProductConfigurationModal();
  const { getChannel } = useProductListChannel();
  const { getLocation } = useProductListLocation();
  const { getTitle } = useProductListTitle();
  const { getListName } = usePageListName();
  const { getProductPosition } = useProductPositionContext();

  const chooseProduct = ({
    product,
    configurationOptions = [],
    salesUnit = '',
    quantity,
  }: ChooseProductForShoppingListProps) => {
    const itemListName = getListName(product);
    const itemPosition = getProductPosition(product.productId);
    const productQuantity = quantity ?? product.quantity.quantity;
    const isConfigurable = isConfigurableProduct(product);

    const openAddToShoppingListModalWithProtect =
      (productInfo: ShoppingListProductInfo): CallbackFunction =>
      async (failure) => {
        try {
          const { data } = await fetchShoppingLists();
          openAddToShoppingListModal({
            productInfo,
            shoppingLists: getShoppingLists(data?.shoppingLists?.shoppingLists),
          });
        } catch (error) {
          failure(error as ApolloError);
        }
      };

    const handleOpenAddToShoppingListModal = (productInfo: ShoppingListProductInfo) => {
      sendGtmEvent(
        GtmAddToListEvent({
          action: GTM_ADD_TO_LIST_EVENTS.OPEN,
          productATL: product.productId,
        }),
      );
      setProtectedCallback(openAddToShoppingListModalWithProtect(productInfo));
    };

    if (isConfigurable) {
      openConfigurationModal({
        product,
        open: true,
        withImageTile: true,
        variant: MODAL_TYPE.PRODUCT,
        initialValues: {
          variables: configurationOptions,
          count: productQuantity,
          salesUnit,
        },
        position: itemPosition,
        channel: getChannel(product),
        location: getLocation(product),
        title: getTitle(product),
        onAddToShoppingList: handleOpenAddToShoppingListModal,
      });
      return;
    }

    const configuratedProduct = getConfigurableProduct({
      product,
      quantity: productQuantity,
      configuration: configurationOptions.map(({ value, name }) => ({
        characteristicName: name,
        characteristicValue: value,
      })),
      itemListName,
      itemPosition,
      salesUnit,
    });

    handleOpenAddToShoppingListModal({
      productDescription: product.productDescription,
      productName: product.productName,
      productImage: product.productImage,
      productId: product.productId,
      addToCartInput: configuratedProduct,
    });
  };

  return { chooseProduct };
};
