import { userAgent } from 'next/server';
import { CLIENT_DEVICE_TYPE } from '@modules/pageSize/pageSize.constants';

export const getDeviceType = (incomingUserAgent = '') => {
  const headers = new Headers();
  headers.set('user-agent', incomingUserAgent);
  const parsedUserAgent = userAgent({ headers });
  let detectedDevice = CLIENT_DEVICE_TYPE.DESKTOP;

  switch (parsedUserAgent.device.type) {
    case CLIENT_DEVICE_TYPE.MOBILE:
      detectedDevice = CLIENT_DEVICE_TYPE.MOBILE;
      break;
    case CLIENT_DEVICE_TYPE.TABLET:
      detectedDevice = CLIENT_DEVICE_TYPE.TABLET;
      break;
  }

  return detectedDevice;
};
