import { IAction, PAction } from '@modules/action';

export enum MODALS_LIST {
  DELIVERY_PASS_ERROR = 'DELIVERY_PASS_ERROR',
  REQUEST_CREDIT = 'REQUEST_CREDIT',
  ADVANCED_PRODUCTS = 'ADVANCED_PRODUCTS',
  RESERVATION_INFO = 'RESERVATION_INFO',
  EDIT_INSTRUCTIONS = 'EDIT_INSTRUCTIONS',
  ADD_TO_STANDING_ORDER = 'ADD_TO_STANDING_ORDER',
  ADD_TO_STANDING_ORDER_SUCCESS = 'ADD_TO_STANDING_ORDER_SUCCESS',
  MANAGE_SHOPPING_LIST = 'MANAGE_SHOPPING_LIST',
  RECEIVE_SMS = 'RECEIVE_SMS',
  RECEIVE_SMS_CONFIRMATION = 'RECEIVE_SMS_CONFIRMATION',
  RESERVATION_TIMESLOTS_MODAL = 'RESERVATION_TIMESLOTS_MODAL',
  DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL',
  NOTIFICATION_ACCOUNT_DELETION = 'NOTIFICATION_ACCOUNT_DELETION',
  PRODUCT_VARIATION_MODAL = 'PRODUCT_VARIATION_MODAL',
  ADD_RESIDENTIAL_ADDRESS_MODAL = 'ADD_RESIDENTIAL_ADDRESS_MODAL',
  ADD_CORPORATE_ADDRESS_MODAL = 'ADD_CORPORATE_ADDRESS_MODAL',
  ADD_TO_SHOPPING_LIST_MODAL = 'ADD_TO_SHOPPING_LIST_MODAL',
  COPY_PRODUCTS_TO_SHOPPING_LIST_MODAL = 'COPY_PRODUCTS_TO_SHOPPING_LIST_MODAL',
  STANDING_ORDER_PRODUCT_LIST_MODAL = 'STANDING_ORDER_PRODUCT_LIST_MODAL',
  SELECT_STANDING_ORDER_DELIVERY_TIME_MODAL = 'SELECT_STANDING_ORDER_DELIVERY_TIME_MODAL',
  SO_PAYMENT_METHOD_MODAL = 'SO_PAYMENT_METHOD_MODAL',
  WELCOME_SIGN_IN_MODAL = 'WELCOME_SIGN_IN_MODAL',
  WELCOME_CREATE_ACCOUNT_MODAL = 'WELCOME_CREATE_ACCOUNT_MODAL',
  ADD_ALL_TO_CART_MODAL = 'ADD_ALL_TO_CART_MODAL',
  DELETE_STANDING_ORDER_MODAL = 'DELETE_STANDING_ORDER_MODAL',
  ALCOHOL_RESTRICTIONS_AGREEEMENT_MODAL = 'ALCOHOL_RESTRICTIONS_MODAL',
  SAMPLE_PRODUCTS_MODAL = 'SAMPLE_PRODUCTS_MODAL',
  EDIT_DELIVERY_ADDRESS_MODAL = 'EDIT_DELIVERY_ADDRESS_MODAL',
  MAGIC_LINK_SENT_MODAL = 'MAGIC_LINK_SENT_MODAL',
}

export interface IPayload {
  modalType: MODALS_LIST;
}

export const MODAL_EVENT = 'MODAL_EVENT';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export type ModalEventAction = typeof MODAL_OPEN | typeof MODAL_CLOSE;

export interface PModalsAction<TData> extends PAction<TData> {
  type?: typeof MODAL_EVENT;
  message: ModalEventAction;
  data: TData;
}

export interface IModalsAction<TData> extends IAction<TData> {
  type: typeof MODAL_EVENT;
  message: ModalEventAction;
  data: TData;
}

export const modalAction = <TData extends IPayload>(
  action: PModalsAction<TData>,
): IModalsAction<TData> => {
  return {
    ...action,
    type: MODAL_EVENT,
  };
};
