import { INIT_MASQUERADE_KEYS } from '@constants/initMasqueradeKeys';
import { MasqueradeQueryParams } from '@modules/masquerade-query-params';

type KeyOfMasqueradeQueryParams = keyof MasqueradeQueryParams;

export const formatInitMasqueradeVariables = <T extends MasqueradeQueryParams>(input: T): T =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(input).reduce((accumulation: any, currentKey) => {
    if (INIT_MASQUERADE_KEYS.includes(currentKey)) {
      accumulation[currentKey] = input[currentKey as KeyOfMasqueradeQueryParams];
    }
    return accumulation;
  }, {});
