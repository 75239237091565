import differenceWith from 'lodash/differenceWith';
import { useRouter } from 'next/router';
import {
  getCartLineByLineId,
  getLightCartLineById,
} from '@modules/ga/eventBodyGetters/utils/getCartLine';
import { getCartPosition } from '@modules/ga/eventBodyGetters/utils/getCartPosition';
import {
  Cart,
  CartLight,
  CartLine,
  SingleStoreCartLine as NonExpressCartLineType,
} from '@commons/cart';
import { fireRemoveFromCartGAEvent } from '@modules/ga/events/ecommerce/add-to-cart/addToCartEventCreator';
import { getFormattedProductPrice } from '@modules/ga/utils/getFormattedProductPrice';
import { Price } from '@commons/price';
import { getDiscount } from '@modules/ga/eventBodyGetters/utils/getDiscount';
import { getBreadcrumbs } from '@modules/ga/utils/getBreadcrumbs';

export const useRemoveFromCartEvent = () => {
  const breadcrumb = getBreadcrumbs();
  const router = useRouter();

  const trackRemoveFromCart = (cart: Cart, cartLineId: string): void => {
    const cartLine = getCartLineByLineId(cart, cartLineId);

    if (!cartLine) return;

    const { itemPosition, itemListName } = cartLine;
    const product = {
      ...cartLine.product,
      discountAmount: getDiscount(cartLine),
      price: <Price>getFormattedProductPrice({
        price: cartLine.product.price,
        quantity: cartLine.quantitySelector.quantity,
        displayPrice: cartLine.displayPrice.value,
      }),
      quantity: {
        ...cartLine.product.quantity,
        quantity: cartLine.quantitySelector.quantity || 1,
      },
    };

    fireRemoveFromCartGAEvent({
      products: [product],
      breadcrumb,
      itemListName,
      itemPosition,
      cartPosition: getCartPosition(router),
    });
  };

  const trackRemoveFromLightCart = (cart: CartLight, cartLineId: string): void => {
    const gaCartLine = getLightCartLineById(cart, cartLineId);

    if (!gaCartLine) return;
    const { itemPosition, itemListName } = gaCartLine;
    const product = {
      ...gaCartLine,
      quantity: {
        maxQuantity: gaCartLine.quantity?.maxQuantity ?? 0,
        minQuantity: gaCartLine.quantity?.minQuantity ?? 0,
        quantityIncrement: gaCartLine.quantity?.quantityIncrement ?? 0,
        quantity: gaCartLine.quantitySelector.quantity || 1,
      },
    };

    fireRemoveFromCartGAEvent({
      cartPosition: getCartPosition(router),
      itemListName,
      breadcrumb,
      products: [product],
      itemPosition,
    });
  };

  const trackRemoveFromCartExpress = (cartLine: NonExpressCartLineType): void => {
    if (!cartLine.product) return;

    const { itemPosition, itemListName } = cartLine;
    const product = {
      ...cartLine.product,
      discountAmount: cartLine.discountPerUnit,
      quantity: {
        ...cartLine.product.quantity,
        quantity: cartLine.quantitySelector.quantity || 1,
      },
    };

    fireRemoveFromCartGAEvent({
      cartPosition: getCartPosition(router),
      itemListName,
      breadcrumb,
      products: [product],
      itemPosition,
    });
  };

  const trackRemoveListFromCart = (prevCart: Cart, cart: Cart): void => {
    const diff = differenceWith(
      prevCart.cartLinesList,
      cart.cartLinesList,
      (line1, line2) => line1.id === line2.id,
    );
    const getDiffProductList = (cartLine: CartLine) => ({
      ...cartLine.product,
      quantity: {
        ...cartLine.product.quantity,
        quantity: cartLine.quantitySelector.quantity,
      },
      price: <Price>getFormattedProductPrice({
        price: cartLine.product.price,
        quantity: cartLine.quantitySelector.quantity,
        displayPrice: cartLine.displayPrice.value,
      }),
      itemPosition: cartLine.itemPosition,
      itemListName: cartLine.itemListName,
      discountAmount: getDiscount(cartLine),
    });
    fireRemoveFromCartGAEvent({
      cartPosition: getCartPosition(router),
      breadcrumb,
      products: diff.map(getDiffProductList),
    });
  };

  return {
    trackRemoveFromCart,
    trackRemoveFromCartExpress,
    trackRemoveListFromCart,
    trackRemoveFromLightCart,
  };
};
