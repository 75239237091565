import { gql } from '@apollo/client';
import { PRODUCT_TILE_FRAGMENT } from '@graphql/fragments/product/productTileFragment';

export const GROUP_SCALE_PRODUCTS = gql`
  query GroupScaleProducts($groupId: ID!, $groupVersion: String!) {
    groupScaleProducts(groupId: $groupId, groupVersion: $groupVersion) {
      ...productTileFragment
    }
  }
  ${PRODUCT_TILE_FRAGMENT}
`;
