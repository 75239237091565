import { useMutation } from '@apollo/client';
import { EDIT_BANK_ACCOUNT } from '@graphql/account/mutations/editBankAccount';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';

export const useEditBankAccount = () =>
  useMutation(EDIT_BANK_ACCOUNT, {
    update(
      cache,
      {
        data: {
          editBankAccount: { data: payments },
        },
      },
    ) {
      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
