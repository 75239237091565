import { Typography } from '@mui/material';
import { Item } from '@layouts/Footer/Footer';
import { useGetCreditsOrderHistory } from '@hooks/credits';
import styles from '../../Footer.module.scss';
import { ColumnInnerItems } from './columnInnerItems/ColumnInnerItems';

interface FooterColumnProps {
  isMobileAppLink: boolean;
  item: Item;
  col: number;
  titleId?: string;
}

export const FooterColumn = ({ col, item, isMobileAppLink, titleId }: FooterColumnProps) => {
  const {
    data: { hasOrders },
  } = useGetCreditsOrderHistory();

  return (
    <div className={styles.top_line_item} style={{ gridArea: `col-${col}` }}>
      <Typography className={styles.title} id={titleId}>
        {item.title}
      </Typography>
      <ul className={styles.item_list}>
        {item.innerItems.map((innerItem, i) => {
          const innerItemId = `footer_inner_item_${col}${i}`;
          return (
            <ColumnInnerItems
              isMobileAppLink={isMobileAppLink}
              key={`innerItem${i}`}
              innerItem={innerItem}
              hasRequestCredit={hasOrders}
              titleId={titleId}
              innerItemId={innerItemId}
            />
          );
        })}
      </ul>
    </div>
  );
};
