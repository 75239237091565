import { useReactiveVar } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { routing } from '@constants/routing';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { expressItemsCountVar } from '@graphql/variables/expressItemsNotification';
import { localStorageService, sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import {
  determineBaseValues,
  determineCountsByThreshold,
  determineRememberedExpressItems,
} from '@utils/expressNotification';
import { useSettings } from './useSettings/useSettings';

const FORBIDDEN_ROUTES = [
  routing.account,
  routing.help,
  routing.deliveryPass,
  routing.legal,
  routing.browse,
  routing.notFound,
  routing.checkout,
  routing.cart,
  routing.orderConfirmation,
];

let lastLoadedUrl = sessionStorageService?.read(STORAGE_KEYS.LAST_URL) ?? '';
let lastExpressItemsCount = 0;

export const useExpressNotification = () => {
  const { enabled: enabledExpress } = useExpressContext();
  const { asPath: routerPath, events } = useRouter();
  const { data } = useSettings();

  const expressItemsCount = useReactiveVar<number | null>(expressItemsCountVar);
  const [expressNotificationClosed, setExpressNotificationClosed] = useState(
    localStorageService?.read(STORAGE_KEYS.EXPRESS_NOTIFICATION_CLOSED) ?? false,
  );

  const forbiddenUrl = FORBIDDEN_ROUTES.find((route) => !!routerPath.match(route));

  const handleCloseNotification = () => {
    localStorageService?.put(STORAGE_KEYS.EXPRESS_NOTIFICATION_CLOSED, true);
    setExpressNotificationClosed(true);
  };

  const threshold = data.expressNotificationCountThreshold;
  const pageRefreshCount = localStorageService?.read(STORAGE_KEYS.PAGE_REFRESH_COUNT) ?? 0;
  let lastSavedExpressItemsCount = localStorageService?.read(STORAGE_KEYS.SAVED_EXPRESS_ITEMS) ?? 0;

  // notification won't be shown when express count is 0 or when we close it manually
  const { baseExpressCount, baseLastExpressItemsCount } = determineBaseValues(
    expressItemsCount,
    lastExpressItemsCount,
  );
  let expressCount = baseExpressCount;
  lastExpressItemsCount = baseLastExpressItemsCount;

  lastSavedExpressItemsCount = determineRememberedExpressItems(
    expressCount,
    pageRefreshCount,
    lastSavedExpressItemsCount,
  );

  const countsByThreshold = determineCountsByThreshold(
    lastSavedExpressItemsCount,
    threshold,
    expressCount,
  );

  expressCount = enabledExpress || forbiddenUrl ? 0 : countsByThreshold;

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      if (FORBIDDEN_ROUTES.includes(url) || lastLoadedUrl === url) return;

      lastLoadedUrl = url;

      // this check handles case when the page loaded for the first time
      // and make work PAGE_REFRESH_COUNT as expected.
      if (!sessionStorageService?.read(STORAGE_KEYS.LAST_URL)) {
        sessionStorageService?.put(STORAGE_KEYS.LAST_URL, url);
        localStorageService?.put(STORAGE_KEYS.PAGE_REFRESH_COUNT, 0);
      } else {
        const count = localStorageService?.read(STORAGE_KEYS.PAGE_REFRESH_COUNT) ?? 0;

        localStorageService?.put(STORAGE_KEYS.PAGE_REFRESH_COUNT, count + 1);
      }
    };
    events.on('routeChangeStart', handleRouteChange);

    return () => {
      events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    expressItemsCount: expressCount,
    expressNotificationClosed,
    handleCloseNotification,
  };
};
