import { Timeslot } from '@commons/timeslots';

export const hasUnavailableTimeslots = (timeslots?: Timeslot) => {
  const days = timeslots?.days || [];
  const hasUnavailableStatusTimeslots = days.some((day) =>
    day.slots.some((slot) => slot?.unavailable),
  );
  const hasSoldOutStatusTimeslots = days.some((day) => day.slots.some((slot) => slot?.soldOut));
  const hasEmptyTimeslots = days.some((day) => !day.slots.length);

  return (
    hasUnavailableStatusTimeslots || hasSoldOutStatusTimeslots || hasEmptyTimeslots || !days.length
  );
};
