import omit from 'lodash/omit';
import {
  EcommerceEvent,
  getProductMetrics,
  ProductMetrics,
} from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { Product } from '@commons/product';
import { CustomerData } from '../../../../eventBodyGetters/utils/getCustomerData';

/* eslint-disable @typescript-eslint/naming-convention */
interface ViewItemOptions {
  currencyCode: string;
  product: Product;
  customer: CustomerData;
  list_name: string;
  breadcrumb: string;
  itemPosition: number;
}

type ViewItemEventProduct = Omit<ProductMetrics, 'list' | 'quantity'>;

interface ViewItemEvent extends EcommerceEvent {
  list_name: string;
  breadcrumb: string;
  ecommerce: {
    currencyCode: string;
    detail: {
      actionField: {
        list: string;
      };
      products: ViewItemEventProduct[];
    };
  };
}

export const getViewItemEvent = ({
  product,
  currencyCode,
  customer,
  list_name,
  breadcrumb,
  itemPosition,
}: ViewItemOptions): ViewItemEvent => {
  const productMetrics = omit(
    getProductMetrics({
      ...product,
      position: itemPosition,
    }),
    ['list', 'quantity'],
  );
  return {
    event: 'productDetail',
    event_name: 'view_item',
    customer: {
      deliverypass_type: customer.deliveryPassType,
      deliverypass_term: customer.deliveryPassTerm,
      express_eligible: customer.expressEligible,
    },
    ua_category: 'Ecommerce Action',
    ua_action: 'Product Detail',
    list_name,
    breadcrumb,
    ecommerce: {
      currencyCode: currencyCode,
      detail: {
        actionField: {
          list: list_name,
        },
        products: [productMetrics],
      },
    },
  };
};
