import { useQuery } from '@apollo/client';
import { useLocalization } from '@hooks/useLocalization';
import { GROUP_SCALE_PRODUCTS } from '@graphql/product/queries/groupScaleProducts';
import { ProductBasicData } from '@api';
import { ProductTile } from '@commons/product';
import { getProductTile } from '@adapters/product/getProductTile';

export const useGroupScaleProducts = (groupId: string | null, groupVersion: string | null) => {
  const { getErrorMessage } = useLocalization();
  const { data, loading, error } = useQuery(GROUP_SCALE_PRODUCTS, {
    variables: {
      groupId,
      groupVersion,
    },
  });

  const products: ProductTile[] = data?.groupScaleProducts
    ? data?.groupScaleProducts.map((product: ProductBasicData) => getProductTile(product))
    : [];

  return {
    isLoading: loading,
    products: products,
    error: error ? getErrorMessage(error.message) : null,
  };
};
