import { sendGtmEvent } from '@modules/ga/gtm-event';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { getShoppingMode } from '@modules/ga/utils/getShoppingMode';

interface ChangeAddressHandlerProps {
  commonAnalyticsData: AnalyticsData;
}

/* eslint-disable @typescript-eslint/naming-convention */
export const changeAddressHandler = (data: ChangeAddressHandlerProps) => {
  sendGtmEvent({
    event: 'change_address',
    customer: getCustomerData({
      userDetails: data.commonAnalyticsData.userDetails,
      minimumAccountPreferences: data.commonAnalyticsData.minimumAccountPreferences,
      userStatus: data.commonAnalyticsData.userStatus,
      address: data.commonAnalyticsData.addressData,
      geoData: data.commonAnalyticsData.geoData,
    }),
    'shopping-mode': getShoppingMode(),
  });
};
/* eslint-enable @typescript-eslint/naming-convention */
