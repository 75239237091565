import { gql } from '@apollo/client';

export const GET_PAYMENT_AVAILABILITIES = gql`
  query PaymentAvailabilities {
    paymentAvailabilities {
      bankAccount
      ebt
    }
  }
`;
