import { gql } from '@apollo/client';
import { PROMOTION_STATE_FRAGMENT } from '@graphql/fragments/cart/common/promotionStateFragment';

export const GET_APPLIED_PROMO = gql`
  query GetAppliedPromo {
    appliedPromotion {
      ...promotionStateFragment
    }
  }
  ${PROMOTION_STATE_FRAGMENT}
`;
