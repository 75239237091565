import React from 'react';
import cx from 'classnames';
import { Divider } from '@components/UI/Divider/Divider';
import { Nutrition as NutritionAPI } from '@commons/product';
import { getNutritionMetaItems } from '@features/ProductPage/utils/getNutritionMetaItems';
import styles from '@features/ProductPage/components/Nutrition/Nutrition.module.scss';
import { MAIN_NUTRITIONS, NESTED_NUTRITIONS } from '@features/ProductPage/constants';
import { getValueByIndex } from '@utils/getValueByIndex';
import { useLocalization } from '@hooks/useLocalization';

interface MultiColumnNutritionTableProps {
  nutrition: NutritionAPI;
  index: number;
}

export const MultiColumnNutritionTable = ({ nutrition, index }: MultiColumnNutritionTableProps) => {
  const {
    NUMBER_OF_SERVINGS,
    SERVING_WEIGHT,
    SERVING_SIZE,
    TOTAL_CALORIES,
    PRODUCT_NAME,
    NUMBER_OF_SERVINGS_BUNDLE_LEVEL,
  } = getNutritionMetaItems(nutrition?.meta);
  const { t } = useLocalization('product');
  {
    /**  since backend gives us only number of columns, and we need iterate
   it to build multicolumn table, we need this piece of code
   to turn this number into array */
  }
  const contentColumns = Array.from(Array(index).keys());

  return (
    <>
      <div>
        <div>
          <div className={styles.table_header}>
            <div className={styles.title_wrapper}>
              <h1 className={styles.title}>{t('nutrition.nutritionFactsTitle')}</h1>
            </div>
            {NUMBER_OF_SERVINGS_BUNDLE_LEVEL?.item?.value?.[0] && (
              <span className={cx(styles.servings_number, styles.left_align)}>
                {NUMBER_OF_SERVINGS_BUNDLE_LEVEL.item.value[0]}
              </span>
            )}
            {NUMBER_OF_SERVINGS && !NUMBER_OF_SERVINGS_BUNDLE_LEVEL?.item?.value?.[0] && (
              <span className={cx(styles.servings_number, styles.left_align)}>
                {t('nutrition.numberOfServings')}
              </span>
            )}
            {SERVING_SIZE && (
              <div className={styles.servings_size}>
                <span>{t('nutrition.servingSize')}</span>
              </div>
            )}
          </div>
          <div className={styles.calories}>
            <span>{t('nutrition.calories')}</span>
          </div>
        </div>
        <div className={cx(styles.daily_value_item_wrapper, styles.empty_cell)}></div>
        <table className={styles.table_main_section}>
          <tbody>
            {nutrition?.main?.map((nutritionItem) => {
              const shouldBeReseted = Object.values(MAIN_NUTRITIONS).includes(nutritionItem.id);
              const isDeepNested = NESTED_NUTRITIONS === nutritionItem.id;
              return (
                <tr key={nutritionItem.displayName}>
                  <th
                    className={cx(styles.table_item, {
                      [styles.reseted_item]: shouldBeReseted,
                      [styles.deep_nested_item]: isDeepNested,
                    })}
                  >
                    {nutritionItem?.displayName && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: nutritionItem.displayName,
                        }}
                      />
                    )}
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={styles.meta_header}>
          <div className={styles.top_large_border}>
            <span className={styles.daily_value_text}>{t('nutrition.dailyValueText')}</span>
          </div>
          <table className={styles.table_extra_section}>
            <tbody>
              {nutrition?.extra?.map((nutritionItem) => {
                return (
                  <tr
                    key={nutritionItem.displayName}
                    className={cx(styles.table_item, styles.reseted_item)}
                  >
                    <th>
                      <span className={styles.multi_column_reseted_item}>
                        {nutritionItem.displayName}
                      </span>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Divider mode="black" orientation="vertical" sx={{ mr: 2, ml: 2 }} flexItem />
      {contentColumns.map((el) => {
        const lastColumn = el === contentColumns.length - 1;
        return (
          <>
            <div key={el}>
              <div className={styles.table_header}>
                <div className={styles.multi_column_header_wrapper}>
                  <h1 className={styles.multi_column_header_title}>
                    {getValueByIndex(PRODUCT_NAME?.item?.uom, el)}
                  </h1>
                </div>
                <span className={cx(styles.servings_number, styles.right_align)}>
                  {getValueByIndex(NUMBER_OF_SERVINGS?.item?.value, el)}
                </span>
                <div className={styles.right_align}>
                  <div>
                    <span className={styles.servings_size}>
                      {`${getValueByIndex(SERVING_SIZE?.item?.value, el)} ${getValueByIndex(
                        SERVING_SIZE?.item?.uom,
                        el,
                      )}`}
                    </span>
                    <span className={styles.servings_size}>
                      {!!SERVING_WEIGHT?.item?.value?.[el] && !!SERVING_WEIGHT?.item?.uom?.[el]
                        ? `(${getValueByIndex(SERVING_WEIGHT.item.value, el)}${getValueByIndex(
                            SERVING_WEIGHT.item.uom,
                            el,
                          )})`
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.total_calories}>
                <span>{getValueByIndex(TOTAL_CALORIES?.item?.value, el)}</span>
              </div>
              <div className={styles.daily_value_item_wrapper}>
                <span className={styles.daily_value_title}>{t('nutrition.dailyValue')}</span>
              </div>
              <table className={styles.table_main_section}>
                <tbody>
                  {nutrition?.main?.map((nutritionItem) => {
                    const shouldBeReseted = Object.values(MAIN_NUTRITIONS).includes(
                      nutritionItem.id?.[el],
                    );
                    const isDeepNested = NESTED_NUTRITIONS === nutritionItem.id?.[el];
                    return (
                      <tr
                        key={nutritionItem.displayName}
                        className={cx(styles.table_item, styles.reseted_item, {
                          [styles.reseted_item]: shouldBeReseted,
                          [styles.deep_nested_item]: isDeepNested,
                        })}
                      >
                        <th>
                          <span className={styles.table_item_weight}>
                            {`${getValueByIndex(nutritionItem.value, el)}${getValueByIndex(
                              nutritionItem.uom,
                              el,
                            )}`}
                          </span>
                        </th>
                        <td>{`${getValueByIndex(nutritionItem.dailyValue, el)} ${getValueByIndex(
                          nutritionItem.dailyUom,
                          el,
                        )}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <table className={styles.table_extra_section}>
                <tbody>
                  {nutrition?.extra?.map((nutritionItem) => {
                    return (
                      <tr key={nutritionItem.displayName} className={styles.table_item}>
                        <th>
                          <span className={styles.extra_weight}>
                            {`${getValueByIndex(nutritionItem?.value, el)} ${getValueByIndex(
                              nutritionItem?.uom,
                              el,
                            )}`}
                          </span>
                        </th>
                        <td>{`${getValueByIndex(nutritionItem?.dailyValue, el)} ${getValueByIndex(
                          nutritionItem?.dailyUom,
                          el,
                        )}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {!lastColumn && (
              <Divider mode="black" orientation="vertical" sx={{ mr: 2, ml: 2 }} flexItem />
            )}
          </>
        );
      })}
    </>
  );
};
