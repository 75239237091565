import React from 'react';
import { DeliveryCar, DeliveredPackage } from '@assets/icons/informational';
import styles from './Animation.module.scss';

export const Animation: React.FC = () => {
  return (
    <div className={styles.animation_container}>
      <div className={styles.animation}>
        <div className={styles.car}>
          <DeliveryCar />
        </div>
        <DeliveredPackage className={styles.delivery_package} />
      </div>
    </div>
  );
};
