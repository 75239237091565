import { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import { Tooltip, Typography, TooltipProps } from '@mui/material';
import { useUpdateEffect } from 'usehooks-ts';
import { useApolloClient } from '@apollo/client';
import { StatusLevelType } from '@api';
import { default as CloseIcon } from '@assets/icons/system/close.svg';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useLocalization } from '@hooks/useLocalization';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { isFirstExpressOverlayVar } from '@graphql/variables/isFirstExpressOverlayVar';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { MINIMUM_ACCOUNT_PREFERENCES } from '@graphql/account';
import { AUTH_USER_ACTIONS_TYPES } from '@modules/auth/context/AuthUserReducer';
import styles from './ExpressOverlay.module.scss';

interface ExpressOverlayProps {
  children: ReactElement;
  placement?: TooltipProps['placement'];
  hasArrow?: boolean;
  className?: string;
  isFirstLoad?: boolean;
}

export const ExpressOverlay = ({
  children,
  placement,
  hasArrow = true,
  className,
  isFirstLoad,
}: ExpressOverlayProps) => {
  const { t } = useLocalization();
  const { isDesktop } = useHeaderContext();
  const { isRecognizedUser, dispatch } = useAuthContext();
  const { enabled: expressEnabled } = useExpressContext();
  const { isLoadingHeaderAlert } = useMainLayoutContext();
  const [showTooltip, setShowTooltip] = useState(false);
  const [updatedAuthStatus, setupdatedAuthStatus] = useState('');
  const [shouldOpen, setShouldOpen] = useState(isRecognizedUser && expressEnabled);
  const client = useApolloClient();
  const defaultPlacement = isDesktop ? 'top' : 'bottom';
  const tooltipPlacement = placement ?? defaultPlacement;

  useEffect(() => {
    if (!updatedAuthStatus && client) {
      const getAuthStatus = async () => {
        const { data } = await client.query({
          query: MINIMUM_ACCOUNT_PREFERENCES,
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true,
        });

        return {
          updatedAuthStatus: data?.accountPreferences?.statusLevel,
        };
      };

      getAuthStatus().then((response) => {
        setupdatedAuthStatus(response.updatedAuthStatus);
      });
    }

    if (updatedAuthStatus === StatusLevelType.RECOGNIZED && !isRecognizedUser) {
      dispatch?.({
        type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS,
        payload: StatusLevelType.RECOGNIZED,
      });
    }
  }, [updatedAuthStatus]);

  useEffect(() => {
    setShouldOpen(isRecognizedUser && expressEnabled);
  }, [isRecognizedUser]);

  useUpdateEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
        isFirstExpressOverlayVar(false);
      }, 3000);
    }
  }, [showTooltip]);

  useEffect(() => {
    if (!isLoadingHeaderAlert && isFirstLoad && shouldOpen) {
      setTimeout(() => {
        setShowTooltip(true);
      }, 100);
    }
  }, [isLoadingHeaderAlert, isFirstLoad, shouldOpen]);

  const handleOverlayClose = () => {
    setShowTooltip(false);
  };

  return (
    <Tooltip
      arrow={hasArrow}
      placement={tooltipPlacement}
      open={shouldOpen && showTooltip}
      onClose={() => setShowTooltip(false)}
      disableTouchListener
      disableHoverListener
      disableFocusListener
      componentsProps={{
        tooltip: {
          className: cx(styles.tooltip, className),
        },
        arrow: {
          className: styles.arrow,
        },
      }}
      title={
        <div className={styles.overlay}>
          <Typography> {t('express.overlayInfo')}</Typography>{' '}
          <CloseIcon onClick={handleOverlayClose} />
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
