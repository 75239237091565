import { gql } from '@apollo/client';

export const MASQUERADE_BAR_DATA_FRAGMENT = gql`
  fragment masqueradeBarDataFragment on MasqueradeBarData {
    agentId
    userId
    makeGoodOrderId
    isForceOrderAvailable
    eStoreId
    parentOrderId
    plantId
  }
`;
