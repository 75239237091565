import { Container, Grid, IconButton, Typography, Box } from '@mui/material';
import Image from 'next/image';
import { useState } from 'react';
import { Navigation } from 'swiper';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { arrowLeft as ArrowLeftIcon, arrowRight as ArrowRightIcon } from '@assets/icons/system';
import { GiftCardTemplate } from '@adapters/giftCardAdatper';
import styles from './Carousel.module.scss';

interface GiftCardCarouselProps {
  giftCardTemplates: GiftCardTemplate[] | null;
  handleChange: (index: number) => void;
  initialSlide?: number;
}

export const GiftCardCarousel = ({
  giftCardTemplates,
  handleChange,
  initialSlide,
}: GiftCardCarouselProps) => {
  const [isEnd, setIsEnd] = useState(false);
  const [isStart, setIsStart] = useState(true);
  const [activeSlide, setActiveSlide] = useState(1);
  return (
    <Swiper
      initialSlide={initialSlide}
      spaceBetween={50}
      navigation={{ nextEl: `.${styles.rightArrow}`, prevEl: `.${styles.leftArrow}` }}
      modules={[Navigation]}
      slidesPerView={1}
      onSlideChange={(slide) => {
        handleChange(slide.activeIndex);
        setIsEnd(slide.isEnd);
        setIsStart(slide.isBeginning);
        setActiveSlide(slide?.activeIndex + 1);
      }}
      className={styles.swiper}
      role="region"
      aria-label="Carousel"
    >
      {giftCardTemplates?.map(({ gcTemplateId, gcTemplateImageLink, gcTemplateTitle }) => (
        <SwiperSlide key={gcTemplateId} className={styles.giftCard}>
          <Grid className={styles.imageContainer}>
            <Image
              className={styles.image}
              src={gcTemplateImageLink}
              alt=""
              layout="fill"
              objectFit="cover"
              priority
            />
          </Grid>
          <Typography variant="h4" component="p" className={styles.imageTitle}>
            {gcTemplateTitle}
          </Typography>
        </SwiperSlide>
      ))}
      <Container slot="container-end" className={styles.swiperControls}>
        <IconButton
          aria-label="display previous slide"
          className={cx(styles.arrowIcon, styles.leftArrow)}
          disabled={isStart}
          disableTouchRipple
        >
          <ArrowLeftIcon width={18} height={18} />
        </IconButton>
        <IconButton
          aria-label="display next slide"
          className={cx(styles.arrowIcon, styles.rightArrow)}
          disabled={isEnd}
          disableTouchRipple
        >
          <ArrowRightIcon width={18} height={18} />
        </IconButton>
        <Box aria-live="assertive" className="sr_only">
          slide {activeSlide} of {giftCardTemplates?.length}
        </Box>
      </Container>
    </Swiper>
  );
};
