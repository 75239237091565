import { RefObject, useRef, useState } from 'react';
import cx from 'classnames';
import { Typography, CircularProgress } from '@mui/material';
import { ProductTile } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import { ButtonOutlined } from '@components/UI';
import { AlternativeTilePopup } from '@components/AlternativeTilePopup/AlternativeTilePopup';
import { useAlternativeProduct } from '@hooks/useAlternativeProduct';
import { arrowRightV2 as ArrowRightIconV2 } from '@assets/icons/system';
import styles from './SoldOutBlock.module.scss';

interface SoldOutBlockProps {
  productRef: RefObject<HTMLDivElement>;
  product: ProductTile;
  isV2?: boolean;
}

export const SoldOutBlock = ({ productRef, product, isV2 = false }: SoldOutBlockProps) => {
  const { t } = useLocalization('product');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { getAlternativeProduct, loading, fetch } = useAlternativeProduct(
    product.alternativeProductId,
  );
  const shouldShowAlternativeProductPopup = anchorEl && product?.alternativeProductId && !loading;
  const alternativeButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const openPopup = () => {
    setAnchorEl(productRef.current);
  };

  const closePopup = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = () => {
    fetch();
    openPopup();
    setTimeout(() => {
      closeButtonRef?.current?.focus();
    }, 100);
  };

  const handleCloseAlternativeTilePopup = () => {
    closePopup();
    alternativeButtonRef?.current?.focus();
  };

  return (
    <div className={cx(styles.wrapper, { [styles.v2]: isV2 })}>
      <Typography className={styles.label}>{t('outOfStock.soldOut')}</Typography>
      {product?.alternativeProductId && !isV2 && (
        <ButtonOutlined
          onClick={handleButtonClick}
          size="extraSmall"
          loading={loading}
          aria-label={`${product.brandName} ${product.productName} ${t(
            'outOfStock.alternativesButton',
          )}`}
          ref={alternativeButtonRef}
        >
          {t('outOfStock.alternativesButton')}
        </ButtonOutlined>
      )}
      {product?.alternativeProductId && isV2 && (
        <button
          className={styles.alternatives_button}
          onClick={handleButtonClick}
          aria-label={`${product.brandName} ${product.productName} ${t(
            'outOfStock.alternativesButton',
          )}`}
          ref={alternativeButtonRef}
          disabled={loading}
        >
          <p className={cx(styles.button_label)}>{t('outOfStock.alternativesButtonV2')}</p>
          {!loading && <ArrowRightIconV2 className={styles.button_label_icon} />}
          {loading && <CircularProgress size={8} />}
        </button>
      )}
      {shouldShowAlternativeProductPopup && (
        <AlternativeTilePopup
          onClose={handleCloseAlternativeTilePopup}
          alternativeProduct={getAlternativeProduct()}
          anchorEl={anchorEl}
          ref={closeButtonRef}
          isV2={isV2}
        />
      )}
    </div>
  );
};
