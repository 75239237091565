import Image from 'next/image';
import { HTMLAttributes } from 'react';
import {
  amex as AmexIcon,
  discover as DiscoverIcon,
  mastercard as MastercardIcon,
  paypal as PaypalIcon,
  venmo as VenmoIcon,
  visa as VisaIcon,
  giftCard as GiftCardIcon,
} from '@assets/icons/payments';
import { EWALLET_PAYMENT_TYPE, PAYMENT_TYPE, PaymentsType } from '@commons/payment';

interface PaymentIconProps extends HTMLAttributes<HTMLOrSVGElement> {
  type?: PaymentsType;
}

export const PaymentIcon = ({ type, ...props }: PaymentIconProps) => {
  switch (type) {
    case PAYMENT_TYPE.VISA:
      return <VisaIcon {...props} />;
    case PAYMENT_TYPE.MASTERCARD:
      return <MastercardIcon {...props} />;
    case EWALLET_PAYMENT_TYPE.PAYPAL:
      return <PaypalIcon {...props} />;
    case PAYMENT_TYPE.DISCOVER:
      return <DiscoverIcon {...props} />;
    case PAYMENT_TYPE.AMEX:
      return <AmexIcon {...props} />;
    case EWALLET_PAYMENT_TYPE.VENMO:
      return <VenmoIcon {...props} />;
    case PAYMENT_TYPE.GIFT_CARD:
      return (
        <div className={props.className}>
          <Image src={GiftCardIcon} alt="gift card icon" />
        </div>
      );
    default:
      return null;
  }
};
