import { useEffect, useState } from 'react';
import { useScript } from 'usehooks-ts';
import { AdServer } from '@api';
import { dfpAdBlockSizes } from '@constants/dfp';
import { useAdServer } from './dfp/useAdServer';
import { useThirdPartyScriptsEnabled } from './useThirdPartyScriptsEnabled';

interface UseDFPBannersProps {
  adBlockId: string;
  targeting?: AdServer['adQueryParams'];
}

const googleTagPlaceholder = {
  cmd: [],
  destroySlots: () => {
    // keep this method defined in case google tag manager never loads
  },
};

export function useDFPBanner({
  adBlockId,
  // targeting should be immutable
  targeting,
}: UseDFPBannersProps) {
  const scriptEnabled = useThirdPartyScriptsEnabled();
  const scriptStatus = useScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js', {
    removeOnUnmount: false,
    shouldPreventLoad: !scriptEnabled,
  });
  const adNetworkCode = process.env.NEXT_PUBLIC_GPT_NETWORK_CODE;
  const adServerData = useAdServer({ customAdQueryParams: targeting });
  const [isEmpty, setIsEmpty] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const googletagEventHandler = (adSlot: any, event: any) => {
    const slot = event.slot;
    if (slot === adSlot) {
      setIsEmpty(event.isEmpty);
    }
  };

  useEffect(() => {
    const scriptNotReady = scriptStatus !== 'ready';
    if (!adServerData || scriptNotReady) {
      return;
    }

    const googletag = window.googletag || googleTagPlaceholder;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let adSlot: any;
    googletag.cmd.push(function () {
      adSlot = googletag
        .defineSlot(`/${adNetworkCode}/${adBlockId}`, dfpAdBlockSizes, adBlockId)
        .addService(googletag.pubads())
        .setCollapseEmptyDiv(true);

      googletag
        .pubads()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .addEventListener('slotRenderEnded', (event: any) => googletagEventHandler(adSlot, event));

      if (adServerData.adQueryParams) {
        adServerData.adQueryParams.forEach(({ key, value }) => {
          adSlot.setTargeting(key, value);
        });
      }

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
      googletag.display(adBlockId);
    });

    return () => {
      if (adSlot) {
        googletag.destroySlots([adSlot]);
      }
      googletag
        .pubads?.()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .removeEventListener('slotRenderEnded', (event: any) =>
          googletagEventHandler(adSlot, event),
        );
    };
  }, [adBlockId, adServerData, adNetworkCode, scriptStatus]);

  const bannerProps = {
    id: adBlockId,
  };

  return { bannerProps, isVisible: !!adServerData, isEmpty, scriptStatus };
}
