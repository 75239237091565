import cx from 'classnames';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { HTMLAttributes } from 'react';
import { useLocalization } from '@hooks/useLocalization';
import { check as SelectedIcon } from '@assets/icons/system';
import { EWALLET_PAYMENT_TYPE, PaymentsType } from '@commons/payment';
import { Button } from '@components/UI';
import { truncateTextWithDots } from '@utils/truncateTextWithDots';
import { useModal } from '@hooks/common';
import { PaymentIcon } from '@components/PaymentIcon/PaymentIcon';
import { PaymentDeleteModal } from '@components/PaymentDeleteModal/PaymentDeleteModal';
import { EditPaymentModalWrapper } from '../EditPaymentModalContent/EditPaymentModalWrapper';
import styles from './PaymentTile.module.scss';

interface PaymentTileProps extends HTMLAttributes<HTMLDivElement> {
  cardNumber?: string | null;
  email?: string | null;
  isDefault?: boolean | null;
  type?: PaymentsType;
  children?: never;
  onDelete: () => void;
  isLastNonEPaymentMethod: boolean;
  onClick: () => void;
  id: string;
}

export const PaymentTile = ({
  type,
  cardNumber,
  email,
  isDefault,
  onDelete,
  isLastNonEPaymentMethod,
  onClick,
  id,
}: PaymentTileProps) => {
  const deleteModal = useModal();
  const { isOpen, closeModal, openModal } = useModal();
  const { t } = useLocalization('account');
  const isEWallet = Object.values(EWALLET_PAYMENT_TYPE).includes(type as EWALLET_PAYMENT_TYPE);
  const buttonTranslation = isEWallet ? 'payments.buttons.unlink' : 'payments.buttons.delete';
  const buttonAriaLabel = isEWallet
    ? 'payments.unlinkPaymentMethodLabel'
    : 'payments.deletePaymentMethodLabel';

  const shouldRenderDeleteButton = !isLastNonEPaymentMethod || isEWallet;
  let ariaText = null;

  const getPaymentDescription = (): JSX.Element => {
    let text = null;
    if (isEWallet) {
      //because of '-' hypen couldnt reach the correct result in CSS, could be removed after exact solution from designers
      text = truncateTextWithDots(email, 35);
    } else if (cardNumber) {
      text = t('payments.paymentTile.cardNumber', { cardNumber });
      ariaText = `${type} ending ${cardNumber}`;
    }

    return (
      <Typography component="span" className={styles.card_number}>
        {text}
      </Typography>
    );
  };

  return (
    <div>
      <button
        className={cx(
          styles.tile,
          {
            [styles.selected]: isDefault,
          },
          'button',
        )}
        onClick={onClick}
      >
        <div className={styles.top_line}>
          {type && (
            <Typography component="span" className={styles.title}>
              {type}
            </Typography>
          )}
          <PaymentIcon type={type} className={styles.payment_icon} />
        </div>
        <div className={styles.bottom_line}>
          {getPaymentDescription()}
          {isDefault && <SelectedIcon className={styles.selected_icon} />}
        </div>
        {isDefault && (
          <Typography sx={visuallyHidden} component="span">
            {t('payments.paymentMethodSelected')}
          </Typography>
        )}
      </button>
      {!isEWallet && (
        <Button
          data-testid="edit_payment_method"
          className={cx(styles.button, styles.with_right_margin)}
          aria-label={`${t('payments.editPaymentMethodLabel')} ${ariaText}`}
          onClick={openModal}
          variant="underline"
        >
          {t('payments.buttons.edit')}
        </Button>
      )}
      {isOpen && (
        <EditPaymentModalWrapper closeModal={closeModal} id={id} type={type} opened={isOpen} />
      )}
      {shouldRenderDeleteButton && (
        <>
          <Button
            className={styles.button}
            aria-label={`${t(buttonAriaLabel)} ${ariaText}`}
            onClick={deleteModal.openModal}
            variant="underline"
          >
            {t(buttonTranslation)}
          </Button>
          <PaymentDeleteModal
            onDelete={onDelete}
            onClose={deleteModal.closeModal}
            opened={deleteModal.isOpen}
            cardNumber={cardNumber}
            type={type}
            isEWallet={isEWallet}
          />
        </>
      )}
    </div>
  );
};
