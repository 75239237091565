import cx from 'classnames';
import { useState } from 'react';
import { GiftCard } from '@adapters/giftCardAdatper';
import { useLocalization, useModal } from '@hooks/index';
import { Button } from '@components/UI';
import { useDeleteFromGiftCards } from '@hooks/giftCard/useDeleteFromGiftCards';
import { useEmptyGiftCards } from '@hooks/giftCard/useEmptyGiftCards';
import { EditGiftCardModal } from '@components/Modals/GiftCardModals/EditGiftCardModal';
import { Values } from '@components/Modals/GiftCardModals/types';
import { GiftCardListView } from '@components/GiftCard/GifrCardListView/GiftCardListView';
import { EditBulkGiftCardModal } from '@components/Modals/GiftCardModals/EditBulkGiftCardModal';
import { GiftCardControllers } from '@components/GiftCard/GiftCardControllers/GiftCardControllers';
import { MAX_BULK_GIFT_CARD_QTY_AMOUNT } from '@utils/validation';
import { RemoveConfirmationModal } from '../RemoveConfirmationModal/RemoveConfirmationModal';
import styles from './ListView.module.scss';

interface ListViewProps {
  giftCards: GiftCard[];
  isBulk: boolean;
}

export const ListView = ({ giftCards, isBulk = false }: ListViewProps) => {
  const { t } = useLocalization('giftCards');
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();
  const {
    isOpen: isConfirmationModalOpen,
    openModal: openConfirmationModal,
    closeModal: closeConfirmationModal,
  } = useModal();
  const [giftCardToEdit, setGiftCardToEdit] = useState<Values>();
  const [deleteFromGiftCards, { loading: deleteFromGiftCardsLoading }] = useDeleteFromGiftCards();
  const [emptyGiftCards, { loading: emptyGiftCardsLoading }] = useEmptyGiftCards();
  const bulkGiftCardTotalQuantity = giftCards.reduce(
    (currentQuantity, card) => currentQuantity + card.quantity,
    0,
  );

  const handleDeleteIconClick = (giftCardId: string) => {
    deleteFromGiftCards({
      variables: {
        giftCardId,
      },
    });
  };

  const handleConfirmButtonClick = () => {
    emptyGiftCards();
    closeConfirmationModal();
  };

  const handleEditIconClick = (giftCard: GiftCard) => {
    setGiftCardToEdit({
      giftCardId: giftCard.id,
      giftCardAmount: String(giftCard.amount.value),
      senderName: giftCard.senderName,
      recipientName: giftCard.recipientName,
      recipientEmail: giftCard.recipientEmail,
      recipientMessage: giftCard.message,
      giftCardType: giftCard.deliveryMethod,
      giftCardTemplateId: giftCard.gcTemplate.gcTemplateId,
      senderEmail: giftCard.senderEmail ?? '',
      quantity: giftCard.quantity,
      isBulk: giftCard.isBulk,
    });
    openEditModal();
  };

  return (
    <div
      className={cx({
        [styles.wrapper_loading]: deleteFromGiftCardsLoading || emptyGiftCardsLoading,
      })}
    >
      <GiftCardListView
        giftCards={giftCards}
        isBulk={isBulk}
        controllers={(idx) => (
          <GiftCardControllers
            giftCard={giftCards[idx]}
            onDeleteIconClick={handleDeleteIconClick}
            onEditIconClick={handleEditIconClick}
            bulk={giftCards[idx].isBulk}
            disableQuantityPlus={
              isBulk && bulkGiftCardTotalQuantity >= MAX_BULK_GIFT_CARD_QTY_AMOUNT
            }
          />
        )}
      />
      <div className={styles.footer}>
        <Button
          variant="underline"
          onClick={() => {
            openConfirmationModal();
          }}
        >
          {t('giftCardBag.emptyBagButton')}
        </Button>
      </div>
      {giftCardToEdit && !isBulk && (
        <EditGiftCardModal
          open={isEditModalOpen}
          onClose={closeEditModal}
          values={giftCardToEdit}
        />
      )}
      {giftCardToEdit && isBulk && (
        <EditBulkGiftCardModal
          open={isEditModalOpen}
          onClose={closeEditModal}
          values={giftCardToEdit}
        />
      )}
      <RemoveConfirmationModal
        open={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        onSubmit={handleConfirmButtonClick}
      />
    </div>
  );
};
