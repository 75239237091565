import { CartLineLight } from '@commons/cart';
import { useMinimumCartLinesListLight } from '@hooks/cart/useMinimumCartLinesListLight';

export const getCountForSoldBySalesUnitLight = (productId: string, cartLines: CartLineLight[]) =>
  cartLines.reduce((sum, cartLine) => (productId === cartLine.productId ? sum + 1 : sum), 0);

export const getCountForConfigurableProductLight = (
  productId: string,
  cartLines: CartLineLight[],
) =>
  cartLines.reduce(
    (sum, cartLine) =>
      productId === cartLine.productId ? sum + cartLine.quantitySelector.quantity : sum,
    0,
  );

export const useCartLineItemLight = (productId: string, soldBySalesUnit = false) => {
  const { cartLines, ...rest } = useMinimumCartLinesListLight();

  const cartLineItem = cartLines.find((cartLine) => cartLine.productId === productId);
  const count = soldBySalesUnit
    ? getCountForSoldBySalesUnitLight(productId, cartLines)
    : getCountForConfigurableProductLight(productId, cartLines);
  return {
    ...rest,
    data: cartLineItem,
    count: count,
  };
};
