/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { RefObject, useEffect, useRef } from 'react';
import { Box, IconButton, Popper, Typography } from '@mui/material';
import { close as CloseIcon } from '@assets/icons/system';
import { Button, Link } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useAuthModal } from '@hooks/authHooks/useAuthModal';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { useOpenLoginReminder } from '@components/SignInReminder/useOpenLoginReminder';
import styles from './SignInReminder.module.scss';

interface SignInReminderProps {
  itemNumber: number;
  loading: boolean;
  cartRef: RefObject<HTMLButtonElement>;
}

export const SignInReminder = ({ itemNumber, loading, cartRef }: SignInReminderProps) => {
  const { t } = useLocalization('modals');
  const reminderRef = useRef(null);
  const { openModal, toggleAuthModal } = useAuthModal();
  const { isOpen, closeReminder } = useOpenLoginReminder({
    loading,
    itemNumber,
  });
  const id = isOpen ? 'simple-popper' : undefined;

  const openCreateAccount = () => {
    toggleAuthModal();
    openModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT);
  };

  const openLoginModal = () => {
    toggleAuthModal();
    openModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (reminderRef.current) {
          (reminderRef.current as HTMLButtonElement).focus();
        }
      }, 300);
    }
  }, [reminderRef, isOpen]);

  return (
    <Popper
      id={id}
      anchorEl={cartRef.current}
      className={styles.popper}
      open={isOpen}
      placement="bottom-end"
      role="dialog"
      aria-modal="false"
      aria-labelledby="login-reminder-title"
    >
      <Box className={styles.box_button}>
        <IconButton
          ref={reminderRef}
          size="small"
          className={styles.button_exit}
          onClick={closeReminder}
          data-qa="reminder_popup_close_btn"
          aria-label={t('common:buttons.close')}
        >
          <CloseIcon width={14} height={14} />
        </IconButton>
      </Box>
      <Typography component="h2" className={styles.title} id="login-reminder-title">
        {t('loginReminder.title', [itemNumber])}
      </Typography>
      <Button
        onClick={openCreateAccount}
        type="button"
        size="large"
        className={styles.button_signup}
      >
        <span className={styles.email}>{t('loginReminder.newAccount')}</span>
      </Button>
      <Box>
        {t('loginReminder.haveAccount')}{' '}
        <Link href="#" onClick={openLoginModal}>
          {t('loginReminder.signIn')}
        </Link>
      </Box>
    </Popper>
  );
};
