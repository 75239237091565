export enum PAGE_SIZES {
  MOBILE = 12,
  TABLET = 20,
  DESKTOP = 30,
}

export enum CLIENT_DEVICE_TYPE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
}

export const DEVICE_TO_PAGE_SIZE: Record<CLIENT_DEVICE_TYPE, PAGE_SIZES> = {
  [CLIENT_DEVICE_TYPE.MOBILE]: PAGE_SIZES.MOBILE,
  [CLIENT_DEVICE_TYPE.TABLET]: PAGE_SIZES.TABLET,
  [CLIENT_DEVICE_TYPE.DESKTOP]: PAGE_SIZES.DESKTOP,
};
export const DEVICE_TO_SKELETON_SIZE: Record<CLIENT_DEVICE_TYPE, number> = {
  [CLIENT_DEVICE_TYPE.MOBILE]: 2,
  [CLIENT_DEVICE_TYPE.TABLET]: 4,
  [CLIENT_DEVICE_TYPE.DESKTOP]: 6,
};

export const DEVICE_TO_LARGE_TILE_DECREMENT_SIZE: Record<CLIENT_DEVICE_TYPE, number> = {
  [CLIENT_DEVICE_TYPE.MOBILE]: 1,
  [CLIENT_DEVICE_TYPE.TABLET]: 3,
  [CLIENT_DEVICE_TYPE.DESKTOP]: 5,
};

export const SHOW_ALL_PAGE_SIZE = 1000; //API currently does not handle "show all".
