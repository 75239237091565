import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@components/UI/Button/Button';
import { BaseLink as Link } from '@components/UI';
import { useContactUsModal } from '@context/contactUsModalContext';
import { InnerItemType } from '@layouts/Footer/Footer';
import { useLocalization } from '@hooks/useLocalization';
import { focusMainLayout } from '@layouts/Footer/utils';
import styles from '../../../../Footer.module.scss';

interface ColumnInnerItemProps {
  innerItem: InnerItemType;
  titleId?: string;
  innerItemId?: string;
}

export enum FOOTER {
  STORE_CREDIT = 'request-a-credit',
  CONTACT_US = 'contact-us-overlay',
}

export const ItemLinkText = ({ innerItem, titleId, innerItemId }: ColumnInnerItemProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const { name, link, function: itemFunction } = innerItem;
  const openContactUsModal = useContactUsModal();
  const isContactUs = itemFunction === FOOTER.CONTACT_US;
  const isExternalLink =
    link?.includes('www.') || link?.includes('https://') || link?.includes('http://');
  const { t } = useLocalization();

  const router = useRouter();

  useEffect(() => {
    const handleRouteComplete = async () => {
      setIsClicked(false);
    };

    if (isClicked) {
      router.events.on('routeChangeComplete', handleRouteComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  }, [isClicked, router.events]);

  const handleClickEvent = (e: React.MouseEvent) => {
    isClicked ? e.preventDefault() : setIsClicked(true);
    focusMainLayout();
  };

  if (isContactUs) {
    return (
      <Button
        className={styles.inner_item_button}
        onClick={() => openContactUsModal.emit('open')}
        variant="underline"
        aria-labelledby={`${titleId} ${innerItemId}`}
        id={innerItemId}
      >
        <span className={styles.subtitle}>{name}</span>
      </Button>
    );
  }

  if (!link) {
    return <span className={styles.subtitle}>{name}</span>;
  }

  if (isExternalLink) {
    return (
      <a
        href={link}
        target={'_blank'}
        className={styles.subtitle}
        rel="noreferrer"
        aria-label={`${name} (${t('common:newWindow')})`}
        aria-labelledby={`${titleId} ${innerItemId}`}
        id={innerItemId}
      >
        {name}
      </a>
    );
  }

  return (
    <Link href={link}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        className={styles.subtitle}
        onClick={handleClickEvent}
        aria-labelledby={`${titleId} ${innerItemId}`}
        id={innerItemId}
      >
        {name}
      </a>
    </Link>
  );
};
