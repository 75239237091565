import { PromoStatus } from '@commons/promo';

export const getInitialStatus = (applied: boolean, code: string): PromoStatus => {
  if (applied) {
    return 'applied';
  }
  if (!applied && code) {
    return 'condition';
  }
  return 'empty';
};
