import { gql } from '@apollo/client';

export const INITIALIZE_HOSTED_PAYMENT = gql`
  query InitializeHostedPayment($type: PaymentMethodType!) {
    initializeHostedPayment(type: $type) {
      type
      url
      accessToken
      refreshToken
    }
  }
`;
