import cx from 'classnames';
import { Skeleton } from '@mui/material';
import styles from './ProductPageImageBlock.module.scss';

export const ProductPageImageBlockSkeleton = () => (
  <>
    <div className={cx(styles.image_wrapper)} role="region" aria-label="carousel">
      <div className={cx(styles.image_container)}>
        <Skeleton className={styles.skeleton_image} variant="rectangular" />
      </div>
    </div>
  </>
);
