import { ClickAwayListener } from '@mui/material';
import { useRef, useEffect } from 'react';
import { EmptyCartNote } from '../EmptyCartNote/EmptyCartNote';
import styles from './StatusInfoWindow.module.scss';

export interface StatusInfoWindowProps {
  onClose: () => void;
}

export const StatusInfoWindow = ({ onClose }: StatusInfoWindowProps) => {
  const windowWrapper = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', onClose);

    return () => {
      window.removeEventListener('scroll', onClose);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={styles.wrapper} ref={windowWrapper}>
        <EmptyCartNote />
      </div>
    </ClickAwayListener>
  );
};
