import { Action, IAction, PAction } from '@modules/action';

export interface PShow extends PAction {
  id: string;
}

export interface IShow extends IAction {
  id: string;
}

export const TShow = 'Show';

export function Show(p: PShow): IShow {
  const type = p.type ?? TShow;
  const action = Action({
    ...p,
    type,
  });
  const id = p.id;
  return {
    ...action,
    id,
  };
}
