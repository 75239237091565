import { CountryState } from '@api';
import { DropdownOptionsType } from '@commons/account';

export const getStateOptions = (states: CountryState[]): DropdownOptionsType[] => {
  const stateOptions: DropdownOptionsType[] = [];

  states.forEach((state) => {
    stateOptions.push({
      value: state.stateCode,
      label: state.stateName || '',
    });
  });

  return stateOptions;
};
