import { Typography } from '@mui/material';
import { FC } from 'react';
import cx from 'classnames';
import { default as NextLink } from 'next/link';
import { useNavigationTracking } from '@hooks/header/useNavigationTracking';
import styles from './CategoriesSection.module.scss';

interface CategoryLinkProps {
  headline?: string | null;
  focused?: boolean;
  name?: string | null;
  href: string;
  tabIndex?: number;
  onClick?: () => void;
  excludeRole?: boolean;
}

export const CategoryLink: FC<CategoryLinkProps> = ({
  focused = false,
  headline,
  name,
  href,
  tabIndex,
  onClick,
  excludeRole,
}) => {
  const gtmProps = useNavigationTracking(`${headline}${name}`);

  return (
    <NextLink
      {...gtmProps}
      prefetch={false}
      href={href}
      tabIndex={tabIndex ?? 0}
      data-testid="category-link"
      {...(!excludeRole && { role: 'menuitem' })}
      ref={(node) => {
        if (node && focused) node.focus();
      }}
      className={cx(styles.element_link, {
        [styles.focused]: focused,
      })}
      onClick={onClick}
    >
      <Typography variant="body" mt={3}>
        {name}
      </Typography>
    </NextLink>
  );
};
