/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'react';
import { setCookie } from 'cookies-next';
import { useMutation } from '@apollo/client';
import { LoginInput, OtpType, VerifyOtpInput, LoginResult } from '@api';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { SEND_OTP } from '@modules/auth/operations/mutations/SendOtp';
import { VERIFY_OTP } from '@modules/auth/operations/mutations/VerifyOTP';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { useEcomCart } from '@modules/ga/hooks/useEcomCart';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { fireUserSignupSuccessEvent } from '@modules/ga/events/custom/user-signup-success/userSignupSuccessCreator';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { COOKIES_LIST } from '@commons/cookies';
import { AUTH_SERVICE_TYPES, AUTH_MODAL_TYPES } from '@commons/auth';
import { homeRedirect } from '../utils/loginRedirections';
import { AuthUser, USER_AUTH_STATUS } from '../types';
import { SIGN_UP_CACHE_FIELDS } from '../constants';
import { AUTH_USER_ACTIONS_TYPES, AuthUserActions } from '../context/AuthUserReducer';
import { handleSuccessfulAuthorization } from '../utils/handleSuccessfulAuthorization';

interface OtpProps {
  dispatch?: Dispatch<AuthUserActions>;
  loginCompletedHandler?: (loginResult: LoginResult, callback?: () => void) => Promise<void>;
}

export const useOtp = ({ dispatch, loginCompletedHandler }: OtpProps) => {
  const trackEcomCart = useEcomCart();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const { dispatch: authModalDispatch } = useAuthModalContext();
  const [verifyOTPMutation, { loading: verifyOTPMutationLoading }] = useMutation(VERIFY_OTP);
  const [sendOtpMutation, { loading: sendOtpMutationLoading }] = useMutation(SEND_OTP);

  const sendOtp = (input: LoginInput, type: OtpType, callback?: (status?: string) => void) => {
    sendOtpMutation({
      variables: { input: { email: input.email, phoneNumber: input.phoneNumber }, type },
      onError: (error) => {
        dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR, payload: error });
        callback?.();
      },
      onCompleted: ({ status }) => {
        callback?.(status);
      },
    });
  };

  const verifyOtp = (
    { login: loginInput, otp, type }: VerifyOtpInput,
    serviceType?: AUTH_SERVICE_TYPES,
    successCallback?: () => void,
    failureCallback?: () => void,
  ) => {
    const { email, phoneNumber, rememberMe } = loginInput as LoginInput;

    const reqObj: VerifyOtpInput = {
      login: phoneNumber ? { phoneNumber, rememberMe } : { email, rememberMe },
      otp,
      type,
    };

    verifyOTPMutation({
      variables: { input: reqObj },
      onError: (error) => {
        dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR, payload: error });
        failureCallback?.();
        if (type === OtpType.LOGIN) {
          sendGtmEvent(
            GtmCustomEvent({
              event: 'login-failure',
              event_name: 'login_failure',
              ua_category: 'Login',
              ua_action: 'login-failure',
            }),
          );
        } else if (type === OtpType.SIGNUP) {
          sendGtmEvent(
            GtmCustomEvent({
              event: 'user-signup-failure',
              event_name: 'signup_failure',
              ua_category: 'Signup',
              ua_action: 'signup-failure',
            }),
          );
        }
      },
      onCompleted: async (response: { verifyOTP: LoginResult }) => {
        if (type === OtpType.SIGNUP) {
          successCallback?.();
          const isHomeServiceType = !!serviceType && serviceType === AUTH_SERVICE_TYPES.HOME;

          authModalDispatch({ type: AUTH_MODAL_TYPES.CREATE_ACCOUNT_WELCOME, data: serviceType });

          if (isHomeServiceType) {
            setCookie(COOKIES_LIST.SERVICE_TYPE, serviceType);
          }

          clearCacheFields(
            isHomeServiceType
              ? [...SIGN_UP_CACHE_FIELDS, 'globalHeader', 'globalFooter']
              : SIGN_UP_CACHE_FIELDS,
          );

          handleSuccessfulAuthorization(response.verifyOTP.customer as AuthUser, dispatch);

          setTimeout(
            () => fireUserSignupSuccessEvent({ authEvent: response.verifyOTP.authEvent }),
            0,
          );
          trackEcomCart();
          homeRedirect();
        } else {
          await loginCompletedHandler?.(response.verifyOTP, successCallback);
        }

        dispatch?.({
          type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
          payload: USER_AUTH_STATUS.AUTH_COMPLETE,
        });
        sessionStorageService?.put(STORAGE_KEYS.AUTH_NOT_COMPLETE, false);
      },
    });
  };

  return {
    sendOtp,
    verifyOtp,
    isLoading: sendOtpMutationLoading || verifyOTPMutationLoading,
  };
};
