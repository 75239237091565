import { Dispatch, SetStateAction } from 'react';
import { PAYMENT_TABS } from '@commons/payment';
import { PaymentsOnSuccess } from '@components/PaymentsAddTabs/PaymentAddTabs.types';
import { PaymentMethodsDropdown } from '../PaymentMethodsDropdown/PaymentMethodsDropdown';
import { PaymentFormFactory } from '../PaymentFormFactory/PaymentFormFactory';

interface AddPaymentsModalContentProps {
  type: PAYMENT_TABS;
  errorMessage: string;
  onTypeChange: Dispatch<SetStateAction<PAYMENT_TABS>>;
  onError: Dispatch<SetStateAction<string>>;
  onSuccess: PaymentsOnSuccess;
}

export const AddPaymentsModalContent = ({
  type,
  onTypeChange,
  onError,
  onSuccess,
  errorMessage,
}: AddPaymentsModalContentProps) => (
  <>
    <PaymentMethodsDropdown type={type} setType={onTypeChange} />
    <PaymentFormFactory
      type={type}
      errorMessage={errorMessage}
      onError={onError}
      onSuccess={onSuccess}
    />
  </>
);
