import { Typography } from '@mui/material';
import { ButtonText } from '@components/UI/ButtonText/ButtonText';
import { chat as ChatIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { useContactUsModal } from '@context/contactUsModalContext';
import { GenesysChatService } from '@utils/genesysChatService';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import styles from '../ContactUsActions.module.scss';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Genesys: any;
  }
}

interface ChatProps {
  onClose?: () => void;
}

export const Chat = ({ onClose }: ChatProps) => {
  const { t } = useLocalization('common');
  const { userInfo } = useContactUsModal();

  const handleGenesysChat = () => {
    sessionStorageService?.put(STORAGE_KEYS.IS_CHAT_INITIALIZED, true);
    sessionStorageService?.put(STORAGE_KEYS.CHAT_INFO, {
      fullName: userInfo?.fullName ?? '',
      email: userInfo?.email ?? '',
    });

    GenesysChatService().init({
      attributes: { Name: userInfo?.fullName, Email: userInfo?.email },
      shouldOpenChat: true,
    });

    onClose?.();
  };

  return (
    <div className={styles.button_group_content}>
      <ChatIcon className={styles.icon} />
      <Typography className={styles.content_heading}>
        {t('contactUs.chatBlock.linkTitle')}
        <span>
          <ButtonText
            id="custom-launcher"
            onClick={handleGenesysChat}
            className={styles.chat_link_text}
          >
            {t('contactUs.chatBlock.linkText')}
          </ButtonText>
        </span>
      </Typography>
    </div>
  );
};
