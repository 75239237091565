import {
  DeliveryPass as DeliveryPassApi,
  DeliveryPassInfo as DeliveryPassInfoApi,
  Maybe,
} from '@api';
import { DeliveryPassInfo, DeliveryPassPlan } from '@commons/deliveryPass';
import { priceAdapter } from './priceAdapter';
import { productAdapter } from './productAdapter';

const { getProduct } = productAdapter();
const { getPrice } = priceAdapter();

export const getDeliveryPassPlan = (plan: DeliveryPassApi): DeliveryPassPlan => ({
  product: getProduct(plan.deliveryPassProduct),
  isActive: !!plan.isActive,
  isBestValue: !!plan.isBestValue,
  isPreviousPlan: !!plan.isPreviousPlan,
  mediaPath: plan.descriptionMediaPath ?? '',
});

export const getDeliveryPassPlans = (plans: DeepPartial<DeliveryPassApi[]>): DeliveryPassPlan[] => {
  return ((plans as DeliveryPassApi[]) ?? []).map(getDeliveryPassPlan);
};

export const getDeliveryPassInfo = (
  deliveryPassInfo?: Maybe<DeliveryPassInfoApi>,
): DeliveryPassInfo => {
  const isExpired = !!deliveryPassInfo?.isExpired;
  const isCanceled = !!deliveryPassInfo?.isCanceled;
  const planDisabled = isExpired || isCanceled;
  const wasMembershipActivated = !!deliveryPassInfo?.wasMembershipActivated;

  return {
    isFreeTrial: !!deliveryPassInfo?.isFreeTrial,
    memberSince: deliveryPassInfo?.memberSince ?? '',
    ordersPlacedQuantity: deliveryPassInfo?.ordersPlacedQuantity ?? 0,
    totalAmountSaved: getPrice(deliveryPassInfo?.totalAmountSaved),
    wasMembershipActivated,
    isExpired,
    isCanceled,
    planDisabled,
    deliveryPassApplied: wasMembershipActivated && !planDisabled,
    userPlanMediaPath: deliveryPassInfo?.userPlanMediaPath ?? '',
    expiration: deliveryPassInfo?.expiration ?? '',
    name: deliveryPassInfo?.name ?? '',
    autoRenewalEnabled: !!deliveryPassInfo?.autoRenewalEnabled,
    mostRecentDPInvoiceId: deliveryPassInfo?.mostRecentDPInvoiceId ?? '',
    dpTypeSku: deliveryPassInfo?.dpTypeSku ?? '',
  };
};
