interface AddToRefineEventOptions {
  category: string;
  label: string;
}

interface AddToRefineEvent {
  /* eslint-disable @typescript-eslint/naming-convention */
  event: string;
  event_name: string;
  refine_category: string;
  ua_category: string;
  ua_action: string;
  ua_label: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export const addToRefineEvent = ({
  category,
  label,
}: AddToRefineEventOptions): AddToRefineEvent => {
  return {
    /* eslint-disable @typescript-eslint/naming-convention */
    event: 'refine_result',
    event_name: 'refine_result',
    refine_category: category,
    ua_category: 'refine',
    ua_action: 'click',
    ua_label: label,
    /* eslint-enable @typescript-eslint/naming-convention */
  };
};
