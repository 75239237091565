import { priceAdapter } from '@adapters/priceAdapter';
import {
  CouponStatusName,
  MaterialPrice,
  Maybe,
  ProductExternalData as ProductExternalDataApi,
  Variation,
  VariationGroup,
} from '@api';
import { COUPON_CLIPPABLE } from '@commons/cart';
import {
  Bundle,
  ConfigurableProductTile,
  ProductCoupon,
  ProductCvPrice,
  ProductExternalData,
  ProductGroupScale,
  ProductQuantity,
  ProductSalesUnit,
  ProductTile,
  ProductTileImage,
  ProductTileRangePrice,
  ProductTileResponse,
  ProductTileVariations,
  ProductTileVariationValues,
} from '@commons/product';

const { getPrice } = priceAdapter();

const getProductAvailability = (product?: ProductTileResponse): string[] =>
  product?.availability?.filter((item): item is string => !!item) ?? [];

const getProductCoupon = (product?: ProductTileResponse): ProductCoupon => {
  const hasCoupon =
    !!product?.coupon?.couponId &&
    !!product?.coupon?.displayDescription &&
    !!product?.coupon?.value;

  const couponActivated =
    product?.coupon?.displayStatus?.name !== CouponStatusName.COUPON_ACTIVE &&
    product?.coupon?.displayStatus?.name !== COUPON_CLIPPABLE;

  return {
    couponId: product?.coupon?.couponId ?? '',
    discount: Number(product?.coupon?.value) ?? 0,
    description: product?.coupon?.displayDescription ?? '',
    detailedDescription: product?.coupon?.detailedDescription || '',
    expirationDate: product?.coupon?.expirationDate || '',
    hasCoupon: hasCoupon,
    isActive: couponActivated,
  };
};

const getProductTileImage = (product?: ProductTileResponse): ProductTileImage => ({
  alt: product?.productDescription ?? `${product?.brandName} ${product?.productName}`,
  ref: product?.productZoomImage ?? '',
  largeRef: product?.productJumboImage ?? '',
});

const getProductQuantity = (product?: ProductTileResponse): ProductQuantity => ({
  minQuantity: product?.quantity?.minQuantity ?? 0,
  maxQuantity: product?.quantity?.maxQuantity ?? 0,
  quantity: product?.quantity?.quantity ?? 0,
  quantityIncrement: product?.quantity?.quantityIncrement ?? 0,
});

const getProductMarketingTags = (product?: ProductTileResponse) => ({
  soldOut: !!product?.marketingTags?.soldOut,
  sponsored: !!product?.marketingTags?.sponsored,
  yourFave: !!product?.marketingTags?.yourFave,
  backOnline: !!product?.marketingTags?.backOnline,
  new: !!product?.marketingTags?.new,
});

const getProductFeatureTags = (product?: ProductTileResponse) => ({
  topPick: !!product?.featureTags?.topPick,
  freeSample: !!product?.featureTags?.topPick,
  expressEligible: !!product?.featureTags?.expressEligible,
});

const getProductCvPrices = (product?: ProductTileResponse): ProductCvPrice[] => {
  return product?.cvPrices?.length
    ? product?.cvPrices.map((price) => ({
        charValueName: price?.charValueName ?? '',
        price: price?.price ?? 0,
        applyHow: price?.applyHow ?? 0,
      }))
    : [];
};

const getProductTileRangePrice = (
  price?: DeepPartial<Maybe<MaterialPrice>>,
): ProductTileRangePrice => ({
  scaleLowerBound: price?.scaleLowerBound ?? 0,
  scaleUpperBound: price?.scaleUpperBound ?? 0,
  price: price?.price ?? 0,
});

const getMapedProductTileRangePrice = (
  product?: DeepPartial<Maybe<Maybe<MaterialPrice>[]>>,
): ProductTileRangePrice[] => {
  return product?.length ? product?.map((price) => getProductTileRangePrice(price)) : [];
};

const getProductGroupScale = (product?: ProductTileResponse): ProductGroupScale => ({
  grpId: product?.groupScale?.grpId ?? '',
  version: product?.groupScale?.version ?? '',
  grpPrice: product?.groupScale?.grpPrice ?? '',
  grpDescription: product?.groupScale?.grpDescription ?? '',
});

const getProductSalesUnits = (product?: ProductTileResponse): ProductSalesUnit[] => {
  return product?.salesUnits?.length
    ? product?.salesUnits.map((value) => ({
        alternateSalesUnit: value?.alternateSalesUnit || '',
        name: value?.name || '',
        ratio: value?.ratio || 1,
        salesUnit: value?.salesUnit || '',
        selected: !!value?.selected,
      }))
    : [];
};

const getSelectedSalesUnit = (salesUnits: ProductSalesUnit[]): string => {
  const selectedSalesUnit = salesUnits?.find((unit) => unit?.selected)?.alternateSalesUnit;

  return selectedSalesUnit ? selectedSalesUnit : '';
};

const getProductTileVariationValues = (
  values: DeepPartial<Variation['values']>,
): ProductTileVariationValues[] => {
  if (values && values?.length) {
    return values.map((value) => ({
      cvp: value?.cvp ?? '',
      description: value?.description ?? '',
      imagePath: value?.imagePath ?? '',
      isLabelValue: !!value?.isLabelValue,
      label: value?.label ?? '',
      name: value?.name ?? '',
      productName: value?.productName ?? '',
      selected: values.length > 1 ? !!value?.selected : true,
      variationItemProductData: {
        categoryId: value?.variationItemProductData?.categoryId ?? '',
        productId: value?.variationItemProductData?.productId ?? '',
      },
    }));
  }
  return [];
};

const getProductTileVariations = (product?: ProductTileResponse): ProductTileVariations[] => {
  return product?.variations?.length
    ? product?.variations.map((item) => {
        const variationValues = getProductTileVariationValues(item?.values);
        return {
          name: item?.name ?? '',
          label: item?.label ?? '',
          display: item?.display ?? '',
          underLabel: item?.underLabel ?? '',
          descrPopup: item?.descrPopup ?? '',
          descrMedia: item?.descrMedia ?? '',
          optional: !!item?.optional,
          isMultipleValues: variationValues.length > 1,
          values: variationValues,
        };
      })
    : [];
};

const getBaseUnitInfo = (product?: ProductTileResponse) => ({
  unitSize: product?.unitSize ?? '',
  unitPrice: product?.unitPrice ?? '',
  scaleUnit: product?.scaleUnit ?? '',
  roughPricePerUnit: product?.roughPricePerUnit ?? '',
  defaultScaleUnit: product?.defaultScaleUnit ?? '',
  pricePerScaleUnit: product?.pricePerScaleUnit ?? '',
});

const getBaseInfo = (product?: ProductTileResponse) => {
  return {
    ...getBaseUnitInfo(product),
    deal: product?.deal ?? 0,
    productId: product?.productId ?? '',
    productPageUrl: product?.productPageUrl ?? '',
    categoryPageUrl: product?.productPageUrl ?? '',
    skuCode: product?.skuCode ?? '',
    productName: product?.productName ?? '',
    productDescription: product?.productDescription ?? '',
    categoryId: product?.categoryId ?? '',
    brandName: product?.brandName ?? '',
    variantId: product?.variantId ?? '',
    formattedCurrentPrice: product?.formattedCurrentPrice ?? '',
    listItemLineId: product?.listItemLineId ?? '',
    clickBeacon: product?.clickBeacon ?? '',
    imageBeacon: product?.imageBeacon ?? '',
    viewBeacon: product?.viewBeacon ?? '',
  };
};

const getProductBundle = (product?: ProductTileResponse): Bundle => {
  return {
    type: product?.bundle?.type ?? '',
    variationGroups: (product?.bundle?.variationGroups ?? []).filter(
      (i): i is VariationGroup => !!i,
    ),
  };
};

export const getProductExternalData = (
  externalData?: Maybe<ProductExternalDataApi>,
): ProductExternalData => ({
  externalResponseId: externalData?.externalResponseId ?? '',
  externalWidgetId: externalData?.externalWidgetId ?? '',
  externalWidgetType: externalData?.externalWidgetType ?? '',
});

export const getProductTile = (product?: ProductTileResponse): ProductTile => {
  const salesUnits = getProductSalesUnits(product);
  return {
    ...getBaseInfo(product),
    estimatedWeightDisclaimer: product?.estimatedWeightDisclaimer ?? '',
    selectedSalesUnit: getSelectedSalesUnit(salesUnits),
    savingString: product?.savingString ?? '',
    preparationTime: product?.preparationTime ?? 0,
    available: !!product?.available,
    hasWineData: !!product?.fdWinesAndSpirits,
    soldBySalesUnit: !!product?.soldBySalesUnit,
    availability: getProductAvailability(product),
    coupon: getProductCoupon(product),
    price: getPrice(product?.price),
    wasPrice: getPrice(product?.wasPrice),
    productImage: getProductTileImage(product),
    quantity: getProductQuantity(product),
    marketingTags: getProductMarketingTags(product),
    featureTags: getProductFeatureTags(product),
    cvPrices: getProductCvPrices(product),
    availMaterialPrices: getMapedProductTileRangePrice(product?.availMaterialPrices),
    grpPrices: getMapedProductTileRangePrice(product?.grpPrices),
    groupScale: getProductGroupScale(product),
    variations: getProductTileVariations(product),
    externalData: getProductExternalData(product?.externalData),
    bundle: getProductBundle(product),
    salesUnits,
    configuration:
      product?.configuration?.map((item) => ({
        characteristicName: item?.key ?? '',
        characteristicValue: item?.value ?? '',
      })) ?? [],
    discountAmount: product?.discountAmount?.value ?? 0,
    alternativeProductId: product?.recommendedProduct?.productId ?? '',
    isAlcoholic: product?.alcoholic ?? false,
  };
};

export const getConfigurableProductTile = (
  product: ProductTileResponse,
): ConfigurableProductTile => ({
  ...getProductTile(product),
  configuredPrice: product?.configuredPrice ?? 0,
});

export const getConfigurationOptions = (variations: ProductTileVariations[]) => {
  return variations
    .map(({ name, values }) => {
      const selectedValue = values?.find(({ selected }) => selected);
      const value = (selectedValue ?? values[0])?.name ?? '';

      return {
        name,
        value,
      };
    })
    .filter((item) => !!item.value);
};
