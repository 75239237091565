/* eslint-disable @typescript-eslint/naming-convention */
import { EcommerceEvent } from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { CustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { getCartPosition } from '@modules/ga/eventBodyGetters/utils/getCartPosition';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { ViewCartOptions } from '../viewCartCreator';
import {
  AdvancedCartProductMetrics,
  getAdvancedCartProductMetrics,
} from './getAdvancedCartProductMetrics';

interface ViewCartEvent extends EcommerceEvent {
  cart_position?: string;
  ecommerce: {
    currency: string;
    value: number;
    items: AdvancedCartProductMetrics[];
  };
}

interface GetViewCartEventProps extends ViewCartOptions {
  customer: CustomerData;
}

export const getViewCartEvent = ({
  router,
  subtotal,
  cartLines,
  customer,
}: GetViewCartEventProps): ViewCartEvent => {
  return {
    event: 'view_cart',
    event_name: 'view_cart',
    ua_action: 'view cart',
    ua_category: 'Ecommerce Action',
    cart_position: getCartPosition(router),
    customer: {
      deliverypass_type: customer.deliveryPassType,
      deliverypass_term: customer.deliveryPassTerm,
      express_eligible: customer.expressEligible,
    },
    ecommerce: {
      currency: getCurrencyCodeFromSymbol(cartLines?.[0]?.price?.currency),
      value: subtotal,
      items: cartLines.map(getAdvancedCartProductMetrics),
    },
  };
};
