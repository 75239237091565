import { gql } from '@apollo/client';

export const ACCEPT_TC = gql`
  mutation AcceptTC {
    acceptUserTerms {
      errorMessage
      success
    }
  }
`;
