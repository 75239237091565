import { Dispatch } from 'react';
import { MutationHookOptions, useApolloClient, useMutation } from '@apollo/client';
import { GET_FEATURE_ELIGIBILITIES } from '@graphql/common/queries/getFeatureEligibilities';
import { SOCIAL_LOGIN } from '@modules/auth/operations/mutations/SocialLogin';
import { fireLoginSuccessEvent } from '@modules/ga/events/custom/login-success/loginSuccessCreator';
import { openWelcomeCreateAccountModal } from '@modules/modals/events/welcomeCreateAccountModal';
import { openWelcomeSignInModal } from '@modules/modals/events/welcomeSignInModal';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { useAuthModalContext } from '@context/AuthModalContext';
import { QUERIES_TO_SKIP_ON_LOGIN } from '../constants';
import { USER_AUTH_STATUS } from '../types';
import { homeRedirect } from '../utils/loginRedirections';
import { handleSuccessfulAuthorization } from '../utils/handleSuccessfulAuthorization';
import { AUTH_USER_ACTIONS_TYPES, AuthUserActions } from '../context/AuthUserReducer';

interface SocialLoginProps {
  dispatch?: Dispatch<AuthUserActions>;
}

export const useSocialLogin = ({ dispatch }: SocialLoginProps) => {
  const client = useApolloClient();
  const [socialLoginMutation, { loading: socialLoginMutationLoading }] = useMutation(SOCIAL_LOGIN);
  const { closeAuthModal, emit } = useAuthModalContext();

  const socialLogin = ({ onCompleted, ...options }: MutationHookOptions) => {
    dispatch?.({
      type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
      payload: USER_AUTH_STATUS.AUTH_BEGIN,
    });
    socialLoginMutation({
      // TODO: refactor to one reusable func with login muttion
      ...options,
      onCompleted: async (data) => {
        homeRedirect();
        const handleModalClose = () => {
          emit?.({ type: 'welcomeClose' });
        };

        handleSuccessfulAuthorization(data.socialLogin.customer, dispatch);
        data?.socialLogin.customer?.hasJustSignUp
          ? openWelcomeCreateAccountModal()
          : openWelcomeSignInModal({ onClose: handleModalClose });
        closeAuthModal();
        onCompleted?.(data);
        emit?.({ type: 'signIn' });
        fireLoginSuccessEvent({
          authEvent: data?.socialLogin.authEvent,
        });

        await client.refetchQueries({
          include: [GET_FEATURE_ELIGIBILITIES],
        });
        await client.refetchQueries({
          include: 'active',
          onQueryUpdated: ({ queryName = '' }) => !QUERIES_TO_SKIP_ON_LOGIN.includes(queryName),
        });
        dispatch?.({
          type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
          payload: USER_AUTH_STATUS.AUTH_COMPLETE,
        });
        sessionStorageService?.put(STORAGE_KEYS.AUTH_NOT_COMPLETE, false);
      },
    });
  };

  return {
    socialLogin,
    isLoading: socialLoginMutationLoading,
  };
};
