import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Order, ORDER_TYPE } from '@commons/order';
import { OrderCard } from '@components/OrderCard/OrderCard';
import { BaseLink, Button } from '@components/UI';
import { useReorderLinkWithFilter } from '@hooks/reorder/useReorderLinkWithFilter';
import { useLocalization } from '@hooks/useLocalization';
import { routing } from '@constants/routing';
import { getCurrencySymbol } from '@utils/productHelper';
import { getDate } from '@utils/getDate';
import styles from './PastOrderCard.module.scss';

interface PastOrderCardProps {
  order: Order;
  className?: string;
  modifyingOrderId?: string | null;
}

export const PastOrderCard = ({
  order,
  className,
  modifyingOrderId,
  ...props
}: PastOrderCardProps) => {
  const { t } = useLocalization('account');
  const isOrderModifying = modifyingOrderId && modifyingOrderId === order.orderId;
  const reorderLink = useReorderLinkWithFilter(order);

  return (
    <OrderCard {...props} aria-label={t('orderHistory.pastOrderCard')} className={className}>
      <div className={styles.info}>
        <Typography
          data-testid={'status&total'}
          component="p"
          variant="body"
          className={styles.status}
        >
          {!!order.orderStatus && t(`orderHistory.status.${order.orderStatus}`)}
          {!!order.orderStatus && !!order.orderTotal && ', '}
          {!!order.orderTotal && (
            <Typography component="strong" variant="body" className={styles.details_item}>
              {getCurrencySymbol() + order.orderTotal}
            </Typography>
          )}
        </Typography>
        <Typography
          className={styles.order_date}
          data-testid={'date&orderId'}
          component="p"
          variant="smallBody"
        >
          {!!order.requestedDate && `${getDate(order.requestedDate, 'MMM D YYYY')}`}
          {!!order.requestedDate && !!order.orderId && ', '}
          {!!order.soTemplateName ? order.soTemplateName : `#${order.orderId}`}
        </Typography>
      </div>
      <div className={styles.buttons_wrapper}>
        {order.orderType !== ORDER_TYPE.GIFT_CARD && reorderLink && (
          <BaseLink passHref href={reorderLink}>
            <Button isFullWidth className={styles.button}>
              {t('orderHistory.order_actions.reorder')}
              <Typography component="span" variant="body" sx={visuallyHidden}>
                {t('orderHistory.order.orderNumber', { orderId: order.orderId })}
              </Typography>
            </Button>
          </BaseLink>
        )}
        {!isOrderModifying && (
          <Button
            href={`${routing.orderDetails}/${encodeURIComponent(order.orderId)}`}
            isFullWidth={true}
            className={styles.button}
            variant="outlined"
          >
            {t('orderHistory.order_actions.details')}
            <Typography component="span" variant="body" sx={visuallyHidden}>
              {t('orderHistory.order.orderNumber', { orderId: order.orderId })}
            </Typography>
          </Button>
        )}
      </div>
    </OrderCard>
  );
};
