import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const DELETE_CART_LINE = gql`
  mutation DeleteCartLine($cartLineId: ID!, $inModifyMode: Boolean) {
    deleteCartLine(cartLineId: $cartLineId, inModifyMode: $inModifyMode) {
      cartLinesCount
      ...cartFragment
    }
  }
  ${CART_FRAGMENT}
`;
