import { useState } from 'react';
import { Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ApolloError, useMutation } from '@apollo/client';
import { useLocalization } from '@hooks/useLocalization';
import { CustomCheckbox } from '@features/AccountPage/components/AccountPagePreferences/components/CustomCheckbox/CustomCheckbox';
import { SubmitButton } from '@features/AccountPage/components/AccountPagePreferences/components/SubmitButton/SubmitButton';
import { APPLY_CSR } from '@graphql/masquerade/mutations/applyCsr';
import { responseErrorHandler } from '@utils/responseErrorHandler';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { updateCartCache } from '@hooks/cart/updateCartCache';
import { LARGE_FIELD_LENGTH } from '@utils/validation';
import { FormikInput } from '@components/UI';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import styles from './MasquaradeIndicators.module.scss';

enum FIELDS {
  DELIVERY_FEE = 'deliveryFee',
  SEND_EMAIL = 'sendEmail',
  INVOICE_MESSAGE = 'invoiceMessage',
}

interface FormValues {
  [FIELDS.DELIVERY_FEE]: boolean;
  [FIELDS.SEND_EMAIL]: boolean;
  [FIELDS.INVOICE_MESSAGE]: string;
}

export const MasquaradeIndicators = () => {
  const { t } = useLocalization('cart');
  const [warning, setWarning] = useState<Record<string, boolean>>({});
  const { cartInfo } = useCartForCheckoutContext();
  const { data } = cartInfo;
  const { dispatchAlert } = useAccountAlertContext();
  const [applyCsrMutation, { loading: isSubmitLoading }] = useMutation(APPLY_CSR);

  const { csrData: indicators } = data;
  const formData = {
    [FIELDS.DELIVERY_FEE]: indicators?.isDeliveryForceWaived ?? false,
    [FIELDS.SEND_EMAIL]: indicators?.isEmailInvoiceSendingSkipped ?? false,
    [FIELDS.INVOICE_MESSAGE]: indicators?.customerInvoiceMessage ?? '',
  };

  const changeWarningByName = (name: string) => {
    setWarning((warnings) => ({
      ...warnings,
      [name]: true,
    }));
  };

  const NotAppliedWarning = () => (
    <Typography variant="smallBody" className={styles.warning}>
      {t('masqueradeIndicators.not_applied')}
    </Typography>
  );

  const handleInputChange = () => {
    if (!warning[FIELDS.INVOICE_MESSAGE]) {
      changeWarningByName(FIELDS.INVOICE_MESSAGE);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    await applyCsrMutation({
      variables: {
        input: {
          deliveryForceWaive: values[FIELDS.DELIVERY_FEE],
          silentMode: values[FIELDS.SEND_EMAIL],
          customerInvoiceMessage: values[FIELDS.INVOICE_MESSAGE].trim(),
        },
      },
      update: (cache, { data: { applyCsr } }) => updateCartCache(cache, applyCsr),
      onError: (error: ApolloError) => responseErrorHandler(error.message, dispatchAlert),
    });
    setWarning({});
  };

  return (
    <div className={styles.indicator_container}>
      <Formik
        initialValues={formData}
        enableReinitialize
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className={styles.checkbox_section}>
            <Field
              className={styles.checkbox}
              name={FIELDS.DELIVERY_FEE}
              id={FIELDS.DELIVERY_FEE}
              type="checkbox"
              aria-label={FIELDS.DELIVERY_FEE}
              label={t('masqueradeIndicators.delivery_fee')}
              component={CustomCheckbox}
              disabled={isSubmitLoading}
              onClick={() => changeWarningByName(FIELDS.DELIVERY_FEE)}
            />
            {warning[FIELDS.DELIVERY_FEE] && <NotAppliedWarning />}
          </div>
          <div className={styles.checkbox_section}>
            <Field
              className={styles.checkbox}
              name={FIELDS.SEND_EMAIL}
              id={FIELDS.SEND_EMAIL}
              type="checkbox"
              aria-label={FIELDS.SEND_EMAIL}
              label={t('masqueradeIndicators.do_not_send_email')}
              component={CustomCheckbox}
              disabled={isSubmitLoading}
              onClick={() => changeWarningByName(FIELDS.SEND_EMAIL)}
            />
            {warning[FIELDS.SEND_EMAIL] && <NotAppliedWarning />}
          </div>
          <div className={styles.checkbox_section}>
            <Typography variant="subtitle2" className={styles.label}>
              {t('masqueradeIndicators.invoice_message')}
            </Typography>
            {warning[FIELDS.INVOICE_MESSAGE] && <NotAppliedWarning />}
          </div>
          <div className={styles.message_section}>
            <Field
              name={FIELDS.INVOICE_MESSAGE}
              control="input"
              maxLength={LARGE_FIELD_LENGTH}
              type="text"
              className={styles.message_input}
              component={FormikInput}
              onInput={handleInputChange}
              id={FIELDS.INVOICE_MESSAGE}
            />
            <SubmitButton
              loading={isSubmitLoading}
              disabled={isSubmitLoading}
              aria-label={'apply'}
              size={'small'}
            >
              {t('masqueradeIndicators.apply')}
            </SubmitButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
