export type PageException = (url: string) => string | undefined;

export const getPagePathname = (url: string, pagesException: Array<PageException>) => {
  for (const pageException of pagesException) {
    const pathname = pageException(url);
    if (pathname) {
      return pathname;
    }

    return url;
  }
};
