import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { FC, useCallback } from 'react';
import { BaseLink } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useNavigationTracking } from '@hooks/header/useNavigationTracking';
import { arrowDown as ArrowDown } from '@assets/icons/system';
import { NavigationMenuItem } from '@api';
import styles from '../../Hamburger.module.scss';
import { NavIcon } from './NavIcon';

interface DropdownMenuListItemProps {
  name?: string | null;
  menuItems?: Array<NavigationMenuItem | null> | null;
  expanded: string | null;
  icon?: string | null;
  link?: string | null;
  setExpanded(name: string | null): void;
  closeMenu: () => void;
}

export const DropdownMenuListItem: FC<DropdownMenuListItemProps> = ({
  name,
  icon,
  link,
  menuItems,
  expanded,
  setExpanded,
  closeMenu,
}) => {
  const { t } = useLocalization('header');
  const seeAllGtmProps = useNavigationTracking(`See all ${name}`);

  const handleAccordionChange = useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? name ?? '' : null);
    },
    [setExpanded, name],
  );

  if (!name) return null;

  return (
    <>
      <NavIcon icon={icon} alt={`${name} icon`} />
      <Accordion
        disableGutters
        className={cx(styles.accordion, styles.without_margin)}
        expanded={expanded === name}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ArrowDown width={18} height={11} />}
          aria-controls={`${name}-content`}
          id={`${name}-header`}
          className={styles.accordion_summary}
        >
          <Typography noWrap className={styles.list_text}>
            {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.sub_dep_list}>
          {link && (
            <BaseLink href={link}>
              <ListItem className={styles.sub_dep_item}>
                <ListItemButton
                  className={styles.list_button}
                  {...seeAllGtmProps}
                  onClick={closeMenu}
                >
                  <Typography noWrap className={styles.list_text}>
                    {t('seeAll', { headline: name })}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </BaseLink>
          )}
          {menuItems?.map(
            (item) =>
              item?.link && (
                <BaseLink href={item.link} key={item.id}>
                  <ListItem key={item.id} className={styles.sub_dep_item}>
                    <ListItemButton className={styles.list_button} onClick={closeMenu}>
                      <Typography noWrap className={styles.list_text}>
                        {item.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </BaseLink>
              ),
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
