import { ApolloLink } from '@apollo/client';
import getConfig from 'next/config';

export const securityHeaderLink = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation.setContext((ctx: Record<string, any>) => {
    const { publicRuntimeConfig } = getConfig();
    return {
      ...ctx,
      headers: {
        ...ctx.headers,
        // security header for blocking brute force attack.
        ['X-jabuticaba']: publicRuntimeConfig.jabuticabaKey,
      },
    };
  });
  return forward(operation);
});
