import { useQuery } from '@apollo/client';
import { GET_GIFT_CART } from '@graphql/cart/queries/getGiftCart';
import { giftCardAdapter } from '@adapters/giftCardAdatper';
import { GiftCardsCartQuery, GiftCardsCartQueryVariables } from '@api';

export const useGiftCardCart = () => {
  const { data, ...rest } = useQuery<GiftCardsCartQuery, GiftCardsCartQueryVariables>(
    GET_GIFT_CART,
    { ssr: false },
  );
  const { getGiftCardsCart } = giftCardAdapter();
  const cart = getGiftCardsCart(data?.giftCardsCart);
  const isEmpty = !data?.giftCardsCart || data?.giftCardsCart?.cardsCount === 0;
  const emailGiftCardsCount = cart.cards.filter((card) => card.deliveryMethod === 'E').length;

  return { data: cart, ...rest, isEmpty, emailGiftCardsCount };
};
