import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Typography } from '@mui/material';
import { useUpdateEffect } from 'usehooks-ts';
import { AutosuggestProduct, ProductTile, ProductTile as IProductTile } from '@commons/product';
import { ProductTileManager } from '@components/Tiles/ProductTile/ProductTileManager';
import { useLocalization } from '@hooks/useLocalization';
import breakpoints from '@styles/breakpoints.module.scss';
import { ProductCarouselNavigation } from '@components/ProductCarousel/components';
import { Button } from '@components/UI';
import { CAROUSEL_TYPES } from '@commons/carousel';
import { useRWExternalWidgetViewGAEvent } from '@modules/ga/events/custom/RWProduct/useRWExternalWidgetViewGAEvent';
import { isServerRendering } from '@utils/isServerRendering';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { RecommendedProductCarouselSkeleton } from './components/RecommendedProductCarouselSkeleton';
import styles from './RecommendedProductCarousel.module.scss';

const DEFAULT_PRODUCTS_PER_VIEW = 4;

const MIN_SCREEN_HEIGHT = 900;

export enum PAGE_TYPE {
  BAG = 'bag',
  CONFIRMATION = 'confirmation',
}

export interface ProductCarouselProps {
  products: Array<IProductTile | null>;
  type?: PAGE_TYPE;
  className?: string;
  name?: string;
  onChange?: (product?: ProductTile | AutosuggestProduct) => void | Promise<void>;
  loading?: boolean;
  isCartRecommender?: boolean;
  productsPerView?: number;
}

export const RecommendedProductCarousel = ({
  products,
  name,
  className,
  onChange,
  type = PAGE_TYPE.BAG,
  productsPerView = DEFAULT_PRODUCTS_PER_VIEW,
  loading,
  isCartRecommender,
  ...props
}: ProductCarouselProps) => {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [activeSlide, setActiveSlide] = useState(1);
  const { t } = useLocalization();
  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);

  const isNavigationVisible = products.length > productsPerView;
  const totalSlides = Math.ceil(products?.length / productsPerView);
  const filteredProducts = products.filter((product): product is IProductTile => product !== null);
  useRWExternalWidgetViewGAEvent(filteredProducts, isCartRecommender);

  useUpdateEffect(() => {
    setIsEnd(false);
  }, [isShow]);

  useEffect(() => {
    if (!isServerRendering() && window.innerHeight < MIN_SCREEN_HEIGHT) {
      setIsShow(false);
    }
  }, [window.innerHeight]);

  const toggleShowCarousel = () => {
    setIsShow((prev) => !prev);
  };

  if (!products.length) return null;

  const bagTitleContent = (
    <>
      <Typography className={styles.title} variant="h5" component="h2">
        {t('cart:recommendationCarouselTitle')}
      </Typography>
      <Button
        onClick={toggleShowCarousel}
        className={styles.title_button}
        variant="underline"
        aria-label={
          isShow ? t('cart:showRecommendationAriaText') : t('cart:hideRecommendationAriaText')
        }
      >
        {isShow ? t('cart:show') : t('cart:hide')}
      </Button>
    </>
  );

  const confirmationTitleContent = (
    <>
      <Typography className={styles.confirmationTitle} variant="h5" component="h2">
        {t('orderDetails:recommendationCarouselTitle')}
      </Typography>
    </>
  );

  const mapPageTypeToTitleContent = {
    [PAGE_TYPE.BAG]: bagTitleContent,
    [PAGE_TYPE.CONFIRMATION]: confirmationTitleContent,
  };

  if (loading) {
    return <RecommendedProductCarouselSkeleton />;
  }

  return (
    <Swiper
      {...props}
      breakpoints={{
        [breakpoints.xs]: {
          slidesPerView: 2,
        },
        [breakpoints.sm]: {
          slidesPerView: 2,
        },
        [breakpoints.lg]: {
          slidesPerView: productsPerView,
          allowTouchMove: false,
        },
      }}
      className={cx(styles.carousel, className)}
      virtual={false}
      spaceBetween={24}
      maxBackfaceHiddenSlides={0}
      slidesPerView={productsPerView}
      slidesPerGroup={productsPerView}
      watchSlidesProgress
      onSlideChange={(swiper) => {
        setIsEnd(swiper.isEnd);
        setIsStart(swiper.isBeginning);
        setActiveSlide(swiper?.snapIndex + 1);
      }}
      preloadImages={false}
      role="region"
      aria-label="Carousel"
      wrapperTag="ul"
      title={t('cart:recommendationCarouselTitle')}
    >
      <div slot="container-start" className={styles.header}>
        {mapPageTypeToTitleContent[type]}
        {isNavigationVisible && !isShow && (
          <ProductCarouselNavigation
            isEnd={isEnd}
            isStart={isStart}
            className={styles.navigation}
            name={name || ''}
          />
        )}
        <Box aria-live="polite" className="sr_only">
          slide {activeSlide} of {totalSlides}
        </Box>
      </div>
      {!isShow &&
        products.map((product: IProductTile | null, index) =>
          product ? (
            <SwiperSlide
              tag="li"
              key={`${product.productId}_${index}`}
              data-testid={`product-carousel-slide-${product.productId}`}
              className={cx(styles.slide, {
                [styles.separator]: index > 0 && !isV2,
              })}
            >
              {({ isVisible }) => (
                <ProductTileManager
                  product={product}
                  productCarouselType={CAROUSEL_TYPES.RECOMMENDATIONS}
                  className={styles.product}
                  nonFocusable={!isVisible}
                  onChange={onChange}
                  skipAddToCart={type === PAGE_TYPE.CONFIRMATION}
                  isShowImage
                  hideShoppingListButton
                  isV2={isV2}
                />
              )}
            </SwiperSlide>
          ) : (
            <SwiperSlide key={index} className={styles.slide} tag="li" />
          ),
        )}
    </Swiper>
  );
};
