import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { Button } from '@components/UI/Button/Button';
import { InnerItemType } from '@layouts/Footer/Footer';
import { openRequestCreditModal } from '@modules/modals/events/requestCreditModal';
import { useAuthModalContext } from '@context/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useLocalization } from '@hooks/useLocalization';
import { ContactUsModalProvider } from '@context/contactUsModalContext';
import styles from '../../../Footer.module.scss';
import { ItemIcon } from './components/ItemIcon';
import { ItemLinkText, FOOTER } from './components/ItemLinkText';

interface ColumnInnerItemProps {
  isMobileAppLink: boolean;
  hasRequestCredit: boolean;
  innerItem: InnerItemType;
  titleId?: string;
  innerItemId?: string;
}

export const ColumnInnerItems = ({
  innerItem,
  isMobileAppLink,
  hasRequestCredit,
  titleId,
  innerItemId,
}: ColumnInnerItemProps) => {
  const { title, function: itemFunction } = innerItem;
  const { openAuthModal } = useAuthModalContext();
  const { isRecognizedUser } = useAuthContext();
  const [columnTitleId, setColumnTitleId] = useState('column_title');
  const { t } = useLocalization();

  useEffect(() => {
    if (columnTitleId === 'column_title') {
      setColumnTitleId(uniqueId('column_title_'));
    }
  }, [columnTitleId]);

  const handleOpenRequestCreditModal = () => {
    if (isRecognizedUser) {
      return openAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
    }
    openRequestCreditModal();
  };

  const isRequestCredit = itemFunction === FOOTER.STORE_CREDIT;

  const renderItem = () => {
    return (
      <li className={cx(styles.inner_item, { [styles.inner_title]: title })}>
        {title && (
          <div className={styles.title} id={columnTitleId}>
            {title}
          </div>
        )}
        <ItemIcon
          innerItem={innerItem}
          isMobileAppLink={isMobileAppLink}
          titleId={title ? columnTitleId : titleId}
          innerItemId={innerItemId}
        />
        {!isMobileAppLink && (
          <ContactUsModalProvider>
            <ItemLinkText
              innerItem={innerItem}
              titleId={title ? columnTitleId : titleId}
              innerItemId={innerItemId}
            />
          </ContactUsModalProvider>
        )}
      </li>
    );
  };

  const renderRequestCreditItem = () => {
    return (
      <li className={styles.inner_item}>
        <Button
          className={styles.inner_item_button}
          onClick={handleOpenRequestCreditModal}
          variant="underline"
        >
          <span className={styles.subtitle}>{t('footer.requestCredit')}</span>
        </Button>
      </li>
    );
  };

  if (isRequestCredit && hasRequestCredit) return renderRequestCreditItem();

  return <>{!isRequestCredit && renderItem()}</>;
};
