import { CartLight } from '@commons/cart';
import { getLightCartLinesByProductId } from '@modules/ga/eventBodyGetters/utils/getCartLine';

export const calcLightCartQuantityDifference = (
  newCart: CartLight,
  oldCart: CartLight,
  productId: string,
) => {
  const newCartLine = getLightCartLinesByProductId(newCart, productId);
  const updatedQuantity =
    newCartLine.reduce((quantity, cartLine) => quantity + cartLine.quantitySelector.quantity, 0) ||
    1;
  const oldCartLine = getLightCartLinesByProductId(oldCart, productId);
  const previousQuantity = oldCartLine.reduce(
    (quantity, cartLine) => quantity + cartLine.quantitySelector.quantity,
    0,
  );

  return updatedQuantity - previousQuantity;
};
