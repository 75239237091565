import { Credit } from '@commons/credits';
import { useLocalization } from '@hooks/useLocalization';
import { Title } from '../../../Title/Title';
import { CreditItem } from '../CreditItem/CreditItem';
import styles from './CreditList.module.scss';

interface CreditListProps {
  children?: never;
  title: string;
  creditItems: Credit[];
  dataTestId?: string;
}

export const CreditList = ({ title, creditItems, dataTestId }: CreditListProps) => {
  const { t } = useLocalization('account');

  if (!creditItems.length) return null;

  return (
    <div data-testid={dataTestId}>
      <Title className={styles.title} testId="store-credit-list-title">
        {title}
      </Title>
      {creditItems.map((creditItem, index) => {
        return (
          <CreditItem
            key={index}
            code={creditItem.order}
            status={creditItem.status ?? t('credit.page.pendingStatus')}
            price={creditItem.amount}
            date={creditItem.date}
            creditId={creditItem.creditId}
            creditNumber={creditItem.accountNum}
          />
        );
      })}
    </div>
  );
};
