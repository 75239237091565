import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { PaymentMethodType } from '@api';
import { useInitHostedPayment } from '@hooks/payments/useInitHostedPayment';
import { useAccountPreferences } from '@hooks/useAccountPreferences';
import { PaymentsOnSuccess } from '@components/PaymentsAddTabs/PaymentAddTabs.types';
import { AddCardForm } from './components/AddCardForm/AddCardForm';

interface AddCardTabContentProps {
  onSuccess?: PaymentsOnSuccess;
  onError: Dispatch<SetStateAction<string>>;
}

export const AddCardTabContent = ({ onSuccess, onError }: AddCardTabContentProps) => {
  const {
    accountPreferencesLoading,
    accountPreferences: { isMasqueradeMode },
  } = useAccountPreferences();
  const { data, refetch } = useInitHostedPayment(
    PaymentMethodType.CC,
    isMasqueradeMode || accountPreferencesLoading,
  );
  const hostedPaymentURL = data?.initializeHostedPayment?.url;

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (!hostedPaymentURL) {
    return null;
  }

  return <AddCardForm url={hostedPaymentURL} onSuccess={onSuccess} onError={onError} />;
};
