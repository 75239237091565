import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getViewCartEvent } from './utils/getViewCartEvent';
import { ViewCartOptions } from './viewCartCreator';

interface ViewCartHandlerProps extends ViewCartOptions {
  commonAnalyticsData: AnalyticsData;
}

export const viewCartHandler = (data: ViewCartHandlerProps) => {
  const { userDetails } = data.commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getViewCartEvent({
      ...data,
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
    }),
  );
};
