import { Typography } from '@mui/material';
import { PromoCodeField } from '@components/PromoCodeField/PromoCodeField';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useMobilePromoModalContext } from '@context/MobilePromoModalContext/MobilePromoModalContext';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './MobilePromoModal.module.scss';

export const MobilePromoModal = () => {
  const { opened, onClose } = useMobilePromoModalContext();
  const { t } = useLocalization();

  return (
    <ModalWindow open={opened} onClose={onClose} labelId={MODAL_TITLE_ID.MOBILE_PROMO}>
      <>
        <Typography
          component="h2"
          variant="h3"
          className={styles.title}
          id={MODAL_TITLE_ID.MOBILE_PROMO}
        >
          {t('promo.modalTitle')}
        </Typography>
        <PromoCodeField className={styles.input} />
      </>
    </ModalWindow>
  );
};
