import { PreparedDropdown } from '../types';

export const isSingleDropdown = (variation: PreparedDropdown, isBundle: boolean) => {
  return (
    (!isBundle && variation.values.length === 1) ||
    (isBundle &&
      variation.values.length === 1 &&
      !variation.optional &&
      variation.values[0].value &&
      variation.values[0].label)
  );
};
