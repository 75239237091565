import Head from 'next/head';
import { SEOData } from '@commons/seoData';

interface SeoTagsProps {
  seoData: SEOData;
  defaultSeoData: SEOData;
}

export const SeoTags = ({ seoData, defaultSeoData }: SeoTagsProps) => {
  const title = seoData.pageTitle || defaultSeoData.pageTitle;
  const content = seoData.metaDescription || defaultSeoData.metaDescription;

  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={content} key="description" />
    </Head>
  );
};
