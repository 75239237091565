import cx from 'classnames';
import { CircularProgress } from '@mui/material';
import { UITheme } from '@commons/uiTheme';
import styles from './CIrcularLoader.module.scss';
interface CircularLoaderProps {
  theme?: UITheme;
  externalWrapperClass?: string;
  externalCircleClass?: string;
}

export const CircularLoader = ({
  theme = 'light',
  externalWrapperClass,
  externalCircleClass,
}: CircularLoaderProps) => {
  const circularProgressClasslist = {
    [styles.ligth]: theme === 'light',
    [styles.orange]: theme === 'orange',
  };

  return (
    <div className={cx(styles.wrapper, externalWrapperClass)}>
      <CircularProgress
        variant="determinate"
        size={40}
        thickness={4}
        className={styles.inner_circle}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        className={cx(styles.outer_circle, circularProgressClasslist, externalCircleClass)}
      />
    </div>
  );
};
