import { Divider, Typography } from '@mui/material';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { openRequestCreditModal } from '@modules/modals/events/requestCreditModal';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import styles from './MainActions.module.scss';

interface MainActionProps {
  closeModal: () => void;
}

export const MainAction = ({ closeModal }: MainActionProps) => {
  const { t } = useLocalization();
  const { openAuthModal } = useAuthModalContext();
  const { isRecognizedUser } = useAuthContext();

  const handleClick = () => {
    closeModal();
    if (isRecognizedUser) {
      return openAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
    }
    openRequestCreditModal();
  };

  return (
    <>
      <div className={styles.main_action}>
        <Typography aria-label="request-credit-title" className={styles.heading}>
          Problem with your order?
        </Typography>
        <Typography aria-label="request-credit-description" className={styles.description}>
          Request an account credit. It’s easy, and faster than an email or phone call.
        </Typography>
        <Button
          size="large"
          aria-label="request-credit-button"
          className={styles.request_credit_button}
          onClick={handleClick}
        >
          {t('contactUs.requestButton')}
        </Button>
      </div>
      <Divider className={styles.divider} />
    </>
  );
};
