import { Product, ProductTile, SalesUnit } from '@commons/product';

const DEFAULT_RATIO = 1;
const CALCULATED_UNITS = ['lb'];

const getSelectedSalesUnit = (salesUnits: SalesUnit[]): SalesUnit | null => {
  const selectedSalesUnit = salesUnits.find((salesUnit) => salesUnit.selected);
  return selectedSalesUnit || null;
};

export const calculateEstimatedWeight = (
  product: Product | ProductTile,
  quantity = 1,
  selectedSalesUnit?: SalesUnit,
): string | null => {
  const salesUnit = selectedSalesUnit || getSelectedSalesUnit(product.salesUnits);
  const ratio = salesUnit?.ratio || DEFAULT_RATIO;
  const calculatedWeight = (ratio * quantity).toFixed(2);

  if (salesUnit?.salesUnit && CALCULATED_UNITS.includes(salesUnit.salesUnit.toLowerCase())) {
    return calculatedWeight + salesUnit?.salesUnit.toLowerCase();
  }

  return null;
};
