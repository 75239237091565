import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '../common/address';

export const ANON_DELIVERY_ADDRESS_FRAGMENT = gql`
  fragment anonDeliveryAddressFragment on DeliveryAddress {
    defaultZip
    expressEnabled
    selected
    isTemporary
    address {
      ...addressFragment
    }
  }
  ${ADDRESS_FRAGMENT}
`;
