import { useMemo } from 'react';
import { useCountryCodeAndState } from '@hooks/account/useCountryCodeAndState';
import { DEFAULT_COUNTRY_NAME } from '@constants/delivery';
import { getCountryOptions } from '@utils/getCountryOptions';
import { getStateOptions } from '@utils/getStateOptions';
import { useValidator } from '@hooks/validation/useValidation';
import {
  validateApartment,
  validateBillingAddress,
  validateCardHolder,
  validateCityRequired,
  validateEbtCard,
  validateRequired,
  validateZipCode,
  validatePhoneNumber,
} from '@utils/validators';
import { getAddressLabel } from '@utils/getAddressLabel';
import { useHomeAddresses } from '@hooks/deliveryAddress/useHomeAddresses';
import { useLocalization } from '@hooks/useLocalization';

export enum FIELDS {
  CARDHOLDER = 'cardHolderName',
  CARD_NUMBER = 'card_number',
  PHONE = 'phone',
  BILLING_ADDRESS = 'billing_address',
  NEW_BILLING_ADDRESS = 'new_billing_address',
  APARTMENT = 'apartment',
  COUNTRY = 'country',
  CITY = 'city',
  ZIP_CODE = 'zip_code',
  STATE = 'state',
}
export type SetFieldValue = (field: string, value?: string) => void;
export const NEW_ADDRESS = 'new_address';

export const useEbtTabContent = () => {
  const { t } = useLocalization('account');
  const { countryStates: states, countryCodes: countries } = useCountryCodeAndState({
    state: DEFAULT_COUNTRY_NAME,
  });
  const { data: addresses } = useHomeAddresses();

  const countryOptions = useMemo(() => getCountryOptions(countries), [countries]);
  const stateOptions = useMemo(() => getStateOptions(states), [states]);

  const validate = {
    [FIELDS.CARD_NUMBER]: useValidator(validateEbtCard),
    [FIELDS.CARDHOLDER]: useValidator(validateCardHolder),
    [FIELDS.PHONE]: useValidator(validatePhoneNumber),
    [FIELDS.APARTMENT]: useValidator(validateApartment),
    [FIELDS.NEW_BILLING_ADDRESS]: useValidator(validateBillingAddress),
    [FIELDS.CITY]: useValidator(validateCityRequired),
    [FIELDS.ZIP_CODE]: useValidator(validateZipCode),
    [FIELDS.COUNTRY]: useValidator(validateRequired),
    [FIELDS.STATE]: useValidator(validateRequired),
  };

  const addressOptions = [
    ...addresses.map((address) => ({
      value: address.address.id,
      label: getAddressLabel(address.address),
    })),
    { value: NEW_ADDRESS, label: t('delivery.addressModal.mainTitle.add') },
  ];

  return {
    countryOptions,
    stateOptions,
    addressOptions,
    addresses,
    validate,
  };
};
