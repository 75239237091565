import { useState, createContext, ReactNode, useContext } from 'react';

export type ErrorTypes =
  | 'ORDER_SUBMITTING_FAILED'
  | 'checkout.under.order.minimum'
  | 'checkout.address.not.selected'
  | 'checkout.timeslot.not.selected'
  | 'checkout.timeslot.reservation.failed'
  | 'checkout.alcohol.is.restricted'
  | 'paymentMethodList'
  | 'expiration'
  | 'payment_method'
  | 'authorization.invalid'
  | 'timeslot.reservation.failed'
  | 'SMS_SETTINGS_SAVE_FAILED'
  | 'UNAVAILABLE_ITEMS'
  | 'atpFailure'
  | 'ebt'
  | 'AUTHORIZATION_EXCEPTION'
  | 'CHECKOUT_ALCOHOL_IS_RESTRICTED'
  | 'CHECKOUT_TIMESLOT_NOT_SELECTED'
  | 'CHECKOUT_UNDER_ORDER_MINIMUM'
  | 'termsnotaccepted'
  | 'orderSubmit'
  | 'timeslot'
  | 'UNAUTHENTICATED'
  | 'ebtAddressRestriction'
  | 'user.otp.signup.not.complete'
  | 'checkout.dlvpass.only.order.modify';

export const smsSettingsFailed = 'SMS_SETTINGS_SAVE_FAILED';
export const paymentMethodError = 'payment_method';
export const expirationError = 'expiration';
export const paymentMethodListError = 'paymentMethodList';

export const timeslotError = 'timeslot';

export const orderPlacementError = 'orderSubmit';
export const unavailableProductErrorMessage = 'This item is unavailable.';
export const underOrderMinimumError = 'checkout.under.order.minimum';
export const underCheckoutOrderMinimumError = 'CHECKOUT_UNDER_ORDER_MINIMUM';
export const billingReferenceMissingError = 'billing.reference.cos.missing';
export const billingReferenceInvalidCharsError = 'billing.reference.cos.invalid.chars';

export interface Error {
  type: ErrorTypes;
  message: string;
}

const PageValidationErrorContext = createContext<
  | {
      error?: Error;
      set: (error: Error) => void;
      clear: () => void;
    }
  | undefined
>(undefined);

function PageValidationErrorProvider({ children }: { children: ReactNode }) {
  const [message, setMessage] = useState<string | undefined>();
  const [type, setType] = useState<ErrorTypes | undefined>();

  const clear = () => {
    setMessage(undefined);
    setType(undefined);
  };

  const setError = (newError: Error) => {
    setMessage(newError.message);
    setType(newError.type);
  };
  const errorValue = type !== undefined && message !== undefined ? { message, type } : undefined;

  return (
    <PageValidationErrorContext.Provider
      value={{
        error: errorValue,
        set: setError,
        clear,
      }}
    >
      {children}
    </PageValidationErrorContext.Provider>
  );
}

function usePageValidationError() {
  const context = useContext(PageValidationErrorContext);
  if (context === undefined) {
    throw new Error('useCartValidationError must be used within a CartValidationErrorProvider');
  }
  return context;
}

export { PageValidationErrorProvider, usePageValidationError };
