import { ApolloCache } from '@apollo/client';
import { Payments } from '@api';

export const updateCheckoutPaymentMethodsCache = (
  cache: ApolloCache<unknown>,
  newPayments?: Payments | null,
) => {
  cache.modify({
    fields: {
      checkout(checkout, { DELETE }) {
        return checkout
          ? {
              ...checkout,
              payments: {
                ...checkout.payments,
                ...newPayments,
              },
            }
          : DELETE;
      },
    },
  });
};
