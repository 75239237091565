import { gql } from '@apollo/client';

export const SALES_UNIT_FRAGMENT = gql`
  fragment salesUnitFragment on SalesUnit {
    alternateSalesUnit
    name
    selected
    salesUnit
    ratio
  }
`;
