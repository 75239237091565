import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import cx from 'classnames';
import { FC, useCallback, useState } from 'react';
import { NavigationData } from '@hooks/header/useNavigationData';
import { DepartmentUnion, DropdownMenu, NavigationMenuItem } from '@api';
import { routing } from '@constants/routing';
import { dealsAnonSubmenu, dealsSubmenu } from '@constants/dealsSubmenu';
import { useAuthContext } from '@modules/auth/context';
import { isSuperDepartmentItem } from '@layouts/Header/isSuperDepartmentItem';
import styles from '../../Hamburger.module.scss';
import { Categories } from '../Categories/Categories';
import { TopMenuListItem } from './TopMenuListItem';
import { DepartmentListItem } from './DepartmentListItem';
import { SubMenuModalData } from './SubMenuModalData';
import { SuperDepartmentListItem } from './SuperDepartmentListItem';
import { DropdownMenuListItem } from './DropdownMenuListItem';

interface PrimaryNavigationProps {
  navigationData: NavigationData;
  subMenuData: SubMenuModalData | null;
  setSubMenuData(subMenuData: SubMenuModalData | null): void;
  closeMenu(): void;
}

const getSubDepartments = (department: DepartmentUnion) => {
  if (!isSuperDepartmentItem(department)) {
    return [];
  }

  return department.departments ?? [];
};

export const PrimaryNavigation: FC<PrimaryNavigationProps> = ({
  navigationData: { primaryNavigation, departments, additionalMenus },
  closeMenu,
  subMenuData,
  setSubMenuData,
}) => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const openSubMenu = useCallback(
    (data: SubMenuModalData) => {
      setExpanded(null);
      setSubMenuData(data);
    },
    [setSubMenuData],
  );
  const closeSubMenu = useCallback(() => setSubMenuData(null), [setSubMenuData]);
  const { isKnownUser } = useAuthContext();

  const getDepartment = (id?: string | null) =>
    id && departments[id] ? departments[id] : undefined;

  const getDropdownMenu = (linkId: string | null | undefined, link: string | null | undefined) => {
    // TUE-12489: hardcode dropdown menu for deals, web only
    if (link === routing.dealsAndSale) {
      return isKnownUser ? dealsSubmenu : dealsAnonSubmenu;
    }

    const dropdownMenu = additionalMenus?.find((menu) => menu?.id === linkId);
    return dropdownMenu ?? null;
  };

  const renderDepartmentMenuItem = (navItem: NavigationMenuItem, department: DepartmentUnion) => {
    const hasSubDepartments = department && isSuperDepartmentItem(department);
    const subDepartments = department ? getSubDepartments(department) : [];

    if (hasSubDepartments) {
      return (
        <SuperDepartmentListItem
          {...navItem}
          {...department}
          subDepartments={subDepartments}
          expanded={expanded}
          setExpanded={setExpanded}
          openSubMenu={openSubMenu}
          closeMenu={closeMenu}
        />
      );
    }

    return (
      <DepartmentListItem department={department} openSubMenu={openSubMenu} icon={navItem.icon} />
    );
  };

  const renderDropdownMenuItem = (navItem: NavigationMenuItem, dropdownMenu: DropdownMenu) => {
    return (
      <DropdownMenuListItem
        {...navItem}
        {...dropdownMenu}
        expanded={expanded}
        setExpanded={setExpanded}
        closeMenu={closeMenu}
      />
    );
  };

  const renderMenuItem = (navItem: NavigationMenuItem) => {
    if (!navItem) {
      return null;
    }

    const link = navItem.link?.trim();
    const department = getDepartment(navItem.id);
    const dropdownMenu = getDropdownMenu(navItem.linkId, link);

    if (department) {
      return renderDepartmentMenuItem(navItem, department);
    } else if (dropdownMenu) {
      return renderDropdownMenuItem(navItem, dropdownMenu);
    }

    return (
      <TopMenuListItem
        icon={navItem.icon}
        name={navItem.name ?? ''}
        href={link ?? ''}
        onClick={closeMenu}
      />
    );
  };

  return (
    <>
      <List className={cx(styles.list_wrapper)}>
        {primaryNavigation.map((navItem) => {
          if (!navItem?.link && !navItem?.linkId) {
            return null;
          }

          return (
            <ListItem
              key={navItem.link ?? navItem.linkId}
              className={cx(styles.list_item, {
                [styles.muted]: Boolean(subMenuData),
                [styles.highlighted]:
                  subMenuData?.department.name === navItem.name ||
                  subMenuData?.superDepartmentName === navItem.name,
              })}
            >
              {renderMenuItem(navItem)}
            </ListItem>
          );
        })}
      </List>
      <Categories
        {...subMenuData}
        isOpen={Boolean(subMenuData)}
        closeSubMenu={closeSubMenu}
        closeMenu={closeMenu}
      />
    </>
  );
};
