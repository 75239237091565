import { gql } from '@apollo/client';

export const FACET_VALUE_FRAGMENT = gql`
  fragment facetValueFragment on FacetValue {
    id
    name
    count
    active
    selected
  }
`;

export const FACET_FRAGMENT = gql`
  fragment facetFragment on Facet {
    id
    name
    detailName
    type
    selectionType
    multiSelection
    disabled
    value {
      ...facetValueFragment
    }
    primary
  }
  ${FACET_VALUE_FRAGMENT}
`;

export const SORT_OPTION_FRAGMENT = gql`
  fragment SortOptionFragment on SortOption {
    id
    name
    selected
  }
`;
