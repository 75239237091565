import { WelcomeCreateAccountModalProps } from '@modules/modals/types/welcomeCreateAccountModal/welcomeCreateAccountModal';
import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';

export const openWelcomeCreateAccountModal = (props: WelcomeCreateAccountModalProps = {}) => {
  fireOpenModal<WelcomeCreateAccountModalProps>(MODALS_LIST.WELCOME_CREATE_ACCOUNT_MODAL, {
    ...props,
  });
};

export const closeWelcomeCreateAccountModal = () => {
  fireCloseModal(MODALS_LIST.WELCOME_CREATE_ACCOUNT_MODAL);
};
