import { useQuery } from '@apollo/client';
import { GET_PAYMENT_AVAILABILITIES } from '@graphql/account/queries/getPaymentAvailabilities';

export const usePaymentAvailabilities = () => {
  const { data, loading, ...queryParams } = useQuery(GET_PAYMENT_AVAILABILITIES, {
    ssr: false,
    fetchPolicy: 'network-only',
  });
  return {
    ...queryParams,
    data: {
      showBankAccount: data?.paymentAvailabilities?.bankAccount || false,
      showEbt: data?.paymentAvailabilities?.ebt || false,
      loading,
    },
  };
};
