import React from 'react';
import { Divider } from '@mui/material';
import cx from 'classnames';
import { joinStr } from '@utils/joinStr';
import { useLocalization } from '@hooks/useLocalization';
import { BottomLine } from './components/bottomLine/BottomLine';
import { CopyRight } from './components/bottomLine/copyRight/CopyRight';
import { FooterColumn } from './components/footerColumn/FooterColumn';
import { useFooterData } from './hooks/useFooterData';
import { focusMainLayout } from './utils';
import styles from './Footer.module.scss';

export interface InnerItemType {
  name: string;
  title: string;
  icon?: React.FC<{ className?: string }>;
  link: string;
  function?: string;
}
export interface Item {
  title: string;
  innerItems: InnerItemType[];
}

const APP_STORE = 'gettheapps';

const backToTop = () => {
  focusMainLayout();
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const Footer = () => {
  const { t } = useLocalization('footer');
  const { data } = useFooterData();
  const { footerColumns, bottomLine } = data ?? {};

  return (
    <>
      <footer className={styles.footer}>
        <button className={styles.to_top} onClick={backToTop}>
          {t('backToTop')}
        </button>
        <div className={styles.top_line}>
          {footerColumns?.map((item: Item, i: number) => {
            const titleId = `footer_title_${i}`;
            const isMobileAppLink = joinStr(item.title) === APP_STORE;
            return (
              <FooterColumn
                key={`ftrColumn${i}`}
                item={item}
                isMobileAppLink={isMobileAppLink}
                col={i + 1}
                titleId={titleId}
              />
            );
          })}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.bottom_line}>
          <CopyRight />
          {bottomLine && (
            <div className={styles.inner_bottom_items}>
              {bottomLine.innerItems.map((item: InnerItemType, i: number) => (
                <BottomLine
                  key={`btmLine${i}`}
                  item={item}
                  className={cx(styles.subtitle, styles.bottom_subtitle)}
                />
              ))}
            </div>
          )}
        </div>
      </footer>
    </>
  );
};
