import {
  Product,
  ProductTile,
  PRODUCT_PRICE_CURRENCY,
  PRODUCT_CURRENCY_NAME,
} from '@commons/product';
import { COMPONENTGROUP_MEAL, HOLIDAY_MEAL_BUNDLE_PRODUCT } from '@constants/product';

export const getCurrencySymbol = (currency?: string | null) => {
  switch (currency) {
    case PRODUCT_CURRENCY_NAME.USD:
      return PRODUCT_PRICE_CURRENCY.USD;
    case PRODUCT_CURRENCY_NAME.EUR:
      return PRODUCT_PRICE_CURRENCY.EUR;
    default:
      return PRODUCT_PRICE_CURRENCY.USD;
  }
};

export const getCurrencyCodeFromSymbol = (currencyName?: string | null) => {
  switch (currencyName) {
    case PRODUCT_PRICE_CURRENCY.USD:
      return PRODUCT_CURRENCY_NAME.USD;
    case PRODUCT_PRICE_CURRENCY.EUR:
      return PRODUCT_CURRENCY_NAME.EUR;
    default:
      return PRODUCT_CURRENCY_NAME.USD;
  }
};

export const isMealBundle = (product: Product | ProductTile) =>
  product.bundle.type === COMPONENTGROUP_MEAL ||
  product.bundle.type === HOLIDAY_MEAL_BUNDLE_PRODUCT;
