import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { NotificationAccountDeletionProps } from '@modules/modals/types/notificationAccountDeletionModal/notificationAccountDeletionModal';

export const openNotificationAccountDeletionModal = (props: NotificationAccountDeletionProps) => {
  fireOpenModal<NotificationAccountDeletionProps>(MODALS_LIST.NOTIFICATION_ACCOUNT_DELETION, {
    ...props,
  });
};

export const closeNotificationAccountDeletionModal = () => {
  fireCloseModal(MODALS_LIST.NOTIFICATION_ACCOUNT_DELETION);
};
