import { gql } from '@apollo/client';

export const PROMOTION_STATE_FRAGMENT = gql`
  fragment promotionStateFragment on PromotionCode {
    promotionApplied
    promotion {
      promotionCode
      promotionType
      offerType {
        name
        description
      }
      description
      terms
      name
    }
  }
`;
