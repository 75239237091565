import { useMutation } from '@apollo/client';
import { SET_SHOW_PENDING_ORDERS } from '@graphql/cart/mutations/setShowPendingOrders';

export const useSetPendingOrders = (isShown?: boolean) => {
  return useMutation(SET_SHOW_PENDING_ORDERS, {
    variables: {
      isShown,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
};
