import React, { RefObject, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Translate } from 'next-translate';
import { initialValues } from '@components/Modals/GiftCardModals/constants';
import { EMAIL_REGEX } from '@utils/validation';
import { EditView } from '@components/Modals/GiftCardModals/components/EditView/EditView';
import { PurchaseView } from '@components/Modals/GiftCardModals/components/PurchaseView/PurchaseView';
import { GIFTCARD_FIELDS, Values } from '@components/Modals/GiftCardModals/types';
import { useLocalization } from '@hooks/useLocalization';
import { UseGiftCardPurchaseInfoData } from '@hooks/giftCard/useGiftCardPurchaseInfo';
import { Button } from '@components/UI';
import styles from './GiftCardForm.module.scss';

interface GiftCardFormDefaultProps {
  values?: Values;
  templateData: UseGiftCardPurchaseInfoData;
  onSubmit: (values: Values) => void;
  isCustomAmountEditShown?: boolean;
  onCloseCustomAmountForm: () => void;
  onOpenCustomAmountForm: () => void;
  loading: boolean;
  submitButtonText: string;
  formRef: RefObject<FormikProps<Values>>;
}

export type GiftCardCustomRenderProps = Partial<GiftCardFormDefaultProps> & { t: Translate };

interface GiftCardFormProps extends GiftCardFormDefaultProps {
  customRender?: (args: GiftCardCustomRenderProps) => JSX.Element;
}

export const GiftCardForm = ({
  values,
  templateData,
  isCustomAmountEditShown,
  onCloseCustomAmountForm,
  onOpenCustomAmountForm,
  onSubmit,
  loading,
  submitButtonText,
  formRef,
  customRender,
}: GiftCardFormProps) => {
  const { t } = useLocalization('giftCards');
  const [giftCardError, setGiftCardError] = useState({});

  const handleCustomAmountClick = () => {
    formRef.current?.setFieldTouched(
      GIFTCARD_FIELDS.GIFTCARD_CUSTOM_AMOUNT,
      !!formRef.current?.values[GIFTCARD_FIELDS.GIFTCARD_CUSTOM_AMOUNT],
    );
    onOpenCustomAmountForm();
  };

  const currentView = isCustomAmountEditShown ? (
    <EditView onSave={onCloseCustomAmountForm} minCustomAmount={templateData.minAmount} />
  ) : (
    <>
      <PurchaseView
        templates={templateData.gcTemplates}
        loadingAmountOptions={loading}
        loadingTemplates={loading}
        amountOptions={templateData.gcAmtOptions}
        onClickCustomAmount={handleCustomAmountClick}
        setGiftCardError={setGiftCardError}
        isSinglePurchaseView
      />
      <Button
        type="submit"
        size="large"
        className={styles.submit_button}
        isFullWidth
        disabled={Object.keys(giftCardError).length > 0}
      >
        {t(submitButtonText)}
      </Button>
    </>
  );

  return (
    <Formik
      initialValues={values ?? initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      innerRef={formRef}
      onSubmit={(formikValues) => onSubmit(formikValues)}
      validationSchema={Yup.object({
        senderName: Yup.string().required(t('giftCardModal.errors.required')),
        recipientName: Yup.string().required(t('giftCardModal.errors.required')),
        recipientEmail: Yup.string()
          .matches(EMAIL_REGEX, t('giftCardModal.errors.incompleteEmail'))
          .required(t('giftCardModal.errors.required')),
        recipientMessage: Yup.string().optional(),
        giftCardAmount: Yup.string().required(t('giftCardModal.errors.giftCardAmount')),
        giftCardCustomAmount: Yup.number()
          [isCustomAmountEditShown ? 'required' : 'optional'](t('giftCardModal.errors.required'))
          .integer(t('giftCardModal.errors.integer'))
          .min(
            templateData.minAmount,
            t('giftCardModal.errors.minAmount', { value: templateData.minAmount }),
          )
          .max(
            templateData.maxAmount,
            t('giftCardModal.errors.maxAmount', { value: templateData.maxAmount }),
          )
          .typeError(t('giftCardModal.errors.badInput')),
        giftCardTemplateId: Yup.string(),
      })}
      enableReinitialize
    >
      <Form>
        {customRender && customRender({ values, submitButtonText, templateData, t })}
        {!customRender && currentView}
      </Form>
    </Formik>
  );
};
