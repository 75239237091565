import { gql } from '@apollo/client';
import { DELIVERY_TIMESLOTS_DATA_FRAGMENT } from '@graphql/fragments/timeslots/deliveryTimeslotData';

export const TIMESLOTS = gql`
  query Timeslots(
    $timeslotContextType: TimeslotContextType
    $includeExpress: Boolean
    $isForce: Boolean
    $standingOrderMode: StandingOrderMode
  ) {
    timeslots(
      timeslotContextType: $timeslotContextType
      includeExpress: $includeExpress
      isForce: $isForce
      standingOrderMode: $standingOrderMode
    ) {
      ...deliveryTimeslotDataFragment
    }
  }
  ${DELIVERY_TIMESLOTS_DATA_FRAGMENT}
`;
