import { useState } from 'react';
import { Button } from '@components/UI';
import { Modal } from '@components/Modals';
import { useLocalization } from '@hooks/useLocalization';
import {
  google as GoogleIcon,
  facebookRound as FacebookIcon,
} from '@assets/icons/socials/colored/index';
import { useAccountPreferences } from '@hooks/useAccountPreferences';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { PageBlock } from '../PageBlock/PageBlock';
import styles from './LinkedAccounts.module.scss';
import { UnlinkModal, PasswordModal } from './components';

enum SOCIAL_NAMES {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

interface LinkedAccountsProps {
  connectedSocials: {
    socialName: (string | null)[];
    email: string | null;
    isSocialOnly: boolean;
  };
}

const getSocialIcons = (socialName: string) => {
  switch (socialName) {
    case SOCIAL_NAMES.GOOGLE:
      return <GoogleIcon width={15} height={15} />;
    case SOCIAL_NAMES.FACEBOOK:
      return <FacebookIcon width={15} height={15} />;
  }
};

const getModalTitleId = (modal: MODAL_TYPES) => {
  if (modal === MODAL_TYPES.PASSWORD) {
    return MODAL_TITLE_ID.PASSWORD;
  }

  if (modal === MODAL_TYPES.UNLINK) {
    return MODAL_TITLE_ID.UNLINK;
  }

  return MODAL_TITLE_ID.NULL;
};

enum MODAL_TYPES {
  PASSWORD = 'password',
  UNLINK = 'unlink',
  NULL = 'null',
}

export const LinkedAccounts = ({ connectedSocials }: LinkedAccountsProps) => {
  const { socialName, email, isSocialOnly } = connectedSocials;
  const { unlinkSocialAccount, updateAccountPassword, updateAccountEmail } =
    useAccountPreferences();
  const { t } = useLocalization('account');
  const [modal, setModal] = useState<MODAL_TYPES>(MODAL_TYPES.NULL);
  const [social, setSocial] = useState<string | null>(null);
  const modalTitleId = getModalTitleId(modal);

  const toggleModal = (modalType: MODAL_TYPES) => {
    setModal(modalType);
  };

  const closeModal = () => {
    toggleModal(MODAL_TYPES.NULL);
  };

  const handleUnlink = () => {
    if (!isSocialOnly) unlinkSocialAccount(email, social);
    else toggleModal(MODAL_TYPES.PASSWORD);
  };

  const handleSubmitPassword = (form: { [key: string]: string }) => {
    updateAccountPassword({ password: form.password, confirmPassword: form.password }, false);
    unlinkSocialAccount(email, social);
    updateAccountEmail(form.email, false);
    closeModal();
  };

  const handleModalBack = () => {
    if (modal === MODAL_TYPES.PASSWORD) {
      toggleModal(MODAL_TYPES.UNLINK);
    } else {
      closeModal();
    }
  };

  const isOpenModal = modal !== MODAL_TYPES.NULL;

  return (
    <>
      <PageBlock title="Linked Accounts">
        <ul className={styles.socials_list}>
          {socialName.map((socialItem) => {
            return (
              <li key={socialItem} className={styles.socials_list_item}>
                {socialItem && (
                  <span className={styles.socials_list_item_icon}>
                    {getSocialIcons(socialItem)}
                  </span>
                )}
                {email}
                <Button
                  variant="underline"
                  className={styles.socials_list_item_button}
                  onClick={() => {
                    setSocial(socialItem);
                    toggleModal(MODAL_TYPES.UNLINK);
                  }}
                >
                  {t('preferences.linkedAccounts.unlinkButton')}
                </Button>
              </li>
            );
          })}
        </ul>
      </PageBlock>
      <Modal
        open={isOpenModal}
        handleClose={closeModal}
        handleBack={handleModalBack}
        labelId={modalTitleId}
      >
        <>
          {modal === MODAL_TYPES.UNLINK && (
            <UnlinkModal
              handleUnlink={handleUnlink}
              handleCloseModal={closeModal}
              socialName={social}
            />
          )}
          {modal === MODAL_TYPES.PASSWORD && (
            <PasswordModal initialEmail={email} onSubmit={handleSubmitPassword} />
          )}
        </>
      </Modal>
    </>
  );
};
