import { useRouter } from 'next/router';
import { useLocalization } from '@hooks/index';
import { arrowRight as ArrowRight } from '@assets/icons/system';
import { switchToOfficeFD as SwitchToOfficeFDIcon } from '@assets/icons/account';
import { useServiceType } from '@hooks/useServiceType';
import { routing } from '@constants/routing';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';
import { getSwitchedServiceType } from '@utils/getSwitchedServiceType';
import { FeatureCheck } from '@modules/featureCheck/FeatureCheck';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import styles from './GoToFDOffice.module.scss';

export const GoToFDOffice = () => {
  const { t } = useLocalization('account');
  const router = useRouter();
  const { serviceType } = useServiceType();

  const handleCosIconClick = () => {
    if (serviceType) {
      router.push(`${routing.home}?serviceType=${getSwitchedServiceType(serviceType)}`);
    }
  };

  const switchBtnText =
    serviceType === SERVICE_TYPE.HOME ? t('preferences.business') : t('preferences.residential');
  const label =
    serviceType === SERVICE_TYPE.HOME
      ? t('preferences.goToBusiness')
      : t('preferences.goToResidential');

  return (
    <div className={styles.wrapper}>
      <FeatureCheck
        featureName={FEATURE.COS}
        enabled={() => (
          <button className={styles.fdSwitch} onClick={handleCosIconClick} aria-label={label}>
            <span className={styles.title}>{t('preferences.goTo')}</span>
            <span className={styles.description}>
              <SwitchToOfficeFDIcon />
              {switchBtnText}
              <ArrowRight width={6} height={6} />
            </span>
          </button>
        )}
        disabled={() => (
          <a
            className={styles.fdSwitch}
            href="/?serviceType=CORPORATE"
            aria-label={t('preferences.goToBusiness')}
          >
            <p className={styles.title}>{t('preferences.goTo')}</p>
            <div className={styles.description}>
              <SwitchToOfficeFDIcon />
              {t('preferences.business')}
              <ArrowRight width={6} height={6} />
            </div>
          </a>
        )}
      />
    </div>
  );
};
