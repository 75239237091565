import { useSeoData } from '@hooks/seoData/useSeoData';
import { SEOData } from '@commons/seoData';
import { SeoTags } from './SeoTags';

interface SeoTagsContainerProps {
  defaultSeoData: SEOData;
}

export const SeoTagsContainer = ({ defaultSeoData }: SeoTagsContainerProps) => {
  const { data } = useSeoData();

  return <SeoTags seoData={data} defaultSeoData={defaultSeoData} />;
};
