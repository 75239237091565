import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './UnlinkModal.module.scss';

interface UnlinkModalProps {
  handleCloseModal: () => void;
  handleUnlink: () => void;
  socialName: string | null;
}

export const UnlinkModal = ({ handleCloseModal, handleUnlink, socialName }: UnlinkModalProps) => {
  const { t } = useLocalization('account');
  return (
    <>
      <div className={styles.text_wrapper}>
        <Typography component="h2" variant="h5" className={styles.title} id={MODAL_TITLE_ID.UNLINK}>
          <Trans
            i18nKey="account:preferences.linkedAccounts.unlinkModal.title"
            components={{
              component: <span className={styles.social_name} />,
            }}
            values={{ socialName }}
          />
        </Typography>
        <Typography component="p" variant="body">
          {t('preferences.linkedAccounts.unlinkModal.text')}
        </Typography>
      </div>
      <div className={styles.button_wrapper}>
        <Button size="large" fullWidth={true} className={styles.button} onClick={handleUnlink}>
          {t('preferences.linkedAccounts.unlinkModal.unlinkButton')}
        </Button>
        <Button size="large" fullWidth={true} variant="outlined" onClick={handleCloseModal}>
          {t('preferences.linkedAccounts.unlinkModal.cancel')}
        </Button>
      </div>
    </>
  );
};
