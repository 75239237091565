import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import { NoResultsTemplate } from '@components/NoResultsTemplate/NoResultsTemplate';
import { on } from '@modules/emitter';
import { IFail, TFail } from '@modules/fail';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { GRAPHQL_ERRORS } from '@commons/graphqlErrors';

const serverError = {
  title: 'serverError.title',
  subtitle: '',
  buttonText: 'serverError.goBackShopping',
};

const errorsMap = {
  [GRAPHQL_ERRORS.INTERNAL_SERVER_ERROR]: serverError,
  [GRAPHQL_ERRORS.UNEXPECTED_BACKEND_EXCEPTION]: serverError,
  [GRAPHQL_ERRORS.GRAPHQL_PARSE_FAILED]: serverError,
  [GRAPHQL_ERRORS.GRAPHQL_VALIDATION_FAILED]: serverError,
  [GRAPHQL_ERRORS.PERSISTED_QUERY_NOT_FOUND]: serverError,
  [GRAPHQL_ERRORS.PERSISTED_QUERY_NOT_SUPPORTED]: serverError,
  [GRAPHQL_ERRORS.OPERATION_RESOLUTION_FAILURE]: serverError,
  [GRAPHQL_ERRORS.TOO_MANY_REQUESTS]: {
    title: 'tooManyRequests.title',
    subtitle: '',
    buttonText: '',
  },
  [GRAPHQL_ERRORS.NOT_FOUND]: {
    title: 'notFound.title',
    subtitle: 'notFound.subtitle',
    buttonText: 'notFound.goBackShopping',
  },
  [GRAPHQL_ERRORS.SERVER_UNAVAILABLE]: {
    title: 'serverUnavailable',
    subtitle: '',
    buttonText: 'serverError.goBackShopping',
  },
  [GRAPHQL_ERRORS.NULL]: serverError,
};

export const ApiErrorBoundary: FC = ({ children }) => {
  const { t } = useLocalization('errors');
  const router = useRouter();
  const [failed, setFailed] = useState(false);
  const [errorType, setErrorType] = useState(GRAPHQL_ERRORS.NULL);

  useEffect(
    () =>
      on((action) => {
        if (action.type === TFail) {
          const errorMessage = (action as IFail).message as GRAPHQL_ERRORS;
          setErrorType(errorsMap[errorMessage] ? errorMessage : GRAPHQL_ERRORS.NULL);
          if (errorType !== GRAPHQL_ERRORS.NULL) {
            setFailed(true);
          }
          if (
            errorMessage === GRAPHQL_ERRORS.INTERNAL_SERVER_ERROR ||
            errorMessage === GRAPHQL_ERRORS.UNEXPECTED_BACKEND_EXCEPTION
          )
            sendGtmEvent(
              GtmCustomEvent({
                /* eslint-disable @typescript-eslint/naming-convention */
                event: 'error',
                event_name: 'error_500',
                ua_category: 'Error',
                ua_action: '500 Error',
                ua_label: '500 Error',
                /* eslint-enable @typescript-eslint/naming-convention */
              }),
            );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (failed)
    return (
      <NoResultsTemplate
        title={t(errorsMap[errorType].title)}
        subtitle={t(errorsMap[errorType].subtitle)}
        buttonText={t(errorsMap[errorType].buttonText)}
        onClick={() => {
          setFailed(false);
          router.push(routing.home);
        }}
      />
    );

  return <>{children}</>;
};
