import { ALERT_TYPES } from '@commons/account';
import { AUTH_ERROR_MESSAGE } from '@constants/errorCodes';

type CallBack = (type: ALERT_TYPES, message: string) => void;

export const responseErrorHandler = (message: string, callBack: CallBack) => {
  if (message !== AUTH_ERROR_MESSAGE) {
    return callBack(ALERT_TYPES.ERROR, message);
  }
  return null;
};
