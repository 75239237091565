import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getCheckoutEvent } from '@modules/ga/eventBodyGetters/getCheckoutEvent';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { getProductMetrics } from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getProductsWithProperQuantity } from '@modules/ga/eventBodyGetters/utils/getProductsWithProperQuantity';
import { BeginCheckoutOptions } from './beginCheckoutCreator';

interface BeginCheckoutHandlerProps extends BeginCheckoutOptions {
  commonAnalyticsData: AnalyticsData;
}

export const beginCheckoutHandler = ({
  cartLines,
  commonAnalyticsData,
}: BeginCheckoutHandlerProps) => {
  const products = getProductsWithProperQuantity(cartLines);
  const formattedProducts =
    products.map((product) =>
      getProductMetrics({
        ...product,
        position: product.itemPosition,
        listName: product.itemListName,
      }),
    ) ?? [];
  const { userDetails } = commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getCheckoutEvent({
      products: formattedProducts,
      currencyCode: getCurrencyCodeFromSymbol(products?.[0]?.price?.currency),
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
      event: 'checkoutStep-1',
      eventName: 'begin_checkout',
      uaLabel: 'Step 1',
      step: 1,
      option: 'begin_checkout',
    }),
  );
};
