import { gql } from '@apollo/client';
import { ANON_DELIVERY_ADDRESS_FRAGMENT } from '@graphql/fragments/account/anonDeliveryAddress';

export const GET_ANON_USER_DELIVERY_ADDRESSES = gql`
  query AnonUserDeliveryAddresses($standingOrderMode: StandingOrderMode) {
    userDeliveryAddresses(standingOrderMode: $standingOrderMode) {
      hasNoDeliveryAddresses
      homeAddresses {
        ...anonDeliveryAddressFragment
      }
      selectedAddress {
        ...anonDeliveryAddressFragment
      }
      corpAddresses {
        ...anonDeliveryAddressFragment
      }
      temporaryAddress {
        ...anonDeliveryAddressFragment
      }
      pickUpDepotsAddresses {
        ...anonDeliveryAddressFragment
      }
      userExpressEnabled
    }
  }
  ${ANON_DELIVERY_ADDRESS_FRAGMENT}
`;
