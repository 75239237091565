import { Skeleton } from '@mui/material';
import cx from 'classnames';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { ProductItemWithPriceSkeleton } from '@components/Skeletons/ProductItemWithPriceSkeleton/ProductItemWithPriceSkeleton';
import styles from './BagSkeleton.module.scss';

interface BagSkeletonProps {
  isModify?: boolean;
}

export const BagSkeleton = ({ isModify = false }: BagSkeletonProps) => {
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();
  return (
    <div
      className={cx(styles.wrapper, {
        [styles.drawer_opened]: isSideBagDrawerOpen,
      })}
    >
      <ProductItemWithPriceSkeleton />
      <ProductItemWithPriceSkeleton />
      <ProductItemWithPriceSkeleton />
      <ProductItemWithPriceSkeleton />
      <Skeleton
        className={cx(styles.buttons, { [styles.modify_mode]: isModify })}
        variant="text"
        width="100vw"
        height={185}
      />
    </div>
  );
};
