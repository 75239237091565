import { gql } from '@apollo/client';

export const FOOTER = gql`
  query Footer {
    globalFooter {
      footerColumns {
        title
        innerItems {
          name
          title
          link
          icon
          function
        }
      }
      bottomLine {
        innerItems {
          name
          link
        }
      }
    }
  }
`;
