import Trans from 'next-translate/Trans';
import { Button } from '@components/UI';
import styles from '../../DeliveryAlert.module.scss';

interface GeolocationErrorProps {
  onClick?: () => void;
}

export const GeolocationError = ({ onClick }: GeolocationErrorProps) => {
  return (
    <span className={styles.alert_link}>
      <Trans
        i18nKey="common:alerts.geolocationError"
        components={{
          button: <Button variant="underline" onClick={onClick} className={styles.link} />,
        }}
      ></Trans>
    </span>
  );
};
