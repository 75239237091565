import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Divider, IconButton, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Trans from 'next-translate/Trans';
import { BaseLink as Link } from '@components/UI';
import { arrowLeft as ArrowLeftIcon, close as CloseIcon } from '@assets/icons/system';
import {
  helpCenter as HelpCenterIcon,
  phone as Phone,
  signOut as SignOutIcon,
} from '@assets/icons/account';
import { useLocalization } from '@hooks/index';
import { Transition } from '@components/Transition/Transition';
import { useContactUsModal } from '@context/contactUsModalContext';
import { useDeliveryPassInfo } from '@hooks/deliveryPass/useDeliveryPassInfo';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import { ACCOUNT_MANAGEMENT_PAGE, ACCOUNT_MANAGEMENT_INFO } from '@commons/account';
import { routing } from '@constants/routing';
import { useLazyReferAFriend } from '@hooks/referAFriend/useReferAFriend';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { getAccountSettingsMain } from '@layouts/Header/components/StaticNavigationData/accountSettings';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { AccountSettingsInfoItem } from '../AccountSettingsInfoItem/AccountSettingsInfoItem';
import styles from './AccountNavigationMenu.module.scss';

interface AccountNavigationMenuProps {
  closeDrawer: () => void;
}

export const AccountNavigationMenu = ({ closeDrawer }: AccountNavigationMenuProps) => {
  const { t } = useLocalization();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleModal = () => setIsOpen((prevState) => !prevState);
  const { userInfo: customer, logout } = useAuthContext();
  const { shortName } = customer || {};
  const { emit, on } = useContactUsModal();
  const {
    data: { wasMembershipActivated },
  } = useDeliveryPassInfo();
  const {
    minimumAccountPreferences: { isChefTableAvailable },
  } = useMinimumAccountPreferences();
  const { isStandingOrderVisible } = useMainLayoutContext();

  const isReferAFriendEnabled = isFeatureEnabled(DYNAMIC_FEATURES.REFER_A_FRIEND);
  const { fetchLazyReferAFriend, data: referAFriendData } = useLazyReferAFriend();
  const showRaf = referAFriendData?.referAFriend?.enabled && isReferAFriendEnabled;

  useEffect(() => {
    if (isReferAFriendEnabled) {
      fetchLazyReferAFriend();
    }
  }, [fetchLazyReferAFriend, isReferAFriendEnabled]);

  const close = () => {
    setIsOpen(false);
    closeDrawer();
  };

  const handleLogout = () => {
    logout();
    close();
  };

  useEffect(
    () =>
      on((event) => {
        if (event === 'close') {
          close();
        }
      }),

    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <button className={styles.list_text} onClick={handleModal}>
        <Typography variant="body" className={styles.text}>
          Your account
        </Typography>
      </button>
      <Dialog
        className={styles.dialogs}
        fullScreen
        open={isOpen}
        onClose={handleModal}
        TransitionComponent={Transition}
      >
        <div className={styles.account_navigation_wrapper}>
          <div className={styles.account_navigation_inner_wrapper}>
            <div className={styles.top_menu}>
              <div className={styles.top_menu_inner_wrapper}>
                <IconButton
                  className={styles.back_button}
                  onClick={handleModal}
                  aria-label={t('common:buttons.back')}
                >
                  <ArrowLeftIcon width={17} height={17} />
                </IconButton>
              </div>
              <IconButton
                className={styles.close_button}
                onClick={close}
                aria-label={t('common:buttons.close')}
              >
                <CloseIcon width={13} height={13} />
              </IconButton>
            </div>
            <Typography variant="h2" className={styles.headline} onClick={handleModal}>
              <Trans
                i18nKey="common:greeting"
                components={{
                  separator: <br />,
                }}
                values={{ name: shortName }}
              />
            </Typography>
            <div>
              <List className={styles.list_wrapper}>
                {getAccountSettingsMain(isStandingOrderVisible).map((settingItem) => {
                  if (
                    settingItem.id === ACCOUNT_MANAGEMENT_PAGE.DELIVERY_PASS &&
                    !wasMembershipActivated
                  ) {
                    return null;
                  }
                  if (
                    settingItem.id === ACCOUNT_MANAGEMENT_PAGE.CHEF_TABLE &&
                    !isChefTableAvailable
                  ) {
                    return null;
                  }
                  if (settingItem.id === ACCOUNT_MANAGEMENT_PAGE.REFER_A_FRIEND && !showRaf) {
                    return null;
                  }
                  const AccountIcon = settingItem.icon;
                  return (
                    <li key={settingItem.id}>
                      <Link legacyBehavior href={settingItem.link}>
                        <ListItem
                          disableRipple
                          button
                          onClick={close}
                          className={`${styles.list_item} ${styles.account_list_item}`}
                        >
                          <ListItemIcon
                            className={cx(styles.list_image, {
                              [styles.standing_order_icon]:
                                settingItem.id === ACCOUNT_MANAGEMENT_INFO.STANDING_ORDERS,
                            })}
                          >
                            <AccountIcon width={32} height={32} />
                          </ListItemIcon>
                          <a className={styles.list_text}>{settingItem.name}</a>
                        </ListItem>
                      </Link>
                    </li>
                  );
                })}
              </List>
              <Divider className={styles.secondary_divider} />
              <List className={styles.list_wrapper}>
                <AccountSettingsInfoItem
                  name={t('common:menu.accountSettingsInfoLinks.faq')}
                  onClick={close}
                  icon={HelpCenterIcon}
                  href={routing.help}
                />
                <AccountSettingsInfoItem
                  name={t('common:menu.accountSettingsInfoLinks.contact')}
                  onClick={() => {
                    emit('open');
                  }}
                  icon={Phone}
                />
              </List>
            </div>
          </div>
          <div>
            <Divider className={styles.divider} />
            <List className={styles.list_wrapper}>
              <li>
                <ListItem
                  disableRipple
                  button
                  className={`${styles.list_item} ${styles.account_list_item}`}
                  onClick={handleLogout}
                >
                  <ListItemIcon className={styles.list_image}>
                    <SignOutIcon width={32} height={32} />
                  </ListItemIcon>
                  <Typography className={styles.list_text}>Sign out</Typography>
                </ListItem>
              </li>
            </List>
          </div>
        </div>
      </Dialog>
    </>
  );
};
