import { Tooltip } from '@mui/material';
import cx from 'classnames';
import { ReactElement, SyntheticEvent, useState } from 'react';
import { Alert } from '@components/UI';
import { isTouchDevice } from '@utils/isTouchDevice';
import styles from './ProductQuantityTooltip.module.scss';

interface ProductQuantityTooltipProps {
  children: ReactElement;
  availability: string[];
  onClick?: (event: SyntheticEvent) => void;
  hasSuggestion?: boolean;
  isV2?: boolean;
}

const LEAVE_TOUCH_DELAY = 2000;
const ENTER_TOUCH_DELAY = 0;

export const ProductQuantityTooltip = ({
  children,
  availability,
  hasSuggestion,
  isV2 = false,
}: ProductQuantityTooltipProps) => {
  const [isWarningOpened, setIsWarningOpened] = useState(false);
  const isTouchableDevice = isTouchDevice();

  const handleOpenWarning = () => {
    if (availability.length > 0) {
      setIsWarningOpened(true);
    }
  };

  const handleCloseWarning = () => {
    setIsWarningOpened(false);
  };

  const renderWarning = () => (
    <Alert type="warning" hasShowIcon={false} isFullWidth>
      {availability?.map((warning, index) => (
        <div key={index}>{warning}</div>
      ))}
    </Alert>
  );

  return isV2 ? (
    <>{children}</>
  ) : (
    <>
      <Tooltip
        open={isWarningOpened}
        leaveTouchDelay={LEAVE_TOUCH_DELAY}
        enterTouchDelay={ENTER_TOUCH_DELAY}
        placement="bottom-end"
        componentsProps={{
          popper: { className: cx({ [styles.popper]: !hasSuggestion }) },
          tooltip: {
            className: styles.tooltip,
          },
        }}
        title={renderWarning()}
        onClose={handleCloseWarning}
        onOpen={handleOpenWarning}
        disableHoverListener={isTouchableDevice}
        disableFocusListener
      >
        {children}
      </Tooltip>
    </>
  );
};
