import { warning as WarningIcon } from '@assets/icons/system';
import { alcoholRestrictions as UnavailableAlcoholIcon } from '@assets/icons/informational';

interface TileIconProps {
  hasWineData: boolean;
  note?: string;
}

export const TileIcon = ({ hasWineData, note }: TileIconProps) => {
  const isUnavailableAlcohol = hasWineData || !!note;

  if (isUnavailableAlcohol) {
    <UnavailableAlcoholIcon width={16} height={16} />;
  }

  return <WarningIcon width={16} height={16} />;
};
