import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_APPLIED_PROMO } from '@graphql/cart/queries/getAppliedPromo';
import { getCartPromotionCode } from '@adapters/cartAdapter';
import { GetAppliedPromoQuery, GetAppliedPromoQueryVariables } from '@api';

export const useAppliedPromo = () => {
  const { data, ...query } = useQuery<GetAppliedPromoQuery, GetAppliedPromoQueryVariables>(
    GET_APPLIED_PROMO,
    {
      ssr: false,
    },
  );

  const appliedPromo = useMemo(
    () => getCartPromotionCode(data?.appliedPromotion),
    [data?.appliedPromotion],
  );

  return {
    ...query,
    data: appliedPromo,
  };
};
