import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import styles from './GiftCardAmountTilesSkeleton.module.scss';

export const GiftCardAmountTilesSkeleton = () => {
  return (
    <Stack className={styles.container}>
      <Skeleton variant="rectangular" className={styles.tile} />
      <Skeleton variant="rectangular" className={styles.tile} />
      <Skeleton variant="rectangular" className={styles.tile} />
      <Skeleton variant="rectangular" className={styles.tile} />
      <Skeleton variant="rectangular" className={styles.tile} />
    </Stack>
  );
};
