import { MouseEventHandler, useEffect, useState } from 'react';
import cx from 'classnames';
import { Typography, Tooltip } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { FakeCheckbox } from '@components/UI';
import { info as Info } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { useClipCoupon } from '@hooks/useClipCoupon';
import styles from './Coupon.module.scss';

export interface CouponProps {
  productId: string;
  title: string | null;
  id: string;
  isActive: boolean;
  highlighted?: boolean;
  expirationDate?: string;
  errorMessage?: string;
  prompt?: string | null;
  fullWidth?: boolean;
  size?: 'medium' | 'large';
  className?: string;
  clippedCouponId?: string;
  onClipCouponEvent?: (couponId: string) => void;
  isCartTileUpdating?: boolean;
  isV2?: boolean;
}

const iconSize = {
  medium: { width: 14, height: 14 },
  large: { width: 16, height: 16 },
  v2: { width: 8, height: 8 },
};

export const Coupon = ({
  productId,
  title,
  fullWidth,
  className,
  errorMessage,
  id,
  expirationDate = '',
  prompt = '',
  size = 'medium',
  isActive = false,
  highlighted = false,
  clippedCouponId,
  onClipCouponEvent,
  isCartTileUpdating = false,
  isV2 = false,
}: CouponProps) => {
  const { t } = useLocalization();
  const [isClipped, setIsClipped] = useState(isActive);
  const [clipError, setClipError] = useState(errorMessage);

  const handleClipCoupon = (clipResult: boolean) => {
    setIsClipped(clipResult);
    onClipCouponEvent?.(id);
    if (!clipResult) {
      setClipError(t('coupon.clipError'));
    } else {
      setClipError('');
    }
  };

  const [clipCoupon] = useClipCoupon();

  const coupon = cx(styles.coupon, styles[size], {
    [styles.full_width]: fullWidth,
    [styles.selected]: isClipped,
    [styles.failed]: !!errorMessage,
    [styles.highlighted]: highlighted,
    [styles.v2]: isV2,
  });

  const arrow = cx([styles.arrow, styles[size]]);
  const infoText = (includeSelected?: boolean) => {
    const toolTipText =
      (isClipped && includeSelected ? `selected ` : '') +
      (title ? `${t('coupon.title')} ${title} ` : '') +
      (expirationDate ? `${prompt}. ${expirationDate}` : prompt);
    return toolTipText;
  };

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isClipped) {
      return;
    }

    try {
      const response = await clipCoupon({ variables: { id } });
      handleClipCoupon(response.data.clipCoupon);

      const productEl = document.querySelector(`[data-product-id=${productId}]`);
      const productCardEl = productEl?.querySelector('.product_tile_card');
      const focusedProductEl = productCardEl?.querySelector(
        '[data-qa="tile_product_name_link"]',
      ) as HTMLAnchorElement;
      if (focusedProductEl) {
        focusedProductEl.focus();
      }
    } catch (error) {
      setClipError(t('coupon.clipError'));
    }
  };

  const hasInfoText = !!infoText();

  const handleInfoIconClick: MouseEventHandler<HTMLButtonElement> = (e) => e.stopPropagation();

  useEffect(() => {
    setClipError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (clippedCouponId === id) {
      setIsClipped(true);
    }
  }, [clippedCouponId]);

  return (
    <div className={cx(styles.container, className)}>
      <Tooltip
        componentsProps={{
          arrow: { className: arrow },
          tooltip: { className: styles.tooltip },
        }}
        PopperProps={{
          disablePortal: true,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [10, 0],
              },
            },
          ],
        }}
        title={<span dangerouslySetInnerHTML={{ __html: infoText() }}></span>}
        onClick={(e) => e.stopPropagation()}
        placement="bottom-end"
        enterTouchDelay={0}
        arrow
        describeChild
      >
        <button
          onClick={handleClick}
          className={coupon}
          aria-disabled={isClipped}
          aria-label={infoText(true)}
        >
          <FakeCheckbox className={styles.checkbox} checked={isClipped} />
          <span className={styles.title}>{t('coupon.title')}</span>
          {isClipped && (
            <Typography component="span" sx={visuallyHidden}>
              selected
            </Typography>
          )}
          {title && <span className={styles.price}>{title}</span>}
          {hasInfoText && (
            <Info
              onClick={handleInfoIconClick}
              className={styles.info_icon}
              {...iconSize[isV2 ? 'v2' : size]}
              aria-hidden={true}
              role="presentation"
            />
          )}
        </button>
      </Tooltip>
      <div role="alert">
        {!isCartTileUpdating && clipError && <span className={styles.error}>{clipError}</span>}
      </div>
    </div>
  );
};
