import { useQuery } from '@apollo/client';
import { productAdapter } from '@adapters/productAdapter';
import { GET_PRODUCT_BY_ID } from '@graphql/product/queries/singleProduct';
import { Product } from '@commons/product';

type UseVariationItemType = (showedVariationItemId: string | undefined) => Product;

// todo: may be we can join positions from here with Bundle type in Product to 1 request
export const useVariationItem: UseVariationItemType = (showedVariationItemId) => {
  const { data, error } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { productId: showedVariationItemId },
    skip: !showedVariationItemId,
  });

  if (error) throw error;

  return (
    data && {
      ...productAdapter().getProduct(data.product),
      extraDetails: productAdapter().getProductExtraDetails(data.product).extraDetails,
    }
  );
};
