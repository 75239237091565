import { gql } from '@apollo/client';
import { SEODATA_FRAGMENT } from '@graphql/fragments/common/seoDataFragment';

export const GET_SEODATA = gql`
  query SeoData($pageUri: ID!) {
    seoData(pageUri: $pageUri) {
      ...seoDataFragment
    }
  }
  ${SEODATA_FRAGMENT}
`;
