import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import cx from 'classnames';
import { Order, ORDER_STATUS } from '@commons/order';
import { ModifyOrderButton } from '@components/ModifyOrderButton/ModifyOrderButton';
import { OrderCard } from '@components/OrderCard/OrderCard';
import { BaseLink, Button } from '@components/UI';
import { useOrderModalState } from '@graphql/variables/orderModalStateVar';
import { useCancelModifyOrder } from '@hooks/account/useCancelModifyOrder';
import { useModal } from '@hooks/common';
import { useReorderLinkWithFilter } from '@hooks/reorder/useReorderLinkWithFilter';
import { useLocalization } from '@hooks/useLocalization';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getCurrencySymbol } from '@utils/productHelper';
import { getTrackingOrderUrl } from '@utils/getTrackingOrderUrl';
import { ORDER_MODAL_STATE } from '@constants/orderModalState';
import { getDate } from '@utils/getDate';
import { getDateIntervalWithDay } from '@helpers/getDateIntervalWithDay';
import { CancelConfirmationModal } from '../CancelConfirmationModal/CancelConfirmationModal';
import styles from './ActiveOrderCard.module.scss';

export interface CancelOrderErrors {
  description: string;
  type: string;
}

interface ActiveOrderCardProps {
  order: Order;
  className?: string;
  modifyingOrderId?: string | null;
  onCancel: (orderId: string) => void;
}

export const ActiveOrderCard = ({
  order,
  className,
  modifyingOrderId,
  onCancel,
  ...rest
}: ActiveOrderCardProps) => {
  const { t } = useLocalization('account');
  const [cancelModify] = useCancelModifyOrder();
  const { setOrderModalState } = useOrderModalState();
  const reorderLink = useReorderLinkWithFilter(order);
  const cancelConfirmationModal = useModal();
  const getTotalSum = () => getCurrencySymbol() + order.orderTotal;
  const isOrderModifying = modifyingOrderId && modifyingOrderId === order.orderId;
  const cancellable =
    order.orderStatus === ORDER_STATUS.CONFIRMED ||
    order.orderStatus === ORDER_STATUS.PENDING_CANCELLATION;
  const { isModifiable, isTrackable } = order.orderState;
  const orderStatusText =
    order.orderStatus === ORDER_STATUS.MODIFIED
      ? `orderHistory.status.IN_PROGRESS`
      : `orderHistory.status.${order.orderStatus}`;
  const handleCancelModify = () => {
    cancelModify({
      onCompleted: () => {
        setOrderModalState(ORDER_MODAL_STATE.PENDING);
      },
    });
    sendGtmEvent(
      GtmCustomEvent({
        /* eslint-disable @typescript-eslint/naming-convention */
        event: 'modify-click',
        event_name: 'modify_cancel_changes',
        ua_category: 'modify',
        ua_action: 'cancel changes',
        ua_label: 'order history cancel changes',
        /* eslint-enable @typescript-eslint/naming-convention */
      }),
    );
  };

  const handleOpenModal = () => {
    cancelConfirmationModal.openModal();
    sendGtmEvent(
      GtmCustomEvent({
        /* eslint-disable @typescript-eslint/naming-convention */
        event: 'cancelled-order',
        event_name: 'cancelled_order',
        ua_category: 'Orders',
        ua_action: 'cancelled-order',
        ua_label: `${order.orderId}`,
        /* eslint-enable @typescript-eslint/naming-convention */
      }),
    );
  };
  return (
    <>
      <OrderCard
        {...rest}
        aria-label={t('orderHistory.activeOrderCard')}
        className={cx(className, styles.active_order_card)}
      >
        <div className={styles.info}>
          <div className={styles.top_line}>
            {order.orderStatus && (
              <Typography component="span" variant="body" className={styles.status}>
                {t(orderStatusText)}
              </Typography>
            )}
            <Typography component="span" variant="body" className={styles.order}>
              {!!order.soTemplateName
                ? order.soTemplateName
                : t('orderHistory.order.orderNumber', { orderId: order.orderId })}
            </Typography>
          </div>
          <div className={styles.details}>
            {order.requestedDate && (
              <Typography
                data-testid={'requestedDate'}
                component="strong"
                variant="body"
                className={cx(styles.details_item, styles.bold)}
              >
                {getDate(order.requestedDate, 'M/D/YYYY')}
              </Typography>
            )}
            {order.orderTotal && (
              <Typography
                component="strong"
                variant="body"
                className={cx(styles.details_item, styles.bold)}
              >
                {t('orderHistory.order.totalSum', { sum: getTotalSum() })}
              </Typography>
            )}
            {!!order.deliveryEnd && !!order.deliveryStart && (
              <Typography
                data-testid={'deliveryTime'}
                component="span"
                variant="body"
                className={styles.details_item}
              >
                {getDateIntervalWithDay(order.deliveryStart, order.deliveryEnd, t, true)}
              </Typography>
            )}
          </div>
        </div>
        <div className={styles.buttons_wrapper}>
          {reorderLink && (
            <BaseLink passHref href={reorderLink}>
              <Button isFullWidth className={styles.button}>
                {t('orderHistory.order_actions.reorder')}
                <Typography component="span" variant="body" sx={visuallyHidden}>
                  {t('orderHistory.order.orderNumber', { orderId: order.orderId })}
                </Typography>
              </Button>
            </BaseLink>
          )}
          {isOrderModifying && (
            <Button className={styles.button} onClick={handleCancelModify} isFullWidth>
              {t('orderHistory.order_actions.cancelChanges')}
              <Typography component="span" variant="body" sx={visuallyHidden}>
                {t('orderHistory.order.orderNumber', { orderId: order.orderId })}
              </Typography>
            </Button>
          )}
          {!modifyingOrderId && isModifiable && (
            <ModifyOrderButton
              className={styles.button}
              orderId={order.orderId}
              isExpressOrder={order.isExpressOrder}
              isFullWidth
            />
          )}
          {!isOrderModifying && (
            <BaseLink passHref href={getTrackingOrderUrl(order.orderId, isTrackable)}>
              <Button
                data-testid="order_details_button"
                className={styles.button}
                variant="outlined"
                isFullWidth
              >
                {t('orderHistory.order_actions.details')}
                <Typography component="span" variant="body" sx={visuallyHidden}>
                  {t('orderHistory.order.orderNumber', { orderId: order.orderId })}
                </Typography>
              </Button>
            </BaseLink>
          )}
          {cancellable && (
            <Button
              className={styles.button}
              variant="outlined"
              isFullWidth
              onClick={handleOpenModal}
            >
              {t('orderHistory.order_actions.cancel')}
              <Typography component="span" variant="body" sx={visuallyHidden}>
                {t('orderHistory.order.orderNumber', { orderId: order.orderId })}
              </Typography>
            </Button>
          )}
        </div>
      </OrderCard>
      <CancelConfirmationModal
        opened={cancelConfirmationModal.isOpen}
        onClose={cancelConfirmationModal.closeModal}
        onCancel={onCancel}
        orderId={order.orderId}
      />
    </>
  );
};
