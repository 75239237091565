import { QueryResult, useQuery, QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';
import { MINIMUM_ACCOUNT_PREFERENCES } from '@graphql/account/queries/minimumAccountPreferences';
import { cartAdapter } from '@adapters/cartAdapter';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import {
  AccountPreferencesQuery,
  GetLightCartQuery,
  GetMasqueradeCartLightQuery,
  GetLightCartQueryVariables,
  GetMasqueradeCartLightQueryVariables,
  LightweightCart as CartLightAPI,
} from '@api';
import { useLazyGetMasqueradeCartLight } from './useLazyGetMasqueradeCartLight';

interface LightCartHookOptions {
  lightCartOptions?: QueryHookOptions<GetLightCartQuery, GetLightCartQueryVariables>;
  masqueradeLightCart?: QueryHookOptions<
    GetMasqueradeCartLightQuery,
    GetMasqueradeCartLightQueryVariables
  >;
}

const { getLightCart } = cartAdapter();

export const useLightCart = (options?: LightCartHookOptions) => {
  const [fetchMasqueradeLightCart, masqueradeLightCartData] = useLazyGetMasqueradeCartLight(
    options?.masqueradeLightCart,
  );
  const lightCartData = useQuery<GetLightCartQuery, GetLightCartQueryVariables>(GET_LIGHT_CART, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    ssr: false,
  });

  const { data: accountPreferencesData, loading: accPrefLoading } =
    useQuery<AccountPreferencesQuery>(MINIMUM_ACCOUNT_PREFERENCES, {
      ssr: false,
      onCompleted: ({ accountPreferences }) => {
        if (accountPreferences?.isMasqueradeMode) {
          fetchMasqueradeLightCart();
        }
      },
    });
  const {
    data,
    previousData,
    loading: cartLoading,
    ...queryResult
  }: QueryResult<
    GetLightCartQuery | GetMasqueradeCartLightQuery,
    GetLightCartQueryVariables | GetMasqueradeCartLightQueryVariables
  > = accountPreferencesData?.accountPreferences?.isMasqueradeMode
    ? masqueradeLightCartData
    : lightCartData;

  const cart = useMemo(
    () => getLightCart(data?.lightweightCart as DeepPartial<CartLightAPI>),
    [data?.lightweightCart],
  );
  const previousCart = useMemo(
    () => getLightCart(previousData?.lightweightCart as DeepPartial<CartLightAPI>),
    [previousData?.lightweightCart],
  );
  const isEmpty = cart.cartLinesCount === 0;

  return {
    ...queryResult,
    loading: accPrefLoading || cartLoading,
    rawData: data,
    data: cart,
    previousData: previousCart,
    isEmpty,
  };
};

export const useLightCartCache = () => {
  const { data, previousData, ...queryResult } = useQuery(GET_LIGHT_CART, {
    fetchPolicy: 'cache-only',
  });

  const cart = useMemo(
    () => getLightCart(data?.lightweightCart as DeepPartial<CartLightAPI>),
    [data?.lightweightCart],
  );
  const previousCart = useMemo(
    () => getLightCart(previousData?.lightweightCart as DeepPartial<CartLightAPI>),
    [previousData?.lightweightCart],
  );
  const isEmpty = cart.cartLinesCount === 0;

  return {
    ...queryResult,
    rawData: data,
    data: cart,
    previousData: previousCart,
    isEmpty,
  };
};
