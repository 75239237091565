import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const RECEIVED_GIFT_CARD_FRAGMENT = gql`
  fragment receivedGiftCardFragment on ReceivedGiftCard {
    id
    senderName
    purchaseDate
    initialAmount {
      ...priceFragment
    }
    currentAmount {
      ...priceFragment
    }
    redeemable
    applied
  }
  ${PRICE_FRAGMENT}
`;
