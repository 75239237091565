import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export interface AppRedirectClickEventOptions {
  redirect: string;
}

export interface AppRedirectDisplayEventOptions {
  isVisible: boolean;
}

export const appRedirectClickEventHandler = ({ redirect }: AppRedirectClickEventOptions) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'appRedirectClick',
      event_name: 'app_redirect_click',
      ua_action: 'App Redirect Click',
      redirect: redirect,
    }),
  );
};

export const appRedirectDisplayEventHandler = ({ isVisible }: AppRedirectDisplayEventOptions) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'appRedirect',
      event_name: 'app_redirect',
      ua_action: 'App Redirect',
      app_redirect_visible: isVisible.toString(),
    }),
  );
};
