import { IconType } from '@commons/icon';
import styles from './ButtonBaseIcon.module.scss';

interface ButtonBaseIconProps {
  icon: IconType;
  height?: number;
  width?: number;
}

export const ButtonBaseIcon = ({ icon: Icon, height = 16, width = 16 }: ButtonBaseIconProps) => {
  return (
    <span className={styles.icon_container}>
      <Icon height={height} width={width} />
    </span>
  );
};
