import { useMutation } from '@apollo/client';
import { DELETE_DELIVERY_ADDRESS_V2 } from '@graphql/account/mutations/deleteDeliveryAddress';
import { updateDeliveryAddressesCache } from './updateDeliveryAddressesCache';

export const useDeleteDeliveryAddress = () => {
  return useMutation(DELETE_DELIVERY_ADDRESS_V2, {
    update(cache, { data: { deleteDeliveryAddressV2: addresses } }) {
      updateDeliveryAddressesCache(cache, addresses);
    },
  });
};
