import { MinimumAccountPreferencesQuery } from '@api';
import { AuthUser } from '@modules/auth/types';

export const getUserFromMinimumAccountPreferences = (
  data: MinimumAccountPreferencesQuery,
): AuthUser => ({
  firstName: data?.accountPreferences?.firstName,
  lastName: data?.accountPreferences?.lastName,
  email: data?.accountPreferences?.emailAddress,
  memberSinceYear: data?.accountPreferences?.memberSinceYear?.toString(),
  phoneNumber: data?.accountPreferences?.phoneNumber,
  statusLevel: data?.accountPreferences?.statusLevel,
  isMasqueradeMode: data?.accountPreferences?.isMasqueradeMode,
  isEligibleForPreReservation: data?.accountPreferences?.isEligibleForPreReservation,
});
