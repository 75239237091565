import { gql } from '@apollo/client';
import { PRODUCT_TILE_FRAGMENT } from '@graphql/fragments/product/productTileFragment';

export const GET_RECOMMENDATION_CAROUSEL = gql`
  query GetRecommendedCarousel {
    cartForCheckout {
      recommendationCarousel {
        products {
          ...productTileFragment
        }
      }
    }
  }
  ${PRODUCT_TILE_FRAGMENT}
`;
