import cx from 'classnames';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useModal } from '@hooks/common';
import { BACKGROUND_MODE } from '@commons/modals';
import { EditPaymentModalWrapper } from '@features/AccountPage/components/AccountPagePayments/components/EditPaymentModalContent/EditPaymentModalWrapper';
import { PaymentsType } from '@commons/payment';
import { UITheme } from '@commons/uiTheme';
import styles from './EditPaymentMethodButton.module.scss';

interface EditPaymentMethodButtonProps {
  id: string;
  type?: PaymentsType;
  accountNumber?: string | null;
  uiTheme?: UITheme;
  noMargin?: boolean;
}

export const EditPaymentMethodButton = ({
  id,
  type,
  accountNumber,
  uiTheme = 'light',
  noMargin = false,
}: EditPaymentMethodButtonProps) => {
  const { t } = useLocalization('checkout');
  const editPaymentMethod = useModal();
  return (
    <>
      <Button
        mode={uiTheme}
        className={cx(styles.edit_button, { [styles.no_margin]: noMargin })}
        onClick={editPaymentMethod.openModal}
        variant="underline"
        aria-label={`${t('account:payments.editPaymentMethodLabel')} ${type} ${accountNumber}`}
      >
        {t('editButton')}
      </Button>
      {editPaymentMethod.isOpen && (
        <EditPaymentModalWrapper
          backgroundMode={BACKGROUND_MODE.TRANSPARENT}
          closeModal={editPaymentMethod.closeModal}
          type={type}
          id={id}
          opened={editPaymentMethod.isOpen}
        />
      )}
    </>
  );
};
