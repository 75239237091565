import { Product, ProductTile, ReorderProduct } from '@commons/product';

export const getProductKey = (product: Product | ReorderProduct | ProductTile) => {
  if ('itemId' in product && product.itemId) {
    return product.itemId;
  }
  if (product.listItemLineId) {
    return product.listItemLineId;
  }
  return product.productId;
};
