import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { Link } from '@components/UI';
import styles from './RecaptchaDisclaimer.module.scss';

export const RecaptchaDisclaimer = () => {
  return (
    <Typography
      className={styles.recaptcha}
      component="p"
      variant="smallBody"
      data-qa="recaptcha_description"
    >
      <Trans
        i18nKey="auth:recaptcha"
        components={{
          privacyPolicy: (
            <Link
              className={styles.link}
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            />
          ),
          termsOfService: (
            <Link
              className={styles.link}
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noreferrer"
            />
          ),
        }}
      />
    </Typography>
  );
};
