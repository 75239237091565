import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';
import { DELIVERY_ADDRESS_RESULT_FRAGMENT } from '@graphql/fragments/account/deliveryAddressResult';

export const EDIT_DELIVERY_ADDRESS = gql`
  mutation EditDeliveryAddress(
    $id: ID!
    $input: DeliveryAddressInput!
    $standingOrderMode: StandingOrderMode
  ) {
    editDeliveryAddress(id: $id, input: $input, standingOrderMode: $standingOrderMode) {
      isUnattendedDelivery
      validationErrors {
        ...validationErrorFragment
      }
      actionResult {
        ...actionResultFragment
      }
      deliveryAddresses {
        ...deliveryAddressResultFragment
      }
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
  ${DELIVERY_ADDRESS_RESULT_FRAGMENT}
`;
