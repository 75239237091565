import { gql } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

export const LAST_DELIVERED_ORDERS = gql`
  query LastDeliveredOrders {
    lastDeliveredOrders {
      orderId
    }
  }
`;

export const LAST_DELIVERED_ORDERS_OPERATION_NAME = getOperationName(LAST_DELIVERED_ORDERS);
