import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from '../../common/priceFragment';

export const GIFT_CARD_FRAGMENT = gql`
  fragment giftCardFragment on GiftCard {
    amount {
      ...priceFragment
    }
    gcTemplate {
      gcTemplateId
      gcTemplateTitle
      gcTemplateLabel
      gcTemplateImageLink
    }
    senderName
    senderEmail
    recipientName
    recipientEmail
    deliveryMethod
    message
    id
    displayPrice {
      currency
      value
      formattedPrice
      properties {
        type
        value
      }
    }
    isBulk
    quantity
  }
  ${PRICE_FRAGMENT}
`;
