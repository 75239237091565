import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { clearDataLayerBuffer } from '@modules/ga/gtm-event';
import { getPagePathname, PageException } from '@modules/ga/hooks/useResetGtm/useResetGtm.helper';

export const useResetGtm = (pagesException: Array<PageException>) => {
  const router = useRouter();

  const trackResetGtm = useCallback(
    (url: string) => {
      if (!window.dataLayer) return;

      const currenUrl = new URL(url, window.location.origin);
      const currentPathname = getPagePathname(currenUrl.pathname, pagesException);
      const prevPathname = getPagePathname(window.location.pathname, pagesException);

      if (currentPathname === prevPathname) return;

      window.dataLayer.push(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.reset();
      });
      window.dataLayer.length = 0;

      clearDataLayerBuffer();
    },
    [pagesException],
  );

  useEffect(() => {
    router.events.on('routeChangeStart', trackResetGtm);
    return () => {
      router.events.off('routeChangeStart', trackResetGtm);
    };
  }, [router.events, trackResetGtm]);
};
