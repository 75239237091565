import { Typography } from '@mui/material';
import { warning as Warning } from '@assets/icons/system';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useModal } from '@hooks/common';
import { CouponModal } from './CouponModal';
import styles from './CouponModalContainer.module.scss';

export const CouponModalContainer = () => {
  const { t } = useLocalization('cart');
  const couponModal = useModal();

  return (
    <div className={styles.container}>
      <Warning width={12} height={12} className={styles.icon} />
      <div>
        <Typography className={styles.error_message} variant="body">
          {t('coupon.notApplied')}
        </Typography>{' '}
        <Button
          variant="underline"
          onClick={couponModal.openModal}
          className={styles.error_button}
          aria-label={t('coupon.learnMoreAriaText')}
        >
          {t('coupon.learnMore')}
        </Button>
      </div>
      <CouponModal isOpen={couponModal.isOpen} onClose={couponModal.closeModal} />
    </div>
  );
};
