import { RefObject, useEffect, useRef } from 'react';
import { fireViewItemListGAEvent } from '@modules/ga/events/ecommerce/view-item-list/viewItemListCreator';
import { ProductTile } from '@commons/product';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductPositionContext } from '@modules/ga/context/position';

interface TrackViewItemListProps {
  productRef: RefObject<HTMLElement>;
  product: ProductTile;
  listName: string;
}

export const TrackViewItemList = ({ product, listName, productRef }: TrackViewItemListProps) => {
  const showed = useRef(false);
  const { getListName } = usePageListName();
  const { setProductPositions } = useProductPositionContext();

  /* eslint-disable @typescript-eslint/naming-convention */
  useEffect(() => {
    const { width, height } = productRef.current?.getBoundingClientRect() ?? {};
    if (product.productId && width && height && !showed.current) {
      showed.current = true;
      fireViewItemListGAEvent(
        {
          product,
          list_name: listName,
          item_list_name: getListName(product),
        },
        setProductPositions,
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productRef]);
  /* eslint-enable @typescript-eslint/naming-convention */

  return <></>;
};
