/* eslint-disable @typescript-eslint/naming-convention */
import { Product, ProductTile } from '@commons/product';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { CustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import {
  EcommerceEvent,
  getProductMetrics,
  ProductMetrics,
} from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { CartLineLightWithGA } from '@commons/cart';

export interface RemoveFromCartOptions {
  products: ((Product | ProductTile | CartLineLightWithGA) & {
    itemPosition?: number;
    itemListName?: string;
  })[];
  cartPosition: string;
  customer: CustomerData;
  breadcrumb: string;
  itemListName?: string;
  itemPosition?: number;
}

interface RemoveFromCartEvent extends EcommerceEvent {
  cart_position?: string;
  breadcrumb: string;
  ecommerce: {
    currencyCode: string;
    remove: {
      products: ProductMetrics[];
    };
  };
}

export const getRemoveFromCartEvent = ({
  products,
  cartPosition,
  customer,
  breadcrumb,
  itemListName,
  itemPosition,
}: RemoveFromCartOptions): RemoveFromCartEvent => {
  return {
    event: 'removeFromCart',
    event_name: 'remove_from_cart',
    cart_position: cartPosition,
    customer: {
      deliverypass_type: customer.deliveryPassType,
      deliverypass_term: customer.deliveryPassTerm,
      express_eligible: customer.expressEligible,
    },
    ua_category: 'Ecommerce Action',
    ua_action: 'Remove From Cart',
    breadcrumb,
    ecommerce: {
      currencyCode: getCurrencyCodeFromSymbol(products?.[0]?.price?.currency),
      remove: {
        products: products.map((product) =>
          getProductMetrics({
            ...product,
            position: itemPosition ?? product.itemPosition,
            listName: itemListName ?? product.itemListName,
          }),
        ),
      },
    },
  };
};
