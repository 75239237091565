import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Button, Link } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { CART_MODAL_TYPES } from '@components/OrderFooter/types';
import { useModal } from '@hooks/common';
import { LegalOverlay } from '@components/Modals/LegalOverlay/LegalOverlay';
import { getTwoLinesDescription } from './CheckboxDisclaimer.utils';
import styles from './CheckboxDisclaimer.module.scss';

interface CheckboxDisclaimerProps {
  description: string;
  phone?: string;
  ariaId?: string;
  isModalDescription?: boolean;
  displayFullDescriptionText?: boolean;
}

export const CheckboxDisclaimer = ({
  description,
  phone,
  ariaId,
  isModalDescription = false,
  displayFullDescriptionText,
}: CheckboxDisclaimerProps) => {
  const { t } = useLocalization('account');
  const [modalType, setModalType] = useState<CART_MODAL_TYPES>(CART_MODAL_TYPES.EMPTY);
  const [fullDescriptionShow, setFullDescriptionShow] = useState(
    isModalDescription || displayFullDescriptionText,
  );
  const { openModal, closeModal, isOpen } = useModal();

  const handleModalClick = (type: CART_MODAL_TYPES) => {
    setModalType(type);
    openModal();
  };
  const [ariaLabel, setAriaLabel] = useState('');

  useEffect(() => {
    const baseKey = ariaId === 'smsOffers' ? 'smsOffers' : 'smsDeliveryUpdates';
    const actionKey = fullDescriptionShow ? 'showLess' : 'showMore';
    const ariaLabelText = t(`common:buttons.${actionKey}.${baseKey}`);
    setAriaLabel(ariaLabelText);
  }, [fullDescriptionShow, ariaId, t]);

  const handleDescriptionToggle = () => {
    setFullDescriptionShow(!fullDescriptionShow);
  };

  return (
    <>
      <Typography variant="smallBody" component="p" className={styles.description}>
        {fullDescriptionShow ? (
          <>
            {description}
            {phone && (
              <Link href={`tel:${phone}`} classNameLinkText={styles.phone_wrapper}>
                <span className={styles.phone}>{phone}.</span>
              </Link>
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              href="#"
              className={styles.link_wrapper}
              onClick={() => handleModalClick(CART_MODAL_TYPES.TERMS_SMS)}
            >
              {t('disclaimer.terms')}
            </Link>
            <Typography className={styles.and_sign} variant="smallBody">
              {t('disclaimer.and')}
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              href="#"
              className={styles.link_wrapper}
              onClick={() => handleModalClick(CART_MODAL_TYPES.PRIVACY_SMS)}
            >
              {t('disclaimer.privacy')}
            </Link>
          </>
        ) : (
          <>{getTwoLinesDescription(description)}</>
        )}
      </Typography>
      {!isModalDescription && (
        <Button
          className={styles.show_btn}
          variant="underline"
          onClick={handleDescriptionToggle}
          aria-describedby={ariaId}
          aria-label={ariaLabel}
        >
          {fullDescriptionShow ? t('disclaimer.showLess') : t('disclaimer.showMore')}
        </Button>
      )}
      <ModalWindow
        open={isOpen}
        onClose={closeModal}
        wrapperClassName={styles.modal_wrapper}
        isScrollable
      >
        <LegalOverlay type={modalType} handleClose={closeModal} />
      </ModalWindow>
    </>
  );
};
