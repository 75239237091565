import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import styles from './ProductTileSkeleton.module.scss';

export const ProductTileSkeleton = () => {
  return (
    <Stack spacing={1} className={styles.tile}>
      <Skeleton variant="rectangular" width={120} height={120} className={styles.image} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" width={162} />
      <Skeleton variant="text" width={110} />
      <Skeleton variant="text" width={63} />
    </Stack>
  );
};
