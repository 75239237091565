import { VariationItemProductData } from '@api';

export enum MODAL_TYPE {
  PRODUCT = 'product',
  CART = 'cart',
  CART_MINI_PDP = 'cart_mini_pdp',
  CART_EDIT = 'cart_edit',
  ADD_TO_STANDING_ORDER = 'ADD_TO_STANDING_ORDER',
}

export interface PreparedDropdownValue {
  label: string | null;
  name: string | null;
  cvp?: string | null;
  selected: boolean | null;
  imagePath?: string | null;
  value?: string;
  index?: number;
  variationItemProductData?: VariationItemProductData;
}

export interface PreparedDropdown {
  name: string | null;
  optional: boolean;
  selectedValue: string | null;
  hasError: boolean;
  isOpen?: boolean;
  values: PreparedDropdownValue[];
  label?: string | null;
  underLabel?: string | null;
  descrMedia?: string | null;
  variationItemProductData?: VariationItemProductData;
}
