import { useApolloClient } from '@apollo/client';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { LightweightCart } from '@api';
import { Params, ParamsContext } from '@context/AddToCartContext/AddToCartContext.utils';

export const useLightCartOptimisticResponse = () => {
  const client = useApolloClient();

  const getAddToCartLightOptimisticResponse = (params: {
    product: Params['product'];
    quantity: Params['quantity'];
    salesUnit: Params['salesUnit'];
    paramsContext: ParamsContext;
  }) => {
    const { product, quantity, salesUnit, paramsContext } = params;

    const lightCart = client.readQuery({
      query: GET_LIGHT_CART,
    });

    const newCartLines = [...(lightCart?.lightweightCart.cartLines || [])];

    newCartLines.push({
      __typename: 'LightweightCartLine',
      id: product.productId,
      skuCode: product.skuCode ?? '',
      productId: product.productId ?? '',
      selectedSalesUnit: salesUnit || product.salesUnits[0].alternateSalesUnit || '',
      quantitySelector: {
        __typename: 'ProductQuantity',
        maxQuantity: product.quantity.maxQuantity,
        minQuantity: product.quantity.minQuantity,
        quantity: quantity,
        quantityIncrement: product.quantity.quantityIncrement,
      },
      displayPrice: {
        __typename: 'Price',
        currency: product.price.currency,
        value: product.price.value,
        formattedPrice: product.price.formattedPrice,
        properties: [],
      },
      isNewItem: false,
      isRecent: true,
      itemListName: paramsContext.listName,
      itemPosition: paramsContext.itemPosition,
      categoryId: product.categoryId || '',
      expressEligible: false,
      coupon: product.coupon,
      discount: {
        __typename: 'Discount',
        amount: {
          __typename: 'Price',
          currency: null,
          value: product.discountAmount,
          formattedPrice: null,
          properties: [],
        },
      },
    });

    const optimisticCart: LightweightCart = {
      __typename: 'LightweightCart',
      ...lightCart?.lightweightCart,
      cartLines: newCartLines,
      productCounter: [
        ...(lightCart?.lightweightCart?.productCounter ?? []),
        {
          count: quantity,
          productId: product.productId,
          __typename: 'CartProductCounter',
        },
      ],
      cartLinesCount: newCartLines.length,
    };

    return {
      __typename: 'Mutation',
      addToCartLight: optimisticCart,
    };
  };

  return { getAddToCartLightOptimisticResponse };
};
