import { getCookie } from 'cookies-next';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { COOKIES_LIST } from '@commons/cookies';
import { IMiddlewareFilter } from './middleware-filter';
import { MiddlewareFilterScope } from './middleware-filter-scope';

const serviceTypeSearchParamKey = 'serviceType';

export const serviceTypeHeaderKey = 'X-SERVICE-TYPE';

export const getServiceType = (ctx: NextPageContext | GetServerSidePropsContext) => {
  if (ctx?.query?.hasOwnProperty(serviceTypeSearchParamKey)) {
    return ctx.query[serviceTypeSearchParamKey] as string;
  }
  return getCookie(COOKIES_LIST.SERVICE_TYPE, ctx);
};

export const applyServiceType: IMiddlewareFilter = async (oldScope) => {
  const requestUrl = new URL(oldScope.requestUrl);
  const serviceType = requestUrl.searchParams.get(serviceTypeSearchParamKey);
  const responseHeaders = new Headers(oldScope.responseHeaders);
  if (serviceType) {
    responseHeaders.set(serviceTypeHeaderKey, serviceType);
  }
  return MiddlewareFilterScope({ ...oldScope, requestUrl, responseHeaders });
};
