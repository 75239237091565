import { DeliveryAddress, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { FIELDS } from './useEbtTabContent';

export const getBillingAddress = (deliveryAddress: DeliveryAddress | undefined) => ({
  address1: deliveryAddress?.address?.address1,
  apartment: deliveryAddress?.address?.apartment,
  city: deliveryAddress?.address?.city,
  state: deliveryAddress?.address?.state,
  country: deliveryAddress?.address?.country,
  zipCode: deliveryAddress?.address?.zipCode,
  serviceType: deliveryAddress?.address?.serviceType,
});

export const getNewBillingAddress = (values: { [key in FIELDS]: string }) => ({
  address1: values.new_billing_address,
  apartment: values.apartment,
  city: values.city,
  country: values.country,
  state: values.state,
  zipCode: values.zip_code,
  serviceType: SERVICE_TYPE.HOME,
});
