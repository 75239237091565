import { useEffect, useState } from 'react';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLocalization } from '@hooks/useLocalization';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ALERT_TYPES } from '@commons/account';
import { Grid } from '@components/UI/Grid/Grid';
import { BACKGROUND_MODE } from '@commons/modals';
import { PaymentsType } from '@commons/payment';
import { PaymentsAddTabs } from '@components/PaymentsAddTabs/PaymentsAddTabs';
import { Payment } from '@api';
import styles from './AddPaymentsModal.module.scss';

interface AddPaymentsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  showPaypal: boolean;
  showBankAccount: boolean;
  showVenmo: boolean;
  showEBT: boolean;
  backgroundMode?: BACKGROUND_MODE;
  onSuccess?: (payment?: Payment) => void;
}

const DEFAULT_ERROR_MESSAGE_VALUE = '';

export const AddPaymentsModal = ({
  isOpen,
  closeModal,
  showPaypal = false,
  showBankAccount = false,
  showVenmo = false,
  showEBT = false,
  backgroundMode,
  onSuccess = () => void 0,
}: AddPaymentsModalProps) => {
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE_VALUE);
  const { t } = useLocalization('account');
  const { dispatchAlert } = useAccountAlertContext();

  const handleSuccess = (_: PaymentsType, message = '', payment?: Payment) => {
    onSuccess(payment);
    dispatchAlert(ALERT_TYPES.SUCCESS, message);
    closeModal();
  };

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE_VALUE);
    }
  }, [isOpen]);

  return (
    <ModalWindow
      className={styles.payment_modal}
      title={t('payments.addPaymentModalTitle')}
      open={isOpen}
      errorMessage={errorMessage}
      onClose={closeModal}
      backgroundMode={backgroundMode}
      isScrollable
    >
      <Grid container className={styles.payments} columns={{ xs: 4, sm: 6 }}>
        <Grid item xs={4} sm={6}>
          <PaymentsAddTabs
            showBankAccount={showBankAccount}
            showPaypal={showPaypal}
            showVenmo={showVenmo}
            showEBT={showEBT}
            onSuccess={handleSuccess}
            onError={setErrorMessage}
            onClose={closeModal}
            showErrorMessage={false}
          />
        </Grid>
      </Grid>
    </ModalWindow>
  );
};
