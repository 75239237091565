import cx from 'classnames';
import { StandingOrderButton } from '@components/StandingOrderButton/StandingOrderButton';
import { Product, ProductTile } from '@commons/product';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import styles from './TileStandingOrderButton.module.scss';

interface TileStandingOrderButtonProps {
  hidden?: boolean;
  product: Product | ProductTile;
  isV2?: boolean;
  className?: string;
}

export const TileStandingOrderButton = ({
  product,
  hidden,
  isV2 = false,
  className,
}: TileStandingOrderButtonProps) => {
  const { getFeature } = useHeaderContext();
  const shouldRender = !!getFeature?.(FEATURE.STANDING_ORDERS) && !!getFeature?.(FEATURE.COS);

  return shouldRender ? (
    <StandingOrderButton
      product={product}
      className={cx(className, styles.standing_order_button)}
      hidden={hidden}
      includeWrapper
      isV2={isV2}
    />
  ) : null;
};
