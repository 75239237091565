import { gql } from '@apollo/client';

export const VALIDATE_REFERER = gql`
  query ValidateReferralPromotion($input: ReferralProgramValidationInput!) {
    validateReferralPromotion(input: $input) {
      valid
      refererName
      message
    }
  }
`;
