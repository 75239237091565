import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { ProductVariationModalProps } from '@modules/modals/types/productVariationModal/productVariationModal';

export const openProductVariationModal = (props: ProductVariationModalProps) => {
  fireOpenModal<ProductVariationModalProps>(MODALS_LIST.PRODUCT_VARIATION_MODAL, {
    ...props,
  });
};

export const closeProductVariationModal = () => {
  fireCloseModal(MODALS_LIST.PRODUCT_VARIATION_MODAL);
};
