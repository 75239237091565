import { BreadcrumbItem } from '@commons/breadcrumbs';

export const safeName = (text: string) =>
  text
    ?.toString()
    .toLowerCase()
    .replace(/[&,]+/g, '+') //Replace &, to +
    .replace(/[âéè.!$]+/g, '') // Remove âé.!_ chars
    .replace(/[\s:]+/g, '_') // Replace spaces with _
    .replace(/__+/g, '_') // Replace multiple _ with single _
    .replace(/^_+/, '') // Trim _ from start of text
    .replace(/_+$/, '') // Trim _ from the end of text
    .replace(/_*\+_*/g, '+') // Replace _+_ with +
    .replace(/_*-_*/g, '-'); // Replace _-_ with -

export const getBreadcrumbsTitleText = (breadcrumbs: BreadcrumbItem[]) =>
  safeName(breadcrumbs.map((b) => b.value).join('_'));

export const getBreadcrumbsString = (breadcrumbs?: BreadcrumbItem[]) =>
  breadcrumbs?.map((breadcrumb) => breadcrumb.value).join(' / ');
