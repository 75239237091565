import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import {
  getUserSignupSuccessEvent,
  GetUserSignupSuccessEventProps,
} from '@modules/ga/events/custom/user-signup-success/utils/getUserSignupSuccessEvent';

export const userSignupSuccessHandler = (data: GetUserSignupSuccessEventProps) => {
  sendGtmEvent(GtmCustomEvent(getUserSignupSuccessEvent(data)));
};
