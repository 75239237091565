import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { GiftCardDeliveryType } from '@api';
import { useAddGiftCardToCart } from '@hooks/giftCard/useAddGiftCardToCart';
import { useLocalization } from '@hooks/useLocalization';
import { useGiftCardModal } from '@hooks/giftCard/useGiftCardModal';
import { GiftCardPurchaseInfo } from '@commons/giftCards';
import { MAX_BULK_GIFT_CARD_QTY_AMOUNT } from '@utils/validation';
import { MODAL_TITLE_ID } from '../Modal';
import styles from './GiftCardModal.module.scss';
import { Values } from './types';
import { GiftCardForm } from './components/GiftCardForm/GiftCardForm';
import { BulkGiftCardForm } from './components/BulkGiftCardForm/BulkGiftCardForm';

interface AddGiftCardModalProps {
  open: boolean;
  onClose: () => void;
  data: GiftCardPurchaseInfo;
  loading: boolean;
  addBulkGiftCard?: boolean;
}

export const AddGiftCardModal = ({
  data,
  loading,
  open,
  onClose,
  addBulkGiftCard = false,
}: AddGiftCardModalProps) => {
  const { t } = useLocalization('giftCards');
  const [addRecipient] = useAddGiftCardToCart();

  const { isCustomAmountEditShown, formRef, handleBack, handleClose, handleOpen } =
    useGiftCardModal();
  const handleSubmit = (values: Values) => {
    addRecipient({
      variables: {
        input: {
          templateId: values.giftCardTemplateId,
          amount: (values.giftCardAmount || values.giftCardDefaultAmount) ?? '',
          deliveryMethod: values.giftCardType as GiftCardDeliveryType,
          recipientName: values.recipientName,
          recipientEmail: values.recipientEmail,
          senderName: values.senderName,
          message: values.recipientMessage ?? '',
          isBulk: false,
        },
      },
      onCompleted: onClose,
    });
  };

  const handleBulkGiftCardSubmit = (values: Values) => {
    addRecipient({
      variables: {
        input: {
          templateId: values.giftCardTemplateId,
          amount: (values.giftCardAmount || values.giftCardDefaultAmount) ?? '',
          deliveryMethod: values.giftCardType as GiftCardDeliveryType,
          quantity: Number(values.quantity),
          senderEmail: values.senderEmail,
          senderName: values.senderName,
          message: values.recipientMessage ?? '',
          recipientName: values.senderName,
          recipientEmail: values.senderEmail,
          isBulk: true,
        },
      },
      onCompleted: onClose,
      onError() {
        formRef.current?.setFieldError(
          'quantity',
          t('giftCardModal.errors.quantityMaxInput', { value: MAX_BULK_GIFT_CARD_QTY_AMOUNT }),
        );
      },
    });
  };

  return (
    <ModalWindow
      open={open}
      onClose={() => onClose()}
      className={styles.modal_wrapper}
      onBack={isCustomAmountEditShown ? handleBack : undefined}
      labelId={MODAL_TITLE_ID.CREATE_GIFTCARD_MODAL}
    >
      <>
        {!isCustomAmountEditShown && (
          <Typography
            variant="h3"
            component="h2"
            className={styles.title}
            id={MODAL_TITLE_ID.CREATE_GIFTCARD_MODAL}
          >
            {t('giftCardModal.createTitle')}
          </Typography>
        )}
        {addBulkGiftCard ? (
          <BulkGiftCardForm
            templateData={data}
            isCustomAmountEditShown={isCustomAmountEditShown}
            onOpenCustomAmountForm={handleOpen}
            onCloseCustomAmountForm={handleClose}
            onSubmit={handleBulkGiftCardSubmit}
            loading={loading}
            submitButtonText={'giftCardModal.createButton'}
            formRef={formRef}
          />
        ) : (
          <GiftCardForm
            templateData={data}
            isCustomAmountEditShown={isCustomAmountEditShown}
            onOpenCustomAmountForm={handleOpen}
            onCloseCustomAmountForm={handleClose}
            onSubmit={handleSubmit}
            loading={loading}
            submitButtonText={'giftCardModal.createButton'}
            formRef={formRef}
          />
        )}
      </>
    </ModalWindow>
  );
};
