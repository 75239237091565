import { useState } from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';
import { Link } from '@components/UI';
import { arrowDown as ArrowDown, arrowUp as ArrowUp } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { Chat, Email, Phones } from '@components/ContactUsActions';
import { routing } from '@constants/routing';
import { DATA_QA } from '@constants/dataQA';
import { ContactUsModalProvider } from '@context/contactUsModalContext';
import { ContactUsFormModalProvider } from '@context/ContactUsFormModalContext/ContactUsFormModalProvider';
import { Modal, MODAL_TITLE_ID } from '../Modal';
import { MainAction } from './components/MainActions/MainActions';
import styles from './ContactUsModal.module.scss';

interface ContactUsProps {
  requestCredit?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
}

export const ContactUsModal = ({
  requestCredit = false,
  onClose,
  onBack,
  isOpen,
}: ContactUsProps) => {
  const { t } = useLocalization();
  const secondaryActionsClasses = cx(styles.secondary_actions, {
    [styles.request_credit_secondary]: requestCredit,
  });
  const [showMore, setShowMore] = useState(false);

  const renderArrow = () => {
    return showMore ? (
      <ArrowUp className={styles.arrow} width={12} height={6} />
    ) : (
      <ArrowDown className={styles.arrow} width={12} height={6} />
    );
  };

  return (
    <Modal
      handleClose={onClose}
      handleBack={onBack}
      labelId={MODAL_TITLE_ID.CONTACT_US}
      dataQA={DATA_QA.CONTACT_US_MODAL}
      isShowLogo={false}
      open={isOpen}
    >
      <ContactUsModalProvider>
        <ContactUsFormModalProvider>
          {requestCredit && <MainAction closeModal={onClose} />}
          <div className={secondaryActionsClasses}>
            <Typography variant="h5" className={styles.heading} id={MODAL_TITLE_ID.CONTACT_US}>
              {t('contactUs.title')}
            </Typography>
            <Email />
            <button
              className={cx(styles.text, styles.showMore)}
              onClick={() => setShowMore(!showMore)}
            >
              <Typography className={styles.text}>{t('contactUs.otherWaysText')}</Typography>
              {renderArrow()}
            </button>
            {showMore && (
              <div className={styles.more_actions}>
                <Typography className={styles.text}>{t('contactUs.csHoursText')}</Typography>
                <Chat onClose={onClose} />
                <Phones />
              </div>
            )}
            <Typography className={styles.text}>
              <Link href={routing.help} classNameLinkText={styles.text_link} onClick={onClose}>
                {t('contactUs.helpLinkText')}
              </Link>
            </Typography>
          </div>
        </ContactUsFormModalProvider>
      </ContactUsModalProvider>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export
export default ContactUsModal;
