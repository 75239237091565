import {
  Day,
  DeliveryTimeslotData,
  LegendData,
  Maybe,
  OrderMetadata,
  ReservationTimeslotData as ReservationTimeslotDataApi,
  ReservationType,
  TimeslotData as TimeslotDataApi,
  Timeslots,
  TimeslotType,
} from '@api';
import {
  DeliveryTimeslots,
  ReservationInfo,
  Timeslot,
  TimeslotData,
  TimeslotDay,
  TimeslotLegend,
} from '@commons/timeslots';
import { addressAdapter } from './addressAdapter';
import { dateAdapter } from './dateAdapter';

interface TimeslotsAdapter {
  getTimeslots: (timeslots?: DeliveryTimeslotData) => DeliveryTimeslots;
  getTimeslotById: (data: DeliveryTimeslots, id: string) => TimeslotData | undefined;
  getMaxNumOfSlots: (timeslots?: Timeslot) => number;
  getReservationInfo: (reservationTimeslotData: ReservationTimeslotDataApi) => ReservationInfo;
}

const { getHandledDate } = dateAdapter();
const { getAddress } = addressAdapter();

const getLegend = (legend: LegendData): TimeslotLegend => ({
  label: legend.label || '',
  title: legend.title || '',
  image: legend.image || '',
  imageAlternative: legend.imageAlternative || '',
});

const getSlots = (
  slots: Maybe<TimeslotDataApi[]> | undefined,
  slotType: TimeslotType,
  isAfterLastAvailableDeliveryDate: boolean,
): TimeslotData[] =>
  slots?.map((slot) => ({
    alcoholRestricted: slot.alcoholRestricted,
    discount: slot.discount,
    earlyDelivery: slot.earlyDelivery,
    ecoFriendly: slot.ecoFriendly,
    forcedSlot: !!slot.forceSlot,
    id: slot.id ?? '',
    startDate: getHandledDate(new Date(slot.startDate ?? '')),
    endDate: getHandledDate(new Date(slot.endDate ?? '')),
    timePeriod: slot.timePeriod || '',
    soldOut: slot.soldOut,
    unavailable: slot.unavailable,
    slotType,
    cutoffTime: slot.cutoffTime ?? '',
    cutoffDate: getHandledDate(new Date(slot.cutoffDate ?? '')),
    nextSODeliveryDate: slot.nextSODeliveryDate,
    nextSODeliveryDay: slot.nextSODeliveryDay,
    modifyRestricted:
      slotType !== TimeslotType.ADVANCE_ORDER &&
      slotType !== TimeslotType.NEW_ADVANCE_ORDER &&
      !!isAfterLastAvailableDeliveryDate,
  })) ?? [];
const checkDayAvailability = (slots: TimeslotData[] = []): boolean => {
  return slots.some((slot) => !slot.unavailable);
};

const checkDayDiscount = (slots: TimeslotData[] = []): boolean => {
  return slots.some((slot) => slot.discount);
};

const getEmptyTimeslotDays = (slotType: TimeslotType): TimeslotDay[] => {
  return new Array(2).fill({
    date: {
      ...getHandledDate(new Date()),
      dateString: String(Date.now()),
      isAvailable: false,
      hasDiscount: false,
    },
    slots: [],
    slotType,
  });
};

const getDefaultTimeslotDays = (
  days: Maybe<Day[]> | undefined,
  slotType: TimeslotType,
  lastAvailableDeliveryDate: Maybe<string> | null,
): TimeslotDay[] =>
  days?.map((day) => {
    const slots = getSlots(day.slots, slotType, !!day.isAfterLastAvailableDeliveryDate) ?? [];
    const slotTypeIsAdvanceOrder =
      slotType === TimeslotType.ADVANCE_ORDER || slotType === TimeslotType.NEW_ADVANCE_ORDER;
    return {
      date: {
        ...getHandledDate(new Date(day.date as string)),
        dateString: day.date as string,
        isAvailable: checkDayAvailability(slots),
        hasDiscount: checkDayDiscount(slots),
      },
      slots,
      slotType,
      isAfterLastAvailableDeliveryDate:
        !slotTypeIsAdvanceOrder && !!day.isAfterLastAvailableDeliveryDate,
      lastAvailableDeliveryDate: !slotTypeIsAdvanceOrder ? lastAvailableDeliveryDate : null,
    };
  }) ?? [];

const getTimeslotDays = (
  days: Maybe<Day[]> | undefined,
  slotType: TimeslotType,
  lastAvailableDeliveryDate: Maybe<string> | null,
): TimeslotDay[] => {
  if (!days?.length && slotType === TimeslotType.EXPRESS) {
    return getEmptyTimeslotDays(slotType);
  }
  return getDefaultTimeslotDays(days, slotType, lastAvailableDeliveryDate);
};

const getTimeslot = (timeslot: Timeslots, orderMetadata: Maybe<OrderMetadata> | null) => ({
  days: getTimeslotDays(
    timeslot.days,
    timeslot.slotType,
    orderMetadata?.lastAvailableDeliveryDate ?? null,
  ),
  rangeStart: timeslot.rangeStart ?? '',
  rangeEnd: timeslot.rangeEnd ?? '',
  slotType: timeslot.slotType ?? '',
});

const getTimeslotById = (data: DeliveryTimeslots, id: string): TimeslotData | undefined => {
  for (const timeslot of data.timeslots) {
    for (const day of timeslot.days) {
      for (const slot of day.slots) {
        if (slot.id === id) {
          return { ...slot, slotType: timeslot.slotType as TimeslotType };
        }
      }
    }
  }
};

const getTimeslots = (data?: DeliveryTimeslotData) => ({
  timeslots:
    data?.grid?.timeslots?.map((ts) => getTimeslot(ts, data?.grid?.orderMetadata ?? null)) || [],
  legends: data?.legends?.map(getLegend) || [],
  warnings: data?.warnings ?? [],
  advanceOrderMetadata: {
    maxAdvanceOrderEndDate: data?.grid?.orderMetadata?.maxAdvanceOrderEndDate ?? '',
  },
  holidayRestrictions: data?.holidayRestrictions ?? [],
  orderMetadata: data?.grid?.orderMetadata,
});

const getMaxNumOfSlots = (timeslots?: Timeslot) =>
  timeslots ? Math.max(...timeslots.days.map((day) => day.slots.length)) : 0;

const getReservationInfo = (
  reservationTimeslotData: ReservationTimeslotDataApi,
): ReservationInfo => {
  const { reservationInfo, eligiblePreReservation } = reservationTimeslotData || {};
  return {
    isWeeklyReservation: reservationInfo?.reservationType === ReservationType.WRR,
    eligiblePreReservation: !!eligiblePreReservation,
    address: getAddress(reservationInfo?.address),
    dateTime: reservationInfo?.dateTime || '',
    expirationDateTime: reservationInfo?.expirationDateTime || '',
    startDateDay: reservationInfo?.startDateDay || '',
    startDateMonthDay: reservationInfo?.startDateMonthDay || '',
    title: reservationInfo?.title || '',
  };
};

export const timeslotsAdapter = (): TimeslotsAdapter => ({
  getTimeslots,
  getTimeslotById,
  getMaxNumOfSlots,
  getReservationInfo,
});
