import { gql } from '@apollo/client';
import { GIFT_CARDS_CART_FRAGMENT } from '../../fragments/cart/common/giftCardsCart';

export const GET_GIFT_CART = gql`
  query GiftCardsCart {
    giftCardsCart {
      ...giftCardsCartFragment
    }
  }
  ${GIFT_CARDS_CART_FRAGMENT}
`;
