import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFetchHtml } from '@hooks/useFetchHtml';
import { DeliveryPassInfo } from '@commons/deliveryPass';
import { deliveryPass as DeliveryPassLogo } from '@assets/icons/logos';
import { AccountAlert } from '@features/AccountPage/components/AccountAlert/AccountAlert';
import { routing } from '@constants/routing';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { GeneralInfoBlock } from './components/GeneralInfoBlock/GeneralInfoBlock';
import { PlanCard } from './components/PlanCard/PlanCard';
import styles from './AccountPageDeliveryPass.module.scss';

interface AccountPageDeliveryPassProps {
  deliveryPassInfo: DeliveryPassInfo;
  loading: boolean;
  called: boolean;
}

export const AccountPageDeliveryPass = ({
  deliveryPassInfo,
  loading,
  called,
}: AccountPageDeliveryPassProps) => {
  const router = useRouter();
  const { isExpired, isCanceled } = deliveryPassInfo;
  const { data: htmlContent } = useFetchHtml(deliveryPassInfo.userPlanMediaPath);
  const planEnded = isExpired || isCanceled;

  useEffect(() => {
    if (called && !loading && !deliveryPassInfo.wasMembershipActivated) {
      router.push(routing.deliveryPass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, deliveryPassInfo.wasMembershipActivated, called]);

  if (loading) {
    return <AccountPageLoader />;
  }

  if (!deliveryPassInfo.wasMembershipActivated) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <AccountAlert />
        <span className={styles.logo}>
          <DeliveryPassLogo />
        </span>
        {!planEnded && <GeneralInfoBlock deliveryPassInfo={deliveryPassInfo} />}
        <PlanCard deliveryPassInfo={deliveryPassInfo} htmlContent={htmlContent} />
      </div>
    </div>
  );
};
