import { useMutation } from '@apollo/client';
import { APPLY_PROMO } from '@graphql/promo/mutations/applyPromo';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { ApplyPromocodeMutation, ApplyPromocodeMutationVariables } from '@api';
import { CART_CACHE_FIELD } from '@graphql/cart/queries/getCart';

export const useApplyPromo = () => {
  const { clearCacheFields } = useCacheFieldsInvalidate();
  return useMutation<ApplyPromocodeMutation, ApplyPromocodeMutationVariables>(APPLY_PROMO, {
    onCompleted() {
      clearCacheFields([CART_CACHE_FIELD, 'appliedPromotion']);
    },
  });
};
