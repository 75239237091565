import { useState, useEffect } from 'react';
import styles from '../DevTools.module.scss';

export const Devnotes = () => {
  const [markedElements, setMarkedElements] = useState(
    document.querySelectorAll('.dev-mark, [dev-mark]'),
  );
  const [needsRefresh, setNeedsRefresh] = useState(false);

  useEffect(() => {
    if (needsRefresh) {
      setNeedsRefresh(false);
      setMarkedElements(document.querySelectorAll('.dev-mark, [dev-mark]'));
    }
  }, [needsRefresh]);

  return (
    <>
      {/* @ts-expect-error details support is lacking */}
      <details name="devtab" className={styles.marks}>
        <summary>
          Marked: {markedElements.length}
          <button
            className={styles.mark_refresh}
            onClick={() => {
              setNeedsRefresh(true);
            }}
          >
            ⟳
          </button>
        </summary>
        <div>
          <ul>
            {Array.from(markedElements).map((el, i) => (
              <li key={i}>
                <button
                  className={styles.mark_details}
                  onClick={() => {
                    // eslint-disable-next-line no-console
                    console.log('[DEV MARK]', el);
                  }}
                >
                  ►
                </button>
                {el.getAttribute('data-dev-note')}
              </li>
            ))}
          </ul>
        </div>
      </details>
    </>
  );
};
