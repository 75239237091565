import { gql } from '@apollo/client';
import { PRODUCT_TILE_FRAGMENT } from '@graphql/fragments/product/productTileFragment';

export const GET_OPTIONAL_PRODUCTS_BY_ID = gql`
  query ProductOptionalProductsData($productId: ID!) {
    product(productId: $productId) {
      productId
      optionalProductsData {
        title
        items {
          ...productTileFragment
        }
      }
    }
  }
  ${PRODUCT_TILE_FRAGMENT}
`;
