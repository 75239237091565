import React from 'react';
import { Typography } from '@mui/material';
import styles from './TabItem.module.scss';

interface TabItemProps {
  title?: string | null;
  values?: Array<string | null> | null;
}

export const TabItem = ({ title, values }: TabItemProps) => {
  return (
    <div className={styles.tab_item}>
      <Typography variant="smallBody" className={styles.title}>
        {title}
      </Typography>
      <Typography variant="body" className={styles.value}>
        {values?.map((alcoholInfoValue) => alcoholInfoValue).join(', ')}
      </Typography>
    </div>
  );
};
