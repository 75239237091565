import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';

export const sideBagDrawerOpenVar = makeVar<boolean>(false);

export const useSideBagDrawerOpenState = () => {
  const setSideBagDrawerOpenStateTo = useCallback((state: boolean) => {
    sideBagDrawerOpenVar(state);
  }, []);
  const isSideBagDrawerOpen = useReactiveVar(sideBagDrawerOpenVar);

  return { isSideBagDrawerOpen, setSideBagDrawerOpenStateTo };
};
