import { routing } from '@constants/routing';
import { PageException } from '../useResetGtm.helper';

enum PAGE_PATHNAME {
  ITEMS = 'items',
  ORDERS = 'orders',
  SHOPPING_LISTS = 'shopping_lists',
}

export const reorderPageException: PageException = (url) => {
  const pathname = new URL(url, window.location.origin).pathname;
  if (!/quickshop/.test(pathname)) {
    return undefined;
  }

  const splittedPathname = pathname.split('/').filter((item) => !!item);

  if (splittedPathname[1] === PAGE_PATHNAME.ORDERS || splittedPathname[1] === PAGE_PATHNAME.ITEMS) {
    return routing.reorderItems;
  }

  if (splittedPathname[1] === PAGE_PATHNAME.SHOPPING_LISTS) {
    return routing.shoppingLists;
  }

  return pathname;
};
