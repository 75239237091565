import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '../common/address';
import { DELIVERY_CONTACT_FRAGMENT } from './deliveryContact';

export const DELIVERY_ADDRESS_FRAGMENT = gql`
  fragment deliveryAddressFragment on DeliveryAddress {
    defaultZip
    expressEnabled
    selected
    isTemporary
    address {
      ...addressFragment
    }
    contact {
      ...deliveryContactFragment
    }
    backupInfo {
      setting
      hasUnattendedDeliveryOption
      contact {
        ...deliveryContactFragment
      }
      address {
        ...addressFragment
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${DELIVERY_CONTACT_FRAGMENT}
`;
