import getConfig from 'next/config';
import { OperationDefinitionNode, FieldNode } from 'graphql';
import { setContext } from '@apollo/client/link/context';
import { getCaptchaToken } from '@utils/captcha';
import { logger } from '@logger';
import { isFeatureEnabled, getFeatureOptions, DYNAMIC_FEATURES } from '@utils/dynamicFeatures';
import { KNOCKING_HEADER, KNOCKING_ACTIONS } from '@constants/knocking';

const { publicRuntimeConfig } = getConfig();

const DEFAULT_WAIT_TIME = 3000;
const DEFAULT_TOKEN = publicRuntimeConfig.knockingFallbackToken || 'no-token';

const getCaptchaAction = (operationName: string) => {
  const action = KNOCKING_ACTIONS[operationName.toLowerCase()];
  return action || 'submit';
};

export const knockingLink = (apiPath: string) =>
  setContext(async (request, ctx) => {
    let knockToken = DEFAULT_TOKEN;
    const knockingRequired = Object.keys(getFeatureOptions(DYNAMIC_FEATURES.KNOCKING)).map((k) =>
      k.toLowerCase(),
    );

    if (
      isFeatureEnabled(DYNAMIC_FEATURES.KNOCKING) &&
      request.operationName &&
      knockingRequired.includes(request.operationName.toLowerCase())
    ) {
      logger.info(`Operation ${request.operationName} requires knocking, performing...`);
      const captchaAction = getCaptchaAction(request.operationName);
      const captchaToken = await getCaptchaToken(captchaAction);
      const operation = request.query.definitions[0] as OperationDefinitionNode;
      const opName =
        (operation.selectionSet?.selections[0] as FieldNode).name?.value || operation.name?.value;
      const opType = operation.operation || 'mutation';
      const knockResponse = await fetch(apiPath, {
        method: 'POST',
        credentials: 'include',
        headers: {
          ['Content-Type']: 'application/json',
          ['X-jabuticaba']: publicRuntimeConfig.jabuticabaKey,
          Gqloperation: 'knock',
        },
        body: JSON.stringify({
          operationName: 'Knock',
          variables: {
            input: {
              captchaToken: captchaToken,
              captchaAction: captchaAction,
              operationName: opName,
              operationType: opType,
            },
          },
          query: `
          mutation Knock($input: KnockingInput!) {
            knock(input: $input) {
              waitTime
              token
              __typename
            }
          }
        `,
        }),
      }).then((res) => res.json());
      const waitTime = knockResponse.data.knock?.waitTime || DEFAULT_WAIT_TIME;
      knockToken = knockResponse.data.knock?.token || DEFAULT_TOKEN;
      await new Promise((resolve) => setTimeout(resolve, waitTime));
    }

    return {
      ...ctx,
      headers: {
        ...ctx.headers,
        [KNOCKING_HEADER]: knockToken,
      },
    };
  });
