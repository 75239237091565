import { ConfOption, PriceProperty, ProductBasicData, VariationGroup } from '@api';
import { BreadcrumbItem } from './breadcrumbs';
import { Price } from './price';

export type ProductTileResponse = DeepPartial<ProductBasicData>;

export interface CvPrice {
  charValueName?: string | null;
  price?: number | null;
  applyHow?: number | null;
}

export interface ProductCvPrice {
  charValueName: string;
  price: number;
  applyHow: number;
}

export interface SelectedConfiguration {
  name: string;
  value: string;
}

export interface SelectedProperties {
  ratio: number;
  quantity: number;
  selectedConfigurations: SelectedConfiguration[];
}

export enum PRODUCT_LABEL {
  YOURFAVE = 'Your Fave',
  SPONSORED = 'Sponsored',
  NEW = 'New',
  BACKONLINE = "It's Back",
}

export enum PRODUCT_PRICE_CURRENCY {
  USD = '$',
  EUR = '€',
}

export enum PRODUCT_CURRENCY_NAME {
  USD = 'USD',
  EUR = 'EURO',
}

export const enum AUTOSUGGEST_PRODUCT_LABEL {
  PURCHASED_BEFORE = 'Purchased before',
  EXACT_MATCH = 'Exact match',
}

export interface ProductMarketingTags {
  soldOut: boolean;
  sponsored: boolean;
  yourFave: boolean;
  backOnline: boolean;
  new: boolean;
}

export enum PRODUCT_MARKETING_TAG_TEXTS {
  TOP_PICK = 'PEAK QUALITY',
  EXPRESS_ELIGIBLE = 'EXPRESS ELIGIBLE',
}

export interface ProductTileImage {
  alt: string;
  ref: string;
  largeRef: string;
}

export interface ProductImage extends ProductTileImage {
  thumbnailRef: string;
}

export interface ProductTileRangePrice {
  scaleLowerBound: number;
  scaleUpperBound: number;
  price: number;
}

export interface ProductRangePrice {
  scaleLowerBound: number | null;
  scaleUpperBound: number | null;
  price: number | null;
}

export interface ProductCoupon {
  couponId: string;
  discount: number;
  description: string;
  detailedDescription: string;
  expirationDate: string;
  hasCoupon: boolean;
  isActive: boolean;
}

export interface ProductQuantity {
  minQuantity: number;
  maxQuantity: number;
  quantity: number;
  quantityIncrement: number;
}

/**
 * @deprecated
 * TODO: Refactor to general type Price
 */
export interface ProductPrice {
  currency: string;
  value: number;
  formattedPrice: string;
}

export interface ProductBasicPrice {
  currency: string;
  value: number;
  formattedPrice: string;
  properties: Array<PriceProperty>;
}

export interface ProductExtraDetails {
  ingredients?: string | null;
  freshnessGuarantee?: number | null;
  weightDisclaimer?: string | null;
  extraDescription?: string | null;
  claims: string[] | null;
  organicClaims: string[] | null;
  kosherIconPath: string | null;
  kosherSymbol: string | null;
  kosherType: string | null;
  productDescriptionNote: string | null;
  origin: string | null;
  seasonText: string | null;
  productAboutMediaPath: string | null;
}

export interface ProductGroupScale {
  grpId: string;
  version: string;
  grpPrice: string;
  grpDescription: string;
}

export interface ProductScalePrice {
  price: Price;
  promoPrice: Price;
  scaleUnit: string | null;
  pricingUnit: string | null;
  scaleLowerBound: number | null;
  scaleUpperBound: number | null;
}

export interface ProductTileVariationValues {
  name: string;
  label: string;
  cvp: string;
  imagePath: string;
  description: string;
  productName: string;
  isLabelValue: boolean;
  selected: boolean;
  variationItemProductData: {
    categoryId: string;
    productId: string;
  };
}

export interface ProductTileVariations {
  name: string;
  label: string;
  display: string;
  underLabel: string;
  descrPopup: string;
  descrMedia: string;
  optional: boolean;
  isMultipleValues: boolean;
  values: ProductTileVariationValues[];
}

export interface ProductFeatureTags {
  topPick: boolean;
  freeSample: boolean;
  expressEligible: boolean;
}

export interface Nutrition {
  panelType: string | null;
  columns: number | null;
  meta: NutritionMetaItem[] | null;
  main: NutritionItem[] | null;
  extra: NutritionItem[] | null;
}

export interface NutritionItem {
  id: string;
  displayName: string;
  priority: number | null;
  value: string[];
  uom: string[];
  lessThan: boolean[] | null;
  dailyValue: string[];
  dailyUom: string[];
  dailyLessThan: boolean[] | null;
}

export interface NutritionItemV2 {
  id: string;
  value1: string | null;
  value2: string | null;
  ingredientValue: number | null;
  uom: string;
  position: number | null;
  bulleted: boolean;
  important: boolean;
  newLine: boolean;
  separator: boolean;
}

export interface NutritionMetaItem {
  type: string | null;
  item: NutritionItem | null;
}

export interface NutritionSection {
  id: string;
  title: string | null;
  position: number | null;
  columnPosition: number | null;
  importance: number | null;
  type: string | null;
  item: NutritionItemV2[] | null;
}

export interface NutritionV2 {
  id: string;
  skuCode: string | null;
  lastModifiedDate: string | null;
  section: NutritionSection[] | null;
  type: string | null;
}

export interface NutritionLine {
  name: string;
  value: number;
  uom: string;
}

export interface WineData {
  alcoholGrade: string;
  country: string;
  grape: string;
  region: string;
  types: string[];
  vintage: string;
  varietals: string[];
  city: string;
  classification: string;
  importer: string;
  agingNotes: string;
}

export enum PRODUCT_TAGS {
  PEAK_QUALITY = 'peak-quality',
  EXPRESS_ELIGIBLE = 'express-eligible',
  WINE_AND_SPIRITS = 'wine-and-spirits',
}

export interface SalesUnit {
  alternateSalesUnit: string | null;
  name: string | null;
  ratio: number | null;
  salesUnit: string | null;
  selected: boolean | null;
}

export interface ProductSalesUnit {
  alternateSalesUnit: string;
  name: string;
  ratio: number;
  salesUnit: string;
  selected: boolean;
}

export interface AnnotationData {
  key: string;
  value: string;
}

export interface ProductAnnotationData {
  annotationData: AnnotationData[];
  erpsyLink: string;
}

/* TODO: need to create 1 full interface of product & some additional like SearchProduct and more using Pick or Omit */
export interface Product {
  productId: string;
  productPageUrl: string;
  skuCode: string;
  categoryPageUrl: string;
  productName: string;
  akaName: string;
  categoryId: string;
  preparationTime: number;
  deal: number;
  brandName: string;
  coupon: ProductCoupon;
  allergens: Array<string | null>;
  available: boolean;
  availability: string[];
  price: Price;
  wasPrice: Price;
  productImage: ProductImage;
  productImageCarousel: ProductImage[];
  productQualityNote: string;
  unitSize: string;
  unitPrice: string;
  packCount: number;
  quantity: ProductQuantity;
  marketingTags: ProductMarketingTags;
  featureTags: ProductFeatureTags;
  cvPrices: CvPrice[];
  availMaterialPrices: ProductRangePrice[];
  grpPrices: ProductRangePrice[];
  extraDetails: ProductExtraDetails;
  productDescription: string;
  oldNutritionContent?: NutritionLine[] | null;
  nutrition: Nutrition;
  nutritionV2: NutritionV2;
  instruction: string;
  instructionLink: string;
  groupScale: ProductGroupScale;
  scalePrice: ProductScalePrice[];
  scaleUnit: string;
  variantId: string;
  variations: ProductTileVariations[];
  hasWineData: boolean;
  isAlcoholic: boolean;
  wineData: WineData;
  salesUnits: SalesUnit[];
  estimatedWeightDisclaimer: string;
  selectedSalesUnit: string;
  formattedCurrentPrice: string;
  roughPricePerUnit: string;
  defaultScaleUnit: string;
  pricePerScaleUnit: string;
  savingString: string;
  breadcrumbs: BreadcrumbItem[];
  soldBySalesUnit: boolean;
  externalData: ProductExternalData;
  bundle: Bundle;
  listItemLineId: string;
  configuration: ConfOption[];
  discountAmount: number;
  clickBeacon: string;
  imageBeacon: string;
  viewBeacon: string;
  alternativeProductId: string;
  productAnnotationData?: ProductAnnotationData | null;
  discontinuedSoon: boolean;
}

export interface ProductBasic {
  productId: string;
  productPageUrl: string;
  categoryPageUrl: string;
  deal: number | null;
  skuCode: string | null;
  productName: string;
  brandName: string | null;
  akaName: string | null;
  available: boolean;
  availability: Array<string | null> | null;
  price: ProductBasicPrice;
  wasPrice: ProductBasicPrice;
  grpPrices: ProductRangePrice[];
  productImage: ProductImage;
  productQualityNote: string | null;
  unitSize: string | null;
  unitPrice: string | null;
  packCount: number | null;
  quantity: ProductQuantity;
  featureTags: ProductFeatureTags;
  fdWinesAndSpirits: boolean;
  isAlcoholic: boolean;
  cvPrices: CvPrice[];
  availMaterialPrices: ProductRangePrice[];
  productDescription: string;
  groupScale: ProductGroupScale;
  scalePrice: ProductScalePrice[];
  scaleUnit: string | null;
  variantId: string | null;
  variations: ProductTileVariations[];
  salesUnits: SalesUnit[];
  formattedCurrentPrice: string;
  defaultScaleUnit: string | null;
  pricePerScaleUnit: string;
  savingString: string;
  soldBySalesUnit: boolean;
  bundle: Bundle;
  listItemLineId: string;
  categoryId: string | null;
  discountAmount: number;
  clickBeacon: string;
  imageBeacon: string;
  viewBeacon: string;
  alternativeProductId: string;
}

export interface Bundle {
  type: string;
  variationGroups: VariationGroup[];
}

export interface SingleProductResponse extends Product {
  breadcrumbs: BreadcrumbItem[];
}

export interface AutosuggestProduct extends Product {
  label: string;
}

export interface ReorderProduct extends ProductTile {
  itemId: string;
  originalLineId: string;
}

export interface ProductExternalData {
  externalResponseId: string;
  externalWidgetId: string;
  externalWidgetType: string;
}

export type ProductForTile = Pick<
  Product,
  | 'brandName'
  | 'bundle'
  | 'coupon'
  | 'available'
  | 'featureTags'
  | 'marketingTags'
  | 'packCount'
  | 'price'
  | 'productId'
  | 'productPageUrl'
  | 'categoryPageUrl'
  | 'productImage'
  | 'productName'
  | 'wasPrice'
  | 'groupScale'
  | 'scaleUnit'
  | 'unitPrice'
  | 'quantity'
  | 'preparationTime'
  | 'variations'
  | 'unitSize'
  | 'scalePrice'
  | 'grpPrices'
  | 'hasWineData'
  | 'wineData'
  | 'akaName'
  | 'categoryId'
  | 'skuCode'
  | 'selectedSalesUnit'
  | 'formattedCurrentPrice'
  | 'roughPricePerUnit'
  | 'defaultScaleUnit'
  | 'pricePerScaleUnit'
  | 'savingString'
  | 'soldBySalesUnit'
  | 'listItemLineId'
  | 'configuration'
  | 'deal'
  | 'clickBeacon'
  | 'imageBeacon'
  | 'viewBeacon'
  | 'isAlcoholic'
  | 'alternativeProductId'
>;

export interface ProductTile {
  productId: string;
  deal: number;
  productPageUrl: string;
  categoryPageUrl: string;
  skuCode: string;
  productName: string;
  productDescription: string;
  categoryId: string;
  preparationTime: number;
  brandName: string;
  unitSize: string;
  unitPrice: string;
  available: boolean;
  availability: string[];
  scaleUnit: string;
  variantId: string;
  estimatedWeightDisclaimer: string;
  selectedSalesUnit: string;
  formattedCurrentPrice: string;
  roughPricePerUnit: string;
  defaultScaleUnit: string;
  pricePerScaleUnit: string;
  savingString: string;
  coupon: ProductCoupon;
  price: Price;
  wasPrice: Price;
  productImage: ProductTileImage;
  quantity: ProductQuantity;
  marketingTags: ProductMarketingTags;
  featureTags: ProductFeatureTags;
  cvPrices: ProductCvPrice[];
  availMaterialPrices: ProductTileRangePrice[];
  grpPrices: ProductTileRangePrice[];
  groupScale: ProductGroupScale;
  variations: ProductTileVariations[];
  salesUnits: ProductSalesUnit[];
  hasWineData: boolean;
  isAlcoholic: boolean;
  soldBySalesUnit: boolean;
  externalData: ProductExternalData;
  bundle: Bundle;
  listItemLineId: string;
  configuration: ConfOption[];
  discountAmount: number;
  clickBeacon: string;
  imageBeacon: string;
  viewBeacon: string;
  alternativeProductId: string;
}

export interface ConfigurableProductTile extends ProductTile {
  configuredPrice: number;
}
