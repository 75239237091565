import { IAction } from '@modules/action';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Handle<TData = any> = (action: IAction<TData>) => void;

export type Off = () => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type On<TData = any> = (handle: Handle<TData>) => Off;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handles: Array<Handle<any>> = [];

/**
 * Add "handle"
 * @param {Handle} handle required
 * @returns {Off}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const on: On<any> = (handle) => {
  if (-1 === handles.indexOf(handle)) {
    handles.push(handle);
  }
  return () => {
    const index = handles.indexOf(handle);
    if (-1 < index) {
      handles.splice(index, 1);
    }
  };
};

/**
 * Emit "action"
 * @param {IAction} action required
 * @returns {void}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emit: Handle<any> = (action) => {
  handles.forEach((handle) => {
    handle(action);
  });
};
