import { ChangeEvent } from 'react';
import { Input } from '@components/UI';
import { AddressAutocomplete } from '@components/AddressAutocomplete/AddressAutocomplete';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { useLocalization } from '@hooks/useLocalization';
import { AddressToSend } from '@commons/deliveryAddresses';
import { accountAdapter } from '@adapters/accountAdapter';
import styles from './addressOptionInput.module.scss';

interface AddressOptionInputProps {
  isAptUnitAvailable: boolean;
  selectedAddress?: Suggestion | null;
  inputAddress: string;
  isAddressSelected: boolean;
  setIsAptUnitAvailable: (value: boolean) => void;
  setInputAddress: (value: string) => void;
  setAPIError: (value: string) => void;
  onChange: (value: AddressToSend) => void;
  onChangeMode: () => void;
  onBlurred?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  id?: string;
}
// TODO: Need to create only one common AddressOptionInput component for main address and neighbouraddress and delete this one

export const AddressOptionInputForNeighborAddress = ({
  isAptUnitAvailable,
  inputAddress,
  formik,
  isAddressSelected,
  setInputAddress,
  setAPIError,
  onChangeMode,
  onChange,
  onBlurred,
  id,
}: AddressOptionInputProps) => {
  const { values, touched, errors, setFieldTouched, setFieldError, handleChange } = formik;

  const { getAddressFromSuggestion } = accountAdapter();
  const { t } = useLocalization();

  const aptUnitTextError = (touched.neighborApt && errors.neighborApt) || undefined;
  const addressTextError = (touched.neighborAddress && errors.neighborAddress) || null;
  const hasAptError = isAptUnitAvailable && !!errors.neighborApt;

  const onChangeAptUnit = (e: ChangeEvent<HTMLInputElement>) => {
    if (errors.neighborApt) setFieldError('neighborApt');
    handleChange(e);
  };

  return (
    <div className={styles.container}>
      <AddressAutocomplete
        inputAddress={inputAddress}
        error={addressTextError}
        isAddressSelected={isAddressSelected}
        className={styles.enter_address_input}
        onInputAddress={setInputAddress}
        onSelect={(value: Suggestion | null) => {
          if (value) onChange(getAddressFromSuggestion(value));
        }}
        onFocusedOut={() => {
          setFieldTouched('neighborAddress');
        }}
        onChangeMode={onChangeMode}
        onAPIError={setAPIError}
        id={id}
      />

      <Input
        className={styles.enter_address}
        label={t('delivery.aptUnit')}
        error={hasAptError}
        helperText={hasAptError ? aptUnitTextError : ''}
        onChange={onChangeAptUnit}
        onBlur={() => {
          setFieldTouched('neighborApt');
        }}
        value={values.neighborApt}
        name="neighborApt"
        id="neighborApt"
      />
    </div>
  );
};
