import { gql } from '@apollo/client';
import { PAYMENT_METHODS_FRAGMENT } from '@graphql/fragments/account/paymentMethods';
import { VALIDATION_RESULT_FRAGMENT } from '@graphql/fragments/account/validationResult';

export const ADD_HOSTED_PAYMENT = gql`
  mutation AddHostedPayment($input: HPFPaymentInput!, $standingOrderMode: StandingOrderMode) {
    addHostedPayment(input: $input, standingOrderMode: $standingOrderMode) {
      payments {
        ...paymentMethodsFragment
      }
      validationResult {
        ...validationResultFragment
      }
    }
  }
  ${PAYMENT_METHODS_FRAGMENT}
  ${VALIDATION_RESULT_FRAGMENT}
`;
