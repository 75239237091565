import { useAuthContext } from '@modules/auth/context';

export const AuthContextGlance = () => {
  const { userStatus, userInfo, userAuthStatus } = useAuthContext();

  return (
    <dl>
      <dt>userStatus</dt>
      <dd>
        <code>{userStatus}</code>
      </dd>
      <dt>userAuthStatus</dt>
      <dd>
        <code>{userAuthStatus}</code>
      </dd>
      <dt>userInfo</dt>
      <dd>
        <code>{JSON.stringify(userInfo, null, 2)}</code>
      </dd>
    </dl>
  );
};
