import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { GetMasqueradeCartLightQuery, GetMasqueradeCartLightQueryVariables } from '@api';
import { GET_MASQUERADE_CART_LIGHT } from '@graphql/masquerade/queries/masqueradeCartLight';

export const useLazyGetMasqueradeCartLight = (
  options?: QueryHookOptions<GetMasqueradeCartLightQuery, GetMasqueradeCartLightQueryVariables>,
) =>
  useLazyQuery<GetMasqueradeCartLightQuery, GetMasqueradeCartLightQueryVariables>(
    GET_MASQUERADE_CART_LIGHT,
    options,
  );
