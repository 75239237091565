import { useRef } from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { Typography } from '@mui/material';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { AccountPasswordInput, ALERT_TYPES } from '@commons/account';
import { useLocalization } from '@hooks/useLocalization';
import { FormikInput } from '@components/UI';
import { SubmitButton } from '@features/AccountPage/components/AccountPagePreferences/components/SubmitButton/SubmitButton';
import { Grid } from '@components/UI/Grid/Grid';
import { useUpdateAccountPassword } from '@hooks/account';
import { check as CheckIcon } from '@assets/icons/system';
import { responseErrorHandler } from '@utils/responseErrorHandler';
import { MIN_PASSWORD_LENGTH } from '@constants/validationFieldsLength';
import { OtpEnabledLoginType } from '@api';
import { PageBlock } from '../PageBlock/PageBlock';
import { getErrorMessage } from '../../../../utils/getErrorMessage';
import { FormValues } from './types';
import styles from './PasswordBlock.module.scss';

enum FIELDS {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}

interface PasswordBlockProps {
  loginType: OtpEnabledLoginType;
  isPasswordLogin: boolean;
  fetchAccountPreferences: () => void;
}

export const PasswordBlock = ({
  loginType,
  isPasswordLogin,
  fetchAccountPreferences,
}: PasswordBlockProps) => {
  const [updateAccountPassword] = useUpdateAccountPassword();
  const { dispatchAlert } = useAccountAlertContext();
  const { t } = useLocalization('account');
  const passwordFieldName = t('preferences.forms.password.fields.password.label');
  const confirmPasswordFieldName = t('preferences.forms.password.fields.confirmPassword.label');
  const passwordHelperText = t('preferences.forms.password.fields.password.helpText');
  const titleRef = useRef<HTMLDivElement>(null);
  const shouldDisplayCreatePassword =
    loginType !== OtpEnabledLoginType.MIXED &&
    loginType !== OtpEnabledLoginType.MIXED_EMAIL &&
    loginType !== OtpEnabledLoginType.MIXED_PHONE &&
    !isPasswordLogin;

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(MIN_PASSWORD_LENGTH, t('preferences.forms.errors.minPassword'))
      .required(t('preferences.forms.errors.required')),
    confirmPassword: yup.string().required(t('preferences.forms.errors.required')),
  });

  const handleSubmit = (input: AccountPasswordInput, onSubmitProps: { resetForm: () => void }) => {
    updateAccountPassword({
      variables: { input },
      onCompleted: (data) => {
        if (data?.updateAccountPassword?.success) {
          if (shouldDisplayCreatePassword) {
            fetchAccountPreferences?.();
          }

          dispatchAlert(
            ALERT_TYPES.SUCCESS,
            t('preferences.alerts.successMessage', {
              message: t('preferences.alerts.message.password'),
            }),
          );
        } else {
          responseErrorHandler(getErrorMessage(data?.updateAccountPassword?.errors), dispatchAlert);
        }
        onSubmitProps.resetForm();
      },
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });
  };

  const getHelperText = (passwordInput: string) => {
    return passwordInput.length >= MIN_PASSWORD_LENGTH ? (
      <>
        {passwordHelperText}
        <CheckIcon className={styles.helper_text_icon} />
      </>
    ) : (
      passwordHelperText
    );
  };

  const handleValidationError = async (
    validateForm: FormikProps<FormValues>['validateForm'],
    submitForm: FormikProps<FormValues>['submitForm'],
  ) => {
    const errors = await validateForm();

    if (!Object.keys(errors).length) {
      submitForm();
    } else {
      dispatchAlert(ALERT_TYPES.ERROR, t('preferences.alerts.message.changePassword'));
    }
    titleRef?.current?.focus();
  };

  return (
    <Grid container rowSpacing={{ xs: 7, sm: 12 }} columns={{ xs: 4, sm: 6, md: 8, lg: 8, xl: 8 }}>
      <Grid item xs={4} sm={6} md={8} lg={8} xl={8}>
        <Formik
          validateOnChange={false}
          validateOnBlur={true}
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, validateForm, submitForm }) => (
            <PageBlock title={t('preferences.forms.password.title')} ref={titleRef}>
              <Form>
                {shouldDisplayCreatePassword && (
                  <Typography variant="smallBody" className={styles.create_password}>
                    {t('preferences.forms.password.subTitle')}
                  </Typography>
                )}

                <Field
                  type="password"
                  className={styles.password}
                  aria-label={t('preferences.forms.password.fields.password.ariaLabel')}
                  name={FIELDS.PASSWORD}
                  label={passwordFieldName}
                  initialValue={values.password}
                  error={touched.password && errors.password}
                  helperText={
                    (touched.password && errors.password) ?? getHelperText(values.password)
                  }
                  component={FormikInput}
                  id={FIELDS.PASSWORD}
                  autoComplete="new-password"
                />
                <Field
                  type="password"
                  className={styles.password}
                  aria-label={t('preferences.forms.password.fields.password.ariaLabel')}
                  name={FIELDS.CONFIRM_PASSWORD}
                  label={confirmPasswordFieldName}
                  initialValue={values.confirmPassword}
                  error={touched.confirmPassword && errors.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  component={FormikInput}
                  id={FIELDS.CONFIRM_PASSWORD}
                  autoComplete="new-password"
                />
                <SubmitButton
                  aria-label={t('preferences.forms.password.button.text')}
                  onClick={() => handleValidationError(validateForm, submitForm)}
                >
                  {shouldDisplayCreatePassword
                    ? t('preferences.forms.password.button.createText')
                    : t('preferences.forms.password.button.text')}
                </SubmitButton>
              </Form>
            </PageBlock>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
