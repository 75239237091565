import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogProps,
  Box,
} from '@mui/material';
import { default as CloseIcon } from '@assets/icons/system/close.svg';
import { Button } from '@components/UI/Button/Button';
import { useLocalization } from '@hooks/useLocalization';
import { DATA_QA } from '@constants/dataQA';
import styles from './AgeVerification.module.scss';

interface AgeVerificationProps extends DialogProps {
  onConfirm: () => void;
  onDecline: () => void;
  onClose: () => void;
  confirmationInProgress?: boolean;
  declineInProgress?: boolean;
}

export const AgeVerification = ({
  onConfirm,
  onDecline,
  confirmationInProgress,
  declineInProgress,
  ...rest
}: AgeVerificationProps) => {
  const { t } = useLocalization('modals');

  return (
    <Dialog
      PaperProps={{
        className: styles.container,
      }}
      data-qa={DATA_QA.AGE_VERIFICATION}
      {...rest}
    >
      <DialogTitle className={styles.title}>
        <Typography variant="h3">{t('ageVerification.title')}</Typography>
        <IconButton onClick={rest.onClose} className={styles.close_button} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography variant="body">
          <span dangerouslySetInnerHTML={{ __html: t('ageVerification.description') }} />
        </Typography>
      </DialogContent>
      <Box className={styles.buttons}>
        <Button
          className={styles.remove_button}
          onClick={onDecline}
          fullWidth
          variant="outlined"
          size="large"
          loading={declineInProgress}
        >
          {t('ageVerification.removeAlcoholButton')}
        </Button>
        <Button
          className={styles.confirm_button}
          onClick={onConfirm}
          fullWidth
          size="large"
          loading={confirmationInProgress}
        >
          {t('ageVerification.confirm21+Button')}
        </Button>
      </Box>
    </Dialog>
  );
};
