import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '../common/address';

export const PAYMENT_FRAGMENT = gql`
  fragment paymentFragment on Payment {
    abaRouteNumber
    accountNumber
    bankAccountType
    bankName
    bestNumber
    billingAddress {
      ...addressFragment
    }
    canDelete
    eWalletID
    email
    expiration
    id
    isDebit
    isDefault
    isExpired
    mpLogoURL
    nameOnCard
    paymentTypeDescription
    ppLogoURL
    referencedOrderId
    selected
    title
    type
    vendorEWalletID
  }
  ${ADDRESS_FRAGMENT}
`;
