import { Field, Form, FormikContextType, useFormikContext } from 'formik';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { Button, DropDown } from '@components/UI';
import { useLocalization } from '@hooks/index';
import { AddressToSend, DeliveryContactInfoInput, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { StatesList } from '@constants/delivery';
import { Grid } from '@components/UI/Grid/Grid';
import { TextField } from '@components/FormikFields';
import { ONLY_DIGITS_REGEXP } from '@utils/validation';
import { useManualAddressValidation } from '@components/ManualAddressForm/hooks/useManualAddressValidation';
import styles from './ManualAddressFormContent.module.scss';

enum ADDRESS_FIELDS {
  ADDRESS = 'address1',
  APARTMENT = 'apartment',
  CITY = 'city',
  STATE = 'state',
  ZIPCODE = 'zipCode',
}

const columnTemplate = { xs: 4, sm: 4, md: 4, lg: 6, xl: 6 };

export const STATES_VALUES = Object.values(StatesList);

export const DROPDOWN_OPTIONS = STATES_VALUES.map((item) => ({
  value: item.code,
  label: item.title,
}));

interface ManualAddressFormContentProps {
  withSubmitButton?: boolean;
  parentFormikContext: FormikContextType<DeliveryContactInfoInput>;
  serviceType?: SERVICE_TYPE;
}

export const ManualAddressFormContent = ({
  withSubmitButton,
  parentFormikContext,
  serviceType,
}: ManualAddressFormContentProps) => {
  const {
    values,
    errors,
    touched,
    isValidating,
    setFieldValue,
    setErrors,
    validateForm,
    handleBlur,
    status,
    setTouched,
  } = useFormikContext<AddressToSend>();
  const { getClientValidation } = useManualAddressValidation();
  const { t } = useLocalization();

  const forceValidate = useCallback(() => {
    setTouched({ address1: true, city: true, zipCode: true });
    validateForm();
  }, [setTouched, validateForm]);

  const handleFormOnBlur = () => {
    if (!withSubmitButton) return validateForm();

    const clientErrors = getClientValidation(values);
    return setErrors(clientErrors);
  };

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Enter') forceValidate();
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (parentFormikContext?.isSubmitting) forceValidate();
  }, [parentFormikContext?.isSubmitting, forceValidate]);

  const handleZipcodeChange = (e: ChangeEvent<HTMLInputElement>) =>
    ONLY_DIGITS_REGEXP.test(e.target.value) &&
    setFieldValue(ADDRESS_FIELDS.ZIPCODE, e.target.value);

  const handleStateChange = (value: string) => setFieldValue(ADDRESS_FIELDS.STATE, value);

  const isError = (field: ADDRESS_FIELDS) => errors[field] && touched[field] && !isValidating;

  const getErrorMessage = (field: ADDRESS_FIELDS) =>
    touched[field] && !isValidating ? errors[field] : undefined;

  const isWarning = (field: ADDRESS_FIELDS) =>
    status?.warning?.[field] && touched[field] && !isValidating;

  const getWarningMessage = (field: ADDRESS_FIELDS) =>
    touched[field] && !isValidating ? status?.warning?.[field] : undefined;

  const apartmentLabel = t(
    `delivery.extendedDeliveryAddressForm.formLabels.${
      serviceType === SERVICE_TYPE.CORPORATE ? 'room' : 'unit'
    }`,
  );

  return (
    <Form className={styles.form} onBlur={handleFormOnBlur}>
      <Grid container columns={columnTemplate} rowSpacing={3}>
        <Grid item xs={4} lg={6}>
          <Field
            name={ADDRESS_FIELDS.ADDRESS}
            component={TextField}
            error={isError(ADDRESS_FIELDS.ADDRESS) ?? isWarning(ADDRESS_FIELDS.ADDRESS)}
            helperText={
              getErrorMessage(ADDRESS_FIELDS.ADDRESS) ?? getWarningMessage(ADDRESS_FIELDS.ADDRESS)
            }
            label={t('delivery.extendedDeliveryAddressForm.formLabels.streetAddress')}
            maxLength={55}
            onBlur={handleBlur}
            id={ADDRESS_FIELDS.ADDRESS}
            autoComplete="street-address"
          />
        </Grid>
        <Grid item xs={2} lg={3}>
          <Field
            name={ADDRESS_FIELDS.APARTMENT}
            component={TextField}
            error={!!errors.apartment}
            helperText={errors.apartment}
            label={apartmentLabel}
            maxLength={20}
            onBlur={handleBlur}
            id={ADDRESS_FIELDS.APARTMENT}
            autoComplete="address-line2"
          />
        </Grid>
        <Grid item xs={2} lg={3}>
          <Field
            name={ADDRESS_FIELDS.CITY}
            component={TextField}
            error={isError(ADDRESS_FIELDS.CITY)}
            helperText={getErrorMessage(ADDRESS_FIELDS.CITY)}
            label={t('delivery.extendedDeliveryAddressForm.formLabels.city')}
            maxLength={55}
            onBlur={handleBlur}
            id={ADDRESS_FIELDS.CITY}
            autoComplete="address-level2"
          />
        </Grid>
        <Grid item xs={2} lg={3}>
          <DropDown
            title={t('delivery.extendedDeliveryAddressForm.formLabels.state')}
            onChange={handleStateChange}
            options={DROPDOWN_OPTIONS}
            value={values.state || ''}
            id="manual-address-form-state"
          />
        </Grid>
        <Grid item xs={2} lg={3}>
          <Field
            name={ADDRESS_FIELDS.ZIPCODE}
            component={TextField}
            error={isError(ADDRESS_FIELDS.ZIPCODE)}
            helperText={getErrorMessage(ADDRESS_FIELDS.ZIPCODE)}
            label={t('delivery.extendedDeliveryAddressForm.formLabels.zipcode')}
            onChange={handleZipcodeChange}
            onBlur={handleBlur}
            maxLength={5}
            id={ADDRESS_FIELDS.ZIPCODE}
            autoComplete="postal-code"
          />
        </Grid>
        {withSubmitButton && (
          <Grid item xs={4} lg={6}>
            <Button size="large" variant="outlined" onClick={forceValidate} isFullWidth>
              {t('delivery.apply')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};
