import { useEffect, useState } from 'react';
import { useLocalization } from '@hooks/useLocalization';

interface UseCounterProps {
  initialQuantity?: number;
  increment?: number;
  minValue?: number;
  maxValue?: number;
  errorLocalization?: {
    minError: string;
    maxError: string;
  };
  setShowAlert?: (show: boolean) => void;
}

const defaultErrorLocale = {
  minError: 'errors.counter.minimum',
  maxError: 'errors.counter.maximum',
};

export const useCounter = ({
  initialQuantity = 0,
  increment = 1,
  minValue = 0,
  maxValue = 0,
  errorLocalization = defaultErrorLocale,
  setShowAlert,
}: UseCounterProps) => {
  const { t } = useLocalization('common');

  const [quantity, setQuantity] = useState<number>(initialQuantity);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isTouched, setIsTouched] = useState(false);
  const [isMinusDisabled, setIsMinusDisabled] = useState(false);
  const [isPlusDisabled, setIsPlusDisabled] = useState(false);
  const [isRemoveDisabled, setIsRemoveDisabled] = useState(false);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const validateMinLimit = (current: number) => {
    const isInvalid = current < minValue;
    let errorMsg = null;

    if (isInvalid) {
      errorMsg = t(errorLocalization.minError, { minQuantity: minValue });
      setShowAlert?.(true);
    }
    setErrorMessage(errorMsg);
    setIsTouched(true);
    return !isInvalid;
  };

  const validateMaxLimit = (current: number) => {
    const isInvalid = current > maxValue;
    let errorMsg = null;

    if (isInvalid) {
      errorMsg = t(errorLocalization.maxError, { maxQuantity: maxValue });
      setShowAlert?.(true);
    }
    setErrorMessage(errorMsg);
    setIsTouched(true);
    return !isInvalid;
  };

  const handleDecrease = () => {
    const newQuantity = quantity - increment;

    if (!validateMinLimit(newQuantity)) {
      return;
    }

    setQuantity(newQuantity);
  };

  const handleIncrease = () => {
    const newQuantity = quantity + increment;

    if (!validateMaxLimit(newQuantity)) {
      return;
    }
    setQuantity(newQuantity);
  };

  const handleRemove = async ({ onRemove }: { onRemove?: () => Promise<unknown> }) => {
    setQuantity(0);
    setIsRemoveDisabled(true);
    await onRemove?.();
    setIsRemoveDisabled(false);
  };

  const handleCounterInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = +e.target.value;

    if (!validateMinLimit(newQuantity)) {
      setQuantity(minValue);
      return;
    }

    if (!validateMaxLimit(newQuantity)) {
      setQuantity(maxValue);
      return;
    }

    setQuantity(newQuantity);
  };

  const updateCounter = (newQuantity: number) => {
    if (!validateMaxLimit(newQuantity) && !validateMinLimit(newQuantity)) {
      return;
    }
    setQuantity(newQuantity);
  };

  const checkRangeValue = () => {
    const isMaxValue: boolean = !!maxValue && maxValue <= quantity;
    const isMinValue = minValue >= quantity;

    if (isMaxValue && maxValue) {
      setQuantity(maxValue);
    }

    if (isMinValue) {
      setQuantity(minValue);
    }

    setIsMinusDisabled(isMinValue);
    setIsPlusDisabled(isMaxValue);
  };

  useEffect(() => {
    checkRangeValue();
  }, [quantity]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleIncrease,
    handleDecrease,
    handleRemove,
    handleCounterInput,
    updateCounter,
    errorMessage,
    quantity,
    increment,
    isTouched,
    isMinusDisabled,
    isPlusDisabled,
    isRemoveDisabled,
    setIsRemoveDisabled,
    minValue,
    maxValue,
  };
};
