import router from 'next/router';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { autosuggestVar } from '@graphql/variables/analyticMetrics';
import { SearchOptions } from '@modules/ga/types/searchEvent';

export const searchHandler = ({ count }: SearchOptions) => {
  const keyword = router.query.search ?? '';
  const autosuggest = autosuggestVar() ?? '';

  /* eslint-disable @typescript-eslint/naming-convention */
  sendGtmEvent({
    event: 'search',
    search_keyword: keyword,
    search_results: count,
    search_autosuggest: autosuggest,
  });
  /* eslint-enable @typescript-eslint/naming-convention */
  autosuggestVar(null);
};
