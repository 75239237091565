import { useApolloClient, useQuery } from '@apollo/client';
import { STANDING_ORDERS } from '@graphql/standingOrders/queries/standingOrders';
import { GetStandingOrdersQuery, GetStandingOrdersQueryVariables } from '@api';
import { useAuthContext } from '@modules/auth/context';

export const useStandingOrders = () => {
  const { isKnownUser: isLoggedIn, isRecognizedUser } = useAuthContext();
  const { data, loading } = useQuery<GetStandingOrdersQuery, GetStandingOrdersQueryVariables>(
    STANDING_ORDERS,
    {
      ssr: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: !isLoggedIn || isRecognizedUser,
    },
  );

  return { data, loading };
};

export const useCustomStandingOrders = () => {
  const client = useApolloClient();

  return () =>
    client.query<GetStandingOrdersQuery, GetStandingOrdersQueryVariables>({
      query: STANDING_ORDERS,
      fetchPolicy: 'network-only',
    });
};
