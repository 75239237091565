import { createContext } from 'react';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';

const initValue = {
  addEventHandler: () => undefined,
};

export type AnalyticsCallback = (pageData: AnalyticsData) => void;

interface AnalyticsDataContextProps {
  addEventHandler: (callback: AnalyticsCallback) => void;
}

export const AnalyticsDataContext = createContext<AnalyticsDataContextProps>(initValue);
