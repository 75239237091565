import { ApolloCache } from '@apollo/client';
import { OrderModifications } from '@api';

export const updateOrderModificationsCache = (
  cache: ApolloCache<unknown>,
  newOrderModifications?: OrderModifications | null,
) => {
  cache.modify({
    fields: {
      orderModifications(existingOrderModifications = {}) {
        if (!newOrderModifications) {
          return existingOrderModifications;
        }

        return {
          ...existingOrderModifications,
          ...newOrderModifications,
        };
      },
    },
  });
};
