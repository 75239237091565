import { gql } from '@apollo/client';

export const SHOPPING_LIST_FRAGMENT = gql`
  fragment shoppingListFragment on ShoppingList {
    id
    name
    productsCount
    default
    selected
    delete
  }
`;
