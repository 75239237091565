import omitBy from 'lodash/omitBy';
import overSome from 'lodash/overSome';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { ApolloError, Operation } from '@apollo/client';
import { StatusLevelType, LoginMode } from '@api';
import { getAuthInfoFromUpdate } from '@adapters/authAdapter';
import { STORAGE_KEYS, sessionStorageService } from '@utils/storageService';
import { AuthUser, USER_AUTH_STATUS } from '../types';

export type AuthUserState = {
  userInfo?: AuthUser;
  userStatus?: StatusLevelType;
  userAuthStatus?: USER_AUTH_STATUS;
  loginMode?: LoginMode;
  rememberMe: boolean;
  requestError: ApolloError | null;
  recognizedLastOperation?: Operation;
};

export enum AUTH_USER_ACTIONS_TYPES {
  SET_USER_INFO = 'SET_USER_INFO',
  SET_USER_STATUS = 'SET_USER_STATUS',
  SET_USER_AUTH_STATUS = 'SET_USER_AUTH_STATUS',
  SET_LOGIN_MODE = 'SET_LOGIN_MODE',
  SET_REMEMBER_ME = 'SET_REMEMBER_ME',
  SET_REQUEST_ERROR = 'SET_REQUEST_ERROR',
  SET_RECOGNIZED_LAST_OPERATION = 'SET_RECOGNIZED_LAST_OPERATION',
  CLEAR_REQUEST_ERROR = 'CLEAR_REQUEST_ERROR',
}

export type AuthUserActions =
  | { type: AUTH_USER_ACTIONS_TYPES.SET_USER_INFO; payload: AuthUser }
  | { type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS; payload: StatusLevelType }
  | { type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS; payload: USER_AUTH_STATUS | undefined }
  | { type: AUTH_USER_ACTIONS_TYPES.SET_LOGIN_MODE; payload: LoginMode | undefined }
  | { type: AUTH_USER_ACTIONS_TYPES.SET_REMEMBER_ME; payload: boolean }
  | { type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR; payload: ApolloError | null }
  | { type: AUTH_USER_ACTIONS_TYPES.SET_RECOGNIZED_LAST_OPERATION; payload: Operation }
  | { type: AUTH_USER_ACTIONS_TYPES.CLEAR_REQUEST_ERROR };

export const initialAuthUserState: AuthUserState = {
  rememberMe: sessionStorageService?.read(STORAGE_KEYS.REMEMBER_ME) ?? false,
  requestError: null,
};

export const authUserReducer = (state: AuthUserState, action: AuthUserActions) => {
  switch (action.type) {
    case AUTH_USER_ACTIONS_TYPES.SET_USER_INFO:
      const updatedUserAuthInfo = getAuthInfoFromUpdate({ ...state.userInfo, ...action.payload });

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...omitBy(updatedUserAuthInfo, overSome([isUndefined, isNull])),
        },
      };
    case AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS:
      return { ...state, userStatus: action.payload };
    case AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS:
      return { ...state, userAuthStatus: action.payload };
    case AUTH_USER_ACTIONS_TYPES.SET_LOGIN_MODE:
      return { ...state, loginMode: action.payload };
    case AUTH_USER_ACTIONS_TYPES.SET_REMEMBER_ME:
      return { ...state, rememberMe: action.payload };
    case AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR:
      return { ...state, requestError: action.payload };
    case AUTH_USER_ACTIONS_TYPES.SET_RECOGNIZED_LAST_OPERATION:
      return { ...state, recognizedLastOperation: action.payload };
    case AUTH_USER_ACTIONS_TYPES.CLEAR_REQUEST_ERROR:
      return { ...state, requestError: null };
    default:
      return state;
  }
};
