import { gql } from '@apollo/client';
import { MASQUERADE_CART_FRAGMENT } from '../../fragments/masquerade/masqueradeCartFragment';

export const GET_MASQUERADE_CART = gql`
  query GetMasqueradeCart {
    cartForCheckout {
      ...masqueradeCartFragment
    }
  }
  ${MASQUERADE_CART_FRAGMENT}
`;
