import uniqBy from 'lodash/uniqBy';
import { FieldReadFunction, InMemoryCache, InMemoryCacheConfig } from '@apollo/client';
import { expressItemsCountVar } from './variables/expressItemsNotification';

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    FacetValue: {
      keyFields: false,
    },
    Facet: {
      keyFields: false,
    },
    SectionInfo: { keyFields: ['sectionTitle'] },
    CartProductCounter: { keyFields: false },
    DeliveryAddress: { keyFields: ['address', ['id']] },
    Address: { keyFields: ['id', 'address1'] },
    ExpressDeliveryNotification: { keyFields: ['expressItemsCount'] },
    CartSubtotalBox: {
      keyFields: false,
    },
    OrderModifications: {
      keyFields: ['__typename'],
    },
    Cart: {
      keyFields: ['__typename'],
    },
    Query: {
      fields: {
        userDeliveryAddresses: {
          merge: true,
        },
        homepageFeed: {
          keyArgs: ['id'],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          merge(existing = [], incoming = []) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return uniqBy([...existing, ...incoming], (el) => el.__ref);
          },
        },
        reorderCarousel: {
          keyArgs: false,
        },
        mostPopularProductsCarousel: {
          keyArgs: false,
        },
        cartForCheckout: {
          merge: mergeCart,
        },
        accountPreferences: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        lightweightCart: {
          merge: mergeCart,
        },
        product: {
          keyArgs: ['productId'],
          merge: true,
        },
      },
    },
    Mutation: {
      fields: {
        deleteCartLine: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
};

function mergeCart(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existing: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incoming: any,
  {
    mergeObjects,
    readField,
    canRead,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mergeObjects: any;
    readField: FieldReadFunction;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canRead: any;
  },
) {
  if (canRead(incoming?.expressDeliveryNotification)) {
    expressItemsCountVar(
      readField('expressItemsCount', incoming?.expressDeliveryNotification) ?? null,
    );
  }
  return mergeObjects(existing, incoming);
}

export const cache: InMemoryCache = new InMemoryCache(cacheConfig);
