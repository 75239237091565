const MAKE_GOOD_TITLE = 'makeGood.makeGoodReason';
const FREE_MAKE_GOOD_TITLE = 'makeGood.freeReason';
const MAKE_GOOD_BUTTON = 'makeGood.applyMakeGoodButton';
const FREE_MAKE_GOOD_BUTTON = 'makeGood.applyFreeButtons';

export const getMakeGoodTitle = (isFree: boolean): string =>
  isFree ? FREE_MAKE_GOOD_TITLE : MAKE_GOOD_TITLE;

export const getMakeGoodButton = (isFree: boolean): string =>
  isFree ? FREE_MAKE_GOOD_BUTTON : MAKE_GOOD_BUTTON;
