import { useLocalization } from '../useLocalization';

export const useValidator = (validator: (value: string) => string | false) => {
  const { t } = useLocalization();

  return (value: string) => {
    const error = validator(value);
    return error && t(error);
  };
};
