import { getCookies } from 'cookies-next';
import { OptionsType } from 'cookies-next/src/types';
import { logger } from '@logger';
import { b64decode } from '@utils/b64';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const extractCookies = (res): string[] => {
  const cookies = res.headers.getSetCookie();

  return cookies;
};

export const getCookieValue = (key: string, cookies?: string[]) => {
  logger.debug('Extract ' + key + ' from ' + JSON.stringify(cookies));
  return cookies?.filter((k) => k.includes(key)).map((v) => v.split(';', 2)[0].split('=')[1]);
};

export const getFDUser = (cookies?: string[]) => {
  return getCookieValue('FDUser', cookies);
};

export const getFDToken = (cookies?: string[]) => {
  return getCookieValue('FD_TOKEN', cookies);
};

export const getFDSession = (cookies?: string[]) => {
  return b64decode(getFDToken(cookies)?.at(0));
};

export const cookiesFromContext = (options?: OptionsType) => {
  return Object.entries(getCookies(options)).map(([k, v]) => `${k}=${v}`);
};
