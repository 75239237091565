import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '@graphql/fragments/common/customerFragment';

export const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      customer {
        ...customerFragment
      }
      id
      authEvent {
        loginId
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;
