import React from 'react';
import { warning as WarningIcon } from '@assets/icons/system';
import styles from './PreviewBox.module.scss';

export function PreviewBox(props: { headers: { [x: string]: string } }) {
  const draftName = props.headers['x-draft-name'];

  return (
    <div className={styles.previewbox}>
      <WarningIcon />
      <span>Draft name: {draftName}</span>
    </div>
  );
}
