import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { AddCorporateAddressModalProps } from '@modules/modals/types/addCorporateAddressModal/addCorporateAddressModal';

export const openAddCorporateAddressModal = (props: AddCorporateAddressModalProps = {}) =>
  fireOpenModal(MODALS_LIST.ADD_CORPORATE_ADDRESS_MODAL, props);

export const closeAddCorporateAddressModal = () =>
  fireCloseModal(MODALS_LIST.ADD_CORPORATE_ADDRESS_MODAL);
