import { ReactNode } from 'react';

type Props = {
  hasVariations: boolean;
  children: ReactNode;
};

export const HasVariations = ({ hasVariations, children }: Props) => {
  if (!hasVariations) return <></>;
  return <>{children}</>;
};
