import { useLazyQuery } from '@apollo/client';
import { giftCardAdapter } from '@adapters/giftCardAdatper';
import { GET_GIFT_CARDS } from '@graphql/account/queries/getGiftCards';
import { GiftCardsQuery, GiftCardsQueryVariables } from '@api';

export const useLazyGiftCards = () => {
  const { getGiftCards } = giftCardAdapter();

  const [fetchGiftCards, { data, ...queryResult }] = useLazyQuery<
    GiftCardsQuery,
    GiftCardsQueryVariables
  >(GET_GIFT_CARDS, {
    variables: {
      showAllReceived: false,
    },
    fetchPolicy: 'network-only',
    ssr: false,
  });

  return {
    ...queryResult,
    data: getGiftCards(data?.giftCards),
    fetchGiftCards,
  };
};
