import { useMutation } from '@apollo/client';
import { USER_DETAILS } from '@graphql/common/queries/userDetails';
import { ADD_TO_ORDER_LIGHT_V2 } from '@graphql/common/mutations/addToOrderLightV2';
import { AddToOrderProduct, AddToOrderProductInput } from './useSetProductToAddToOrder';

export interface AddToCartDataInput {
  products: AddToOrderProductInput[];
  eventSource: string;
}

export interface AddToOrderInput {
  orderId: string;
  addToCartDataInput: AddToCartDataInput;
}

export const getAddToOrderVariables = (config: AddToOrderProduct): AddToOrderProduct => {
  const {
    productId,
    skuCode,
    categoryId,
    quantity,
    salesUnit,
    configuration = [],
    eventSource = 'pdp_main',
  } = config;
  return {
    quantity,
    eventSource,
    productId,
    categoryId,
    salesUnit,
    skuCode,
    configuration,
  };
};

export const useAddToOrderLightV2 = (input?: AddToOrderInput) => {
  return useMutation(ADD_TO_ORDER_LIGHT_V2, {
    variables: { input },
    refetchQueries: [USER_DETAILS],
  });
};
