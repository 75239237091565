import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { EmptyCartNote } from '../EmptyCartNote/EmptyCartNote';
import { StatusInfoWindowProps } from '../StatusInfoWindow/StatusInfoWindow';
import styles from './MobileStatusModal.module.scss';

interface MobileStatusModalProps extends StatusInfoWindowProps {
  isOpen: boolean;
}

export const MobileStatusModal = ({ isOpen, onClose }: MobileStatusModalProps) => {
  return (
    <ModalWindow
      open={isOpen}
      onClose={onClose}
      wrapperClassName={styles.modal_wrapper}
      labelId={MODAL_TITLE_ID.EMPTY_CART_MODAL}
    >
      <EmptyCartNote titleId={MODAL_TITLE_ID.EMPTY_CART_MODAL} />
    </ModalWindow>
  );
};
