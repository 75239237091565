import React from 'react';
import cx from 'classnames';
import { NutritionItemV2, NutritionV2 } from '@commons/product';
import { MainItemsV2 } from '@features/ProductPage/components/Nutrition/components/NutritionV2Table/components/MainItemsV2';
import { useLocalization } from '@hooks/useLocalization';
import { V2_TYPES } from '@features/ProductPage/constants/nutrition';
import { Grid } from '@components/UI/Grid/Grid';
import styles from '../../Nutrition.module.scss';

interface NutritionV2TableProps {
  nutrition: NutritionV2;
}

const TITLE_IMPORTANCE = 4;

const getServingSizeAndWeight = (str: string) => {
  const arr = str.split('(');
  const servSize = arr[0];
  const weight = arr[1]?.replace(')', '') ?? '';

  return {
    servSize,
    weight,
  };
};

const renderSuplementHeader = (arr: NutritionItemV2[]) => {
  return arr.map((el, index) => {
    if (index === 1) {
      const { servSize, weight } = getServingSizeAndWeight(el.value1 as string);
      return (
        <div className={styles.servings_number} key={el.id}>
          <span>{servSize}</span>
          <span>{weight}</span>
        </div>
      );
    }
    return (
      <span className={styles.servings_number} key={el.id}>
        {el.value1}
      </span>
    );
  });
};

const renderDrugHeader = (arr: NutritionItemV2[]) => {
  return arr.map((el, index) => {
    if (index === 0) {
      return (
        <Grid container key={el.id}>
          <Grid className={styles.freetext_title} item xs={9}>
            {el.value1}
          </Grid>
          <Grid className={styles.freetext_title} item xs>
            {el.value2}
          </Grid>
        </Grid>
      );
    }
    if (el.value1 && el.value2) {
      return (
        <Grid container key={el.id}>
          <Grid className={styles.section_item} item xs={6}>
            {el.value1 + (el.uom ? ' ' + el.ingredientValue + el.uom : '')}
          </Grid>
          <Grid className={styles.section_item} item xs>
            {el.value2}
          </Grid>
        </Grid>
      );
    }
    if (el.value1 && !el.value2) {
      return (
        <Grid className={styles.section_item} key={el.id} item xs={6} sx={{ pb: 1 }}>
          {el.value1}
        </Grid>
      );
    }
  });
};

export const NutritionV2Table = ({ nutrition }: NutritionV2TableProps) => {
  const isDrug = nutrition.type === V2_TYPES.drug;
  const isPet = nutrition.type === V2_TYPES.pet;
  const { t } = useLocalization('product');
  const title = isPet ? t('nutrition.petNutritionFactsTitle') : t('nutrition.nutritionFactsTitle');

  return (
    <table>
      <tbody>
        {nutrition?.section?.map((column) => {
          if (column.importance === TITLE_IMPORTANCE) {
            const { title: sectionTitle } = column;
            return (
              <div key={column.id}>
                <h1
                  className={cx(styles.title, {
                    [styles.pet_title]: nutrition.type === V2_TYPES.pet,
                    [styles.thick_border]: nutrition.type === V2_TYPES.supplement,
                  })}
                >
                  {title}
                </h1>
                <div className={styles.supplement_title_wrapper}>
                  <span className={styles.freetext_title}>{sectionTitle}</span>
                  {column?.item?.length && !isDrug
                    ? renderSuplementHeader(column.item as NutritionItemV2[])
                    : renderDrugHeader(column.item as NutritionItemV2[])}
                </div>
              </div>
            );
          } else {
            return MainItemsV2(column, nutrition.type);
          }
        })}
      </tbody>
    </table>
  );
};
