import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { getCurrentPageType } from '@modules/ga/utils/getPageType';

interface RwProduct {
  productId: string;
  variantId: string;
}

interface RwProductClickOptions {
  product: RwProduct;
}

interface RwProductAddToCartOptions {
  product: RwProduct;
}

interface RwTotalProductCountOptions {
  totalProductCount: number;
}

export const rwProductClick = ({ product }: RwProductClickOptions) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  sendGtmEvent(
    GtmCustomEvent({
      event: 'rw_product_click',
      ua_category: 'Recommendation Widget',
      ua_action: 'product click',
      ua_label: getCurrentPageType(),
      id: product.productId,
      variantId: product.variantId,
    }),
  );
  /* eslint-enable @typescript-eslint/naming-convention */
};
export const rwProductAddToCart = ({ product }: RwProductAddToCartOptions) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  sendGtmEvent(
    GtmCustomEvent({
      event: 'rw_add_to_cart',
      ua_category: 'Recommendation Widget',
      ua_action: 'add to cart',
      ua_label: getCurrentPageType(),
      id: product.productId,
      variantId: product.variantId,
    }),
  );
  /* eslint-enable @typescript-eslint/naming-convention */
};

export const rwExternalWidgetView = ({ totalProductCount }: RwTotalProductCountOptions) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  sendGtmEvent(
    GtmCustomEvent({
      event: 'rw_external_widget_view',
      ua_category: 'Recommendation Widget',
      ua_action: 'external widget view',
      ua_label: getCurrentPageType(),
      totalProductCount: totalProductCount,
    }),
  );
  /* eslint-enable @typescript-eslint/naming-convention */
};
