import { gql } from '@apollo/client';
import { MASQUERADE_CART_FRAGMENT } from '@graphql/fragments/masquerade/masqueradeCartFragment';

export const CHANGE_CART_LINE_COMPLAINT_REASON = gql`
  mutation ChangeCartLineComplaintReason($cartLineIds: [ID!]!, $complaintReasonId: ID) {
    changeCartLineComplaintReason(
      cartLineIds: $cartLineIds
      complaintReasonId: $complaintReasonId
    ) {
      ...masqueradeCartFragment
    }
  }
  ${MASQUERADE_CART_FRAGMENT}
`;
