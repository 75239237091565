import { localStorageService, STORAGE_KEYS } from './storageService';

export const determineRememberedExpressItems = (
  expressItemsCount: number,
  refreshCount: number,
  savedItems: number,
) => {
  const isPageRefresh = refreshCount % 2 === 0;
  // Checking if we have express items and refreshed the page
  // or navigated somewhere 2 or divided by 2 times
  // and count of refreshes is 0 we should remember count of express items for the next time
  if (expressItemsCount !== 0 && isPageRefresh && refreshCount !== 0) {
    localStorageService?.put(STORAGE_KEYS.PAGE_REFRESH_COUNT, 0);
    localStorageService?.put(STORAGE_KEYS.SAVED_EXPRESS_ITEMS, expressItemsCount);
    return expressItemsCount;
  }

  return savedItems;
};

export const determineCountsByThreshold = (
  savedItemsCount: number,
  threshold: number,
  expressItemsCount: number,
) => {
  const isThresholdReached = expressItemsCount >= threshold;
  const hiddenNotification = localStorageService?.read(STORAGE_KEYS.HIDDEN_EXPRESS_NOTIFICATION);
  let expressCount = expressItemsCount;

  const savedExpressItemsAndThresholdNotZero =
    (savedItemsCount !== 0 || (savedItemsCount === 0 && hiddenNotification)) && threshold !== 0;
  const hasNewExpressItemsAndThresholdReached =
    expressItemsCount !== savedItemsCount && isThresholdReached;

  if (
    savedExpressItemsAndThresholdNotZero &&
    hasNewExpressItemsAndThresholdReached &&
    expressItemsCount !== 0
  ) {
    localStorageService?.put(STORAGE_KEYS.PAGE_REFRESH_COUNT, 0);
    localStorageService?.put(STORAGE_KEYS.SAVED_EXPRESS_ITEMS, 0);
    localStorageService?.put(STORAGE_KEYS.HIDDEN_EXPRESS_NOTIFICATION, false);
  }

  if (!isThresholdReached) {
    expressCount = 0;
  }

  return expressCount;
};

export const determineBaseValues = (
  expressItemsCount: number | null,
  lastExpressItemsCount: number,
) => {
  let baseExpressCount = 0;
  let baseLastExpressItemsCount = lastExpressItemsCount;

  if (expressItemsCount !== null) {
    baseExpressCount = expressItemsCount;
    if (expressItemsCount !== 0) {
      baseLastExpressItemsCount = expressItemsCount;
    }
  }

  if (expressItemsCount === 0 && baseLastExpressItemsCount !== expressItemsCount) {
    localStorageService?.put(STORAGE_KEYS.HIDDEN_EXPRESS_NOTIFICATION, true);
  }

  return {
    baseLastExpressItemsCount,
    baseExpressCount,
  };
};
