import { ServerResponse } from 'http';
import { IMiddlewareFilter } from './middleware-filter';
import { MiddlewareFilterScope } from './middleware-filter-scope';

const draftIdSearchParamKey = 'draftId';
const draftNameSearchParamKey = 'draftName';
const previewSearchParamKey = 'preview';

export enum PREVIEW_MODE_HEADER_KEYS {
  DRAFT_ID = 'x-draft-id',
  DRAFT_NAME = 'x-draft-name',
  PREVIEW = 'x-preview',
}

export type IPreviewModeHeaders = {
  [key in PREVIEW_MODE_HEADER_KEYS]: string;
};

export const getPreviewHeaders = (res?: ServerResponse): IPreviewModeHeaders | undefined => {
  const draftId = res?.getHeader(PREVIEW_MODE_HEADER_KEYS.DRAFT_ID);
  const draftName = res?.getHeader(PREVIEW_MODE_HEADER_KEYS.DRAFT_NAME);
  const preview = res?.getHeader(PREVIEW_MODE_HEADER_KEYS.PREVIEW);

  if (draftId && draftName && preview) {
    return {
      [PREVIEW_MODE_HEADER_KEYS.DRAFT_ID]: String(draftId),
      [PREVIEW_MODE_HEADER_KEYS.DRAFT_NAME]: String(draftName),
      [PREVIEW_MODE_HEADER_KEYS.PREVIEW]: String(preview),
    };
  }
};

export const applyPreviewMode: IMiddlewareFilter = async (oldScope) => {
  const requestUrl = new URL(oldScope.requestUrl);
  const draftId = requestUrl.searchParams.get(draftIdSearchParamKey);
  const draftName = requestUrl.searchParams.get(draftNameSearchParamKey);
  const preview = requestUrl.searchParams.get(previewSearchParamKey);
  const responseHeaders = new Headers(oldScope.responseHeaders);
  if (draftId && draftName && preview) {
    responseHeaders.set(PREVIEW_MODE_HEADER_KEYS.DRAFT_ID, draftId);
    responseHeaders.set(PREVIEW_MODE_HEADER_KEYS.DRAFT_NAME, draftName);
    responseHeaders.set(PREVIEW_MODE_HEADER_KEYS.PREVIEW, preview);
  }
  return MiddlewareFilterScope({ ...oldScope, requestUrl, responseHeaders });
};
