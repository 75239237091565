import { useMutation } from '@apollo/client';
import { DELETE_ACCOUNT } from '@graphql/account/mutations/deleteAccount';
import { useLocalization } from '@hooks/useLocalization';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { openNotificationAccountDeletionModal } from '@modules/modals/events/notificationAccountDeletionModal';

interface UseDeleteAccountProps {
  deletionToken: string;
}

export const useDeleteAccount = ({ deletionToken }: UseDeleteAccountProps) => {
  const { t } = useLocalization('account');
  const { logout } = useAuthContext();

  const handleCloseModal = () => {
    logout();
  };

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    variables: { deletionToken },
    onCompleted: (data) => {
      if (!data.deleteAccount.success) {
        const [{ type, description }] = data.deleteAccount.errors;
        openNotificationAccountDeletionModal({
          title: type,
          description,
        });

        return;
      }

      if (data.deleteAccount.success) {
        const title = t('preferences.deleteAccount.notificationModal.successAccountDelition.title');
        const description = t(
          'preferences.deleteAccount.notificationModal.successAccountDelition.description',
        );

        openNotificationAccountDeletionModal({ title, description, onClose: handleCloseModal });
      }
    },
  });

  return { deleteAccount };
};
