import { IGaCustomer } from '@modules/ga/eventBodyGetters/utils/ga-customer';
import { PGaPageView } from '@modules/ga/events/custom/custom-page-view/ga-page-view';
import { getCurrentPageType } from '@modules/ga/utils/getPageType';
import { getShoppingMode } from '@modules/ga/utils/getShoppingMode';

/* eslint-disable @typescript-eslint/naming-convention */
export const getPageViewEventData = (
  url: string,
  customerData: IGaCustomer,
  pageViewPixelEventId: string,
): PGaPageView => {
  const pageURL = new URL(url, window.location.origin).href;
  const shopping_mode = getShoppingMode();
  const page_type = getCurrentPageType();
  const page_name = document.title;

  return {
    pageURL,
    customer: customerData,
    page_type,
    page_name,
    shopping_mode,
    pageViewPixelEventId,
  };
};
/* eslint-enable @typescript-eslint/naming-convention */
