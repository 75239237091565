import { gql } from '@apollo/client';
import { ORDER_INFO_FRAGMENT } from '@graphql/fragments/account/orderInfo';

export const MODIFYING_ORDER_ALERT_INFO = gql`
  query modifyingOrderAlertInfo {
    modifyingOrderAlertInfo {
      ...orderInfoFragment
      isExpressOrder
    }
  }
  ${ORDER_INFO_FRAGMENT}
`;

export const MODIFYING_ORDER_ALERT_INFO_CACHE_FIELD = 'modifyingOrderAlertInfo';
