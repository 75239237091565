import { Product, ProductTile } from '@commons/product';
import { StandingOrderButton } from '@components/StandingOrderButton/StandingOrderButton';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';

interface ProductPageStandingOrderButtonProps {
  product: Product | ProductTile;
}

export const ProductPageStandingOrderButton = ({
  product,
}: ProductPageStandingOrderButtonProps) => {
  const { getFeature } = useHeaderContext();
  const shouldRender = !!getFeature?.(FEATURE.STANDING_ORDERS) && !!getFeature?.(FEATURE.COS);

  return shouldRender ? (
    <StandingOrderButton product={product} variant="outlined" mode={'light'} size="large" />
  ) : null;
};
