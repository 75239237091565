import { gql } from '@apollo/client';

export const CREDIT_ORDER_FRAGMENT = gql`
  fragment creditOrderFragment on CreditOrder {
    orderId
    requestedDate
    deliveryStart
    deliveryEnd
    standingOrderName
  }
`;
