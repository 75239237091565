import { DeliveryPassInfo } from '@commons/deliveryPass';

export type AnalyticDeliveryPassStatus = 'Active' | 'Expired' | 'None';

export const getAnalyticDeliveryPassStatus = (
  deliveryPass: DeliveryPassInfo,
): AnalyticDeliveryPassStatus => {
  if (deliveryPass.deliveryPassApplied) {
    return 'Active';
  }
  if (deliveryPass.planDisabled) {
    return 'Expired';
  }
  return 'None';
};
