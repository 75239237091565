import { gql } from '@apollo/client';
import { RECEIVED_GIFT_CARD_FRAGMENT } from '@graphql/fragments/account/receivedGiftCard';

export const REDEEM_GIFT_CARD = gql`
  mutation RedeemGiftCard($redemptionCode: String!) {
    redeemGiftCard(redemptionCode: $redemptionCode) {
      success
      actionResult {
        name
        error
      }
      redeemedGiftCard {
        ...receivedGiftCardFragment
      }
    }
  }
  ${RECEIVED_GIFT_CARD_FRAGMENT}
`;
