import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { getShoppingMode } from '@modules/ga/utils/getShoppingMode';
import { AuthEventResult } from '@api';

export interface GetUserSignupSuccessEventProps {
  authEvent?: AuthEventResult | null;
  commonAnalyticsData: AnalyticsData;
}

/* eslint-disable @typescript-eslint/naming-convention */
export const getUserSignupSuccessEvent = (p: GetUserSignupSuccessEventProps) => ({
  event: 'user-signup-success',
  event_name: 'signup_success',
  ua_category: 'Signup',
  ua_action: 'signup-success',
  customer: getCustomerData({
    userDetails: p.commonAnalyticsData.userDetails,
    minimumAccountPreferences: p.commonAnalyticsData.minimumAccountPreferences,
    userStatus: p.commonAnalyticsData.userStatus,
    address: p.commonAnalyticsData.addressData,
    geoData: p.commonAnalyticsData.geoData,
  }),
  pixelEventId: p.authEvent?.loginId ?? '',
  signupPixelEventId: p.authEvent?.signupId ?? '',
  'shopping-mode': getShoppingMode(),
});
/* eslint-enable @typescript-eslint/naming-convention */
