import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI/Button/Button';
import { useLocalization } from '@hooks/useLocalization';
import { warning as Warning } from '@assets/icons/system';
import { useCancelOrder } from '@hooks/account/useCancelOrder';
import { PENDING_ORDERS_ALERTS_INFO } from '@graphql/common/queries/pendingOrdersAlertInfo';
import { GET_PAST_AND_ACTIVE_ORDER_HISTORY } from '@graphql/account/queries/getPastAndActiveOrderHistory';
import { CancelOrderErrors } from '../ActiveOrderCard/ActiveOrderCard';
import styles from './CancelConfirmationModal.module.scss';

interface CancelOrderModalProps {
  opened: boolean;
  onClose: () => void;
  onCancel: (orderId: string) => void;
  orderId: string;
}

export const CancelConfirmationModal = ({
  opened,
  onClose,
  onCancel,
  orderId,
}: CancelOrderModalProps) => {
  const { t } = useLocalization('account');

  const [errors, setErrors] = useState<CancelOrderErrors[]>([]);
  const [cancelOrder, { loading: cancelRequestInProgress }] = useCancelOrder(orderId);

  const handleCancelOrder = () => {
    if (!cancelRequestInProgress) {
      cancelOrder({
        variables: {
          orderId,
        },
        refetchQueries: [PENDING_ORDERS_ALERTS_INFO, GET_PAST_AND_ACTIVE_ORDER_HISTORY],
        onCompleted: (data) => {
          if (data?.cancelOrder.success) {
            onClose();
            onCancel(orderId);
          } else {
            setErrors([...data.cancelOrder.errors]);
          }
        },
      });
    }
  };

  useEffect(() => {
    setErrors([]);
  }, [opened]);

  return (
    <ModalWindow
      open={opened}
      onClose={onClose}
      title={t('orderHistory.areYouSure')}
      className={styles.modal_content}
      titleClassName={styles.title}
    >
      <>
        <Typography className={styles.text} variant="body">
          {t('orderHistory.cancelWarning')}
        </Typography>
        <Button onClick={onClose} size="large" isFullWidth>
          {t('orderHistory.order_actions.dontCancel')}
        </Button>
        <div className={styles.error_wrapper} aria-live="polite">
          {errors.length > 0 && (
            <>
              <Warning width={12} height={12} className={styles.icon} />
              <div className={styles.errors_list}>
                {errors.map(({ description }: CancelOrderErrors) => (
                  <Typography className={styles.error_message} variant="body" key={description}>
                    {description}
                  </Typography>
                ))}
              </div>
            </>
          )}
        </div>
        <Button
          className={styles.button}
          onClick={handleCancelOrder}
          variant="outlined"
          size="large"
          loading={cancelRequestInProgress}
          isFullWidth
        >
          {t('orderHistory.order_actions.cancel')}
        </Button>
      </>
    </ModalWindow>
  );
};
