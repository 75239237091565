import { gql } from '@apollo/client';
import { PRODUCT_QUANTITY_FRAGMENT } from '@graphql/fragments/common/ProductQuantityFragment';
import { PRICE_FRAGMENT } from '../common/priceFragment';
import { COUPON_FRAGMENT } from '../common/couponFragment';
import { PRODUCT_MEAL_BUNDLE_FRAGMENT } from '../common/ProductMealBundleFragment';
import { SALES_UNIT_FRAGMENT } from './common/salesUnitFragment';

export const PRODUCT_BASIC_FRAGMENT = gql`
  fragment productBasicFragment on ProductBasicData {
    listItemLineId
    productId
    productPageUrl
    categoryId
    deal
    categoryPageUrl
    skuCode
    productName
    akaName
    brandName
    packCount
    unitSize
    unitPrice
    weightDisclaimer
    estimatedWeightDisclaimer
    salesUnits {
      ...salesUnitFragment
    }
    price {
      ...priceFragment
    }
    formattedCurrentPrice
    roughPricePerUnit
    defaultScaleUnit
    pricePerScaleUnit
    scaleUnit
    servingSize
    productImage
    productDetailImage
    productZoomImage
    productJumboImage
    productAlternateImage
    productImagePackage
    productDescription
    productQualityNote
    marketingTags {
      soldOut
      sponsored
      yourFave
      backOnline
      new
    }
    featureTags {
      topPick
      freeSample
      expressEligible
    }
    groupScale {
      grpId
      version
      grpPrice
      grpDescription
    }
    coupon {
      ...couponFragment
    }
    wasPrice {
      ...priceFragment
    }
    quantity {
      ...productQuantityFragment
    }
    availability
    available
    alcoholic
    preparationTime
    scalePrice {
      price {
        ...priceFragment
      }
      promoPrice {
        ...priceFragment
      }
      scaleUnit
      pricingUnit
      scaleLowerBound
      scaleUpperBound
    }
    savingString
    availMaterialPrices {
      price
      pricingUnit
      scaleLowerBound
      scaleUpperBound
      scaleUnit
      promoPrice
    }
    cvPrices {
      charName
      charValueName
      price
      pricingUnit
      applyHow
      salesOrg
      distChannel
    }
    grpPrices {
      price
      pricingUnit
      scaleLowerBound
      scaleUpperBound
      scaleUnit
      promoPrice
    }
    variantId
    variations {
      name
      label
      optional
      display
      underLabel
      descrPopup
      descrMedia
      values {
        name
        label
        isLabelValue
        selected
        cvp
        imagePath
        productName
        description
        variationItemProductData {
          categoryId
          productId
        }
      }
    }
    fdWinesAndSpirits
    soldBySalesUnit
    bundle {
      ...productMealBundleFragment
    }
    configuration {
      key
      value
    }
    discountAmount {
      ...priceFragment
    }
    clickBeacon
    imageBeacon
    viewBeacon
    recommendedProduct {
      productId
    }
    discontinuedSoon
  }
  ${PRICE_FRAGMENT}
  ${SALES_UNIT_FRAGMENT}
  ${COUPON_FRAGMENT}
  ${PRODUCT_QUANTITY_FRAGMENT}
  ${PRODUCT_MEAL_BUNDLE_FRAGMENT}
`;

export const SHOPPING_LIST_PRODUCT_BASIC_FRAGMENT = gql`
  fragment shoppingListProductBasicFragment on ProductBasicData {
    configuration {
      key
      value
    }
    configuredPrice
  }
`;
