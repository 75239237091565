import { useMutation } from '@apollo/client';
import { ACCEPT_TC } from '@modules/auth/operations/mutations/acceptTC';

export const useAcceptTC = () => {
  const [acceptTCMutation, loading] = useMutation(ACCEPT_TC);

  const acceptTC = async () => {
    await acceptTCMutation();
  };

  return {
    acceptTC,
    isLoading: loading,
  };
};
