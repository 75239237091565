import { ApolloLink } from '@apollo/client';
import { isClient } from '@utils/isClient';

export const maintenanceLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (operation.getContext().response.status === 302 && isClient()) {
      const baseUrl = window.location.protocol + '//' + window.location.host;
      window.location.replace(baseUrl + '/media/maintenance/index.html');
    }
    return response;
  });
});
