import { ApolloLink } from '@apollo/client';
import { CookieValueTypes, getCookie } from 'cookies-next';
import isUndefined from 'lodash/isUndefined';
import { COOKIES_LIST } from '@commons/cookies';
import { EXPRESS_FILTER_HEADER } from '@constants/requestHeaders';

export const expressDeliveryFilterLink = (isExpressInitial: CookieValueTypes) => {
  let isInited = false;
  return new ApolloLink((operation, forward) => {
    let expressHeaderValue = getCookie(COOKIES_LIST.IS_EXPRESS);
    if (!isUndefined(isExpressInitial) && !isInited) {
      expressHeaderValue = isExpressInitial;

      if (typeof window !== 'undefined') {
        isInited = true;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.setContext((ctx: Record<string, any>) => {
      return {
        ...ctx,
        headers: {
          [EXPRESS_FILTER_HEADER]: expressHeaderValue,
          ...ctx.headers,
        },
      };
    });
    return forward(operation);
  });
};
