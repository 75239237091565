import { useCallback, useEffect, useState } from 'react';
import { Order } from '@commons/order';

export const ORDERS_PER_PAGE = 10;
export const useOrdersListPagination = (items: Order[]) => {
  const [allOrders, setAllOrders] = useState<Order[]>(items);
  const [orders, setOrders] = useState(allOrders.slice(0, ORDERS_PER_PAGE));

  useEffect(() => setOrders(items.slice(0, ORDERS_PER_PAGE)), [items]);

  const removeOrder = useCallback((orderId: string) => {
    setOrders((prevOrders) => {
      return prevOrders.filter((order) => orderId !== order.orderId);
    });
    setAllOrders((prevOrders) => {
      return prevOrders.filter((order) => orderId !== order.orderId);
    });
  }, []);

  const getMoreOrders = useCallback(() => {
    if (orders.length < allOrders.length) {
      setOrders((prevRenderedOrders) => {
        const step = Math.min(ORDERS_PER_PAGE, allOrders.length - orders.length);
        return allOrders.slice(0, prevRenderedOrders.length + step);
      });
    }
  }, [allOrders, orders.length]);

  return {
    removeOrder,
    getMoreOrders,
    orders,
    hasMore: allOrders.length > orders.length,
  };
};
