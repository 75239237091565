import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLoadHtml } from '@hooks/useLoadHtml/useLoadHtml';
import { externalContent } from '@constants/routing';

interface SpecialInstructionsInfoModalProps {
  open: boolean;
  onClose: () => void;
}

export const SpecialInstructionsInfoModal = ({
  open,
  onClose,
}: SpecialInstructionsInfoModalProps) => {
  const htmlText = useLoadHtml(externalContent.specialInstructionsInfo);
  return (
    <ModalWindow open={open} onClose={onClose}>
      <>
        {htmlText && (
          <div
            dangerouslySetInnerHTML={{
              __html: htmlText,
            }}
          />
        )}
      </>
    </ModalWindow>
  );
};
