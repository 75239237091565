import { GeolocationResult as GeolocationResultApi } from '@api';
import { GeolocationResult } from '@commons/deliveryAddresses';
import { addressAdapter } from './addressAdapter';

export const geolocationAdapter = (geolocation?: GeolocationResultApi): GeolocationResult => {
  const { getAddress } = addressAdapter();
  return {
    isSuccess: !!geolocation?.success,
    isDeliverable: !!geolocation?.deliverable,
    expressEnabled: !!geolocation?.expressEnabled,
    address: geolocation?.address ? getAddress(geolocation.address) : null,
  };
};
