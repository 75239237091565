import { ConfOptionInput } from '@commons/order';
import { getAddToOrderVariables } from '@hooks/order/useAddToOrderLightV2';
import { CartLineLight, MinimumCartLine } from '@commons/cart';
import {
  getCountForConfigurableProduct,
  getCountForSoldBySalesUnit,
} from '@hooks/cart/useCartLineItem';
import { AutosuggestProduct, Product, ProductTile } from '@commons/product';
import { getAddToCartVariables } from '@hooks/cart/useAddToCart';
import {
  getCountForConfigurableProductLight,
  getCountForSoldBySalesUnitLight,
} from '@hooks/cart/useCartLineItemLight';

type AddProduct = ProductTile | AutosuggestProduct | Product;

export interface ParamsContext {
  itemPosition: number;
  listName: string;
  sponsored?: boolean;
  productIds: string[];
}

export interface Params {
  quantity: number;
  configuration?: ConfOptionInput[];
  salesUnit?: string;
  product: ProductTile | AutosuggestProduct;
  eventSource?: string;
}

export interface GetAddToCartParams {
  product: AddProduct;
  quantity: number;
  configuration?: ConfOptionInput[];
  itemPosition: number;
  itemListName: string;
  salesUnit?: string;
  eventSource?: string;
}

export interface GetAddToOrderParams {
  product: AddProduct;
  quantity: number;
  configuration?: ConfOptionInput[];
  salesUnit?: string;
  eventSource?: string;
}

export const getAddToCartParams = ({
  product,
  quantity,
  configuration,
  itemPosition,
  salesUnit,
  itemListName,
  eventSource,
}: GetAddToCartParams) =>
  getAddToCartVariables({
    productId: product.productId,
    categoryId: product.categoryId,
    skuCode: product.skuCode,
    salesUnit: salesUnit || product.salesUnits[0].alternateSalesUnit || '',
    quantity,
    configuration: configuration ?? [],
    itemPosition,
    itemListName,
    eventSource,
  });

export const getAddToOrderParams = ({
  product,
  quantity,
  configuration,
  salesUnit,
  eventSource = 'pdp_main',
}: GetAddToOrderParams) =>
  getAddToOrderVariables({
    productId: product.productId,
    categoryId: product.categoryId,
    skuCode: product.skuCode,
    salesUnit: salesUnit || product.salesUnits[0].alternateSalesUnit || '',
    quantity,
    configuration: configuration ?? [],
    eventSource,
  });

const getCartLineItem = (
  cartLines: MinimumCartLine[],
  productId: string,
  soldBySalesUnit = false,
) => {
  const cartLineItem = cartLines.find((cartLine) => cartLine.productId === productId);
  const count = soldBySalesUnit
    ? getCountForSoldBySalesUnit(productId, cartLines)
    : getCountForConfigurableProduct(productId, cartLines);

  return {
    data: cartLineItem,
    count: count,
  };
};

const getCartLineItemLight = (
  cartLines: CartLineLight[],
  productId: string,
  soldBySalesUnit = false,
) => {
  const cartLineItem = cartLines.find((cartLine) => cartLine.productId === productId);
  const count = soldBySalesUnit
    ? getCountForSoldBySalesUnitLight(productId, cartLines)
    : getCountForConfigurableProductLight(productId, cartLines);

  return {
    data: cartLineItem,
    count: count,
  };
};

interface IGetProductInfoFromCartLines {
  cartLineId: string;
  variables: { name: string; value: string }[];
  salesUnit?: string;
  count: number;
}

interface IGetProductInfoFromCartLinesLight {
  cartLineId: string;
  salesUnit?: string;
  count: number;
}

export interface IGetProductInfoFromCart {
  loading: boolean;
  data: IGetProductInfoFromCartLines;
}

export interface IGetProductInfoFromCartLight {
  loading: boolean;
  data: IGetProductInfoFromCartLinesLight;
}

export const getProductInfoFromCartLines = (
  cartLines: MinimumCartLine[],
  productId: string,
  soldBySalesUnit = false,
): IGetProductInfoFromCartLines => {
  const { data: cartLineItem, count } = getCartLineItem(cartLines, productId, soldBySalesUnit);
  return {
    cartLineId: cartLineItem?.id || '',
    variables:
      cartLineItem?.confOptions.map((item) => ({
        name: item?.characteristicName,
        value: item?.characteristicValue,
      })) || [],
    salesUnit: cartLineItem?.selectedSalesUnit || undefined,
    count,
  };
};

export const getProductInfoFromCartLinesLight = (
  cartLines: CartLineLight[],
  productId: string,
  soldBySalesUnit = false,
): IGetProductInfoFromCartLinesLight => {
  const { data: cartLineItem, count } = getCartLineItemLight(cartLines, productId, soldBySalesUnit);
  return {
    cartLineId: cartLineItem?.id || '',
    salesUnit: cartLineItem?.selectedSalesUnit || undefined,
    count,
  };
};
