import { ApolloCache } from '@apollo/client';
import { LightweightCart } from '@api';
import { expressItemsCountVar } from '@graphql/variables/expressItemsNotification';

export const updateLightCartCache = (cache: ApolloCache<unknown>, newCart: LightweightCart) => {
  cache.modify({
    fields: {
      lightweightCart() {
        expressItemsCountVar(newCart?.expressDeliveryNotification?.expressItemsCount ?? null);
        return {
          ...newCart,
        };
      },
    },
  });
};
