import { LazyQueryExecFunction, QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import { Exact, LastDeliveredOrdersQuery, LastDeliveredOrdersQueryVariables } from '@api';
import { LAST_DELIVERED_ORDERS } from '@graphql/order/queries/lastDeliveredOrders';
import { LastDeliveredOrderInfo, LastDeliveredOrder } from '@commons/order';

type ExtendedQueryData = Omit<
  QueryResult<
    LastDeliveredOrdersQuery,
    Exact<{
      [key: string]: never;
    }>
  >,
  'data'
> & { data: LastDeliveredOrder };

type Output = [
  LazyQueryExecFunction<LastDeliveredOrdersQuery, Exact<{ [key: string]: never }>>,
  ExtendedQueryData,
];

const mapData = (data?: LastDeliveredOrdersQuery): LastDeliveredOrder => {
  const lastDeliveredOrders: LastDeliveredOrderInfo[] = [];

  if (data?.lastDeliveredOrders) {
    for (const { orderId } of data.lastDeliveredOrders) {
      if (typeof orderId === 'string') {
        lastDeliveredOrders.push({ orderId });
      }
    }
  }

  return {
    lastDeliveredOrders,
  };
};

export const useLazyLastDeliveredOrders = (
  options?: QueryHookOptions<LastDeliveredOrdersQuery, LastDeliveredOrdersQueryVariables>,
): Output => {
  const [fetch, queryData] = useLazyQuery<
    LastDeliveredOrdersQuery,
    LastDeliveredOrdersQueryVariables
  >(LAST_DELIVERED_ORDERS, {
    ...options,
    fetchPolicy: 'no-cache',
  });

  return [
    fetch,
    {
      ...queryData,
      data: mapData(queryData.data),
    },
  ];
};
