import { Product, ProductTile } from '@commons/product';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductPositionContext } from '@modules/ga/context/position';
import { fireSelectItemEvent } from '@modules/ga/events/ecommerce/select-item/selectItemCreator';

export const useSelectItemEvent = () => {
  const { getProductPosition } = useProductPositionContext();
  const { getListName } = usePageListName();

  const trackSelectItem = (product: ProductTile | Product, listName?: string): void => {
    const itemPosition = getProductPosition(product.productId);
    const itemListName = listName ?? getListName(product);
    fireSelectItemEvent({ product, itemPosition, itemListName });
  };

  return {
    trackSelectItem,
  };
};
