import { gql } from '@apollo/client';

export const GET_REFER_A_FRIEND_INVITES = gql`
  query ReferAFriendInvites {
    referAFriendInvites {
      allInvites {
        email
        date
        status
        credit
      }
    }
  }
`;

export const GET_REFER_A_FRIEND = gql`
  query ReferAFriend {
    referAFriend {
      enabled
      balance
      message
      referralUrl
    }
  }
`;
