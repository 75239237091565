import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const SUBTOTAL_BOX_FRAGMENT = gql`
  fragment subtotalBoxFragment on CartSubtotalBox {
    id
    text
    value {
      ...priceFragment
    }
    other {
      mark
      isDeliveryPassApplied
      isDeliveryPassFreeTrialEligible
    }
  }
  ${PRICE_FRAGMENT}
`;
