import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { routing, noLoginRoutes } from '@constants/routing';
import { freshDirectFD as FreshDirectIcon } from '@assets/icons/logos';
import { close as CloseIcon, info as InfoIcon, user as UserIcon } from '@assets/icons/system';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { Button } from '@components/UI';
import { useAuthModalContext } from '@context/AuthModalContext';
import { useGeolocation } from '@hooks/deliveryAddress/useGeolocation';
import { NavigationData } from '@hooks/header/useNavigationData';
import { useLocalization } from '@hooks/useLocalization';
import { AddressButton } from '@layouts/Header/components/TopLine/components/Address/AddressButton';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { ContactUsModalProvider } from '@context/contactUsModalContext';
import { AccountNavigationMenu } from './components/AccountNavigationMenu/AccountNavigationMenu';
import { PrimaryNavigation } from './components/PrimaryNavigation/PrimaryNavigation';
import { SubMenuModalData } from './components/PrimaryNavigation/SubMenuModalData';
import styles from './Hamburger.module.scss';

interface HamburgerProps {
  setDrawer: (open: boolean, event?: React.KeyboardEvent) => void;
  onAddressButtonClick: () => void;
  navigationData: NavigationData;
  isModifyMode?: boolean;
}

export const Hamburger = ({
  setDrawer,
  onAddressButtonClick,
  navigationData,
  isModifyMode,
}: HamburgerProps) => {
  const { t } = useLocalization('header');
  const authModalContext = useAuthModalContext();
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const { data: geolocationData } = useGeolocation();
  const [subMenuData, setSubMenuData] = useState<SubMenuModalData | null>(null);
  const closeMenu = () => setDrawer(false);
  const router = useRouter();
  const currentRoute = router.route || '';
  const isNoLoginRoute = noLoginRoutes.some((noLoginRoute) =>
    currentRoute.startsWith(noLoginRoute),
  );
  const handleLogoClick = () => {
    router.push(routing.home);
    closeMenu();
  };

  const handleSigninClick = () => {
    closeMenu();
    authModalContext?.toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
  };

  const handleSignupClick = () => {
    closeMenu();
    authModalContext?.toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT);
  };

  return (
    <div data-testid="hamburger" className={styles.hamburger_wrapper} role="presentation">
      <div className={styles.hamburger_inner_wrapper}>
        <div className={styles.top_menu}>
          <div className={styles.inner_wrapper}>
            <IconButton className={styles.home_button} onClick={handleLogoClick}>
              <FreshDirectIcon width={23} height={23} aria-label={t('goToHome')} />
            </IconButton>
            <Typography
              className={cx(styles.delivery, { [styles.hidden]: isModifyMode })}
              variant="h5"
            >
              {t('deliveryTitle')}{' '}
              {!geolocationData && (
                <IconButton
                  onClick={() => {
                    return;
                  }}
                >
                  <InfoIcon className={styles.info_icon} width={20} height={20} />
                </IconButton>
              )}
              <AddressButton
                className={cx(styles.delivery_address, { [styles.hidden]: isModifyMode })}
                onClick={onAddressButtonClick}
              />
            </Typography>
          </div>
          {!Boolean(subMenuData) && (
            <IconButton
              className={styles.close_button}
              onClick={() => setDrawer(false)}
              aria-label={t('common:buttons.close')}
            >
              <CloseIcon width={13} height={13} />
            </IconButton>
          )}
        </div>
        <PrimaryNavigation
          navigationData={navigationData}
          closeMenu={closeMenu}
          subMenuData={subMenuData}
          setSubMenuData={setSubMenuData}
        />
      </div>
      {!isNoLoginRoute && (
        <div>
          <Divider className={styles.divider} />
          <div className={styles.list_wrapper}>
            <div className={cx(styles.list_item, styles.account_list_item, styles.list_link_text)}>
              <div className={cx(styles.list_image, styles.account_list_image)}>
                <UserIcon width={20} />
              </div>
              {isLoggedIn ? (
                <ContactUsModalProvider>
                  <AccountNavigationMenu closeDrawer={() => setDrawer(false)} />
                </ContactUsModalProvider>
              ) : (
                <>
                  <Button
                    variant="underline"
                    onClick={handleSigninClick}
                    className={styles.link}
                    data-qa="user_sign_in"
                  >
                    {t('signIn')}
                  </Button>
                  &nbsp;
                  <Typography component="span" variant="h5" className={styles.link_connector}>
                    {t('or')}
                  </Typography>
                  &nbsp;
                  <Button variant="underline" onClick={handleSignupClick} className={styles.link}>
                    {t('createAccount')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
