import { useLocalStorage, STORAGE_KEYS } from '@utils/storageService';

export const EMPTY_REFERER_INFO = {
  refererName: '',
  refererInvite: '',
};

export const useRefererInfo = () => {
  const [refererInfo, setRefererInfo] = useLocalStorage(
    STORAGE_KEYS.REFERER_INFO,
    EMPTY_REFERER_INFO,
  );
  const removeRefererInfo = () => {
    setRefererInfo(EMPTY_REFERER_INFO);
  };

  return {
    refererInfo,
    setRefererInfo,
    removeRefererInfo,
  };
};
