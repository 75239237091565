export const PRODUCT_CACHE_FIELDS = [
  'pastOrders',
  'pastItems',
  'categorySearch',
  'subcategorySearch',
  'productSearch',
  'saleProductSearch',
  'deals',
  'coupons',
  'salesCarousels',
  'freshDealsModule',
  'superDepartmentSearch',
  'departmentSearch',
  'homepageFeed',
  'feed',
  'product',
  'suggest',
  'shoppingLists',
  'reorderFeed',
  'section',
  'superDepartmentInfo',
  'newProductSearch',
  'groupScaleProducts',
];

export const CAROUSELS_CACHE_FIELDS = ['mostPopularProductsCarousel', 'reorderCarousel'];
