export enum GTM_ADD_TO_LIST_EVENTS {
  OPEN = 'addToListButtonClicked',
  EXIST = 'addToList',
  NEW = 'addToNewList',
  CONFIRMATION = 'confirmationButtonClicked',
}
export interface PGtmAddToListEvent {
  action: string;
  productATL: string;
}

export interface IGtmAddToListEvent {
  event: string;
  action: string;
  productATL: string;
}

export const GtmAddToListEvent = (p: PGtmAddToListEvent): IGtmAddToListEvent => {
  return {
    event: 'addToList',
    action: p.action,
    productATL: p.productATL,
  };
};
