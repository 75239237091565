import { ADDRESS_SERVICE_TYPE, SERVICE_TYPE } from '@commons/deliveryAddresses';

export const getDeliveryAddressServiceType = (
  availableServiceTypes: SERVICE_TYPE[] | null,
  cosFeatureEnabled = false,
): ADDRESS_SERVICE_TYPE => {
  const isHome = availableServiceTypes?.some((serviceType) => serviceType === SERVICE_TYPE.HOME);
  const isCorporate = availableServiceTypes?.some(
    (serviceType) => serviceType === SERVICE_TYPE.CORPORATE,
  );

  if (isHome && isCorporate) {
    return cosFeatureEnabled ? ADDRESS_SERVICE_TYPE.DUAL : ADDRESS_SERVICE_TYPE.RESIDENTIAL;
  }

  if (isCorporate && !isHome) return ADDRESS_SERVICE_TYPE.CORPORATE;

  return ADDRESS_SERVICE_TYPE.RESIDENTIAL;
};
