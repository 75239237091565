import { gql } from '@apollo/client';
import { PRODUCT_EXTENDED_FRAGMENT } from '@graphql/fragments/product/productExtendedFragment';

export const GET_PRODUCT_BY_ID = gql`
  query Product($productId: ID!) {
    product(productId: $productId) {
      ...productExtendedFragment
    }
  }
  ${PRODUCT_EXTENDED_FRAGMENT}
`;
