import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { fireEvent } from '@modules/ga/emitter';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

export const fireRWProductClickGAEvent = (productId: string, variantId: string) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.RW_PRODUCT_CLICK,
    data: { product: { productId, variantId } },
  });

export const fireRWProductAddToCartGAEvent = (productId: string, variantId: string) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.RW_PRODUCT_ADD_TO_CART,
    data: { product: { productId, variantId } },
  });

export const fireRWExternalWidgetViewGAEvent = (totalProductCount: number) =>
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.RW_EXTERNAL_WIDGET_VIEW_GA_EVENT,
    data: { totalProductCount: totalProductCount },
  });
