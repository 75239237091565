import Image from 'next/image';
import cx from 'classnames';
import { useLocalization } from '@hooks/useLocalization';
import { BaseGiftCard } from '@adapters/giftCardAdatper';
import styles from './GiftCardSingleView.module.scss';

interface GiftCardSingleViewProps {
  giftCard: BaseGiftCard;
}

export const GiftCardSingleView = ({ giftCard }: GiftCardSingleViewProps) => {
  const { t } = useLocalization('giftCards');

  return (
    <>
      <div className={styles.image_container}>
        {giftCard?.gcTemplate?.gcTemplateImageLink && (
          <Image
            src={giftCard.gcTemplate.gcTemplateImageLink}
            alt=""
            className={styles.image}
            fill
          />
        )}
      </div>
      <div className={styles.info}>
        <div className={cx(styles.info_item)}>
          <span className={styles.from}>{t('giftCardBag.from')}: </span>
          <span>{giftCard.senderName}</span>
        </div>
        <div className={cx(styles.info_item)}>
          <span className={styles.to}>{t('giftCardBag.to')}: </span>
          <span>
            {giftCard.recipientName}, {giftCard.recipientEmail}
          </span>
        </div>
        {!!giftCard.message && (
          <div className={cx(styles.info_item, styles.message)}>
            <span>{t('giftCardBag.message')}: </span>
            <span>{giftCard.message}</span>
          </div>
        )}
        <div className={cx(styles.info_item, styles.message)}>
          <span>{t('giftCardBag.deliveryMethod')}: </span>
          <span>{t(`giftCardBag.deliveryMethodType.${giftCard.deliveryMethod}`)}</span>
        </div>
      </div>
    </>
  );
};
