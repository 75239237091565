import { useEffect } from 'react';
import {
  underOrderMinimumError,
  underCheckoutOrderMinimumError,
  usePageValidationError,
} from '@context/pageValidationErrorContext';

export const useFilledBagOrderMinimumError = (current: number, minimum: number) => {
  const { error, clear } = usePageValidationError();

  const hasOrderMinimumError = [underOrderMinimumError, underCheckoutOrderMinimumError].includes(
    error?.type ?? '',
  );

  useEffect(() => {
    if (hasOrderMinimumError && current >= minimum) {
      clear();
    }
  }, [clear, hasOrderMinimumError, current, minimum]);

  useEffect(() => {
    return () => {
      handleClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (hasOrderMinimumError) {
      clear();
    }
  };

  return {
    close: handleClose,
    error: hasOrderMinimumError ? error?.message : '',
  };
};
