import { ReactNode } from 'react';
import { OrderDeliveryModal } from '@components/OrderDeliveryModal/OrderDeliveryModal';
import { on, emit } from '@modules/emitter';
import { OrderDeliveryModalContext } from './OrderDeliveryModalContext';

interface OrderDeliveryModalProviderProps {
  children: ReactNode;
}

export const OrderDeliveryModalProvider = ({ children }: OrderDeliveryModalProviderProps) => {
  return (
    <OrderDeliveryModalContext.Provider value={{ on, emit }}>
      <OrderDeliveryModal />
      {children}
    </OrderDeliveryModalContext.Provider>
  );
};
