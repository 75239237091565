import cx from 'classnames';
import { useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { getImageUrl } from '@features/ProductPage/utils/getImageUrl';
import { getSrcSet } from '@utils/imageUtils';
import { ProductImage } from '@commons/product';
import { ProductPageImageCarousel } from '../ProductPageImageCarousel/ProductPageImageCarousel';
import styles from './ProductPageImageBlock.module.scss';

interface ProductPageImageBlockProps {
  image: ProductImage | null;
  imageCarousel: ProductImage[] | null;
  className?: string;
  isDisabled?: boolean;
}

export const ProductPageImageBlock = ({
  className,
  image,
  imageCarousel = [],
  isDisabled = false,
}: ProductPageImageBlockProps) => {
  const hasImage = !!(image?.largeRef || image?.ref);
  const hasCarousel = imageCarousel && imageCarousel.length > 1;
  const defaultImage = hasCarousel ? imageCarousel[0] : image;
  const [selectedImage, setSelectedimage] = useState<ProductImage | null>(defaultImage);
  const selectedImageUrl = getImageUrl(selectedImage);

  const handleChangeSelectedImage = (choosedImage: ProductImage) => {
    setSelectedimage(choosedImage);
  };

  useUpdateEffect(() => {
    setSelectedimage(defaultImage);
  }, [defaultImage]);

  if (!hasImage) {
    return null;
  }

  return (
    <>
      <div
        className={cx(styles.image_wrapper, className, { [styles.disabled]: isDisabled })}
        role="region"
        aria-label="carousel"
      >
        <div className={cx(styles.image_container)}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            sizes="(max-width: 768px) 100vw, (max-width: 1400px) 50vw, 1000px"
            srcSet={getSrcSet(selectedImageUrl)}
            src={selectedImageUrl}
            alt={selectedImage?.alt ?? 'product image'}
            data-qa="pdp_image"
            fetchPriority="high"
          />
        </div>
        {hasCarousel && (
          <ProductPageImageCarousel
            images={imageCarousel}
            onChange={handleChangeSelectedImage}
            selectedImage={selectedImage}
          />
        )}
      </div>
    </>
  );
};
