import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const GET_CART = gql`
  query GetCart {
    cartForCheckout {
      ...cartFragment
    }
  }
  ${CART_FRAGMENT}
`;

export const CART_CACHE_FIELD = 'cartForCheckout';
