import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GetFeatureEligibilitiesQuery, GetFeatureEligibilitiesQueryVariables } from '@api';
import { GET_FEATURE_ELIGIBILITIES } from '@graphql/common/queries/getFeatureEligibilities';
import { FULL_FEATURES_LIST } from '@commons/featureList';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';

function getFeaturesMap(data?: GetFeatureEligibilitiesQuery): Map<FEATURE, boolean> {
  const features = data?.featureEligibilities ?? [];
  return features.reduce((acc, cur) => {
    acc.set(cur.key as FEATURE, Boolean(cur.value));
    return acc;
  }, new Map<FEATURE, boolean>());
}

export const useFeatureEligibilityQuery = () => {
  return useQuery<GetFeatureEligibilitiesQuery, GetFeatureEligibilitiesQueryVariables>(
    GET_FEATURE_ELIGIBILITIES,
    {
      variables: {
        features: FULL_FEATURES_LIST,
      },
    },
  );
};

export const useFeatureEligibility = () => {
  const { data } = useFeatureEligibilityQuery();

  const getFeature = (feature: FEATURE): boolean => {
    const featuresMap = getFeaturesMap(data);

    if (!featuresMap.has(feature)) {
      return false;
    }

    return Boolean(getFeaturesMap(data).get(feature));
  };

  const getResult = () => {
    return { getFeature };
  };

  return useMemo(getResult, [data]);
};
