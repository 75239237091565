export const isSameOrigin = (url: string): boolean => {
  if (!url) {
    return false;
  }

  return url.startsWith(`${location.protocol}//${location.host}`);
};

export const isCurrentUrlEqualTo = (url: string): boolean => {
  return removeTrailingSlash(url) === removeTrailingSlash(location.href);
};

function removeTrailingSlash(str: string): string {
  return str.replace(/\/+$/, '');
}

export const getFirstRefererNotEqualTo = (regexp: RegExp, history: string[]) => {
  let redirectUrl;

  for (let i = history.length - 1; i >= 0; i--) {
    if (!regexp.test(history[i])) {
      redirectUrl = history[i];
      break;
    }
  }

  return redirectUrl;
};

interface UrlQueries {
  [key: string]: string;
}

export const getUrlQueries = (): UrlQueries => {
  let params = {};
  const searchParams = new URLSearchParams(window.location.search.replace('?', ''));
  searchParams.forEach((value, key) => {
    params = { ...params, [key]: value };
  });

  return params;
};

export const simplifyQueryParameter = (param: string | string[] | undefined) => {
  if (Array.isArray(param)) {
    return param[0];
  }
  return param || '';
};

const hashComponents = (hash: string): string[] => {
  return decodeURIComponent(hash)
    .replace(/^#/, '')
    .split('|')
    .filter((part) => !!part);
};

export const isInHash = (hash: string): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  return hashComponents(window.location.hash).includes(hash);
};

export const addToHash = (hash: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const parts = hashComponents(window.location.hash);
  parts.push(hash);

  window.location.hash = parts.join('|');
};

export const removeFromHash = (hash: string) => {
  const parts = hashComponents(window.location.hash).filter((part) => part !== hash);

  window.location.hash = parts.join('|');
};
