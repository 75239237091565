import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useMinimalStandingOrderInfo } from '@features/StandingOrders/hooks/useMinimalStandingOrderInfo';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useServiceType } from '@hooks/useServiceType';

export const useStandingOrdersAvailability = () => {
  const { isKnownUser } = useAuthContext();
  const { getFeature } = useHeaderContext();
  const { isCorporateServiceType } = useServiceType();
  const { enabled: expressEnabled } = useExpressContext();

  const {
    standingOrdersLength,
    validStandingOrdersLength,
    loading: isStandingOrderLoading,
  } = useMinimalStandingOrderInfo();

  const hasStandingOrders = !isStandingOrderLoading && !!standingOrdersLength;

  const isStandingOrderEligible =
    isKnownUser && (isCorporateServiceType || hasStandingOrders) && !expressEnabled;

  const isStandingOrderVisible = isStandingOrderEligible && !!getFeature?.(FEATURE.STANDING_ORDERS);

  const isStandingOrderAvailable = isStandingOrderVisible && !!getFeature?.(FEATURE.COS);

  return {
    isStandingOrderAvailable,
    isStandingOrderEligible,
    isStandingOrderVisible,
    hasValidStandingOrders: !!validStandingOrdersLength,
    isStandingOrderLoading,
  };
};
