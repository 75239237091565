import { Tab, TabProps } from '@mui/material';
import cx from 'classnames';
import { check as CheckIcon } from '@assets/icons/system';
import { PAYMENT_TABS } from '@commons/payment';
import styles from './PaymentTabButton.module.scss';

interface PaymentTabButtonProps extends TabProps {
  tabValue: PAYMENT_TABS;
  activeTab: PAYMENT_TABS;
}

const disableRippleEffectProps = {
  disableRipple: true,
  disableTouchRipple: true,
  disableFocusRipple: true,
};

export const PaymentTabButton = ({
  tabValue,
  activeTab,
  className,
  hidden,
  ...props
}: PaymentTabButtonProps) => {
  const isActiveTab = tabValue === activeTab;
  const tabClassName = cx(
    styles.tab,
    { [styles.active]: isActiveTab, [styles.hidden]: hidden },
    className,
  );
  return (
    <Tab
      {...disableRippleEffectProps}
      {...props}
      className={tabClassName}
      value={tabValue}
      id={`payment-tab-${tabValue}`}
      aria-controls={`payment-tabpanel-${tabValue}`}
      icon={<CheckIcon width={13} className={cx({ [styles.hidden]: !isActiveTab })} />}
      iconPosition="end"
      tabIndex={0}
    />
  );
};
