import { ApolloLink } from '@apollo/client';
import { getGQLOperationNameFromDocumentNode } from '@graphql/links/utils';

export const gqlOperationLink = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation.setContext((ctx: Record<string, any>) => {
    return {
      ...ctx,
      headers: {
        ...ctx.headers,
        GqlOperation: getGQLOperationNameFromDocumentNode(operation.query),
      },
    };
  });
  return forward(operation);
});
