import cx from 'classnames';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import { PromoCodeField } from '@components/PromoCodeField/PromoCodeField';
import { warning as WarningIcon } from '@assets/icons/system';
import { PromoStatus } from '@commons/promo';
import { useMobilePromoModalContext } from '@context/MobilePromoModalContext/MobilePromoModalContext';
import styles from './CartPromo.module.scss';

interface CartPromoProps {
  promoStatus: PromoStatus;
  promoCode?: string;
}

export const CartPromo = ({ promoStatus, promoCode }: CartPromoProps) => {
  const { t } = useLocalization();
  const { isSmallScreen } = useHeaderContext();
  const upLaptop = !isSmallScreen;

  const applied = promoStatus === 'applied';
  const condition = promoStatus === 'condition';

  const buttonName = applied || condition ? promoCode : t('buttons.addPromo');

  const { onOpen } = useMobilePromoModalContext();

  return upLaptop ? (
    <>
      <span className="sr_only">{t('checkout:promoOffscreenText')}</span>
      <PromoCodeField className={styles.promo_container} />
    </>
  ) : (
    <div className={styles.mobile_promo}>
      <Button
        size="large"
        onClick={onOpen}
        variant="underline"
        className={cx(styles.mobile_promo_button, {
          [styles.condition]: condition,
        })}
      >
        {buttonName}
      </Button>
      {condition && <WarningIcon height={14} width={14} className={styles.warning_icon} />}
    </div>
  );
};
