import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useAddHostedPayment } from '@hooks/payments/useAddHostedPayment';
import { AddHostedPaymentMutation } from '@api';
import { useLocalization } from '@hooks/useLocalization';
import { PAYMENT_TYPE } from '@commons/payment';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';
import { PaymentsOnSuccess } from '@components/PaymentsAddTabs/PaymentAddTabs.types';
import styles from './AddCardForm.module.scss';

interface AddCardFormProps {
  url: string;
  onError: Dispatch<SetStateAction<string>>;
  onSuccess?: PaymentsOnSuccess;
}

enum POST_MESSAGE_RESPONSE {
  HANDLE_PAYMENT_ERRORS = 'handlePaymentErrors',
  COMPLETE_PAYMENT = 'completePayment',
}

export const AddCardForm = ({ url, onSuccess = () => void 0, onError }: AddCardFormProps) => {
  const [successResponse, setSuccessResponse] = useState<{ code: string; uid: string }>({
    code: '',
    uid: '',
  });
  const { t } = useLocalization('account');
  const [addHostedPayment] = useAddHostedPayment();
  const standingOrderMode = useStandingOrderMode();

  const handlePostMessageResult = useCallback(
    // Don't have an interface for that Event
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (e.data?.subject === POST_MESSAGE_RESPONSE.HANDLE_PAYMENT_ERRORS) {
        const errorCode = e.data.content?.errorCode[0];
        addHostedPayment({
          variables: {
            input: { code: errorCode },
            standingOrderMode,
          },
          onCompleted: (res) => {
            const errorMessage = res.addHostedPayment.validationResult.errors[0].error || '';
            onError(errorMessage);
          },
        });
      }
      if (e.data.subject === POST_MESSAGE_RESPONSE.COMPLETE_PAYMENT) {
        setSuccessResponse({ code: e.data.content.code, uid: e.data.content.uID });
      }
    },
    [addHostedPayment, onError, standingOrderMode],
  );

  const getPaymentInfo = (paymentInfo: AddHostedPaymentMutation) => {
    const payment = paymentInfo?.addHostedPayment?.payments?.payments?.find(
      ({ isDefault }) => isDefault,
    );
    const cardNumber = payment?.accountNumber?.slice(4) || '';
    const cardType = payment?.type || '';

    return {
      cardNumber,
      cardType,
      payment,
    };
  };

  useEffect(() => {
    if (!!successResponse?.code) {
      const input = {
        code: successResponse?.code,
        uid: successResponse?.uid,
      };
      addHostedPayment({
        variables: {
          input,
          standingOrderMode,
        },
        onCompleted: (res) => {
          const firstValidationError = (res?.addHostedPayment?.validationResult?.errors ?? [])[0];
          if (!!firstValidationError) {
            return onError(firstValidationError?.error);
          }
          const { cardNumber, cardType, payment } = getPaymentInfo(res);
          onSuccess(
            cardType as PAYMENT_TYPE,
            t('payments.successMessages.addCard', { cardType, cardNumber }),
            payment,
          );
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successResponse, addHostedPayment]);

  //Post message approach is described in library's docs
  useEffect(() => {
    window.addEventListener('message', handlePostMessageResult);
    return () => {
      removeEventListener('message', handlePostMessageResult);
    };
  }, [handlePostMessageResult]);

  //The Chasepaymentechhostedpay library shows native JS alerts when the user filled the form incorrectly.
  //This function avoid showing these unused alerts.
  useEffect(() => {
    const oldAlert = window.alert;
    window.alert = function () {
      return false;
    };
    return () => {
      window.alert = oldAlert;
    };
  }, []);

  return (
    <div className={styles.iframe_wrapper}>
      <iframe src={url} title={t('payments.addCardForm')} />
    </div>
  );
};
