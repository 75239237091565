import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '@graphql/fragments/common/customerFragment';

export const SIGN_UP = gql`
  mutation Signup($input: SignUpInput!) {
    signup(input: $input) {
      accessToken
      customer {
        ...customerFragment
      }
      id
      authEvent {
        loginId
        signupId
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;
