import { ApolloClient } from '@apollo/client';
import { ACCOUNT_PREFERENCES, MINIMUM_ACCOUNT_PREFERENCES } from '@graphql/account';

export const queryMinimumAccountPreferences = async (client: ApolloClient<object>) =>
  await client.query({
    query: MINIMUM_ACCOUNT_PREFERENCES,
  });

export const queryAccountPreferences = async (client: ApolloClient<object>) =>
  await client.query({ query: ACCOUNT_PREFERENCES });
