import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { fireCommonCustomPageView } from '@modules/ga/events/custom/custom-page-view/customPageViewCreator';
import { useEffectOnceWithCondition } from '@hooks/useEffectOnceWithCondition';
import { useEcomCart } from '@modules/ga/hooks/useEcomCart';
import { isExistPageViewInDataLayer } from '@modules/ga/utils/isExistPageViewInDataLayer';
import { getCurrentPageType } from '@modules/ga/utils/getPageType';
import { PAGE_TYPE } from '@constants/pageType';

export const useAnalyticsPageView = (exceptionPageType: PAGE_TYPE[]) => {
  const router = useRouter();
  const trackEcomCart = useEcomCart();

  const trackPageView = useCallback(
    (url: string) => {
      const isExceptionPageType = exceptionPageType.includes(getCurrentPageType());
      const shouldFireEvent = !isExistPageViewInDataLayer() && !isExceptionPageType;

      if (shouldFireEvent) {
        trackEcomCart();

        fireCommonCustomPageView({
          url,
        });
      }
    },
    [exceptionPageType, trackEcomCart],
  );

  useEffect(() => {
    const handleRouterComplete = (url: string) => {
      trackPageView(url);
    };

    router.events.on('routeChangeComplete', handleRouterComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouterComplete);
    };
  }, [router.events, trackPageView]);

  useEffectOnceWithCondition(() => {
    trackPageView(router.asPath);
  }, true);
};
