import { AuthInfo } from '@commons/auth';
import { Customer } from '@commons/customer';
import { Customer as CustomerApi } from '@api';
import { AccountNotifications } from '@commons/account';

const getCustomerNotifications = (
  notifications?: Partial<AccountNotifications> | null,
): AccountNotifications => {
  const sendNewsLetter = !!notifications?.sendNewsLetter;
  const orderNotices = !!notifications?.orderNotices;
  const offers = !!notifications?.offers;

  return {
    sendNewsLetter,
    orderNotices,
    offers,
  };
};

const getUserFullName = (firstName?: string | null, lastName?: string | null): string | null =>
  firstName ? `${firstName} ${lastName ?? ''}` : null;

const getShortName = (firstName?: string | null, lastName?: string | null): string | null => {
  if (!firstName) return null;

  return lastName?.[0] ? firstName : `${firstName} ${lastName}`;
};

export const getAuthInfoFromUpdate = (authData?: Partial<Customer> | null): AuthInfo => ({
  firstName: authData?.firstName ?? null,
  lastName: authData?.lastName ?? null,
  fullName: getUserFullName(authData?.firstName, authData?.lastName),
  shortName: getShortName(authData?.firstName, authData?.lastName),
  memberSinceYear: authData?.memberSinceYear ?? null,
  email: authData?.email ?? null,
  serviceType: authData?.serviceType ?? null,
  phoneNumber: authData?.phoneNumber ?? null,
  workPhoneNumber: authData?.workPhoneNumber ?? null,
  workPhoneExtension: authData?.workPhoneExtension ?? null,
  notifications: getCustomerNotifications(authData?.notifications),
  isMasqueradeMode: authData?.isMasqueradeMode,
});

export const getAuthInfoFromLogin = (authData?: Partial<CustomerApi> | null): AuthInfo => ({
  firstName: authData?.firstName ?? null,
  lastName: authData?.lastName ?? null,
  fullName: getUserFullName(authData?.firstName, authData?.lastName),
  shortName: getShortName(authData?.firstName, authData?.lastName),
  memberSinceYear: authData?.memberSinceYear ?? null,
  email: null,
  phoneNumber: null,
  notifications: null,
});
