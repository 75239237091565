import { gql } from '@apollo/client';

export const RESEND_GIFT_CARD = gql`
  mutation ResendGiftCard($input: ResendGiftCardInput!) {
    resendGiftCard(input: $input) {
      success
      errors {
        name
        error
      }
    }
  }
`;
