import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

interface FireCustomPageViewProps {
  url: string;
}

export const fireCommonCustomPageView = (data: FireCustomPageViewProps) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.COMMON_CUSTOM_PAGE_VIEW,
    data,
  });
};

interface FireSearchCustomPageViewProps extends FireCustomPageViewProps {
  searchResults: number;
  searchParams: string;
}

export const fireSearchCustomPageView = (data: FireSearchCustomPageViewProps) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.SEARCH_CUSTOM_PAGE_VIEW,
    data,
  });
};

interface FireCartCustomPageViewProps extends FireCustomPageViewProps {
  availableTimeslotValue: string;
  unavailableTimeslotPresent: string;
}

export const fireCartCustomPageView = (data: FireCartCustomPageViewProps) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.CART_CUSTOM_PAGE_VIEW,
    data,
  });
};
