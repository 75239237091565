import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { RequestCreditModalProps } from '@modules/modals/types/requestCreditModal/requestCreditModal';

export const openRequestCreditModal = (props: RequestCreditModalProps = {}) => {
  fireOpenModal<RequestCreditModalProps>(MODALS_LIST.REQUEST_CREDIT, {
    ...props,
  });
};

export const closeRequestCreditModal = () => {
  fireCloseModal(MODALS_LIST.REQUEST_CREDIT);
};
