import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const RESET_UNAVAILABLE_DATA = gql`
  mutation resetUnavailabilityData {
    resetUnavailabilityData {
      ...cartFragment
    }
  }
  ${CART_FRAGMENT}
`;
