import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from './priceFragment';

export const DELIVERY_PASS_INFO_FRAGMENT = gql`
  fragment deliveryPassInfoFragment on DeliveryPassInfo {
    memberSince
    ordersPlacedQuantity
    totalAmountSaved {
      ...priceFragment
    }
    userPlanMediaPath
    isFreeTrial
    wasMembershipActivated
    isExpired
    isCanceled
    expiration
    name
    autoRenewalEnabled
    mostRecentDPInvoiceId
    dpTypeSku
  }
  ${PRICE_FRAGMENT}
`;
