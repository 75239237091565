import React, { ChangeEvent, useRef, useState } from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { useModal } from '@hooks/common/useModal';
import { Switch } from '@components/UI/Switch/Switch';
import { useSwitchDeliveryPassAutoRenew } from '@hooks/account/useSwitchDeliveryPassAutoRenew';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ALERT_TYPES } from '@commons/account';
import { BaseLink as Link } from '@components/UI/BaseLink/BaseLink';
import { routing } from '@constants/routing';
import { AutoRenewalDisableConfirmationModal } from './components/AutoRenewalDisableConfirmationModal/AutoRenewalDisableConfirmationModal';
import styles from './AutoRenewal.module.scss';

interface AutoRenewalProps {
  enabled: boolean;
  planEndDate: string;
  mostRecentDPInvoiceId: string;
  freeTrialAvailable: boolean;
  customerServicePhoneNumber: string;
}

export const AutoRenewal = ({
  enabled,
  planEndDate,
  mostRecentDPInvoiceId,
  freeTrialAvailable,
  customerServicePhoneNumber,
}: AutoRenewalProps) => {
  const { t } = useLocalization('account');
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [switchDeliveryPassAutoRenew] = useSwitchDeliveryPassAutoRenew();
  const disableAutoRenewalModal = useModal();
  const { dispatchAlert } = useAccountAlertContext();
  const expirationDate = freeTrialAvailable
    ? t('deliveryPass.trialEnd')
    : t('deliveryPass.onPreposition', { date: planEndDate });
  const inputRef = useRef<HTMLInputElement>(null);

  const setAutoRenewal = (activated: boolean, message = '') => {
    switchDeliveryPassAutoRenew({
      variables: {
        enabled: activated,
      },
      onCompleted: () => {
        dispatchAlert(ALERT_TYPES.SUCCESS, message);
        setIsSwitchDisabled(false);
      },
    });
  };

  const handleCloseConfirmationModal = () => {
    disableAutoRenewalModal.closeModal();
    setIsSwitchDisabled(false);
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 100);
  };

  const handleDisable = () => {
    setAutoRenewal(
      false,
      t('deliveryPass.autoRenewAlertMessage', { status: t('deliveryPass.disabled') }),
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSwitchDisabled(true);
    if (!event.target.checked) {
      disableAutoRenewalModal.openModal();
    } else {
      setAutoRenewal(
        true,
        t('deliveryPass.autoRenewAlertMessage', { status: t('deliveryPass.enabled') }),
      );
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.inner_wrapper}>
          <div>
            <Typography component="p" variant="smallBody" className={styles.title}>
              {t('deliveryPass.autoRenewal')}
            </Typography>

            <Typography
              component="p"
              variant="body"
              className={cx(styles.description, styles.cta_title)}
            >
              {enabled ? (
                <span>{t('deliveryPass.enabled')}</span>
              ) : (
                <span>{t('deliveryPass.disabled')}</span>
              )}
            </Typography>
          </div>

          <Switch
            id="auto-renewal-switch"
            onChange={handleChange}
            name="auto-renewal-switch"
            checked={enabled}
            disabled={isSwitchDisabled}
            ariaLabel={t('deliveryPass.autoRenewal')}
            ref={inputRef}
          />
        </div>

        <Typography component="p" variant="body" className={cx(styles.description)}>
          {enabled ? t('deliveryPass.autoRenewMessage') : t('deliveryPass.expiresOn')}{' '}
          {expirationDate}
        </Typography>

        <Typography component="span" variant="body" className={cx(styles.title, styles.cta_title)}>
          {t('deliveryPass.autoRenewalText')}{' '}
          <a href={`tel:${customerServicePhoneNumber}`} className={styles.phone_link}>
            {t('deliveryPass.call', { phoneNumber: customerServicePhoneNumber })}
          </a>
        </Typography>

        {mostRecentDPInvoiceId && (
          <Link legacyBehavior href={`${routing.orderDetails}/${mostRecentDPInvoiceId}`}>
            <a className={cx(styles.title, styles.link)}>
              <Typography component="p" variant="body">
                {t('deliveryPass.viewLastInvoice')}
              </Typography>
            </a>
          </Link>
        )}

        <AutoRenewalDisableConfirmationModal
          opened={disableAutoRenewalModal.isOpen}
          onClose={handleCloseConfirmationModal}
          expireDate={expirationDate}
          onDisable={handleDisable}
        />
      </div>
    </>
  );
};
