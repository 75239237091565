import { gql } from '@apollo/client';
import { PRODUCT_QUANTITY_FRAGMENT } from '@graphql/fragments/common/ProductQuantityFragment';
import { PRICE_FRAGMENT } from '../common/priceFragment';
import { COUPON_FRAGMENT } from '../common/couponFragment';
import { PRODUCT_MEAL_BUNDLE_FRAGMENT } from '../common/ProductMealBundleFragment';
import { SALES_UNIT_FRAGMENT } from './common/salesUnitFragment';
import { PRODUCT_EXTERNAL_DATA_FRAGMENT } from './common/productExternalDataFragment';

export const PRODUCT_TILE_FRAGMENT = gql`
  fragment productTileFragment on ProductBasicData {
    productId
    productPageUrl
    categoryId
    skuCode
    productName
    productDescription
    brandName
    unitSize
    deal
    unitPrice
    weightDisclaimer
    estimatedWeightDisclaimer
    discountAmount {
      ...priceFragment
    }
    salesUnits {
      ...salesUnitFragment
    }
    price {
      ...priceFragment
    }
    formattedCurrentPrice
    roughPricePerUnit
    defaultScaleUnit
    pricePerScaleUnit
    scaleUnit
    servingSize
    productZoomImage
    productJumboImage
    productAlternateImage
    marketingTags {
      soldOut
      sponsored
      yourFave
      backOnline
      new
    }
    featureTags {
      topPick
      freeSample
      expressEligible
    }
    groupScale {
      grpId
      version
      grpPrice
      grpDescription
    }
    coupon {
      ...couponFragment
    }
    wasPrice {
      ...priceFragment
    }
    # TODO: NEED CHECK
    quantity {
      ...productQuantityFragment
    }
    availability
    available
    alcoholic
    preparationTime
    savingString
    # TODO: Could be removed, if get total price from cart
    availMaterialPrices {
      price
      pricingUnit
      scaleLowerBound
      scaleUpperBound
      scaleUnit
      promoPrice
    }
    # TODO: Could be removed, if get total price from cart
    cvPrices {
      charName
      charValueName
      price
      pricingUnit
      applyHow
      salesOrg
      distChannel
    }
    # TODO: Need check useApplyScalePrice
    grpPrices {
      price
      pricingUnit
      scaleLowerBound
      scaleUpperBound
      scaleUnit
      promoPrice
    }
    # TODO: Need check with getProductMetrics
    variantId
    # TODO: Need check all values
    variations {
      name
      label
      optional
      display
      underLabel
      descrPopup
      descrMedia
      values {
        name
        label
        isLabelValue
        selected
        cvp
        imagePath
        productName
        description
        variationItemProductData {
          categoryId
          productId
        }
      }
    }
    externalData {
      ...productExternalDataFragment
    }
    fdWinesAndSpirits
    soldBySalesUnit
    bundle {
      ...productMealBundleFragment
    }
    configuration {
      key
      value
    }
    clickBeacon
    imageBeacon
    viewBeacon
    recommendedProduct {
      productId
    }
  }
  ${PRICE_FRAGMENT}
  ${SALES_UNIT_FRAGMENT}
  ${COUPON_FRAGMENT}
  ${PRODUCT_QUANTITY_FRAGMENT}
  ${PRODUCT_EXTERNAL_DATA_FRAGMENT}
  ${PRODUCT_MEAL_BUNDLE_FRAGMENT}
`;
