import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import styles from './GiftCardTemplateSkeleton.module.scss';

export const GiftCardTemplateSkeleton = () => {
  return (
    <Stack spacing={4}>
      <Skeleton variant="rectangular" className={styles.tile} />
      <Skeleton variant="text" className={styles.text_tile} />
    </Stack>
  );
};
