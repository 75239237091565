export const UNSETTED_VALUE = '0';
export const MAX_RETRY_COUNT = 20;
export const WHITE_LIST = [
  '/c/',
  '/sc/',
  '/d/',
  '/sd/',
  '/fresh_deals',
  '/ecoupon',
  '/all_sale',
  '/deals_sale',
];
export const GA_TOTAL_PAGES = 'data-ga-total-pages';
export const GA_BREADCRUMBS = 'data-ga-breadcrumbs';
