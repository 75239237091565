import { gql } from '@apollo/client';

export const INITIALIZE_E_WALLET = gql`
  query InitializeEWallet($type: EWalletType!) {
    initializeEWallet(type: $type) {
      token
      validationErrors {
        name
        error
      }
    }
  }
`;
