import { useEffect, useRef, useState } from 'react';
import { pushCartToGTM } from '@modules/ga/events/custom/ga-cart-object/ga-cart-object';
import { useLightCart } from '@hooks/cart/useLightCart';
import { CartLight } from '@commons/cart';

const options = {
  notifyOnNetworkStatusChange: true,
};

export const useEcomCart = () => {
  const { data: lightCart, loading } = useLightCart({
    lightCartOptions: options,
    masqueradeLightCart: options,
  });
  const [fireEcomCart, setFireEcomCart] = useState<((cart: CartLight) => void) | undefined>(
    undefined,
  );

  const trackEcomCart = useRef(() => {
    setFireEcomCart(() => pushCartToGTM);
  });

  useEffect(() => {
    if (!loading && fireEcomCart) {
      fireEcomCart(lightCart);
      setFireEcomCart(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, fireEcomCart]);

  return trackEcomCart.current;
};
