import { gql } from '@apollo/client';

export const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on Address {
    id
    address1
    apartment
    city
    state
    zipCode
    serviceType
    isCustomerAnonymousAddress
    selected
    popupUrl
    country
  }
`;
