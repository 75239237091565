import { Typography } from '@mui/material';
import cx from 'classnames';
import React from 'react';
import {
  description as DescriptionIcon,
  nutrition as NutritionIcon,
  ingredients as IngredientsIcon,
  instructions as InstructionsIcon,
  alcoholInfo as AlcoholIcon,
} from '@assets/icons/informational';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { Nutrition, NutritionV2, NutritionLine, Product } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import { TABS, TAB_HREFS } from '../../constants';
import styles from './AdditionalInfo.module.scss';

interface AdditionalInfoProps extends Pick<Product, 'productDescription'> {
  ingredients?: string | null;
  nutrition: Nutrition;
  nutritionV2: NutritionV2;
  oldNutritionContent?: NutritionLine[] | null;
  className?: string;
  productDescription: string;
  instructionLink?: string | null;
  alcohol?: string | null;
}

interface InfoBlock {
  [key: string]: {
    icon: JSX.Element;
    name: TABS;
    href: string;
    label: string;
    hideAtLargeDevice?: boolean;
    value?: string | NutritionLine[] | null;
  };
}

const ICON_SIZE = 20;

export const AdditionalInfo = ({
  ingredients,
  nutrition,
  nutritionV2,
  oldNutritionContent,
  className,
  productDescription,
  instructionLink,
  alcohol,
}: AdditionalInfoProps) => {
  const { t } = useLocalization();
  const { isSmallScreen } = useHeaderContext();
  const isLargeDevice = !isSmallScreen;

  const INFO_BLOCKS: InfoBlock = {
    productDescription: {
      icon: <DescriptionIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: TABS.DESCRIPTION,
      href: TAB_HREFS[TABS.DESCRIPTION],
      label: t('additionalInfo.tabs.description'),
      hideAtLargeDevice: true,
      value: productDescription,
    },
    nutrition: {
      icon: <NutritionIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: TABS.NUTRITION,
      href: TAB_HREFS[TABS.NUTRITION],
      label: t('additionalInfo.tabs.nutrition'),
      value: nutrition?.panelType || nutritionV2?.id || oldNutritionContent,
    },
    ingridients: {
      icon: <IngredientsIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: TABS.INGREDIENTS,
      href: TAB_HREFS[TABS.INGREDIENTS],
      label: t('additionalInfo.tabs.ingredients'),
      value: ingredients,
    },
    instruction: {
      icon: <InstructionsIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: TABS.INSTRUCTIONS,
      href: TAB_HREFS[TABS.INSTRUCTIONS],
      label: t('additionalInfo.tabs.instructions'),
      value: instructionLink,
    },
    alcohol: {
      icon: <AlcoholIcon width={ICON_SIZE} height={ICON_SIZE} />,
      name: TABS.ALCOHOL,
      href: TAB_HREFS[TABS.ALCOHOL],
      label: t('additionalInfo.tabs.alcoholInfo'),
      value: alcohol,
    },
  };

  return (
    <div className={cx(styles.wrapper, styles.additional_info, className)}>
      {Object.keys(INFO_BLOCKS).map((key: string) => {
        const currentBlock = INFO_BLOCKS[key];
        const isHidden = isLargeDevice && currentBlock.hideAtLargeDevice;
        const IconComponent = () => currentBlock.icon;
        if (!isHidden && currentBlock.value) {
          return (
            <a
              href={currentBlock.href}
              key={currentBlock.name}
              type="button"
              className={cx(styles.item, 'button')}
              data-testid={`info-${key}`}
            >
              <IconComponent />
              <Typography component="span" variant="smallBody" className={styles.item_name}>
                {currentBlock.label}
              </Typography>
            </a>
          );
        }
      })}
    </div>
  );
};
