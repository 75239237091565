import { accountAdapter } from '@adapters/accountAdapter';
import {
  useUserDeliveryAddresses,
  UseUserDeliveryAddressOptions,
} from '@hooks/deliveryAddress/useUserDeliveryAddresses';

export const useHomeAddresses = (options?: UseUserDeliveryAddressOptions) => {
  const { data, ...queryResult } = useUserDeliveryAddresses({ queryOptions: options });
  const { getDeliveryAddressArr } = accountAdapter();

  return {
    ...queryResult,
    data: getDeliveryAddressArr(data?.userDeliveryAddresses?.homeAddresses),
  };
};
