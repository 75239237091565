import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { WelcomeSignInModalProps } from '@modules/modals/types/welcomeSignInModal/welcomeSignInModal';

export const openWelcomeSignInModal = (props: WelcomeSignInModalProps = {}) => {
  fireOpenModal<WelcomeSignInModalProps>(MODALS_LIST.WELCOME_SIGN_IN_MODAL, {
    ...props,
  });
};

export const closeWelcomeSignInModal = () => {
  fireCloseModal(MODALS_LIST.WELCOME_SIGN_IN_MODAL);
};
