import { KeyboardEvent } from 'react';
import cx from 'classnames';
import { plus as PlusIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/index';
import { KEYBOARD_CODE } from '@constants/keyboard';
import styles from './DeliveryCard.module.scss';

export interface DeliveryCardProps {
  onClick: () => void;
  className?: string;
}

export const DeliveryAddCard = ({ onClick, className }: DeliveryCardProps) => {
  const keyDownHandler = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KEYBOARD_CODE.ENTER) onClick();
  };

  const { t } = useLocalization('account');

  return (
    <div className={styles.add_new_card_wrapper}>
      <div
        onKeyDown={keyDownHandler}
        tabIndex={0}
        role="button"
        onClick={onClick}
        aria-label={t('delivery.addNewAddress')}
        className={cx(styles.wrapper, styles.content, styles.add_new_card, className)}
      >
        <span>
          {t('delivery.addNewAddress')}
          <PlusIcon className={styles.icon} />
        </span>
      </div>
    </div>
  );
};
