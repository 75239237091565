import { IncomingMessage, ServerResponse } from 'http';
import getConfig from 'next/config';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { Settings, StringBooleanPair } from '@api';
import { localStorageService, STORAGE_KEYS } from '@utils/storageService';
import { KNOCKING_REQUIRED } from '@constants/knocking';
import { DYNAMIC_FEATURES, DynamicFeature } from '../types/dynamicFeatures';

export { DYNAMIC_FEATURES };
export type { DynamicFeature };

const FEATURE_COOKIE = 'FD_FEATURES';

const { publicRuntimeConfig } = getConfig();
const initialFeatures: Array<DynamicFeature> = [
  {
    name: DYNAMIC_FEATURES.KNOCKING,
    enabled: publicRuntimeConfig.knockingEnabled !== 'false',
    options: Object.fromEntries(KNOCKING_REQUIRED.map((key) => [key, 'true'])),
  },
  {
    name: DYNAMIC_FEATURES.REDIRECT_TO_APP_FEATURE,
    enabled: publicRuntimeConfig.redirectToAppEnabled !== 'false',
  },
  {
    name: DYNAMIC_FEATURES.RECOMMENDED_BOTTOM_CAROUSEL,
    enabled: publicRuntimeConfig.recommendedBottomCarousel !== 'false',
  },
  {
    name: DYNAMIC_FEATURES.HIDE_ADD_PAYMENT,
    enabled: publicRuntimeConfig.hideAddPayment !== 'false',
  },
  {
    name: DYNAMIC_FEATURES.LEFT_NAV,
    enabled: publicRuntimeConfig.leftNav !== 'false',
  },
  {
    name: DYNAMIC_FEATURES.BANNERSERVICE,
    enabled: !!publicRuntimeConfig.bannerServiceSlots,
    options: Object.fromEntries(
      (publicRuntimeConfig.bannerServiceSlots?.split(',') || []).map((key: string) => [
        key.trim(),
        'true',
      ]),
    ),
  },
];

let ssrFeatures: Record<string, DynamicFeature> = {};

const setFeatureCookie = (features: Record<string, DynamicFeature>) => {
  if (typeof window !== undefined) {
    const expdate = new Date();
    expdate.setFullYear(expdate.getFullYear() + 1);
    setCookie(FEATURE_COOKIE, JSON.stringify(features), { expires: expdate });
  }
};

const deleteFeatureCookie = () => {
  deleteCookie(FEATURE_COOKIE);
};

export const updateSSRFeatures = (req: IncomingMessage, res: ServerResponse) => {
  const ssrFeaturesFromCookie = getCookie(FEATURE_COOKIE, { req, res });
  try {
    if (ssrFeaturesFromCookie && typeof ssrFeaturesFromCookie === 'string') {
      ssrFeatures = JSON.parse(ssrFeaturesFromCookie);
    } else {
      ssrFeatures = {};
    }
  } catch (o_0) {}
};

export const getInitialFeatures = (): Array<DynamicFeature> => {
  return initialFeatures;
};

export const replaceFeatures = (features: Array<DynamicFeature>) => {
  initialFeatures.splice(0, initialFeatures.length, ...features);
};

export const getInitialFeature = (featureName: DYNAMIC_FEATURES): DynamicFeature | undefined => {
  return initialFeatures.find((feature) => feature.name === featureName);
};

export const isFeatureEnabled = (featureName: DYNAMIC_FEATURES): boolean => {
  const features = localStorageService?.read(STORAGE_KEYS.DYNAMIC_FEATURES) || ssrFeatures;
  return featureName in features
    ? features[featureName].enabled
    : !!getInitialFeature(featureName)?.enabled;
};

export const getFeatureOptions = (featureName: DYNAMIC_FEATURES): Record<string, string> => {
  const features = localStorageService?.read(STORAGE_KEYS.DYNAMIC_FEATURES) || ssrFeatures;

  return features[featureName]?.options || getInitialFeature(featureName)?.options || {};
};

export const enableFeature = (
  featureName: DYNAMIC_FEATURES,
  options?: Record<string, string>,
): void => {
  const features = localStorageService?.read(STORAGE_KEYS.DYNAMIC_FEATURES) || ssrFeatures;
  if (options === undefined) {
    options = getInitialFeature(featureName)?.options || undefined;
  }
  features[featureName] = {
    name: featureName,
    enabled: true,
    options,
  };
  localStorageService?.put(STORAGE_KEYS.DYNAMIC_FEATURES, features);
  setFeatureCookie(features);
};

export const disableFeature = (featureName: DYNAMIC_FEATURES): void => {
  const features = localStorageService?.read(STORAGE_KEYS.DYNAMIC_FEATURES) || ssrFeatures;

  if (!(featureName in features)) {
    features[featureName] = getInitialFeature(featureName) || { name: featureName, enabled: false };
  } else {
    features[featureName].enabled = false;
  }

  localStorageService?.put(STORAGE_KEYS.DYNAMIC_FEATURES, features);
  setFeatureCookie(features);
};

export const resetAllFeatures = (): void => {
  localStorageService?.remove(STORAGE_KEYS.DYNAMIC_FEATURES);
  deleteFeatureCookie();
};

export const updateFeaturesFromEligibilities = (features: StringBooleanPair[]): void => {
  features.forEach(({ key, value }) => {
    if (Object.values(DYNAMIC_FEATURES).includes(key as DYNAMIC_FEATURES)) {
      let found = false;
      initialFeatures.forEach((feature) => {
        if (feature.name === key) {
          found = true;
          feature.enabled = value;
        }
      });
      if (!found) {
        initialFeatures.push({
          name: key as DYNAMIC_FEATURES,
          enabled: value,
        });
      }
    }
  });
};

export const updateFromSettings = (settings: Settings): void => {
  settings.features?.forEach((feature) => {
    if (feature?.name) {
      const options =
        feature.options?.length &&
        Object.fromEntries(feature.options.map((option) => [option?.key, option?.value]));
      initialFeatures.forEach((feat) => {
        if (feat.name === feature.name) {
          feat.options = options;
          feat.enabled = feature.enabled;
        }
      });
    }
  });
};
