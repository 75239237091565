import { Customer, Maybe, StatusLevelType, ServiceType } from '@api';
import { AccountNotifications } from '@commons/account';

export type AuthUser = UserInfo & Partial<Customer> & Maybe<Customer>;

export interface Auth {
  status: StatusLevelType;
  user: AuthUser | null;
}

export interface UserInfo {
  firstName?: string | null;
  lastName?: string | null;
  shortName?: string | null;
  fullName?: string | null;
  email?: string | null;
  memberSinceYear?: string | null;
  hasJustSignUp?: boolean;
  expressEligible?: boolean;
  isMasqueradeMode?: boolean;
  isSocialLoginOnly?: boolean;
  isEligibleForPreReservation?: boolean;
  phoneNumber?: string | null;
  workPhoneNumber?: string | null;
  workPhoneExtension?: string | null;
  notifications?: AccountNotifications | null;
  statusLevel?: StatusLevelType;
  serviceType?: ServiceType | null;
}

export enum USER_AUTH_STATUS {
  AUTH_BEGIN = 'BEGIN_AUTH',
  AUTH_COMPLETE = 'AUTH_COMPLETE',
}
