import { gql } from '@apollo/client';
import { MASQUERADE_BAR_DATA_FRAGMENT } from '../../fragments/masquerade/masqueradeBarData';
import { ACCOUNT_PREFERENCES_FRAGMENT } from '../../fragments/account/accountPreferences';

export const INIT_MASQUERADE_MODE = gql`
  mutation InitMasqueradeMode($input: MasqueradeInitInput!) {
    initMasqueradeMode(input: $input) {
      redirectUrl
      isExpressAddOnOrder
      accountPreferences {
        ...accountPreferencesFragment
      }
      masqueradeBarData {
        ...masqueradeBarDataFragment
      }
    }
  }
  ${MASQUERADE_BAR_DATA_FRAGMENT}
  ${ACCOUNT_PREFERENCES_FRAGMENT}
`;
