/* eslint-disable @typescript-eslint/naming-convention */
import { routing } from '@constants/routing';

const SCHEMA_URL = 'https://schema.org';
const FRESH_DIRECT_URL = 'https://www.freshdirect.com/';
const WEBSITE = 'Website';
const SEARCH_ACTION = 'SearchAction';
const ORGANIZATION = 'Organization';
const FRESH_DIRECT = 'FreshDirect';
const LOCAL_BUSINESS = 'LocalBusiness';
const POSTAL_ADDRESS = 'PostalAddress';
const GEO_COORDINATES = 'GeoCoordinates';
const US = 'US';
const NY = 'NY';
const BROOKLIN = 'Brooklyn';
const TARGET = `${FRESH_DIRECT_URL}search?searchParams={search_term_string}&pageSize=30&activePage=1&sortBy=Sort_Relevancy&orderAsc=true&departmentFilterGroup=clearall&brandFilterGroup=clearall&PopularFilterGroup=ProductGrabbers_Global_Sale`;
const QUERY_INPUT = 'required name=search_term_string';
const BROWSE_ROUTE_PREFIX = routing.browse;
const HELP_ROUTE_PREFIX = routing.help;
const WINE_ROUTE_PATH = '/d/fdw';
const BRONX_DELIVERY_PAGE = `${BROWSE_ROUTE_PREFIX}?id=grocery_delivery_bronx`;
const BROOKLYN_DELIVERY_PAGE = `${BROWSE_ROUTE_PREFIX}?id=grocery_delivery_brooklyn`;
const MANHATTAN_DELIVERY_PAGE = `${BROWSE_ROUTE_PREFIX}?id=grocery_delivery_manhattan`;
const HAMPTONS_DELIVERY_PAGE = `${HELP_ROUTE_PREFIX}/delivery_hamptons`;
const WINE_SPIRITS_DELIVERY_PAGE = `${WINE_ROUTE_PATH}`;

interface PagesWebsiteStructure {
  [key: string]: Record<string, unknown>;
}

export const DEFAULT_ORGANIZATION_STRUCTURE = {
  '@context': SCHEMA_URL,
  '@type': ORGANIZATION,
  url: FRESH_DIRECT_URL,
  name: FRESH_DIRECT,
  logo: `${FRESH_DIRECT_URL}media/layout/nav/globalnav/fdx/logo.svg`,
  sameAs: [
    'https://twitter.com/FreshDirect',
    'https://www.facebook.com/FreshDirect',
    'https://www.pinterest.com/freshdirect',
    'https://plus.google.com/+freshdirect/videos',
    'https://www.youtube.com/FreshDirect',
  ],
};

const DEFAULT_WEBSITE_STRUCTURE = {
  '@context': SCHEMA_URL,
  '@type': WEBSITE,
  url: FRESH_DIRECT_URL,
  potentialAction: {
    '@type': SEARCH_ACTION,
    target: TARGET,
    'query-input': QUERY_INPUT,
  },
};

const BRONX_DELIVERY_PAGE_WEBSITE_STRUCTURE = {
  '@context': SCHEMA_URL,
  '@type': LOCAL_BUSINESS,
  name: 'Fresh Direct Grocery Delivery Bronx',
  image: `${FRESH_DIRECT_URL}media/editorial/whats_good/SEO/img/bronx/truck.jpg`,
  '@id': `${FRESH_DIRECT_URL}browse?id=grocery_delivery_bronx`,
  url: `${FRESH_DIRECT_URL}browse?id=grocery_delivery_bronx`,
  telephone: '18662837374',
  address: {
    '@type': POSTAL_ADDRESS,
    streetAddress: "2 St Ann's Ave",
    addressLocality: 'Bronx',
    addressRegion: NY,
    postalCode: '10454',
    addressCountry: US,
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.801706390689624,
    longitude: -73.92049526747037,
  },
};

const BROOKLYN_DELIVERY_PAGE_WEBSITE_STRUCTURE = {
  '@context': SCHEMA_URL,
  '@type': LOCAL_BUSINESS,
  name: 'Fresh Direct Grocery Delivery Brooklyn Crossdock',
  image: `${FRESH_DIRECT_URL}media/editorial/whats_good/SEO/img/brooklyn/updates/truck_desktop.jpg`,
  '@id': `${FRESH_DIRECT_URL}browse?id=grocery_delivery_brooklyn`,
  url: `${FRESH_DIRECT_URL}browse?id=grocery_delivery_brooklyn`,
  telephone: '17189281476',
  address: {
    '@type': POSTAL_ADDRESS,
    streetAddress: '9 29th St',
    addressLocality: BROOKLIN,
    addressRegion: NY,
    postalCode: '11232',
    addressCountry: US,
  },
  geo: {
    '@type': GEO_COORDINATES,
    latitude: 40.66324994724515,
    longitude: -74.00534504547124,
  },
};

const MANHATTAN_DELIVERY_PAGE_WEBSITE_STRUCTURE = {
  '@context': SCHEMA_URL,
  '@type': LOCAL_BUSINESS,
  name: 'Fresh Direct Grocery Delivery Manhattan',
  image: `${FRESH_DIRECT_URL}media/editorial/whats_good/SEO/img/manhattan/updates/delivery_desktop.jpg`,
  '@id': `${FRESH_DIRECT_URL}browse?id=grocery_delivery_manhattan`,
  url: `${FRESH_DIRECT_URL}browse?id=grocery_delivery_manhattan`,
  telephone: '18662837374',
  address: {
    '@type': POSTAL_ADDRESS,
    streetAddress: '630 W 52nd St',
    addressLocality: 'New York',
    addressRegion: NY,
    postalCode: '10036',
    addressCountry: US,
  },
  geo: {
    '@type': GEO_COORDINATES,
    latitude: 40.7703886510961,
    longitude: -73.99545041642587,
  },
};

const HAMPTONS_DELIVERY_PAGE_WEBSITE_STRUCTURE = {
  '@context': SCHEMA_URL,
  '@type': LOCAL_BUSINESS,
  name: 'Fresh Direct Grocery Delivery Hamptons',
  image: `${FRESH_DIRECT_URL}media/editorial/summer_services/hamptons/img/updates/2022/002-Map.jpg`,
  '@id': `${FRESH_DIRECT_URL}help/delivery_hamptons`,
  url: `${FRESH_DIRECT_URL}help/delivery_hamptons`,
  telephone: '2127968002',
  address: {
    '@type': POSTAL_ADDRESS,
    streetAddress: '30 Enterprise Zone Dr',
    addressLocality: 'Riverhead',
    addressRegion: NY,
    postalCode: '11901',
    addressCountry: US,
  },
  geo: {
    '@type': GEO_COORDINATES,
    latitude: 40.91191449036709,
    longitude: -72.65097923417143,
  },
};

const WINE_SPIRITS_DELIVERY_PAGE_WEBSITE_STRUCTURE = {
  '@context': SCHEMA_URL,
  '@type': LOCAL_BUSINESS,
  name: 'FreshDirect Wine and Spirits',
  image: `${FRESH_DIRECT_URL}media/editorial/whats_good/SEO/img/bronx/truck.jpg`,
  '@id': `${FRESH_DIRECT_URL}d/fdw`,
  url: `${FRESH_DIRECT_URL}d/fdw`,
  telephone: '+17187681515',
  address: {
    '@type': POSTAL_ADDRESS,
    streetAddress: '3 Flushing Ave, Suite 120',
    addressLocality: BROOKLIN,
    addressRegion: NY,
    postalCode: '11205',
    addressCountry: US,
  },
  geo: {
    '@type': GEO_COORDINATES,
    latitude: 40.698493,
    longitude: -73.9797246,
  },
  openingHourSpecification: [
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      opens: '10:00',
      closes: '20:00',
    },
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Sunday'],
      opens: '12:00',
      closes: '20:00',
    },
  ],
};

export const PAGES_WEBSITE_STRUCTURE: PagesWebsiteStructure = {
  DEFAULT: DEFAULT_WEBSITE_STRUCTURE,
  [BRONX_DELIVERY_PAGE]: BRONX_DELIVERY_PAGE_WEBSITE_STRUCTURE,
  [BROOKLYN_DELIVERY_PAGE]: BROOKLYN_DELIVERY_PAGE_WEBSITE_STRUCTURE,
  [MANHATTAN_DELIVERY_PAGE]: MANHATTAN_DELIVERY_PAGE_WEBSITE_STRUCTURE,
  [HAMPTONS_DELIVERY_PAGE]: HAMPTONS_DELIVERY_PAGE_WEBSITE_STRUCTURE,
  [WINE_SPIRITS_DELIVERY_PAGE]: WINE_SPIRITS_DELIVERY_PAGE_WEBSITE_STRUCTURE,
};
