import { gql } from '@apollo/client';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';

export const MODIFY_ORDER = gql`
  mutation ModifyOrder($orderId: ID!) {
    modifyOrder(orderId: $orderId) {
      errors {
        ...validationErrorFragment
      }
      success
    }
  }
  ${VALIDATION_ERROR_FRAGMENT}
`;
