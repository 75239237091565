import cx from 'classnames';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { arrowRight as ArrowRight } from '@assets/icons/system/index';
import styles from './Link.module.scss';

export interface LinkProps extends NextLinkProps {
  withIcon?: boolean;
  disableBorder?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  classNameLinkText?: string;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  ariaLabel?: string;
  downloadLink?: boolean;
  ariaCurrent?: boolean;
  inheritParentStyle?: boolean;
  id?: string;
  ariaLabelledby?: string;
}

/**
 * @deprecated
 * Use LinkV2 | PrimaryLink instead
 */
export const Link = ({
  children,
  href,
  onClick,
  withIcon,
  icon,
  disableBorder,
  className,
  classNameLinkText,
  target,
  downloadLink,
  rel,
  ariaLabel,
  ariaCurrent,
  inheritParentStyle,
  id,
  ariaLabelledby,
  ...restProps
}: LinkProps) => {
  const linkClassNames = cx(styles.link_text, classNameLinkText, {
    [styles.disable_border]: disableBorder,
    [styles.current_selected]: ariaCurrent,
    [styles.inherit_parent]: inheritParentStyle,
  });

  return (
    <NextLink legacyBehavior {...restProps} href={href} prefetch={false}>
      {/* eslint-disable-next-line*/}
      <a
        className={cx(styles.link, className, { [styles.inherit_parent]: inheritParentStyle })}
        target={target}
        rel={rel}
        download={downloadLink}
        onClick={onClick}
        {...(ariaLabel && { 'aria-label': ariaLabel })}
        {...(ariaCurrent && { 'aria-current': 'page' })}
        {...(id && { id: id })}
        {...(ariaLabelledby && { 'aria-labelledby': ariaLabelledby })}
      >
        <span className={linkClassNames}>{children}</span>
        {withIcon && <span className={styles.link_icon}>{icon || <ArrowRight />}</span>}
      </a>
    </NextLink>
  );
};
