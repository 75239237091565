import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import IconButton from '@mui/material/IconButton';
import { CheckoutBlock } from '@features/CartPage/components/CheckoutBlock/CheckoutBlock';
import { routing } from '@constants/routing';
import { CHECKOUT_ERROR_TYPES } from '@constants/errorCodes';
import { getModifyingOrderLink } from '@utils/getModifyingOrderLink';
import { useModifyMode } from '@hooks/orderModification/useModifyMode';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';
import { useSelectedAddress } from '@hooks/deliveryAddress/useSelectedAddress';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { BagDetails } from '@features/CartPage/components/Bag/components/FilledBag/components/BagDetails/BagDetails';
import { BagDetailsSideBagDrawer } from '@features/CartPage/components/Bag/components/FilledBag/components/BagDetails/BagDetailsSideBagDrawer';
import { Alert, Button } from '@components/UI';
import { TimeSlotsSelected } from '@features/CartPage/components/CartPageOptions/components/TimeSlotsSelected/TimeSlotsSelected';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { Subtotal as ISubtotal } from '@commons/cart';
import { Price } from '@commons/price';
import { OrderMinimum } from '@features/CartPage/components/Bag/components/FilledBag/components/OrderMinimum/OrderMinimum';
import { TimeslotData } from '@commons/timeslots';
import { useFilledBagOrderMinimumError } from '@features/CartPage/components/Bag/components/FilledBag/useFilledBagOrderMinimumError';
import { getOrderMinimumDetails } from '@features/CartPage/components/Bag/components/FilledBag/components/OrderMinimum/OrderMinimum.utils';
import { usePageValidationError } from '@context/pageValidationErrorContext';
import { useIsShowTimeslots, useTimeslots } from '@hooks/timeslots';
import { useLocalization } from '@hooks/index';
import { useRefererInfo } from '@hooks/referAFriend/useRefererInfo';
import { useValidateReferer } from '@hooks/referAFriend/useValidateReferer';
import { close as CloseIcon } from '@assets/icons/system';
import { localStorageService, STORAGE_KEYS } from '@utils/storageService';
import { useUserDetails } from '@hooks/account/useUserDetails';
import styles from './BagFooter.module.scss';

interface BagFooterProps {
  hideTimeslot?: boolean;
  onCloseSideBagDrawer?: () => void;
  onOpenDeliveryOptions?: () => void;
  orderMinimum: Price;
  subtotal: ISubtotal;
  totalWithoutDiscount: Price;
  omitTestIds?: boolean;
}

export const BagFooter = ({
  hideTimeslot,
  onCloseSideBagDrawer,
  onOpenDeliveryOptions,
  orderMinimum,
  subtotal,
  totalWithoutDiscount,
  omitTestIds = false,
}: BagFooterProps) => {
  const router = useRouter();
  const { t } = useLocalization('cart');
  const { isMobile, isTablet } = useHeaderContext();
  const untilLargeTablet = !!isMobile || !!isTablet;
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();
  const { refererInfo, removeRefererInfo } = useRefererInfo();

  const { selectedTimeslot, selectedTimeslotId } = useTimeslots();
  const { data: userDetails, loading: loadingUserDetails } = useUserDetails();
  const referralFlag = !loadingUserDetails && userDetails?.displayRafBanner;

  const showTimeslots =
    untilLargeTablet && selectedTimeslotId && !hideTimeslot && !isSideBagDrawerOpen;

  const { openTimeslots } = useIsShowTimeslots();
  const openDeliveryWithTimeSlots = () => {
    onOpenDeliveryOptions?.();
    openTimeslots();
  };
  const { error: orderMinimumAlertError, close: closeOrderMinimumError } =
    useFilledBagOrderMinimumError(subtotal.value.value, orderMinimum.value);

  const orderMinimumDetails = getOrderMinimumDetails(subtotal.value.value, orderMinimum.value);

  const { data: selectedAddress } = useSelectedAddress({
    queryOptions: { fetchPolicy: 'cache-first' },
  });
  const isAddressSelected = !!selectedAddress.address.address1;
  const { error } = usePageValidationError();
  const isAlcoholRestrictedError =
    error?.type === CHECKOUT_ERROR_TYPES.ALCOHOL_IS_RESTRICTED ||
    error?.type === CHECKOUT_ERROR_TYPES.CHECKOUT_ALCOHOL_IS_RESTRICTED;
  const deliveryVisible =
    !isAlcoholRestrictedError &&
    !untilLargeTablet &&
    isAddressSelected &&
    selectedAddress.address.serviceType !== SERVICE_TYPE.PICKUP;
  const { data: modifyMode } = useModifyMode();

  const handleNavigateToCart = () => {
    const navigateTo = modifyMode.active ? getModifyingOrderLink(modifyMode.orderId) : routing.cart;
    onCloseSideBagDrawer?.();
    router.push(navigateTo);
  };

  const [hasInitReferInfo, setHasInitReferInfo] = useState(false);
  const {
    data: validatedData,
    refetch: fetchRefererInfo,
    loading,
  } = useValidateReferer(refererInfo.refererName, refererInfo.refererInvite);

  const validatedDataRef = useRef(null);
  const getHideRefText = () => {
    if (typeof window !== 'undefined') {
      return localStorageService?.read(STORAGE_KEYS.HIDE_BAG_REFER_INFO) || false;
    } else {
      return false;
    }
  };
  const [hideRefText, setHideRefText] = useState(getHideRefText());

  useEffect(() => {
    if (
      !loading &&
      ((refererInfo.refererInvite && refererInfo.refererName) || referralFlag) &&
      !validatedDataRef.current &&
      !hasInitReferInfo
    ) {
      setHasInitReferInfo(true);
      fetchRefererInfo().then((res) => {
        if (res?.data?.validateReferralPromotion?.valid) {
          validatedDataRef.current = validatedData;
        } else {
          removeRefererInfo();
        }
      });
    }
  }, [
    fetchRefererInfo,
    refererInfo,
    removeRefererInfo,
    loading,
    hasInitReferInfo,
    referralFlag,
    validatedData,
  ]);

  const renderBagDetails = () => {
    return isSideBagDrawerOpen ? (
      <BagDetailsSideBagDrawer
        orderMinimumDetails={orderMinimumDetails}
        subtotal={subtotal}
        totalWithoutDiscount={totalWithoutDiscount}
      />
    ) : (
      <BagDetails
        orderMinimumDetails={orderMinimumDetails}
        subtotal={subtotal}
        orderMinimum={orderMinimum}
        deliveryVisible={deliveryVisible}
        totalWithoutDiscount={totalWithoutDiscount}
      />
    );
  };

  const renderCta = () => {
    if (isSideBagDrawerOpen) {
      return (
        <Button
          variant="contained"
          onClick={handleNavigateToCart}
          size="large"
          isFullWidth
          className={styles.cta_button}
          data-testid={!omitTestIds && 'cart-drawer-go-to-bag'}
        >
          {t('goToBag')}
        </Button>
      );
    }
    if (!untilLargeTablet) return null;
    return selectedTimeslotId || hideTimeslot ? (
      <CheckoutBlock isCartPage={true} />
    ) : (
      <Button
        variant="outlined"
        onClick={onOpenDeliveryOptions}
        size="large"
        isFullWidth
        className={styles.cta_button}
      >
        {t('selectTimeSlot')}
      </Button>
    );
  };

  const showReferralText =
    ((validatedData?.validateReferralPromotion.valid && refererInfo.refererInvite) ||
      referralFlag) &&
    validatedData?.validateReferralPromotion.refererName &&
    !hideRefText;
  return (
    <div
      className={cx(styles.bottom_panel_wrapper, {
        [styles.drawer_opened]: isSideBagDrawerOpen,
      })}
    >
      {showReferralText && (
        <div className={styles.refer_text_wrapper}>
          <div className={styles.refer_text_container}>
            <IconButton
              className={styles.close_button}
              onClick={() => {
                setHideRefText(true);
                localStorageService?.put(STORAGE_KEYS.HIDE_BAG_REFER_INFO, true);
              }}
              data-testid="refer-text-close-icon"
              aria-label="close"
            >
              <CloseIcon width={14} height={14} />
            </IconButton>
            <div className={styles.refer_text}>
              {t('header:referAFriend', {
                name: validatedData?.validateReferralPromotion.refererName,
              })}
            </div>
          </div>
        </div>
      )}
      <div
        className={cx(styles.bottom_panel_inner, {
          [styles.drawer_opened]: isSideBagDrawerOpen,
        })}
      >
        <OrderMinimum
          subtotal={subtotal}
          orderMinimum={orderMinimum}
          className={styles.order_minimum_mobile}
        />
        {showTimeslots && (
          <TimeSlotsSelected
            onEditClick={openDeliveryWithTimeSlots}
            selectedTimeslot={selectedTimeslot as TimeslotData}
          />
        )}
        {!!orderMinimumAlertError && (
          <Alert className={styles.error_pop_up} type="error" onClose={closeOrderMinimumError}>
            {orderMinimumAlertError}
          </Alert>
        )}
        {renderBagDetails()}
        {renderCta()}
      </div>
    </div>
  );
};
