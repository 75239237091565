import cx from 'classnames';
import { check as CheckIcon } from '@assets/icons/system';
import styles from './FakeCheckbox.module.scss';

export interface FakeCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  className?: string;
}

export const FakeCheckbox = ({ checked, disabled, className }: FakeCheckboxProps) => {
  return (
    <span
      className={cx(
        styles.box,
        { [styles.checked]: checked },
        { [styles.disabled]: disabled },
        className,
      )}
    >
      {checked && <CheckIcon />}
    </span>
  );
};
