import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  AnalyticsCallback,
  AnalyticsDataContext,
} from '@modules/ga/context/AnalyticsData/AnalyticsDataContext';
import { useAnalyticsData } from '@modules/ga/hooks/useAnalyticsData';
import { useRefetchEventId } from '@modules/ga/hooks/useRefetchEventId';
import { GeolocationResult, MinimumDeliveryAddress } from '@commons/deliveryAddresses';
import { AccountPreferences, UserDetailsInfo } from '@commons/account';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { StatusLevelType } from '@api';

export interface AnalyticsData {
  geoData: GeolocationResult;
  addressData?: MinimumDeliveryAddress;
  userDetails: UserDetailsInfo;
  pageViewEventId: string;
  minimumAccountPreferences: AccountPreferences;
  userStatus: StatusLevelType;
}

export const AnalyticsDataProvider = ({ children }: { children: ReactNode }) => {
  const [callbackQueue, setCallbackQueue] = useState<AnalyticsCallback[]>([]);
  const { userStatus, isUserInfoLoading } = useAuthContext();
  const data = useAnalyticsData();
  const isLoadingRefetchPageEventId = useRefetchEventId();
  const loading = data.loading || isLoadingRefetchPageEventId || isUserInfoLoading;

  const fireCallbackQueue = () => {
    callbackQueue.forEach((callback) => {
      callback?.({
        ...data,
        userStatus: userStatus ?? StatusLevelType.GUEST,
      });
    });
    setCallbackQueue([]);
  };
  const addEventHandler = useRef((callback: AnalyticsCallback) => {
    setCallbackQueue((prevState) => [...prevState, callback]);
  });
  const result = useRef({ addEventHandler: addEventHandler.current });

  useEffect(() => {
    if (!loading && callbackQueue.length > 0) {
      fireCallbackQueue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, callbackQueue]);

  return (
    <AnalyticsDataContext.Provider value={result.current}>{children}</AnalyticsDataContext.Provider>
  );
};
