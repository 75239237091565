import { useLazyQuery } from '@apollo/client';
import { ProductQuery, ProductQueryVariables } from '@api';
import { productAdapter } from '@adapters/productAdapter';
import { GET_PRODUCT_BY_ID } from '@graphql/product/queries/singleProduct';
import { Product } from '@commons/product';

const { getProduct } = productAdapter();

export const useLazyVariationItem = () => {
  const [fetch] = useLazyQuery<ProductQuery, ProductQueryVariables>(GET_PRODUCT_BY_ID);

  return async (id: string): Promise<Product | undefined> => {
    const { data } = await fetch({ variables: { productId: id } });
    return data?.product ? getProduct(data.product) : undefined;
  };
};
