import { Grid } from '@mui/material';
import { FC } from 'react';
import { DepartmentItem } from '@api';
import { getHeadline } from './getters';
import { CategoriesSectionDualView } from './CategoriesSectionDualView';
import { CategoriesSectionSingleView } from './CategoriesSectionSingleView';

enum VIEW_TYPE {
  SINGLE = 'single',
  DUAL = 'dual',
}

interface CategoriesSectionProps {
  department: DepartmentItem;
  showHeadlines?: boolean;
  onClick?: () => void;
}

export const CategoriesSection: FC<CategoriesSectionProps> = ({
  department,
  showHeadlines = false,
  onClick,
}) => {
  const { dropDownCategories } = department;
  if (!dropDownCategories) return null;
  const headlines = dropDownCategories
    .map((dropDownCategory) => getHeadline(dropDownCategory))
    .filter(Boolean);
  const viewType: VIEW_TYPE = headlines.length > 1 ? VIEW_TYPE.DUAL : VIEW_TYPE.SINGLE;

  return (
    <Grid container columns={6} tabIndex={-1} role="menu">
      {viewType === VIEW_TYPE.DUAL && (
        <CategoriesSectionDualView
          department={department}
          headlines={headlines}
          showHeadlines={showHeadlines}
          onClick={onClick}
        />
      )}
      {viewType === VIEW_TYPE.SINGLE && (
        <CategoriesSectionSingleView
          department={department}
          headlines={headlines}
          showHeadlines={showHeadlines}
          onClick={onClick}
        />
      )}
    </Grid>
  );
};
