import { ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import {
  AuthModalContext,
  IAuthModalAction,
  initAuthModalState,
} from '@context/AuthModalContext/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { Emitter } from '@utils/emitter';
import { on as onEventEmitted } from '@modules/emitter';
import { sensitiveRoutes } from '@constants/routing';
import { authModalReducer } from './authModalReducer';

interface AuthModalProviderProps {
  children: ReactNode;
}

export const AuthModalProvider = ({ children }: AuthModalProviderProps) => {
  const router = useRouter();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { on, emit } = useRef(Emitter<IAuthModalAction>()).current;
  const [state, dispatch] = useReducer(authModalReducer, initAuthModalState);
  const [callBack, setCallBack] = useState<() => void>();

  const toggleAuthModal = (modalType?: AUTH_MODAL_TYPES) => {
    if (modalType) {
      dispatch({ type: modalType });
    }
    setIsAuthModalOpen(!isAuthModalOpen);
  };

  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
    callBack?.();
  };

  const openAuthModal = (modalType?: AUTH_MODAL_TYPES, cb?: () => void) => {
    if (modalType) {
      dispatch({ type: modalType });
    }
    setIsAuthModalOpen(true);

    if (cb) {
      setCallBack(() => cb);
    }
  };

  useEffect(
    () =>
      on((event) => {
        if (
          event.type === 'close' ||
          event.type === 'welcomeClose' ||
          event.type === 'back' ||
          event.type === 'tcAgreed'
        )
          setIsAuthModalOpen(false);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () =>
      onEventEmitted((event) => {
        if (event.type !== 'needLogin') {
          return;
        }

        const currentRoute = router.asPath;
        const isSensitiveRoute = sensitiveRoutes.some((sensitiveRoute) =>
          currentRoute.startsWith(sensitiveRoute),
        );

        if (isSensitiveRoute && !isAuthModalOpen) {
          openAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
        }
      }),
    [isAuthModalOpen, router.asPath],
  );

  return (
    <AuthModalContext.Provider
      value={{
        toggleAuthModal,
        openAuthModal,
        closeAuthModal,
        isAuthModalOpen,
        state,
        on,
        emit,
        // TODO: Refactor: Merge "emit" with "dispatch" https://freshdirectjira.atlassian.net/browse/TUE-18050
        dispatch,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};
