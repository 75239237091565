import { PAYMENT_TYPE, PaymentsType } from '@commons/payment';
import { BACKGROUND_MODE } from '@commons/modals';
import { EditBankAccountModal } from './components/EditBankAccountModal/EditBankAccountModal';
import { EditEbtCardModal } from './components/EditEbtCardModal/EditEbtCardModal';
import { EditCardModal } from './components/EditCardModal/EditCardModal';

interface EditPaymentModalContentProps {
  closeModal: () => void;
  type?: PaymentsType;
  id: string;
  backgroundMode?: BACKGROUND_MODE;
  opened: boolean;
}

export const EditPaymentModalWrapper = ({
  closeModal,
  type,
  id,
  backgroundMode,
  opened,
}: EditPaymentModalContentProps) => {
  if (type === PAYMENT_TYPE.ECHECK) {
    return (
      <EditBankAccountModal
        backgroundMode={backgroundMode}
        closeModal={closeModal}
        id={id}
        opened={opened}
      />
    );
  }

  if (type === PAYMENT_TYPE.EBT) {
    return (
      <EditEbtCardModal
        backgroundMode={backgroundMode}
        closeModal={closeModal}
        id={id}
        opened={opened}
      />
    );
  }

  return (
    <EditCardModal
      backgroundMode={backgroundMode}
      closeModal={closeModal}
      id={id}
      opened={opened}
    />
  );
};
