import { FormikProps } from 'formik';
import { useState } from 'react';
import { ManualAddressForm } from '@components/ManualAddressForm/ManualAddressForm';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { AddressToSend } from '@commons/deliveryAddresses';
import { AddressOptionInputForNeighborAddress } from '@features/CartPage/components/CartPageOptions/components/addressOptionInput/addressOptionInputForNeighbour';

export interface AddressWidgetForNeighbourProps {
  onChange: (value: AddressToSend) => void;
  // DEPRECATED:BEGIN
  isAptUnitAvailable: boolean;
  inputAddress: string;
  selectedFormattedAddress: Suggestion | null;
  isAddressSelected: boolean;
  setIsAptUnitAvailable: (value: boolean) => void;
  setInputAddress: (value: string) => void;
  setAPIErrorMessage: (value: string | null) => void;
  onBlurred?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  // DEPRECATED:END
}

// TODO: Need to create only one common AddressWidget component for main delivery address and neighbour address and delete this one
export const AddressWidgetForNeighbour = ({
  onChange,
  // DEPRECATED:BEGIN
  isAddressSelected,
  isAptUnitAvailable,
  inputAddress,
  formik,
  selectedFormattedAddress,
  onBlurred,
  setIsAptUnitAvailable,
  setInputAddress,
  setAPIErrorMessage,
}: // DEPRECATED:END
AddressWidgetForNeighbourProps) => {
  const [isManual, setIsManual] = useState(false);

  const handleChangeOfManualAddressForm = (value: AddressToSend) => {
    onChange(value);
    setIsManual(false);
  };

  return isManual ? (
    <ManualAddressForm onChange={handleChangeOfManualAddressForm} />
  ) : (
    // TODO: Need to create only one common AddressOptionInput component for main address and neighbouraddress and delete this one
    <AddressOptionInputForNeighborAddress
      isAptUnitAvailable={isAptUnitAvailable}
      inputAddress={inputAddress}
      formik={formik}
      selectedAddress={selectedFormattedAddress}
      isAddressSelected={isAddressSelected}
      setIsAptUnitAvailable={setIsAptUnitAvailable}
      setInputAddress={setInputAddress}
      setAPIError={setAPIErrorMessage}
      onChange={handleChangeOfManualAddressForm}
      onChangeMode={() => setIsManual(true)}
      onBlurred={onBlurred}
      id="add_neighbor_address"
    />
  );
};
