import { useRouter } from 'next/router';
import { FAQLinkHelper } from '@utils/FAQLinkHelper';
import { Button } from '@components/UI/Button/Button';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import styles from './DescriptionTabContentItem.module.scss';

interface DescriptionTabContentItemProps {
  title?: string;
  value: string | string[] | null;
  contentClassName?: string;
}

export const DescriptionTabContentItem = ({
  title,
  value,
  contentClassName,
}: DescriptionTabContentItemProps) => {
  const router = useRouter();
  const { t } = useLocalization('product');
  const { isFAQLinkExist, getContentWithoutFAQLinkString } = FAQLinkHelper();
  if (!value) {
    return null;
  }
  if (typeof value === 'string') {
    return (
      <div className={styles.description_block}>
        {title && <p className={styles.description_item}>{title}</p>}
        <div
          className={contentClassName}
          dangerouslySetInnerHTML={{ __html: getContentWithoutFAQLinkString(value) }}
        />
        {isFAQLinkExist(value) && (
          <Button
            variant="underline"
            onClick={() => {
              router.push(routing.help);
            }}
          >
            {t('descriptionTab.goToFAQLink')}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={styles.description_block}>
      {title && <p className={styles.description_item}>{title}</p>}
      <ul className={styles.description_list}>
        {value.map((valueItem) => (
          <li key={valueItem} className={styles.description_list_item}>
            {valueItem}
          </li>
        ))}
      </ul>
    </div>
  );
};
