import { useEffect } from 'react';

interface UseAlertProps {
  delay?: number;
  shouldHide: boolean;
  hideCallback: () => void;
}

export const useAlert = ({ delay = 4000, shouldHide, hideCallback }: UseAlertProps) => {
  useEffect(() => {
    if (shouldHide) {
      const timer = setTimeout(hideCallback, delay);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [shouldHide, hideCallback, delay]);
};
