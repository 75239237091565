import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { PAYMENT_TABS } from '@commons/payment';
import { paypal as PayPalIcon, venmo as VenmoIcon } from '@assets/icons/payments';
import { useLocalization } from '@hooks/useLocalization';
import styles from './PaymentsButtonsList.module.scss';

interface PaymentsButtonsListProps {
  handleButtonClick: (type: PAYMENT_TABS) => void;
}

export const PaymentsButtonsList = ({ handleButtonClick }: PaymentsButtonsListProps) => {
  const { t } = useLocalization('account');
  const ICON_SIZE = 16;

  const paymentsBtns = [
    {
      title: t('payments.modalTabs.creditDebitTab'),
      hasIcon: false,
      type: PAYMENT_TABS.CREDIT_CARD,
    },
    {
      title: t('payments.modalTabs.bankAccountTab'),
      hasIcon: false,
      type: PAYMENT_TABS.BANK_ACCOUNT,
    },
    {
      title: t('payments.modalTabs.paypalTab'),
      hasIcon: true,
      icon: <PayPalIcon width={ICON_SIZE} className={styles.icon} />,
      type: PAYMENT_TABS.PAYPAL,
    },
    {
      title: t('payments.modalTabs.venmo'),
      hasIcon: true,
      icon: <VenmoIcon width={ICON_SIZE} className={styles.icon} />,
      type: PAYMENT_TABS.VENMO,
    },
    {
      title: t('payments.modalTabs.ebt'),
      hasIcon: false,
      type: PAYMENT_TABS.EBT,
    },
  ];

  return (
    <>
      <Grid flexDirection="column" container>
        {paymentsBtns.map((paymentsBtn) => (
          <Button
            key={paymentsBtn.type}
            onClick={() => handleButtonClick(paymentsBtn.type)}
            className={styles.payment_btn}
          >
            {paymentsBtn.hasIcon ? (
              <div className={styles.icon_tab}>
                {paymentsBtn.icon}
                {paymentsBtn.title}
              </div>
            ) : (
              <>{paymentsBtn.title}</>
            )}
          </Button>
        ))}
      </Grid>
    </>
  );
};
