import { gql } from '@apollo/client';

export const PAGE_FRAGMENT = gql`
  fragment PageFragment on Page {
    totalItems
    page
    size
    count
    firstOffset
    lastOffset
  }
`;
