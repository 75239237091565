import { gql } from '@apollo/client';
import { ORDER_INFO_FRAGMENT } from '@graphql/fragments/account/orderInfo';
import { RESERVATION_FRAGMENT } from '@graphql/fragments/account/reservationFragment';

export const PENDING_ORDERS_ALERTS_INFO = gql`
  query PendingOrdersAlertInfo {
    pendingOrdersAlertInfo {
      deliveredOrdersCount
      pendingOrders {
        ...orderInfoFragment
      }
      reservation {
        ...reservationFragment
      }
      approachingExpressTimeslot {
        cutoffTime
        cutoffDate
      }
    }
  }
  ${RESERVATION_FRAGMENT}
  ${ORDER_INFO_FRAGMENT}
`;
