import { GiftCardDeliveryType } from '@api';
import { Values } from '@components/Modals/GiftCardModals/types';

export const radioGroupOptions = [
  { value: GiftCardDeliveryType.E, label: 'giftCardModal.deliverByEmail' },
  { value: GiftCardDeliveryType.P, label: 'giftCardModal.downloadPdf' },
] as const;

export const initialValues: Values = {
  giftCardId: '',
  giftCardAmount: '',
  senderName: '',
  senderEmail: '',
  recipientName: '',
  recipientEmail: '',
  recipientMessage: '',
  giftCardType: GiftCardDeliveryType.E,
  giftCardTemplateId: '',
  giftCardCustomAmount: '',
  quantity: '',
  isBulk: false,
};

export const bulkInitialValues: Values = {
  ...initialValues,
  giftCardType: GiftCardDeliveryType.P,
};

export const CUSTOM_AMOUNT_INPUT_VALUE = 'custom';
