import { useState } from 'react';
import { Button } from '@components/UI';
import { ProductExtraDetails } from '@commons/product';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './FreshBlock.module.scss';

interface FreshBlockProps extends Pick<ProductExtraDetails, 'freshnessGuarantee'> {
  className?: string;
}

export const FreshBlock = ({ freshnessGuarantee, className }: FreshBlockProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useLocalization();
  const date = freshnessGuarantee === 1 ? 'day' : 'days';
  const openModalToggle = () => {
    setIsOpen(!isOpen);
  };

  if (!freshnessGuarantee) return null;

  return (
    <>
      <Button
        className={className}
        onClick={openModalToggle}
        variant="underline"
        data-qa="pdp_fg_link"
      >
        {t('freshnessGuarantee.freshnessText', { freshnessGuarantee, date })}
      </Button>
      <ModalWindow
        isScrollable
        onClose={openModalToggle}
        open={isOpen}
        labelId={MODAL_TITLE_ID.FRESH_BLOCK}
      >
        <>
          <h2 className={styles.heading} id={MODAL_TITLE_ID.FRESH_BLOCK}>
            <span>{t('freshnessGuarantee.headingStart')}</span>
            <span>{t('freshnessGuarantee.headingEnd')}</span>
          </h2>
          <Block
            title={t('freshnessGuarantee.introducing')}
            text={t('freshnessGuarantee.introducingText')}
          />
          <Block
            title={t('freshnessGuarantee.counting')}
            text={t('freshnessGuarantee.countingText')}
          />
          <Block
            title={t('freshnessGuarantee.benefit')}
            text={t('freshnessGuarantee.benefitText')}
          />
          <Block
            title={t('freshnessGuarantee.guarantee')}
            text={t('freshnessGuarantee.guaranteeText')}
          />
          <Block text={t('freshnessGuarantee.ensureText')} />
          <Block
            listText={[
              t('freshnessGuarantee.listText.inspect'),
              t('freshnessGuarantee.listText.come'),
              t('freshnessGuarantee.listText.retail'),
              t('freshnessGuarantee.listText.refrigerator'),
            ]}
          />
          <Block text={t('freshnessGuarantee.smartStorageText')} />
        </>
      </ModalWindow>
    </>
  );
};

type BlockProps = { title?: string; text?: string; listText?: string[] };

function Block({ title, text, listText }: BlockProps) {
  return (
    <div className={styles.block}>
      {title && <p className={styles.title}>{title}</p>}
      {text && <p className={styles.text}>{text}</p>}
      {listText && (
        <ul className={styles.list}>
          {listText.map((text) => (
            <li key={text}>{text}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
