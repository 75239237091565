import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  QueryLazyOptions,
} from '@apollo/client';
import omit from 'lodash/omit';
import { useLazyGiftCards } from '@hooks/giftCard/useLazyGiftCards';
import { useGiftCardPurchaseInfo } from '@hooks/giftCard/useGiftCardPurchaseInfo';
import { ACCOUNT_MANAGEMENT_PAGE, ACCOUNT_MANAGEMENT_INFO } from '@commons/account';
import { useLazyAdaptedAccountPreference } from '@hooks/account/useAccountPreference';
import { useLazyPastAndActiveOrderHistory } from '@hooks/account/useLazyPastAndActiveOrderHistory';
import { useCredits } from '@hooks/credits/useCredits';
import { useLazyPaymentMethods } from '@hooks/payments/useLazyPaymentMethods';
import { useLazyDeliveryPassInfo } from '@hooks/deliveryPass/useLazyDeliveryPassInfo';
import { routing } from '@constants/routing';
import { useAuthModalContext } from '@context/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { setHasAccessToAccountPages } from '@hooks/common/useHasAccessToAccountPages';
import { useLazyUserDeliveryAddresses } from '@hooks/deliveryAddress/useLazyUserDeliveryAddresses';
import { accountAdapter } from '@adapters/accountAdapter';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { getId } from '@helpers/common.helpers';
import {
  AccountDeleteConfirm,
  AccountDeliveryAddresses,
  AccountPageChefTable,
  AccountPageCredits,
  AccountPageDeliveryPass,
  AccountPageGiftCards,
  AccountPageOrderHistory,
  AccountPagePayments,
  AccountPagePreferences,
  AccountPageReferAFriend,
} from '../components';

type Content = {
  [key in ACCOUNT_MANAGEMENT_PAGE]: {
    component: ReactNode;
    dataCallback: (options?: QueryLazyOptions<OperationVariables>) => Promise<unknown>;
    url: string;
  };
};

export interface UseAccountPageType {
  getPageComponent: () => ReactNode | null;
  fetchPageData: (
    page: ACCOUNT_MANAGEMENT_PAGE | ACCOUNT_MANAGEMENT_INFO,
    shouldNavigate: boolean,
  ) => void;
  currentPage: ACCOUNT_MANAGEMENT_PAGE;
  isLoading: boolean;
}

const DEFAULT_PAGE = ACCOUNT_MANAGEMENT_PAGE.EMPTY;

export const getInitialPage = (
  pageId: string | string[] = DEFAULT_PAGE,
): ACCOUNT_MANAGEMENT_PAGE => {
  const currentPage = pageId.toString();
  return Object.values(ACCOUNT_MANAGEMENT_PAGE).includes(currentPage as ACCOUNT_MANAGEMENT_PAGE)
    ? (currentPage as ACCOUNT_MANAGEMENT_PAGE)
    : ACCOUNT_MANAGEMENT_PAGE.EMPTY;
};

const { getDeliveryAddressArr } = accountAdapter();

export const useAccountPage = (): UseAccountPageType => {
  const router = useRouter();
  const { isSignedIn } = useAuthContext();
  const { openAuthModal } = useAuthModalContext();
  const initialPage = getInitialPage(router.query.id);
  const [currentPage, setCurrentPage] = useState<ACCOUNT_MANAGEMENT_PAGE>(initialPage);

  const [fetchUserDeliveryAddresses, { data: addressesData, loading: loadingAddresses }] =
    useLazyUserDeliveryAddresses({
      fetchPolicy: 'network-only',
    });
  const homeAddresses = getDeliveryAddressArr(addressesData?.userDeliveryAddresses?.homeAddresses);
  const corpAddresses = getDeliveryAddressArr(addressesData?.userDeliveryAddresses?.corpAddresses);

  const {
    fetchAccountPreferences,
    data: accountPreferences,
    loading: loadingPreferences,
  } = useLazyAdaptedAccountPreference();
  const { fetchCredits, data: credits, loading: loadingCredits } = useCredits();
  const { fetchGiftCards, data: giftCards, loading: loadingGiftCards } = useLazyGiftCards();
  const { data: giftCardPurchaseInfo, loading: loadingGiftCardPurchaseInfo } =
    useGiftCardPurchaseInfo();
  const { fetchPayments, data: payments, loading: loadingPayments } = useLazyPaymentMethods();
  const [
    fetchOrderHistory,
    {
      data: { activeOrders, pastOrders, modifyingOrderId },
      loading: loadingOrderHistory,
      previousData: prevOrderHistoryData,
    },
  ] = useLazyPastAndActiveOrderHistory();
  const {
    fetchDeliveryPassInfo,
    data: deliveryPassInfo,
    loading: loadingDeliveryPassInfo,
    called: deliveryPassInfoCalled,
  } = useLazyDeliveryPassInfo();

  const loading =
    loadingPreferences ||
    loadingCredits ||
    loadingGiftCards ||
    loadingGiftCardPurchaseInfo ||
    loadingPayments ||
    loadingAddresses ||
    loadingOrderHistory ||
    loadingDeliveryPassInfo;

  const content: Content = useMemo(
    () => ({
      [ACCOUNT_MANAGEMENT_PAGE.PREFERENCES]: {
        component: (
          <AccountPagePreferences
            accountPreferences={accountPreferences}
            loading={loadingPreferences}
            fetchAccountPreferences={fetchAccountPreferences}
          />
        ),
        dataCallback: fetchAccountPreferences,
        url: routing.preferences,
      },
      [ACCOUNT_MANAGEMENT_PAGE.CREDITS]: {
        component: <AccountPageCredits credits={credits} loading={loadingCredits} />,
        dataCallback: fetchCredits,
        url: routing.credits,
      },
      [ACCOUNT_MANAGEMENT_PAGE.GIFT_CARDS]: {
        component: (
          <AccountPageGiftCards
            giftCards={giftCards}
            giftCardPurchaseInfo={giftCardPurchaseInfo}
            loading={loadingGiftCards}
          />
        ),
        dataCallback: fetchGiftCards,
        url: routing.giftCards,
      },
      [ACCOUNT_MANAGEMENT_PAGE.PAYMENT]: {
        component: <AccountPagePayments paymentsData={payments} loading={loadingPayments} />,
        dataCallback: fetchPayments,
        url: routing.payment,
      },
      [ACCOUNT_MANAGEMENT_PAGE.DELIVERY_ADDRESSES]: {
        component: (
          <AccountDeliveryAddresses
            homeAddresses={homeAddresses}
            corpAddresses={corpAddresses}
            selectedAddressServiceType={
              addressesData?.userDeliveryAddresses?.selectedAddress?.address?.serviceType
            }
            loading={loadingAddresses}
          />
        ),
        dataCallback: fetchUserDeliveryAddresses,
        url: routing.delivery,
      },
      [ACCOUNT_MANAGEMENT_PAGE.ORDER_HISTORY]: {
        component: (
          <AccountPageOrderHistory
            orderHistory={{
              pastOrders,
              activeOrders,
              modifyingOrderId,
            }}
            loading={loadingOrderHistory}
            prevOrderHistoryData={prevOrderHistoryData}
          />
        ),
        dataCallback: fetchOrderHistory,
        url: routing.orderHistory,
      },
      [ACCOUNT_MANAGEMENT_PAGE.DELIVERY_PASS]: {
        component: (
          <AccountPageDeliveryPass
            deliveryPassInfo={deliveryPassInfo}
            loading={loadingDeliveryPassInfo}
            called={deliveryPassInfoCalled}
          />
        ),
        dataCallback: fetchDeliveryPassInfo,
        url: routing.accountDeliveryPass,
      },
      [ACCOUNT_MANAGEMENT_PAGE.DELETE_CONFIRM]: {
        component: <AccountDeleteConfirm />,

        dataCallback: async () => false,
        url: routing.deleteConfirm,
      },
      [ACCOUNT_MANAGEMENT_PAGE.CHEF_TABLE]: {
        component: <AccountPageChefTable />,
        dataCallback: fetchAccountPreferences,
        url: routing.chefTable,
      },
      [ACCOUNT_MANAGEMENT_PAGE.REFER_A_FRIEND]: {
        component: <AccountPageReferAFriend />,
        dataCallback: async () => false,
        url: routing.referAFriend,
      },
      [ACCOUNT_MANAGEMENT_PAGE.EMPTY]: {
        component: null,

        dataCallback: async () => false,
        url: routing.account,
      },
    }),
    [
      accountPreferences,
      activeOrders,
      credits,
      giftCards,
      giftCardPurchaseInfo,
      deliveryPassInfo,
      fetchAccountPreferences,
      fetchCredits,
      fetchGiftCards,
      fetchDeliveryPassInfo,
      fetchUserDeliveryAddresses,
      fetchOrderHistory,
      fetchPayments,
      corpAddresses,
      homeAddresses,
      loadingDeliveryPassInfo,
      loadingOrderHistory,
      loadingPayments,
      loadingAddresses,
      loadingGiftCards,
      modifyingOrderId,
      loadingCredits,
      loadingPreferences,
      pastOrders,
      prevOrderHistoryData,
      payments,
      deliveryPassInfoCalled,
      addressesData?.userDeliveryAddresses?.selectedAddress?.address,
    ],
  );

  const fetchPageData = useCallback(
    async (page: ACCOUNT_MANAGEMENT_PAGE | ACCOUNT_MANAGEMENT_INFO, shouldNavigate = false) => {
      if (
        page === ACCOUNT_MANAGEMENT_INFO.STANDING_ORDERS ||
        page === ACCOUNT_MANAGEMENT_PAGE.EMPTY ||
        !content[page as ACCOUNT_MANAGEMENT_PAGE]
      ) {
        return;
      }
      const nextPage = content[page as ACCOUNT_MANAGEMENT_PAGE] ? page : DEFAULT_PAGE;
      const nextPageData = content[nextPage as ACCOUNT_MANAGEMENT_PAGE];
      try {
        const { error } = (await nextPageData.dataCallback()) as ApolloQueryResult<
          ApolloError | undefined
        >;
        if (!error) {
          setCurrentPage(nextPage as ACCOUNT_MANAGEMENT_PAGE);
          setHasAccessToAccountPages(true);
        }
        if (shouldNavigate) {
          await router.push(
            {
              pathname: nextPageData.url,
            },
            undefined,
            { shallow: true },
          );
        }
      } catch (error) {
        setCurrentPage(nextPage as ACCOUNT_MANAGEMENT_PAGE);
        openAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
      }
    },
    [content, openAuthModal, router],
  );

  function checkIsAccountPage() {
    const pageId = getId(router.query.id);
    const accountPages: string[] = Object.values(omit(ACCOUNT_MANAGEMENT_PAGE, 'EMPTY'));
    const isAllowedAccountPage = accountPages.includes(pageId);
    return router.asPath.startsWith(routing.account) && isAllowedAccountPage;
  }

  useEffect(() => {
    if (isSignedIn) {
      if (!checkIsAccountPage()) {
        return;
      }
      const page = (router?.query?.id ?? DEFAULT_PAGE) as ACCOUNT_MANAGEMENT_PAGE;
      setCurrentPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, router]);

  const getPageComponent = () => {
    return content[currentPage]?.component || null;
  };

  return {
    getPageComponent,
    fetchPageData,
    currentPage,
    isLoading: loading,
  };
};
