import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const ACTIVATE_FREE_TRIAL_DELIVERY_PASS = gql`
  mutation ActivateFreeTrialDeliveryPass($freeTrialOptinSku: String!) {
    activateFreeTrialDeliveryPass(freeTrialOptinSku: $freeTrialOptinSku) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
