import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { Button } from '@components/UI';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useEmptyCart } from '@hooks/cart';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import styles from './ClearCartConfirmModal.module.scss';

interface ClearCartConfirmModalProps {
  children?: never;
  open: boolean;
  onClose: () => void;
}

export const ClearCartConfirmModal = ({ open, onClose }: ClearCartConfirmModalProps) => {
  const [emptyCart, { loading }] = useEmptyCart();
  const { t } = useLocalization('cart');

  const emptyCartHandler = () => {
    emptyCart({
      onCompleted: () => {
        onClose();
        sendGtmEvent({
          event: 'emptyCart',
        });
      },
    });
  };

  return (
    <ModalWindow
      className={styles.modal}
      open={open}
      onClose={onClose}
      labelId={MODAL_TITLE_ID.CLEAR_CART}
    >
      <div>
        <Typography
          className={styles.title}
          variant="h3"
          component="h2"
          id={MODAL_TITLE_ID.CLEAR_CART}
        >
          <Trans
            i18nKey="cart:emptyCartModal.title"
            components={{
              separator: <br />,
            }}
          />
        </Typography>
        <Typography className={styles.message} variant="body" component="div">
          {t('emptyCartModal.content')}
        </Typography>
        <Button size="large" isFullWidth onClick={emptyCartHandler} loading={loading}>
          {t('emptyCartModal.button')}
        </Button>
      </div>
    </ModalWindow>
  );
};
