import { useExpressContext } from '@context/ExpressContext/ExpressContext';

export const useDisableExpressContext = () => {
  const { enabled, toggle } = useExpressContext();

  return (receivedIsExpress: boolean) => {
    if (enabled && !receivedIsExpress) {
      toggle(receivedIsExpress);
    }
  };
};
