import { Grid, Typography } from '@mui/material';
import React from 'react';
import cx from 'classnames';
import { ProductPrice } from '@commons/product';
import { Subtotal as SubtotalType } from '@commons/cart';
import { Price } from '@commons/price';
import { usePromo } from '@hooks/promo/usePromo';
import { PromotionType } from '@api';
import { useLocalization } from '@hooks/useLocalization';
import { CartPromo } from '../CartPromo/CartPromo';
import { OrderMinimum } from '../OrderMinimum/OrderMinimum';
import { Delivery } from '../Delivery/Delivery';
import { Subtotal } from '../Subtotal/Subtotal';
import styles from './BagDetails.module.scss';

interface BagDetailsProps {
  orderMinimumDetails: { remaining: string; min: string } | undefined;
  subtotal: SubtotalType;
  orderMinimum: Price;
  deliveryVisible: boolean;
  totalWithoutDiscount: ProductPrice;
}

export const BagDetails = ({
  orderMinimumDetails,
  subtotal,
  orderMinimum,
  deliveryVisible,
  totalWithoutDiscount,
}: BagDetailsProps) => {
  const { t } = useLocalization();
  const { status: promoStatus, cartPromo, promotionApplied, promotionType } = usePromo();
  const isPromoAppliedAtCheckout = promotionApplied && promotionType !== PromotionType.LINE_ITEM;

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        className={cx(styles.details_wrapper, {
          [styles.order_error]: orderMinimumDetails,
        })}
      >
        {
          <Grid item className={styles.no_left_padding}>
            <CartPromo promoStatus={promoStatus} promoCode={cartPromo} />
          </Grid>
        }
        <Grid item className={cx(styles.details_wrapper_sub, styles.no_left_padding)}>
          {
            <OrderMinimum
              subtotal={subtotal}
              orderMinimum={orderMinimum}
              className={styles.order_minimum_desktop}
            />
          }
          <div className={styles.detail_items}>
            {<Delivery deliveryVisible={deliveryVisible} />}
            <Subtotal
              dataTestId="cart-subtotal"
              current={subtotal}
              previous={totalWithoutDiscount}
            />
          </div>
        </Grid>
      </Grid>
      <div aria-live="polite">
        {isPromoAppliedAtCheckout && (
          <Typography
            variant="smallBody"
            className={styles.promo_applied}
            data-testid="promo-applied-at-checkout"
          >
            {t('promo.appliedAtCheckout')}
          </Typography>
        )}
      </div>
    </Grid>
  );
};
