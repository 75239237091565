import { FocusEventHandler, SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import isString from 'lodash/isString';
import { InputProps } from '@components/UI/Inputs/Input/Input';
import {
  Suggestion,
  useLazyAddressSuggestions,
} from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import { Input } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { AddressNotFound } from './components/AddressNotFound/AddressNotFound';
import styles from './addressAutocomplete.module.scss';

export type AddressAutocompleteProps = Omit<
  AutocompleteProps<Suggestion, false, false, true>,
  'options' | 'renderInput' | 'onSelect' | 'onInputChange'
> &
  Pick<InputProps, 'label'> & {
    inputAddress: string;
    isAddressSelected: boolean;
    blurOnSelect?: boolean;
    error?: string | null;
    onSelect: (value: Suggestion | null) => void;
    onChangeMode: () => void;
    onInputAddress: (value: string) => void;
    onAPIError?: (value: string) => void;
    onInputChange?: (value?: string) => void;
    onFocusedOut?: () => void;
    id?: string;
  };

const TIME_DELAY = 300;

const filterOptions = (options: Suggestion[]) => options;

export const AddressAutocomplete = ({
  inputAddress,
  isAddressSelected,
  error,
  placeholder,
  label,
  disabled,
  blurOnSelect,
  onSelect,
  onChangeMode,
  onInputAddress,
  onAPIError,
  onInputChange,
  onFocusedOut,
  id,
  onFocus,
  ...rest
}: AddressAutocompleteProps) => {
  const {
    fetch: fetchSuggestions,
    data: suggestions,
    error: suggestionsError,
    isLoading,
  } = useLazyAddressSuggestions();

  const { t } = useLocalization();
  useEffect(() => {
    if (onAPIError && suggestionsError) onAPIError(suggestionsError);
  }, [suggestionsError, onAPIError]);

  const [isFocused, setIsFocused] = useState(false);

  const inputAddressDebounced = useDebounce<string>(inputAddress, TIME_DELAY);

  useUpdateEffect(() => {
    fetchSuggestions(inputAddress);
  }, [inputAddressDebounced]);

  const handleInputChange = (e: SyntheticEvent, value: string, reason: string) => {
    if (reason !== 'input') return;
    onInputAddress(value);
    if (onInputChange) onInputChange(value);
  };

  const renderOption = <T,>(props: T, option: Suggestion): JSX.Element => {
    const matchedString = option.label.slice(0, inputAddress.length);
    const restString = option.label.replace(matchedString, '');
    const key = Object.values(option).join();
    return (
      <li {...props} key={key} className={styles.option}>
        {matchedString && <span className={styles.matched}>{matchedString}</span>}
        {restString}
      </li>
    );
  };

  const shouldRenderNoOptions = !isAddressSelected && !!inputAddress?.length && !isLoading;

  const select = (value: Suggestion | string | null): void => {
    if (typeof value === 'string') {
      if (suggestions.length && suggestions[0].label.toLowerCase() === value.toLowerCase()) {
        onSelect(suggestions[0]);
      } else {
        // TODO: Research "inputAddress === value"
      }
    } else {
      onSelect(value || null);
    }
  };

  const handleChange = (event: SyntheticEvent, value: Suggestion | string | null) => {
    select(value);
  };

  const handleFocus: FocusEventHandler<HTMLDivElement> = (e) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = () => {
    select(inputAddress);
    setIsFocused(false);
  };

  useUpdateEffect(() => {
    !isFocused && onFocusedOut && onFocusedOut();
  }, [isFocused]);

  return (
    <div className={styles.autocomplete_wrapper}>
      <Autocomplete
        {...rest}
        inputValue={inputAddress}
        disabled={disabled}
        className={styles.input}
        options={isAddressSelected ? [] : suggestions}
        filterOptions={filterOptions}
        classes={{
          paper: styles.list_wrapper,
          listbox: styles.list,
          noOptions: styles.no_options_wrapper,
        }}
        clearOnBlur={false}
        freeSolo
        getOptionLabel={(item) => (isString(item) ? item : item?.label || '')}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        renderOption={renderOption}
        renderInput={(params) => (
          <Input
            data-qa="address_input"
            ref={params.InputProps.ref}
            label={label ?? t('address')}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-address',
            }}
            error={!!error}
            helperText={error || undefined}
            disabled={disabled}
            id={id}
          />
        )}
        isOptionEqualToValue={(option, item) =>
          option.label === item.label && option.zipcode === item.zipcode
        }
        blurOnSelect={blurOnSelect}
        id={id}
      />
      {shouldRenderNoOptions && <AddressNotFound onClick={onChangeMode} />}
    </div>
  );
};
