export interface PComplaintReason {
  id?: string | null;
  reason?: string | null;
}

export interface IComplaintReason {
  id: string;
  reason: string;
}

export function ComplaintReason(p?: PComplaintReason): IComplaintReason {
  return {
    id: p?.id ?? '',
    reason: p?.reason ?? '',
  };
}
