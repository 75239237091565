import { gql } from '@apollo/client';
import { COUPON_FRAGMENT } from '@graphql/fragments/common/couponFragment';
import { PRODUCT_BASIC_FRAGMENT } from '@graphql/fragments/product/productBasicFragment';
import { TOTAL_SECTION_FRAGMENT } from '@graphql/fragments/product/common/totalSectionFragment';
import { SUBTOTAL_BOX_FRAGMENT } from '@graphql/fragments/product/common/subtotalBoxFragment';
import { BREADCRUMB_FRAGMENT } from '@graphql/fragments/common/breadcrumbFragment';
import { PRODUCT_TILE_FRAGMENT } from '@graphql/fragments/product/productTileFragment';
import { PRODUCT_COUNTER_FRAGMENT } from '@graphql/fragments/product/productCounterFragment';
import { PROMOTION_STATE_FRAGMENT } from '@graphql/fragments/cart/common/promotionStateFragment';
import { DISCOUNT_FRAGMENT } from '@graphql/fragments/cart/common/discount';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';
import { PRODUCT_CART_PAGE_FRAGMENT } from '@graphql/fragments/product/productCartPageFragment';
import { ORDER_MODIFICATIONS_FRAGMENT } from '@graphql/fragments/cart/common/orderModificationsFragment';

export const CART_FRAGMENT = gql`
  fragment cartFragment on Cart {
    changeQuantityEventId
    timeslotTypeSuggestion
    cartLinesCount
    couponMessage
    deliveryPassCartOnly
    productCounter {
      ...productCounterFragment
    }
    totalSection {
      ...totalSectionFragment
    }
    promotionCode {
      ...promotionStateFragment
    }
    cartLinePromotionApplied
    tips {
      tipAmounts
      tipApplied
    }
    estimatedTotal {
      ...subtotalBoxFragment
    }
    totalWithoutDiscount {
      ...priceFragment
    }
    nonExpressSection {
      subTotal {
        ...priceFragment
      }
      cartLines {
        id
        itemPosition
        itemListName
        categoryId
        productId
        productImage
        productPageUrl
        description
        descriptionExtended
        hasRecommendedReplacement
        advanceOrderAvailable
        productCouponClipped
        promotionCode
        promotionApplied
        promotionDescription
        expressEligible
        discountPerUnit {
          ...priceFragment
        }
        coupon {
          ...couponFragment
        }
        unscaledPrice {
          ...priceFragment
        }
        displayPrice {
          ...priceFragment
        }
        unitPrice {
          ...priceFragment
        }
        unscaledPrice {
          ...priceFragment
        }
        quantitySelector {
          maxQuantity
          minQuantity
          quantity
          quantityIncrement
        }
        confOptions {
          characteristicName
          characteristicValue
        }
        discount {
          ...discountFragment
        }
        priceIndicators {
          hasTax
          hasDepositValue
          hasScaledPricing
          hasEstimatedPrice
        }
        recommendedProducts {
          ...productBasicFragment
        }
        breadcrumbs {
          ...breadcrumbFragment
        }
        productBasicData {
          ...productBasicFragment
        }
      }
    }
    expressOnlySection {
      subTotal {
        ...priceFragment
      }
      cartLines {
        id
        itemPosition
        itemListName
        categoryId
        productId
        productImage
        productPageUrl
        description
        descriptionExtended
        hasRecommendedReplacement
        advanceOrderAvailable
        productCouponClipped
        promotionCode
        promotionApplied
        promotionDescription
        expressEligible
        discountPerUnit {
          ...priceFragment
        }
        coupon {
          ...couponFragment
        }
        unscaledPrice {
          ...priceFragment
        }
        displayPrice {
          ...priceFragment
        }
        unitPrice {
          ...priceFragment
        }
        unscaledPrice {
          ...priceFragment
        }
        quantitySelector {
          maxQuantity
          minQuantity
          quantity
          quantityIncrement
        }
        confOptions {
          characteristicName
          characteristicValue
        }
        discount {
          ...discountFragment
        }
        priceIndicators {
          hasTax
          hasDepositValue
          hasScaledPricing
          hasEstimatedPrice
        }
        recommendedProducts {
          ...productBasicFragment
        }
        breadcrumbs {
          ...breadcrumbFragment
        }
        productBasicData {
          ...productBasicFragment
        }
      }
    }
    cartSections {
      name
      sectionImage
      sectionInfo {
        sectionTitle
        subTotal {
          ...priceFragment
        }
        wine
      }
      cartLines {
        id
        categoryId
        configurationDescription
        coupon {
          ...couponFragment
        }
        itemPosition
        couponClipped
        couponStatusText
        promotionCode
        promotionApplied
        promotionDescription
        selectedSalesUnit
        discountPerUnit {
          ...priceFragment
        }
        unscaledPrice {
          ...priceFragment
        }
        displayPrice {
          ...priceFragment
        }
        productExtendedData {
          ...productCartPageFragment
          optionalProductsData {
            title
            items {
              ...productTileFragment
            }
          }
        }
        freeSample
        unitPrice {
          ...priceFragment
        }
        unscaledPrice {
          ...priceFragment
        }
        quantitySelector {
          maxQuantity
          minQuantity
          quantity
          quantityIncrement
        }
        confOptions {
          characteristicName
          characteristicValue
        }
        discount {
          ...discountFragment
        }
        atpLine {
          availableQuantity
          cartLineId
          recommendedProducts {
            ...productBasicFragment
          }
          description
        }
        priceIndicators {
          hasTax
          hasDepositValue
          hasScaledPricing
          hasEstimatedPrice
        }
        advanceOrderAvailable
        itemListName
        departmentLabel
      }
    }
    minOrder {
      ...priceFragment
    }
    totalWithoutDiscount {
      ...priceFragment
    }
    containsWineSection
    expressDeliveryNotification {
      isNotificationRequired
      expressItemsCount
    }
    orderModifications {
      ...orderModificationsFragment
    }
    billingReferenceInfo {
      billingReference
      isCorporateUser
    }
    sampleProducts {
      ...productBasicFragment
    }
  }
  ${COUPON_FRAGMENT}
  ${PRODUCT_CART_PAGE_FRAGMENT}
  ${PRODUCT_BASIC_FRAGMENT}
  ${TOTAL_SECTION_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${DISCOUNT_FRAGMENT}
  ${PROMOTION_STATE_FRAGMENT}
  ${SUBTOTAL_BOX_FRAGMENT}
  ${PRODUCT_COUNTER_FRAGMENT}
  ${PRODUCT_TILE_FRAGMENT}
  ${BREADCRUMB_FRAGMENT}
  ${ORDER_MODIFICATIONS_FRAGMENT}
`;
