import React from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { BaseLink as Link } from '@components/UI';
import { InnerItemType } from '@layouts/Footer/Footer';

interface BottomLineProps {
  item: InnerItemType;
  className?: string;
}

export const BottomLine = ({ item, className }: BottomLineProps) => {
  const { link } = item;
  return (
    <Link legacyBehavior href={link ?? '#'}>
      <a className={cx(className)}>
        <Typography variant="smallBody">{item.name}</Typography>
      </a>
    </Link>
  );
};
