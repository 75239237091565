import { gql } from '@apollo/client';
import { LIGHT_CART_MUTATION_FRAGMENT } from '@graphql/fragments/cart/common/lightCartMutationFragment';

export const SET_PRODUCT_QUANTITY_IN_CART_LIGHT = gql`
  mutation SetProductQuantityInCartLight(
    $productDataInput: ProductDataInput!
    $inModifyMode: Boolean
  ) {
    changeCartItemQuantityLight(productDataInput: $productDataInput, inModifyMode: $inModifyMode) {
      ...lightCartMutationFragment
    }
  }
  ${LIGHT_CART_MUTATION_FRAGMENT}
`;
