import { createContext, Dispatch, useContext } from 'react';
import { ALERT_TYPES } from '@commons/account';

export enum ACCOUNT_ALERT_TYPES {
  SHOW_ALERT = 'SHOW_ALERT',
  CLEAR_ALERT = 'CLEAR_ALERT',
}

export type Action = {
  type: ACCOUNT_ALERT_TYPES;
  payload: AccountAlertState;
};

export type AlertType = 'success' | 'error';

export interface AccountAlertData {
  type: AlertType;
  message: string;
}

export type AccountAlertState = Partial<AccountAlertData>;

export type AccountAlertContextInterface = {
  state: AccountAlertState;
  dispatch: Dispatch<Action>;
};

export const initAccountAlertState: AccountAlertState = {};

export const AccountAlertContext = createContext<AccountAlertContextInterface>({
  state: initAccountAlertState,
  dispatch: () => undefined,
});

export const useAccountAlertContext = () => {
  const context = useContext(AccountAlertContext);

  if (context === undefined) {
    throw new Error('useAccountAlertContext must be used within a AccountAlertProvider');
  }

  const dispatchAlert = (type: ALERT_TYPES, message: string) => {
    context.dispatch({
      type: ACCOUNT_ALERT_TYPES.SHOW_ALERT,
      payload: {
        type: type,
        message,
      },
    });
  };

  return { context, dispatchAlert };
};
