import Trans from 'next-translate/Trans';
import { Typography } from '@mui/material';
import cx from 'classnames';
import { deliveryPass as DeliveryPassIcon } from '@assets/icons/system/index';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI/Button/Button';
import styles from './ActivatedDeliveryPass.module.scss';

interface ActivatedDeliveryPassProps {
  onClose: () => void;
}

export const ActivatedDeliveryPass = ({ onClose }: ActivatedDeliveryPassProps) => {
  const { t } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <DeliveryPassIcon width={50} height={50} className={styles.icon} />
      <Typography variant="h4" className={styles.title}>
        <Trans
          i18nKey="common:deliveryPass.modal.title"
          components={{
            span: (
              <Typography
                variant="h4"
                component="span"
                className={cx(styles.colored, styles.title)}
              />
            ),
          }}
        />
      </Typography>
      <Button size="large" className={styles.button} onClick={onClose}>
        {t('buttons.okay')}
      </Button>
    </div>
  );
};
