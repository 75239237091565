import { gql } from '@apollo/client';
import { PAYMENT_METHODS_FRAGMENT } from '@graphql/fragments/account/paymentMethods';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';

export const LINK_E_WALLET = gql`
  mutation LinkEWallet($eWalletInput: LinkEWalletInput!) {
    linkEWallet(eWalletInput: $eWalletInput) {
      data {
        ...paymentMethodsFragment
      }
      validationErrors {
        ...validationErrorFragment
      }
    }
  }
  ${PAYMENT_METHODS_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
`;
