import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { RadioButton } from '@components/UI';
import { PAYMENT_TYPE, PaymentsType, EWALLET_PAYMENT_TYPE } from '@commons/payment';
import { getLastFourNumbers } from '@utils/getCardNumber';
import { getRoutingBasedUITheme } from '@helpers/common.helpers';
import { UnlinkPaymentMethodButton } from './components/UnlinkPaymentMethodButton/UnlinkPaymentMethodButton';
import { RemovePaymentMethodButton } from './components/RemovePaymentMethodButton/RemovePaymentMethodButton';
import { EditPaymentMethodButton } from './components/EditPaymentMethodButton/EditPaymentMethodButton';
import styles from './PaymentMethod.module.scss';

interface PaymentMethodProps {
  children?: ReactNode;
  id: string;
  email: string | null;
  type: PaymentsType;
  expiration: string | null;
  accountNumber: string | null;
  eWalletID: string | null;
  onChange: () => void;
  value: string;
  onClose: () => void;
  onUnlinkModalOpen?: (type: PaymentsType) => void;
  bankAccountType: string | null;
  isExpired: boolean;
  selectMode?: boolean;
  isModifying?: boolean;
  isCreditDebitCardOnly?: boolean;
  isSinglePayment: boolean;
}

export const PaymentMethod = ({
  id,
  type,
  expiration,
  email,
  accountNumber,
  value,
  onChange,
  eWalletID,
  onClose,
  onUnlinkModalOpen,
  bankAccountType,
  isExpired,
  selectMode,
  isModifying,
  isCreditDebitCardOnly,
  isSinglePayment,
}: PaymentMethodProps) => {
  const { t } = useLocalization('checkout');
  const uiTheme = getRoutingBasedUITheme();

  const handleUnlink = () => {
    onClose();
    onUnlinkModalOpen?.(type);
  };

  const isBankAccountType = type === PAYMENT_TYPE.ECHECK;
  const isEbtType = type === PAYMENT_TYPE.EBT;
  const hasNoExpirationDatePayment = isBankAccountType || isEbtType;
  const isEWallet = type === EWALLET_PAYMENT_TYPE.PAYPAL || type === EWALLET_PAYMENT_TYPE.VENMO;
  const isGiftCard = type === PAYMENT_TYPE.GIFT_CARD;
  const unlinkPaymentMethod = isSinglePayment ? null : (
    <UnlinkPaymentMethodButton onUnlink={handleUnlink} type={type} email={email} />
  );
  const editButton = !!eWalletID ? (
    unlinkPaymentMethod
  ) : (
    <EditPaymentMethodButton
      id={id}
      type={type}
      accountNumber={accountNumber}
      uiTheme={uiTheme}
      noMargin={hasNoExpirationDatePayment}
    />
  );
  const paymentMethodButton = isExpired ? (
    <RemovePaymentMethodButton id={id} accountNumber={accountNumber} isModifying={isModifying} />
  ) : (
    editButton
  );
  const infoPaymentText = !!eWalletID ? (
    <Typography className={styles.email}>{email}</Typography>
  ) : (
    <Typography>{t('payment.editPaymentModal.expiration', { expiration })}</Typography>
  );
  const expiredInfoText = isExpired ? (
    <Typography>{t('payment.editPaymentModal.expiredDate')}</Typography>
  ) : (
    infoPaymentText
  );
  const expirationDateText = hasNoExpirationDatePayment ? null : expiredInfoText;
  const paymentTypeText = isBankAccountType ? bankAccountType : type;
  const lastCardNumbers = !eWalletID ? ` ${getLastFourNumbers(accountNumber)}` : '';
  const paymentName = `${paymentTypeText}${lastCardNumbers}`;

  if (isCreditDebitCardOnly && (isEWallet || isEbtType || isGiftCard || isBankAccountType))
    return null;

  return (
    <div>
      <RadioButton
        className={styles.payment_method}
        key={id}
        isChecked={value === id}
        id={id}
        value={value}
        onChange={onChange}
        isDisabled={isExpired}
        dataTestId="payment_method"
        mode={uiTheme}
      >
        <div className={styles.payment_info}>
          <Typography>{paymentName}</Typography>
          {expirationDateText}
        </div>
        {!selectMode && paymentMethodButton}
      </RadioButton>
    </div>
  );
};
