import { useMutation } from '@apollo/client';
import { SET_PRODUCT_QUANTITY_IN_CART_LIGHT } from '@graphql/cart/mutations/setProductQuantityInCartLight';
import { updateLightCartCache } from '@hooks/cart/updateLightCartCache';
import { updateModifyingOrderAlertCache } from '@hooks/account/updateModifyingOrderAlertCache';
import {
  LightweightCart,
  SetProductQuantityInCartLightMutation,
  SetProductQuantityInCartLightMutationVariables,
} from '@api';

export const useSetProductQuantityInCartLight = () => {
  return useMutation<
    SetProductQuantityInCartLightMutation,
    SetProductQuantityInCartLightMutationVariables
  >(SET_PRODUCT_QUANTITY_IN_CART_LIGHT, {
    update(cache, { data }) {
      const newCartApi = data?.changeCartItemQuantityLight as LightweightCart;

      if (newCartApi) {
        updateModifyingOrderAlertCache(cache, newCartApi);
        updateLightCartCache(cache, newCartApi);
      }
    },
  });
};
