import { FieldProps } from 'formik';
import { Input } from '@components/UI';

interface TextFieldProps extends FieldProps {
  minLength: number;
  maxLength: number;
}

export const TextField = ({ field, form, minLength, maxLength, ...props }: TextFieldProps) => {
  return <Input {...field} {...props} inputProps={{ minLength, maxLength }} />;
};
