import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { GiftCardPdfQuery, GiftCardPdfQueryVariables } from '@api';
import { PurchasedGiftCard } from '@commons/giftCards';
import { GET_GIFT_CARD_PDF } from '@graphql/giftCard/queries/giftCardPdf';
import { useAlert } from '@hooks/useAlert';
import { useModal } from '..';

export const usePurchasedGiftCard = (card: PurchasedGiftCard) => {
  const [getGiftCardPdf, { loading: loadingGiftCardPdf }] = useLazyQuery<
    GiftCardPdfQuery,
    GiftCardPdfQueryVariables
  >(GET_GIFT_CARD_PDF, {
    variables: {
      certNum: card.certificateNumber,
      saleId: card.saleId,
    },
    nextFetchPolicy: 'cache-first',
  });
  const resendGiftCardModalProps = useModal();
  const [alertOpened, setAlertOpened] = useState(false);

  const handleAlertToggle = () => {
    setAlertOpened(!alertOpened);
  };

  const handleSuccessResend = () => {
    handleAlertToggle();
    resendGiftCardModalProps.closeModal();
  };

  useAlert({
    shouldHide: alertOpened,
    hideCallback: handleAlertToggle,
  });

  return {
    getGiftCardPdf,
    resendGiftCardModalProps,
    alertOpened,
    handleAlertToggle,
    handleSuccessResend,
    loadingGiftCardPdf,
  };
};
