import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { routing } from '@constants/routing';
import { Button } from '@components/UI';
import styles from './ChangeOrCancel.module.scss';

interface ChangeOrCancelProps {
  customerServicePhoneNumber: string;
}

export const ChangeOrCancel = ({ customerServicePhoneNumber }: ChangeOrCancelProps) => {
  const { t } = useLocalization('account');

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography component="p" variant="smallBody" className={styles.title}>
          {t('deliveryPass.changeOrCancel')}
        </Typography>
        <Typography component="p" variant="body" className={styles.change_CTA_text}>
          {t('deliveryPass.changeCTAText')}
        </Typography>
        <Typography component="span" variant="body">
          {t('deliveryPass.please')}{' '}
          <a href={`tel:${customerServicePhoneNumber}`} className={styles.phone_link}>
            {t('deliveryPass.call', { phoneNumber: customerServicePhoneNumber })}
          </a>
        </Typography>
      </div>
      <Button variant="underline" href={routing.deliveryPass}>
        {t('deliveryPass.viewPlans')}
      </Button>
    </div>
  );
};
