import { CustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { EcommerceEvent, ProductMetrics } from './utils/eCommerceEvent';

/* eslint-disable @typescript-eslint/naming-convention */
interface CheckoutEventOptions {
  currencyCode: string;
  products: ProductMetrics[];
  customer: CustomerData;
  event: string;
  eventName: string;
  uaLabel: string;
  step: number;
  option: string;
}

interface CheckoutEvent extends EcommerceEvent {
  ecommerce: {
    currencyCode: string;
    checkout: {
      actionField: {
        step: number;
        option: string;
      };
      products: ProductMetrics[];
    };
  };
}

export const getCheckoutEvent = ({
  products,
  currencyCode,
  customer,
  event,
  eventName,
  uaLabel,
  step,
  option,
}: CheckoutEventOptions): CheckoutEvent => {
  return {
    event: event,
    event_name: eventName,
    customer: {
      deliverypass_type: customer.deliveryPassType,
      express_eligible: customer.expressEligible,
      deliverypass_term: customer.deliveryPassTerm,
    },
    ua_category: 'Ecommerce Action',
    ua_action: 'Checkout',
    ua_label: uaLabel,
    ecommerce: {
      currencyCode: currencyCode,
      checkout: {
        actionField: {
          step: step,
          option: option,
        },
        products,
      },
    },
  };
};
