import { CircularProgress } from '@mui/material';
import cn from 'classnames';
import styles from './ButtonOutlinedLoader.module.scss';

interface ButtonOutlinedLoaderProps {
  type?: 'primary' | 'secondary' | 'tertiary';
}

export const ButtonOutlinedLoader = ({ type = 'primary' }: ButtonOutlinedLoaderProps) => {
  return (
    <div className={cn(styles.loader_container, styles[type])}>
      <div className={styles.loader_wrapper}>
        <CircularProgress size={20} className={cn(styles.loader, styles[type])} />
      </div>
    </div>
  );
};
