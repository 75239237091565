import Image from 'next/image';
import cx from 'classnames';
import { useLocalization } from '@hooks/useLocalization';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { BaseGiftCard } from '@adapters/giftCardAdatper';
import styles from './GiftCardListView.module.scss';

interface GiftCardSingleViewProps {
  giftCards: BaseGiftCard[];
  isBulk?: boolean;
  controllers?: (idx: number) => JSX.Element;
}

export const GiftCardListView = ({
  giftCards,
  isBulk = false,
  controllers,
}: GiftCardSingleViewProps) => {
  const { t } = useLocalization('giftCards');
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();

  if (!giftCards) {
    return null;
  }

  const getSenderText = (giftCard: BaseGiftCard) =>
    isBulk
      ? `${t('giftCardBag.to')}: ${giftCard.senderName}`
      : `${t('giftCardBag.to')}: ${giftCard.recipientName}`;

  const getDeliveryText = (giftCard: BaseGiftCard) =>
    t(`giftCardBag.deliveryMethodType.${giftCard.deliveryMethod}`);

  return (
    <>
      {giftCards &&
        giftCards.map((giftCard, idx) => (
          <div className={cx(styles.tile_wrapper)} key={giftCard.id}>
            <div
              className={cx(styles.tile, {
                [styles.with_hover]: !!controllers,
                [styles.drawer_opened]: isSideBagDrawerOpen,
              })}
            >
              <div className={styles.basic_info_wrapper}>
                <div className={styles.image_wrapper}>
                  {giftCard?.gcTemplate?.gcTemplateImageLink && (
                    <Image
                      src={giftCard.gcTemplate.gcTemplateImageLink}
                      alt=""
                      className={styles.image}
                      width="60"
                      height="38"
                    />
                  )}
                </div>
                <div className={styles.basic_info}>
                  <p className={styles.top_text}>
                    {giftCard.amount.formattedPrice.split('.')[0]} {t('giftCardBag.giftCardTitle')}
                  </p>
                  <p className={styles.bottom_text}>
                    {`${getSenderText(giftCard)} | ${getDeliveryText(giftCard)}`}
                  </p>
                </div>
              </div>
              {controllers && controllers(idx)}
            </div>
          </div>
        ))}
    </>
  );
};
