import { ChangeEvent, useRef, useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import Trans from 'next-translate/Trans';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import {
  getAddressInput,
  getAddressInString,
  getAptForForm,
  getExtensionInString,
  getShouldShowAtpUnit,
  handleAddressFormValidation,
  handleFormChange,
  setAddressFieldWarnings,
  setAptUnitFieldError,
} from '@utils/addressForm';
import { SubmitError } from '@components/UI/SubmitError/SubmitError';
import { useLocalization } from '@hooks/useLocalization';
import { Button, Link, RequiredNote } from '@components/UI';
import { Address, AddressToSend, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { accountAdapter } from '@adapters/accountAdapter';
import { useSelectedAddress } from '@hooks/deliveryAddress/useSelectedAddress';
import { addressToString } from '@utils/addressToString';
import { scrollToErrorField } from '@utils/scrollToErrorField';
import { AddAddressField, PhoneField, TextField } from '@components/FormikFields';
import {
  hasOnlyNumbers,
  MAX_COMPANY_NAME_FIELD_LENGTH,
  MAX_EXTENSION_FIELD_LENGTH,
  MEDIUM_FIELD_LENGTH,
} from '@utils/validation';
import { DELIVERY_CORPORATE_LEGACY_LINK } from '@constants/delivery';
import { Grid } from '@components/UI/Grid/Grid';
import { routing } from '@constants/routing';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { useValidator } from '@hooks/validation/useValidation';
import { validateRequiredEmail } from '@utils/validators';
import { ServiceTypeErrorAlert } from '../ServiceTypeErrorAlert/ServiceTypeErrorAlert';
import { useCorporateAddressFormValidation } from './hooks/useCorporateAddressFormValidation';
import {
  CorporateAddressFormProps,
  CorporateFormKeys as FormKeys,
} from './CorporateAddressForm.types';
import { FORM_FIELDS } from './CorporateAddressForm.enums';
import styles from './CorporateAddressForm.module.scss';

export const CorporateAddressForm = ({
  initialValues,
  onSubmitForm,
  submitError,
  onServiceTypeErrorClick,
}: CorporateAddressFormProps) => {
  const standingOrderMode = useStandingOrderMode();
  const { getFeature } = useHeaderContext();
  const { t } = useLocalization();
  const { getAddressFromSuggestion, getSuggestionFromAddress } = accountAdapter();
  const {
    validateAptFormat,
    validateCompanyName,
    validateAddress,
    validateAptUnit,
    validateName,
    validatePhone,
    validateLocationDetails,
  } = useCorporateAddressFormValidation();
  const validateEmailField = useValidator(validateRequiredEmail);
  const { data: selectedAddress } = useSelectedAddress({
    queryOptions: {
      variables: {
        standingOrderMode,
      },
    },
  });
  const {
    minimumAccountPreferences: { email, phoneNumber },
  } = useMinimumAccountPreferences();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const cosFeatureEnabled = !!getFeature?.(FEATURE.COS);
  const router = useRouter();
  const isCreateStandingOrder = router.pathname === routing.createStandingOrder;
  const buttonMode = isCreateStandingOrder ? 'orange' : 'light';
  const [formWarnings, setFormWarnings] = useState({});
  const [APIErrorMessage, setAPIErrorMessage] = useState<string | null>(null);
  const [inputAddress, setInputAddress] = useState<string | null>(null);
  const [inputExtension, setExtension] = useState<string | null>(null);
  const [serviceTypeError, setServiceTypeError] = useState(false);
  const handleExtensionChange = (e: ChangeEvent<HTMLInputElement>) =>
    hasOnlyNumbers(e.target.value) && setExtension(e.target.value);

  const selectedFormattedAddress = selectedAddress?.address
    ? getSuggestionFromAddress(selectedAddress?.address)
    : null;

  const buttonSubmitText = initialValues.address.zipCode
    ? t('delivery.done')
    : t('account:delivery.addressModal.submitButton');

  const initialDeliveryAddress = initialValues?.address?.zipCode
    ? getSuggestionFromAddress(initialValues?.address as Address)
    : null;

  const hasInitialAptUnit = !!initialDeliveryAddress?.secondary;
  const deliveryAddressSuggestion = useRef(initialDeliveryAddress);
  const [isAptUnitAvailable, setIsAptUnitAvailable] = useState(hasInitialAptUnit);

  useEffect(() => {
    if (isSubmittingForm) {
      setTimeout(() => {
        scrollToErrorField();
      }, 100);
      setIsSubmittingForm(false);
    }
  }, [isSubmittingForm]);

  const getFormWarnings = (messageKey: string) => {
    if (messageKey && !cosFeatureEnabled) {
      return {
        address: (
          <Trans
            i18nKey={`common:delivery.warning.addressField.${messageKey}`}
            components={{ a: <Link inheritParentStyle href={DELIVERY_CORPORATE_LEGACY_LINK} /> }}
          />
        ),
      };
    }
    return {};
  };

  const handleAddressAptUnitFieldError = (
    setFieldError: (field: string, message: string | undefined) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addressInString: any,
    addressError: string | void,
  ) => {
    const isResidentialAddressError = addressError === t('delivery.error.residentialAddress');

    if (isResidentialAddressError && isCreateStandingOrder) {
      setFieldError(
        FORM_FIELDS.ADDRESS,
        t('delivery.warning.addressField.residentialAddress.textOnly'),
      );
    }

    if (!addressInString) {
      setServiceTypeError(false);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        companyName: initialValues.contact.companyName,
        address: addressToString(
          initialValues.address.address1,
          initialValues.address.city,
          initialValues.address.state || '',
        ),
        aptUnit: initialValues.address.apartment || null,
        firstName: initialValues.contact.firstName,
        lastName: initialValues.contact.lastName,
        phone: initialValues.contact.phoneNumber.phone || phoneNumber,
        extension: initialValues.contact.phoneNumber.extension,
        instructions: initialValues.contact.instructions,
        deliveryOption: initialValues.backupInfo?.setting,
        email: email || '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (serviceTypeError) {
          setSubmitting(false);
          return;
        }
        return onSubmitForm(
          getAddressInput({
            values,
            getAddressFromSuggestion,
            deliveryAddressSuggestion,
            inputExtension,
          }),
          { setSubmitting },
        );
      }}
    >
      {(formik) => {
        const { values, errors, setFieldValue, setFieldError, validateField } = formik;

        const isErrorVisible = (fieldName: string) => !!errors[fieldName as FormKeys];
        const getHelperText = (fieldName: string) => errors[fieldName as FormKeys];

        if (!isEqual(formik.status?.warnings, formWarnings)) {
          formik.setStatus({ warnings: formWarnings });
        }

        const extensionInString = getExtensionInString(inputExtension, formik.values);
        const shouldShowAtpUnit = getShouldShowAtpUnit(isAptUnitAvailable, values);
        const addressInString = getAddressInString(inputAddress, formik.values);

        const validateAddressField = async () => {
          if (APIErrorMessage) return t('delivery.error.somethingWentWrong');

          const aptFormatError = validateAptFormat(values.aptUnit ?? '');

          const addressError = await validateAddress(
            addressInString,
            values.aptUnit,
            deliveryAddressSuggestion.current,
            (message) => setFieldError(FORM_FIELDS.ADDRESS, message),
            setIsAptUnitAvailable,
            setAddressFieldWarnings(
              getFormWarnings,
              setFormWarnings,
              setServiceTypeError,
              cosFeatureEnabled,
              isCreateStandingOrder,
            ),
            aptFormatError,
            isCreateStandingOrder,
          );
          const aptUnitError = await validateAptUnit(
            values.aptUnit,
            addressInString,
            deliveryAddressSuggestion.current,
            setAptUnitFieldError(setFieldError, SERVICE_TYPE.CORPORATE, t, FORM_FIELDS.APT_UNIT),
            aptFormatError,
          );

          handleAddressAptUnitFieldError(setFieldError, addressInString, aptUnitError);

          setTimeout(() => {
            setFieldError(FORM_FIELDS.APT_UNIT, aptUnitError);
          }, 0);
          return addressError;
        };

        const handleSubmitEvent = () => {
          if (!isSubmittingForm) {
            setIsSubmittingForm(true);
          }
          formik.submitForm();
        };

        return (
          <Form onChange={handleFormChange(values, isErrorVisible, setFieldError)}>
            <Grid container>
              <Grid item xs={12}>
                <RequiredNote optionalNote />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={FORM_FIELDS.COMPANY_NAME}
                  className={styles.field}
                  label={t(`account:delivery.addressModal.fields.businessOrSchoolName.fieldName`)}
                  component={TextField}
                  maxLength={MAX_COMPANY_NAME_FIELD_LENGTH}
                  error={isErrorVisible(FORM_FIELDS.COMPANY_NAME)}
                  helperText={getHelperText(FORM_FIELDS.COMPANY_NAME)}
                  validate={validateCompanyName}
                  id={FORM_FIELDS.COMPANY_NAME}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={FORM_FIELDS.ADDRESS}
                  serviceType={SERVICE_TYPE.CORPORATE}
                  component={AddAddressField}
                  onChange={(value: AddressToSend, isManualFormSubmitted: boolean) => {
                    deliveryAddressSuggestion.current = getSuggestionFromAddress({
                      ...value,
                      state: value.state as string,
                      id: '',
                      isCustomerAnonymousAddress: false,
                      selected: false,
                      popupUrl: '',
                      serviceType: SERVICE_TYPE.CORPORATE,
                    });

                    handleAddressFormValidation(
                      setFieldError,
                      FORM_FIELDS,
                      validateField,
                      isManualFormSubmitted,
                    );
                    setFieldValue(
                      FORM_FIELDS.APT_UNIT,
                      getAptForForm(value.apartment, values.aptUnit),
                    );
                    setInputAddress(addressToString(value.address1, value.city, value.state || ''));
                  }}
                  validate={validateAddressField}
                  // DEPRECATED:BEGIN
                  isAptUnitAvailable={shouldShowAtpUnit}
                  inputAddress={addressInString}
                  setInputAddress={(value: string) => {
                    setInputAddress(value);
                    deliveryAddressSuggestion.current = null;
                    setFieldValue(FORM_FIELDS.APT_UNIT, '');
                    setIsAptUnitAvailable(false);
                  }}
                  isAddressSelected={!!deliveryAddressSuggestion.current}
                  selectedFormattedAddress={selectedFormattedAddress}
                  setIsAptUnitAvailable={setIsAptUnitAvailable}
                  setAPIErrorMessage={setAPIErrorMessage}
                  formik={formik}
                  // DEPRECATED:END
                />
              </Grid>
              {serviceTypeError && (
                <Grid item xs={12}>
                  <ServiceTypeErrorAlert
                    mainText={t('common:delivery.warning.addressField.residentialAddress.text')}
                    linkText={t('common:delivery.warning.addressField.residentialAddress.link')}
                    onLinkClick={() => onServiceTypeErrorClick?.(SERVICE_TYPE.CORPORATE)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Field
                  name={FORM_FIELDS.FIRST_NAME}
                  className={styles.field}
                  label={t(`account:delivery.addressModal.fields.firstName.fieldName`)}
                  component={TextField}
                  maxLength={MEDIUM_FIELD_LENGTH}
                  error={isErrorVisible(FORM_FIELDS.FIRST_NAME)}
                  helperText={getHelperText(FORM_FIELDS.FIRST_NAME)}
                  validate={validateName}
                  id={FORM_FIELDS.FIRST_NAME}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name={FORM_FIELDS.LAST_NAME}
                  className={styles.field}
                  label={t(`account:delivery.addressModal.fields.lastName.fieldName`)}
                  component={TextField}
                  maxLength={MEDIUM_FIELD_LENGTH}
                  error={isErrorVisible(FORM_FIELDS.LAST_NAME)}
                  helperText={getHelperText(FORM_FIELDS.LAST_NAME)}
                  validate={validateName}
                  id={FORM_FIELDS.LAST_NAME}
                />
              </Grid>
              {!email && (
                <Grid item xs={12}>
                  <Field
                    name={FORM_FIELDS.EMAIL}
                    className={styles.field}
                    label={t(`account:delivery.addressModal.fields.email.fieldName`)}
                    component={TextField}
                    error={isErrorVisible(FORM_FIELDS.EMAIL)}
                    helperText={getHelperText(FORM_FIELDS.EMAIL)}
                    onBlur={() => validateField(FORM_FIELDS.EMAIL)}
                    validate={validateEmailField}
                    id={FORM_FIELDS.EMAIL}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  name={FORM_FIELDS.PHONE}
                  className={styles.field}
                  label={t(`account:delivery.addressModal.fields.phone.fieldName`)}
                  component={PhoneField}
                  error={isErrorVisible(FORM_FIELDS.PHONE)}
                  helperText={getHelperText(FORM_FIELDS.PHONE)}
                  validate={validatePhone}
                  id={FORM_FIELDS.PHONE}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={FORM_FIELDS.EXTENSION}
                  className={styles.field}
                  label={t('account:delivery.addressModal.fields.extension.fieldName')}
                  value={extensionInString}
                  component={TextField}
                  onChange={handleExtensionChange}
                  maxLength={MAX_EXTENSION_FIELD_LENGTH}
                  id={FORM_FIELDS.EXTENSION}
                />
                <div className={styles.separator} />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={FORM_FIELDS.INSTRUCTIONS}
                  label={t(`account:delivery.addressModal.fields.corporateInstructions.fieldName`)}
                  className={styles.instructions_field}
                  placeholder={t(
                    'account:delivery.addressModal.fields.corporateInstructions.placeholder',
                  )}
                  component={TextField}
                  maxLength={255}
                  error={isErrorVisible(FORM_FIELDS.INSTRUCTIONS)}
                  helperText={getHelperText(FORM_FIELDS.INSTRUCTIONS)}
                  validate={validateLocationDetails}
                  id={FORM_FIELDS.INSTRUCTIONS}
                  multiline
                  isActive
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitError message={submitError}>
                  <Button
                    className={styles.button}
                    mode={buttonMode}
                    size="large"
                    type="submit"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    onClick={handleSubmitEvent}
                    fullWidth
                  >
                    {buttonSubmitText}
                  </Button>
                </SubmitError>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
