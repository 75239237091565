import { InputHTMLAttributes, ChangeEvent, forwardRef } from 'react';
import cx from 'classnames';
import styles from './Switch.module.scss';

export interface SwitchButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  ariaLabel?: string;
}

export const Switch = forwardRef((props: SwitchButtonProps, ref: React.Ref<HTMLInputElement>) => {
  const { id, disabled, checked, value, onChange = () => null, className, name, ariaLabel } = props;

  return (
    <span className={cx(styles.wrapper, className, checked ? styles.checked : styles.unChecked)}>
      <span className={styles.track} />
      <span className={styles.thumb} />
      <input
        type="checkbox"
        aria-label={ariaLabel}
        className={styles.input}
        id={id}
        data-testid="input-test-id"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        name={name}
        value={value}
        ref={ref}
      />
    </span>
  );
});

Switch.displayName = 'Switch';
