import { createContext, useContext, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { AdServerLazyQueryResult, useAdServerLazy } from '@hooks/account/useAdServerLazy';
import { AdServer } from '@api';

interface IAdServerContext {
  adServer?: AdServer;
  fetchAdServer: () => Promise<AdServerLazyQueryResult>;
  adServerLoading: boolean;
}

const initialContextValue = {
  fetchAdServer: () => {
    // eslint-disable-next-line no-console
    console.error('the initial stub has been invoked instead of the real method');
    return Promise.resolve() as unknown as Promise<AdServerLazyQueryResult>;
  },
  adServerLoading: true,
};

export const AdServerContext = createContext<IAdServerContext>(initialContextValue);

export const useAdServerContext = () => {
  const context = useContext(AdServerContext);
  if (context === undefined) {
    throw new Error('useAdServerContext must be used within a AdServerProvider');
  }
  return context;
};

interface AdServerProviderProps {
  children: ReactNode;
}

export const AdServerProvider = ({ children }: AdServerProviderProps) => {
  const [fetchAdServer, { data, loading: adServerLoading }] = useAdServerLazy();
  const adServer = data?.adServer;
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      fetchAdServer();
    };

    fetchAdServer();

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdServerContext.Provider value={{ adServer, fetchAdServer, adServerLoading }}>
      {/* this div is needed for testing purposes only (TUE-9358) */}
      <div data-query={adServer?.adQuery} data-dfpid={adServer?.dfpId} hidden></div>
      {children}
    </AdServerContext.Provider>
  );
};
