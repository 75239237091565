import { useCartProductCounterLight } from '@hooks/cart/useCartProductCounterLight';

export const useProductQuantityInLineItemLight = (productId: string | null) => {
  const { data: cart, ...rest } = useCartProductCounterLight();

  const data =
    cart?.productCounter?.find((counter: { productId: string }) => counter.productId === productId)
      ?.count || 0;

  return { data, ...rest };
};
