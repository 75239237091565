import { DropdownOptionsType } from '@commons/account';

const getMonthNumber = (number: number) => number.toString().padStart(2, '0');

export const getExpirationMonthOptions = (): DropdownOptionsType[] => {
  const monthsArray = [];
  const endMonth = 12;
  for (let startMonth = 1; startMonth <= endMonth; startMonth++) {
    monthsArray.push({
      value: getMonthNumber(startMonth),
      label: getMonthNumber(startMonth),
    });
  }

  return monthsArray;
};
