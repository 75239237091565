import { useContext, useEffect } from 'react';
import { onGAEvent } from '@modules/ga/emitter';
import { useAnalyticsPageView } from '@modules/ga/hooks/useAnalyticsPageView';
import { AnalyticsDataContext } from '@modules/ga/context/AnalyticsData/AnalyticsDataContext';
import { useResetGtm } from '@modules/ga/hooks/useResetGtm/useResetGtm';
import {
  pagesExceptionResetGtm,
  pageTypesExceptionAnalyticsPageView,
} from '@modules/ga/components/constants';

export const GAHandler = () => {
  const { addEventHandler } = useContext(AnalyticsDataContext);
  useResetGtm(pagesExceptionResetGtm);
  useEffect(() => onGAEvent(addEventHandler), [addEventHandler]);
  useAnalyticsPageView(pageTypesExceptionAnalyticsPageView);
  return null;
};
