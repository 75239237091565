import { Divider, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { paypal as PayPalIcon, venmo as VenmoIcon } from '@assets/icons/payments';
import { PAYMENT_TABS, PaymentsType } from '@commons/payment';
import { useLocalization } from '@hooks/useLocalization';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { AddPaymentsDesktopModal } from '@features/AccountPage/components/AccountPagePayments/components/AddPaymentsDesktopModal/AddPaymentsDesktopModal';
import { useModal } from '@hooks/common/useModal';
import { usePaymentMethods } from '@hooks/usePaymentMethods/usePaymentMethods';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';
import { PaymentsOnSuccess } from '@components/PaymentsAddTabs/PaymentAddTabs.types';
import { Payment } from '@api';
import { AddPaymentsModalContent } from './components/AddPaymentsModalContent/AddPaymentsModalContent';
import { PaymentsButtonsList } from './components/PaymentsButtonsList/PaymentsButtonsList';
import { PaymentTabButton } from './components/PaymentTabButton/PaymentTabButton';
import { PaymentTabPanel } from './components/PaymentTabPanel/PaymentTabPanel';
import { AddCardTabContent } from './components/AddCardTabContent/AddCardTabContent';
import { AddBankAccountTabContent } from './components/AddBankAccountTabContent/AddBankAccountTabContent';
import { usePaymentsScripts } from './hooks/usePaymentsScripts/usePaymentsScripts';
import { VenmoTabContent } from './components/VenmoTabContent/VenmoTabContent';
import { PaypalTabContent } from './components/PaypalTabContent/PaypalTabContent';
import { EbtTabContent } from './components/EbtTabContent/EbtTabContent';
import styles from './PaymentsAddTabs.module.scss';
import { PaymentError } from './components/PaymentError/PaymentError';

interface PaymentsAddTabsProps {
  showPaypal?: boolean;
  showVenmo?: boolean;
  showBankAccount?: boolean;
  showEBT?: boolean;
  showErrorMessage?: boolean;
  defaultTab?: PAYMENT_TABS;
  onSuccess?: PaymentsOnSuccess;
  onError?: (errorMessage: string) => void;
  onClose?: () => void;
}

const ICON_SIZE = 16;
const DEFAULT_ACTIVE_TAB = PAYMENT_TABS.CREDIT_CARD;
const DEFAULT_ERROR_MESSAGE_VALUE = '';

export const PaymentsAddTabs = ({
  showPaypal = false,
  showVenmo = false,
  showBankAccount = false,
  showEBT = false,
  showErrorMessage = true,
  defaultTab,
  onSuccess = () => undefined,
  onError = () => undefined,
  onClose = () => undefined,
}: PaymentsAddTabsProps) => {
  const standingOrderMode = useStandingOrderMode();
  const [activeTab, setActiveTab] = useState<PAYMENT_TABS>(defaultTab || DEFAULT_ACTIVE_TAB);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE_VALUE);
  const [chosenPaymentBtn, setChosenPaymentBtn] = useState<PAYMENT_TABS>(PAYMENT_TABS.CREDIT_CARD);
  const { isLoaded: scriptsLoaded } = usePaymentsScripts();
  const { t } = useLocalization('account');
  const desktopPaymentsModal = useModal();
  const allPaymentsAvailable = showPaypal && showVenmo && showBankAccount && showEBT;
  const { isSmallScreen } = useHeaderContext();
  const LargeDevice = !isSmallScreen;
  const { data } = usePaymentMethods({
    fetchPolicy: 'network-only',
    variables: {
      standingOrderMode,
    },
  });
  const hasNoExistedPaymentMethods =
    data.payments.length === 0 && showPaypal && showVenmo && showEBT;
  const desktopViewActivated = allPaymentsAvailable && LargeDevice;
  const showPaymentMethodsDropDown = !allPaymentsAvailable && hasNoExistedPaymentMethods;

  const resetActiveTab = () => {
    setActiveTab(DEFAULT_ACTIVE_TAB);
    setErrorMessage(DEFAULT_ERROR_MESSAGE_VALUE);
  };

  const handleSuccess = (paymentType: PaymentsType, message?: string, payment?: Payment) => {
    resetActiveTab();
    onSuccess(paymentType, message, payment);
  };

  const handleErrorMessage = (err: string) => {
    setErrorMessage(err);
  };

  const handleChange = (event: React.SyntheticEvent, nextTab: PAYMENT_TABS) => {
    setActiveTab(nextTab);
    setErrorMessage(DEFAULT_ERROR_MESSAGE_VALUE);
  };

  useUpdateEffect(() => {
    onError(errorMessage);
  }, [errorMessage]);

  const handleButtonClick = (paymentType: PAYMENT_TABS) => {
    setChosenPaymentBtn(paymentType);
    desktopPaymentsModal.openModal();
  };

  if (!scriptsLoaded) return null;

  if (showPaymentMethodsDropDown) {
    return (
      <AddPaymentsModalContent
        type={chosenPaymentBtn}
        onTypeChange={setChosenPaymentBtn}
        onError={setErrorMessage}
        errorMessage={showErrorMessage ? errorMessage : DEFAULT_ERROR_MESSAGE_VALUE}
        onSuccess={handleSuccess}
      />
    );
  }

  if (desktopViewActivated) {
    return (
      <>
        <PaymentsButtonsList handleButtonClick={handleButtonClick} />
        <AddPaymentsDesktopModal
          type={chosenPaymentBtn}
          setType={setChosenPaymentBtn}
          closeModal={onClose}
          isOpen={desktopPaymentsModal.isOpen}
          setErrorMessage={setErrorMessage}
        />
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <Tabs
          className={styles.payment_tabs_container}
          aria-label={t('payments.modalTabs.ariaTitle')}
          variant="scrollable"
          scrollButtons={false}
          value={activeTab}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <PaymentTabButton
            className={styles.payment_tab}
            tabValue={PAYMENT_TABS.CREDIT_CARD}
            activeTab={activeTab}
            label={t('payments.modalTabs.creditDebitTab')}
          />
          <PaymentTabButton
            className={styles.payment_tab}
            tabValue={PAYMENT_TABS.BANK_ACCOUNT}
            activeTab={activeTab}
            hidden={!showBankAccount}
            label={t('payments.modalTabs.bankAccountTab')}
          />
          <PaymentTabButton
            className={styles.payment_tab}
            tabValue={PAYMENT_TABS.PAYPAL}
            activeTab={activeTab}
            hidden={!showPaypal}
            label={
              <div className={styles.icon_tab}>
                <PayPalIcon width={ICON_SIZE} className={styles.icon} />
                {t('payments.modalTabs.paypalTab')}
              </div>
            }
          />
          <PaymentTabButton
            className={styles.payment_tab}
            tabValue={PAYMENT_TABS.VENMO}
            activeTab={activeTab}
            hidden={!showVenmo}
            label={
              <div className={styles.icon_tab}>
                <VenmoIcon width={ICON_SIZE} className={styles.icon} />
                {t('payments.modalTabs.venmo')}
              </div>
            }
          />
          <PaymentTabButton
            className={styles.payment_tab}
            tabValue={PAYMENT_TABS.EBT}
            activeTab={activeTab}
            hidden={!showEBT}
            label={
              <div className={styles.icon_tab}>
                <Typography className={styles.text_tab} variant="smallBody">
                  {t('payments.modalTabs.ebt')}
                </Typography>
              </div>
            }
          />
        </Tabs>
        <PaymentTabPanel tabValue={PAYMENT_TABS.CREDIT_CARD} activeTab={activeTab}>
          {showErrorMessage && !!errorMessage && <PaymentError errorMessage={errorMessage} />}
          <AddCardTabContent onSuccess={handleSuccess} onError={setErrorMessage} />
        </PaymentTabPanel>
        {showBankAccount && (
          <PaymentTabPanel tabValue={PAYMENT_TABS.BANK_ACCOUNT} activeTab={activeTab}>
            <AddBankAccountTabContent onSuccess={handleSuccess} onError={handleErrorMessage} />
          </PaymentTabPanel>
        )}
        {showPaypal && (
          <PaymentTabPanel tabValue={PAYMENT_TABS.PAYPAL} activeTab={activeTab}>
            <PaypalTabContent onSuccess={handleSuccess} />
          </PaymentTabPanel>
        )}
        {showVenmo && (
          <PaymentTabPanel tabValue={PAYMENT_TABS.VENMO} activeTab={activeTab}>
            <VenmoTabContent onSuccess={handleSuccess} />
          </PaymentTabPanel>
        )}
        {showEBT && (
          <PaymentTabPanel tabValue={PAYMENT_TABS.EBT} activeTab={activeTab}>
            <EbtTabContent onSuccess={handleSuccess} onError={handleErrorMessage} />
          </PaymentTabPanel>
        )}
      </div>
      <Divider className={styles.divider} />
    </>
  );
};
