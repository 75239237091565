import { AccountPageContextProvider } from '@features/AccountPage/context/AccountPageContext';
import { MainLayout } from '@layouts/MainLayout/MainLayout';
import { MainLayoutProps } from '@layouts/types';

export const AccountLayout = (props: MainLayoutProps) => {
  return (
    <AccountPageContextProvider>
      <MainLayout {...props} />
    </AccountPageContextProvider>
  );
};
