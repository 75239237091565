import { ChangeEvent, WheelEvent, KeyboardEvent, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Divider, Typography } from '@mui/material';
import Image from 'next/image';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useLocalization } from '@hooks/useLocalization';
import { Button, DropDown, FormikInput } from '@components/UI';
import { EDIT_BANK_ACCOUNT_TYPE, BANK_ACCOUNT_DROPDOWN_TYPE } from '@commons/payment';
import { Grid } from '@components/UI/Grid/Grid';
import { usePaymentMethod } from '@hooks/usePaymentMethods/usePaymentMethod';
import { ALERT_TYPES, DropdownOptionsType } from '@commons/account';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { PhoneField } from '@components/FormikFields';
import { SERVICE_TYPE } from '@commons/deliveryAddresses';
import { useCountries } from '@hooks/common/useCountries';
import { getCountryOptions } from '@utils/getCountryOptions';
import { BACKGROUND_MODE } from '@commons/modals';
import { info as InfoIcon } from '@assets/icons/system';
import accountNumberImage from '@assets/images/accountNumber.jpeg';
import routingNumberImage from '@assets/images/routingNumber.jpeg';
import { useValidator } from '@hooks/validation/useValidation';
import {
  validatePhoneNumber,
  validateZipCode,
  validateBillingAddress,
  validateApartment,
  validateUserName,
  validateRoutingNumber,
  validateBankAccountName,
} from '@utils/validators';
import {
  NAME_FIELD_LENGTH,
  MEDIUM_FIELD_LENGTH,
  SMALL_FIELD_LENGTH,
  ZIPCODE_FIELD_LENGTH,
  ROUTING_NUMBER_FIELD_LENGTH,
  ONLY_DIGITS_REGEXP,
} from '@utils/validation';
import { DEFAULT_COUNTRY_NAME } from '@constants/delivery';
import { useEditBankAccount } from '@hooks/payments/useEditBankAccount';
import { EditBankAccountMutation } from '@api';
import { RecaptchaDisclaimer } from '@components/RecaptchaDisclaimer/RecaptchaDisclaimer';
import { getRoutingBasedUITheme } from '@helpers/common.helpers';
import { useStandingOrderMode } from '@features/StandingOrders/hooks/useStandingOrderMode';
import styles from './EditBankAccountModal.module.scss';

interface EditBankAccountModalProps {
  closeModal: () => void;
  id: string;
  backgroundMode?: BACKGROUND_MODE;
  opened: boolean;
}

const enum INFO_IMAGES_TYPE {
  ACCOUNT_NUMBER_IMAGE = 'account_number_image',
  ROUTING_NUMBER_IMAGE = 'routing_number_image',
}

export const EditBankAccountModal = ({
  closeModal,
  id,
  backgroundMode,
  opened,
}: EditBankAccountModalProps) => {
  const standingOrderMode = useStandingOrderMode();
  const { t } = useLocalization('account');
  const { data: editBankAccountInfo } = usePaymentMethod(id);
  const [editBankAccount] = useEditBankAccount();
  const uiTheme = getRoutingBasedUITheme();
  const [errorMessage, setErrorMessage] = useState('');
  const { dispatchAlert } = useAccountAlertContext();
  const { data: countries } = useCountries();
  const countryOptions = useMemo(() => {
    return getCountryOptions(countries);
  }, [countries]);
  const validatePhoneNumberField = useValidator(validatePhoneNumber);
  const validateZipCodeField = useValidator(validateZipCode);
  const validateBillingAddressField = useValidator(validateBillingAddress);
  const validateApartmentField = useValidator(validateApartment);
  const validateBankAccountNameField = useValidator(validateBankAccountName);
  const validateBankNameField = useValidator(validateUserName);
  const validateRoutingNumberField = useValidator(validateRoutingNumber);
  const [imageType, setImageType] = useState<INFO_IMAGES_TYPE | null>(null);
  const isAccountInfoIconShown = INFO_IMAGES_TYPE.ACCOUNT_NUMBER_IMAGE === imageType;
  const isRoutingInfoIconShown = INFO_IMAGES_TYPE.ROUTING_NUMBER_IMAGE === imageType;
  const initialValues = {
    [EDIT_BANK_ACCOUNT_TYPE.BANK_ACCOUNT_TYPE]: editBankAccountInfo.bankAccountType,
    [EDIT_BANK_ACCOUNT_TYPE.NAME]: editBankAccountInfo.nameOnCard,
    [EDIT_BANK_ACCOUNT_TYPE.BANK_NAME]: editBankAccountInfo.bankName,
    [EDIT_BANK_ACCOUNT_TYPE.ACCOUNT_NUMBER]: `•••• •••• •••• ${editBankAccountInfo.cardNumber}`,
    [EDIT_BANK_ACCOUNT_TYPE.VERIFY_ACCOUNT_NUMBER]: `•••• •••• •••• ${editBankAccountInfo.cardNumber}`,
    [EDIT_BANK_ACCOUNT_TYPE.ROUTING_NUMBER]: editBankAccountInfo.routeNumber,
    [EDIT_BANK_ACCOUNT_TYPE.BILLING_ADDRESS]: editBankAccountInfo.billingAddress?.address1 || '',
    [EDIT_BANK_ACCOUNT_TYPE.APARTMENT]: editBankAccountInfo.billingAddress?.apartment || '',
    [EDIT_BANK_ACCOUNT_TYPE.COUNTRY]:
      editBankAccountInfo.billingAddress?.country || DEFAULT_COUNTRY_NAME,
    [EDIT_BANK_ACCOUNT_TYPE.PHONE]: editBankAccountInfo.phoneNumber,
    [EDIT_BANK_ACCOUNT_TYPE.CITY]: editBankAccountInfo.billingAddress?.city || '',
    [EDIT_BANK_ACCOUNT_TYPE.ZIP_CODE]: editBankAccountInfo.billingAddress?.zipCode || '',
  };

  const getBankAccountTypeOptions = () => {
    const bankAccountTypeOptions: DropdownOptionsType[] = [];
    Object.keys(BANK_ACCOUNT_DROPDOWN_TYPE).forEach((type) => {
      bankAccountTypeOptions.push({
        value: type,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: BANK_ACCOUNT_DROPDOWN_TYPE[type],
      });
    });
    return bankAccountTypeOptions;
  };

  const getPaymentInfo = (paymentInfo: EditBankAccountMutation) => {
    const payment = paymentInfo.editBankAccount.data?.payments?.find(
      ({ id: paymentId }) => paymentId === id,
    );
    const accountNumber = payment?.accountNumber?.slice(4) || '';
    const accountType = payment?.bankAccountType || '';

    return {
      accountNumber,
      accountType,
    };
  };

  const handleSubmit = async (values: { [key in EDIT_BANK_ACCOUNT_TYPE]: string }) => {
    const input = {
      id,
      bankName: values.bank_name,
      nameOnCard: values.name,
      abaRouteNumber: values.routing_number,
      bankAccountType: values.bank_account_type,
      phone: values.phone,
      billingAddress: {
        address1: values.billing_address,
        city: values.city,
        country: values.country,
        zipCode: values.zip_code.toString(),
        apartment: values.apartment,
        state: editBankAccountInfo.billingAddress?.state,
        serviceType: editBankAccountInfo.billingAddress?.serviceType || SERVICE_TYPE.HOME,
      },
    };

    editBankAccount({
      variables: {
        input,
        standingOrderMode,
      },
      onCompleted: (res) => {
        if (!!res?.editBankAccount?.validationErrors?.length) {
          return setErrorMessage(res.editBankAccount.validationErrors[0].error);
        }
        const { accountNumber, accountType } = getPaymentInfo(res);
        dispatchAlert(
          ALERT_TYPES.SUCCESS,
          t('payments.successMessages.editBankAccount', {
            accountNumber,
            accountType,
          }),
        );
        closeModal();
      },
    });
  };

  return (
    <ModalWindow
      title={t('payments.editBanAccountModalTitle')}
      className={styles.edit_modal}
      errorMessage={errorMessage}
      onClose={closeModal}
      backgroundMode={backgroundMode}
      open={opened}
      isScrollable
    >
      <Formik
        className={styles.form}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {({ errors, validateField, setFieldValue, values }) => {
          const handlePreventNotNumbers = (e: KeyboardEvent<HTMLImageElement>) => {
            if (!ONLY_DIGITS_REGEXP.test(e.key)) e.preventDefault();
          };

          const handleRoutingNumChange = (e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue(
              EDIT_BANK_ACCOUNT_TYPE.ROUTING_NUMBER,
              e.target.value.slice(0, ROUTING_NUMBER_FIELD_LENGTH),
            );

          const handleZipCodeChange = (e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue(
              EDIT_BANK_ACCOUNT_TYPE.ZIP_CODE,
              e.target.value.slice(0, ZIPCODE_FIELD_LENGTH),
            );

          return (
            <Form className={styles.form}>
              <Grid rowSpacing={4} columnSpacing={2} columns={{ xs: 4, sm: 6 }} container>
                <Grid xs={4} sm={6} item>
                  <Field
                    name={EDIT_BANK_ACCOUNT_TYPE.NAME}
                    control="input"
                    type="text"
                    label={t('payments.editModal.name')}
                    component={FormikInput}
                    maxLength={NAME_FIELD_LENGTH}
                    validate={validateBankAccountNameField}
                    error={!!errors[EDIT_BANK_ACCOUNT_TYPE.NAME]}
                    helperText={errors[EDIT_BANK_ACCOUNT_TYPE.NAME]}
                    onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.NAME)}
                    id={EDIT_BANK_ACCOUNT_TYPE.NAME}
                  />
                </Grid>
                <Grid xs={2} sm={3} item>
                  <Field
                    name={EDIT_BANK_ACCOUNT_TYPE.BANK_NAME}
                    control="input"
                    type="text"
                    label={t('payments.editModal.bank')}
                    component={FormikInput}
                    maxLength={NAME_FIELD_LENGTH}
                    validate={validateBankNameField}
                    error={!!errors[EDIT_BANK_ACCOUNT_TYPE.BANK_NAME]}
                    helperText={errors[EDIT_BANK_ACCOUNT_TYPE.BANK_NAME]}
                    onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.BANK_NAME)}
                    id={EDIT_BANK_ACCOUNT_TYPE.BANK_NAME}
                  />
                </Grid>
                <Grid xs={2} sm={3} item>
                  <DropDown
                    title={t('payments.editModal.bankAccountType')}
                    value={values.bank_account_type}
                    onChange={(newValue) =>
                      setFieldValue(EDIT_BANK_ACCOUNT_TYPE.BANK_ACCOUNT_TYPE, newValue)
                    }
                    options={getBankAccountTypeOptions()}
                    id="edit-payment-bank-type"
                  />
                </Grid>
                <Grid xs={4} sm={6} item>
                  <div className={styles.field_wrapper}>
                    <Field
                      name={EDIT_BANK_ACCOUNT_TYPE.ACCOUNT_NUMBER}
                      control="input"
                      type="text"
                      label={t('payments.editModal.accountNumber')}
                      component={FormikInput}
                      icon={
                        <InfoIcon
                          className={styles.icon}
                          onMouseEnter={() => setImageType(INFO_IMAGES_TYPE.ACCOUNT_NUMBER_IMAGE)}
                          onMouseLeave={() => setImageType(null)}
                        />
                      }
                      readOnly
                      id={EDIT_BANK_ACCOUNT_TYPE.ACCOUNT_NUMBER}
                    />
                    {isAccountInfoIconShown && (
                      <div className={styles.image_wrapper}>
                        <Image
                          width={250}
                          height={100}
                          src={accountNumberImage.src}
                          alt={INFO_IMAGES_TYPE.ACCOUNT_NUMBER_IMAGE}
                        />
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid xs={4} sm={6} item>
                  <Field
                    name={EDIT_BANK_ACCOUNT_TYPE.VERIFY_ACCOUNT_NUMBER}
                    control="input"
                    type="text"
                    label={t('payments.editModal.verifyAccountNumber')}
                    component={FormikInput}
                    readOnly
                    id={EDIT_BANK_ACCOUNT_TYPE.VERIFY_ACCOUNT_NUMBER}
                  />
                </Grid>
                <Grid xs={4} sm={6} item>
                  <div className={styles.field_wrapper}>
                    <Field
                      name={EDIT_BANK_ACCOUNT_TYPE.ROUTING_NUMBER}
                      readOnly
                      control="input"
                      type="text"
                      label={t('payments.editModal.routingNumber')}
                      component={FormikInput}
                      validate={validateRoutingNumberField}
                      error={!!errors[EDIT_BANK_ACCOUNT_TYPE.ROUTING_NUMBER]}
                      helperText={errors[EDIT_BANK_ACCOUNT_TYPE.ROUTING_NUMBER]}
                      onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.ROUTING_NUMBER)}
                      onChange={handleRoutingNumChange}
                      onKeyPress={handlePreventNotNumbers}
                      onWheel={(e: WheelEvent) => (e.target as HTMLInputElement).blur()}
                      icon={
                        <InfoIcon
                          className={styles.icon}
                          onMouseEnter={() => setImageType(INFO_IMAGES_TYPE.ROUTING_NUMBER_IMAGE)}
                          onMouseLeave={() => setImageType(null)}
                        />
                      }
                      id={EDIT_BANK_ACCOUNT_TYPE.ROUTING_NUMBER}
                    />
                    {isRoutingInfoIconShown && (
                      <div className={styles.image_wrapper}>
                        <Image
                          width={250}
                          height={100}
                          src={routingNumberImage.src}
                          alt={INFO_IMAGES_TYPE.ROUTING_NUMBER_IMAGE}
                        />
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid xs={4} sm={6} item>
                  <Divider className={styles.divider} />
                </Grid>
                <Grid xs={4} sm={6} item>
                  <Typography variant="h5" className={styles.title}>
                    {t('payments.editModal.billingAddress')}
                  </Typography>
                </Grid>
                <Grid xs={4} sm={6} item>
                  <Field
                    name={EDIT_BANK_ACCOUNT_TYPE.BILLING_ADDRESS}
                    control="input"
                    type="text"
                    maxLength={MEDIUM_FIELD_LENGTH}
                    validate={validateBillingAddressField}
                    label={t('payments.editModal.billingAddress')}
                    component={FormikInput}
                    error={!!errors[EDIT_BANK_ACCOUNT_TYPE.BILLING_ADDRESS]}
                    helperText={errors[EDIT_BANK_ACCOUNT_TYPE.BILLING_ADDRESS]}
                    onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.BILLING_ADDRESS)}
                    id={EDIT_BANK_ACCOUNT_TYPE.BILLING_ADDRESS}
                  />
                </Grid>
                <Grid xs={2} sm={3} item>
                  <Field
                    className={styles.with_margin_right}
                    name={EDIT_BANK_ACCOUNT_TYPE.APARTMENT}
                    control="input"
                    type="text"
                    maxLength={SMALL_FIELD_LENGTH}
                    validate={validateApartmentField}
                    label={t('payments.editModal.apartment')}
                    component={FormikInput}
                    error={!!errors[EDIT_BANK_ACCOUNT_TYPE.APARTMENT]}
                    helperText={errors[EDIT_BANK_ACCOUNT_TYPE.APARTMENT]}
                    onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.APARTMENT)}
                    id={EDIT_BANK_ACCOUNT_TYPE.APARTMENT}
                  />
                </Grid>
                <Grid xs={2} sm={3} item>
                  <DropDown
                    title={t('payments.editModal.country')}
                    value={values.country}
                    options={countryOptions}
                    onChange={(newValue) => setFieldValue(EDIT_BANK_ACCOUNT_TYPE.COUNTRY, newValue)}
                    id="edit-payment-bank-country"
                  />
                </Grid>
                <Grid xs={2} sm={3} item>
                  <Field
                    className={styles.with_margin_right}
                    name={EDIT_BANK_ACCOUNT_TYPE.CITY}
                    control="input"
                    type="text"
                    maxLength={MEDIUM_FIELD_LENGTH}
                    validate={validateBillingAddressField}
                    label={t('payments.editModal.city')}
                    component={FormikInput}
                    error={!!errors[EDIT_BANK_ACCOUNT_TYPE.CITY]}
                    helperText={errors[EDIT_BANK_ACCOUNT_TYPE.CITY]}
                    onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.CITY)}
                    id={EDIT_BANK_ACCOUNT_TYPE.CITY}
                  />
                </Grid>
                <Grid xs={2} sm={3} item>
                  <Field
                    name={EDIT_BANK_ACCOUNT_TYPE.ZIP_CODE}
                    control="input"
                    type="text"
                    label={t('payments.editModal.zipCode')}
                    component={FormikInput}
                    validate={validateZipCodeField}
                    error={!!errors[EDIT_BANK_ACCOUNT_TYPE.ZIP_CODE]}
                    helperText={errors[EDIT_BANK_ACCOUNT_TYPE.ZIP_CODE]}
                    onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.ZIP_CODE)}
                    onChange={handleZipCodeChange}
                    onKeyPress={handlePreventNotNumbers}
                    onWheel={(e: WheelEvent) => (e.target as HTMLInputElement).blur()}
                    id={EDIT_BANK_ACCOUNT_TYPE.ZIP_CODE}
                  />
                </Grid>
                <Grid xs={4} sm={6} item>
                  <Field
                    name={EDIT_BANK_ACCOUNT_TYPE.PHONE}
                    label={t(`payments.editModal.phone`)}
                    component={PhoneField}
                    value={values.phone}
                    validate={validatePhoneNumberField}
                    error={!!errors[EDIT_BANK_ACCOUNT_TYPE.PHONE]}
                    helperText={errors[EDIT_BANK_ACCOUNT_TYPE.PHONE]}
                    onBlur={() => validateField(EDIT_BANK_ACCOUNT_TYPE.PHONE)}
                    id={EDIT_BANK_ACCOUNT_TYPE.PHONE}
                  />
                </Grid>
                <Grid xs={4} sm={6} item>
                  <Button
                    className={styles.button}
                    type="submit"
                    size="large"
                    mode={uiTheme}
                    fullWidth
                  >
                    {t('payments.editModal.saveCreditCardButton')}
                  </Button>
                  <RecaptchaDisclaimer />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </ModalWindow>
  );
};
