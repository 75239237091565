export const DEFAULT_IMAGE_WIDTHS = [300, 800, 1200, 1500];
export const PRODUCT_TILE_WIDTH = 202;

export const getResponsiveImageUrl = (imagePath: string, width: number): string => {
  if (!imagePath.startsWith('http')) {
    imagePath = `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL ?? ''}${imagePath}`;
  }

  if (imagePath.includes('?')) {
    imagePath = `${imagePath}&imwidth=${width}`;
  } else {
    imagePath = `${imagePath}?imwidth=${width}`;
  }

  return imagePath;
};

export const getSrcSet = (imagePath: string, widths: number[] = DEFAULT_IMAGE_WIDTHS): string => {
  return widths
    .map((width) => {
      return `${getResponsiveImageUrl(imagePath, width)} ${width}w`;
    })
    .join(', ');
};

export const getProductTileSrcSet = (imagePath: string): string => {
  return `${getResponsiveImageUrl(imagePath, PRODUCT_TILE_WIDTH)}, ${getResponsiveImageUrl(
    imagePath,
    2 * PRODUCT_TILE_WIDTH,
  )} 2x`;
};
