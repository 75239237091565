import { useLocalization } from '@hooks/useLocalization';
import { Order } from '@commons/order';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { useModal } from '@hooks/common';
import { CancelNotificationModal } from '@features/AccountPage/components/AccountPageOrderHistory/components/CancelNotificationModal/CancelNotificationModal';
import { TitleWithCounter } from '../TitleWithCounter';
import { EmptyOrderHistory } from './components/EmptyOrderHistory/EmptyOrderHistory';
import { PastOrders } from './components/PastOrders/PastOrders';
import { ActiveOrders } from './components/ActiveOrders/ActiveOrders';
import styles from './AccountPageOrderHistory.module.scss';

interface OrderHistory {
  pastOrders: Order[];
  activeOrders: Order[];
  modifyingOrderId?: string | null;
}
interface AccountPageOrderHistoryProps {
  orderHistory: OrderHistory;
  prevOrderHistoryData?: OrderHistory;
  loading?: boolean;
}

export const AccountPageOrderHistory = ({
  orderHistory: {
    pastOrders: newPastOrders,
    activeOrders: newActiveOrders,
    modifyingOrderId: newModifyingOrderId,
  },
  loading = true,
  prevOrderHistoryData,
}: AccountPageOrderHistoryProps) => {
  const { t } = useLocalization('account');
  const orderNotificationModal = useModal();

  const activeOrders = newActiveOrders ?? prevOrderHistoryData?.activeOrders;
  const pastOrders = newPastOrders ?? prevOrderHistoryData?.pastOrders;
  const modifyingOrderId = newModifyingOrderId ?? prevOrderHistoryData?.modifyingOrderId;

  const hasActiveOrders = activeOrders?.length > 0;
  const hasPastOrders = pastOrders?.length > 0;
  const hasOrders = hasActiveOrders || hasPastOrders;

  const closeNotificationModal = () => {
    orderNotificationModal.closeModal();
  };

  if (loading && !hasOrders) {
    return <AccountPageLoader />;
  }

  if (!hasOrders) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <EmptyOrderHistory />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {hasActiveOrders && (
          <>
            <TitleWithCounter component="h2" className={styles.title} counter={activeOrders.length}>
              {t('orderHistory.activeOrders')}
            </TitleWithCounter>
            <ActiveOrders
              className={styles.list}
              items={activeOrders}
              modifyingOrderId={modifyingOrderId}
              orderNotificationModalOpen={() => orderNotificationModal.openModal()}
            />
          </>
        )}
        {hasPastOrders && (
          <>
            <TitleWithCounter component="h2" className={styles.title} counter={pastOrders.length}>
              {t('orderHistory.pastOrders')}
            </TitleWithCounter>
            <PastOrders
              className={styles.list}
              items={pastOrders}
              modifyingOrderId={modifyingOrderId}
            />
          </>
        )}
        <CancelNotificationModal
          opened={orderNotificationModal.isOpen}
          onClose={closeNotificationModal}
        />
      </div>
    </div>
  );
};
