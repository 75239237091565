import { ReactNode } from 'react';
import isUndefined from 'lodash/isUndefined';
import { useModifyPageExpressContext } from '@features/ModifyOrderPage/hooks/useModifyPageExpressContext';
import { ModifyOrderContext } from '@context/ModifyOrderContext/ModifyOrderContext';
import { useGetModifyingOrderAlertInfo } from '@hooks/account/useGetModifyingOrderAlertInfo';

export interface ModifyOrderProviderProps {
  children: ReactNode;
}

export const ModifyOrderProvider = ({ children }: ModifyOrderProviderProps) => {
  const { data } = useGetModifyingOrderAlertInfo();
  const isExpressOrder = data?.isExpressOrder;
  const isModifyMode = !isUndefined(isExpressOrder);

  useModifyPageExpressContext(isExpressOrder);

  return (
    <ModifyOrderContext.Provider value={{ isExpressOrder, isModifyMode }}>
      {children}
    </ModifyOrderContext.Provider>
  );
};
