import { useQuery, QueryHookOptions } from '@apollo/client';
import { DELIVERY_PASS_INFO } from '@graphql/deliveryPass/queries/deliveryPassInfo';
import { getDeliveryPassInfo } from '@adapters/deliveryPassAdapter';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useDeliveryPassInfo = (queryOptions?: QueryHookOptions) => {
  const { isKnownUser: isLoggedIn, isRecognizedUser } = useAuthContext();
  const { data, ...queryResult } = useQuery(DELIVERY_PASS_INFO, {
    ...queryOptions,
    skip: !isLoggedIn || isRecognizedUser,
  });

  return {
    ...queryResult,
    data: getDeliveryPassInfo(data?.deliveryPassInfo),
  };
};
