import { Grid } from '@mui/material';
import { Maybe } from '@api';
import { CategoriesSectionDivider } from './CategoriesSectionDivider';
import { SeeAllLink } from './SeeAllLink';
import styles from './CategoriesSectionSeeAll.module.scss';

interface CategoriesSectionSeeAllLinksProps {
  headlines: Array<Maybe<string> | undefined>;
  sectionColumns: number;
  url: string;
  onClick?: () => void;
}

export const CategoriesSectionSeeAllLinks = ({
  headlines,
  sectionColumns,
  url,
  onClick,
}: CategoriesSectionSeeAllLinksProps) => {
  return (
    <>
      {headlines.map((headline, index) => (
        <Grid key={headline} item lg={sectionColumns} pl={5}>
          <CategoriesSectionDivider isShown={Boolean(headlines[index + 1])}>
            {headline && (
              <div className={styles.wrapper}>
                <SeeAllLink
                  headline={headline}
                  href={url}
                  tab-index={-1}
                  onClick={onClick}
                  role="menuitem"
                />
              </div>
            )}
          </CategoriesSectionDivider>
        </Grid>
      ))}
    </>
  );
};
