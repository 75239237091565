import cn from 'classnames';
import { forwardRef } from 'react';
import { IconType } from '@commons/icon';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase/ButtonBase';
import { ButtonBaseIcon } from '../ButtonBase/ButtonBaseIcon';
import { ButtonOutlinedLoader } from './ButtonOutlinedLoader';
import styles from './ButtonOutlined.module.scss';

interface ButtonOutlinedProps extends Omit<ButtonBaseProps, 'className'> {
  type?: 'primary' | 'secondary' | 'tertiary';
  iconComponent?: IconType;
  loading?: boolean;
  customClass?: string;
  role?: string;
}

export const ButtonOutlined = forwardRef(
  (
    {
      type = 'primary',
      loading = false,
      children,
      iconComponent: Icon,
      disabled,
      customClass,
      role,
      ...restProps
    }: ButtonOutlinedProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const className = cn(styles.button, styles[type], customClass, { [styles.loader]: loading });

    return (
      <ButtonBase {...restProps} disabled={loading || disabled} className={className} ref={ref}>
        {loading && <ButtonOutlinedLoader type={type} />}
        {Icon && <ButtonBaseIcon icon={Icon} />}
        {children}
      </ButtonBase>
    );
  },
);

ButtonOutlined.displayName = 'ButtonOutlined';
