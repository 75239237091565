import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import {
  getLoginSuccessEvent,
  GetLoginSuccessEventProps,
} from '@modules/ga/events/custom/login-success/utils/getLoginSuccessEvent';

export const loginSuccessHandler = (data: GetLoginSuccessEventProps) => {
  sendGtmEvent(GtmCustomEvent(getLoginSuccessEvent(data)));
};
