import Typography from '@mui/material/Typography';
import { FC } from 'react';
import ListItem from '@mui/material/ListItem';
import { BaseLink as Link } from '@components/UI';
import styles from '../../Hamburger.module.scss';
import { NavIcon } from './NavIcon';

export interface TopMenuListItemProps {
  icon?: string | null;
  name: string;
  href: string;
  onClick: () => void;
}

export const TopMenuListItem: FC<TopMenuListItemProps> = ({ icon, name, href, onClick }) => {
  return (
    <ListItem className={`${styles.list_button} ${styles.list_link_text}`} onClick={onClick}>
      <NavIcon icon={icon} alt={`${name} icon`} />
      <Link legacyBehavior href={href}>
        <a>
          <Typography noWrap className={styles.list_text}>
            {name}
          </Typography>
        </a>
      </Link>
    </ListItem>
  );
};
