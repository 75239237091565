import { createContext, ReactNode, useContext, useMemo } from 'react';
import { ProductBasicData } from '@api';
import {
  fireRWProductAddToCartGAEvent,
  fireRWProductClickGAEvent,
} from '@modules/ga/events/custom/RWProduct/rwEventCreator';
import { getRWChannel } from '@modules/ga/context/utils';

export const DEFAULT_CHANNEL_NAME = 'unknown';

export const CHANNEL_LIST = {
  recBrSearch: 'rec_br_search',
};

const ChannelEventContext = createContext<{
  channel: string;
}>({ channel: DEFAULT_CHANNEL_NAME });

function ProductListChannelProvider({
  children,
  channel,
}: {
  children: ReactNode;
  channel?: string;
}) {
  const oldContext = useContext(ChannelEventContext);
  const channelMemo = useMemo(
    () => (channel ? channel : oldContext.channel),
    [channel, oldContext.channel],
  );

  return (
    <ChannelEventContext.Provider value={{ channel: channelMemo }}>
      {children}
    </ChannelEventContext.Provider>
  );
}

function useProductListChannel() {
  const context = useContext(ChannelEventContext);

  const getChannel = (product?: { variantId?: string } | ProductBasicData) => {
    if (context.channel === DEFAULT_CHANNEL_NAME && product?.variantId) {
      return product.variantId;
    }
    return context.channel;
  };

  const isProductInRWChannel = (product: { variantId?: string }) =>
    getChannel(product) === getRWChannel(product);

  const fireRWProductClickEvent = (product: { variantId: string; productId: string }) => {
    if (isProductInRWChannel(product)) {
      fireRWProductClickGAEvent(product?.productId, product.variantId);
    }
  };

  const fireRWProductAddToCartEvent = (product: { variantId: string; productId: string }) => {
    if (isProductInRWChannel(product)) {
      fireRWProductAddToCartGAEvent(product?.productId, product.variantId);
    }
  };
  return {
    getChannel,
    fireRWProductAddToCartEvent,
    fireRWProductClickEvent,
  };
}

export { ProductListChannelProvider, useProductListChannel };
