import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  GetMinimalStandingOrdersInfoQuery,
  GetMinimalStandingOrdersInfoQueryVariables,
} from '@api';
import { MINIMAL_STANDING_ORDERS_INFO } from '@graphql/standingOrders/queries/minimalSOInfo';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { AUTH_SIGNUP_NOT_COMPLETE } from '@constants/errorCodes';
import { STORAGE_KEYS, sessionStorageService } from '@utils/storageService';

export const useMinimalStandingOrderInfo = (isCacheOnly?: boolean) => {
  const { isKnownUser } = useAuthContext();
  const { enabled: expressEnabled } = useExpressContext();
  const { getFeature } = useHeaderContext();
  const isStandingOrderFeatureEnabled = !!getFeature?.(FEATURE.STANDING_ORDERS);
  const isCosFeatureEnabled = !!getFeature?.(FEATURE.COS);
  const shouldSkip =
    expressEnabled || !isStandingOrderFeatureEnabled || !isCosFeatureEnabled || !isKnownUser;
  const queryFetchPolicy = isCacheOnly ? 'cache-only' : 'cache-first';
  const { data, error, loading } = useQuery<
    GetMinimalStandingOrdersInfoQuery,
    GetMinimalStandingOrdersInfoQueryVariables
  >(MINIMAL_STANDING_ORDERS_INFO, {
    fetchPolicy: queryFetchPolicy,
    ssr: false,
    skip: shouldSkip,
  });

  const validStandingOrdersLength = data?.standingOrders?.filter((order) => !order.error)?.length;

  useEffect(() => {
    if (error?.message === AUTH_SIGNUP_NOT_COMPLETE) {
      sessionStorageService?.put(STORAGE_KEYS.AUTH_NOT_COMPLETE, true);
    }
  }, [error]);

  return {
    standingOrdersLength: data?.standingOrders?.length,
    validStandingOrdersLength,
    loading,
  };
};
