export enum COOKIES_LIST {
  DELIVERY_ALERT = 'deliveryAlert',
  IS_SAME_USER = 'isSameUser',
  PREV_USER_ID = 'prevUserId',
  IS_EXPRESS = 'isExpress',
  COS_ENABLED = 'cosEnabled',
  STANDING_ORDERS_ENABLED = 'standingOrdersEnabled',
  SERVICE_TYPE = 'serviceType',
  AUTOMATION = 'automation',
  BANNERSQNR = 'bannerSqNr',
  DEVELOPER = 'developer',
}
