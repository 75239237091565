import { Dictionary, State } from '@commons/deliveryAddresses';

export const DEFAULT_COUNTRY_NAME = 'US';
export const DEFAULT_STATE_NAME = 'NY';
export const DELIVERY_CORPORATE_LEGACY_LINK =
  '/your_account/delivery_information.jsp?serviceType=CORPORATE&force_ux=legacy';

export const StatesList: Dictionary<State> = {
  alabama: {
    code: 'AL',
    title: 'Alabama',
  },
  alaska: {
    code: 'AK',
    title: 'Alaska',
  },
  arizona: {
    code: 'AZ',
    title: 'Arizona',
  },
  arkansas: {
    code: 'AR',
    title: 'Arkansas',
  },
  americanSamoa: {
    code: 'AS',
    title: 'American Samoa',
  },
  california: {
    code: 'CA',
    title: 'California',
  },
  colorado: {
    code: 'CO',
    title: 'Colorado',
  },
  connecticut: {
    code: 'CT',
    title: 'Connecticut',
  },
  delaware: {
    code: 'DE',
    title: 'Delaware',
  },
  districtOfColumbia: {
    code: 'DC',
    title: 'District of Columbia',
  },
  florida: {
    code: 'FL',
    title: 'Florida',
  },
  georgia: {
    code: 'GA',
    title: 'Georgia',
  },
  guam: {
    code: 'GU',
    title: 'Guam',
  },
  hawaii: {
    code: 'HI',
    title: 'Hawaii',
  },
  idaho: {
    code: 'ID',
    title: 'Idaho',
  },
  illinois: {
    code: 'IL',
    title: 'Illinois',
  },
  indiana: {
    code: 'IN',
    title: 'Indiana',
  },
  iowa: {
    code: 'IA',
    title: 'Iowa',
  },
  kansas: {
    code: 'KS',
    title: 'Kansas',
  },
  kentucky: {
    code: 'KY',
    title: 'Kentucky',
  },
  louisiana: {
    code: 'LA',
    title: 'Louisiana',
  },
  maine: {
    code: 'ME',
    title: 'Maine',
  },
  maryland: {
    code: 'MD',
    title: 'Maryland',
  },
  massachusetts: {
    code: 'MA',
    title: 'Massachusetts',
  },
  michigan: {
    code: 'MI',
    title: 'Michigan',
  },
  minnesota: {
    code: 'MN',
    title: 'Minnesota',
  },
  mississippi: {
    code: 'MS',
    title: 'Mississippi',
  },
  missouri: {
    code: 'MO',
    title: 'Missouri',
  },
  montana: {
    code: 'MT',
    title: 'Montana',
  },
  nebraska: {
    code: 'NE',
    title: 'Nebraska',
  },
  nevada: {
    code: 'NV',
    title: 'Nevada',
  },
  newHampshire: {
    code: 'NH',
    title: 'New Hampshire',
  },
  newJersey: {
    code: 'NJ',
    title: 'New Jersey',
  },
  newMexico: {
    code: 'NM',
    title: 'New Mexico',
  },
  newYork: {
    code: 'NY',
    title: 'New York',
  },
  northCarolina: {
    code: 'NC',
    title: 'North Carolina',
  },
  northDakota: {
    code: 'ND',
    title: 'North Dakota',
  },
  northernMarianaIslands: {
    code: 'CM',
    title: 'Northern Mariana Islands',
  },
  ohio: {
    code: 'OH',
    title: 'Ohio',
  },
  oklahoma: {
    code: 'OK',
    title: 'Oklahoma',
  },
  oregon: {
    code: 'OR',
    title: 'Oregon',
  },
  pennsylvania: {
    code: 'PA',
    title: 'Pennsylvania',
  },
  puertoRico: {
    code: 'PR',
    title: 'Puerto Rico',
  },
  rhodeIsland: {
    code: 'RI',
    title: 'Rhode Island',
  },
  southCarolina: {
    code: 'SC',
    title: 'South Carolina',
  },
  southDakota: {
    code: 'SD',
    title: 'South Dakota',
  },
  tennessee: {
    code: 'TN',
    title: 'Tennessee',
  },
  texas: {
    code: 'TX',
    title: 'Texas',
  },
  trustTerritories: {
    code: 'TT',
    title: 'Trust Territories',
  },
  utah: {
    code: 'UT',
    title: 'Utah',
  },
  vermont: {
    code: 'VT',
    title: 'Vermont',
  },
  virginia: {
    code: 'VA',
    title: 'Virginia',
  },
  virginIslands: {
    code: 'VI',
    title: 'Virgin Islands',
  },
  washington: {
    code: 'WA',
    title: 'Washington',
  },
  westVirginia: {
    code: 'WV',
    title: 'West Virginia',
  },
  wisconsin: {
    code: 'WI',
    title: 'Wisconsin',
  },
  wyoming: {
    code: 'WY',
    title: 'Wyoming',
  },
};
