import { useQuery } from '@apollo/client';
import { MasqueradeBarDataQuery } from '@api';
import { MASQUERADE_BAR_DATA } from '@graphql/masquerade/queries/masqueradeBarData';
import { MasqueradeBarData } from '@modules/masquerade-bar-data';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { useAuthContext } from '@modules/auth/context/AuthContext';

export const useMasqueradeBarData = () => {
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const {
    minimumAccountPreferences: { isMasqueradeMode },
  } = useMinimumAccountPreferences();
  const skipCondition = !(isLoggedIn && isMasqueradeMode);

  const { data, ...queryParams } = useQuery<MasqueradeBarDataQuery>(MASQUERADE_BAR_DATA, {
    skip: skipCondition,
    ssr: false,
  });

  return {
    ...queryParams,
    data: MasqueradeBarData(data?.masqueradeBarData ?? {}),
  };
};
