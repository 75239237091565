import { useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';

interface UseCartLineControlsStateProps {
  defaultQuantity: number;
}

export const useCartLineControlsState = ({ defaultQuantity }: UseCartLineControlsStateProps) => {
  const [currentQuantity, setCurrentQuantity] = useState<number>(defaultQuantity ?? 0);
  const debouncedDefaultQuantity = useDebounce(defaultQuantity, 500);
  useUpdateEffect(() => {
    setCurrentQuantity(defaultQuantity || currentQuantity);
  }, [debouncedDefaultQuantity]);

  return { currentQuantity, setCurrentQuantity };
};
