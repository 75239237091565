import { gql } from '@apollo/client';
import { PRODUCT_BASIC_FRAGMENT } from '@graphql/fragments/product/productBasicFragment';

export const GET_ALTERNATIVE_PRODUCT_BY_ID = gql`
  query AlternativeProduct($productId: ID!, $skipHealthWarning: Boolean) {
    product(productId: $productId, skipHealthWarning: $skipHealthWarning) {
      productBasicData {
        ...productBasicFragment
      }
    }
  }
  ${PRODUCT_BASIC_FRAGMENT}
`;
