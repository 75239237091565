import { useMutation } from '@apollo/client';
import { CANCEL_MODIFY } from '@graphql/common/mutations/cancelModifyAction';
import { PENDING_ORDERS_ALERTS_INFO } from '@graphql/common/queries/pendingOrdersAlertInfo';
import { USER_DETAILS } from '@graphql/common/queries/userDetails';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { CART_CACHE_FIELD } from '@graphql/cart/queries/getCart';
import { MODIFYING_ORDER_ALERT_INFO } from '@graphql/common/queries/modifyingOrderAlertInfo';

export const useCancelModifyOrder = () => {
  const { clearCacheFields } = useCacheFieldsInvalidate();

  return useMutation(CANCEL_MODIFY, {
    onCompleted: () => {
      clearCacheFields([LIGHT_CART_CACHE_FIELD, CART_CACHE_FIELD]);
    },
    refetchQueries: [MODIFYING_ORDER_ALERT_INFO, PENDING_ORDERS_ALERTS_INFO, USER_DETAILS],
  });
};
