import { gql } from '@apollo/client';

export const GLOBAL_HEADER = gql`
  fragment CategoryFields on CategoryData {
    id
    name
    url
  }
  fragment CategoriesRecursive on CategoryData {
    categories {
      ...CategoryFields
      categories {
        ...CategoryFields
        categories {
          ...CategoryFields
        }
      }
    }
  }
  query GlobalHeader {
    globalHeader {
      departmentNavigation {
        primaryNavigation {
          id
          name
          icon
          link
          linkId
        }
        secondaryNavigation {
          id
          name
          icon
          link
        }
        additionalMenus {
          id
          menuItems {
            name
            link
            linkId
          }
        }
        abstractDepartments {
          ... on DepartmentItem {
            id
            name
            url
            dropDownCategories {
              categoryMap {
                headline
                categories {
                  id
                  name
                  url
                }
              }
            }
          }
          ... on SuperDepartmentItem {
            id
            name
            url
            departments {
              ... on DepartmentItem {
                id
                name
                url
                dropDownCategories {
                  categoryMap {
                    headline
                    categories {
                      ...CategoryFields
                      ...CategoriesRecursive
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
