import { FC } from 'react';

interface CategoriesSectionGridProps {
  rows: number;
  columns: number;
}

export const CategoriesSectionGrid: FC<CategoriesSectionGridProps> = ({
  children,
  rows,
  columns,
}) => (
  <div
    style={{
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateRows: `repeat(${rows}, 1fr)`,
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
    }}
  >
    {children}
  </div>
);
