import { useRouter } from 'next/router';
import { StandingOrderMode } from '@api';
import { routing } from '@constants/routing';
import { getId } from '@helpers/common.helpers';

export const useStandingOrderMode = () => {
  const { pathname, query } = useRouter();
  const standingOrderId = getId(query?.id);

  if (pathname?.includes(routing.modifyOrder)) {
    return StandingOrderMode.NONE;
  }

  if (pathname === routing.createStandingOrder) {
    return StandingOrderMode.NEW;
  }
  if (standingOrderId) {
    return StandingOrderMode.CURRENT;
  }
  return StandingOrderMode.NONE;
};
