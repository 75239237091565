import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { usePendingOrderAlerts } from '@hooks/pendingOrderAlerts/usePendingOrderAlerts';
import { useGetModifyingOrderAlertInfo } from '@hooks/account/useGetModifyingOrderAlertInfo';
import { useOrderModalState } from '@graphql/variables/orderModalStateVar';
import { useMasqueradeBarData } from '@hooks/masquerade/useMasqueradeBarData';
import { ORDER_MODAL_STATE } from '@constants/orderModalState';
import { useSelectedAddress } from '@hooks/deliveryAddress/useSelectedAddress';
import { useMainLayoutContext } from '@context/MainLayoutContext';

const DynamicMasqueradeAlertController = dynamic(
  () =>
    import(
      '@components/GlobalAlertContainer/components/MasqueradeAlertController/MasqueradeAlertConroller'
    ).then((lib) => lib.MasqueradeAlertController),
  { ssr: false },
);
const DynamicModifyingOrderAlertController = dynamic(
  () =>
    import(
      '@components/GlobalAlertContainer/components/ModifyingOrderAlertController/ModifyingOrderAlertController'
    ).then((lib) => lib.ModifyingOrderAlertController),
  { ssr: false },
);
const DynamicPendingOrderAlertController = dynamic(
  () =>
    import('./components/PendingOrderAlertController/PendingOrderAlertController').then(
      (lib) => lib.PendingOrderAlertController,
    ),
  { ssr: false },
);
const DynamicWeeklyReservationsAlertController = dynamic(
  () =>
    import('./components/WeeklyReservationsAlertController/WeeklyReservationsAlertController').then(
      (lib) => lib.WeeklyReservationsAlertController,
    ),
  { ssr: false },
);
const DynamicPendingExpressOrderAlertContainer = dynamic(
  () =>
    import(
      '@components/GlobalAlertContainer/components/PendingExpressOrderAlertContainer/PendingExpressOrderAlertContainer'
    ).then((lib) => lib.PendingExpressOrderAlertContainer),
  { ssr: false },
);

interface GlobalAlertContainerProps {
  showAlerts: boolean;
}

export const GlobalAlertContainer = ({ showAlerts }: GlobalAlertContainerProps) => {
  const [shouldRenderAlerts, setShouldRenderAlerts] = useState(false);
  const { handleIsShowingHeaderAlert, handleIsLoadingHeaderAlert } = useMainLayoutContext();

  const { data: pendingOrdersInfo, loading: isPendingOrderAlertsLoading } = usePendingOrderAlerts({
    skip: !showAlerts,
  });
  const {
    data: { expressEnabled },
    loading: isSelectedAddressLoading,
  } = useSelectedAddress({ forGuestUser: true });

  const { data: modifyingOrderAlertInfo, loading: isModifyingAlertsLoading } =
    useGetModifyingOrderAlertInfo(!showAlerts);
  const { orderModalState } = useOrderModalState();
  const { data: masqueradeBarData, loading: isMasqueradeAlertsLoading } = useMasqueradeBarData();
  const renderModifyAlert =
    modifyingOrderAlertInfo && orderModalState === ORDER_MODAL_STATE.MODIFYING;
  const hasPendingOrdersInfo = pendingOrdersInfo?.pendingOrders?.length;
  const hasPendingExpressOrder = expressEnabled && pendingOrdersInfo?.approachingExpressTimeslot;

  const isAlertsLoading =
    isPendingOrderAlertsLoading ||
    isSelectedAddressLoading ||
    isModifyingAlertsLoading ||
    isMasqueradeAlertsLoading;

  useEffect(() => {
    if (isAlertsLoading) {
      handleIsLoadingHeaderAlert?.(true);
    }

    if (!isAlertsLoading) {
      handleIsLoadingHeaderAlert?.(false);
      setShouldRenderAlerts(showAlerts);
      handleIsShowingHeaderAlert?.(showAlerts);
    }
  }, [isAlertsLoading]);

  return (
    <div>
      {(() => {
        const Masquerade = masqueradeBarData.masqueradeModeActive && (
          <DynamicMasqueradeAlertController masqueradeBarData={masqueradeBarData} />
        );
        if (renderModifyAlert) {
          return (
            <>
              {Masquerade}
              {shouldRenderAlerts && (
                <DynamicModifyingOrderAlertController orderInfo={modifyingOrderAlertInfo} />
              )}
            </>
          );
        }
        if (hasPendingExpressOrder) {
          return (
            <>
              {Masquerade}
              {shouldRenderAlerts && (
                <DynamicPendingExpressOrderAlertContainer
                  pendingTimeslotData={pendingOrdersInfo.approachingExpressTimeslot}
                />
              )}
            </>
          );
        }
        if (!hasPendingOrdersInfo && pendingOrdersInfo?.reservation?.isWeekly) {
          return (
            <>
              {shouldRenderAlerts && (
                <DynamicWeeklyReservationsAlertController
                  reservation={pendingOrdersInfo.reservation}
                />
              )}
              {Masquerade}
            </>
          );
        }
        if (!hasPendingOrdersInfo) {
          return Masquerade;
        }
        return (
          <>
            {shouldRenderAlerts && (
              <DynamicPendingOrderAlertController
                orders={pendingOrdersInfo.pendingOrders}
                reservation={pendingOrdersInfo.reservation}
              />
            )}
            {Masquerade}
          </>
        );
      })()}
    </div>
  );
};
