import { Cart } from '@commons/cart';
import { getCartLinesByProductId } from '@modules/ga/eventBodyGetters/utils/getCartLine';

export const calcQuantityDifference = (newCart: Cart, oldCart: Cart, productId: string) => {
  const newCartLine = getCartLinesByProductId(newCart, productId);
  const updatedQuantity =
    newCartLine.reduce((quantity, cartLine) => quantity + cartLine.quantitySelector.quantity, 0) ||
    1;
  const oldCartLine = getCartLinesByProductId(oldCart, productId);
  const previousQuantity = oldCartLine.reduce(
    (quantity, cartLine) => quantity + cartLine.quantitySelector.quantity,
    0,
  );

  return updatedQuantity - previousQuantity;
};
