import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';

export const DELETE_ALCOHOL = gql`
  mutation DeleteAlcohol {
    deleteAlcohol {
      ...cartFragment
    }
  }
  ${CART_FRAGMENT}
`;
