import { useApolloClient } from '@apollo/client';
import { GET_SHOPPING_LISTS } from '@graphql/shoppingLists/queries/shoppingLists';
import { GetShoppingListsQuery, GetShoppingListsQueryVariables } from '@api';

// Fix of the problem https://github.com/apollographql/apollo-client/issues/10787
// fetch from useLazyQuery don't reject when get error in response
export const useFetchShoppingLists = () => {
  const client = useApolloClient();

  return () =>
    client.query<GetShoppingListsQuery, GetShoppingListsQueryVariables>({
      query: GET_SHOPPING_LISTS,
      fetchPolicy: 'network-only',
    });
};
