import { useLazyQuery } from '@apollo/client';
import { accountAdapter } from '@adapters/accountAdapter';
import { ACCOUNT_PREFERENCES } from '@graphql/account';

export const useLazyAdaptedAccountPreference = () => {
  const { getAccountPreferences } = accountAdapter();
  const [fetchAccountPreferences, { data, ...queryResult }] = useLazyQuery(ACCOUNT_PREFERENCES, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    ssr: false,
  });

  return {
    ...queryResult,
    data: getAccountPreferences(data?.accountPreferences),
    fetchAccountPreferences,
  };
};
