import cx from 'classnames';
import { Typography } from '@mui/material';
import { Checkbox } from '@components/UI';
import { ProductTile as IProductTile } from '@commons/product';
import { getProductKey } from '@components/ProductsGrid/getProductKey';
import { useSelectProductContext } from '@context/SelectProductContext/SelectProductContext';
import styles from './ProductTileSelectWrapper.module.scss';

interface ProductTileSelectWrapperProps {
  product: IProductTile;
}

export const ProductTileSelectWrapper: React.FC<ProductTileSelectWrapperProps> = ({
  product,
  children,
}) => {
  const { checkIsSelectedProduct, isSelectMode, selectProduct } = useSelectProductContext();
  const id = getProductKey(product);
  const selected = checkIsSelectedProduct(product);
  const productName = product?.brandName
    ? `${product.brandName} ${product.productName}`
    : product.productName;
  return (
    <div className={cx(styles.selectWrapper)}>
      {isSelectMode && (
        <div className={styles.checkboxWrapper}>
          <Checkbox
            id={id}
            isChecked={selected}
            onChange={(_, event) => {
              event?.stopPropagation();
              selectProduct(product, !selected);
            }}
          >
            <Typography component="span" className="sr_only">
              {productName}
            </Typography>
          </Checkbox>
        </div>
      )}
      <div
        className={cx(styles.selectProductWrapper, {
          [styles.productWrapper]: isSelectMode,
          [styles.selected]: selected,
        })}
      >
        {children}
      </div>
    </div>
  );
};
