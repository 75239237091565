import { useQuery } from '@apollo/client';
import { GetPendingOrdersQuery, GetPendingOrdersQueryVariables } from '@api';
import { GET_PENDING_ORDERS } from '@graphql/cart/queries/getPendingOrders';

export const useGetPendingOrders = () => {
  return useQuery<GetPendingOrdersQuery, GetPendingOrdersQueryVariables>(GET_PENDING_ORDERS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
};

export const getModifiableOrders = (data?: GetPendingOrdersQuery) => {
  if (!data) return [];

  return data.pendingOrdersInfo.pendingOrders
    .filter(({ orderState }) => orderState?.isModifiable)
    .map((order) => ({
      orderId: order.orderId ?? '',
      requestedDate: order.requestedDate ?? '',
      orderTotal: order.orderTotal ?? '',
      orderStatus: order.orderStatus ?? undefined,
      orderItemsCounter: order.orderItemsCounter ?? 0,
      reorder: !!order.reorder,
      deliveryStart: order.deliveryStart ?? '',
      deliveryEnd: order.deliveryEnd ?? '',
      isActive: !!order.isActive,
    }));
};
