import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const UPDATE_ACCOUNT_WORK_PHONE = gql`
  mutation UpdateAccountWorkPhone($input: AccountWorkPhoneInput!) {
    updateAccountWorkPhone(input: $input) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
