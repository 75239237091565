import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { GiftCardsCart } from '@adapters/giftCardAdatper';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import styles from './YourBag.module.scss';

interface YourBagProps {
  withNavigation: boolean;
  cartLinesCount: number;
  giftCards: GiftCardsCart;
  isLoading: boolean;
  isSideBag?: boolean;
}

export const YourBag = ({
  withNavigation,
  giftCards,
  cartLinesCount,
  isLoading,
  isSideBag,
}: YourBagProps) => {
  const { t } = useLocalization('cart');

  const getHeadlineWithCount = (count: number) => {
    if (isSideBag) {
      return (
        <div className={styles.header_block}>
          <Typography
            component="h2"
            variant="h3"
            className={styles.headline}
            id={MODAL_TITLE_ID.SIDE_BAG_MODAL_TITLE}
          >
            <span className={styles.title}>{t('title')}</span>{' '}
          </Typography>
          {!isLoading && <span className={styles.title_counter}>{t('titleCounter', [count])}</span>}
        </div>
      );
    }
    return (
      <Typography component="h1" variant="h3" className={styles.headline}>
        <span className={styles.title}>{t('title')}</span>{' '}
        {!isLoading && <span className={styles.title_counter}>{t('titleCounter', [count])}</span>}
      </Typography>
    );
  };

  if (!withNavigation) {
    return getHeadlineWithCount(cartLinesCount === 0 ? giftCards.cardsCount : cartLinesCount);
  }

  if (withNavigation) {
    return (
      <>
        <Typography
          component={isSideBag ? 'h2' : 'h3'}
          variant="h3"
          className={styles.headline_with_tabs}
          {...(isSideBag && { id: MODAL_TITLE_ID.SIDE_BAG_MODAL_TITLE })}
        >
          <span className={styles.title}>{t('title')}</span>
        </Typography>
      </>
    );
  }

  return null;
};
