export enum DATA_QA {
  USER_AUTH_ERROR = 'user_auth_error',
  USER_SUBMIT_AUTH = 'user_submit_auth',
  USER_AUTH_CONTINUE = 'user_auth_continue',
  USER_SOCIAL_AUTH = 'user_social_auth',
  ADD_MORE_ITEMS_BUTTON = 'add_more_items_button',
  REVIEW_AND_ACTIVATE_ORDER_BUTTON = 'review_and_activate_order_button',
  ADD_TO_STANDING_ORDER_CONFIRMATION_MODAL = 'add_to_standing_order_confirmation_modal',
  REQUEST_CREDIT_MODAL = 'request_credit_modal',
  DELIVERY_AREA_CHANGE_MODAL = 'delivery_area_change_modal',
  PRODUCT_CONFIGURATION_MODAL = 'product_configuration_modal',
  CONTACT_US_MODAL = 'contact_us_modal',
  DELETE_STANDING_ORDER_MODAL = 'delete_standing_order_modal',
  AGE_VERIFICATION = 'age_verification_modal',
  MODAL_WINDOW = 'modal_window',
  MODAL = 'modal',
  POP_UP_MODAL = 'pop_up_modal',
  TIPS_DETAILS_MODAL = 'tips_details_modal',
  CUSTOM_TIP_MODAL = 'custom_tip_modal',
  ADD_NEW_CORPORATE_ADDRESS_MODAL = 'add_new_corporate_address_modal',
  DELIVERY_CARD_DELETE_MODAL = 'delivery_card_delete_modal',
  ACCOUNT_MENU_MODAL = 'account_menu_modal',
  AUTH_MODAL = 'auth_modal',
  SIGN_OUT = 'sign_out',
}
