import { Maybe, GaCustomerData as GaCustomerDataApi } from '@api';
import { GACustomerData } from '@commons/analytics';
import { getDeliveryPassInfo } from './deliveryPassAdapter';

export const getGoogleAnalyticsCustomerData = (
  data?: Maybe<GaCustomerDataApi>,
): GACustomerData => ({
  county: data?.county ?? '',
  state: data?.state ?? '',
  city: data?.city ?? '',
  orderCount: data?.orderCount ?? 0,
  orderCountResidential: data?.orderCountResidential ?? 0,
  modifyMode: !!data?.modifyMode,
  timeslotReservationEligible: !!data?.timeslotReservationEligible,
  timeslotReservation: !!data?.timeslotReservation,
  deliveryPassInfo: getDeliveryPassInfo(data?.deliveryPassInfo),
  expressEligible: !!data?.expressEligible,
  orderCountCorporate: data?.orderCountCorporate ?? 0,
});
