import { Action, IAction, PAction } from './action';

export interface POpen extends PAction {
  message: string;
}

export interface IOpen extends IAction {
  message: string;
}

export const TOpen = 'Open';

export function Open(p: POpen): IOpen {
  const type = p.type ?? TOpen;
  const action = Action({
    ...p,
    type,
  });
  const message = p.message;

  return {
    ...action,
    message,
  };
}
