import first from 'lodash/first';
import { DEFAULT_SECTIONS_TO_LOAD } from '@constants/sectionsToLoad';
import { SHOP_BY_PREFIX } from '@helpers/common.constants';
import { QueryParams } from '@helpers/common.types';
import { isServerRendering } from '@utils/isServerRendering';
import { routing } from '@constants/routing';

const PATH_FRAGMENTS = {
  SUPERDEPARTMENT: '/sd/',
  DEPARTMENT: '/d/',
  CATEGORY: '/c/',
  SUBCATEGORY: '/sc/',
  PRODUCT: '/p/',
  SEARCH: '/search',
  QUICKSHOP: '/quickshop/',
};

export const getId = (id: string | string[] | undefined): string =>
  (Array.isArray(id) ? first(id) : id) ?? '';

export const getOptionalQueryParam = (value?: string | string[]): string | undefined =>
  Array.isArray(value) ? first(value) : value;

export const getIsStandingOrderRoute = () => {
  const standingOrderPrefix = 'standing_order';
  return !isServerRendering() && window.location.href.includes(standingOrderPrefix);
};

export const getIsCreateStandingOrderRoute = () => {
  const createStandingOrderPrefix = routing.createStandingOrder;
  return !isServerRendering() && window.location.href.includes(createStandingOrderPrefix);
};

export const getRoutingBasedUITheme = () => {
  return getIsStandingOrderRoute() ? 'orange' : 'light';
};

export const isSuperDepartmentPage = (path: string) => {
  return path.includes(PATH_FRAGMENTS.SUPERDEPARTMENT);
};

export const isDepartmentPage = (path: string) => {
  return path.includes(PATH_FRAGMENTS.DEPARTMENT);
};

export const isCategoryPage = (path: string) => {
  return path.includes(PATH_FRAGMENTS.CATEGORY);
};

export const isSubCategoryPage = (path: string) => {
  return path.includes(PATH_FRAGMENTS.SUBCATEGORY);
};

export const isProductPage = (path: string) => {
  return path.includes(PATH_FRAGMENTS.PRODUCT);
};

export const isSearchPage = (path: string) => {
  return path.startsWith(PATH_FRAGMENTS.SEARCH);
};

export const isDealsLikePage = (path: string) => {
  return (
    path.startsWith(routing.dealsAndSale) ||
    path.startsWith(routing.freshDeals) ||
    path.startsWith(routing.coupons) ||
    path.startsWith(routing.allSales)
  );
};

export const isQuickshopPage = (path: string) => {
  return path.startsWith(PATH_FRAGMENTS.QUICKSHOP);
};

export const isShoppingPage = (path: string) => {
  return (
    isSuperDepartmentPage(path) ||
    isDepartmentPage(path) ||
    isCategoryPage(path) ||
    isSubCategoryPage(path) ||
    isProductPage(path) ||
    isSearchPage(path) ||
    isDealsLikePage(path) ||
    isQuickshopPage(path)
  );
};

export const hasModuleImageHighPriority = (position?: number) =>
  (position || 0) <= DEFAULT_SECTIONS_TO_LOAD;

const getShopByKey = (query: QueryParams) =>
  Object.keys(query).find((key) => key.includes(SHOP_BY_PREFIX));

export const getShopByValue = (query: QueryParams) => {
  const shopByKey = getShopByKey(query);

  return shopByKey && (query?.[shopByKey] as string | undefined);
};

export const getShopByVariables = (query: QueryParams) => {
  const shopByKey = getShopByKey(query);
  const shopByValue = getShopByValue(query);

  if (!shopByValue || !shopByKey) return [];
  return [{ facetId: shopByKey, facetValueIds: [shopByValue] }];
};

export const fetchHTML = async (url: string) => {
  try {
    const res = await fetch(url);

    return await res.text();
  } catch (error) {
    throw error;
  }
};
