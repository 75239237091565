import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { DeleteAccountProps } from '@modules/modals/types/deleteAccountModal/deleteAccountModal';

export const openDeleteAccountModal = (props: DeleteAccountProps = {}) => {
  fireOpenModal<DeleteAccountProps>(MODALS_LIST.DELETE_ACCOUNT_MODAL, {
    ...props,
  });
};

export const closeDeleteAccountModal = () => {
  fireCloseModal(MODALS_LIST.DELETE_ACCOUNT_MODAL);
};
