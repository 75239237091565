import { useRouter } from 'next/router';
import { routing } from '../constants/routing';

export const useRedirect = () => {
  const router = useRouter();

  const isConfirmationPage = () => router.asPath.startsWith(routing.orderConfirmation);
  const isOrderDetailsPage = () => router.asPath.startsWith(routing.orderDetails);
  const gotoHomepage = () => router.push(routing.home);
  const gotoOrdersHistory = () => router.push(routing.orderHistory);

  return {
    home: () => router.push(routing.home),
    cart: () => router.push(routing.cart),
    orderDetails: (id: string) => router.push(`${routing.orderDetails}/${id}`),
    refererOrHome: () => {
      if (isConfirmationPage()) {
        return gotoHomepage();
      }
      if (isOrderDetailsPage()) {
        return gotoOrdersHistory();
      }

      return gotoHomepage();
    },
  };
};
