import { useCallback, useState, useEffect } from 'react';
import { useEventListener } from 'usehooks-ts';
import { KEYBOARD_CODE } from '@constants/keyboard';

interface AccountKeyboardNavigationProps {
  accountRef: React.RefObject<HTMLElement>;
  popperRef: React.RefObject<HTMLElement>;
}

enum FOCUS_DIRECTION {
  FORWARD = 1,
  BACKWARD = -1,
}

export const useAccountKeyboardNavigation = ({
  accountRef,
  popperRef,
}: AccountKeyboardNavigationProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const moveFocusAddressButton = useCallback(() => {
    accountRef?.current?.focus();
  }, [accountRef]);

  const moveFocusStart = useCallback(() => {
    (popperRef?.current?.querySelector('[role="menuitem"]') as HTMLElement)?.focus();
  }, [popperRef]);

  const moveFocusEnd = useCallback(() => {
    Array.from(popperRef?.current?.querySelectorAll('[role="menuitem"]') as NodeListOf<HTMLElement>)
      ?.pop()
      ?.focus();
  }, [popperRef]);

  const moveFocusUD = useCallback(
    (direction: FOCUS_DIRECTION) => {
      const menuItems = popperRef?.current?.querySelectorAll(
        '[role="menuitem"]',
      ) as NodeListOf<HTMLElement>;
      if (!menuItems?.length) return;

      let elToFocus;
      const lastItemIndex = menuItems.length - 1;
      const currentIndex = Array.from(menuItems).findIndex(
        (item: HTMLElement) => item === document.activeElement,
      );
      if (currentIndex === -1) {
        menuItems[0].focus();
        return;
      }

      if (currentIndex === 0 && direction === FOCUS_DIRECTION.BACKWARD) {
        elToFocus = menuItems[lastItemIndex];
      } else if (currentIndex === lastItemIndex && direction === FOCUS_DIRECTION.FORWARD) {
        elToFocus = menuItems[0];
      } else {
        elToFocus =
          menuItems[direction === FOCUS_DIRECTION.BACKWARD ? currentIndex - 1 : currentIndex + 1];
      }

      elToFocus.focus();
    },
    [popperRef],
  );

  const close = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = useCallback(() => {
    setAnchorEl(accountRef.current);
  }, [setAnchorEl]);

  useEffect(() => {
    if (!!anchorEl) {
      moveFocusStart();
    }
  }, [anchorEl, moveFocusStart]);

  const handleKeyDown = useCallback(
    (event) => {
      if (!!anchorEl)
        switch (event.key) {
          case KEYBOARD_CODE.ARROW_LEFT:
            event.preventDefault();
            break;
          case KEYBOARD_CODE.ARROW_RIGHT:
            event.preventDefault();
            break;
          case KEYBOARD_CODE.ARROW_UP:
            event.preventDefault();
            moveFocusUD(FOCUS_DIRECTION.BACKWARD);
            break;
          case KEYBOARD_CODE.ARROW_DOWN:
            event.preventDefault();
            moveFocusUD(FOCUS_DIRECTION.FORWARD);
            break;
          case KEYBOARD_CODE.ESCAPE:
            event.preventDefault();
            moveFocusAddressButton();
            close();
            break;
          case KEYBOARD_CODE.SPACE:
            event.preventDefault();
            (event.target as HTMLElement)?.click();
            break;
          case KEYBOARD_CODE.HOME:
            event.preventDefault();
            moveFocusStart();
            break;
          case KEYBOARD_CODE.END:
            event.preventDefault();
            moveFocusEnd();
            break;
          case KEYBOARD_CODE.TAB:
            if (event.shiftKey) {
              moveFocusAddressButton();
            } else {
              moveFocusEnd();
            }
            close();
            break;
        }
      else
        switch (event.key) {
          case KEYBOARD_CODE.ENTER:
            open();
            break;
          case KEYBOARD_CODE.SPACE:
            event.preventDefault();
            open();
            break;
        }
    },
    [moveFocusUD, moveFocusStart, moveFocusEnd, moveFocusAddressButton, anchorEl, open, close],
  );

  useEventListener('keydown', handleKeyDown, popperRef);

  return {
    anchorEl,
    setAnchorEl,
  };
};
