import React from 'react';
import { Alert as MUIAlert, AlertProps as AlertPropsMui, IconButton } from '@mui/material';
import cx from 'classnames';
import { close as Close, warning as Warning } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import styles from './Alert.module.scss';

export interface AlertProps extends AlertPropsMui {
  type?: 'error' | 'success' | 'warning' | 'errorTimeSlot';
  size?: 'medium' | 'large';
  isHover?: boolean;
  hasShowIcon?: boolean;
  isFullWidth?: boolean;
  className?: string;
  withoutShadow?: boolean;
  withoutBorder?: boolean;
}

const icons = {
  warning: <Warning width={18} height={16} />,
  error: <Warning width={18} height={16} />,
  errorTimeSlot: <Warning width={18} height={16} />,
  success: false,
};

export const Alert = ({
  children,
  onClose,
  isHover,
  type = 'success',
  size = 'large',
  severity,
  isFullWidth,
  hasShowIcon = true,
  className,
  withoutShadow,
  withoutBorder,
  ...rest
}: AlertProps) => {
  const { t } = useLocalization('components');
  const classes = cx(
    styles.alert,
    styles[type],
    styles[size],
    {
      [styles.is_icon_close]: onClose,
      [styles.is_hover]: isHover,
      [styles.full_width]: isFullWidth,
      [styles.without_shadow]: withoutShadow,
      [styles.without_border]: withoutBorder,
    },
    className,
  );

  const icon = hasShowIcon ? icons[type] : false;

  return (
    <MUIAlert
      data-testid="alert"
      icon={icon}
      className={classes}
      severity={severity}
      action={
        onClose && (
          <IconButton aria-label={t('alert.ariaLabel')} onClick={onClose}>
            <Close width={10} height={10} />
          </IconButton>
        )
      }
      {...rest}
    >
      {children}
    </MUIAlert>
  );
};
