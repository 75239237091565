import { useLocalization } from '@hooks/useLocalization';
import { DeliveryPassInfo } from '@commons/deliveryPass';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import { getMembershipFormattedDate } from './getMembershipFormattedDate';
import styles from './GeneralInfoBlock.module.scss';

interface GeneralInfoBlockProps {
  deliveryPassInfo: DeliveryPassInfo;
}

export const GeneralInfoBlock = ({ deliveryPassInfo }: GeneralInfoBlockProps) => {
  const { t } = useLocalization('account');
  const {
    memberSince,
    ordersPlacedQuantity,
    totalAmountSaved: { formattedPrice, value },
  } = deliveryPassInfo;

  return (
    <div className={styles.wrapper}>
      <GeneralInfo
        title={t('deliveryPass.memberSince')}
        value={getMembershipFormattedDate(memberSince)}
      />
      <GeneralInfo title={t('deliveryPass.ordersPlaced')} value={ordersPlacedQuantity} />
      {!!value && <GeneralInfo title={t('deliveryPass.amountSaved')} value={formattedPrice} />}
    </div>
  );
};
