import { check as CheckIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import styles from './HelperText.module.scss';

interface HelperTextProps {
  showCheckIcon: boolean;
}

export const HelperText = ({ showCheckIcon }: HelperTextProps) => {
  const { t } = useLocalization('account');

  return (
    <div className={styles.helper_text}>
      {t('preferences.linkedAccounts.passwordModal.describe')}
      {showCheckIcon && <CheckIcon className={styles.helper_text_icon} />}
    </div>
  );
};
