import { gql } from '@apollo/client';
import { FACET_FRAGMENT, SORT_OPTION_FRAGMENT } from '@graphql/fragments/common/facetFragments';
import { PAGE_FRAGMENT } from '@graphql/fragments/common/pageFragment';
import {
  PRODUCT_BASIC_FRAGMENT,
  SHOPPING_LIST_PRODUCT_BASIC_FRAGMENT,
} from '@graphql/fragments/product/productBasicFragment';
import { SHOPPING_LIST_FRAGMENT } from '@graphql/fragments/reorder/shoppingList';
import { PRICE_FRAGMENT } from '@graphql/fragments/common/priceFragment';

export const SHOPPING_LISTS_PAGE_DATA = gql`
  query ShoppingListsPageData($input: ShoppingListsFilterInput) {
    shoppingLists(input: $input) {
      shoppingLists {
        ...shoppingListFragment
      }
      facets {
        ...facetFragment
      }
      sortOptions {
        ...SortOptionFragment
      }
      products {
        ...productBasicFragment
        ...shoppingListProductBasicFragment
      }
      page {
        ...PageFragment
      }
      expressFilterAvailable
      subTotal {
        ...priceFragment
      }
    }
  }
  ${SORT_OPTION_FRAGMENT}
  ${FACET_FRAGMENT}
  ${SHOPPING_LIST_FRAGMENT}
  ${SHOPPING_LIST_PRODUCT_BASIC_FRAGMENT}
  ${PRODUCT_BASIC_FRAGMENT}
  ${PAGE_FRAGMENT}
  ${PRICE_FRAGMENT}
`;

export const GET_SHOPPING_LISTS = gql`
  query GetShoppingLists($input: ShoppingListsFilterInput) {
    shoppingLists(input: $input) {
      shoppingLists {
        ...shoppingListFragment
      }
      products {
        ...productBasicFragment
        ...shoppingListProductBasicFragment
      }
    }
  }
  ${SHOPPING_LIST_FRAGMENT}
  ${SHOPPING_LIST_PRODUCT_BASIC_FRAGMENT}
  ${PRODUCT_BASIC_FRAGMENT}
`;
