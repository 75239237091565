import { FC } from 'react';
import { useNavigationTracking } from '@hooks/header/useNavigationTracking';
import { useLocalization } from '@hooks/useLocalization';
import { PrimaryLink } from '@components/UI/PrimaryLink/PrimaryLink';
import styles from './CategoriesSection.module.scss';

interface SeeAllLinkProps {
  headline: string;
  href: string;
  focused?: boolean;
  role?: string;
  onClick?: () => void;
}

export const SeeAllLink: FC<SeeAllLinkProps> = ({ headline, focused, ...restProps }) => {
  const { t } = useLocalization('header');
  const seeAllGtmProps = useNavigationTracking(`See all ${headline}`);

  return (
    <PrimaryLink
      {...seeAllGtmProps}
      {...restProps}
      className={styles.link}
      ref={(node: HTMLAnchorElement) => {
        if (node && focused) node.focus();
      }}
    >
      {t('seeAll', { headline })}
    </PrimaryLink>
  );
};
