import cx from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { close as CloseIcon } from '@assets/icons/system';
import { deliveryAddressErrors } from '@graphql/variables/deliveryAddressErrors';
import { useLocalization } from '@hooks/index';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useGiftCardCart } from '@hooks/cart/useGiftCardCart';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { Button } from '@components/UI';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { useAuthModalContext } from '@context/AuthModalContext';
import { routing } from '@constants/routing';
import { isShoppingPage } from '@helpers/common.helpers';
import { getPreviousPage } from '@hooks/common/useRouterHistory';
import { useMakeGoodControls } from '@modules/masquerade/hooks/useMakeGoodControls';
import { usePageValidationError } from '@context/pageValidationErrorContext';
import { useMinimumAccountPreferences } from '@hooks/account/useMinimumAccountPreferences';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { SampleProductAlert } from '@components/SampleProductAlert/SampleProductAlert';
import { ReplaceModalProvider } from '@context/ReplaceContext/ReplaceModalContext';
import { useOrderCheckoutData } from '@features/ModifyOrderPage/hooks/useOrderCheckoutData';
import { RecommendedProductCarousel } from '@components/RecommendedProductCarousel/RecommendedProductCarousel';
import { ProductListChannelProvider } from '@modules/ga/context/channel';
import { getRWChannel } from '@modules/ga/context/utils';
import { useRecommendationCarousel } from '@hooks/cart/useRecommendationCarousel';
import { EmptyBag, FilledBag, GiftCardBag, Navigation, YourBag } from './components';
import { Tab } from './components/Navigation/Navigation';
import { BagSkeleton } from './components/BagSkeleton/BagSkeleton';
import styles from './Bag.module.scss';

interface BagProps {
  loading?: boolean;
  omitTestIds?: boolean;
  bagClassName?: string;
  onOpenCartOptionsModal?: () => void;
  onTabChange: (tab: TAB_TYPE) => void;
  onCloseSideBagDrawer?: () => void;
  handleCartButton?: () => void;
  hasAddress?: boolean;
  isModifyMode?: boolean;
  modifyOrderId?: string | null;
  isSignedIn?: boolean;
  isSideBag?: boolean;
  hasItemsInCart?: boolean;
}

export enum TAB_TYPE {
  GROCERIES = 'GROCERIES',
  GIFT_CARDS = 'GIFT_CARDS',
}

const getCartsLoading = (cartLoading: boolean, giftCardCartLoading: boolean, loading?: boolean) =>
  cartLoading || giftCardCartLoading || loading;

export const Bag = ({
  loading,
  onOpenCartOptionsModal,
  onTabChange,
  bagClassName,
  onCloseSideBagDrawer,
  omitTestIds = false,
  hasAddress,
  hasItemsInCart,
}: BagProps) => {
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const { t } = useLocalization('cart');
  const {
    minimumAccountPreferences: { isMasqueradeMode },
  } = useMinimumAccountPreferences();
  const { toggleAuthModal } = useAuthModalContext();
  const { isMobile } = useHeaderContext();
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const router = useRouter();
  const {
    data: giftCardsCart,
    loading: giftCardCartLoading,
    isEmpty: isGiftCardCartEmpty,
  } = useGiftCardCart();
  const { cartInfo } = useCartForCheckoutContext();
  const { data: recommendationCarousel } = useRecommendationCarousel();
  const { loading: cartLoading, data: cart, isEmpty: isGroceriesCartEmpty, refetch } = cartInfo;
  const {
    totalSection,
    cartLinesCount,
    totalWithoutDiscount,
    hasCouponError,
    alcoholRestricted,
    cartSections,
    minOrder,
    cartLinePromotionApplied,
    cartTotalPriceIndicators,
    nonExpressCartTotalPriceIndicators,
    deliveryPassCartOnly,
    nonExpressSection,
    expressOnlySection,
  } = cart;
  useOrderCheckoutData();
  const { renderAdditionalControlsForLineItem } = useMakeGoodControls(cart);

  const { subTotal: subtotal, deliveryFee } = totalSection;
  const showMobileLogin = !isLoggedIn && isMobile;
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();
  const showContinueShopping = (isLoggedIn || !isMobile) && !isSideBagDrawerOpen;
  const cartsLoading = getCartsLoading(cartLoading, giftCardCartLoading, loading);
  const hideTimeslot = deliveryPassCartOnly;

  const [withNavigation, setWithNavigation] = useState(false);
  const getGiftCardsCartCardsCount = () => {
    return giftCardsCart?.cardsCount ?? 0;
  };
  const hasOnlyOneGiftCard = getGiftCardsCartCardsCount() === 1 && isGroceriesCartEmpty;

  const [tabs, setTabs] = useState<null | Tab[]>([
    {
      id: TAB_TYPE.GROCERIES,
      name: t('tabs.groceries'),
      quantity: cart.cartLinesCount,
      isActive: true,
    },
    {
      id: TAB_TYPE.GIFT_CARDS,
      name: t('tabs.giftCards'),
      quantity: getGiftCardsCartCardsCount(),
    },
  ]);
  const [activeTab, setActiveTab] = useState(TAB_TYPE.GROCERIES);
  const showSampleProductsAlert =
    !!cartInfo.data.sampleProducts.length &&
    activeTab === TAB_TYPE.GROCERIES &&
    isLoggedIn &&
    hasAddress;

  const countTabs = (values: Tab[]) => {
    return values.map((tab) => ({
      ...tab,
      quantity: tab.id === TAB_TYPE.GROCERIES ? cart.cartLinesCount : getGiftCardsCartCardsCount(),
    }));
  };

  useEffect(() => {
    if (getCartsLoading(cartLoading, giftCardCartLoading, loading)) return;

    if (!isGroceriesCartEmpty && !isGiftCardCartEmpty) {
      setActiveTab(TAB_TYPE.GROCERIES);
      setWithNavigation(true);
      setTabs((prevState) => (prevState?.length ? countTabs(prevState) : null));
    } else {
      if (!isGiftCardCartEmpty) {
        setActiveTab(TAB_TYPE.GIFT_CARDS);
      }

      if (!isGroceriesCartEmpty) {
        setActiveTab(TAB_TYPE.GROCERIES);
      }

      setWithNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cart.cartLinesCount,
    giftCardsCart.cardsCount,
    cartLoading,
    giftCardCartLoading,
    loading,
    isGroceriesCartEmpty,
    isGiftCardCartEmpty,
  ]);

  const shouldShowRecommendedCarousel =
    !!recommendationCarousel.products.length && activeTab !== TAB_TYPE.GIFT_CARDS;

  const orderMinimum = isMasqueradeMode ? { ...minOrder, value: 0 } : minOrder;
  const variantProduct = recommendationCarousel?.products?.find(
    (product: { variantId: string }) => product.variantId,
  );
  const RWChannel = getRWChannel(variantProduct);

  const renderFilledBag = () => (
    <ReplaceModalProvider>
      <FilledBag
        filledBagClassName={bagClassName}
        subtotal={subtotal}
        lineItemPromoApplied={cartLinePromotionApplied}
        cartSections={cartSections}
        deliveryFee={deliveryFee}
        totalWithoutDiscount={totalWithoutDiscount}
        onOpenDeliveryOptions={onOpenCartOptionsModal}
        hasCouponError={hasCouponError}
        alcoholRestricted={alcoholRestricted}
        orderMinimum={orderMinimum}
        cartTotalPriceIndicators={cartTotalPriceIndicators}
        hideTimeslot={hideTimeslot}
        nonExpressSection={nonExpressSection}
        nonExpressCartTotalPriceIndicators={nonExpressCartTotalPriceIndicators}
        renderAdditionalControlsForLineItem={renderAdditionalControlsForLineItem}
        onCloseSideBagDrawer={onCloseSideBagDrawer}
        expressOnlySection={expressOnlySection}
        omitTestIds={omitTestIds}
        shouldShowRecommendedCarousel={shouldShowRecommendedCarousel}
      />
    </ReplaceModalProvider>
  );

  const renderGiftCardBag = () => {
    return <GiftCardBag cart={giftCardsCart} onCloseSideBagDrawer={onCloseSideBagDrawer} />;
  };

  const renderTabContent = () => {
    return activeTab === TAB_TYPE.GROCERIES ? renderFilledBag() : renderGiftCardBag();
  };

  const renderCartContent = () => {
    if (hasItemsInCart || !isGiftCardCartEmpty || !isGroceriesCartEmpty) {
      if (!isGiftCardCartEmpty && !isGroceriesCartEmpty) {
        return renderTabContent();
      }

      if (!isGiftCardCartEmpty && isGroceriesCartEmpty) {
        return renderGiftCardBag();
      }

      if ((isGiftCardCartEmpty && !isGroceriesCartEmpty) || nonExpressSection) {
        return renderFilledBag();
      }

      return <BagSkeleton />;
    } else {
      return <EmptyBag />;
    }
  };

  const handleNavigate = (id: TAB_TYPE) => {
    setActiveTab(id);
    onTabChange(id);
    setTabs(
      (previousTabs) =>
        previousTabs &&
        previousTabs.map((tab) => ({
          ...tab,
          isActive: tab.id === id,
        })),
    );
  };

  const shouldShowYourBagTitle =
    (isMasqueradeMode && hasOnlyOneGiftCard) || !hasOnlyOneGiftCard || isSideBagDrawerOpen;
  const { clear } = usePageValidationError();

  const handleContinueShopping = () => {
    clearCacheFields([LIGHT_CART_CACHE_FIELD]);
    deliveryAddressErrors('');
    clear();

    const previousPage = getPreviousPage();

    if (isShoppingPage(previousPage)) {
      router.push(previousPage);
    } else {
      router.push(routing.home);
    }
  };

  const renderCartDrawerHeader = () => {
    if (!isSideBagDrawerOpen) return;
    return (
      <div className={styles.sidecart_header}>
        {shouldShowYourBagTitle && (
          <YourBag
            withNavigation={withNavigation}
            cartLinesCount={cartLinesCount}
            giftCards={giftCardsCart}
            isLoading={!!cartsLoading}
            isSideBag
          />
        )}
        <IconButton
          className={styles.close_button}
          onClick={onCloseSideBagDrawer}
          data-testid="cart-drawer-close-icon"
          aria-label="close"
        >
          <CloseIcon width={16} height={16} />
        </IconButton>
      </div>
    );
  };

  const renderCartInner = () => (
    <div
      className={cx(styles.inner, {
        [styles.inner_empty]: isGroceriesCartEmpty && isGiftCardCartEmpty,
        [styles.drawer_opened]: isSideBagDrawerOpen,
        [styles.with_tabs]: withNavigation,
      })}
    >
      <div
        className={cx({
          [styles.continue_shopping_section]: !isSideBagDrawerOpen && shouldShowRecommendedCarousel,
        })}
      >
        {showContinueShopping && (
          <div
            className={cx(styles.linkline, {
              [styles.with_tabs]: withNavigation,
              [styles.with_recommended]: shouldShowRecommendedCarousel,
            })}
          >
            <Button onClick={handleContinueShopping} variant="underline">
              <Typography variant="body">{t('continueShopping')}</Typography>
            </Button>
          </div>
        )}
      </div>

      {!isSideBagDrawerOpen && !isGroceriesCartEmpty && shouldShowRecommendedCarousel && (
        <div className={styles.recommend_carousel_wrapper}>
          <ProductListChannelProvider channel={RWChannel}>
            <RecommendedProductCarousel
              products={recommendationCarousel.products}
              onChange={() => {
                refetch();
                return;
              }}
              loading={cartLoading}
              isCartRecommender
            />
          </ProductListChannelProvider>
        </div>
      )}

      <div className={cx(styles.bag_wrapper, { [styles.drawer_opened]: isSideBagDrawerOpen })}>
        {!isSideBagDrawerOpen && showSampleProductsAlert && (
          <SampleProductAlert sampleProducts={cartInfo.data.sampleProducts} />
        )}

        {showMobileLogin && (
          <div className={styles.linkline}>
            <Button
              onClick={handleContinueShopping}
              className={cx(styles.continue_button, styles.linkline_item)}
              variant="underline"
            >
              {t('continueShopping')}
            </Button>
            <Typography className={styles.linkline_item}>
              <Button
                variant="underline"
                className={cx(styles.login_button)}
                onClick={() => toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN)}
              >
                {t('signIn')}
              </Button>
              {` / `}
              <Button
                variant="underline"
                className={styles.login_button}
                onClick={() => toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT)}
              >
                {t('signUp')}
              </Button>
            </Typography>
          </div>
        )}

        {!isSideBagDrawerOpen && shouldShowYourBagTitle && (
          <YourBag
            withNavigation={withNavigation}
            cartLinesCount={cartLinesCount}
            giftCards={giftCardsCart}
            isLoading={!!cartsLoading}
          />
        )}

        {withNavigation && (
          <div
            className={cx(styles.nav_wrapper, {
              [styles.drawer_opened]: isSideBagDrawerOpen,
            })}
          >
            <Navigation onNavigate={handleNavigate} tabs={tabs} />
          </div>
        )}

        {renderCartContent()}
      </div>
    </div>
  );

  return (
    <>
      {renderCartDrawerHeader()}
      <div
        className={cx(styles.wrapper, {
          [styles.is_gift_cards]: activeTab === TAB_TYPE.GIFT_CARDS,
          [styles.drawer_opened]: isSideBagDrawerOpen,
        })}
      >
        {renderCartInner()}
      </div>
    </>
  );
};
