import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from '../common/priceFragment';

export const CREDIT_FRAGMENT = gql`
  fragment creditFragment on Credit {
    amount {
      ...priceFragment
    }
    creditId
    date
    order
    status
    store
    type
    accountNum
  }
  ${PRICE_FRAGMENT}
`;
