import { useState } from 'react';
import { useDeleteFromCart } from '@hooks/cart/useDeleteFromCart';
import { addTask } from '@modules/queue/queue';
import { useRemoveUnavailableCartLine } from '../useRemoveUnavailableCartLine';

interface UseDeleteCartLine {
  cartLineId: string;
  inModifyMode?: boolean;
}

export const useDeleteCartLine = ({ cartLineId, inModifyMode }: UseDeleteCartLine) => {
  const [deleteItem, { loading: deleteLoading }] = useDeleteFromCart(cartLineId, inModifyMode);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const removeUnavailableItem = useRemoveUnavailableCartLine();

  const deleteItemHandler = async () => {
    setDeleteInProgress(true);
    try {
      await addTask(() => deleteItem());
      removeUnavailableItem(cartLineId);
    } finally {
      setDeleteInProgress(false);
    }
  };

  return {
    deleteItem: deleteItemHandler,
    deleteInProgress: deleteLoading || deleteInProgress,
  };
};
