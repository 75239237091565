import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

interface FireExpressOnEventProps {
  pageType: string;
  eventId: string;
}

export const fireExpressOnEvent = (data: FireExpressOnEventProps) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.EXPRESS_ON,
    data,
  });
};
