import { MouseEventHandler } from 'react';
import Typography from '@mui/material/Typography/Typography';
import { info as InfoIcon } from '@assets/icons/system';
import { PreparedDropdown } from '@components/ProductConfigurationModal/types';
import { DropDown, ButtonText } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import styles from './SingleDropdown.module.scss';

type SingleDropdownProps = {
  variation: PreparedDropdown;
  index: number;
  className: string;
  onShowVariationInfo?: (value: string) => void;
};

export const SingleDropdown = ({
  variation,
  index,
  className,
  onShowVariationInfo,
}: SingleDropdownProps) => {
  const { t } = useLocalization('product');
  const defaultOption = {
    value: variation.values[0].value ?? '',
    label: variation.values[0].label ?? '',
    productId: variation.values[0].variationItemProductData?.productId ?? '',
  };

  const handleIconClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    onShowVariationInfo?.(defaultOption.productId);
  };

  return (
    <div className={className}>
      <DropDown
        className={styles.dropdown_disabled}
        aria-label={t('configurationModal.ariaLabels.select')}
        value={defaultOption.value}
        options={[defaultOption]}
        title={variation.label ?? t('configurationModal.defaultLabel')}
        error={variation.hasError ? t('configurationModal.errors.required') : ''}
        id={`${variation.name}_${index}`}
      />
      {onShowVariationInfo && (
        <div className={styles.info_icon}>
          <ButtonText onClick={handleIconClick}>
            <InfoIcon />
            <Typography variant="smallBody" className={styles.link_text}>
              {t('configurationModal.descAndHeatingLabel')}
            </Typography>
          </ButtonText>
        </div>
      )}
    </div>
  );
};
