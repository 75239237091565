/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { ReactEventHandler, useEffect, useState } from 'react';
import cx from 'classnames';
import { NextRouter, useRouter } from 'next/router';
import { useNavigationData } from '@hooks/header/useNavigationData';
import { LeftNavNode } from '@commons/navigation';
import { arrowDown as ArrowDown } from '@assets/icons/system';
import { LinkV2 } from '@components/UI';
import { DropdownMenu } from '@api';
import { routing } from '@constants/routing';
import { dealsAnonSubmenu, dealsSubmenu } from '@constants/dealsSubmenu';
import { useAuthContext } from '@modules/auth/context';
import { useLocalization } from '@hooks/useLocalization';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { LeftNavSkeleton } from '@components/Skeletons/LeftNavSkeleton/LeftNavSkeleton';
import styles from './LeftNav.module.scss';

type LeftNavigationType =
  | {
      id: string;
      name: string;
      url: string;
      children: LeftNavNode[];
    }[]
  | undefined;

const fullyIncludes = (fullString: string, subString: string) => {
  const regex = new RegExp(`\\b${subString}\\b`);
  return regex.test(fullString);
};

const getAllParentDetails = (detail: HTMLDetailsElement | null) => {
  const parentDetails = [];
  let currentElement = detail?.parentElement;

  while (currentElement) {
    if (currentElement.tagName.toLowerCase() === 'details') {
      parentDetails.push(currentElement);
    }
    currentElement = currentElement.parentElement;
  }
  return parentDetails;
};

const handleSummaryIconClick: ReactEventHandler<HTMLDetailsElement> = (e) => {
  e.stopPropagation();
  const detailsElements = document.querySelectorAll('details');
  const currentDetailElement = (e.target as HTMLElement).parentElement?.parentElement;
  const parentDetails = getAllParentDetails(currentDetailElement as HTMLDetailsElement);

  detailsElements.forEach((detail) => {
    if (detail !== currentDetailElement && !parentDetails.includes(detail)) {
      detail.removeAttribute('open');
    }
  });
};

const getLeftNavData = (leftNavigation: LeftNavigationType, routePath: string) => {
  const leftNavData = leftNavigation?.filter((navItem) => {
    return navItem?.id === routePath.split('/').filter(Boolean)?.[0];
  });

  if (leftNavData?.length !== 0) {
    return leftNavData;
  }

  return leftNavigation?.filter((navItem) => {
    return navItem?.url === routePath || routePath.includes(navItem?.url);
  });
};

const gethasAdditionalLeftNav = (additionalLeftNav: DropdownMenu | null) => {
  return !!additionalLeftNav?.menuItems?.length && additionalLeftNav?.menuItems?.length > 0;
};

const shouldLoadDealsSalesLeftNav = (routePath: string) => {
  const dealsSalesUrls = [
    routing.dealsAndSale,
    routing.freshDeals,
    routing.allSales,
    routing.coupons,
  ];

  return dealsSalesUrls.some((url) => routePath.includes(url));
};

const shouldLoadAdditionalLeftNav = (
  additionalMenus: (DropdownMenu | null)[],
  routerAsPath: string,
) => {
  const additionalMenusLength = additionalMenus?.[0]?.menuItems?.filter(
    (item) => item?.link === routerAsPath,
  ).length;

  return additionalMenusLength && additionalMenusLength > 0;
};

const renderAdditionalLeftNav = (
  additionalLeftNav: DropdownMenu | null,
  title: string,
  routerAsPath?: string,
) => {
  if (!additionalLeftNav?.menuItems) {
    return null;
  }

  return (
    <ul className={styles.additional_left_nav}>
      <li className={styles.list_item}>
        <h2>
          <LinkV2 href={routing.dealsAndSale} className={styles.link}>
            {title}
          </LinkV2>
        </h2>
      </li>
      {additionalLeftNav?.menuItems?.length > 0 &&
        additionalLeftNav?.menuItems.map((item, index) => {
          const isActive = () => {
            if (!!item?.link && !!routerAsPath) {
              return fullyIncludes(routerAsPath, item.link) || routerAsPath == item.link;
            }
            return false;
          };

          return (
            <li
              key={`${item?.name}_${index}`}
              className={cx(styles.list_item, {
                [styles.is_active]: isActive(),
              })}
            >
              <LinkV2 href={item?.link} className={styles.link}>
                {item?.name}
              </LinkV2>
            </li>
          );
        })}
    </ul>
  );
};

const renderLeftNav = (
  children: LeftNavNode[],
  router: NextRouter,
  routerAsPath?: string,
  title?: string,
  titleUrl?: string,
) => {
  if (!children) {
    return null;
  }

  return (
    <>
      {children?.length > 0 && (
        <ul className={styles.left_nav}>
          {!!title && (
            <li>
              <h2>
                <LinkV2 href={titleUrl} className={styles.link}>
                  {title}
                </LinkV2>
              </h2>
            </li>
          )}
          {children.map((child, index) => {
            const isActive =
              (routerAsPath && fullyIncludes(routerAsPath, child.url)) || routerAsPath == child.url;
            const isInPath = !!(routerAsPath && fullyIncludes(routerAsPath, child.id));

            return child.name !== title ? (
              <li key={`${child.id}_${index}`} className={styles.list_item}>
                <details
                  open={isActive || isInPath}
                  className={styles.details}
                  id={child.id}
                  onToggle={(e) => {
                    e.preventDefault();
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <summary
                    className={cx(styles.summary, {
                      [styles.no_marker]: child.children?.length === 0,
                      [styles.is_active]: isActive,
                    })}
                  >
                    <LinkV2
                      href={child.url}
                      className={styles.link}
                      onClick={() => {
                        //TODO: TUE-19781
                        //Investigate why the last layer of hyperlink doesn't work for logged in user, add a click event handler as a temp solution.
                        router.push(child.url);
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: child.name }} />
                    </LinkV2>
                    {child.children?.length !== 0 && (
                      <ArrowDown
                        height={16}
                        width={16}
                        className={styles.arrow_down}
                        onClick={handleSummaryIconClick}
                      />
                    )}
                  </summary>
                  {renderLeftNav(child.children || [], router, routerAsPath)}
                </details>
              </li>
            ) : (
              <li key={`${child.id}_${index}`} className={styles.list_item}>
                {renderLeftNav(child.children || [], router, routerAsPath)}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export const LeftNav = () => {
  const router = useRouter();
  const { t } = useLocalization();
  const { isKnownUser } = useAuthContext();
  const { leftNavigation, additionalMenus } = useNavigationData();
  const { breadcrumbInfo } = useHeaderContext();
  const [additionalLeftNav, setAdditionalLeftNav] = useState<DropdownMenu | null>(null);
  const [highlightPath, sethighlightPath] = useState(router.asPath);
  const [leftNavData, setLeftNavData] = useState(getLeftNavData(leftNavigation, router.asPath));
  const hasAdditionalLeftNav = gethasAdditionalLeftNav(additionalLeftNav);

  useEffect(() => {
    if (shouldLoadAdditionalLeftNav(additionalMenus, router.asPath)) {
      setAdditionalLeftNav(additionalMenus?.[0]);
      setLeftNavData([]);
    } else if (shouldLoadDealsSalesLeftNav(router.pathname)) {
      setAdditionalLeftNav(isKnownUser ? dealsSubmenu : dealsAnonSubmenu);
      setLeftNavData([]);
    } else {
      setAdditionalLeftNav(null);
    }
  }, [isKnownUser, router]);

  useEffect(() => {
    if (!!breadcrumbInfo && breadcrumbInfo.length > 0) {
      const breadCrumbPath = breadcrumbInfo[breadcrumbInfo.length - 1].url;
      sethighlightPath(breadCrumbPath);
      setLeftNavData(getLeftNavData(leftNavigation, breadCrumbPath));
    }
  }, [breadcrumbInfo]);

  useEffect(() => {
    sethighlightPath(router.asPath);
  }, [router.asPath]);

  if (!!leftNavData && leftNavData?.length > 0) {
    return renderLeftNav(
      leftNavData,
      router,
      highlightPath.split('?')[0],
      leftNavData[0]?.name,
      leftNavData[0]?.url,
    );
  }

  if (hasAdditionalLeftNav) {
    return renderAdditionalLeftNav(
      additionalLeftNav,
      !shouldLoadAdditionalLeftNav ? t('pageHeader.dealsAndSales') : '',
      highlightPath,
    );
  }

  return <LeftNavSkeleton />;
};
