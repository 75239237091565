/* eslint-disable @typescript-eslint/naming-convention */
import { Cart as CartApi } from '@api';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { CartLineLightWithGA } from '@commons/cart';
import { CustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import {
  EcommerceEvent,
  getProductMetrics,
  ProductMetrics,
} from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { Product, ProductTile } from '@commons/product';

interface AddToCartOperation extends EcommerceEvent {
  cart_position?: string;
  breadcrumb: string;
  ecommerce: {
    currencyCode: string;
    add: {
      products: ProductMetrics[];
      pixelEventIds: CartApi['atcEventIds'];
    };
  };
}

export interface AddToCartOptions {
  products: (Product | ProductTile | CartLineLightWithGA)[];
  cartPosition: string;
  customer: CustomerData;
  listName: string;
  breadcrumb: string;
  pixelEventIds?: CartApi['atcEventIds'];
  isQuantityDecremented?: boolean;
  itemPosition: number;
  sponsored?: boolean;
}

export const getAddToCartEvent = ({
  products,
  cartPosition,
  customer,
  breadcrumb,
  pixelEventIds,
  listName,
  itemPosition,
  sponsored,
}: AddToCartOptions): AddToCartOperation => {
  return {
    event: 'addToCart',
    event_name: 'add_to_cart',
    ua_action: 'Add To Cart',
    cart_position: cartPosition,
    breadcrumb,
    customer: {
      deliverypass_type: customer.deliveryPassType,
      deliverypass_term: customer.deliveryPassTerm,
      express_eligible: customer.expressEligible,
    },
    ua_category: 'Ecommerce Action',
    ecommerce: {
      currencyCode: getCurrencyCodeFromSymbol(products?.[0]?.price?.currency),
      add: {
        products: products.map((product) =>
          getProductMetrics({ ...product, position: itemPosition, listName, sponsored }),
        ),
        pixelEventIds,
      },
    },
  };
};
