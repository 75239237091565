import { useMutation } from '@apollo/client';
import { SWITCH_DELIVERY_PASS_AUTORENEW } from '@graphql/account/mutations/switchDeliveryPassAutorenew';

export const useSwitchDeliveryPassAutoRenew = () => {
  return useMutation(SWITCH_DELIVERY_PASS_AUTORENEW, {
    update: (cache, { data: { switchDeliveryPassAutorenew } }) => {
      cache.modify({
        fields: {
          deliveryPassInfo(prevDeliveryPassInfo) {
            return {
              ...prevDeliveryPassInfo,
              autoRenewalEnabled: switchDeliveryPassAutorenew.success
                ? !prevDeliveryPassInfo?.autoRenewalEnabled
                : prevDeliveryPassInfo?.autoRenewalEnabled,
            };
          },
        },
      });
    },
  });
};
