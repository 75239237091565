import { useQuery } from '@apollo/client';
import { InitializeHostedPaymentQuery } from '@api';
import { INITIALIZE_HOSTED_PAYMENT } from '@graphql/account/queries/initializeHostedPayment';

export const useInitHostedPayment = (type: string, skip = false) => {
  const { data, ...queryParams } = useQuery<InitializeHostedPaymentQuery>(
    INITIALIZE_HOSTED_PAYMENT,
    {
      variables: {
        type,
      },
      skip,
    },
  );

  return {
    ...queryParams,
    data,
    type: data?.initializeHostedPayment?.type ?? null,
  };
};
