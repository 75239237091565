import { gql } from '@apollo/client';

export const CREATIVE_FRAGMENT = gql`
  fragment creativeFragment on Creative {
    name
    altText
    url
    width
    height
  }
`;
