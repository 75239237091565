import React, { Dispatch, SetStateAction, useState } from 'react';
import { useLocalization } from '@hooks/useLocalization';
import { DropDown } from '@components/UI/DropDown/DropDown';
import { PAYMENT_TABS } from '@commons/payment';
import styles from './PaymentMethodsDropdown.module.scss';

interface PaymentMethodsProps {
  type: PAYMENT_TABS;
  setType: Dispatch<SetStateAction<PAYMENT_TABS>>;
}

interface Payment {
  label: string;
  value: string;
  id: PAYMENT_TABS;
}

export const PaymentMethodsDropdown = ({ type, setType }: PaymentMethodsProps) => {
  const { t } = useLocalization();
  const payments: Payment[] = [
    {
      label: t('account:payments.modalTabs.creditDebitTab'),
      value: t('account:payments.modalTabs.creditDebitTab'),
      id: PAYMENT_TABS.CREDIT_CARD,
    },
    {
      label: t('account:payments.modalTabs.paypalTab'),
      value: t('account:payments.modalTabs.paypalTab'),
      id: PAYMENT_TABS.PAYPAL,
    },
    {
      label: t('account:payments.modalTabs.venmo'),
      value: t('account:payments.modalTabs.venmo'),
      id: PAYMENT_TABS.VENMO,
    },
    {
      label: t('account:payments.modalTabs.ebt'),
      value: t('account:payments.modalTabs.ebt'),
      id: PAYMENT_TABS.EBT,
    },
  ];

  const initialPayment = payments[type];
  const [currentPayment, setCurrentPayment] = useState(initialPayment);

  const handleChange = (newValue: string) => {
    const newCurrentPayment =
      payments.find((payment) => payment.value === newValue) || initialPayment;
    const newType = newCurrentPayment.id;
    setCurrentPayment(newCurrentPayment);
    setType(newType);
  };

  return (
    <DropDown
      className={styles.dropdown}
      value={currentPayment.value}
      options={payments}
      onChange={handleChange}
      id="payment-method"
    />
  );
};
