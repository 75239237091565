import { useMutation } from '@apollo/client';
import { DELETE_CART_LINES } from '@graphql/cart/mutations/deleteCartLines';
import { useRemoveFromCartEvent } from '@modules/ga/hooks/useRemoveFromCart';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { usePrevious } from '@hooks/usePrevious';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { updateCartCache } from './updateCartCache';

export const useDeleteUnavailable = (cartLineIds?: (string | null)[]) => {
  const { trackRemoveListFromCart } = useRemoveFromCartEvent();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);

  return useMutation(DELETE_CART_LINES, {
    variables: { cartLineIds },
    update(cache, { data: { deleteCartLines: cart } }) {
      cache.evict({ fieldName: LIGHT_CART_CACHE_FIELD });
      cache.gc();
      updateCartCache(cache, cart);
    },
    onCompleted({ deleteCartLines: cart }) {
      if (oldCart) {
        trackRemoveListFromCart(oldCart, cart);
      }
    },
  });
};
