import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

interface FireExpressOffEventProps {
  pageType: string;
}

export const fireExpressOffEvent = (data: FireExpressOffEventProps) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.EXPRESS_OFF,
    data,
  });
};
