import { Divider as MuiDivider, DividerProps as DividerPropsMui } from '@mui/material';
import cx from 'classnames';
import styles from './Divider.module.scss';

interface DividerProps extends DividerPropsMui {
  mode?: 'black';
}

export const Divider = ({ mode, ...props }: DividerProps) => {
  const className = cx(mode && styles[mode]);
  return <MuiDivider className={className} {...props} />;
};
