import { Typography } from '@mui/material';
import cx from 'classnames';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { DATA_QA } from '@constants/dataQA';
import styles from './DeliveryCardDeleteModal.module.scss';

interface AddressDeleteModalProps {
  onClose: () => void;
  onDelete: () => void;
  isOpen: boolean;
  loading?: boolean;
}

export const DeliveryCardDeleteModal = ({
  onClose,
  onDelete,
  isOpen = false,
  loading,
}: AddressDeleteModalProps) => {
  const { t } = useLocalization('account');

  return (
    <ModalWindow
      className={styles.modal}
      onClose={onClose}
      open={isOpen}
      labelId={MODAL_TITLE_ID.DELIVERY_CARD_DELETE}
      dataQA={DATA_QA.DELIVERY_CARD_DELETE_MODAL}
    >
      <div className={styles.modal_wrapper} data-testid="delete-delivery-card-modal">
        <Typography
          variant="h3"
          component="h2"
          className={styles.heading}
          id={MODAL_TITLE_ID.DELIVERY_CARD_DELETE}
        >
          {t('delivery.deleteModal.areYouSure')}
        </Typography>
        <Typography variant="smallBody" className={styles.info_text}>
          {t('delivery.deleteModal.deleteInfo')}
        </Typography>
        <Button className={cx(styles.button)} onClick={onClose} variant="contained" size="large">
          {t('delivery.deleteModal.keepAddress')}
        </Button>
        <Button onClick={onDelete} variant="outlined" size="large" loading={loading}>
          {t('delivery.deleteModal.deleteAddress')}
        </Button>
      </div>
    </ModalWindow>
  );
};
