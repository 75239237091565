import { fireOpenModal } from '@modules/modals/events/fireOpenModal';
import { fireCloseModal } from '@modules/modals/events/fireCloseModal';
import { MODALS_LIST } from '@modules/modals/types/core';
import { ReservationTimeSlotsModalProps } from '@modules/modals/types/reservationTimeSlotsModal/reservationTimeSlotsModal';

export const openReservationTimeSlotsModal = (props: ReservationTimeSlotsModalProps = {}) => {
  fireOpenModal<ReservationTimeSlotsModalProps>(MODALS_LIST.RESERVATION_TIMESLOTS_MODAL, {
    ...props,
  });
};

export const closeReservationTimeSlotsModal = () => {
  fireCloseModal(MODALS_LIST.RESERVATION_TIMESLOTS_MODAL);
};
