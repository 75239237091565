import { useEffect } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '@hooks/usePrevious';
import { ProductTile } from '@commons/product';
import { fireRWExternalWidgetViewGAEvent } from '@modules/ga/events/custom/RWProduct/rwEventCreator';
import { useRecommenderWidgetViewVar } from '@graphql/variables/recommenderWidgetViewVar';

export const useRWExternalWidgetViewGAEvent = (
  recommendations?: ProductTile[],
  isCartRecommender?: boolean,
) => {
  const previousRecommendations = usePrevious(recommendations);
  const { isRecommenderWidgetViewed, setRecommenderWidgetViewState } =
    useRecommenderWidgetViewVar();

  useEffect(() => {
    return () => {
      setRecommenderWidgetViewState(false);
    };
  }, [setRecommenderWidgetViewState]);

  useUpdateEffect(() => {
    const areNewRecommendationsProvided =
      !!recommendations?.length && !isEqual(recommendations, previousRecommendations);

    if (areNewRecommendationsProvided && !isRecommenderWidgetViewed) {
      fireRWExternalWidgetViewGAEvent(recommendations?.length);
      setRecommenderWidgetViewState(true);
    }

    if (isCartRecommender && isRecommenderWidgetViewed) {
      setRecommenderWidgetViewState(true);
    }
  }, [recommendations, previousRecommendations, isRecommenderWidgetViewed, isCartRecommender]);
};
