import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getSelectItemEvent } from '@modules/ga/eventBodyGetters/getSelectItemEvent';
import { getCurrencyCodeFromSymbol } from '@utils/productHelper';
import { SelectItemEventOptions } from '@modules/ga/events/ecommerce/select-item/selectItemCreator';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getCustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';

interface SelectItemHandlerProps extends SelectItemEventOptions {
  commonAnalyticsData: AnalyticsData;
}

export const selectItemHandler = ({
  itemPosition,
  itemListName,
  commonAnalyticsData,
  product,
}: SelectItemHandlerProps) => {
  const { userDetails } = commonAnalyticsData;
  const { expressEligible, deliveryPassInfo } = userDetails;

  sendGtmEvent(
    getSelectItemEvent({
      itemListName,
      itemPosition,
      product,
      currencyCode: getCurrencyCodeFromSymbol(product?.price?.currency),
      customer: getCustomerData({ ...deliveryPassInfo, expressEligible }),
    }),
  );
};
