import { useQuery } from '@apollo/client';
import { cartAdapter } from '@adapters/cartAdapter';
import { GET_CART } from '@graphql/cart';

const { getMinimumCartLinesList } = cartAdapter();

export const useMinimumCartLinesList = () => {
  const { data, ...rest } = useQuery(GET_CART, {
    ssr: false,
    fetchPolicy: 'cache-first',
  });

  const cartLines = getMinimumCartLinesList(data);

  return { cartLines, ...rest };
};
