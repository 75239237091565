import { Typography } from '@mui/material';
import { Alert, ButtonText } from '@components/UI';
import { ServiceTypeErrorAlertProps } from './ServiceTypeErrorAlert.types';
import styles from './ServiceTypeErrorAlert.module.scss';

export const ServiceTypeErrorAlert = ({
  mainText,
  linkText,
  onLinkClick,
}: ServiceTypeErrorAlertProps) => {
  return (
    <Alert
      type="warning"
      hasShowIcon={false}
      className={styles.service_type_error}
      withoutBorder
      isFullWidth
    >
      <Typography variant="body">
        {mainText}
        <ButtonText
          type="secondary"
          className={styles.service_type_error_link}
          onClick={onLinkClick}
        >
          {linkText}
        </ButtonText>
      </Typography>
    </Alert>
  );
};
