import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import cx from 'classnames';
import { RadioButton } from '@components/UI';
import styles from './RadioGroup.module.scss';

interface Option {
  label: string;
  value: string;
}

export interface RadioGroupProps {
  children?: never;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  options: Option[];
  isRow?: boolean;
  className?: string;
}

export const RadioGroup = ({
  label,
  value: selectedValue,
  onChange,
  options,
  isRow = false,
  className,
}: RadioGroupProps) => {
  const handleChange = (value?: string) => {
    if (value) {
      onChange(value);
    }
  };

  return (
    <FormControl className={cx(styles.radio_group, { [styles.rowed]: isRow }, className)}>
      {label && <FormLabel className={styles.label}>{label}</FormLabel>}
      {options.map(({ value, label: optionLabel }, index) => (
        <RadioButton
          className={styles.radio_button}
          id={`${optionLabel}_${index}`}
          key={`${optionLabel}_${index}`}
          value={value}
          isChecked={value === selectedValue}
          onChange={handleChange}
        >
          {optionLabel}
        </RadioButton>
      ))}
    </FormControl>
  );
};
