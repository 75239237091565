import { gql } from '@apollo/client';
import { PAYMENT_FRAGMENT } from '@graphql/fragments/account/payment';

export const PAYMENT_METHOD = gql`
  query PaymentMethod($paymentId: ID!) {
    payment(id: $paymentId) {
      ...paymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;
