import { useMutation } from '@apollo/client';
import { CLIP_COUPON } from '@graphql/product/mutations/clipCoupon';
import { emit } from '@modules/emitter';
import { APPLY_COUPON } from '@constants/actions';

export const useClipCoupon = () => {
  return useMutation(CLIP_COUPON, {
    onCompleted: (res) => {
      if (res?.clipCoupon) {
        emit({ type: APPLY_COUPON });
      }
    },
  });
};
