import { gql } from '@apollo/client';
import { DELIVERY_ADDRESS_FRAGMENT } from '@graphql/fragments/account/deliveryAddress';
import { CART_FRAGMENT } from '@graphql/fragments/cart/common/cartFragment';
import { MASQUERADE_CART_FRAGMENT } from '@graphql/fragments/masquerade/masqueradeCartFragment';

export const MODIFY_CHECKOUT = gql`
  query ModifyCheckout($isMasquerade: Boolean = false) {
    initModifyOrderCheckout {
      isExpressOrder
      deliveryAddress {
        ...deliveryAddressFragment
      }
      cart @include(if: $isMasquerade) {
        ...masqueradeCartFragment
      }
      cart @skip(if: $isMasquerade) {
        ...cartFragment
      }
      timeslot {
        id
        endDate
        soldOut
        startDate
        timePeriod
        unavailable
        selected
      }
      payments {
        coveredByGiftCard
        payments {
          id
          selected
          title
          accountNumber
          type
          expiration
          accountNumber
          bankAccountType
          bankName
          paymentTypeDescription
          eWalletID
          vendorEWalletID
          mpLogoURL
          ppLogoURL
          email
          isDefault
          isDebit
          isExpired
        }
      }
    }
  }
  ${DELIVERY_ADDRESS_FRAGMENT}
  ${CART_FRAGMENT}
  ${MASQUERADE_CART_FRAGMENT}
`;
