import { Typography } from '@mui/material';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { ButtonV2 } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import styles from './DeliveryCardDeleteModal.module.scss';

interface AddressDeleteErrorModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const DeliveryCardDeleteErrorModal = ({
  onClose,
  isOpen = false,
}: AddressDeleteErrorModalProps) => {
  const { t } = useLocalization('account');

  return (
    <ModalWindow className={styles.modal} onClose={onClose} open={isOpen}>
      <div className={styles.err_modal_wrapper}>
        <Typography variant="body" component="p">
          {t('delivery.deleteModal.upcomingOrderError')}
        </Typography>

        <ButtonV2 fullWidth onClick={onClose}>
          {t('delivery.deleteModal.okay')}
        </ButtonV2>
      </div>
    </ModalWindow>
  );
};
