import { useCallback, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { ID } from 'graphql-ws';
import { useRouter } from 'next/router';
import { useLocalization } from '@hooks/useLocalization';
import { useModal } from '@hooks/common';
import {
  useDeletePaymentMethod,
  useSetDefaultPaymentMethod,
  useUnlinkEWallet,
} from '@hooks/payments';
import { getLastFourNumbers } from '@utils/getCardNumber';
import { ALERT_TYPES } from '@commons/account';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { AccountAlert } from '@features/AccountPage/components/AccountAlert/AccountAlert';
import { EWALLET_PAYMENT_TYPE, FullPayments, PaymentsType } from '@commons/payment';
import { usePaymentAvailabilities } from '@hooks/payments/usePaymentAvailabilities';
import { useLazyPastAndActiveOrderHistory } from '@hooks/account/useLazyPastAndActiveOrderHistory';
import { getEWalletType } from '@utils/getEWalletType';
import { responseErrorHandler } from '@utils/responseErrorHandler';
import { useFilterPayments } from '@hooks/payments/useFilterPayments';
import { useAccountPreferences } from '@hooks/useAccountPreferences';
import { useInitHostedPayment } from '@hooks/payments/useInitHostedPayment';
import { PaymentMethodType } from '@api';
import { useApplyPciPalPayment } from '@hooks/masquerade/useApplyPciPalPayment';
import { createAndSubmitBlankForm } from '@utils/createAndSubmitBlankForm';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { isFeatureEnabled, DYNAMIC_FEATURES } from '@utils/dynamicFeatures';
import { Title } from '../Title';
import { PaymentTile } from './components/PaymentTile/PaymentTile';
import styles from './AccountPagePayments.module.scss';
import { AddPaymentsModal } from './components';
import { AddNewPaymentDesktop } from './components/AddNewPaymentDesktop/AddNewPaymentDesktop';
import { AddNewPaymentMobile } from './components/AddNewPaymentMobile/AddNewPaymentMobile';

interface AccountPagePaymentsProps {
  paymentsData?: FullPayments;
  loading?: boolean;
}

const initialPaymentsData = {
  hasPayments: false,
  hasPaypalWallet: false,
  hasVenmoWallet: false,
  payments: [],
  default: null,
  selected: null,
  defaultPayment: null,
  selectedPayment: null,
};

export const AccountPagePayments = ({
  paymentsData = initialPaymentsData,
  loading = true,
}: AccountPagePaymentsProps) => {
  const router = useRouter();
  const { isOpen, closeModal, openModal } = useModal();
  const { dispatchAlert } = useAccountAlertContext();
  const {
    accountPreferencesLoading,
    accountPreferences: { isMasqueradeMode },
  } = useAccountPreferences();
  const { refetch: fetchInitHostedPayment } = useInitHostedPayment(
    PaymentMethodType.CC,
    !isMasqueradeMode || !!router.query.sessionID || accountPreferencesLoading,
  );
  const { hasPayments, payments, hasPaypalWallet, hasVenmoWallet } = paymentsData;
  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethod();
  const [deletePaymentMethod] = useDeletePaymentMethod();
  const [unlinkEWallet] = useUnlinkEWallet();
  const {
    data: { showBankAccount, showEbt, loading: isCheckingAvailablePayment },
  } = usePaymentAvailabilities();
  const filteredPayments = useFilterPayments(payments);
  const { t } = useLocalization('account');
  const isLastNonEPaymentMethod =
    payments?.filter((method) => method.type !== EWALLET_PAYMENT_TYPE.PAYPAL).length === 1;

  const [
    fetchOrderHistory,
    {
      data: { activeOrders, pastOrders },
    },
  ] = useLazyPastAndActiveOrderHistory();

  useEffect(() => {
    fetchOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldShowAddNewPayment =
    !isFeatureEnabled(DYNAMIC_FEATURES.HIDE_ADD_PAYMENT) ||
    isMasqueradeMode ||
    (hasPayments && (activeOrders.length > 0 || pastOrders.length > 0));

  const onCompleted = useCallback(
    (message: string) => {
      dispatchAlert(ALERT_TYPES.SUCCESS, message);
    },
    [dispatchAlert],
  );

  useApplyPciPalPayment(onCompleted);

  const onError = useCallback(
    (error: ApolloError) => {
      responseErrorHandler(error.message, dispatchAlert);
    },
    [dispatchAlert],
  );

  const createPaymentTileClickHandler = useCallback(
    ({
        id,
        cardTitle,
        cardNumber,
        isDefault,
      }: {
        id: ID;
        cardTitle: string | null;
        cardNumber: string | null;
        isDefault: boolean;
      }) =>
      () => {
        if (isDefault) return;

        setDefaultPaymentMethod({
          variables: { paymentMethodId: id },
          onCompleted: (res) => {
            if (res.setDefaultPaymentMethod.validationErrors?.length > 0) {
              const errorMessage = res.setDefaultPaymentMethod.validationErrors[0].error;
              responseErrorHandler(errorMessage, dispatchAlert);
              return;
            }

            onCompleted(t('payments.alerts.default', { cardTitle, cardNumber }));
          },
          onError,
        });
      },
    [setDefaultPaymentMethod, onError, onCompleted, dispatchAlert, t],
  );

  const createOnDeleteHandler = (id: ID) => {
    deletePaymentMethod({
      variables: { paymentMethodId: id },
      onCompleted: () => onCompleted(t('payments.alerts.delete')),
      onError,
    });
  };

  const handleDeletePayment = (id: ID, type: PaymentsType) => {
    const eWalletType = getEWalletType(type);
    if (eWalletType) {
      unlinkEWallet({
        variables: { type: eWalletType },
        onCompleted: () => onCompleted(t('payments.alerts.delete')),
        onError,
      });
    } else {
      createOnDeleteHandler(id);
    }
  };

  const handleAddNewPaymentClick = async () => {
    if (isMasqueradeMode) {
      const { data: hostedPaymentData } = await fetchInitHostedPayment();
      const { url, accessToken, refreshToken } = hostedPaymentData.initializeHostedPayment;

      if (url && accessToken && refreshToken) {
        createAndSubmitBlankForm(url, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-BEARER-TOKEN': accessToken,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-REFRESH-TOKEN': refreshToken,
        });
      }

      return;
    }

    openModal();
  };

  const renderContent = () => {
    if (loading) return <AccountPageLoader />;

    return (
      <div className={styles.payments}>
        <div className={styles.container}>
          <div className={styles.content}>
            <AccountAlert className={styles.alert} />
            <Title component="h2">
              {hasPayments
                ? t('payments.pageTitles.payments')
                : t('payments.pageTitles.noPayments')}
            </Title>
            <div className={styles.payments_list}>
              {hasPayments &&
                !isCheckingAvailablePayment &&
                filteredPayments.map(({ id, title, type, email, accountNumber, isDefault }) => {
                  return (
                    <PaymentTile
                      key={id}
                      id={id}
                      type={type}
                      cardNumber={getLastFourNumbers(accountNumber)}
                      email={email}
                      onClick={createPaymentTileClickHandler({
                        id,
                        cardTitle: title,
                        cardNumber: getLastFourNumbers(accountNumber),
                        isDefault,
                      })}
                      onDelete={() => handleDeletePayment(id, type)}
                      isDefault={isDefault}
                      isLastNonEPaymentMethod={isLastNonEPaymentMethod}
                    />
                  );
                })}
              {shouldShowAddNewPayment && (
                <AddNewPaymentDesktop onClick={handleAddNewPaymentClick} />
              )}
            </div>
            {shouldShowAddNewPayment && (
              <AddNewPaymentMobile onClick={handleAddNewPaymentClick} hasPayments={hasPayments} />
            )}
            <AddPaymentsModal
              closeModal={closeModal}
              showPaypal={!hasPaypalWallet}
              showVenmo={!hasVenmoWallet}
              showBankAccount={showBankAccount}
              showEBT={showEbt}
              isOpen={isOpen}
            />
          </div>
        </div>
      </div>
    );
  };

  return <div className={styles.wrapper}>{renderContent()}</div>;
};
