import { gql } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { MASQUERADE_BAR_DATA_FRAGMENT } from '../../fragments/masquerade/masqueradeBarData';

export const MASQUERADE_BAR_DATA = gql`
  query MasqueradeBarData {
    masqueradeBarData {
      ...masqueradeBarDataFragment
    }
  }
  ${MASQUERADE_BAR_DATA_FRAGMENT}
`;

export const MASQUERADE_BAR_DATA_OPERATION_NAME = getOperationName(MASQUERADE_BAR_DATA);
