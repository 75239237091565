import useTranslation from 'next-translate/useTranslation';
import { LocalizationNamespace } from '@commons/localization';

export const useLocalization = (namespace: LocalizationNamespace = 'common') => {
  const { t, lang } = useTranslation(namespace);
  const FALLBACK_KEY = 'errors.backend.error.unexpected';

  const getErrorCode = (errorKey?: string) => {
    if (!errorKey) {
      return FALLBACK_KEY;
    }
    const errorCode = `common:errors.${errorKey}`;
    if (t(errorCode) !== errorCode) {
      return errorCode;
    }
    if (t(`${errorCode}._default`) !== `${errorCode}._default`) {
      return `${errorCode}._default`;
    }

    return FALLBACK_KEY;
  };

  const hasTranslation = (key = ''): boolean => {
    if (key === '') {
      return false;
    }

    return (
      (t(`${namespace}.${key}`) !== `${namespace}.${key}` ||
        t(`common:errors.${key}`) !== `common:errors.${key}` ||
        t(`common:errors.${key}._default`) !== `common:errors.${key}._default` ||
        t(key) !== key) &&
      !(
        t(`common:errors.${key}`).includes('common:errors.') &&
        t(`common:errors.${key}._default`).includes('common:errors.')
      )
    );
  };

  const getErrorMessage = (errorKey?: string) => {
    const errorCode = getErrorCode(errorKey);
    let errorMessage = t(errorCode, {}, { fallback: FALLBACK_KEY });
    if (errorMessage === errorCode) {
      errorMessage = t(`${errorCode}._default`, {}, { fallback: FALLBACK_KEY });
    }

    return errorMessage;
  };

  return {
    t,
    lang,
    hasTranslation,
    getErrorMessage,
    getErrorCode,
  };
};
