import cx from 'classnames';
import { Field, FormikErrors } from 'formik';
import { Button, IconButton } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { trash as ClosedTrashIcon, openedTrash as OpenedTrashIcon } from '@assets/icons/system';
import { PhoneField } from '@components/FormikFields';
import { NumericField } from '@components/FormikFields/NumericField';
import { FIELDS, PhoneBlockValues } from '../../types';
import { validateWorkPhone } from '../../helpers/validation';
import styles from './WorkPhone.module.scss';

interface WorkPhoneProps {
  values: PhoneBlockValues;
  errors: FormikErrors<PhoneBlockValues>;
  isSubmitting: boolean;
  showWorkPhoneFields: boolean;
  toggleFieldsShowing: (isShown: boolean) => void;
  onDeleteWorkPhone: () => void;
}

export const WorkPhone = ({
  values,
  errors,
  isSubmitting,
  showWorkPhoneFields,
  toggleFieldsShowing,
  onDeleteWorkPhone,
}: WorkPhoneProps) => {
  const { t } = useLocalization('account');

  if (!showWorkPhoneFields) {
    return (
      <div className={styles.container}>
        <Button
          className={styles.add_button}
          disabled={isSubmitting}
          variant="outlined"
          size="large"
          aria-label={t('preferences.forms.phone.workPhoneButton.ariaLabel')}
          onClick={() => toggleFieldsShowing(true)}
        >
          {t('preferences.forms.phone.workPhoneButton.text')}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.fields_container}>
      <Field
        name={FIELDS.WORK_PHONE_NUMBER}
        className={cx(styles.input, styles.number_field)}
        label={t('preferences.forms.phone.fields.workPhoneNumber.label')}
        aria-label={t('preferences.forms.phone.fields.workPhoneNumber.ariaLabel')}
        component={PhoneField}
        validate={validateWorkPhone(t)}
        value={values.workPhoneNumber}
        error={!!errors[FIELDS.WORK_PHONE_NUMBER]}
        helperText={errors[FIELDS.WORK_PHONE_NUMBER]}
        disabled={isSubmitting}
        id={FIELDS.WORK_PHONE_NUMBER}
        autoComplete="tel"
      />
      <Field
        name={FIELDS.WORK_PHONE_EXTENSION}
        className={cx(styles.input, styles.extension_field)}
        label={t('preferences.forms.phone.fields.workPhoneExtension.label')}
        aria-label={t('preferences.forms.phone.fields.workPhoneExtension.ariaLabel')}
        component={NumericField}
        value={values.workPhoneExtension}
        error={!!errors[FIELDS.WORK_PHONE_EXTENSION]}
        helperText={errors[FIELDS.WORK_PHONE_EXTENSION]}
        disabled={isSubmitting}
        format="#####"
        id={FIELDS.WORK_PHONE_EXTENSION}
        autoComplete="tel-extension"
      />
      <IconButton
        data-testid="delete-button"
        size="large"
        className={styles.delete_button}
        onClick={onDeleteWorkPhone}
        aria-label={t('preferences.forms.phone.workPhoneDelete.ariaLabel')}
      >
        <ClosedTrashIcon className={styles.closed_trash} width="16" height="16" />
        <OpenedTrashIcon className={styles.opened_trash} width="16" height="16" />
      </IconButton>
    </div>
  );
};
