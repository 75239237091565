import {
  CreditsResult as StoreCreditsApi,
  Credit as CreditApi,
  CreditsOrderHistory as CreditsOrderHistoryApi,
  CreditOrder as CreditOrderApi,
  CreditOrderLine as CreditOrderLineApi,
  CreditComplaintReason as CreditComplaintReasonApi,
  CreditsOrderDetailsQuery,
} from '@api';
import {
  StoreCredits,
  Credit,
  CreditOrder,
  CreditsOrderHistory,
  CreditsOrderDetails,
  CreditOrderLine,
  CreditIssue,
  CREDIT_STATUSES_RESPONSES,
  CREDIT_STATUSES,
} from '@commons/credits';
import { dateAdapter } from './dateAdapter';
import { priceAdapter } from './priceAdapter';

const { getHandledDate } = dateAdapter();
const { getPrice } = priceAdapter();

interface CreditAdapter {
  getCredits: (creditsResult: StoreCreditsApi) => StoreCredits;
  getCreditsOrderHistory: (creditHistory: CreditsOrderHistoryApi) => CreditsOrderHistory;
  getCreditsOrderDetails: (creditDetails?: CreditsOrderDetailsQuery) => CreditsOrderDetails;
}

const getCreditStatus = (creditResponse: string): string | undefined => {
  switch (creditResponse) {
    case CREDIT_STATUSES_RESPONSES.REDEEMED:
      return CREDIT_STATUSES.REDEEMED;
    case CREDIT_STATUSES_RESPONSES.ISSUED:
    case CREDIT_STATUSES_RESPONSES.REFERAL:
      return CREDIT_STATUSES.ISSUED;
    case CREDIT_STATUSES_RESPONSES.REFUND:
      return CREDIT_STATUSES.REFUND;
    case CREDIT_STATUSES_RESPONSES.REFUND_FAILED:
      return CREDIT_STATUSES.REFUND_FAILED;
    case CREDIT_STATUSES_RESPONSES.EXPIRED:
      return CREDIT_STATUSES.EXPIRED;
    default:
      return undefined;
  }
};

const getCreditData = (credit: CreditApi): Credit => ({
  amount: getPrice(credit.amount),
  order: credit.order,
  store: credit.store,
  status: getCreditStatus(credit.status),
  type: credit.type ?? '',
  date: credit.date,
  creditId: credit.creditId ?? undefined,
  accountNum: credit.accountNum ?? undefined,
});

const getCreditIssue = (creditIssue: CreditComplaintReasonApi): CreditIssue => ({
  id: creditIssue.id,
  issue: creditIssue.reason,
});

const mapCreditsArr = (creditsArr?: CreditApi[] | null): Credit[] | undefined =>
  creditsArr?.map(getCreditData);

const mapCreditIssues = (creditIssues: CreditComplaintReasonApi[]): CreditIssue[] =>
  creditIssues.map(getCreditIssue);

const getCredits = (creditsResult: StoreCreditsApi): StoreCredits => ({
  pendingCredits: mapCreditsArr(creditsResult?.pendingCredits),
  approvedCredits: mapCreditsArr(creditsResult?.approvedCredits),
  totalAmount: getPrice(creditsResult?.totalAmount),
  totalAmountFD: getPrice(creditsResult?.totalAmountFD),
  totalAmountFK: getPrice(creditsResult?.totalAmountFK),
  isCreditRestricted: creditsResult?.creditRestricted ?? false,
});

const getCreditOrder = (creditOrder: CreditOrderApi): CreditOrder => ({
  orderId: creditOrder?.orderId,
  requestedDate: getHandledDate(new Date(Number(creditOrder?.requestedDate))),
  deliveryStart: getHandledDate(new Date(Number(creditOrder?.deliveryStart))),
  deliveryEnd: getHandledDate(new Date(Number(creditOrder?.deliveryEnd))),
  standingOrderName: creditOrder?.standingOrderName,
});

const getCreditOrderLine = (creditOrderLine: CreditOrderLineApi): CreditOrderLine => ({
  id: creditOrderLine.orderLineId,
  brand: creditOrderLine.brand,
  productName: creditOrderLine.productName,
  quantity: creditOrderLine.quantity,
  productImage: creditOrderLine.productImage,
  issues: mapCreditIssues(creditOrderLine.complaintReasons),
  basePrice: creditOrderLine.basePrice,
  basePriceUnit: creditOrderLine.basePriceUnit,
  configurationDescription: creditOrderLine.configurationDescription ?? '',
  isIneligible: creditOrderLine.mealBundle,
  isSubstituted: creditOrderLine.substituted,
  sample: creditOrderLine.sample,
  free: creditOrderLine.free,
  cartonNumbers: creditOrderLine.cartonNumbers,
  finalPrice: creditOrderLine.finalPrice,
  taxDepositSum: creditOrderLine.taxDepositSum,
  savedAmount: creditOrderLine.savedAmount,
  formState: {
    quantity: creditOrderLine.formState.quantity ?? 0,
    complainReasonId: creditOrderLine.formState.complainReasonId ?? '',
  },
});

const getCreditOrderArr = (creditOrderArr?: CreditOrderApi[] | null): CreditOrder[] | undefined =>
  creditOrderArr?.map(getCreditOrder);

const getCreditOrderLineArr = (creditOrderLineArr?: CreditOrderLineApi[]): CreditOrderLine[] =>
  (creditOrderLineArr || []).map(getCreditOrderLine);

const getCreditsOrderHistory = (creditHistory?: CreditsOrderHistoryApi): CreditsOrderHistory => ({
  orders: getCreditOrderArr(creditHistory?.orders),
});

const getCreditsOrderDetails = (creditDetails?: CreditsOrderDetailsQuery): CreditsOrderDetails => ({
  customerServiceContact:
    creditDetails?.creditsOrderDetails?.customerServiceContact?.replace('1-', '') ?? '',
  orderLines: getCreditOrderLineArr(creditDetails?.creditsOrderDetails.orderLines),
  calculationResult: {
    totalPrice: creditDetails?.creditsOrderDetails.calculationResult?.totalPrice || 0,
    totalPromo: creditDetails?.creditsOrderDetails.calculationResult?.totalPromo || 0,
    totalTax: creditDetails?.creditsOrderDetails.calculationResult?.totalTax || 0,
  },
});

export const creditAdapter = (): CreditAdapter => {
  return {
    getCredits,
    getCreditsOrderHistory,
    getCreditsOrderDetails,
  };
};
