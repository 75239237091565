import { sendGtmEvent } from '@modules/ga/gtm-event';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { getShoppingMode } from '@modules/ga/utils/getShoppingMode';
import { getExpressVariant } from '@modules/ga/utils/getExpressVariant';

interface ExpressOffHandlerProps {
  pageType: string;
  eventId: string;
  commonAnalyticsData: AnalyticsData;
}

/* eslint-disable @typescript-eslint/naming-convention */

export const expressOffHandler = async (data: ExpressOffHandlerProps) => {
  sendGtmEvent({
    event: 'express-action',
    event_name: 'express_off',
    ua_category: 'express shop',
    ua_action: 'off',
    customer: getCustomerData({
      userDetails: data.commonAnalyticsData.userDetails,
      minimumAccountPreferences: data.commonAnalyticsData.minimumAccountPreferences,
      userStatus: data.commonAnalyticsData.userStatus,
      address: data.commonAnalyticsData.addressData,
      geoData: data.commonAnalyticsData.geoData,
    }),
    page_type: data.pageType,
    'shopping-mode': getShoppingMode(),
    'express-variant': getExpressVariant(),
  });
};

/* eslint-enable @typescript-eslint/naming-convention */
