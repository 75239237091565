import { useMutation } from '@apollo/client';
import { EDIT_DELIVERY_ADDRESS } from '@graphql/account/mutations/editDeliveryAddress';
import { useDisableExpressContext } from '@hooks/useDisableExpressContext';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { FIELDS_DEPEND_ON_DEFAULT_ADDRESS } from '@hooks/deliveryAddress/constants';
import { updateCheckoutAddressesCache } from './updateCheckoutAddressesCache';
import { updateDeliveryAddressesCache } from './updateDeliveryAddressesCache';

export const useEditDeliveryAddress = () => {
  const disableExpressContext = useDisableExpressContext();
  const { clearCacheFields } = useCacheFieldsInvalidate();

  return useMutation(EDIT_DELIVERY_ADDRESS, {
    update(
      cache,
      {
        data: {
          editDeliveryAddress: { deliveryAddresses: addresses },
        },
      },
    ) {
      updateDeliveryAddressesCache(cache, addresses);
      updateCheckoutAddressesCache(cache, addresses.selectedAddress);
    },
    onCompleted: (response) => {
      clearCacheFields(FIELDS_DEPEND_ON_DEFAULT_ADDRESS);
      disableExpressContext(
        response.editDeliveryAddress.deliveryAddresses.selectedAddress.expressEnabled,
      );
    },
  });
};
