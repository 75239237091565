import { useMutation } from '@apollo/client';
import { UNLINK_E_WALLET } from '@graphql/account/mutations/unlinkEWallet';
import { PAYMENT_METHODS } from '@graphql/account/queries/paymentMethods';
import { UnlinkEWalletMutation, UnlinkEWalletMutationVariables } from '@api';
import { updatePaymentMethodsCache } from './updatePaymentMethodsCache';
import { updateCheckoutPaymentMethodsCache } from './updateCheckoutPaymentMethodsCache';

export const useUnlinkEWallet = () => {
  return useMutation<UnlinkEWalletMutation, UnlinkEWalletMutationVariables>(UNLINK_E_WALLET, {
    refetchQueries: [PAYMENT_METHODS],
    update(cache, { data }) {
      const payments = data?.unlinkEWallet.data;

      updatePaymentMethodsCache(cache, payments);
      updateCheckoutPaymentMethodsCache(cache, payments);
    },
  });
};
