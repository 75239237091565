import { gql } from '@apollo/client';
import { DELIVERY_ADDRESS_RESULT_FRAGMENT } from '../../fragments/account/deliveryAddressResult';

export const DELETE_DELIVERY_ADDRESS_V2 = gql`
  mutation DeleteDeliveryAddressV2($deliveryAddressId: ID!) {
    deleteDeliveryAddressV2(deliveryAddressId: $deliveryAddressId) {
      ...deliveryAddressResultFragment
    }
  }
  ${DELIVERY_ADDRESS_RESULT_FRAGMENT}
`;
