import { useRouter } from 'next/router';
import { routing } from '@constants/routing';
import { JsonLdScript } from '@components/JsonLdScript/JsonLdScript';
import {
  DEFAULT_ORGANIZATION_STRUCTURE,
  PAGES_WEBSITE_STRUCTURE,
} from '@components/CommonSeoMarkup/constants';

const PAGES_TO_INCLUDE_ORGANIZATION_STRUCTURE_FOR = [routing.home, routing.help];

export const CommonSeoMarkup = () => {
  const router = useRouter();
  const path = router.asPath;
  const shouldAddOrganizationStructure = PAGES_TO_INCLUDE_ORGANIZATION_STRUCTURE_FOR.includes(path);
  const websiteStructure = PAGES_WEBSITE_STRUCTURE[path] ?? PAGES_WEBSITE_STRUCTURE.DEFAULT;

  return (
    <>
      {shouldAddOrganizationStructure && <JsonLdScript data={DEFAULT_ORGANIZATION_STRUCTURE} />}
      <JsonLdScript data={websiteStructure} />
    </>
  );
};
