import { EWALLET_PAYMENT_TYPE, PaymentsType } from '@commons/payment';
import { EWalletType } from '@api';

export const getEWalletType = (type: PaymentsType) => {
  switch (type) {
    case EWALLET_PAYMENT_TYPE.PAYPAL:
      return EWalletType.PP;
    case EWALLET_PAYMENT_TYPE.VENMO:
      return EWalletType.VE;
    default:
      return '';
  }
};
