import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const UPDATE_ACCOUNT_EMAIL_PREFERENCES = gql`
  mutation UpdateAccountEmailPreferences($sendNewsLetter: Boolean!) {
    updateAccountEmailPreference(sendNewsLetter: $sendNewsLetter) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
