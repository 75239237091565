import { gql } from '@apollo/client';
import { GA_CUSTOMER_DATA_FRAGMENT } from '@graphql/fragments/common/GACustomerDataFragment';

export const USER_DETAILS = gql`
  query userDetails {
    userDetails {
      cohort
      gaCustomerData {
        ...GACustomerDataFragment
      }
      expressEnabled
      referralSignup
      displayRafBanner
    }
  }
  ${GA_CUSTOMER_DATA_FRAGMENT}
`;

export const USER_DETAILS_CACHE_FIELD = 'userDetails';
