import { Dispatch } from 'react';
import { StatusLevelType } from '@api';
import { AuthUser, USER_AUTH_STATUS } from '../types';
import { AUTH_USER_ACTIONS_TYPES, AuthUserActions } from '../context/AuthUserReducer';

export const handleSuccessfulAuthorization = (
  customer: AuthUser,
  dispatch?: Dispatch<AuthUserActions>,
) => {
  dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_USER_INFO, payload: customer });
  dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_USER_STATUS, payload: StatusLevelType.SIGNED_IN });
  dispatch?.({
    type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
    payload: USER_AUTH_STATUS.AUTH_COMPLETE,
  });
};
