/* eslint-disable @typescript-eslint/naming-convention */
import {
  GA_TOTAL_PAGES,
  UNSETTED_VALUE,
  WHITE_LIST,
} from '@modules/ga/events/ecommerce/view-item-list/utils/viewItemListCreator.constants';
import { GROUPSCALE, shouldPassTitle } from '@utils/getCurrentTitle';

export const getTotalPage = () =>
  document.querySelector(`[${GA_TOTAL_PAGES}]`)?.getAttribute(GA_TOTAL_PAGES) ?? '';
export const needWaitForTotalPage = (totalPage: string) =>
  !totalPage && totalPage === UNSETTED_VALUE;
export const needWaitForBreadcrumbs = (breadcrumbs: string) => {
  const pageHasBreadcrumbs = WHITE_LIST.some((page) => window.location.pathname.includes(page));

  const breadcrumbsNotExists = !breadcrumbs;
  return pageHasBreadcrumbs && breadcrumbsNotExists;
};
export const formatListName = (list_name: string) =>
  shouldPassTitle(window.location.pathname) || list_name.includes(GROUPSCALE)
    ? list_name
    : list_name.replace(/-title.*/g, '');
