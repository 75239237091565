import { NextRouter } from 'next/router';
import { routing } from '@constants/routing';

type RoutingMap = {
  [key: string]: string;
};

const map: RoutingMap = {
  ...Object.entries(routing).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {}),
  [`${routing.product}/[id]`]: 'pdp',
  [routing.home]: 'homepage',
  [routing.modifyOrder]: 'modified order',
};

// @TODO: implement dynamic routing page type resolving
export const getCartPosition = (router: NextRouter): string => {
  return map[router.pathname] ?? '';
};
