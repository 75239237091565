import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { useCartPageView } from '@modules/ga/hooks/useCartPageView';
import styles from './EmptyBag.module.scss';

export const EmptyBag = () => {
  const { t } = useLocalization('cart');

  useCartPageView({
    loading: false,
  });

  return (
    <div className={styles.wrapper}>
      <Typography component="h1" className={styles.title}>
        {t('emptyBag.title')}
      </Typography>
      <Typography component="span" className={styles.subtitle}>
        {t('emptyBag.subTitle')}
      </Typography>
    </div>
  );
};
