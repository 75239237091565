import { gql } from '@apollo/client';

export const GET_COUNTRY_CODE_AND_NAME = gql`
  query Catalogue($state: String) {
    catalogue(state: $state) {
      countryCodes {
        countryCode
        countryName
      }
      countryStates {
        stateCode
        stateName
      }
    }
  }
`;
