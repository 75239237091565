import { useMutation } from '@apollo/client';
import { DELETE_FROM_GIFT_CARDS_CART } from '@graphql/giftCard/mutations/deleteFromGiftCardsCart';
import { GET_GIFT_CART } from '@graphql/cart/queries/getGiftCart';
import { DeleteFromGiftCardsCartMutation, DeleteFromGiftCardsCartMutationVariables } from '@api';

export const useDeleteFromGiftCards = () => {
  return useMutation<DeleteFromGiftCardsCartMutation, DeleteFromGiftCardsCartMutationVariables>(
    DELETE_FROM_GIFT_CARDS_CART,
    {
      update(cache, { data }) {
        if (data) {
          cache.writeQuery({
            query: GET_GIFT_CART,
            data: { giftCardsCart: data.deleteFromGiftCardsCart.cart },
          });
        }
      },
    },
  );
};
