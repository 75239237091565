import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocalization } from '@hooks/useLocalization';
import { Order } from '@commons/order';
import { useOrdersListPagination } from '../../useOrdersListPagination';
import { PastOrderCard } from '../PastOrderCard/PastOrderCard';

interface PastOrdersProps {
  children?: never;
  className?: string;
  items: Order[];
  modifyingOrderId?: string | null;
}

export const PastOrders = ({ items, className, modifyingOrderId, ...props }: PastOrdersProps) => {
  const { t } = useLocalization('account');
  const { orders, hasMore, getMoreOrders } = useOrdersListPagination(items);

  return (
    <div {...props} className={className} aria-label={t('orderHistory.pastOrderList')}>
      <InfiniteScroll
        next={getMoreOrders}
        hasMore={hasMore}
        loader={<></>}
        dataLength={orders.length}
      >
        {orders.map((order) => (
          <PastOrderCard {...props} key={order.orderId} order={order} />
        ))}
      </InfiniteScroll>
    </div>
  );
};
