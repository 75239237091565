import { gql } from '@apollo/client';
import { DELIVERY_ADDRESS_FRAGMENT } from './deliveryAddress';

export const DELIVERY_ADDRESS_RESULT_FRAGMENT = gql`
  fragment deliveryAddressResultFragment on DeliveryAddressesResult {
    homeAddresses {
      ...deliveryAddressFragment
    }
    pickUpDepotsAddresses {
      ...deliveryAddressFragment
    }
    corpAddresses {
      ...deliveryAddressFragment
    }
    selectedAddress {
      ...deliveryAddressFragment
    }
    temporaryAddress {
      ...deliveryAddressFragment
    }
    guestUser
    userExpressEnabled
    futureZoneNotification
    showRecaptcha
  }
  ${DELIVERY_ADDRESS_FRAGMENT}
`;
