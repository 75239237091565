import { CountryCode } from '@api';
import { DropdownOptionsType } from '@commons/account';

export const getCountryOptions = (countries: CountryCode[]): DropdownOptionsType[] => {
  const countryOptions: DropdownOptionsType[] = [];

  countries.forEach((country) => {
    countryOptions.push({
      value: country.countryCode,
      label: country.countryName || '',
    });
  });

  return countryOptions;
};
