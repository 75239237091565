import { Typography } from '@mui/material';
import { Button } from '@components/UI';
import styles from '../LegalOverlay.module.scss';

interface EstimatedPriceProps {
  handleClose: () => void;
}

export const EstimatedPrice = ({ handleClose }: EstimatedPriceProps) => {
  return (
    <>
      <Typography variant="h3" className={styles.title}>
        Estimated Price
      </Typography>
      <div className={styles.content}>
        <Typography className={styles.content_text} component="p">
          While shopping, and prior to day of your delivery, you will see in your cart an estimated
          weight and price for variable weight items which are priced by the pound. On the day of
          your delivery, your actual variable items are prepared by weighing each item to determine
          the final price you see on your receipt. Please note that actual weight will vary from the
          estimated weight due to seasonality, source, and country of origin.
        </Typography>
      </div>
      <Button size="large" className={styles.button} onClick={handleClose}>
        Okay
      </Button>
    </>
  );
};
