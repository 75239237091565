export const NESTED_NUTRITIONS = 'ADDED_SUGARS_QUANTITY';
export const MAIN_NUTRITIONS = {
  totalFatQuantity: 'TOTAL_FAT_QUANTITY',
  totalCarbohydrateQuantity: 'TOTAL_CARBOHYDRATE_QUANTITY',
  totalCholesterolQuantity: 'TOTAL_CHOLESTEROL_QUANTITY',
  totalProteinQuantity: 'TOTAL_PROTEIN_QUANTITY',
  totalSodiumQuantity: 'TOTAL_SODIUM_QUANTITY',
};
export const V2_TYPES = {
  supplement: 'SUPPL',
  pet: 'PET',
  drug: 'DRUG',
  baby: 'BABY',
};
export const SUB_NUTRITIONS = {
  totalSaturated: 'TOTAL_SATURATED_FAT_QUANTITY',
  transFat: 'TRANS_FAT',
  dietaryFiber: 'TOTAL_DIETARY_FIBER_QUANTITY',
  sugar: 'TOTAL_SUGAR_QUANTITY',
};
