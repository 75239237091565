import { Typography } from '@mui/material';
import cx from 'classnames';
import { Subtotal } from '@commons/cart';
import { Price } from '@commons/price';
import { useLocalization } from '@hooks/useLocalization';
import { useMasqueradeBarData } from '@hooks/masquerade/useMasqueradeBarData';
import styles from './OrderMinimum.module.scss';
import { getOrderMinimumDetails } from './OrderMinimum.utils';

interface OrderMinimumProps {
  subtotal: Subtotal;
  orderMinimum: Price;
  className?: string;
}

export const OrderMinimum = ({ subtotal, orderMinimum, className }: OrderMinimumProps) => {
  const { t } = useLocalization('cart');
  const {
    data: { isAddOnOrderMode, isMakeGoodMode },
  } = useMasqueradeBarData();
  const orderMinimumDetails = getOrderMinimumDetails(subtotal.value.value, orderMinimum.value);
  if (!orderMinimumDetails || isAddOnOrderMode || isMakeGoodMode) return null;

  return (
    <Typography component="p" variant="smallBody" className={cx(styles.order_minimum, className)}>
      {t('minOrderAlert', orderMinimumDetails)}
    </Typography>
  );
};
