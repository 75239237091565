import { gql } from '@apollo/client';
import { ACCOUNT_ACTION_RESULT_FRAGMENT } from '@graphql/fragments/account/actionResult';

export const SWITCH_DELIVERY_PASS_AUTORENEW = gql`
  mutation SwitchDeliveryPassAutorenew($enabled: Boolean!) {
    switchDeliveryPassAutorenew(enabled: $enabled) {
      ...actionResultFragment
    }
  }
  ${ACCOUNT_ACTION_RESULT_FRAGMENT}
`;
