import { gql } from '@apollo/client';
import { PAYMENT_FRAGMENT } from './payment';

export const PAYMENT_METHODS_FRAGMENT = gql`
  fragment paymentMethodsFragment on Payments {
    default
    payments {
      ...paymentFragment
    }
    selected
  }
  ${PAYMENT_FRAGMENT}
`;
