import { createContext, ReactNode, useContext, useMemo } from 'react';
import { safeName } from '@utils/getBreadcrumbsTitleText';

export const defaultTitleName = 'unknown';

const TitleEventContext = createContext<{
  title: string;
}>({ title: defaultTitleName });

function ProductListTitleProvider({ children, title }: { children: ReactNode; title?: string }) {
  const titleMemo = useMemo(() => (title ? safeName(title) : defaultTitleName), [title]);
  return (
    <TitleEventContext.Provider value={{ title: titleMemo }}>{children}</TitleEventContext.Provider>
  );
}

function useProductListTitle() {
  const context = useContext(TitleEventContext);

  return {
    getTitle: (product?: { variantId: string | null }) => {
      if (context.title === defaultTitleName && product?.variantId) return product.variantId;
      return context.title;
    },
  };
}

export { ProductListTitleProvider, useProductListTitle };
