import { useState, ReactNode, useRef, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { Emitter, IEmitter } from '@utils/emitter';
import { ContactUsFormModalContext, Action } from './ContactUsFormModalContext';

const DynamicContactUsFormModal = dynamic(
  () => import('@components/Modals/ContactUsFormModal/ContactUsFormModal'),
  {
    ssr: false,
  },
);

interface ContactUsFormModalProviderProps {
  children: ReactNode;
}

export const ContactUsFormModalProvider = ({ children }: ContactUsFormModalProviderProps) => {
  const [opened, setOpened] = useState(false);
  const eventEmitterRef = useRef<IEmitter<Action>>(Emitter<Action>());
  const { on, emit } = eventEmitterRef.current;

  useEffect(
    () =>
      on((event) => {
        if (event === 'open') {
          setOpened(true);
        }

        if (event === 'close' || event === 'back') {
          setOpened(false);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ContactUsFormModalContext.Provider value={{ opened, on, emit }}>
      {opened && (
        <DynamicContactUsFormModal
          handleClose={() => {
            emit('close');
          }}
          opened={opened}
        />
      )}
      {children}
    </ContactUsFormModalContext.Provider>
  );
};
