import { useMutation } from '@apollo/client';
import { CANCEL_ORDER } from '@graphql/account/mutations/cancelOrder';
import { OrderInfo } from '@api';

export const useCancelOrder = (orderId: string) =>
  useMutation(CANCEL_ORDER, {
    update: (
      cache,
      {
        data: {
          cancelOrder: { success },
        },
      },
    ) => {
      cache.modify({
        fields: {
          activeOrdersHistory(prevActiveOrdersHistory) {
            if (success) {
              return {
                ...prevActiveOrdersHistory,
                ordersInfo: prevActiveOrdersHistory.ordersInfo.filter(
                  (order: OrderInfo) => order.orderId !== orderId,
                ),
              };
            }
            return prevActiveOrdersHistory;
          },
        },
      });
    },
  });
