import { useQuery } from '@apollo/client';
import { ProductQuery, ProductQueryVariables } from '@api';
import { GET_PRODUCT_BY_ID } from '@graphql/product/queries/singleProduct';
import { Product, ProductTile } from '@commons/product';
import { MODAL_TYPE } from '../types';

export const useConfigurableProductById = (variant: MODAL_TYPE, product: Product | ProductTile) => {
  const query = useQuery<ProductQuery, ProductQueryVariables>(GET_PRODUCT_BY_ID, {
    skip: variant !== MODAL_TYPE.CART_MINI_PDP,
    notifyOnNetworkStatusChange: true,
    variables: { productId: product.productId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return query;
};
