import { gql } from '@apollo/client';

export const INVITE_FRIEND = gql`
  mutation SendReferralInvites($emails: String!) {
    sendReferralInvites(emails: $emails) {
      success
      message
    }
  }
`;
