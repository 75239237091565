import { useQuery } from '@apollo/client';
import { Query } from '@api';
import { GET_COUNTRY_CODE_AND_NAME } from '@graphql/account/queries/getCountryCodeAndState';

type State = 'US';

interface UseCountryCodeAndStateProps {
  state: State;
}

export const useCountryCodeAndState = ({ state }: UseCountryCodeAndStateProps) => {
  const { data, ...params } = useQuery<Pick<Query, 'catalogue'>>(GET_COUNTRY_CODE_AND_NAME, {
    variables: {
      state,
    },
  });

  const countryCodes =
    data?.catalogue?.countryCodes?.map((country) => ({
      countryCode: country?.countryCode ?? '',
      countryName: country?.countryName ?? '',
    })) ?? [];

  const countryStates =
    data?.catalogue?.countryStates?.map((country) => ({
      stateCode: country?.stateCode ?? '',
      stateName: country?.stateName ?? '',
    })) ?? [];

  return {
    ...params,
    countryCodes,
    countryStates,
  };
};
