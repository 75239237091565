import { useLocalization } from '@hooks/useLocalization';
import { TermsAndConditionsButton } from '@components/TermsAndConditionsButton/TermsAndConditionsButton';
import { TermsAndConditionsModalProvider } from '@context/TermsAndConditionsModalContext/TermsAndConditionsModalProvider';
import styles from './PlanOverview.module.scss';

interface PlanOverviewProps {
  htmlContent: string;
  isOneYearFreeDeliveryPass?: boolean;
}

export const PlanOverview = ({ htmlContent, isOneYearFreeDeliveryPass }: PlanOverviewProps) => {
  const { t } = useLocalization();

  return (
    <TermsAndConditionsModalProvider>
      <div className={styles.wrapper}>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />

        <TermsAndConditionsButton
          wrapperClass={styles.terms}
          buttonClass={styles.terms_button}
          freeOneYearEligible={isOneYearFreeDeliveryPass}
          buttonText={t('deliveryPass.termsAndConditions')}
          isSmallBody
        />
      </div>
    </TermsAndConditionsModalProvider>
  );
};
