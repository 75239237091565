import { useCartLineItemLight } from '@hooks/cart/useCartLineItemLight';

export interface InitialValuesLight {
  cartLineId?: string;
  salesUnit?: string;
  count?: number;
}

export const useProductInfoFromCartLight = (productId: string, soldBySalesUnit = false) => {
  const { data: cartLineItem, ...rest } = useCartLineItemLight(productId, soldBySalesUnit);
  const getProductCartInfo = () => {
    return {
      cartLineId: cartLineItem?.id || '',
      salesUnit: cartLineItem?.selectedSalesUnit || undefined,
      count: rest.count,
    };
  };

  return { ...rest, data: getProductCartInfo() };
};
