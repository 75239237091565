import { useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { REQUEST_DEBOUNCE_TIME } from '@constants/debounceTime';
import { useDeleteFromCart } from '@hooks/cart/useDeleteFromCart';
import { useSetProductQuantityInLineItem } from '@hooks/cart/useSetProductQuantityInLineItem';
import { cartAdapter } from '@adapters/cartAdapter';
import { Cart as CartApi } from '@api';
import { useLocalization } from '@hooks/useLocalization';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { addTask } from '@modules/queue/queue';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { usePrevious } from '@hooks/usePrevious';
import { getCartLineByLineId } from '@modules/ga/eventBodyGetters/utils/getCartLine';
import { useAddToCartEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { validateMaxLimit } from '../../helpers/validateMaxLimit';
import { validateMinLimit } from '../../helpers/validateMinLimit';
import { useValidationAlert } from '../useValidationAlert';

interface RequestItem {
  lineItemId: string;
  couponId: string;
  prevQuantity: number;
  newQuantity: number;
}

interface UseChangeCartLineQuantity {
  productCouponId: string;
  quantity: number;
  maxQuantity: number;
  minQuantity: number;
  cartLineId: string;
  setQuantity: (quantity: number) => void;
  inModifyMode?: boolean;
}

const { getCart } = cartAdapter();

export const useChangeCartLineQuantity = ({
  productCouponId,
  quantity,
  maxQuantity,
  minQuantity,
  cartLineId,
  setQuantity,
  inModifyMode,
}: UseChangeCartLineQuantity) => {
  const { t } = useLocalization('components');
  const { alert, setAlert, resetAlert } = useValidationAlert();
  const [currentRequest, setCurrentRequest] = useState<RequestItem>();
  const completedRequest = useDebounce<RequestItem | undefined>(
    currentRequest,
    REQUEST_DEBOUNCE_TIME,
  );
  const [deleteItem] = useDeleteFromCart(cartLineId, inModifyMode);
  const [setProductQuantityInLineItem] = useSetProductQuantityInLineItem();
  const { cartInfo } = useCartForCheckoutContext();
  const oldCart = usePrevious(cartInfo.data);
  const { trackAddToCart } = useAddToCartEvent();

  const updateCartLineQuantity = ({ lineItemId, couponId, newQuantity }: RequestItem) => {
    setProductQuantityInLineItem({
      onError(error) {
        setQuantity(currentRequest?.prevQuantity ?? 0);
        setAlert({
          active: true,
          message: t('cartTile.alerts.cantUpdateQuantity'),
        });
      },
      onCompleted(data: { changeCartLineQuantity: CartApi }, options) {
        const cart = getCart(data?.changeCartLineQuantity);
        const cachedQuantity =
          cart.cartLinesList.find((cartLine) => cartLine.id === cartLineId)?.quantitySelector
            .quantity ?? 0;
        setQuantity(cachedQuantity);
        if (oldCart) {
          const gaCartLine = getCartLineByLineId(oldCart, options?.variables?.lineItemId);
          const productId = gaCartLine?.product.productId ?? '';
          const itemPosition = gaCartLine?.itemPosition ?? 0;
          const itemListName = gaCartLine?.itemListName ?? '';

          // trackAddToCart includes fireRemoveFromCartGAEvent and fireAddToCartGAEvent methods
          trackAddToCart({
            newCart: cart,
            oldCart,
            productIds: [productId],
            itemPosition,
            listName: itemListName,
          });
        }
      },

      variables: {
        lineItemId,
        couponId,
        quantity: newQuantity,
        inModifyMode: !!inModifyMode,
      },
    });
  };

  useUpdateEffect(() => {
    if (completedRequest) {
      updateCartLineQuantity(completedRequest);
    }
  }, [completedRequest]);

  const handleSetNewQuantity = (newQuantity: number) => {
    setQuantity(newQuantity);
    setCurrentRequest((oldRequest?: RequestItem) => {
      if (oldRequest) {
        return {
          ...oldRequest,
          newQuantity,
        };
      }
      return {
        lineItemId: cartLineId,
        couponId: productCouponId,
        prevQuantity: quantity,
        newQuantity,
      };
    });
  };

  const change = (newQuantity: number): void => {
    if (minQuantity < 1 && newQuantity === 0) {
      addTask(deleteItem);
      return;
    }
    if (!validateMinLimit({ newQuantity, minQuantity })) {
      setAlert({
        active: true,
        message: t('cartTile.alerts.minimum', { minQuantity }),
      });
      handleSetNewQuantity(minQuantity);
      return;
    }
    if (!validateMaxLimit({ newQuantity, maxQuantity })) {
      sendGtmEvent(
        GtmCustomEvent({
          /* eslint-disable @typescript-eslint/naming-convention */
          event: 'item_limit_reached',
          event_name: 'item_limit_reached',
          ua_category: 'item limit',
          ua_action: 'item limit reached',
          /* eslint-enable @typescript-eslint/naming-convention */
        }),
      );
      setAlert({
        active: true,
        message: t('cartTile.alerts.maximum', { maxQuantity }),
      });
      handleSetNewQuantity(maxQuantity);
      return;
    }
    handleSetNewQuantity(newQuantity);
  };

  return {
    change,
    alert,
    resetAlert,
  };
};
