/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
import getConfig from 'next/config';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';

interface GenesysAttributes {
  Name?: string | null;
  Email?: string | null;
}

interface InitFunctionProps {
  attributes: GenesysAttributes;
  shouldOpenChat?: boolean;
}

interface GenesysConfig {
  environment: string;
  deploymentId: string;
}

interface LoadGenesysScriptProps {
  windowObject: any;
  genesysFunctionName: string;
  scriptUrl: string;
  genesysConfig: GenesysConfig;
  attributes: GenesysAttributes;
  shouldOpenChat: boolean;
}

export const GenesysChatService = () => {
  const { publicRuntimeConfig } = getConfig();

  const setParticipantData = (attributes: GenesysAttributes) => {
    window?.Genesys('subscribe', 'Database.ready', function () {
      window?.Genesys('command', 'Database.set', {
        messaging: { customAttributes: attributes },
      });
    });
  };

  const initGenesysChat = (attributes: GenesysAttributes) => {
    window?.Genesys('subscribe', 'MessagingService.conversationReset', function () {
      setParticipantData(attributes);
    });

    window?.Genesys('subscribe', 'MessagingService.conversationCleared', function () {
      setParticipantData(attributes);
    });

    setParticipantData(attributes);
  };

  const openGenesysChat = () => {
    window?.Genesys(
      'command',
      'Messenger.open',
      {},
      () => {
        /* fulfilled callback */
      },
      () => {
        /* rejected callback */
      },
    );
  };

  const loadGenesysScript = ({
    windowObject,
    genesysFunctionName,
    scriptUrl,
    genesysConfig,
    attributes,
    shouldOpenChat,
  }: LoadGenesysScriptProps) => {
    windowObject['_genesysJs'] = genesysFunctionName;
    windowObject[genesysFunctionName] =
      windowObject[genesysFunctionName] ||
      function () {
        if (!windowObject[genesysFunctionName].q) {
          windowObject[genesysFunctionName].q = [];
        }
        windowObject[genesysFunctionName].q.push(arguments);
      };
    windowObject[genesysFunctionName].t = 1 * (new Date() as any);
    windowObject[genesysFunctionName].c = genesysConfig;

    const genesysScriptElement = document.getElementById('genesys-script');
    if (genesysScriptElement) {
      initGenesysChat(attributes);
    } else {
      const newScriptElement = document.createElement('script');
      newScriptElement.setAttribute('id', 'genesys-script');
      newScriptElement.async = true;
      newScriptElement.src = scriptUrl;
      newScriptElement.charset = 'utf-8';
      document.head.appendChild(newScriptElement);
      setParticipantData(attributes);

      newScriptElement.onload = () => {
        window.Genesys('subscribe', 'Messenger.ready', () => {
          initGenesysChat(attributes);
          const isChatInitialized = sessionStorageService?.read(STORAGE_KEYS.IS_CHAT_INITIALIZED);

          if (!isChatInitialized || shouldOpenChat) {
            openGenesysChat();
          }
        });
      };
    }
  };

  const init = ({ attributes, shouldOpenChat = false }: InitFunctionProps) => {
    const config: GenesysConfig = {
      environment: 'prod',
      deploymentId: publicRuntimeConfig.genesysDeploymentId,
    };

    loadGenesysScript({
      windowObject: window,
      genesysFunctionName: 'Genesys',
      scriptUrl: 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js',
      genesysConfig: config,
      attributes,
      shouldOpenChat,
    });
  };

  return {
    init,
    openGenesysChat,
  };
};
