/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'react';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { LoginInitResult, LoginInput, LoginMode, LoginResult } from '@api';
import { useAuthModalContext } from '@context/AuthModalContext/AuthModalContext';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { useEcomCart } from '@modules/ga/hooks/useEcomCart';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { GET_FEATURE_ELIGIBILITIES } from '@graphql/common/queries/getFeatureEligibilities';
import { fireLoginSuccessEvent } from '@modules/ga/events/custom/login-success/loginSuccessCreator';
import { LOGIN_INIT } from '../operations/mutations/LoginInit';
import { LOGIN } from '../operations/mutations/Login';
import { AuthUser, USER_AUTH_STATUS } from '../types';
import { QUERIES_TO_SKIP_ON_LOGIN } from '../constants';
import { homeRedirect, modifyOrderRedirect } from '../utils/loginRedirections';
import { AUTH_USER_ACTIONS_TYPES, AuthUserActions } from '../context/AuthUserReducer';
import { handleSuccessfulAuthorization } from '../utils/handleSuccessfulAuthorization';

interface LoginProps {
  dispatch?: Dispatch<AuthUserActions>;
}

export const useLogin = ({ dispatch }: LoginProps) => {
  const client = useApolloClient();
  const trackEcomCart = useEcomCart();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const { dispatch: authModalDispatch, emit } = useAuthModalContext();
  const [loginMutation, { loading: loginMutationLoading }] = useMutation(LOGIN);
  const [loginInitMutation, { loading: loginInitMutationLoading }] = useMutation(LOGIN_INIT);

  const loginErrorHandler = (error: ApolloError) => {
    dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR, payload: error });
    sendGtmEvent(
      GtmCustomEvent({
        event: 'login-failure',
        event_name: 'login_failure',
        ua_category: 'Login',
        ua_action: 'login-failure',
      }),
    );
  };

  const loginCompletedHandler = async (loginResult: LoginResult, callback?: () => void) => {
    homeRedirect();
    await client?.refetchQueries({
      include: [GET_FEATURE_ELIGIBILITIES],
    });

    callback?.();
    if (!loginResult.customer?.tcAcknowledged) {
      authModalDispatch({ type: AUTH_MODAL_TYPES.TCACKNOWLEDGEMENT });
    } else {
      authModalDispatch({ type: AUTH_MODAL_TYPES.SIGN_IN_WELCOME });
    }
    fireLoginSuccessEvent({
      authEvent: loginResult.authEvent,
    });
    trackEcomCart();
    handleSuccessfulAuthorization(loginResult.customer as AuthUser, dispatch);
    emit?.({ type: 'signIn' });
    modifyOrderRedirect();
    clearCacheFields(['homepageFeed']);

    await client?.refetchQueries({
      include: 'active',
      onQueryUpdated: ({ queryName = '' }) => !QUERIES_TO_SKIP_ON_LOGIN.includes(queryName),
    });
  };

  const loginInit = (input: LoginInput, callback?: (loginMode?: LoginMode) => void) => {
    const { email, phoneNumber, rememberMe } = input;

    loginInitMutation({
      variables: { input: { email, phoneNumber, rememberMe } },
      onError: (error) => {
        loginErrorHandler(error);
        callback?.();
      },
      onCompleted: (response: { loginInit: LoginInitResult }) => {
        const newLoginMode = response.loginInit?.mode;
        dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_LOGIN_MODE, payload: newLoginMode });
        callback?.(newLoginMode);
        dispatch?.({
          type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
          payload: USER_AUTH_STATUS.AUTH_BEGIN,
        });
      },
    });
  };

  const login = (input: LoginInput, callback?: () => void) => {
    const { email, password, mfaToken, phoneNumber, rememberMe } = input;

    dispatch?.({
      type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
      payload: USER_AUTH_STATUS.AUTH_BEGIN,
    });

    loginMutation({
      variables: {
        input: { email, phoneNumber, password, mfaToken, rememberMe },
      },
      onError: (error) => {
        loginErrorHandler(error);
        callback?.();
      },
      onCompleted: async (response: { login: LoginResult }) => {
        await loginCompletedHandler(response.login, callback);
        dispatch?.({
          type: AUTH_USER_ACTIONS_TYPES.SET_USER_AUTH_STATUS,
          payload: USER_AUTH_STATUS.AUTH_COMPLETE,
        });
        sessionStorageService?.put(STORAGE_KEYS.AUTH_NOT_COMPLETE, false);
      },
    });
  };

  return {
    login,
    loginInit,
    loginCompletedHandler,
    isLoading: loginMutationLoading || loginInitMutationLoading,
  };
};
