import { useEffect, useState } from 'react';
import { useScript } from 'usehooks-ts';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const READY_MESSAGE = 'ready';

export const usePaymentsScripts = () => {
  const [isLoaded, setisLoaded] = useState(false);
  const client = useScript(`https://js.braintreegateway.com/web/3.79.1/js/client.min.js`);
  const paypal = useScript(`https://js.braintreegateway.com/web/3.79.1/js/paypal-checkout.min.js`);
  const venmo = useScript(`https://js.braintreegateway.com/web/3.79.1/js/venmo.min.js`);
  const dataCollector = useScript(
    `https://js.braintreegateway.com/web/3.79.1/js/data-collector.min.js`,
  );
  const banking = useScript(publicRuntimeConfig.creditPaymentScript);

  useEffect(() => {
    const clientLoaded = client === READY_MESSAGE;
    const paypalLoaded = paypal === READY_MESSAGE;
    const bankingLoaded = banking === READY_MESSAGE;
    const venmoLoaded = venmo === READY_MESSAGE && dataCollector === READY_MESSAGE;
    const loadedScripts = clientLoaded && paypalLoaded && bankingLoaded && venmoLoaded;
    const definedScripts = window.braintree && window.postMessage !== undefined;

    if (loadedScripts || definedScripts) {
      setisLoaded(true);
    }
  }, [client, paypal, banking, venmo, dataCollector]);

  return {
    isLoaded,
  };
};
