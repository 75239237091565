import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import styles from './EmptyCartNote.module.scss';

interface EmptyCartNoteProps {
  titleId?: string;
}

export const EmptyCartNote = ({ titleId }: EmptyCartNoteProps) => {
  const { t } = useLocalization('header');

  return (
    <>
      <Typography component="h2" className={styles.title} id={titleId}>
        {t('basket.emptyStatus.title')}
      </Typography>
      <Typography component="p" className={styles.subtitle}>
        {t('basket.emptyStatus.subtitle')}
      </Typography>
    </>
  );
};
