import { Product } from '@commons/product';
import { IGNORING_SALES_UNITS } from '@constants/salesUnits';
import { PreparedDropdown } from '../types';

export const prepareSalesUnits = (
  salesUnits: Product['salesUnits'],
  alternateSalesUnit: string,
  initialValue?: string,
): PreparedDropdown | null => {
  if (!salesUnits.length) return null;
  const foundSelectedValue = salesUnits.find(({ selected }) => !!selected)?.alternateSalesUnit;
  const preparedSalesUnits = {
    name: alternateSalesUnit,
    optional: false,
    selectedValue: initialValue || foundSelectedValue || null,
    hasError: false,
    values: salesUnits
      .map((value) => ({
        label: value.name,
        name: value.alternateSalesUnit,
        selected: !!value.selected,
      }))
      .filter((item) => !IGNORING_SALES_UNITS.includes(item.label as string)),
  };

  if (preparedSalesUnits.values.length > 0) {
    return preparedSalesUnits;
  }

  return null;
};
