import { routing } from '@constants/routing';

const FEED = '/feed';
const SECTION = '/section';

export const GROUPSCALE = 'channel_rec_groupscale';

export const shouldPassTitle = (value: string) =>
  value === routing.home || value.startsWith(FEED) || value.startsWith(SECTION);
export const getCurrentTitle = (pathname: string, title: string) =>
  shouldPassTitle(pathname) ? title : '';
