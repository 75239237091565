import { ApolloCache } from '@apollo/client';
import { DeliveryAddressesResult } from '@api';

export const updateDeliveryAddressesCache = (
  cache: ApolloCache<unknown>,
  newAddresses: DeliveryAddressesResult,
) => {
  cache.modify({
    fields: {
      userDeliveryAddresses(oldAddress) {
        return {
          ...oldAddress,
          ...newAddresses,
        };
      },
      initModifyOrderCheckout(oldOrderCheckout) {
        return {
          ...oldOrderCheckout,
          deliveryAddress: newAddresses.selectedAddress,
        };
      },
    },
  });
};
