import { DYNAMIC_FEATURES, isFeatureEnabled, getFeatureOptions } from '@utils/dynamicFeatures';

export const getExpressVariant = () => {
  if (isFeatureEnabled(DYNAMIC_FEATURES.EXPRESS_TOGGLE)) {
    const expressToggleOptions = getFeatureOptions(DYNAMIC_FEATURES.EXPRESS_TOGGLE);
    return expressToggleOptions?.variant || 'default';
  }

  return 'disabled';
};
