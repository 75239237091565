import { gql } from '@apollo/client';

export const DELIVERY_TIMESLOTS_DATA_FRAGMENT = gql`
  fragment deliveryTimeslotDataFragment on DeliveryTimeslotData {
    legends {
      imageAlternative
      label
      title
      image
    }
    grid {
      anyDaySlotPresent
      selectedTimeslotId
      orderMetadata {
        maxAdvanceOrderEndDate
        isAdvanceOrderTimeslotReserved
        lastAvailableDeliveryDate
      }
      standingOrder {
        selectedFrequency
        frequencyOptions {
          id
          description
        }
      }
      timeslots {
        days {
          date
          slots {
            id
            startDate
            endDate
            timePeriod
            soldOut
            unavailable
            alcoholRestricted
            discount
            earlyDelivery
            ecoFriendly
            forceSlot
            selected
            cutoffDate
            cutoffTime
            nextSODeliveryDate
            nextSODeliveryDay
          }
          isAfterLastAvailableDeliveryDate
        }
        rangeStart
        rangeEnd
        slotType
      }
    }
    warnings
    holidayRestrictions
  }
`;
