/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IncomingMessage, ServerResponse } from 'http';
import { ApolloLink } from '@apollo/client';
import { IMiddlewareFilterScope } from '@modules/middleware-filter-scope';

export const ref_prog_id = 'ref_prog_id';
export const ref_trk_dtls = 'ref_trk_dtls';
export const iplocator_mocked_ip_address = 'iplocator_mocked_ip_address';

export const webTrackingHeaderKeyPerCodeKey: Record<string, string> = {
  [ref_prog_id]: 'ref_prog_id',
  [ref_trk_dtls]: 'ref_trk_dtls',
  [iplocator_mocked_ip_address]: 'iplocator_mocked_ip_address',
};

export const webTrackingCodeKeys = Object.keys(webTrackingHeaderKeyPerCodeKey);

export const getWebTrackingValuePerHeaderKey = (res?: ServerResponse<IncomingMessage>) =>
  webTrackingCodeKeys.reduce((valuePerHeaderKey, codeKey) => {
    const headerKey = webTrackingHeaderKeyPerCodeKey[codeKey];

    if (
      res
        ?.getHeader(headerKey)
        ?.toString()
        .match(/^[a-zA-Z0-9]$/)
    ) {
      const headerValue = res?.getHeader(headerKey) as string;

      return {
        ...valuePerHeaderKey,
        [headerKey]: headerValue,
      };
    }
    return valuePerHeaderKey;
  }, {});

export const copyWebTrackingCodes = (oldScope: IMiddlewareFilterScope) =>
  Promise.resolve({
    ...oldScope,
    responseHeaders: webTrackingCodeKeys.reduce((responseHeaders, codeKey) => {
      if (oldScope.requestUrl.searchParams.has(codeKey)) {
        responseHeaders.append(
          webTrackingHeaderKeyPerCodeKey[codeKey],
          oldScope.requestUrl.searchParams.get(codeKey) as string,
        );
      }
      return responseHeaders;
    }, new Headers(oldScope.responseHeaders)),
  });

export const copyWebTrackingCodesLink = (valuePerHeaderKey: Record<string, string>) =>
  new ApolloLink((operation, forward) => {
    operation.setContext((oldContext: Record<string, any>) => ({
      ...oldContext,
      headers: {
        ...oldContext.headers,
        ...valuePerHeaderKey,
      },
    }));
    return forward(operation);
  });
