import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AUTH_ERROR_MESSAGE, UNAUTHORIZED_CART_OPERATION } from '@constants/errorCodes';
import {
  handleAuthError,
  handleAuthorizationOperation,
  handleLogoutOperation,
} from '@modules/auth/services/recognizeService';
import { AUTH_OPERATIONS, LOGOUT_OPERATION } from '@modules/auth/constants';

const authErrors = [AUTH_ERROR_MESSAGE, UNAUTHORIZED_CART_OPERATION];

export const recognizedUserLink = onError(({ graphQLErrors, operation }) => {
  const isAuthRequired = graphQLErrors?.some(({ message }) => authErrors.includes(message));

  if (isAuthRequired) {
    handleAuthError(operation);
  }
});

export const resetRecognizedUserLink = new ApolloLink((operation, forward) => {
  const isLogoutOperation = operation.operationName === LOGOUT_OPERATION;
  if (isLogoutOperation) {
    handleLogoutOperation();
  }
  return forward(operation).map((data) => {
    if (data && Object.values<string>(AUTH_OPERATIONS).includes(operation.operationName)) {
      handleAuthorizationOperation(data);
    }

    return data;
  });
});
