import { ERRORS } from '@utils/mapAddressServerErrorsToTranslationKey';
import { useLocalization } from '@hooks/index';
import { validateInstructions } from '@utils/validateOtherInsctuctions';
import { validateUserName } from '@utils/validateUserName';
import { useAddressValidation } from '@hooks/useAddressValidation/useAddressValidation';
import { Suggestion } from '@hooks/useLazyAddressSuggestions/useLazyAddressSuggestions';
import {
  validateApartmentRequired,
  validateCompanyName as companyNameValidator,
} from '@utils/validators';
import { useValidator } from '@hooks/validation/useValidation';
import { PHONE_MASK } from '@utils/validation';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';

type SetFieldErrorHandler = (field: string) => void;

export const useCorporateAddressFormValidation = () => {
  const { validate } = useAddressValidation();
  const { t } = useLocalization();
  const { getFeature } = useHeaderContext();

  const requireErrorText = t('delivery.error.required');
  const notRecognizedErrorText = t('delivery.error.notRecognized');
  const validateNameErrorText = t('delivery.error.validateName');

  const formatError = (key: string) => {
    return !!ERRORS[key] ? t('delivery.error.' + ERRORS[key]) : key;
  };

  const validateCompanyName = useValidator(companyNameValidator);

  const validateAptFormat = useValidator(validateApartmentRequired);

  const validateName = (value: string) => {
    if (!value.trim()) return requireErrorText;
    if (validateUserName(value)) return validateNameErrorText;
  };
  const validatePhone = (value: string) => {
    if (!value) return requireErrorText;
    if (value === PHONE_MASK) return requireErrorText;
    if (value.includes('_')) return t('delivery.error.phoneNumberInvalid');
  };

  const validateAddress = async (
    addressString: string,
    aptUnit: string | null,
    deliveryAddress: Suggestion | null,
    setFieldError: SetFieldErrorHandler,
    setIsAptUnitAvailable: (value: boolean) => void,
    setWarnings?: (warnings: { address: string }) => void,
    aptFormatError?: string | false,
    isCreateStandingOrder?: boolean,
  ) => {
    if (!addressString) return requireErrorText;

    const {
      serviceTypeMessageKey,
      textError: errorKey,
      isErrorAtpUnit,
      isAptUnitAvailable,
    } = await validate(
      { aptUnit, address: deliveryAddress },
      'cache-first',
      !!getFeature?.(FEATURE.COS),
    );

    if (serviceTypeMessageKey) {
      setWarnings?.({ address: serviceTypeMessageKey });
    } else {
      setWarnings?.({ address: '' });
    }

    setIsAptUnitAvailable(!aptUnit && isAptUnitAvailable);

    if (errorKey && addressString.length > 0 && !deliveryAddress) return notRecognizedErrorText;

    if (
      errorKey &&
      !isErrorAtpUnit &&
      (!serviceTypeMessageKey || !setWarnings || isCreateStandingOrder)
    ) {
      return formatError(errorKey);
    }
  };

  const validateAptUnit = async (
    aptUnit: string | null,
    addressString: string,
    deliveryAddress: Suggestion | null,
    setFieldError: SetFieldErrorHandler,
    aptFormatError?: string | false,
  ) => {
    if (!aptUnit) return requireErrorText;

    const { textError: errorKey, isErrorAtpUnit } = await validate(
      {
        aptUnit,
        address: deliveryAddress,
      },
      'cache-first',
      !!getFeature?.(FEATURE.COS),
    );

    if (isErrorAtpUnit) {
      return aptFormatError ? aptFormatError : formatError(errorKey);
    }
    if (errorKey) setFieldError(formatError(errorKey));
  };

  const validateLocationDetails = (value: string) => {
    if (validateInstructions(value)) return validateNameErrorText;
  };

  return {
    validateAptFormat,
    validateCompanyName,
    validateAddress,
    validateAptUnit,
    validateName,
    validatePhone,
    validateLocationDetails,
  };
};
