import omit from 'lodash/omit';
import { Product, ProductTile } from '@commons/product';
import { CustomerData } from '@modules/ga/eventBodyGetters/utils/getCustomerData';
import { EcommerceEvent, getProductMetrics, ProductMetrics } from './utils/eCommerceEvent';

/* eslint-disable @typescript-eslint/naming-convention */
interface SelectItemOptions {
  currencyCode: string;
  product: ProductTile | Product;
  customer: CustomerData;
  itemListName: string;
  itemPosition: number;
}

interface SelectItemEvent extends EcommerceEvent {
  list_name: string;
  ecommerce: {
    currencyCode: string;
    click: {
      actionField: {
        list: string;
      };
      products: Omit<ProductMetrics, 'list' | 'quantity'>[];
    };
  };
}

export const getSelectItemEvent = ({
  product,
  currencyCode,
  customer,
  itemListName,
  itemPosition,
}: SelectItemOptions): SelectItemEvent => {
  const productMetrics = omit(
    getProductMetrics({
      ...product,
      position: itemPosition,
    }),
    ['list', 'quantity'],
  );

  return {
    event: 'productClick',
    event_name: 'select_item',
    customer: {
      deliverypass_type: customer.deliveryPassType,
      deliverypass_term: customer.deliveryPassTerm,
      express_eligible: customer.expressEligible,
    },
    ua_category: 'Ecommerce Action',
    ua_action: 'Product Click',
    list_name: itemListName,
    ecommerce: {
      currencyCode: currencyCode,
      click: {
        actionField: {
          list: itemListName,
        },
        products: [productMetrics],
      },
    },
  };
};
