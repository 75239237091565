import isEqual from 'lodash/isEqual';
import { ConfOption } from '@api';
import { InitialValues } from '@hooks/cart/useProductInfoFromCart';

interface HasCartLineBeenChangedOptions {
  quantity: number;
  salesUnit: string;
  configuration: ConfOption[];
  initialValues?: InitialValues;
}

const CONFIGURATION_CHARACTERISTIC_NAME = 'alternateSalesUnit';

export const hasCartLineChanged = ({
  quantity,
  salesUnit,
  configuration,
  initialValues,
}: HasCartLineBeenChangedOptions) => {
  const { count, salesUnit: initialSalesUnit, variables } = initialValues ?? {};
  const isQuantityChanged = count !== quantity;
  const isSalesUnitChanged = salesUnit !== initialSalesUnit;

  const isConfigurationChanged = configuration.reduce((isChanged, conf) => {
    if (conf.characteristicName !== CONFIGURATION_CHARACTERISTIC_NAME) {
      const foundVariable = variables?.find(
        (variable) => variable.name === conf.characteristicName,
      );
      if (!isEqual(foundVariable?.value, conf.characteristicValue)) {
        return true;
      }
    }
    return isChanged;
  }, false);

  return isQuantityChanged || isSalesUnitChanged || isConfigurationChanged;
};
