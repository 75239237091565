import { gql } from '@apollo/client';
import { DELIVERY_ADDRESS_FRAGMENT } from '@graphql/fragments/account/deliveryAddress';

export const GET_USER_DELIVERY_ADDRESSES = gql`
  query UserDeliveryAddresses($standingOrderMode: StandingOrderMode) {
    userDeliveryAddresses(standingOrderMode: $standingOrderMode) {
      homeAddresses {
        ...deliveryAddressFragment
      }
      pickUpDepotsAddresses {
        ...deliveryAddressFragment
      }
      corpAddresses {
        ...deliveryAddressFragment
      }
      selectedAddress {
        ...deliveryAddressFragment
      }
      temporaryAddress {
        ...deliveryAddressFragment
      }
      guestUser
      userExpressEnabled
      futureZoneNotification
      showRecaptcha
    }
  }
  ${DELIVERY_ADDRESS_FRAGMENT}
`;

export const GET_USER_DELIVERY_ADDRESSES_PROTECTED = gql`
  query UserDeliveryAddressesProtected($standingOrderMode: StandingOrderMode) {
    userDeliveryAddresses(standingOrderMode: $standingOrderMode) {
      hasNoDeliveryAddresses
      homeAddresses {
        ...deliveryAddressFragment
      }
      pickUpDepotsAddresses {
        ...deliveryAddressFragment
      }
      corpAddresses {
        ...deliveryAddressFragment
      }
      selectedAddress {
        ...deliveryAddressFragment
      }
      temporaryAddress {
        ...deliveryAddressFragment
      }
      guestUser
      userExpressEnabled
      futureZoneNotification
      showRecaptcha
      signedUser
    }
  }
  ${DELIVERY_ADDRESS_FRAGMENT}
`;

export const USER_DELIVERY_ADDRESSES_CACHE_FIELD = 'userDeliveryAddresses';
