import { PageRequest, SearchFilter } from '@api';
import { ReorderProduct } from './product';

export const enum FILTER_IDS {
  ORDERS_BY_DATE = 'ordersByDate',
  SHOW_ME_ONLY = 'show_me_only',
  DEPARTMENTS = 'dept',
}

export const enum FILTER_NAMES {
  DATES = 'Dates',
  SHOP_BY = 'Shop by',
  DEPARTMENTS = 'Departments',
}

export interface PastItemsFilterInput {
  pageRequest: PageRequest;
  filters: SearchFilter[];
  text?: string;
}

export interface PastOrdersFilterInput {
  itemsFilter: PastItemsFilterInput;
  shouldBeMerged: boolean;
  monthFilter: boolean;
}

export interface PastOrder {
  id: string;
  deliveryDate: string;
  title: string;
  productsCount: number;
  orderItemLines: ReorderProduct[];
}

export interface MonthsFilter {
  month: string;
  shortMonth: string;
  year: string;
  orderIds: string[];
  disabled: boolean;
}

export const REORDER_FILTER_OPTIONS = {
  AllOrders: 'all orders',
  January: 'jan',
  February: 'feb',
  March: 'mar',
  April: 'apr',
  May: 'may',
  June: 'jun',
  July: 'jul',
  August: 'aug',
  September: 'sept',
  October: 'oct',
  November: 'nov',
  December: 'dec',
};
