import cn from 'classnames';
import { forwardRef, ReactNode } from 'react';
import Link from 'next/link';
import styles from './LinkV2.module.scss';

export interface LinkProps {
  children: ReactNode;
  href: string;
  fontWeight?: 'regular' | 'bold';
  className?: string;
  // for restProps
  [x: string]: unknown;
}

export const LinkV2 = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, fontWeight = 'regular', className, href, ...restProps }, ref) => {
    return (
      <Link
        {...restProps}
        ref={ref}
        href={href}
        className={cn(
          styles.link,
          {
            [styles.bold]: fontWeight === 'bold',
          },
          className,
        )}
      >
        {children}
      </Link>
    );
  },
);

LinkV2.displayName = 'LinkV2';
