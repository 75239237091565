import { SyntheticEvent, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useInterceptIframeHtmlLink } from '@hooks/useInterceptIframeHtmlLink';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { EXTERNAL_LINK } from '@utils/validation';

interface IframeModalContentProps {
  url: string;
  title: string;
  dataTestId?: string;
}
const VERTICAL_IFRAME_MARGINS = 16;

export const IframeModalContent = ({ url, title, dataTestId }: IframeModalContentProps) => {
  const [iframeHeight, setIframeHeight] = useState(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const router = useRouter();
  const { isMobile } = useHeaderContext();

  const onLoadHandler = (event: SyntheticEvent<HTMLIFrameElement, Event>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { contentWindow } = event.target;
    const main = contentWindow.document.querySelector('body');

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setIframeHeight(entry.contentRect.height);
      });
    });

    resizeObserver.observe(main);

    const onVisibilityChange = () => {
      resizeObserver.disconnect();
      contentWindow.addEventListener('visibilitychange', onVisibilityChange);
    };

    contentWindow.addEventListener('visibilitychange', onVisibilityChange);
  };

  const handleIframeClick = (event: Event, link?: string | null) => {
    if (link && link[0] !== '#') {
      event.preventDefault();
      event.stopPropagation();

      if (isMobile) {
        router[link.includes('gift_card') ? 'reload' : 'push'](link);
      } else {
        const path = EXTERNAL_LINK.test(link) ? link : `${router.basePath}${link}`;
        window.open(path, '_blank');
      }
    }
  };

  useInterceptIframeHtmlLink(iframeRef.current, handleIframeClick);

  return (
    <iframe
      src={url}
      height={iframeHeight + VERTICAL_IFRAME_MARGINS}
      width="100%"
      frameBorder="0"
      scrolling="no"
      title={title}
      data-testid={dataTestId}
      onLoad={onLoadHandler}
      ref={iframeRef}
    />
  );
};
