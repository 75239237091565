import { createContext, useContext } from 'react';
import { On, Emit } from '@utils/emitter';

export type Action = 'open' | 'close' | 'back';

export const ContactUsFormModalContext = createContext<
  | {
      opened: boolean;
      on: On<Action>;
      emit: Emit<Action>;
    }
  | undefined
>(undefined);

export const useContactUsFormModalContext = () => {
  const context = useContext(ContactUsFormModalContext);
  if (context === undefined) {
    throw new Error(
      'useContactUsFormModalContext must be used within a ContactUsFormModalProvider',
    );
  }
  return context;
};
