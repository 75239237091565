import { gql } from '@apollo/client';
import { PAYMENT_METHODS_FRAGMENT } from '@graphql/fragments/account/paymentMethods';
import { VALIDATION_ERROR_FRAGMENT } from '@graphql/fragments/account/validationError';

export const EDIT_BANK_ACCOUNT = gql`
  mutation EditBankAccount($input: BankAccountInput!, $standingOrderMode: StandingOrderMode) {
    editBankAccount(input: $input, standingOrderMode: $standingOrderMode) {
      data {
        ...paymentMethodsFragment
      }
      validationErrors {
        ...validationErrorFragment
      }
    }
  }
  ${PAYMENT_METHODS_FRAGMENT}
  ${VALIDATION_ERROR_FRAGMENT}
`;
