import { useMutation } from '@apollo/client';
import { DeleteReceivedGiftCardMutation, DeleteReceivedGiftCardMutationVariables } from '@api';
import { DELETE_RECEIVED_GIFT_CARD } from '@graphql/account/mutations/deleteReceivedGiftCard';

export const useDeleteRecivedGiftCard = () => {
  return useMutation<DeleteReceivedGiftCardMutation, DeleteReceivedGiftCardMutationVariables>(
    DELETE_RECEIVED_GIFT_CARD,
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            giftCards(giftCards) {
              return {
                ...giftCards,
                receivedCards: { ...data?.deleteReceivedGiftCard },
              };
            },
          },
        });
      },
    },
  );
};
