import { gql } from '@apollo/client';
import { PAYMENT_METHODS_FRAGMENT } from '@graphql/fragments/account/paymentMethods';
import { VALIDATION_RESULT_FRAGMENT } from '@graphql/fragments/account/validationResult';

export const APPLY_PCI_PAL_PAYMENT = gql`
  mutation ApplyPciPalPayment($input: [InputPair!]!) {
    applyPciPalPayment(input: $input) {
      payments {
        ...paymentMethodsFragment
      }
      validationResult {
        ...validationResultFragment
      }
    }
  }
  ${PAYMENT_METHODS_FRAGMENT}
  ${VALIDATION_RESULT_FRAGMENT}
`;
