import { useLazyQuery } from '@apollo/client';
import { VALIDATE_REFERER } from '@graphql/account/queries/validateRefer';

export const useValidateReferer = (userCode?: string, promoCode?: string) => {
  const [refetch, { data, loading, ...queryResult }] = useLazyQuery(VALIDATE_REFERER, {
    variables: { input: { userCode, promoCode } },
    fetchPolicy: 'network-only',
  });

  return {
    ...queryResult,
    data,
    loading,
    refetch,
  };
};
